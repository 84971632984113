import { useMemo } from "react";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";

const useTranslationsV2 = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	return useMemo(() => {
		if (!workingEvent) {
			return false;
		}

		// Specific check for v2, not >= 2
		const useV2 = (
			(featureFlags["streams.translations_v2"] && Number(workingEvent.settings.translation_version) === 2)
			// Or fallback to v2 if v3 is selected but FF gets flagged OFF
			|| (Number(workingEvent.settings.translation_version) === 3 && !featureFlags["streams.translations_v3"])
		);
		return useV2;
	}, [workingEvent, featureFlags]);
};

export default useTranslationsV2;