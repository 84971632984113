import classNames from 'classnames';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { useIsNewModuleGrouping } from 'hooks/session.hooks';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useSessionUuidParamAdmin } from 'utils/use-session-uuid-param';

import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { TextOverrides, } from '../../../types/working-model';
import { parseColor } from '../../../utils/document-head';
import { OptionalComponent } from '../../../utils/optional-component';
import { useRefCallback } from '../../../utils/use-ref-callback';
import EditSectionColorsModal, { EContentColorOverrideNames } from '../edit-section-colors/edit-section-colors-modal';
import Icon, { COLORS, ICONS } from '../icon';
import { useGetColorOptionsFromColor } from 'utils/colors/use-get-color-options-from-color';
import {
	EPaletteModes,
	ColorKeys,
	ColorOptions,
	TColorVariantKeys,
} from 'types/theme-packs';

import './editable-text.scss';
import { ThemeContext } from 'components/live-event/theme-context';

type MultiColorType = TColorVariantKeys | ColorKeys;

const useStartingColor = (color: MultiColorType | undefined, defaultColor?: MultiColorType): MultiColorType | undefined => {
	const sessionUuid = useSessionUuidParamAdmin();
	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	return useMemo(() => {
		if (color) {
			return color;
		}

		if (sessionUuid && isModuleGroupingV2) {
			if (isDarkMode) {
				return ColorKeys.darkModeColor;
			} else {
				return ColorKeys.lightModeColor;
			}
		}

		return defaultColor;
	}, [color, defaultColor, isDarkMode, isModuleGroupingV2, sessionUuid]);
};

const useCurrentColor = (
	startingColor: MultiColorType | undefined,
	colorPalette: ColorOptions | undefined
): [string, number] => {
	return useMemo(() => {
		if (startingColor) {
			if (startingColor === ColorKeys.lightModeColor) {
				return ['#081543', 1];
			} else if (startingColor === ColorKeys.darkModeColor) {
				return ['#FFFFFF', 1];
			}

			const color = colorPalette?.[startingColor];
			if (!Array.isArray(color)) return ['#444', 1];

			return color;
		}

		return ['#444', 1];
	}, [colorPalette, startingColor]);
};


interface Props {
	value: string;
	/** handles text changes (this only runs on blur) */
	onChange: (value: string) => void;
	/** handles text changes (this runs on text change) */
	onTextChange?: (value: string) => void;
	style?: React.CSSProperties;
	className?: string;
	elementClass?: string;

	tagType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";//creates the specified tag and removes markdown, more tag types can be added

	elementName?: EContentColorOverrideNames; //name of the element being edited (description/title), used in the color editor modal

	//Your onOverrideChange func should update the styling_override attribute of the module you're editing
	//and include pathing to the specific elemnt you're editing
	//e.g.
	// {
	// 	...module,
	// 	styling_overrides: {
	// 		...module.styling_overrides,
	// 		[your element e.g "title"]: override
	// 	}
	// }
	onOverrideChange?: (override: TextOverrides) => void;
	// that path is also what you would pass in as the initial textOverrides
	colorOverride?: string;
	hideColors?: boolean;

	//include these defaults if you want to give the user the option of editing an override without setting one before hand. 
	//The default should represet what the template would be (title is "h1"/"h2" and "headingTextcolor", most things are left aligned etc.)
	defaultColor?: MultiColorType;
	disabled?: boolean;
	maxCharacters?: number;
	displayCountAtXCharacter?: number;
	onEditableClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
	onEditableContainerClick?: React.MouseEventHandler<HTMLDivElement> | undefined;

	onFocus?: () => void;
	onBlur?: () => void;
	placeholder?: string;
	hidePencil?: boolean;
}

function convertFromHtml(html: string): string {
	const tempCont = document.createElement('div');
	tempCont.innerHTML = html;

	return tempCont.innerText;
}

const EditableTextSlim: React.FC<Props> = (props) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const {
		colorOverride,
		defaultColor,
		disabled,
		displayCountAtXCharacter = 0,
		elementClass = "",
		elementName,
		hideColors = false,
		hidePencil,
		maxCharacters,
		onBlur = () => null,
		onEditableClick,
		onEditableContainerClick = () => null,
		onFocus = () => null,
		onOverrideChange,
		placeholder,
		tagType = "h1",
		value,
	} = props;

	const colorPalette = useGetColorOptionsFromColor(
		workingEvent?.settings.design.colors,
		workingEvent?.settings.design.color_theme || EPaletteModes.Light
	);

	const color = colorOverride?.split("-")[0] as MultiColorType | undefined; //removing the "-color" from the classname
	const [text, setText] = useState(value);
	const [editSectionColorsModalResetKey, setEditSectionColorsModalResetKey] = useState(0);
	const [colorsOpen, setColorsOpen] = useState(false);
	const [toolBarActive, setToolBarActive] = useState(false);

	const editorRef = useRef<HTMLDivElement | null>(null);

	// keep in sync with parent
	useEffect(() => {
		setText(value);
	}, [value]);

	// Use Refcallback per https://github.com/lovasoa/react-contenteditable/issues/161#issuecomment-736053428
	const handleTextChange = useRefCallback((event: ContentEditableEvent) => {
		setText(event?.target.value);

		if (props.onTextChange) {
			const convertedFromHtml = convertFromHtml(event?.target.value);
			props.onTextChange(convertedFromHtml);
		}
	}, []);

	const handleKeyDown = useRefCallback((event: React.KeyboardEvent) => {
		const isArrow = event.key === 'ArrowLeft'
			|| event.code === 'ArrowLeft'
			|| event.keyCode === 37
			|| event.key === 'ArrowRight'
			|| event.code === 'ArrowRight'
			|| event.keyCode === 39;

		const isBackspace = event.key === 'Backspace'
			|| event.code === 'Backspace'
			|| event.keyCode === 8;

		const greaterThanMaxCharacters = maxCharacters && convertFromHtml(text).length >= maxCharacters;

		if (greaterThanMaxCharacters && !isBackspace && !isArrow) {
			event.preventDefault();
		}

		if (event.key === 'Enter') {
			document?.execCommand('insertLineBreak');
			event.preventDefault();
		}
	});

	// Use Refcallback per https://github.com/lovasoa/react-contenteditable/issues/161#issuecomment-736053428
	const handleBlur = useRefCallback(() => {
		if (convertFromHtml(text) !== value) { // Only update if changed
			props.onChange(convertFromHtml(text));
		}
		onBlur();
		//We need to give the onClick function time to fire otherwise the onBlur fires first and removes the toolbar before it has a chance to fire the onClick.
		setTimeout(() => setToolBarActive(false), 300);
	}, [text]);

	function handleColorChange(_: string, color: string) {
		if (!onOverrideChange) return;
		onOverrideChange({
			color
		});
	}

	const startingColor = useStartingColor(color, defaultColor);
	const currentColor = useCurrentColor(startingColor, colorPalette);

	function openColors() {
		setColorsOpen(true);
	}

	return (
		<div
			style={props.style ?? {}}
			className={classNames("editable-text slim-container", { disabled }, props.className, { active: toolBarActive })}
			onClick={onEditableContainerClick}
		>
			<ContentEditable
				innerRef={editorRef}
				html={text || ''}
				onBlur={handleBlur}
				onChange={handleTextChange}
				onKeyDown={handleKeyDown}
				onClick={onEditableClick}
				onFocus={() => {
					setToolBarActive(true);
					onFocus();
				}}
				tagName={tagType}
				className={classNames(elementClass, colorOverride)}
				placeholder={placeholder}
			/>

			<OptionalComponent display={!hidePencil}>
				<button
					className="round edit-text-button"
					onClick={() => editorRef.current?.focus()}
					disabled={disabled}
				>
					<Icon name={ICONS.EDIT} size={12} color={COLORS.WHITE} />
				</button>
			</OptionalComponent>

			{startingColor && !hideColors ? (
				<div className="toolbar slim">
					<button
						className={classNames("small round color-display")}
						style={{
							backgroundColor: currentColor ? parseColor(currentColor) : "",
						}}
						onClick={openColors}
					/>
				</div>
			) : null}

			<EditSectionColorsModal
				open={colorsOpen}
				onClose={() => {
					setColorsOpen(false);
					// reset everything in the modal after it closes
					// timeout to keep the close animation
					setTimeout(() => {
						setEditSectionColorsModalResetKey(prev => prev + 1);
					}, 100);
				}}
				onFinish={handleColorChange}
				title={elementName || EContentColorOverrideNames.title}
				initialSelectedColorVariable={colorOverride?.split("-")[0] || defaultColor}
				key={editSectionColorsModalResetKey}
			/>
			<AnimatePresence>
				<LazyMotion features={domAnimation}>
					<OptionalComponent display={!!maxCharacters && toolBarActive && convertFromHtml(text).length >= displayCountAtXCharacter}>
						<m.span
							className="max-character-count"
							initial={{ scale: 0, opacity: 0 }}
							animate={{ scale: 1, opacity: 1 }}
							exit={{ scale: 0, opacity: 0 }}
							transition={{
								type: "spring",
								stiffness: 900,
								damping: 15,
								velocity: 1.1
							}}
						>{convertFromHtml(text).length}/{maxCharacters}</m.span>
					</OptionalComponent>
				</LazyMotion>
			</AnimatePresence>
		</div >
	);
};

export default EditableTextSlim;
