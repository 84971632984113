//import { Action, AsyncAction } from '../types/types';
import {
	GetBroadcastServer,
	GetEventsSessionRtmpEndpoint,
	GetEventsLegacyRtmpEndpoint,
	StartBroadcast,
	StopBroadcast,
	GetRtmpEndpoint,
	ResetBroadcastServer
} from '../../../connection/broadcasting';
import { BroadcastStats, BROADCAST_STATES } from '../../reducers/admin/broadcasting';
import { GetBroadcastStreamingConfiguration } from '../../../connection/broadcasting';

export interface Action {
	type: string;
	payload: any;
	remote?: boolean;
	id?: number;
}

export interface AsyncAction {
	type: string;
	promise: Promise<any>
}

export interface CustomRtmpData {
	useCustomEndpoint: boolean;
	endpoint: {
		name: string,
		url: string,
		stream_key: string
	}
}

export const SET_META_MENU_ROUTE = 'SET_META_MENU_ROUTE';
export function setMetaMenuRoute(route: string): Action {
	return {
		type: SET_META_MENU_ROUTE,
		payload: route,
	};
}

export const SET_META_MENU_STATE = 'SET_META_MENU_STATE';
export function setMetaMenuState(metaMenuState: string): Action {
	return {
		type: SET_META_MENU_STATE,
		payload: metaMenuState,
	};
}

export const GET_BROADCAST_SERVER = "GET_BROADCAST_SERVER";
export function getBroadcastServer(token: string, session_uuid: string): AsyncAction {
	return {
		type: GET_BROADCAST_SERVER,
		promise: GetBroadcastServer(token, session_uuid)
	};
}

export const GET_BROADCAST_ENDPOINT = "GET_BROADCAST_ENDPOINT";
export function getBroadcastEndpoint(token: string, session_uuid: string): AsyncAction {
	return {
		type: GET_BROADCAST_ENDPOINT,
		promise: GetRtmpEndpoint(token, session_uuid)
	};
}

export const START_BROADCAST = "START_BROADCAST";
export function startBroadcast(token: string, session_uuid: string, resolution: string): AsyncAction {
	return {
		type: START_BROADCAST,
		promise: StartBroadcast(token, session_uuid, resolution)
	};
}

export const STOP_BROADCAST = "STOP_BROADCAST";
export function stopBroadcast(token: string, session_uuid: string): AsyncAction {
	return {
		type: STOP_BROADCAST,
		promise: StopBroadcast(token, session_uuid)
	};
}

export const SET_BROADCAST_STATE = "SET_BROADCAST_STATE";
export function setBroadcastState(state: BROADCAST_STATES): Action {
	return {
		type: SET_BROADCAST_STATE,
		payload: state
	};
}

export const SET_PREVIOUS_BROADCAST_STATE = "SET_PREVIOUS_BROADCAST_STATE";
export function setPreviousBroadcastState(state: number): Action {
	return {
		type: SET_PREVIOUS_BROADCAST_STATE,
		payload: state
	};
}

export const SET_BROADCAST_STATS = "SET_BROADCAST_STATS";
export function setBroadcastStats(stats: BroadcastStats): Action {
	return {
		type: SET_BROADCAST_STATS,
		payload: stats
	};
}

export const GET_EVENTS_SESSION_BROADCAST_ENDPOINT = "GET_EVENTS_SESSION_BROADCAST_ENDPOINT";
export function getEventsSessionRtmpEndpoint(token: string, session_uuid: string, language: string): AsyncAction {
	return {
		type: GET_EVENTS_SESSION_BROADCAST_ENDPOINT,
		promise: GetEventsSessionRtmpEndpoint(token, session_uuid, language)
	};
}

export const GET_EVENTS_LEGACY_BROADCAST_ENDPOINT = "GET_EVENTS_LEGACY_BROADCAST_ENDPOINT";
export function getEventsLegacyBroadcastEndpoint(token: string, channel: number): AsyncAction {
	return {
		type: GET_EVENTS_LEGACY_BROADCAST_ENDPOINT,
		promise: GetEventsLegacyRtmpEndpoint(token, channel)
	};
}

export const GET_SHOWROOMS_BROADCAST_ENDPOINT = "GET_SHOWROOMS_BROADCAST_ENDPOINT";
export function getShowroomsBroadcastEndpoint(token: string, sessionID: string): AsyncAction {
	return {
		type: GET_SHOWROOMS_BROADCAST_ENDPOINT,
		promise: GetBroadcastStreamingConfiguration(token, sessionID)
	};
}

export const SET_CUSTOM_BROADCAST_ENDPOINT = "SET_CUSTOM_BROADCAST_ENDPOINT";
export function setCustomBroadcastEndpoint(data: CustomRtmpData): Action {
	return {
		type: SET_CUSTOM_BROADCAST_ENDPOINT,
		payload: data
	};
}

export const SET_STREAM_STATE = "SET_STREAM_STATE";
export function setStreamState(status: number): Action {
	return {
		type: SET_STREAM_STATE,
		payload: status
	};
}

export const SET_GO_LIVE_BUTTON_CLICKED = "SET_GO_LIVE_BUTTON_CLICKED";
export function setGoLiveButtonClicked(goLiveButtonClicked: boolean): Action {
	return {
		type: SET_GO_LIVE_BUTTON_CLICKED,
		payload: goLiveButtonClicked
	};
}

export const RESET_BROADCAST_SERVER = "RESET_BROADCAST_SERVER";
export function resetBroadcastServer(token: string, session_uuid: string): AsyncAction {
	return {
		type: RESET_BROADCAST_SERVER,
		promise: ResetBroadcastServer(token, session_uuid)
	};
}