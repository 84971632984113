export enum StreamState {
	live = 'live',
	offline = 'offline',
}

export type StreamStateChange = {
	/** id is a BIGINT */
	id: number;
	stream_id: string;
	current_state: StreamState;
	session_uuid: string;
	timestamp: Date;
	metadata: Record<string, string>;
}

export type LiveStateChangePayload = {
	sessionUuid: string;
	state: StreamState;
	language: string;
	playbackUrl: string;
	endAt?: number;
}

export type LiveStateGetResponseItem = {
	playbackUrl: string;
	data: {
		state: StreamState;
		timestamp: number;
	};
}

export type LiveStateGetResponse = LiveStateGetResponseItem[];

export type LiveStateEntry = {
	state: StreamState;
	liveAt: number;
	endAt?: number;
}

export type LiveState = {
	[key: string]: LiveStateEntry;
};