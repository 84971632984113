import {
	ColorKeys,
	ColorValueIndexes,
	EPaletteModes,
	TColorItemArray,
	TColorVariantKeys,
	ThemePack,
} from "types/theme-packs";
import getRelativeContrastColor from "./generate-relative-contrasting-color";

const getAdjustedThemePackColor = (
	updatedPack: ThemePack,
	updatedColor: TColorItemArray,
	colorTheme: EPaletteModes,
	colorKey: TColorVariantKeys,
) => {
	const currentColor = updatedPack?.colors?.[colorTheme]?.[colorKey];
	const buttonTextColor = getRelativeContrastColor(
		currentColor?.[ColorValueIndexes.hex] || '',
		updatedColor[ColorValueIndexes.hex],
	);

	return { [ColorKeys.buttonTextColor]: [buttonTextColor, 1] };
};

export default getAdjustedThemePackColor;
