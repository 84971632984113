import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTypedSelector } from '../../store/reducers/use-typed-selector';
import { Options } from './types';
import TrackingContext from './TrackingContext';
import { useTrackingImpl } from './use-tracking-impl';
import { ActionPayload } from '../../types/working-model';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTracking = <P extends ActionPayload>(trackingData?: Partial<P>, options?: Partial<Options<P>>) => {
	const bl_profile = useTypedSelector((state) => state?.LiveEventReducer?.blProfileUser?.bl_profile || 0);
	const channel_id = useTypedSelector((state) => state?.LiveEventReducer?.eventBundle?.channel || 0);
	const event_id = useTypedSelector((state) => state?.LiveEventReducer?.eventBundle?.event || 0);
	const registration_id = useTypedSelector((state) => state?.LiveEventReducer?.registrationId || '');
	const user_session_uuid = useTypedSelector((state) => state?.LiveEventReducer?.userSession || '');
	const validPasscodeLists = useTypedSelector((state) => state?.LiveEventReducer?.validPasscodeLists);

	const _trackingData = { ...trackingData };
	if (registration_id) { // no point in doing this if there's no registration
		if (_trackingData.miscellaneous) {
			const parsed = JSON.parse(_trackingData.miscellaneous);
			parsed.valid_passcode_lists = validPasscodeLists;
			_trackingData.miscellaneous = JSON.stringify(parsed);
		} else {
			_trackingData.miscellaneous = JSON.stringify({ valid_passcode_lists: validPasscodeLists });
		}
	}

	const contextValue = useTrackingImpl({
		..._trackingData,
		action_id: uuidv4(),
		bl_profile,
		channel_id,
		event_id,
		registration_id,
		source_app: 'events',
		source_app_version: process.env.REACT_APP_VERSION ?? "0",
		user_session_uuid,
	}, options);

	const Track = useCallback(({ children }: { children: React.ReactNode }) => (
		<TrackingContext.Provider value={contextValue}>
			{children}
		</TrackingContext.Provider>
	), [contextValue]);

	return useMemo(() => ({
		Track,
		trackEvent: contextValue?.tracking.dispatch,
	}), [contextValue, Track]);
};

export default useTracking;
