import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Icon, { ICONS } from '../icon';
import './collapsing-list.scss';
import useTrackHeight from 'utils/use-track-height';
import { ConditionalWrapper } from 'utils/conditional-wrapper';

interface Props {
	label?: string;
	children: any;
	defaultOpen?: boolean;
	className?: string;
	size?: string;
	headerRight?: any;
	overflowVisible?: boolean;
	icon?: any;
	customHeader?: (toggleOpen: () => void, isOpen: boolean) => JSX.Element;
	preHeader?: (toggleOpen: () => void, isOpen: boolean) => JSX.Element;
	closeAll?: boolean;
	autoWidthTrigger?: boolean;
	reverseIconPosition?: boolean;
	collapsingListStyles?: {
		open?: CSSProperties;
		closed?: CSSProperties;
	};
	scrollToSelectedOnOpen?: boolean;
	onToggle?: (open: boolean) => void;
	showScrollBar?: boolean;
	conditionalContentsWrapper?: (children: JSX.Element) => JSX.Element;
}

export default function CollapsingList(props: Props): JSX.Element {
	const {
		label = '',
		children,
		defaultOpen,
		className,
		size = "normal",
		headerRight,
		overflowVisible = false,
		icon,
		customHeader,
		preHeader,
		closeAll,
		autoWidthTrigger = false,
		reverseIconPosition = false,
		collapsingListStyles = {},
		onToggle = () => null,
		showScrollBar = false,
		conditionalContentsWrapper,
	} = props;
	const [open, setOpen] = useState(!!defaultOpen);
	const [displayNone, setDisplayNone] = useState(true);
	const [isFocused, setIsFocused] = useState(false);

	const triggerRef = useRef<HTMLButtonElement | null>(null);
	const listContentsRef = useRef<HTMLDivElement | null>(null);
	const listContentsRevealerRef = useRef<HTMLDivElement | null>(null);

	const listContentsHeight = useTrackHeight({ ref: listContentsRef.current });
	const listContentsRevealerHeight = useTrackHeight({ ref: listContentsRevealerRef.current });
	const hasOverflow = listContentsRevealerHeight.height > listContentsHeight.height;

	const timeout = useRef<NodeJS.Timeout | null>(null);

	const [showTransitionScrollMargin, setShowTransitionScrollMargin] = useState(false);
	useEffect(() => {
		// on close, if we have an overflow and showScrollbar, let's keep a margin-right of 20px (scrollbar width plus margin)
		if (open) {
			// remove the class
			setShowTransitionScrollMargin(false);
		} else {
			// remove the class
			if (hasOverflow && showScrollBar) {
				setShowTransitionScrollMargin(true);
			}
		}
	}, [hasOverflow, open, showScrollBar]);


	// prevent hook from overfiring by using a ref
	const onToggleRef = useRef(onToggle);
	useEffect(() => {
		onToggleRef.current = onToggle;
	}, [onToggle]);

	useEffect(() => {
		onToggleRef.current?.(open);
	}, [open]);

	const collapsingListCustomStyles = useMemo(() => {
		if (open) {
			return collapsingListStyles?.open ?? {};
		}
		return collapsingListStyles?.closed ?? {};
	}, [open, collapsingListStyles]);

	const toggleOpen = useCallback(() => (shouldOpen = false, shouldClose = false) => {
		let updatedOpen = !open;
		if (shouldOpen) {
			setOpen(true);
			updatedOpen = true;
		} else if (shouldClose) {
			setOpen(false);
			updatedOpen = false;
		} else {
			setOpen(updatedOpen);
		}
		// We want to set the content to display none if it's closed
		// otherwise everything inside can be tabbed to and clicked when it's collapsed.
		// But we also want to maintain the css transitions, so we need to delay setting it to display none
		// until it's collapsed
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		if (updatedOpen) {
			setDisplayNone(false);
		} else {
			timeout.current = setTimeout(() => {
				setDisplayNone(true);
			}, 200);
		}
	}, [open]);

	useEffect(() => {
		if (closeAll) {
			toggleOpen()(false, true);
		}
	}, [closeAll, toggleOpen]);

	// allow left and right arrow keys to open/close
	useEffect(() => {
		const triggerRefCopy = triggerRef?.current;

		const handleKeydown = (e: KeyboardEvent) => {
			if (isFocused) {
				if (e.key === 'ArrowLeft') {
					toggleOpen()(false, true);
				}
				if (e.key === 'ArrowRight') {
					toggleOpen()(true);
				}
			}
		};

		const handleKeyup = (e: KeyboardEvent) => {
			if (e.key === 'Tab') {
				setIsFocused(true);
			}
		};

		if (triggerRefCopy) {
			triggerRefCopy.addEventListener('keydown', handleKeydown);
			triggerRefCopy.addEventListener('keyup', handleKeyup);
		}

		return () => {
			if (triggerRefCopy) {
				triggerRefCopy.addEventListener('keydown', handleKeydown);
				triggerRefCopy.removeEventListener('keyup', handleKeyup);
			}
		};
	}, [triggerRef, isFocused, toggleOpen]);

	return (
		<>
			{preHeader?.(toggleOpen(), open)}
			<div className={classNames("collapsing-list-container", className, { open })}>
				{customHeader ? (
					customHeader ? customHeader(toggleOpen(), open) : null
				) : (
					<div
						className={classNames(
							'collapsing-list-header',
							{ 'auto-width-trigger': autoWidthTrigger }
						)}
					>
						<button
							ref={triggerRef}
							onClick={() => toggleOpen()()}
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							className="no-style clear collapsing-list-header-button"
						>
							<span
								style={{
									display: 'flex',
									flexDirection: reverseIconPosition ? 'row-reverse' : 'row',
								}}
							>
								{icon
									? icon
									: <Icon name={ICONS.KEYBOARD_ARROW_RIGHT} color={classNames("default-arrow", { open })} size={16} />
								}
								<label
									style={{
										marginRight: reverseIconPosition ? '8px' : 0,
									}}
									className={size}>{label}</label>
								{icon && <Icon name={ICONS.LITTLE_ARROW_DOWN} color={classNames("small-arrow", { open })} size={6} />}
							</span>
						</button>
						{headerRight && (<div className="collapsing-list-header-right">{headerRight}</div>)}
					</div>
				)}
				<ConditionalWrapper condition={!!conditionalContentsWrapper} wrapper={conditionalContentsWrapper || (children => children)}>
					<div
						className={
							classNames(
								"collapsing-list-contents read-only-exception",
								{
									"show-scrollbar": showScrollBar && hasOverflow,
									"show-transition-scroll-margin": showTransitionScrollMargin,
								}
							)
						}
						style={{
							maxHeight: !open ? 0 : 'fit-content',
							overflow: overflowVisible ? 'visible' : 'hidden',
							...collapsingListCustomStyles,
						}}
						ref={listContentsRef}
					>
						{/* do not put the display logic on the parent class or it won't animate, so we need to create a sub wrapper:  */}
						<div ref={listContentsRevealerRef} style={{ display: displayNone ? 'none' : 'block' }}>
							{/* "children" must be a <ul> with <li>'s in order to use default styling*/}
							{/* add the class "selected" to a list item for it to be darkened when selected */}
							{children}
						</div>
					</div>
				</ConditionalWrapper>
			</div>
		</>
	);
}
