import './full-screen-overlay.scss';

interface IFullScreenOverlayProps {
	backgroundColor?: React.CSSProperties['backgroundColor'];
}

const FullScreenOverlay: React.FC<React.PropsWithChildren<IFullScreenOverlayProps>> = ({
	children,
	backgroundColor = 'transparent',
}) => {
	return (
		<div
			className="full-screen-overlay"
			style={{
				backgroundColor,
			}}>
			{children}
		</div>
	);
};

export default FullScreenOverlay;
