import { Dispatch, SetStateAction } from "react";

import { CreateCommentBoxPrompt, CreateQuestionPrompt, CreateSurvey, SurveyQuestion, SurveyQuestionType } from "../../../../../../../types/working-model";

import { IFeedback } from "../create-feedback/create-feedback";
import { ErrorsTypes, EngagementErrorsTexts } from "../types/engage";

type ISetValidationErrors = (setValidationErrors: Dispatch<SetStateAction<{ [key: string]: string } | null>>) => (error: { [key: string]: string }) => void;

export const setErrorHandler: ISetValidationErrors = (setValidationErrors) => {
	return (error: { [key: string]: string }) => {
		setValidationErrors(prevErrors => ({
			...prevErrors,
			...error,
		}));
	};
};

type IClearValidationErrors = (setValidationErrors: Dispatch<SetStateAction<{ [key: string]: string } | null>>) => (key: ErrorsTypes | null) => void;

export const clearErrorsHandler: IClearValidationErrors = (setValidationErrors) => {
	return (key: ErrorsTypes | null) => {
		if (key) {
			setValidationErrors(prevErrors => {
				const newErrors = { ...prevErrors };
				delete newErrors[key];
				return newErrors;
			});
		} else {
			setValidationErrors(null);
		}
	};
};

interface IValidateQAndAParams {
	question: CreateQuestionPrompt;
	setError: (error: { [p: string]: string }) => void;
}

export const validateQAndA = ({ question, setError }: IValidateQAndAParams) => {
	const { title } = question;
	let errors: { [key: string]: string } = {};

	if (!title.base?.trim()) {
		errors = {
			...errors,
			[ErrorsTypes.QAndATitle]: EngagementErrorsTexts.QAndATitle
		};
	}

	setError(errors);
	return errors;
};

interface IValidateCommentBoxParams {
	commentBox: CreateCommentBoxPrompt;
	setError: (error: { [p: string]: string }) => void;
}

export const validateCommentBox = ({ commentBox, setError }: IValidateCommentBoxParams) => {
	const { title } = commentBox;
	let errors: { [key: string]: string } = {};

	if (!title.base?.trim()) {
		errors = {
			...errors,
			[ErrorsTypes.CommmentBoxTitle]: EngagementErrorsTexts.CommmentBoxTitle
		};
	}

	setError(errors);
	return errors;
};


interface IValidateQuestionParams {
	question: SurveyQuestion | null;
	setError: (error: { [p: string]: string }) => void;
	isQuiz: boolean;
}


export const validateQuestion = ({ question, setError, isQuiz }: IValidateQuestionParams) => {
	const { title } = question || {};
	let errors: { [key: string]: string } = {};

	const optionLabel = question?.options.map((option) => {
		return option.label.base?.trim() ?? '';
	});

	const correctOption = question?.options.find((option) => option.is_correct);

	if (question && !title?.base?.trim()) {
		errors = {
			...errors,
			[ErrorsTypes.QuestionTitle]: EngagementErrorsTexts.QuestionTitle
		};
	}

	if (question && question?.question_type !== SurveyQuestionType.text && !question?.options.length) {
		errors = {
			...errors,
			[ErrorsTypes.QuestionOptions]: EngagementErrorsTexts.QuestionOptions
		};
	}

	if (optionLabel?.length && optionLabel.includes("")) {
		errors = {
			...errors,
			[ErrorsTypes.QuestionOptionText]: EngagementErrorsTexts.QuestionOptionText
		};
	}

	if (question && isQuiz && !correctOption) {
		errors = {
			...errors,
			[ErrorsTypes.QuizCorrectOption]: EngagementErrorsTexts.QuizCorrectOption
		};
	}

	if (question) {
		errors = {
			...errors,
			[ErrorsTypes.NotSavedQuestion]: EngagementErrorsTexts.NotSavedQuestion
		};
	}

	setError(errors);
	return errors;
};

interface IValidateSurveyParams {
	survey: CreateSurvey | null;
	setError: (error: { [p: string]: string }) => void;
}

export const validateSurvey = ({ survey, setError }: IValidateSurveyParams) => {
	const { name, questions } = survey || {};
	let errors: { [key: string]: string } = {};

	if (!name?.base?.trim()) {
		errors = {
			...errors,
			[ErrorsTypes.SurveyTitle]: EngagementErrorsTexts.SurveyTitle
		};
	}

	if (!questions?.length) {
		errors = {
			...errors,
			[ErrorsTypes.SurveyWithoutQuestions]: EngagementErrorsTexts.SurveyWithoutQuestions
		};
	}

	setError(errors);
	return errors;
};

interface IValidateFeedbackParams {
	feedback: IFeedback;
	setError: (error: { [p: string]: string }) => void;
}

export const validateFeedback = ({ feedback, setError }: IValidateFeedbackParams) => {
	const { title } = feedback;
	let errors: { [key: string]: string } = {};

	if (!title.trim()) {
		errors = {
			...errors,
			[ErrorsTypes.FeedbackTitle]: EngagementErrorsTexts.FeedbackTitle
		};
	}

	setError(errors);
	return errors;
};
