import { TranslateFunction, useTranslate } from '../i18n/useTranslationModules';
import { intersection, isObject, uniq } from 'underscore';

import { SelectOption } from '../components/general-ui/select/select';
import { defaultModuleContentTextAlign, TemplateClassNames, TemplateNames, TextAlignmentsEnum } from '../types/template-layouts';
import {
	BLAdmin,
	BlProfile,
	Session,
	BlProfileSearchResult,
	BlProfileValues,
	Channel,
	ConnectedAdmin,
	Dictionary,
	LanguagesAbbr,
	RegFieldsEnum,
	TranslateString,
	IQuestion,
	ISocketCommentProps,
	BrandliveEvent,
	RegistrationStep,
	RegistrationStepType,
	SessionTypesEnum,
	SessionPreview,
	SessionTypesFeatures,
	PageModule,
	ExchangeResponse,
	PageModuleType,
	Template,
	Requirement,
	Templates,
	IModeratorProfileFields,
	CreateSession,
	Language,
	RegistrationQuestion,
} from '../types/working-model';
import { getStorageItem, setStorageItem, getStorageObject, setStorageObject } from './local-storage';
import { getFileContentType } from '../connection/uploads';
import { ICONS } from '../components/general-ui/icon';
import { PATHNAMES } from './admin-routing-utils';
import { HvHostMap } from '../connection/helpers';

export function toDict(key: string, arr: any[]): { [key: string]: any; } {
	const len = arr.length;
	const dict: { [key: string]: any[]; } = {};
	for (let i = 0; i < len; ++i) {
		dict[arr[i][key]] = arr[i];
	}
	return dict;
}

export function toDictTyped<T, KeyType extends keyof T>(key: KeyType, arr: T[]): Record<string | number, T> {
	const len = arr.length;
	const dict = {} as any;
	for (let i = 0; i < len; ++i) {
		dict[arr[i][key]] = arr[i];
	}

	return dict;
}

export function handleLoginErrorMessage(error: string): string {
	if (error === 'no password') {
		return 'It looks like you need to set a password for this account. Please click on “Forgot Password” to set one.';
	}

	if (error === 'no channels') {
		return 'You do not have admin access to any channels, please reach out to support@brandlive.com if you feel this is an error.';
	}

	if (error.includes('email_temporarily_locked')) {
		const lockedUntil = error.split(' ')[1] || new Date();
		const minutesUntilUnlock = Math.ceil((Number(lockedUntil) - Number(new Date())) / 1000 / 60);

		return `This email is temporarily locked for ${minutesUntilUnlock} minutes.`;
	}

	return `Looks like you've attempted to sign in with ${error}. Please follow the "Forgot Password" link to set a password for this account.`;
}

export function formatStringEllipses(titleString: string, characters: number): string {
	const ellipsesString = titleString.length > characters ? "..." : "";
	return titleString.substring(0, characters) + ellipsesString;
}

export function isValidEmail(email: string): boolean {
	//eslint-disable-next-line no-useless-escape
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function isValidSenderName(name: string): boolean {
	const regex = /[,.<>"]/g;
	return !regex.test(name); // returning opposite because it is valid if it does NOT contain the characters
}

export function isValidPrivateEmail(email: string): boolean {
	// not nearly a complete list, just the most commonly used...
	const publicDomains = ['gmail', 'yahoo', 'outlook', 'inbox', 'icloud', 'mail', 'hotmail', 'me'];
	const domain = email.split('@')[1]?.split('.')[0];
	return isValidEmail(email) && !publicDomains.includes(domain);
}

export function isValidPassword(password: string): boolean {
	const hasEightChars = () => {
		return password.length >= 8;
	};

	const hasOneLowercase = () => {
		return /[a-z]/.test(password);
	};

	const hasOneUpperCase = () => {
		return /[A-Z]/.test(password);
	};

	const hasOneNumber = () => {
		return /[0-9]/.test(password);
	};

	const hasOneSymbol = () => {
		return /[#?!@$ %^&*-]+/.test(password);
	};

	return (
		hasEightChars() &&
		hasOneLowercase() &&
		hasOneUpperCase() &&
		hasOneNumber() &&
		hasOneSymbol()
	);
}

export function extractFileType(fileName: string): string {
	const sections = fileName.split('.');
	return sections[sections.length - 1].toLowerCase();
}
export const basicSearch = (existing: string, input: string): boolean => {
	return existing.toLocaleLowerCase().includes(input.toLocaleLowerCase());
};

export function convertMinutesToHMS(minutes: number): string {
	type sn = string | number;
	let h: sn = Math.floor(minutes / 60);
	let m: sn = Math.floor(minutes - (h * 3600) / 60);
	let s: sn = Math.floor(minutes * 60 - h * 3600 - m * 60);

	if (h) {
		if (h < 10) { h = '0' + h; }
		h = h + ':';
	} else { h = ''; }

	if (m < 10) { m = '0' + m; }
	if (s < 10) { s = '0' + s; }

	return `${h}${m}:${s}`;
}

export function convertSecondsToHMS(duration: number, skipLeading = false): string {
	const hours = Math.floor(duration / 3600);
	const minutes = Math.floor(duration % 3600 / 60);
	const seconds = Math.floor(duration % 3600 % 60);

	const h = (hours < 10) ? "0" + hours : hours;
	const m = (minutes < 10) ? "0" + minutes : minutes;
	const s = (seconds < 10) ? "0" + seconds : seconds;

	if (skipLeading && hours === 0) {
		return `${m}:${s}`.replace(/^0/, '');
	}

	if (skipLeading) {
		return `${h}:${m}:${s}`.replace(/^0/, '');
	}
	return h + ":" + m + ":" + s;
}

export function convertMsToHMS(duration: number): string {
	// const milliseconds = Math.floor((duration % 1000) / 100);
	const seconds = Math.floor((duration / 1000) % 60);
	const minutes = Math.floor((duration / (1000 * 60)) % 60);
	const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

	const h = (hours < 10) ? "0" + hours : hours;
	const m = (minutes < 10) ? "0" + minutes : minutes;
	const s = (seconds < 10) ? "0" + seconds : seconds;

	return h + ":" + m + ":" + s;
}

export function getImageLocation(uri: null | string): string {
	if (!uri) { return ''; }
	let image = '';

	if (uri.includes('assets/')) {
		image = 'https://brand.live/' + uri;
	} else if (!uri.includes('http')) {
		image = 'https://cdn1.brnd.live/' + uri;
	} else if (uri.endsWith('.m3u8')) {
		image = uri.replace('/hls/master.m3u8', '/thumbnails/thumb0.jpg');
	} else {
		image = uri;
	}
	return getCloudfrontUrl(image);
}

export function validateUrl(value: string): boolean {
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		value
	);
}

export function bytesToString(bytes: number, toFixedNumber = 2): string {
	if (bytes > 1024 * 1024 * 1024) {
		return `${(bytes / 1024 / 1024 / 1024).toFixed(toFixedNumber)} GB`;
	}
	if (bytes > 1024 * 1024) {
		return `${(bytes / 1024 / 1024).toFixed(toFixedNumber)} MB`;
	}
	if (bytes > 1024) {
		return `${(bytes / 1024).toFixed(toFixedNumber)} KB`;
	}
	return `${bytes} B`;
}

export function dateCountdown(timestamp: string | number): string {
	const date = new Date(timestamp);
	const now = new Date();
	const msBetween = date.valueOf() - now.valueOf();
	const day = 1000 * 60 * 60 * 24;
	const hour = 1000 * 60 * 60;
	const minute = 1000 * 60;
	const second = 1000;
	// const t = (word: string) => word;
	const { t } = useTranslate("session");

	//if event is in future
	if (msBetween > 0) {
		if (msBetween > day) {
			//drop english plurals for "minute" "hour" "day"
			let desc = t("days", "");

			//use round to get to closest
			const amt = Math.round(msBetween / day);
			if (desc === "days" && amt === 1) {
				desc = "day";
			}

			return `${t("Live in", "")} ${amt} ${desc}`;
		}

		if (msBetween > hour) {
			let desc = t("hours", "");
			const amt = Math.round(msBetween / hour);
			if (desc === "hours" && amt === 1) {
				desc = "hour";
			}

			return `${t("Live in", "")} ${amt} ${desc}`;
		}

		if (msBetween > minute) {
			let desc = t("minutes", "");
			const amt = Math.round(msBetween / minute);
			if (desc === "minutes" && amt === 1) {
				desc = "minute";
			}

			return `${t("Live in", "")} ${amt} ${desc}`;
		}

		return `${t("Live in", "")} ${Math.round(msBetween / second)} ${t("seconds", "")}`;
	} else {
		if (msBetween < -day) {
			return `${t("Live", "")} ${Math.round(msBetween / -day)} ${t("days ago", "")}`;
		}

		if (msBetween < -hour) {
			return `${t("Live", "")} ${Math.round(msBetween / -hour)} ${t("hours ago", "hours ago")}`;
		}

		if (msBetween < -minute) {
			return `${t("Live", "")} ${Math.round(msBetween / -minute)} ${t("minutes ago", "")}`;
		}

		return `${t("Live", "")} ${Math.round(msBetween / -second)} ${t("seconds ago", "")}`;
	}
	return date.toLocaleString();
}

interface updateTranslateKeyProps {
	translateString: TranslateString | string,
	input: string,
	baseLanguage: string,
	language: string | null,
	doNotChange?: boolean,
}
export function updateTranslateKey({
	translateString,
	input,
	baseLanguage,
	language,
	doNotChange,
}: updateTranslateKeyProps): TranslateString {
	if (!translateString) {
		translateString = { base: '', changed: '' };
	}

	if (typeof translateString === 'string') {
		translateString = { base: translateString, changed: '' };
	}

	if (language && language !== baseLanguage) {
		const updated = (translateString.overrides ?? []) as string[];

		return {
			...translateString,
			[language]: input,
			overrides: Array.from(new Set([...updated, language]).values()),
		};
	} else if (language) {
		return {
			...translateString,
			[language]: input,
			base: input,
			changed: doNotChange ? '' : 'true',
		};
	} else {
		return {
			...translateString,
			base: input,
			changed: 'true',
		};
	}
}
export function capitalize(str: string, capitalizeHyphenated?: boolean): string {
	let _str = str;
	if (capitalizeHyphenated && str.includes('-')) {
		_str = str.split('-').map((word) => {
			const first = word[0];
			const rest = word.slice(1);
			return first?.toLocaleUpperCase() + rest.toLocaleLowerCase();
		})
			.join('-');
	}

	return _str
		.split(' ')
		.map((word) => {
			const first = word[0];
			const rest = word.slice(1);
			return first?.toLocaleUpperCase() + (capitalizeHyphenated ? rest : rest?.toLocaleLowerCase());
		})
		.join(' ');
}

export function timeSince(date: Date, t: TranslateFunction, verbiage?: 'short' | 'long'): string {
	let timestamp: number = new Date(date).getTime();

	const dated = new Date(date);
	const dateUTC = Date.UTC(
		dated.getUTCFullYear(),
		dated.getUTCMonth(),
		dated.getUTCDate(),
		dated.getUTCHours(),
		dated.getUTCMinutes(),
		dated.getUTCSeconds()
	);
	timestamp = dateUTC;

	const seconds = Math.floor((Date.now() - timestamp) / 1000);
	let interval = seconds / 31536000;

	function formatString(val: number, string: string) {
		if (Math.floor(val) > 1) return string;
		let str = string;
		if (str.length > 1) {
			str = str.slice(0, -1);
		}
		return str;
	}

	const yearsText = verbiage === 'short' ? t('session:y', t('y', 'y')) : t('session:years', t('years', 'years'));
	const monthsText = verbiage === 'short' ? t('session:Months', t('Months', 'Months')) : t('session:months', t('months', 'months'));
	const daysText = verbiage === 'short' ? t('session:d', t('d', 'd')) : t('session:days', t('days', 'days'));
	const hoursText = verbiage === 'short' ? t('session:h', t('h', 'h')) : t('session:hours', t('hours', 'hours'));
	const minutesText = verbiage === 'short' ? t('session:m', t('m', 'm')) : t('session:minutes', t('minutes', 'minutes'));
	const secondsText = verbiage === 'short' ? t('session:s', t('s', 's')) : t('session:seconds', t('seconds', 'seconds'));

	if (interval > 1) {
		return Math.floor(interval) + formatString(interval, `${yearsText}`) + ' ' + t('session:ago', t('ago', 'ago'));
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return (
			Math.floor(interval) + formatString(interval, ` ${monthsText}`) + ' ' + t('session:ago', t('ago', 'ago')) // Adding space before monthsText to avoid "3months ago"
		);
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + formatString(interval, `${daysText}`) + ' ' + t('session:ago', t('ago', 'ago'));
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + formatString(interval, `${hoursText}`) + ' ' + t('session:ago', t('ago', 'ago'));
	}
	interval = seconds / 60;
	if (interval > 1) {
		return (
			Math.floor(interval) + formatString(interval, `${minutesText}`) + ' ' + t('session:ago', t('ago', 'ago'))
		);
	}
	if (seconds === 0) { return t('session:Just now', t('Just now', 'Just now')); }
	return Math.floor(seconds) + formatString(seconds, `${secondsText}`) + ' ' + t('session:ago', t('ago', 'ago'));
}

export const getVidDuration = (file: File): Promise<number> => new Promise<number>((resolve, reject) => {
	try {
		// tested with large multi-GB video, resolves in under a second, just giving a very large buffer 
		// in case file is corrupted. If this does not time out and the file is not playable, promise will never resolve
		// and the upload will stall forever without any error
		const timeout = setTimeout(() => {
			reject('Unable to get thumbnail in reasonable amount of time');
		}, 10000);
		const video = document.createElement('video');
		video.preload = 'metadata';
		video.onloadedmetadata = () => {
			const duration = video.duration || 0;
			window.URL.revokeObjectURL(video.src);
			resolve(Math.trunc(duration));
			clearTimeout(timeout);
		};
		video.src = window.URL.createObjectURL(file);
	} catch (e) {
		reject(e);
	}
});

export const EXAMPLE_IMAGE_1 =
	'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtQtyjWJeJwd5fSvmKVFX0i8t6B0tVpm5MKw&usqp=CAU';
export const EXAMPLE_IMAGE_2 =
	'https://www.kids-world-travel-guide.com/images/xkoala.jpg.pagespeed.ic.fg-HUBT_tb.jpg';
export const EXAMPLE_IMAGE_3 =
	'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBPdFHPaGebRk1P-UaSRoamb_qhx-ajvqIKA&usqp=CAU';
export const EXAMPLE_IMAGE_4 =
	'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvkC1NkcRwsoi2VzVr7ba0zfD8LSnkEk1zjA&usqp=CAU';

export function copyStringToClipboard(str: string): Promise<void> {
	return navigator.clipboard.writeText(str);
}

export const parseUrl = (url: string): string => {
	if (url.startsWith('http')) { return url; }
	else { return 'https://' + url; }
};

export const parseUri = (uri: string): string => {
	const isURI = (str: string) => {
		try {
			new URL(str);
			return true;
		} catch {
			return false;
		}
	};


	if (isURI(uri)) {
		return uri;
	} else {
		return 'https://' + uri;
	}
};

export function replaceSpaceWithUnderscore(string: string): string {
	return string.replace(/\s+/g, '_');
}

export function replaceSpaceWithDash(string: string): string {
	return string.replace(/\s+/g, '-');
}

export function getTextValue(content: any, language: LanguagesAbbr): { custom_heading: string, title: string, description: string; } {
	// if changed === 'true' return base, otherwise do the normal thing below
	function getText(key: string) {
		const changed = content?.[key]?.changed === 'true';
		if (changed) return content?.[key]?.base;
		return content?.[key]?.[language] ?? content?.[key]?.base;
	}

	return {
		custom_heading: getText('custom_heading'),
		title: getText('title'),
		description: getText('description'),
	};
}

export function getNavigatorLanguage(): string | null {
	if (window.navigator.language) {
		return window.navigator.language.substr(0, 2);
	}
	return null;
}

function getPosition(string: string, subString: string, index: number): number {
	return string.split(subString, index).join(subString).length;
}

export function niceDate(timestamp: number): string {
	return new Date(timestamp).toLocaleDateString(navigator.language, { month: 'long', day: '2-digit', year: 'numeric' });
}

export function niceDateCalendar(date: number | string, language?: LanguagesAbbr, weekday?: 'narrow' | 'short' | 'long'): string {
	return new Date(date).toLocaleDateString(language || navigator.language, { weekday: weekday || 'long', month: 'long', day: '2-digit' });
}

export function niceTime(timestamp: number): string {
	return new Date(timestamp).toLocaleTimeString(navigator.language, { hour: 'numeric', minute: '2-digit' });
}

export function niceTimeCalendar(timestamp: number, language?: LanguagesAbbr): string {
	return new Date(timestamp).toLocaleTimeString(language || navigator.language, { hour: 'numeric', minute: '2-digit' });
}

export function niceDateTime(timestamp: number, langCode?: string): string {
	const date = new Date(timestamp);
	const lang = langCode || navigator.language;

	const weekday = date.toLocaleString(lang, { weekday: 'short' });
	const month = date.toLocaleString(lang, { month: 'long' });
	const day = date.toLocaleString(lang, { day: '2-digit' });
	const time = date.toLocaleString(lang, { hour: 'numeric', minute: '2-digit', timeZoneName: "short" });

	return `${weekday}, ${month} ${day} \u00B7 ${time}`;
}

export function niceSessionDateTime(timestamp: number, endTimestamp: number, separator: string, langCode?: string): string {
	const startDate = new Date(timestamp);
	const endDate = new Date(endTimestamp);
	const lang = langCode || navigator.language;

	const day = startDate.toLocaleString(lang, { day: '2-digit' });
	const weekday = startDate.toLocaleString(lang, { weekday: 'long' });
	const month = startDate.toLocaleString(lang, { month: 'short' });
	const year = startDate.toLocaleString(lang, { year: 'numeric' });
	const startTime = startDate.toLocaleString(lang, { hour: 'numeric', minute: '2-digit' });
	const endTime = endDate.toLocaleString(lang, { hour: 'numeric', minute: '2-digit' });
	const timezone = endDate.toLocaleString(lang, { hour: 'numeric', minute: '2-digit', timeZoneName: "short" }).split(' ')[2];

	return `${weekday}, ${month} ${day}, ${year} \u00B7 ${startTime} ${separator} ${endTime} (${timezone})`;
}

type TNiceDateTimeWithoutTimeZone = (params: {
	timestamp: number;
	language?: string;
	short?: boolean;
	dateOnly?: boolean;
	timeOnly?: boolean;
	useDot?: boolean;
	oneDigit?: boolean

}) => string;

export const niceDateTimeWithoutTimeZone: TNiceDateTimeWithoutTimeZone = ({
	timestamp,
	language,
	short,
	dateOnly,
	timeOnly,
	useDot,
	oneDigit,
}) => {
	const date = new Date(timestamp);
	const lang = language || navigator.language;

	const digits = oneDigit ? 'numeric' : '2-digit';
	const weekday = date.toLocaleString(lang, { weekday: 'short' });
	const month = date.toLocaleString(lang, { month: short ? 'short' : 'long' });
	const day = date.toLocaleString(lang, { day: digits });
	const time = date.toLocaleString(lang, { hour: 'numeric', minute: digits });

	const separator = useDot ? `  ·  ` : ', ';

	if (dateOnly && short) return `${month} ${day}`;
	if (dateOnly) return `${weekday}, ${month} ${day}`;
	if (timeOnly) return `${time}`;

	return !short ? `${weekday}, ${month} ${day}${separator} ${time}` : `${month} ${day}${separator}${time}`;
};

export function niceDateTimeRange(startTimestamp: number, endTimestamp?: number, langCode?: LanguagesAbbr): string {
	const startDay = new Date(startTimestamp).toLocaleString(langCode || navigator.language, { month: 'long', day: '2-digit' });
	const start = new Date(startTimestamp).toLocaleString(langCode || navigator.language, { hour: 'numeric', minute: '2-digit' });

	if (!endTimestamp) {
		return `${startDay}, ${start}`;
	}

	const endDay = new Date(endTimestamp).toLocaleString(langCode || navigator.language, { month: 'long', day: '2-digit' });
	const end = new Date(endTimestamp).toLocaleTimeString(langCode || navigator.language, { hour: 'numeric', minute: '2-digit', timeZoneName: "short" });

	if (endDay && endDay !== startDay) {
		return `${startDay}, ${start} - ${endDay}, ${end}`;
	}
	return `${startDay}, ${start} - ${end}`;
}

export function niceDateTimeTz(timestamp: number, langCode?: string, monthLen: 'long' | 'short' = 'long', showYear = true): string {
	//if this is the US - replace the second comma generated by the locale date string with the word "at". We don't know what is the expected behavior outside the US - The local date string does not have a second comma, so removed the getPosition function in the original code to get rid of the extra "at" in the time string.
	if (navigator.language === 'en-US' && langCode && langCode !== 'en') {
		// if the user is in the US, but chooses a different default language, then override
		return new Date(timestamp).toLocaleString(langCode, { month: monthLen, day: '2-digit', year: showYear ? 'numeric' : undefined, hour: 'numeric', minute: '2-digit', timeZoneName: "short" });
	}
	else {
		return new Date(timestamp).toLocaleString(navigator.language, { month: monthLen, day: '2-digit', year: showYear ? 'numeric' : undefined, hour: 'numeric', minute: '2-digit', timeZoneName: "short" });
	}
}

export function niceTwoDigitTime(timestamp: number): string {
	return new Date(timestamp).toLocaleTimeString(navigator.language, {
		hour: '2-digit',
		minute: '2-digit'
	});
}

export function niceDateTimeTzEmail(timestamp: number, language: string, timezone: string): string {
	return new Date(timestamp).toLocaleString(language, {
		month: 'long',
		day: '2-digit',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		timeZone: timezone,
		timeZoneName: "short",
	});
}

// Returns given number correctly formatted to the given language
export function numberWithCommas(x: number | string, language = navigator.language): string {
	if (typeof x === 'string' && !isNaN(Number(x))) {
		return Number(x).toLocaleString(language);
	}
	return x.toLocaleString(language);
}

export function toMap<Type>(key: keyof Type, arr: Type[]): Map<Type[keyof Type], Type> {
	return new Map(arr.map(item => [item[key], item]));
}

export function toArrayMap<Type>(key: keyof Type, arr: Type[]): Map<Type[keyof Type], Type[]> {
	const map = new Map();
	const len = arr.length;
	for (let i = 0; i < len; ++i) {
		if (map.has(arr[i][key])) {
			map.get(arr[i][key]).push(arr[i]);
		} else {
			map.set(arr[i][key], [arr[i]]);
		}
	}
	return map;
}

export function getPrefixUrl(channelUrl?: string, isPreview?: boolean): string {
	if (!channelUrl) return '';
	//If the event url is not a custom url, it will be something.brand.live.
	if (channelUrl.includes('brand.live')) {
		//If we are in a live environment (non local), it will have an env variable of the form https://brandlive-someenvironment.com
		if (process.env.REACT_APP_BASE_URL) {
			if (isPreview) {
				return process.env.REACT_APP_BASE_URL.replace('https://', 'https://admin.');
			}

			return process.env.REACT_APP_BASE_URL.replace('https://', 'https://' + channelUrl.split('.')[0] + '.');
		} else {
			if (isPreview) {
				return 'https://admin.brandlive-dev.com';
			}

			//If we are in local environment, use brandlive-dev.com.
			return 'https://' + channelUrl.split('.')[0] + '.brandlive-dev.com';
		}
	} else {
		//If custom url, use whole url.
		return 'https://' + channelUrl;
	}
}

export function getQueryParams(searchParams: string): Dictionary {
	const paramsMap: Dictionary = {};
	try {
		if (!searchParams?.length) return {};
		let decodedParams = decodeURIComponent(searchParams);
		if (searchParams.charAt(0) === '?') {
			decodedParams = decodeURIComponent(searchParams.slice(1));
		}
		decodedParams.split('&').forEach((item: string) => {
			const key = item.substr(0, item.indexOf('='));
			const val = item.substr(item.indexOf('=') + 1);
			paramsMap[key] = val;
		});
	} catch (e) {
		console.error(e);
	}
	return paramsMap;
}

export function createQueryString(paramsMap: Dictionary): string {
	let queryString = '';
	try {
		Object.entries(paramsMap).forEach(data => {
			const key = encodeURIComponent(data[0]);
			const val = encodeURIComponent(data[1]);
			if (val?.trim()?.length) {
				if (queryString?.length) {
					queryString += '&';
				}
				queryString += `${key}=${val}`;
			}
		});
	} catch (e) {
		console.error(e);
	}
	return queryString;
}

/**
 * Returns an object with its keys lowercased
 */
export function lowercaseObjectKeys(obj: Record<string, any>): Record<string, any> {
	if (!obj) {
		return obj;
	}

	const keys = Object.keys(obj);

	if (keys.length === 0) {
		return obj;
	}

	return keys.reduce((acc, key) => {
		acc[key.toLowerCase()] = obj[key];
		return acc;
	}, {} as Record<string, any>);
}

export function userHasRoleOnActiveChannel(roles: string[], user?: BLAdmin | null): boolean {
	const channelRoles = user?.channels[user?.active_channel] || [];
	return intersection(roles, channelRoles).length != 0;
}

export function binarySearchNumbers(arr: any[], target: number): null | number {
	const midpoint = Math.floor(arr.length / 2);

	if (arr[midpoint][0] === target) {
		return arr[midpoint];
	}
	if (arr.length === 1) {
		return arr[0];
	}

	if (arr[midpoint] > target) {
		return binarySearchNumbers(arr.slice(0, midpoint), target);
	} else if (arr[midpoint] < target) {
		return binarySearchNumbers(arr.slice(midpoint), target);
	}
	return null;
}

export function isDevEnvironment(): boolean {
	return process.env.NODE_ENV === 'development';
}

/**
 * Stores all query params with their keys lowercased
 * in localstorage.
 */
export function storeAllQueryParams(search: string, eventUuid?: string): void {
	const newQueryParams = lowercaseObjectKeys(getQueryParams(search));
	if (eventUuid) {
		if (Object.keys(newQueryParams).length) {
			const existingParams = getAllStoredQueryParams(eventUuid);
			if (existingParams) {
				setStorageObject(`queryParams.${eventUuid}`, { ...existingParams, ...newQueryParams });
			} else {
				setStorageObject(`queryParams.${eventUuid}`, newQueryParams);
			}
		}
	}
}

export function getAllStoredQueryParams(eventUuid: string): Record<string, string> {
	return getStorageObject(`queryParams.${eventUuid}`) ?? {};
}

export function storeReferrer(eventUuid?: string): void {
	const referrer = document.referrer;
	if (referrer && eventUuid) {
		setStorageItem(`referrer.${eventUuid}`, referrer);
	}
}

export function getStoredReferrer(eventUuid?: string) {
	return eventUuid ? getStorageItem(`referrer.${eventUuid}`) : null;
}

export const sleep = (time = 300): Promise<NodeJS.Timeout> => new Promise(resolve => setTimeout(resolve, time));

export function userToConnectedUser(user: BLAdmin, currentEvent: string | null): ConnectedAdmin {
	//create deep copy
	const _user = JSON.parse(JSON.stringify(user));

	return {
		brandlive_profile: _user.id,
		profile: _user.profile,
		id: _user.id,
		email: _user.email,
		current_event: currentEvent
	};
}

export const getInitials = (user: ConnectedAdmin): string => {
	if (user.profile.first_name && user.profile.last_name) {
		return (user.profile.first_name[0] ?? '') + (user.profile.last_name[0] ?? '');
	}
	else if (user.profile.first_name) {
		return (user.profile.first_name[0] ?? '');
	}
	else if (user.profile.last_name) {
		return (user.profile.last_name[0] ?? '');
	}
	else if (user.email) {
		return user.email[0];
	}
	else if (typeof user === 'string') {
		return user[0];
	}
	else {
		return '';
	}
};

export const getChannelInitials = (name: Channel["name"]): string => {
	const nameParts = name.split(' ');

	return `${nameParts[0]?.charAt(0) ?? ''}${nameParts[1]?.charAt(0) ?? ''}`;
};

export const getTime = (): string => {
	return formatTime(new Date());
};

export const formatTime = (dateToFormat: Date | string | number): string => {
	if (!(dateToFormat instanceof Date)) {
		dateToFormat = new Date(dateToFormat);
	}

	return new Date(dateToFormat).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
};

type sizeParams = "height" | "width" | "fit" | "quality";
export type ResizeParams = { height?: number, width?: number, fit?: 'cover' | 'contain', quality?: number; };
export const resizedImage = (url: string, size?: ResizeParams): string => {
	// do not resize svgs
	if (url.endsWith('.svg')) return getCloudfrontUrl(url);

	const params = new URLSearchParams();
	if (size) {
		for (const key in size) {
			if (size[key as sizeParams]) {
				params.set(key, String(size[key as sizeParams]));
			}
		}
	}

	const hostname = process.env.REACT_APP_BASE_URL?.includes('.io') ? 'brandlive.io' : 'brandlive.com';
	url = url.replace(/https:\/\/brandlive-upload.s3-us-west-2.amazonaws.com|https:\/\/assets.brandlive.com/, `https://image-resizer.${hostname}/e3-image`); // Original Assets in Util
	url = url.replace(/https:\/\/uploads.brandlive(-.*)?.(com|io)/, HvHostMap.imageResizer + "/image-resizer"); // New Uploads

	try {
		const urlObject = new URL(url);
		params.forEach((value, key) => {
			urlObject.searchParams.set(key, value);
		});

		return urlObject.toString();
	} catch (e) {
		return url + `?${params.toString()}`;
	}
};

export const getAvatarFromBlProfile = (profile: BlProfile, channel: number, eventUuid?: string): string => {
	try {
		const moderatorProfile = eventUuid && profile?.moderator_profile?.[channel]?.[eventUuid];
		if (moderatorProfile) {
			return moderatorProfile.avatar || '';
		}
		return profile?.profile?.[channel]?.avatar || '';
	}
	catch {
		return '';
	}
};

export const getNameFromBlProfile = (user: BlProfile, channel: number, eventUuid?: string): string => {
	if (eventUuid && user.moderator_profile?.[channel]?.[eventUuid]) {
		const profile = user.moderator_profile?.[channel]?.[eventUuid];
		const name = `${profile?.first_name || ''} ${profile?.last_name || ''}`.trim();
		if (name) {
			return name;
		}
	}
	if (user.profile?.[channel]?.[RegFieldsEnum.first_name] && user.profile?.[channel]?.[RegFieldsEnum.last_name]) {
		return (user.profile[channel][RegFieldsEnum.first_name] ?? '') + ' ' + (user.profile[channel][RegFieldsEnum.last_name] ?? '');
	}
	else if (user.profile?.[channel]?.[RegFieldsEnum.first_name]) {
		return (user.profile[channel][RegFieldsEnum.first_name] ?? '');
	}
	else if (user.profile?.[channel]?.[RegFieldsEnum.last_name]) {
		return (user.profile[channel][RegFieldsEnum.last_name] ?? '');
	}
	else if (user.profile?.[channel]?.[RegFieldsEnum.email]) {
		return user.profile[channel][RegFieldsEnum.email];
	}
	else {
		return '';
	}
};

export const getCompanyFromBlProfile = (profile: BlProfile, channel: number): string => {
	try {
		return profile?.profile?.[channel]?.[BlProfileValues.companyName];
	}
	catch {
		return '';
	}
};

export const getInitialsFromBlProfile = (user: BlProfile | IQuestion | ISocketCommentProps, channel: number): string => {
	try {
		if (user.profile?.[channel]?.[RegFieldsEnum.first_name] && user.profile?.[channel]?.[RegFieldsEnum.last_name]) {
			return (user.profile[channel][RegFieldsEnum.first_name][0] ?? '') + (user.profile[channel][RegFieldsEnum.last_name][0] ?? '');
		}
		else if (user.profile?.[channel]?.[RegFieldsEnum.first_name]) {
			return (user.profile[channel][RegFieldsEnum.first_name][0] ?? '');
		}
		else if (user.profile?.[channel]?.[RegFieldsEnum.last_name]) {
			return (user.profile[channel][RegFieldsEnum.last_name][0] ?? '');
		}
		else if (user.profile?.[channel]?.[RegFieldsEnum.email]) {
			return user.profile[channel][RegFieldsEnum.email][0];
		}
		else {
			return '';
		}
	}
	catch {
		return '';
	}
};

export const getCompanyAndJob = (blProfile: BlProfile, channel: number): string => {
	const profile = blProfile.profile?.[channel];
	if (!profile) return '';
	// including position here for backwards compatibility.
	const jobTitle = profile?.[RegFieldsEnum.job_title] || profile?.[BlProfileValues.position] || '';
	const companyName = profile?.[RegFieldsEnum.company_name] || '';
	const separator = jobTitle && companyName ? ' - ' : '';
	return `${companyName}${separator}${jobTitle}`;
};

export function arrayToOptions(options: string[]): SelectOption[] {

	if (Array.isArray(options)) { // Safety check
		return options.map((option: string) => ({ label: option, value: option }));
	}

	return [];
}

export function shouldDisableRecord(session: Session | null): boolean {
	if (session && session.timestamp && session.end_timestamp) {
		const now = new Date();
		const startTime = new Date(session.timestamp);
		const endTime = new Date(session.end_timestamp);

		const timeSinceStart = now.getTime() - startTime.getTime();
		const timeSinceEnded = now.getTime() - endTime.getTime();

		const timeSinceStartInMins = Math.floor(timeSinceStart / 1000 / 60);
		const timeSinceEndedInMins = Math.floor(timeSinceEnded / 1000 / 60);

		if (timeSinceStartInMins >= -15 && timeSinceEndedInMins <= 15) {
			return true;
		}
	}
	return false;
}
// returns true if string arrays contains exactly the same values
export const compareArrays = (arr1: (string | null)[], arr2: (string | null)[]): boolean => {
	// defined lengths of both array so we don't have to get length on each loop below
	const arr1Length = arr1.length;
	const arr2Length = arr2.length;

	// arrays have different lengths, can't have identical elements
	if (arr1Length !== arr2Length) {
		return false;
	}

	const arr1Sorted = arr1.sort();
	const arr2Sorted = arr2.sort();
	for (let i = 0; i < arr1Length; i++) {
		if (arr1Sorted[i] !== arr2Sorted[i]) {
			return false;
		}
	}
	return true;
};

export const getInitialsFromProfile = (profile: BlProfileSearchResult | Record<string | number, string>): string => {
	const first = profile?.[RegFieldsEnum.first_name];
	const last = profile?.[RegFieldsEnum.last_name];
	let initials = '';

	if ((typeof first === 'string')) {
		initials += first.charAt(0);
	}

	if ((typeof last === 'string')) {
		initials += last.charAt(0);
	}

	return initials;
};

export const getDevicePermissions = async (): Promise<boolean> => {
	const isFullySupportedBrowser = 'setSinkId' in HTMLMediaElement.prototype;

	try {
		// prompt user permissions
		// chromium browsers
		if (isFullySupportedBrowser) {
			await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

			// handle user permission actions
			const cameraAllowed = (await navigator.permissions?.query({ name: 'camera' as PermissionName }))?.state;
			const microphoneAllowed = (await navigator.permissions?.query({ name: 'microphone' as PermissionName }))?.state;

			if (cameraAllowed === 'denied' || microphoneAllowed === 'denied') {
				throw new Error();
			}

			return true;
		}
		// other browsers
		else {
			let tempStream: MediaStream | null = await navigator.mediaDevices.getUserMedia({
				video: {
					width: { ideal: 1280 },
					height: { ideal: 720 }
				},
				audio: true
			});

			if (tempStream) {
				const tracks = tempStream.getTracks();

				tracks.forEach((track: MediaStreamTrack | null) => {
					if (track) track.stop();
					track = null;
				});

				tempStream = null;

				return true;
			} else {
				throw new Error("Unable to get camera stream for user");
			}
		}
	} catch (e) {
		console.error(e);

		return false;
	}
};

type MediaDeviceResponse = [MediaDeviceInfo[], MediaDeviceInfo[], MediaDeviceInfo[]];
export const getMediaDeviceTypes = (devices: MediaDeviceInfo[]): MediaDeviceResponse => {
	const cameraDevices = devices.filter(device => device.kind === 'videoinput');
	const microphoneDevices = devices.filter(device => device.kind === 'audioinput');
	const speakerDevices = devices.filter(device => device.kind === 'audiooutput');
	return [cameraDevices, microphoneDevices, speakerDevices];
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function jwtDecode<T>(token: string): BLAdmin | BlProfile | T {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}


export function stringToArrayBuffer(str: string): ArrayBuffer {
	const buf = new ArrayBuffer(str.length);
	const bufView = new Uint8Array(buf);
	for (let i = 0, strLen = str.length; i < strLen; i++) {
		bufView[i] = str.charCodeAt(i);
	}
	return buf;
}

export function shouldDisplayLandingPage(event: BrandliveEvent | null): boolean {
	if (!event?.homepage) return false;
	return event?.settings?.display_landing_page ?? true;
}

export function shouldDisplayHomepage(event: BrandliveEvent | null): boolean {
	if (!event?.homepage) return false;
	return event?.settings?.display_homepage ?? true;
}

export function sortSessionsByTime(a: Session, b: Session): number {
	if (a.session_type === SessionTypesEnum.onDemand) {
		return 1;
	}

	return Math.abs((a.timestamp || 0) - (b.timestamp || 0));
}

export function getLiveSessionBackground(session: Session): string | null {

	// first the thumbnail associated with the session
	// else use the pre_broadcast_thumbnail
	// but if its breakout, use the associate banner image

	if (session?.image) return session.image;
	if (session.session_type === SessionTypesEnum.breakoutRooms && session?.breakout_session?.banner_image) {
		return session.breakout_session.banner_image;
	}
	if (session?.pre_broadcast_thumbnail) return session.pre_broadcast_thumbnail;

	return null;
}

export function getMockBlProfile(user: BLAdmin): BlProfile {
	return {
		bl_profile: 0,
		uuid: '',
		first_name: '',
		last_name: '',
		email: '',
		verified: true,
		profile: {
			[user.active_channel]: {
				[RegFieldsEnum.first_name]: user.profile?.first_name ?? '',
				[RegFieldsEnum.last_name]: user.profile?.last_name ?? '',
			}
		},
		created_date: '',
		modified_date: '',
		saml_id: '',
		bookmarked_profiles: {}
	};
}

export function displayAddToCalendarButton(session: Session | SessionPreview, calBtnSetting = true): boolean {
	const scheduledSession = SessionTypesFeatures[session.session_type].has_start_time;
	if (!scheduledSession) return false;

	const futureSession = session.timestamp && session.timestamp > Date.now();
	if (!futureSession) return false;

	return calBtnSetting;
}

export function sessionsFromVodOrder<SessionType extends Session | SessionPreview>(sessions: SessionType[], vodOrder?: number[] | null): SessionType[] {
	//check of vod order exists
	if (Array.isArray(vodOrder)) {
		//create Map of the sessions to prevent double loop
		const sessionMap = toMap<SessionType>('session', sessions);
		let _sessions: SessionType[] = [];

		//iterate over vodOrder, get sessions from order
		vodOrder.forEach(sessionId => {
			const found = sessionMap.get(sessionId as unknown as SessionType[keyof SessionType]);
			if (found) {
				_sessions.push(found);

				//remove from Map
				sessionMap.delete(sessionId as unknown as SessionType[keyof SessionType]);
			}
		});

		//we have remaining sessions, need to show those too, may have been added after vodOrder was set
		if (sessionMap.size > 0) {
			_sessions = [..._sessions, ...(Array.from(sessionMap.values()))];
		}

		return _sessions;
	} else {
		return sessions;
	}
}

export function getDefaultModuleAlignment(pageModule: PageModule, template: string): TextAlignmentsEnum {
	return defaultModuleContentTextAlign?.[template]?.[pageModule.type]?.[pageModule.content?.layout_type ?? 'standard']?.description || TextAlignmentsEnum.left;
}

export function isiOSMobile(): boolean {
	if (navigator.platform) {
		return [
			'iPhone Simulator',
			'iPod Simulator',
			'iPhone',
			'iPod'
		].includes(navigator.platform);
	} else {
		return /iPhone|iPod/g.test(navigator.userAgent);
	}
}

declare global {
	interface Navigator {
		userAgentData?: {
			mobile?: boolean;
			platform?: string;
		}
	}
}

// this is marked as experimental in MDN but has been in Android Chrome for over a year
// this should be a good enough method to use to check if the user is on Android
export function isAndroidMobile(): boolean {
	return !!(navigator.userAgentData?.mobile && navigator.userAgentData?.platform === 'Android');
}

export function isIpad(): boolean {
	return /iPad/g.test(navigator.userAgent);
}

export function openNativeIOSPlayer(videoElement: HTMLVideoElement, currentlyPlaying: boolean): void {
	// The `playsinline` attributes cant be on the element to be able to use iOS native player.
	// So we have to remove them, toggle play / pause (which triggers the full screen to open), and then put them back on so it can play in line again.
	const attributesToToggle = ['webkit-playsinline', 'x5-playsinline', 'playsinline'];
	attributesToToggle.forEach(attr => {
		videoElement?.removeAttribute(attr);
	});

	videoElement.setAttribute('x-webkit-airplay', 'allow');

	if (currentlyPlaying) {
		videoElement.pause();
		videoElement.play();
	} else {
		videoElement.play()
			.then(() => {
				videoElement.pause();
			})
			.catch(e => console.error(e));
	}

	// we remove these once the player has opened so after the player has closed they can play it in line again.
	attributesToToggle.forEach(attr => {
		videoElement?.setAttribute(attr, '');
	});

	// if native player tries to add controls to video element, remove them.
	videoElement.removeAttribute('controls');
}

export const getDisplayPrice = (price?: number, currency?: string, multiplier?: number): string | undefined => {
	if (!price || !currency) return undefined;

	// prices are entered as integers so we show digits after decimal point based on currency decimal base
	// 3 is fallback because multiplier for USD is 100 which has 3 digits
	const afterDecimalPoint = (multiplier?.toString().length ?? 3) - 1;

	return price.toLocaleString(undefined, { minimumFractionDigits: afterDecimalPoint, style: 'currency', currency: currency });
};

/**
 * Sorts a list of RegistrationStep so that ticketing is last
 * If the list is undefined or less than one return early.
 */
export const sortRegistrationSteps = (registrationSteps?: Array<RegistrationStep>): Array<RegistrationStep> | undefined => {
	if (!registrationSteps || registrationSteps.length <= 1) {
		return registrationSteps;
	}
	// Deep copy to prevent mutation
	const registrationStepsCopy = JSON.parse(JSON.stringify(registrationSteps));

	// move ticketing to the end of the list if it exists
	registrationStepsCopy.push(...registrationStepsCopy.splice(registrationStepsCopy.findIndex((r: RegistrationStep) => r.type === RegistrationStepType.ticketing), 1));

	return registrationStepsCopy;
};

/**
 * Returns the list of registration questions from the registration steps
 */
export const getRegistrationQuestionsFromRegistrationSteps = (registrationSteps?: Array<RegistrationStep>): Array<RegistrationQuestion> => {
	if (!registrationSteps) return [];

	const registrationQuestions = registrationSteps?.reduce((questions: RegistrationQuestion[], step: RegistrationStep): RegistrationQuestion[] => {
		if (step?.questions) {
			questions.push(...step.questions);
		}
		return questions;
	}, []) ?? [];

	return registrationQuestions;
};

/**
 * Converts basePrice of baseCurrency into targetCurrency
 */
export const convertCurrency = (exchangeRates: ExchangeResponse, basePrice: number, baseCurrency: string, targetCurrency: string): number => {
	let convertedToTarget = basePrice / (exchangeRates.conversion_rates?.[baseCurrency]);

	if (exchangeRates?.base !== targetCurrency) {
		convertedToTarget = convertedToTarget * (exchangeRates.conversion_rates?.[targetCurrency]);
	}
	return convertedToTarget;
};
function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function SnakeCaseToWords(str: string): string {
	if (!str) return "";

	const words = str.split("_");
	return words.map(capitalizeFirstLetter).join(" ");
}

export function getRandomValue() {
	const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let array = new Uint8Array(40);
	window.crypto.getRandomValues(array);
	array = array.map(x => validChars.charCodeAt(x % validChars.length));
	const randomState = String.fromCharCode.apply(null, array as unknown as Array<number>);

	return randomState;
}

export const isMobile = (function() {
	let check = false;
	(function(a) {
		if (
			//eslint-disable-next-line no-useless-escape
			/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
				a
			) ||
			//eslint-disable-next-line no-useless-escape
			/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
				a.substr(0, 4)
			)
		) { check = true; }
	})(navigator.userAgent || navigator.vendor || (window as any).opera);
	return check;
})();

export const isUneditedActivePageModule = (module: PageModule): boolean => {
	const ignoreList = [PageModuleType.embed_widget, PageModuleType.similar_sessions, PageModuleType.survey, PageModuleType.questions, PageModuleType.comment_box_prompt, PageModuleType.quizzes];
	return !!module &&
		module.is_on &&
		module.is_edited === false && // For backwards compatibility, we'll ignore null / undefined cases
		!ignoreList.includes(module.type);
};

export const findUneditedPageModules = (workingEvent: BrandliveEvent | null, workingSession?: Session | null): { [key: string]: PageModule[] } => {
	let uneditedPageModules = {};
	if (!workingEvent) {
		return uneditedPageModules;
	}

	const { custom_pages, homepage, sessions } = workingEvent;

	for (const customPage of custom_pages ?? []) {
		if (!customPage?.modules || customPage.modules.length === 0) {
			continue;
		}
		const name = customPage.page_name?.base;

		const unedited = customPage.modules.filter(isUneditedActivePageModule);
		if (unedited.length > 0) {
			uneditedPageModules = { ...uneditedPageModules, [name]: unedited };
		}
	}

	if (homepage) {
		if (homepage.modules?.length > 0) {
			const unedited = homepage.modules.filter(isUneditedActivePageModule);
			if (unedited.length > 0) {
				uneditedPageModules = { ...uneditedPageModules, ["Landing Page"]: unedited };
			}
		}

		const { post_register_home_modules } = homepage;

		if (post_register_home_modules.length > 0) {
			const unedited = post_register_home_modules.filter(isUneditedActivePageModule);
			if (unedited.length > 0) {
				uneditedPageModules = { ...uneditedPageModules, ["Homepage"]: unedited };
			}
		}
	}

	for (const session of sessions ?? []) {
		if (!session?.modules || session.modules.length === 0) {
			continue;
		}

		const name = session.title?.base;

		const unedited = session.modules.filter(isUneditedActivePageModule);
		if (unedited.length > 0) {
			uneditedPageModules = { ...uneditedPageModules, [name]: unedited };
		}
	}

	/**
	 * Because the workingSession can differ from the sessions in the working event,
	 * we need to explicitly check if the workingSession has had any page modules
	 * edits.
	 *
	 * This needs to be last to overwrite any potentially stale data from the working event.
	 */

	if (!!workingSession &&
		(workingSession.modules && workingSession.modules?.length > 0)) {
		const name = workingSession?.title?.base;
		const unedited = workingSession.modules.filter(isUneditedActivePageModule);
		if (unedited.length === 0) { // no more unedited modules. clear this key.
			delete uneditedPageModules[name as keyof typeof uneditedPageModules];
		} else { // remaining items found.
			uneditedPageModules = { ...uneditedPageModules, [name]: unedited };
		}
	}

	return uneditedPageModules;
};

export function getUploadFilename(filename: string): string {
	return filename.replace(/[^0-9a-zA-Z.]/g, '-');
}
interface ISlugOptions {
	removeLeadingHyphens?: boolean;
	removeTrailingHyphens?: boolean;
}

export const slugify = (str: string, options?: ISlugOptions) => {

	let slug = str.replace(/[^0-9a-zA-Z]/g, '-');

	const removeLeadingHyphens = !!options?.removeLeadingHyphens;
	const removeTrailingHyphens = !!options?.removeTrailingHyphens;

	// remove all leading hyphens
	if (removeLeadingHyphens) {
		while (slug.startsWith('-')) {
			slug = slug.substring(1);
		}
	}

	// remove all ending hyphens
	if (removeTrailingHyphens) {
		while (slug.endsWith('-')) {
			slug = slug.substring(0, slug.length - 1);
		}
	}
	return slug;
};

/**
 * returns input, or upper/lower bound if input exceeds either
*/
export const numClamp = (input: number, min: number, max: number): number => {
	return Math.min(Math.max(input, min), max);
};

// use in lieu of striptags to convert html special characters
export const convertHtmlString = (html: string, replaceLineBreaksWith?: string): string => {
	const text = document.createElement("text");
	if (replaceLineBreaksWith) {
		const container = document.createElement('div');
		container.innerHTML = html;
		const children = container.querySelectorAll('div > *');
		const childArray = Array.from(children);
		const textArray = childArray.map(child => (child as HTMLElement).innerText);
		const text = textArray.join(replaceLineBreaksWith);
		return text;
	} else {
		text.innerHTML = html;
	}
	return text.innerText;
};

/**
 * Removes characters from filenames that will be escaped as URL entities
 * 
 * @param str string we want to drop illegal characters from - most likely a filename
 * @param replacement optional replacement character, defaults to "-"
 * @returns string without the illegal characters
 * 
 * @example
 *	removeIllegalChars("(1) hello world (2).mp4") // returns "1-hello-world-2.mp4"
 */
export const removeIllegalChars = (str: string, replacement = "-"): string => {
	const leadingRegex = new RegExp(`(^\\${replacement}+)`, 'g');
	const endingRegex = new RegExp(`(\\${replacement}\\.)`, 'g');
	return str
		.replace(/[^0-9a-zA-Z._-]/g, replacement) // replace the illegal chars with replacement
		.split(replacement)												// split on the replacement, will have empty items in the array for duplicate chars, ie: "foo---bar" -> ["foo", "", "", "bar"]
		.filter(char => !!char)										// drop the empty items from the array
		.join(replacement)												// join on the replacement
		.replace(leadingRegex, '')								// drop leading hypen ie: "-foo.mp4" -> "foo.mp4"
		.replace(endingRegex, '.');								// drop trailing hypen in filename ie: "foo-.mp4" -> "foo.mp4"
};

/**
 * 
 * @param object Record<string, string> any one-level object
 * @returns Record<string, string> the object with keys/values swapped
 * 
 * @example { foo: 'bar' } -> { bar: 'foo' }
 */
export const reverseObject = (object: Record<string, string>): Record<string, string> => {
	const entries = Object.entries(object);
	return Object.fromEntries(entries.map(([key, value]) => [value, key]));
};

export const getTemplateClassName = (template?: string, fallback = TemplateNames.Limelight) => {
	if (!template) return TemplateClassNames[fallback];
	return TemplateClassNames[template] || slugify(template);
};

/**
 * 
 * @param selector string 
 * @returns boolean
 * 
 * @example isCssSelectorValid('.random-class') -> true; 
 * @example isCssSelectorValid(100) -> false;
 */

export const isCssSelectorValid = (selector: string) => {
	try {
		document.createDocumentFragment().querySelector(selector);
		return true;
	} catch {
		return false;
	}
};

export const separateCustomAndDefaultThemes = (
	themes: Template[] | null,
	isModuleGroupingV2 = false,
): [Template[], Template[]] => {

	if (!themes) return [[], []];

	const defaultThemes: Template[] = [];
	const customThemes: Template[] = [];
	// When the feature flag is off, we don't want to show the Limelight template
	const templateInvisibled = (templateId: number) => {
		const isSeason2ButNoFeatureFlag = (!isModuleGroupingV2 && [Templates['Limelight']].includes(templateId));
		const isFeatureFlagAndRemoveTmp = (isModuleGroupingV2 && [Templates.O2, Templates['Studio 54'], Templates.Apollo].includes(templateId));
		return isSeason2ButNoFeatureFlag || isFeatureFlagAndRemoveTmp;
	};

	themes?.forEach((theme: Template) => {
		if (theme.channel === 0) {
			if (!templateInvisibled(theme.template)) {
				defaultThemes.push(theme);
			}
		} else {
			customThemes.push(theme);
		}
	});

	return [defaultThemes, customThemes];
};

/**
 * Return path for default create event flow from blank template
 * @param templates 
 * @returns string => path for create event flow
 */
export const getDefaultCreateEventLink = (templates: Template[] | null, sessionDetailsV2 = false): string => {
	if (!templates) return '';
	const [defaultThemes] = separateCustomAndDefaultThemes(templates, sessionDetailsV2);
	if (!defaultThemes[0]?.template) return '';
	return PATHNAMES.CreateEventFromThemeInfoLink(defaultThemes[0].template);
};

export const isRtlLanguage = (language: LanguagesAbbr): boolean => {
	const forceRtlLanguages: LanguagesAbbr[] = ['ar', 'he', 'fa', 'ps', 'sd', 'ur', 'ku'];
	return forceRtlLanguages.includes(language);
};


const decToHex = (value: number) => {
	if (value > 255) {
		return 'ff';
	} else if (value < 0) {
		return '00';
	} else {
		return value.toString(16).padStart(2, '0').toLowerCase();
	}
};

export const rgbToHex = (rgb: string) => {
	const nums = rgb.split('(')[1].split(')')[0];
	const [r, g, b] = nums.split(',').map(n => Number(n.trim()));
	return '#' + decToHex(r) + decToHex(g) + decToHex(b);
};

// the t argument needs to be the result of the useTranslate hook
export const getLanguageDropdownDefault = (eventId?: number, registrationLang?: string, eventLang?: string, t?: any): string => {
	let langDefault;
	// first, check if there is a profile language preference set
	langDefault = localStorage.getItem(`event.${eventId}.language`);
	if (langDefault) {
		langDefault = JSON.parse(langDefault)['item'];
	}
	// if no profile language is set, then fallback to the registration language preference
	if (!langDefault) {
		langDefault = registrationLang;
	}
	// if no registration language is set, then fallback to the system/browser language
	if (!langDefault) {
		const navLanguage = navigator.language;
		// handle 3-letter, supported language codes
		if (
			navLanguage?.slice(0, 3) === 'bho' ||
			navLanguage?.slice(0, 3) === 'ceb' ||
			navLanguage?.slice(0, 3) === 'ckb' ||
			navLanguage?.slice(0, 3) === 'doi' ||
			navLanguage?.slice(0, 3) === 'gom' ||
			navLanguage?.slice(0, 3) === 'haw' ||
			navLanguage?.slice(0, 3) === 'hmn' ||
			navLanguage?.slice(0, 3) === 'ilo' ||
			navLanguage?.slice(0, 3) === 'kri' ||
			navLanguage?.slice(0, 3) === 'lus' ||
			navLanguage?.slice(0, 3) === 'mai' ||
			navLanguage?.slice(0, 3) === 'nso'
		) {
			langDefault = navigator.language.slice(0, 3);
			// handle 5-letter, supported language codes
		} else if (navLanguage?.slice(0, 5) === 'zh-CN' || navLanguage?.slice(0, 5) === 'zh-TW') {
			langDefault = navLanguage.slice(0, 5);
		} else if (t) {
			// assert whether the language code is valid if we've made it this far
			const translationIsValid = !!t(`languages-list:Languages.${navigator.language?.slice(0, 2)}`);
			if (translationIsValid) {
				langDefault = navigator.language?.slice(0, 2);
			}
		}
	}
	// if no system/browser language can be found or is not supported, then fallback to the event language default
	if (!langDefault) {
		langDefault = eventLang;
	}
	return langDefault;
};

export function convertByteToHex(byte: number): string {
	return ('0' + byte.toString(16))?.slice(-2);
}

export async function getFileFirstMBAsHex(file: File): Promise<string[]> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = e => {
			const result = e.target?.result;
			if (!result || typeof result === 'string') return;

			const bytes: string[] = [];
			const uint = new Uint8Array(result);
			uint.forEach(byte => {
				const byteAsHex = convertByteToHex(byte);
				byteAsHex && bytes.push(byteAsHex);
			});

			resolve(bytes);
		};

		// read first 50KB of file to not hit ArrayBuffer limit of browser
		reader.readAsArrayBuffer(file.slice(0, 50000));
		reader.onerror = error => reject(error);
	});
}


export async function validateFileType(file: File, acceptedFileTypes: string[], acceptedFileExtensions?: string[]): Promise<boolean> {
	let fileType = file.type;
	if (!fileType) {
		fileType = await getFileContentType(file);
	}
	const acceptedFileType = acceptedFileTypes.some(type => {
		if (type.includes('/*')) {
			const generalMimeType = type.split('/')[0];
			const fileMimeType = fileType.split('/')[0];

			return generalMimeType === fileMimeType;
		}

		return type === fileType;
	});

	const acceptedFileExtension = acceptedFileExtensions?.some(ext => file.name.endsWith(ext));

	if (!acceptedFileType && !acceptedFileExtension) return false;

	const magicNumbers = (await import('./magic-numbers.json')).default;
	type IMagicNumbers = typeof magicNumbers

	const fileExt = extractFileType(file.name);
	const magicNumberAttributes = (magicNumbers as IMagicNumbers)[fileExt as keyof IMagicNumbers];
	const bytes = await getFileFirstMBAsHex(file);

	if (!magicNumberAttributes || !bytes) {

		// some file types dont have magic numbers. If this is one of those, then we are not going to include magic number in the validation
		return true;
	}

	const validMagicNumber = magicNumberAttributes.signs.some((sign: any) => {
		const [offset, allowedMagicNumber] = sign.split(',');
		const offsetAsNumber = Number(offset || 0);
		const magicNumber = bytes.slice(offsetAsNumber, offsetAsNumber + 4).join('').toUpperCase();
		if (!magicNumber) return false;

		if (allowedMagicNumber.length < magicNumber.length) {
			return allowedMagicNumber.includes(magicNumber.slice(0, allowedMagicNumber.length));
		}
		return allowedMagicNumber.includes(magicNumber);
	});

	return validMagicNumber;
}

export const iconNameList = () => {
	return [ICONS.BREAKOUT_ROOMS, ICONS.INFO_OUTLINE, ICONS.BAR_GRAPH, ICONS.DOCUMENTS, ICONS.IMAGES, ICONS.SIGNAL];
};

export const isPartiallyGatedEvent = (passcodeLists: Requirement[] | undefined, sessionList: number[]) => {
	if (!passcodeLists || !passcodeLists.length) return false;
	const gatedSessions = uniq(passcodeLists.map(list => list.gatedSessions).flat());
	const allSessionsAreGated = intersection(gatedSessions, sessionList).length === sessionList.length;

	return !allSessionsAreGated;
};

interface IFilterSessions {
	sessions: (Session | SessionPreview)[];
	showOnDemandSessions: boolean;
	showBroadcastSessions: boolean;
}

export const filterSessionsByAgendaModuleSettings = (
	{
		sessions,
		showOnDemandSessions,
		showBroadcastSessions,
	}: IFilterSessions
) => {
	const _filteredSessions: (Session | SessionPreview)[] = [];
	sessions?.forEach(session => {
		const sessionFeatures = SessionTypesFeatures[session.session_type];
		if (sessionFeatures.has_start_time) {
			if (showBroadcastSessions) {
				_filteredSessions.push(session);
			}
		} else {
			if (showOnDemandSessions) {
				_filteredSessions.push(session);
			}
		}
	});
	return _filteredSessions;
};

export const getChannelFlagMapFromList = (channelFlagList: string[] = []) => {
	const channelFlagMap: Record<string, boolean> = {};
	channelFlagList.forEach(flag => {
		channelFlagMap[flag] = true;
	});
	return channelFlagMap;
};

export const getHostUrl = () => {
	const host = window.location.host;
	const protocol = window.location.protocol;
	return `${protocol}//${host}`;
};

export const isDefined = <T>(value: T | null | undefined): value is T => value !== undefined;

export const VIDEO_TRACKING_MILESTONES: ReadonlyArray<number> = [10, 25, 50, 75, 90, 100];

export const hasReachedMilestone = () => {
	let trackingMilestones = [...VIDEO_TRACKING_MILESTONES];

	return (playedPercentage: number) => {
		if (trackingMilestones.length === 0) return false;
		const filteredMilestones = trackingMilestones.filter((milestone: number) => milestone > playedPercentage);
		if (filteredMilestones.length === trackingMilestones.length) {
			return false;
		}

		trackingMilestones = filteredMilestones;
		return true;
	};
};

// we might need to create a unique key for this event before any data for it has loaded
// this is intended for use in the live event only as it only generates paths based on
// the live event path routing pattern
export const getPathKey = () => {
	const path = window.location.pathname;

	// leading slash will always create an empty string at array position 0
	const pathParts = path.split('/');

	// on local, path pattern is `event/[eventuuid]` otherwise path is `/[eventname]`
	const local = process.env.REACT_APP_STAGE === 'local';

	return local ? pathParts[2] : pathParts[1];
};

export const splitFilenameAndExtension = (filename: string): { name: string, extension: string } => {
	// returns match groups "name" and "extension" without the . character separator
	// "name" should be everything before the last . character and can itself include "." characters
	// "extension" should be everything after the last . character
	// example output: "foo.bar.baz.mp4" => { name: "foo.bar.baz", extension: "mp4" }
	const matches = filename.match(/(?<name>.*\.)(?<extension>.+$)/);

	// if the filename does not contain a . character, then we will return the entire filename as the name
	const name = matches?.groups?.name || filename;
	if (!matches?.groups?.name) {
		console.warn('splitFilenameAndExtension: filename does not contain a valid name. Returning default name.');
	}

	// if the file extension does not exist, return "file" as the defaulte extension
	const extension = matches?.groups?.extension;
	if (!matches?.groups?.extension) {
		console.warn('splitFilenameAndExtension: filename does not contain a file extension. Returning default extension.');
	}

	return {
		// drop the final . character from the name, fallback to "untitled" if there's nothing there
		name: name.replace(/\.$/, '') || 'untitled',

		// return "file" as the extension if there is no file extension
		extension: extension || 'file'
	};
};


export const dropNonLettersAndNumbers = (str: string, replacer = '-') => {
	return str.replace(/[^\p{Letter}\p{Decimal_Number}_]/gu, replacer);
};

export const safeFilename = (str: string, replacer = '-') => {
	const {
		name,
		extension
	} = splitFilenameAndExtension(str);

	// replace all illegal chars from name base and re-add extension
	// this is done to prevent . characters from appearing in the middle of the filename
	return `${fullTrim(name.replace(/[^\p{Letter}\p{Decimal_Number}_]/gu, replacer))}.${extension}`;
};

// trim leading and trailing whitespace and leading and trailing slashes ie: " -file-name- " -> "file-name"
export const fullTrim = (str: string) => {
	return str.trim().replace(/^-+|-+$/g, '');
};

// drop duplicate instances of dashes ie: "file------name" -> "file-name"
export const dedupDashes = (str: string) => {
	return str.replace(/-+/g, '-');
};

export const getCleanFilename = (filename: string): string => {
	return dedupDashes(fullTrim(safeFilename(filename)));
};

export const mergeUniqueById = <T>(key: keyof T, incoming: T[]) => (existing: T[]): T[] => {
	const map = new Map([
		...existing.map<readonly [unknown, T]>(item => [item[key], item]),
		...incoming.map<readonly [unknown, T]>(item => [item[key], item])
	]);

	return Array.from(map.values());
};

export const reactAppStage = {
	isLocal: process.env.REACT_APP_STAGE === 'local',
	isDev: process.env.REACT_APP_STAGE === 'dev',
	isSandbox: process.env.REACT_APP_STAGE === 'sandbox',
	isQA: process.env.REACT_APP_STAGE === 'qa',
	isHotfix: process.env.REACT_APP_STAGE === 'hotfix',
	isLoad: process.env.REACT_APP_STAGE === 'load',
	isStaging: process.env.REACT_APP_STAGE === 'staging',
};

export const getFormattedPrice = (price?: number, currency?: string, multiplier?: number): string | undefined => {
	if (!price || !currency) return undefined;

	// prices are entered as integers so we show digits after decimal point based on currency decimal base
	// 3 is fallback because multiplier for USD is 100 which has 3 digits
	const afterDecimalPoint = (multiplier?.toString().length ?? 3) - 1;

	return price.toLocaleString("en-US", { minimumFractionDigits: afterDecimalPoint, style: 'currency', currency: currency });
};

// 8 or 9 numbers OR empty for a non entered ID
export const validateDmaID = (dmaID: string | undefined | null) => dmaID && /^[0-9]{8,9}$/.test(dmaID) || !dmaID?.length;

export const getModeratorProfileFromChatMessage = (comment: any, channel: number, eventUuid: string) => {
	let moderatorProfile: IModeratorProfileFields | undefined;
	if (comment.is_moderator) {
		if (comment.moderator_profile?.[channel]?.[eventUuid]) {
			moderatorProfile = comment.moderator_profile[channel][eventUuid];
		} else if (isObject(comment.moderator_profile) && 'first_name' in comment.moderator_profile) {
			moderatorProfile = comment.moderator_profile;
		}
	}

	return moderatorProfile;
};

export const cloudfrontUrlMap: Record<string, string> = {
	// Assets
	"brandlive-upload.s3-us-west-2.amazonaws.com": "assets.brandlive.com",
};

export const getCloudfrontUrl = (url: string): string => {
	try {
		const urlObject = new URL(url);

		if (urlObject.hostname in cloudfrontUrlMap) {
			urlObject.hostname = cloudfrontUrlMap[urlObject.hostname];
		}

		return urlObject.toString();
	} catch (e) {
		return url;
	}
};

/*
 We don't show the language selector on the fireside or breakout room session editor
 */
export const canShowLanguageSelector = (workingSession: Session | CreateSession, defaultLanguage: Language) => {
	const isFireside = workingSession?.session_type === SessionTypesEnum.fireside;
	const isBreakoutRoom = workingSession?.session_type === SessionTypesEnum.breakoutRooms;

	return !((isFireside || isBreakoutRoom) && defaultLanguage);
};

/**
 * Because our registration questions are sortable, we need to make sure that our event.registration_questions
 * correctly match the order of the registration steps questions.
 * This function is used to sort the redux registration questions based on the order of the registration steps questions
 * We need to ensure that the order of workingEvent.registration_questions matches the combined order of
 * general and profile registration steps questions, respectively.
 * Once sorted, redux will save the correct order of registration questions into the db.
 * @param registrationSteps sorted redux registration steps
 * @param registrationQuestions redux workingEvent.registration_questions
 * @param registrationQuestionsToAppend if we are adding a new registration question, we need to append it to the correct step
 * @returns sorted registration questions
 */
export const sortWorkingEventRegistrationQuestions = ({
	registrationSteps,
	registrationQuestions,
	registrationQuestionsToAppend,
}: {
	registrationSteps?: RegistrationStep[];
	registrationQuestions?: RegistrationQuestion[];
	registrationQuestionsToAppend?: {
		step: RegistrationStepType;
		questions: RegistrationQuestion[];
	}
}): RegistrationQuestion[] => {

	// updatedRegistrationQnOrder stores our properly sorted registration questions
	const updatedRegistrationQnOrder: RegistrationQuestion[] = [];

	// get all general step question ids
	const alreadySortedGeneralStepQuestions = registrationSteps?.
		find(step => step.type === RegistrationStepType.general)?.questions?.
		map(q => q.registration_question) || [];

	// get all profile step questions ids
	const alreadySortedProfileStepQuestions = registrationSteps?.
		find(step => step.type === RegistrationStepType.profile)?.questions?.
		map(q => q.registration_question) || [];

	// The order below matters:
	// `alreadySortedGeneralStepQuestions` must come before `alreadySortedProfileStepQuestions`

	// for each general step question, find the corresponding registration question and add it to the sort array
	// so that we create the correct order of registration questions
	alreadySortedGeneralStepQuestions.forEach(q => {
		const found = registrationQuestions?.find(rq => rq.registration_question === q);
		if (found) updatedRegistrationQnOrder.push(found);
	});
	// if we are adding a new general step registration question, we need to append it to the end of the general step
	if (registrationQuestionsToAppend?.step === RegistrationStepType.general) {
		updatedRegistrationQnOrder.push(...registrationQuestionsToAppend.questions);
	}

	// begin profile step questions
	// for each profile step question, find the corresponding registration question and add it to the sort array
	alreadySortedProfileStepQuestions.forEach(q => {
		const found = registrationQuestions?.find(rq => rq.registration_question === q);
		if (found) updatedRegistrationQnOrder.push(found);
	});
	// if we are adding a new profile step registration question, we need to append it to the end of the profile step
	if (registrationQuestionsToAppend?.step === RegistrationStepType.profile) {
		updatedRegistrationQnOrder.push(...registrationQuestionsToAppend.questions);
	}

	// this will become our new workingEvent.registration_questions, which redux will save to the db
	// note that only the question ids actually get saved into the db as number[],
	// but we need the full RegistrationQuestion[] for redux
	return updatedRegistrationQnOrder;
};
