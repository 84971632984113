import { lazy, Suspense } from 'react';
const EditableHypertextModalWrapped = lazy(() => import('./editable-hyperlink-modal'));

interface EditableHypertextModalProps {
	value: string;
	onChange: (text: string) => void;
	open: boolean;
	onClose: () => void;
	onSave: () => void;
	disableSave?: boolean;
	placeholder?: string;
	className?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
}

const EditableHypertextModal = (props: EditableHypertextModalProps): JSX.Element => {
	return (
		<Suspense fallback="">
			<EditableHypertextModalWrapped {...props} />
		</Suspense>
	);
};

export default EditableHypertextModal;