import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import './session-modules-lists-v2.scss';
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import {
	BreakoutRoom
} from "../../../../../../types/working-model";
import {
	updateWorkingSession
} from '../../../../../../store/actions/admin/create-event/session';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import WaitingIndicator from '../../../../../general-ui/waiting-indicator/waiting-indicator';
import ModalComponent from '../../../../../general-ui/modal/modal';
import { EditBreakoutRoom } from '../../../session/breakout-session-modal/breakout-chat-rooms/breakout-chat-rooms';
import { UploadFile } from '../../../../../../connection/uploads';
import { debounce } from 'underscore';
import { DisableZoomRoom } from '../../../../../../connection/zoom';
import { SaveBreakoutRoomsForSession } from '../../../../../../connection/sessions';

// PULLED COMPONENT FROM session-content.tsx

const EditSingleBreakoutRoom = ({ initialRooms, room, open, close }: { initialRooms: BreakoutRoom[], room: BreakoutRoom | null, open: boolean, close: () => void }) => {
	const { user, token } = useTypedSelector(state => state.AuthReducer);
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const PrimaryTooltip = (): JSX.Element => <Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12}></Icon>;
	const [rooms, setRooms] = useState(initialRooms);
	const { workingSession } = useTypedSelector(state => state.CreateSessionReducer);
	const dispatch = useDispatch();
	const [saving, setSaving] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const handleAttendeesChange = useCallback((attendeesCount: string, roomIndex: number) => {
		const newRooms = [...rooms];
		newRooms[roomIndex] = {
			...newRooms[roomIndex],
			max_users: parseInt(attendeesCount)
		};
		setRooms([...newRooms]);
	}, [rooms]);

	const debounced = useRef(debounce((value: string, cb: (value: string) => void) => {
		cb(value);
	}, 300)).current;

	const handlenameChange = (value: string, room: BreakoutRoom) => {
		debounced(value, async value => {
			setRooms(prev => prev.map(prevRoom => ({
				...prevRoom,
				name: prevRoom?.id === room.id ? value.trim() : prevRoom.name,
			})));
		});
	};

	const handleDescriptionChange = (value: string, room: BreakoutRoom) => {
		debounced(value, async value => {
			setRooms(prev => prev.map(prevRoom => ({
				...prevRoom,
				description: prevRoom?.id === room.id ? value : prevRoom.description,
			})));
		});
	};

	const handleImageChange = (value: string, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			image: prevRoom?.id === room.id ? value : prevRoom.image,
		})));
	};

	const handleClearImage = (room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			image: prevRoom?.id === room.id ? undefined : prevRoom.image,
		})));
	};

	const handlenameFocused = (value: boolean, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			nameFocused: prevRoom?.id === room.id ? value : prevRoom.nameFocused,
		})));
	};

	const handleMaxUsersChange = (value: number, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			max_users: prevRoom?.id === room.id ? value : prevRoom.max_users,
		})));
	};

	const handleImageUploading = (uploading: boolean, room: BreakoutRoom) => {
		setRooms(prev => prev.map(prevRoom => ({
			...prevRoom,
			imageUploading: prevRoom?.id === room.id ? uploading : prevRoom.imageUploading,
		})));
	};

	async function handleAddImage(f: File | FileList, room: BreakoutRoom) {
		if (!user || !token) return;
		handleImageUploading(true, room);
		const uploadedImage = await UploadFile(user, token, f as File);
		handleImageChange(uploadedImage, room);
		handleImageUploading(false, room);
	}

	const killZoom = useCallback(() => {
		setConfirmDelete(true);
	}, []);

	const handleSave = useCallback(async () => {
		if (!workingSession || !token) return;
		setSaving(true);
		const use_zoom = workingSession?.breakout_session?.use_zoom || false;
		const savedRooms = await SaveBreakoutRoomsForSession(rooms, use_zoom, workingSession.session, token, { return_results: true });
		dispatch(updateWorkingSession({
			...workingSession,
			breakout_rooms: savedRooms
		}));
		setSaving(false);
		close();
	}, [rooms, dispatch, token, workingSession, close]);

	//called from the "Are you sure" dialog modal
	const confirmKill = useCallback(async () => {
		if (!token) return;
		//sends message to API to fwd on to zoom to disable the zoom room and kick all the participants out
		await DisableZoomRoom(room?.video_settings?.zoom?.id, token);

		const roomUpdate = rooms.map(prevRoom => ({
			...prevRoom,
			video_settings: prevRoom.id === room?.id ? {
				...prevRoom.video_settings,
				zoom: {
					...prevRoom.video_settings.zoom,
					enabled: false
				}
			} : prevRoom.video_settings,
		}));

		//update the room data to show the room as disabled
		setRooms(roomUpdate);

		//update visible state
		setConfirmDelete(false);
		setSaving(true);

		//save the rooms
		if (workingSession) {
			const savedRooms = await SaveBreakoutRoomsForSession(roomUpdate, !!workingSession?.breakout_session?.use_zoom, workingSession.session, token, { return_results: true });

			//update the reducer
			dispatch(updateWorkingSession({
				...workingSession,
				breakout_rooms: savedRooms
			}));
		}

		//update visible state
		setSaving(false);

	}, [room, token, rooms, workingSession, dispatch]);

	const useRoom = rooms?.find(_room => _room?.id === room?.id);

	if (!useRoom) {
		return <div />;
	}

	return (
		<>
			<ModalComponent
				open={open}
				onRequestClose={close}
				closeable={true}
				title={"Edit Breakout Room"}
				cancellable={false}
				footer={(
					<div style={{ display: 'flex', width: '100%' }}>
						{useRoom?.video_settings.zoom && (
							<div style={{ flex: 1 }}>
								<button disabled={!useRoom?.video_settings.zoom?.enabled} className="lemonade" onClick={killZoom} style={{ flex: 1 }}>
									End Zoom
								</button>
							</div>
						)}

						<button onClick={close} style={{ marginRight: 16 }}>
							Cancel
						</button>
						<button disabled={saving} onClick={handleSave} className="lemonade">
							{saving ? <WaitingIndicator /> : 'Save'}
						</button>
					</div>
				)}
			>
				{useRoom && (
					<EditBreakoutRoom
						room={useRoom}
						index={0}
						PrimaryTooltip={PrimaryTooltip}
						handleAddImage={handleAddImage}
						onAttendeeChange={handleAttendeesChange}
						onDescriptionChange={handleDescriptionChange}
						onClearImage={handleClearImage}
						onNameChange={handlenameChange}
						onMaxUsersChange={handleMaxUsersChange}
						onNameFocused={handlenameFocused}
						onRemove={() => ({})}
						maxUsersOpened={() => ({})}
						displayMaxUsers={(!workingSession?.breakout_session?.use_zoom) || true}
						numberOfRooms={rooms.length}
					/>
				)}

			</ModalComponent>
			<ModalComponent
				open={confirmDelete}
				onRequestClose={() => setConfirmDelete(false)}
				closeable={true}
				cancellable={true}
				title={"Confirm End Room"}
				footer={
					<>
						<button onClick={() => setConfirmDelete(false)}>Cancel</button>
						{!!publishedUrl && <button className="lemonade" onClick={confirmKill}>End Breakout</button>}
					</>
				}
			>
				<div>
					{publishedUrl ? (
						<>
							<p>Are you sure you want to end <strong><span className="cyan">{room?.name}</span></strong>?</p>

							<p style={{ marginTop: 16 }}>This action cannot be undone!</p>
						</>
					) : (
						<p>You can only end a breakout after the event has been published.</p>
					)}
				</div>

			</ModalComponent>
		</>
	);
};

export default EditSingleBreakoutRoom;
