import { useState } from "react";

type Props = {
	heading: string;
	data: string[];
	threshold?: number;
}

const DEFAULT_NUMBER_OF_TAGS_THRESHOLD = 3;

export default function Tags(props: Props): JSX.Element {
	const [showMore, setShowMore] = useState(props.threshold ? props.threshold >= props.data.length : true);

	const dataToShow = showMore ? props.data : props.data.slice(0, props.threshold || DEFAULT_NUMBER_OF_TAGS_THRESHOLD);

	const leftOverTags = props.data.length - dataToShow.length;
	const overshownTags = dataToShow.length - (props.threshold || DEFAULT_NUMBER_OF_TAGS_THRESHOLD);

	function showMoreTags() {
		setShowMore(!showMore);
	}

	return (
		<div className="settings-section categories">
			<h6>{props.heading}</h6>
			<div className="tag-container firesides-hosts-settings-container">
				<div className="tags-subcontainer">
					{dataToShow.map((d) => (
						<span key={d} className="tag">
							{d}
						</span>
					))}
				</div>
				<div className="tag-container-show-more-btn" onClick={showMoreTags}>
					{leftOverTags > 0 && !showMore && `+ ${leftOverTags} more`}
					{leftOverTags === 0 && showMore && overshownTags > 0 && "Collapse"}
				</div>
			</div>
		</div>
	);
}