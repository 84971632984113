import Dexie from 'dexie';
import { RoomChatMessage, DirectChat } from '../reducers/event/breakout-chat-reducer';
export interface OnDemandWatchTime {
	sessionId: number;
	video_source: string;
	watchtime: number;
	duration: number;
}

class Db extends Dexie {
	//lets not access these directly from outside the class, write deliberate methods if we want to 
	//interface with the database
	private chatRooms: Dexie.Table<RoomChatMessage, number>;
	private directMessages: Dexie.Table<DirectChat, number>;
	private lastReadOpenChat: Dexie.Table<any, number>;
	private onDemandWatchTime: Dexie.Table<OnDemandWatchTime, number>;


	//creates a new table if not exists OR accesses table if it exists
	constructor() {
		super("Events3");

		//index structure
		this.version(3).stores({
			chatRooms: '++id, roomId, user, message',
			directMessages: '++id, roomId, groupId, user, messages',
			lastReadOpenChat: 'roomId, timestamp',
			onDemandWatchTime: 'sessionId'
		});

		this.chatRooms = this.table("chatRooms");
		this.directMessages = this.table("directMessages");
		this.lastReadOpenChat = this.table("lastReadOpenChat");
		this.onDemandWatchTime = this.table("onDemandWatchTime");
	}

	//gets all messages for a room by breakout id
	getChatRoomMessages = async (roomId: string) => {
		const messages = await this.chatRooms.where("roomId").equals(roomId).toArray();
		return messages;
	};

	//gets just direct messages for a room by breakout id - will include all dms from all users
	getDirectMessages = async (roomId: string) => {
		const messages = await this.directMessages.where("roomId").equals(roomId).toArray();
		return messages;
	};

	//add a new chat message to the room
	insertChatMessage = async (chatMessage: RoomChatMessage) => {
		chatMessage.createDate = new Date();
		await this.chatRooms.add(chatMessage);
		return chatMessage;
	};

	//add a new direct chat message to the store
	insertDirectChat = async (chatMessage: RoomChatMessage, groupId: string) => {
		const roomRecord = await this.directMessages.where("groupId").equals(groupId);

		if (roomRecord) {
			const [room] = await roomRecord.toArray();
			room.messages = [...room.messages, chatMessage];
			await this.directMessages.update(room.id!, room);
		}

		return [chatMessage, groupId];
	};

	setLastReadOpenChat = async (roomId: number) => {
		await this.lastReadOpenChat.put({ roomId, timestamp: Date.now() + 1000 });
		return { [roomId]: Date.now() + 1000 };
	};

	getLastReadOpenChat = async (roomIds: number[]) => {
		const lastReads = await this.lastReadOpenChat.bulkGet(roomIds);
		const len = roomIds.length;
		const readMap: Record<number, number | null> = {};
		for (let i = 0; i < len; ++i) {
			readMap[roomIds[i]] = lastReads[i].timestamp ?? null;
		}
		return readMap;
	};


	// get and set onDemandWatchTime
	setOnDemandWatchTime = async (sessionId: number, video_source: string, watchtime: number, duration: number) => {
		await this.onDemandWatchTime.put({ sessionId, video_source, watchtime, duration });
		return `${sessionId}-${video_source}`;
	};

	getOnDemandWatchTime = async (sessionId: number, video_source: string) => {
		const watchTime = await this.onDemandWatchTime.where('sessionId').equals(sessionId)
			.and(item => item.video_source === video_source)
			?.toArray();

		return watchTime;
	};


}

export default new Db();