import Icon, { COLORS, ICONS } from '../icon';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import './paging.scss';

interface PagingProps {
	page: number;
	setPage: (page: number) => void;
	totalPages: number;
	disabled?: boolean;
}

const getInitPageGroup = (totalPages: number): number[] => {
	switch (totalPages) {
		case 1:
			return [];
		case 2:
			return [];
		case 3:
			return [2];
		case 4:
			return [2, 3];
		case 5:
			return [2, 3, 4];
		default:
			return [3, 4, 5];
	}
};

export default function Paging({ page = 1, setPage, totalPages, disabled }: PagingProps): JSX.Element {
	const [pageGroup, setPageGroup] = useState<number[]>([]);

	useEffect(() => {
		if (totalPages < 2) { return; }
		setPageGroup(getInitPageGroup(totalPages));
	}, [setPage, totalPages]);

	const noExtraPages = totalPages <= 5;
	const noLastPage = totalPages === 1 || !totalPages;

	const firstInGroup = pageGroup[0];
	const noPrev = firstInGroup === 3;
	const prevGroup = noPrev ? '2' : '...';
	const noPrevEllipsis = firstInGroup === 2;
	const handlePrevGroup = () => {
		const page = noPrev ? 2 : firstInGroup - 1;
		setPage(page);
	};

	const lastInGroup = pageGroup[pageGroup.length - 1];
	const noNext = lastInGroup === totalPages - 2;
	const nextGroup = noNext ? totalPages - 1 : '...';
	const noNextEllipsis = lastInGroup === totalPages - 1;
	const handleNextGroup = () => {
		const page = noNext ? totalPages - 1 : lastInGroup + 1;
		setPage(page);
	};

	useEffect(() => {
		if (noExtraPages) { return; }
		if (noPrev && page <= 4) { return; }
		if (noNext && page >= totalPages - 2) { return; }
		if (page <= 4) { setPageGroup([3, 4, 5]); }
		else if (page >= totalPages - 2) { setPageGroup([totalPages - 4, totalPages - 3, totalPages - 2]); }
		else { setPageGroup([page - 1, page, page + 1]); }
	}, [noExtraPages, noNext, noPrev, page, totalPages]);

	if (totalPages < 2) {
		return <></>;
	}
	return (
		<div className="paging-container">
			<button
				disabled={page <= 1 || disabled}
				className="no-style"
				onClick={() => setPage(page - 1)}
			>
				<Icon name={ICONS.ARROW_LEFT} color={COLORS.WHITE} size={12} />
			</button>

			<button
				className={classNames('no-style', { active: page === 1 })}
				onClick={() => setPage(1)}
				disabled={disabled}
			>
				1
			</button>

			{!noExtraPages && !noPrevEllipsis && (
				<button
					className={classNames('no-style', {
						active: page === 2,
						ellipsis: !noPrev,
					})}
					onClick={handlePrevGroup}
					disabled={disabled}
				>
					{prevGroup}
				</button>
			)}

			{pageGroup.map((p: number) => (
				<button
					className={classNames('no-style', { active: p === page })}
					onClick={() => setPage(p)}
					key={p}
					disabled={disabled}
				>
					{p}
				</button>
			))}

			{!noExtraPages && !noNextEllipsis && (
				<button
					className={classNames('no-style', {
						active: page === totalPages - 1,
						ellipsis: !noNext,
					})}
					onClick={handleNextGroup}
					disabled={disabled}
				>
					{nextGroup}
				</button>
			)}

			{!noLastPage && (
				<button
					className={classNames('no-style', {
						active: page === totalPages,
					})}
					onClick={() => setPage(totalPages)}
					disabled={disabled}
				>
					{totalPages}
				</button>
			)}

			<button
				disabled={page >= totalPages || disabled}
				className="no-style"
				onClick={() => setPage(page + 1)}
			>
				<Icon name={ICONS.ARROW_RIGHT} color={COLORS.WHITE} size={12} />
			</button>
		</div>
	);
}
