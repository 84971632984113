import React, { useState } from 'react';

import { useTypedSelector } from '../../../../../../../../store/reducers/use-typed-selector';
import { HomepageHeader } from '../../../../../../../../types/working-model';
import Icon, { COLORS, ICONS } from '../../../../../../../general-ui/icon';
import Switch from '../../../../../../../general-ui/switch/switch';
import { Tooltip } from '../../../../../../../general-ui/tooltip/tooltip';

interface IHomepageHeaderAdvancedLayoutProps {
	module: HomepageHeader;
	onUpdate?: (data: { sticky: boolean; signInBtn: boolean; }) => void;
}

const HomepageHeaderAdvancedLayout: React.FC<IHomepageHeaderAdvancedLayoutProps> = ({ onUpdate }) => {

	const workingEvent = useTypedSelector(event => event.CreateEventReducer.workingEvent);
	const isEmailConfirmationEnabled = !!workingEvent?.registration_settings?.sendEmailConfirmation;

	const [sticky, setSticky] = useState(!!workingEvent?.homepage?.header.is_sticky);
	const [signInBtn, setSignInBtn] = useState(!!workingEvent?.homepage?.header?.sign_in_button);

	const handleStickyHeaderClick = (i: any, on: boolean) => {
		setSticky(on);
		onUpdate?.({ sticky: on, signInBtn });
	};

	const handleSignInBtnClick = (i: any, on: boolean) => {
		setSignInBtn(on);
		onUpdate?.({ sticky, signInBtn: on });
	};

	return (
		<>
			<div className="settings-row">
				<Switch
					value={'sticky header'}
					on={sticky}
					onClick={handleStickyHeaderClick}
				/>
				<p>Sticky header</p>
				<Tooltip tooltip="Keep header fixed to the top of the page when scrolling." position={"top-right"}>
					<Icon
						name={ICONS.INFO_OUTLINE}
						color={COLORS.CYAN}
						size={14}
					/>
				</Tooltip>
			</div>
			<div className="settings-row">
				<Switch
					value={'sign-in-btn'}
					on={signInBtn}
					disabled={!signInBtn && !isEmailConfirmationEnabled} // Keep this enabled in the case that an admin has toggled this on before disabling email confirmation.
					onClick={handleSignInBtnClick}
				/>
				<p>"Sign In" Button</p>
				<Tooltip tooltip="Add a Sign In button for easy returning user when they land on your landing page. Send email confirmation must be on." position={"top-right"}>
					<Icon
						name={ICONS.INFO_OUTLINE}
						color={COLORS.CYAN}
						size={14}
					/>
				</Tooltip>
			</div>
		</>
	);
};

export default HomepageHeaderAdvancedLayout;
