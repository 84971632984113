import { ZoomData } from "../types/working-model";
import { Get, Post } from "./helpers";

export function GetZoomRoom(name: string, token: string): Promise<ZoomData> {
	const params = new URLSearchParams({ name });
	return Get(`/v3/admin/breakout-sessions/zoom?${params.toString()}`, token);
}

export function DisableZoomRoom(id: number | string, token: string): Promise<any> {
	return Post(`/v3/admin/breakout-sessions/zoom`, token, { meeting: id, status: "end" }, true);
}