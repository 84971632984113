import { lazy, Suspense } from "react";
import { EventNavbar, HomepageFooter, HomepageHeader, LanguagesAbbr, NavMainItems, PageModule } from "../../../../../../types/working-model";
const _Controls = lazy(() => import('./controls-wrapped'));
export interface InitialControlsDisplay {
	editColors?: boolean;
	editLayout?: boolean;
	addContent?: boolean;
	changeSectionPosition?: boolean;
	removeSection?: boolean;
}

export interface IEditableContent {
	jsx: JSX.Element;
	onDone: () => void;
}

type ModuleTypes = PageModule | HomepageFooter | HomepageHeader | EventNavbar;
interface Props {
	openContentModal?: () => void;
	openEditModal?: () => void;
	openQuizEditModal?: () => void;
	openReorderModal?: () => void;
	openContentManagementModal?: () => void;
	module: ModuleTypes;
	contentButtonText?: string;
	editableContent?: IEditableContent;
	display?: InitialControlsDisplay;
	addContentIconAsEditIcon?: boolean //makes the add content button use the edit icon
	baseLanguage: LanguagesAbbr;
	language: LanguagesAbbr;
	interceptFinished?: (
		module: ModuleTypes,
		backgroundOverrides: any,
		setOpenStyleModal: React.Dispatch<React.SetStateAction<boolean>>
	) => void;
	disabled?: boolean;
	isEventPages?: boolean;
	className?: string;
	page?: NavMainItems;
	previewContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
	hideEdit?: boolean;
}

export default function Controls(props: Props) {
	return (
		<Suspense fallback="">
			<_Controls {...props} />
		</Suspense>
	);
}