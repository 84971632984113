import { isObject } from "underscore";

interface StoreItem {
	item: string;
	__expiration?: number;
}

export function setStorageItem(key: string, item: string, expirationInHours?: number): void {
	if (isObject(key)) {
		console.error(`setStorageItem: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const storeItem: StoreItem = {
		item: item
	};

	if (expirationInHours) {
		storeItem.__expiration = Date.now() + (expirationInHours * 60 * 60 * 1000);
	}

	localStorage.setItem(key, JSON.stringify(storeItem));
}

export function setStorageObject(key: string, item: any | { [key: string]: any }, expirationInHours?: number): void {
	if (isObject(key)) {
		console.error(`setStorageObject: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	if (expirationInHours) {
		item = {
			...item,
			__expiration: Date.now() + (expirationInHours * 60 * 60 * 1000)
		};
	}

	localStorage.setItem(key, JSON.stringify(item));
}

export function setStorageArray<Type>(key: string, item: Array<Type>, expirationInHours?: number): void {
	if (isObject(key)) {
		console.error(`setStorageArray: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const storeItem = { array: item };

	setStorageObject(key, storeItem, expirationInHours);
}

/**
 * 
 * @param {string} key 
 */
// NOTE: this SHOULD be returning 'string | null' because storage item can ONLY be a string
// but we've got some code issues with setting a proper response type. This should be fixed separately. 
export function getStorageItem(key: string): any | null {
	if (isObject(key)) {
		console.error(`getStorageItem: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const str = localStorage.getItem(key);

	if (str) {
		const item = JSON.parse(str);

		if (item.__expiration && item.__expiration < Date.now()) {
			removeStorageItem(key);
			return null;
		} else {
			return item.item;
		}
	} else {
		return null;
	}
}

export function getStorageObject<T = any>(key: string, newTtlHours?: number): T | null {
	if (isObject(key)) {
		console.error(`getStorageObject: key is an object: ${JSON.stringify(key)}`);
		return null;
	}

	const str = localStorage.getItem(key);

	if (str) {
		const item = JSON.parse(str);

		// if the ttl should be updated, just reset the object with the new ttl
		if (newTtlHours !== undefined && newTtlHours >= 0) {
			setStorageObject(key, item, newTtlHours);
		}

		if (item.__expiration && item.__expiration < Date.now()) {
			removeStorageItem(key);
			return null;
		} else {
			// we don't want the __expiration key included in the response 
			const withoutExpKey = { ...item };

			if ('__expiration' in withoutExpKey) {
				delete withoutExpKey.__expiration;
			}

			return withoutExpKey;
		}
	} else {
		return null;
	}
}

export function checkExpiration(key: string, str: string): void {
	if (isObject(key)) {
		console.error(`checkExpiration: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const strstr = JSON.stringify(str); // For those "unexpected non-whitespace character" errors
	const item = JSON.parse(strstr);

	if (item.__expiration && item.__expiration < Date.now()) {
		removeStorageItem(key);
	}
}

export function getStorageArray<Type>(key: string): Array<Type> {
	if (isObject(key)) {
		console.error(`getStorageArray: key is an object: ${JSON.stringify(key)}`);
		return [];
	}

	const storedArray = getStorageObject<{ array: Array<Type> }>(key);
	if (!storedArray
		|| !storedArray.array
		|| !Array.isArray(storedArray.array)) {
		return [];
	}
	return storedArray.array;
}

export function removeStorageItem(key: string): void {
	if (isObject(key)) {
		console.error(`removeStorageItem: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	localStorage.removeItem(key);
}

export function clearStorage(keepAdminKeys?: boolean): void {
	if (keepAdminKeys) {
		const adminKeysMap: Record<string, string | null> = {
			currentUser: null,
			currentChannel: null,
			admin_SSO_access: null,
			auth_email: null,
			activityTime: null,
			storedTwoFactor: null,
		};
		const keys = Object.keys(adminKeysMap);
		keys.forEach(key => {
			const value = localStorage.getItem(key);
			if (value) {
				adminKeysMap[key] = value;
			} else {
				delete adminKeysMap[key];
			}
		});

		localStorage.clear();

		Object.entries(adminKeysMap).forEach(data => {
			const [key, val] = data;
			if (val) {
				localStorage.setItem(key, val);
			}
		});

		return;
	}

	localStorage.clear();
}