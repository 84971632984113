import { showAlert } from "@general-ui/alert/alert-service";
import { HvHostMap, parseHvPath } from "../connection/helpers";
import { SingleSignOnTypes, BrandliveEvent, SSOIntegrationProviderType } from "../types/working-model";
import { setStorageItem, setStorageObject } from "./local-storage";
import { getRandomValue } from "./utils";

//export const customSSOTypes = [SingleSignOnTypes.SAML, SingleSignOnTypes.OIDC, SingleSignOnTypes.oAuth];
export const socialSSOTypes: Array<string> = [SingleSignOnTypes.Google, SingleSignOnTypes.Facebook, SingleSignOnTypes.LinkedIn];

const includesSocial = (type: string): boolean => socialSSOTypes.includes(type);

export const hasAnyConfiguredCustomSSO = (event: BrandliveEvent | null): boolean => {
	const singleSignOn = event?.registration_settings?.singleSignOn;
	if (!singleSignOn) return false;
	return singleSignOn.isOn && !!singleSignOn.singleSignOns?.length && !singleSignOn.singleSignOns?.some(includesSocial);
};

export const isUsingCustomSSO = (event: BrandliveEvent | null): boolean => {
	const singleSignOn = event?.registration_settings?.singleSignOn;
	if (!singleSignOn) return false;
	return singleSignOn.isOn && !singleSignOn.singleSignOns?.some(includesSocial);
};

export const isCustomSignOnType = (type: string): boolean => {
	return !socialSSOTypes.includes(type);
};

export const getSSOLogoutUrl = (event: BrandliveEvent | null): string | null => {
	const publicOauthIntegration = event?.channel_settings?.oauth2Integration;

	if (!event || !isUsingCustomSSO(event) || !publicOauthIntegration?.logout_url) {
		return null;
	}

	return publicOauthIntegration.logout_url.replace('${EVENT_URL}', window.location.href);
};

export interface SsoConfig {
	user_pool_client_id: string;
	idp_name: string;
	integration_provider?: string;
}
export const handleSSOSignIn = (ssoConfig: SsoConfig, channel?: number, domain?: string) => () => {
	const client_id = ssoConfig.user_pool_client_id;

	// either domain or channel is required
	if (!channel && !domain) {
		showAlert({
			message: 'SSO failed',
			description: 'SSO is not enabled for this channel',
			duration: 10000,
			type: 'error',
		});
		return;
	}

	setStorageObject('sso_admin_auth', { client_id, channel, domain });

	const params = new URLSearchParams({
		redirect_uri: `${location.origin}/sso-redirect`,
		client_id: client_id,
		identity_provider: ssoConfig.idp_name,
		response_type: 'code',
	});

	let baseUrl = process.env.REACT_APP_COGNITO_URL;

	if (ssoConfig.integration_provider === SSOIntegrationProviderType.JACKSON) {
		const state = getRandomValue();
		setStorageItem(`sso_state`, state, 1);

		params.set('state', state);

		baseUrl = `${process.env.REACT_APP_SSO_HOST}/sso-redirect`;
	}

	window.location.href = `${baseUrl}?${params}`;
};

export const handleOauth2Siginin = (channel: number) => async () => {
	const state = getRandomValue();
	setStorageItem(`sso_state`, state, 1);
	setStorageObject('sso_admin_auth', { channel, oauth2: true });

	const params = new URLSearchParams({ redirect_uri: `${location.origin}/sso-redirect`, state });
	window.location.href = await parseHvPath(HvHostMap.authorization, `/e3-oauth-sign-in/${channel}?${params}`);
};

export const handleOauth2SigininByDomain = (uuid: string, domain: string) => async () => {
	const state = getRandomValue();
	setStorageItem(`sso_state`, state, 1);
	setStorageObject('sso_admin_auth', { domain, oauth2: true });

	const params = new URLSearchParams({ redirect_uri: `${location.origin}/sso-redirect`, state });
	window.location.href = await parseHvPath(HvHostMap.authorization, `/e3-oauth-sign-in-by-domain/${uuid}?${params}`);
};