import { TemplateClassNames } from "../types/template-layouts";
import { Session } from "../types/working-model";

export const getSessionHeaderStyleOverrides = (workingSession: Session | null, template: string, isDesktop: boolean): any => {
	// const sessionToUse = session ? session : workingSession;
	const backgroundColor = workingSession?.video?.customizations?.backgroundColor;
	// Apollo desktop has a gradient
	if (backgroundColor && template === TemplateClassNames.Apollo && isDesktop) {
		return { background: `linear-gradient(180deg, rgba(0, 0, 0, .3) 0%, rgba(var(--${backgroundColor}-rgb), 0)`, backgroundColor: `var(--${backgroundColor})` };
	}

	if (backgroundColor) {
		return { background: `var(--${backgroundColor})` };
	}

	return {};
};