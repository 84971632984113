import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { EIcon } from '../../../general-ui/icon/icon';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import { isValidEmail } from '../../../../utils/utils';
import { useAppDispatch, useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { Button } from '../../../general-ui/button/button';
import { OptionalComponent } from '../../../../utils/optional-component';
import { RegFieldsEnum, LanguagesAbbr } from 'types/working-model';
import { requestRegistrationPasswordReset } from 'store/actions/event/event-actions';
import { useLanguageParam } from 'components/live-event/utils';

interface IResetPassword {
	template: string;
	handleExitPasswordResetView?: () => void;
}

export default function ResetPassword({ template, handleExitPasswordResetView }: IResetPassword): JSX.Element {
	const dispatch = useAppDispatch();
	const language = useLanguageParam() as LanguagesAbbr;
	const { t } = useTranslate('homepage');
	const eventUuid = useTypedSelector(state => state.LiveEventReducer.eventBundle?.uuid);

	const [email, setEmail] = useState('');
	const [valid, setValid] = useState(Validation.normal);
	const [emailSent, setEmailSent] = useState(false);


	const setValue = useCallback((value: string) => {
		setEmail(value);
		setValid(isValidEmail(value) ? Validation.ok : Validation.error);
	}, []);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (isValidEmail(email) && eventUuid) {
			try {
				await dispatch(requestRegistrationPasswordReset(email, language, eventUuid));
				setEmailSent(true);
			} catch (e) {
				console.error(e);
			}
		}
	};

	return (
		<div className="registration-slider">
			{/* this component is dynamically rendered so we can hard code "active" class to make sure it's visible when rendered */}
			<div className="registration-panel-v2 fixed active">
				<form className="registration-form" onSubmit={handleSubmit}>
					<div className="registration-form-inner verification" onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
						<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", template)} tagName="h2">
							{t("homepage:registration.Reset Password", 'Reset Password')}
						</TypographyItem>
						<br />
						<OptionalComponent display={emailSent}>
							<TypographyItem tagName="p" className={classNames(template)}>
								{t("homepage:registration.Password Reset Request Sent", 'Password Reset Request Sent')} <br />
								{t("homepage:registration.Password Reset Request Sent.subtext", "If an account with the provided email exists, you'll receive instructions shortly to reset your password.")}
							</TypographyItem>
						</OptionalComponent>
						<OptionalComponent display={!emailSent}>
							<div className={classNames('evt-field-wrapper', template)} id={`registration-field-${RegFieldsEnum.email}`}>
								<TextInput
									id="reregister-email-field"
									placeholder={t(`homepage:registration.registration_fields.${RegFieldsEnum.email}`)}
									label={t(`homepage:registration.registration_fields.${RegFieldsEnum.email}`)}
									required
									onChange={e => setValue(e.target.value)}
									email
									valid={valid}
									value={email}
								/>
								<OptionalComponent display={valid === Validation.error}>
									<p className="registration-error" role="alert">{t("homepage:registration.Please enter a valid email address", 'Please enter a valid email address')}</p>
								</OptionalComponent>						
							</div>
						</OptionalComponent>
					</div>
					<div className="registration-actions secondary">
						<Button
							isRound={true}
							classButton={classNames('registration-button secondary')}
							template={template}
							onClick={handleExitPasswordResetView}
							typeBtn="button"
						>
							<EIcon name="keyboard-arrow-left" />
						</Button>
						<div>
							<Button
								classButton={classNames('registration-button primary')}
								template={template}
								typeBtn="submit"
								isDisabled={!email || valid === Validation.error}
							>
								{emailSent 
									? t("homepage:registration.Resend", 'Resend')
									: t("homepage:registration.Reset Password", 'Reset Password')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
