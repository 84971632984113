import { PropsWithChildren, forwardRef } from 'react';
import classNames from 'classnames';

import { useReadonlyMode } from '../../../shared/use-readonly-mode';

interface Props {
	isSession?: boolean;
	className?: string;
	divProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
	allowException?: boolean;
	checkScroll?: boolean;
	trackDataScroll?: boolean;
}

const PanelBody = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
	const isReadOnly = useReadonlyMode(props.isSession);

	return (
		<div
			{...props.divProps}
			className={classNames(props.className, "panel-body", { 'read-only': isReadOnly && !props.allowException })}
			ref={ref}
		>
			{props.children}
		</div>
	);
});

export default PanelBody;
