import { PropsWithChildren, useMemo, useRef, useState } from "react";
import classNames from "classnames";

import EditSectionColorsModal, { EContentColorOverrideNames } from "@general-ui/edit-section-colors/edit-section-colors-modal";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { TColorVariantKeys } from "types/theme-packs";
import { useGetColorOptionsFromColor } from "utils/colors/use-get-color-options-from-color";
import { parseColor } from "utils/document-head";
import useClickAwayListener from "utils/use-click-away-listener";

import "./custom-color-override-picker.scss";

type ICustomColorOverridePickerProps = PropsWithChildren<{
	onFinish?: (themeMode: string, colorVariableName: string) => void;
	contentOverrideName?: EContentColorOverrideNames;
	defaultColorOverride?: string;
}>;

const CustomColorOverridePicker = ({
	children,
	contentOverrideName,
	onFinish,
	defaultColorOverride,
}: ICustomColorOverridePickerProps) => {

	const wrapperRef = useRef<HTMLDivElement>(null);
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [colorsOpen, setColorsOpen] = useState(false);

	useClickAwayListener({
		ref: wrapperRef,
		open: showColorPicker,
		onClickAway: () => {
			setShowColorPicker(false);
		},
		onEscape: () => {
			setShowColorPicker(false);
		},
		onTab: () => {
			setShowColorPicker(false);
		},
	});

	const handleFinish = (themeMode: string, color: string) => {
		setShowColorPicker(false);
		onFinish?.(themeMode, color);
	};

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const colorPalette = useGetColorOptionsFromColor(
		workingEvent?.settings.design.colors,
		workingEvent?.settings.design.color_theme
	);

	const currentColor: [string, number] | undefined = useMemo(() => {
		if (defaultColorOverride) {
			const _currentColor = colorPalette?.[(defaultColorOverride as TColorVariantKeys)?.split('-')[0] as TColorVariantKeys];
			if (_currentColor && Array.isArray(_currentColor) && _currentColor.length === 2) {
				return _currentColor;
			}
		}

		return ['#444', 1];
	}, [defaultColorOverride, colorPalette]);

	return (
		<>
			<div
				ref={wrapperRef}
				className={classNames("custom-color-picker-wrapper", { 'color-picker-open': showColorPicker })}
				onClick={() => setShowColorPicker(true)}
				tabIndex={1}
				onFocus={() => setShowColorPicker(true)}
				onBlur={() => setShowColorPicker(false)}
			>
				<>{children}</>
				<div
					className={classNames("color-picker-popup", { 'color-picker-open': showColorPicker })}
				>
					<div className="color-picker-popup-btn-wrapper">
						<button
							className="small round color-display"
							style={{
								backgroundColor: currentColor ? parseColor(currentColor) : '',
							}}
							onClick={() => setColorsOpen(true)}
						/>
					</div>
				</div>
			</div>

			<EditSectionColorsModal
				open={colorsOpen}
				onClose={() => {
					setColorsOpen(false);
					setShowColorPicker(false);
				}}
				onFinish={handleFinish}
				title={contentOverrideName || EContentColorOverrideNames.background}
			/>
		</>
	);
};

export default CustomColorOverridePicker;
