import { publicKey } from './public-key';
import { stringToArrayBuffer } from '../../utils/utils';

type hidden = 'hidden' | 'msHidden' | 'webkitHidden';

export const getVisibilityListeners = (): [hidden, string] => {
	let hidden: hidden = "hidden";
	let visibilityChange = "visibilitychange";

	if (typeof document.msHidden !== "undefined") {
		hidden = "msHidden";
		visibilityChange = "msvisibilitychange";
	} else if (typeof document.webkitHidden !== "undefined") {
		hidden = "webkitHidden";
		visibilityChange = "webkitvisibilitychange";
	}

	return [hidden, visibilityChange];
};

export const verifyMessageSignature = async (signature: string, message: any) => {
	const arrayKey = stringToArrayBuffer(atob(publicKey));
	const key = await crypto.subtle.importKey('spki', arrayKey, { name: 'RSASSA-PKCS1-v1_5', hash: 'SHA-256' }, false, ['verify']);

	const arraySignature = stringToArrayBuffer(atob(signature));

	const enc = new TextEncoder();
	const encodedMessage = enc.encode(JSON.stringify(message));
	const ver = await window.crypto.subtle.verify('RSASSA-PKCS1-v1_5', key, arraySignature, encodedMessage);
	return ver;
};