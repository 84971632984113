import { useEffect } from "react";

interface IUsePositionOnScroll {
	setPositions: () => void;
	show: boolean;
	tooltipContainerRef: React.RefObject<HTMLDivElement>;
}

export const usePositionOnScroll = ({
	setPositions,
	show,
	tooltipContainerRef,
}: IUsePositionOnScroll) => {

	useEffect(() => {
		const _tooltipContainerRef = tooltipContainerRef.current;
		if (!_tooltipContainerRef) return;

		// this helps us remove the tooltip when scrolling and the mouse is no longer over the tooltip
		// Note: even though this is not being throttled or debounced, it doesn't fire that much (about 2 times on fast scrolling)
		// because we are immediately removing the event listener when the tooltip is hidden.
		// In fact, the faster someone scrolls, the fewer times this will fire, and the slower someone scrolls, the more
		// time we have to check and prevent any jankiness
		const handleChange = () => {
			// detect if mouse is still over the tooltip
			requestAnimationFrame(setPositions);
		};

		if (show) {
			window.addEventListener('scroll', handleChange, true);
		} else {
			// stop listening for scroll events to avoid memory leaks and improve efficiency
			window.removeEventListener('scroll', handleChange, true);
		}

		return () => {
			window.removeEventListener('scroll', handleChange, true);
		};
	}, [setPositions, show, tooltipContainerRef]);

};
