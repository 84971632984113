import { useMemo, useState } from "react";

import ModalComponent from "../../../../../../../general-ui/modal/modal";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { updateTranslateKey } from "../../../../../../../../utils/utils";
import { getHomepageDefaultLanguage } from "../../../../../../../../utils/get-language";
import { useParams } from "react-router-dom";
import { ParamsProps } from "../../../../../../../live-event/live-event";
import EditableHypertextModal from "../../../../../../../general-ui/editable-hyperlink-modal/editable-hyperlink-modal-wrapper";
import { useTranslate } from "../../../../../../../../i18n/useTranslationModules";
import { setRejectedPasscodeMessage } from "../../../../../../../../store/actions/admin/create-event";
import { MarkdownItem } from "../../../../../../../general-ui/typography-item/typography-item";
import Icon, { ICONS } from "../../../../../../../general-ui/icon";

const GatedCustomErrorMessage = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer?.workingEvent);

	const { language } = useParams() as ParamsProps;

	const [rejectedPasscodeMessageModal, setRejectedPasscodeMessageModal] = useState(false);
	const [workingRejectedPasscodeeMessage, setWorkingRejectedPasscodeeMessage] = useState<string>('');

	const { t } = useTranslate('homepage');

	const dispatch = useAppDispatch();

	const rejectedPasscodeMessage = useMemo(() => {
		if (workingEvent?.registration_settings?.customRejectedPasscodeMessage?.base) {
			return workingEvent?.registration_settings?.customRejectedPasscodeMessage?.[language] as string ?? workingEvent?.registration_settings?.customRejectedPasscodeMessage?.base;
		}

		return t('homepage:registration.gatingErrorMessage');
	}, [language, t, workingEvent?.registration_settings]);

	const toggleOpenRejectedPasscodeMessageModal = () => {
		setRejectedPasscodeMessageModal(prev => !prev);
	};

	const saveRejectedPasscodeMessage = () => {
		dispatch(setRejectedPasscodeMessage(
			updateTranslateKey({
				translateString: workingEvent?.registration_settings?.customRejectedPasscodeMessage ?? { base: 'Passcode', changed: '' },
				input: workingRejectedPasscodeeMessage,
				language,
				baseLanguage: getHomepageDefaultLanguage()
			})
		));

		toggleOpenRejectedPasscodeMessageModal();
	};

	const onEditableHypertextChange = (text: string) => setWorkingRejectedPasscodeeMessage(text);

	return (
		<>
			<p className="advanced-settings-text">Customize Error Message</p>
			<div className="settings-card">
				<label className="pre-line">
					{/* empty array so both a and p tags are unwrapped and put into the label to look like normal text */}
					<MarkdownItem allowedElements={[]} unwrapDisallowed>
						{rejectedPasscodeMessage}
					</MarkdownItem>
				</label>
				<button className="no-style no-margin no-padding options" onClick={toggleOpenRejectedPasscodeMessageModal}>
					<Icon name={ICONS.EDIT} size={20} color="cyan" />
				</button>
			</div>

			<ModalComponent
				open={rejectedPasscodeMessageModal}
				onRequestClose={toggleOpenRejectedPasscodeMessageModal}
				title="Rejected Blocked Domain Message"
				cancellable={true}
				className="rejected-passcode-message"
				closeable={true}
				footer={(
					<>
						<button onClick={toggleOpenRejectedPasscodeMessageModal}>Cancel</button>
						<button onClick={saveRejectedPasscodeMessage} className="lemonade" disabled={!(workingRejectedPasscodeeMessage ?? '').trim().length}>
							Save
						</button>
					</>
				)}
			>
				<EditableHypertextModal
					value={rejectedPasscodeMessage}
					className="rejected-passcode-message"
					onChange={onEditableHypertextChange}
					open={rejectedPasscodeMessageModal}
					onSave={saveRejectedPasscodeMessage}
					onClose={toggleOpenRejectedPasscodeMessageModal}
					disableSave={!(workingRejectedPasscodeeMessage ?? '').trim().length}
				/>
			</ModalComponent>
		</>
	);
};

export default GatedCustomErrorMessage;
