import WaitingIndicator from "../general-ui/waiting-indicator/waiting-indicator";

export function PageLoader(): JSX.Element {
	return (
		<div style={{
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: 'var(--backgroundColor)',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		}}>
			<WaitingIndicator />
		</div>
	);
}