import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { isObject } from "underscore";
import { CreatePageModule } from "../../../../../../../connection/page-modules";
import { SearchResults, useContentSearch } from "../../../../../../../connection/sessions-panel/search";
import { eFilters, ESort } from "../../../../../../../connection/sessions-panel/types";
import { addSessionPageModule, updateGroupModules } from "../../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { EngageModule, PageModuleEngageMap, SessionPanelLayoutsTypes, SurveyType, Templates } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { mergeUniqueById } from "../../../../../../../utils/utils";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import { OptionalComponent } from "../../../../../../general-ui/animated/optional-component";
import StaggerChildren from "../../../../../../general-ui/animated/stagger-children";
import Icon, { ICONS } from "../../../../../../general-ui/icon";
import SmallSelect from "../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { engageItemsConfig } from "../../../../../../live-event/session/engage-section/utils/engage.utils";
import { useFinishNavigate, usePageModuleGroup } from "../../panel/hooks/panel.hooks";
import { SessionPanelMap } from "../../panel/session-panel-route-map";
import { appendNewPageModulesToGroups } from "../../panel/utils/module-group.utils";
import { PageModuleGroup } from "../../panel/utils/prototypes/page-module-group-modules";
import { loadFullEngageItems } from "../utils/engage-create.utils";
import EngageCard from "./engage-card";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const filterOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Surveys', value: eFilters.survey },
	{ label: 'Questions', value: eFilters.question_prompt },
	{ label: 'Polls', value: eFilters.poll },
	{ label: 'Quizzes', value: eFilters.quiz },
	// { label: 'Feedback', value: eFilters.feedback } TODO when feedback is added
];

export default function AddEngagement(): JSX.Element {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const [awaitingInitialLoad, setAwaitingInitialLoad] = useState(true);
	const [total, setTotal] = useState(0);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const [userFilter, setUserFilter] = useState<eFilters | 'all'>('all');
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedItems, setSelectedItems] = useState<number[]>([]);
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(true);
	const [saving, setSaving] = useState(false);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const loadMoreTimeout = useRef<NodeJS.Timeout | null>(null);
	const { language } = useParams<{ page_module?: string, language: string }>();
	const pageModuleGroup = usePageModuleGroup();
	const dispatch = useDispatch();
	const finish = useFinishNavigate();
	const adminPath = useGetAdminUrl();
	const [data, setData] = useState<EngageModule[]>([]);
	const [optionsOpen, setOptionsOpen] = useState(false);

	const handleError = useCallback((e: string) => {
		console.error(e);
		showAlert({
			message: e,
			type: "error",
		});
	}, []);

	const {
		load,
		search,
		loading,
		clear,
		setSort,
		next,
		fullItems,
		filter
	} = useContentSearch<EngageModule>('engage', handleError);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	// append new items to end of list
	const handleMoreResults = useCallback((res: SearchResults<EngageModule> | undefined) => {
		if (isObject(res) && res.results) {
			setTotal(res.total ?? 0);
			// This curries prev into mergeUniqueById
			setData(mergeUniqueById<EngageModule>('module_id', res.results ?? []));
		}
	}, []);

	// replace list with new items
	const handleNewResults = useCallback((res: SearchResults<EngageModule> | undefined) => {
		if (isObject(res)) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}

			setAwaitingInitialLoad(false);
			setTotal(res.total ?? 0);
			setData(res.results ?? []);
		}
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		if (searchTerm && searchTerm.length >= 3) {
			// apply new search term and replace existing results with new
			search(searchTerm).then(handleNewResults);
		} else {
			// clear search term and replace existing results with new
			clear(true).then(handleNewResults);
		}
	}, [clear, handleNewResults, search, searchTerm]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleFilter = useCallback((value: eFilters | 'all') => {
		setUserFilter(value);
	}, []);

	useEffect(() => {
		const moreAvailable = total > data.length;

		if (hasScrolledToBottom && moreAvailable && !loading) {
			// debounce send in case user scrolls like crazy or we get multiple triggers
			if (loadMoreTimeout.current) {
				clearTimeout(loadMoreTimeout.current);
			}

			loadMoreTimeout.current = setTimeout(() => {
				loadMoreTimeout.current = null;
				next().then(handleMoreResults);
			}, 200);
		}
	}, [hasScrolledToBottom, total, data.length, handleMoreResults, next, loading]);

	useEffect(() => {
		// apply new value and replace list with new results
		filter(userFilter === 'all' ? undefined : userFilter).then(handleNewResults);
	}, [handleNewResults, userFilter, filter]);

	useEffect(() => {
		// apply new value and replace list with new results
		setSort(sortOrder).then(handleNewResults);
	}, [handleNewResults, setSort, sortOrder]);

	useEffect(() => {
		setAwaitingInitialLoad(true);
		// initialize list
		load().then(handleNewResults);
	}, [handleNewResults, load]);


	const handleDone = async () => {
		if (!token || !workingEvent || !workingSession || !pageModuleGroup) return;

		try {
			setSaving(true);

			// the search function only loads minimal data for these items
			const [surveys, questions, itemMap] = await loadFullEngageItems(data, selectedItems, fullItems);
			const newModules: number[] = [];

			// iterate over IDs of selected items
			for (const module_id of selectedItems) {
				const item = itemMap.get(module_id);
				if (item) {
					// create a new page module for each selected item
					const mod = await CreatePageModule(token, {
						type: PageModuleEngageMap[item.type],
						eventName: workingEvent.name,
						template: Templates.Limelight,
						languages: workingSession.languages,
						baseLanguage: workingSession.default_language
					});

					// set the selected items as the content of this new page module
					mod.content_modules = [item.id];
					mod.modules = item.type === 'question_prompt' ?
						questions.filter(question => question.question_prompt === item.id) :
						surveys.filter(survey => survey.survey === item.id);

					// append the page module to the session
					dispatch(addSessionPageModule(mod));

					// hold the id of the new page module for insertion into the page module group
					newModules.push(mod.id as number);
				}
			}
			// add new module IDs to this group
			const newModuleGroups = (workingSession.module_grouping as PageModuleGroup[])
				.map(appendNewPageModulesToGroups(pageModuleGroup.uuid, newModules));

			// update redux
			dispatch(updateGroupModules(newModuleGroups));

			// back to engage panel
			finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Problem saving content",
				description: "We ran into an issue saving this content. Please wait and try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	const handleCheckboxChange = useCallback((module_id, isOn) => {
		if (isOn) {
			setSelectedItems(prev => [...prev, module_id]);
		} else {
			setSelectedItems(prev => prev.filter(_module_id => _module_id !== module_id));
		}
	}, []);

	const toggleFilters = useCallback(() => {
		setOptionsOpen(prev => !prev);
	}, []);

	useEffect(() => {
		if (!optionsOpen) {
			setUserFilter('all');
			setSortOrder(ESort.dateDesc);
		}
	}, [optionsOpen]);

	return (
		<div className={classNames("session-panel engagement-list", { 'has-selections': selectedItems.length > 0 })} ref={containerRef}>
			<div className={classNames("session-panel-header-options openable", { open: optionsOpen })}>
				<div className="grid search-row">
					<TextInput
						defaultValue={''}
						onChange={handleSearchChange}
						onBlur={handleSearch}
						onEnterKey={handleSearch}
						placeholder="Search..."
						className="small search-area"
						onClear={() => {
							setSearchTerm('');
						}}
						isAdmin
					/>
					<button onClick={toggleFilters} className="round no-style no-padding no-margin button-area">
						<Icon name={ICONS.FILTER} size={12} color="" />
					</button>
				</div>

				<OptionalComponent display={optionsOpen} usingHeight={32 + 8}>
					<div className="grid col-2">
						<SmallSelect
							options={sortOptions}
							selected={sortOrder || ''}
							onChange={handleSort}
							className="sort-area"
						/>
						<SmallSelect
							options={filterOptions}
							selected={userFilter}
							onChange={handleFilter}
							className="filter-area"
						/>
					</div>
				</OptionalComponent>

			</div>

			<label className="session-panel-section-label">Library</label>

			{data.length ? (
				<StaggerChildren
					onScrolledToBottom={() => setHasScrolledToBottom(true)}
					onScrolledUpFromBottom={() => setHasScrolledToBottom(false)}
					ref={scrollRef}
					footer={loading ? <WaitingIndicator fillSpace={true} minHeight={36} transparentFill={true} /> : undefined}
					className={classNames("session-panel-content")}>
					{data?.map((survey) => {
						const { name, type, module_id } = survey;
						const { tagIcon, name: tagName } = engageItemsConfig[type as SurveyType];

						return (
							<EngageCard
								key={module_id}
								id={module_id}
								type={tagName}
								icon={tagIcon}
								title={name[language] as string ?? name.base ?? ''}
								checked={selectedItems.includes(module_id)}
								onCheckboxChange={handleCheckboxChange}
							/>
						);
					})}
				</StaggerChildren>
			) : (
				<div className="session-panel-no-results">
					<section>{awaitingInitialLoad ? <WaitingIndicator /> : 'No results'}</section>
				</div>
			)}

			<div className="session-panel-footer">
				<button onClick={() => setSelectedItems([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleDone}
					disabled={saving}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>
		</div>
	);
}