// const resolutionMap = {
// 	minute: [60 * 1000, 16],
// 	second: [1000, 19],
// 	millisecond: [1, 24]
// }

// This class is used to cache timestamps to avoid creating new Date objects for the same timestamp
// creating new Date objects is expensive and can cause performance issues
export class MinuteTimestamps {
	times: Map<number | string, Date> = new Map();
	timeStrings: Map<number | string, string> = new Map();
	datetimeStrings: Map<number | string, string> = new Map();

	get(ts: number | string) {
		const normalized = (() => {
			if (typeof ts === 'number') {
				const precision = 1000 * 60;
				return Math.floor(ts / precision) * precision;
			} else {
				const precision = 16;
				return ts.substring(0, precision) + ':00.000Z';
			}
		})();

		const existing = this.times.get(normalized);
		if (!existing) {
			const date = new Date(normalized);
			this.times.set(normalized, date);
			return date;
		} else {
			return existing;
		}
	}

	getPrettyTime(ts: number | string) {
		const normalized = (() => {
			if (typeof ts === 'number') {
				const precision = 1000 * 60;
				return Math.floor(ts / precision) * precision;
			} else {
				const precision = 16;
				return ts.substring(0, precision) + ':00.000Z';
			}
		})();

		const existing = this.timeStrings.get(normalized);
		if (!existing) {
			const date = new Date(normalized);
			const timeStr = date.toLocaleTimeString(navigator.language, { hour: 'numeric', minute: '2-digit' });
			this.timeStrings.set(normalized, timeStr);
			return timeStr;
		} else {
			return existing;
		}
	}

	getPrettyDateTime(ts: number | string) {
		const normalized = (() => {
			if (typeof ts === 'number') {
				const precision = 1000 * 60;
				return Math.floor(ts / precision) * precision;
			} else {
				const precision = 16;
				return ts.substring(0, precision) + ':00.000Z';
			}
		})();

		const existing = this.datetimeStrings.get(normalized);
		if (!existing) {
			const date = new Date(normalized);
			const timeStr = date.toLocaleString(navigator.language, {
				month: 'short',
				day: 'numeric',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit'
			});
			this.datetimeStrings.set(normalized, timeStr);
			return timeStr;
		} else {
			return existing;
		}
	}
}

const mTimestamps = new MinuteTimestamps();
export default mTimestamps;