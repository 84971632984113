const hexToRGB = (hex: string) => {
	let color = hex;
	if (!hex.startsWith('#')) {
		color = '#' + hex;
	}

	if (!/^#{1}([A-Za-z0-9]{3}|[A-Za-z0-9]{6})$/.test(color)) {
		return null;
	}

	// handle 3 letter hex: if 3 letter hex, duplicate letters so we get a 6 letter hex
	if (color.length === 4) {
		color = color[0] + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
	}
	const r = parseInt(color.substring(1, 3), 16);
	const g = parseInt(color.substring(3, 5), 16);
	const b = parseInt(color.substring(5, 7), 16);
	const rgb = `rgb(${r}, ${g}, ${b})`;

	return rgb;
};

export default hexToRGB;