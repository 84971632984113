import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import './sliding-menu.scss';

export type SlidingMenuOption<T = any> = { //returned in passed `callback` prop
	label: string,
	data?: T,
	disabled?: boolean;
}
export type Props = {
	options: SlidingMenuOption[];
	selectedOption: SlidingMenuOption | null;
	callback: (tab: SlidingMenuOption) => void;
	className?: string;
	fullWidthDivider?: boolean;
}
export default function SlidingMenu(props: Props) {
	const {
		fullWidthDivider = false,
		options,
		selectedOption,
		callback,
	} = props;

	const [underlineStyle, setUnderlineStyle] = useState({ width: 0, left: 0 });
	const wrapper = useRef<HTMLDivElement>(null);
	const underline = useRef<HTMLDivElement>(null);
	const optionsWrapper = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!wrapper.current || !optionsWrapper.current) {
			return;
		}
		const selectedIndex = options.findIndex((option) => option.label === selectedOption?.label);
		const selectedElement = optionsWrapper.current.querySelector<HTMLElement>('.selected');
		if (![-1, 0].includes(selectedIndex)) {
			wrapper.current.classList.add('animate');
		}
		if (selectedElement) {
			setUnderlineStyle({
				width: selectedElement.offsetWidth ?? 0,
				left: selectedElement.offsetLeft ?? 0,
			});
			if (optionsWrapper.current.scrollWidth > wrapper.current.offsetWidth) {
				optionsWrapper.current.scroll({
					left: (selectedElement.offsetLeft + (selectedElement.offsetWidth / 2) - wrapper.current.offsetWidth) + (wrapper.current.offsetWidth / 2)
					, top: underline.current?.offsetHeight
					, behavior: 'smooth',
				});
			}
		}
	}, [options, selectedOption, optionsWrapper.current?.offsetWidth]);
	return (
		<div className={classNames('sliding-menu-wrapper', 'width-calc-full', props.className)} ref={wrapper}>
			<div className='sliding-menu-options position-relative' ref={optionsWrapper}>
				{options.map((option: SlidingMenuOption, index: number) => {
					return (
						<div
							key={index}
							onClick={() => callback(option)}
							className={classNames(
								'sliding-menu-option',
								'font-weight-heavy',
								'white-space-nowrap',
								'padding-medium',
								option.disabled || options.length === 1 ? 'disabled' : '',
								{ selected: selectedOption ? selectedOption.label === option.label : index === 0 },
							)}
						>
							<span className='text'>{option.label}</span>
							{/* REMOVING 10/10/2023 based on comment from will but may return */}
							{/* {option.data?.tabCounter} */}
						</div>
					);
				})}
				<div className='sliding-menu-underline position-absolute' style={underlineStyle} ref={underline}></div>
			</div>
			{fullWidthDivider && <div className='sliding-menu-divider' />}
		</div>
	);
}