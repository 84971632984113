import { useState } from 'react';

import ModalComponent from '../../../../../general-ui/modal/modal';
import { setSessionInternalDescriptor } from '../../../../../../store/actions/admin/create-event/session';
import { useAppDispatch, useTypedSelector } from '../../../../../../store/reducers/use-typed-selector';
import TextInput from '../../../../../general-ui/text-input/text';

interface Props {
	open: boolean;
	onClose: () => void;
}

export default function SessionInternalDescriptorModal({ open, onClose }: Props): JSX.Element {
	const dispatch = useAppDispatch();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const [internalDescriptor, setInternalDescriptor] = useState(workingSession?.internal_descriptor || '');

	const save = () => {
		dispatch(setSessionInternalDescriptor(internalDescriptor));
		onClose();
	};

	return (
		<ModalComponent
			title="Session Metadata"
			className="session-internal-descriptor-modal"
			footer={(
				<>
					<div>
						<button onClick={onClose}>Cancel</button>
						<button onClick={save} className="lemonade">
							Save
						</button>
					</div>
				</>
			)}
			cancellable
			closeable={false}
			open={open}
			onRequestClose={close}
		>
			<div>
				<TextInput
					onChange={e => setInternalDescriptor(e.target.value)}
					defaultValue={internalDescriptor}
					value={internalDescriptor}
				/>
			</div>
		</ModalComponent>
	);
}