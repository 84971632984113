import classNames from "classnames";
import { useState } from "react";

import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import hexToRGBA from "../../../../../../utils/hexToRGBA";
import { reactionIconMap } from "../../../../../../utils/reactions";
import ReactionConfigurationModal from "./reaction-configuration-modal";

import './reaction-segment.scss';

function ReactionSegment(): JSX.Element {
	const reactionSettings = useTypedSelector(state => state.CreateSessionReducer.workingSession?.reaction_settings);
	const [showReactionConfigurationModal, setShowReactionConfigurationModal] = useState(false);

	return (
		<>
			<div className="field-group">
				<div className="reactions-container">
					{reactionSettings && reactionSettings.reactions.filter(r => r.enabled).map(r => (
						<div
							key={r.name}
							className={classNames('reaction-container', r.opacity ? 'background' : '')}
							style={{ background: hexToRGBA(r.color, r.opacity) }}
						>
							<img className="reaction" src={r.icon ? reactionIconMap[r.icon] : r.url} alt={r.description} />
						</div>
					))}
					<div className="reaction add-reaction" onClick={() => setShowReactionConfigurationModal(true)}>
						+
					</div>
				</div>
			</div>

			<ReactionConfigurationModal
				isOpen={showReactionConfigurationModal}
				onClose={() => setShowReactionConfigurationModal(false)}
				onSave={() => null}
			/>
		</>
	);
}


export default ReactionSegment;

