import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { difference } from 'underscore';

import { Channel, AuthType, SingleSignOnTypes, EPermissions, FeatureFlagsEnum } from "../../../types/working-model";
import { PATHNAMES } from "../../../utils/admin-routing-utils";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { ReactComponent as BrandliveLogo } from '../../../images/BrandLive_Logo.svg';
import Search from "../../general-ui/search-bar/search";
import { setSelectedEventGroup } from '../../../store/actions/admin/events';
import { resetTemplateEvents } from "../../../store/actions/admin/create-event";
import { setChangingChannels, setUser } from "../../../store/actions/authentication";
import { handleSSOSignIn, handleOauth2Siginin } from "../../../utils/sso-utils";
import { SelectChannel } from "../../../connection/auth";
import { showAlert } from '@general-ui/alert/alert-service';
import { resizedImage } from 'utils/utils';

import './admin-side-nav.scss';

const ChannelAvatar = ({ channel }: { channel: Channel }): JSX.Element => {
	const avatarSrc = channel.logos.color || channel.logos.white || channel.logos.gray;

	return (
		<div className="channel-avatar" style={{ backgroundColor: channel.colors.primary || '#333333' }}>
			{avatarSrc
				? <img src={resizedImage(avatarSrc, { height: 64, width: 64, fit: 'contain' })} alt={channel.name} />
				: <h5>{channel.name[0]}</h5>}
		</div>
	);
};

export default function AdminSideNav(): JSX.Element {
	const dispatch = useDispatch();

	const user = useTypedSelector(state => state.AuthReducer.user);
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const eventsLoading = useTypedSelector(state => state.EventsReducer.eventsLoading);
	const changingChannels = useTypedSelector(state => state.AuthReducer.changingChannels);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const domainBased = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.domain_based_sso]);
	const unified = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.unified_auth]);
	const useDomainBased = domainBased || unified;

	const [clicked, setClicked] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState('');

	const activeChannel = useMemo(() => user?.active_channel, [user]);

	const selectChannel = async (destinationChannel: Channel) => {
		if (changingChannels || eventsLoading || !user) return;

		// Do nothing if already on the clicked channel
		const channelId = destinationChannel.channel;
		if (channelId === user?.active_channel) return;

		const { auth_integration, auth_type } = destinationChannel;
		const isAdminSsoEnabled = !!user.channels_using_sso?.includes(channelId);

		// Admin should use SSO
		if (!useDomainBased && isAdminSsoEnabled && auth_type?.includes(AuthType.sso)) {
			// OAuth2
			if (auth_integration?.type === SingleSignOnTypes.OAUTH2) {
				return handleOauth2Siginin(channelId)();
			}

			// SAML/OIDC
			if (auth_integration?.type === SingleSignOnTypes.OIDC || auth_integration?.type === SingleSignOnTypes.SAML) {
				return handleSSOSignIn(auth_integration, channelId)();
			}
		}

		// Invalid state but is technically possible
		if (!useDomainBased && !auth_type?.includes(AuthType.email_password)) return;

		const isOnlyPresenterOnChannel = difference(
			user?.channels[destinationChannel.channel],
			[EPermissions.Presenter]
		).length === 0;

		if (isOnlyPresenterOnChannel) {

			showAlert({
				message: "Presenter only",
				description: `You have presenter only access in this channel, we’ve opened Greenroom in a new tab.`,
				extraContent: <div className="alert-buttons">
					<a
						className="theme-btn link-btn"
						href={process.env.REACT_APP_GREENROOM_FRONTEND_URL}
						rel="noreferrer"
						target={'_blank'}
					>
						Click here to go to Greenroom
					</a>
				</div>,
				duration: 10000
			});

			return;
		}

		// Email / password path
		if (token) {
			setClicked(true);
			dispatch(setChangingChannels(true));
			const updatedToken = await SelectChannel(token, channelId);
			dispatch(setUser(updatedToken));
			dispatch(setSelectedEventGroup(''));
			dispatch(setChangingChannels(false));
			dispatch(resetTemplateEvents());
			location.reload();
		}
	};

	return user ? (
		<div className={`admin-side-nav ${clicked ? "clicked" : ''}`}>
			<div className="admin-side-nav-container">
				<Link to={PATHNAMES.Events}>
					<BrandliveLogo className="brandlive-icon" />
				</Link>
				<div className="channels-wrapper">
					{channels.filter(channel => channel.name.toLowerCase().includes(searchCriteria.toLowerCase())).map(channel => (
						<div
							id={channel.uuid}
							title={channel.name}
							className={classNames('channel-wrapper', { active: activeChannel === channel.channel })}
							key={channel.uuid}
							onClick={() => user.active_channel !== channel.channel ? selectChannel(channel) : null}
						>
							<div className="colored-border">
								<div className="transparent-container">
									<ChannelAvatar channel={channel} />
								</div>
							</div>

							<div className="channel-name">
								{channel.name}
							</div>
						</div>
					))}
				</div>
				<div className="channel-search-container">
					<Search
						onSearch={value => setSearchCriteria(value)}
						onClear={() => setSearchCriteria('')}
						onChange={value => setSearchCriteria(value)}
						autoFocus
						placeholder="Search channels"
					/>
				</div>
			</div>
		</div>
	) : (
		<WaitingIndicator minHeight={'90vh'} fillSpace={true} />
	);
}