import { UpdateFeatureFlags } from '../../connection/feature-flags';
import { Action, AsyncAction } from '../../types/actions';
import { FeatureFlagMap, FeatureFlagsEnum, ChannelFeatures } from '../../types/working-model';

export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";
export function setFeatureFlags(featureFlags: FeatureFlagMap): Action {
	return {
		type: SET_FEATURE_FLAGS,
		payload: featureFlags
	};
}

export const UPDATE_FEATURE_FLAGS = "UPDATE_FEATURE_FLAGS";
export function updateFeatureFlags(featureFlag: FeatureFlagsEnum, value: boolean, token: string): AsyncAction {
	return {
		type: UPDATE_FEATURE_FLAGS,
		promise: UpdateFeatureFlags(featureFlag, value, token)
	};
}

export const TOGGLE_FEATURE_FLAG = "TOGGLE_FEATURE_FLAG";
export function toggleFeatureFlag(featureFlag: FeatureFlagsEnum) {
	return {
		type: TOGGLE_FEATURE_FLAG,
		payload: featureFlag
	};
}

export const SET_CHANNEL_FEATURES = "SET_CHANNEL_FEATURES";
export function setChannelFeatures(channelFlags: ChannelFeatures) {
	return {
		type: SET_CHANNEL_FEATURES,
		payload: channelFlags
	};
}