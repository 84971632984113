import { SocialLink } from "../../../types/working-model";
import { parseUri } from "../../../utils/utils";
import Icon from '../icon';

export const renderSocialLinks = (links: SocialLink[], classOverrides?: string): JSX.Element | null => {
	if (!links.length) return null;
	return <ul className="social-links">
		{links.map(({ url, platform }) => {
			//if the url is just a space dont display (spaces are used in the profile editing page and may carry over accidentally)
			if (!url.trim()) return null;
			return <li key={platform + url}>
				<a href={parseUri(url.trim())} target="_blank" rel="noreferrer">
					<Icon name={platform} color={classOverrides ?? ""} size={18} />
				</a>
			</li>;
		})}
	</ul>;
};
