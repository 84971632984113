import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import { extrasSpeakersItems } from "../../empty-state-panel/constants/empty-panel";
import EmptyStatePanel from "../../empty-state-panel/empty-state-panel";

import './add-menu-dropdown.scss';

const AddMenuDropdown = () => {
	return (
		<EmptyStatePanel
			title=""
			description=""
			dropDownItems={extrasSpeakersItems}
			className="panel-card speaker-card speaker-list-add-more"
			buttonChildren={<div className="custom-add-btn"><Icon name={ICONS.ADD} color={COLORS.CYAN} size={13} /> <span>Add</span></div>}
			isArrow={false}
			renderCustomIcon={() => <></>}
		/>
	);
};

export default AddMenuDropdown;
