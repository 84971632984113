import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { Document } from '../../../types/working-model';
import {
	ADD_DOCUMENT,
	LOAD_DOCUMENTS,
	UPDATE_DOCUMENT,
} from '../../actions/admin/documents';

export interface DocumentsState {
	documents: Document[];
	loadingDocuments: boolean;
	errorLoadingDocuments: string;
}

const initialState: DocumentsState = {
	documents: [],
	errorLoadingDocuments: '',
	loadingDocuments: false,
};

export default function DocumentsReducer(
	state: DocumentsState = initialState,
	action: Action
): DocumentsState {
	switch (action.type) {
		case LOAD_DOCUMENTS: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingDocuments: true }),
				failure: (state) => ({
					...state,
					errorLoadingDocuments: 'Could not load documents.',
				}),
				success: (state) => ({
					...state,
					documents: action.payload,
					errorLoadingDocuments: '',
				}),
				finish: (state) => ({ ...state, loadingDocuments: false }),
			});
		}
		case ADD_DOCUMENT: {
			return {
				...state,
				documents: [...state.documents, action.payload],
			};
		}
		case UPDATE_DOCUMENT: {
			return {
				...state,
				documents: state.documents.map((document: Document) => {
					if (document.document === action.payload.document) {
						return action.payload;
					}

					return document;
				}),
			};
		}
		default:
			return state;
	}
}
