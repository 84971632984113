import classNames from 'classnames';
import { ReactNode } from 'react';
import { Validation } from '../text-input/text';
import '../../../scss/live-event/base/general-ui/field.scss';
import { EIcon } from '../../general-ui/icon/icon';

export interface FieldProps<T = unknown> {
	tooltip?: string | [string | (() => JSX.Element), string];
	name?: string;
	label?: string;
	required?: boolean;
	defaultValue?: T;
	placeholder?: string;
	valid?: Validation;
	errorMessage?: string;
	successMessage?: string;
}

export const Field = ({
	children,
	valid,
	errorMessage,
	successMessage,
}: FieldProps & { children: ReactNode }): JSX.Element => {
	return (
		<>
			{children}
			<FieldFooter
				valid={valid}
				errorMessage={errorMessage}
				successMessage={successMessage}
			/>
		</>
	);
};

interface FieldErrorProps {
	valid?: Validation;
	errorMessage?: string;
	successMessage?: string;
	className?: string;
}
export const FieldFooter = ({
	valid,
	errorMessage,
	successMessage,
	className,
}: FieldErrorProps): JSX.Element => {
	return (
		<>
			{errorMessage && valid === Validation.error ? (
				<div className={classNames('field-error', className)}>
					{errorMessage}
				</div>
			) : null}
			{successMessage && valid === Validation.ok ? (
				<div className={classNames('field-success', className)}>
					{successMessage}
				</div>
			) : null}{' '}
		</>
	);
};
interface LabelProps extends FieldProps {
	className?: string;
}

export const FieldLabel = ({
	label,
	required,
	className,
	tooltip,
}: LabelProps): JSX.Element | null => {
	const renderTooltip = (): JSX.Element => {
		if (typeof tooltip === "string") {
			return (
				<div className="evt-tooltip">
					<EIcon name="info-outline" size="small" />
					<span className="evt-tooltip-text">{tooltip}</span>
				</div>
			);
		} else {
			const [image, text] = tooltip || [];
			return (
				<div className="tooltip">
					{image ? (
						typeof image === "function" ? (
							image()
						) : (
							<img src={image} alt="tooltip" height="18px" width="auto" />
						)
					) : null}{" "}
					<span className="tooltip-text">{tooltip && text}</span>
				</div>
			);
		}
	};
	return label ? (
		<>
			<label
				className={classNames(
					"evt-field-label",
					{ "evt-field-label-required": required },
					className
				)}
			>
				{label}
			</label>
			{tooltip ? renderTooltip() : null}
		</>
	) : null;
};
