import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { PASSCODE_LIST_TYPE } from "../../../../types/working-model";
import RadioGroup from "../../../general-ui/radio-group/radio-group";
import { SelectOption } from "../../../general-ui/select/select";
import { WorkingPasscodeList } from "./passcode-list";

interface Props {
	setWorkingPasscodeList: React.Dispatch<React.SetStateAction<WorkingPasscodeList>>;
	workingPasscodeList: WorkingPasscodeList;
	isCustomGating?: boolean;
}

const passcodeListTypeOptions: SelectOption[] = [
	{
		label: 'Email only',
		value: PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(),
	},
	{
		label: 'Passcode only',
		value: PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(),
	},
	{
		label: 'Both email and passcode',
		value: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(),
	}
];

const passcodeListTypeOptionsFFMagicLink: SelectOption[] = [

	{
		label: 'Email only',
		value: PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(),
	},
];

export function UploadAudienceListRadios({ setWorkingPasscodeList, workingPasscodeList }: Props) {
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const options = featureFlags["streams.magic_links"]
		? [
			...passcodeListTypeOptions,
			{
				label: 'Email & Magic Link',
				value: PASSCODE_LIST_TYPE.MAGIC_LINKS.toString(),
			}
		]
		: passcodeListTypeOptions;

	function handlePasscodeListType(value: string | number) {
		switch (value) {
			case PASSCODE_LIST_TYPE.EMAILS_ONLY:
			case PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.EMAILS_ONLY,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.PASSCODES_ONLY:
			case PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.PASSCODES_ONLY,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES:
			case PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.MAGIC_LINKS:
			case PASSCODE_LIST_TYPE.MAGIC_LINKS.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.MAGIC_LINKS,
				}));
				break;
			}
		}
	}

	return (
		<RadioGroup
			options={options}
			onChange={handlePasscodeListType}
			selected={workingPasscodeList?.type.toString()}
			style={{ marginTop: 0 }}
		// defaultValue={PASSCODE_LIST_TYPE.MAGIC_LINKS}
		/>
	);
}