/* eslint-disable @typescript-eslint/no-explicit-any */

//NOTE: this event emitter only allows ONE listener per event tag, so if you add another
//listener with the same tag name you WILL overwrite the first one.
type Handler = (...e: any[]) => void;

const signalMap = new Map<string, Handler>();
const onceHandlers = new Set<string>();

// This event emitter handles multiple listeners per event tag and they must be removed by passing the tag AND the handler
const eventMap = new Map<string, Set<Handler>>();

export function on(tag: string, func: Handler): void {
	signalMap.set(tag, func);
}

export function once(tag: string, func: Handler): void {
	signalMap.set(tag, func);
	onceHandlers.add(tag);
}

export function addEventListener(tag: string, func: Handler): void {
	if (!eventMap.has(tag)) {
		eventMap.set(tag, new Set());
	}

	eventMap.get(tag)?.add(func);
}

export function off(tag: string): void {
	signalMap.delete(tag);
}

export function removeEventListener(tag: string, func: Handler): void {
	eventMap.get(tag)?.delete(func);
}

export function broadcastSignal(tag: string, ...args: any[]): void {
	signalMap.get(tag)?.(...args);
	if (onceHandlers.has(tag)) {
		signalMap.delete(tag);
		onceHandlers.delete(tag);
	}
}

export function broadcastEvent(tag: string, ...args: any[]): void {
	eventMap.get(tag)?.forEach(func => func(...args));
}

export function removeAll(): void {
	signalMap.clear();
	eventMap.clear();
}

/**

************************
Usage
************************

import * as EventEmitter from './path/to/event-emitter.ts'

function myComponent() {
	const [foo, setFoo] = useState('');

	useEffect(() => {
		//declare the handler
		const myHandler = (someArg: someType) => {
			setFoo(someArg);
		}

		//listen for the event
		EventEmitter.addEventListener('some-event', myHandler);

		//remove the event listener
		return () => {
			EventEmitter.removeEventListener('some-event', myHandler);
		}
	}, []);
}

function myOtherComponent() {
	function emitTheEvent() {
		EventEmitter.broadcastEvent('some-event', someVariable);
	}

	return (
		<button onClick={emitTheEvent}>Emit Event</button>
	)
}

*/