import { BrandliveEvent, LanguagesAbbr } from "../types/working-model";
import { matchPath, generatePath } from 'react-router-dom';
import type { match } from 'react-router-dom';
import { shouldDisplayHomepage } from "./utils";
import { getAttendeeLocalLanguage } from "../components/live-event/utils";

const SafeRouteMap = {
	CustomPage: "/:eventName/:language/pages/:customPath",
	Session: "/:eventName/:language/session/:sessionUuid",
	Breakout: "/:eventName/:language/session/:sessionUuid/breakout/:breakoutUuid",
	Fireside: "/:eventName/:language/session/:sessionUuid/fireside",
	FiresideNoHomepage: "/:eventName/:language/fireside",
	Registration: "/:eventName/:language/registration",
	PrivacyPolicy: "/:eventName/:language/privacy-policy",
	TermsConditions: "/:eventName/:language/terms-conditions",
	Home: `/:eventName/:language/home`,
	Sessions: `/:eventName/:language/sessions`,
	People: `/:eventName/:language/people`,
	Directory: `/:eventName/:language/directory`,
	Profile: "/:eventName/:language/profile",
	Watchlist: "/:eventName/:language/watchlist",
	AttendeeProfile: "/:eventName/:language/profile/:attendeeId",
	Landing: `/:eventName/:language`,
	LandingRedirect: "/:eventName",
	Leaderboard: `/:eventName/:language/leaderboard`,
};

const LocalRouteMap = {
	CustomPage: "/event/:uuid/:language/pages/:customPath",
	Session: "/event/:uuid/:language/session/:sessionUuid",
	Fireside: "/event/:uuid/:language/session/:sessionUuid/fireside",
	FiresideNoHomepage: "/event/:uuid/:language/fireside",
	Breakout: "/event/:uuid/:language/session/:sessionUuid/breakout/:breakoutUuid",
	Registration: "/event/:uuid/:language/registration",
	PrivacyPolicy: "/event/:uuid/:language/privacy-policy",
	TermsConditions: "/event/:uuid/:language/terms-conditions",
	Home: `/event/:uuid/:language/home`,
	Sessions: `/event/:uuid/:language/sessions`,
	People: `/event/:uuid/:language/people`,
	Directory: '/event/:uuid/:language/directory',
	Profile: "/event/:uuid/:language/profile",
	Watchlist: "/event/:uuid/:language/watchlist",
	AttendeeProfile: "/event/:uuid/:language/profile/:attendeeId",
	Landing: `/event/:uuid/:language`,
	LandingRedirect: "/event/:uuid",
	Leaderboard: `/event/:uuid/:language/leaderboard`
};

const getRoutes = () => {
	const isOnDebug = location.pathname.startsWith('/event/');

	if (isOnDebug) {
		return LocalRouteMap;
	}

	return SafeRouteMap;
};

type CommonParams = {
	eventName?: string,
	uuid?: string,
	language: string,
	sessionUuid?: string,
	customPath?: string,
	attendeeId?: string,
	breakoutUuid?: string
};

const getParams = (match: match<CommonParams>, languageOverride?: LanguagesAbbr) => {
	return {
		eventName: match.params.eventName,
		uuid: match.params.uuid,
		sessionUuid: match.params.sessionUuid,
		language: languageOverride ?? match.params.language,
		customPath: match.params.customPath,
		attendeeId: match.params.attendeeId,
		breakoutUuid: match.params.breakoutUuid
	};
};

export const Redirects = {
	fromRegistration: (event: BrandliveEvent | undefined | null, languageOverride?: LanguagesAbbr): string => {
		const routes = getRoutes();
		const match = matchPath<CommonParams>(location.pathname, { path: Object.values(routes), exact: true });
		if (!event || !match) {
			return location.pathname.replace('/registration', '');
		}

		// if user has been redirected to registration page, redirect back to previous page
		if (history.state?.state?.referrer) {
			return history.state.state.referrer;
		}

		const language = languageOverride ?? getAttendeeLocalLanguage(event);

		// only redirect to homepage if it is visible
		const hasHomepage = shouldDisplayHomepage(event);
		if (hasHomepage) {
			return generatePath(routes.Home, getParams(match, language));
		}

		// redirect to landing page (which doubles as the single-session page)
		return generatePath(routes.Landing, getParams(match, language));
	},
	toRegistration: (languageOverride?: LanguagesAbbr): string => {
		const routes = getRoutes();
		const match = matchPath<CommonParams>(location.pathname, { path: Object.values(routes), exact: true });

		if (!match) {
			// no matched location, redirect to event root
			return `/${location.pathname.split('/')[1]}`;
		}

		const language = languageOverride ?? window.navigator.language.substring(0, 2) as LanguagesAbbr;

		return generatePath(routes.Registration, getParams(match, language));
	},
	postRegistrationReferrer: (event: BrandliveEvent): string => {
		const hasHomepage = shouldDisplayHomepage(event);

		const routes = getRoutes();
		const match = matchPath<CommonParams>(location.pathname, { path: Object.values(routes), exact: true });

		if (!match) {
			// no matched location, redirect to event root
			return `/${location.pathname.split('/')[1]}`;
		}

		const language = getAttendeeLocalLanguage(event);

		// if homepage is off, do not redirect them to home even if they ask for it
		if (!hasHomepage && match.path === routes.Home) {
			return generatePath(routes.Landing, getParams(match, language));
		} else {
			return generatePath(match.path, getParams(match, language));
		}
	},
	toPage: (page: string, languageOverride?: LanguagesAbbr): string => {
		const routes = getRoutes();
		const routableDestinations: Record<string, string> = {};

		// cannot direct route to paths with variable endings ie: /:customPath
		// only literal paths like /home or /registration or /terms-conditions, etc
		for (const value of Object.values(routes)) {
			const path = value.split('/').pop();
			if (path && !path.startsWith(':')) {
				routableDestinations[path] = value;
			}
		}

		routableDestinations.registration = routes.Registration;

		const match = matchPath<CommonParams>(location.pathname, { path: Object.values(routes), exact: true });

		const pageDefault = `/${location.pathname.split('/')[1]}`;

		if (!match || !routableDestinations[page]) {
			// no matched location, redirect to event root
			return pageDefault;
		}

		const language = languageOverride ?? window.navigator.language.substring(0, 2) as LanguagesAbbr;

		return generatePath(routableDestinations[page], getParams(match, language));
	}
};

// note: this is only designed to be in use within the live-event
// and has not been tested against any other part of the application
export const getQueryStringState = () => {

	// on page load, parse query string and store in history state
	const search = new URLSearchParams(location.search);
	const params = Object.fromEntries(search.entries());
	const utmParams: Record<string, string> = {};

	let redirectTo: string | undefined;

	const DefaultRouteMap = getRoutes();

	// page variable requests an immediate redirect to a given page
	// page must not end in a variable, ie: /:customPath
	if (params.page) {
		const routableDestinations = Object.values(DefaultRouteMap).reduce<string[]>((acc, val) => {
			const path = val.split('/').pop();
			if (path && !path.startsWith(':')) {
				acc.push(path);
			}
			return acc;
		}, []);

		// registration variously ends with a uuid - ignore and redirect user to base registration page
		routableDestinations.push('registration');

		if (routableDestinations.includes(params.page)) {
			redirectTo = params.page;
		}
	}

	// store utm params separately for later use
	for (const param in params) {
		if (param.toLowerCase().startsWith('utm')) {
			utmParams[param] = params[param];
			search.delete(param);
		}
	}

	search.delete('page');
	search.delete('language');

	return {
		utmParams,
		querystring: search.toString(),
		redirectTo,
		params,
		language: params.language
	};
};