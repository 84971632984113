import { useCallback, useMemo } from "react";

import { CallToAction, CallToActionType, EPermissions, LanguagesAbbr, ModuleMap, PageLinkType } from "../../../types/working-model";
import { updateTranslateKey, userHasRoleOnActiveChannel } from "../../../utils/utils";
import Icon, { COLORS, ICONS } from "../icon";
import SelectInput from "../select/select";
import TextInput from "../text-input/text";
import './call-to-action.scss';
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";

//if more page links are needed add them here and to the PageLinkType in working-model.ts
const pageOptions = [
	{
		label: "Registration",
		value: PageLinkType.registration
	},
	{
		label: "Landing Page",
		value: PageLinkType.landing
	},
	{
		label: "Terms & Conditions",
		value: PageLinkType.termsAndConditions
	},
	{
		label: "Privacy Policy",
		value: PageLinkType.privacyPolicy
	},
	{
		label: "Home Page",
		value: PageLinkType.homepage
	},
	{
		label: "Agenda Page",
		value: PageLinkType.agenda
	}
];

const defaultTypeOptions: (showSessionOption: boolean) => { label: string, value: CallToActionType }[] = (showSessionOption = false) => {
	const options = [
		{
			label: "Section",
			value: CallToActionType.section
		},
		{
			label: "Page",
			value: CallToActionType.page
		},
		{
			label: "URL",
			value: CallToActionType.url
		}
	];
	if (showSessionOption) {
		options.unshift({
			label: "Session",
			value: CallToActionType.session
		});
	}
	return options;
};

interface CallToActionProps {
	workingCTA?: CallToAction;
	onWorkingCTAChange: (workingCallToAction: CallToAction) => void;
	useFocusClick?: boolean;
	language: LanguagesAbbr;
	baseLanguage: LanguagesAbbr;
	ctaTypeOptions?: CallToActionType[];
	isHomepage?: boolean;
}

const initRegistrationCTA: CallToAction = {
	type: CallToActionType.page,
	button_name: {
		base: "Register now",
		changed: ""
	},
	content: { page: PageLinkType.registration },
};

const CallToActionForm: React.FC<CallToActionProps> = ({
	workingCTA,
	onWorkingCTAChange,
	useFocusClick,
	language,
	baseLanguage,
	ctaTypeOptions,
	isHomepage = false,
}) => {
	if (!workingCTA) {
		workingCTA = initRegistrationCTA;
	}

	const workingEvent = useTypedSelector((state) => state.CreateEventReducer.workingEvent);
	const user = useTypedSelector((state) => state.AuthReducer.user);

	function handleTypeChange(type: CallToActionType) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				type
			});
		}
	}
	function handleNameChange({ target }: React.ChangeEvent<HTMLInputElement>) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				button_name: updateTranslateKey({
					translateString: workingCTA?.button_name,
					input: target.value,
					language,
					baseLanguage
				})
			});
		}
	}
	function handleSessionDest(value: string) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				content: { session: value }
			});
		}
	}
	function handleSectionDest(value: number) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				content: { section_module_id: value }
			});
		}
	}
	function handlePageDest(value: PageLinkType) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				content: { page: value }
			});
		}
	}
	function handleLinkDest(e: React.ChangeEvent<HTMLInputElement>) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				content: { url: e.target.value }
			});
		}
	}

	// session options
	const sessionOptions = useMemo(() => {
		return [
			{ label: '', value: '' },
			...(workingEvent?.sessions || []).map(session => ({
				label: session.title?.[language] || session.title.base,
				value: session.uuid
			}))
		];
	}, [language, workingEvent?.sessions]);

	//module options
	const sectionOptions = useMemo(() => {
		if (!workingEvent?.homepage?.modules) return [];
		const opts = workingEvent?.homepage?.modules
			.filter(module => module.is_on)
			.map(module => {
				if (!module?.id) return null;
				return {
					value: module.id,
					label: (module?.content?.reference_title || ModuleMap[module.type] || '') as string,
				};
			})
			.filter(i => i) as { label: string; value: number | '' }[];

		// just adding an empty option at the beginning so the select input doesn't incorrectly display the first item
		// as "selected" when it isn't.
		opts?.unshift({ label: '', value: '' });
		return opts;
	}, [workingEvent]);

	const customPageOptions = workingEvent?.custom_pages
		? workingEvent.custom_pages.map(page => ({
			label: page.page_name.base,
			value: page.uuid
		}))
		: [];

	const extendedPageOptions = [...pageOptions, ...customPageOptions];

	const selectedSectionModule = useCallback(() => {
		if (workingCTA?.content?.section_module_id) {
			return workingCTA?.content?.section_module_id;
		}
		if (workingCTA?.content?.section || workingCTA?.content?.section === 0) { // this is for backwards compatability only
			// find the first module id, matching by type (section)
			const matchedModule = workingEvent?.homepage?.modules?.find((module) => {
				if (module.is_on && module.type === workingCTA?.content?.section) {
					return true;
				}
				return false;
			});
			if (matchedModule?.id) {
				return matchedModule.id;
			}
		}
		return '';
	}, [workingCTA.content.section, workingCTA.content?.section_module_id, workingEvent?.homepage?.modules]);

	const showSessionOption = useMemo(() => {
		return isHomepage && userHasRoleOnActiveChannel(
			[EPermissions.Owner, EPermissions.Admin, EPermissions.Builder, EPermissions.Editor, EPermissions.GroupAdmin],
			user,
		);
	}, [isHomepage, user]);

	return (
		<div className="call-to-action-settings">
			<div className="call-to-action-upper">
				<TextInput
					id="button-text"
					label="Button text"
					placeholder={"Enter button text"}
					defaultValue={workingCTA?.button_name?.[language] as string || workingCTA?.button_name?.base || ''}
					onChange={handleNameChange}
					useFocusClick={useFocusClick}
				/>
				<SelectInput
					mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />}
					iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={12} color={COLORS.WHITE} />}
					options={ctaTypeOptions ? defaultTypeOptions(showSessionOption).filter(({ value }) => ctaTypeOptions.includes(value)) : defaultTypeOptions(showSessionOption)}
					selected={workingCTA.type}
					onChange={handleTypeChange}
					label={"Link to *"}
					dropdownClass="call-to-action-dropdown"
				/>

			</div>
			{/* the session options is only available on the home page */}
			{showSessionOption && workingCTA.type === CallToActionType.session && (
				<div className="call-to-action-lower call-to-action-section-options">
					<SelectInput
						id="select-session"
						mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />}
						iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={12} color={COLORS.WHITE} />}
						options={sessionOptions}
						selected={workingCTA?.content?.session || ""}
						onChange={handleSessionDest}
						placeholder="Select session"
						label="Session"
						highlightSelectedItem
						hideEmptyOption
					/>
				</div>
			)}
			{workingCTA.type === CallToActionType.section && (
				<div className="call-to-action-lower call-to-action-section-options">
					<SelectInput
						id="select-section"
						mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />}
						iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={12} color={COLORS.WHITE} />}
						options={sectionOptions || []}
						// check if the section is main_event_banner which has enum value of 0
						selected={selectedSectionModule()}
						onChange={handleSectionDest}
						placeholder={"Select section"}
						label={"Section"}
						highlightSelectedItem
						hideEmptyOption
					/>
				</div>
			)}
			{workingCTA.type === CallToActionType.page && (
				<div className="call-to-action-lower">
					<SelectInput
						id="select-page"
						mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />}
						iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={12} color={COLORS.WHITE} />}
						options={extendedPageOptions}
						selected={workingCTA.content?.page || ""}
						onChange={handlePageDest}
						placeholder={"Select page"}
						label={"Page"}
					/>
				</div>
			)}

			{workingCTA.type === CallToActionType.url && (
				<div className="call-to-action-lower">
					<TextInput
						id="enter-url"
						label="URL"
						placeholder={"Enter URL"}
						onChange={handleLinkDest}
						defaultValue={workingCTA.content?.url}
						useFocusClick={useFocusClick}
					/>
				</div>
			)}
		</div>
	);
};

export default CallToActionForm;
