import { Access, UserAccess } from "access/access";
import { BLAdminWithPermissions } from "connection/access";
import { handle } from "redux-pack";
import {
	ACCESS__GET_MORE_USERS,
	ACCESS__GET_USERS,
	ACCESS__GRANT_USER_ACCESS,
	ACCESS__REPLACE_USER_ACCESS,
	ACCESS__REVOKE_CHANNEL_ACCESS,
	ACCESS__RESET_CHANNEL,
	AccessAction,
	ACCESS__UPDATE_ACCESS

} from "store/actions/admin/access";

export type AdminWithAccess = BLAdminWithPermissions & { permissions: Access[] };

export interface AccessState {
	users: UserAccess[];
	page: number;
	totalUsers: number;
	gettingUsers: boolean;
	errorGettingUsers?: string;
	updatingUser: string;
	errorUpdatingUser?: string;
}

const initialState: AccessState = {
	users: [],
	page: 1,
	totalUsers: 0,
	gettingUsers: false,
	updatingUser: ''
};

const requestDataToUserAccess = (user: BLAdminWithPermissions) => (
	new UserAccess(
		user.email,
		user.user_access.map(access => Access.fromPermissionStatement(access)),
		user
	)
);

export default function AccessReducer(state: AccessState = initialState, action: AccessAction): AccessState {
	switch (action.type) {
		case ACCESS__GET_USERS: {
			return handle(state, action, {
				start: state => ({ ...state, gettingUsers: true, errorGettingUsers: undefined }),
				success: state => ({
					...state,
					users: (action.payload?.data ?? []).map(requestDataToUserAccess),
					gettingUsers: false,
					page: 1,
					totalUsers: action.payload?.total ?? 0
				}),
				failure: state => ({ ...state, gettingUsers: false, errorGettingUsers: 'Error getting users' })
			});
		}
		case ACCESS__GET_MORE_USERS: {
			return handle(state, action, {
				start: state => ({ ...state, gettingUsers: true, errorGettingUsers: undefined }),
				success: state => ({
					...state,
					page: state.page + 1,
					users: [
						...state.users,
						...(action.payload?.data ?? []).map(requestDataToUserAccess)
					],
					gettingUsers: false,
					totalUsers: action.payload?.total ?? 0
				}),
				failure: state => ({ ...state, gettingUsers: false, errorGettingUsers: 'Error getting users' })
			});
		}
		case ACCESS__GRANT_USER_ACCESS: {
			return handle(state, action, {
				start: state => ({ ...state, updatingUser: action.meta?.email ?? '', errorUpdatingUser: undefined }),
				success: state => {
					return {
						...state,
						users: state.users.map(user => {
							if (user.userId === action.meta?.email) {
								user.permissions = [...user.permissions, ...action.meta.accesses];
							}
							return user;
						}),
						updatingUser: ''
					};
				},
				failure: state => ({ ...state, updatingUser: '', errorUpdatingUser: 'Error updating user' })
			});
		}
		case ACCESS__REVOKE_CHANNEL_ACCESS: {
			return handle(state, action, {
				start: state => ({ ...state, updatingUser: action.meta?.email ?? '', errorUpdatingUser: undefined }),
				success: state => {
					return {
						...state,
						users: state.users.map(user => {
							if (user.userId === action.meta?.email) {
								user.permissions = [];
							}
							return user;
						}),
						updatingUser: ''
					};
				},
				failure: state => ({ ...state, updatingUser: '', errorUpdatingUser: 'Error updating user' })
			});
		}

		case ACCESS__REPLACE_USER_ACCESS: {
			return handle(state, action, {
				start: state => ({ ...state, updatingUser: action.meta?.email ?? '', errorUpdatingUser: undefined }),
				success: state => {
					return {
						...state,
						users: state.users.map(access => {
							if (access.userId === action.meta?.email) {
								access.permissions = action.meta.accesses;
								access.user.roles = action.meta.roles;
								return UserAccess.from(access);
							}
							return access;
						}),
						updatingUser: ''
					};
				},
				failure: state => ({ ...state, updatingUser: '', errorUpdatingUser: 'Error updating user' })
			});
		}

		case ACCESS__UPDATE_ACCESS: {
			return {
				...state,
				users: state.users.map(user => {
					if (user.userId === action.payload.userId) {
						// replace class instance with new replaced instance to force reducer to update
						return UserAccess.from(action.payload);
					}
					return user;
				})
			};
		}

		case ACCESS__RESET_CHANNEL: {
			return initialState;
		}

		default:
			return state;
	}
}
