import { handle } from 'redux-pack';
import * as EventChatActions from '../../actions/event/event-chat-actions';
import { Action } from '../../../types/actions';
import {
	BlProfileSearchResult,
	EventChatMessageType,
	LanguagesAbbr,
	IBlockedChatParticipant,
} from '../../../types/working-model';
import { toArrayMap, toMap } from '../../../utils/utils';

export interface IEventChatMessage {
	id: number;
	content: string;
	chat: number;
	created_at: Date;
	deleted_at: Date | string | null;
	edited_at: Date | string | null;
	language: LanguagesAbbr;
	sender: number;
	bl_profile: number;
	type: EventChatMessageType;
	uuid: string;
	profile: Record<string | number, string>;
	profile_uuid: string;
}

export interface IEventChatParticipant {
	id: number;
	bl_profile: number;
	bl_profile_uuid: string;
	chat: number;
	created_at: Date | string; // time at which user joined chat
	last_read: Date | string | null;
	left_chat: Date | string | null; // ADDING NULL FOR NO LEFT CHAT
	selected: boolean;
	first_name: string;
	last_name: string;
	avatar: string | null;
	blocked?: IBlockedChatParticipant | null;
}

export interface IEventChat {
	id: number;
	created_at: Date | string;
	default_language: LanguagesAbbr;
	event: number;
	is_open: boolean; // designates a general chat vs direct or group message
	languages: LanguagesAbbr[];
	title: string | null;
	uuid: string;

	// fields to add beyond api data model
	messages: Map<number, IEventChatMessage>;
	participants: IEventChatParticipant[];
	unread?: number;
	image: string | null;
	recentMessage: IEventChatMessage;
}

interface IEventChatState {
	eventChats: IEventChat[];
	eventChatsLoaded: boolean;
	eventChatsLoading: boolean;
	participants: IEventChatParticipant[]; // this might need to move to a key value structure to have arrays for each chat like the messages below
	messages: IEventChatMessage[] | null; // arrays of messages for each chat in current event
	newParticipantsSearch: BlProfileSearchResult[];
	lastReadChats: Record<number, number>;
	totalUnread: number;
	blockedParticipants: IBlockedChatParticipant[];
	bannedUsers: string[];
	latestAddedParticipants: string[][];
	loadingAttendees: boolean;
	displayChatGroup: { chat: IEventChat, title?: string, avatar?: string } | null
}

const defaultState: IEventChatState = {
	bannedUsers: [],
	blockedParticipants: [],
	displayChatGroup: null,
	eventChats: [],
	eventChatsLoaded: false,
	eventChatsLoading: false,
	lastReadChats: {},
	latestAddedParticipants: [],
	loadingAttendees: true,
	messages: [],
	newParticipantsSearch: [],
	participants: [],
	totalUnread: 0,
};

function getUnreadNumber(chatRoom: IEventChat, lastReadChats: Record<number, number>) {
	let unread = 0;
	const numberOfRoomMessages = chatRoom.messages.size;
	const lastReadTimestamp = lastReadChats[chatRoom.id];
	if (!lastReadTimestamp) {
		return numberOfRoomMessages;
	}
	chatRoom.messages.forEach((message: IEventChatMessage) => {
		if (
			![EventChatMessageType.ENABLE_CHAT, EventChatMessageType.DISABLE_CHAT].includes(message.type)
			&& (new Date(message.created_at).valueOf()) >= lastReadTimestamp
		) {
			unread += 1;
		}
	});
	return unread;
}

function getUnreadMap(messages: IEventChatMessage[], lastReadChats: Record<number, number>) {
	const unreadMap: Record<number, number> = {};
	messages.forEach((message) => {
		if (![EventChatMessageType.ENABLE_CHAT, EventChatMessageType.DISABLE_CHAT].includes(message.type)) {
			const lastReadTimestamp = lastReadChats[message.chat];
			if (lastReadTimestamp) {
				if (new Date(message.created_at).valueOf() > lastReadTimestamp) {
					if (unreadMap[message.chat]) {
						unreadMap[message.chat] += 1;
					} else {
						unreadMap[message.chat] = 1;
					}
				} else {
					unreadMap[message.chat] = 0;
				}
			}
		}

	});
	return unreadMap;
}

function dropUnblocked(bl: IBlockedChatParticipant) {
	return bl.status === 'blocked';
}

function blockParticipantInEventChat(blockedParticipant: IBlockedChatParticipant) {
	return (chat: IEventChat) => {
		chat.participants = chat.participants.map(participant => {
			if (participant.bl_profile === blockedParticipant.blocked_bl_profile) {
				participant.blocked = { ...blockedParticipant };
			}

			return participant;
		});
		return chat;
	};
}

function unblockParticipantInEventChat(blockedParticipant: IBlockedChatParticipant) {
	return (chat: IEventChat) => {
		chat.participants = chat.participants.map(participant => {
			if (participant.bl_profile === blockedParticipant.blocked_bl_profile) {
				participant.blocked = null;
			}

			return participant;
		});
		return chat;
	};
}

function blockParticipantInParticipants(blockedParticipant: IBlockedChatParticipant) {
	return (participant: IEventChatParticipant) => {
		if (participant?.bl_profile === blockedParticipant.blocked_bl_profile) {
			return {
				...participant,
				blocked: {
					...blockedParticipant,
				},
			};
		}
		return participant;
	};
}

function unblockParticipantInParticipants(blockedParticipant: IBlockedChatParticipant) {
	return (participant: IEventChatParticipant) => {
		if (participant?.bl_profile === blockedParticipant.blocked_bl_profile) {
			return {
				...participant,
				blocked: null,
			};
		}
		return participant;
	};
}

function removeFromBlockedParticipants(blockedParticipant: IBlockedChatParticipant) {
	return (part: IBlockedChatParticipant) => part.blocked_bl_profile !== blockedParticipant.blocked_bl_profile;
}

export default function EventChatReducer(state: IEventChatState = defaultState, action: Action): IEventChatState {
	switch (action.type) {
		case EventChatActions.LOAD_EVENT_CHAT_MESSAGES: {
			return handle(state, action, {
				success: state => {
					if (action?.payload?.responseCode === 401) return state;

					const blockedParticipantMap: Record<number, IBlockedChatParticipant> = {};

					action.payload?.blockedParticipants?.filter(dropUnblocked).forEach?.((bp: IBlockedChatParticipant) => {
						blockedParticipantMap[bp.blocked_bl_profile] = bp;
					});

					const blockedSenders = Object.keys(blockedParticipantMap).map(Number);

					// if participant is blocked, add it to the profile
					const payloadParticipants = action.payload?.participants?.map?.((participant: IEventChatParticipant) => {
						const blockedParticipant = blockedParticipantMap[participant?.bl_profile];
						if (blockedParticipant) {
							participant.blocked = blockedParticipant;
						}
						return participant;
					});

					// create maps to prevent nested loops
					const participantMap = toArrayMap('chat', payloadParticipants as IEventChatParticipant[]);
					const messageMap = toArrayMap('chat', action.payload.messages as IEventChatMessage[]);

					//get just this user's participant rows which will only be chats that is_open is false
					const myParticipation = action.payload.participants.filter((participant: IEventChatParticipant) => participant.bl_profile === action.payload.bl_profile);

					//create map of last_reads
					const myLastSeen: Record<number, number> = {};
					myParticipation.forEach((participant: IEventChatParticipant) => myLastSeen[participant.chat] = new Date(participant.last_read as string).valueOf());

					const unreadMap = getUnreadMap(action.payload.messages, { ...state.lastReadChats, ...myLastSeen });

					//append participants and messages to eventChats
					const eventChats = action.payload.eventChats.map((chat: IEventChat) => {
						const messages = (messageMap.get(chat.id) ?? []).filter(message => !blockedSenders.includes(message.sender));

						return {
							...chat,
							participants: participantMap.get(chat.id) ?? [],
							messages: toMap('id', messages.slice(0).reverse()) as Map<number, IEventChatMessage>,
							unread: unreadMap[chat.id] ?? messages.length,
							recentMessage: messages[0] ?? null
						};
					});

					return {
						...state,
						eventChats: eventChats,
						participants: action.payload.participants,
						messages: action.payload.messages,
						lastReadChats: myLastSeen,
						totalUnread: eventChats.reduce((acc: number, cur: IEventChat) => acc + (cur.unread ?? 0), 0),
						blockedParticipants: action.payload?.blockedParticipants.filter(dropUnblocked) ?? [],
						bannedUsers: action.payload.bannedUsers,
					};
				},
				start: state => ({ ...state, eventChatsLoading: true }),
				finish: state => ({ ...state, eventChatsLoaded: true, eventChatsLoading: false })
			});
		}

		case EventChatActions.SEARCH_NEW_CHAT_PARTICIPANTS: {
			return handle(state, action, {
				start: state => ({
					...state,
					loadingAttendees: true,
				}),
				success: state => ({
					...state,
					newParticipantsSearch: action.payload.filter((participant: BlProfileSearchResult) => !state.bannedUsers?.includes(participant.uuid)),
				}),
				finish: state => ({
					...state,
					loadingAttendees: false,
				})
			});
		}

		case EventChatActions.RESET_NEW_PARTICIPANT_SEARCH: {
			return {
				...state,
				newParticipantsSearch: action.payload
			};
		}

		case EventChatActions.UPDATE_LAST_READ_EVENT_CHAT_MESSAGE: {
			return handle(state, action, {
				success: state => {
					let totalUnread = 0;
					const lastRead = {
						...state.lastReadChats,
						[action.payload.chat]: new Date(action.payload.last_read).valueOf()
					};

					const eventChats = state.eventChats.map(chat => {
						if (chat.id === action.payload.chat) {
							chat.unread = getUnreadNumber(chat, lastRead);
						}

						totalUnread += chat?.unread ?? 0;
						return chat;
					});

					return {
						...state,
						eventChats,
						totalUnread,
						lastReadChats: lastRead
					};
				}
			});
		}

		case EventChatActions.INCOMING_EVENT_CHAT_MESSAGE: {
			const { message, currentChat } = action.payload;

			let shouldUpdateTotalUnread = true;

			const blockedArr = state.blockedParticipants.map(part => part.blocked_bl_profile);

			if (blockedArr.includes(message.sender)) {
				return {
					...state
				};
			}

			const chatToUpdate = state.eventChats.find((stateChat: IEventChat) => stateChat.id === message.chatId);
			const previousUnread = chatToUpdate?.unread;
			const lastReadTimestamp = chatToUpdate ? state.lastReadChats[chatToUpdate.id] : 0;
			const shouldIncrement = lastReadTimestamp ? new Date(message?.created_at).valueOf() > lastReadTimestamp : true;
			const newUnread = (shouldIncrement && previousUnread !== undefined) ? previousUnread + 1 : previousUnread;

			const eventChats = state.eventChats.map(chat => {
				let updated = { ...chat };

				if (chat.id === message.chatId) {
					const newMessages = new Map(chat.messages);
					newMessages.set(message.id, message);
					updated = {
						...updated,
						messages: newMessages,
						unread: newUnread,
						recentMessage: message
					};

					if (chat.id === currentChat) {
						// don't update global notification if this chat is currently open
						shouldUpdateTotalUnread = false;
					}
				}

				return updated;
			});

			// don't increment unread if the chat message is enable/disable chat
			const isChatToggle = [EventChatMessageType.ENABLE_CHAT, EventChatMessageType.DISABLE_CHAT].includes(message.type);

			const totalUnread = shouldUpdateTotalUnread ? state.totalUnread + (isChatToggle ? 0 : 1) : state.totalUnread;

			return {
				...state,
				eventChats: eventChats,
				totalUnread,
			};
		}

		case EventChatActions.OUTGOING_EVENT_CHAT_MESSAGE: {
			return handle(state, action, {
				success: state => ({
					...state,
					// TODO update something
				})
			});
		}

		case EventChatActions.INCOMING_EDIT_EVENT_CHAT_MESSAGE: {
			return {
				...state,
				// TODO update something
			};
		}

		case EventChatActions.OUTGOING_EDIT_EVENT_CHAT_MESSAGE: {
			return handle(state, action, {
				success: state => ({
					...state,
					// TODO update something
				})
			});
		}

		case EventChatActions.INCOMING_DELETE_CHAT_MESSAGE: {
			return {
				...state,
				// TODO update something
			};
		}

		case EventChatActions.OUTGOING_DELETE_CHAT_MESSAGE: {
			return handle(state, action, {
				success: state => ({
					...state,
					// TODO update something
				})
			});
		}

		case EventChatActions.INCOMING_CREATE_EVENT_CHAT: {
			const { newEventChat, newEventChatParticipants } = action.payload;
			newEventChat.participants = newEventChatParticipants;
			newEventChat.messages = new Map<number, IEventChatMessage>();
			newEventChat.unread = 0;

			return {
				...state,
				eventChats: [
					...state.eventChats,
					newEventChat
				]
			};
		}

		case EventChatActions.OUTGOING_CREATE_EVENT_CHAT: {
			return handle(state, action, {
				success: state => ({
					...state,
					eventChats: [
						...state.eventChats,
						{ ...action.payload.newEventChat, messages: new Map<number, IEventChatMessage>(), participants: action.payload.newEventChatParticipants }
					],
					participants: [...state.participants, ...action.payload.newEventChatParticipants],
					newParticipantsSearch: [],
				})
			});
		}

		case EventChatActions.OUTGOING_CREATE_EVENT_DM: {
			// nearly identical to the above action, but without resetting newParticipantsSearch
			// this is used for when we need to create a DM from someone clicking the "message" button on a profile and the dm doesn't already exist
			// newParticipantsSearch is reset in the hook that handles opening this chat
			return handle(state, action, {
				success: state => {
					const newChat = { ...action.payload.newEventChat, messages: new Map<number, IEventChatMessage>(), participants: action.payload.newEventChatParticipants };

					return {
						...state,
						eventChats: [
							...state.eventChats,
							newChat
						],
						participants: [...state.participants, ...action.payload.newEventChatParticipants],
						displayChatGroup: {
							chat: newChat,
							title: action.payload.dmTitle,
							avatar: action.payload.avatar
						}
					};
				}
			});
		}

		case EventChatActions.INCOMING_LEAVE_EVENT_CHAT: {
			return {
				...state,
				// TODO update something
			};
		}

		case EventChatActions.OUTGOING_LEAVE_EVENT_CHAT: {
			return handle(state, action, {
				success: state => ({
					...state,
					eventChats: state.eventChats.filter(eventChat => eventChat.id !== action.payload.chatId)
				})
			});
		}

		case EventChatActions.GET_LAST_READ_OPEN_CHAT: {
			return handle(state, action, {
				success: state => {
					const lastRead = { ...state.lastReadChats, ...action.payload };
					let totalUnread = 0;
					const chats = state.eventChats.map((chat) => {
						chat.unread = getUnreadNumber(chat, lastRead);
						totalUnread += chat.unread;
						return chat;
					});
					return ({
						...state,
						lastReadChats: lastRead,
						eventChats: chats,
						totalUnread
					});
				}
			});
		}

		case EventChatActions.SET_LAST_READ_OPEN_CHAT: {
			return handle(state, action, {
				success: state => {
					const lastRead = { ...state.lastReadChats, ...action.payload };
					let totalUnread = 0;
					const chats = state.eventChats.map((chat) => {
						chat.unread = getUnreadNumber(chat, lastRead);
						totalUnread += chat.unread;
						return chat;
					});

					return ({
						...state,
						lastReadChats: lastRead,
						eventChats: chats,
						totalUnread
					});
				}
			});
		}

		case EventChatActions.UPDATE_CHAT_BLOCKED_STATUS: {
			const blockedParticipant: IBlockedChatParticipant = action.payload;

			if (!blockedParticipant) return state;

			if (blockedParticipant.status === 'blocked') {
				//update participants in participants array
				const updatedParticipants = state.participants?.map?.(blockParticipantInParticipants(blockedParticipant));

				//drop duplicates
				const updatedBlockedParticipants = toMap('blocked_bl_profile', state.blockedParticipants);
				updatedBlockedParticipants.set(blockedParticipant.blocked_bl_profile, blockedParticipant);

				//update participants in all matching chats
				const eventChats = state.eventChats.map(blockParticipantInEventChat(blockedParticipant));

				return {
					...state,
					participants: updatedParticipants ?? [],
					blockedParticipants: Array.from(updatedBlockedParticipants.values()),
					eventChats
				};
			} else {
				//update participant in participants array
				const updatedParticipants = state.participants?.map(unblockParticipantInParticipants(blockedParticipant));

				//update event chats containing this participant
				const eventChats = state.eventChats.map(unblockParticipantInEventChat(blockedParticipant));

				return {
					...state,
					participants: updatedParticipants ?? [],
					blockedParticipants: state.blockedParticipants.filter(removeFromBlockedParticipants(blockedParticipant)),
					eventChats
				};
			}
		}

		case EventChatActions.UPDATE_BANNED_USER_LIST: {
			return {
				...state,
				bannedUsers: [...state.bannedUsers, action.payload]
			};
		}

		case EventChatActions.SET_DISPLAY_CHAT_GROUP: {
			return {
				...state,
				displayChatGroup: action.payload
			};
		}

		default: return state;
	}
}
