import { useEffect, useState } from "react";

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import db from '../../../utils/datastore/indexed-db';
import { COOKIES_ACCEPTED_INDEX_DB } from "../../../types/gdpr";
import { IRegistrationGDPR } from "../../../connection/registration";

interface IUseCheckIfCookieAccepted {
	isEditor?: boolean;
}

const useCheckIfCookieAccepted = (props?: IUseCheckIfCookieAccepted) => {
	const isEditor = props?.isEditor;

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const userRegistrationGDPR = useTypedSelector(state => state.LiveEventReducer.gdpr);
	const forceRecheckCookieStatus = useTypedSelector(state => state.LiveEventReducer.forceRecheckCookieStatus);

	const event = eventBundle || workingEvent;

	const cookiesEnabled = !!event?.settings.gdpr?.enable_cookie_banner;

	const eventUuid = event?.uuid;

	const [userRegGDPR, setUserRegGDPR] = useState<IRegistrationGDPR>();
	const [checkingUserCookieStatus, setCheckingUserCookieStatus] = useState(true);

	useEffect(() => {
		if (!eventUuid) return;
		(async () => {
			try {
				// await db.deleteItem(COOKIES_ACCEPTED_INDEX_DB, eventUuid); // Only bring this in for testing when needing to clear data
				if (!cookiesEnabled || isEditor) {
					setCheckingUserCookieStatus(false);
					return;
				}
				const gdprAcceptedPreReg = await db.getItem<IRegistrationGDPR>(COOKIES_ACCEPTED_INDEX_DB, eventUuid);
				if (gdprAcceptedPreReg?.cookies_accepted) {
					setUserRegGDPR(gdprAcceptedPreReg);
					return;
				}
				if (userRegistrationGDPR?.cookies_accepted) {
					setUserRegGDPR(userRegistrationGDPR);
					return;
				}
			} catch (e) {
				console.error(e);
			} finally {
				setCheckingUserCookieStatus(false);
			}
		})();
	}, [cookiesEnabled, eventUuid, isEditor, userRegistrationGDPR, forceRecheckCookieStatus]);

	return { userRegGDPR, checkingUserCookieStatus };
};

export default useCheckIfCookieAccepted;
