import Socket from '../../../connection/socket';
import { GetBreakoutRoomsForSession, GetBreakoutRoomsForSessionHv, GetGoogleMeetRoom, GetGoogleMeetRoomForFireside } from '../../../connection/sessions';
import { Action, AsyncAction } from '../../../types/actions';
import { EBreakoutPanels, IAttendeeVideoSettings, IBreakoutRoomProfile } from '../../../types/working-model';

export const SET_CONNECTED_PROFILES = "SET_CONNECTED_PROFILES";
export function setConnectedProfiles(profiles: IBreakoutRoomProfile[]): Action {
	return {
		type: SET_CONNECTED_PROFILES,
		payload: profiles
	};
}

export const ADD_CONNECTED_PROFILE = "ADD_CONNECTED_PROFILE";
export function addConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: ADD_CONNECTED_PROFILE,
		payload: profile
	};
}

export const ADD_LIST_OF_PROFILES = "ADD_LIST_OF_PROFILES";
export function addListOfProfiles(profile: IBreakoutRoomProfile[]): Action {
	return {
		type: ADD_LIST_OF_PROFILES,
		payload: profile
	};
}

export const UPDATE_CONNECTED_PROFILES = "UPDATE_CONNECTED_PROFILES";
export function updateConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: UPDATE_CONNECTED_PROFILES,
		payload: profile
	};
}

export const REMOVE_CONNECTED_PROFILE = "REMOVE_CONNECTED_PROFILE";
export function removeConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: REMOVE_CONNECTED_PROFILE,
		payload: profile
	};
}

export const SET_BREAKOUT_ID = "SET_BREAKOUT_ID";
export function setBreakoutId(uuid: string | null): Action {
	return {
		type: SET_BREAKOUT_ID,
		payload: uuid
	};
}

export const CLEAR_BREAKOUT_ROOM = "CLEAR_BREAKOUT_ROOM";
export function clearBreakoutRoom(): Action {
	return {
		type: CLEAR_BREAKOUT_ROOM,
		payload: null
	};
}

export const SET_LOADING_PROFILES = "SET_LOADING_PROFILES";
export function setLoadingProfiles(loading: boolean): Action {
	return {
		type: SET_LOADING_PROFILES,
		payload: loading
	};
}

export const SET_OPENTOK_ID = "SET_OPENTOK_ID";
export function setOpenTokId(id: string | number | null): Action {
	return {
		type: SET_OPENTOK_ID,
		payload: id
	};
}

export const SET_BREAKOUT_PANEL_ITEM = "SET_BREAKOUT_PANEL_ITEM";
export function setBreakoutPanelItem(panel: EBreakoutPanels): Action {
	return {
		type: SET_BREAKOUT_PANEL_ITEM,
		payload: panel,
	};
}

export const SET_VIEW_ATTENDEE = "SET_VIEW_ATTENDEE";
export function setViewAttendee(profileId: number | null): Action {
	return {
		type: SET_VIEW_ATTENDEE,
		payload: profileId,
	};
}

export const SET_ATTENDEE_VIDEO_SETTINGS = "SET_ATTENDEE_VIDEO_SETTINGS";
export function setAttendeeVideoSettings(data: IAttendeeVideoSettings): Action {
	return {
		type: SET_ATTENDEE_VIDEO_SETTINGS,
		payload: data,
	};
}

export const SET_BREAKOUT_ROOM_USAGE = "SET_BREAKOUT_ROOM_USAGE";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setBreakoutRoomUsage(data: any): Action {
	return {
		type: SET_BREAKOUT_ROOM_USAGE,
		payload: data,
	};
}

export const GET_BREAKOUT_ROOMS_FOR_SESSION = 'GET_BREAKOUT_ROOMS_FOR_SESSION';
export function getBreakoutRoomsForSession(session: number, token: string): AsyncAction {
	return {
		type: GET_BREAKOUT_ROOMS_FOR_SESSION,
		promise: GetBreakoutRoomsForSession(session, token),
	};
}

export const SET_BREAKOUT_SOCKET = 'SET_BREAKOUT_SOCKET';
export function setBreakoutSocket(socket: Socket | null): Action {
	return {
		type: SET_BREAKOUT_SOCKET,
		payload: socket,
	};
}

export const SET_CLOSING_ALL_ROOMS = 'SET_CLOSING_ALL_ROOMS';
export function setClosingAllRooms(isClosing: boolean): Action {
	return {
		type: SET_CLOSING_ALL_ROOMS,
		payload: isClosing,
	};
}

export const GET_BREAKOUT_ROOMS_FOR_SESSION_HV = 'GET_BREAKOUT_ROOMS_FOR_SESSION_HV';
export function getBreakoutRoomsForSessionHv(sessionUuid: string, token: string): AsyncAction {
	return {
		type: GET_BREAKOUT_ROOMS_FOR_SESSION_HV,
		promise: GetBreakoutRoomsForSessionHv(sessionUuid, token),
	};
}

export const GET_GOOGLE_MEET_ROOM = 'GET_GOOGLE_MEET_ROOM';
export function getGoogleMeetRoom(sessionUuid: string, breakoutUuid: string, serviceAccountEnabled: boolean, token: string): AsyncAction {
	return {
		type: GET_GOOGLE_MEET_ROOM,
		promise: GetGoogleMeetRoom(sessionUuid, breakoutUuid, serviceAccountEnabled, token),
	};
}

export const FORCE_BREAKOUT_RENDER = 'FORCE_BREAKOUT_RENDER';
export function forceBreakoutCountRender(): Action {
	return {
		type: FORCE_BREAKOUT_RENDER,
		payload: null,
	};
}
