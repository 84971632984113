import React from "react";

import Datepicker from "../../../general-ui/datepicker/datepicker";
import Timepicker from "../../../general-ui/time-picker/timepicker";
import { useGetEvent } from "hooks/session.hooks";

interface SessionStartTimeFieldProps {
	minDate?: Date,
	handleSetDate: (date: Date) => void,
	workingStartDate?: Date,
	handleSetDuration: (minutes: number | undefined) => void,
	duration?: number,
}

const SessionStartTimeFields: React.FC<SessionStartTimeFieldProps> = ({
	minDate,
	handleSetDate,
	workingStartDate,
	handleSetDuration,
	duration,
}) => {
	const workingEvent = useGetEvent();

	return (
		<div className="field-group broadcast-group">
			<div className="broadcast-date">
				<Datepicker
					label={"Date & Time *"}
					minDate={minDate}
					onChange={handleSetDate}
					placeholder="Select a date and time"
					portalId="date-portal"
					value={workingStartDate}
					disabled={!!(workingEvent && !workingEvent?.settings.is_module_grouping_v2)} // No v1 event's rescheduled to the future.
				/>
			</div>
			<div className="broadcast-time">
				<Timepicker
					onChange={(e) => handleSetDuration(e.target.valueAsNumber)}
					label={"Duration (minutes) *"}
					placeholder={"Enter a duration in minutes"}
					initialValue={duration}
				/>
			</div>
		</div>
	);
};

export default SessionStartTimeFields;
