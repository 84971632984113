import React, { Suspense, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { IHomepageMainNavItems, IAnnouncement, NavMainItems } from "../../../types/working-model";
import { eventPath, getDefaultLanguage } from "../utils";
import MarketingRegisteredAgenda from "./marketing-registered-agenda/marketing-registered-agenda";
import { MarketingRegisteredPage as MarketingRegisteredHome } from "./marketing-registered-page";
import useTranslationModules from "../../../i18n/useTranslationModules";
import useRedirectLanguage from "./use-redirect-language";
import { getStorageItem } from "../../../utils/local-storage";
import useRouteMap from "../hooks/use-route-map";
import { useSocket } from '../../../connection/socket';
import { showAnnouncement } from "../../general-ui/alert/alert-service";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { PageLoader } from "../page-loader";
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from "../../../utils/error-boundary";
import { clearErrorBoundaryComponents } from "../../../store/actions/event/event-actions";
import Leaderboard from "./leaderboard/leaderboard";
import { ParamsProps } from "../live-event";
import { isDefined } from "../../../utils/utils";
import { getCurrentPageSourceInfo } from "../../../utils/tracking";

const WatchlistPage = React.lazy(() => import('../profile-page/watchlist-page'));
const ProfilePage = React.lazy(() => import('../profile-page/profile-page'));
const MarketingRegisteredDirectory = React.lazy(() => import('./marketing-registered-directory/marketing-registered-directory'));
interface MarketingRegisteredRoutesProps {
	debug?: boolean;
	onRenderError?: (path: 'home' | 'landing' | 'registration' | 'support' | 'singleSessionPage') => () => void;
}

const MarketingRegisteredRoutes: React.FC<MarketingRegisteredRoutesProps> = ({ debug, onRenderError = () => () => ({}) }) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const blProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);

	const history = useHistory();
	const dispatch = useDispatch();

	useRedirectLanguage({ event: eventBundle?.event });
	const baseLanguage = eventBundle ? getDefaultLanguage(eventBundle) : '';

	const eventMainNavItems = eventBundle?.homepage?.event_main_nav_items;
	const { uuid, eventName, language }: ParamsProps = useParams();

	// check to make sure language is in translated list, if not, push them to the default language
	// in this case english is currently locked as the homepage and registration default
	const homepageToggleExists = isDefined<boolean>(eventBundle?.settings?.display_homepage);
	const homepageToggledOff = !eventBundle?.settings?.display_homepage;

	const hasHomepage = !!eventBundle?.homepage && !(homepageToggleExists && homepageToggledOff);
	const notValidTranslation = hasHomepage ? !eventBundle?.homepage?.languages.includes(language) : !eventBundle?.sessions?.[0]?.languages.includes(language);
	// if stored language, get it and check if it's in the translated list
	const storedLanguage = eventBundle?.event ? getStorageItem(`event.${eventBundle.event}.language`) : null;
	const validStoredTranslation = storedLanguage && eventBundle?.homepage?.languages.includes(storedLanguage);
	const defaultLangage = eventBundle ? getDefaultLanguage(eventBundle) : 'en';

	const RouteMap = useRouteMap({ debug, eventMainNavItems });

	// first try and use the stored language, else go back to the params language
	// this will work because if a stored langauge exists, we are always forcing a redirect to that language
	useTranslationModules({
		language: validStoredTranslation && storedLanguage
			? storedLanguage
			: notValidTranslation
				? defaultLangage
				: language
	});

	useEffect(() => {
		// if its a single session, they are going to profile page
		// if homepage is toggled off, direct them back to the session page (which will live at base event path)
		if (notValidTranslation && (eventName || uuid) && eventBundle) {
			const baseLang = getDefaultLanguage(eventBundle);
			hasHomepage ?
				history.replace(`/${eventPath(eventName, uuid)}/${baseLang}/home`) :
				history.replace(`/${eventPath(eventName, uuid)}/${baseLang}`);

		}
	}, [history, eventName, uuid, notValidTranslation, hasHomepage, eventBundle]);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	// using socket for announcements from admin or moderator
	const socket = useSocket(`registered-${eventBundle?.uuid}-${language}`);
	useEffect(() => {
		// show announcement as alert
		const handleAnnouncement = ({ data }: { data: Partial<IAnnouncement>; }) => {
			if (eventBundle) {
				const { source_id, source_type } = getCurrentPageSourceInfo(location.pathname, eventBundle, blProfile?.bl_profile || 0);

				showAnnouncement({ ...data, language, baseLanguage, source_id, source_type });
			}
		};
		// add listener and remove when done
		socket.addListener('announcement', handleAnnouncement);
		return () => {
			socket.removeListener('announcement', handleAnnouncement);
		};
	}, [socket]);

	function getComponent(tabName: string) {
		switch (tabName.toUpperCase()) {
			case 'HOME': return <MarketingRegisteredHome />;
			case 'SESSIONS': return <MarketingRegisteredAgenda />;
			case 'LEADERBOARD': return <Leaderboard />;
			default: return <MarketingRegisteredHome />;
		}
	}

	const MarketingRegisteredComponentMap = useMemo(() => {
		if (eventMainNavItems) return eventMainNavItems?.reduce((a: { [key: string]: JSX.Element }, c: IHomepageMainNavItems) => {
			a[c.name] = getComponent(c.name);
			return a;
		}, {});
		return {};
	}, [eventMainNavItems]);

	const profilePagePaths = [RouteMap.Profile, RouteMap.AttendeeProfile];

	return (
		<>
			<Switch>
				{
					eventMainNavItems && eventMainNavItems?.map((navItem) => (
						<Route key={navItem.uuid} exact={true} path={RouteMap[navItem.name]}>
							<ErrorBoundary uniqueLabel={`Homepage ON Registration ON - ${navItem.name} page`} onError={onRenderError(navItem.name !== NavMainItems.Home ? "home" : eventBundle?.registration_on ? "support" : 'landing')}>
								{MarketingRegisteredComponentMap[navItem.name]}
							</ErrorBoundary>
						</Route>
					))
				}
				{/*  default to home */}
				<Route exact={true} path={RouteMap.Home}>
					<ErrorBoundary uniqueLabel="Homepage ON Registration ON - Home page" onError={onRenderError("support")}>
						<MarketingRegisteredHome />
					</ErrorBoundary>
				</Route>
				<Route exact={true} path={profilePagePaths}>
					<Suspense fallback={<PageLoader />}>
						<ErrorBoundary uniqueLabel={`Homepage${eventBundle?.homepage ? " ON" : " OFF"} Registration${eventBundle?.registration_on ? " ON" : " OFF"} - Profile page`} onError={onRenderError(eventBundle?.homepage ? "home" : "singleSessionPage")}>
							<ProfilePage />
						</ErrorBoundary>
					</Suspense>
				</Route>

				<Route exact={true} path={RouteMap.Watchlist}>
					<Suspense fallback={<PageLoader />}>
						<ErrorBoundary uniqueLabel={`Homepage${eventBundle?.homepage ? " ON" : " OFF"} Registration${eventBundle?.registration_on ? " ON" : " OFF"} - Watchlist page`} onError={onRenderError(eventBundle?.homepage ? "home" : "singleSessionPage")}>
							<WatchlistPage />
						</ErrorBoundary>
					</Suspense>
				</Route>

				<Route exact={true} path={RouteMap.Directory}>
					<Suspense fallback={<PageLoader />}>
						<ErrorBoundary uniqueLabel="Homepage ON Registration ON - Directory page" onError={onRenderError("home")}>
							<MarketingRegisteredDirectory />
						</ErrorBoundary>
					</Suspense>
				</Route>
				<Route exact={true} path={RouteMap.CustomPage}>
					<ErrorBoundary uniqueLabel="Homepage ON Registration ON - Custom page" onError={onRenderError("home")}>
						<MarketingRegisteredHome isCustomPage />
					</ErrorBoundary>
				</Route>
				<Route exact={true} path={RouteMap.Landing}>
					<Redirect to={`/${eventPath(eventName, uuid)}/${language}/home`} />
				</Route>
			</Switch>
		</>
	);
};

export default MarketingRegisteredRoutes;
