import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { HeaderAllTemplates } from '../../../../types/template-layouts';
import { HomepageHeader, LanguagesAbbr, PageModuleType } from '../../../../types/working-model';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import NavItem from '../../../general-ui/nav-item/nav-item';
import NewDropdown from '../../../general-ui/dropdown/new-dropdown';
import { EIcon } from '../../../general-ui/icon/icon';
import { LanguageDropdown } from '../../../general-ui/language-dropdown/language-dropdown';
import { ParamsProps } from '../../live-event';
import { eventPath, getDefaultLanguage, getEventNameURL, getHeaderStylingOverrides, useLanguageParam } from '../../utils';
import LiveCallToAction from '../../../general-ui/live-call-to-action/live-call-to-action';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { setStorageItem } from '../../../../utils/local-storage';
import { useTranslate } from '../../../../i18n/useTranslationModules';

import '../../../../scss/live-event/base/marketing-page/header.scss';

interface HeaderProps {
	header: HomepageHeader;
	languages: LanguagesAbbr[];
	template: string;
	registration_on: boolean;
	isUserLoggedIn: boolean;
	handleScroll: (moduleId?: number, p?: PageModuleType) => void;
	previewLanguage?: LanguagesAbbr;
	sendEmailConfirmation: boolean;
	languageSelectorOnly?: boolean;
	isLandingPage?: boolean;
}

const Header: React.FC<HeaderProps> = ({
	header,
	languages,
	template,
	registration_on,
	isUserLoggedIn,
	handleScroll,
	previewLanguage,
	sendEmailConfirmation,
	languageSelectorOnly = false,
	isLandingPage,
}) => {
	const { eventBundle, editorSize } = useTypedSelector(state => ({
		eventBundle: state.LiveEventReducer.eventBundle,
		editorSize: state.CreateEventReducer.editorSize
	}));
	const { eventName }: ParamsProps = useParams();

	const {
		backgroundColor,
		backgroundImg,
		mainTextOverrides
	} = getHeaderStylingOverrides(header?.styling_overrides);

	const { t } = useTranslate(isLandingPage ? ["landing", "homepage"] : ["home", "homepage"]);

	const language = previewLanguage ? previewLanguage : useLanguageParam() as LanguagesAbbr;

	const [selectedLanguage, setSelectedLanguage] = useState(language ?? '');
	const history = useHistory();
	const routeMatch = useRouteMatch<any>();
	const handleLanguageChange = (langAbbr: LanguagesAbbr) => {
		if (!eventBundle?.event) return;
		setSelectedLanguage(langAbbr);
		// language is set in storage so we can remember the users chosen language so if they enter
		// a session in another language and then go back to the home page, we can reset the language to their chosen language
		setStorageItem(`event.${eventBundle.event}.language`, langAbbr);
		if (langAbbr && langAbbr !== routeMatch?.params?.language) {
			// replace the language in the url with langAbbr
			const updatedParams = { ...routeMatch.params };
			updatedParams.language = langAbbr;
			let updatedUrl = routeMatch.path;
			for (const key in updatedParams) {
				updatedUrl = updatedUrl.replace(`:${key}`, updatedParams[key]);
			}

			history.replace(updatedUrl);
		}
	};
	// TODO: bring in styling_overrides for homepage

	const { isLessThan1024 } = useScreenMediaQuery();

	const editorLessThan1024 = (editorSize === 'tablet') || (editorSize === 'mobile');

	const renderLinks = () => {
		const overrideColor = mainTextOverrides?.split('-')[0];
		const navItems = header.navItems?.map((navItem, i) => {
			const withV3Translation = navItem.button_name?.[language] || t(`header.${navItem.button_name.base}`, navItem.button_name?.base || '');
			const override = navItem.button_name.base === 'Sessions'
				? t('homepage:Watch', withV3Translation as string)
				: withV3Translation as string;

			return (
				<NavItem
					key={navItem.button_name?.base + i}
					navItem={navItem}
					handleScroll={handleScroll}
					previewLanguage={language}
					className={classNames(
						'navigation-link',
						mainTextOverrides,
						{ [`${overrideColor}-hover`]: mainTextOverrides }
					)}
					labelOverride={override ? override : undefined}
				/>
			);
		});
		return navItems;
	};

	const layout = header?.layout || HeaderAllTemplates.left;

	const callToActionText = t('header.callToAction', (header.callToAction?.button_name[language] as string || header.callToAction?.button_name.base || ''));

	const pageLink = header?.callToAction?.content?.page || null;
	const sectionLink = header?.callToAction?.content?.section || null;
	const urlLink = header?.callToAction?.content?.url || null;

	const hasSignInBtn = !!header?.sign_in_button && sendEmailConfirmation;

	const renderSigInButton = () => {
		if (!hasSignInBtn || !eventBundle) return null;
		const overrideColor = mainTextOverrides?.split('-')[0];
		return (
			<div
				style={{
					...(!isLessThan1024 && !editorLessThan1024 ? {
						position: [HeaderAllTemplates.right, HeaderAllTemplates.left].includes(layout) ? 'static' : 'initial',
						right: '30px',
						margin: '0',
					} : {})
				}}
			>
				<Link
					to={`/${eventPath(eventName, eventBundle?.uuid)}/${language || getDefaultLanguage(eventBundle)}/registration#sign-in`}
					className={classNames("no-style", "navigation-link", mainTextOverrides, { [`${overrideColor}-hover`]: mainTextOverrides })}
					style={{
						...(isLessThan1024 && editorLessThan1024 ? {
							margin: '0 0 0 20px'
						} : {})
					}}
				>{t("homepage:Sign In", "")}</Link>
			</div>
		);
	};


	const eventNameOrUrl = eventName || eventBundle && getEventNameURL(eventBundle) || '';

	return (
		<header
			className={classNames(
				'marketing-page-header header-with-language header-fixed-mobile-only',
				`header-${layout}`,
				template,
				backgroundColor,
				{ 'vertical': isUserLoggedIn, 'is-sticky': header?.is_sticky },
			)}
		>
			<div className={classNames("header-container", backgroundColor)} style={backgroundImg}>
				<div className="header-col-left">
					<img
						className="header-logo"
						src={header.logo || undefined}
					/>
				</div>
				<nav
					className="header-navigation"
					style={{
						...(layout === HeaderAllTemplates.left && hasSignInBtn ? {
							position: 'relative',
						} : {})
					}}
				>
					{!languageSelectorOnly && !isLessThan1024 && !editorLessThan1024 && renderLinks()}
					<div
						style={{
							display: 'flex',
							margin: layout === HeaderAllTemplates.left ? '0 0 0 auto' : '0',
						}}
					>
						{
							languages.length > 1 ? (
								<LanguageDropdown
									template={template}
									languages={languages}
									currentLanguageCode={selectedLanguage}
									onLanguageChange={handleLanguageChange}
									className={hasSignInBtn ? 'adjust-for-sign-in-btn' : ''}
								/>
							) : null
						}
					</div>

					{!languageSelectorOnly && (isLessThan1024 || editorLessThan1024) && (
						<NewDropdown
							className="menu-tablet"
							popupClassName="menu-tablet-popup"
							showArrow={false}
							renderTitle={({ isOpen }) =>
								isOpen ? (
									<EIcon name="close" size="large" />
								) : (
									<EIcon name="menu" size="large" />
								)
							}
						>
							<div className="tablet-links">{renderLinks()}</div>
							<LiveCallToAction
								classname={"primary header-call-to-action"}
								template={template}
								buttonText={callToActionText}
								pageLink={pageLink}
								sectionLink={sectionLink}
								urlLink={urlLink}
								eventName={eventNameOrUrl}
								uuid={eventBundle?.uuid}
								selectedLanguage={selectedLanguage}
								registration_on={registration_on}
							/>
						</NewDropdown>
					)}
				</nav>
				<div className="header-col-right">
					{!languageSelectorOnly && !isLessThan1024 && !editorLessThan1024 && (
						<LiveCallToAction
							classname={"primary header-call-to-action"}
							template={template}
							buttonText={callToActionText}
							pageLink={pageLink}
							sectionLink={sectionLink}
							urlLink={urlLink}
							eventName={eventNameOrUrl}
							uuid={eventBundle?.uuid}
							selectedLanguage={selectedLanguage}
							registration_on={registration_on}
						/>
					)}
					{renderSigInButton()}
				</div>
			</div>
		</header>
	);
};

export default Header;
