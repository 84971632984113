import SelectInput from "../../../../../general-ui/select/select";
import { SelectOption } from "../../../../../general-ui/select/select-native";
import TextInput, { Validation } from "../../../../../general-ui/text-input/text";
import { EIcon } from "../../../../../general-ui/icon/icon";
import LargeButton from "../../../../../general-ui/button/large-button";
import classNames from "classnames";
import { UploadFile } from "../../../../../../connection/uploads";
import { useSelector } from "react-redux";
import { EventsState } from "../../../../../../store/types";
import { FieldLabel } from "../../../../../general-ui/field/field";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import { BreakoutRoom } from "../../../../../../types/working-model";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";

interface BreakoutSessionProps {
	attendees?: SelectOption[];
	rooms: BreakoutRoom[];
	onRemove: (room: BreakoutRoom) => void;
	onAttendeeChange: (attendeesCount: string, roomIndex: number) => void;
	selectInputLabel?: string;
	showSelectInput?: boolean;
	uploadInput?: JSX.Element;
	onNameChange?: (event: string, room: BreakoutRoom) => void;
	onDescriptionChange?: (event: string, room: BreakoutRoom) => void;
	onImageChange?: (event: string, room: BreakoutRoom) => void;
	onMaxUsersChange?: (event: number, room: BreakoutRoom) => void;
	onImageUploading?: (uploading: boolean, room: BreakoutRoom) => void;
	onNameFocused?: (focused: boolean, room: BreakoutRoom) => void;
	lastMaxUsersOpen?: () => void;
	onClearImage: (room: BreakoutRoom) => void;
	saveClicked?: boolean;
	creatingBreakouts?: boolean;
	displayMaxUsers: boolean;
}

export const BreakoutRooms = ({
	attendees,
	rooms,
	onRemove,
	onAttendeeChange,
	selectInputLabel = "Max. Attendees *",
	showSelectInput = true,
	uploadInput,
	onNameChange,
	onDescriptionChange,
	onImageChange,
	onImageUploading,
	onNameFocused,
	lastMaxUsersOpen,
	saveClicked,
	onClearImage,
	creatingBreakouts = false,
	displayMaxUsers
}: BreakoutSessionProps): JSX.Element => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	async function handleAddImage(f: File | FileList, room: BreakoutRoom) {
		if (!user || !token) return;
		onImageUploading?.(true, room);
		const uploadedImage = await UploadFile(user, token, f as File);
		onImageChange?.(uploadedImage, room);
		onImageUploading?.(false, room);
	}

	const maxUsersOpened = (roomIndex: number) => {
		// If the last room (bottom of the list) max users dropdown is opened, we want to scroll to the bottom of the list
		// even further because the dropdown adds more height when opened and is hidden behind the scrollable container.
		if (rooms.length - 1 === roomIndex) {
			lastMaxUsersOpen && lastMaxUsersOpen();
		}
	};

	const PrimaryTooltip = (): JSX.Element => <Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12}></Icon>;

	return (
		<>
			{rooms.map((room, index) => (
				<EditBreakoutRoom
					key={index}
					room={room}
					index={index}
					PrimaryTooltip={PrimaryTooltip}
					handleAddImage={handleAddImage}
					onNameChange={onNameChange}
					onDescriptionChange={onDescriptionChange}
					onNameFocused={onNameFocused}
					saveClicked={saveClicked}
					showSelectInput={showSelectInput}
					attendees={attendees}
					onAttendeeChange={onAttendeeChange}
					selectInputLabel={selectInputLabel}
					maxUsersOpened={maxUsersOpened}
					uploadInput={uploadInput}
					onRemove={onRemove}
					onClearImage={onClearImage}
					displayMaxUsers={displayMaxUsers}
					numberOfRooms={rooms.length}
				/>
			))}
			{creatingBreakouts && (
				<div className="breakouts-loading"><WaitingIndicator /></div>
			)}
		</>
	);
};

interface EditBreakoutRoomProps {
	room: BreakoutRoom;
	index: number;
	PrimaryTooltip: () => JSX.Element;
	attendees?: SelectOption[];
	onRemove: (room: BreakoutRoom) => void;
	onAttendeeChange: (attendeesCount: string, roomIndex: number) => void;
	selectInputLabel?: string;
	showSelectInput?: boolean;
	uploadInput?: JSX.Element;
	onNameChange?: (event: string, room: BreakoutRoom) => void;
	onDescriptionChange?: (event: string, room: BreakoutRoom) => void;
	onMaxUsersChange?: (event: number, room: BreakoutRoom) => void;
	onNameFocused?: (focused: boolean, room: BreakoutRoom) => void;
	lastMaxUsersOpen?: () => void;
	saveClicked?: boolean;
	creatingBreakouts?: boolean;
	handleAddImage: (f: File | FileList, room: BreakoutRoom) => void;
	maxUsersOpened: (roomIndex: number) => void;
	onClearImage: (room: BreakoutRoom) => void;
	displayMaxUsers: boolean;
	numberOfRooms: number;
}

export const EditBreakoutRoom = ({
	room: _room,
	index,
	PrimaryTooltip,
	handleAddImage,
	onNameChange,
	onDescriptionChange,
	onNameFocused,
	saveClicked,
	showSelectInput,
	attendees,
	onAttendeeChange,
	selectInputLabel,
	maxUsersOpened,
	uploadInput,
	onRemove,
	onClearImage,
	displayMaxUsers,
	numberOfRooms
}: EditBreakoutRoomProps): JSX.Element => {

	const [room, setRoom] = useState(_room);

	// update local room state so we never work with stale data
	useEffect(() => {
		setRoom(_room);
	}, [_room]);

	return (
		<div className="breakout-room-card" key={room.id}>
			<div className="header">
				<div className="breakout-room-card-title">
					Breakout room {index + 1}
				</div>
				<div className="top-right">
					{numberOfRooms > 1 && (
						<button className="breakout-room-card-remove" onClick={() => onRemove(room)} aria-label="remove breakout room">
							<EIcon name="trash-outline"></EIcon>
						</button>
					)}
				</div>
			</div>
			<div className="breakout-room-card-body">
				<div className="image">
					<div className="image-label field-group">
						<FieldLabel label={"Image"} required={true} tooltip={[PrimaryTooltip, 'This image will serve as a thumbnail visible to users joining this breakout room.']} />
					</div>

					{room.image ?
						<div className={classNames("image-container")}>
							<img
								src={room.image}
							/>
							<button onClick={() => onClearImage(room)} className="no-style no-padding no-margin"><Icon name={ICONS.TRASH} size={14} color={COLORS.WHITE} /></button>
						</div>
						:
						<LargeButton
							title={"Upload"}
							allowedFileTypes={['image/png', 'image/jpeg']}
							onFile={e => handleAddImage(e, room)}
							style={{ width: 112, height: 112, marginTop: 0 }}
							uploading={room.imageUploading}
						/>
					}

				</div>

				<div className="right-side">
					<div className="top-row">
						<div className="room-name">
							<TextInput
								placeholder="Give your room a name"
								label="Room name *"
								onChange={event => setRoom(prev => ({ ...prev, name: event.target.value }))}
								style={{ marginBottom: 16 }}
								valid={!saveClicked || (saveClicked && (room.name.length > 0 || room.nameFocused)) ? Validation.normal : Validation.error}
								onFocus={() => onNameFocused && onNameFocused(true, room)}
								onBlur={() => {
									onNameFocused?.(false, room);
									onNameChange?.(room.name, room);
								}}
								defaultValue={room.name}	// Provided if editing existing session
								value={room.name}
							/>
						</div>
						{displayMaxUsers && showSelectInput && attendees && (
							<div className="max-users">
								<SelectInput
									options={attendees}
									mainIcon={
										<EIcon
											name="keyboard-arrow-down"
											size={14}
											color="white"
										/>
									}
									selected={room.max_users.toString()}
									onChange={(value: string): void =>
										onAttendeeChange(value, index)
									}
									label={selectInputLabel}
									placeholder="Attendees"
									ignorePositioning={true}
									onOpen={() => maxUsersOpened(index)}
								/>
							</div>
						)}
					</div>
					<div className="description">
						<TextInput
							placeholder="Describe what your room will discuss (optional)"
							onBlur={() => onDescriptionChange?.(room.description || '', room)}
							onChange={event => setRoom(prev => ({ ...prev, description: event.target.value }))}
							defaultValue={room.description}	// Provided if editing existing session
							value={room.description}
						/>
					</div>
				</div>
			</div>
			{uploadInput}
		</div>
	);
};