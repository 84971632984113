import React, { useCallback, useEffect, useState } from 'react';

import { useGetLanguage } from '../../../../hooks/language.hooks';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { useAppDispatch, useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import {
	PageModule,
	Survey,
	SurveyQuestionType
} from '../../../../types/working-model';
import { OptionalComponent } from '../../../../utils/optional-component';
import useTranslationRoute from '../../hooks/use-translation-route';
import EngageItem from '../engage-item';
import EngageCardWrapper from './engage-card-wrapper';
import { useGetAnsweredQuestionCount } from './hooks/engage.hooks';
import ProgressBar from './progress-bar';
import {
	checkEngageType,
	removePreviousAnswersFromStorage,
} from './utils/engage.utils';

import './engage-card.scss';
import { setStatsResults } from '../../../../store/actions/admin/surveys';

interface IEngageCardProps {
	engageCardId: number;
	survey: Survey;
	module: PageModule;
	template: string;
	preview?: boolean;
	sessionUuid?: string;
	className?: string;
	activeEngageCard?: number;
	order?: number;
	setActiveEngageCard: (state?: number) => void;
	isEditor?: boolean;
}

export default function EngageCard({
	engageCardId,
	survey,
	module,
	template,
	preview = false,
	sessionUuid,
	className = '',
	activeEngageCard,
	order,
	setActiveEngageCard,
	isEditor
}: IEngageCardProps): JSX.Element {
	const eventId = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.event);
	const answeredQuestionsCount = useGetAnsweredQuestionCount(survey);
	const [currentQuestion, setCurrentQuestion] = useState(answeredQuestionsCount);
	const [viewResults, setViewResults] = useState(false);
	const [showPrevious, setShowPrevious] = useState(false);
	const namespace = useTranslationRoute();
	const { t } = useTranslate([namespace, "session"]);
	const language = useGetLanguage();
	const dispatch = useAppDispatch();

	const {
		survey: surveyId,
		questions,
		allow_retake,
		show_results,
		survey_type
	} = survey;

	const maxQuestions = questions.length;

	const handleRetake = useCallback(() => {
		removePreviousAnswersFromStorage({ eventId, surveyId, preview, questions });
		setCurrentQuestion(0);
		setViewResults(false);

		// REMOVE PREVIOUS ANSWERS FORM STATS RESULTS
		survey.questions.forEach(question => {
			if (question.question_type !== SurveyQuestionType.text) {
				dispatch(setStatsResults(question.survey_question, { total: 0, counts: {} }));
			}
		});

	}, [dispatch, eventId, preview, questions, survey.questions, surveyId]);

	const handleViewResults = () => {
		setViewResults(true);
	};

	const { isSurveyType } = checkEngageType(show_results, survey_type);
	const isAnsweredSurvey = maxQuestions === answeredQuestionsCount;
	const isUnansweredSurvey = maxQuestions !== answeredQuestionsCount;
	const isReviewMode = viewResults && (isAnsweredSurvey || preview);
	const surveyName = t(`survey.${survey.survey}.name`, survey?.name?.[language] as string ?? survey?.name?.base);

	const shouldShowEndScreen = preview
		? maxQuestions === currentQuestion && !isReviewMode && !showPrevious
		: isAnsweredSurvey && !isReviewMode && !showPrevious;

	const shouldShowCardContent = preview
		? maxQuestions !== currentQuestion || isReviewMode || showPrevious
		: isUnansweredSurvey || isReviewMode || showPrevious;

	useEffect(() => {
		if (shouldShowEndScreen) {
			setViewResults(true);
		}
	}, [shouldShowEndScreen]);

	return (
		<EngageCardWrapper
			engageCardId={engageCardId}
			cardName={surveyName}
			survey={survey}
			isSurveyType={isSurveyType}
			forceRetake={handleRetake}
			isReviewMode={isReviewMode}
			engageType={survey_type}
			showResults={show_results}
			className={className}
			preview={preview}
			activeEngageCard={activeEngageCard}
			order={order}
			setActiveEngageCard={setActiveEngageCard}
			onViewResults={handleViewResults}
			isEditor={isEditor}
			module={module}
		>
			<ProgressBar
				survey={survey}
				label={t(`session:progress of ${surveyName}`)}
				preview={preview}
				currentQuestion={currentQuestion}
			/>

			<div className="engage-card-body">
				<OptionalComponent display={shouldShowCardContent}>
					<EngageItem
						sessionUuid={sessionUuid}
						survey={survey}
						module={module}
						template={template}
						preview={preview}
						currentQuestion={currentQuestion}
						setCurrentQuestion={setCurrentQuestion}
						isReviewMode={isReviewMode}
						viewResults={viewResults}
						showPrevious={showPrevious}
						setViewResults={setViewResults}
						handleRetake={handleRetake}
						setShowPrevious={setShowPrevious}
						canRetake={allow_retake}
						isEditor={isEditor}
					/>
				</OptionalComponent>
			</div>
		</EngageCardWrapper>
	);
}
