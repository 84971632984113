import Icon, { COLORS, ICONS } from "@general-ui/icon";
import { Tooltip } from "@general-ui/tooltip/tooltip";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { OptionalComponent } from "utils/optional-component";
import { toggleSpeakers } from "../../../../../../../store/actions/admin/create-event";

import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import Switch from "../../../../../../general-ui/switch/switch";

const SettingsDisplaySpeakers: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const enableSpeakers = workingEvent?.registration_settings?.enableSpeakers;

	const [isOn, setIsOn] = useState(enableSpeakers ?? true);

	const dispatch = useDispatch();

	const handleToggle = (_value: string, _isOn: boolean) => {
		setIsOn(_isOn);
		dispatch(toggleSpeakers(_isOn));
	};
	return (
		<div className="settings-card">
			<label>
				Display Speakers
			</label>

			<div>
				<Switch
					on={isOn}
					onClick={handleToggle}
					value="Enable Speakers"
				/>
			</div>
		</div>
	);
};

export default SettingsDisplaySpeakers;
