import { Get, Put } from "./helpers";
import { LeaderboardMultiplier } from "../types/leaderboard";

export function UpdateActionCustomPoint(token: string, action: LeaderboardMultiplier, channel: number): Promise<LeaderboardMultiplier> {
	return Put(`/v3/admin/leaderboard/multiplier/${channel}`, token, action, true);
}

export function LoadActionCustomPoint(token: string, channel: number, event_id: number): Promise<LeaderboardMultiplier> {
	return Get(`/v3/admin/leaderboard/multipliers/${channel}/${event_id}`, token);
}
