import {
	CreateSurvey,
	LanguagesAbbr,
	Survey,
	SurveyQuestion,
	SurveyQuestionOption,
	SurveyQuestionType,
} from "../types/working-model";
import { Get, Put, Post, GetHv, HvHostMap, PostHv } from "./helpers";

export function GetSurveys(token: string): Promise<Survey[]> {
	return Get("/v3/admin/channel/surveys", token);
}

export function InsertSurvey(
	token: string,
	survey: CreateSurvey,
	languages?: LanguagesAbbr[],
	baseLanguage?: LanguagesAbbr
): Promise<Survey> {
	return Post("/v3/admin/channel/surveys", token, { survey, languages, baseLanguage });
}

export function UpdateSurvey(token: string, survey: Survey, languages?: LanguagesAbbr[], baseLanguage?: LanguagesAbbr): Promise<Survey> {
	return Put("/v3/admin/channel/surveys", token, { survey, languages, baseLanguage }, true);
}

export function GetNewQuestion(token: string): Promise<SurveyQuestion> {
	return Post("/v3/admin/channel/surveys/question", token, {});
}

export function GetNewOption(token: string): Promise<SurveyQuestionOption> {
	return Post("/v3/admin/channel/surveys/question/option", token, {});
}


//  >>>>>>>>> REMOVE THESE ONCE CONNORS CODE IS DONE AND MERGED 1/3/2023
export function GetQuestionStatsForSession(session: number, question: number, question_type: SurveyQuestionType): Promise<{ counts: { [key: string]: number }, total: number }> {
	return Get(`/v3/admin/events/surveys/question-stats/session/${session}/${question}/${question_type}`, "");
}
export function GetQuestionStatsForEvent(event: number, question: number, question_type: SurveyQuestionType): Promise<{ counts: { [key: string]: number }, total: number }> {
	return Get(`/v3/admin/events/surveys/question-stats/event/${event}/${question}/${question_type}`, "");
}
//  >>>>>>>>> REMOVE THESE ONCE CONNORS CODE IS DONE AND MERGED

export function GetSurveyStatsForSession(token: string, session: number, survey: number): Promise<{ counts: { [key: string]: number }, totals: { [key: string]: number } }> {
	return Get(`/v3/admin/events/surveys/survey-stats/session/${session}/${survey}`, token);
}
export function GetSurveyStatsForEvent(token: string, event: number, survey: number): Promise<{ counts: { [key: string]: number }, totals: { [key: string]: number } }> {
	return Get(`/v3/admin/events/surveys/survey-stats/event/${event}/${survey}`, token);
}

export const GetSurveyQuestionResults = async (
	level: 'event' | 'session',
	levelId: number, // ID of session or event
	questionId: number,
	questionType: SurveyQuestionType
): Promise<{ counts: { [key: string]: number }, total: number }> => {
	return GetHv(HvHostMap.surveys, `/e3-survey-question-results/${level}/${levelId}/${questionId}/${questionType}`);
};


export const GetEngagementResults = async (
	eventID: number,
	token: string,
): Promise<{
	[engagement: number]: {
		[question: number]: number
	}
}> => {
	return PostHv(HvHostMap.surveys, `/e3-engagement-results`, { eventID }, token);
};
