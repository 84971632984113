/**
 * Brandlive Notes
 * This file was manually copied from source https://www.gstatic.com/meetjs/index.d.ts
 * TODO - Once this is hosted as a package, remove file and install
 */

// Original file: index.d.ts


declare global {
	export interface Window {
		commonFrames: {
			dialog: (element: Element) => FrameDescriptor<FrameOptions>;
			docked: (element: Element) => FrameDescriptor<FrameOptions>;
		};
		meet: () => MeetAppBuilder;
	}
}


// Original file: frames.types.d.ts

/**
 * A full `FrameSet` with customization available. Specify what elements
 * the `MeetApp` should use for various components.
 *
 * It's recommended that you use the tools on `window.commonFrames` to create
 * these, as they'll set the default descriptor options.
 */
export interface FrameSetDescriptor {
	/**
	 * The frame where Meet shows all dialogs.
	 */
	dialog: FrameDescriptor<FrameOptions>;
	/**
	 * The frame which is docked to a sidebar area.
	 */
	docked: FrameDescriptor<FrameOptions>;
	/**
	 * The frame which is a floating picture-in-picture (PIP).
	 */
	pip: FrameDescriptor<PipFrameOptions>;
}

/**
 * Options for basic frames.
 */
export interface FrameOptions {
	/**
	 * The CSS classes used to format the frame.
	 */
	cssClasses: string;
}

/**
 * Options for the picture-in-picture (PIP) frame.
 */
export interface PipFrameOptions extends FrameOptions { }

/**
 * Element and options pair that describes a given frame.
 */
export interface FrameDescriptor<OptionsType> {
	/**
	 * The element for the frame.
	 */
	element: Element;
	/**
	 * The options for the frame.
	 */
	options?: OptionsType;
}


// Original file: app.types.d.ts


/**
 * A mutable `MeetApp` config.
 */
export interface MeetAppBuilder {
	/**
	 * Create a `MeetApp` from the current `MeetAppBuilder`.
	 * @param args Required configuration parameters for the `MeetApp`.
	 */
	build(args: MeetAppRequiredConfiguration): MeetApp;
	/**
	 * Attach an event handler to a `MeetAppEvent`. You can only attach one
	 * handler to each event type. Repeated handlers overwrite the existing
	 * handler.
	 * @param eventId The event ID.
	 * @param eventHandler The event handler.
	 */
	on<T extends keyof MeetAppCallbacks>(
		eventId: T,
		eventHandler: MeetAppCallbacks[T],
	): this;
	/**
	 * Supplies a custom `FrameSet` for the `MeetApp`. If this method isn't called,
	 * a default `FrameSet` is supplied based on your eventual `parentElement`.
	 * @param frameSet Specifies the frame objects Meet has access to.
	 */
	useFrameSet(frameSet: FrameSetDescriptor): MeetAppBuilder;
	/**
	 * Set the `authUser` field that's passed to the `MeetApp`.
	 * @param authUser The authorized user to use when joining a meeting.
	 *
	 * For example, if there's two Google Accounts logged in ("A" being the
	 * default account and "B" being another account) by default `authUser` is
	 * set to `0` and joins the meeting as account "A". If a client sets
	 * `authUser` to `1` then they join as account "B".
	 */
	setAuthUser(authUser: string): this;
	/**
	 * Set if Meet should ignore browser compatibility.
	 * @param ignoreBrowserCompatibilityChecks Whether the browser compatibility checks should be ignored.
	 *
	 * If true, browser compatibility checks are ignored. If false, browser compatibility checks are set and if compatibility fails an error is thrown. Default: False.
	 */
	setIgnoreBrowserCompatibilityChecks(
		ignoreBrowserCompatibilityChecks: boolean,
	): this;
	/**
	 * Set the host product name for UI customization in Meet.
	 * @param hostProductName The host product name.
	 */
	setHostProductName(hostProductName: string): this;
	/**
	 * Set if Meet should share the host's `window.location.href` with other
	 * participants in the call.
	 * @param shareHostUrl Whether the `hostUrl` should be shared with Meet or not.
	 *
	 * If true, the `hostUrl` is shared. If false, the `hostUrl` isn't shared. Default: False.
	 * @param hostUrl The shared URL when provided. When not provided,
	 *   window.location.href is shared. This param isn't used if `shareHostUrl`
	 *   is false.
	 */
	setHostUrlSharing(shareHostUrl: boolean, hostUrl?: string): this;
	/**
	 * Set the `MeetAppRetryOptions` passed to the `MeetApp`.
	 * @param retryOptions The retry option being passed to the app.
	 */
	useRetryOptions(retryOptions: MeetAppRetryOptions): this;
	/**
	 * Set the `captureHandle` from a client. For more information, see {@link
	 * https://developer.chrome.com/docs/web-platform/capture-handle/
	 * | Capture Handle}.
	 * @param captureHandle Helps collaboration between capturing and captured web apps.
	 */
	setCaptureHandle(captureHandle: string): this;
	/**
	 * Set the app's supported presentation type. This isn't supported on mobile web.
	 * @param presentationSupport The content the user can present to a call.
	 */
	setPresentationSupport(presentationSupport: PresentationSupport): this;
	/**
	 * Set whether the call can be popped out to Meet in an independent
	 * browser tab. This isn't supported on mobile web.
	 * @param supportsPopOut Whether the call can be popped out.
	/**
	 * Set whether the meeting info is shown in the call UI.
	 * @param showMeetingInfo Whether the meeting info is shown in the call UI.
	 *
	 * If true, the meeting info is shown. If false, the meeting info is hidden. Default: True.
	 */
	setShowMeetingInfo(showMeetingInfo: boolean): this;
	/**
	 * Set the theme to be applied to the Meet call UI.
	 * @param theme The theme to be applied to the Meet call UI. Default: Light.
	 */
	setTheme(theme: 'light' | 'dark'): this;
	/**
	 * Set whether the end call rating dialog is shown when call ends.
	 * @param enableEndCallRating Whether the end call rating dialog is shown.
	 *
	 * If true, the end call rating dialog is shown. If false, the dialog isn't shown. Default: True
	 */
	setEnableEndCallRating(enableEndCallRating: boolean): this;
	/**
	 * Set the preferred anonymous username which is used to pre-populate
	 * the name field for an anonymous user. By default, no preferred name is
	 * assigned. If the user isn't anonymous, setting this field has no
	 * effect and their username is used instead.
	 * @param name Set the username for an anonymous meeting participant.
	 */
	setPreferredAnonymousUserName(name: string): this;
	/**
	 * Set whether the chat window is hidden when chat is disabled.
	 * @param hideChatWhenDisabled Whether the chat window is shown when chat is disabled.
	 *
	 * If true, the chat window is hidden. If false, the window is shown. Default: False.
	 */
	setHideChatWhenDisabled(hideChatWhenDisabled: boolean): this;
	/**
	 * Set the OpenID Connect (OIDC) ID token which is used to authorize the user for
	 * pre-configured privileges when joining meetings, such as co-host.
	 * @param oidcIdToken The email address in the OIDC ID token is used to authorize the user when joining meetings.
	 */
	setOidcIdToken(oidcIdToken: string): this;
}

/**
 * Required configuration parameters for a `MeetApp`, supplied at start time.
 * These are separate from the optional parameters supplied using the `MeetAppBuilder`.
 */
export interface MeetAppRequiredConfiguration {
	/**
	 * Optional. OAuth access token used to show proof of user consent. This access
	 * token must have the scope 'https://www.googleapis.com/auth/meetings.host'
	 * included.
	 *
	 * @deprecated You no longer need to show proof of consent through the OAuth token. Meet
	 * shows its own consent dialog to the user before joining a call.
	 */
	accessToken?: string;
	/**
	 * A unique identifier that authenticates requests associated with your
	 * project for usage and billing purposes. For more information, see {@link
	 * https://cloud.google.com/docs/authentication/api-keys
	 * | Authenticate by using API keys}.
	 */
	apiKey: string;
	/** An HTML element to embed the `MeetApp`. */
	parentElement: Element;
}

/**
 * A running Meet app that's attached to some iframes after `init()`. The
 * configuration is immutable. Imperative commands are issued through method
 * calls.
 */
export interface MeetApp {
	/**
	 * Initialize the `MeetApp`. If this method isn't called directly, it's
	 * called internally when another method is called that requires the
	 * `MeetApp` to be initialized.
	 */
	init(): Promise<void>;
	/**
	 * Set the HTML element to be presented. For more information, see {@link
	 * https://developer.chrome.com/docs/web-platform/region-capture/
	 * | Region Capture}.
	 * @param element The HTML element to be presented.
	 */
	cropPresentationsTo(element: HTMLDivElement): Promise<void>;
	/**
	 * Join a Meet call by URL.
	 * @param url The join URL for the call.
	 */
	joinCallFromUrl(url: string): Promise<StartCallResult>;
}

/**
 * Information required to attach a document to the meeting this `MeetApp` is
 * running.
 */
export interface DocumentInfo {
	/**
	 * The URL of the document.
	 */
	url: string;
	/**
	 * The name of the document.
	 */
	name?: string;
}

/** Event object provided to `MeetAppCallbacks.callStarted`. */
export interface CallStartedEvent {
	/**
	 * A unique session ID for each session where a participant joins or leaves a conference.
	 */
	readonly participantSessionId: string;
}

/**
 * All the `HostCallbacks` that the `MeetApp` can attach to.
 */
export interface MeetAppCallbacks {
	/**
	 * Message sent to the host that the call was started.
	 */
	readonly callStarted?: (event: CallStartedEvent) => void;
	/**
	 * Message sent to the host that a link was clicked.
	 */
	readonly linkClicked?: (link: { url: string }) => void;
	/**
	 * Message sent to the host that a host-level toast must be shown.
	 */
	readonly showToast?: (toast: { message: string }) => void;
}

/**
 * Retry options, error handling, and success notifications.
 */
export interface MeetAppRetryOptions {
	/**
	 * An array of millisecond intervals for managing the iframe's loading cycle. The array length
	 * specifies how many retries to attempt. The ith array entry specifies how long to wait before
	 * making the ith retry attempt.
	 */
	pipRetryIntervalsMs?: number[];
	/**
	 * A function called before the picture-in-picture (PIP) iframe is reloaded.
	 * Only called if a successful load at the end of each `pipRetryIntervalsMs`
	 * interval hasn't been detected to be loaded.
	 */
	onBeforePipRetry?: () => void;
	/**
	 * A function called when PIP is successfully loaded.
	 */
	onPipSuccess?: () => void;
	/**
	 * A function called when the final PIP retry times out.
	 */
	onPipTimeout?: () => void;
	/**
	 * A function called when PIP requests the creation of the Dialog iframe.
	 */
	onDialogRequest?: () => void;
	/**
	 * A function called when the Dialog iframe is successfully loaded, after PIP
	 * was successfully loaded.
	 */
	onDialogSuccess?: () => void;
	/**
	 * A function called when the Dialog iframe, requested by PIP, isn't
	 * successfully loaded. This means that the iframe creation process for both PIP
	 * and the Dialog fails.
	 */
	onDialogTimeout?: () => void;
}

/** Possible results of `startCall`. */
export const enum StartCallResult {
	/**
	 * Unknown call result.
	 */
	UNKNOWN_START_CALL_RESULT,
	/**
	 * The requested call is starting.
	 */
	STARTING,
	/**
	 * The start call request was ignored.
	 */
	IGNORED,
	/**
	 * The start call request was ignored because a previous request is still in
	 * progress.
	 */
	IGNORED_START_IN_PROGRESS,
}

/**
 * The types of content the user can present to a call.
 */
export const enum PresentationSupport {
	/**
	 * The user presentation support is unknown.
	 */
	UNKNOWN,
	/**
	 * The user cannot present content to the call.
	 */
	NONE,
	/**
	 * The user can present this tab to the call.
	 */
	THIS_TAB,
	/**
	 * The user can present anything to the call.
	 */
	ANYTHING,
}