import classNames from 'classnames';
import ReactSelect from 'react-select';

import { useTranslate } from '../../../i18n/useTranslationModules';
import { reactSelectStyles } from '../react-select/react-select-styles';
import '../../../scss/live-event/base/general-ui/multi-select.scss';

// TODO: add proper typings
interface IMultiSelectProps {
	options: any;
	onChange: any;
	field: any;
	template: any;
	label?: string;
	defaultValue?: any[];
	placeholder?: string;
	disabled?: boolean;
}

const MultiSelect: React.FC<IMultiSelectProps> = ({
	options,
	onChange,
	field,
	template = '',
	label,
	defaultValue = [],
	placeholder,
	disabled = false
}) => {
	const { t } = useTranslate("homepage");

	return (
		<div className="multi-select-container">
			<label
				className={classNames(
					"evt-field-label",
					template,
					{ 'evt-field-label-required': field.required },
				)}
			>{label ?? field?.name ?? field?.label ?? ''}</label>
			<ReactSelect
				// components={{ DropdownIndicator }}
				classNamePrefix="multi_select"
				className={`multi-select-component ${template}`}
				styles={reactSelectStyles({
					focusedBorderColor: 'var(--accentColor)',
					optionFocusBackgroundColor: 'var(--containerColor)',
					optionFocusColor: 'var(--accentColor)'
				})}
				tabSelectsValue={false}
				isMulti={true}
				options={options}
				onChange={(value, meta) => onChange(value, meta, field)}
				placeholder={placeholder || t('homepage:registration.selectOptions')}
				closeMenuOnSelect={false}
				defaultValue={defaultValue}
				isDisabled={disabled}
			/>
		</div>
	);
};

export default MultiSelect;
