import React, { Dispatch, Fragment, SetStateAction } from 'react';

import classNames from 'classnames';

import { useIsNewModuleGrouping } from '../../../hooks/session.hooks';
import { useTranslate } from '../../../i18n/useTranslationModules';
import {
	PageModule,
	Survey,
	SurveyQuestion,
} from '../../../types/working-model';
import useTranslationRoute from '../hooks/use-translation-route';
import { getEngageItemByType } from './engage-section/utils/engage.utils';
import { OptionalComponent } from '../../../utils/optional-component';
import GenericSurveyQuestion from './session-modules/survey/generic-survey-question';

interface IEngageItemProps {
	sessionUuid?: string;
	survey: Survey;
	module: PageModule;
	template: string;
	preview?: boolean;
	currentQuestion: number;
	setCurrentQuestion: Dispatch<SetStateAction<number>>;
	isReviewMode: boolean;
	viewResults: boolean;
	showPrevious: boolean
	setViewResults: (viewResults: boolean) => void;
	handleRetake: VoidFunction;
	setShowPrevious: (showPrevious: boolean) => void;
	canRetake?: boolean;
	isEditor?: boolean;
}

export default function EngageItem({
	sessionUuid,
	survey,
	module,
	template,
	preview = false,
	currentQuestion,
	setCurrentQuestion,
	isReviewMode,
	viewResults,
	showPrevious,
	setViewResults,
	handleRetake,
	setShowPrevious,
	canRetake,
	isEditor,
}: IEngageItemProps): JSX.Element {
	const namespace = useTranslationRoute();
	const { t } = useTranslate([namespace, "session"]);
	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const { questions, show_results, survey_type } = survey;
	const { name } = getEngageItemByType(show_results, survey_type);
	const maxQuestions = questions.length;

	const renderSurveyQuestion = (question: SurveyQuestion) =>
		<GenericSurveyQuestion
			currentQuestion={currentQuestion}
			isReviewMode={isReviewMode}
			handleShowResults={() => setViewResults(true)}
			maxQuestions={maxQuestions}
			module={module}
			question={question}
			sessionUuid={sessionUuid}
			setCurrentQuestion={setCurrentQuestion}
			setShowPrevious={setShowPrevious}
			survey={survey}
			template={template}
			viewAll={false}
			viewResults={viewResults}
			isEditor={isEditor}
		/>;

	if (isReviewMode) {
		return (
			<>
				{survey.questions.map((question: SurveyQuestion) => (
					<Fragment key={`survey-question-${question.survey_question}`}>
						{renderSurveyQuestion(question)}
					</Fragment>
				))}
				<div className="engage-card-actions">
					<OptionalComponent display={canRetake}>
						<button
							className={classNames({ 'evt-button primary btn-sm btn-font-tiny': isModuleGroupingV2 })}
							onClick={handleRetake}
						>
							{t(`session:Retake ${name.toLowerCase()}`)}
						</button>
					</OptionalComponent>
				</div>
			</>
		);
	}

	return (
		<Fragment key={`survey-question-${questions[currentQuestion]?.survey_question}`}>
			{renderSurveyQuestion(questions[currentQuestion])}
		</Fragment>
	);
}