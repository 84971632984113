import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import classnames from 'classnames';

import { EventsState } from "../../../store/types";
import Icon, { COLORS, ICONS } from "../icon";
import './image-editor.scss';
import { OptionalComponent } from "../../../utils/optional-component";
import { InitialAspectRatioOptionsEnum } from "../../../types/working-model";

interface Props {
	originalImage: string;
	onFinished: (image: File) => void;
	// this should be a required function but I am leaving it optional until all components using this editor have been updated to use ui image-upload-modal
	setEditing?: (inProcess: boolean) => void;
	disableEditingAspectRatio?: boolean;
	initialAspectRatio?: InitialAspectRatioOptionsEnum;
}

function base64ToFile(dataurl: string, filename: string, mimetype: string): Promise<File> {
	return fetch(dataurl).then(res => {
		return res.arrayBuffer();
	}).then(buf => {
		return new File([buf], filename, { type: mimetype });
	});
}

const containerWidth = 560;
const containerHeight = 400;
const imgContainerPadding = 20;
// hide image during reset until actual size and position is calculated
const defaultPosition = { left: 0, top: 0, visibility: "hidden" };
const defaultDraggingCoords = { mouseX: 0, mouseY: 0, imgLeft: 0, imgTop: 0, };

interface AspectRatio {
	text: string;
	w: number;
	h: number;
}
// if aspect ratio (w / h) is greater than container ratio (560 / 400), w = 1, else h =1
// w and h are rounded to the nearest 100th
const aspectRatioList: Array<AspectRatio> = [
	{ text: "1:1", w: 1, h: 1 },
	{ text: "10:3", w: 1, h: .3 },
	{ text: "5:9", w: .56, h: 1 },
	{ text: "3:2", w: 1, h: .67 },
	{ text: "4:3", w: 1.33, h: 1 },
	{ text: "16:9", w: 1, h: .56 },
];

interface dimensions {
	top: number,
	left: number,
	height?: number,
	width?: number
}
export default function ImageEditor({
	originalImage,
	onFinished,
	// temp function for components using this editor that have not been updated to use ui image-upload-modal
	setEditing,
	disableEditingAspectRatio = false,
	initialAspectRatio
}: Props): JSX.Element {

	const {
		workingEvent
	} = useSelector((state: EventsState) => state.CreateEventReducer);

	const [activeAspect, setActiveAspect] = useState<AspectRatio>(initialAspectRatio ? aspectRatioList[initialAspectRatio] : aspectRatioList[0]);
	const [imgDimensions, setImgDimensions] = useState<dimensions>(defaultPosition); //can't start with a predefined width or height
	const [startingImgDimensions, setStartingImgDimensions] = useState<dimensions>(defaultPosition);
	const [isScaling, setIsScaling] = useState(false);
	const [scale, setScale] = useState(1);
	const [, setGenImg] = useState(''); // for debugging

	const [isDragging, setIsDragging] = useState(false);
	const [draggingCoords, setDraggingCoords] = useState(defaultDraggingCoords);

	const image = useRef<HTMLImageElement>(null);
	const container = useRef<HTMLDivElement | null>(null);

	const boxDimensions = useMemo(() => {
		const widerBox = activeAspect.w / activeAspect.h > containerWidth / containerHeight;
		const fitBox = widerBox ? (containerWidth - (imgContainerPadding * 2)) : (containerHeight - (imgContainerPadding * 2));
		const calcWidth = fitBox * activeAspect.w;
		const calcHeight = fitBox * activeAspect.h;
		return {
			width: calcWidth,
			height: calcHeight,
			top: (containerHeight - calcHeight) / 2,
			left: (containerWidth - calcWidth) / 2
		};
	}, [activeAspect]);

	// previous height and width must be cleared before new image is loaded
	useEffect(() => {
		setEditing?.(true);
		setImgDimensions(defaultPosition);
		setStartingImgDimensions(defaultPosition);
	}, [originalImage, setEditing]);

	useEffect(() => {
		if (isScaling || isDragging) setEditing?.(true);
	}, [setEditing, isScaling, isDragging]);

	const handleAspect = () => {
		setTimeout(() => {
			generateImage();
		}, 200);
	};

	function handleMouseDown(e: React.MouseEvent) {
		setDraggingCoords({
			mouseX: e.clientX,
			mouseY: e.clientY,
			imgLeft: imgDimensions.left,
			imgTop: imgDimensions.top
		});
		setIsDragging(true);
		setEditing?.(true);
	}

	function handleMouseUp(e: React.MouseEvent) {
		setDraggingCoords({
			mouseX: e.clientX,
			mouseY: e.clientY,
			imgLeft: imgDimensions.left,
			imgTop: imgDimensions.top
		});
		setIsDragging(false);
		generateImage();
	}

	function handleMouseMove(e: React.MouseEvent) {
		if (isDragging) {
			const deltaX = Math.floor(e.clientX - draggingCoords.mouseX);
			const deltaY = Math.floor(e.clientY - draggingCoords.mouseY);
			const newX = Math.floor(draggingCoords.imgLeft + deltaX);
			const newY = Math.floor(draggingCoords.imgTop + deltaY);

			setImgDimensions({
				...imgDimensions,
				top: newY,
				left: newX
			});
		}
	}

	const handleScale = () => {
		setIsScaling(false);
		setTimeout(() => {
			generateImage();
		}, 200);
	};

	function resizeImg(e: React.ChangeEvent<HTMLInputElement>) {
		if (!startingImgDimensions) return;
		const multiple = e.target.valueAsNumber;
		setScale(multiple);

		if (startingImgDimensions?.height && startingImgDimensions.width) {
			setImgDimensions({
				...imgDimensions,
				height: startingImgDimensions.height * multiple,
				width: startingImgDimensions.width * multiple
			});
		}
	}

	function handleImageLoad({ target }: any) {
		setEditing?.(true);
		const containerRatio = containerWidth / containerHeight;
		const imageRatio = target.width / target.height;

		const fitImage =
			imageRatio > containerRatio ?
				{
					top: (containerHeight - (containerWidth / imageRatio)) / 2,
					left: 0,
					width: containerWidth,
					height: containerWidth / imageRatio,
					visibility: "visible"
				} : {
					top: 0,
					left: (containerWidth - (containerHeight * imageRatio)) / 2,
					width: containerHeight * imageRatio,
					height: containerHeight,
					visibility: "visible"
				};

		setImgDimensions(fitImage);
		setStartingImgDimensions(fitImage);
		setTimeout(() => {
			generateImage();
		}, 200);
	}

	async function generateImage() {
		setEditing?.(true);
		if (image.current && workingEvent) {

			const canvas = document.createElement('canvas');
			canvas.style.position = "fixed";
			canvas.style.zIndex = "2";
			canvas.style.bottom = "0";

			const pixelRatio = (image.current.naturalWidth / image.current.width);

			canvas.width = Math.floor((boxDimensions.width) * pixelRatio * scale);
			canvas.height = Math.floor((boxDimensions.height) * pixelRatio * scale);

			const canvasLeft = (boxDimensions.left - imgDimensions.left);
			const canvasTop = (boxDimensions.top - imgDimensions.top);

			const interpLeft = Math.floor(pixelRatio * canvasLeft);
			const interpTop = Math.floor(pixelRatio * canvasTop);

			const ctx = canvas.getContext('2d');
			ctx?.scale(scale, scale);
			ctx?.drawImage(image.current, -interpLeft, -interpTop);

			// only use blob method for pngs (which might need transparency) because it dramatically increases file size
			if (originalImage.endsWith("png")) {
				canvas.toBlob((blob: Blob | null) => {
					if (blob)
						onFinished(new File([blob], workingEvent.name.replace(/[^a-zA-Z0-9]/g, '-') + ".png"));
				}, 'image/png', 0.7);
			} else {
				const img = canvas.toDataURL('image/jpeg', 0.9);
				setGenImg(img);
				const file = await base64ToFile(img, workingEvent.name.replace(/[^a-zA-Z0-9]/g, '-') + ".jpg", 'image/jpeg');
				onFinished(file);
			}
			if (!isDragging && !isScaling) setEditing?.(false);
		}
	}

	const RatioIcon = ({ aspect }: { aspect: AspectRatio; }): JSX.Element => {
		const ratio = aspect.w / aspect.h;
		const iconWidth = ratio > 1 ? 12 : 12 * ratio;
		const iconHeight = ratio > 1 ? 12 / ratio : 12;
		return (
			<div className="ratio-icon" style={{ width: `${iconWidth}px`, height: `${iconHeight}px` }} />
		);
	};

	return (
		<div className="image-editor">
			<OptionalComponent display={!disableEditingAspectRatio}>
				<div className="aspect-selector" onMouseLeave={handleAspect}>
					{aspectRatioList.map((aspect => (
						<button key={aspect.text} className={classnames({ active: activeAspect.text === aspect.text })} onClick={() => setActiveAspect(aspect)}>
							<RatioIcon aspect={aspect} />
							{aspect.text}
						</button>
					)))}
				</div>
			</OptionalComponent>
			<div
				ref={container}
				className="image-container"
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				onMouseLeave={handleMouseUp}
			>
				<img
					style={imgDimensions}
					ref={image}
					crossOrigin="anonymous"
					src={originalImage}
					alt={"uploaded image"}
					onLoad={handleImageLoad}
				/>
				<div
					style={boxDimensions}
					className="image-window-indicator"
				>
					<div className={`drag-icon-container ${isDragging ? 'dragging' : ''}`}>
						<Icon name={ICONS.DRAG_ARROWS} size={12} color={COLORS.WHITE} />
						<p>Drag to Reposition</p>
					</div>
				</div>
			</div>
			<div className="zoom-slider" onMouseDown={() => setIsScaling(true)} onMouseOut={handleScale}>
				<Icon name={ICONS.ZOOM_OUT} size={14} color={COLORS.DEFAULT_GRAY} />
				<input type="range" min={0.8} max={3} value={scale} step={0.05} onChange={resizeImg} />
				<Icon name={ICONS.ZOOM_IN} size={14} color={COLORS.DEFAULT_GRAY} />
			</div>

			{/* For debugging */}
			{/* <img src={genImg} alt=""style={{position: "fixed", top: "-350px", left: "-650px"}}/> */}
		</div>
	);
}