import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { batch } from "react-redux";

import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { RegFieldsEnum, RegistrationPanelLayoutsTypes, RegistrationQuestion, RegistrationStepType } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../utils/path-utils";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import TextInput from "../../../../../../general-ui/text-input/text";
import SessionPanelAddFooter from "../../../../session/navigation/panel/components/session-panel-add-footer";
import { customProductsItems, extrasProductsItems } from "../../../../session/navigation/panel/empty-state-panel/constants/empty-panel";
import { useFinishNavigate } from "../../../../session/navigation/panel/hooks/panel.hooks";
import AddQuestionCard from "./card-add-question";
import { RegistrationPanelMap } from "@admin/navigation/registration-panel-route-map";
import { toggleCustomizeFormModal, updateRegistrationSteps } from "store/actions/admin/create-event";
import { useFieldRequired } from "utils/registration-required-fields";
import { updateRegistrationQuestions } from "store/actions/admin/registration-questions";
import { toMap } from "utils/utils";

type Props = {
	withinTab?: boolean;
}
const PanelQuestionLibrary: React.FC<Props> = () => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);
	const registrationQuestions = useTypedSelector(state => state.RegistrationQuestionsReducer.registrationQuestions);
	const isFieldRequired = useFieldRequired();

	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);

	const [activeQuestionsForCurrentStep, setActiveQuestionsForCurrentStep] = useState<RegistrationQuestion[]>([]);
	const [activeQuestions, setActiveQuestions] = useState<RegistrationQuestion[]>([]);
	const [requiredQuestions, setRequiredQuestions] = useState<number[]>([]);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customProductsItems : extrasProductsItems;
	const dispatch = useAppDispatch();

	const adminPath = useGetAdminUrl();
	const finish = useFinishNavigate();

	useEffect(() => {
		if (!workingEvent?.registration_steps) return;
		const _activeQuestionsForCurrentStep = workingEvent.registration_steps.find(step => {
			return step.type === registrationStep;
		})?.questions;
		// if question exists in ANY step, don't show it because it completely breaks our sorting
		// because our data structure in workingEvent.registration_questions is an array of numbers
		// that  cover both the general step and the profile step :(
		const _activeQuestions: RegistrationQuestion[] = [];
		workingEvent.registration_steps.forEach(step => {
			if (step.questions?.length) {
				_activeQuestions.push(...step.questions);
			}
		});
		setActiveQuestionsForCurrentStep(_activeQuestionsForCurrentStep || []);
		setRequiredQuestions(workingEvent.required_registration_questions || []);
		setActiveQuestions(_activeQuestions);
	}, [registrationStep, workingEvent?.required_registration_questions, workingEvent?.registration_steps, workingEvent?.registration_questions]);


	const sortedQuestions = (searchTerm: string): RegistrationQuestion[] => {
		const globalQuestions: RegistrationQuestion[] = [];
		const customQuestions: RegistrationQuestion[] = [];

		const _searchTerm = searchTerm.toLowerCase();
		const selectedQuestionMap = activeQuestions.reduce((acc: { [key: string]: string; }, cur) => {
			acc[cur.registration_question] = cur.name;
			return acc;
		}, {});

		registrationQuestions.forEach((_question: RegistrationQuestion) => {
			// if step is not general and question is email, don't show it
			if (registrationStep !== RegistrationStepType.general && _question.registration_question === RegFieldsEnum.email) {
				return;
			}
			// if this question is selected in either general or profile, dont do anything
			if (selectedQuestionMap?.[_question.registration_question]) return;
			// don't show deleted questions
			if (_question.deleted) return;
			if (_question.global) return globalQuestions.push(_question);
			customQuestions.push(_question);
		});

		const filteredQuestions = [globalQuestions, customQuestions].flat().filter(q => q.name.toLowerCase().includes(_searchTerm));
		return filteredQuestions;
	};

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);


	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	const handleToggleQuestion = useCallback((registration_question: number, on: boolean) => {
		if (on) {
			// for now this is just for passcode, to prevent turning it on if there is no shared passcode or passcode list
			if (isFieldRequired.off.includes(registration_question)) {
				showAlert({
					message: "This field can't be used without related registration gating functions.",
					duration: 3000,
					type: "error",
				});
				return;
			}

			setSelectedQuestions([...selectedQuestions, registration_question]);
		}
		else {
			const requiredOn = isFieldRequired.on.includes(registration_question);
			const requiredOnAndOptional = isFieldRequired.onAndOptional.includes(registration_question);
			const requiredOnAndOptionalOrRequired = isFieldRequired.onAndOptionalOrRequired.includes(registration_question);
			if (requiredOn || requiredOnAndOptional || requiredOnAndOptionalOrRequired) {
				showAlert({
					message: `This field ${requiredOn ? 'is required' : 'must be on'} for event features currently in use.`,
					duration: 3000,
					type: "error",
				});
				return;
			}
			if (requiredQuestions.includes(registration_question)) {
				setRequiredQuestions(prev => prev.filter(requiredQn => requiredQn !== registration_question));
			}
			setSelectedQuestions(prev => prev.filter((question) => question !== registration_question));
		}
	}, [isFieldRequired.off, isFieldRequired.on, isFieldRequired.onAndOptional, isFieldRequired.onAndOptionalOrRequired, selectedQuestions, requiredQuestions]);



	const handleDone = async () => {
		if (!token || !workingEvent || !workingEvent.registration_steps) return;
		if (requiredQuestions.length === 0) {
			showAlert({
				message: "Cannot save registration form",
				description: "Your registration form must contain at least one required field",
				duration: 3000,
				type: 'error',
			});
			return;
		}

		const selectedQuestionObjects = registrationQuestions.filter(question => selectedQuestions.includes(question.registration_question as number));

		const updatedSteps = workingEvent.registration_steps.map(step => {
			if (step.type !== registrationStep) return step;

			return { ...step, questions: [...activeQuestionsForCurrentStep, ...selectedQuestionObjects] };
		});

		const newQuestions = Array.from(toMap('registration_question', workingEvent?.registration_questions).values());

		const noDupRequiredQuestions = Array.from(new Set(requiredQuestions));

		batch(() => {
			dispatch(updateRegistrationQuestions([...newQuestions, ...selectedQuestionObjects]));
			dispatch(updateRegistrationSteps(updatedSteps, {
				requiredQuestions: noDupRequiredQuestions,
				registrationQuestionsToAppend: {
					step: registrationStep,
					questions: selectedQuestionObjects,
				},
			}));
		});

		dispatch(toggleCustomizeFormModal(false));

		const path = (() => {
			return adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.ContentGeneralInformation] });
		})();

		finish(path);
	};

	return (
		<div className={classNames("session-panel", { 'has-selections': selectedQuestions.length > 0 })} ref={containerRef}>
			<div className="session-panel-header-options search-only">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
					isAdmin
				/>
			</div>

			{sortedQuestions(searchTerm).length ? (
				<div className="registration-panel-content">
					{sortedQuestions(searchTerm).map((question: RegistrationQuestion) => (
						<AddQuestionCard
							key={question.registration_question}
							question={question}
							selected={selectedQuestions.includes(question.registration_question as number)}
							handleSelectQuestion={handleToggleQuestion}
							requiredQuestions={requiredQuestions}
						/>
					))}
				</div>
			) : (
				<div className="session-panel-no-results padding-24">
					<section>No results</section>
					<SessionPanelAddFooter
						scrollRef={scrollRef}
						items={extrasItems}
						small
					/>
				</div>
			)}

			<div className="session-panel-footer">
				<button onClick={() => {
					selectedQuestions.forEach(question => {
						handleToggleQuestion(question, false);
					});
				}}>Clear Selections</button>

				<button className="lemonade" onClick={handleDone}>Add to page</button>
			</div>
		</div>
	);
};

export default PanelQuestionLibrary;
