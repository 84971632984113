import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { HomepageVideo, Product, Speaker, Sponsor, Survey, Document, EngageModule, PaginatedQuestion, QuestionComment } from "../../types/working-model";
import { FetchError, GetJson } from "../helpers";
import { ESort, GetFullProps, SearchProps, SearchTypes, SearchRequestQuestionsParams, QuestionResponseCommentParams, eFilters } from "./types";
import { GetSearchParams } from "./utils";

export type SearchResults<T> = {
	total: number | null,
	results: T[] | null
}

export const GetItems = <T>(type: SearchTypes) => async (props: SearchProps) => {
	const { token, channelUuid, language } = props;
	const querystring = GetSearchParams(props);

	return await GetJson<SearchResults<T>>({
		token,
		path: `/v3/admin/channel/${channelUuid}/content-items/search/${type}${language ? `/${language}` : ''}?${querystring}`,
	});
};

export const GetItemsFull = <T>(type: SearchTypes | 'surveys-polls-quizzes') => async (props: GetFullProps) => {
	const { token, channelUuid } = props;
	const querystring = `ids=${props.ids.join(',')}`;

	return await GetJson<T[]>({
		token,
		path: `/v3/admin/channel/${channelUuid}/content-items/full/${type}?${querystring}`,
	});
};

export const GetProducts = async (props: SearchProps) => {
	const GetFunc = GetItems<Product>('products');
	return await GetFunc(props);
};

export const GetSpeakers = async (props: SearchProps) => {
	const GetFunc = GetItems<Speaker>('speakers');
	return await GetFunc(props);
};

export const GetSurveys = async (props: SearchProps) => {
	const GetFunc = GetItems<Survey>('surveys');
	return await GetFunc(props);
};

export const GetQuizzes = async (props: SearchProps) => {
	const GetFunc = GetItems<Survey>('quizzes');
	return await GetFunc(props);
};

export const GetPolls = async (props: SearchProps) => {
	const GetFunc = GetItems<Survey>('polls');
	return await GetFunc(props);
};

export const GetSponsors = async (props: SearchProps) => {
	const GetFunc = GetItems<Sponsor>('sponsors');
	return await GetFunc(props);
};

export const GetVideos = async (props: SearchProps) => {
	const GetFunc = GetItems<HomepageVideo>('videos');
	return await GetFunc(props);
};

export const GetDocuments = async (props: SearchProps) => {
	const GetFunc = GetItems<Document>('documents');
	return await GetFunc(props);
};

export const GetEngage = async (props: SearchProps) => {
	const GetFunc = GetItems<EngageModule>('engage');
	return await GetFunc(props);
};

export const GetQuestionResponses = async (token: string, props: SearchRequestQuestionsParams) => {
	const querystring = GetSearchParams(props);
	return await GetJson<SearchResults<PaginatedQuestion>>({
		path: `/v3/admin/channel/${props.channelUuid}/question-responses/search/${props.module_id}/${props.language ?? 'all'}?${querystring}`,
		token
	});
};

export const GetQuestionResponseComments = async (token: string, props: QuestionResponseCommentParams) => {
	return await GetJson<QuestionComment[]>({
		path: `/v3/admin/channel/${props.channelUuid}/question-responses/full/${props.module_id}/${props.question_id}`,
		token
	});
};

const PAGE_SIZE = 10;

export const useContentSearch = <T>(type: SearchTypes, onError: (err: string) => void) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const { language } = useParams<{ language: string }>();
	const [loading, setLoading] = useState(true);
	const page = useRef(1);
	const searchTerm = useRef<string | undefined>();
	const eFilter = useRef<eFilters | undefined>();
	const sort = useRef<ESort | undefined>(ESort.dateDesc);
	const onErrorRef = useRef<(err: string) => void>(onError);
	const userActiveChannel = user?.active_channel;
	const emptyResult = useRef<SearchResults<T>>({ total: null, results: null });
	const lastResults = useRef<SearchResults<T>>(emptyResult.current);

	useEffect(() => {
		onErrorRef.current = onError;
	}, [onError]);

	const loadFuncs = useMemo(() => {
		const load = async () => {
			if (!token || !userActiveChannel) return emptyResult.current;
			const channelUuid = channels.find(c => c.channel === userActiveChannel)?.uuid;
			if (!channelUuid) return emptyResult.current;

			try {
				setLoading(true);


				const res = await GetItems<T>(type)({
					token,
					channelUuid,
					language,
					offset: (page.current - 1) * PAGE_SIZE,
					limit: PAGE_SIZE,
					search: searchTerm.current,
					sort: sort.current,
					eFilter: eFilter.current
				});
				lastResults.current = res as SearchResults<T>;
				return res;
			} catch (e) {
				console.error(e);

				if (e instanceof FetchError) {
					onErrorRef.current(e.error);
				} else {
					onErrorRef.current("Error loading content");
				}

				return emptyResult.current;
			} finally {
				setLoading(false);
			}
		};

		const clear = async (reload?: boolean) => {
			page.current = 1;
			searchTerm.current = undefined;
			if (reload) {
				return await load();
			}
		};

		const setSort = async (userSort?: ESort) => {
			if (userSort === sort.current) {
				return lastResults.current;
			}

			sort.current = userSort;
			page.current = 1;
			return await load();
		};

		const next = async () => {
			if (lastResults.current.total && (lastResults.current.total < page.current * PAGE_SIZE)) {
				// Don't load if you've reached the end of the list
				return;
			}
			page.current++;
			return await load();
		};

		const search = async (search: string) => {
			if (search === searchTerm.current) {
				return lastResults.current;
			}

			page.current = 1;
			searchTerm.current = search;
			return await load();
		};

		const filter = async (filter?: eFilters) => {
			if (filter === eFilter.current) {
				return lastResults.current;
			}

			eFilter.current = filter;
			page.current = 1;
			return await load();
		};

		const fullItems = async <T = any>(type: SearchTypes | 'surveys-polls-quizzes', ids: number[]): Promise<T[]> => {
			const loader = GetItemsFull<T>(type);
			if (!token || !userActiveChannel) return [];
			const channelUuid = channels.find(c => c.channel === userActiveChannel)?.uuid;
			if (!channelUuid) return [];
			return await loader({
				token,
				channelUuid,
				ids
			});
		};
		return {
			load,
			clear,
			setSort,
			next,
			search,
			fullItems,
			filter
		};
	}, [channels, language, token, type, userActiveChannel]);

	return { ...loadFuncs, loading };
};