import { useMemo } from 'react';
import { TemplateClassNames } from '../types/template-layouts';
import { BrandliveEvent } from '../types/working-model';
import { useSessionDetailsV2 } from './channel.hooks';

const limelight = 'limelight'; // The formatted version of Limelight

export const useTemplateName = (template: string | undefined | null, lower = false): string => {
	const isNewSessionDetailsFF = useSessionDetailsV2();
	const templateName = lower ? template?.toLowerCase() : template;

	// If the this is a new event but we turn off the feature flag, we'll need to default to the old template apollo
	// Then they'll be able to turn it back to the new template once the feature flag is back on
	if (!isNewSessionDetailsFF && templateName === limelight) {
		return "apollo";
	}

	return useMemo(() => {
		const override = localStorage.getItem('dev#template_override');
		return override ? override : templateName ?? '';
	}, [templateName]);
};

export const useTemplateFilename = (event: BrandliveEvent | undefined | null): string => {
	const isNewSessionDetailsFF = useSessionDetailsV2();
	const templateFileName = event?.template?.name?.replace(/\s/g, '') ?? '';
	const override = localStorage.getItem('dev#template_override');

	return useMemo(() => {

		// If the this is a new event but we turn off the feature flag, we'll need to default to the old template apollo
		// Then they'll be able to turn it back to the new template once the feature flag is back on
		if (!isNewSessionDetailsFF && templateFileName.toLocaleLowerCase() === limelight) {
			return TemplateClassNames.Apollo;
		}

		if (override) {
			return override;
		} else {
			return templateFileName;
		}
	}, [isNewSessionDetailsFF, override, templateFileName]);
};