import { Access, UserAccess } from "access/access";
import { GetChannelAccess, GetChannelAccessResponse, GrantAccess, ReplaceUserAccess, ReplaceUserAccessBulk, RevokeAccess } from "connection/access";
import { DispatchedAction, DispatchedAsyncAction } from "types/actions";
import { EPermissions } from "types/working-model";

export const ACCESS__GET_USERS = "ACCESS__GET_USERS";
type GetUsersAction = DispatchedAsyncAction<typeof ACCESS__GET_USERS, GetChannelAccessResponse>;
export const getUsers = (channel: number, page: number, limit = 20, search?: string, roleFilter?: EPermissions | ''): GetUsersAction | GetMoreUsersAction => ({
	type: ACCESS__GET_USERS,
	promise: GetChannelAccess(channel, page, limit, search, roleFilter)
});

export const ACCESS__RESET_CHANNEL = "ACCESS__RESET_CHANNEL";
type ResetChannelAction = DispatchedAction<typeof ACCESS__RESET_CHANNEL, never>;
export const resetChannel = () => ({ type: ACCESS__RESET_CHANNEL });

export const ACCESS__GET_MORE_USERS = "ACCESS__GET_MORE_USERS";
type GetMoreUsersAction = DispatchedAsyncAction<typeof ACCESS__GET_MORE_USERS, GetChannelAccessResponse>;
export const getMoreUsers = getUsers;

export const ACCESS__GRANT_USER_ACCESS = "ACCESS__GRANT_USER_ACCESS";
type UpdateUserAccessAction = DispatchedAsyncAction<
	typeof ACCESS__GRANT_USER_ACCESS,
	{
		message: string
	},
	unknown,
	{
		channel: number,
		email: string,
		accesses: Access[]
	}
>;
export const grantUserAccess = (
	channel: number,
	email: string,
	accesses: Access[]
): UpdateUserAccessAction => ({
	type: ACCESS__GRANT_USER_ACCESS,
	promise: GrantAccess(channel, email, accesses.map(access => access.toResourceRequest())),
	meta: { channel, email, accesses }
});

export const ACCESS__REVOKE_CHANNEL_ACCESS = "ACCESS__REVOKE_CHANNEL_ACCESS";
type RevokeChannelAccessAction = DispatchedAsyncAction<
	typeof ACCESS__REVOKE_CHANNEL_ACCESS,
	{
		message: string
	},
	unknown,
	{
		channel: number,
		email: string
	}
>;
export const revokeUserAccessToChannel = (channel: number, email: string): RevokeChannelAccessAction => ({
	type: ACCESS__REVOKE_CHANNEL_ACCESS,
	promise: RevokeAccess(channel, email),
	meta: { channel, email }
});

export const ACCESS__REPLACE_USER_ACCESS = "ACCESS__REPLACE_USER_ACCESS";
export type ReplaceUserAccessAction = DispatchedAsyncAction<
	typeof ACCESS__REPLACE_USER_ACCESS,
	{
		message: string
	},
	unknown,
	{
		channel: number,
		email: string,
		accesses: Access[],
		roles: EPermissions[]
	}
>;
export const replaceUserAccess = (
	channel: number,
	email: string,
	accesses: Access[],
	roles: EPermissions[]
): ReplaceUserAccessAction => ({
	type: ACCESS__REPLACE_USER_ACCESS,
	promise: ReplaceUserAccess(channel, email, accesses.map(access => access.toString()), roles),
	meta: { channel, email, accesses, roles }
});

export type ReplaceUserAccessBulkPayload = ({
	email: string,
	accesses: Access[],
	roles: EPermissions[]
})[];

export const ACCESS__REPLACE_USER_ACCESS_BULK = "ACCESS__REPLACE_USER_ACCESS_BULK";
export type ReplaceUserAccessBulkAction = DispatchedAsyncAction<
	typeof ACCESS__REPLACE_USER_ACCESS_BULK,
	{
		message: string
	},
	unknown,
	{ channel: number, payload: ReplaceUserAccessBulkPayload }
>;

export const replaceUserAccessBulk = (
	channel: number,
	payload: ReplaceUserAccessBulkPayload
): ReplaceUserAccessBulkAction => ({
	type: ACCESS__REPLACE_USER_ACCESS_BULK,
	promise: ReplaceUserAccessBulk(channel, payload.map(({ email, accesses, roles }) => ({
		email,
		statements: accesses.map(access => access.toString()),
		roles
	})),
	),
	meta: { channel, payload }
});

export const ACCESS__UPDATE_ACCESS = "ACCESS__UPDATE_ACCESS";
export type UpdateAccessAction = DispatchedAction<typeof ACCESS__UPDATE_ACCESS, UserAccess>;
export const updateAccess = (access: UserAccess): UpdateAccessAction => ({
	type: ACCESS__UPDATE_ACCESS,
	payload: access
});

export type AccessAction =
	GetUsersAction |
	UpdateUserAccessAction |
	RevokeChannelAccessAction |
	ReplaceUserAccessAction |
	GetMoreUsersAction |
	ResetChannelAction |
	UpdateAccessAction |
	ReplaceUserAccessBulkAction;
