import classNames from 'classnames';
import React from 'react';
import "./grab-your-spot.scss";

interface RegistrationPageProps {
	className?: string,
	title: string | string[],
	buttonText: string,
	onclick: () => void
}

const GrabYourSpot: React.FC<RegistrationPageProps> = ({
	className, title, buttonText, onclick
}): JSX.Element => {

	return (
		<div className={classNames("long-description-mobile-cta", className)}>
			<span>
				{title}
			</span>
			<button onClick={onclick}>
				{buttonText}
			</button>
		</div>

	);
};

export default GrabYourSpot;
