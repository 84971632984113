import { createContext } from 'react';

export enum AppContexts {
	admin = 'admin',
	liveEvent = 'live-event',
	embed = 'embed',
	googleAuth = 'google-auth',
}

const AppContext = createContext(AppContexts.liveEvent);

export default AppContext;