import { useEffect, useMemo, useRef, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";

import { ActionSource, BrandliveEvent, LanguagesAbbr, ModuleMap, PageModuleType, Session, SessionPreview } from "../../../types/working-model";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import MainEventBanner from '../modules/main-event-banner/main-event-banner';
import Header from "./header/header";
import useTranslationModules from "../../../i18n/useTranslationModules";
import { eventPath, getDefaultLanguage, useLanguageParam } from "../utils";
import { useTracking } from "../../../utils/tracking";
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from "../../../utils/error-boundary";
import { clearErrorBoundaryComponents } from "../../../store/actions/event/event-actions";
import { getTemplateClassName } from "../../../utils/utils";
import { getStartTime } from "utils/get-event-start-time";
import { ProjectOverviewContent } from "../registration-v2/project-overview-content";
import images from "images";
import { OptionalComponent } from "utils/optional-component";
import { useTypedSelector } from "store/reducers/use-typed-selector";

const BlankSection = lazy(() => import("../modules/blank-section/blank-section"));
const Speakers = lazy(() => import("../modules/speakers/speakers"));
const Agenda = lazy(() => import('../modules/agenda/agenda'));
const CallOut = lazy(() => import("../modules/call-out/call-out"));
const Sponsors = lazy(() => import("../modules/sponsors/sponsors"));
const Products = lazy(() => import("../modules/products/products"));
const Videos = lazy(() => import("../modules/video/videos"));
const Footer = lazy(() => import("./footer/footer"));
const About = lazy(() => import('../modules/about/about'));
const Accordion = lazy(() => import('../modules/accordion/accordion'));

import "./marketing-page.scss";

interface LandingPageProps {
	previewEventBundle?: BrandliveEvent;
	previewLanguage?: LanguagesAbbr;
	isRegistrationPage?: boolean;
	className?: string;
	editable?: boolean;
	workingEvent?: BrandliveEvent | null;
}

interface LiveModuleMapType {
	[key: number]: any;
}

const LandingPage: React.FC<LandingPageProps> = ({
	previewEventBundle,
	previewLanguage,
	isRegistrationPage,
	className = '',
	editable = true,
	workingEvent,
}) => {
	const _eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const eventBundle = workingEvent ?? _eventBundle;
	const translationUpdates = useTypedSelector(state => state.LiveEventReducer.translationUpdates);

	const homepage = eventBundle?.homepage;
	const template = getTemplateClassName(eventBundle?.template.name);
	const sessions = eventBundle?.sessionsPreviews ? eventBundle.sessionsPreviews as SessionPreview[] : eventBundle?.sessions as Session[];
	const { eventName, uuid }: any = useParams();
	const language = useLanguageParam();
	const history = useHistory();
	const dispatch = useDispatch();

	const hasMainEventBanner = useMemo(() => homepage?.modules.some(module => module.type === PageModuleType.main_event_banner && module.is_on), [homepage?.modules]);
	//check to make sure language is in translated list, if not, push them to the default language
	//in this case english is currently locked as the homepage and registration default
	const notValidTranslation = !eventBundle?.homepage?.languages.includes(language);
	const defaultLangage = eventBundle ? getDefaultLanguage(eventBundle) : 'en';
	const languageToUse = previewLanguage //if in preview module/portal use the passed language beacuse params is not avalable
		? previewLanguage
		: notValidTranslation ? defaultLangage : language;

	const startDateTicks = eventBundle ? getStartTime(eventBundle, false) : undefined;
	const startDate = startDateTicks ? new Date(startDateTicks) : undefined;

	useTranslationModules({
		language: languageToUse
	});

	useEffect(() => {
		if (notValidTranslation && (eventName || uuid) && eventBundle) {
			const baseLang = getDefaultLanguage(eventBundle);
			history.replace(`/${eventPath(eventName, uuid)}/${baseLang}/home`);
		}
	}, [history, eventName, uuid, notValidTranslation, eventBundle]);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	const refMap = useRef<any>({});

	function handleScroll(moduleId?: number, pageModuleType?: PageModuleType) {
		let fullRef: any;
		// if there's a module id, scroll to that
		if (moduleId) {
			fullRef = refMap.current[moduleId];
		} else if (pageModuleType) {
			// else since we can't update already created modules because we never stored any module info, only the module type,
			// we will find the first module based on the type and scroll to that
			const matchedModule = homepage?.modules?.find((module) => {
				return module.is_on && module.type === pageModuleType;
			});
			if (matchedModule?.id) {
				fullRef = refMap.current[matchedModule?.id];
			}
		}
		if (fullRef) {
			fullRef.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}
	}

	const LiveModuleMap: LiveModuleMapType = {
		[PageModuleType.main_event_banner]: MainEventBanner,
		[PageModuleType.speakers]: Speakers,
		[PageModuleType.agenda]: Agenda,
		[PageModuleType.about]: About,
		[PageModuleType.videos]: Videos,
		[PageModuleType.callout]: CallOut,
		[PageModuleType.sponsors]: Sponsors,
		[PageModuleType.products]: Products,
		[PageModuleType.blank]: BlankSection,
		[PageModuleType.accordion]: Accordion,
	};

	const homepageModules = useMemo(() => {
		if (!eventBundle || !homepage) return null;

		const filteredModules = isRegistrationPage ? homepage?.modules.filter(module => module.type !== PageModuleType.main_event_banner) : homepage?.modules;
		return filteredModules.map(module => {
			if (module.is_on) {
				const Module = LiveModuleMap[module.type];
				// sessions, isLive, isUpNext included for Agenda
				return (
					<Suspense fallback="" key={module.id}>
						<ErrorBoundary uniqueLabel={`${ModuleMap[module.type]} ${module.id}`}>
							<Module
								module={module}
								template={template}
								ref={(ref: any) => refMap.current[module.id || 0] = ref}
								sessions={sessions}
								isLive={false}
								isUpNext={false}
								eventBundle={eventBundle}
								isLandingPage
								handleScroll={handleScroll}
								editable={false}
							/>
						</ErrorBoundary>
					</Suspense>
				);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventBundle, homepage, template, sessions, translationUpdates]);

	const languages = Array.from(new Set(homepage?.languages as LanguagesAbbr[])); // new Set to Array for deduping

	// Preview Event variables

	const previewHomepage = previewEventBundle?.homepage;
	const previewTemplate = getTemplateClassName(previewEventBundle?.template.name);
	const previewSessions = previewEventBundle?.sessionsPreviews ? previewEventBundle?.sessionsPreviews as SessionPreview[] : previewEventBundle?.sessions as Session[];

	const previewHomepageModules = useMemo(() => {
		if (!previewEventBundle || !previewHomepage) return null;

		return previewHomepage.modules.map(module => {
			if (module.is_on) {
				const Module = LiveModuleMap[module.type];
				return (
					<Suspense fallback="" key={module.id}>
						<Module
							module={module}
							template={previewTemplate}
							ref={(ref: any) => refMap.current[module.id || 0] = ref}
							sessions={previewSessions}
							isLive={false}
							isUpNext={false}
							eventBundle={previewEventBundle}
							isLandingPage
							editable={editable}
						/>
					</Suspense>
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [previewEventBundle, previewHomepage, previewTemplate, previewSessions, translationUpdates]);

	const { Track } = useTracking({ source_type: ActionSource.Landing, source_id: homepage?.homepage });

	const previewLanguages = Array.from(new Set(previewHomepage?.languages as LanguagesAbbr[]));

	if (previewEventBundle) {
		if (!previewHomepage) return <></>;
		return (
			<div className={`marketing-page ${className ?? ''}`}>
				<Header isUserLoggedIn={false} header={previewHomepage.header} languages={previewLanguages} template={previewTemplate} registration_on={!!previewEventBundle?.registration_on} handleScroll={handleScroll} sendEmailConfirmation={!!previewEventBundle?.registration_settings?.sendEmailConfirmation} previewLanguage={previewLanguage} isLandingPage={true} />
				{previewHomepageModules}
				<OptionalComponent display={previewHomepage.footer && eventBundle?.settings.display_session_footer !== false}>
					<Footer footer={previewHomepage.footer} template={previewTemplate} previewLanguage={previewLanguage} isLandingPage={true} />
				</OptionalComponent>
			</div>
		);
	}

	if (!eventBundle) return <WaitingIndicator />;
	if (!homepage) return <WaitingIndicator />;

	if (isRegistrationPage) {
		return (
			<div className='landing-page-v2' >

				<OptionalComponent display={hasMainEventBanner}>
					<div
						className="registration-heading-container"
						style={{
							backgroundImage: `url(${eventBundle?.registration_settings?.image})`
						}}
					>
						<OptionalComponent display={!eventBundle.registration_settings?.image}>
							<video
								src={images.OrbitsVid}
								poster={images.OrbitsStill}
								autoPlay
								muted
								loop
								controls={false}
								playsInline
								className="registration-background-video"
							/>
						</OptionalComponent>
						{!!eventBundle?.registration_settings &&
							<ProjectOverviewContent startDate={startDate} registration={eventBundle.registration_settings} />}
					</div>
				</OptionalComponent>

				{homepageModules}
				<OptionalComponent display={homepage.footer && eventBundle.settings.display_session_footer !== false}>
					<ErrorBoundary uniqueLabel="Marketing page footer">
						<Footer footer={homepage.footer} template={template} isLandingPage={true} />
					</ErrorBoundary>
				</OptionalComponent>
			</div>
		);
	}


	return (
		<div className="marketing-page">
			<Track>
				<ErrorBoundary uniqueLabel="Marketing page header">
					<Header isUserLoggedIn={false} header={homepage.header} languages={languages} template={template} registration_on={!!eventBundle?.registration_on} handleScroll={handleScroll} sendEmailConfirmation={!!eventBundle?.registration_settings?.sendEmailConfirmation} isLandingPage={true} />
				</ErrorBoundary>
				{homepageModules}
				<OptionalComponent display={homepage.footer && eventBundle.settings.display_session_footer !== false}>
					<ErrorBoundary uniqueLabel="Marketing page footer">
						<Footer footer={homepage.footer} template={template} isLandingPage={true} />
					</ErrorBoundary>
				</OptionalComponent>
			</Track>
		</div>
	);

};

export default LandingPage;
