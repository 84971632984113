import classNames from "classnames";
import { MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIsSingleSessionWithoutHome } from "../../../../../../hooks/session.hooks";
import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import { BrandliveEvent } from "../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../utils/optional-component";
import { getTemplateClassName } from "../../../../../../utils/utils";
import { ICONS } from "../../../../../general-ui/icon";
import { linker } from "../../../../hooks/use-route-map";
import { Actions, VideoStateContext } from "../../session-stream-provider";
import { VideoTitle } from "./titles";
import { ButtonIcon, ControlButton } from "../controls";
import NavigationToggle from "../../../../marketing-page/navigation-v2/navigation-toggle";
import { useScreenMediaQuery } from "../../../../../../utils/use-screen-media-query";
import ActionBar from "../../../session-modules/action-bar/action-bar";

const disable = localStorage.getItem('DISABLE_PAUSED_COVER');

const PausedCover: React.FC<{ event: BrandliveEvent, isFullscreen: boolean }> = ({ event, isFullscreen }) => {
	const {
		state: {
			session,
			idle,
			playing,
			ended,
			moderatorView
		},
		dispatch
	} = useContext(VideoStateContext);

	const [show, setShow] = useState(false);
	const [visible, setVisible] = useState(false);
	const closingTimeout = useRef<NodeJS.Timeout | null>(null);
	const pauseCoverRef = useRef<HTMLDivElement | null>(null);

	const mounted = useRef(true);
	const template = getTemplateClassName(event.template.name);
	const singleSession = useIsSingleSessionWithoutHome();
	const history = useHistory();
	const { t } = useTranslate(["session", "homepage"]);
	const { isLessThan1024 } = useScreenMediaQuery();
	const isDesktop = !isLessThan1024;

	useEffect(() => {
		if (idle && !playing) {
			setShow(true);
			setVisible(true);
			dispatch({ type: Actions.SetOverlayOpen, payload: true });
		} else {
			setVisible(false);
			dispatch({ type: Actions.SetOverlayOpen, payload: false });

			// certain interactions like scrolling down to pip size can cause this 
			// component to become hidden and prevent the CSS transition callback from firing
			// 750ms is 3x the specified animation time, so if the user's machine is particularly sluggish
			// the pause view will still disappear
			closingTimeout.current = setTimeout(() => {
				if (mounted.current) {
					setShow(false);
				}
			}, 750);
		}
	}, [dispatch, idle, playing]);
	
	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const pauseCover = pauseCoverRef.current;
		if (e.target !== pauseCover) return;
		dispatch({ type: Actions.Play, payload: undefined });
	}; 
	
	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	const transitionEnded = useCallback(() => {
		if (closingTimeout.current) {
			clearTimeout(closingTimeout.current);
		}

		if (!visible && mounted.current) {
			setShow(false);
		}
	}, [visible]);

	const showBack = !singleSession;

	const handleBack = () => {
		history.push(linker.getLink('Home'));
	};

	if (!session || disable) return null;

	return (
		<div onTransitionEnd={transitionEnded} className={classNames("paused-cover-container", { 'show': !moderatorView && show, closing: !moderatorView && !visible && show })} ref={pauseCoverRef} onClick={handleClick}>
			{/* Top Controls */}
			<div className="upper">
				<OptionalComponent display={!isFullscreen}>
					{/* Replaces the session header when in use */}
					<div className="left">

						{/* Marketing Nav Button Opener */}
						<NavigationToggle
							hideToggle={false}
							isDesktop={isDesktop}
							forceOn={true}
						/>

						{/* Back Button */}
						<OptionalComponent display={showBack}>
							<ControlButton
								onClick={handleBack}
								tooltip={t('Go back', 'Go back')}
								tooltipClass='under'
							>
								{ButtonIcon(ICONS.ARROW_LEFT_SHADOW)}
							</ControlButton>
						</OptionalComponent>
					</div>
				</OptionalComponent>
			</div>

			<VideoTitle session={session} />
			{!moderatorView && (
				<ActionBar
					session={session}
					event={event}
					template={template}
					className="paused-button-cover-actions"
					includePlay={true}
					ended={ended}
					onClickPlay={() => dispatch({ type: Actions.Play, payload: undefined })}
					forceDark={true}
					isFullscreen={isFullscreen}
				/>
			)}

		</div>
	);
};

export default PausedCover;