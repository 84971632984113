import { ConnectedAdmin, IBreakoutRoomProfile } from "../../types/working-model";
import { SocketMessage } from "./types";

export function isChatMessage(message?: any): message is { chat_message: any[] } {
	if (message && 'chat_messages' in message) {
		return true;
	} else {
		return false;
	}
}

export function assertIsMessage(message?: SocketMessage): asserts message is SocketMessage {
	if (!message || !('type' in message || 'signature' in message)) {
		throw new TypeError("Message received was not of the correct type.");
	}
}

export function assertIsAdmin(admin?: ConnectedAdmin): asserts admin is ConnectedAdmin {
	if (!admin) {
		throw new TypeError("Expected to receive an admin");
	}
}

export function assertIsAdmins(admins?: ConnectedAdmin[]): asserts admins is ConnectedAdmin[] {
	if (!admins) {
		throw new TypeError("Expected to receive admins");
	}
}

export function assertIsBlProfile(blProfile?: IBreakoutRoomProfile): asserts blProfile is IBreakoutRoomProfile {
	if (!blProfile) {
		throw new TypeError("Expected to receive a blProfile");
	}
}

export function assertIsBlProfiles(blProfiles?: IBreakoutRoomProfile[]): asserts blProfiles is IBreakoutRoomProfile[] {
	if (!blProfiles) {
		throw new TypeError("Expected to receive blProfiles");
	}
}

export function assertIsViewers(viewers?: { viewers: string }): asserts viewers is { viewers: string } {
	if (!viewers || !("viewers" in viewers)) {
		throw new TypeError("Expected to receive viewers");
	}
}