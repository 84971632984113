import { SurveyQuestion, SurveyType } from '../../../../../types/working-model';
import { removeStorageItem } from '../../../../../utils/local-storage';
import { ICONS } from '../../../../general-ui/icon';
import {
	getPollAnswerLocalStorageKey,
	getPollAnswerLocalStorageKeyBackwardsCompatible,
} from '../../session-modules/survey/survey';

type TRemovePreviousAnswersFromStorage = (params: {
	eventId?: number;
	surveyId: number;
	preview?: boolean;
	questions: SurveyQuestion[];
}) => void;

export const removePreviousAnswersFromStorage: TRemovePreviousAnswersFromStorage = ({
	eventId,
	surveyId,
	preview,
	questions
}) => {
	if (eventId && !preview) {
		questions.forEach((question: SurveyQuestion) => {
			//remove any previous answers from local storage
			const lsKey = getPollAnswerLocalStorageKey(question, surveyId, eventId);
			const lsKeyBackwardsCompatible = getPollAnswerLocalStorageKeyBackwardsCompatible(question, surveyId);
			removeStorageItem(lsKey);
			removeStorageItem(lsKeyBackwardsCompatible);
		});
	}
};

type TGetEngageItemType = (showResults: boolean, engageType?: SurveyType) => SurveyType;

export const getEngageItemType: TGetEngageItemType = (showResults, engageType) => {
	if (!showResults && engageType === SurveyType.survey) {
		return SurveyType.survey;
	}

	if (!showResults && engageType === SurveyType.quiz) {
		return SurveyType.quiz;
	}

	if (!showResults && engageType === SurveyType.question_prompt) {
		return SurveyType.question_prompt;
	}

	if (!showResults && engageType === SurveyType.comment_box_prompt) {
		return SurveyType.comment_box_prompt;
	}

	return SurveyType.poll;
};

type TCheckEngageType = (showResults: boolean, engageType?: SurveyType) => {
	isSurveyType: boolean;
	isQuizType: boolean;
	isPollType: boolean
};

export const checkEngageType: TCheckEngageType = (showResults, engageType) => {
	const isSurveyType = !showResults && engageType === SurveyType.survey;
	const isQuizType = !showResults && engageType === SurveyType.quiz;
	const isPollType = showResults && engageType === SurveyType.survey;

	return { isSurveyType, isQuizType, isPollType };
};

const BASE_ASSET_URL = process.env.REACT_APP_ASSET_SERVER;
const ENGAGE_ASSET_URL = '/bl-platform/images/engage';

interface IEngageItemConfig {
	icon: string;
	name: string;
	tagIcon: string;
}

export const engageItemsConfig: { [key in SurveyType]: IEngageItemConfig } = {
	[SurveyType.survey]: {
		icon: `${BASE_ASSET_URL}${ENGAGE_ASSET_URL}/survey-end-screen-icon.png`,
		name: 'Survey',
		tagIcon: ICONS.SURVEY,
	},
	[SurveyType.quiz]: {
		icon: `${BASE_ASSET_URL}${ENGAGE_ASSET_URL}/quiz-end-screen-icon.png`,
		name: 'Quiz',
		tagIcon: ICONS.QUIZ,
	},
	[SurveyType.poll]: {
		icon: `${BASE_ASSET_URL}${ENGAGE_ASSET_URL}/poll-end-screen-icon.png`,
		name: 'Poll',
		tagIcon: ICONS.POLL_FILLED,
	},
	[SurveyType.question_prompt]: {
		icon: '',
		name: 'Question',
		tagIcon: ICONS.QUESTION_FILLED,
	},
	[SurveyType.comment_box_prompt]: {
		icon: '',
		name: 'Comment Box',
		tagIcon: ICONS.COMMENT_FULL,
	},
};

type TGetEngageItemByType = (showResults: boolean, engageType?: SurveyType) => IEngageItemConfig;

export const getEngageItemByType: TGetEngageItemByType = (showResults, engageType) => {
	const engageItemType = getEngageItemType(showResults, engageType);

	return engageItemType && engageItemsConfig[engageItemType];
};

interface IGetSurveyTextParams {
	textToReplace: string;
	replaceMapping: { [key: string]: number };
}

type TGetSurveyText = (params: IGetSurveyTextParams) => string;

// NOTE: This function is used to replace the numbers in the survey result text
export const getSurveyText: TGetSurveyText = ({ textToReplace, replaceMapping }) => {
	let result = textToReplace;

	for (const key in replaceMapping) {
		result = result.replace(key, replaceMapping[key].toString());
	}

	return result;
};
