import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { CREATE_ADMIN_EVENT_CHAT, DELETE_ADMIN_EVENT_CHAT, GET_ADMIN_EVENT_CHATS, RENAME_EVENT_CHAT_CHANNEL } from '../../actions/admin/event-chat';
import { IEventChat } from '../event/event-chat-reducer';

interface IAdminEventChat {
	eventChats: IEventChat[];
}

const initialState = {
	eventChats: []
};

export default function AdminEventChatReducer(state: IAdminEventChat = initialState, action: Action): IAdminEventChat {
	switch (action.type) {
		case GET_ADMIN_EVENT_CHATS: {
			return handle(state, action, {
				success: (state) => ({ ...state, eventChats: action.payload })
			});
		}
		case CREATE_ADMIN_EVENT_CHAT: {
			return handle(state, action, {
				success: (state) => ({ ...state, eventChats: [...state.eventChats, action.payload] })
			});
		}
		case DELETE_ADMIN_EVENT_CHAT: {
			return handle(state, action, {
				success: (state) => ({ ...state, eventChats: state.eventChats.filter(eventChat => eventChat.id !== action.payload.deleted) })
			});
		}
		case RENAME_EVENT_CHAT_CHANNEL: {
			return handle(state, action, {
				success: (state) => {
					const chats = state.eventChats.map((chat) => {
						if (chat.id === action.payload.id) {
							return action.payload;
						} else {
							return chat;
						}
					});

					return {
						...state,
						eventChats: chats
					};
				}
			});
		}
		default: return state;
	}
}