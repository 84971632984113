import classNames from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MarkQuestionsAsAnswered } from "../../../../../../../connection/moderator";
import { IRegistrationResponse } from "../../../../../../../connection/registration";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { LanguagesAbbr, SessionPanelLayoutsTypes } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../../general-ui/animated/stagger-children";
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import TextInput from "../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import useCreateModeratorRegistration from "../../../../../../moderation/use-create-moderator-registration";
import PanelHeaderOptions from "../../panel/components/panel-header-options";
import { questionsAnsweredBulk, QuestionsContext, QuestionsProvider } from "../../panel/contexts/engage/questions-state";
import { SessionPanelMap } from "../../panel/session-panel-route-map";
import QuestionMessagesSocketListener from "../hooks/question-moderation-sockets.hooks";
import { useQuestionPrompts } from "../hooks/question-prompts.hooks";
import QuestionCard from "./components/question-card";
import './question-prompt-view.scss';
import { OptionalComponent } from "utils/optional-component";
import useClickAwayListener from "utils/use-click-away-listener";

const EmptyStateImage = `${process.env.REACT_APP_ASSET_SERVER}/Telly_HeartHands.png`;

export enum QFilters {
	all = 'all',
	pending = 'pending',
	notAnswered = 'not-answered',
	answered = 'answered',
	dismissed = 'dismissed',
}

const filterOptions = [
	{ label: 'All Questions', value: QFilters.all },
	{ label: 'Pending', value: QFilters.pending },
	{ label: 'Not Answered', value: QFilters.notAnswered },
	{ label: 'Answered', value: QFilters.answered },
	{ label: 'Dismissed', value: QFilters.dismissed },
];

const QuestionPromptView = ({ isCommentBox }: { isCommentBox?: boolean }) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const moderation = useTypedSelector(state => state.CreateSessionReducer.workingSession?.moderation);
	const session_uuid = useTypedSelector(state => state.CreateSessionReducer.workingSession?.uuid);
	const publishUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const [stateRegistration] = useState<IRegistrationResponse | null>(null);
	const { language } = useParams<{ language: LanguagesAbbr }>();
	const [selectedFilter, setSelectedFilter] = useState(QFilters.all);
	const [state, dispatch] = useContext(QuestionsContext);
	const [userSearchTerm, setUserSearchTerm] = useState('');
	const [selecting, setSelecting] = useState(false);
	const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);
	const [bulkAnswering, setBulkAnswering] = useState(false);
	const panelRef = useRef(null);

	const adminPath = useGetAdminUrl();
	const history = useHistory();
	const handleError = useCallback((e: string) => {
		console.error(e);
		showAlert({
			message: e,
			type: "error",
		});
	}, []);

	const {
		load,
		setFilter,
		search
	} = useQuestionPrompts(handleError);

	const registration = useCreateModeratorRegistration({
		activeEvent: workingEvent,
		waitForAdminUser: true,
		adminUser: user,
		propsToken: token,
		propsLanguage: language,
		propsRegistration: stateRegistration,
		useCache: true
	});

	const handleSearchChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setUserSearchTerm(value);
	};

	const handleSearch = () => {
		if (userSearchTerm.length > 2) {
			search(userSearchTerm);
		} else {
			search('');
		}
	};

	const handleBulkAnswer = async () => {
		try {
			if (!token || !language || !session_uuid) return;
			const unmarked = state.questions.filter(q => !selectedQuestions.includes(q.id)).map(q => q.id);

			setBulkAnswering(true);
			dispatch(questionsAnsweredBulk(selectedQuestions, true));

			await Promise.all([
				MarkQuestionsAsAnswered({
					token,
					question_ids: selectedQuestions,
					language,
					session_uuid,
					answered: true
				}),
				MarkQuestionsAsAnswered({
					token,
					question_ids: unmarked,
					language,
					session_uuid,
					answered: false
				})
			]);

			setSelectedQuestions([]);
			setSelecting(false);
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error marking questions as answered",
				description: "We ran into an issue marking the questions as answered. Please wait and try again.",
				type: "error"
			});
		} finally {
			setBulkAnswering(false);
		}
	};

	useEffect(() => {
		load();
	}, [load]);

	useClickAwayListener({
		ref: panelRef,
		onClickAway: () => null,
		onMousedownClickAway: () => setSelecting(false),
		open: selecting
	});

	if (!publishUrl) {
		return (
			<div className="session-panel flex-center column">
				<h6 style={{ marginTop: -200, opacity: 0.75 }}>Event publish required</h6>
			</div>
		);
	}

	return registration ? (
		<div ref={panelRef}>
			<PanelHeaderOptions>
				<span className={classNames('e-engage-label', { 'comment-box': isCommentBox, 'questions': !isCommentBox })}>
					{isCommentBox ?
						<Icon name={ICONS.COMMENT_FULL} size={12} color="" /> :
						<Icon name={ICONS.QUESTION_FILLED} size={12} color="" />
					}
					{isCommentBox ? "Commment Box" : "Question"}
				</span>
				<NavigationDropdown
					title={<Icon name={ICONS.THREE_DOTS_VERTICAL} size={12} color={COLORS.WHITE} />}
					className="session-panel-dropdown"
					isArrow={false}
					buttonClassName="session-panel-right-header-button round no-style"
					edge="right"
				>
					<>
						<button
							onClick={() => history.replace(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.BannedUsers] }))}
						>
							Banned users
						</button>
						<button onClick={() => {
							setSelecting(prev => !prev);
							setSelectedQuestions(state.questions.reduce<number[]>((acc, cur) => cur.answered ? [...acc, cur.id] : acc, []));
						}}>
							{selecting ? 'Stop Selecting' : 'Select Multiple'}
						</button>
					</>
				</NavigationDropdown>
			</PanelHeaderOptions>
			<div className={classNames("session-panel questions-view", { 'has-selections': selectedQuestions.length })}>
				{(state.loadingQuestions && !state.hasEverLoaded) ? (
					<div className="empty-questions-state">
						<WaitingIndicator fillSpace={true} transparentFill={true} />
					</div>
				) : (
					<>
						{state.questions.length === 0 ? (
							<div className="empty-questions-state">
								<img src={EmptyStateImage} alt="Telly with heart hands" />
								<h5>
									{isCommentBox ? "No comments yet" : "No questions yet"}
								</h5>
							</div>
						) : (
							<>
								<div className="session-panel-header-options with-filter">
									<TextInput
										defaultValue={userSearchTerm}
										onChange={handleSearchChange}
										onBlur={handleSearch}
										onEnterKey={handleSearch}
										placeholder="Search..."
										className="small"
										isAdmin
									/>
									<NavigationDropdown
										title={<Icon name={ICONS.FILTER} size={12} color={COLORS.WHITE} />}
										className="session-panel-dropdown"
										isArrow={false}
										buttonClassName="round"
										edge="right"
									>
										{filterOptions.map(option => (
											<button
												onClick={() => {
													setSelectedFilter(option.value ?? QFilters.all);
													setFilter(option.value);
												}}
												key={option.value}
											>
												{option.label}

												{option.value === selectedFilter && (
													<Icon name={ICONS.CHECKMARK} size={12} color={COLORS.WHITE} />
												)}
											</button>
										))}
									</NavigationDropdown>
								</div>
								<StaggerChildren className={classNames('questions-prompt-list', { selecting: selecting && selectedQuestions.length })}>
									{state.questions.map((question) => (
										<QuestionCard
											key={question.id}
											question={question}
											approvalRequired={moderation?.questions}
											filter={selectedFilter}
											selectedQuestions={selectedQuestions}
											setSelectedQuestions={setSelectedQuestions}
											selecting={selecting}
											isCommentBox={isCommentBox}
										/>
									))}
								</StaggerChildren>
								<OptionalComponent display={selecting}>
									<div className="session-panel-footer">
										<button onClick={() => {
											setSelecting(false);
											setSelectedQuestions([]);
										}}>
											Cancel
										</button>
										<button
											className="lemonade"
											onClick={handleBulkAnswer}
											disabled={bulkAnswering}
										>
											{bulkAnswering ? <WaitingIndicator /> : 'Mark as answered'}
										</button>
									</div>
								</OptionalComponent>
							</>
						)}
					</>
				)}
			</div>
		</div>
	) : (
		<div className="session-panel flex-center column">
			<h6 style={{ marginTop: -200, opacity: 0.75 }}>Creating moderator registration...</h6>
			<br />
			<WaitingIndicator fillSpace={true} transparentFill={true} />
		</div>
	);

};

const QuestionPrompts = ({ isCommentBox }: { isCommentBox?: boolean }) => {
	return (
		<QuestionsProvider>
			<QuestionMessagesSocketListener singleLanguage={false} />
			<QuestionPromptView isCommentBox={isCommentBox} />
		</QuestionsProvider>
	);
};

export default QuestionPrompts;