import { useCallback, useEffect, useState } from "react";

import "../../../scss/live-event/base/general-ui/countdown.scss";
import { useTranslate } from "../../../i18n/useTranslationModules";
import useTimeout from "../../../utils/use-timeout";
import { TypographyItem } from "../typography-item/typography-item";

export const Countdown: React.FC<{
	date: Date;
	template?: string;
}> = ({ date, template }) => {
	const [second, setSecond] = useState(0);
	const [minute, setMinute] = useState(0);
	const [hour, setHour] = useState(0);
	const [day, setDay] = useState(0);
	const { t } = useTranslate("homepage");
	const { runWithTimeout } = useTimeout(() => {
		update();
		runWithTimeout();
	}, 1000);

	const isEnd = useCallback(() => {
		const today = new Date();
		const msBetween = date.valueOf() - today.valueOf();
		return msBetween <= 0;
	}, [date]);

	const update = useCallback((): void => {
		const today = new Date();
		let msBetween = date.valueOf() - today.valueOf();
		msBetween = Math.max(msBetween / 1000, 0);
		setSecond(Math.floor(msBetween % 60));
		msBetween = msBetween / 60;
		setMinute(Math.floor(msBetween % 60));
		msBetween = msBetween / 60;
		setHour(Math.floor(msBetween % 24));
		setDay(Math.floor(msBetween / 24));
	}, [date]);

	useEffect(() => {
		update();

		if (!isEnd()) {
			runWithTimeout();
		}
	}, [update, runWithTimeout, isEnd]);

	// Force all single-digit numbers to be prepended by "0" unless it's a large number like days until event.
	const formatCountdownDigit = (num: number): string => String(num).padStart(2, "0");

	return (
		<div className={`countdown-container ${template}`}>
			<div>
				<TypographyItem className="countdown-number evt-heading-1">
					{formatCountdownDigit(day)}
				</TypographyItem>
				<div className="countdown-label">{t("homepage:DAYS")}</div>
			</div>
			<div className="countdown-separator">:</div>
			<div>
				<TypographyItem className="countdown-number evt-heading-1">
					{formatCountdownDigit(hour)}
				</TypographyItem>
				<div className="countdown-label">{t("homepage:HOURS")}</div>
			</div>
			<div className="countdown-separator">:</div>
			<div>
				<TypographyItem className="countdown-number evt-heading-1">
					{formatCountdownDigit(minute)}
				</TypographyItem>
				<div className="countdown-label">{t("homepage:MINUTES")}</div>
			</div>
			<div className="countdown-separator">:</div>
			<div>
				<TypographyItem className="countdown-number evt-heading-1">
					{formatCountdownDigit(second)}
				</TypographyItem>
				<div className="countdown-label">{t("homepage:SECONDS")}</div>
			</div>
		</div>
	);
};
