import { CustomPage, CustomPageRequest } from "../types/working-model";
import { Post, Put } from "./helpers";

export function CreateCustomPage(page: CustomPageRequest, token: string): Promise<CustomPage> {
	return Post("/v3/admin/events/custom-page", token, page);
}

export function UpdateCustomPage(page: CustomPage, token: string): Promise<CustomPage> {
	return Put("/v3/admin/events/custom-page", token, page, true);
}

export function DeleteCustomPage(page: CustomPage, token: string): Promise<CustomPage> {
	return Put("/v3/admin/events/delete/custom-page", token, page, true);
}