import { useHistory, useParams, useRouteMatch } from 'react-router';
import { ParamsProps } from '../components/live-event/live-event';
import { BrandliveEvent, LanguagesAbbr } from '../types/working-model';
import { setStorageItem } from './local-storage';


function useChangeLanguage(event: BrandliveEvent | null | undefined) {

	const { language } = useParams<ParamsProps>();
	const routeMatch = useRouteMatch<any>();
	const history = useHistory();

	const handleLanguageChange = (newLang: LanguagesAbbr) => {
		if (!event?.event) return;
		if (!language) return;

		// language is set in storage so we can remember the users chosen language so if they enter
		// a session in another language and then go back to the home page, we can reset the language to their chosen language
		setStorageItem(`event.${event.event}.language`, newLang);

		if (newLang && newLang !== language) {
			// replace the language in the url with newLang
			const updatedParams = { ...routeMatch.params };
			updatedParams.language = newLang;
			let updatedUrl = routeMatch.path;
			for (const key in updatedParams) {
				updatedUrl = updatedUrl.replace(`:${key}`, updatedParams[key]);
			}
			history.replace(updatedUrl);
		}
	};
	return handleLanguageChange;
}

export default useChangeLanguage;
