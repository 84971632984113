import React from 'react';
import { useHistory } from 'react-router';

import { useTranslate } from '../../i18n/useTranslationModules';
import { PATHNAMES } from '../../utils/admin-routing-utils';
import { Button } from '../general-ui/button/button';
import './404.scss';


const textFallbacks = {
	adminHeading: "Oops, it's a 404",
	adminText1: "We couldn't find the page you were looking for.",
	adminText2: "Where to next?",
	eventHeading: "We couldn't find the event you were looking for.",
	eventText: "Please double check your URL or check back with your event organizer.",
	previousPage: "Previous page",
	homePage: "Home page",
	support: "Contact Support",
};

interface PageNotFoundProps {
	event?: boolean;
}

const PageNotFound = ({ event }: PageNotFoundProps): JSX.Element => {
	const { t } = useTranslate('homepage');
	const history = useHistory();

	const tiredTelly = `${process.env.REACT_APP_ASSET_SERVER}/assets/telly-tired-404.png`;

	const handleMail = (e: React.MouseEvent) => {
		window.location.href = 'mailto:support@brandlive.com';
		e.preventDefault();
	};

	const handleRedirect = (goTo: 'home' | 'previous'): void => {
		if (goTo === 'previous') {
			history.goBack();
		}
		history.replace(PATHNAMES.Dashboard);
	};

	return (
		<div className='page-not-found'>
			{event ? (
				<div className='event-404'>
					<div className='event-404-content'>
						<div className='event-404-text'>
							<h3>{t('404.event.heading', textFallbacks.eventHeading)}</h3>
							<h4>{t('404.event.text', textFallbacks.eventText)}</h4>
						</div>
						<h1>404</h1>
						<img className='event-telly' src={tiredTelly} alt='brandlive tired telly' />
					</div>
					<Button ariaLabel='contact support' onClick={handleMail} >
						{t('404.support', textFallbacks.support)}
					</Button>
				</div>
			) : (
				<div className='admin-404'>
					<h1>{t('404.admin.heading', textFallbacks.adminHeading)}</h1>
					<h4>{t('404.admin.text1', textFallbacks.adminText1)}</h4>
					<h4>{t('404.admin.text2', textFallbacks.adminText2)}</h4>
					<div className='buttons'>
						<Button ariaLabel='go to previous page' onClick={() => handleRedirect('previous')} >
							{t('404.previousPage', textFallbacks.previousPage)}
						</Button>
						<Button ariaLabel='go to home page' onClick={() => handleRedirect('home')} >
							{t('PageNotFound.homePage', textFallbacks.homePage)}
						</Button>
					</div>
					<img className='admin-telly' src={tiredTelly} alt='brandlive tired telly' />
				</div>
			)}
		</div>
	);
};

export default PageNotFound;