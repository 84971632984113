import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { SurveyType, SurveyQuestion, SurveyQuestionType } from "../../../../../../../../types/working-model";
import { matchSurveyResponseKey } from "../../../../../../../../utils/regex";
import QuestionCardOption from "./question-card-option";
import QuestionCardTextReplies from "./question-card-text-replies";
import './question-card.scss';

type QuestionCardProps = {
	question: SurveyQuestion;
	type: SurveyType;
	questionNumber: number;
}
const QuestionCard = (props: QuestionCardProps) => {
	const { question, questionNumber, type } = props;
	const { language, session: sessionUuid } = useParams<{ language: string, session: string }>();
	const engagementResults = useTypedSelector(state => state.SurveysReducer.engagementResults);
	const [totalResponses, setTotalResponses] = useState(0);
	const [questionResults, setQuestionResults] = useState<Record<number, number>>({});
	const questionId = question.survey_question;

	useEffect(() => {
		let totalResponses = 0;
		const questionResults: Record<number, number> = {};
		const res = Object.entries(engagementResults ?? {});

		for (const [resultKey, value] of res) {
			const match = matchSurveyResponseKey(resultKey);

			if (match) {
				const { survey_question: surveyQuestion, option: optionId } = match;
				if (Number(surveyQuestion) === questionId) {
					totalResponses += value;
					questionResults[Number(optionId)] = value;
				}
			}
		}

		setTotalResponses(totalResponses);
		setQuestionResults(questionResults);
	}, [engagementResults, questionId]);

	const isText = question.question_type === SurveyQuestionType.text;

	return (
		<div className="survey-results-question-card">
			<div className="survey-results-question-card-title">
				<h6>{questionNumber}. {question.title[language] ?? question.title.base}</h6>
			</div>

			<div className="survey-results-question-card-options">
				{!isText ? question.options.map((option, i) => {
					if (!option) return <div key={i}></div>;
					return (
						<QuestionCardOption
							key={option.survey_question_option}
							option={option}
							language={language}
							total={totalResponses}
							responses={questionResults[option.survey_question_option] ?? 0}
							type={type}
						/>
					);
				}) : (
					<QuestionCardTextReplies
						questionId={question.survey_question}
						language={language}
						sessionUuid={sessionUuid}
					/>
				)}
			</div>
		</div>
	);
};

export default QuestionCard;