import classNames from 'classnames';
import { useState, useEffect, useRef, CSSProperties } from 'react';

import '../../../scss/live-event/base/general-ui/tab-header.scss';
import { useHistoryState } from '../../../utils/use-history-state';

interface ITabHeaderProps {
	defaultTab?: string;
	tabs: string[];
	append?: any;
	onClick?: (tab: any) => any;
	currentTab?: string;
	renderTab?: (tab: string) => JSX.Element;
	template?: string;
	underlineSpacing?: string;
	translatedTabs?: string[];
	tabsAsPills?: boolean;
	disabled?: boolean;
	shouldTransformText?: boolean;
	hasDivider?: boolean;
	ignoreHistoryState?: boolean;
	forceUnderline?: boolean;
	bottomBorder?: boolean;
	activeTabClassName?: string;
	wrapperClassNames?: string;
	margin?: React.CSSProperties['margin'];
	padding?: React.CSSProperties['padding'];
	activeLineHeight?: React.CSSProperties['height'];
	buttonClassName?: string;
	inactiveColor?: string;
}

export default function TabHeader(props: ITabHeaderProps): JSX.Element {
	const {
		defaultTab,
		tabs,
		append,
		onClick,
		currentTab,
		template,
		renderTab,
		underlineSpacing,
		translatedTabs,
		tabsAsPills = false,
		disabled = false,
		shouldTransformText = false,
		hasDivider = false,
		ignoreHistoryState = false,
		forceUnderline = false,
		bottomBorder = false,
		activeTabClassName = '',
		wrapperClassNames = '',
		margin,
		padding,
		activeLineHeight = '2px',
		buttonClassName,
		inactiveColor,
	} = props;

	const historyState = useHistoryState<{ currentTab?: string }>();
	const [activeTab, setActiveTab] = useState((!ignoreHistoryState && historyState?.currentTab) ?? defaultTab ?? tabs?.[0] ?? '');
	const tabRefs = useRef<Record<string, HTMLButtonElement | null>>({});

	function clickTab(tab: string) {
		return () => {
			if (disabled || !onClick) return;
			setActiveTab(tab);
			onClick(tab);
		};
	}

	useEffect(() => {
		if (!ignoreHistoryState && historyState?.currentTab) {
			setActiveTab(historyState.currentTab);
		}
	}, [historyState?.currentTab, ignoreHistoryState]);

	useEffect(() => {
		if (currentTab) {
			setActiveTab(currentTab);
		}
	}, [currentTab, tabs]);

	const getTabHeader = (tab: string, index: number) => {
		if (translatedTabs) {
			return translatedTabs?.[index] ?? '';
		} else {
			return tab;
		}
	};

	return (
		<div
			className={classNames(
				"tab-header-container",
				template ? `evt-tab-header ${template}` : "",
				wrapperClassNames,
				{
					'with-divider': hasDivider,
					'force-underline': forceUnderline,
					'bottom-border': bottomBorder,
				},
			)}
			style={{
				...(margin || margin === 0 ? { margin } : {}),
				...(padding || padding === 0 ? { padding } : {}),
				'--active-line-height': activeLineHeight,
			} as CSSProperties}
		>
			<div className="tab-header-buttons">
				{tabs?.map((tab: string, index: number) => (
					<div className="tab-header-button" key={`header-button ${tab} ${index}`}>
						<button
							disabled={disabled && activeTab !== tab}
							className={
								classNames(
									buttonClassName || '',
									{
										active: activeTab === tab,
										[activeTabClassName]: activeTab === tab,
										'tabs-as-pills': tabsAsPills,
									}
								)
							}
							key={tab}
							onClick={clickTab(tab)}
							ref={(ref) => (tabRefs.current[tab] = ref)}
							style={{
								...(underlineSpacing ? { paddingBottom: underlineSpacing } : {}),
								textTransform: shouldTransformText ? 'uppercase' : 'none',
								...(!tab ? { width: 0, padding: 0, margin: 0 } : {}),
								...(inactiveColor && activeTab !== tab ? { color: inactiveColor } : {}),
							}}
						>
							{renderTab ? renderTab(getTabHeader(tab, index)) : <> {getTabHeader(tab, index)}
								{append ? (
									append[index] ? (
										<span className="tab-header-append">&nbsp;{append[index]}</span>
									) : (<></>)
								) : (<></>)}</>}
						</button>
					</div>
				))}
			</div>
		</div>
	);
}
