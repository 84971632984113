export interface ITrayUser {
	user: number;
	tray_id: string;
	channel: number;
	name: string;
	integrations: number[] | null;
	status: number;
}
export interface ITraySolutionInstance {
	authValues: {
		authId: string;
		externalId: string;
	}[];
	configValue: any[];
	configValues: any[];
	created: string;
	enabled: boolean;
	id: string;
	name: string;
	owner: string;
	solutionVersionFlags: {
		hasNewerVersion: boolean;
		requiresSystemInputToUpdateVersion: boolean;
		requiresUserInputToUpdateVersion: boolean;
	}
	solution: {
		id: string;
	}
	workflows: any[];
}

export interface ITraySolution {
	description: string;
	id: string;
	tags: string[];
	title: string;
	type?: EMappedIntegrationTypes;
}

// The tag values will be used to filter
export const SolutionTagTypes = {
	// Environment tag types
	Environment: {
		development: "stage_development",
		production: "stage_production",
		// add more types as they are added to workflow
	},

	// Instance type tags, these will determine where in the live event flow the data is sent
	// as well as the format of data that is sent
	InstanceType: {
		// registration type sends all registration data gathered from every event owned by a given channel, to th endpoint of the instance 
		registration: "type_registration",

		// inbound defines an instance that is receiving data from am external source, and storing it within the app 
		inbound: "type_inbound",

		// activity defines an integration that can be triggered by multiple different user interactions on the event page
		activity: "type_activity",
		// add more tag types as we add different integration types
	},

	// Option tags are miscellaneous options that can be added to a solution instance
	// These options allow different interactions than the traditional integrations workflow
	Options: {
		// no auth defines an instance that doesn't require any authentication values to enable
		no_auth: "no_auth",

		// show passcodes defines an instance that displays a list of passcode lists when configuring the instance
		// more information about this can be found in the Tray Reference doc
		show_passcodes: "show_passcodes",

		// indicates this is an integration that follows the new custom mapping flow.
		// These integrations are treated differently than the standard integrations in both appearance and behavior.
		custom_mapping: "mapping"
	}
};

export const SolutionConfigKeys = {
	// NOTE: tray io state that all config values that are accessed through their api must be prefixed with the string "external_"
	// These config keys will need to be verified in the tray workflow
	external_passcode: "external_passcode",
	external_mapping: "external_mapping"
};

export interface ITrayInstanceToCreate {
	instanceId: string;
	solutionId: string;
}

export interface ITraySolutionMapped {
	[key: string]: ITraySolution;
}

export enum EIntegrationType {
	registration = 1,
	inbound = 2,
	activity = 3, // activity tracking integrations (event/session attendance)
	// add more when defined types are added
}

export enum EIntegrationOverrideType {
	event = "event"
}

export interface IntegrationAction {
	action_endpoint: string;
	action_label: string;
	action_description?: string;
}

export interface IntegrationActionDB extends IntegrationAction {
	id: number;
	solution_uuid: string;
}

export interface InstanceMappingDB {
	channel_mapping: IntegrationsMappedDB;
	entity_mapping: IntegrationsMappedDB;
}

export interface ActivityConfig {
	label: string;
	value: string;
	enabled: boolean;
	activityTypeId?: number;
}

export interface IntegrationsMappedOptions {
	registration_mapping: { [key: string]: string },
	query_mapping: { [key: string]: string },
	outbound_activity_configuration: { crm?: string, enabled_actions: { [key: string]: ActivityConfig } },
	static_mapping?: { [key: string]: string },
	opt_in_mapping: string,
	action: IntegrationAction,
	action_title_keyname?: string,
	confirmation_url_mapping: string | null
}

export interface InstanceConfigValue {
	externalId: { [key: string]: string };
	value: { [key: string]: string }
}

export enum EMappedIntegrationTypes {
	registration = 'registration',
	inbound = 'inbound',
	activity = 'activity'
}

export interface InsertIntegrationsMappedBody extends IntegrationsMappedOptions {
	channel: number;
	solution_uuid: string;
	type: EMappedIntegrationTypes;
	tray_id: string;
	custom_name: string;
	config_values?: InstanceConfigValue[];
	event_groups: string[];
	all_events: boolean;
	confirmation_url_mapping: string | null;
	crm?: string;
}

export interface UpdateIntegrationsMappedBody extends IntegrationsMappedOptions {
	channel: number;
	instance_uuid: string;
	enabled: boolean;
	config_values?: InstanceConfigValue[]
	tray_id: string;
	custom_name: string;
	event_groups: string[];
	all_events: boolean;
	confirmation_url_mapping: string | null;
	type: EMappedIntegrationTypes;
}

export type InsertIntegrationsMappedResponse = {
	inserted: IntegrationsMappedDB;
	integrations_events: IntegratedEventsDB[];
}

export type UpdateIntegrationsMappedResponse = {
	updated: IntegrationsMappedDB;
}

export interface IntegrationsMappedDB {
	instance_uuid: string;
	channel: number;
	solution_uuid: string;
	external_uuid: string;
	name: string;
	webhook_url: string;
	enabled: boolean;
	type: EMappedIntegrationTypes;
	created_by: number;
	created_at: string;
	modified_by?: number;
	modified_at?: string;
	registration_mapping: { [key: string]: string };
	query_mapping: { [key: string]: string };
	outbound_activity_configuration: { crm?: string, enabled_actions: { [key: string]: ActivityConfig } };
	static_mapping?: { [key: string]: string };
	opt_in_mapping: string;
	action_endpoint: string;
	action_label: string;
	action_description?: string;
	action_title_keyname: string;
	confirmation_url_mapping: string | null;
	event_groups: string[];
	all_events: boolean;
}

export interface IntegratedEvent {
	instance_uuid: string;
	event_uuid: string;
}

export interface IntegratedEventsDB extends IntegratedEvent {
	id: number;
	created_at: string;
	created_by: number;
}

export interface InsertMapOverridesBody extends IntegrationsMappedOptions {
	channel: number;
	entity_uuid: string;
	type: EIntegrationOverrideType;
	instance_uuid: string;
}

export interface IntegrationsMapOverridesDB {
	id: number;
	entity_uuid: string;
	type: EIntegrationOverrideType;
	instance_uuid: string;
	action_endpoint: string;
	action_label: string;
	action_description?: string;
	action_title_keyname?: string;
	registration_mapping: { [key: string]: string };
	query_mapping: { [key: string]: string };
	static_mapping: { [key: string]: string };
	outbound_activity_configuration: { crm?: string, enabled_actions: { [key: string]: ActivityConfig } };
	created_at: string;
	created_by: number;
	modified_at: string;
	modified_by: number;
}

export type InsertMapOverridesResponse = {
	inserted: IntegrationsMapOverridesDB;
}

export interface UpdateMapOverridesBody extends IntegrationsMappedOptions {
	channel: number;
	entity_uuid: string;
	instance_uuid: string;
}

export type UpdateMapOverridesResponse = {
	updated: IntegrationsMapOverridesDB;
}

export enum ESolutionNames {
	Salesforce = "Salesforce",
	Hubspot = "HubSpot",
	AddThis = "AddThis",
	GoogleAnalytics = "Google Analytics",
	Saml = 'SAML',
	Marketo = 'Marketo',
	SurveyMonkey = 'SurveyMonkey',
	Custom = 'Custom',
}