import { motion } from "framer-motion";
import { OptionalComponent } from "utils/optional-component";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { PASSCODE_LIST_TYPE } from "../../../../types/working-model";
import GenerateExampleCsv, { GenerateExamplePaidListCsv } from "../../../../utils/generate-csv";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";

type Required = {
	isForPaidList: boolean;
	listType?: never;
} | {
	listType: PASSCODE_LIST_TYPE;
	isForPaidList?: never;
}

type Props = {
	buttonText?: string;
	classNames?: string;
	disabled?: boolean;
	eventUuid?: string;
	noLabel?: boolean;
} & Required;

export function DownloadCsvButton(props: Props) {
	const {
		buttonText = 'Download Template',
		classNames = 'small download-template-button',
		disabled = false,
		isForPaidList = false,
		listType,
		noLabel,
	} = props;

	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	// Magic links example CSV generator currently requires event UUID and token
	const eventUuid = props.eventUuid ?? workingEvent?.uuid;
	const isDisabled = disabled || (listType === PASSCODE_LIST_TYPE.MAGIC_LINKS && !(eventUuid && token));

	return (
		<motion.button
			disabled={isDisabled}
			initial={{ opacity: 0, scale: 0.8 }}
			animate={{ opacity: isDisabled ? 0.4 : 1, scale: 1 }}
			exit={{ opacity: 0 }}
			onClick={() => {
				if (isForPaidList) {
					GenerateExamplePaidListCsv();
				} else {
					GenerateExampleCsv(listType, token, eventUuid);
				}
			}}
			className={classNames}
		>
			<Icon name={ICONS.DOWNLOAD} color={COLORS.WHITE} size={10} />
			<OptionalComponent display={!noLabel}>
				<>
					{buttonText}
				</>
			</OptionalComponent>
		</motion.button>
	);
}