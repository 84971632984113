
import React, { useState } from "react";

import { FeatureFlagsEnum } from "../../../../../../../types/working-model";
import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { EventsState } from "store/types";
import { toggleCloseRegistration } from "store/actions/admin/create-event";

// COMPONENT UI IMPORTS
import { OptionalComponent } from "../../../../../../../utils/optional-component";
import MarketingEmailOptInSwitches from "./marketing-email-opt-in-switches";
import InPersonAttendanceToggleV2 from "../settings/registration-settings/in-person-attendance-toggle-v2";
import DisableNativeRegistrationToggleV2 from "./disable-native-reg-toggle-v2";
import RecaptchaSettings from "./recaptcha-settings";
import Switch from "@general-ui/switch/switch";
import SettingsDisplayOverviewContent from "./settings-display-overview-content";
import SettingsDisplaySpeakers from "./settings-display-speakers";
import PanelBody from "../panel-body";
import RegistrationLimitToggle from "./registration-limit-toggle";

import "./registration-panel.scss";

const PanelAdditionalSettings: React.FC = () => {
	const eventCookieSettingsFlag = useTypedSelector(state => !!state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.event_cookie_settings]);
	const workingEvent = useTypedSelector((state: EventsState) => state.CreateEventReducer.workingEvent);

	const [isRegistrationClosed, setIsRegistrationClosed] = useState(!!workingEvent?.registration_settings?.isClosed);

	const dispatch = useAppDispatch();

	const handleCloseRegistration = async (_value: string, _isClosed: boolean) => {
		setIsRegistrationClosed(_isClosed);
		dispatch(toggleCloseRegistration(_isClosed));
	};

	return (
		<PanelBody>
			<div className="registration-panel-container">
				<InPersonAttendanceToggleV2 />
				<DisableNativeRegistrationToggleV2 />
				<RegistrationLimitToggle />

				<RecaptchaSettings />

				<OptionalComponent display={!eventCookieSettingsFlag}>
					<MarketingEmailOptInSwitches />
				</OptionalComponent>

				<div className="settings-card">
					<label>
						Close Registration
					</label>
					<Switch
						value={"closeRegistration"}
						on={isRegistrationClosed}
						onClick={handleCloseRegistration}
					/>
				</div>

				<SettingsDisplayOverviewContent />
				<SettingsDisplaySpeakers />

				{/* HIDING EMBED CODE */}
				{/* <Link role="button" to={adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.SettingsEmbedCode] })} className="clear no-style">
					<div className="settings-card no-style">
						<div>
							<label>Embed Code</label>
							<p>Configure site specific code</p>
						</div>
						<Icon name={ICONS.ARROW_RIGHT} size={14} color={COLORS.WHITE} />
					</div>
				</Link> */}

			</div>
		</PanelBody>
	);
};

export default PanelAdditionalSettings;
