import { TooltipPortalContentDirection, TooltipPortalPosition } from "./tooltip-portal-types";

interface ISetAnchorPositionProps {
	tooltipChildrenRef: React.RefObject<HTMLDivElement> | null;
	portalContentRef: React.RefObject<HTMLDivElement> | null;
	tooltipContainerRef: React.RefObject<HTMLDivElement> | null;
	position?: TooltipPortalPosition;
	contentDirection?: TooltipPortalContentDirection;
	horizontalOffset?: number;
	tailSize: number;
}

export const setAnchorPosition = ({
	tooltipChildrenRef,
	portalContentRef,
	tooltipContainerRef,
	position = TooltipPortalPosition.TOP,
	contentDirection = TooltipPortalContentDirection.LTR,
	horizontalOffset = 1,
	tailSize
}: ISetAnchorPositionProps) => {
	const _tooltipChildren = tooltipChildrenRef?.current;
	const _portalContent = portalContentRef?.current;
	const _tooltipContainer = tooltipContainerRef?.current;
	if (!_tooltipChildren) return;
	if (!_portalContent) return;
	if (!_tooltipContainer) return;

	const tooltipChildrenRect = _tooltipChildren.getBoundingClientRect();

	let x = tooltipChildrenRect.x;
	let y = tooltipChildrenRect.y - _portalContent.offsetHeight;

	const tailAdjustment = tailSize - 1;

	// Note: anywhere we adjust for tailAdjustment is so the tail can overlap by 1px
	// so that hovering over the tail does not cause the tooltip to disappear
	switch (position) {
		case TooltipPortalPosition.TOP: {
			x = tooltipChildrenRect.x - horizontalOffset;
			y = (tooltipChildrenRect.y - _portalContent.offsetHeight) - tailAdjustment;

			switch (contentDirection) {
				case TooltipPortalContentDirection.CENTER: {
					x = tooltipChildrenRect.x + (tooltipChildrenRect.width / 2) - (_portalContent.offsetWidth / 2);
					break;
				}
				case TooltipPortalContentDirection.RTL: {
					x = tooltipChildrenRect.x + tooltipChildrenRect.width - _portalContent.offsetWidth + horizontalOffset;
					break;
				}
			}
			break;
		}
		case TooltipPortalPosition.RIGHT: {
			x = tooltipChildrenRect.x + tooltipChildrenRect.width + tailAdjustment;
			y = tooltipChildrenRect.y + (tooltipChildrenRect.height / 2) - (_portalContent.offsetHeight / 2);

			switch (contentDirection) {
				case TooltipPortalContentDirection.CENTER: {
					x = tooltipChildrenRect.x + tooltipChildrenRect.width - (_portalContent.offsetWidth / 2);
					break;
				}
				case TooltipPortalContentDirection.RTL: {
					x = tooltipChildrenRect.x + tooltipChildrenRect.width - _portalContent.offsetWidth;
					break;
				}
			}
			break;
		}
		case TooltipPortalPosition.BOTTOM: {
			x = tooltipChildrenRect.x - horizontalOffset;
			y = tooltipChildrenRect.y + tooltipChildrenRect.height + tailAdjustment;

			switch (contentDirection) {
				case TooltipPortalContentDirection.CENTER: {
					x = tooltipChildrenRect.x + (tooltipChildrenRect.width / 2) - (_portalContent.offsetWidth / 2);
					break;
				}
				case TooltipPortalContentDirection.RTL: {
					x = tooltipChildrenRect.x + tooltipChildrenRect.width - _portalContent.offsetWidth + horizontalOffset;
					break;
				}
			}
			break;
		}
		case TooltipPortalPosition.LEFT: {
			x = tooltipChildrenRect.x;
			y = tooltipChildrenRect.y + (tooltipChildrenRect.height / 2) - (_portalContent.offsetHeight / 2);

			switch (contentDirection) {
				case TooltipPortalContentDirection.CENTER: {
					x = tooltipChildrenRect.x - (_portalContent.offsetWidth / 2);
					break;
				}
				case TooltipPortalContentDirection.RTL: {
					x = tooltipChildrenRect.x - _portalContent.offsetWidth - tailAdjustment;
					break;
				}
			}
			break;
		}
	}

	_portalContent.style.left = `${x}px`;
	_portalContent.style.top = `${y}px`;
};
