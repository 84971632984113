import { useEffect, useState } from "react";

type Props = {
	display?: boolean;
	children: JSX.Element | JSX.Element[];
	/** delays rendering children in ms. Useful, for example, to show things like waiting indicators after a specific amount of time */
	delayRender?: number;
};


export function OptionalComponent({ display, children, delayRender = 0 }: Props): JSX.Element | null {
	const [shouldRender, setShouldRender] = useState(delayRender <= 0);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (display && delayRender > 0) {
			timeoutId = setTimeout(() => {
				setShouldRender(true);
			}, delayRender);
		} else {
			setShouldRender(!!display);
		}

		return () => clearTimeout(timeoutId);
	}, [display, delayRender]);

	if (display && shouldRender) {
		return <>{children}</>;
	}

	return null;
}
