import AdminSideNav from "@admin/header/admin-side-nav";
import images from "images";
import "./no-access-admin.scss";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import AdminTopNav from "@admin/header/admin-top-nav";

import { Route, Switch } from "react-router-dom";
import AdminProfile from "@admin/profile/admin-profile";

export default function NoAccessAdmin(): JSX.Element {
	return (
		<div className="no-access-admin side-nav">
			<main className="content-panel-container">
				<Switch>
					<Route path="/admin/profile">
						<AdminProfile />
					</Route>
					<Route path="*">
						<div
							className={'no-access-container'}
							style={{ backgroundImage: `url(${images.NoEventsStars})` }}
						>
							<img src={images.TellyTired} />
							<div className="primary-text">
								Whoops, no access found!
							</div>
						</div>
					</Route>
				</Switch>
			</main>
			<AdminTopNav noOptions={true} />
			<AdminSideNav />
		</div>
	);
}