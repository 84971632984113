const Assets: Record<string, string> = {
	O2_video_background: "O2-session-video-background.jpg",
	Apollo_video_background: "Apollo-session-video-background.jpg",
	'Studio 54_video_background': "Studio54-session-video-background.jpg",
	O2_registration_header: "O2-registration-header.jpg",
	Apollo_registration_header: "Apollo-registration-header.jpg",
	'Studio 54_registration_header': "Studio54-registration-header.jpg"
};

export function getAsset(template: string, name: string): string {
	return `${process.env.REACT_APP_ASSET_SERVER}/template-assets/${Assets[`${template}_${name}`]}`;
}

export default Assets;

export const DirectoryAssets = {
	"Steven": "Steven",
	"Marvin": "Marvin",
	"Victoria": "Victoria",
	"Clara": "Clara",
	"Eva": "Eva",
	"Daniel": "Daniel",
	"Daniel_large": "Daniel_large"
};

export function getDirectoryAsset(name: keyof typeof DirectoryAssets): string {
	return `${process.env.REACT_APP_ASSET_SERVER}/directory/profile-avatars/${DirectoryAssets[name]}.png`;
}