export const publicKey = `MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqxqDZQBEC5ktzM+c9me1
IUXo7xq14Beyw3tyL561DrrU+1XetN/L+pQiNv7Br8LEN6S9C4BAMxvo2ido0BA9
fSUZ3ZlM+xrQ1xzw+alnEAilmQO7T/BjzPhnBn1ldaLgMTnl9jj4jR//XYVOo0iH
8S8ReQhJKB9zCp6OVCvv085hA81wum3UAzPYhaWIBaZcLT5qHZHbm4GBITaotNRv
v1rWPrIoen/0AUO9J5OibG5eRPbGiWU4gPGSxKimv+kPuacAT1nLTR+6AUmOKkzz
mI83e/B9AgLYtA7ofEVxjM4c5X41dZSchSqXKrUl209v1ZaOUstwVPoU/VRjqYfI
vq8pWhHuyYCGXSxWJ73Ntn3A+HjUtZrxVyuaSocOe0seWOHHRCi4jQzqD5vb480K
ryldCu2rLUetbdW1UwoHei+cBQ89gHVIzv1ND0BKyWuOzUcjxnZH7mYpmeUoNXYC
gGsssSOnd8X/12I4xbHxzP1XAlYJod/b+UmdttY0qqbVdCQ0TWCxF8IlqSMtiAdE
WF0AgV+K1s2KTlmezXEsfdfycPf0tKm1udCeA/zrifu9Zx9j+Kjcv6yKrIG8U1Rf
RFtLh8sawgDDLP3AmGTHWuOhbCmxu4Leqb8o7OAKBoc3F35zm9SdJcuj6Lev+8Ma
Z/vxNBWpHGmsv63gdrMr6dMCAwEAAQ==
`;