import { Action, AsyncAction } from "../../../types/actions";
import { BreakoutRoom, IDirectMessage } from "../../../types/working-model";
import { RoomChatMessage } from "../../reducers/event/breakout-chat-reducer";
import db from '../../utils/indexed-db';

export const NEW_BREAKOUT_CHAT_MESSAGE = "NEW_BREAKOUT_CHAT_MESSAGE";
export function newBreakoutChatMessage(message: RoomChatMessage): AsyncAction {
	return {
		type: NEW_BREAKOUT_CHAT_MESSAGE,
		promise: db.insertChatMessage(message)
	};
}

export const LOAD_STORED_MESSAGES = "LOAD_STORED_MESSAGES";
export function loadStoredMessages(breakout: string): AsyncAction {
	return {
		type: LOAD_STORED_MESSAGES,
		promise: db.getChatRoomMessages(breakout)
	};
}

export const SET_ACTIVE_DM_ATTENDEE = "SET_ACTIVE_DM_ATTENDEE";
export function setAttendeeDMAttendee(id: number | null): Action {
	return {
		type: SET_ACTIVE_DM_ATTENDEE,
		payload: id,
	};
}

export const ADD_DIRECT_MESSAGE = "ADD_DIRECT_MESSAGE";
export function addDirectMessage(data: IDirectMessage, opentokId: number | string): Action {
	return {
		type: ADD_DIRECT_MESSAGE,
		payload: { data, opentokId },
	};
}

export const ADD_SENDER_DIRECT_MESSAGE = "ADD_SENDER_DIRECT_MESSAGE";
export function addSenderMessage(data: IDirectMessage, opentokId: number | string): Action {
	return {
		type: ADD_SENDER_DIRECT_MESSAGE,
		payload: { data, opentokId },
	};
}

export const SHOW_DM_NOTIFICATION = "SHOW_DM_NOTIFICATION";
export function showDMNotification(bl_profile: number): Action {
	return {
		type: SHOW_DM_NOTIFICATION,
		payload: { bl_profile },
	};
}

export const CLEAR_DM_NOTIFICATION = "CLEAR_DM_NOTIFICATION";
export function clearDMNotification(bl_profile: number): Action {
	return {
		type: CLEAR_DM_NOTIFICATION,
		payload: { bl_profile },
	};
}

export const SHOW_CHAT_NOTIFICATION = "SHOW_CHAT_NOTIFICATION";
export function showChatNotification(breakoutUuid: BreakoutRoom['uuid']): Action {
	return {
		type: SHOW_CHAT_NOTIFICATION,
		payload: breakoutUuid
	};
}

export const CLEAR_CHAT_NOTIFICATION = "CLEAR_CHAT_NOTIFICATION";
export function clearChatNotification(breakoutUuid: BreakoutRoom['uuid']): Action {
	return {
		type: CLEAR_CHAT_NOTIFICATION,
		payload: breakoutUuid
	};
}
