import { useCallback, useEffect, useState } from "react";
import { GetBannedUsersForEvent, UnbanChatUser } from "../../../../../../connection/moderator";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { BanChatResults } from "../../../../../../types/working-model";
import mTimestamps from "../../../../../../utils/timestamps";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../general-ui/animated/stagger-children";
import Avatar from "../../../../../general-ui/avatar/avatar";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";
const EmptyStateImage = `${process.env.REACT_APP_ASSET_SERVER}/Telly_HeartHands.png`;

const BannedUsers: React.FC = () => {
	const [bannedUsers, setBannedUsers] = useState<BanChatResults[]>([]);
	const [loading, setLoading] = useState(false);
	const [unbanning, setUnbanning] = useState(false);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	const event_uuid = workingEvent?.uuid;
	const session_uuid = workingSession?.uuid;
	const channel = workingEvent?.channel;

	useEffect(() => {
		if (!token || !event_uuid || !channel) return;

		setLoading(true);

		GetBannedUsersForEvent({ token, event_uuid, channel })
			.then(res => {
				setBannedUsers(res);
			})
			.catch(e => {
				console.error(e);
				showAlert({
					message: "Error loading users",
					description: "We ran into an issue loading the banned users. Please wait and try again.",
					type: "error"
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [token, event_uuid, channel]);

	const unbanChatUser = useCallback((bannedUser: BanChatResults) => async () => {
		try {
			if (!token || !session_uuid || !event_uuid || !user) return;

			setUnbanning(true);
			await UnbanChatUser(token, session_uuid, event_uuid, bannedUser.bl_profile, bannedUser.bl_profile_non_reg, user.id);
			setBannedUsers(user => user.filter(u => (u.bl_profile !== bannedUser.bl_profile) && (u.bl_profile_non_reg !== bannedUser.bl_profile_non_reg)));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error unbanning user",
				description: "We ran into an issue unbanning the user. Please wait and try again.",
				type: "error"
			});
		} finally {
			setUnbanning(false);
		}
	}, [event_uuid, session_uuid, token, user]);

	if (!channel) return null;

	return (
		<div className="session-panel banned-users">
			{loading ? (
				<div className="empty-questions-state">
					<WaitingIndicator fillSpace={true} transparentFill={true} />
				</div>
			) : (
				<>
					{bannedUsers.length === 0 ? (
						<div className="empty-questions-state">
							<img src={EmptyStateImage} alt="Telly with heart hands" />
							<h5>No banned users</h5>
						</div>
					) : (
						<>
							<label className="session-panel-section-label">{bannedUsers.length} User{bannedUsers.length === 1 ? '' : 's'}</label>
							<StaggerChildren className="banned-users-list padding-24">
								{bannedUsers.map((user) => {
									const profile = Object.values(user.profile)[0];

									return (
										<div
											key={user.bl_profile + ' ' + user.bl_profile_non_reg}
											className="banned-user-row"
										>
											<Avatar
												imageUrl={profile?.avatar}
												initials={`${profile['1']?.[0] ?? ''}${profile['2']?.[0] ? ' ' + profile['2']?.[0] ?? '' : ''}` || `${profile['3']?.[0]}`}
											/>
											<div className="banned-user-info">
												<p className="banned-user-name">
													{profile?.['1'] ?? ''} {profile?.['2'] ?? ''}
												</p>
												<span className="banned-user-byline">
													{mTimestamps.getPrettyDateTime(user.banned_at)} by {user.admin_profile?.first_name?.[0]} {user.admin_profile?.last_name}
												</span>
											</div>
											<div className="banned-user-action">
												<button disabled={unbanning} onClick={unbanChatUser(user)} className="small">Unban</button>
											</div>
										</div>
									);
								})}
							</StaggerChildren>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default BannedUsers;