import { SetStateAction, useEffect, useMemo, useState } from "react";
import { batch, useDispatch } from "react-redux";

import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { GateTypes, Requirement, RequirementMap } from "../../../../../../../types/working-model";

// UTIL IMPORTS
import { OptionalComponent } from "utils/optional-component";
import { domainRegex, finishedWithRequirement, handleCancelRequirment } from "./requirements-utils";

// GENERAL UI IMPORTS
import TagSelectInput from "../../../../../../general-ui/tag-select/tag-select";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import PillToggleRound from "@general-ui/pill-toggle/pill-toggle-round";
import CardEditRequirementButton from "./card-edit-requirement-button";
import { setBlockedDomainEmails, setRegistrationFeature } from "store/actions/admin/create-event";

interface CardDomainsRequirementProps {
	requirement: Requirement | undefined,
	setAllRequirements: (value: SetStateAction<RequirementMap | undefined>) => void
}

enum EDomainTypes {
	allowed = "Allowed",
	blocked = "Blocked"
}

const CardDomainsRequirement: React.FC<CardDomainsRequirementProps> = ({ requirement, setAllRequirements }) => {
	// The original domain requirement. To be used when resetting the req

	const workingEvent = useTypedSelector((state) => state.CreateEventReducer.workingEvent);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);

	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const registration_settings = workingEvent?.registration_settings;

	// The copy of the original requirement to be edited
	const [workingGateRequirement, setWorkingGateRequirement] = useState<Requirement | undefined>({ ...requirement } as Requirement | undefined);
	const [workingBlockedDomains, setWorkingBlockedDomains] = useState<string[]>(registration_settings?.blockedDomainEmails ?? []);

	const [isAllowed, setIsAllowed] = useState<boolean>(!!workingGateRequirement?.allowedDomains?.length);

	const [editRequirement, setEditRequirement] = useState(!requirement?.allowedDomains);
	const [popoverOpen, setPopoverOpen] = useState<GateTypes | null>(null);
	const [formError, setFormError] = useState("");
	const [saving, setSaving] = useState<boolean>(false);

	const domainsString = useMemo(() => {
		if (isAllowed) {
			return (requirement?.allowedDomains?.join(', ') ?? "");
		} else {
			return (workingBlockedDomains.join(', '));
		}
	}, [isAllowed, requirement?.allowedDomains, workingBlockedDomains]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!requirement?.allowedDomains) {
			setEditRequirement(true);
		}

		if (editRequirement) {
			setWorkingGateRequirement(requirement);
		}
	}, [editRequirement, requirement]);

	function handleBlockedDomains(domains: string[]) {
		//remove @, and change to lower case

		const formErrorMessage = domainRegex(domains);
		if (formErrorMessage) {
			setFormError(formErrorMessage);
			return;
		} else {
			setFormError("");
		}

		const constrainedDomains = domains.map(domain => domain.replace("@", "").toLowerCase());
		setWorkingBlockedDomains(constrainedDomains);
	}

	function handleAllowedDomains(domains: string[]) {
		//remove @, and change to lower case
		if (domains.length) {
			const formErrorMessage = domainRegex(domains);
			if (formErrorMessage) {
				setFormError(formErrorMessage);
				return;
			} else {
				setFormError("");
			}
		}

		const constrainedDomains = domains.map(domain => domain.replace("@", "").toLowerCase());

		const updatedDomainGating = { ...requirement, allowedDomains: constrainedDomains } as Requirement | null;
		if (updatedDomainGating) {
			setWorkingGateRequirement(updatedDomainGating);
		}
	}

	function handleCancel() {
		handleCancelRequirment({
			setEditRequirement,
			setAllRequirements,
			requirement,
			setWorkingGateRequirement,
			gatingType: GateTypes.domain_email,
		});
	}

	function handleSave() {
		if (isAllowed) {
			finishedWithRequirement({ user, token, fetchingPublishedStatus, setFormError, workingGateRequirement, dispatch, setWorkingGateRequirement, setSaving, setEditRequirement });
		} else {
			setSaving(true);
			batch(() => {
				dispatch(setRegistrationFeature("useBlockedDomainEmails", !!workingBlockedDomains?.length));
				dispatch(setBlockedDomainEmails(workingBlockedDomains));
			});
			setSaving(false);
		}

		setEditRequirement(false);
	}

	return (
		<div className="display-options">
			<OptionalComponent display={!editRequirement}>
				<>
					<CardEditRequirementButton
						type={GateTypes.domain_email}
						setEditRequirement={setEditRequirement}
						popoverOpen={popoverOpen}
						setPopoverOpen={setPopoverOpen}
						setAllRequirements={setAllRequirements}
					/>

					<div className="display-option-block">
						<div className="display-option-title">Type</div>
						<div className="display-option-content">{isAllowed ? EDomainTypes.allowed : EDomainTypes.blocked} </div>
					</div>

					<div className="display-option-block">
						<div className="display-option-title">Domains</div>
						<div className="display-option-content">{domainsString}</div>
					</div>
				</>
			</OptionalComponent>

			<OptionalComponent display={!!editRequirement}>
				<>
					<PillToggleRound
						selected={isAllowed ? 1 : 0}
						value={isAllowed ? EDomainTypes.allowed : EDomainTypes.blocked}
						onClick={(_, selected) => setIsAllowed(!!selected)}
						leftText={EDomainTypes.allowed}
						rightText={EDomainTypes.blocked}
					/>
					{isAllowed
						? <TagSelectInput
							onChange={handleAllowedDomains}
							label={"Allowed Email Domains *"}
							placeholder="Press ENTER after each domain input"
							tags={workingGateRequirement?.allowedDomains ?? []}
							errorMessage={formError}
							disableFocus
						/>
						: <TagSelectInput
							onChange={handleBlockedDomains}
							label={"Blocked Email Domains *"}
							placeholder="Press ENTER after each domain input"
							tags={workingBlockedDomains}
							errorMessage={formError}
							disableFocus
						/>
					}

					<div className="requirement-footer">
						<button onClick={handleCancel}>Cancel</button>
						<button onClick={handleSave} className="primary rainbow">{saving ? <WaitingIndicator /> : "Save"}</button>
					</div>
				</>
			</OptionalComponent>
		</div>
	);
};
export default CardDomainsRequirement;
