import React, { useMemo } from 'react';
import { batch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { toggleDisplayLiveEventBanner, updateHomepageModule } from '../../../../../../../../store/actions/admin/create-event';
import { useTypedSelector, useAppDispatch } from '../../../../../../../../store/reducers/use-typed-selector';
import { HomepageMainEventBanner, PageModule, SessionTypesFeatures } from '../../../../../../../../types/working-model';
import { PATHNAMES } from '../../../../../../../../utils/admin-routing-utils';
import { OptionalComponent } from '../../../../../../../../utils/optional-component';
import Switch from '../../../../../../../general-ui/switch/switch';

interface IMainEventBannerAdvancedLayoutProps {
	module: PageModule;
}

const MainEventBannerAdvancedLayout: React.FC<IMainEventBannerAdvancedLayoutProps> = ({ module }) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const displayLiveBanner = useTypedSelector(state => state.CreateEventReducer.displayLiveBanner);

	const content: HomepageMainEventBanner = module.content;

	const match = useRouteMatch();

	const isLandingPage = match.path === PATHNAMES.Event.Landing;

	const eventHasLiveSessions = useMemo(() => {
		// backward compatible: check if any session has a broadcast date, then turn on countdown, enable live banner, and preview by default
		return workingEvent?.sessions.some(session => SessionTypesFeatures[session.session_type].has_start_time) ?? false;
	}, [workingEvent?.sessions]);

	const countdownIsOnInit = ("countdownIsOn" in content)
		? content.countdownIsOn
		: eventHasLiveSessions;

	const enableLiveBannerInit = ("enable_live_banner" in content)
		? content?.enable_live_banner ?? true
		: eventHasLiveSessions;

	const enableDynamicMainEventBannerHeight = !!content?.enableDynamicMainEventBannerHeight;

	const dispatch = useAppDispatch();

	function toggleCountdown(_: any, _isOn: boolean) {
		dispatch(
			updateHomepageModule(
				{
					...module,
					content: {
						...module.content,
						countdownIsOn: _isOn
					}
				}
			)
		);
	}

	function toggleEnableLiveBanner(_: any, _isOn: boolean) {
		batch(() => {
			dispatch(
				updateHomepageModule(
					{
						...module,
						content: {
							...module.content,
							enable_live_banner: _isOn
						}
					}
				)
			);
			dispatch(toggleDisplayLiveEventBanner(_isOn));
		});
	}



	function toggleDisplayLiveBanner(_: any, isOn: boolean) {
		dispatch(toggleDisplayLiveEventBanner(isOn));
	}

	function toggleEnableDynamicMainEventBannerHeight(_: any, isOn: boolean) {
		dispatch(
			updateHomepageModule(
				{
					...module,
					content: {
						...module.content,
						enableDynamicMainEventBannerHeight: isOn
					}
				}
			)
		);
	}

	return (
		<>
			<div className="settings-row">
				<Switch
					value={"Count Down"}
					on={!!countdownIsOnInit}
					onClick={toggleCountdown}
				/>
				<p>Countdown</p>
			</div>
			<div className="settings-row">
				<Switch
					value="highlight-live-sessions"
					on={enableDynamicMainEventBannerHeight}
					onClick={toggleEnableDynamicMainEventBannerHeight}
				/>
				<p>Enable Dynamic Main Event Banner Height</p>
			</div>
			<OptionalComponent display={!isLandingPage}>
				<>
					<div className="settings-row">
						<Switch
							value="highlight-live-sessions"
							on={enableLiveBannerInit}
							onClick={toggleEnableLiveBanner}
							disabled={!eventHasLiveSessions}
						/>
						<p>Enable Highlighted Live Sessions</p>
					</div>
					<div className="settings-row">
						<Switch
							value="highlight-live-sessions"
							on={eventHasLiveSessions && displayLiveBanner}
							onClick={toggleDisplayLiveBanner}
							disabled={!eventHasLiveSessions || !enableLiveBannerInit}
						/>
						<p>Preview Highlighted Live Sessions</p>
					</div>
				</>
			</OptionalComponent>
		</>
	);
};

export default MainEventBannerAdvancedLayout;
