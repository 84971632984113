import { isObject } from "underscore";

interface StoreItem {
	item: string;
	__expiration?: number;
}

export function setSessionStorageItem(key: string, item: string, expirationInHours?: number): void {
	if (isObject(key)) {
		console.error(`session storage: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const storeItem: StoreItem = {
		item: item
	};

	if (expirationInHours) {
		storeItem.__expiration = Date.now() + (expirationInHours * 60 * 60 * 1000);
	}

	sessionStorage.setItem(key, JSON.stringify(storeItem));
}

export function setSessionStorageObject(key: string, item: any | { [key: string]: any }, expirationInHours?: number): any {
	if (isObject(key)) {
		console.error(`session storage: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	if (expirationInHours) {
		item.__expiration = Date.now() + (expirationInHours * 60 * 60 * 1000);
	}

	sessionStorage.setItem(key, JSON.stringify(item));
}

/**
 * 
 * @param {string} key 
 */
export function getSessionStorageItem(key: string): any | null {
	if (isObject(key)) {
		console.error(`session storage: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const str = sessionStorage.getItem(key);

	if (str) {
		const item = JSON.parse(str);

		if (item.__expiration && item.__expiration < Date.now()) {
			removeSessionStorageItem(key);
			return null;
		} else {
			return item.item;
		}
	} else {
		return null;
	}
}

export function getSessionStorageObject(key: string): any | null {
	if (isObject(key)) {
		console.error(`session storage: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	const str = sessionStorage.getItem(key);

	if (str) {
		const item = JSON.parse(str);

		if (item.__expiration && item.__expiration < Date.now()) {
			removeSessionStorageItem(key);
			return null;
		} else {
			return item;
		}
	} else {
		return null;
	}
}

export function removeSessionStorageItem(key: string): void {
	if (isObject(key)) {
		console.error(`session storage: key is an object: ${JSON.stringify(key)}`);
		return;
	}

	sessionStorage.removeItem(key);
}

export function clearSessionStorage(): void {
	sessionStorage.clear();
}