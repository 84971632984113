import ActiveSpeakersList from "../extras/speakers/active-speakers-list";
import { usePageModule } from "../hooks/panel.hooks";
import { customSpeakersItems, extrasSpeakersItems } from "./constants/empty-panel";
import EmptyStatePanel from "./empty-state-panel";

export default function ExtrasSpeakersPanel({ custom = false }: { custom?: boolean }): JSX.Element {
	const pageModule = usePageModule();
	const hasContent = pageModule?.modules?.length;

	return hasContent ? (
		<ActiveSpeakersList />
	) : (
		<EmptyStatePanel
			title="Add Speakers"
			description="Tabs without content will not be visible to the audience"
			dropDownItems={custom ? customSpeakersItems : extrasSpeakersItems}
		/>
	);
}