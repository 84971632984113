import { TFunction } from "react-i18next";
import { BrandliveEvent, ExtendedHomepageMainNavItems, IHomepageMainNavItems, LanguagesAbbr } from "types/working-model";

export const filterDisabledAgenda = (
	agendaEnabled: boolean,
) => (navItem: IHomepageMainNavItems) => {
	if (navItem.name.includes('Session')) {
		return agendaEnabled;
	}

	return true;
};

export const isSessionsLabel = (label: string) => {
	return ['sessions', 'session'].includes(label.toLowerCase());
};

const isDefaultPageLabel = (name: string) => {
	return ['Home', 'Watch', 'Sessions', 'Session', 'Leaderboard', 'Directory', 'Logout', 'Log out'].includes(name);
};

export const getNavItemLabel = (
	navItem: IHomepageMainNavItems | ExtendedHomepageMainNavItems,
	t: TFunction,
	language: LanguagesAbbr
): string => {
	const label = navItem.name.toLowerCase();
	const baseName = navItem.custom_name.base;

	if (navItem.custom_name[language]) {
		if (!navItem.custom_name.changed && isSessionsLabel(label)) {
			return t('homepage:Watch', 'Watch');
		}

		return navItem.custom_name[language] as string;
	}

	if (isDefaultPageLabel(baseName)) {
		return t(baseName, baseName);
	}

	return baseName;
};

export const getCustomSessionUrl = (
	eventBundle: BrandliveEvent | undefined | null,
	routeBaseName: string
): string => {
	if (routeBaseName === 'Session' && eventBundle?.sessions?.length === 1) {
		return eventBundle.sessions[0].custom_url || '';
	}

	return '';
};

export const directoryNavItem: ExtendedHomepageMainNavItems = {
	name: 'Directory',
	linkReferenceType: 'DirectoryLink',
	custom_name: {
		base: 'Directory',
		changed: ''
	},
	uuid: 'directory',
	is_on: true
};

export const logoutNavItem: ExtendedHomepageMainNavItems = {
	name: 'Logout',
	custom_name: {
		base: 'Log out',
		changed: ''
	},
	uuid: 'logout',
	is_on: true
};

export const extendedNavItems = [
	logoutNavItem
];