import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { UploadFile } from '../../../../../../connection/uploads';
import { updateBreakoutBackgroundImage, updateUseBreakoutImage } from '../../../../../../store/actions/admin/create-event/session';
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import LargeButton from '../../../../../general-ui/button/large-button';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import { SwitchNew } from "../../../../../general-ui/switch/switch-new";

const BreakoutRoomBackground: React.FC = () => {
	const breakoutSession = useTypedSelector(state => state.CreateSessionReducer.workingSession?.breakout_session);
	const sessionId = useTypedSelector(state => state.CreateSessionReducer.workingSession?.session);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	const [useImage, setUseImage] = useState(!!breakoutSession?.use_background_image);
	const [uploading, setUploading] = useState(false);

	const dispatch = useDispatch();

	const handleUseImage = () => {
		if (!token || !sessionId) return;
		const updated = !useImage;
		setUseImage(updated);
		dispatch(updateUseBreakoutImage(updated, sessionId, token));
	};

	const handleFile = async (file: File | FileList) => {
		if (!user || !token || !sessionId) return;
		setUploading(true);

		const _file = file as File;

		const url = await UploadFile(user, token, _file);

		// It should be re-visited when we pick this back up

		// update database and redux
		dispatch(updateBreakoutBackgroundImage(url, sessionId, token));

		setUploading(false);
	};

	if (!breakoutSession) return null;

	return (
		<div className="settings-block">
			<div className="settings-block-header">
				<h5>Breakout room session background</h5>
				<SwitchNew
					content=""
					onChange={handleUseImage}
					checked={useImage}
				/>
			</div>
			<div className="settings-section">
				<h6>Sessions proportions are 16x9</h6>
				<LargeButton
					title=""
					customTitle={<Icon name={ICONS.ADD} size={18} color={COLORS.WHITE} />}
					allowedFileTypes={[
						'image/jpeg',
						'image/png',
						'image/gif',
					]}
					onFile={handleFile}
					uploading={uploading}
					style={{
						height: '104px',
						backgroundImage: `url("${breakoutSession?.background_image || ''}")`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
			</div>
		</div>
	);
};

export default BreakoutRoomBackground;
