import { useMemo } from "react";
import { BrandliveEvent, RegistrationStep } from "../types/working-model";

export const getAvatarIsEnabled = (steps: RegistrationStep[] | undefined): boolean => {
	return !!steps?.some(step => step.type === 'avatar' && step.isOn);
};

export const useAvatarIsEnabled = (eventBundle: BrandliveEvent | undefined | null): boolean => {
	const steps = eventBundle?.registration_steps;
	const isOn = !!eventBundle?.registration_on;
	return useMemo(() => {
		return (isOn && getAvatarIsEnabled(steps));
	}, [isOn, steps]);
};