import { ReactNode } from 'react';
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";

import './typography-item.scss';

type TTagName =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'span'
	| 'div'
	| 'a'
	| 'time';

interface ITypographyItemProps {
	children: ReactNode;
	tagName?: TTagName;
	className?: string;
	error?: boolean;
	[key: string]: any;
}

export const TypographyItem = ({
	children,
	tagName = 'div',
	className = '',
	error,
	...rest
}: ITypographyItemProps): JSX.Element => {
	const TagName = `${tagName}` as keyof JSX.IntrinsicElements;

	return (
		<TagName
			className={`${className} ${error ? 'typography-item-error' : ''}`}
			{...rest}
		>
			{children}
		</TagName>
	);
};


// moving our call to react markdown here so as it's capabilities grow 
// it only has to be edited in a single location rather than scattered throughout the app
// plugins:
// remark-breaks translates \n into </br >, 
// remark-gfm brings in extra github features including easy links
interface IMarkdownItemProps {
	children: string;
	className?: string;
	[key: string]: any;
}

export const MarkdownItem = ({
	children,
	className = '',
	...rest
}: IMarkdownItemProps): JSX.Element => {
	return (
		<ReactMarkdown
			className={className}
			remarkPlugins={[remarkBreaks, remarkGfm]}
			rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ['nofollow', 'noopener', "noreferrer"] }]]}
			components={{
				// strikethrough markdown is used for underline text so we have to override the styling to show as underline
				// eslint-disable-next-line react/display-name
				del: ({ ...props }) => <span style={{ textDecorationLine: 'underline' }} {...props} />
			}}
			{...rest}>
			{children}
		</ReactMarkdown>
	);
};