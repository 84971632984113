import { Dispatch } from "react";
import { AnyAction } from "redux";
import { getSurveyTextAnswers } from "../../../../../../../../connection/moderator";
import { setTextAnswers } from "../../../../../../../../store/actions/admin/surveys";

export type TextAnswerLoaderFuncProps = {
	questionId: number;
	limit: number;
	token: string;
	sessionUuid: string;
	channel: number;
	dispatch: Dispatch<AnyAction>;
}

class TextAnswerLoader {
	loading: number[] = [];

	load = (props: TextAnswerLoaderFuncProps): Promise<void> => {
		const { questionId, limit, token, sessionUuid, channel, dispatch } = props;
		if (this.loading.includes(questionId)) return Promise.resolve();

		this.loading.push(questionId);

		return getSurveyTextAnswers({
			token,
			sessionUuid,
			channel,
			question_id: questionId,
			limit
		}).then(res => {
			dispatch(setTextAnswers(questionId, res.results, res.total));
		}).catch(e => {
			console.error(e);
		}).finally(() => {
			this.loading = this.loading.filter(id => id !== questionId);
			return;
		});
	};
}

export default new TextAnswerLoader();