import React, { ChangeEvent } from 'react';
import { fileType } from '../types/working-model';
import { validateFileType } from './utils';
interface IFileInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'accept' | 'onChange'> {
	accept: fileType[];
	skipValidation?: boolean;
	/** validate the file extension instead of the file content. This can be used when you need to upload a certain file extention but not all files with that extensions type. For example: scss files have type application/octet-stream, but we dont want to allow all application/octet-stream files, so we just allow .scss extensions. */
	acceptFileExtensions?: string[]
	onChange?: (event: ChangeEvent<HTMLInputElement>, cb?: () => void) => void;
	onFileError?: (message: string, erroredFile: File) => void;
	/** you can add a type to this component, but it better be type "file" MUHAHAHAHAHAHAHHAHAHAH */
	type?: 'file';
}

const FileInput = React.forwardRef<HTMLInputElement, IFileInputProps>((props: IFileInputProps, ref) => {
	const {
		type = 'file',
		accept,
		onFileError = (error: string) => console.error(error),
		onChange: handleChange,
		skipValidation = false,
		acceptFileExtensions,
		...attributes
	} = props;

	const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
		if (skipValidation) return handleChange?.(event);

		const files = event.target.files;

		if (!files || !files?.length) {
			return handleChange?.(event);
		}

		// validate
		if (files.length > 1) {
			// validate multiple
			const validateFileTypeList = async () => {
				let _isValid = true;
				let _invalidFile: File | null = null;
				for (let i = 0; i < files.length; ++i) {
					const validFileType = await validateFileType(files[i], accept, acceptFileExtensions);
					if (!validFileType) {
						_isValid = false;
						_invalidFile = files[i];
						break;
					}
				}
				return { isValid: _isValid, invalidFile: _invalidFile };
			};

			const { isValid, invalidFile } = await validateFileTypeList();
			if (!isValid && invalidFile) {
				return onFileError(`invalid file type for: ${invalidFile.name}`, invalidFile);
			}
		}

		if (files.length === 1) {
			// validate single
			const validMagicNumber = await validateFileType(files[0], accept, acceptFileExtensions);
			if (!validMagicNumber) {
				return onFileError(`invalid file type for: ${files[0].name}`, files[0]);
			}
		}

		handleChange?.(event);
	};
	const acceptedFiles = [...accept, ...(acceptFileExtensions ?? [])].join(',');

	return (
		<input ref={ref} type={type} onChange={onChange} accept={acceptedFiles} {...attributes} disabled={!accept.length} />
	);
});

FileInput.displayName = "FileInput";

export default FileInput;
