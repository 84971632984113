import SelectedSuggested from "../extras/suggested/selected-suggested";
import { usePageModule } from "../hooks/panel.hooks";
import { customSuggestedItems, extrasSuggestedItems } from "./constants/empty-panel";
import EmptyStatePanel from "./empty-state-panel";

export default function ExtrasSuggestedPanel({ custom = false }: { custom?: boolean }): JSX.Element {
	const pageModule = usePageModule();

	if (pageModule?.content_modules?.length) {
		return <SelectedSuggested withinTab />;
	}

	return (
		<EmptyStatePanel
			title="Add Suggested Content and Related Sessions"
			description="Sections without content will not be visible to the audience"
			dropDownItems={custom ? customSuggestedItems : extrasSuggestedItems}
		/>
	);
}