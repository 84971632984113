import { fetchDocuments } from "../../../connection/content-connection";
import { Action, AsyncAction } from "../../../types/actions";
import { Document } from "../../../types/working-model";

export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS";
export function loadDocuments(channel: number, token: string): AsyncAction {
	return {
		type: LOAD_DOCUMENTS,
		promise: fetchDocuments(channel, token)
	};
}

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export function addDocument(document: Document): Action {
	return {
		type: ADD_DOCUMENT,
		payload: document
	};
}

export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export function updateDocument(document: Document): Action {
	return {
		type: UPDATE_DOCUMENT,
		payload: document
	};
}
