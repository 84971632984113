import React, { useState, useEffect } from 'react';
import {
	GetUploadEndpoint,
	UploadFileToProvider,
} from '../../../../connection/uploads';
import { GetDefaultPasscodeList } from '../../../../store/utils/create-event';
import {
	PASSCODE_LIST_TYPE,
	PasscodeList,
} from '../../../../types/working-model';
import LargeButton from '../../../general-ui/button/large-button';
import TagSelectInput from '../../../general-ui/tag-select/tag-select';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import { getUploadFilename } from '../../../../utils/utils';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { PrimaryTooltip } from '../session/session-modal';
import { DownloadCsvButton } from './download-csv-button';
import { UploadAudienceListRadios } from './upload-audience-list-radios';
import { OptionalComponent } from '../../../../utils/optional-component';

interface PasscodeListProps {
	isCreating: boolean;
	onChange: (lists: string[]) => void;
	setPasscodeListToCreate: React.Dispatch<React.SetStateAction<WorkingPasscodeList | undefined>>;
	passcodeLists?: PasscodeList[];
	nameErrorMessage?: string;
	urlErrorMessage?: string;
	isCustomGating?: boolean;
	eventHasBeenPublished?: boolean;
}

export interface WorkingPasscodeList {
	type: PASSCODE_LIST_TYPE;
	name: string;
	max_uses: number;
	master_passcode: string;
	url: string;
	isCustomGating?: boolean;
}

export default function PasscodeListCreator(props: PasscodeListProps): JSX.Element {
	const {
		isCreating,
		eventHasBeenPublished = false,
		isCustomGating = true,
		nameErrorMessage,
		onChange,
		passcodeLists,
		setPasscodeListToCreate,
		urlErrorMessage,
	} = props;

	const [displayListManipulation, setDisplayListManipulation] = useState<boolean>(true);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const availablePasscodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const [workingPasscodeList, setWorkingPasscodeList] = useState<WorkingPasscodeList>(GetDefaultPasscodeList());

	// Because TagSelectInput is being used to manage password lists, we'll have to work around it
	// Converting between string[] name tags, number[] IDs and PassCodeLists[] is going to happen in a few places
	// Here, a string[] for TagSelectInput is generated from PassCodeLists[]
	const passcodeListNames = passcodeLists?.map((list) => list.name) || [];

	// No longer filtering for unique because names are used like IDs and we don't want to cover up the duplicate problem
	// TagSelectInput does exclude used tags, though, which can hide duplicates
	const passcodeListOptions = availablePasscodeLists.map((list: PasscodeList) => list.name);

	async function onFile(file: File | FileList) {
		if (!token) {
			return;
		}
		if (file instanceof File) {
			const endpoint = await GetUploadEndpoint(
				token,
				getUploadFilename(file.name)
			);

			const finalUrl = await UploadFileToProvider(
				endpoint,
				file,
			);

			setWorkingPasscodeList((list: WorkingPasscodeList) => ({
				...list,
				url: finalUrl,
			}));
		}
	}

	function handlePasscodeListName(e: React.ChangeEvent<HTMLInputElement>) {
		setWorkingPasscodeList((list: WorkingPasscodeList) => ({
			...list,
			name: e.target.value,
		}));
	}

	function handlePasscodeListMasterPasscode(
		e: React.ChangeEvent<HTMLInputElement>
	) {
		setWorkingPasscodeList((list: WorkingPasscodeList) => ({
			...list,
			master_passcode: e.target.value.toLocaleLowerCase(),
		}));
	}

	useEffect(() => {
		if (
			workingPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS
			&& !eventHasBeenPublished
		) {
			setDisplayListManipulation(false);
		} else {
			setDisplayListManipulation(true);
		}
	}, [setDisplayListManipulation, eventHasBeenPublished, workingPasscodeList]);

	useEffect(() => {
		if (isCreating) {
			setPasscodeListToCreate(workingPasscodeList);
		} else {
			setPasscodeListToCreate(undefined);
		}
	}, [setPasscodeListToCreate, workingPasscodeList, isCreating]);

	return (
		<div>
			{isCreating ? (
				<div style={{ marginTop: 16 }}>

					<TextInput
						label={'Audience list name*'}
						onChange={handlePasscodeListName}
						placeholder={'Audience list name'}
						errorMessage={nameErrorMessage}	// does nothing
						valid={nameErrorMessage ? Validation.error : Validation.normal}
						ignoreZIndex
					/>
					<UploadAudienceListRadios
						setWorkingPasscodeList={setWorkingPasscodeList}
						workingPasscodeList={workingPasscodeList}
						isCustomGating={isCustomGating}
					/>
					<OptionalComponent display={displayListManipulation}>
						<div>
							<LargeButton
								title={'Upload an audience list'}
								subtitle={'or drag and drop here in csv file format'}
								onFile={onFile}
								allowedFileTypes={[
									'text/csv',
									'application/vnd.ms-excel',
								]}
								errorMessage={urlErrorMessage}
							/>

							<div style={{ marginTop: 24 }} className="download-template-container">
								<OptionalComponent display={workingPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS}>
									<p className="add-item info" style={{ width: '60%' }}>
										If you're planning to generate magic links, please download the dynamic template
									</p>
								</OptionalComponent>
								<DownloadCsvButton listType={workingPasscodeList.type} />
							</div>
						</div>
					</OptionalComponent>
					<OptionalComponent display={!displayListManipulation}>
						<div className="publish-error">Please publish this event before attaching an Email & Magic Link list</div>
					</OptionalComponent>
					<OptionalComponent display={workingPasscodeList.type !== PASSCODE_LIST_TYPE.MAGIC_LINKS && workingPasscodeList.type !== PASSCODE_LIST_TYPE.EMAILS_ONLY}>
						<div style={{ marginTop: 16 }}>
							<TextInput
								label={'Master Passcode'}
								tooltip={[PrimaryTooltip, 'Note: master passcodes are not case sensitive']}
								onChange={handlePasscodeListMasterPasscode}
								placeholder={'Enter master passcode'}
								value={workingPasscodeList.master_passcode}
							/>
						</div>
					</OptionalComponent>
				</div>
			) : (
				<div style={{ marginTop: 16 }}>
					<TagSelectInput
						onChange={onChange}
						defaultTags={passcodeListOptions}
						label={'Audience lists'}
						tags={passcodeListNames}
						restrictToList
						shouldSearch
					/>
				</div>
			)
			}
		</div >
	);
}
