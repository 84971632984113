import { handle } from 'redux-pack';
import { Action } from "../../../types/actions";
import { IChatPromotion, ISocketCommentProps, Status } from '../../../types/working-model';
import { toMap } from '../../../utils/utils';
import {
	CLEAR_CHAT_MESSAGES,
	DELETE_CHAT_MESSAGE,
	INCOMING_CHAT_MESSAGE,
	LOAD_CHAT_MESSAGES,
	SET_CHAT_PROMOTION,
	UNAPPROVE_CHAT_MESSAGE,
	UNDELETE_CHAT_MESSAGE,
	TOGGLE_CHAT_PANEL,
	LOAD_CHAT_MESSAGES_PAGE,
	EMBED_CLEAR_HAS_NEW_MESSAGE,
	SET_CHAT_AS_MODERATOR,
} from '../../actions/event/chat-actions';

export interface ChatState {
	comments: Map<number, ISocketCommentProps>;
	removedComments: Set<number>;
	loadingComments: boolean;
	loadingCommentsError: string;
	count: number;
	chatPromotion: IChatPromotion | null;
	isChatPanelOpen: boolean;
	chatKey: string;
	hasNewMessage: boolean;
	asModerator: boolean;
	initialLoad: boolean;
	min_cursor: number;
}

const defaultState: ChatState = {
	chatPromotion: null,
	comments: new Map(),
	removedComments: new Set(),
	count: 0,
	isChatPanelOpen: true,
	loadingComments: false,
	loadingCommentsError: "",
	chatKey: '',
	hasNewMessage: false,
	asModerator: false,
	initialLoad: true,
	min_cursor: -Infinity
};

export default function ChatReducer(state: ChatState = defaultState, action: Action): ChatState {
	switch (action.type) {
		case UNDELETE_CHAT_MESSAGE:
		case INCOMING_CHAT_MESSAGE: {
			const existed = state.comments.has(action.payload.id);
			const comments = new Map(state.comments);
			comments.set(action.payload.id, action.payload);
			let updatedCount = state.count;

			// we are allowing banned messages through to be displayed to sender
			const allowed = [Status.Approved, Status.Banned].includes(action.payload?.status);

			if (!action.payload?.spam && allowed) {
				// users receive their own messages directly and from WS - don't increment if this message was already in the map
				if (!existed) {
					updatedCount += 1;
				}
			} else {
				updatedCount -= 1;
			}

			// we want to track the in-memory state of removed comments
			// so that we can force the render of the chat panel when 
			// a message has been removed (or put back)
			let removedComments = state.removedComments;
			if (action.type === UNDELETE_CHAT_MESSAGE) {
				removedComments = new Set(state.removedComments);
				removedComments.delete(action.payload.id);
			}

			return {
				...state,
				count: updatedCount < 0 ? 0 : updatedCount,
				removedComments: removedComments,
				comments: comments,
				hasNewMessage: true
			};
		}

		case DELETE_CHAT_MESSAGE:
		case UNAPPROVE_CHAT_MESSAGE: {
			const comments = new Map(state.comments);
			const removedComments = new Set(state.removedComments);
			removedComments.add(action.payload.id);
			comments.delete(action.payload);
			const updatedCount = state.count - 1;
			return {
				...state,
				count: updatedCount < 0 ? 0 : updatedCount,
				comments: comments
			};
		}

		case LOAD_CHAT_MESSAGES: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingComments: true }),
				finish: (state) => ({ ...state, loadingComments: false, initialLoad: false }),
				failure: (state) => ({ ...state, loadingCommentsError: 'Unable to load chat' }),
				success: (state) => {
					if ('error' in action.payload) {
						return ({
							...state,
							loadingCommentsError: action.payload.error
						});
					} else if ('msg' in action.payload) {
						return ({
							...state,
							loadingCommentsError: action.payload.msg
						});
					} else {
						return {
							...state,
							loadingCommentsError: '',
							comments: toMap('id', action?.payload?.messages ?? []) as Map<number, ISocketCommentProps>,
							count: action.payload.count,
						};
					}
				}
			});
		}

		case LOAD_CHAT_MESSAGES_PAGE: {
			return handle(state, action, {
				start: (state) => {
					// state reset based on request
					// new comments were requested on another session or language
					// so clear the existing state
					if (action.meta.chatKey !== state.chatKey || action.meta.reset) {
						return {
							...state,
							loadingComments: true,
							comments: new Map(),
							chatKey: action.meta.chatKey,
							initialLoad: true,
							min_cursor: -Infinity,
							count: 0
						};
					} else {
						return {
							...state,
							loadingComments: true
						};
					}
				},
				finish: (state) => ({ ...state, loadingComments: false, initialLoad: false }),
				failure: (state) => ({ ...state, loadingCommentsError: 'Unable to load chat' }),
				success: (state) => {
					if (action.payload.count === null) {
						return state;
					}

					if ('error' in action.payload) {
						return ({
							...state,
							loadingCommentsError: action.payload.error
						});
					} else if ('msg' in action.payload) {
						return ({
							...state,
							loadingCommentsError: action.payload.msg
						});
					} else if (action.payload.messages) {
						return {
							...state,
							loadingCommentsError: '',
							count: action.payload.count,
							min_cursor: action.payload.min_cursor,
							comments: toMap('id', [
								...action?.payload?.messages ?? [],
								...state.comments.values()
							]) as Map<number, ISocketCommentProps>
						};
					} else {
						return state;
					}
				}
			});
		}

		case SET_CHAT_PROMOTION: {
			return {
				...state,
				chatPromotion: action.payload
			};
		}

		case CLEAR_CHAT_MESSAGES: {
			return {
				...state,
				loadingCommentsError: '',
				comments: new Map(),
				count: 0,
			};
		}

		case TOGGLE_CHAT_PANEL: {
			return {
				...state,
				isChatPanelOpen: action.payload,
			};
		}

		case EMBED_CLEAR_HAS_NEW_MESSAGE: {
			return {
				...state,
				hasNewMessage: false
			};
		}

		case SET_CHAT_AS_MODERATOR: {
			return {
				...state,
				asModerator: action.payload
			};
		}

		default: return state;
	}
}