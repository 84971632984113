import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Virtuoso, type VirtuosoHandle } from 'react-virtuoso';
import { BLChannelProfile, BlProfile, ISocketCommentProps, SessionTabsChatStyles, Status } from "../../../../types/working-model";
import { useAppDispatch, useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import Avatar from '../../../general-ui/avatar/avatar';
import { setDisplayLoadingProfileModal, setDisplayProfileModal } from '../../../../store/actions/event/event-actions';
import { getAvatarForComment, getIsPrevUser, matchChatSender, useLanguageParam } from '../../utils';
import Lightbox from '../../../general-ui/lightbox/lightbox';
import dateGetter from '../../../../utils/date-getter';
import { useAvatarIsEnabled } from '../../../../utils/avatar-is-enabled';
import { getAttendeeProfile } from '../../profile-page/profile-utils';
import { useGetSession, useIsNewModuleGrouping } from '../../../../hooks/session.hooks';
import { getModeratorProfileFromChatMessage } from 'utils/utils';
import { OptionalComponent } from 'utils/optional-component';
import { useFetchSessionChat } from './chat-loader';
import Icon, { COLORS, ICONS } from '@general-ui/icon';

async function importEmojiRegex() {
	const regexEmoji = (await import('emoji-regex'))?.default();
	return regexEmoji;
}

const processMessages = (
	blProfileUser: BlProfile | null,
	comments: ISocketCommentProps[],
	removedComments: Set<number>
) => {
	const retArray: ISocketCommentProps[] = [];
	for (const comment of comments) {
		if (removedComments.has(comment.id)) {
			continue;
		}

		if (comment.spam) {
			continue;
		}

		// if this is someone else's comment, show it if it has been approved
		// if this is the own user's comment, show it regardless of the current status
		const canDisplay = comment.status === Status.Approved ||
			matchChatSender(blProfileUser, comment.bl_profile, comment.bl_profile_non_registered);

		if (!canDisplay) {
			continue;
		}

		retArray.push(comment);
	}

	return retArray;
};

interface IChatFeedProps {
	comments: Map<number, ISocketCommentProps>;
	blProfileUser: BlProfile | null;
	darkMode?: boolean;
	atTop: boolean;
	atBottom: boolean;
	setAtTop: (atTop: boolean) => void;
	setAtBottom: (atBottom: boolean) => void;
}

const ChatFeed: React.FC<IChatFeedProps> = ({
	comments,
	blProfileUser,
	darkMode,
	atTop,
	atBottom,
	setAtTop,
	setAtBottom
}) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer?.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer?.workingEvent);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const chatCount = useTypedSelector(state => state.ChatReducer.count);
	const removedComments = useTypedSelector(state => state.ChatReducer.removedComments);

	const dispatch = useAppDispatch();
	const session = useGetSession();
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const onFailToFetchAttendee = useCallback(() => dispatch(setDisplayProfileModal(null)), [dispatch]);
	const language = useLanguageParam();
	const [attendee, setAttendee] = useState<BLChannelProfile | null>(null);
	const [, setLoading] = useState(false);
	const [regexEmoji, setRegexEmoji] = useState<RegExp>();
	const event = eventBundle ?? workingEvent;
	const channel = event?.channel;
	const sessionChatStyle = session?.session_chat_style;
	const displayAvatar = useAvatarIsEnabled(event);
	const profileIsOn = event?.social_settings?.profiles?.isOn;
	const [commentsArray, setCommentsArray] = useState<ISocketCommentProps[]>(Array.from(comments.values()));
	const [currentCount, setCurrentCount] = useState(chatCount);
	const [showMoreButton, setShowMoreButton] = useState(false);
	const commentsArrayRef = useRef<ISocketCommentProps[]>([]);
	const virtuosoRef = useRef<VirtuosoHandle | null>(null);
	const isMounted = useRef<boolean>();
	const chatLang = session?.session_chat_single_language ? 'all' : language;
	const loadChatBeforeId = useFetchSessionChat({ sessionUuid: session?.uuid, language: chatLang });
	const containerRef = useRef<HTMLDivElement | null>(null);
	const removedCommentsRef = useRef(removedComments);

	useEffect(() => {
		removedCommentsRef.current = removedComments;
		const comms = processMessages(blProfileUser, commentsArrayRef.current, removedCommentsRef.current);
		setCommentsArray(comms);
	}, [removedComments, blProfileUser]);

	useEffect(() => {
		const comms = Array.from(comments.values());

		// skip updating the view if the user is not scrolled to either top or bottom
		if (atBottom || atTop) {
			const messages = processMessages(blProfileUser, comms, removedCommentsRef.current);
			setCommentsArray(messages);
			setCurrentCount(chatCount);
			commentsArrayRef.current = comms;

			// if the user is at the bottom AND the set show more button is visible, hide it
			if (atBottom) {
				setShowMoreButton(false);
			}
		} else {

			// if the user is at the bottom and a new comment has been loaded in at the bottom,
			// show the "show more" button but do not update the visible comments array
			if (!atBottom && commentsArrayRef.current.at(-1)?.id !== comms.at(-1)?.id) {
				setShowMoreButton(true);
			}
		}
	}, [comments, chatCount, atBottom, atTop, blProfileUser]);

	useEffect(() => {
		isMounted.current = true;

		(async () => {
			try {
				// because we are using an async import and then updating state,
				// it throws a warning about updating state on an unmounted component,
				// so we need to check if the component is still mounted before updating state
				const result = await importEmojiRegex();
				if (isMounted.current) {
					setRegexEmoji(result);
				}
			} catch (e) {
				console.error('Failed to load emoji regex:');
				console.error(e);
			}
		})();

		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (attendee) {
			dispatch(setDisplayProfileModal(attendee));
		}
	}, [attendee, dispatch]);

	const eventUuid = event?.uuid;

	const handleProfileClick = useCallback(async (comment: ISocketCommentProps) => {
		if (eventBundle) {
			const attendeeId = comment?.profile_uuid;
			try {
				if (attendeeId && eventUuid && blProfileUserToken) {
					dispatch(setDisplayLoadingProfileModal(true)); // this allows the modal to open in a loading state so we get some feedback for the user
					getAttendeeProfile(
						attendeeId,
						eventUuid,
						blProfileUserToken,
						setAttendee,
						onFailToFetchAttendee,
						setLoading
					);
				}
			} catch (e) {
				console.error(e);
			}
		}
	}, [blProfileUserToken, dispatch, eventBundle, eventUuid, onFailToFetchAttendee]);

	const handleShowMore = useCallback(() => {
		setShowMoreButton(false);
		virtuosoRef.current?.scrollToIndex({ index: commentsArrayRef.current.length - 1, behavior: 'smooth' });
	}, []);

	const commentRenderer = useCallback((index, comment: ISocketCommentProps) => {
		if (!channel || !eventUuid) {
			// virtuoso requires a height for each item, so we return a div with a height of 32px while we await
			// the channel and eventUuid to be defined
			return <div key={index} style={{ height: 32 }} />;
		}

		const prevMessage = commentsArrayRef.current?.[index - 1];
		const isPrevUser = getIsPrevUser(prevMessage, comment);
		const moderatorProfile = getModeratorProfileFromChatMessage(comment, channel, eventUuid);
		const firstName = moderatorProfile?.first_name ?? comment.first_name;
		const lastName = moderatorProfile?.last_name ?? comment.last_name;
		const userInitials = `${firstName?.charAt(0)?.toUpperCase() || ''}${lastName?.charAt(0)?.toUpperCase() || ''}`;
		const avatar = moderatorProfile ? moderatorProfile.avatar : getAvatarForComment(channel, displayAvatar, comment);

		return (
			<ChatMessage
				avatar={avatar}
				comment={comment}
				isPrevUser={!!isPrevUser}
				userInitials={userInitials}
				handleProfileClick={handleProfileClick}
				profileIsOn={!!profileIsOn}
				darkMode={!!darkMode}
				index={index}
				key={comment.uuid}
				firstName={firstName}
				lastName={lastName}
				isModuleGroupingV2={isModuleGroupingV2}
				regexEmoji={regexEmoji}
				sessionChatStyle={sessionChatStyle}
			/>
		);
	}, [regexEmoji, channel, darkMode, displayAvatar, handleProfileClick, isModuleGroupingV2, profileIsOn, eventUuid, sessionChatStyle]);

	return commentsArray.length ? (
		<div
			ref={containerRef}
			className={classNames("comment-feed-container", { 'v2': isModuleGroupingV2 })}
		>
			<Virtuoso
				id="virtuoso-chat-feed"
				className={"comment-feed-virtuoso"}
				ref={virtuosoRef}
				initialTopMostItemIndex={commentsArray.length - 1}
				data={commentsArray}
				atBottomStateChange={(bottom) => {
					setAtBottom(bottom);
				}}
				itemContent={commentRenderer}
				followOutput={'auto'}
				atTopStateChange={(atTop: boolean) => {
					setAtTop(atTop);
					if (atTop && commentsArray.length >= 100) {
						loadChatBeforeId(commentsArray[0].id);
					}
				}}
				firstItemIndex={Math.max(currentCount - commentsArray.length, 0)}
			/>
			<button
				onClick={handleShowMore}
				className={classNames("show-more-button", { visible: showMoreButton })}
			><Icon name={ICONS.ARROW_DOWN} color={COLORS.WHITE} size={16} /></button>
		</div>
	) : null;
};

interface IChatMessage {
	avatar: string;
	firstName: string;
	lastName: string;
	comment: ISocketCommentProps;
	isPrevUser: boolean;
	userInitials: string;
	handleProfileClick: (comment: ISocketCommentProps) => void;
	profileIsOn: boolean;
	darkMode: boolean;
	index: number;
	isModuleGroupingV2?: boolean;
	regexEmoji?: RegExp | undefined;
	sessionChatStyle?: SessionTabsChatStyles | null;
}
function isOnlyEmojis(regexEmoji: RegExp | undefined, inputString: string) {
	// Define a regular expression to match emojis
	if (!regexEmoji) return false;
	const removeEmoji = inputString.replace(regexEmoji, '');
	// Test if the input string contains only emojis
	return !removeEmoji.length;
}

const ChatMessage = ({
	avatar,
	firstName,
	lastName,
	comment,
	isPrevUser,
	userInitials,
	handleProfileClick,
	profileIsOn,
	darkMode,
	index,
	isModuleGroupingV2,
	regexEmoji,
	sessionChatStyle
}: IChatMessage) => {
	const isConversational = sessionChatStyle === SessionTabsChatStyles.Conversational;

	return useMemo(() => (
		<div
			id={`chat-comment-${comment.id}`}
			style={index === 0 ? { paddingTop: 20 } : undefined}
			className={classNames(
				"chat-comment",
				{
					'user-continued': isPrevUser,
					'high-volume-style': !isConversational,
					'first-comment': index === 0,
				}
			)}
		>
			<Avatar
				imageUrl={avatar}
				initials={userInitials}
				onClick={() => {
					if (comment?.is_moderator) return;
					handleProfileClick(comment);
				}}
				canViewProfile={profileIsOn}
				tabIndex={index + 1}
				className={classNames({ moderator: comment.is_moderator, 'disable-click': comment.is_moderator })}
				isModuleGroupingV2={isModuleGroupingV2}
			/>
			<div className={classNames("chat-comment-inner", { 'dark-mode': darkMode })}>
				<OptionalComponent display={!isConversational}>
					<div className="chat-comment">
						<span className="chat-commenter-name">
							{firstName} {lastName}&nbsp;&nbsp;
						</span>
						<span className="chat-comment-content">
							{comment.comment}
						</span>
					</div>
				</OptionalComponent>
				{comment?.gif?.url && (
					<Lightbox
						imgProps={{
							src: comment.gif.url,
							alt: comment.gif?.title || 'gif',
							width: '100px',
							height: '100px'
						}}
					/>
				)}
				{comment?.image?.url && (
					<Lightbox
						imgProps={{
							src: comment.image.url,
							alt: comment.image.name || 'image',
							width: '100px',
							height: '100px'
						}}
					/>
				)}
				<OptionalComponent display={isConversational}>
					<div className={classNames("chat-comment-meta")}>
						<span className={classNames("chat-comment-name", { moderator: comment.is_moderator })}>
							{firstName} {lastName}
						</span>
						<span className="chat-comment-date">
							{dateGetter.get(comment.created_at)[0]}
						</span>
					</div>
					<div className={classNames("chat-comment-content", {
						"jumbo-font": isOnlyEmojis(regexEmoji, comment.comment) && isModuleGroupingV2
					})} >
						{comment.comment}
					</div>
				</OptionalComponent>
			</div>
			<OptionalComponent display={!isConversational}>
				<div className={classNames('chat-comment-date', { 'dark-mode': darkMode })}>
					{dateGetter.get(comment.created_at)[0]}
				</div>
			</OptionalComponent>
		</div>
	), [avatar, comment, darkMode, firstName, handleProfileClick, index, isModuleGroupingV2, isPrevUser, lastName, profileIsOn, regexEmoji, userInitials, isConversational]);
};

ChatFeed.displayName = 'ChatFeed';

export default ChatFeed;
