import { ColorValueIndexes, EPaletteModes } from "types/theme-packs";
import { getColorOptionsFromColor } from "./get-color-options-from-color";

const getDefaultPackColors = (colorTheme = EPaletteModes.Light) => {
	const colors = getColorOptionsFromColor(undefined, colorTheme);

	return `
			--classicAccentColor: ${colors.accentColor[ColorValueIndexes.hex]};
			--classicSecondaryAccentColor: ${colors.secondaryAccentColor[ColorValueIndexes.hex]};
			--classicButtonTextColor: ${colors?.buttonTextColor?.[ColorValueIndexes.hex] || '#ffffff'};
			--classicBackgroundColor: ${colors.backgroundColor[ColorValueIndexes.hex]};
			--classicContainerColor: ${colors.containerColor[ColorValueIndexes.hex]};
			--classicHeadingTextColor: ${colors.headingTextColor[ColorValueIndexes.hex]};
			--classicBodyTextColor: ${colors.bodyTextColor[ColorValueIndexes.hex]};
			--classicSecondaryBackgroundColor: ${colors.secondaryBackgroundColor[ColorValueIndexes.hex]};		
		`;
};

export default getDefaultPackColors;
