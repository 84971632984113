import { Suspense, lazy } from 'react';
const CustomCSSWrapped = lazy(() => import('./edit-css-wrapped'));

interface CustomCssProps {
	value: string;
	useSafeCss: boolean;
	setUseSafeCss: (key: any, on: boolean) => void;
	onChange: ([css, compiled_scss]: [string, string | undefined]) => void;
	open: boolean;
	onClose: () => void;
	onCancel?: () => void;
	isLeaderboard?: boolean;
}

export default function CustomCSS(props: CustomCssProps): JSX.Element {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<CustomCSSWrapped {...props} />
		</Suspense>
	);
}