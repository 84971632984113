import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from 'react-router';
import { batch, useDispatch } from "react-redux";
import classNames from "classnames";
import { throttle } from "underscore";

import { EditorSizes } from "../../../../types/template-layouts";
import {
	ActionEvent,
	ActionTargetType,
	ActionType,
	BlProfile,
	BrandliveEvent,
	Dictionary,
	EBroadcastTypes,
	FeatureFlagsEnum,
	LanguagesAbbr,
	MiscellaneousActionData,
	Session,
	SessionPlaybackVideo,
	SessionTypesEnum
} from "../../../../types/working-model";
import {
	getTemplateClassName,
	isiOSMobile,
	isAndroidMobile
} from "../../../../utils/utils";
import { useSocket } from "../../../../connection/socket";
import useLive from "../../../../utils/use-live";
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import { useTracking } from '../../../../utils/tracking';
import useTimestampStatus, { ETimestampStatus } from '../../../../utils/use-timestamp-status';
import { setFiresideLive } from '../../../../store/actions/event/firesides-actions';
import ErrorBoundary from "../../../../utils/error-boundary";
import { toggleChatPanel } from "../../../../store/actions/event/chat-actions";
import store from "../../../../store/main";
import { ParamsProps } from "../../live-event";
import { setLiveSessionIds } from "../../../../store/actions/admin/create-event";
import { useIsNewModuleGrouping, useIsSingleSession } from "../../../../hooks/session.hooks";
import { useIsNewNavigation } from "../../../../hooks/navigation.hooks";
import AboveTheFoldContent from "../above-the-fold/above-the-fold-content";
import { exitFullscreen, getOnDemandVideo, isTestBroadcast, requestFullscreen } from "./video/utils";
import { SessionStreamProvider } from "./session-stream-provider";
import Video from './video/video';
import * as Signals from '../../../../utils/event-emitter';
import SessionThumbnail from "./session-thumbnail";
import FiresideHostBanner from "./fireside-host-banner";
import { useFiresideMeetData } from "../hooks/fireside-meet";

import '../../../../scss/live-event/base/session/session-stream.scss';

const LiveChat = React.lazy(() => import('../live-chat/live-chat'));
const AddToCalendarModal = React.lazy(() => import('../../modules/agenda/add-to-calendar-modal/add-to-calendar-modal'));

import '../../../../scss/live-event/base/session/session-stream.scss';
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";
import { useCurrentDynamicVideo } from "../hooks/video-hooks";
import { addSessionVideosLive, deleteLiveSessionVideo } from "store/actions/event/event-actions";
import { adminAddSessionVideosLive, adminDeleteLiveSessionVideo } from "store/actions/admin/create-event";

interface SessionStreamProps {
	session: Session;
	eventBundle: BrandliveEvent;
	isEditor?: boolean;
	languageProp?: LanguagesAbbr;
	editorSize?: string;
	isAdmin?: boolean;
	interacted?: boolean;
	moderatorView?: boolean;
	sessionHeaderRef?: React.MutableRefObject<HTMLDivElement | null>;
	googleMeetBreakoutsRef?: React.MutableRefObject<HTMLDivElement | null>;

	/** @deprecated only included for legacy sessions currentDynamicVideo should be used instead */
	playback?: string;
	/** @deprecated only included for legacy sessions currentDynamicVideo should be used instead */
	secondaryVideos?: Dictionary;
}

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>;
		msExitFullscreen?: () => Promise<void>;
		webkitExitFullscreen?: () => Promise<void>;
		mozFullScreenElement?: Element;
		msFullscreenElement?: Element;
		webkitFullscreenElement?: Element;
	}

	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>;
		mozRequestFullscreen?: () => Promise<void>;
		webkitRequestFullscreen?: () => Promise<void>;
	}
}

const isIphone = isiOSMobile();
const isAndroid = isAndroidMobile();
const isMobile = isIphone || isAndroid;

const SessionStream: React.FC<SessionStreamProps> = ({
	session,
	eventBundle,
	isEditor,
	languageProp,
	editorSize = '',
	isAdmin = false,
	moderatorView,
	googleMeetBreakoutsRef,
	playback
}) => {
	const dispatch = useDispatch();
	const { isLessThan1024, isLessThan640 } = useScreenMediaQuery();
	const [currentDynamicVideo, livePlaybackUrl] = useCurrentDynamicVideo(session);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const loadingEventBundle = useTypedSelector(state => state.LiveEventReducer.loadingEventBundle);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	// const isChatPanelOpen = useTypedSelector(state => state.ChatReducer.isChatPanelOpen);
	// hiding this behind a feature flag so we can test on real devices and roll back if there is an issue
	const [/*currentlyWatching*/, setCurrentlyWatching] = useState<string | null>(null);
	const [hasScrolledPast, setHasScrolledPast] = useState(false);
	const [openAddToCalendar, setOpenAddToCalendar] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [tryNativeFullscreen, setTryNativeFullscreen] = useState(false);

	// isMobile or isLessThan1024 (because "tablet" is considered less than 1024 in our chat layout)
	const isMobileEditor = editorSize === EditorSizes.mobile;
	const isTabletEditor = editorSize === EditorSizes.tablet;

	const isEditorNonDesktop = isMobileEditor || isTabletEditor;
	const isFireside = session.session_type === SessionTypesEnum.fireside;
	const isFiresideTablet = isFireside && isLessThan1024;
	const [chatClosed, setChatClosed] = useState(isMobile || isLessThan640 || isFiresideTablet);
	const language = languageProp ?? useParams<ParamsProps>()?.language ?? 'en';

	// const isSessionDetailsAboveTheFoldV2 = useIsAboveTheFold(session);
	const isNewModuleGrouping = useIsNewModuleGrouping();

	const template = getTemplateClassName(eventBundle.template.name);
	const isIFrameBroadcast = session.session_type === SessionTypesEnum.broadcast && session.broadcast_type === EBroadcastTypes.embed;
	const useiOSNativePlayer = featureFlags?.[FeatureFlagsEnum.iOS_mobile_native_player];

	// orientation can either be 0, -90, 90, or 180 https://developer.apple.com/documentation/webkitjs/domwindow/1632568-orientation.
	const isLandscapeIphone = isIphone && (window.orientation === -90 || window.orientation === 90);

	const isTest = isTestBroadcast(session, !!isEditor);
	const sessionName = session.title.base;
	const sessionChatEnabled = moderatorView ? !moderatorView : session.session_chat_enabled;

	const socket = useSocket(`session-${session.uuid}-${language}`);

	const fsMeetData = useFiresideMeetData({ session, currentDynamicVideo });

	const {
		hostToken,
		isFiresideMeet
	} = fsMeetData;

	const [isLive, streamStartTime] = useLive(livePlaybackUrl, session, featureFlags);

	const scrollContainer = useRef<HTMLDivElement | null>(null);
	const container = useRef<HTMLDivElement | null>(null);

	const session_type = session?.session_type;

	const sessionUUID = session.uuid;

	useEffect(() => {
		dispatch(setLiveSessionIds({ sessionUUID, isLive }));
		if (isLive && !isTest) {
			if (session_type === SessionTypesEnum.fireside) {
				dispatch(setFiresideLive(Date.now()));
			}
		} else {
			if (session_type === SessionTypesEnum.fireside) {
				dispatch(setFiresideLive(null));
			}
		}
	}, [isLive, isEditor, session_type, dispatch, language, sessionUUID, isTest]);

	useEffect(() => {
		const playerUrlListener = (data: unknown) => {
			if (typeof data === 'string' || data === null || data === undefined) {
				if (data) {
					const payload: SessionPlaybackVideo = {
						session_uuid: session.uuid,
						playback_url: data,
						language: language as LanguagesAbbr,
						type: 'live_stream'
					};
					batch(() => {
						dispatch(adminAddSessionVideosLive(payload));
						dispatch(addSessionVideosLive(payload));
					});
				} else {
					batch(() => {
						dispatch(adminDeleteLiveSessionVideo(session.uuid, language));
						dispatch(deleteLiveSessionVideo(session.uuid, language));
					});
				}
			}
		};

		const currentlyOnPage = (data: { viewers: string | null; }) => {
			setCurrentlyWatching(data.viewers);
		};

		socket.addListener('playbackUrl', playerUrlListener);
		socket.addListener('on-page', currentlyOnPage);

		//get currently watching on load
		socket.getCurrentlyWatching(({ viewers }) => {
			setCurrentlyWatching(viewers);
		});

		return () => {
			socket.removeListener('playbackUrl', playerUrlListener);
			socket.removeListener('on-page', currentlyOnPage);
		};
	}, [socket, session.uuid, dispatch, language]);

	const timestampStatus = useTimestampStatus(session);

	const styleOverrides = useMemo(() => {

		if (session.video?.customizations?.backgroundColor) {
			return { backgroundColor: `var(--${session.video?.customizations?.backgroundColor})`, padding: isEditor ? 0 : undefined };
		}

		if (session.video?.customizations?.backgroundImage) {
			return { backgroundImage: `url(${session.video?.customizations?.backgroundImage})` };
		}

		return {};
	}, [session, isEditor]);

	useEffect(() => {
		const handleScroll = throttle(() => {
			const rect = scrollContainer.current?.getBoundingClientRect();
			if (rect) {
				// if screen is less than 640px we immediately fix the video to the top as soon as we scroll,
				// so we only have to cheeck if we have scrolled any amount
				if (window.innerWidth < 640) {
					// const scrolledPast = window.scrollY > 0;

					// video is position: fixed on mobile, so we don't need to do anything with the video on scroll
					setHasScrolledPast(false);
				} else {
					// else if greater than 640
					// we need to see if we've scrolled past the video height, because that's when we fix the video to the screen
					const scrolledPast = Math.abs(rect.y) > rect.height;
					if (scrolledPast !== hasScrolledPast) {
						setHasScrolledPast(scrolledPast);
					}
				}
			}
		}, 500);

		if (scrollContainer.current) {
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleScroll);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleScroll);
		};
	}, [scrollContainer, hasScrolledPast,]);

	const closeAddToCalendar = useCallback(() => {
		setOpenAddToCalendar(false);
	}, []);

	// const openAddToCalendarModal = useCallback(() => {
	// 	setOpenAddToCalendar(true);
	// }, []);

	// fireside and iframe broadcasts never uses chat overlay
	const forceOverlay = useMemo(() => (
		!isFireside
		&& !isIFrameBroadcast
		&& (editorSize === EditorSizes.tablet || (isLessThan1024 && !isLessThan640))
	), [isFireside, isIFrameBroadcast, editorSize, isLessThan1024, isLessThan640]);

	// so for tablet, fireside and iframe broadcasts acts like mobile
	const mobileOnly =
		isLessThan640
		|| editorSize === EditorSizes.mobile
		|| isFiresideTablet
		|| (isFireside && editorSize && editorSize !== EditorSizes.desktop)
		|| (isIFrameBroadcast && isLessThan1024)
		|| (isIFrameBroadcast && editorSize && editorSize !== EditorSizes.desktop)
		// only need to check landscape for iphone cause landscape on Android will force a fullscreen of the video
		|| isLandscapeIphone;

	const didEnterFullscreen = useCallback(() => {
		setIsFullscreen(true);
	}, []);

	const errorEnterFullscreen = useCallback((e: unknown) => {
		setIsFullscreen(false);
		console.error(e);

		setTryNativeFullscreen(true);
	}, []);

	const onEndNativeFullscreen = useCallback(() => {
		setTryNativeFullscreen(false);
	}, []);

	const didExitFullscreen = useCallback(() => {
		setIsFullscreen(false);
		setHasScrolledPast(false);
	}, []);

	useEffect(() => {
		const _container = container.current;
		const handleFullscreenChange = () => {
			if (document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
				setIsFullscreen(true);
			} else {
				setIsFullscreen(false);
			}
		};

		_container?.addEventListener('fullscreenchange', handleFullscreenChange);
		_container?.addEventListener('webkitfullscreenchange', handleFullscreenChange);
		_container?.addEventListener('webkitendfullscreen', handleFullscreenChange);
		_container?.addEventListener('fullscreentrigger', handleFullscreenChange);

		return () => {
			_container?.removeEventListener('fullscreenchange', handleFullscreenChange);
			_container?.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
			_container?.removeEventListener('webkitendfullscreen', handleFullscreenChange);
			_container?.removeEventListener('fullscreentrigger', handleFullscreenChange);
		};
	}, [isFullscreen]);

	const { trackEvent } = useTracking();

	useEffect(() => {
		if (isEditor) return;

		const validPasscodeLists = store.getState().LiveEventReducer.validPasscodeLists;
		const timer = setTimeout(() => {
			const { end_timestamp: session_end, timestamp: session_start } = session;
			const session_type = isLive ? SessionTypesEnum.broadcast : SessionTypesEnum.onDemand;
			const miscPayload: Pick<MiscellaneousActionData, 'attendance' | 'valid_passcode_lists'> = {
				attendance: {
					session_end,
					session_start,
					session_type,
					user_session_start: Date.now(),
					event_name: eventBundle.name,
					session_name: sessionName,
					email: blProfileUser?.email ?? null
				},
				valid_passcode_lists: validPasscodeLists
			};

			trackEvent({
				action: ActionEvent.View,
				action_type: ActionType.Passive,
				target_type: ActionTargetType.Attendance,
				target_id: session.session,
				miscellaneous: JSON.stringify(miscPayload)
			});
		}, 90 * 1000); // fire after 90 seconds

		return () => clearTimeout(timer);
		// Fire only if the streaming status changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditor, isLive]);

	/**
	 * Browsers haven't fully adopted the requestFullscreen API.
	 *
	 * Need to polyfill a bunch here.
	 */
	const handleFullscreen = useCallback((fullscreen: boolean) => {
		if (!container.current) return;

		if (fullscreen) {
			requestFullscreen(container.current)
				.then(didEnterFullscreen)
				.catch(errorEnterFullscreen);
		} else {
			exitFullscreen()
				.then(didExitFullscreen)
				.catch(errorEnterFullscreen);
		}
	}, [didEnterFullscreen, didExitFullscreen, errorEnterFullscreen]);

	useEffect(() => {
		const handleOrientation = (e: Event) => {
			if (e.target && e.target instanceof ScreenOrientation) {
				handleFullscreen(e.target.type === 'landscape-primary' || e.target.type === 'landscape-secondary');
			}
		};

		if (screen?.orientation?.addEventListener) {
			screen.orientation.addEventListener('change', handleOrientation);

			return () => {
				screen.orientation.removeEventListener('change', handleOrientation);
			};
		}
	}, [handleFullscreen]);

	useEffect(() => {
		Signals.on('toggle-fullscreen', () => handleFullscreen(!isFullscreen));
		Signals.on('open-add-to-calendar-modal', () => setOpenAddToCalendar(true));
		return () => {
			Signals.off('toggle-fullscreen');
			Signals.off('open-add-to-calendar-modal');
		};
	}, [isFullscreen, handleFullscreen]);

	const handleChatClose = useCallback((chatClosed: boolean) => {
		dispatch(toggleChatPanel(false));
		setChatClosed(chatClosed);
	}, [dispatch]);

	useEffect(() => {
		// if it is a fireside, chat opens automatically in the initial loading
		// unless screen size is tablet or smaller
		if (
			isFireside
			&& timestampStatus === ETimestampStatus.preLive
			&& !isLessThan1024
			&& !isMobile
		) {
			setChatClosed(false);
		}
	}, [timestampStatus, isFireside, isLessThan1024]);

	// because the session header position is absolute, we cannot properly set the width using css
	// so we dynamically set the width of the header to be the width of the session stream placeholder
	const refToContainHeader = useRef<HTMLDivElement | null>(null);
	const showNewNav = useIsNewNavigation();
	const isSingleSession = useIsSingleSession();

	const [/*_*/, onDemandVideo] = useMemo(() => {
		return getOnDemandVideo(session, language);
	}, [language, session]);

	const isOverlayChat = session.layout?.overlayChat || forceOverlay;

	if (session.session_type === SessionTypesEnum.breakoutRooms) {
		return null;
	}

	return (
		<SessionStreamProvider
			session={session}
			event={eventBundle}
			language={language}
			isLive={fsMeetData.isFiresideMeet ? fsMeetData.isFiresideLive : isLive}
			isEditor={isEditor}
			isIphone={isIphone}
			isMobile={isMobile}
			chatClosed={chatClosed}
			onDemandVideo={onDemandVideo}
			hasScrolledPast={hasScrolledPast}
			chatIsOverlaid={isOverlayChat && !isLessThan640}
			streamStartTime={streamStartTime}
			manualPip={hasScrolledPast && !isEditor && !isMobile && !isFullscreen}
			moderatorView={moderatorView}
			fsMeetData={fsMeetData}
			currentDynamicVideo={currentDynamicVideo}
		>
			<div
				className={classNames("session-stream-wrapper version-2", template, {
					'chat-closed': chatClosed || isLessThan640,
					'overlay-chat': isOverlayChat,
					'fireside-session': isFireside && !isEditor,
					[timestampStatus]: isLive ? ETimestampStatus.live : timestampStatus,
					['supports-fullscreen']: isIphone && useiOSNativePlayer,
					'moderator-view': moderatorView,
					'dark-mode': isDarkMode,
					'new-navigation': showNewNav && isSingleSession,
					'is-pip': hasScrolledPast,
				})}
				style={{ ...styleOverrides, ...(moderatorView ? { height: '100%' } : {}) }}
				ref={scrollContainer}
			>
				<div
					className='session-stream-container version-2'>
					<div
						className={
							classNames(
								"session-stream-container-inner",
								{
									center: !!isOverlayChat,
									"greater-than-1024": !isLessThan1024 && !isTabletEditor && !isMobileEditor,
								}
							)
						}
						ref={refToContainHeader}
					>

						<SessionThumbnail
							session={session}
							isEditor={isEditor}
						/>
						<div
							id="player-container"
							className={
								classNames(
									"session-stream",
									"version-2",
									{
										"scrolled-past": hasScrolledPast && !isEditor && !isMobile && !isFullscreen,
										"is-iphone": isIphone,
										"less-than-1024": isLessThan1024 || isMobileEditor || isTabletEditor,
										"greater-than-640": !isLessThan640 && !isMobileEditor,
										"is-editor": isEditor,
										"is-fullscreen": isFullscreen,
										"meet-fireside": isFireside && isFiresideMeet && !isEditor,
									},
								)
							}
							ref={container}>
							<FiresideHostBanner fsMeetData={fsMeetData} />
							<ErrorBoundary uniqueLabel="Session stream">
								<Video
									session={session}
									onDemandVideo={onDemandVideo}
									isFullscreen={isFullscreen}
									onRequestFullscreen={handleFullscreen}
									onChatClose={handleChatClose}
									chatClosed={chatClosed}
									hideChatButton={false}
									chatIsOverlaid={false}
									isMobile={isMobile}
									pip={hasScrolledPast && !isEditor && !isMobile && !isFullscreen}
									isAdmin={isAdmin}
									event={eventBundle}
									moderatorView={moderatorView}
									tryNativeFullscreen={tryNativeFullscreen}
									onEndNativeFullscreen={onEndNativeFullscreen}
									hostToken={hostToken}
									firesideMeetData={fsMeetData}
								/>
							</ErrorBoundary>

							{//for tablet, we force the overlay regardless of what the admin has selected (except for Firesides and iFrame broadcasts)
								(!moderatorView && !mobileOnly && isOverlayChat) && sessionChatEnabled && (
									<Suspense fallback="">
										<ErrorBoundary uniqueLabel="Live chat overlay">
											<LiveChat
												session={session}
												loading={!!loadingEventBundle}
												language={language}
												registrationOn={!!eventBundle?.registration_on}
												blProfileUser={blProfileUser as BlProfile}
												template={template}
												forceOverlay={true}
												mobileOnly={false}
												onChatClose={handleChatClose}
												chatClosed={chatClosed}
												eventBundle={eventBundle}
												isAdmin={isAdmin}
												canGoLandscape={false}

											/>
										</ErrorBoundary>
									</Suspense>
								)
							}
						</div>
					</div>
					{isNewModuleGrouping && !isLessThan1024 && !isEditorNonDesktop && !moderatorView ? // && isChatPanelOpen
						<Suspense fallback="">
							<AboveTheFoldContent
								session={session}
								language={language}
								googleMeetBreakoutsRef={googleMeetBreakoutsRef}
								chatClosed={chatClosed}
								isEditor={!!isEditor}
								overlayChatEnabled={isOverlayChat}
							/>
						</Suspense> :
						//necessary to break these up to get the layouts correct

						!isOverlayChat
						&& !isFullscreen
						&& !mobileOnly
						&& sessionChatEnabled
						&& !moderatorView
						&& (
							<Suspense fallback="">
								<ErrorBoundary uniqueLabel="Live chat not overlay">
									<LiveChat
										session={session}
										loading={!!loadingEventBundle}
										language={language}
										registrationOn={!!eventBundle?.registration_on}
										blProfileUser={blProfileUser as BlProfile}
										template={template}
										forceOverlay={false}
										mobileOnly={false}
										onChatClose={handleChatClose}
										chatClosed={chatClosed}
										eventBundle={eventBundle}
										isAdmin={isAdmin}
									/>
								</ErrorBoundary>
							</Suspense>
						)
					}
				</div >

				{!moderatorView &&
					<Suspense fallback="">
						<AddToCalendarModal
							session={session}
							eventName={eventBundle?.name || ""}
							open={openAddToCalendar}
							close={closeAddToCalendar}
							language={language}
							isSingleSessionNoHomepage={eventBundle?.sessions?.length === 1 && !eventBundle?.homepage}
						/>
					</Suspense>
				}
			</div >
		</SessionStreamProvider>

	);
};

export default SessionStream;
