import { GetHv, HvHostMap, PostHv, PutHv, PutHvJson } from './helpers';
import { BlProfile, BrandliveEvent, Dictionary, RegistrationStepType, StepsCompleted, GlobalPrice, LanguagesAbbr, MarketingOptInTypes, OptInStatus, SessionVideo, SessionPlaybackVideo } from '../types/working-model';
import { FontPack } from 'types/theme-packs';
import { getStorageItem } from 'utils/local-storage';

export enum EValidateRegistrationResponseType {
	valid = "valid",
	invalid = "invalid"
}

export function ValidateRegistration(eventUuid: string, registration_id: string, userCode: string | null, bl_token?: string, is_moderator?: boolean): Promise<IRegistrationResponse> {
	return PostHv(HvHostMap.registration, `/e3-validate-registration/${eventUuid}${is_moderator ? '?is_moderator=true' : ''}`, { registration_id, userCode }, bl_token);
}

interface Dict {
	[key: string]: any;
}

export interface IRegistrationResponse {
	authorizedPages: number[];
	blProfile?: BlProfile;
	error?: string;
	eventBundle: BrandliveEvent;
	firesidesHost?: number[];
	gdpr?: IRegistrationGDPR;
	leaderboardIsOn: boolean;
	newLinkGenerated?: boolean;
	paidSessions: Array<any>;
	paymentRequired: boolean;
	playbackUrls: any;
	registered_language?: string; // Has to use underscore instead of carmel case since it is what is returned from microservices. 
	registrationId: string;
	registration_bypass: boolean;
	secondaryVideos: Dictionary;
	stepsCompleted: StepsCompleted;
	token: string;
	used?: string;
	userCode?: string; // Code generated when email link is clicked
	userSavedSessions?: string[];
	userVerified: boolean; // Email Link clicked
	validSessions: Array<any>,
	statusCode?: number;
	registrationDeleted: boolean;
	sessionVideos?: SessionPlaybackVideo[];
}

export interface IRegistrationRequest {
	action?: string;
	allQueryParams?: Record<string, string>;
	authCode?: string;
	fields: Dict;
	hostname?: string;
	isFinalNonTicketStep: boolean;
	isFinalStep: boolean;
	isMarketingOptInChecked?: boolean;
	lang: string;
	optInStatus?: IOptInStatus;
	redirect_uri?: string;
	referrer?: string;
	registered_language: LanguagesAbbr;
	source: string;
	step?: RegistrationStepType;
	timezone: string;
	userCode?: string;
	utmParams?: Record<string, string>;
	gdpr?: IRegistrationGDPR;
	password?: string;
}

export interface IRegistrationGDPR {
	cookies_accepted?: boolean;
	cookies_accepted_at?: number;
}

export interface IOptInStatus { singleOptIn: string | undefined, doubleOptIn: string | undefined }
export interface GoogleSSORequest {
	token: string;
	passcode?: string;
	lang: string;
	source: string;
	timezone: string;
	userCode?: string;
	utmParams?: string;
	referrer?: string;
}

export function Register(request: IRegistrationRequest, uuid: string, bl_token?: string, headers?: Record<string, string>, is_moderator?: boolean): Promise<IRegistrationResponse> {
	return PostHv(HvHostMap.registration, `/e3-create-registration/${uuid}${is_moderator ? '?is_moderator=true' : ''}`, { ...request }, bl_token, undefined, headers);
}

export function SSORegister(request: GoogleSSORequest, uuid: string, bl_token?: string): Promise<IRegistrationResponse> {
	return PostHv(HvHostMap.registration, `/e3-sso-registration/${uuid}`, { ...request }, bl_token);
}

export interface IProfileEditRequest {
	fields: Dict;
	channel: number;
	avatar: string | null;
	registrationId: string;
}
export interface IProfileComplete extends IProfileEditRequest {
	lang: string;
	eventUuid?: string;
	emailAddress?: string;
	source?: string;
	timezone?: string;
	step?: RegistrationStepType;
}

export interface IStripeOptions {
	channel_id: number,
	event_uuid: string,
	lang: string;
	profile: BlProfile | null,
	registration_uuid: string | null,
	source?: string;
	timezone?: string;
}

interface EventInfo {
	eventName: string;
	marketing_email_options: MarketingOptInTypes | null | undefined;
	eventUUID: string;
	fonts: FontPack;
}

export interface IStripeData {
	[key: string]: string | boolean | GlobalPrice;
}


export interface IEventbriteTicketPurchaseData {
	orderId: string;
	eventUuid: string;
}
export interface IEventbriteOptions {
	channel_id: number,
	event_uuid: string,
	lang: string;
	profile: BlProfile | null,
	registration_uuid: string | null,
	source?: string;
	timezone?: string;
}
export function EventbriteTicketPurchase(data: IEventbriteTicketPurchaseData): Promise<{ details: Record<string, string>; }> {
	return PostHv(HvHostMap.registration, `/e3-eventbrite-ticket-purchase`, { data });
}

export function CompleteProfile(request: IProfileComplete, bl_token?: string): Promise<{ token: string; }> {
	return PostHv(HvHostMap.registration, `/e3-complete-profile`, { ...request }, bl_token);
}

export function EditProfile(profile: IProfileEditRequest, eventUuid: string, bl_token?: string): Promise<{ token: string; }> {
	return PostHv(HvHostMap.registration, `/e3-edit-profile`, { profile, eventUuid }, bl_token);
}

export function GetMarketingEmailSelection(token: string, eventUUID: string, blProfile: number): Promise<any> {
	return GetHv(HvHostMap.registration, `/marketing-email-selection/${eventUUID}/${blProfile}`, token);
}

export function updateMarketingEmailSelection({ eventUUID, blProfile, profileUUID, source, email, fromDoubleOptIn, eventInfo, optinTextStatus, channel }: { eventUUID: string, blProfile?: number, profileUUID?: string, source?: string, email?: string, fromDoubleOptIn?: boolean, eventInfo?: EventInfo, optinTextStatus?: OptInStatus, channel: number }): Promise<{ status: string }> {
	return PostHv(HvHostMap.registration, `/marketing-email-selection`, { eventUUID, blProfile, profileUUID, source, email, fromDoubleOptIn, eventInfo, optinTextStatus, channel });
}

export function CreateStripeIntent(options: IStripeOptions, data: IStripeData): Promise<{ clientSecret: string; }> {
	return PostHv(HvHostMap.stripe, '/e3-create-intent', { options, data });
}
export interface IReRegistrationRequest {
	lang: string;
	timezone: string;
	eventUuid: string;
	email: string;
	action?: string; // for recaptcha v3
	hostname?: string; // for recaptcha v3
	isMagicLink?: boolean;
	password?: string;
}

export function ReRegister({ lang, timezone, eventUuid, email, hostname, action, isMagicLink, password }: IReRegistrationRequest, headers = {}): Promise<any> {
	return PostHv(
		HvHostMap.registration,
		`/e3-reregister/${eventUuid}`,
		{
			emailAddress: email,
			lang,
			timezone,
			source: window.location.href.replace('/registration', '').split(/\?|#/)[0],
			hostname,
			action,
			isMagicLink,
			password
		},
		undefined,
		false,
		headers,
	);
}

export function ReRegisterCheck(eventUuid: string, code: string): Promise<IRegistrationResponse> {
	return PostHv(HvHostMap.registration, `/e3-reregister-check/${eventUuid}`, { code });
}

export function RegistrationSignIn(
	eventUuid: string,
	email: string,
	password: string,
	source: string,
	lang: string,
	timezone: string
): Promise<{ token: string, registrationId: string; }> {
	return PostHv(HvHostMap.registration, '/e3-registration-sign-in', {
		eventUuid,
		email,
		password,
		source,
		lang,
		timezone,
		userCode: getStorageItem('user-code')
	});
}

export function RequestRegistrationPasswordReset(email: string, language: LanguagesAbbr, eventUuid: string): Promise<boolean> {
	return PostHv(HvHostMap.registration, `/e3-request-registration-password-reset`, { email, language, eventUuid });
}

export function UpdateRegistrationPassword(
	eventUuid: string,
	code: string,
	password: string,
	source: string,
	lang: string,
	timezone: string
): Promise<{ token: string, registrationId: string; }> {
	return PutHvJson({
		service: HvHostMap.registration,
		path: `/e3-update-registration-password`,
		data: {
			eventUuid,
			code,
			password,
			source,
			lang,
			timezone
		}
	});
}

export function MagicLinkCheck(eventUuid: string, code: string, lang?: string | null | undefined): Promise<IRegistrationResponse> {
	return PostHv(HvHostMap.registration, `/e3-magiclink-check/${eventUuid}`, {
		code,
		lang,
		// Copying ReRegister's pattern and grabbing the host and path from window
		siteUrl: window.location.href.split(/\?|#/)[0]
	});
}

interface IValidateRegisteredUserSessions {
	eventUuid: string;
	blProfileToken: string;
	registrationId: string;
	checkType?: 'publish' | 'reload';
}

export function ValidateRegisteredUserSessions({ eventUuid, blProfileToken, registrationId, checkType }: IValidateRegisteredUserSessions): Promise<unknown> {
	return PostHv(
		HvHostMap.registration,
		`/e3-validate-registered-user-sessions/${eventUuid}`,
		{ registration_id: registrationId, checkType },
		blProfileToken,
	);
}

