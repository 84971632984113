import { showAlert } from '@general-ui/alert/alert-service';
import { useGoogleLogin } from '@react-oauth/google';
import { useCallback, useState } from 'react';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { FeatureFlagsEnum } from 'types/working-model';

// BP-2155 Remove when google meet phase 0 is over
const googleScope = 'https://www.googleapis.com/auth/meetings.space.created';

export type UseGoogleLoginHookProps = {
	setUseThirdPartyProvider?: (val: boolean) => void;
	initialMeetCode?: string;
};
export const useGoogleLoginHook = ({ initialMeetCode, setUseThirdPartyProvider }: UseGoogleLoginHookProps) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const session_id = workingSession?.session;
	const [googleMeetCode, setGoogleMeetCode] = useState(initialMeetCode);
	const [googleMeetDisabled, setGoogleMeetDisabled] = useState(false);
	const googleMeetPhase1Enabled = useGoogleMeetPhase1();
	const [googleMeetUseServiceAccount, setGoogleMeetUseServiceAccount] = useState<boolean | undefined>();

	const handleGoogleNoAuth = useCallback((errMsg?: string) => {
		const description = errMsg || "You must give permission to create and edit your Google Meet conferences to in order to continue with Meet Powered Breakout Rooms.";
		showAlert({
			message: "Credentials not stored",
			description,
			type: "error",
			duration: 5000
		});
	}, []);

	const googleLogin = useGoogleLogin({
		onSuccess: async (credentialResponse) => {
			if (!credentialResponse?.scope.includes(googleScope)) {
				handleGoogleNoAuth();
			} else if (token) {
				setGoogleMeetCode(credentialResponse.code);
				showAlert({
					message: "Credentials Saved",
					description: "Your credentials have been saved and will be used to create Google Meet rooms for breakout sessions.",
					type: "normal",
					duration: 10000
				});
				setUseThirdPartyProvider?.(true);
				setGoogleMeetDisabled(false);
			} else {
				const userMsg = 'Unable to save refresh as no token was provided';
				const errMsg = `${userMsg}: session_id: ${session_id}, token: ${token}`;
				console.error(errMsg);
				handleGoogleNoAuth(userMsg);
			}
		},
		onError: (e) => {
			const errMsg = `Error returned from Google permission/login call ${e.error_description}`;
			console.error(errMsg);
			console.error(e);
			handleGoogleNoAuth(errMsg);
		},
		flow: "auth-code", // Returns refresh token
		scope: googleScope,
	});

	const connectGoogleMeet = useCallback(() => {
		if (!googleMeetPhase1Enabled) {
			googleLogin();
		} else {
			setGoogleMeetUseServiceAccount(true);
		}
	}, [googleLogin, googleMeetPhase1Enabled]);

	return {
		googleLogin,
		connectGoogleMeet,
		handleGoogleNoAuth,
		setGoogleMeetDisabled,
		googleMeetUseServiceAccount,
		googleMeetCode,
		googleMeetDisabled
	};
};

export const useGoogleMeetPhase1 = () => {
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	return !!featureFlags[FeatureFlagsEnum.google_meet_phase_1];
};