import { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleRecaptcha } from "../../../../../../../store/actions/admin/create-event";

import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import Switch from "../../../../../../general-ui/switch/switch";
import { useReadonlyMode } from "../../../../shared/use-readonly-mode";
import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { Tooltip } from "@general-ui/tooltip/tooltip";

const RecaptchaSettings: React.FC = () => {
	const enableRecaptcha = useTypedSelector(state => !!state.CreateEventReducer.workingEvent?.registration_settings?.enableRecaptcha);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const [isOn, setIsOn] = useState(enableRecaptcha);

	const dispatch = useDispatch();

	const handleToggle = async (_value: string, _isOn: boolean) => {
		setIsOn(_isOn);
		dispatch(toggleRecaptcha(_isOn));
	};

	const isReadOnly = useReadonlyMode();

	const registration_settings = workingEvent?.registration_settings;
	const singleSignOnIsOn = registration_settings?.singleSignOn?.isOn;

	const tooltipComponent = (children: JSX.Element) => (
		<Tooltip position="bottom-left" tooltip="SSO is enabled. Please disable to use recaptcha">
			{children}
		</Tooltip>);

	return (
		<div className="card-container" style={{ pointerEvents: isReadOnly ? 'none' : 'initial' }}>
			<div className="toggle-card recaptcha-card">
				<label>Recaptcha</label>
				<div>
					<ConditionalWrapper condition={!!singleSignOnIsOn} wrapper={children => tooltipComponent(children)}>
						<Switch
							on={isOn}
							onClick={handleToggle}
							value="recaptcha"
							disabled={singleSignOnIsOn && !enableRecaptcha}
						/>
					</ConditionalWrapper>
				</div>
			</div>

		</div>
	);
};

export default RecaptchaSettings;
