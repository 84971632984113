import { handle } from 'redux-pack';

import { Action } from "../../../types/actions";
import {
	ADD_ADMIN,
	GET_ADMINS,
	SET_WORKING_PERMISSIONS,
	REMOVE_ADMIN,
	GET_CHANNEL_REDIRECTS,
	CREATE_CHANNEL_REDIRECT,
	UPDATE_CHANNEL_REDIRECT,
	ADD_ADMIN_TO_GROUP,
	REMOVE_ADMIN_FROM_GROUP,
	REMOVE_ADMIN_FROM_ALL_GROUPS,
	UNLOCK_EMAIL,
	GET_ADMINS_AND_LOCKED_EMAILS,
	CLEAR_BL_ADMIN_ERROR,
	GET_CHANNEL_API_KEYS,
	GET_PROJECT_ALERT_EMAILS,
	UPDATE_PROJECT_ALERT_EMAILS,
	ADD_ADMIN_TO_GROUPS,
	UPDATE_USER_CHANNEL_SETTINGS,
	GET_SUPER_ADMIN_NOTIFICATIONS
} from '../../actions/admin/bl-admin';
import { BLAdmin, Dictionary, SuperAdminNotification } from '../../../types/working-model';
import { ChannelRedirect } from '../../../components/admin/settings/channel-redirects';
import { ChannelApiKey } from '../../../components/admin/settings/api-key-management';
import { EmailDomainFlag } from 'types/email-domain-flags';

export interface ChannelAdminState {
	admins: (BLAdmin & { flags?: EmailDomainFlag[] })[];
	temporarilyLockedEmails: Dictionary;
	workingPermissions: string[] | null;
	redirects: ChannelRedirect[];
	updatingGroups: boolean;
	unlockingEmail: boolean;
	channelAdminError: string;
	channelAPIKeys: ChannelApiKey[];
	projectAlertEmails: string[] | null;
	superAdminNotifications: SuperAdminNotification[];
}

const initialState: ChannelAdminState = {
	admins: [],
	channelAdminError: '',
	channelAPIKeys: [],
	redirects: [],
	temporarilyLockedEmails: {},
	unlockingEmail: false,
	updatingGroups: false,
	workingPermissions: null,
	projectAlertEmails: null,
	superAdminNotifications: []
};

export default function BLAdminReducer(state: ChannelAdminState = initialState, action: Action): ChannelAdminState {
	switch (action.type) {
		case GET_ADMINS: {
			return handle(state, action, {
				success: state => ({ ...state, admins: action.payload })
			});
		}
		case GET_ADMINS_AND_LOCKED_EMAILS: {
			return handle(state, action, {
				success: state => ({ ...state, admins: action.payload.admins, temporarilyLockedEmails: action.payload.temporarilyLockedEmails }),
				failure: state => ({ ...state, channelAdminError: 'Error getting admins' })
			});
		}
		case UNLOCK_EMAIL: {
			return handle(state, action, {
				start: state => ({ ...state, unlockingEmail: true }),
				success: state => {
					const temporarilyLockedEmails = { ...state.temporarilyLockedEmails };
					delete temporarilyLockedEmails[action.payload.unlocked_email];

					return {
						...state, temporarilyLockedEmails
					};
				},
				finish: state => ({ ...state, unlockingEmail: false }),
				failure: state => ({ ...state, channelAdminError: "Error unlocking email" })
			});
		}
		case SET_WORKING_PERMISSIONS: {
			return ({ ...state, workingPermissions: action.payload });
		}
		case UPDATE_USER_CHANNEL_SETTINGS: {
			return handle(state, action, {
				success: state => {
					const updatedAdmins = [...state.admins];
					for (const admin of updatedAdmins) {
						if (admin.id === action.payload.id) {
							admin.channels = action.payload.channels;
							admin.channels_using_sso = action.payload.channels_using_sso;
							break;
						}
					}
					return {
						...state,
						admins: updatedAdmins
					};
				},
				failure: state => {
					return state;
				}
			});
		}
		case ADD_ADMIN: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						admins: [...state.admins, action.payload]
					};
				}
			});
		}
		case REMOVE_ADMIN: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						admins: state.admins.filter((admin: BLAdmin) => admin.id !== action.payload.id)
					};
				},
				failure: state => {
					return state;
				}
			});
		}
		case GET_CHANNEL_REDIRECTS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						redirects: action.payload
					};
				},
				failure: state => {
					return state;
				}
			});

		}
		case CREATE_CHANNEL_REDIRECT: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						redirects: [...state.redirects, action.payload]
					};
				},
				failure: state => {
					return state;
				}
			});

		}
		case UPDATE_CHANNEL_REDIRECT: {
			return handle(state, action, {
				success: state => {
					const redirects = [...state.redirects];
					const index = redirects.findIndex(redirect => redirect.id === action.payload.id);
					redirects[index] = action.payload;
					return {
						...state,
						redirects: redirects
					};
				},
				failure: state => {
					return state;
				}
			});
		}
		case ADD_ADMIN_TO_GROUP: {
			return handle(state, action, {
				start: state => ({ ...state, updatingGroups: true }),
				success: state => {
					const admins = state.admins.map((admin) => {
						if (admin.id === action.payload.admin_id) {
							admin.groups = [...(admin.groups ?? []), action.payload.group_uuid];
						}
						return admin;
					});

					return { ...state, admins };
				},
				finish: state => ({ ...state, updatingGroups: false })
			});
		}
		case ADD_ADMIN_TO_GROUPS: {
			return handle(state, action, {
				start: state => ({ ...state, updatingGroups: true }),
				success: state => {
					const admins = state.admins.map((admin) => {
						if (admin.id === action.payload.admin_id) {
							action.payload.group_uuids.forEach((uuid: string) => {
								admin.groups = [...(admin.groups ?? []), uuid];
							});
						}
						return admin;
					});

					return { ...state, admins };
				},
				finish: state => ({ ...state, updatingGroups: false })
			});
		}
		case REMOVE_ADMIN_FROM_GROUP: {
			return handle(state, action, {
				start: state => ({ ...state, updatingGroups: true }),
				success: state => {
					const admins = state.admins.map((admin) => {
						if (admin.id === action.payload.admin_id) {
							admin.groups = (admin.groups ?? []).filter((uuid: string) => uuid !== action.payload.group_uuid);
						}
						return admin;
					});

					return { ...state, admins };
				},
				finish: state => ({ ...state, updatingGroups: false })
			});
		}
		case REMOVE_ADMIN_FROM_ALL_GROUPS: {
			return handle(state, action, {
				start: state => ({ ...state, updatingGroups: true }),
				success: state => {
					const admins = state.admins.map((admin) => {
						if (admin.id === action.payload.admin_id) {
							admin.groups = [];
						}
						return admin;
					});

					return { ...state, admins };
				},
				finish: state => ({ ...state, updatingGroups: false })
			});
		}

		case CLEAR_BL_ADMIN_ERROR: {
			return ({ ...state, channelAdminError: '' });
		}

		case GET_CHANNEL_API_KEYS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						channelAPIKeys: action.payload
					};
				},
				failure: state => {
					return state;
				}
			});

		}

		case GET_PROJECT_ALERT_EMAILS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						projectAlertEmails: action.payload || [],
					};
				},
				failure: state => {
					return state;
				}
			});
		}

		case UPDATE_PROJECT_ALERT_EMAILS: {
			return handle(state, action, {
				success: state => ({
					...state,
					projectAlertEmails: action.payload,
				}),
				failure: state => {
					return state;
				}
			});
		}

		case GET_SUPER_ADMIN_NOTIFICATIONS: {
			return handle(state, action, {
				success: state => ({
					...state,
					superAdminNotifications: action.payload
				})
			});
		}

		default: return state;
	}
}
