import { SetStateAction, useEffect, useState } from "react";

import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { GateTypes, Requirement, RequirementMap } from "../../../../../../../types/working-model";
import { PrimaryTooltip } from "@admin/create/session/session-modal";

// UTIL IMPORTS
import { finishedWithRequirement, handleCancelRequirment } from "./requirements-utils";
import { OptionalComponent } from "utils/optional-component";

// GENERAL UI IMPORTS
import CardEditRequirementButton from "./card-edit-requirement-button";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import TextInput, { Validation } from "@general-ui/text-input/text";
import Icon, { ICONS } from "@general-ui/icon";
import { mouseAlert, showAlert } from "@general-ui/alert/alert-service";

interface CardSharedPasscodeRequirementProps {
	requirement: Requirement | undefined,
	setAllRequirements: (value: SetStateAction<RequirementMap | undefined>) => void
}

const CardSharedPasscodeRequirement: React.FC<CardSharedPasscodeRequirementProps> = (props) => {

	// The original shared code requirement. To be used when resetting the req
	const { requirement, setAllRequirements } = props;

	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector((state) => state.CreateEventReducer.workingEvent);

	const passcodeListGatings = workingEvent?.registration_settings?.requirements?.[GateTypes.passcode_list];

	// The copy of the original requirement to be edited
	const [workingGateRequirement, setWorkingGateRequirement] = useState<Requirement | undefined>({ ...requirement } as Requirement | undefined);

	const [editRequirement, setEditRequirement] = useState(!requirement?.sharedPasscode);
	const [popoverOpen, setPopoverOpen] = useState<GateTypes | null>(null);
	const [formError, setFormError] = useState("");
	const [saving, setSaving] = useState<boolean>(false);

	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!requirement?.sharedPasscode) {
			setEditRequirement(true);
		}
	}, [requirement]);

	useEffect(() => {
		if (editRequirement) {
			setWorkingGateRequirement(requirement);
		}
	}, [editRequirement, requirement]);

	function setPasscode(e: React.ChangeEvent<HTMLInputElement>) {
		const val = e.target.value.toLocaleLowerCase();
		const updatedPasscodeGating = { ...requirement, sharedPasscode: val } as Requirement | null;

		if (updatedPasscodeGating) {
			setWorkingGateRequirement(updatedPasscodeGating);
		}
	}

	function handleCancel() {
		handleCancelRequirment({
			setEditRequirement,
			setAllRequirements,
			requirement,
			setWorkingGateRequirement,
			gatingType: GateTypes.shared_passcode,
		});
	}

	function handleSave() {
		finishedWithRequirement({ user, token, fetchingPublishedStatus, setFormError, workingGateRequirement, dispatch, setWorkingGateRequirement, setSaving, setEditRequirement, passcodeListGatings });
	}

	function copyPasscode() {
		if (requirement?.sharedPasscode) {

			navigator.clipboard.writeText(requirement.sharedPasscode).then(() => {
				mouseAlert({
					message: 'Copied!',
				});
			}).catch(() => {
				showAlert({
					message: 'Unable to copy to clipboard',
					description: 'We ran into an issue while trying to copy to clipboard. Please try again later.',
					type: 'error',
					duration: 5000,
				});
			});
		}
	}

	return (
		<div className="display-options">
			<OptionalComponent display={!editRequirement}>
				<>
					<CardEditRequirementButton
						type={GateTypes.shared_passcode}
						setEditRequirement={setEditRequirement}
						popoverOpen={popoverOpen}
						setPopoverOpen={setPopoverOpen}
						setAllRequirements={setAllRequirements} />

					<button className="copy-passcode no-style no-padding options-button" onClick={copyPasscode}>
						<Icon name={ICONS.COPY_FILLED} size={16} color="white" />
					</button>

					<div className="display-option-block">
						<div className="display-option-title">Passcode</div>
						<div className="display-option-content">{requirement?.sharedPasscode}</div>
					</div>
				</>
			</OptionalComponent>

			<OptionalComponent display={!!editRequirement}>
				<>
					<TextInput
						placeholder={"Enter shared passcode"}
						label={"Shared Passcode"}
						tooltip={[PrimaryTooltip, 'Note: shared passcodes are not case sensitive']}
						onChange={setPasscode}
						value={workingGateRequirement?.sharedPasscode || ''}
						valid={formError ? Validation.error : Validation.normal}
						errorMessage={formError || undefined}
					/>

					<div className="requirement-footer">
						<button onClick={handleCancel}>Cancel</button>
						<button onClick={handleSave} className="primary lemonade">{saving ? <WaitingIndicator /> : "Save"}</button>
					</div>

				</>
			</OptionalComponent>

		</div>
	);
};
export default CardSharedPasscodeRequirement;
