import { BrandliveEvent, CreateEventRequest, CreateSession, Session, SessionTypesEnum } from "../../../types/working-model";

export class InvalidCreateSessionError extends Error {
	constructor(message: string) {
		super(message);
	}
}

type CreateSessionFunction = <T extends (CreateSession | Session) >(session: T) => T;
export const getSessionWithLanguagesFromCreateEventRequest = (event: CreateEventRequest): CreateSessionFunction => {
	const defaultLanguage = event.defaultLanguage;
	if (!defaultLanguage) {
		throw new InvalidCreateSessionError("Event default language selection is required");
	}

	return getSessionWithLanguages(defaultLanguage);
};

export const getSessionWithLanguagesFromWorkingEvent = (event: BrandliveEvent): CreateSessionFunction => {
	const defaultLanguage = event.settings.i18n?.base;
	if (!defaultLanguage) {
		throw new InvalidCreateSessionError("Event default language selection is required");
	}

	return getSessionWithLanguages(defaultLanguage);
};

const getSessionWithLanguages = <T extends (CreateSession | Session)>(defaultLanguage: string) => (_session: T): T => {
	const session = { ..._session };
	if (!session.default_language) {
		session.default_language = defaultLanguage;
	}

	if (!session.languages.length) {
		session.languages = [defaultLanguage];
	}

	if (session.session_type === SessionTypesEnum.fireside) {
		if (session.languages.length > 1) {
			throw new InvalidCreateSessionError("Fireside sessions can only have one language.");
		}

		if (session.languages[0] !== session.default_language) {
			throw new InvalidCreateSessionError("Fireside sessions can only have one language and that language must be the default language");
		}
	}

	if (!session.languages.includes(session.default_language)) {
		throw new InvalidCreateSessionError("Default language must be included in the session's languages.");
	}

	return session;
};