import Alert from "@general-ui/alert/alert";
import WaitingIndicator from "@general-ui/waiting-indicator/waiting-indicator";
import blAuth from "connection/bl-auth/bl-auth";
import { GetFeatureFlags } from "connection/feature-flags";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { usingUnifiedAuth } from "store/actions/authentication";
import { setFeatureFlags } from "store/actions/feature-flags";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { FeatureFlagsEnum } from "types/working-model";

const style: React.CSSProperties = {
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};

// ensures that feature flags are ready before attempting to render the admin side of the app
const FeatureFlagGate = ({ children }: { children: React.ReactNode }) => {
	const loading = useTypedSelector(state => state.FeatureFlagsReducer.loading);
	const dispatch = useDispatch();

	const fetchFeatureFlags = useCallback(async () => {
		const flags = await GetFeatureFlags();
		blAuth.enabled = !!flags[FeatureFlagsEnum.unified_auth];

		// handle unified auth within the auth reducer to avoid 
		// race conditions in side effects. The auth reducer should know
		// asap if unified auth is being used.
		if (flags[FeatureFlagsEnum.unified_auth]) {
			dispatch(usingUnifiedAuth());
		}

		dispatch(setFeatureFlags(flags));
	}, [dispatch]);

	useEffect(() => {
		fetchFeatureFlags();
	}, [fetchFeatureFlags]);

	if (loading) {
		return (
			<div
				id="auth-gate"
				style={style}
			>
				<WaitingIndicator />
				<Alert />
			</div>
		);
	}

	return <>{children}</>;
};

export default FeatureFlagGate;