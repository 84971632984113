import { Action } from "../../../types/actions";
import { IQuestion, ISocketCommentProps } from "../../../types/working-model";
import {
	SET_ANONYMOUS_COMMENT,
	SET_ANONYMOUS_QUESTION,
	SET_MODULE_QUESTIONS,
	SET_MODULE_QUESTION_COMMENTS
} from "../../actions/event/questions-actions";

export interface ChatState {
	questions: { [key: number]: Map<number, IQuestion> }; // [module_id]: questionsMap
	comments: { [key: number]: { [key: number]: Map<number, ISocketCommentProps> } }; // [module_id]: { [question_id]: commentsMap }
	isAnonymousQuestion: boolean;
	isAnonymousComment: boolean;
}

const defaultState: ChatState = {
	comments: {},
	isAnonymousComment: false,
	isAnonymousQuestion: false,
	questions: {},
};

export default function ChatReducer(state: ChatState = defaultState, action: Action): ChatState {
	switch (action.type) {
		case SET_MODULE_QUESTIONS: {
			return {
				...state,
				questions: {
					...state.questions,
					...action.payload,
				},
			};
		}
		case SET_MODULE_QUESTION_COMMENTS: {
			const { module_id, question_id, comments } = action.payload;
			return {
				...state,
				comments: {
					...state.comments,
					[module_id]: {
						...(state.comments[module_id] || {}),
						[question_id]: comments,
					},
				},
			};
		}
		case SET_ANONYMOUS_QUESTION: {
			return {
				...state,
				isAnonymousQuestion: action.payload,
			};
		}
		case SET_ANONYMOUS_COMMENT: {
			return {
				...state,
				isAnonymousComment: action.payload,
			};
		}

		default: return state;
	}
}
