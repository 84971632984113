import { AsyncAction, Action } from "../../../types/actions";
import { GetQuizzes } from "../../../connection/quizzes";
import { Quiz } from "../../../types/working-model";

export const GET_QUIZZES = "GET_QUIZZES";
export function getQuizzes(token: string): AsyncAction {
  return {
    type: GET_QUIZZES,
    promise: GetQuizzes(token),
  };
}

export const ADD_QUIZ = "ADD_QUIZ";
export function addQuiz(quiz: Quiz): Action {
  return {
    type: ADD_QUIZ,
    payload: quiz,
  };
}

export const UPDATE_QUIZ = "UPDATE_QUIZ";
export function updateQuiz(quiz: Quiz): Action {
  return {
    type: UPDATE_QUIZ,
    payload: quiz,
  };
}
