import { CreateSponsor, Sponsor } from "../types/working-model";
import { Get, Post, Put } from "./helpers";

export function GetSponsors(
	token: string,
	channel: number
): Promise<Sponsor[]> {
	return Get(`/v3/admin/channel/sponsors/${channel}`, token);
}

export function InsertSponsor(
	token: string,
	sponsor: CreateSponsor,
	languages?: string[]
): Promise<Sponsor> {
	return Post(`/v3/admin/channel/sponsors/`, token, { sponsor, languages });
}

export function UpdateSponsor(
	token: string,
	sponsor: Sponsor,
	languages?: string[]
): Promise<Sponsor> {
	return Put(`/v3/admin/channel/sponsors/`, token, { sponsor, languages }, false);
}
