import { uniq } from "underscore";
import { ILeaderboardSearchCriteria } from "../../../../types/leaderboard";
import { EventSettings, LanguagesAbbr, RegistrationQuestion, RegistrationStep, Session } from "../../../../types/working-model";

export const getRegistrationQuestionById = (registration_steps?: RegistrationStep[], question_id?: number | null): RegistrationQuestion | null => {
	if (!registration_steps || !question_id) return null;


	for (const step of registration_steps) {
		if (!step.questions) continue;

		for (const question of step.questions) {
			if (question.registration_question === question_id) {
				return question;
			}
		}
	}
	return null;
};

export const getUniqueSessionTrackOptions = (
	sessions: Session[] | undefined,
	t: (key: string, fallback?: string | undefined, options?: Record<string, string> | undefined) => string,
	language: LanguagesAbbr,
	eventSettings?: EventSettings,
) => {
	if (!sessions) return [];

	const tracks = [];
	for (const session of sessions) {
		tracks.push(...(session.tracks ?? []));
	}

	const uniqueTracks = uniq(tracks);

	return uniqueTracks.map(track => {
		let label = t(`home:tracks.${track}`, track);
		// if v3 translation data for tracks exists, use that instead
		const dbTranslation = eventSettings?.tracks_translations?.[track]?.[language];
		if (dbTranslation && typeof dbTranslation === 'string') {
			label = dbTranslation;
		}
		return { value: track, label };
	});
};

export const generateLeaderboardQueryParams = (values: ILeaderboardSearchCriteria, excludePage = false) => {
	const params = new URLSearchParams();
	for (const key in values) {
		if (excludePage && key === 'page') continue;
		const value = values[key as keyof ILeaderboardSearchCriteria];
		if (!value) continue;

		params.set(key, value.toString());
	}

	return params.toString();
};

export function getLeaderboardQueryParams(search: string): ILeaderboardSearchCriteria {
	const queryString = new URLSearchParams(search);
	const page = queryString.get("page");

	const searchParam = queryString.get('search') ?? '';
	const track = queryString.get('track') ?? undefined;
	const registration_question_value = queryString.get('fv') ?? undefined;

	return {
		page: Number(page) || 1,
		search: searchParam,
		track,
		fv: registration_question_value
	};
}

export const getBylineMap = (
	questions: RegistrationQuestion[],
	t: (key: string, fallback?: string | undefined, options?: Record<string, string> | undefined) => string,
	language: string
) => {
	const optionsTranslationMap = new Map<string, string>();

	for (const question of questions) {
		// first iterate down translations from V1
		if (question.options_translation) {
			question.options_translation.forEach((option) => {
				if (option[language]) {
					optionsTranslationMap.set(option.base, option[language] as string);
				}
			});
		}

		// then iterate down options from i18n, first from uploaded, then from static
		// these will override the generated ones from the step above
		question.options?.forEach((option, index) => {
			optionsTranslationMap.set(
				option,
				t(`registrations:question.${question.registration_question}.options.${index}`,
					t(`homepage:registration.registration_fields.options.${option.replace(/\./g, "")}`, option)
				)
			);
		});
	}

	return optionsTranslationMap;
};


// TODO: Remove hardcoded values
const HARDCODED_MAX_SCORE = 1420;

const EVENT_UUIDS_WITH_HARDCODED_MAX_SCORE: Array<string> = [
	// 'c303764e-c7fe-11ed-9fb0-ed5e95d24165', // Dev
	// '53674382-d3f8-11ed-9ecd-9b5a7375dd46', // Hot
	// 'e1ddd9a4-c422-11ed-af31-7bcb9ae0acdf', // Prod
];

export function getScore(score: number, eventUuid?: string): number {
	if (eventUuid && EVENT_UUIDS_WITH_HARDCODED_MAX_SCORE.includes(eventUuid)) {
		return Math.min(score, HARDCODED_MAX_SCORE);
	}
	return score;
}