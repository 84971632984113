import { PageModule, PageModuleType, Templates } from "../types/working-model";
import { GetJson, Post, Put } from "./helpers";

interface CreateModuleProps {
	type: PageModuleType;
	eventName: string;
	template: Templates;
	languages: string[];
	baseLanguage: string;
	custom_heading?: string;
}
export function CreatePageModule(
	token: string,
	{
		type,
		eventName,
		template,
		languages,
		baseLanguage,
		custom_heading
	}: CreateModuleProps
): Promise<PageModule> {
	return Post('/v3/admin/events/page-module', token, {
		type,
		eventName,
		template,
		languages,
		baseLanguage,
		custom_heading
	});
}
export function UpdatePageModule(
	token: string,
	pageModule: PageModule
): Promise<any> {
	return Put('/v3/admin/events/page-module', token, pageModule);
}
export function CreateEmptyPageModule(token: string, pageModule: PageModule): Promise<any> {
	return Post('/v3/admin/events/page-module/empty', token, pageModule);
}

export function TogglePageModuleVisibility(token: string, id: number, is_on: boolean): Promise<any> {
	return Post(`/v3/admin/events/page-module/visibility/${id}`, token, { is_on }, true);
}
export function GetPageModule(
	token: string,
	pageModuleId: number
): Promise<PageModule> {
	return GetJson({ path: `/v3/admin/events/page-module/${pageModuleId}`, token });
}
