import { useEffect, useState } from "react";
import { throttle } from "underscore";

interface IUseHasScrolledPastProps {
	scrollContainer: React.RefObject<HTMLElement>;
}

const useHasScrolledPast = ({ scrollContainer }: IUseHasScrolledPastProps) => {
	const [hasScrolledPast, setHasScrolledPast] = useState(false);

	useEffect(() => {
		const handleScroll = throttle(() => {
			const rect = scrollContainer.current?.getBoundingClientRect();
			if (rect) {
				// we need to see if we've scrolled past the element height
				const scrolledPast = window.scrollY > rect.height;
				setHasScrolledPast(scrolledPast);
			}
		}, 500);

		if (scrollContainer.current) {
			handleScroll(); // initializer
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleScroll);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleScroll);
		};
	}, [hasScrolledPast, scrollContainer]);

	return hasScrolledPast;
};

export default useHasScrolledPast;
