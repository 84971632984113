import { useTypedSelector } from "store/reducers/use-typed-selector";
import { EBroadcastTypes, FeatureFlagsEnum, Session, SessionTypesEnum } from "types/working-model";

export const useShowGreenroomButton = (session: Session): boolean => {
	const goToGreenroomForAllBroadcastTypesFlagEnabled = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.go_to_greenroom_for_all_broadcast_types]);

	const isBroadcast = session.session_type === SessionTypesEnum.broadcast;

	if (!goToGreenroomForAllBroadcastTypesFlagEnabled) {
		const isGreenroomStream = session.broadcast_type === EBroadcastTypes.greenroom;
		return !!(isBroadcast && isGreenroomStream);
	}

	// if it's an on demand but has a timestanmp, then it was once a broadcast session so we need to show the button
	const onDemandWithTimestamp = session.session_type === SessionTypesEnum.onDemand && session.timestamp;
	const shouldHaveGreenroomProducerUrl = !!(isBroadcast || onDemandWithTimestamp);

	return shouldHaveGreenroomProducerUrl;
};
