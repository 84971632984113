import React, { useCallback, useMemo, useContext, useEffect, useState, useRef, Fragment, createRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import classNames from "classnames";

import { useGetSession } from "../../../../../../hooks/session.hooks";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import {
	PageModule,
	PageModuleGroupModules,
	PageModuleType,
	SessionPanelLayoutsTypes,
	SessionTabNames,
	Templates,
	TranslateString,
} from "../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../utils/optional-component";
import { toMap, updateTranslateKey } from "../../../../../../utils/utils";
import { useGetAdminUrl } from "../../../../../../utils/admin-routing-utils";
import { setActiveTabModule, SessionPanelContext } from "./session-panel-state";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import { getSessionPanelRouteState } from "../../../../../../utils/path-utils";
import { MODULE_TAB_NAMES } from "../../../../../../types/module-tabs";
import { usePageModuleGroup } from "./hooks/panel.hooks";
import { CreatePageModule } from "../../../../../../connection/page-modules";
import { addSessionPageModule, updateGroupModules, updatePageModule, updatePageModuleAndSave } from "../../../../../../store/actions/admin/create-event/session";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import { PageModuleTypeMap, SessionPanelMap } from "./session-panel-route-map";
import TextInput from "../../../../../general-ui/text-input/text";
import { getDefaultLanguage } from "../../../../../live-event/utils";
import { GetDefaultTranslateString } from "../../../../../../store/utils/create-event";
import ModalComponent from "../../../../../general-ui/modal/modal";
import useTrackElementPositions from "../../../../../../utils/use-track-element-positions";
import useTrackHeight from "utils/use-track-height";

import "./module-tabs.scss";

const MAX_TAB_NAME_LENGTH = 24;
const APP_HEADER_HEIGHT = 82;
const PANEL_HEADER_HEIGHT = 72;
const SINGLE_ROW_TAB_FILTER_HEIGHT = 32; // the height of the tab filters if there's only one row of tabs
const ADDITIONAL_PADDING = 15; // the padding on the bottom of the tab filter container

// watch the path to determine what module is active at any given time
// they should not be set manually because user may refresh
export const ActiveTabModuleSetter = () => {
	const location = useLocation();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const { dispatch } = useContext(SessionPanelContext);

	useEffect(() => {
		if (!workingSession) return;

		const {
			isExtras,
			isEngage,
			isDetails,
			isEducation
		} = getSessionPanelRouteState(location.pathname, true);

		let module: PageModuleGroupModules | undefined;

		if (isExtras) {
			module = workingSession.module_grouping?.find(module => module.type === 'extras');
		} else if (isEngage) {
			module = workingSession.module_grouping?.find(module => module.type === 'engage');
		} else if (isDetails) {
			module = workingSession.module_grouping?.find(module => module.type === 'details');
		} else if (isEducation) {
			module = workingSession.module_grouping?.find(module => module.type === 'education');
		}

		dispatch(setActiveTabModule(module));
	}, [dispatch, location.pathname, workingSession]);

	return <></>;
};

export default function ModuleTabs(): JSX.Element {
	const history = useHistory<{ panelTitle?: string | TranslateString, activeModule?: number, currentTab?: string }>();
	const session = useGetSession();
	const { customPath, language, page_module: paramsPageModuleId } = useParams<{ language: string, customPath?: string, page_module?: string }>();
	const adminPath = useGetAdminUrl();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const [addingTab, setAddingTab] = useState(false);
	const [customTabName, setCustomTabName] = useState('');
	const [saving, setSaving] = useState(false);
	const [editingTab, setEditingTab] = useState<number | undefined>();
	const [moduleToDelete, setModuleToDelete] = useState<PageModule | undefined>();
	const [forceMutationRefresh, setForceMutationRefresh] = useState(0);
	const tabContainerRefs = useRef<Record<number, React.RefObject<HTMLDivElement>>>({});

	const dispatch = useDispatch();
	const location = useLocation();
	const { isExtras, isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const isCustomTab = !!customPath;
	const multipleSessions = workingEvent?.sessions && workingEvent.sessions.length > 1;

	const pageModuleGroup = usePageModuleGroup();

	const activeTabModule = useMemo(() => {
		return pageModuleGroup;
	}, [pageModuleGroup]);

	const modules = useMemo(() => {
		if (!activeTabModule || !session) return [];

		const moduleIds = activeTabModule.modules;
		const moduleMap = toMap('id', session.modules);

		return moduleIds.map(moduleId => moduleMap.get(moduleId)).filter(module => !!module?.is_on);
	}, [activeTabModule, session]);

	const oneTabLeft = useMemo(() => {
		if (modules.length == 1) {
			return true;
		}
		// If project has a single session, we "hide" the similar sessions tab.
		// In this case, we want to validate the tab count against all non-similar session tabs
		if (!multipleSessions) {
			return modules.filter(m => m?.type !== PageModuleType.similar_sessions).length === 1;
		}
		return false;
	}, [modules, multipleSessions]);

	const handleTab = useCallback((module: PageModule) => {
		const paths = PageModuleTypeMap(customPath ? 'custom' : 'extras');

		history.replace(adminPath({
			path: SessionPanelMap[paths[module.type as PageModuleType] as SessionPanelLayoutsTypes] ?? '',
			customPath,
			page_module: module.id
		}), {
			...history.location.state,
			activeModule: module.id,
		});
	}, [adminPath, customPath, history]);

	const saveCustomTab = useCallback(async () => {
		const paths = PageModuleTypeMap(customPath ? 'custom' : 'extras');

		try {
			if (!token || !workingEvent || !workingSession?.module_grouping || !customTabName.trim().length) {
				return;
			}

			setSaving(true);

			const page_module = await CreatePageModule(token, {
				type: PageModuleType.feed,
				eventName: workingEvent.name,
				template: Templates.Limelight,
				languages: workingSession.languages,
				baseLanguage: workingSession.default_language,
				custom_heading: customTabName.trim(),
			});

			dispatch(addSessionPageModule(page_module));

			const newModules = workingSession.module_grouping.map(group => {
				if (pageModuleGroup && pageModuleGroup.uuid === group.uuid) {
					return {
						...group,
						modules: [
							...group.modules,
							page_module.id as number
						]
					};
				} else {
					return group;
				}
			});

			setAddingTab(false);
			dispatch(updateGroupModules(newModules));

			const path = adminPath({
				path: SessionPanelMap[paths[PageModuleType.feed] as SessionPanelLayoutsTypes],
				customPath,
				page_module: page_module.id
			});

			history.replace(path, { panelTitle: history.location.state.panelTitle, activeModule: page_module.id });
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error creating tab",
				description: "We ran into an issue creating that tab. Please try again later.",
				duration: 5000,
				type: "error"
			});
		} finally {
			setCustomTabName('');
			setSaving(false);
			setAddingTab(false);
		}
	}, [adminPath, customPath, dispatch, customTabName, history, pageModuleGroup, token, workingEvent, workingSession]);

	const getTabIsActive = (module: PageModule) => {
		const paramMatch = window.location.pathname.match(/(?:[0-9]+)$/)?.[0];
		if (paramMatch) {
			return module.id === parseInt(paramMatch);
		}
	};

	const baseLanguage = workingEvent && getDefaultLanguage(workingEvent);

	const handleUpdateLabel = (module: PageModule, value: string) => {
		setEditingTab(undefined);
		setForceMutationRefresh(prev => prev + 1);
		if (!baseLanguage || !language) return;
		dispatch(
			updatePageModule({
				...module,
				content: {
					...(module.content || {}),
					custom_heading: updateTranslateKey({
						translateString: module.content?.custom_heading || GetDefaultTranslateString(),
						input: value,
						baseLanguage,
						language,
					}),
				},
			})
		);
	};

	const renderDeleteModalContent = () => {
		if (!moduleToDelete) return null;
		if (oneTabLeft) {
			return (
				<div className="delete-custom-tab-inner-modal">
					<p>You must have at least one tab.</p>
				</div>
			);
		}
		return (
			<div className="delete-custom-tab-inner-modal">
				<p>Are you sure you want to delete {moduleToDelete.content.custom_heading?.[language] || moduleToDelete.content.custom_heading?.base || ''}?</p>
			</div>
		);
	};

	const handleDeleteTab = (_moduleToDelete?: PageModule) => {
		const module = _moduleToDelete || moduleToDelete;

		if (!module) {
			setModuleToDelete(undefined);
			return;
		}

		dispatch(
			updatePageModuleAndSave({
				...module,
				is_on: false,
			})
		);
		setModuleToDelete(undefined);

		// if we're deleting the active tab, we need to navigate to a different tab
		if (Number(module.id) === Number(paramsPageModuleId)) {
			// navigate to the first tab (as long as the first tab is not this one)
			const firstAvailableModule = modules?.find(module => module?.id && module.id !== module.id);
			if (firstAvailableModule) {
				handleTab(firstAvailableModule);
			} else {
				// else just navigate back to the content page
				if (history.length) {
					history.goBack();
					return;
				}

				history.replace(
					adminPath({ path: SessionPanelMap[SessionTabNames.Content_v2] }),
					{
						panelTitle: workingSession?.title, currentTab: 'Content'
					}
				);
			}
		}
	};

	// because the tab actions container is directly underneath the panel header they get cut off when using position absolute
	// so we must use position fixed and track the position of the tab actions container for each tab
	useEffect(() => {
		if (modules) {
			modules?.forEach((_, idx) => {
				tabContainerRefs.current[idx] = createRef<HTMLDivElement>();
			});
			setForceMutationRefresh(prev => prev + 1);
		}
	}, [modules]);

	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const _containerRef = containerRef.current;
		if (!_containerRef) return;

		// I would normally throttle this but it's not a huge deal if it fires a few extra times because it's such a small
		// component in the app that doesn't cause any re-renders in any other components. And it's not likely to be scrolled a lot.
		const handleScroll = () => {
			setForceMutationRefresh(prev => prev + 1);
		};

		_containerRef.addEventListener('scroll', handleScroll);

		return () => {
			_containerRef.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const { elementPositions } = useTrackElementPositions({
		refs: tabContainerRefs,
		forceRerender: forceMutationRefresh,
		cancelTracking: !!editingTab || addingTab || saving,
	});

	const { height: containerHeight } = useTrackHeight({
		ref: containerRef.current,
	});

	useEffect(() => {
		if (document.documentElement) {
			const _containerHeight = containerHeight || (SINGLE_ROW_TAB_FILTER_HEIGHT + ADDITIONAL_PADDING);
			const totalHeight = _containerHeight + PANEL_HEADER_HEIGHT + APP_HEADER_HEIGHT + ADDITIONAL_PADDING;
			document.documentElement.style.setProperty('--panel-tab-filter-height', `${totalHeight}px`);
		}
	}, [containerHeight]);

	// if this is a custom tab, only render when a module has actually been added
	if (isCustomTab && activeTabModule?.modules.length === 0) {
		return <></>;
	}

	// no other tab types render sub tabs at this time
	if (!isCustomTab && !isExtras && !isExtrasCustom) {
		return <></>;
	}

	return (
		<div className={classNames("tab-filter panel-tab-filter", { custom: !!customPath })} ref={containerRef}>
			{modules?.map((module, idx) => {
				if (!module) return <Fragment key={idx} />;
				const isActiveTab = getTabIsActive(module);
				const moduleType = module.type || PageModuleType.documents;
				const isSuggested = module.type === PageModuleType.similar_sessions;

				// do not show suggested sessions tab if there is only one session 
				// see src/components/admin/create/session/navigation/panel/empty-state-panel/empty-state-panel.tsx
				if (isSuggested && !multipleSessions) {
					return <Fragment key={module.id}></Fragment>;
				}

				const label = (() => {
					return module.content.custom_heading?.[language]
						|| module.content.custom_heading?.base
						|| MODULE_TAB_NAMES[moduleType]
						|| '';
				})();

				return (
					<OptionalComponent key={module.id} display={module.is_on}>
						<div
							className="module-tab editable-tab"
							ref={tabContainerRefs.current[idx]}
						>
							<div className={classNames('lemonade-wrapper', { "selected": isActiveTab && !addingTab })}>
								<OptionalComponent display={editingTab === module.id}>
									<TextInput
										className="tab-name-input"
										autoFocus
										maxLength={MAX_TAB_NAME_LENGTH}
										highlightOnFocus
										defaultValue={label}
										onBlur={event => {
											handleUpdateLabel(module, event.target.value);
										}}
										onEnterKey={(value) => {
											if (value.length > MAX_TAB_NAME_LENGTH) return;
											handleUpdateLabel(module, value.trim());
										}}
										onEscape={() => {
											setEditingTab(undefined);
											setForceMutationRefresh(prev => prev + 1);
										}}
									/>
								</OptionalComponent>
								<OptionalComponent display={editingTab !== module.id}>
									<button
										className={classNames({ "selected": isActiveTab && !addingTab })}
										onClick={() => handleTab(module)}
									>
										{label}
									</button>
								</OptionalComponent>
								<OptionalComponent display={!editingTab && !addingTab && !saving}>
									<div
										className="tab-actions-container"
										style={{
											top: `${(elementPositions[idx]?.top ?? 0) - 15}px`,
											left: `${(elementPositions[idx]?.right ?? 0) - 15}px`,
										}}
									>
										<button
											className="small round edit-icon"
											onClick={() => {
												setEditingTab(module.id);
												setForceMutationRefresh(prev => prev + 1);
											}}
										>
											<Icon name={ICONS.EDIT} color={COLORS.WHITE} size={13} />
										</button>
										<button
											className="small round delete-icon"
											onClick={() => module.is_custom && modules.length > 1
												? handleDeleteTab(module)
												: setModuleToDelete(module)
											}
										>
											<Icon name={ICONS.DELETE} color={COLORS.WHITE} size={13} />
										</button>
									</div>
								</OptionalComponent>
							</div>
						</div>
					</OptionalComponent>
				);
			})}
			{modules && modules.length <= 4 && (
				<>
					{!addingTab ? (
						<button
							onClick={() => setAddingTab(true)}
							className="add">
							<span><Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /> Add</span>
						</button>
					) : (
						<div className="lemonade-wrapper input">
							<TextInput
								className="new-tab-name-input"
								autoFocus
								maxLength={MAX_TAB_NAME_LENGTH}
								highlightOnFocus
								defaultValue={customTabName}
								onChange={e => {
									setCustomTabName(e.target.value);
								}}
								disabled={saving}
								inputSize={customTabName.length + 1}
								onBlur={(e) => {
									e.preventDefault();
									if (customTabName) {
										saveCustomTab();
									} else {
										setAddingTab(false);
									}
								}}
								onEnterKey={(_, e) => {
									e?.preventDefault();
									saveCustomTab();
								}}
								onEscape={() => {
									setCustomTabName('');
									setAddingTab(false);
								}}
							/>
						</div>
					)}
				</>
			)}

			<ModalComponent
				open={!!moduleToDelete}
				title={`Delete ${moduleToDelete?.content?.custom_heading?.[language] || moduleToDelete?.content?.custom_heading?.base || 'custom tab'}`}
				onRequestClose={() => setModuleToDelete(undefined)}
				cancellable={false}
				closeable={false}
				className="delete-custom-tab-modal"
				footer={
					<>
						<button onClick={() => setModuleToDelete(undefined)}>Cancel</button>
						<OptionalComponent display={!oneTabLeft}>
							<button
								className="confirm-delete-custom-tab-btn"
								onClick={() => handleDeleteTab()}
							>
								Delete
							</button>
						</OptionalComponent>
					</>
				}
			>
				{renderDeleteModalContent()}
			</ModalComponent>
		</div>
	);
}
