
import { ChipTypes } from '../../../types/working-model';
import './chip.scss';

interface IChipProps {
	type?: ChipTypes;
	styles?: React.CSSProperties;
	className?: string;
}

const Chip: React.FC<React.PropsWithChildren<IChipProps>> = ({
	children,
	type = 'primary',
	styles = {},
	className = '',
}) => {

	return (
		<div className={`chip-container chip-${type} ${className}`} style={{ ...styles }}>
			{children}
		</div>
	);
};

export default Chip;
