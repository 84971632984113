import './button-control-wrapper.scss';
interface ITopRightButtonControlContainerProps {
	children: JSX.Element | JSX.Element[];
}

export default function TopRightButtonControlContainer({ children }: ITopRightButtonControlContainerProps): JSX.Element {
	return (
		<div className='control-buttons'>
			<div className='control-buttons-image-actions'>
				{children}
			</div>
		</div>
	);
}


