import React from "react";

/**
 * 
 * 
 * Usage:
 * 
 * <SwitchCase input={myValue}>
 * 	<Case value={'foo'}>
 * 		<p>Foo</p>
 * 	</Case>
 * 	<Case value={'bar'}>
 * 		<p>Bar</p>
 * 	</Case>
 * 	<DefaultCase>
 * 		<p>Baz</p>
 * 	</DefaultCase>
 * </SwitchCase>
 * 
 * This component does not behave exactly like a regular JS switch case. 
 * 1. There is no fall-through. This will render the first matching case value
 * 2. 
 * 
 */

type CaseProps = {
	value: any;
	children: JSX.Element | JSX.Element[];
}
export const Case: React.FC<CaseProps> = ({ children }) => {
	return <>{children}</>;
};

type DefaultProps = {
	children: JSX.Element | JSX.Element[];
}
export const DefaultCase: React.FC<DefaultProps> = ({ children }) => {
	return <>{children}</>;
};

type SwitchProps = {
	children: JSX.Element | JSX.Element[];
	input: any;
}

export const SwitchCase: React.FC<SwitchProps> = ({ children, input }) => {
	if (!Array.isArray(children)) {
		children = [children];
	}

	for (const child of children) {
		if (child.props.value === input) {
			return <>{child}</>;
		}
	}

	if (children.at(-1)?.type.name === 'DefaultCase') {
		return <>{children.at(-1)}</>;
	}

	return <></>;
};