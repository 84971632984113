import { handle } from "redux-pack";

import { EMessageButtonType } from "../../../components/general-ui/message-card/message-card";
import { Action } from "../../../types/actions";
import {
	IQuestionSocket,
	ISocketCommentProps,
	SqsSocketTypes,
	Status,
	BrandliveEvent,
	EMessageTypes,
	IvsStream,
	IUserSurveyDB,
	BlProfile,
	Dictionary,
	IAnnouncement,
	SessionVideo,
	SessionVideoTypes,
	SessionPlaybackVideo,
} from "../../../types/working-model";
import { toMap } from "../../../utils/utils";
import {
	ADD_MODERATOR_CHAT_MESSAGE,
	UPDATE_MODERATOR_CHAT_MESSAGE,
	FETCH_MODERATOR_CHAT_MESSAGES,
	UNAPPROVE_MODERATOR_CHAT_MESSAGE,
	DELETED_MODERATOR_CHAT_MESSAGE,
	TOGGLE_SAVE_MODERATOR_CHAT_MESSAGE,
	ADD_MODERATOR_QUESTION,
	ADD_MODERATOR_QUESTION_COMMENT,
	ADD_MODERATOR_QUESTION_LIKE,
	ADD_MODERATOR_QUESTION_UNLIKE,
	FETCH_MODERATOR_QUESTIONS_AND_COMMENTS,
	UPDATE_MODERATOR_QUESTION,
	GET_MODERATOR_VIDEO,
	UPDATE_MODERATOR_VIDEO,
	FETCH_MODERATOR_EVENTS_DATA,
	CLEAR_MODERATOR_STATE,
	MODERATOR_DELETE_QUESTION,
	SENT_TO_PRESENTER,
	MOD_GET_LIVE_VIDEO_STREAM,
	SET_MODERATOR_REGISTRATION,
	BAN_CHAT_USER,
	MODERATOR_HIGHEST_CONCURRENT,
	ADD_SCHEDULED_ANNOUNCEMENT,
	GET_SCHEDULED_ANNOUNCEMENTS,
	UPDATE_SCHEDULED_ANNOUNCEMENT,
	DELETE_SCHEDULED_ANNOUNCEMENT,
	SEND_USER_SURVEY,
	GET_USER_SURVEYS,
	UPDATE_USER_SURVEY,
	DELETE_USER_SURVEY,
	UPDATE_MODERATOR_PROFILE,
	CLEAR_MODERATOR_ACTIVE_EVENT,
	CLEAR_HAS_NEW_MESSAGE,
	FETCH_MODERATOR_CHAT_MESSAGES_PAGE
} from "../../actions/moderator/moderator";
import { sortPresenterMessages } from "../../utils/content-utils";

export interface IModeratorState {
	activeEvent?: BrandliveEvent;
	fetchingActiveEvent: boolean;
	chat: Map<number, ISocketCommentProps>;
	loadingChat: boolean;
	fetchingMoreChat: boolean;
	hasMore: boolean;
	errorLoadingChat: string;
	lastFetchId: number | null;
	totalChatCount: number;
	deletedMessages: {
		messages: Map<string, any>;
		totalCount: number;
		loading: boolean;
		fetchingMore: boolean;
		hasMore: boolean;
		errorLoading: string;
		lastFetchId: number | null;
	};
	loadingLiveVideoStream: boolean;
	liveVideoStream: IvsStream;
	moderatorVideos: SessionPlaybackVideo[];
	moderatorVideoIsLive: boolean;
	loadingModeratorVideo: boolean;
	noModeratorVideo: boolean;
	chatScrollCounter: number; // when this number changes, we know to show chat arrow message. The actual value does not matter.
	remeasureChatList: number; // when this number changes, we know to show chat arrow message. The actual value does not matter.
	questionsScrollCounter: number; // when this number changes, we know to show chat arrow message. The actual value does not matter.
	remeasureQuestionsList: number; // when this number changes, we know to show arrow message. The actual value does not matter.
	// questions_and_comments: Map<string, any>;
	questions_and_comments: Map<string, IQuestionSocket | ISocketCommentProps>;
	errorLoadingQuestions: string;
	loadingQuestions: boolean;
	questionsAndCommentsSize: number;
	sentToPresenter: Map<string, IQuestionSocket | ISocketCommentProps>;
	moderatorRegistration: IModeratorRegistration | null;
	highestConcurrent: { value: number };
	scheduledAnnouncements: IAnnouncement[];
	updatingScheduledAnnouncements: boolean;
	sessionUserSurveys: IUserSurveyDB[];
	chatKey: string;
	hasNewMessage: boolean;
	initialLoad: boolean;
	min_cursor: number;
}

export interface IModeratorRegistration {
	token: string,
	blProfile: BlProfile,
	isModerator: boolean,
	secondaryVideos?: Dictionary
	registrationId?: string;
}

const initialState: IModeratorState = {
	activeEvent: undefined,
	chat: new Map(),
	chatScrollCounter: 0,
	deletedMessages: {
		messages: new Map(),
		totalCount: 0,
		loading: false,
		fetchingMore: false,
		hasMore: false,
		errorLoading: '',
		lastFetchId: null,
	},
	errorLoadingChat: '',
	errorLoadingQuestions: '',
	fetchingActiveEvent: false,
	fetchingMoreChat: false,
	hasMore: false,
	highestConcurrent: { value: 0 },
	liveVideoStream: {},
	lastFetchId: null,
	loadingChat: false,
	loadingLiveVideoStream: false,
	loadingModeratorVideo: false,
	loadingQuestions: false,
	moderatorRegistration: null,
	moderatorVideos: [],
	moderatorVideoIsLive: true,
	noModeratorVideo: false,
	questions_and_comments: new Map(),
	questionsAndCommentsSize: 0,
	questionsScrollCounter: 0,
	remeasureChatList: 0,
	remeasureQuestionsList: 0,
	scheduledAnnouncements: [],
	sentToPresenter: new Map(),
	sessionUserSurveys: [],
	hasNewMessage: false,
	chatKey: '',
	initialLoad: true,
	min_cursor: -Infinity,
	totalChatCount: 0,
	updatingScheduledAnnouncements: false
};


export default function ModeratorReducer(
	state: IModeratorState = initialState,
	action: Action
): IModeratorState {
	switch (action.type) {
		case FETCH_MODERATOR_CHAT_MESSAGES_PAGE: {
			return handle(state, action, {
				start: (state) => {
					// state reset based on request
					// new comments were requested on another session or language
					// so clear the existing state
					if (action.meta.chatKey !== state.chatKey) {
						return {
							...state,
							loadingChat: true,
							comments: new Map(),
							chatKey: action.meta.chatKey,
							initialLoad: true,
							min_cursor: -Infinity,
							count: 0,
							fetchingMoreChat: true
						};
					} else {
						return {
							...state,
							loadingChat: true,
							fetchingMoreChat: true
						};
					}
				},
				failure: state => ({
					...state,
					errorLoadingChat: "Could not load messages."
				}),
				success: state => {
					// split the messages up between deleted and not deleted
					const incomingChatMap = toMap<ISocketCommentProps & { message_type: SqsSocketTypes }>('id', action?.payload?.messages ?? []);
					const updatedChat = toMap<ISocketCommentProps & { message_type: SqsSocketTypes }>('id', [
						...action?.payload?.messages ?? [],
						...state.chat.values()
					]);

					const updatedDeleted = new Map(state.deletedMessages.messages);
					const updatedPresenter = new Map(state.sentToPresenter);

					for (const [key, value] of incomingChatMap.entries()) {
						value.message_type = SqsSocketTypes.CHAT;

						if (value.status === Status.Banned) {
							updatedChat.delete(key);
							continue;
						}

						if (value.status === Status.Deleted) {
							updatedDeleted.set(`${EMessageTypes.Chat}-${key}`, value);
						}

						if (value.sent_to_presenter) {
							updatedPresenter.set(`${EMessageTypes.Chat}-${key}`, value);
						}
					}

					const min_cursor = action.payload.min_cursor;

					return {
						...state,
						chat: updatedChat,
						errorLoadingChat: "",
						totalChatCount: action.payload.count - updatedDeleted.size,
						deletedMessages: {
							...state.deletedMessages,
							messages: updatedDeleted,
							totalCount: updatedDeleted.size,
						},
						sentToPresenter: sortPresenterMessages(updatedPresenter),
						min_cursor: min_cursor,
						lastFetchId: action?.payload?.messages[0]?.id || null,
						hasMore: !updatedChat.get(min_cursor),
					};
				},
				finish: state => ({ ...state, loadingChat: false, fetchingMoreChat: false, initialLoad: false })
			});
		}
		case FETCH_MODERATOR_CHAT_MESSAGES: {
			return handle(state, action, {
				start: state => ({ ...state, loadingChat: true, fetchingMoreChat: true }),
				failure: state => ({
					...state,
					errorLoadingChat: "Could not load messages."
				}),
				success: state => {
					// split the messages up between deleted and not deleted
					const updatedChat = new Map(state.chat);
					const updatedDeleted = new Map(state.deletedMessages.messages);
					const updatedPresenter = new Map(state.sentToPresenter);

					action.payload.chat.forEach((msg: any) => {
						if (msg.status !== Status.Banned) { // hide banned from moderator
							msg.message_type = SqsSocketTypes.CHAT;
							updatedChat.set(msg.id, msg);
							if (msg.status === Status.Deleted) {
								updatedDeleted.set(`${EMessageTypes.Chat}-${msg.id}`, msg);
							}
							if (msg.sent_to_presenter) {
								updatedPresenter.set(`${EMessageTypes.Chat}-${msg.id}`, msg);
							}
						}
					});

					return {
						...state,
						chat: updatedChat,
						hasMore: action.payload.has_more,
						errorLoadingChat: "",
						totalChatCount: updatedChat.size - updatedDeleted.size,
						lastFetchId: action?.payload?.chat[action?.payload?.chat?.length - 1]?.id || null,
						deletedMessages: {
							...state.deletedMessages,
							messages: updatedDeleted,
							totalCount: updatedDeleted.size,
						},
						sentToPresenter: sortPresenterMessages(updatedPresenter),
					};
				},
				finish: state => ({ ...state, loadingChat: false, fetchingMoreChat: false })
			});
		}

		case ADD_MODERATOR_CHAT_MESSAGE: {
			// create a copy of the map
			const mapCopy = new Map(state.chat);
			action.payload.message_type = SqsSocketTypes.CHAT;
			if (action.payload.status !== Status.Banned) {
				mapCopy.set(action.payload.id, action.payload);
			}
			// set the lastFetchId so we can properly paginate without getting duplicate from the database when
			// scroll fetching more items in the moderator panel
			const lastFetchId = state.lastFetchId || action?.payload?.id || null;
			return {
				...state,
				chat: mapCopy,
				totalChatCount: state.totalChatCount + 1,
				lastFetchId,
				chatScrollCounter: state.chatScrollCounter + 1,
				remeasureChatList: state.remeasureChatList + 1,
				hasNewMessage: true,
			};
		}
		case CLEAR_HAS_NEW_MESSAGE: {
			return {
				...state,
				hasNewMessage: false,
			};
		}
		case UPDATE_MODERATOR_CHAT_MESSAGE: {
			const updatedChat = new Map(state.chat);
			const updatedDeleted = new Map(state.deletedMessages.messages);
			const old = updatedChat.get(action.payload.id);
			if (!old) return state;

			const { actionType, ...restOfUpdate } = action.payload;
			let isUndeleted = false;
			let isDeleted = false;
			if (actionType && actionType === EMessageButtonType.UnDelete) {
				isUndeleted = true;
				updatedDeleted.delete(`chat-${action.payload.id}`);
			}
			if (actionType && actionType === EMessageButtonType.Delete) {
				isDeleted = true;
				updatedDeleted.set(`chat-${action.payload.id}`, restOfUpdate);
			}

			updatedChat.set(action.payload.id, { ...old, ...restOfUpdate });

			return {
				...state,
				chat: updatedChat,
				remeasureChatList: state.remeasureChatList + 1,
				totalChatCount: isUndeleted
					? state.totalChatCount + 1
					: isDeleted
						? state.totalChatCount - 1
						: state.totalChatCount,
				deletedMessages: {
					...state.deletedMessages,
					messages: updatedDeleted,
					totalCount: updatedDeleted.size,
				}
			};
		}
		case UNAPPROVE_MODERATOR_CHAT_MESSAGE: {
			const updated = new Map(state.chat);
			const msg = updated.get(action.payload);
			if (!msg) return state;

			const newMessage = { ...msg, status: Status.Unapproved, deleted_at: null };
			updated.set(newMessage.id, newMessage);

			// if message is in the deleted column, then remove it from there and move it back to the regular column
			const updatedDeleted = new Map(state.deletedMessages.messages);
			if (updatedDeleted.get(action.payload)) {
				updatedDeleted.delete(action.payload);
			}
			return {
				...state,
				chat: updated,
				deletedMessages: {
					...state.deletedMessages,
					messages: updatedDeleted,
				}
			};
		}
		case DELETED_MODERATOR_CHAT_MESSAGE: {
			const updated = new Map(state.chat);
			const old = updated.get(action.payload.id);
			if (!old) return state;
			updated.set(old.id, {
				...old,
				deleted_at: action.payload.deleted_at,
				status: Status.Deleted,
			});
			// do not delete from list, instead we will just not render the item if its status is deleted because
			// we want to be able to easily re-show the message in order if it's undeleted
			const updatedDeleted = new Map(state.deletedMessages.messages);
			updatedDeleted.set(action.payload.id, {
				...old,
				deleted_at: action.payload.deleted_at,
				status: Status.Deleted,
			});
			return {
				...state,
				chat: updated,
				totalChatCount: state.totalChatCount - 1,
				remeasureChatList: state.remeasureChatList + 1,
				deletedMessages: {
					...state.deletedMessages,
					messages: updatedDeleted,
					totalCount: state.deletedMessages.totalCount + 1,
				}
			};
		}
		case TOGGLE_SAVE_MODERATOR_CHAT_MESSAGE: {
			const updated = new Map(state.chat);
			const msg = updated.get(action.payload.id);
			if (!msg) return state;

			const newMessage = { ...msg, saved: action.payload.saved };
			updated.set(newMessage.id, newMessage);

			const updatedDeleted = new Map(state.deletedMessages.messages);
			updatedDeleted.set(action.payload.id, newMessage);
			return {
				...state,
				chat: updated,
			};
		}
		case FETCH_MODERATOR_QUESTIONS_AND_COMMENTS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingQuestions: true }),
				failure: state => ({
					...state,
					errorLoadingQuestions: "Could not load questions."
				}),
				success: state => {
					const questions_and_comments: Map<string, any> = new Map();
					const deletedMessagesCopy = new Map(state.deletedMessages.messages);
					const updatedPresenter = new Map(state.sentToPresenter);
					const all: any[] = [];
					action.payload.forEach((qn: any) => {
						qn.message_type = SqsSocketTypes.QUESTION;
						const { comments, ...question } = qn;
						all.push(question);
						if (qn?.status === Status.Deleted) {
							deletedMessagesCopy.set(`${EMessageTypes.Question}-${qn.id}`, question); // don't use qn, because we don't need all the comments in the list
						}
						questions_and_comments.set(`${EMessageTypes.Question}-${qn.id}`, question); // don't use qn, because we don't need all the comments in the list
						if (qn.sent_to_presenter) {
							updatedPresenter.set(`${EMessageTypes.Question}-${qn.id}`, question);
						}
						// only display the comments if the question itself is not deleted
						if (comments?.[0]) {
							comments.forEach((comment: any) => {
								comment.original_question = question;
								comment.message_type = SqsSocketTypes.QUESTION_COMMENT;
								if (comment?.status === Status.Deleted) {
									deletedMessagesCopy.set(`${EMessageTypes.QuestionComment}-${comment.id}`, comment);
								}
								questions_and_comments.set(`${EMessageTypes.QuestionComment}-${comment.id}`, comment);
								all.push(comment);
								if (comment.sent_to_presenter) {
									updatedPresenter.set(`${EMessageTypes.QuestionComment}-${comment.id}`, comment);
								}
							});
						}
					});

					const questionsAndCommentsSize = all.length;
					return {
						...state,
						questions_and_comments,
						questionsAndCommentsSize,
						deletedMessages: {
							...state.deletedMessages,
							messages: deletedMessagesCopy,
							totalCount: deletedMessagesCopy.size,
						},
						sentToPresenter: sortPresenterMessages(updatedPresenter),
					};
				},
				finish: state => ({ ...state, loadingQuestions: false })
			});
		}
		case ADD_MODERATOR_QUESTION: {
			const mapCopy = new Map(state.questions_and_comments);
			mapCopy.set(`question-${action.payload.id}`, action.payload);
			return {
				...state,
				questions_and_comments: mapCopy,
				questionsAndCommentsSize: state.questionsAndCommentsSize + 1,
				questionsScrollCounter: state.questionsScrollCounter + 1,
			};
		}
		case ADD_MODERATOR_QUESTION_COMMENT: {
			const mapCopy = new Map();
			const originalQn = state.questions_and_comments.get(`question-${action?.payload?.question_id}`);
			if (!originalQn) return state;
			// replies from participants don't have the original_question included
			action.payload.original_question = originalQn;
			// we need to iterate over the original to set the new map so the comment can be inserted in the correct location
			state.questions_and_comments.forEach((qn, qnKey) => {
				mapCopy.set(qnKey, qn);
				if (qn.id === originalQn.id) {
					mapCopy.set(`question_comment-${action.payload.id}`, action.payload);
				}
			});
			return {
				...state,
				questions_and_comments: mapCopy,
				questionsAndCommentsSize: state.questionsAndCommentsSize + 1,
				questionsScrollCounter: state.questionsScrollCounter + 1,
				remeasureQuestionsList: state.remeasureQuestionsList + 1,
			};
		}
		case ADD_MODERATOR_QUESTION_LIKE: {
			return state;
		}
		case ADD_MODERATOR_QUESTION_UNLIKE: {
			return state;
		}
		case UPDATE_MODERATOR_QUESTION: {
			// find and update the question or question comment

			// because questions and question comments can potentially have the same id
			// we find the question with the "question-" prefix and comments with "question_comment-"
			const prefix = action.payload?.question_id ? 'question_comment-' : 'question-';

			// make a copy of questions_and_comments
			const updatedQuestionsAndComments = new Map(state.questions_and_comments);

			// make a copy of deleted
			const updatedDeleted = new Map(state.deletedMessages.messages);
			// extract payload type so we know what button was clicked
			const { actionType, ...restOfUpdate } = action.payload;

			const msg = updatedQuestionsAndComments.get(`${prefix}${action.payload.id}`);

			if (!msg) return state;

			updatedQuestionsAndComments.set(`${prefix}${msg.id}`, {
				...msg,
				...restOfUpdate,
			});

			let isUndeleted = false;
			let isDeleted = false;
			// determine what type of button was triggered.
			// If UnDelete, then delete the message from the deleted list
			if (actionType && actionType === EMessageButtonType.UnDelete) {
				isUndeleted = true;
				updatedDeleted.delete(`${prefix}${action.payload.id}`);
			}
			// if delete, then add the message to the deleted list
			if (actionType && actionType === EMessageButtonType.Delete) {
				isDeleted = true;
				updatedDeleted.set(`${prefix}${action.payload.id}`, restOfUpdate);
			}

			return {
				...state,
				questions_and_comments: updatedQuestionsAndComments,
				remeasureQuestionsList: state.remeasureQuestionsList + 1,
				questionsAndCommentsSize: isUndeleted
					? state.questionsAndCommentsSize + 1
					: isDeleted
						? state.questionsAndCommentsSize - 1
						: state.questionsAndCommentsSize,
				deletedMessages: {
					...state.deletedMessages,
					messages: updatedDeleted,
					totalCount: updatedDeleted.size,
				},
			};
		}
		case GET_MODERATOR_VIDEO: {
			return handle(state, action, {
				start: state => ({ ...state, loadingModeratorVideo: true }),
				finish: state => ({ ...state, loadingModeratorVideo: false }),
				success: state => {
					const sessionVideos = action.payload as SessionPlaybackVideo[];
					if (sessionVideos.length) {
						return {
							...state,
							moderatorVideos: action.payload,
							moderatorVideoIsLive: sessionVideos.some(video => video.type === 'live_stream')
						};
					} else {
						return { ...state, noModeratorVideo: true, moderatorVideos: [] };
					}
				}
			});
		}

		case MOD_GET_LIVE_VIDEO_STREAM: {
			return handle(state, action, {
				start: state => ({ ...state, loadingLiveVideoStream: true }),
				finish: state => ({ ...state, loadingLiveVideoStream: false }),
				success: state => {
					return { ...state, liveVideoStream: action.payload };

				}
			});
		}

		case UPDATE_MODERATOR_VIDEO: {
			return state;
			// return {
			// 	...state,
			// 	moderatorVideos: {
			// 		live_video: {
			// 			playbackUrl: action.payload
			// 		}
			// 	},
			// 	noModeratorVideo: false,
			// 	moderatorVideoIsLive: true,
			// 	loadingModeratorVideo: false
			// };
		}
		case FETCH_MODERATOR_EVENTS_DATA: {
			return handle(state, action, {
				start: state => ({ ...state, fetchingActiveEvent: true }),
				success: state => ({
					...state,
					activeEvent: action.payload,
				}),
				finish: state => ({ ...state, fetchingActiveEvent: false }),
			});
		}
		case CLEAR_MODERATOR_ACTIVE_EVENT: {
			return {
				...state,
				activeEvent: undefined
			};
		}
		case CLEAR_MODERATOR_STATE: {
			const clearProfile = action.payload;

			// clear chat messages, video, questions and comments
			const copyOfInitialState = { ...initialState };
			// remove stuff we don't want to clear
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { activeEvent, fetchingActiveEvent, ...rest } = copyOfInitialState;
			return {
				...state,
				...rest,
				moderatorRegistration: clearProfile ? null : state.moderatorRegistration,
			};
		}
		case MODERATOR_DELETE_QUESTION: {
			// get the comment
			// update the status to deleted
			// move to deleted map

			const { id, question_id } = action.payload;
			const prefix = question_id ? 'question_comment-' : 'question-';

			// make a copy of questions_and_comments
			const updatedQuestionsAndComments = new Map(state.questions_and_comments);
			// make a copy of deleted
			const updatedDeleted = new Map(state.deletedMessages.messages);

			const msg = updatedQuestionsAndComments.get(`${prefix}${id}`);

			if (!msg) return state;

			updatedQuestionsAndComments.set(`${prefix}${msg.id}`, {
				...msg,
				status: Status.Deleted,
			});

			updatedDeleted.set(`${prefix}${id}`, updatedQuestionsAndComments.get(`${prefix}${id}`));
			return {
				...state,
				questions_and_comments: updatedQuestionsAndComments,
				remeasureQuestionsList: state.remeasureQuestionsList + 1,
				questionsAndCommentsSize: state.questionsAndCommentsSize - 1,
				deletedMessages: {
					...state.deletedMessages,
					messages: updatedDeleted,
					totalCount: updatedDeleted.size,
				},
			};
		}
		case SENT_TO_PRESENTER: {
			const {
				id,
				messageType,
				isSent,
			}: {
				id: number;
				messageType: EMessageTypes;
				isSent: boolean
			} = action.payload;

			const updatedChat = new Map(state.chat);
			const updatedQuestionsComments = new Map(state.questions_and_comments);
			const updatedSentToPresenter = new Map(state.sentToPresenter);
			const msg = messageType === EMessageTypes.Chat ?
				updatedChat.get(id) :
				updatedQuestionsComments.get(`${messageType}-${id}`);

			if (!msg) return state;

			if (isSent) {
				msg.sent_to_presenter = true;
				updatedSentToPresenter.set(`${messageType}-${id}`, msg);
			} else {
				msg.sent_to_presenter = false;
				updatedSentToPresenter.delete(`${messageType}-${id}`);
			}

			if (messageType === EMessageTypes.Chat) {
				updatedChat.set(id, msg as ISocketCommentProps);
			} else if ([EMessageTypes.Question, EMessageTypes.QuestionComment].includes(messageType)) {
				updatedQuestionsComments.set(`${messageType}-${id}`, msg);
			}

			return {
				...state,
				chat: updatedChat,
				questions_and_comments: updatedQuestionsComments,
				sentToPresenter: updatedSentToPresenter,
			};
		}
		case SET_MODERATOR_REGISTRATION: {
			return {
				...state,
				moderatorRegistration: action.payload,
			};
		}
		case BAN_CHAT_USER: {
			return handle(state, action, {
				start: state => ({ ...state, loadingChat: true }),
				success: state => {
					const bannedProfile = action.payload.bl_profile || action.payload.bl_profile_non_reg;
					const profileKey = action.payload.bl_profile ? "bl_profile" : "bl_profile_non_registered";

					const filterBannedMessages = (filterMessages: Map<string | number, any>): Map<string | number, any> => {
						const updatedMessages = new Map();
						filterMessages.forEach((value: any, key: string | number) => {
							if (Number(value[profileKey]) !== bannedProfile) {
								updatedMessages.set(key, value);
							}
						});
						return updatedMessages;
					};

					const updatedChat = filterBannedMessages(state.chat) as Map<number, ISocketCommentProps>;
					const updatedDeleted = filterBannedMessages(state.deletedMessages.messages) as Map<string, any>;
					const updatedPresenter = filterBannedMessages(state.sentToPresenter) as Map<string, IQuestionSocket | ISocketCommentProps>;

					return {
						...state,
						chat: updatedChat,
						deletedMessages: {
							...state.deletedMessages,
							messages: updatedDeleted,
							totalCount: updatedDeleted.size,
						},
						sentToPresenter: updatedPresenter,
						totalChatCount: updatedChat.size - updatedDeleted.size,
						errorLoadingChat: "",
					};
				},
				failure: state => ({ ...state, errorLoadingChat: "Error banning chat user." }),
				finish: state => ({ ...state, loadingChat: false })
			});
		}
		case MODERATOR_HIGHEST_CONCURRENT: {
			return handle(state, action, {
				success: state => ({ ...state, highestConcurrent: action.payload })
			});
		}
		case ADD_SCHEDULED_ANNOUNCEMENT:
			return handle(state, action, {
				start: state => {
					return {
						...state,
						updatingScheduledAnnouncements: true
					};
				},
				success: state => {
					return {
						...state,
						scheduledAnnouncements: [...state.scheduledAnnouncements, action.payload]
					};
				},
				finish: state => {
					return {
						...state,
						updatingScheduledAnnouncements: false
					};
				}
			});
		case GET_SCHEDULED_ANNOUNCEMENTS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						scheduledAnnouncements: action.payload
					};
				}
			});
		}
		case UPDATE_SCHEDULED_ANNOUNCEMENT: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						updatingScheduledAnnouncements: true
					};
				},
				success: state => {
					return {
						...state,
						scheduledAnnouncements: state.scheduledAnnouncements.map((announcement: IAnnouncement) => {
							if (announcement.uuid === action.payload.uuid) {
								return action.payload;
							}
							return announcement;
						})
					};
				},
				finish: state => {
					return {
						...state,
						updatingScheduledAnnouncements: false
					};
				}
			});
		}
		case DELETE_SCHEDULED_ANNOUNCEMENT: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						updatingScheduledAnnouncements: true
					};
				},
				success: state => {
					return {
						...state,
						scheduledAnnouncements: state.scheduledAnnouncements.filter(announcement => announcement.uuid !== action.payload.uuid)
					};
				},
				finish: state => {
					return {
						...state,
						updatingScheduledAnnouncements: false
					};
				}
			});
		}
		case GET_USER_SURVEYS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						sessionUserSurveys: action.payload
					};
				}
			});
		}
		case SEND_USER_SURVEY: {
			const _surveys = [...state.sessionUserSurveys, action.payload];
			return {
				...state,
				sessionUserSurveys: _surveys,
			};
		}
		case UPDATE_USER_SURVEY: {
			const filteredSurveys = state.sessionUserSurveys.filter(survey => survey.uuid !== action.payload.uuid);
			return {
				...state,
				sessionUserSurveys: [
					...filteredSurveys,
					action.payload
				]
			};
		}
		case DELETE_USER_SURVEY: {
			const _surveys = state.sessionUserSurveys;
			return {
				...state,
				sessionUserSurveys: _surveys.filter(survey => survey.uuid !== action.payload)
			};
		}
		case UPDATE_MODERATOR_PROFILE: {
			return handle(state, action, {
				success: state => {
					if (!state.moderatorRegistration?.blProfile) return state;
					return {
						...state,
						moderatorRegistration: {
							...state.moderatorRegistration,
							blProfile: {
								...state.moderatorRegistration.blProfile,
								...action.payload,
							},
						},
					};
				},
			});
		}
		default:
			return state;
	}
}