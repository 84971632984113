import { useMemo, useState } from 'react';

import { AgendaContent, EShowAgendaLists, PageModule, SessionTypesFeatures } from '../../../../../../../../types/working-model';
import { updateHomepageModule } from '../../../../../../../../store/actions/admin/create-event';
import Switch from '../../../../../../../general-ui/switch/switch';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../store/reducers/use-typed-selector';
import { OptionalComponent } from '../../../../../../../../utils/optional-component';
import { Tooltip, TooltipPositions } from '../../../../../../../general-ui/tooltip/tooltip';
import Icon, { COLORS, ICONS } from '../../../../../../../general-ui/icon';

interface IAgendaAdvancedLayoutProps {
	module: PageModule;
}

const AgendaAdvancedLayout: React.FC<IAgendaAdvancedLayoutProps> = ({ module }) => {
	const content: AgendaContent = module.content;

	const sessions = useTypedSelector(state => state.CreateEventReducer.workingEvent?.sessions);

	// check if event has broadcast or on demand sessions
	const { hasBroadcast, hasOnDemand } = useMemo(() => {
		const hasSessionTypes = {
			hasOnDemand: false,
			hasBroadcast: false,
		};

		if (!sessions?.length) {
			return hasSessionTypes;
		}

		for (let i = 0; i < sessions.length; i++) {
			const sessionType = sessions[i].session_type;
			if (SessionTypesFeatures[sessionType].has_start_time) {
				hasSessionTypes.hasBroadcast = true;
			} else {
				hasSessionTypes.hasOnDemand = true;
			}
			if (hasSessionTypes.hasBroadcast && hasSessionTypes.hasOnDemand) {
				break;
			}
		}

		return hasSessionTypes;
	}, [sessions]);

	const [showSessions, setShowSessions] = useState<Record<EShowAgendaLists, boolean>>({
		[EShowAgendaLists.show_on_demand_sessions]: content?.show_on_demand_sessions ?? true,
		[EShowAgendaLists.show_broadcast_sessions]: content?.show_broadcast_sessions ?? true,
	});

	const dispatch = useAppDispatch();

	const handleToggle = (value: EShowAgendaLists, isOn: boolean): void => {
		const updated = {
			...showSessions,
			[value]: isOn,
		};

		// one value must always be true, so if both values are false, then set the other to true
		const allValsFalse = !updated[EShowAgendaLists.show_broadcast_sessions] && !updated[EShowAgendaLists.show_on_demand_sessions];
		if (allValsFalse) {
			if (value === EShowAgendaLists.show_broadcast_sessions) {
				updated[EShowAgendaLists.show_on_demand_sessions] = true;
			}
			if (value === EShowAgendaLists.show_on_demand_sessions) {
				updated[EShowAgendaLists.show_broadcast_sessions] = true;
			}
		}

		setShowSessions(updated);
		dispatch(updateHomepageModule({
			...module,
			content: {
				...module.content,
				...updated,
			}
		}));
	};

	const renderTooltip = () => {
		return (
			<OptionalComponent display={!hasBroadcast || !hasOnDemand}>
				<div className="agenda-tooltip">
					<Tooltip position={TooltipPositions.topRight} tooltip="Event requires both on demand sessions and live sessions to toggle this feature">
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
					</Tooltip>
				</div>
			</OptionalComponent>
		);
	};

	return (
		<div className="agenda-advanced-settings">
			<div className="settings-row">
				<p className="toggle-label">Live Sessions {renderTooltip()}</p>
				<Switch
					value={EShowAgendaLists.show_broadcast_sessions}
					on={showSessions.show_broadcast_sessions}
					onClick={handleToggle}
					disabled={!hasBroadcast || !hasOnDemand}
				/>
			</div>
			<div className="settings-row">
				<p className="toggle-label">On-Demand {renderTooltip()}</p>
				<Switch
					value={EShowAgendaLists.show_on_demand_sessions}
					on={showSessions.show_on_demand_sessions}
					onClick={handleToggle}
					disabled={!hasBroadcast || !hasOnDemand}
				/>
			</div>
		</div>
	);
};

export default AgendaAdvancedLayout;