// This file is similiar to ./helpers, but it's used to correctly handle calls from the live event directly to events-api
import { fetchFailTimeout, MAX_RETRIES, generateSignedRequest, handleFailedRequestWithMessage } from './helpers';

const getHeaders = (token: string, headers = {}): Record<string, string> => {
	return {
		"Content-Type": "application/json",
		"BL-TOKEN": token,
		...headers
	};
};

function getParams(token: string, headers = {}) {
	return {
		method: "GET",
		headers: getHeaders(token, headers)
	};
}

function postParams(token: string, data: any, headers: { [key: string]: string }) {
	return {
		method: "POST",
		headers: getHeaders(token, headers),
		body: JSON.stringify(data)
	};
}

function patchParams(token: string, data: any, headers: { [key: string]: string }) {
	return {
		method: "PATCH",
		headers: getHeaders(token, headers),
		body: JSON.stringify(data)
	};
}

function putParams(token: string, data: any, headers = {}) {
	return {
		method: "PUT",
		headers: getHeaders(token, headers),
		body: JSON.stringify(data)
	};
}

function parsePath(path: string) {
	return generateSignedRequest(`${process.env.REACT_APP_API_HOST}${path}`);
}

export class FetchError {
	status: number;
	error: string;
	constructor(err: { status: number, error: string }) {
		this.status = err.status;
		this.error = err.error;
	}

	toString() {
		return `${this.status}: ${this.error}`;
	}
}

export const Get = async (
	path: string,
	token: string,
	headers?: { [key: string]: string },
	returnRaw?: boolean,
	options?: { throwOnErrorBody?: boolean; },
): Promise<any> => {
	let _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, getParams(token, headers));

			const response = returnRaw ? res : await res.json();

			if (options?.throwOnErrorBody && response?.error && res.status >= 400 && res.status <= 599) {
				return Promise.reject(response.error);
			}

			return response;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const Patch = async <T>(path: string, token: string, data: T, returnResults?: boolean, returnRaw?: boolean): Promise<any> => {
	const _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, patchParams(token, data, {}));

			if (returnRaw) {
				return res;
			}

			return returnResults ? await res.json() : res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const Put = async <T>(
	path: string,
	token: string,
	data: T,
	returnResults?: boolean,
	returnRaw?: boolean,
	returnErrorMsg?: boolean,
	headers?: Record<string, string>,
): Promise<any> => {
	const _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, putParams(token, data, headers));

			if (returnErrorMsg && !res.ok) {
				const result = await handleFailedRequestWithMessage(res);
				return Promise.reject(result);
			}

			if (returnRaw) {
				return res;
			}

			return returnResults ? await res.json() : res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const Post = async <T>(path: string, token: string, data: T, noReturn?: boolean, headers = {}, returnRaw?: boolean, returnErrorMsg?: boolean): Promise<any> => {
	let _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, postParams(token, data, headers));

			if (returnErrorMsg && !res.ok) {
				const result = await handleFailedRequestWithMessage(res);
				return Promise.reject(result);
			}

			if (noReturn) {
				return res.ok;
			}

			return returnRaw ? res : await res.json();
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};
