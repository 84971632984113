import { handle } from "redux-pack";
import { IMessage } from "../../../components/general-ui/message-card/message-card";
import { Action } from "../../../types/actions";
import {
	ADD_MESSAGE,
	LOAD_MESSAGES,
	UPDATE_MESSAGE
} from "../../actions/messages/messages";

export interface MessagesState {
	messages: IMessage[];
	loadingMessages: boolean;
}

const initialState: MessagesState = {
	loadingMessages: false,
	messages: [],
};

export default function MessagesReducer(
	state: MessagesState = initialState,
	action: Action
): MessagesState {
	switch (action.type) {
		case LOAD_MESSAGES: {
			return handle(state, action, {
				start: state => ({ ...state, loadingMessages: true }),
				failure: state => ({
					...state,
					errorLoadingDocuments: "Could not load messages."
				}),
				success: state => ({
					...state,
					messages: action.payload,
					errorLoadingDocuments: ""
				}),
				finish: state => ({ ...state, loadingMessages: false })
			});
		}
		case ADD_MESSAGE: {
			return { ...state, messages: [...state.messages, action.payload] };
		}
		case UPDATE_MESSAGE: {
			return {
				...state,
				messages: state.messages.map((message: IMessage) => {
					if (message.id === action.payload.id) {
						return action.payload;
					}

					return message;
				})
			};
		}
		default:
			return state;
	}
}
