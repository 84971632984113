import { LeaderboardActions, LeaderboardScoreForProfile } from "../../types/leaderboard";
import { CreateVideo, HomepageVideo } from "../../types/working-model";

const processLeaderboardActions = (
	collated: Map<string | LeaderboardActions, LeaderboardScoreForProfile>,
	action: LeaderboardScoreForProfile
) => {
	const existing = collated.get(action.action ?? action.custom_action);
	if (existing) {
		existing.score += action.score;
	} else {
		collated.set(action.action ?? action.custom_action, action);
	}
	return collated;
};

export const getLeaderboardActionsAggregatedByAction = (payload: LeaderboardScoreForProfile[]): LeaderboardScoreForProfile[] => {
	const mapped = payload.reduce(
		processLeaderboardActions,
		new Map<string | LeaderboardActions, LeaderboardScoreForProfile>()
	);

	return Array.from(mapped.values());
};

export const homepageVideoToCreateVideo = (video: CreateVideo | HomepageVideo): CreateVideo => {
	return {
		...video,
		source: video.source ?? null,
		duration: video.duration ?? undefined
	};
};