import { Action } from '../../../types/actions';

export const SET_PANEL_OPEN = "SET_PANEL_OPEN";
export function setPanelOpen(open: boolean): Action {
	return {
		type: SET_PANEL_OPEN,
		payload: open
	};
}
export const SET_SCROLL_TO_MODULE = 'SET_SCROLL_TO_MODULE';
export function setScrollToModule(pageModuleId: number): Action {
	return {
		type: SET_SCROLL_TO_MODULE,
		payload: pageModuleId
	};
}

export const SET_SESSION_GROUP = 'SET_SESSION_GROUP';
export function setSessionGroup(moduleGroup: string): Action {
	return {
		type: SET_SESSION_GROUP,
		payload: moduleGroup
	};
}

export const RESET_SCROLL_STATE = 'RESET_SCROLL_STATE';
export function resetScrollState(): Action {
	return {
		type: RESET_SCROLL_STATE,
		payload: ''
	};
}
