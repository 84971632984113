import React from 'react';

import { Survey } from '../../../../types/working-model';
import { useGetAnsweredQuestionCount } from './hooks/engage.hooks';

import './progress-bar.scss';

interface IProgressBarProps {
	survey: Survey;
	label?: string;
	preview?: boolean;
	currentQuestion: number;
}

export default function ProgressBar({
	survey,
	label = 'progress',
	preview,
	currentQuestion,
}: IProgressBarProps): JSX.Element {
	const answeredQuestionsCount = useGetAnsweredQuestionCount(survey);

	const { questions } = survey;
	const currentLevel = preview ? currentQuestion : answeredQuestionsCount;
	const progressLevel = currentLevel / questions.length;

	return (
		<div
			className="engage-progress-bar"
			role="progressbar"
			aria-valuemin={0}
			aria-valuemax={100}
			aria-valuenow={progressLevel * 100}
			aria-label={label}
		>
			<div
				className="engage-progress-bar-value"
				style={{ transform: `scaleX(${progressLevel})` }}
			/>
		</div>
	);
}