export default ({ onDone }: { onDone: (e: React.ChangeEvent<HTMLInputElement>) => void }) => (
	<div className="new-module-group-container">
		<input
			type="text"
			placeholder="Tab name"
			autoFocus={true}
			onBlur={onDone}
			maxLength={24}
			onKeyUp={(e: React.KeyboardEvent) => {
				if (e.key === 'Enter') onDone(e as any);
			}}
		/>
	</div>
);