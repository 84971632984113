import { useCallback } from 'react';
import { useParams } from 'react-router';

import { QuizzesStatus } from "../../../../../types/working-model";
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { isRtlLanguage } from '../../../../../utils/utils';
import { ParamsProps } from '../../../../live-event/live-event';
import { OptionalComponent } from '../../../../../utils/optional-component';
import Icon, { COLORS, ICONS } from '../../../../general-ui/icon';
import { useTypedSelector } from 'store/reducers/use-typed-selector';

interface QuizzesStatusProps {
	quizStatus: QuizzesStatus;
	retake: () => void;
	canRetake?: boolean;
}
const QuizQuestionEndScreen: React.FC<QuizzesStatusProps> = ({ quizStatus, canRetake, retake }) => {
	const { t } = useTranslate("session");
	const { language } = useParams<ParamsProps>();
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);


	const calculateFinishedRatio = (quizOverall: QuizzesStatus) => {
		if (quizOverall) {
			return ` ${(quizOverall.correctAnswers)}/${quizOverall.totalAnswered} `;
		}
	};

	const completionText = useCallback(() => {
		return !isRtlLanguage(language) ?
			[`You’ve finished the quiz and answered`, `quiz questions correctly`] :
			[`quiz questions correctly`, `You’ve finished the quiz and answered`];
	}, [language]);

	const completionStatus = useCallback(() => {
		return (
			<div className="completion-status">
				{quizStatus?.passed
					? t(`Congratulations`) || "Congratulations"
					: t(`Oops!`) || "Oops!"}
			</div>
		);
		// we need translationUpdates to force re-render when language changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quizStatus?.passed, t, translationUpdates]);

	const completeStatusSubtext = useCallback(() => {
		const _completionText = completionText();
		return (
			<div className="completion-status-subtext">
				{t(_completionText[0])}
				{/* IN ENGLISH: You’ve finished the quiz and answered */}
				<b>{calculateFinishedRatio(quizStatus)}</b>
				{t(_completionText[1])}
				{/* IN ENGLISH: quiz questions correctly */}
			</div>
		);
		// we need translationUpdates to force re-render when language changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [completionText, quizStatus, t, translationUpdates]);

	const partyIcon = useCallback(() => {
		return (
			<div className="party-icon">{quizStatus?.passed ? '🎉' : '😌'}</div>
		);
	}, [quizStatus?.passed]);

	const retakeButton = useCallback(() => {
		return (
			<OptionalComponent display={!!canRetake}>
				<div className='retake-container'>
					<button className='retake-button' onClick={() => retake()}>
						<Icon name={ICONS.REFRESH_TRANSPARENT} size={20} color={COLORS.DEFAULT} />
						<span>{t(`Retake`) ?? 'Retake'}</span>
					</button>
				</div>
			</OptionalComponent>
		);
		// we need translationUpdates to force re-render when language changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canRetake, retake, t, translationUpdates]);

	if (!quizStatus) return <></>;
	return (
		<>
			<QuizCompletionContainer layout="desktop">
				<div className="quiz-completion-status">
					{partyIcon()}
					<div className="completion-status-container">
						{completionStatus()}
						{completeStatusSubtext()}
					</div>
				</div>
				{retakeButton()}
			</QuizCompletionContainer>

			<QuizCompletionContainer layout="mobile">
				<div className="quiz-completion-status">
					<div className="quiz-mobile-status-title-container">{completionStatus()}{partyIcon()}</div>
					<div className="completion-status-container">
						{completeStatusSubtext()}
					</div>
				</div>
				{retakeButton()}
			</QuizCompletionContainer>
		</>
	);
};

interface IQuizCompletionContainerProps {
	layout: 'desktop' | 'mobile';
	children: React.ReactNode;
}

const QuizCompletionContainer = ({ layout, children }: IQuizCompletionContainerProps) => {
	return (
		<div className={`quiz-completion ${layout}`}>
			{children}
		</div>
	);
};

export default QuizQuestionEndScreen;
