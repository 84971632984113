import { useParams } from 'react-router-dom';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { OptionalComponent } from '../../../../utils/optional-component';
import Icon, { COLORS, ICONS } from '../../../general-ui/icon';
import { ISurveyMiscTranslations, SurveyType } from 'types/working-model';

import './answers-views.scss';

interface IEngageCardInfoProps {
	engageType?: SurveyType;
	surveyCompleted: boolean;
	answeredQuestionsCount: number;
	maxQuestions: number;
	totalResponses?: number;
	totalQuestions?: number;
	miscTranslations?: ISurveyMiscTranslations;
	privateResponsesEnabled: boolean
}

export default function EngageCardInfo({
	engageType,
	surveyCompleted,
	answeredQuestionsCount,
	maxQuestions,
	totalResponses,
	totalQuestions,
	miscTranslations,
	privateResponsesEnabled
}: IEngageCardInfoProps): JSX.Element {
	const { t } = useTranslate("session");

	const isQuestionType = engageType === SurveyType.question_prompt;
	const isCommentBoxType = engageType === SurveyType.comment_box_prompt;
	const replyCount = (isQuestionType || isCommentBoxType) ? totalQuestions : totalResponses;

	const { language } = useParams<Record<string, string>>();

	const renderNotStartedTranslation = () => {
		return miscTranslations?.not_started?.[language] || miscTranslations?.not_started?.base || t('Not started', 'Not started');
	};

	const renderCompletedTranslation = () => {
		return miscTranslations?.completed?.[language] || miscTranslations?.completed?.base || t('Completed', 'Completed');
	};

	const getCountSubtext = () => {
		if (replyCount === 0) {
			if (engageType === SurveyType.poll) {
				return t('No Votes', 'No Votes');
			} else if (engageType === SurveyType.question_prompt) {
				return t('No Questions', 'No Questions');
			} else if (engageType === SurveyType.survey) {
				return t('No Responses', 'No Responses');
			} else if (engageType === SurveyType.quiz) {
				return t('No Responses', 'No Responses');
			} else if (engageType === SurveyType.comment_box_prompt) {
				return t('No Comments', 'No Comments');
			}
		} else if (replyCount === 1) {
			if (engageType === SurveyType.poll) {
				return t('Vote', 'Vote');
			} else if (engageType === SurveyType.question_prompt) {
				return t('Question', 'Question');
			} else if (engageType === SurveyType.survey) {
				return t('Response', 'Response');
			} else if (engageType === SurveyType.quiz) {
				return t('Response', 'Response');
			} else if (engageType === SurveyType.comment_box_prompt) {
				return t('Comment', 'Comment');
			}
		} else {
			if (engageType === SurveyType.poll) {
				return t('Votes', 'Votes');
			} else if (engageType === SurveyType.question_prompt) {
				return t('Questions', 'Questions');
			} else if (engageType === SurveyType.survey) {
				return t('Responses', 'Responses');
			} else if (engageType === SurveyType.quiz) {
				return t('Responses', 'Responses');
			} else if (engageType === SurveyType.comment_box_prompt) {
				return t('Comments', 'Comments');
			}
		}
	};

	return (
		<div className="engage-card-header-bottom">
			<div className="view-answer-count evt-typography-caption-l3">
				<div className="row">
					<span className="value">{replyCount === 0 ? '' : `${replyCount} `}{getCountSubtext()}</span>
				</div>
			</div>
			<OptionalComponent display={(isQuestionType || isCommentBoxType) && privateResponsesEnabled}>
				<div className="engage-card-info evt-typography-caption-l3">
					<span>
						<Icon name={ICONS.VIEWERS_EYE_OFF} color={COLORS.GRAY} size={14} />
					</span>
				</div>
			</OptionalComponent>
			<OptionalComponent display={!(isQuestionType || isCommentBoxType)}>
				<div className="engage-card-info evt-typography-caption-l3">
					<span>
						<OptionalComponent display={!surveyCompleted && !!answeredQuestionsCount}>
							<>
								<Icon name={ICONS.CHECK_CIRCLE_OUTLINE} color={COLORS.GRAY} size={12} />
								<span>{`${answeredQuestionsCount} ${t('of')} ${maxQuestions}`}</span>
							</>
						</OptionalComponent>
						<OptionalComponent display={surveyCompleted}>
							<>
								<Icon name={ICONS.CHECK_CIRCLE_FILLED} color={COLORS.GRAY} size={12} />
								<span>{renderCompletedTranslation()}</span>
							</>
						</OptionalComponent>
						<OptionalComponent display={!answeredQuestionsCount}>
							<>
								<Icon name={ICONS.CHECK_CIRCLE_OUTLINE} color={COLORS.GRAY} size={12} />
								<span>{renderNotStartedTranslation()}</span>
							</>
						</OptionalComponent>
					</span>
				</div>
			</OptionalComponent>
		</div>
	);
}