import { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleOverviewContent } from "../../../../../../../store/actions/admin/create-event";

import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import Switch from "../../../../../../general-ui/switch/switch";

const SettingsDisplayOverviewContent: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const enableOverviewContent = workingEvent?.registration_settings?.enableOverviewContent;

	const [isOn, setIsOn] = useState(enableOverviewContent ?? true);

	const dispatch = useDispatch();

	const handleToggle = (_value: string, _isOn: boolean) => {
		setIsOn(_isOn);
		dispatch(toggleOverviewContent(_isOn));
	};

	return (
		<div className="settings-card">
			<label>Display Overview Content</label>

			<div>
				<Switch
					on={isOn}
					onClick={handleToggle}
					value="Enable Overview Content"
				/>
			</div>
		</div>
	);
};

export default SettingsDisplayOverviewContent;
