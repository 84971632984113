import { Component } from 'react';

interface IPageVisible {
	children: JSX.Element;
}

interface IPageVisibleState {
	visible: boolean;
}

export default class PageVisible extends Component<IPageVisible, IPageVisibleState> {
	constructor(props: IPageVisible) {
		super(props);
		this.state = { visible: true };
	}

	handleVisibility = () => {
		this.setState({ visible: document.visibilityState !== 'hidden' });
	};

	componentDidMount() {
		document.addEventListener('visibilitychange', this.handleVisibility);
	}

	componentWillUnmount() {
		document.removeEventListener('visibilitychange', this.handleVisibility);
	}

	render() {
		if (this.state.visible) {
			return <>{this.props.children}</>;
		}

		return <></>;
	}
}