import { ConnectedAdmin, IBreakoutRoomProfile } from "../../types/working-model";
import { SocketConnection } from "./socket-connection";
import * as EventEmitter from '../../utils/event-emitter';
import { getLogger } from "../../utils/debug-logger";

const log = getLogger('bl-socket:manager');
const silly = getLogger('bl-socket-silly:manager');

const improperUsageWarning = `Error: using the same socket namespace manually and from a hook is deprecated and can lead to accidental socket disconnects.`;

class SocketManager {
	private sockets: Map<string, SocketConnection> = new Map();
	private socketsFromHook: Set<string> = new Set();
	private toLeave: Set<string> = new Set();
	private toHave: Set<string> = new Set();
	private changed = false;

	constructor() {
		setInterval(() => this.tick(), 1000);
		EventEmitter.on('socket-disconnect', this.disconnectAll);
		EventEmitter.on('socket-reconnect', this.reconnectAll);
	}

	private disconnectAll = () => {
		for (const socket of this.sockets.values()) {
			socket.end();
		}

		EventEmitter.off('socket-disconnect');
	};

	private reconnectAll = () => {
		for (const socket of this.sockets.values()) {
			socket.setupConnection();
		}

		EventEmitter.on('socket-disconnect', this.disconnectAll);
	};

	public get = (ns: string, admin?: ConnectedAdmin, profile?: IBreakoutRoomProfile): SocketConnection => {
		if (this.socketsFromHook.has(ns)) {
			if (process.env.REACT_APP_STAGE === 'local') {
				throw new Error(improperUsageWarning);
			}

			console.warn(improperUsageWarning);
		}

		silly(`Mounted ${ns}`);
		this.toHave.add(ns);
		if (this.sockets.has(ns)) {
			return this.sockets.get(ns) as SocketConnection;
		} else {
			const socket = new SocketConnection(ns, admin, profile);
			this.sockets.set(ns, socket);
			this.changed = true;
			return socket;
		}
	};

	public getFromHook = (ns: string, admin?: ConnectedAdmin, profile?: IBreakoutRoomProfile): SocketConnection => {
		silly(`Mounted ${ns}`);
		this.toHave.add(ns);
		this.socketsFromHook.add(ns);
		if (this.sockets.has(ns)) {
			return this.sockets.get(ns) as SocketConnection;
		} else {
			const socket = new SocketConnection(ns, admin, profile);
			this.sockets.set(ns, socket);
			this.changed = true;
			return socket;
		}
	};

	public leave = (ns: string) => {
		this.toLeave.add(ns);
		silly(`Dismounted ${ns}`);
	};

	private tick = () => {
		for (const ns of this.toLeave) {
			if (!this.toHave.has(ns)) {
				this.changed = true;
				silly(`Leaving ${ns}`);
				const socket = this.sockets.get(ns);
				const listeners = socket?.getListeners();
				if (socket && (!listeners || listeners?.length === 0)) {
					this.sockets.get(ns)?.disconnect();
					this.sockets.delete(ns);
					silly(`Left ${ns}`);
				} else if (socket) {
					silly(`Cannot leave ${ns}, ${listeners} remain.`);
				}
			}
		}

		if (this.changed) {
			log(`Should have channels`, Array.from(this.sockets.keys()));
		}

		this.toHave = new Set();
		this.toLeave = new Set();
		this.changed = false;
	};
}

export default new SocketManager();