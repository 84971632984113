import { useParams } from 'react-router';
import classNames from 'classnames';

import { OptionalComponent } from '../../../../utils/optional-component';
import { LeaderboardEntry } from '../../../../types/leaderboard';
import { BlProfileValues } from '../../../../types/working-model';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import LeaderboardAvatar from './leaderboard-avatar';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { ParamsProps } from '../../live-event';
import { numberWithCommas } from '../../../../utils/utils';
import { useMemo } from 'react';
import { getBylineMap, getScore } from './leaderboard-utils';


interface LeaderBoardCardProps {
	attendee: LeaderboardEntry;
	viewProfile?: (uuid: string) => void;
}

const LeaderboardCard = ({ attendee, viewProfile }: LeaderBoardCardProps): JSX.Element => {
	const { profile, score, rank, bl_profile_uuid } = attendee;

	const userProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const eventBylineQuestion = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.leaderboard_settings?.byline_question);
	const editorBylineQuestion = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.leaderboard_settings?.byline_question);
	const regQuestions = useTypedSelector(state => state.LiveEventReducer.eventBundle?.registration_questions);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const { t } = useTranslate(['homepage', 'registrations']);

	const { language } = useParams<ParamsProps>();

	const firstName = profile?.[BlProfileValues.firstName] ?? '';
	const lastName = profile?.[BlProfileValues.lastName] ?? '';

	const eventUuid = eventBundle?.uuid ?? workingEvent?.uuid;

	const safeScore = getScore(score, eventUuid);

	const bylineMap = useMemo(() => {
		if (regQuestions) {
			return getBylineMap(regQuestions, t, language);
		} else {
			return new Map();
		}
	}, [language, regQuestions, t]);

	// if there is no byline question set both variables will be undefined or null
	// fall back to 0 matches how the question is reset in the editor panel
	let byline = bylineMap.get(profile?.[`select_${eventBylineQuestion ?? editorBylineQuestion ?? 0}`]) ?? profile?.[eventBylineQuestion ?? editorBylineQuestion ?? 0];

	// for editor, use placeholder text for custom byline if not found in sample profiles
	if (editorBylineQuestion && editorBylineQuestion > 0 && !byline) {
		byline = 'Attendee response to selected question';
	}

	const thisIsMe = userProfile?.uuid === bl_profile_uuid;

	const handleClick = () => {
		if (!viewProfile) return;
		viewProfile(bl_profile_uuid);
	};

	return (
		<div className={classNames('leaderboard-card', { 'clickable': viewProfile })} role='link' onClick={handleClick} >
			<LeaderboardAvatar entry={attendee} />
			<div className='card-content'>
				<div className='profile'>
					<p className='name'>
						{`${rank}. ${firstName} ${lastName}`}
						<OptionalComponent display={thisIsMe}>
							<span className='me'>{t('leaderboard.Me')}</span>
						</OptionalComponent>
					</p>
					<OptionalComponent display={!!byline}>
						<p className='title'>{byline}</p>
					</OptionalComponent>
				</div>
				<div className='score'>
					{numberWithCommas(safeScore, language) + ` ${safeScore === 1 ? t('leaderboard.point') : t('leaderboard.points')}`}
				</div>
			</div>
		</div>
	);
};

export default LeaderboardCard;
