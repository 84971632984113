import React from 'react';
import classNames from 'classnames';

import { HomepageFooter, LanguagesAbbr } from '../../../../types/working-model';
import { ReactComponent as NowStreamingLogo } from '../../../../images/now-streaming-on-brandlive.svg';
import NavItem from '../../../general-ui/nav-item/nav-item';
import { getFooterStylingOverrides } from '../../utils';
import '../../../../scss/live-event/base/modules/marketing-page-footer.scss';
import '../../../../scss/live-event/base/marketing-page/footer.scss';
import { useTranslate } from '../../../../i18n/useTranslationModules';

interface FooterProps {
	footer: HomepageFooter;
	template: string;
	previewLanguage?: LanguagesAbbr;
	isLandingPage?: boolean;
}

const Footer: React.FC<FooterProps> = ({ footer, template, previewLanguage }) => {
	const { logo } = footer;
	const {
		backgroundColor,
		backgroundImg,
		mainTextOverrides,
		blLogoColor
	} = getFooterStylingOverrides(footer.styling_overrides);
	const { t } = useTranslate("landing");

	const renderLinks = () => {
		const overrideColor = mainTextOverrides?.split('-')[0];
		const navItems = footer.navItems?.map((navItem, i) => {
			const override = t(`footer.${navItem.button_name.en}`, '');
			return (
				<NavItem
					key={navItem.button_name.base + i}
					navItem={navItem}
					handleScroll={() => ({})}
					className={classNames(
						"footer-nav-item",
						mainTextOverrides,
						{ [`${overrideColor}-hover`]: mainTextOverrides }
					)}
					previewLanguage={previewLanguage}
					labelOverride={override ? override : undefined}
				/>
			);
		});

		return navItems;
	};

	return (
		<footer className={
			classNames(
				"marketing-page-footer",
				template,
				backgroundColor
			)}
		>
			<div className={classNames("footer-container")} style={backgroundImg}>
				<div className={classNames("footer-left", { 'hide-mobile': !logo })}>
					<img className="footer-logo" src={logo ? logo : ''} aria-hidden="true" />
				</div>
				<nav className="footer-nav">
					{renderLinks()}
				</nav>
				<div className="footer-right">
					<div className="footer-author">
						<NowStreamingLogo className={classNames("bl-logo", blLogoColor)} />
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
