import { handle } from "redux-pack";
import Socket from "../../../connection/socket";
import { Action } from "../../../types/actions";
import { BlProfile, EFiresidePanels, FiresideSessionSettings, IAttendeeVideoSettings, IBreakoutRoomProfile } from "../../../types/working-model";
import { VideoFeedAnalyzer } from "../../../utils/video-feed-analyzer";

import {
	FS_ADD_CONNECTED_PROFILE,
	FS_REMOVE_CONNECTED_PROFILE,
	FS_SET_CONNECTED_PROFILES,
	FS_SET_LOADING_PROFILES,
	FS_UPDATE_CONNECTED_PROFILES,
	FS_SET_OPENTOK_ID,
	SET_FIRESIDE_PANEL_ITEM,
	FS_SET_VIEW_ATTENDEE,
	FS_ADD_LIST_OF_PROFILES,
	FS_SET_ATTENDEE_VIDEO_SETTINGS,
	SET_FIRESIDE_USAGE,
	SET_FIRESIDE_STAGE_SOCKET,
	FORCE_FIRESIDE_RENDER,
	FS_RAISE_HAND,
	FS_LOWER_HAND,
	FS_JOIN_REQ_DENY,
	SET_FIRESIDE_SPEAKER_VIDEO_MAP,
	FS_SET_JOIN_REQUESTS,
	FS_ADD_JOIN_REQUEST,
	FS_REMOVE_JOIN_REQUEST,
	ADD_FIRESIDE_SPEAKER_VIDEO_MAP,
	DELETE_FIRESIDE_SPEAKER_VIDEO_MAP,
	SET_FIRESIDES_DEVICES,
	SET_SELF_STREAM,
	SET_FIRESIDE_LIVE,
	SET_SCREENSHARE_DISABLED,
	SET_HAS_ACTIVE_STREAM,
	SET_FS_JOINED,
	SET_MEET_ENTERED,
	FS_MEET_IN_WAITING_ROOM,
	FS_MEET_GET_GOOGLE_MEET_FS_SETTINGS,
	FS_MEET_SET_GOOGLE_MEET_FS_SETTINGS,
	FS_MEET_USER_LEFT
} from '../../actions/event/firesides-actions';

interface IConnectedProfilesState {
	connected_profiles: IBreakoutRoomProfile[];
	loading: boolean;
	opentok_id: number | string | null;
	active_panel: EFiresidePanels;
	view_attendee: number | null;
	attendee_video_settings: { [key: string]: IAttendeeVideoSettings };
	room_usage: any;
	stageSocket: Socket | null;
	fireside_count_render: number;
	closing_all_rooms: boolean;
	handRaised: boolean;
	speaker_video_map: Map<number, VideoFeedAnalyzer>;	// blProfile is the key
	joinRequests: Array<BlProfile>;
	videoDevices: MediaDeviceInfo[];
	audioDevices: MediaDeviceInfo[];
	audioOutputs: MediaDeviceInfo[];
	selfStream: MediaStream | null;
	firesideLive: number | null; // timestamp of session start
	screenshareDisabled: boolean;
	hasActiveStream: boolean;
	fsJoined: boolean;
	meetEntered: boolean;
	inMeetWaitingRoom: boolean;
	firesideSessionSettings?: FiresideSessionSettings;
	userLeft: boolean;
}

const defaultState: IConnectedProfilesState = {
	active_panel: EFiresidePanels.chat,
	attendee_video_settings: {},
	audioDevices: [],
	audioOutputs: [],
	closing_all_rooms: false,
	connected_profiles: [],
	fireside_count_render: 0,
	firesideLive: null,
	handRaised: false,
	hasActiveStream: false,
	joinRequests: [],
	loading: true,
	opentok_id: null,
	room_usage: null,
	screenshareDisabled: false,
	selfStream: null,
	speaker_video_map: new Map(),
	stageSocket: null,
	videoDevices: [],
	view_attendee: null,
	fsJoined: false,
	meetEntered: false,
	inMeetWaitingRoom: false,
	userLeft: false
};

export default function FiresidesReducer(state = defaultState, action: Action): IConnectedProfilesState {
	switch (action.type) {
		case FS_SET_CONNECTED_PROFILES: {
			//should effectively prevent reordering and always place newly added admins to the end
			const connectedProfiles = new Map<number, any>([
				...state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]),
				...(action?.payload ?? []).map((blProfile: any) => [blProfile.bl_profile, blProfile])
			]);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case FS_ADD_CONNECTED_PROFILE: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.set(action.payload.bl_profile, action.payload);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case FS_ADD_LIST_OF_PROFILES: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			action.payload.forEach((profile: any) => {
				connectedProfiles.set(profile.bl_profile, profile);
			});
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case FS_REMOVE_CONNECTED_PROFILE: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.delete(action.payload.bl_profile);
			// remove the users video settings:
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [action?.payload?.bl_profile]: current, ...restOfUserSettings } = { ...state.attendee_video_settings };
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values()),
				attendee_video_settings: restOfUserSettings,
			};
		}
		case FS_UPDATE_CONNECTED_PROFILES: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.set(action.payload.brandlive_profile, action.payload);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case FS_SET_LOADING_PROFILES: {
			return {
				...state,
				loading: !!action.payload,
			};
		}
		case FS_SET_OPENTOK_ID: {
			return {
				...state,
				opentok_id: action.payload,
			};
		}
		case SET_FIRESIDE_PANEL_ITEM: {
			return {
				...state,
				active_panel: action.payload,
			};
		}
		case FS_SET_VIEW_ATTENDEE: {
			return {
				...state,
				view_attendee: action.payload,
			};
		}
		case FS_SET_ATTENDEE_VIDEO_SETTINGS: {
			if (!action.payload?.bl_profile) return state;
			return {
				...state,
				attendee_video_settings: {
					...state.attendee_video_settings,
					[action.payload.bl_profile]: action.payload,
				},
			};
		}

		case SET_FIRESIDE_USAGE: {
			return {
				...state,
				room_usage: action.payload,
			};
		}

		case SET_FIRESIDE_STAGE_SOCKET: {
			return {
				...state,
				stageSocket: action.payload,
			};
		}

		case FORCE_FIRESIDE_RENDER: {
			return {
				...state,
				fireside_count_render: state.fireside_count_render + 1,
			};
		}

		case FS_RAISE_HAND: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						handRaised: true
					};
				},
				failure: state => {
					return {
						...state,
						handRaised: false
					};
				},
				success: state => {
					return {
						...state
					};
				},
				finish: state => {
					return { ...state };
				}
			});
		}

		case FS_LOWER_HAND: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						handRaised: false
					};
				},
				failure: state => {
					return {
						...state,
						handRaised: true
					};
				}
			});
		}
		case FS_JOIN_REQ_DENY: {
			return {
				...state,
				handRaised: false
			};
		}
		case SET_FIRESIDE_SPEAKER_VIDEO_MAP: {
			return {
				...state,
				speaker_video_map: action.payload,
			};
		}

		case ADD_FIRESIDE_SPEAKER_VIDEO_MAP: {
			const { videoKey, newSpeaker } = action.payload;

			const copy = new Map(state.speaker_video_map);

			copy.set(videoKey, newSpeaker);

			return {
				...state,
				speaker_video_map: copy,
			};
		}

		case DELETE_FIRESIDE_SPEAKER_VIDEO_MAP: {
			const videoKey = action.payload;

			const copy = new Map(state.speaker_video_map);

			copy.delete(videoKey);

			return {
				...state,
				speaker_video_map: copy,
			};
		}

		case FS_SET_JOIN_REQUESTS: {
			return {
				...state,
				joinRequests: action.payload
			};
		}

		case FS_ADD_JOIN_REQUEST: {
			const newRequests = [...state.joinRequests];

			if (!newRequests.find(bl => bl.uuid === action.payload.uuid)) {
				newRequests.push(action.payload);
			}

			return {
				...state,
				joinRequests: newRequests
			};
		}

		case FS_REMOVE_JOIN_REQUEST: {
			return {
				...state,
				joinRequests: state.joinRequests.filter(bl => bl.uuid !== action.payload)
			};
		}

		case SET_FIRESIDES_DEVICES: {
			return {
				...state,
				...action.payload
			};
		}

		case SET_SELF_STREAM: {
			return {
				...state,
				selfStream: action.payload
			};
		}

		case SET_FIRESIDE_LIVE: {
			return {
				...state,
				firesideLive: action.payload
			};
		}

		case SET_SCREENSHARE_DISABLED: {
			return {
				...state,
				screenshareDisabled: action.payload
			};
		}

		case SET_HAS_ACTIVE_STREAM: {
			return {
				...state,
				hasActiveStream: action.payload
			};
		}

		case SET_FS_JOINED: {
			return {
				...state,
				fsJoined: action.payload
			};
		}

		case SET_MEET_ENTERED: {
			return {
				...state,
				meetEntered: action.payload
			};
		}

		case FS_MEET_IN_WAITING_ROOM: {
			return {
				...state,
				inMeetWaitingRoom: action.payload
			};
		}

		case FS_MEET_USER_LEFT: {
			return {
				...state,
				userLeft: true
			};
		}

		case FS_MEET_SET_GOOGLE_MEET_FS_SETTINGS: {
			return {
				...state,
				firesideSessionSettings: action.payload
			};
		}

		case FS_MEET_GET_GOOGLE_MEET_FS_SETTINGS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						firesideSessionSettings: action.payload
					};
				},
			});
		}


		default: return state;
	}
}