import { useTranslate } from "i18n/useTranslationModules";

import { MarkdownItem } from "@general-ui/typography-item/typography-item";
import { useMemo } from "react";
import { useParams } from "react-router";
import { ParamsProps } from '../components/live-event/live-event';
import { useTypedSelector } from "store/reducers/use-typed-selector";


export enum RegFailureReasons {
	passcode = 'invalid-passcode',
	domain = 'invalid-email-domain',
	missingRequired = 'missing-required-field',
	missingPasscodeLists = 'no-matching-passcode-lists',
	notFoundInPasscodeLists = 'not-found-passcode-lists',
	failedRecaptchaV3 = 'failed-recaptcha-v3',
	failedRecaptchaV2 = 'failed-recaptcha-v2',
	registrationClosed = 'registration-closed',
	badWords = 'bad-words-detected',
	deletedRegistration = 'deleted-registration',
	unmatchedPassword = 'unmatched-password',
	noPassword = 'no-password',
	emailAndPasswordNotFound = 'email-and-password-not-found',
	passwordRequirementsNotMet = 'password-requirements-not-met',
	invalidPasswordResetCode = 'invalid-pw-reset-code'
}

export const useGetRegistrationErrorMessage = (error?: RegFailureReasons) => {
	const { t } = useTranslate(["homepage", "session"]);
	const { language } = useParams() as ParamsProps;

	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);
	const registrationError = useTypedSelector(state => state.LiveEventReducer.registrationError);

	return useMemo(() => {
		if (!error && !registrationError) return '';
		let errorMessage;

		switch (error || registrationError) {
			case RegFailureReasons.domain:
			case RegFailureReasons.missingPasscodeLists:
			case RegFailureReasons.notFoundInPasscodeLists:
			case RegFailureReasons.passcode: {
				if (eventBundle?.registration_settings?.customRejectedPasscodeMessage?.base) {
					return (
						<MarkdownItem className="registration-error custom-failed-passcode" allowedElements={['a', 'p']}>
							{t(
								`email.${eventBundle.registration_settings.customRejectedPasscodeMessage.base}`,
								eventBundle?.registration_settings?.customRejectedPasscodeMessage?.[language] as string ?? eventBundle?.registration_settings?.customRejectedPasscodeMessage?.base
							)}
						</MarkdownItem>
					);
				}
				errorMessage = t("homepage:registration.gatingErrorMessage");
				break;
			}
			// this should never fire because submission is (should be) blocked until required fields are completed
			case RegFailureReasons.missingRequired: {
				errorMessage = t("homepage:registration.missingRequired");
				break;
			}
			case RegFailureReasons.deletedRegistration: {
				errorMessage = t("homepage:registration.deletedRegistration");
				break;
			}
			case RegFailureReasons.registrationClosed: {
				errorMessage = t("homepage:registration.Registration is closed");
				break;
			}
			case RegFailureReasons.badWords: {
				errorMessage = t("homepage:registration.badWords");
				break;
			}
			case RegFailureReasons.unmatchedPassword: {
				errorMessage = t("homepage:registration.unmatchedPassword", "Passwords do not match");
				break;
			}
			case RegFailureReasons.noPassword: {
				errorMessage = t("homepage:registration.noPassword", "Must include a password");
				break;
			}
			case RegFailureReasons.emailAndPasswordNotFound: {
				errorMessage = t("homepage:registration.emailAndPasswordNotFound", "Email and password not found");
				break;
			}
			case RegFailureReasons.passwordRequirementsNotMet: {
				errorMessage = t("homepage:registration.Password Requirement", "Password must be at least 8 characters long.");
				break;
			}
			case RegFailureReasons.invalidPasswordResetCode: {
				errorMessage = t("homepage:registration.Invalid Password Reset Code", "Invalid password reset code");
				break;
			}
			default: {
				errorMessage = t("homepage:registration.error");
			}
		}

		return errorMessage;

		// we need translationUpdates in deps array to make sure we have the latest translations
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, registrationError, eventBundle?.registration_settings?.customRejectedPasscodeMessage, language, t, translationUpdates]);
};
