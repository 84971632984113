import { useCallback, useEffect, useMemo, useRef } from 'react';

export default function useTimeout(
	callback: () => void,
	delay: number
): {
	runWithTimeout: () => void;
	clearTimeout: () => void;
} {
	const timeoutRef = useRef<number>();
	const clear = useCallback(() => clearTimeout(timeoutRef.current), [
		timeoutRef,
	]);

	useEffect(() => {
		return (): void => {
			clear();
		};
	}, [clear]);

	return useMemo(() => {
		return {
			runWithTimeout: (): void => {
				if (timeoutRef.current) {
					clear();
				}
				timeoutRef.current = window.setTimeout(() => {
					callback();
				}, delay);
			},
			clearTimeout: clear,
		};
	}, [clear, timeoutRef, callback, delay]);
}
