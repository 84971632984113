import { useEffect, useState } from 'react';
import { useRouteMatch, } from 'react-router-dom';
import _, { isObject } from 'underscore';
import { pathToRegexp } from 'path-to-regexp';

import { shouldDisplayHomepage } from 'utils/utils';
import { DebugDefaultRouteMap, DefaultRouteMap } from '../components/live-event/hooks/use-route-map';
import { PATHNAMES } from '../utils/admin-routing-utils';
import { useGetEvent, useIsSingleSession } from './session.hooks';

/**
 * matches a path to the current window location pathname so we can match paths outside of react-router context
 * particularly useful for matching paths in modal portals
 * @param pathToCheck - The path to look for. For example: ['/event/:eventId/en/session/:sessionId']
 * @param validations - The validations object, which is an object with the index of the path part as the key and a function that validates the part as the value.
 * @returns Boolean. True if every validation passes, false otherwise.
 */
export const useMatchRouteToWindowPath = (
	pathToCheck: string,
	validations: {
		[pathIndex: number]: (part: string) => boolean;
	},
): boolean => {

	const [isMatch, setIsMatch] = useState(false);

	const pathname = window.location.pathname;
	useEffect(() => {
		if (!pathToCheck) {
			return;
		}

		let _pathname = pathname;
		if (pathname.startsWith('/')) { // remove leading slash because it create an additional empty string in the split array
			_pathname = _pathname.slice(1);
		}
		if (pathname.endsWith('/')) { // remove trailing slash because it create an additional empty string in the split array
			_pathname = _pathname.slice(0, -1);
		}
		const splitPathname = _pathname.split('/');
		setIsMatch(splitPathname.every((part, index) => {
			return validations?.[index]?.(part);
		}));

		// only run when pathname changes or we'll get unnecessary re-renders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return isMatch;
};

const useSafeRouteMatch = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let routeMatch = {} as Record<string, any>;
	try {
		const _routeMatch = useRouteMatch() || {};
		if (_routeMatch && isObject(_routeMatch)) {
			routeMatch = _routeMatch;
		}
	} catch (error) {
		// don't bloat production with this error since we're going to get this a lot
		// because we have modal portals that don't live within react router context
		if (process.env.REACT_APP_DEBUG_LEVEL === '1') {
			console.error(error);
		}
	}

	return routeMatch;
};

export const useIsSessionsPage = (): boolean => {
	const routerMatch = useSafeRouteMatch();
	const path = routerMatch?.path;
	const isDebugSessionPath = path?.includes(DebugDefaultRouteMap.Sessions);
	const isPublishSessionsPath = path?.includes(DefaultRouteMap.Sessions);

	return isDebugSessionPath || isPublishSessionsPath;
};

export const useIsSessionPage = (): boolean => {
	const isEditSessionPage = useIsAdminCreateSessionPage();
	const isSingleSession = useIsSingleSessionPage();

	const isDebugSessionPageRexp = pathToRegexp(DebugDefaultRouteMap.Session);
	const isDebugSessionPage = isDebugSessionPageRexp.exec(window.location.pathname);
	const isLiveSessionPageRexp = pathToRegexp(DefaultRouteMap.Session);
	const isLiveSessionPage = isLiveSessionPageRexp.exec(window.location.pathname);

	return isEditSessionPage
		|| !!isDebugSessionPage
		|| !!isLiveSessionPage
		|| isSingleSession;
};

export const useIsAdminCreateSessionPage = (): boolean => {
	const routerMatch = useSafeRouteMatch();
	const path = routerMatch?.path;

	const isAdminSessionRegexp = pathToRegexp(PATHNAMES.Session.Session);
	const isAdminSessionPage = isAdminSessionRegexp.exec(window.location.pathname);

	return !!isAdminSessionPage || path?.includes(PATHNAMES.Session.Session);
};

export const useIsAdminCreateSessionsPage = (): boolean => {
	const routerMatch = useSafeRouteMatch();
	const path = routerMatch?.path;

	return path?.includes(PATHNAMES.Event.SessionsPath);
};

export const useIsSingleSessionPage = (): boolean => {
	const routerMatch = useSafeRouteMatch();
	const path = routerMatch?.path;
	const event = useGetEvent();
	const isSingleSession = useIsSingleSession();

	if (!isSingleSession) return false;

	const isDebugSingleSessionPath = path === DebugDefaultRouteMap.SingleSession;
	const isAdminSingleSessionPath = path === DebugDefaultRouteMap.AdminSingleSession;
	const isAdminSessionPath = path === PATHNAMES.Session.Session;
	const isAdminEventPath = path === PATHNAMES.Event.Event;
	const isSingleSessionWithoutHome = !shouldDisplayHomepage(event) && path === DefaultRouteMap.Landing;

	return isAdminEventPath
		|| isSingleSessionWithoutHome
		|| isAdminSessionPath
		|| isDebugSingleSessionPath
		|| isAdminSingleSessionPath;
};
