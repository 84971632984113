import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { isObject } from "underscore";
import { CreatePageModule } from "../../../../../../../../connection/page-modules";
import { useContentSearch, SearchResults } from "../../../../../../../../connection/sessions-panel/search";
import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { getDocuments } from "../../../../../../../../store/actions/admin/content-actions";
import { addSessionPageModule, updateGroupModules, updatePageModule } from "../../../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { Document, PageModule, PageModuleType, SessionPanelLayoutsTypes, Templates } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import { mergeUniqueById } from "../../../../../../../../utils/utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import DocumentCard from "../../components/document-card";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import { customResourcesItems, extrasResourcesItems } from "../../empty-state-panel/constants/empty-panel";
import { usePageModule, usePageModuleGroup } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import { appendNewPageModulesToGroups } from "../../utils/module-group.utils";
import { PageModuleGroup } from "../../utils/prototypes/page-module-group-modules";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const ResourcesList: React.FC<unknown> = () => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [total, setTotal] = useState(0);
	const [saving, setSaving] = useState(false);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(true);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const loadMoreTimeout = useRef<NodeJS.Timeout | null>(null);
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const { customPath } = useParams<{ customPath?: string }>();
	const extrasItems = isExtrasCustom ? customResourcesItems : extrasResourcesItems(!!customPath);
	const dispatch = useDispatch();
	const pageModule = usePageModule();
	const history = useHistory();
	const pageModuleGroup = usePageModuleGroup();
	const adminPath = useGetAdminUrl();

	const eventId = workingEvent?.event;
	const channelId = workingSession?.channel;

	const handleError = useCallback((e: string) => {
		console.error(e);
		showAlert({
			message: e,
			type: "error",
		});
	}, []);

	const {
		load,
		search,
		loading,
		clear,
		setSort,
		next
	} = useContentSearch<Document>('documents', handleError);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	// append new items to end of list
	const handleMoreResults = useCallback((res: SearchResults<Document> | undefined) => {
		if (isObject(res) && res.results) {
			setTotal(res.total ?? 0);
			setDocuments(mergeUniqueById<Document>('document', res.results ?? []));
		}
	}, []);

	// replace list with new items
	const handleNewResults = useCallback((res: SearchResults<Document> | undefined) => {
		if (isObject(res)) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}

			setTotal(res.total ?? 0);
			setDocuments(res.results ?? []);
		}
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		if (searchTerm && searchTerm.length >= 3) {
			// apply new search term and replace existing results with new
			search(searchTerm).then(handleNewResults);
		} else {
			// clear search term and replace existing results with new
			clear(true).then(handleNewResults);
		}
	}, [clear, handleNewResults, search, searchTerm]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleSelectDocument = (document: number, on: boolean) => {
		if (on) {
			setSelectedDocuments(documents => [...documents, document]);
		} else {
			setSelectedDocuments(documents => documents.filter(p => p !== document));
		}
	};

	useEffect(() => {
		const moreAvailable = total > documents.length;

		if (hasScrolledToBottom && moreAvailable && !loading) {
			// debounce send in case user scrolls like crazy or we get multiple triggers
			if (loadMoreTimeout.current) {
				clearTimeout(loadMoreTimeout.current);
			}

			loadMoreTimeout.current = setTimeout(() => {
				loadMoreTimeout.current = null;
				next().then(handleMoreResults);
			}, 200);
		}
	}, [hasScrolledToBottom, total, documents.length, handleMoreResults, next, loading]);

	useEffect(() => {
		// apply new value and replace list with new results
		setSort(sortOrder).then(handleNewResults);
	}, [handleNewResults, setSort, sortOrder]);

	useEffect(() => {
		// initialize list
		load().then(handleNewResults);
	}, [handleNewResults, load]);

	const handleFinished = async () => {
		if (!token || !workingEvent || !workingSession || !workingSession.module_grouping || !pageModuleGroup) return;

		let page_module: PageModule;
		let appendToGroup = false;

		if (!pageModule?.id) {
			// no page module exists to contain these speakers - create a new one and append it ot the module group
			page_module = await CreatePageModule(token, {
				type: PageModuleType.documents,
				eventName: workingEvent.name,
				template: Templates.Limelight,
				languages: workingSession.languages,
				baseLanguage: workingSession.default_language
			});

			dispatch(addSessionPageModule(page_module));
			appendToGroup = true;
		} else {
			page_module = pageModule;

			// this is a custom tab, and the user has navigated to the resources tab, so we need to convert this
			// empty custom tab to a resources(documents) tab
			if (page_module.type === PageModuleType.feed) {
				page_module.type = PageModuleType.documents;
				dispatch(updatePageModule(page_module));
			}
		}

		try {
			if (!token || !channelId || !eventId) {
				throw new Error('Unable to select docuemnts when there is no event to edit.');
			}
			setSaving(true);
			// prepend the newly selected, use a map to drop duplicates below
			const newModules = [
				...documents.filter(doc => selectedDocuments.includes(doc.document)),
				...(page_module.modules ?? []),
			];
			dispatch(updatePageModule({
				...page_module,
				modules: Array.from(new Map(newModules.map(item => [item.document, item])).values()),
				content_modules: Array.from(new Set([
					...selectedDocuments,
					...(page_module.content_modules ?? []),
				])),
				is_edited: true
			}));
			dispatch(getDocuments(channelId, token));
		} catch (e) {
			console.error(e);
			showAlert({
				message: 'Unable to Save Documents',
				description: "We ran into an issue adding these documents. Please try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}

		if (appendToGroup) {
			const newModules = (workingSession.module_grouping as PageModuleGroup[])
				.map(appendNewPageModulesToGroups(pageModuleGroup.uuid, [page_module.id as number]));

			dispatch(updateGroupModules(newModules));
		}

		const path = (() => {
			if (customPath) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.CustomResources], customPath, page_module: page_module.id });
			}

			if (page_module.content.custom_tab) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraCustom], page_module: page_module.id });
			} else {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraResources], page_module: page_module.id });
			}
		})();

		history.replace(path);
	};

	return (
		<div className={classNames("session-panel resources-list", { 'has-selections': selectedDocuments.length > 0 })} ref={containerRef}>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
					isAdmin
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>

			<label className="session-panel-section-label">Library</label>
			{documents.length ? (
				<StaggerChildren
					onScrolledToBottom={() => setHasScrolledToBottom(true)}
					onScrolledUpFromBottom={() => setHasScrolledToBottom(false)}
					ref={scrollRef}
					footer={loading ? <WaitingIndicator fillSpace={true} minHeight={36} transparentFill /> : undefined}
					className={classNames("session-panel-content")}>
					{documents.map((document: Document) => (
						<DocumentCard
							key={document.document}
							document={document}
							selected={selectedDocuments.includes(document.document as number)}
							handleSelectedDocument={handleSelectDocument}
						/>
					))}
				</StaggerChildren>
			) : (
				<>
					{(loading) ? (
						<div className="session-panel-no-results padding-24">
							<section><WaitingIndicator /></section>
						</div>
					) : (
						<div className="session-panel-no-results padding-24">
							<section>No results</section>
							<SessionPanelAddFooter
								scrollRef={scrollRef}
								items={extrasItems}
								small
							/>
						</div>
					)}
				</>
			)}

			<div className={"session-panel-footer"}>
				<button onClick={() => setSelectedDocuments([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleFinished}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>
		</div>
	);
};

export default ResourcesList;