import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	streamsLoader,
	translationNamespaces,
} from './config';
interface UseTranslationModuleProps {
	language?: string;
}

function getLang(lang: string | undefined) {
	if (lang && lang !== 'undefined') {
		return lang;
	}

	return 'en';
}

const useTranslationModules = (
	props: UseTranslationModuleProps
): {
	selectedLanguage: string;
	setSelectedLanguage: Dispatch<SetStateAction<string>>;
} => {
	const [selectedLanguage, setSelectedLanguage] = useState<string>(getLang(props.language));
	const { i18n } = useTranslation<string[]>(translationNamespaces);

	useEffect(() => {
		setSelectedLanguage(getLang(props.language));
		i18n.changeLanguage(props.language);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.language]);

	return {
		selectedLanguage,
		setSelectedLanguage
	};
};

export type TranslateFunction = (key: string, fallback?: string, options?: Record<string, string>) => string;
export const useTranslate = (ns: string | string[]): { t: TranslateFunction } => {
	const { t } = useTranslation(ns);

	useEffect(() => {
		if (typeof ns === 'string') {
			streamsLoader.addNamespace(ns);
		} else {
			for (const _ns of ns) {
				streamsLoader.addNamespace(_ns);
			}
		}
	}, [ns]);

	const _t = useCallback((key: string, fallback?: string, options?: Record<string, string>) => {
		// skip the key separator if there is a fallback specified, we know that's the new method

		// BEEEEEEWAAAAAAAARE - this is an empty space character, NOT a regular space character
		// we want the browser to render the empty space, and by default regular empty spaces are not rendered with any height
		// they are ignored
		const emSpace = ' '; // ⚡ high voltage ⚡
		return t(key, { keySeparator: false, defaultValue: fallback ?? emSpace, ...(options ?? {}) });
	}, [t]);

	return useMemo(() => ({ t: _t }), [_t]);
};

export default useTranslationModules;
