import classNames from "classnames";
import { useParams } from "react-router-dom";
import { Speaker } from "../../../../../../../../types/working-model";
import { tString } from "../../../../../../../../utils/translate-string";
import NavigationDropdown from "../../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import './speaker-card.scss';

type Props = {
	speaker: Speaker;
	selected?: boolean;
	handleSelectSpeaker?: (id: number, selected: boolean) => void;
	handleEdit?: (speaker: Speaker) => void;
	handleDelete?: (speaker: Speaker) => void;
}

const SpeakerCard = ({
	speaker,
	selected,
	handleSelectSpeaker,
	handleDelete,
	handleEdit
}: Props) => {
	const { speaker: id } = speaker;
	const { language } = useParams<{ language: string }>();

	return (
		<div
			className={classNames("panel-card speaker-card", { selected })}
			key={speaker.speaker}
			onClick={() => handleSelectSpeaker?.(id as number, !selected)}
		>
			<div
				className="speaker-image"
				style={{ backgroundImage: `url(${speaker.image})` }}
			/>
			<div className="speaker-data">
				<h5 className="speaker-name">{speaker.first_name} {speaker.last_name}</h5>
				<span className="speaker-job">{speaker.job_title ? tString(speaker.job_title, language) : ''}</span>
				<p className="speaker-description">{speaker.description ? tString(speaker.description, language) : ''}</p>
			</div>

			{(handleEdit && handleDelete) && (
				<NavigationDropdown
					title={<Icon name={ICONS.THREE_DOTS_VERTICAL} size={20} color={COLORS.WHITE} />}
					className="added-card-dropdown"
					buttonClassName="question-card-options round no-style"
					edge="right"
					isArrow={false}
				>
					<>
						<button onClick={() => handleEdit(speaker)}>Edit</button>
						<button onClick={() => handleDelete(speaker)}>Delete</button>
					</>
				</NavigationDropdown>
			)}
		</div>
	);
};

export default SpeakerCard;