import { useEffect, useState } from "react";
import { throttle } from "underscore";

interface Props {
	scrolledContainerRef: HTMLDivElement | null;
	offset?: number;
	throttleTime?: number;
	initialState?: boolean;
}

interface ReturnType {
	hasScrolledToBottom: boolean;
	setHasScrolledToBottom: React.Dispatch<React.SetStateAction<boolean>>;
}

const useHasScrolledToBottom = ({
	scrolledContainerRef,
	offset = 10,
	throttleTime = 100,
	initialState = false,
}: Props): ReturnType => {
	const [hasScrolledToBottom, setHasScrolledToBottom] = useState(initialState);

	useEffect(() => {
		const currentRef = scrolledContainerRef;
		const handleScroll = throttle(() => {
			if (currentRef) {
				const bottom =
					currentRef.scrollHeight -
					currentRef.scrollTop -
					currentRef.clientHeight <
					offset;
				if (bottom) {
					setHasScrolledToBottom(true);
				} else {
					setHasScrolledToBottom(false);
				}
			}
		}, throttleTime);
		if (currentRef) {
			currentRef.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener("scroll", handleScroll);
			}
		};
	}, [scrolledContainerRef, offset, throttleTime]);

	return {
		hasScrolledToBottom,
		setHasScrolledToBottom
	};
};

export default useHasScrolledToBottom;
