import { useState } from "react";
import { useDispatch } from 'react-redux';

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { Profile, AdminProfileFields } from "../../../types/working-model";
import { UploadFile } from "../../../connection/uploads";
import { UpdateAdminProfile } from "../../../connection/auth";
import { updateAdminProfile } from "../../../store/actions/authentication";
import Icon, { COLORS, ICONS } from "../../general-ui/icon";
import ButtonPlain from "../../general-ui/button-plain/button-plain";
import { EditProfileModal } from "./edit-profile";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import { showAlert } from "../../general-ui/alert/alert-service";

// design requested to match settings pages so we are using the settings scss
import '../settings/settings-main.scss';
import { getCloudfrontUrl, resizedImage } from "../../../utils/utils";
import FileInput from "../../../utils/file-input";


export interface EProfileItem {
	field: AdminProfileFields;
	icon: string;
	title: string;
}

// BLProfile fields currently in use for this page mapped to their titles and icons
export const adminProfileItems: EProfileItem[] = [
	{ field: AdminProfileFields.first_name, icon: ICONS.PERSON, title: "First name" },
	{ field: AdminProfileFields.last_name, icon: ICONS.PERSON, title: "Last name" },
	{ field: AdminProfileFields.job_title, icon: ICONS.PERSON, title: "Job title" },
	{ field: AdminProfileFields.location, icon: ICONS.MAP_ARROW, title: "Location" },
	{ field: AdminProfileFields.phone, icon: ICONS.PHONE_SLIM, title: "Phone" },
];

export default function AdminProfile(): JSX.Element {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const [editing, setEditing] = useState<boolean>(false);
	const [uploading, setUploading] = useState<boolean>(false);

	const dispatch = useDispatch();

	if (!user || !token) return <></>;
	const userProfile: Profile = user.profile;

	// dont show first and last name in card body since they are used as the card heading
	const hideFields: AdminProfileFields[] = [AdminProfileFields.first_name, AdminProfileFields.last_name];
	const displayProfileItems: EProfileItem[] = adminProfileItems.filter(item => !hideFields.includes(item.field));

	const handleImage = async (imageFile: FileList | null) => {
		if (!imageFile?.length) return;
		setUploading(true);
		try {
			const uploaded = getCloudfrontUrl(await UploadFile(user, token, imageFile[0]));
			const updatedProfile: Profile = { ...userProfile, picture: uploaded };
			await UpdateAdminProfile(token, updatedProfile);
			dispatch(updateAdminProfile(updatedProfile));
		} catch (e: any) {
			showAlert({
				message: "Error updating avatar",
				duration: 4000,
				type: "error"
			});
			console.log(e);
		} finally {
			setUploading(false);
		}
	};

	const nameBanner = () => {
		if (!!userProfile.first_name && !!userProfile.last_name) {
			//trim it in case either first or last is missing to remove extra spaces
			return `${userProfile.first_name} ${userProfile.last_name}`.trim();
		} else {
			//return whatever we have there, first name or last name or email, will fall back to last string
			return userProfile.first_name || userProfile.last_name || user.email;
		}
	};

	return (
		<main className="profile-main wrapper">
			<div className="settings-container profile">
				<h2>Profile</h2>
				<article className="settings-card-container">
					<div className="settings-card">
						<div className="settings-card-header">
							<h6></h6> {/* no title now but keeping placeholder for when more cards are added*/}
							<ButtonPlain
								icon={ICONS.EDIT}
								iconSize={12}
								color="white"
								style={{ color: COLORS.WHITE }}
								onclick={() => setEditing(true)}
							>
								Edit
							</ButtonPlain>
						</div>
						<div className="settings-card-body">
							<div className="primary-card">
								<div className="avatar">
									{uploading ? <WaitingIndicator fillSpace /> :
										!!userProfile.picture && <img src={resizedImage(userProfile.picture, { height: 178, width: 178, fit: 'cover' })} alt="user-profile-avatar" />}
									<FileInput
										name={"imageUpload"}
										id={"imageUpload"}
										accept={["image/*"]}
										multiple={false}
										style={{ display: "none" }}
										onChange={(event) => handleImage(event?.target?.files)}
									/>
									<label htmlFor="imageUpload">
										<div className="camera-button">
											<Icon name={ICONS.PHOTO} color={COLORS.BLACK} size={20} />
										</div>
									</label>
								</div>
								<div>
									<h3>{nameBanner()}</h3>
									<div className="primary-info">
										<div className="primary-info-icon">
											<Icon name={ICONS.EMAIL_FILLED} color={COLORS.WHITE} size={16} />
										</div>
										<div>
											<span className="s3">{"Email"}</span>
											<p className="p1">{user.email}</p>
										</div>
									</div>
									{displayProfileItems.map((item: EProfileItem) => (
										<div className="primary-info" key={item.field}>
											<div className="primary-info-icon">
												<Icon name={item.icon} color={COLORS.WHITE} size={16} />
											</div>
											<div>
												<span className="s3">{item.title}</span>
												<p className="p1">{userProfile[item.field]}</p>
											</div>
										</div>
									))}
									<p className="disclaimer">If you would like a copy of your data or would like it deleted, please reach out to <a href="mailto:privacy@brandlive.com">privacy@brandlive.com</a>.</p>
								</div>
								<EditProfileModal isOpen={editing} onClose={() => setEditing(false)} />
							</div>
						</div>
					</div>
				</article>
			</div>
		</main>
	);
}