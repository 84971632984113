import React from "react";

import { useIsNewModuleGrouping } from '../../../../../hooks/session.hooks';
import { Session, } from "../../../../../types/working-model";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { OptionalComponent } from '../../../../../utils/optional-component';
import ActionBar from "../action-bar/action-bar";

import './description-actions.scss';

interface DescriptionActionsProps {
	template: string;
	session: Session | null;
	setShowFeedbackSurveyAlert: (showFeedbackSurveyAlert: boolean) => void;
	isEditor?: boolean;
}

const DescriptionActions: React.FC<DescriptionActionsProps> = ({ template, session }) => {
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle || workingEvent;

	return (
		<OptionalComponent display={isModuleGroupingV2}>
			{(session && event) ? (
				<ActionBar
					session={session}
					event={event}
					template={template}
					className="description-actions"
				/>
			) : <div />}
		</OptionalComponent>
	);
};

export default DescriptionActions;