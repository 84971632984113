import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DuplicateModulesForPostRegisterHomeView } from "../connection/events";
import { updatePostRegisterHomeModules } from "../store/actions/admin/create-event";

import { useTypedSelector } from "../store/reducers/use-typed-selector";
import { PageModule } from "../types/working-model";

const useCheckAndDuplicateModulesForPostRegisterHomePreview = (postRegisterModules = false): { loading: boolean; } => {
	const [loading, setLoading] = useState(false);

	const {
		post_register_home_modules,
		homepage,
		token,
		modules,
	} = useTypedSelector(state => ({
		modules: state.CreateEventReducer?.workingEvent?.homepage?.modules || [],
		post_register_home_modules: state.CreateEventReducer?.workingEvent?.homepage?.post_register_home_modules,
		homepage: state.CreateEventReducer?.workingEvent?.homepage?.homepage,
		token: state.AuthReducer.token,
	}));

	const dispatch = useDispatch();

	// if no post_register_home_modules, then create them in the database homepage table and update redux
	useEffect(() => {
		if (modules?.length && !post_register_home_modules?.length && homepage && token && postRegisterModules && !loading) {
			setLoading(true);
			const initializePostRegisterPreviewModules = async () => {
				try {
					const results: PageModule[] = await DuplicateModulesForPostRegisterHomeView(homepage, token, modules);
					dispatch(updatePostRegisterHomeModules(results));
					setLoading(false);
				} catch (e: any) {
					console.error(e?.response || e);
					setLoading(false);
				}
			};
			initializePostRegisterPreviewModules();
		}
	}, [
		post_register_home_modules,
		dispatch,
		homepage,
		modules,
		token,
		postRegisterModules,
		loading,
	]);

	return {
		loading,
	};
};

export default useCheckAndDuplicateModulesForPostRegisterHomePreview;