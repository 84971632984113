import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateDocumentName, updateDocumentThumbnail } from "../../../../../../../../connection/content-connection";
import { UploadAndConvertDocument, UploadFile } from "../../../../../../../../connection/uploads";
import socketManager from "../../../../../../../../connection/socket-main-thread/socket-manager";
import { SocketConnection } from "../../../../../../../../connection/socket-main-thread/socket-connection";
import { deleteDocument, refreshDocumentThumbnail } from "../../../../../../../../store/actions/admin/content-actions";
import { addDocument } from "../../../../../../../../store/actions/admin/documents";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { GetDefaultDocument } from "../../../../../../../../store/utils/create-event";
import { CreateDocument, Document, LanguagesAbbr, SessionPanelLayoutsTypes, ThumbnailStatus } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import LargeButton from "../../../../../../../general-ui/button/large-button";
import FileCard from "../../../../../../../general-ui/file-card/file-card";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import '../extras.scss';
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import classNames from "classnames";
import { SessionPanelMap } from "../../session-panel-route-map";
import { useFinishNavigate, usePageModule } from "../../hooks/panel.hooks";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";

const isDocument = (doc: CreateDocument | Document): doc is Document => {
	return (doc as Document).document !== undefined;
};

const THUMBNAIL_UPDATE_KEY = 'document-thumbnail-ready';

/**
 * 
 * Notes:
 * 
 * Due to legacy reasons, the way we create and save documents is a bit convoluted.
 * 
 * When a user uploads a document, we immediately create a document in the database, 
 * this is done so that we can begin generating the thumbnail for it prior to the user saving.
 * 
 * The database will return a valid ID for the document immediately. This document ID should
 * be referenced for any edits made to the document (name, thumbnail) prior to saving.
 * 
 * The API handler for setting the document's name and thumbnail already exist and are already 
 * in use, so we're reusing those already-tested routes, and they exist separately. Prior
 * to the existence of this component, documents were not treated as individual content items that 
 * could be edited in the same sense as all other content modules. So in order to handle
 * this new method, this is the process:
 * 
 * 1. user uploads document, we create a document in the database and begin generating the thumbnail
 * 2. user sets image thumbnail, we hold that image in memory as a File object until the user is ready to save
 * 3. user sets the document's display name, we hold that string in memory until the user is ready to save
 * 4. user clicks save, we send the document's ID, the new name, and the new thumbnail to the API as separate requests
 *    and update the in-memory document with the updated data, then we update redux with the updated data
 * 5. user clicks cancel, we send a delete request to the API for the document ID, and clear the in-memory document
 * 
 */

const CreateNewResource: React.FC<unknown> = () => {
	const dispatch = useDispatch();
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const [workingDocument, setWorkingDocument] = useState<CreateDocument | Document>(GetDefaultDocument());
	const [name, setName] = useState<string | undefined>(undefined);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [file, setFile] = useState<File | null>(null);
	const [editing, setEditing] = useState(false);
	const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
	const [fileUploading, setFileUploading] = useState(false);
	const [imageUploading, setImageUploading] = useState(false);
	const [fileClear, setFileClear] = useState(false);
	const [imageClear, setImageClear] = useState(false);
	const [saving, setSaving] = useState(false);
	const [canceling, setCanceling] = useState(false);
	const isSavedDocument = isDocument(workingDocument);
	const pageModule = usePageModule();
	const history = useHistory<{ documentToEdit?: Document }>();
	const finish = useFinishNavigate();
	const adminPath = useGetAdminUrl();
	const socket = useRef<SocketConnection | null>(null);
	const activeChannel = user?.active_channel;
	const { language, customPath } = useParams<{ language: LanguagesAbbr; customPath?: string; }>();
	const workingDocumentId = isDocument(workingDocument) ? workingDocument.document : undefined;

	useEffect(() => {
		if (history.location.state.documentToEdit) {
			const { documentToEdit } = history.location.state;
			setWorkingDocument(documentToEdit);
			setName(documentToEdit.display_name.base);
			setEditing(true);
			if (documentToEdit.thumbnail) {
				setImageUrl(documentToEdit.thumbnail);
			}
		}
	}, [history.location.state, language]);

	const clearFile = useCallback(() => {
		setFile(null);
		setImageClear(true);
		setFileClear(true);
		setImageFile(null);
		setImageUrl(undefined);
		setWorkingDocument(GetDefaultDocument());
		setTimeout(() => {
			setFileClear(false);
			setImageClear(false);
		});
	}, []);

	useEffect(() => {
		const updateThumbnail = (message: { document: number, image: string }) => {
			dispatch(refreshDocumentThumbnail(message));
			if (workingDocumentId === message.document) {
				setWorkingDocument(doc => ({
					...doc,
					thumbnail: doc.thumbnail || message.image,
					generated_thumbnail: message.image
				}));
			}
		};

		if (activeChannel) {
			socket.current = socketManager.get(`admin-${activeChannel}`);
			socket.current.addListener(THUMBNAIL_UPDATE_KEY, updateThumbnail);
		}

		return () => {
			// not leaving the socket in this cleanup because this socket will be left when the admin changes channels anyway
			// as this socket channel is shared with admin.tsx, the parent component
			socket.current?.removeListener(THUMBNAIL_UPDATE_KEY, updateThumbnail);
		};
	}, [socket, dispatch, activeChannel, workingDocumentId]);

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setName(value);
	};

	const handleFile = async (file: File | FileList) => {
		if (!user || !token || !file) {
			return;
		}

		try {
			if (file instanceof File && file.size <= 10 * 1024 * 1024) {
				setFile(file);
				setFileUploading(true);

				// document changing, clear it entirely
				if (isDocument(workingDocument)) {
					dispatch(deleteDocument([workingDocument.document], token, user.active_channel));
					setWorkingDocument(GetDefaultDocument());
				}

				const newDoc = await UploadAndConvertDocument(user, token, file, undefined, undefined, name, imageUrl);
				setWorkingDocument(newDoc);
			}
		} catch (e) {
			setFileClear(true);
			setFile(null);
			setTimeout(() => setFileClear(false));

			if (e instanceof Error && e.message === 'Too many upload attempts. Please wait a moment and try again.') {
				showAlert({
					message: "Too Many Uploads",
					description: "It looks like you're trying to upload too many files. Please wait a moment and try again.",
					type: "error",
					duration: 5000
				});
			} else if (e instanceof Error) {
				showAlert({
					message: "Error uploading file",
					description: e.message,
					type: "error"
				});
			} else {
				showAlert({
					message: "Error uploading file",
					description: "It looks like we ran into a problem uploading that file. Please check your inputs and try again.",
					type: "error"
				});
			}
		} finally {
			setFileUploading(false);
		}
	};

	const handleImageFile = async (file: File | FileList) => {
		if (!user || !token || !file) {
			return;
		}

		try {
			if (file instanceof File && file.size <= 10 * 1024 * 1024) {
				setImageUploading(true);
				setImageFile(file);
				const url = await UploadFile(user, token, file);
				setImageUrl(url);
			}
		} catch (e: unknown) {
			setImageClear(true);
			setTimeout(() => setImageClear(false));

			if (e instanceof Error && e.message === 'Too many upload attempts. Please wait a moment and try again.') {
				showAlert({
					message: "Too Many Uploads",
					description: "It looks like you're trying to upload too many files. Please wait a moment and try again.",
					type: "error",
					duration: 5000
				});
			} else if (e instanceof Error) {
				showAlert({
					message: "Error uploading image",
					description: e.message,
					type: "error"
				});
			} else {
				showAlert({
					message: "Error uploading image",
					description: "It looks like we ran into a problem uploading that image. Please check your inputs and try again.",
					type: "error"
				});
			}
		} finally {
			setImageUploading(false);
		}
	};

	const handleSave = async () => {
		if (!user || !token || !pageModule) return;

		try {
			setSaving(true);
			if (isDocument(workingDocument)) {
				const promises: Promise<unknown>[] = [];
				let savedDocument = workingDocument;
				if (name) {
					promises.push(updateDocumentName(name, workingDocument.document, token));

					savedDocument = {
						...savedDocument,
						display_name: { base: name, changed: language }
					};

					setWorkingDocument(savedDocument);
				}

				if (imageUrl) {
					promises.push(updateDocumentThumbnail(imageUrl, workingDocument.document, token));

					savedDocument = {
						...savedDocument,
						thumbnail: imageUrl,
						thumbnail_status: ThumbnailStatus.done
					};

					setWorkingDocument(savedDocument);
				}

				await Promise.all(promises);

				if (!editing) {
					dispatch(addDocument(savedDocument));
					dispatch(updatePageModuleAndSave({
						...pageModule,
						modules: [...(pageModule.modules ?? []), savedDocument],
						content_modules: [...(pageModule.content_modules ?? []), savedDocument.document],
					}));
				} else {
					dispatch(updatePageModuleAndSave({
						...pageModule,
						modules: (pageModule.modules ?? []).map((doc: Document) => {
							if (doc.document === savedDocument.document) {
								return savedDocument;
							} else {
								return doc;
							}
						})
					}));
				}

				finish(adminPath({
					path: customPath ? SessionPanelMap[SessionPanelLayoutsTypes.CustomResources] : SessionPanelMap[SessionPanelLayoutsTypes.ExtraResources],
					page_module: pageModule.id,
					customPath
				}));
			}
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Problem saving document",
				description: "We ran into an issue saving that document. Please check your inputs and try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	const cancel = () => {
		try {
			setCanceling(true);

			// document was created immediately on upload, but user cancelled. Delete that document.
			if (isDocument(workingDocument) && token && user) {
				dispatch(deleteDocument([workingDocument.document], token, user.active_channel));
			}

		} catch (e) {
			// we are going to nav away regardless of whether it errored or not
			console.error(e);
		} finally {
			setCanceling(false);

			finish(adminPath({
				path: customPath ? SessionPanelMap[SessionPanelLayoutsTypes.CustomResources] : SessionPanelMap[SessionPanelLayoutsTypes.ExtraResources],
				page_module: pageModule?.id,
				customPath
			}));
		}
	};

	const canSave = isSavedDocument;

	const tooltip = useMemo(() => {
		if (imageUrl || editing) {
			return <>This image will be used as<br />the thumbnail for this document.</>;
		}

		if (workingDocument.generated_thumbnail) {
			return <>This generated image can be<br />overridden by uploading a new image.</>;
		}

		return <>Generating a thumbnail<br />for this image...</>;
	}, [editing, imageUrl, workingDocument.generated_thumbnail]);

	const filenameLabel = useMemo(() => {
		if (name) return name;

		if (editing) {
			return workingDocument.display_name.base ?? workingDocument.original_name ?? '';
		} else {
			return workingDocument.display_name.base ?? file?.name ?? workingDocument.original_name ?? '';
		}
	}, [workingDocument, name, file, editing]);

	return (
		<div className="session-panel upload-resource">
			<StaggerChildren className="session-panel-content">
				<TextInput
					label="Name"
					placeholder="File Name (optional)"
					className="resource-name"
					onChange={handleName}
					key="name-input"
					value={name}
					isAdmin
				/>

				{(!fileClear && !editing) ? (
					<LargeButton
						title="Upload document"
						subtitle="Up to 10MB file size"
						onFile={handleFile}
						label="File*"
						maxSizeInBytes={10 * 1024 * 1024}
						key="file-input"
						allowedFileTypes={[
							"application/pdf",
							"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
							"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
							"application/vnd.openxmlformats-officedocument.presentationml.presentation"
						]}
					/>
				) : <Fragment key="file-input-placeholder"></Fragment>}

				{(!imageClear) ? (
					<LargeButton
						title={"Upload Thumbnail"}
						label={editing ? "New thumbnail" : "Thumbnail (optional)"}
						subtitle="Up to 10MB file size"
						maxSizeInBytes={10 * 1024 * 1024}
						onFile={handleImageFile}
						key="image-input"
						allowedFileTypes={[
							"image/jpeg",
							"image/png",
							"image/gif"
						]}
					/>
				) : <Fragment key="image-input-placeholder"></Fragment>}

				{(file || editing) ? (
					<FileCard
						label="Document"
						filename={filenameLabel}
						filesize={(!editing && file) ? file.size : workingDocument.filesize}
						imageUrl={imageUrl ?? workingDocument.generated_thumbnail ?? undefined}
						onEdit={(e: React.ChangeEvent<HTMLInputElement>) => {
							if (e.target.files) handleFile(e.target.files[0]);
						}}
						readonly={editing}
						tooltip={tooltip}
						key="file-card"
						onDelete={() => {
							if (!user || !token) return;

							if (isDocument(workingDocument)) {
								dispatch(deleteDocument([workingDocument.document], token, user.active_channel));
								clearFile();
							}

							setFile(null);
							setWorkingDocument(GetDefaultDocument());
						}}
					/>
				) : <Fragment key="file-card-placeholder"></Fragment>}

			</StaggerChildren>
			<div className={classNames("session-panel-footer visible")}>
				<button
					onClick={cancel}
					disabled={saving || canceling || fileUploading || imageUploading}
				>
					{canceling ? <WaitingIndicator /> : "Cancel"}
				</button>
				<button
					className="lemonade"
					disabled={!canSave || saving || canceling || fileUploading || imageUploading}
					onClick={handleSave}
				>
					{saving ? <WaitingIndicator /> : "Save"}
				</button>
			</div>
		</div>
	);
};

export default CreateNewResource;