import { Fragment, useCallback, useState } from "react";
import classNames from "classnames";

import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { Link, useHistory, useParams } from 'react-router-dom';
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS } from "../../../../../../general-ui/icon";
import { TypographyItem } from "../../../../../../general-ui/typography-item/typography-item";
import { customExtraItems, customItems, CustomTabContentTypes, EExtrasGroups, ESuggestedItemsType, ExtrasTabContentTypes, IEmptyPanelItem } from "./constants/empty-panel";

import './empty-state-panel.scss';
import { CreatePageModule } from "../../../../../../../connection/page-modules";
import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { PageModuleType, SessionPanelLayoutsTypes, Templates } from "../../../../../../../types/working-model";
import { useDispatch } from "react-redux";
import { addSessionPageModule, updateGroupModules, updatePageModuleAndSave } from "../../../../../../../store/actions/admin/create-event/session";
import { usePageModule, usePageModuleGroup } from "../hooks/panel.hooks";
import { PageModuleGroup } from "../utils/prototypes/page-module-group-modules";
import { SessionPanelMap } from "../session-panel-route-map";
import { appendNewPageModulesToGroups } from "../utils/module-group.utils";
import { OptionalComponent } from "../../../../../../../utils/optional-component";

export const useCreateModuleAndGo = (setCreatingModule: (creating: boolean) => void, replace = true) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const dispatch = useDispatch();
	const getAdmin = useGetAdminUrl();
	const pageModuleGroup = usePageModuleGroup();
	const history = useHistory();
	const { customPath } = useParams<{ customPath?: string, page_module?: string, language: string }>();

	const createModuleAndGo = useCallback((pageModuleType: PageModuleType, navTo: string) => {
		// sanity check
		if (!token || !workingEvent || !workingSession || !workingSession.module_grouping || !pageModuleGroup) {
			return;
		}

		// set state, this is async
		setCreatingModule(true);

		// no page module exists to contain these products - create a new one and append it ot the module group
		CreatePageModule(token, {
			type: pageModuleType,
			eventName: workingEvent.name,
			template: Templates.Limelight,
			languages: workingSession.languages,
			baseLanguage: workingSession.default_language,
			custom_heading: pageModuleType === PageModuleType.blank ? 'Custom' : undefined,
		}).then((module => {

			// add to redux
			dispatch(addSessionPageModule(module));

			// append to module group
			const newModules = (workingSession.module_grouping as PageModuleGroup[])
				.map(appendNewPageModulesToGroups(pageModuleGroup.uuid, [module.id as number]));

			// push user to the new module
			setCreatingModule(false);
			dispatch(updateGroupModules(newModules));

			const go = replace ? history.replace : history.push;
			go(getAdmin({ path: SessionPanelMap[navTo as SessionPanelLayoutsTypes], customPath, page_module: module.id }));
		})).catch(e => {
			console.error(e);
		}).finally(() => setCreatingModule(false));
	}, [customPath, dispatch, getAdmin, history.push, history.replace, pageModuleGroup, replace, setCreatingModule, token, workingEvent, workingSession]);

	return createModuleAndGo;
};

interface EmptyStatePanelProps {
	title: string;
	description: string;
	dropDownItems?: IEmptyPanelItem;
	engage?: boolean;
	className?: string;
	buttonChildren?: string | JSX.Element;
	isArrow?: boolean;
	renderCustomIcon?: () => JSX.Element;
}

export default function EmptyStatePanel({
	title,
	description,
	dropDownItems,
	engage,
	className = "",
	buttonChildren,
	isArrow = true,
	renderCustomIcon = () => <></>,
}: EmptyStatePanelProps): JSX.Element {
	const getAdmin = useGetAdminUrl();
	const [selectedDropdownItems, setSelectedDropdownItems] = useState<IEmptyPanelItem | undefined>(dropDownItems);
	const { customPath, page_module } = useParams<{ customPath?: string, page_module?: string, language: string }>();
	const history = useHistory<{ activeModule?: number, toCustom?: boolean; refetchPageModule?: boolean; }>();
	const customTypes = customPath ? CustomTabContentTypes : ExtrasTabContentTypes;
	const innerItems = customPath ? customItems : customExtraItems;
	const [creatingModule, setCreatingModule] = useState(false);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const dispatch = useAppDispatch();
	const pageModule = usePageModule();
	const singleSession = workingEvent?.sessions.length === 1;
	const replace = !page_module && !engage; // constant used for determining history push vs replace
	const createModuleAndGo = useCreateModuleAndGo(setCreatingModule, replace);

	return (
		<div className={classNames("editor-empty-panel", className)}>
			<OptionalComponent display={!!title.trim().length}>
				<TypographyItem className="admin-typography-h4" tagName="p">
					{title}
				</TypographyItem>
			</OptionalComponent>
			<OptionalComponent display={!!description.trim().length}>
				<TypographyItem className="editor-empty-panel-text admin-typography-paragraph-1" tagName="p">
					{description}
				</TypographyItem>
			</OptionalComponent>
			<NavigationDropdown
				title={buttonChildren || "+ Add"}
				className="session-panel-dropdown"
				buttonClassName="lemonade small"
				isArrow={isArrow}
				renderCustomIcon={renderCustomIcon}
			>
				{selectedDropdownItems && Object.entries(selectedDropdownItems).map(([group, groupItems]) => (
					<Fragment key={group}>
						{groupItems.map((item) => {
							const { icon, name, navTo } = item;

							return (
								<Link
									key={name}
									replace={replace}
									className={creatingModule ? "disabled" : ""}
									onClick={(e) => {
										// this is a new custom tab with no content, create a new page module and append it to the group
										// before navigating there
										if (!page_module && item.navTo) {
											e.preventDefault();
											createModuleAndGo(item.pageModuleType, item.navTo);
										}
									}}
									to={(navTo && page_module) ? {
										pathname: getAdmin({ path: SessionPanelMap[navTo as SessionPanelLayoutsTypes], customPath, page_module })
									} : '#'}>
									<Icon
										name={icon}
										color={group === EExtrasGroups.UploadFromCloud ? "transparent" : COLORS.WHITE}
										size={12}
									/>
									{name}
								</Link>
							);
						})}
					</Fragment>
				))}
				{!selectedDropdownItems && customTypes.map((item) => {
					// do not show suggested sessions button if there is only one session
					// see: src/components/admin/create/session/navigation/panel/module-tabs.tsx
					if (item.type === PageModuleType.similar_sessions && singleSession) {
						return <Fragment key={item.label} />;
					}

					return (
						<button
							key={item.label}
							className="nav-dropdown-search"
							disabled={creatingModule}
							onClick={() => {
								if (!item.directTo) {
									setSelectedDropdownItems(innerItems(item.type));
								} else {
									if (!page_module) {
										createModuleAndGo(item.type, item.directTo);
									} else {
										if (pageModule && pageModule?.type !== item.type) {
											dispatch(updatePageModuleAndSave({
												...pageModule,
												type: item.type,
											}));
										}
										history.replace(getAdmin({
											path: SessionPanelMap[item.directTo as SessionPanelLayoutsTypes],
											customPath,
											page_module
										}), { toCustom: true, activeModule: history.location.state?.activeModule });
									}
								}
							}}
						>
							<Icon
								name={item.icon}
								color={COLORS.WHITE}
								size={12}
							/>
							{item.label}
						</button>
					);
				})}
			</NavigationDropdown>
		</div>
	);
}