import { useMemo } from "react";

import {
	EPaletteModes,
	defaultColors,
	ThemePack,
	IColorVariants,
} from "types/theme-packs";

export const useGetColorOptionsFromColor = (color?: ThemePack | null, colorTheme = EPaletteModes.Light): IColorVariants => {
	// intentionally separately pulling light and dark so they don't get added to topLevelColors
	const { [colorTheme]: colorsByTheme, light, dark, ...topLevelColors } = color?.colors || defaultColors;

	return useMemo(() => {
		return colorsByTheme || topLevelColors;
	}, [colorsByTheme, topLevelColors]);
};
