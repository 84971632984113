import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

//https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads/17/documents/drlc7nzefg/Screen_Shot_2020-11-02_at_8.52.48_AM.png this switch here

interface SwitchProps {
	value: any;
	on: boolean;
	onClick: (value: any, on: boolean) => void;
	inline?: boolean;
	disabled?: boolean;
	stopPropagation?: boolean;
	paused?: boolean;
}

export default function Switch(props: SwitchProps): JSX.Element {
	const [isFocused, setIsFocused] = useState(false);

	const switchRef = useRef<HTMLDivElement | null>(null);

	const disabled = !!props?.disabled;

	const memoizedClick = useCallback((e?: React.MouseEvent<HTMLDivElement>) => {
		if (disabled) return;
		props.stopPropagation && e?.stopPropagation();
		props.onClick(props.value, !props.on);
	}, [props, disabled]);

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (disabled) return;
			// if spacebar, prevent scroll
			if (e.key === " " && isFocused) {
				e.preventDefault();
			}
			// if enter or spacebar, trigger click
			if ((e.key === 'Enter' || e.key === " ") && isFocused) {
				props.stopPropagation && e.stopPropagation();
				memoizedClick();
			}
		};
		document.addEventListener('keypress', handleKeyPress);
		return () => {
			document.removeEventListener('keypress', handleKeyPress);
		};
	}, [switchRef, isFocused, memoizedClick, disabled, props.stopPropagation]);

	return (
		<div
			tabIndex={disabled ? -1 : 0}
			ref={switchRef}
			onFocus={() => {
				if (!disabled) {
					setIsFocused(true);
				}
			}}
			onBlur={() => setIsFocused(false)}
			className={classNames('form-control switch', { on: props.on, inlineSwitch: props.inline, isDisabled: disabled, paused: props.paused })}
		>
			<div className={classNames('gutter')} onClick={memoizedClick}>
				<div className="thumb" />
			</div>
		</div>
	);
}
