import classNames from "classnames";
import { useContext, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { TemplateNames } from "../../../../types/template-layouts";
import { EPromotionTypes, Session, SessionTypesEnum } from "../../../../types/working-model";
import { OptionalComponent } from "../../../../utils/optional-component";
import { getTemplateClassName } from "../../../../utils/utils";
import { Button } from "../../../general-ui/button/button";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";
import { lowerHand } from "../../../../store/actions/event/firesides-actions";
import { removeStorageItem } from "../../../../utils/local-storage";
import { setChatPromotion } from "../../../../store/actions/event/chat-actions";
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";

export const getHandRaisedAlertId = (sessionId: string) => `hand-raised.${sessionId}`;

interface IPromotionsProps {
	isFireside?: boolean;
	session?: Session;
}

export default function Promotions({ isFireside = false, session }: IPromotionsProps): JSX.Element {

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const promotion = useTypedSelector(state => state.ChatReducer.chatPromotion);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);

	const dispatch = useAppDispatch();

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const event = eventBundle ?? workingEvent;

	const template = event?.template?.name || TemplateNames.Classic;

	const [opened, setOpened] = useState(true);

	useEffect(() => {
		setOpened(true);
	}, [promotion]);

	const promotionTypeIsRaiseHand = (promotion && promotion.type === EPromotionTypes.raise_hand);
	const isNotFiresideSession = (session && session.session_type !== SessionTypesEnum.fireside);

	const handleDismiss = () => {
		setOpened(false);
		if (token && session) {
			dispatch(lowerHand(session.uuid, token));
			removeStorageItem(getHandRaisedAlertId(session.uuid));
			dispatch(setChatPromotion(null));
		}
	};

	if (!promotion || (promotionTypeIsRaiseHand && isNotFiresideSession)) {
		return <></>;
	}
	return (
		<OptionalComponent display={opened}>
			<div className={classNames("live-chat-promotions-container", { 'fireside-session': isFireside, 'dark-mode': isDarkMode })}>
				{promotion?.icon &&
					<div className="icon-container">
						<Icon name={promotion.icon} size={18} color={COLORS.WHITE} />
					</div>
				}
				<div className="message">
					{promotion.message}

					<OptionalComponent display={!!promotion.actionButton}>
						<Button classButton={classNames('action-button', promotion.actionButton?.class)} onClick={promotion.actionButton?.action} template={getTemplateClassName(template)}>
							{promotion.actionButton?.icon &&
								<Icon name={promotion.actionButton.icon} size={18} color={COLORS.WHITE} />
							}
							{promotion.actionButton?.text}
						</Button>
					</OptionalComponent>
				</div>
				<OptionalComponent display={promotion.dismissable}>
					<div
						className="dismiss"
						onClick={handleDismiss}
					>
						<Icon name={ICONS.CLOSE} size={12} color={COLORS.DEFAULT} />
					</div>
				</OptionalComponent>
			</div>
		</OptionalComponent>
	);
}

