import React, { CSSProperties, useCallback, useContext, useEffect, useMemo } from "react";
import { useParams, useHistory, useLocation, useRouteMatch, matchPath } from "react-router-dom";
import classNames from 'classnames';

import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { BrandliveEvent, EBroadcastTypes, ENavbarTypes, LanguagesAbbr, Session, SessionTypesEnum } from "../../../../../types/working-model";
import { EditorSizes, TemplateClassNames } from "../../../../../types/template-layouts";
import { formatStringEllipses, isMobile, isiOSMobile, getTemplateClassName, shouldDisplayHomepage } from "../../../../../utils/utils";
import { COLORS, ICONS } from "../../../../general-ui/icon";
import ButtonPlain from "../../../../general-ui/button-plain/button-plain";
import { TypographyItem } from "../../../../general-ui/typography-item/typography-item";
import { ParamsProps } from "../../../live-event";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import useTimestampStatus, { ETimestampStatus } from "../../../../../utils/use-timestamp-status";
import { checkIsLive } from "../../../../../utils/live-checker";
import { useScreenMediaQuery } from "../../../../../utils/use-screen-media-query";
import { eventPath } from "../../../utils";
import { DebugDefaultRouteMap, DefaultRouteMap } from "../../../hooks/use-route-map";
import { PATHNAMES } from "../../../../../utils/admin-routing-utils";
import { OptionalComponent } from "../../../../../utils/optional-component";
import { LanguageDropdown } from "../../../../general-ui/language-dropdown/language-dropdown";
import useChangeLanguage from "../../../../../utils/use-change-language";
import { useIsNewNavigation } from "../../../../../hooks/navigation.hooks";
import NavigationToggle from "components/live-event/marketing-page/navigation-v2/navigation-toggle";
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";

import '../../../../../scss/live-event/base/session/session-header.scss';

interface SessionHeaderProps {
	session: Session;
	eventBundle: BrandliveEvent;
	isEditor?: boolean;
	editorSize?: string;
	styleOverrides?: CSSProperties;
	previewLanguage?: LanguagesAbbr;
	hideProfile?: boolean;
	hideNavItems?: boolean;
	bannerAnnouncementShowing?: boolean;
}

const SessionHeader = React.forwardRef<HTMLDivElement, SessionHeaderProps>(({
	session,
	eventBundle,
	isEditor,
	editorSize,
	styleOverrides,
	previewLanguage,
	hideProfile = false,
	hideNavItems = false,
	bannerAnnouncementShowing = false
}, sessionHeaderRef) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const blProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const chatPanelOpen = useTypedSelector(state => state.ChatReducer.isChatPanelOpen);
	const hideControls = useTypedSelector(state => state.VideosReducer.showVideoControls);
	const embedMeetJoined = useTypedSelector(state => state.LiveEventReducer.embeddedMeetJoined);
	const isNavigationVisible = useTypedSelector(state => state.ContentReducer.isNavigationOpen);

	const showNewNav = useIsNewNavigation();

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const isDesktop = editorSize ? editorSize === 'desktop' : undefined;

	const event = eventBundle || workingEvent;
	const { uuid, language, eventName }: ParamsProps = useParams();
	const history = useHistory();
	const location = useLocation();
	const routerMatch = useRouteMatch();
	const { t } = useTranslate(['session', 'homepage']);
	const { isLessThan1024, isLessThan640 } = useScreenMediaQuery();
	const timestampStatus = useTimestampStatus(session);
	const handleLanguageChange = useChangeLanguage(event);

	const template = getTemplateClassName(eventBundle.template.name);
	const isTablet = editorSize ? editorSize === 'tablet' : undefined;
	const isMobileSize = isLessThan640 || isMobile || editorSize === EditorSizes.mobile;

	const isIphone = isiOSMobile?.();
	// orientation can either be 0, -90, 90, or 180 https://developer.apple.com/documentation/webkitjs/domwindow/1632568-orientation.
	const isLandscapeIphone = isIphone && (window.orientation === -90 || window.orientation === 90);
	const isTopNavbar = eventBundle?.settings?.event_navbar?.navbarType === ENavbarTypes.Horizontal;

	const hasLandingPage = !!workingEvent?.homepage || !!eventBundle?.homepage;
	const workingEventSession = workingEvent?.sessions.find((s: Session) => s.session === session.session);
	const title = workingEventSession?.title;
	const displaySessionLanguageDropdown = session.languages.length > 1 && (event.settings.display_session_language_dropdown ?? false);

	let displayTitle: string;
	if (session.title[language]) {
		displayTitle = session.title[language].toString();
	} else if (title?.[language]) {
		displayTitle = title[language].toString();
	} else {
		displayTitle = session.title.base;
	}

	const isOnDemand = session.session_type === SessionTypesEnum.onDemand;
	const isBroadcast = session.session_type === SessionTypesEnum.broadcast;
	const isFireside = session.session_type === SessionTypesEnum.fireside;
	const isBreakout = session.session_type === SessionTypesEnum.breakoutRooms;
	const isIFrameBroadcast = session.session_type === SessionTypesEnum.broadcast && session.broadcast_type === EBroadcastTypes.embed;
	const isInGoogleMeetSession = !!(isBreakout && session.breakout_session?.use_google_meet && embedMeetJoined);

	const hideNavMenu = hideNavItems
		|| ((isFireside || isIFrameBroadcast)
			&& ((isLessThan1024 && !isMobileSize)
				|| editorSize && editorSize === EditorSizes.tablet
				|| editorSize && editorSize === EditorSizes.mobile));

	const tagIconColor = () => {
		switch (template) {
			case TemplateClassNames["Apollo"]:
			case TemplateClassNames['Limelight']:
				return COLORS.WHITE;
			case TemplateClassNames["Studio 54"]:
			case TemplateClassNames["O2"]:
				return COLORS.BLACK;
			default:
				return COLORS.WHITE;
		}
	};

	// for firesides and breakouts we need to store the original location from which the session was accessed
	// in order to properly route the user when they click the back arrow (or they could be taken back on stage or into their room)
	const locationState = location.state as unknown as Record<string, string>;
	useEffect(() => {
		if (locationState?.from && !isFireside && !isBreakout) {
			sessionStorage.setItem('backTo', locationState.from);
		}
	}, [isBreakout, isFireside, locationState]);

	const backButton = (): void => {
		if (isFireside || isBreakout) {
			const path = sessionStorage.getItem('backTo') ?? `/${eventPath(eventName, uuid)}/${language}/sessions`;
			sessionStorage.removeItem('backTo');
			history.push(path);
		} else {
			history.goBack();
		}
	};

	{/*
		Do not show back button if breakout and we're on the sessions or home page.
		This follows the same pattern for other session types since they also don't show the back button on Home/Agenda pages
	*/ }
	const hideBackButtonForBreakout = useCallback(() => {
		return isBreakout && [
			DebugDefaultRouteMap.Home,
			DefaultRouteMap.Home,
			DebugDefaultRouteMap.Sessions,
			DefaultRouteMap.Sessions,
			PATHNAMES.Event.SessionsPath,
			PATHNAMES.Event.HomePath,
		].includes(routerMatch.path);
	}, [isBreakout, routerMatch.path]);

	const isSingleSession = (event)?.sessions?.length === 1;
	// if single session on demand and screen size is in between mobile and desktop, then make header static
	const isSingleSessionTablet = useMemo(() => {
		const match = matchPath(history.location.pathname, {
			path: [
				DebugDefaultRouteMap.Landing,
				DefaultRouteMap.Landing,
				DefaultRouteMap.Session,
				DebugDefaultRouteMap.Session,
			],
			exact: true
		});
		const liveEventStaticHeader = match
			&& isSingleSession
			&& !isMobile
			&& !isLessThan640
			&& isLessThan1024;

		const editorStaticHeader = isSingleSession
			&& isEditor
			&& isTablet;

		const onDemandOrBroadcast = isOnDemand || isBroadcast;

		return (liveEventStaticHeader || editorStaticHeader) && onDemandOrBroadcast;

	}, [
		history.location.pathname,
		isSingleSession,
		isLessThan640,
		isLessThan1024,
		isEditor,
		isTablet,
		isOnDemand,
		isBroadcast,
	]);

	const GenerateNavigationText: React.FC = () => {
		return (<>
			{
				(timestampStatus !== ETimestampStatus.preLive || (isSingleSession && !hasLandingPage && displayTitle)) &&
				<TypographyItem tagName={"h5"} className={classNames({ "dark-mode": isDarkMode })}>{formatStringEllipses(displayTitle, 100)}</TypographyItem>
			}

			{
				(timestampStatus === ETimestampStatus.preLive && hasLandingPage) &&
				<TypographyItem tagName={"h5"} className={classNames({ "dark-mode": isDarkMode })}>{t("session:Back")}</TypographyItem>
			}
		</>);
	};

	const GenerateNavigation: React.FC = () => {
		return (
			<div className="session-title-text">
				<div className="session-title-text-content">
					<GenerateNavigationText />
				</div>
			</div>
		);
	};

	const hasHomepage = shouldDisplayHomepage(event);
	const shouldShowForMobile = isTablet || isLessThan1024 || isMobileSize;
	const shouldShowForTablet = isTablet || isLessThan1024;

	// if there is no homepage on this single-session event, then there's nothing to go back to
	const shouldHideBackButton = shouldShowForMobile ? true : !hideControls;
	const isBackButtonHidden = showNewNav ? shouldHideBackButton : true;
	const backButtonVisibility = showNewNav
		? isBackButtonHidden && !isNavigationVisible ? 'visible' : 'hidden'
		: undefined;

	const shouldShowNavBar = () => {
		if (showNewNav) {
			return true;
		} else {
			return shouldShowForTablet;
		}
	};

	return (
		<div
			className={classNames("session-header", template, {
				// 'breakout-session': isBreakout,
				'fireside-session': !showNewNav && isFireside,
				'has-navigation': hasLandingPage,
				[timestampStatus]: timestampStatus,
				'is-mobile': isMobileSize,
				'is-desktop': !isLessThan1024,
				'is-landscape-iphone': isLandscapeIphone,
				'chat-open': chatPanelOpen,
				'static-header': showNewNav && isSingleSessionTablet,
				'single-session': isSingleSession,
				"new-navigation": showNewNav,
				'has-banner-announcement': (eventBundle?.announcements && eventBundle.announcements.length > 0 && isLessThan1024) || bannerAnnouncementShowing,
				'is-editor': isEditor,
				'is-editor-desktop': isEditor && isDesktop
			})}
			style={styleOverrides}
			ref={sessionHeaderRef}
		>
			<header
				className={classNames(template, {
					'is-mobile': isMobileSize,
					'display-language-dropdown': isMobileSize && displaySessionLanguageDropdown,
					'in-google-meet-session': isInGoogleMeetSession,
					'has-banner-announcement': eventBundle?.announcements && eventBundle.announcements.length > 0 && isMobileSize
				})}
				style={{ pointerEvents: (isEditor || (previewMode === 'session')) ? 'none' : 'auto' }}>
				<div
					style={{ visibility: backButtonVisibility }}
					className={
						classNames(
							"session-titlebox",
							{
								"dark-mode": isDarkMode,
								"new-navigation": showNewNav
							}
						)}>
					{!hasHomepage || hideBackButtonForBreakout()
						? <GenerateNavigation />
						: <ButtonPlain
							className={
								classNames(
									"back-button",
									{
										"dark-mode": isDarkMode,
										"hidden-button-text": showNewNav
									}
								)
							}
							onclick={backButton}
							icon={ICONS.ARROW_LEFT}
							color={"inherit"}
						>
							<OptionalComponent display={!showNewNav}>
								<GenerateNavigation />
							</OptionalComponent>
						</ButtonPlain>
					}
				</div>

				<OptionalComponent display={isMobileSize && displaySessionLanguageDropdown}>
					<div className="language-dropdown-container">
						<LanguageDropdown languages={session.languages as LanguagesAbbr[]} currentLanguageCode={language} useISOLangCodes onLanguageChange={handleLanguageChange} isNoScroll={false} animateDropdown />
					</div>
				</OptionalComponent>
				<NavigationToggle hideToggle={false} />
				<OptionalComponent display={shouldShowNavBar()}>
					<NavigationToggle hideToggle={false} />
				</OptionalComponent>
			</header>
		</div>
	);
});

SessionHeader.displayName = 'SessionHeader';

export default SessionHeader;
