import React from "react";
import { useTracking } from ".";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";

// wrapper component to allow class component access to useTracking hook
export const withTrackingHook = (Component: typeof React.Component): any => {
	return function WrapComponent(props: any): any {
		const { trackEvent } = useTracking();
		const eventName = useTypedSelector(state => state.LiveEventReducer.eventBundle?.name);
		const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
		return <Component {...props} trackEvent={trackEvent} eventName={eventName ?? ""} email={blProfileUser?.email ?? ""} />;
	};
};