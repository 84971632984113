import { getDefaultLanguage } from "../components/live-event/utils";
import { BrandliveEvent, LanguagesAbbr, Session } from "../types/working-model";
import store from '../store/main';


// this function returns a default language code based on the event passed in
export function getDefaultLanguageCode(event: BrandliveEvent, session?: Session): string {
	if (session) {
		// if session has default language, return
		if (session.default_language) {
			return session.default_language;
		} else {
			return session.languages[0];
		}
	}

	return getDefaultLanguage(event);
}

export function getHomepageDefaultLanguage(event?: BrandliveEvent | null): LanguagesAbbr {
	if (event) {
		return getDefaultLanguage(event);
	} else {
		const state = store.getState();

		// try absolutely everything before defaulting to English

		if (state.LiveEventReducer.eventBundle) {
			return getDefaultLanguage(state.LiveEventReducer.eventBundle);
		}

		if (state.CreateEventReducer.workingEvent) {
			return getDefaultLanguage(state.CreateEventReducer.workingEvent);
		}

		if (state.ModeratorReducer.activeEvent) {
			return getDefaultLanguage(state.ModeratorReducer.activeEvent);
		}

		return 'en';
	}
}