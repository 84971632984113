import type { SlugifyFunction } from 'transliteration';

class LatinChars {
	transliterate?: SlugifyFunction;

	slugify = async (input: string): Promise<string> => {
		if (!this.transliterate) {
			this.transliterate = (await import('transliteration')).slugify;
		}

		// get slug from transliterate library
		const slug = this.transliterate(input, { lowercase: false });

		// constrain to alphanumeric and dashes, then remove leading and trailing dashes
		// TAKE CARE when changing this that we are not introducing regressions where users 
		// suddenly cannot use their existing URLs and must change them as the existing URLs might have been published
		return slug.replace(/[^a-zA-Z0-9-]/g, '-').replace(/^-+|-+$/g, '');
	};
}

export const latinChars = new LatinChars();