import { TranslateFunction } from "../../../../i18n/useTranslationModules";
import { BrandliveEvent, IRegistrationQuestionTranslations, LanguagesAbbr, RegFieldsEnum, RegistrationQuestion } from "../../../../types/working-model";
import { IRegistrationFormField } from "../registration";

type GetRegFieldLabelLiveEventParams = {
	t: TranslateFunction,
	field: IRegistrationFormField,
	eventBundle: BrandliveEvent,
	language: LanguagesAbbr,
	question?: never;
	eventRegistrationTranslations?: IRegistrationQuestionTranslations;
}
type GetRegFieldLabelAdminParams = {
	t: TranslateFunction,
	field?: never,
	eventBundle: BrandliveEvent,
	language: LanguagesAbbr,
	question: RegistrationQuestion;
	eventRegistrationTranslations?: IRegistrationQuestionTranslations;
}

const getIsAdminReg = (reg: RegistrationQuestion | IRegistrationFormField): reg is RegistrationQuestion => {
	return ('registration_question' in reg);
};

type GetRegFieldLabelParams = GetRegFieldLabelAdminParams | GetRegFieldLabelLiveEventParams;
export const getRegFieldLabel = (params: GetRegFieldLabelParams): [string, string] => {
	const {
		t,
		field,
		eventBundle,
		language,
		question,
		eventRegistrationTranslations,
	} = params;
	const reg = question ?? field;
	let id: number;
	let label: string;
	let placeholder: string;

	// Begin checking V3 translations (from database)
	const questionId = question?.registration_question ?? field?.field_id;
	if ((questionId || questionId === 0) && eventRegistrationTranslations) {
		const dbNameTranslation = eventRegistrationTranslations?.[questionId]?.name?.[language];
		const dbPlaceholderTranslation = eventRegistrationTranslations?.[questionId]?.placeholder?.[language];
		if (typeof dbNameTranslation === 'string' && typeof dbPlaceholderTranslation === 'string') {
			return [dbNameTranslation, dbPlaceholderTranslation];
		}
	}
	// End checking v3 translations (from database)

	// assign base variables based on field input origin (admin or live event)
	if (getIsAdminReg(reg)) {
		id = reg.registration_question;
		label = (reg.name_translation?.[language] ?? reg.name) as string;
		placeholder = (reg.placeholder?.[language] ?? reg.name) as string;
	} else {
		id = reg.field_id;
		label = reg.label;
		placeholder = reg.placeholder ?? reg.label;
	}

	const isPasscode = id === RegFieldsEnum.passcode;
	const baseLabel = label;
	const basePlaceholder = placeholder;

	if (isPasscode) {
		/*

		Remember that part in First Blood where Rambo falls through a tree and hits all the branches on the way down?
		There are 6 potential values for the passcode label and placeholder.

		Order of precedence:
		1. Custom label field from uploaded V2 translations
		2. Custom label field from V1 translations
		3. Custom label from V2 translations for field "passcode" (no custom label applied by admin)
		4. Our 18next translations for field 10
		5. The base string "Passcode" or "Enter Passcode"

		*/

		// Detect if custom label is set. Default is empty strings for base + all langs
		// Only use if at least one is filled out
		const usingCustomLabel =
			!!(eventBundle.registration_settings?.customPasscodeLabel?.[language]?.length ||
				eventBundle.registration_settings?.customPasscodeLabel?.base?.length);

		const translatedLabel = t(
			`registrations:question.custom_passcode_label`, // new custom field from V2
			usingCustomLabel ? eventBundle.registration_settings?.customPasscodeLabel?.[language] as string : // value set in admin console for custom label
				t(`registrations:question.${RegFieldsEnum.passcode}.name`, // the name of this field from V2 translations if there was no custom label
					t(`registration.registration_fields.${RegFieldsEnum.passcode}`, baseLabel) // the i18next translations for this field falling back to the string "Passcode"
				)
		);

		const translatedPlaceholder = t(
			`registrations:question.custom_passcode_placeholder`, // new custom field from V2
			usingCustomLabel ? eventBundle.registration_settings?.customPasscodeLabel?.[language] as string : // value set in admin console for custom label
				t(`registrations:question.${RegFieldsEnum.passcode}.placeholder`, // custom field from V2 for the passcode field
					t(`registration.registration_fields.${RegFieldsEnum.passcode}`, basePlaceholder) // the i18next value for the passcode field falling back to "Passcode"
				)
		);

		return [translatedLabel, translatedPlaceholder];
	}

	/*

	Order of precedence non-passcodes:
	1. Custom label from uploaded V2 translations
	2. Translated label from local i18n translations
	3. Label as entered within the database

	*/
	const translatedLabel = t(
		`registrations:question.${id}.name`,
		t(`registration.registration_fields.${id}`, baseLabel)
	);

	const translatedPlaceholder = t(
		`registrations:question.${id}.placeholder`,
		t(`registration.registration_fields.${id}`, basePlaceholder || baseLabel || "")
	);

	return [translatedLabel, translatedPlaceholder];
};