import React, { CSSProperties, Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isObject } from 'underscore';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { SocketMessage, useSocket } from '../../../../connection/socket';
import { BlProfile, BrandliveEvent, EBroadcastTypes, ISocketCommentProps, LanguagesAbbr, Session, SessionTypesEnum, Status } from '../../../../types/working-model';
import WaitingIndicator from '../../../general-ui/waiting-indicator/waiting-indicator';
import ChatFeed from './chat-feed';
import Promotions from './promotions';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import Icon, { COLORS, ICONS } from '../../../general-ui/icon';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { deleteChatMessage, incomingChatMessage, toggleChatPanel } from '../../../../store/actions/event/chat-actions';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import useTimestampStatus from '../../../../utils/use-timestamp-status';
import { OptionalComponent } from '../../../../utils/optional-component';
import { matchChatSender } from '../../utils';
import '../../../../scss/live-event/base/session/live-chat.scss';
import { isiOSMobile } from '../../../../utils/utils';
import ScrollOverlays from './scroll-overlays';
import { useIsNewModuleGrouping } from 'hooks/session.hooks';
import { ThemeContext } from 'components/live-event/theme-context';
import { EPaletteModes } from 'types/theme-packs';

//defer loading because emoji mart is freaking huge
const AddLiveComment = React.lazy(() => import('./add-live-comment'));

interface ILiveChatProps {
	loading?: boolean;
	session: Session | null;
	language: LanguagesAbbr;
	registrationOn: boolean;
	blProfileUser: BlProfile | null;
	template: string;
	forceOverlay: boolean;
	mobileOnly: boolean;
	isEditor?: boolean;
	onChatClose: (chatClosed: boolean) => void;
	chatClosed: boolean;
	eventBundle: BrandliveEvent;
	isAdmin?: boolean;
	canGoLandscape?: boolean;
	videoV2?: boolean;
}

const LiveChat: React.FC<ILiveChatProps> = ({
	session,
	loading = false,
	language,
	blProfileUser,
	template,
	forceOverlay,
	mobileOnly,
	isEditor = false,
	onChatClose,
	chatClosed,
	eventBundle,
	isAdmin = false,
	canGoLandscape = true,
	videoV2 = false
}) => {
	const comments = useTypedSelector(state => state.ChatReducer.comments);
	const loadingComments = useTypedSelector(state => state.ChatReducer.loadingComments);
	const count = useTypedSelector(state => state.ChatReducer.count);
	const [extraPadding, setExtraPadding] = useState(false);
	const [showSpamFilterAlert, setShowSpamFilterAlert] = useState(false);
	const [chatActiveHeight, setChatActiveHeight] = useState('');
	const [atTop, setAtTop] = useState(false);
	const [atBottom, setAtBottom] = useState(true);

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const isSessionDetailsV2 = useIsNewModuleGrouping();
	const dispatch = useDispatch();

	// keep redux in sync
	useEffect(() => {
		dispatch(toggleChatPanel(!chatClosed));
	}, [chatClosed, dispatch]);

	const { t } = useTranslate('session');

	const { isLessThan768 } = useScreenMediaQuery();
	const timestampStatus = useTimestampStatus(session);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const moreButtonRef = useRef<HTMLButtonElement | null>(null);
	const socketChannel = session?.session_chat_single_language ? `session-${session?.uuid}` : `session-${session?.uuid}-${language || 'en'}`;
	const socket = useSocket(socketChannel);

	const customChatBackgroundColor = session?.video?.customizations?.backgroundColor;

	// this is only for chat WITHOUT moderation
	useEffect(() => {
		// add socket listener
		const handleSessionChatComment = (comment: unknown) => {
			if (comment && isObject(comment) && comment?.chat_messages) {
				comment.chat_messages.forEach((msg: ISocketCommentProps) => {
					const isSender: boolean = matchChatSender(blProfileUser, msg.bl_profile, msg.bl_profile_non_registered);
					// messages coming through will be approved or banned, show banned only to sender
					if (!msg?.spam && (msg.status === Status.Approved || isSender)) {
						dispatch(incomingChatMessage(msg));
					}
					// if spam && the comment originates from this user, then show a message
					if (msg?.spam && isSender) {
						setShowSpamFilterAlert(true);
					}
				});
			}
		};
		socket.addListener('chat', handleSessionChatComment);
		return () => {
			// remove socket listener
			socket.removeListener('chat', handleSessionChatComment);
		};
	}, [socket, blProfileUser, dispatch, /*autoScroll*/]);

	// this is for chat WITH moderation
	useEffect(() => {
		const handleModeration = ({ data }: SocketMessage) => {
			switch (data.message_type_update) {
				case Status.Approved: {
					dispatch(incomingChatMessage(data));
					break;
				}
				case Status.Unapproved:
				case Status.Deleted: {
					dispatch(deleteChatMessage(Number(data.id)));
					break;
				}
				case Status.Banned: {
					// delete banned messages for everyone other than the sender
					const isSender: boolean = matchChatSender(blProfileUser, data.bl_profile, data.bl_profile_non_registered);
					if (isSender) {
						dispatch(incomingChatMessage(data));
					} else {
						dispatch(deleteChatMessage(Number(data.id)));
					}
				}
			}
		};

		socket.addListener('update_chat_message', handleModeration);

		return () => {
			socket.removeListener('update_chat_message', handleModeration);
		};
	}, [socket, dispatch, blProfileUser]);

	const toggleActive = useCallback(() => {
		// ensure we always have the correct chat height on mobile
		if (mobileOnly && containerRef.current) {
			const activeMobileChatHeightValue = (window.innerHeight - (containerRef.current.getBoundingClientRect().y));
			setChatActiveHeight(Math.round(activeMobileChatHeightValue) + 'px');
		}

		onChatClose(!chatClosed);
	}, [chatClosed, mobileOnly, onChatClose]);

	const handleTransitionEnd = useCallback((e: unknown) => {
		if (!isObject(e)) return;

		const targetClassList = e?.target?.classList?.value;

		// do not force scroll bottom if a transition comes from the lightbox or comment action buttons
		if (
			targetClassList.includes('lightbox') ||
			targetClassList.includes('action-trigger-btn') ||
			targetClassList.includes('chat-comment-date') ||
			targetClassList.includes('emoji-mart') ||
			targetClassList.includes('reactGiphy') ||
			targetClassList.includes('popover-wrapper') ||
			targetClassList.includes('add-live-comment-textarea')
		) {
			return;
		}

		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
		}
	}, []);

	const isBreakout = session?.session_type === SessionTypesEnum.breakoutRooms;
	const isFireside = session?.session_type === SessionTypesEnum.fireside;
	const isIFrameBroadcast = session?.session_type === SessionTypesEnum.broadcast && session?.broadcast_type === EBroadcastTypes.embed;

	const isIos = useMemo(() => isiOSMobile(), []);

	const chatContainerStyle = useMemo(() => {
		if (!mobileOnly) return undefined;
		const styles: CSSProperties = {
			height: chatActiveHeight
		};

		if (isSessionDetailsV2 && chatClosed) {
			styles.height = 50;
		} else {
			if (customChatBackgroundColor) {
				styles.backgroundColor = `var(--${customChatBackgroundColor})`;
			}

			if (chatClosed) {
				styles.height = 60;
			}
		}

		return styles;
	}, [chatActiveHeight, chatClosed, customChatBackgroundColor, mobileOnly, isSessionDetailsV2]);

	const content = useMemo(() => {
		// if session is breakout, do not show chat
		if (!session || isBreakout) return null;
		return (
			<div
				className={classNames("live-chat-container", template, {
					'chat-overlay': (!mobileOnly && session?.layout?.overlayChat || forceOverlay),
					'is-editor': isEditor,
					'is-open': !mobileOnly && !chatClosed,
					'is-open--mobile': (mobileOnly && !chatClosed && !isEditor),
					'move-chat-up': mobileOnly && !chatClosed,
					'chat-as-bar': (mobileOnly && isFireside) || (mobileOnly && isIFrameBroadcast),
					'fireside-session': isFireside && !isEditor,
					'no-landscape': !canGoLandscape,
					'dark-mode': isDarkMode,
					'video-v2': videoV2,
					'is-ios': isIos,
				})}
				style={chatContainerStyle}
				ref={containerRef}
			>
				<OptionalComponent display={mobileOnly}>
					<div
						onClick={toggleActive}
						className={classNames("live-chat-header-mobile", { "dark-mode": isDarkMode })}
					>
						<TypographyItem tagName="h6">
							{t("Chat")}<span className="live-chat-message-count"> {count <= 0 && isLessThan768 ? null : <>• {count} message{count !== 1 ? "s" : ""}</>}</span>
						</TypographyItem>
						<button className="no-style"><Icon name={!chatClosed && !isEditor ? ICONS.CONTRACT : ICONS.EXPAND} color={isDarkMode ? COLORS.BLACK : COLORS.DEFAULT_GRAY} size={16} /></button>
					</div>
				</OptionalComponent>
				<OptionalComponent display={!mobileOnly}>
					<div
						onClick={toggleActive}
						className={classNames("live-chat-header", { "dark-mode": isDarkMode, 'fireside-session': isFireside })}
					>
						<TypographyItem tagName="h5">{t("Chat")}</TypographyItem>
						{/* cannot close chat if using an iFrame broadcast */}
						<OptionalComponent display={!isIFrameBroadcast}>
							<button className="no-style"><Icon name={ICONS.CLOSE} color={isDarkMode ? COLORS.BLACK : COLORS.WHITE} size={16} /></button>
						</OptionalComponent>
					</div >
				</OptionalComponent>

				<div
					className={
						classNames("feed-container",
							{
								'fireside-session': isFireside,
								[timestampStatus]: timestampStatus,
								"dark-mode": isDarkMode,
								"at-top": atTop,
								"at-bottom": atBottom,
								"at-middle": !atTop && !atBottom
							})}
					ref={scrollContainerRef}
					// We have JS inside of use-track-scroll-pos that will set this to top/middle/bottom if the user scrolls
					// then the css will either show/hide the scroll overlays based on the data-scroll value.
					// We set this to 0 on load and use css to hide overlay if "0" so that the scroll overlays are hidden by default if the list is not scrollable on load.
					data-scroll="0"
				>
					{loadingComments && <div style={{ textAlign: 'center' }}><WaitingIndicator /></div>}

					<ChatFeed atTop={atTop} atBottom={atBottom} setAtBottom={setAtBottom} setAtTop={setAtTop} comments={comments} blProfileUser={blProfileUser} darkMode={isDarkMode} />

					<ScrollOverlays session={session} mobileOnly={mobileOnly} forceOverlay={forceOverlay} />
				</div>
				<Promotions isFireside={isFireside} session={session} />
				<div className={classNames("chat-actions-container", { extraPadding, chatClosed, 'dark-mode': isDarkMode })}>
					<Suspense fallback="">
						<AddLiveComment
							setShowSpamFilterAlert={setShowSpamFilterAlert}
							showSpamFilterAlert={showSpamFilterAlert}
							session={session}
							language={language}
							eventBundle={eventBundle}
						/>
					</Suspense>
				</div>
			</div>
		);
	}, [atBottom, atTop, blProfileUser, canGoLandscape, chatClosed, chatContainerStyle, comments, count, eventBundle, extraPadding, forceOverlay, isBreakout, isEditor, isFireside, isIFrameBroadcast, isIos, isLessThan768, language, loadingComments, mobileOnly, session, showSpamFilterAlert, t, template, timestampStatus, toggleActive, videoV2]);


	if (loading) return <WaitingIndicator />;
	if (!session) return null;
	//live-chat-wrapper handles the in/out transitions, and conflicts severely with the mobile orientation.

	return !mobileOnly ? (
		<div
			className={
				classNames(
					"live-chat-wrapper",
					{
						'active': !chatClosed,
						'chat-overlay': (session?.layout?.overlayChat || forceOverlay),
						'dark-mode': isDarkMode
					})}
			onTransitionEnd={handleTransitionEnd}
		>
			{content}
		</div>
	) : (
		<>
			{content}
		</>
	);

};

export default LiveChat;
