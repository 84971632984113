import { UploadFile } from "connection/uploads";
import { BLAdmin } from "types/working-model";

export async function putVideoThumbnail(videoUrl: string, token: string, user: BLAdmin): Promise<string | null> {
	try {
		const thumbnailData = await getVideoThumbnail(videoUrl);
		const thumbFile = new File([thumbnailData], 'thumbnail.jpg');
		return await UploadFile(user, token, thumbFile);
	} catch (e) {
		console.error(e);
		return null;
	}
}

export default function getVideoThumbnail(file: File | string): Promise<File | Blob> {
	return new Promise((resolve, reject) => {
		try {
			const url = file instanceof File ? URL.createObjectURL(file) : file;
			const videoEl = document.createElement('video');

			if (typeof url === "string") {
				videoEl.crossOrigin = "anonymous";
			}

			videoEl.src = url;
			videoEl.muted = true;

			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			videoEl.addEventListener('playing', () => {
				videoEl.currentTime = videoEl.duration / 1.5;
			});

			videoEl.addEventListener('seeked', () => {
				//console.log(videoEl.currentTime);
				canvas.width = videoEl.videoWidth;
				canvas.height = videoEl.videoHeight;

				ctx?.drawImage(videoEl, 0, 0, videoEl.videoWidth, videoEl.videoHeight);
				const frame = canvas.toDataURL('image/jpeg', 0.8);

				resolve(dataURItoBlob(frame));
				videoEl.pause();
				videoEl.remove();
				canvas.remove();
			});

			videoEl.play();
		} catch (e) {
			reject(e);
		}
	});
}

function dataURItoBlob(dataURI: string) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else
		byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}