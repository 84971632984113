import { Session } from "inspector";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Broadcast {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Question {}

export interface QuizQuestion {
  quiz_question: number;
  question: string;
  choice_1: string | null;
  choice_2: string | null;
  choice_3: string | null;
  choice_4: string | null;
  choice_5: string | null;
  correct_choice: number | "";
  quiz: number;
  multiple_choice: Bool;
  quiz_question_rand: string;
}

export interface Quiz {
  quiz: number;
  title: string;
  success_theshold: number | "";
  success_message: string;
  failure_message: string;
  client: number;
  status: Status;
  allow_retake: Bool;
  quiz_rand: string;
  archived: ArchiveStatus;
  created_by: number;
  created_at: string;
  modified_by: number;
  modified_at: string;
  questions: QuizQuestion[];
}

export interface SurveyQuestion {
  survey_question: number;
  survey: number;
  question_title: number;
  required: Bool;
  question_type: 1 | 2 | 3;
  status: Status;
  sort_order: number;
}

export interface Survey {
  survey: number;
  name: string;
  description: string;
  client: number;
  completion_message: string;
  date_created: string;
  allow_navigation: Bool;
  allow_retake: Bool;
  status: Status;
  show_results: Bool;
  created_by: number | null;
  modified_by: number | null;
  modified_at: string | null;
  questions: SurveyQuestion[];
}

export interface Document {
  document: number;
  client: number;
  original_name: string;
  location: string;
  uploaded_time: string;
  display_name: string;
  brandlive_profile: number;
  thumbnail: string;
  thumbnail_status: 0 | 1 | 2;
  status: Status;
  archived: ArchiveStatus;
  filesize: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SimilarSession {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Feed {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmbedWidget {}

//new type
export interface Speaker {
  speaker: number;
  image: string; //image with scale/position applied
  original_image: string; //unedited image
  first_name: string;
  last_name: string;
  about: string;
  related_session: Session;
}

export interface Slideshow {
  slideshow: number;
  name: string;
  client: number;
  slide_images: string;
  archived: Status;
  created_by: number;
  created_at: string;
  modified_by: number | null;
  modified_at: string | null;
  status: Status;
}

export interface Product {
  product: number;
  client: number;
  status: number;
  tab_title: string;
  product_title: string;
  description: string;
  price: string;
  buy_now_link: string;
  live_chat: number;
  image: string;
  sale_price: string;
  button_text: string;
  product_rand: string;
  product_created: string;
  product_modified: string;
  archived: ArchiveStatus;
  created_by: number;
  modified_by: number;
}

//features on/off states stored separately from the objects themselves in case an admin
//accidentally toggles a feature off - separation will prevent data loss
export interface BrandliveHomepage {
  header: {
    logo: string;
    call_to_action: {
      type: CallToActionType;
      button_name: string;
    };
  };
  main_event_banner: {
    title: string;
    description: string;
    image: string | null;
    original_image: string | null;
    call_to_action: {
      type: CallToActionType;
      button_name: string;
    };
  };
  use_main_event_banner: Bool;
  speakers: Speaker[] | null;
  use_speakers: Bool;
  agenda: any; //no idea what this is yet, should set when design is visible
  use_agenda: Bool;
}

export interface BrandliveSession {
  instances: BrandliveSessionInstance[];
  products: Product[];
}

//since a session can contain separate instances for each selected language
export interface BrandliveSessionInstance {
  description: string;
  title: string;
  timestamp: Date | string | number; //haven't decided yet
}

export interface BrandliveEvent {
  homepage: BrandliveHomepage;
  sessions: BrandliveSession[];
}

export enum Status {
	Deleted = 0,
	Published = 1,
	Unpublished = 2
}

//MySQL constraint - rather than just convert everything to booleans, just going to keep the types in sync
export enum Bool {
  off,
  on
}

export enum ArchiveStatus {
  active,
  archived
}

export enum CallToActionType {
  registration = "registration"
}
