import {
  CreateQuiz,
  Quiz,
  QuizQuestion,
  QuizQuestionOption
} from "../types/working-model";
import { Get, Put, Post } from "./helpers";

export function GetQuizzes(token: string) :Promise<Quiz[]>{
  return Get("/v3/admin/channel/quizzes", token);
}

export function InsertQuiz(
  token: string,
  quiz: CreateQuiz,
  languages?: string[]
) :Promise<any>{
  return Post("/v3/admin/channel/quizzes", token, { quiz, languages });
}

export function UpdateQuiz(
  token: string,
  quiz: Quiz,
  languages?: string[]
): Promise<Quiz> {
  return Put("/v3/admin/channel/quizzes", token, { quiz, languages }, true);
}

export function GetNewQuestion(token: string): Promise<QuizQuestion> {
  return Post("/v3/admin/channel/quizzes/question", token, {});
}

export function GetNewOption(token: string): Promise<QuizQuestionOption> {
  return Post("/v3/admin/channel/quizzes/question/option", token, {});
}
