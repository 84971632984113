import { ISocketCommentProps } from '../types/working-model';
import { GetHv, GetHvJson, HvHostMap } from './helpers';

export function GetChat(sessionUuid: string, lang: string, blProfile?: number): Promise<{ count: number, messages: ISocketCommentProps[] }> {
	const queryParam = blProfile ? `?bl_profile=${blProfile}` : '';
	return GetHv(HvHostMap.chat, `/e3-chat-messages/${sessionUuid}/${lang}${queryParam}`);
}

export type GetChatPaginatedProps = {
	sessionUuid: string;
	lang: string | null;
	blProfile?: number;
	cursor?: number;
	size?: number;
	moderator?: boolean;
	token?: string;
}
export function GetChatPaginated(props: GetChatPaginatedProps): Promise<{ count: number, messages: ISocketCommentProps[] }> {
	const { sessionUuid, lang, blProfile, cursor, size, moderator, token } = props;

	const queryParams = new URLSearchParams();
	if (blProfile) {
		queryParams.set('bl_profile', blProfile.toString());
	}

	if (cursor) {
		queryParams.set('cursor', cursor.toString());
	}

	if (size) {
		queryParams.set('size', size.toString());
	} else {
		queryParams.set('size', '100');
	}

	if (moderator) {
		queryParams.set('moderator', '1');
	}

	return GetHvJson({
		service: HvHostMap.chat,
		path: `/e3-chat-messages-paginated/${sessionUuid}/${lang}?${queryParams.toString()}`,
		headers: token ? {
			'bl-token': token
		} : undefined
	});
}