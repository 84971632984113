import React from "react";
import { Action } from "redux";
import { CreateChildren } from "../../../../../../connection/products";
import { updateHomepageModule } from "../../../../../../store/actions/admin/create-event";
import { updatePageModule, updatePageModuleAndSave } from "../../../../../../store/actions/admin/create-event/session";
import { loadProducts } from "../../../../../../store/actions/admin/products";
import { PageModule, Product } from "../../../../../../types/working-model";
import { toDict } from "../../../../../../utils/utils";
import { showAlert } from "../../../../../general-ui/alert/alert-service";

const getUnsavedIds = (productsToSave: number[], product: Product) => {
	if (product.module_id && !product.parent_id && !product.event_id) {
		productsToSave = [...productsToSave, product.module_id];
	}

	return productsToSave;
};

export const getProductsToSave = (products: Product[]): number[] => {
	return products.reduce<number[]>(getUnsavedIds, []);
};

const replaceWithChild = (updatedProductsDict: Record<number, Product>) => {
	return (product: Product) => {
		if (product.module_id && updatedProductsDict[product.module_id]) {
			return updatedProductsDict[product.module_id];
		}

		return product;
	};
};

export const replaceProductsWithChildren = (products: Product[], updatedProducts: { products: Product[] }) => {
	const updatedProductsDict = toDict('parent_id', updatedProducts.products);
	return products.map(replaceWithChild(updatedProductsDict));
};

type HandleCompleteSelectionParams = {
	token: string | null;
	channelId?: number;
	eventId?: number;
	page_module: PageModule;
	setSaving: (value: React.SetStateAction<boolean>) => void;
	products: Product[];
	dispatch: (action: Action) => void;
	isSession?: boolean;
}
export const handleCompleteSelection = async ({
	token,
	channelId,
	eventId,
	page_module,
	setSaving,
	products,
	dispatch,
	isSession,
}: HandleCompleteSelectionParams) => {
	try {
		if (!token || !channelId || !eventId) {
			throw new Error('Unable to select products when there is no event to edit.');
		}

		setSaving(true);

		const productsToSave = getProductsToSave(products);

		if (productsToSave.length) {
			const updatedProducts = await CreateChildren(token, productsToSave, channelId, eventId);
			products = replaceProductsWithChildren(products, updatedProducts);
		}

		if (isSession) {
			dispatch(
				updatePageModuleAndSave({
					...page_module,
					modules: products,
					content_modules: products.map((product) => product.product),
					is_edited: true,
				})
			);
		} else {
			dispatch(
				updateHomepageModule(
					{
						...page_module,
						modules: products,
						content_modules: products.map((product) => product.product)
					}
				)
			);
		}

		// fetch updated products list for redux store
		dispatch(loadProducts(token, channelId));

		return products;
	} catch (e) {
		console.error(e);
		showAlert({
			message: 'Unable to Save Products',
			description: "We ran into an issue adding these products. Please try again.",
			type: "error"
		});
	} finally {
		setSaving(false);
	}
};