import { PageModule, PageModuleType } from "../types/working-model";
import { Post } from "./helpers";

interface CreateSessionModuleProps {
	moduleType: PageModuleType;
	groupUuid?: string;
	languages: string[]; //LanguagesAbbr[]
	baseLanguage: string;
	sessionTitle: string;
	workingBlankModule?: PageModule
}
export function CreateSessionModule(
	token: string,
	session: number,
	{
		groupUuid,
		moduleType,
		languages,
		baseLanguage,
		sessionTitle,
		workingBlankModule
	}: CreateSessionModuleProps): Promise<any> {
	return Post(`/v3/admin/events/session/module/${session}`, token, { groupUuid, moduleType, languages, sessionTitle, baseLanguage, workingBlankModule });
}
