import { CallToAction, CallToActionType, LanguagesAbbr, PageLinkType } from "../../../types/working-model";
import { updateTranslateKey } from "../../../utils/utils";
import TextInput from "../text-input/text";
import './call-to-action.scss';

interface CallToActionProps {
	workingCTA?: CallToAction;
	onWorkingCTAChange: (workingCallToAction: CallToAction) => void;
	useFocusClick?: boolean;
	language: LanguagesAbbr;
	baseLanguage: LanguagesAbbr;
	ctaTypeOptions?: CallToActionType[];
	isHomepage?: boolean;
}

const initRegistrationCTA: CallToAction = {
	type: CallToActionType.page,
	button_name: {
		base: "Register",
		changed: ""
	},
	content: { page: PageLinkType.registration },
};

const CallToActionFormRegistration: React.FC<CallToActionProps> = ({
	workingCTA,
	onWorkingCTAChange,
	useFocusClick,
	language,
	baseLanguage,
}) => {
	if (!workingCTA) {
		workingCTA = initRegistrationCTA;
	}

	function handleNameChange({ target }: React.ChangeEvent<HTMLInputElement>) {
		if (workingCTA) {
			onWorkingCTAChange({
				...workingCTA,
				button_name: updateTranslateKey({
					translateString: workingCTA?.button_name,
					input: target.value,
					language,
					baseLanguage
				})
			});
		}
	}

	return (
		<TextInput
			id="button-text"
			label="Button text"
			placeholder={"Enter button text"}
			defaultValue={workingCTA?.button_name?.[language] as string || workingCTA?.button_name?.base || ''}
			onChange={handleNameChange}
			useFocusClick={useFocusClick}
			isAdmin
		/>
	);
};

export default CallToActionFormRegistration;
