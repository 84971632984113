import React from 'react';
import classNames from 'classnames';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import CustomCheckbox from '../../../general-ui/checkbox/custom-checkbox';
import { MarketingOptInTypes } from '../../../../types/working-model';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';

export function OptInCheckBoxes({
	isMarketingOptInChecked,
	setIsMarketingOptInChecked,
	template,
	soldOut,
}: {
	isMarketingOptInChecked: boolean;
	setIsMarketingOptInChecked: React.Dispatch<React.SetStateAction<boolean>>;
	soldOut: boolean;
	template: string;
}) {
	const { t } = useTranslate(['homepage', 'registrations']);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const registrationStep = useTypedSelector(state => state.LiveEventReducer.registrationStep);
	const marketingOptInType = eventBundle?.registration_settings?.marketing_email_options;
	const isMarketingOptInVisible = marketingOptInType === MarketingOptInTypes.singleOptIn || marketingOptInType === MarketingOptInTypes.doubleOptIn;

	const isSingleSignOn = !!eventBundle?.registration_settings?.singleSignOn?.isOn;

	return registrationStep === 0 && isMarketingOptInVisible && !isSingleSignOn ? (
		<div className={classNames('evt-field-wrapper', template)} id={`registration-field-single_opt_in`}>
			<CustomCheckbox
				key={'single_opt_in'}
				label={t(`registrations:question.opt.in`, t(`registration.registration_fields.single_opt_in`))}
				name={'single_opt_in'}
				disabled={soldOut}
				required={false}
				onChange={setIsMarketingOptInChecked}
				defaultValue={false}
				checked={isMarketingOptInChecked}
				useMarkdown
			/>
		</div>
	) : null;
}
