import classNames from 'classnames';

import Icon, { COLORS, ICONS } from "../../../../general-ui/icon";
import { SurveyType } from "../../../../../types/working-model";
import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { useIsNewModuleGrouping } from "../../../../../hooks/session.hooks";

interface ISurveyOptionProps {
	selected: boolean;
	percentage: number | null;
	value: string | number;
	onClick: (value: string | number) => void;
	title: string;
	disabled?: boolean;
	surveyType?: SurveyType;
	isCorrect?: boolean;
}

const SurveyOption: React.FC<ISurveyOptionProps> = ({ title, selected, value, percentage, onClick, disabled, surveyType, isCorrect }) => {
	const { t } = useTranslate("session");

	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const isQuiz = (surveyType == SurveyType.quiz);

	const handleClick = () => {
		if (!disabled)
			onClick(value);
	};

	return (
		<div className={classNames('survey-option-container')}>
			<button
				className={classNames('no-style clear survey-option focus-visible', {
					disabled: disabled && isModuleGroupingV2,
					'selected-incorrect': !isCorrect && selected && disabled && isModuleGroupingV2 && isQuiz,
					correct: isCorrect && disabled && isModuleGroupingV2 && isQuiz,
				})}
				onClick={handleClick}
			>
				{surveyType !== SurveyType.quiz &&
					<div className="survey-option-progress-bar-container">
						<div className="survey-option-progress-bar" style={percentage !== null ? { width: `${percentage}%` } : { width: 0 }} />
					</div>
				}

				<div className={classNames('survey-option-content', { 'long-title-answered': disabled && selected && title.length > 30 })}>
					<div className="survey-option-title">{title}</div>
					<div className={classNames({ 'survey-option-selected-answer-container': selected })}>
						{surveyType !== SurveyType.quiz ? (
							<div className={classNames("survey-option-check", { disabled })}>
								<div style={{ visibility: selected ? 'visible' : 'hidden' }}>
									{t('session:userSurvey.yourAnswer')}
								</div>
								<div className="survey-option-percentage-text">{percentage !== null ? `${percentage}%` : ''}</div>
							</div>
						) : (
							disabled &&
							<div className="survey-option-check-quiz">
								{selected &&
									<div className="survey-option-pill">
										{t('session:userSurvey.yourAnswer')}
									</div>
								}
								{isCorrect ?
									<Icon name={ICONS.CHECK_CIRCLE_FILLED} color={COLORS.GREEN} size={16} />
									: <Icon name={ICONS.ADD_CLOSE_CIRCLE} color={COLORS.RED} size={16} />
								}
							</div>
						)}
					</div>
				</div>
			</button>
		</div>
	);
};

export default SurveyOption;
