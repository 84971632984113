import { useEffect, useContext } from "react";
import { broadcastSignal } from "../../../../../../utils/event-emitter";
import { Actions, VideoStateContext } from "../../session-stream-provider";

const useKeyboardShortcuts = (handleBackTen: () => void, handleFwdTen: () => void) => {
	const { state: { muted, playing }, dispatch } = useContext(VideoStateContext);
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const keyName = event.key;
			const activeNodeName = document?.activeElement?.nodeName;
			const activeClassList = document?.activeElement?.classList;

			if (activeNodeName === 'BODY' || activeClassList?.contains?.('control-button')) {
				// mute on letter "m"
				if (keyName === 'm') {
					dispatch({ type: Actions.SetMuted, payload: !muted });
				}

				// fullscreen on letter "f"
				if (keyName === 'f') {
					broadcastSignal('toggle-fullscreen');
				}

				// toggle playing on spacebar
				if (keyName === ' ') {
					event.preventDefault(); // prevent scroll down
					dispatch({ type: playing ? Actions.Pause : Actions.Play, payload: undefined });
				}
			}
		};

		const handleKey = (e: KeyboardEvent) => {
			const keyName = e.key;
			const activeNodeName = document?.activeElement?.nodeName;
			const activeClassList = document?.activeElement?.classList;

			if (activeNodeName === 'BODY' || activeClassList?.contains?.('control-button')) {
				if (keyName === 'ArrowLeft') {
					handleBackTen();
				}

				if (keyName === 'ArrowRight') {
					handleFwdTen();
				}
			}
		};

		document.addEventListener('keypress', handleKeyDown);
		document.addEventListener('keydown', handleKey);

		return () => {
			document.removeEventListener('keypress', handleKeyDown);
			document.removeEventListener('keydown', handleKey);
		};
	}, [dispatch, muted, playing, handleBackTen, handleFwdTen]);
};

export default useKeyboardShortcuts;