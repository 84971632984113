import { getElementTransformValues } from "@admin/utils/utils";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { addEventListener, broadcastEvent, removeEventListener } from "utils/event-emitter";

export const useTrackEditorScale = (editorRef: React.RefObject<HTMLDivElement>) => {

	useEffect(() => {
		const editor = editorRef.current;
		let timeout: NodeJS.Timeout | null = null;

		if (editor) {

			const getScale = () => {
				timeout = setTimeout(() => {
					const _scale = getElementTransformValues(editor)?.scaleX ?? 1;

					broadcastEvent('editor-scale', _scale);
				}, 750); // animation transition duration is 0.7s - wait until after animation finishes
			};

			editor.addEventListener("mouseenter", getScale);

			return () => {
				editor.removeEventListener("mouseenter", getScale);
				if (timeout) {
					clearTimeout(timeout);
				}
			};
		}
	}, [editorRef]);
};

export const useGetEditorScale = () => {
	const [scale, setScale] = useState(1);

	useEffect(() => {
		addEventListener('editor-scale', setScale);

		return () => {
			removeEventListener('editor-scale', setScale);
		};
	}, []);

	return scale;
};


type UseSetDisabledInteractionsReturn = { disabledInteractions: boolean, setDisabledInteractions: React.Dispatch<React.SetStateAction<boolean>> };

export const useSetDisabledInteractions = (): UseSetDisabledInteractionsReturn => {
	const [disabledInteractions, setDisabledInteractions] = useState(false);
	const savingEvent = useTypedSelector(state => state.CreateEventReducer.saving);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		if (savingEvent && disabledInteractions === false) {
			setDisabledInteractions(true);
		}

		// Wait to check for savingEvent because it takes a moment for the save to take effect
		// Otherwise the save will start in the middle of another drag event
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
			if (!savingEvent) {
				setDisabledInteractions(false);
			}
		}, 1000);

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};

	}, [disabledInteractions, savingEvent]);

	return { disabledInteractions, setDisabledInteractions };
};
