import { useCallback, useEffect, useState } from "react";

import Portal from "../../../utils/react-portal";
import WaitingIndicator from "../waiting-indicator/waiting-indicator";

import "./lightbox.scss";

interface IProps {
	imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
}

const Lightbox: React.FC<IProps> = ({ imgProps }) => {
	const [open, setOpen] = useState(false);
	const [showLightbox, setShowLightbox] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const handleClick = useCallback(() => {
		if (open) {
			document.body.classList.remove("lightbox-no-scroll");
			setShowLightbox(false);
			setTimeout(() => {
				setOpen(false);
			}, 200);
		} else {
			document.body.classList.add("lightbox-no-scroll");
			setShowLightbox(true);
			setOpen(true);
		}
	}, [open]);

	useEffect(() => {
		const handelKeyDown = (e: any) => {
			if ((open || showLightbox) && e.key === "Escape") {
				handleClick();
			}
		};

		document.addEventListener("keydown", handelKeyDown);

		return () => {
			document.removeEventListener("keydown", handelKeyDown);
		};
	}, [open, showLightbox, handleClick]);

	const { style, ...rest } = imgProps;

	return (
		<>
			<button
				className="lightbox-trigger-btn"
				onClick={handleClick}
			>
				<img
					className="lightbox-default-img"
					src={imgProps.src}
					alt={imgProps.alt}
					style={{ ...style }}
					loading="lazy"
					{...rest}
				/>
			</button>

			<Portal className="lightbox">
				<div
					className="lightbox-modal-container"
					style={{
						visibility: open || showLightbox ? "visible" : "hidden",
						zIndex: open || showLightbox ? 120 : -1, // 120 so that it covers the side nav
						opacity: showLightbox ? "1" : "0",
					}}
					onClick={handleClick}
				>
					<div
						className="lightbox-overlay"
						style={{
							opacity: showLightbox ? "1" : "0"
						}}
					/>

					{loaded ? null : (
						<div
							className="lightbox-loading-indicator"
							style={{
								opacity: showLightbox ? "1" : "0",
								transform: open && showLightbox ? "scale(1)" : "scale(0.5)"
							}}
						>
							<WaitingIndicator />
						</div>
					)}
					<img
						onClick={handleClick}
						// don't render image unless open (to save user data)
						src={open || showLightbox ? imgProps.src : ""}
						alt={imgProps.alt}
						loading="lazy"
						onLoad={() => {
							setLoaded(true);
						}}
						className="lightbox-image"
						style={{
							opacity: showLightbox ? "1" : "0",
							transform: open && showLightbox ? "scale(1)" : "scale(0.5)"
						}}
					/>
				</div>
			</Portal>
		</>
	);
};

export default Lightbox;
