// not stored direct in table, derived from load/search
export type LeaderboardEntry = {
	bl_profile: number;
	bl_profile_uuid: string;
	profile: Record<number | string, string>; // { 1: "Adam", 2: "Denny", 3: "adam@brandlive.com", avatar: "https://i.imgur.com/dFQUip7.gif" }
	score: number;
	rank: number;
}

// multiply number of actions by this number per action
export type LeaderboardMultiplier = {
	event_id: number;
	action: LeaderboardActions,
	multiplier: number;
	is_on: boolean
}

export interface ILeaderboardSearchCriteria {
	page: number;
	search?: string;
	sq?: number // searchable registration question abbreviated to shorten params
	fq?: number; // registration question abbreviated to shorten params
	fv?: string; // registration question value abbreviated to shorten params
	track?: string;
}

// PG column is smallint - 128 is the max number scored actions we can create
export enum LeaderboardActions {
	registered,
	watched_minute,
	quiz_answer_correct
}

export type LeaderboardCustomScore = {
	score: number;
	action?: never;
	custom_action: string;
	multiplier: number;
	is_on: boolean; // always true
}

export type LeaderboardInternalScore = {
	score: number;
	action: LeaderboardActions;
	custom_action?: never;
	multiplier: number;
	is_on: boolean;
}

export type LeaderboardScoreForProfile = LeaderboardCustomScore | LeaderboardInternalScore;