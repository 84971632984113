import { GetPaidListMembers, GetPaidLists, SearchPaidListMembers } from "../../../connection/paid-lists";
import { Action, AsyncAction } from "../../../types/actions";
import { IPaidList, TicketSetMember } from "../../reducers/admin/paid-list-members";

export const GET_PAID_LISTS = "GET_PAID_LISTS";
export function getPaidLists(token: string, channel: number): AsyncAction {
	return {
		type: GET_PAID_LISTS,
		promise: GetPaidLists(token, channel)
	};
}

export const GET_PAID_LIST_MEMBERS = "GET_PAID_LIST_MEMBERS";
export function getPaidListMembers(token: string, paid_list: string, offset: number): AsyncAction {
	return {
		type: GET_PAID_LIST_MEMBERS,
		promise: GetPaidListMembers(token, paid_list, offset)
	};
}

export const SEARCH_PAID_LIST_MEMBERS = "SEARCH_PAID_LIST_MEMBERS";
export function searchPaidListMembers(token: string, paid_list: string, offset: number, term: string): AsyncAction {
	return {
		type: SEARCH_PAID_LIST_MEMBERS,
		promise: SearchPaidListMembers(token, paid_list, offset, term)
	};
}

export const ADD_PAID_LIST_MEMBER = "ADD_PAID_LIST_MEMBER";
export function addPaidListMember(paidListMember: TicketSetMember): Action {
	return {
		type: ADD_PAID_LIST_MEMBER,
		payload: paidListMember
	};
}

export const SET_SELECTED_PAID_LIST = "SET_SELECTED_PAID_LIST";
export function setSelectedPaidList(paidList: IPaidList | null): Action {
	return {
		type: SET_SELECTED_PAID_LIST,
		payload: paidList
	};
}