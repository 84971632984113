import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { getStorageItem } from "../../../utils/local-storage";

interface IProps {
	event: number | undefined;
}

const useRedirectLanguage = ({ event }: IProps): void => {
	const history = useHistory();
	const routerMatch = useRouteMatch<any>();

	// Use this hook on any url that requires language translations in live event (but NOT session view).
	// if the url language param does not match the users selected language, then change the url to
	// use their selected language. This is needed because if a user filters session languages and enters a session
	// in another language, the url is updated with the new language. However, if they then navigate out of that session
	// they are now viewing the event in the language that was only meant for the session view.
	useEffect(() => {
		if (event && routerMatch?.params?.language) {
			const storedLanguage = getStorageItem(`event.${event}.language`);
			if (storedLanguage && storedLanguage !== routerMatch?.params?.language) {
				// replace the language in the url with storedLanguage
				const updatedParams = { ...routerMatch.params };
				updatedParams.language = storedLanguage;
				let updatedUrl = routerMatch.path;
				for (const key in updatedParams) {
					updatedUrl = updatedUrl.replace(`:${key}`, updatedParams[key]);
				}
				history.push(updatedUrl);
			}
		}
	}, [
		routerMatch,
		event,
		history,
	]);
};

export default useRedirectLanguage;
