import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { TextAlignmentsEnum } from "../../../types/template-layouts";

import { OptionalComponent } from "../../../utils/optional-component";
import { MarkdownItem } from "../typography-item/typography-item";
import { replaceTextAlignAttribute } from "./replace-text-align-attribute";

interface IRenderEditorDescriptionProps {
	descriptionOverrides?: string;
	description: string;
	useNew?: boolean;
	defaultTextAlignment?: TextAlignmentsEnum;
	stopPropagation?: boolean;
}

const RenderEditorDescription: React.FC<IRenderEditorDescriptionProps> = ({
	description,
	descriptionOverrides,
	useNew = false,
	defaultTextAlignment = TextAlignmentsEnum.left,
	stopPropagation
}) => {

	const [shouldUseNew, setShouldUseNew] = useState(true);

	useEffect(() => {
		if (useNew) {
			setShouldUseNew(true);
		} else {
			setShouldUseNew(false);
		}
	}, [useNew]);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	// if content is straight text (not html), we need to wrap it in html and give it the appropriate text alignment
	const convertToHtml = useCallback(() => {
		if (useNew) {
			return replaceTextAlignAttribute({ htmlString: description, newValue: defaultTextAlignment, bypassIfNodeElement: true });
		}
		return description;
	}, [description, useNew, defaultTextAlignment]);

	return (
		<>
			<OptionalComponent display={shouldUseNew}>
				<div className="quill-content">
					<div
						className="session-description"
						dangerouslySetInnerHTML={{ __html: convertToHtml() }}
						// For some reason stopPropagation isn't working with conditionals inside the callback, only as a conditional around the entire function
						onClick={stopPropagation ? undefined : handleClick}
					/>
				</div>
			</OptionalComponent>

			<OptionalComponent display={!shouldUseNew}>
				<MarkdownItem className={classNames(descriptionOverrides)}>{description}</MarkdownItem>
			</OptionalComponent>
		</>
	);
};

export default RenderEditorDescription;
