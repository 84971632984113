import { AsyncAction } from "../../../types/actions";

import {
	GenerateMagicLinksForEvent,
	GenerateMagicLinksForList,
	GetMagicLinksForEvent,
	InvalidateMagicLinksForEvent,
} from "../../../connection/magic-links";
import { PasscodeListMemberType } from "../../../types/working-model";

export const GET_MAGIC_LINKS_FOR_EVENT = "GET_MAGIC_LINKS_FOR_EVENT";
export function getMagicLinksForEvent(token: string, channel: number, list_id: number, event_uuid: string): AsyncAction {
	return {
		type: GET_MAGIC_LINKS_FOR_EVENT,
		promise: GetMagicLinksForEvent(token, channel, list_id, event_uuid)
	};
}



export const GENERATE_MAGIC_LINKS_FOR_EVENT = "GENERATE_MAGIC_LINKS_FOR_EVENT";
export function generateMagicLinksForEvent(token: string, channel: number, list_id: number, event_uuid: string, selectedMembers?: PasscodeListMemberType[]): AsyncAction {
	return {
		type: GENERATE_MAGIC_LINKS_FOR_EVENT,
		promise: GenerateMagicLinksForEvent(token, channel, list_id, event_uuid, selectedMembers)
	};
}

export const GENERATE_MAGIC_LINKS_FOR_LIST = "GENERATE_MAGIC_LINKS_FOR_LIST";
export function generateMagicLinksForList(token: string, channel: number, list_id: number, eventReturnFilter?: string): AsyncAction {
	return {
		type: GENERATE_MAGIC_LINKS_FOR_LIST,
		promise: GenerateMagicLinksForList(token, channel, list_id, eventReturnFilter),
	};
}

export const INVALIDATE_MAGIC_LINKS_FOR_EVENT = "INVALIDATE_MAGIC_LINKS_FOR_EVENT";
export function invalidateMagicLinksForEvent(token: string, channel: number, list_id: number, event_uuid: string, member_ids?: number[]): AsyncAction {
	return {
		type: INVALIDATE_MAGIC_LINKS_FOR_EVENT,
		promise: InvalidateMagicLinksForEvent(token, channel, list_id, event_uuid, member_ids)
	};
}