import React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { useGetItemsCount, useIsNewNavigation } from '../../../../hooks/navigation.hooks';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { CustomPage, ENavbarTypes, LanguagesAbbr } from '../../../../types/working-model';
import NavigationDropdown from '../../../general-ui/dropdown/navigation-dropdown';
import { ParamsProps } from '../../live-event';
import ButtonOthers from './button-others';
import CustomNavigationItem from './custom-navigation-item';
import { useTranslate } from '../../../../i18n/useTranslationModules';

interface ICustomNavigationItemsProps {
	generalNavItemsCount: number;
	customNavItems?: CustomPage[],
	previewLanguage?: LanguagesAbbr;
	navItemActiveAccent: string;
	navItemColor: string;
	getCustomPagePath: (customPath: string) => string;
	isDesktop: boolean;
	forceIcon?: boolean;
	fullWidth?: boolean;
}

export default function CustomNavigationItems({
	generalNavItemsCount,
	customNavItems,
	previewLanguage,
	navItemActiveAccent,
	navItemColor,
	getCustomPagePath,
	isDesktop,
	forceIcon,
	fullWidth
}: ICustomNavigationItemsProps): JSX.Element | null {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const showNewNav = useIsNewNavigation();
	const { t } = useTranslate(['homepage', 'home']);
	const { customPath }: ParamsProps = useParams();

	const navbarType = eventBundle?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;

	const allNavItemsCount = generalNavItemsCount + (customNavItems?.length || 0);
	const {
		showItemsCount,
		hideItemsCount,
		isOthersButtonHidden
	} = useGetItemsCount({
		allNavItemsCount,
		customNavItems,
		generalNavItemsCount
	});

	if (!customNavItems?.length) return null;

	const isMobileView = showNewNav && !isDesktop;

	const isHiddenItemActive = hideItemsCount.some(({ path }) => path === customPath);

	if (!showNewNav || isMobileView || isOthersButtonHidden) {
		return (
			<CustomNavigationItem
				customNavItems={customNavItems}
				previewLanguage={previewLanguage}
				navItemActiveAccent={navItemActiveAccent}
				navItemColor={navItemColor}
				getCustomPagePath={getCustomPagePath}
				forceIcon={forceIcon}
				fullWidth={fullWidth}
			/>
		);
	}

	return (
		<>
			<CustomNavigationItem
				customNavItems={showItemsCount}
				previewLanguage={previewLanguage}
				navItemActiveAccent={navItemActiveAccent}
				navItemColor={navItemColor}
				getCustomPagePath={getCustomPagePath}
				forceIcon={forceIcon}
				fullWidth={fullWidth}
			/>

			<NavigationDropdown
				title={t('home.Others', 'Others')}
				edge={isTopNavbar ? 'left' : 'right'}
				className="header-nav-dropdown"
				buttonClassName={classNames('no-style no-padding navigation-link', { active: isHiddenItemActive })}
				renderCustomIcon={() => <ButtonOthers />}
			>
				<CustomNavigationItem
					customNavItems={hideItemsCount}
					previewLanguage={previewLanguage}
					navItemActiveAccent={navItemActiveAccent}
					navItemColor={navItemColor}
					getCustomPagePath={getCustomPagePath}
					forceIcon={forceIcon}
					fullWidth={fullWidth}
				/>
			</NavigationDropdown>
		</>
	);
}