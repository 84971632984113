import React, { useCallback, useEffect, useState } from 'react';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import useTimeout from '../../../../utils/use-timeout';

interface ISessionCountdownProps {
	timestamp: number | null;
	end_timestamp: number | null;
	replay_on?: boolean;
}

const SessionCountdown: React.FC<ISessionCountdownProps> = ({ timestamp, end_timestamp, replay_on = false }) => {
	const [countdown, setCountdown] = useState('');
	const { t } = useTranslate("session");

	const dateCountdown = useCallback(() => {
		if (timestamp) {

			const date = new Date(timestamp);
			const now = new Date();
			const end = end_timestamp ? new Date(end_timestamp) : date;
			const msBetween = date.valueOf() - now.valueOf();
			const day = 1000 * 60 * 60 * 24;
			const hour = 1000 * 60 * 60;
			const minute = 1000 * 60;
			const second = 1000;

			let specialString = '';
			if (msBetween > 0) {
				if (msBetween > day) {
					const daysRemaining = Math.round(msBetween / day);
					const desc = daysRemaining === 1 ? "day" : "days";
					specialString = `${t("Live in")} ${daysRemaining} ${t(desc)}`;
					// Live in 1 day OR Live in 2 days
				} else if (msBetween > hour) {
					// round down here so we only have the hours, then round down on the minutes
					const hoursRemaining = Math.floor(msBetween / hour);
					const minsRemaining = Math.floor(msBetween / minute) - (hoursRemaining * 60);

					const hourStr = hoursRemaining === 1 ? "hour" : "hours";
					const minStr = minsRemaining === 1 ? "minute" : "minutes";

					specialString = `${t("Live in")} ${hoursRemaining} ${t(hourStr)} ${minsRemaining} ${t(minStr)}`;
					// Live in 1 hour 30 minutes
				} else if (msBetween > minute) {
					const minsRemaining = Math.round(msBetween / minute);
					const desc = minsRemaining === 1 ? "minute" : "minutes";
					specialString = `${t("Live in")} ${minsRemaining} ${t(desc)}`;
					// Live in 1 minute
				} else {

					specialString = `${t("Live in")} ${Math.round(msBetween / second)} ${t("seconds")}`;
					// Live in 30 seconds
				}
			} else {
				if (now.valueOf() < end.valueOf()) { //if the session hasn't ended
					specialString = t("Video available shortly");
					// Video available shortly
				} else if (now.valueOf() > end.valueOf() && replay_on) { // if session ended and replay on
					specialString = t("Your replay will be available shortly");
					// Your replay will be available shortly
				} else if (now.valueOf() > end.valueOf() && !replay_on) { // if session ended and replay off
					specialString = t("Session stream completed");
					// Session stream completed
				} else if (msBetween < -day) {
					const daysPast = Math.round(msBetween / -day);
					const desc = daysPast === 1 ? "day ago" : "days ago";
					specialString = `${t("Live")} ${daysPast} ${t(desc)}`;
					// Live 1 day ago OR Live 2 days ago
				} else if (msBetween < -hour) {
					const hoursPast = Math.round(msBetween / -hour);
					const desc = hoursPast === 1 ? "hour ago" : "hours ago";
					// Live 1 hour ago OR Live 2 hours ago
					specialString = `${t("Live")} ${hoursPast} ${t(desc)}`;
				} else if (msBetween < -minute) {
					const minsPast = Math.round(msBetween / -minute);
					const desc = minsPast === 1 ? "minute ago" : "minutes ago";
					specialString = `${t("Live")} ${minsPast} ${t(desc)}`;
					// Live 1 minute ago
				} else {
					specialString = `${t("Live")} ${Math.round(msBetween / -second)} ${t("seconds ago")}`;
					// Live 30 seconds ago
				}
			}
			setCountdown(specialString);
		}
	}, [timestamp, t, end_timestamp, replay_on]);

	const { runWithTimeout } = useTimeout(() => {
		dateCountdown();
		runWithTimeout();
	}, 1000);

	const isEnd = useCallback(() => {
		if (timestamp) {
			const today = new Date();
			const date = new Date(timestamp);
			const msBetween = date.valueOf() - today.valueOf();
			return msBetween <= 0;
		}
		return true;
	}, [timestamp]);

	useEffect(() => {
		dateCountdown();

		if (!isEnd()) {
			runWithTimeout();
		}
	}, [dateCountdown, isEnd, runWithTimeout, timestamp]);
	return <h3>{countdown}</h3>;
};

export default SessionCountdown;
