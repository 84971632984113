import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

// HOOKS
import { useActiveModulesInGroup, useIsAboveTheFold, useIsBelowTheFold } from '../../../hooks/session.hooks';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { useScreenMediaQuery } from '../../../utils/use-screen-media-query';
import useTranslationRoute from '../hooks/use-translation-route';
import { useSessionDetailsV2 } from '../../../hooks/channel.hooks';

// TYPES
import { LanguagesAbbr, ModuleGroups, PageModuleGroupModules, Session, TranslateString } from '../../../types/working-model';

// COMPONENTS
import Icon from '../../general-ui/icon';
// import EngageCount from './above-the-fold/engage-count';
import { moduleRequiresContentInV2 } from "./session-utils";

type Props = {
	session: Session;
	group: PageModuleGroupModules;
	moduleGroupsRefs: React.RefObject<HTMLParagraphElement>[];
	setAutoScroll: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedTabUuid: React.Dispatch<React.SetStateAction<string | undefined>>;
	selectedTabUuid: string | undefined;
	index: number;
	handleTabChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	showIcons: boolean;
	language: LanguagesAbbr;
}

const GroupedNavButton = (props: Props) => {
	const { session, group, moduleGroupsRefs, setAutoScroll, setSelectedTabUuid, selectedTabUuid, index, handleTabChange, showIcons, language } = props;
	const { name, uuid, icon, is_on, modules, type } = group;

	const activeModules = useActiveModulesInGroup(session, group);
	const isSessionDetailsBelowTheFoldV2 = useIsBelowTheFold(session);
	const isSessionDetailsAboveTheFoldV2 = useIsAboveTheFold(session);
	const translationNamespace = useTranslationRoute();
	const { t } = useTranslate(["session", translationNamespace]);
	const { isLessThan640 } = useScreenMediaQuery();
	const isMobile = isLessThan640;
	const isSessionContentV2 = isSessionDetailsAboveTheFoldV2 || isSessionDetailsBelowTheFoldV2;
	const sessionDetailsV2FeatureFlagOn = useSessionDetailsV2();
	const itemRef = useRef<HTMLLIElement>(null);

	const belowTheFoldNavigate = (moduleGroup: PageModuleGroupModules, idName: string, index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
		const container = moduleGroupsRefs[index];

		if (container?.current) {
			setAutoScroll(true);
			setSelectedTabUuid(moduleGroup.uuid);

			const videoContainerHeight = document.querySelector('.session-stream-container-inner')?.clientHeight || 1;

			//NOTE: Offsets the scroll by the height of the fixed module tabs title visible
			const mobileHeaderAndChatOffset = 130;
			const mobileTopOffset = isMobile && (videoContainerHeight + mobileHeaderAndChatOffset);
			const desktopAndTabletTopOffset = 85;
			const moduleTopOffset = mobileTopOffset || desktopAndTabletTopOffset;
			const rect = container.current.getBoundingClientRect();

			requestAnimationFrame(() => {
				window.scrollTo({
					top: rect.top + window.scrollY - moduleTopOffset,
					behavior: 'smooth'
				});
			});
		}
	};

	//If there is no content in the module don't make the tab
	const _modules = session.modules.filter(module => module?.id && modules.includes(module.id) && module.is_on);
	const moduleGroup = session?.module_grouping?.find(group => group.uuid === uuid);
	const hasContent = _modules.some(_module => !(moduleRequiresContentInV2[_module.type] && isSessionContentV2) || !!_module.modules?.length || !!_module.content_modules?.length);

	let _name: string;
	//Check to see if "_name" is a Translate string object, if so return the translated value, if not, then it is already a string. Use static translation if available or return the string
	const translateName = name;

	if (typeof translateName === 'string') {
		_name = t(name as string) ?? name;
	}
	else {
		_name = t(`${translationNamespace}:tabName.${translateName.base}`, translateName[language] as string ?? translateName.base);
	}

	const isActive = selectedTabUuid
		? uuid === selectedTabUuid
		: index === 0;

	const isBelowTheFoldScroll = sessionDetailsV2FeatureFlagOn && (isSessionDetailsBelowTheFoldV2 || isSessionDetailsAboveTheFoldV2) && moduleGroup;

	useEffect(() => {
		// whenever this button becomes active, check if it is fully visible within the left-to-right scroll container
		if (isBelowTheFoldScroll && isActive) {
			// NOTE: if we add another wrapping element to the tabs, this will need to be updated
			const scrollParent = itemRef.current?.parentElement;
			const item = itemRef.current;

			// after the next paint
			requestAnimationFrame(() => {
				if (scrollParent && item) {
					const isInvisibleToLeft = item.offsetLeft < scrollParent.scrollLeft;
					const isInvisibleToRight = item.offsetLeft + item.offsetWidth > scrollParent.scrollLeft + scrollParent.clientWidth;

					// NOTE: using parent scrollTo rather than element.scrollIntoView because element.scrollIntoView
					// awaits any other page scrolls going on, particularly if the page is scrolling to the target element
					// when the user has tapped a button. Because we can't await any callback from scrollIntoView or window.scrollTo
					// we can't guarantee that the user won't scroll the page before the scrollIntoView has finished, which means
					// the page will scroll to the wrong place. Using parent.scrollTo allows us to set the scroll position immediately.

					// scroll to left edge of the button if it is scrolled off to the left
					if (isInvisibleToLeft) {
						scrollParent.scrollTo({
							left: item.offsetLeft - 20,
							behavior: 'smooth'
						});
					}

					// scroll to right edge of the button if it is scrolled off to the right
					else if (isInvisibleToRight) {
						scrollParent.scrollTo({
							left: (item.offsetLeft - scrollParent.clientWidth) + (item.offsetWidth + 20),
							behavior: 'smooth'
						});
					}

					// otherwise, the button is somewhere in the middle of the view, do nothing. 
				}
			});
		}
	}, [isActive, isBelowTheFoldScroll]);

	// Breakout Rooms don't have modules.length so add check for BRs here too
	if (!is_on || (!modules.length && (name as TranslateString).base !== ModuleGroups.BreakoutRooms)) return null;
	if (!hasContent && type !== "breakout") return null;

	if (isBelowTheFoldScroll) {
		return (
			<li key={uuid}
				ref={itemRef}
				data-name={_name}
				className={classNames({
					active: isActive
				})}
			>
				<button
					onClick={belowTheFoldNavigate(moduleGroup, _name, index)}
					className={classNames('clear no-style')}
				>
					<span>{_name}</span>
					{/* REMOVING 10/10/2023 based on comment from will but may return */}
					{/* <EngageCount group={moduleGroup} session={session} /> */}
				</button>
			</li>
		);
	}
	return (
		<li key={uuid}>
			<input
				type="radio"
				name="tab-navigation"
				id={`session-${uuid}`}
				value={uuid}
				onChange={handleTabChange}
				checked={uuid === selectedTabUuid}
			/>
			<label htmlFor={`session-${uuid}`}>
				<Icon name={icon || ""} size={16} color={classNames({ noIcon: !showIcons })} />
				<span>{_name}</span>
			</label>
		</li>
	);
};

export default GroupedNavButton;