import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { GetEngagementsTotals } from "../../../../../../../connection/sessions-panel/actions";

import { GetNewOption, GetNewQuestion, InsertSurvey, UpdateSurvey } from "../../../../../../../connection/surveys";
import { updatePageModule, updatePageModuleAndSave } from "../../../../../../../store/actions/admin/create-event/session";
import { addSurvey as dispatchAddSurvey, updateSurvey } from "../../../../../../../store/actions/admin/surveys";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import {
	CreateSurvey, LanguagesAbbr, PageModule, PageModuleType, SessionPanelLayoutsTypes,
	Survey,
	SurveyQuestion,
	SurveyQuestionType,
	SurveyType,
	SurveyTypeDisplayNames,
} from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { updateTranslateKey } from "../../../../../../../utils/utils";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import { ParamsProps } from "../../../../../../live-event/live-event";
import { useFinishNavigate, usePageModule } from "../../panel/hooks/panel.hooks";
import { SessionPanelMap } from "../../panel/session-panel-route-map";
import { ErrorsTypes } from "../types/engage";
import { validateQuestion } from "../utils/engage-validate.utils";
import { GetDefaultTranslateString } from "store/utils/create-event";

interface IUseAddQuestion {
	initiate: boolean;
	isQuiz: boolean;
	workingQuestion: SurveyQuestion | null;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>
	setError: (error: { [key: string]: string }) => void;
}

export const useAddQuestion = ({
	initiate,
	isQuiz,
	workingQuestion,
	setWorkingQuestion,
	setWorkingSurvey,
	setError,
}: IUseAddQuestion): (() => Promise<void>) => {
	const token = useTypedSelector(state => state.AuthReducer.token);

	return async () => {
		if (!token) return;

		if (!initiate) {
			const questionErrors = validateQuestion({ question: workingQuestion, setError, isQuiz });

			if (workingQuestion && Object.keys(questionErrors).length) {
				if (questionErrors?.[ErrorsTypes.NotSavedQuestion]) {
					showAlert({
						message: questionErrors?.[ErrorsTypes.NotSavedQuestion],
						type: "error",
					});
				}

				return;
			}
		}

		try {
			const question = await GetNewQuestion(token);
			const option = await GetNewOption(token);

			setWorkingSurvey((workingSurvey) => {
				if (!workingSurvey) return null;

				if (workingSurvey.survey_type !== SurveyType.survey) {
					question.question_type = SurveyQuestionType.radio;
					question.options = [option];
				}

				setWorkingQuestion(question);

				return {
					...workingSurvey,
					questions: [...workingSurvey.questions, question],
				};
			});
		} catch (e) {
			showAlert({
				message: "Problem create question",
				type: "error",
			});
			console.error(e);
		}
	};
};

interface IUseAddOption {
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
	clearErrors: (key: (ErrorsTypes | null)) => void;
}

export const useAddOption = ({
	setWorkingQuestion,
	clearErrors,
}: IUseAddOption): (() => Promise<void>) => {
	const token = useTypedSelector(state => state.AuthReducer.token);

	return async () => {
		if (!token) return;

		clearErrors(ErrorsTypes.QuestionOptions);
		clearErrors(ErrorsTypes.QuestionOptionText);

		try {
			const newOption = await GetNewOption(token);

			setWorkingQuestion((question) => {
				if (!question) return null;

				return {
					...question,
					options: [...question.options, newOption],
				};
			});
		} catch (e) {
			showAlert({
				message: "Problem create option",
				type: "error",
			});
			console.error(e);
		}
	};
};

interface IUseSaveSurvey {
	page_module?: PageModule;
	workingSurvey: Survey | CreateSurvey | null;
	isQuiz: boolean;
	validateQuestionHandler: () => { [key: string]: string };
	validateSurveyHandler: () => { [key: string]: string };
	setSaving: (saving: boolean) => void;
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>;
}

export const useSaveSurvey = ({
	page_module,
	workingSurvey,
	isQuiz,
	validateQuestionHandler,
	validateSurveyHandler,
	setSaving,
	setWorkingSurvey,
}: IUseSaveSurvey): (() => Promise<void>) => {
	const dispatch = useDispatch();
	const token = useTypedSelector(state => state.AuthReducer.token);
	const finish = useFinishNavigate();
	const adminPath = useGetAdminUrl();
	const ePageModule = usePageModule();

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const baseLanguage = (workingSession ? workingSession?.default_language : workingEvent?.settings.i18n?.base) as LanguagesAbbr;
	const set = new Set(workingEvent?.sessions?.flatMap(session => session.languages));
	const allLanguages = Array.from(set) as LanguagesAbbr[];

	return async () => {
		if (!workingSurvey || !token || !page_module) return;

		const survey = { ...workingSurvey };

		const questionErrors = validateQuestionHandler();
		const surveyErrors = validateSurveyHandler();

		if (Object.keys(questionErrors).length || Object.keys(surveyErrors).length) {
			if (surveyErrors?.[ErrorsTypes.SurveyWithoutQuestions]) {
				showAlert({
					message: surveyErrors?.[ErrorsTypes.SurveyWithoutQuestions],
					type: "error",
				});
			}

			if (questionErrors?.[ErrorsTypes.NotSavedQuestion]) {
				showAlert({
					message: questionErrors?.[ErrorsTypes.NotSavedQuestion],
					type: "error",
				});
			}

			return;
		}

		setSaving(true);

		try {
			if ('survey' in survey) {
				if (!ePageModule) return;

				const updatedSurvey = await UpdateSurvey(token, survey, allLanguages, baseLanguage);
				dispatch(updateSurvey(updatedSurvey));
				dispatch(updatePageModuleAndSave({
					...ePageModule,
					modules: ePageModule.modules?.map((pm: any) => pm.module_id === updatedSurvey.module_id ? updatedSurvey : pm),
					is_edited: true,
				}));
				setWorkingSurvey(updatedSurvey);
			} else {
				if (!ePageModule) return;

				const surveyToSave: CreateSurvey = {
					...survey,
					label: updateTranslateKey({
						translateString: GetDefaultTranslateString(),
						input: SurveyTypeDisplayNames[workingSurvey.survey_type || SurveyType.survey],
						baseLanguage,
						language: baseLanguage,
					}),
					misc_translations: {
						completed: updateTranslateKey({
							translateString: GetDefaultTranslateString(),
							input: "Completed",
							baseLanguage,
							language: baseLanguage,
						}),
						not_started: updateTranslateKey({
							translateString: GetDefaultTranslateString(),
							input: "Not started",
							baseLanguage,
							language: baseLanguage,
						}),
					}
				};

				const newSurvey = await InsertSurvey(
					token,
					surveyToSave,
					allLanguages,
					baseLanguage,
				);

				dispatch(dispatchAddSurvey(newSurvey));

				const mod = {
					...ePageModule,
					content_modules: [newSurvey.survey],
					modules: [newSurvey],
					type: PageModuleType.survey
				};

				dispatch(updatePageModuleAndSave(mod));
			}

			setSaving(false);
			finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
		} catch (e) {
			showAlert({
				message: "Problem create survey",
				type: "error",
			});
			console.error(e);
		}
	};
};

interface IUseSetOptionLabel {
	clearErrors: (key: (ErrorsTypes | null)) => void;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
}

export const useSetOptionLabel = ({
	clearErrors,
	setWorkingQuestion,
}: IUseSetOptionLabel): ((index: number) => (({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void)) => {
	const { language } = useParams<ParamsProps>();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const baseLanguage = (workingSession ? workingSession?.default_language : workingEvent?.settings.i18n?.base) as LanguagesAbbr;

	return (index: number) => {
		return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			clearErrors(ErrorsTypes.QuestionOptionText);
			setWorkingQuestion((question) => {
				if (!question) return null;

				return {
					...question,
					options: question.options.map((option, _index) => {
						if (_index === index) {
							option.label = updateTranslateKey({
								translateString: option.label,
								input: value,
								language,
								baseLanguage
							});
						}
						return option;
					}),
				};
			});
		};
	};
};

interface IUseDeleteQuestion {
	workingSurvey: Survey | CreateSurvey | null;
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
	clearErrors: (key: (ErrorsTypes | null)) => void;
}

export const useDeleteQuestion = ({
	workingSurvey,
	setWorkingSurvey,
	setWorkingQuestion,
	clearErrors,
}: IUseDeleteQuestion): ((question: SurveyQuestion) => () => Promise<void>) => {
	const dispatch = useDispatch();
	const token = useTypedSelector(state => state.AuthReducer.token);

	return (question: SurveyQuestion) => async () => {
		if (!workingSurvey?.questions?.length) return;
		if ('survey' in workingSurvey) {
			const updatedWorkingSurvey: Survey = {
				...workingSurvey,
				questions: workingSurvey.questions.filter(qn => qn.survey_question !== question.survey_question),
			};
			try {
				if (!token) return;
				const updatedSurvey = await UpdateSurvey(token, updatedWorkingSurvey);
				dispatch(updateSurvey(updatedSurvey));
				setWorkingSurvey(updatedSurvey);
			} catch (e: any) {
				showAlert({
					message: "Problem delete survey",
					type: "error",
				});
				console.error(e?.response || e);
			}
		} else {
			setWorkingSurvey({
				...workingSurvey,
				questions: workingSurvey.questions.filter(qn => qn.survey_question !== question.survey_question),
			});
		}

		setWorkingQuestion(null);
		clearErrors(null);
	};
};

interface IUseSetQuestionTitle {
	clearErrors: (key: (ErrorsTypes | null)) => void;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
}

export const useSetQuestionTitle = ({
	clearErrors,
	setWorkingQuestion,
}: IUseSetQuestionTitle): (({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void) => {
	const { language } = useParams<ParamsProps>();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const baseLanguage = (workingSession ? workingSession?.default_language : workingEvent?.settings.i18n?.base) as LanguagesAbbr;

	return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors(ErrorsTypes.QuestionTitle);

		setWorkingQuestion((question) => {
			if (!question) return null;

			const title = updateTranslateKey({
				translateString: question.title,
				input: value,
				baseLanguage,
				language
			});

			return { ...question, title };
		});
	};
};

export type TTranslateKey = 'name' | 'description' | 'completion_message';
type TUseSetWorkingSurveyTransKey = (setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>) => (key: TTranslateKey, value: string) => void;
export const useSetWorkingSurveyTransKey: TUseSetWorkingSurveyTransKey = (setWorkingSurvey) => {
	const { language } = useParams<ParamsProps>();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const baseLanguage = (workingSession ? workingSession?.default_language : workingEvent?.settings.i18n?.base) as LanguagesAbbr;

	return (key: TTranslateKey, value: string) => {
		setWorkingSurvey((workingSurvey) => {
			if (!workingSurvey) return null;

			return {
				...workingSurvey,
				[key]: updateTranslateKey({
					translateString: workingSurvey[key],
					input: value,
					baseLanguage,
					language
				}),
			};
		});
	};
};

export const useEngagementTotals = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const sessionUuid = workingSession?.uuid;
	const [engagementMap, setEngagementMap] = useState<Map<number, number>>(new Map<number, number>());

	useEffect(() => {
		if (sessionUuid && token) {
			GetEngagementsTotals({ sessionUuid, token }).then(res => {
				setEngagementMap(new Map(res.map(row => [row.module_id, row.responses])));
			}).catch(e => {
				console.error(e);
				setEngagementMap(new Map());
			});
		}
	}, [sessionUuid, token]);

	return engagementMap;
};
