import classNames from 'classnames';
import { useState, useEffect } from 'react';

import { useTranslate } from '../../../i18n/useTranslationModules';
import { LanguagesAbbr } from '../../../types/working-model';
import TranslateLangs from '../../../utils/translate-langs.json';
import { useScreenMediaQuery } from '../../../utils/use-screen-media-query';
import NewDropdown from '../dropdown/new-dropdown';
import { TypographyItem } from '../typography-item/typography-item';
import '../../../scss/live-event/base/general-ui/language-dropdown.scss';

export interface ILang {
	code: LanguagesAbbr;
	label: string;
}

export const ALL_LANGS: ILang[] = TranslateLangs as any;

interface LanguageDropdownProps {
	currentLanguageCode?: LanguagesAbbr;
	onLanguageChange?: (lang: LanguagesAbbr) => void;
	languages?: LanguagesAbbr[];
	className?: string;
	template?: string;
	verticalPosition?: 'top' | 'bottom';
	edge?: 'left' | 'right';
	top?: boolean;
	useISOLangCodes?: boolean;
	isNoScroll?: boolean;
	animateDropdown?: boolean;
}

export const LanguageDropdown = ({
	currentLanguageCode,
	onLanguageChange,
	className,
	template,
	languages,
	edge = 'right',
	top = false,
	useISOLangCodes = false,
	isNoScroll,
	animateDropdown = false,
}: LanguageDropdownProps): JSX.Element => {
	const { isLessThan768 } = useScreenMediaQuery();
	const allLanguages: ILang[] = languages
		? (languages
			.map((l) => ALL_LANGS.find((f) => f.code === l))
			.filter((f) => f) as any)
		: ALL_LANGS;

	const [code, setCode] = useState<LanguagesAbbr>(currentLanguageCode || 'en');
	const { t } = useTranslate('languages-list');

	const translateLangCode = ((code: LanguagesAbbr): string => {
		if (!code) return '';
		if (useISOLangCodes) return code;

		const codeToTranslate = `languages-list:Languages.${code}`;
		const translatedString = t(codeToTranslate, code.toUpperCase()) as string;
		return translatedString.charAt(0).toUpperCase() + translatedString.slice(1);
	});

	useEffect(() => {
		setCode(currentLanguageCode as LanguagesAbbr);
	}, [currentLanguageCode]);

	return (
		<NewDropdown
			className={classNames('language-dropdown', className, template)}
			popupClassName={top ? 'menu-top-placement' : ''}
			edge={edge}
			renderTitle={() => translateLangCode(code)}
			template={template}
			isNoScroll={isNoScroll ?? isLessThan768}
			animateDropdown={animateDropdown}
		>
			<TypographyItem className="language-dropdown-title">
				{t('homepage:Select Language', "Select Language")}
			</TypographyItem>
			<div className="language-list">
				{allLanguages.map((lang) => (
					<div
						className={classNames('language-list-item', {
							selected: lang.code === currentLanguageCode,
						})}
						onClick={() => {
							setCode(lang.code);
							onLanguageChange && onLanguageChange(lang.code);
						}}
						key={lang.code}
					>
						{translateLangCode(lang.code)}
					</div>
				))}
			</div>
		</NewDropdown>
	);
};
