import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import chroma from 'chroma-js';

import isColorLightOrDark from 'utils/colors/is-color-light-or-dark';
import { EPaletteModes } from 'types/theme-packs';
import { ThemeContext } from 'components/live-event/theme-context';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { useGetColorOptionsFromColor } from 'utils/colors/use-get-color-options-from-color';

import '../../../scss/live-event/base/general-ui/avatar.scss';

const pickColor = (char: string, colors: string[]) => {
	if (char) {
		return colors[char.charCodeAt(0) % colors.length];
	} else {
		return colors[0];
	}
};

const cachedAvatarColors: { [userInitial: string]: { textColor: string; bgColor: string; } } = {};

interface IAvatarProps {
	imageUrl?: string;
	initials?: string;
	className?: string;
	style?: React.CSSProperties;
	onClick?(): void;
	tabIndex?: number;
	canViewProfile?: boolean;
	isModuleGroupingV2?: boolean;
}

const Avatar: React.FC<IAvatarProps> = ({
	imageUrl,
	initials = '',
	className = '',
	style = {},
	onClick = () => ({}),
	tabIndex,
	canViewProfile = false,
	isModuleGroupingV2 = false,
}) => {

	const displayInitials = initials ? initials.replace(/ /g, '') : '';

	const [styles, setStyles] = useState(style);
	const [theme] = useContext(ThemeContext);

	const eventBundleDesignColors = useTypedSelector(state => state.LiveEventReducer?.eventBundle?.settings?.design?.colors);
	const workingEventDesignColors = useTypedSelector(state => state.CreateEventReducer?.workingEvent?.settings?.design?.colors);
	const eventDesignColors = eventBundleDesignColors || workingEventDesignColors;
	const designColors = useGetColorOptionsFromColor(eventDesignColors, theme);

	const primary = useMemo(() => designColors?.accentColor?.[0] || '#026A93', [designColors]);
	const accent = useMemo(() => designColors?.secondaryAccentColor?.[0] || '#1da1f2', [designColors]);

	const [primaryState, setPrimaryState] = useState(primary);
	const [accentState, setAccentState] = useState(accent);
	const [themeState, setThemeState] = useState(theme);

	// force a re-render if theme or primary/accent colors change
	useEffect(() => {
		setPrimaryState(primary);
		setAccentState(accent);
		setThemeState(theme);
	}, [primary, accent, theme]);

	useEffect(() => {
		if (isModuleGroupingV2 && !imageUrl) {
			const cached = cachedAvatarColors[initials[0]];
			if (cached && primaryState === primary && accentState === accent && themeState === theme) {
				setStyles(prev => ({
					...prev,
					color: cached.textColor,
					backgroundColor: cached.bgColor,
				}));
			} else {
				// using chromajs generate 5 shades of primary and 5 shades of secondaryAccentColor
				const toColor = theme === EPaletteModes.Dark ? 'white' : 'black';
				const primaryShades = chroma.scale([primary, toColor]).colors(6);
				const accentShades = chroma.scale([accent, toColor]).colors(6);

				// remove the last shade of each color because it's white/black
				primaryShades.pop();
				accentShades.pop();

				const colors = [
					primary,
					accent,
					...primaryShades,
					...accentShades,
				];

				const bgColor = pickColor(initials[0], colors);
				const isDarkBackground = isColorLightOrDark(bgColor) === EPaletteModes.Dark;
				const textColor = isDarkBackground ? "#ffffff" : "#000000";

				cachedAvatarColors[initials[0]] = { textColor, bgColor };

				setStyles(prev => ({
					...prev,
					color: textColor,
					backgroundColor: bgColor,
				}));
			}
		}
	}, [accent, accentState, imageUrl, initials, isModuleGroupingV2, primary, primaryState, theme, themeState]);

	return (
		<div
			className={classNames('chat-avatar', className, { clickable: canViewProfile })}
			style={{ backgroundImage: imageUrl ? `url("${imageUrl}")` : 'none', ...styles }}
			onClick={canViewProfile ? onClick : undefined}
			tabIndex={tabIndex}
			onKeyDown={e => {
				if (e.key === 'Enter' && canViewProfile) {
					onClick();
				}
			}}
		>
			{!imageUrl && displayInitials}
		</div>
	);
};

export default Avatar;
