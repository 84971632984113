import type { Level } from 'hls.js';
import type { Quality } from 'amazon-ivs-player';

export enum VideoPlayerType {
	hls = 'hls',
	ivs = 'ivs',
	embed = 'embed',
	link = 'link',
	none = 'none',
	meet = 'meet',
	placeholder = 'placeholder',
	mp4 = 'mp4',
	mux = 'mux'
}

export type QualityLevel = {
	index: number;
	label: string;
	internal: Level | Quality | 'auto';
}

export type TimeUpdate = {
	loaded: number;
	loadedSeconds: number;
	played: number;
	playedSeconds: number;
};
