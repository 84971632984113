import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { PageModule, PageModuleType } from '../../../types/working-model';
import { moduleRequiresContentInV2 } from "components/live-event/session/session-utils";
import { useTranslate } from '../../../i18n/useTranslationModules';
import useTranslationRoute from '../../live-event/hooks/use-translation-route';
import { MODULE_TAB_NAMES } from '../../../types/module-tabs';

import './tab-filter.scss';

export type SlidingMenuOption<T = any> = { //returned in passed `callback` prop
	label: string,
	data?: T,
	disabled?: boolean;
}
export type Props = {
	availableModules: PageModule[];
	selectedModuleTabId: number | undefined;
	handleTab: (tab: number) => () => void;
	className?: string;
}
export default function TabFilter(props: Props) {
	const {
		availableModules,
		selectedModuleTabId,
		handleTab
	} = props;
	const wrapper = useRef<HTMLDivElement>(null);
	const optionsWrapper = useRef<HTMLDivElement>(null);
	const { language } = useParams<{ language: string }>();
	const translationNamespace = useTranslationRoute();
	const { t } = useTranslate(["session", translationNamespace]);

	useEffect(() => {
		if (!wrapper.current || !optionsWrapper.current) {
			return;
		}

		const selectedElement = optionsWrapper.current.querySelector<HTMLElement>('.selected');

		if (selectedElement) {
			if (optionsWrapper.current.scrollWidth > wrapper.current.offsetWidth) {
				optionsWrapper.current.scroll({
					left: (selectedElement.offsetLeft + (selectedElement.offsetWidth / 2) - wrapper.current.offsetWidth) + (wrapper.current.offsetWidth / 2),
					top: 0,
					behavior: 'smooth'
				});
			}
		}
	}, [selectedModuleTabId, optionsWrapper.current?.offsetWidth]);

	const tabsToShow = availableModules.filter(module => {
		// if this is the attendee view, the module must be defined, have a valid type, and contain modules
		const hasContent = !!(module?.type && (!moduleRequiresContentInV2[module?.type] || module?.modules?.length || module?.content_modules?.length));
		return hasContent && module?.is_on;
	});

	if (tabsToShow.length === 1) {
		return <></>;
	}


	return (
		<div className="tab-filter-wrapper" ref={wrapper}>
			<div className="tab-filter" ref={optionsWrapper}>
				{tabsToShow.map(module => {
					const moduleType = module?.type || PageModuleType.documents;

					const isActiveTab = selectedModuleTabId === module?.id;

					const label = (() => {
						return module?.content?.custom_heading?.[language]
							|| module?.content?.custom_heading?.base
							|| t(MODULE_TAB_NAMES[moduleType] || '');
					})();

					return (
						<button
							key={module?.id}
							className={classNames('evt-button secondary btn-sm', { "selected": isActiveTab })}
							onClick={handleTab(module?.id || 0)}
						>
							{label}
						</button>
					);
				})}
			</div>
		</div>
	);
}