import { PageModule, PageModuleType, Speaker } from "../types/working-model";

export const dedupSpeakers = (acc: [Speaker[], Set<number>], speaker: Speaker): [Speaker[], Set<number>] => {
	const [speakers, speakerIds] = acc;
	if (!speakerIds.has(speaker.speaker)) {
		return [[...speakers, speaker], speakerIds.add(speaker.speaker)];
	} else {
		return acc;
	}
};

export const allSpeakerPageModules = (module: PageModule) => {
	return module.type === PageModuleType.speakers && module.is_on;
};

export const allSpeakerModulesWithout = (toExclude: PageModule) => (module: PageModule) => {
	return module.id !== toExclude.id && module.type === PageModuleType.speakers && module.is_on;
};
