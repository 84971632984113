import { CSSProperties } from "react";

import Icon, { COLORS, ICONS } from "../icon";
import { Tooltip } from "../tooltip/tooltip";
import Switch from "./switch";

import './toggle-option.scss';

interface ToggleOptionProps {
	title: string;
	tooltip: string;
	id: string
	switchName: string;
	onSwitchToggle: (value: string, on: boolean) => void;
	toggleValue: boolean;
	disabled?: boolean;
	children?: React.ReactNode;
}
const tooltipStyles: CSSProperties = {
	zIndex: 1,
};

export default function ToggleOption(props: ToggleOptionProps): JSX.Element {
	const onSwitch = props.onSwitchToggle;

	return (
		<div className="toggle-group-container" id={props.id}>
			<div className="toggle-group" id={props.id}>
				<div className="left-group">
					<label>{props.title}</label>
					<Tooltip position="top-right" tooltip={props.tooltip} maxWidth={150} styles={tooltipStyles}>
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.LIGHT_GRAY} size={12} />
					</Tooltip>
				</div>

				<Switch
					value={props.switchName}
					on={props.toggleValue}
					onClick={(_: unknown, enabled: boolean) => onSwitch(_ as string, enabled)}
					disabled={props.disabled}
				/>
			</div>
			{props.children}
		</div>
	);
}
