import { useEffect, useState } from "react";

interface IUseTrackElementPositionsProps<T> {
	refs: React.MutableRefObject<Record<number, React.RefObject<T | null>>>;
	options?: MutationObserverInit | undefined;
	forceRerender?: number;
	cancelTracking?: boolean;
}

const useTrackElementPositions = <T extends HTMLElement>(props: IUseTrackElementPositionsProps<T>) => {
	const { refs, options, forceRerender, cancelTracking } = props;

	const initVals = Object.fromEntries(Object.entries(refs.current).map(([key]) => {
		return [
			key,
			{
				top: 0,
				right: 0,
				bottom: 0,
				left: 0,
			}
		];
	}));

	const [elementPositions, setElementPositions] = useState<Record<number, {
		top: number;
		right: number;
		bottom: number;
		left: number;
	}>>(initVals);

	useEffect(() => {
		const _observers: MutationObserver[] = [];
		Object.entries(refs.current).forEach(value => {
			const key = parseInt(value[0]);
			const el = value[1].current;
			if (el && !cancelTracking) {
				const boundingClientInit = el.getBoundingClientRect();
				// initialize on load:
				setElementPositions(prev => ({
					...prev,
					[key]: {
						top: boundingClientInit.top,
						right: boundingClientInit.right,
						bottom: boundingClientInit.bottom,
						left: boundingClientInit.left,
					},
				}));

				// listen for changes:
				const observer = new MutationObserver(() => {
					if (el && !cancelTracking) {
						const boundingClient = el.getBoundingClientRect();
						setElementPositions(prev => ({
							...prev,
							[key]: {
								top: boundingClient.top,
								right: boundingClient.right,
								bottom: boundingClient.bottom,
								left: boundingClient.left,
							},
						}));
					}
				});

				observer.observe(el, {
					attributes: true,
					characterData: true,
					childList: true,
					subtree: true,
					...options
				});
				_observers.push(observer);
			}
		});

		return () => {
			_observers?.forEach((observer) => {
				observer.disconnect();
			});
		};
	}, [refs, forceRerender, options, cancelTracking]);

	return {
		elementPositions
	};
};

export default useTrackElementPositions;
