import React, { useState } from "react";
import { useDispatch, batch } from "react-redux";
import { unique } from "underscore";

import Switch from "../../../../../../../general-ui/switch/switch";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import {
	toggleInPersonAttendeeMode,
	updateRegistrationSteps,
	updateRequiredQuestions
} from '../../../../../../../../store/actions/admin/create-event';
import { updateRegistrationQuestions } from '../../../../../../../../store/actions/admin/registration-questions';
import {
	RegFieldsEnum,
	RegistrationQuestion,
	RegistrationStep,
	RegistrationStepType
} from '../../../../../../../../types/working-model';
import { toMap } from '../../../../../../../../utils/utils';
import { isUsingCustomSSO } from "../../../../../../../../utils/sso-utils";
import PanelBody from "../../panel-body";


const InPersonAttendanceToggleV2: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const registrationQuestions = useTypedSelector(state => state.RegistrationQuestionsReducer.registrationQuestions);
	const [isOn, setIsOn] = useState<boolean>(workingEvent?.registration_settings?.adminInPersonAttendeeModeEnabled ?? false);
	const dispatch = useDispatch();

	const requiredQuestions = workingEvent?.required_registration_questions;
	const usingSSO = isUsingCustomSSO(workingEvent);

	const handleToggle = () => {
		dispatch(toggleInPersonAttendeeMode(!isOn));
		const question = registrationQuestions.find(
			(question: RegistrationQuestion) => question.registration_question === RegFieldsEnum.attend_in_person);
		// If the admin turns on in person attendee mode, add that question in the appropriate places. The default behavior is off.
		if (!isOn && question?.registration_question) {
			const newSteps = workingEvent?.registration_steps;
			if (workingEvent) {
				const newQuestions = Array.from(toMap('registration_question', workingEvent?.registration_questions).values());
				if (newQuestions && question) newQuestions.push(question);
				newSteps?.forEach((step: RegistrationStep) => {
					if (step.type === RegistrationStepType.general) {
						if (question) step.questions?.push(question);
					}
				});
				const newRequiredQuestions = workingEvent?.required_registration_questions ? unique(workingEvent?.required_registration_questions) : [];
				newRequiredQuestions?.push(question.registration_question);
				if (newRequiredQuestions && newSteps && newQuestions) {
					batch(() => {
						dispatch(updateRequiredQuestions(newRequiredQuestions));
						dispatch(updateRegistrationSteps(newSteps));
						dispatch(updateRegistrationQuestions(newQuestions));
					});
				}
			}
		}
		// If the admin turns off in person attendee mode, remove that question from the appropriate places
		if (isOn && requiredQuestions) {
			const requiredWithoutInPersonAttendanceQuestion = requiredQuestions?.filter((requiredQuestion: any) => requiredQuestion !== question?.registration_question);
			const questionsWithoutInPersonAttendance = workingEvent?.registration_questions?.filter((registrationQuestion: RegistrationQuestion) => registrationQuestion.registration_question !== question?.registration_question);
			const stepsWithoutInPersonAttendance = workingEvent?.registration_steps?.map((step: RegistrationStep) => {
				if (step.type === RegistrationStepType.general) {
					step.questions = step.questions?.filter((stepQuestion: RegistrationQuestion) => stepQuestion.registration_question !== question?.registration_question);
				}
				return step;
			});
			if (stepsWithoutInPersonAttendance && questionsWithoutInPersonAttendance && requiredWithoutInPersonAttendanceQuestion) {
				batch(() => {
					dispatch(updateRequiredQuestions(requiredWithoutInPersonAttendanceQuestion));
					dispatch(updateRegistrationSteps(stepsWithoutInPersonAttendance));
					dispatch(updateRegistrationQuestions(questionsWithoutInPersonAttendance));
				});
			}
		}
		setIsOn(!isOn);
	};

	return (
		<div className="settings-card">
			<div>
				<label>
					Enable in person attendee mode
				</label>
				{usingSSO && <p>Cannot combine with SSO</p>}
			</div>

			<Switch
				value={"In person attendee mode"}
				on={isOn ?? false}
				onClick={handleToggle}
				disabled={usingSSO}
			/>
		</div>
	);
};

export default InPersonAttendanceToggleV2;
