import { ENavbarTypes } from "../../../../../types/working-model";
import { ICONS } from "../../../../general-ui/icon";

type TUseGetNavIconName = (prams: { navbarType?: ENavbarTypes }) => string;

export const useGetNavIconName: TUseGetNavIconName = ({ navbarType }) => {
	switch (navbarType) {
		case ENavbarTypes.Vertical:
			return ICONS.MENU_VER_CLOSE;
		case ENavbarTypes.Horizontal:
			return ICONS.MENU_HOR_CLOSE;
		default:
			return ICONS.MENU_VER_CLOSE;
	}
};