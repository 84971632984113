import { Get, Post } from './helpers';

export function GetPasscodeListMembers(
	token: string,
	passcode_list: number,
	offset: number,
	order: number
): Promise<any[]> {
	return Get(
		`/v3/admin/content/passcode-lists/members/${passcode_list}/${offset}/${order}`,
		token
	);
}

export function SearchMembers(
	token: string,
	passcode_list: number,
	offset: number,
	value: string,
	order: number
): Promise<any[]> {
	if (value === '') {
		return Get(
			`/v3/admin/content/passcode-lists/members/${passcode_list}/${offset}/${order}`,
			token
		);
	} else {
		return Get(
			`/v3/admin/content/passcode-lists/members/${passcode_list}/${offset}/${order}/${encodeURIComponent(value)}`,
			token
		);
	}
}

export function SearchMembersCount(
	token: string,
	list: number,
	value: string
): Promise<any[]> {
	if (value === '') {
		return Get(
			`/v3/admin/content/passcode-lists/members-count/${list}`,
			token
		);
	}
	else {
		return Get(
			`/v3/admin/content/passcode-lists/members-count/${list}/${encodeURIComponent(value)}`,
			token
		);
	}
}

export function AddNewMember(
	token: string,
	list: number | undefined,
	passcode: string,
	email: string,
	firstName: string,
	lastName: string
): Promise<any> {
	if (list) {
		return Post(`/v3/admin/content/passcode-lists/addmember/`, token, {
			list,
			passcode,
			email,
			firstName,
			lastName
		});
	} else { return Promise.resolve(); }
}

export function GetAudienceInviteStatus(token: string, channel: number, passcodeList: number, emails: string[]): Promise<any[]> {
	return Post(
		`/v3/admin/scheduled-emails/invite-status/${channel}/${passcodeList}`,
		token,
		{ emails }
	);
}

export function GetPostmarkStatus(token: string, channel: number, passcodeList: number, email: string): Promise<{ invites: any[] }> {
	return Post(
		`/v3/admin/scheduled-email/invite-status/${channel}/${passcodeList}`,
		token,
		{ email }
	);
}