import React from "react";
import classNames from "classnames";

import Checkbox from "../../../../../../general-ui/checkbox/checkbox";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { EngageListItem } from "../active-engage-list";

interface IEngageCardProps {
	id: number;
	type: string;
	icon: string;
	title: string;
	item?: EngageListItem;
	checked?: boolean;
	responsesCount?: number;
	onCheckboxChange?: (value: string | number, isOn: boolean) => void;
	clickHandler?: (key: 'edit' | 'delete', item: EngageListItem) => void;
	onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
}

export default function EngageCard({
	id,
	type,
	icon,
	title,
	checked = false,
	responsesCount: questionsCount,
	onCheckboxChange,
	clickHandler,
	onClick,
	item
}: IEngageCardProps): JSX.Element {

	const typeClassName = type.toLowerCase().replaceAll('&', '-and-').replaceAll(' ', '-');

	const resLabel = questionsCount !== 1 ? 'responses' : 'response';

	return (
		<div
			className={classNames('e-engage-card', { selected: checked })}
			onClick={(e) => {
				onCheckboxChange?.(id, !checked);
				onClick?.(e);
			}}
		>
			<div className="e-engage-card-top">
				<span className={classNames('e-engage-label', typeClassName)}>
					<Icon name={icon} size={12} color="" />
					{type}
				</span>
				{onCheckboxChange && (
					<Checkbox
						value={id}
						checked={checked}
						inactiveColor={COLORS.BORDER_LIGHT_GRAY}
						activeColor={COLORS.WHITE}
						onChange={onCheckboxChange}
					/>
				)}
			</div>
			<p className="e-engage-card-title admin-typography-subtitle-1">{title}</p>

			<div className="e-engage-card-bottom">
				{typeof questionsCount === 'number' ? (
					<span className="admin-typography-subtitle-2">
						See {questionsCount} {resLabel}
					</span>
				) : <div />}

				{(clickHandler && item) && (
					<div className="e-engage-card-actions">
						<button className="round" onClick={(e) => {
							e.stopPropagation();
							clickHandler("delete", item);
						}}>
							<Icon name={ICONS.TRASH} color={COLORS.LIGHT_GRAY} size={12} />
						</button>
						<button className="round" onClick={(e) => {
							e.stopPropagation();
							clickHandler("edit", item);
						}}>
							<Icon name={ICONS.EDIT} color={COLORS.LIGHT_GRAY} size={12} />
						</button>
					</div>
				)}

			</div>

		</div>
	);
}
