import { useMemo } from "react";

import { RegistrationStep, RegistrationStepType } from "types/working-model";

const useIsStepSkippable = (
	currentStep?: RegistrationStepType,
	activeSteps?: RegistrationStep[],
	requiredRegQns?: number[] | null,
) => {
	return useMemo(() => {
		// can't skip a step we don't have
		if (!activeSteps || !currentStep) return false;

		// if ticketing step, we can't skip
		if (currentStep === RegistrationStepType.ticketing) return false;

		// if avatar step, we can skip
		if (currentStep === RegistrationStepType.avatar) return true;

		// get all questions for the current step
		const currentStepQns = activeSteps?.find(step => step.type === currentStep)?.questions;
		// if no questions, we can skip
		if (!currentStepQns?.length) return true;

		// if one of the questions on the current step is required, we can't skip
		const hasRequiredQuestion = currentStepQns?.find(qn => requiredRegQns?.includes(qn.registration_question));
		return !hasRequiredQuestion;
	}, [activeSteps, currentStep, requiredRegQns]);
};

export default useIsStepSkippable;
