export type SurveyResponseMatch = {
	survey_question: string,
	option: string
}
export const matchSurveyResponseKey = (key: string): SurveyResponseMatch | undefined => {
	const match = key.match(/engagement_question:(?<survey_question>\d+)-answer:(?<option>\w+)/);

	if (match?.groups && match.groups.survey_question && match.groups.option) {
		return match.groups as SurveyResponseMatch;
	}

	return undefined;
};