import { ChangeEvent, useCallback, useRef, useState, } from 'react';
import { debounce } from "underscore";

import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import Switch from "../../../../../../general-ui/switch/switch";
import TextInput from "../../../../../../general-ui/text-input/text";
import { updateRegistrationLimit } from "../../../../../../../store/actions/admin/create-event";

import './registration-limit-toggle.scss';

export default function RegistrationLimitToggle() {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const [isOn, setIsOn] = useState(!!workingEvent?.registration_settings?.registration_limit_is_on);
	const [limit, setLimit] = useState(workingEvent?.registration_settings?.registration_limit);

	const dispatch = useAppDispatch();

	const onToggle = (_: string, isOn: boolean) => {
		setIsOn(isOn);
		dispatch(updateRegistrationLimit(isOn, limit || 0));
	};

	const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const value = e.target.valueAsNumber;
		setLimit(value);
		debouncedSave(isOn, value);
	}, [isOn, limit]);

	const debouncedSave = useRef(debounce((isOn: boolean, limit: number) => {
		dispatch(updateRegistrationLimit(isOn, limit || 0));
	}, 250)).current;

	return (
		<div className="settings-card-stacked">
			<div className="top-row">
				<div>
					<label>
						Registration Limit
					</label>
				</div>

				<Switch
					value="reg-limit"
					on={isOn ?? false}
					onClick={onToggle}
				/>
			</div>

			{isOn && (
				<div className="top-row registration-limit-input">
					<TextInput
						placeholder="Digits only, e.g. 10000"
						className="reg-limit-input"
						onChange={handleChange}
						value={limit ? String(limit) : ''}
						onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
						numeric
					/>
				</div>
			)}
		</div>
	);
}