import classNames from "classnames";
import { useRef, useEffect, forwardRef, useState } from "react";
import { isObject } from "underscore";
import ScrollViewWithFade from "./fade-container";

import './stagger-children.scss';
import React from "react";

type StaggerChildrenProps = {
	children: JSX.Element | JSX.Element[],
	className?: string,
	containerAnimation?: unknown,
	itemAnimation?: unknown,
	onScrolledToBottom?: () => void,
	onScrolledUpFromBottom?: () => void,
	footer?: JSX.Element
	enableFades?: boolean,
	flexGrow?: boolean
}

const StaggerChildren = forwardRef<HTMLDivElement, StaggerChildrenProps>((props, ref) => {
	const {
		className,
		onScrolledToBottom,
		onScrolledUpFromBottom,
		footer
	} = props;

	const children = Array.isArray(props.children) ? props.children : [props.children ?? <React.Fragment />];
	const lastChildKey = children?.at?.(-1)?.key;
	const onScrolledToBottomRef = useRef(onScrolledToBottom);
	const onScrolledUpFromBottomRef = useRef(onScrolledUpFromBottom);
	const lastChildCount = useRef(children.length);
	const [childCount, setChildCount] = useState(0);

	useEffect(() => {
		if (lastChildCount.current < children.length) {
			setChildCount(lastChildCount.current);
		}

		lastChildCount.current = children.length;
	}, [children.length]);

	// prevent redefinitions of callbacks, which might be coming from a parent component
	// from causing the intersection observer to be redefined and reattached each render
	useEffect(() => {
		onScrolledToBottomRef.current = onScrolledToBottom;
		onScrolledUpFromBottomRef.current = onScrolledUpFromBottom;
	}, [onScrolledToBottom, onScrolledUpFromBottom]);

	useEffect(() => {
		const lastChild = (isObject(ref) && 'current' in ref) ?
			ref.current?.lastElementChild as HTMLElement :
			undefined;

		if (lastChild) {
			const io = new IntersectionObserver((e) => {
				if (e[0]?.isIntersecting) {
					onScrolledToBottomRef.current?.();
				} else {
					onScrolledUpFromBottomRef.current?.();
				}
			}, { threshold: 1 });

			io.observe(lastChild);

			return () => {
				io.disconnect();
			};
		}
	}, [lastChildKey, ref]);

	return (
		<div className={className} ref={ref}>
			{children.map((child, i) => (
				<div
					style={{
						'--stagger-children-index': i - childCount,
					} as React.CSSProperties}
					key={child.key}
					className={'stagger-child'}
				>
					{child}
				</div>
			))}
			{footer}
		</div>
	);
});

const StaggerChildrenWrapper = forwardRef<HTMLDivElement, StaggerChildrenProps>((props, ref) => {
	if (props.enableFades) {
		return (
			<ScrollViewWithFade>
				<div className={classNames("stagger-children", { "fades-enabled": props.enableFades, 'flex-grow': props.flexGrow })}>
					<StaggerChildren {...props} ref={ref} />
				</div>
			</ScrollViewWithFade>
		);
	}

	return (
		<div className={classNames("stagger-children", { 'flex-grow': props.flexGrow })}>
			<StaggerChildren {...props} ref={ref} />
		</div>
	);
});

export default StaggerChildrenWrapper;
