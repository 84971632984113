import { isObject } from "underscore";
import {
	LanguagesAbbr,
	ModuleGroupingTypes,
	PageModuleGroupModules,
	Session,
	SessionTabsLayoutTypes,
} from "types/working-model";

// ATF, we never show group module titles
// BTF:
// - show group module title title for overview modules
// - show group module title if there are ANY active modules

const getModuleSectionTitle = (session: Session, language: LanguagesAbbr, moduleGroup?: PageModuleGroupModules): string => {
	// if mnoduleGroup is not on, don't show
	if (!moduleGroup?.is_on) return '';

	// if no group modules, no need to show the title so immediately return
	if (!moduleGroup?.modules?.length) return '';

	// never show titles in ATF, module groups in ATF have nested pills
	const isAboveTheFold = !!(session?.layout?.tabs?.layout === SessionTabsLayoutTypes.AboveTheFold);
	if (isAboveTheFold) return '';

	// rest of the code is for BTF

	const name = getModuleGroupTitle(moduleGroup, language);
	// if no module group name, immediately return
	if (!name.length) return '';

	// if overview module, we always show the title
	const isOverviewModule = moduleGroup?.type === ModuleGroupingTypes.Overview;
	if (isOverviewModule) return name;

	// if no active modules for the group, don't show the title
	const activeModules = getActiveModulesForGroup(moduleGroup, session);
	if (!activeModules.length) return '';

	// if we have active modules we always return the module group title for BTF, regardless if it's 1 or 100 modules
	return name;
};

export default getModuleSectionTitle;

export const getModuleGroupTitle = (moduleGroup: PageModuleGroupModules, language: LanguagesAbbr) => {
	let name = '';
	const moduleGroupName = moduleGroup?.name;
	if (moduleGroupName) {
		if (isObject(moduleGroupName)) {
			name = (moduleGroupName?.[language] ?? moduleGroupName?.base ?? '') as string;
		} else {
			name = moduleGroupName ?? '';
		}
	}

	return name.trim();
};

export const getActiveModulesForGroup = (moduleGroup: PageModuleGroupModules, session: Session): number[] => {
	return (moduleGroup?.modules || []).filter(group_module_id => {
		const matchingModule = session?.modules.find(session_module => session_module.id === group_module_id);

		return matchingModule
			? matchingModule.is_on && !!matchingModule.content_modules?.length
			: false;
	});
};
