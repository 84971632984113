import { AnalyticsEvent, BrandliveEventListItem, EventGroup } from '../../../../types/working-model';
import { IDateOption } from '../../../general-ui/datepicker/date-range';

export function getMetricsQueryParams(search: string) {
	const queryString = new URLSearchParams(search);
	const startParam = queryString.get("start");
	const endParam = queryString.get("end");
	const audienceLists = queryString.getAll('audienceLists');
	const audienceListsSr = queryString.get('audienceLists');
	const language = queryString.get('language');

	return {
		start: startParam ? parseInt(startParam || '') : undefined,
		end: endParam ? parseInt(endParam || '') : undefined,
		audienceLists: audienceLists.length ? audienceLists : undefined,
		audienceListsStr: audienceListsSr || undefined,
		language: language ?? undefined,
	};
}

export function buildReportsURLString(
	end: IDateOption | undefined,
	start: IDateOption | undefined,
	timeZone: string,
	event: AnalyticsEvent,
	reportName: string,
	anonymizeSentimentData: boolean,
	channel: number,
	forceEmail?: boolean,
	dmaID?: string | null
) {
	const params = new URLSearchParams();
	end && params.set('endDate', new Date(end).getTime().toString());
	start && params.set('startDate', new Date(start).getTime().toString());
	params.set('channel', event.channel.toString());
	params.set('timeZone', timeZone);
	params.set('eventUuid', event.uuid);
	params.set('channel', String(channel));

	if (anonymizeSentimentData) {
		params.set('anonymizeSentimentData', 'true');
	}

	if (forceEmail) {
		params.set('forceEmail', 'true');
	}

	if (reportName === 'Registrations') {
		event.registration_settings?.marketing_email_options && params.set('marketingEmailOptions', event.registration_settings?.marketing_email_options);
	}

	if (dmaID) {
		params.set('dmaID', dmaID);
	}

	return params.toString();
}

interface MetricParamsOptions {
	start?: Date | null;
	end?: Date | null;
	audienceList?: string[];
	language?: string;
	v2Dashboard?: boolean;
}

export function generateMetricsFilterParams(options: MetricParamsOptions) {
	const { start, end, audienceList, language } = options;

	const params = new URLSearchParams();
	if (start) {
		params.set('start', start.valueOf().toString());
	}

	if (end) {
		params.set('end', end.valueOf().toString());
	}

	if (audienceList?.length) {
		audienceList?.forEach(list => params.append('audienceLists', list));
	}

	if (language?.length) {
		params.set('language', language);
	}

	const urlParams = params.toString();

	return urlParams ? `?${urlParams}` : '';
}

const sortEventsList = (a: BrandliveEventListItem, b: BrandliveEventListItem) => {
	return a.created_date > b.created_date ? 1 : -1;
};

export const getQueryDates = (
	start: IDateOption | string | number | null,
	end: IDateOption | string | number | null,
	eventsList: BrandliveEventListItem[],
	eventUUID: string | undefined,
	forceUseStart = false
): { start: Date, end?: Date; } => {
	// channel events are sorted DESC so the last in the array is the first created
	// find first created event that has a registered user - the getEvents time range query filters by user session activity
	const firstEventStart = new Date([...eventsList].sort(sortEventsList)[0]?.created_date || Date.now() * 1000 * 60 * 60 * 24 * 365).valueOf();

	const getQueryStart = (): number => {
		if (forceUseStart) {
			return Number(start);
		}

		// at event and session level, return latest of start prop or event creation date
		if (eventUUID) {
			const event = eventsList.find(e => e.uuid === eventUUID);
			if (event?.created_date) {
				const eventStart = new Date(event.created_date).valueOf();
				return !!start && (Number(start) >= eventStart) ? Number(start) : eventStart;
			}
		}

		// at channel level, return latest of provided start prop or first event creation date

		return !!start && (Number(start) >= firstEventStart) ? Number(start) : firstEventStart;
	};

	let queryStart = getQueryStart();
	if (!end) {
		return {
			start: new Date(queryStart)
		};
	}

	let queryEnd = Number(end);

	// double check validity
	// start before end
	if (queryStart > queryEnd) {
		[queryStart, queryEnd] = [queryEnd, queryStart];
	}
	// a minimum 5 minute range
	const buffer = 1000 * 60 * 5;
	if (queryEnd - queryStart < buffer) {
		queryEnd = queryStart + buffer;
	}

	return { start: new Date(queryStart), end: queryEnd ? new Date(queryEnd) : undefined };
};

export function getEventGroups(eventGroups: EventGroup[], event_uuid: string): EventGroup[] {
	return eventGroups.filter(group => group.event_uuids?.includes(event_uuid));
}