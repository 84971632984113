import classNames from "classnames";
import { OptionalComponent } from "../../../utils/optional-component";
import { RadioOption } from "./radio-group";

/**
 * This is an individual radio input element for use when a RadioGroup is not appropriate
 * For example, when one group of radios needs to be implemented across multiple components (see quiz-option.tsx)
 */

type Accessibility = {
	ariaLabel?: string;
	ariaLabeledBy?: never;
} | {
	ariaLabel?: never;
	ariaLabeledBy?: string;
}

// May be controlled by option and value or by selected boolean
type Control = {
	option?: never;
	selected: boolean;
	value?: never;
} | {
	option: RadioOption;
	selected?: never;
	value: RadioOption['value'];
}

type Props = {
	onClick: (value: RadioOption['value'] | true) => void;
	customClassName?: string;
	label?: string;
	style?: React.CSSProperties;
} & Accessibility & Control;

export function RadioInput({
	ariaLabel,
	ariaLabeledBy,
	customClassName = '',
	label,
	onClick,
	option,
	selected,
	style = {},
	value,
}: Props) {
	const handleClick = () => {
		// Radios controlled with boolean will only onClick with true for ordinary radio behavior
		const arg = typeof value === 'undefined' || value;
		onClick(arg);
	};
	const isSelected = selected ?? option?.value === value;
	const ariaLabelText = ariaLabeledBy ? undefined : ariaLabel ?? label ?? value?.toString();

	return (
		<div
			aria-label={ariaLabelText}
			aria-checked={isSelected}
			className={classNames("radio-option", customClassName, { selected: isSelected })}
			onClick={handleClick}
			role="radio"
			style={style}
		>
			<div className="radio"><span /></div>
			<OptionalComponent display={!!label}>
				<label>{label}</label>
			</OptionalComponent>
		</div>
	);
}
