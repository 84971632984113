interface IUseColorPalettesImportProps {
	filename: string;
}

const useColorPalettesImportFilename = ({ filename }: IUseColorPalettesImportProps) => {
	if (!filename) return '';

	const _filename = filename.toLowerCase();

	// keeping original around for a while
	// if (_filename === 'limelight') {
	// 	return `${_filename}-original.scss`;
	// }

	return `${_filename}.scss`;
};

export default useColorPalettesImportFilename;
