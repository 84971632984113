import { RootState } from "store/reducers/main";
import { useTypedSelector } from "../store/reducers/use-typed-selector";
import { Channel, ChannelFeaturesEnum, FeatureFlagsEnum } from "../types/working-model";
import { EventsState } from "store/types";


export const useSessionDetailsV2 = () => {
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const sessionDetailsV2FeatureFlagOn = featureFlags[FeatureFlagsEnum.session_details_v2];
	const channelSessionV2On = useChannelSessionV2();

	// Allow channel override for this, even if the global flag is off
	return sessionDetailsV2FeatureFlagOn || channelSessionV2On;
};

export const useRegistrationV2 = () => {
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const registrationV2FeatureFlagOn = featureFlags[FeatureFlagsEnum.registration_v2_q1_2024];

	return registrationV2FeatureFlagOn;
};

export const useNavbarV2 = () => {
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const navbarV2FeatureFlagOn = featureFlags[FeatureFlagsEnum.navbar_v2];
	const channelSessionV2On = useChannelSessionV2();

	// Allow channel override for this, even if the global flag is off
	return navbarV2FeatureFlagOn || channelSessionV2On;
};

export const useVideoV2 = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);

	const event = workingEvent || eventBundle;

	// Allow channel override for this, even if the global flag is off
	return event?.settings?.is_module_grouping_v2;
};


const useChannelSessionV2 = () => {
	const channelFeatures = useTypedSelector(
		state => state.AuthReducer?.channels
			?.find(c => c.channel === state.AuthReducer?.user?.active_channel)
			?.enabled_features);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);

	const event = workingEvent || eventBundle;

	const sessionV2Enabled =
		channelFeatures?.includes(ChannelFeaturesEnum.sessions_v2)
		|| event?.channel_features?.includes(ChannelFeaturesEnum.sessions_v2)
		|| false;

	return sessionV2Enabled;
};

const findCurrentChannel = (state: RootState) => (c: Channel) => {
	return c.channel === state.AuthReducer?.user?.active_channel;
};

const findChannelFeatures = (state: RootState) => {
	return state.AuthReducer?.channels?.find(findCurrentChannel(state))?.enabled_features;
};

const findEventChannelFeatures = (state: RootState) => {
	const event = state.LiveEventReducer.eventBundle || state.CreateEventReducer.workingEvent;
	return event?.channel_features;
};

export const useCanEnableMeetFiresides = () => {
	const channelFeatures = useTypedSelector(findChannelFeatures);
	return channelFeatures?.includes(ChannelFeaturesEnum.google_meet_firesides) || false;
};

export const useEventCanUseMeetFiresides = () => {
	const eventChannelFeatures = useTypedSelector(findEventChannelFeatures);
	return eventChannelFeatures?.includes(ChannelFeaturesEnum.google_meet_firesides) || false;
};

export const eventCanUseMeetFiresides = (state: EventsState) => {
	const eventChannelFeatures = state.LiveEventReducer.eventBundle?.channel_features;
	return eventChannelFeatures?.includes(ChannelFeaturesEnum.google_meet_firesides) || false;
};