import React, { useCallback, useEffect, useState, useRef, Suspense } from "react";
import { useDispatch } from "react-redux";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
	useParams,
	matchPath
} from "react-router-dom";
import classNames from 'classnames';

import Socket from "../../connection/socket";
import {
	updateUserSessionEndTime,
	loadPage,
	loadEventBundle,
	setBlProfileToken,
	setLiveSessions,
	setDisplayProfileModal,
	getPageSession,
	setPageSession,
	updatePageSessionEndTime,
	clearErrorBoundaryComponents,
} from "../../store/actions/event/event-actions";
import { LanguagesAbbr, BrandliveEvent, SqsSocketTypes, IAnnouncement } from "../../types/working-model";
import WaitingIndicator from "../general-ui/waiting-indicator/waiting-indicator";
import Alerts from '../general-ui/alert/alert';
import LiveEventDocumentHead from "./live-event-document-head";
import MarketingPage from "./marketing-page/marketing-page";
import MarketingRegisteredRoutes from "./marketing-page/marketing-registered-routes";
import RegistrationPage from "./registration/registration";
import Session from "./session/session";
import { eventPath, getAttendeeLocalLanguage, getDefaultLanguage } from "./utils";
import ScrollToTop from './scrolll-to-top';
import { getStorageItem, setStorageObject } from "../../utils/local-storage";
import { trackGoogleAnalytics } from "../../utils/google-analytics";
import useRouteMap from "./hooks/use-route-map";
import { hasEventAccess } from "../../utils/registration-utils";
import { showAlert, showAnnouncement } from "../general-ui/alert/alert-service";
import { storeAllQueryParams, storeReferrer, getStoredReferrer, shouldDisplayLandingPage, getTemplateClassName, isRtlLanguage, shouldDisplayHomepage, getPathKey } from "../../utils/utils";
import useCheckBreakoutBootedStatus from "./hooks/use-check-breakout-booted-status";
import { useTypedSelector } from '../../store/reducers/use-typed-selector';
import { TemplateClassNames, TemplateNames } from "../../types/template-layouts";
import useBundleUpdateSockets from "./use-bundle-update-sockets";
import useCheckUserGating from "./use-check-user-gating";
import TestErrorBoundary from "../../utils/test-error-boundary";
import { OptionalComponent } from "../../utils/optional-component";
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from "../../utils/error-boundary";
import LocationTracking from "./location-tracking";
import PreviewPasscodeChecker from "./preview-passcode-checker";
import PageNotFound from "../404/404";
import socketManager from "../../connection/socket-main-thread/socket-manager";
import useTrackPageView from "../../utils/tracking/use-track-page-view";

import { useTranslate } from '../../i18n/useTranslationModules';
import CookieBanner, { getCookieIndexDB, setCookieIndexDB } from "../general-ui/cookie-banner/cookie-banner";
import CookieBannerStickyWrapper from "../general-ui/cookie-banner/cookie-banner-sticky-wrapper";
import { getQueryStringState, Redirects } from "../../utils/redirects";
import { useTemplateFilename, useTemplateName } from "../../hooks/template-name.hooks";
import { MousePopup } from "../general-ui/mouse-popup/mouse-popup";
import useResetRegistrationSockets from "./use-reset-registration-sockets";
import { deregister } from "utils/deregister";
import { HvHostMap } from "connection/helpers";
import { getCurrentPageSourceInfo } from "../../utils/tracking";
import { useIsRegistrationV2 } from "hooks/registration-hooks";
import RegistrationWrappedV2 from "./registration-v2/registration-v2";
import useErrorUpdatingProfileAlert from "./hooks/use-error-updating-profile-alert";
import { useTicketHistory } from "./hooks/use-ticket-history";
import { useEventbriteListener } from "./hooks/use-eventbrite-listener";
import Navbar from "./navbar/navbar";
import useColorPalettesImportFilename from "./use-color-palettes-import-filename";
import { useThemeUpdate } from "hooks/theme.hooks";
import { addOrRemoveRecaptchaBadge } from "./registration/use-show-recaptcha-badge";

const EventChatMain = React.lazy(() => import("./event-chat/event-chat-main"));
const BreakoutRoom = React.lazy(() => import('./modules/breakout-rooms/breakout-room'));
const Fireside = React.lazy(() => import("./session/fireside/fireside"));
const PrivacyPolicy = React.lazy(() => import("./privacy-policy/privacy-policy"));
const TermsConditions = React.lazy(() => import("./terms-and-conditions/terms-and-conditions"));
const NonRegisteredUserForm = React.lazy(() => import("./session/live-chat/non-registered-user-form"));
const ProfileModal = React.lazy(() => import("./profile-page/profile-modal"));

import '../../scss/live-event/base/containers/user-survey-modal.scss';

// if uncommenting this, also uncomment the early async import hook return for Limelight
// Note: this will only work properly if we wrap all limelight css with body[data-template="Limelight"] { @content }
// import '../../scss/live-event/limelight.scss';

export interface ParamsProps {
	id: string; /** event uuid */
	uuid: string;
	eventName: string;
	tab: string; /** editor nav tab */
	language: LanguagesAbbr;
	sessionUuid: string;
	attendeeId?: string;
	// scheduleUuid?: IScheduledEmail['uuid']; // FUTURE DEV: Keeping for now until we add content editing to emails and decide if we want to use the details page
	/**@deprecated  keeping for backwards compatibility*/
	session: string;
	customPath: string;
	breakoutUuid: string;
	audienceID: string;
	customUuid: string;
	page_module: string;
}

export interface LiveEventProps {
	debug?: boolean;
	host?: string;
	eventNamePass?: string;
	isEditor: boolean;
}

function Loader() {
	return (
		<div style={{
			position: 'absolute',
			inset: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#1a1a1f'
		}}>
			<WaitingIndicator fillSpace />
		</div>
	);
}

function registrationPath(lang: string, eventName?: string, uuid?: string): string {
	if (eventName) return `${eventName}/${lang}/registration`;
	else return `event/${uuid}/${lang}/registration`;
}

const LiveEvent: React.FC<LiveEventProps> = (props) => {
	const { debug, host, eventNamePass, isEditor } = props;
	const dispatch = useDispatch();
	const history = useHistory();

	const { uuid }: ParamsProps = useParams();
	const location = useLocation();

	const { pathname, search: locationSearch, state } = location;
	const stateReferrer = (state as Record<string, string> | undefined)?.referrer;

	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const registrationId = useTypedSelector(event => event.LiveEventReducer.registrationId);
	const loadingEventBundle = useTypedSelector(event => event.LiveEventReducer.loadingEventBundle);
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);
	const pageSession = useTypedSelector(event => event.LiveEventReducer.pageSession);
	const gettingPageSession = useTypedSelector(event => event.LiveEventReducer.gettingPageSession);
	const getPageSessionError = useTypedSelector(event => event.LiveEventReducer.getPageSessionError);
	const userSession = useTypedSelector(event => event.LiveEventReducer.userSession);
	const _404 = useTypedSelector(event => event.LiveEventReducer._404);
	const blProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const shouldRedirectToRegistration = useTypedSelector(event => event.LiveEventReducer.shouldRedirectToRegistration);
	const registrationDeleted = useTypedSelector(event => event.LiveEventReducer.registrationDeleted);
	const playbackUrls = useTypedSelector(event => event.LiveEventReducer.playbackUrls);
	const validSessions = useTypedSelector(event => event.LiveEventReducer.validSessions);
	const finishedRegistering = useTypedSelector(event => event.LiveEventReducer.finishedRegistering);
	const userVerified = useTypedSelector(event => event.LiveEventReducer.userVerified);
	const displayProfile = useTypedSelector(event => event.LiveEventReducer.displayProfileModal);
	const displayLoadingProfileModal = useTypedSelector(event => event.LiveEventReducer.displayLoadingProfileModal);
	const firesidesHostSessions = useTypedSelector(event => event.LiveEventReducer.firesidesHostSessions);
	const registrationBypass = useTypedSelector(state => !!state.LiveEventReducer.registration_bypass);
	const loadingValidSessions = useTypedSelector(state => state.LiveEventReducer.loadingValidSessions);
	const adminToken = useTypedSelector(state => state.AuthReducer.token);
	const enableRecaptcha = useTypedSelector(state => !!state.LiveEventReducer.eventBundle?.registration_settings?.enableRecaptcha);

	const isRegistrationV2 = useIsRegistrationV2();

	const eventChatEnabled = eventBundle?.social_settings?.messaging;
	const displayLandingPage = shouldDisplayLandingPage(eventBundle);
	const template = getTemplateClassName(eventBundle?.template.name);
	const templateName = (eventBundle?.template.name) || TemplateNames.Classic;
	const isPreview = eventBundle?.is_preview;

	const [isModalPreview, setIsModalPreview] = useState(isPreview);
	const [canShowLoader, setCanShowLoader] = useState(true);
	const [templateLoaded, setTemplateLoaded] = useState(false);
	const [fetchEventBundle, setFetchEventBundle] = useState<'before' | 'during' | 'complete'>('before');

	useThemeUpdate();

	// additional socket with language parameter
	const socketL = useRef<Socket | null>(null);
	const urlParamsParsed = useRef<boolean>(false);

	const { t } = useTranslate(['homepage', 'session']);
	const deferredTranslation = useRef({ t });
	useEventbriteListener();

	const RouteMap = useRouteMap({ debug });
	const [interacted, setInteracted] = useState(false);
	// Prevents duplicated end session API calls from firing
	const [pageSessionAlreadyExists, setPageSessionAlreadyExists] = useState(false);

	const excludeChat = matchPath(pathname, {
		path: [
			//TODO: put firesides route here when the epic is merged in
			RouteMap.Fireside,
			RouteMap.FiresideNoHomepage,
			RouteMap.Registration,
			RouteMap.Breakout
		],
		exact: true
	});

	useTicketHistory(props, uuid);

	useEffect(() => {
		setIsModalPreview(isPreview);
	}, [isPreview]);

	const templateClassName = useTemplateName(eventBundle?.template.name);
	const templateFileName = useTemplateFilename(eventBundle);
	const templateClassname = getTemplateClassName(eventBundle?.template.name);

	const importFilename = useColorPalettesImportFilename({
		filename: templateFileName,
	});

	useEffect(() => {
		async function loadTemplate() {
			// Testing for the Limelight template (will need to uncomment the limelight.scss import statement above as well)
			// if (templateFileName === 'Limelight') {
			// 	setTemplateLoaded(true);
			// 	return;
			// }

			// template logic defaults to Apollo, which even if the correct template is imported
			// eventually, it will still import apollo at some point. This was easier than changing the logic for template above
			if (importFilename) {
				// async import live event template styling
				await import(`../../scss/live-event/${importFilename}`);
				setTemplateLoaded(true);
			}
		}
		if (eventBundle?.template && TemplateClassNames[eventBundle.template.name]) {
			// only load if we have a filename and we have pulled the flags, because if we try and render before we know 
			// the state of the feature flag, we will compile the wrong file
			if (importFilename) {
				loadTemplate();
			}
		} else {
			// the template css will be loaded in the document head, similar to custom css. 
			// We can assume the template is loaded since the document head is only rendered if the event bundle (with the template css) exists
			setTemplateLoaded(true);
		}
	}, [eventBundle?.template, importFilename]);

	useEffect(() => {
		// Boots the user out if their registration has been hidden
		if (eventBundle && registrationDeleted) {
			navigator.sendBeacon(`${HvHostMap.sessionTracking}/update-page-session-end-time`, JSON.stringify({ uuid: pageSession }));
			deregister(eventBundle);
		}
	}, [eventBundle, pageSession, registrationDeleted]);

	const getLanguage = useCallback(() => getAttendeeLocalLanguage(eventBundle), [eventBundle]);

	const language = getLanguage();
	const baseLanguage = eventBundle ? getDefaultLanguage(eventBundle) : '';
	const shouldBlockSessionPageView = true;  // pulled out into a named variable to give context about what this boolean is controlling
	useTrackPageView(shouldBlockSessionPageView, language);

	useCheckUserGating();

	useResetRegistrationSockets();

	// if a user is kicked out of a breakout room, show message
	useCheckBreakoutBootedStatus();

	useErrorUpdatingProfileAlert();

	// if gdpr is on and user is registered but the user has not made a selection, then we hide the cookie banner
	// because when they registered without making the selection we defaulted their choice to opt out
	useEffect(() => {
		if (eventBundle?.uuid && registrationId && eventBundle.settings.gdpr?.enable_cookie_banner) {
			(async () => {
				// set cookie for gdpr upon registration to remove the banner after registration
				try {
					const indexDbCookies = await getCookieIndexDB(eventBundle.uuid);
					if (!indexDbCookies) {
						await setCookieIndexDB(eventBundle.uuid, { cookies_accepted: false });
					}
				} catch (err) {
					console.error(err);
				}
			})();
		}
	}, [
		eventBundle?.settings?.gdpr?.enable_cookie_banner,
		eventBundle?.uuid,
		registrationId,
	]);

	// if storage token then set it in redux
	useEffect(() => {
		const blProfileToken = getStorageItem('Bl-Profile-Token');
		if (blProfileToken) {
			dispatch(setBlProfileToken(blProfileToken));
		}

		trackGoogleAnalytics();
	}, [dispatch]);

	useEffect(() => {
		if (eventBundle) return;

		//REMOVE THIS BLOCK WHEN REMOVING DEPRECATED METHOD
		if (!debug) {
			if (eventNamePass && host) {
				//munging for staging
				dispatch(loadEventBundle(host.replace('brandlive-staging.com', 'brand.live'), eventNamePass));
			}
		} else if (uuid) {
			dispatch(loadEventBundle('dev', uuid));
		}
	}, [uuid, dispatch, debug, host, eventNamePass, eventBundle]);


	useEffect(() => {
		const { t } = deferredTranslation.current;
		if (eventBundle && loadingEventBundle) {
			dispatch(loadPage(eventBundle, t));
		}
	}, [dispatch, eventBundle, loadingEventBundle]);

	useEffect(() => {
		if (loadingEventBundle && fetchEventBundle === 'before') {
			setFetchEventBundle('during');
		}
		if (!loadingEventBundle && fetchEventBundle === 'during') {
			setFetchEventBundle('complete');
		}
	}, [fetchEventBundle, loadingEventBundle]);

	useEffect(() => {
		dispatch(setLiveSessions(playbackUrls));
	}, [playbackUrls, dispatch]);

	useEffect(() => {
		// getPageSessionError prevents this from turning into an infinite loop.
		// if the dispatch(getPageSession...) lambda call fails, then getPageSessionError will be the result string message
		if (blProfile && !gettingPageSession && eventBundle?.event && !getPageSessionError) {
			const storedPageSession = getStorageItem(`pageSession.${eventBundle.event}`);
			if (storedPageSession) {
				dispatch(setPageSession(storedPageSession));
			} else {
				dispatch(getPageSession(eventBundle.event, blProfileUserToken, registrationId ?? undefined, getStoredReferrer(eventBundle?.uuid) ?? undefined));
			}
		}
	}, [blProfile, gettingPageSession, eventBundle, dispatch, blProfileUserToken, getPageSessionError, registrationId]);

	const setupEndPageSessionListeners = useCallback((existingPageSession: string) => {
		const endPageSession = () => {
			dispatch(updatePageSessionEndTime(pageSession));
			navigator.sendBeacon(`${process.env.REACT_APP_HV_HOST}/update-page-session-end-time`, JSON.stringify({ uuid: existingPageSession }));
		};

		const updatePageSessionEndTimeVisibility = () => {
			if (document.visibilityState !== 'hidden') {
				return;
			}

			endPageSession();
		};

		// Not all browsers support visibility change. And some, like safari, just don't fire it when leaving the page.
		// pagehide and (before)unload are less reliable but a good fallback
		document.addEventListener('visibilitychange', updatePageSessionEndTimeVisibility);

		if (typeof window.onpagehide !== 'undefined') {
			window.addEventListener('pagehide', endPageSession);
		} else {
			window.addEventListener('unload', endPageSession);
			window.addEventListener('beforeunload', endPageSession);
		}
	}, [pageSession, dispatch]);

	// Ensures existing page sessions can be ended
	useEffect(() => {
		let existingPageSession: string | undefined;

		for (let i = 0; i < localStorage.length; i++) {
			if (localStorage.key(i)?.includes('pageSession')) {
				existingPageSession = getStorageItem(localStorage.key(i) as string);
			}
		}

		if (!existingPageSession) {
			return;
		}

		setPageSessionAlreadyExists(true);

		return setupEndPageSessionListeners(existingPageSession);
	}, [setPageSessionAlreadyExists, setupEndPageSessionListeners]);

	useEffect(() => {
		if (!pageSession || pageSessionAlreadyExists) {
			return;
		}

		return setupEndPageSessionListeners(pageSession);
	}, [pageSession, setupEndPageSessionListeners, pageSessionAlreadyExists]);

	useEffect(() => {
		if (!userSession) {
			return;
		}

		const updateEndTime = () => {
			dispatch(updateUserSessionEndTime(userSession));
		};

		const updateEndTimeVisibility = () => {
			if (document.visibilityState !== 'hidden') {
				return;
			}

			updateEndTime();
		};

		// Not all browsers support visibility change. And some, like safari, just don't fire it when leaving the page.
		// pagehide and (before)unload are less reliable but a good fallback
		document.addEventListener('visibilitychange', updateEndTimeVisibility);

		if (typeof window.onpagehide !== 'undefined') {
			window.addEventListener('pagehide', updateEndTime);
		} else {
			window.addEventListener('unload', updateEndTime);
			window.addEventListener('beforeunload', updateEndTime);
		}

		return () => {
			dispatch(updatePageSessionEndTime(pageSession));
			dispatch(updateUserSessionEndTime(userSession));
			window.removeEventListener('pagehide', updateEndTime);
			window.removeEventListener('unload', updateEndTime);
			window.removeEventListener('beforeunload', updateEndTime);
			document.removeEventListener('visibilitychange', updateEndTimeVisibility);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession]);

	useBundleUpdateSockets();

	// show announcements in users selected language
	const socketKey = `event-${eventBundle?.uuid}-${language}`;
	useEffect(() => {
		// show announcement as alert
		const handleAnnouncement = ({ data }: { data: Partial<IAnnouncement>; }) => {
			// misc is needed for tracking, and hooks cannot be called in the Alert component
			if (eventBundle) {
				const { source_id, source_type } = getCurrentPageSourceInfo(location.pathname, eventBundle, blProfile?.bl_profile || 0);

				showAnnouncement({ ...data, language, baseLanguage, source_id, source_type });
			}
		};
		//create socket manually - like above - and remove when done
		if (!socketKey.includes('undefined')) {
			// use the language the user has selected via the profile menu or the browser preferred language with fallback to english
			socketL.current = socketManager.get(socketKey);
			socketL.current.addListener(SqsSocketTypes.ANNOUNCEMENT, handleAnnouncement);
		}
		return () => {
			socketManager.leave(socketKey);
			socketL.current?.removeListener(SqsSocketTypes.ANNOUNCEMENT, handleAnnouncement);
		};
	}, [socketKey, baseLanguage, eventBundle, blProfile?.bl_profile, language, location.pathname]);

	useEffect(() => {
		// we need to ensure that this is only called once
		if (urlParamsParsed.current) {
			return;
		}

		// get the initial state from the query string 
		// drop the redirect page, language, and utm params
		const { utmParams, querystring, redirectTo, language, params } = getQueryStringState();

		let path = window.location.pathname;
		const hash = window.location.hash;

		// get unique path for this event by the url, not awaiting the event loading to prevent
		// the url params from being dropped before they are parsed here
		const pathKey = getPathKey();

		// state contains a redirect to page request - language can only be used as a param in conjunction with a page request
		if (redirectTo) {
			path = Redirects.toPage(redirectTo, language as LanguagesAbbr | undefined);
		}

		// store params in object storage for 2 hours
		// use local storage because SSO login redirects the user away from the page
		// and session storage gets cleared out

		// we also don't want to overwrite an empty set of params if the user has been
		// redirectd to this page from SSO login.

		// there is a potential that if someone registers for the event multiple times
		// that the UTM params will be accidentally included in subsequent registrations
		// but this is acceptable. 
		if (Object.keys(utmParams).length) {
			setStorageObject(`utm_params-${pathKey}`, utmParams, 2);
		}

		if (Object.keys(params).length) {
			setStorageObject(`search_params-${pathKey}`, params, 2);
		}

		// clear the redirect component from the state to prevent unwanted redirects
		history.replace(path + hash + (querystring ? `?${querystring}` : ''));
		urlParamsParsed.current = true;
	}, [history]);

	useEffect(() => {
		storeAllQueryParams(locationSearch, eventBundle?.uuid);
		storeReferrer(eventBundle?.uuid);
		//don't think this is even turned on, but in case it is, kill it here.
		if (eventBundle?.channel_settings?.integrations?.google_analytics && eventBundle.channel_settings.integrations.google_analytics.startsWith("UA-")) {
			// window.ga("create", eventBundle.channel_settings.integrations.google_analytics, "auto", `brandlive${eventBundle.uuid}`);
			// window.ga(`brandlive${eventBundle.uuid}.send`, "pageview");
		}
	}, [eventBundle?.channel_settings?.integrations?.google_analytics, eventBundle?.uuid, locationSearch]);

	useEffect(() => {
		if (interacted) return;

		const hasInteracted = () => {
			setInteracted(true);
		};

		window.addEventListener('click', hasInteracted);
		window.addEventListener('touchstart', hasInteracted);

		return () => {
			window.removeEventListener('click', hasInteracted);
			window.removeEventListener('touchstart', hasInteracted);
		};
	}, [interacted]);

	const fadeOutLoader = useCallback(() => {
		setCanShowLoader(false);
	}, []);

	const displayHomepage = shouldDisplayHomepage(eventBundle);
	const renderRedirect = useCallback(() => {
		if (displayHomepage) {
			return <Redirect to={stateReferrer ? stateReferrer : `/${eventPath(eventNamePass, uuid)}/${getLanguage()}/home`} />;
		}
		return <Redirect to={stateReferrer ? stateReferrer : `/${eventPath(eventNamePass, uuid)}/${getLanguage()}`} />;
	}, [displayHomepage, eventNamePass, getLanguage, stateReferrer, uuid]);

	const hasEventAccessMemoized = useCallback((eventBundle: BrandliveEvent): boolean => {
		return hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, registrationBypass, loadingValidSessions);
	}, [registrationBypass, loadingValidSessions, validSessions, finishedRegistering, userVerified, firesidesHostSessions]);

	const handlePageErrorReroute = useCallback((routeOptions: 'home' | 'landing' | 'registration' | 'support' | 'singleSessionPage') => {
		return () => {
			if (window.location.hostname === 'localhost') return;
			let routeTo;
			switch (routeOptions) {
				case 'landing': {
					routeTo = `/${eventPath(eventNamePass, uuid)}/${language}`;
					break;
				}
				case 'singleSessionPage': {
					if (eventBundle?.homepage) {
						const singleSessionUuid = eventBundle?.sessions[0].uuid;
						routeTo = `/${eventPath(eventNamePass, uuid)}/${language}/session/${singleSessionUuid}`;
					} else {
						routeTo = `/${eventPath(eventNamePass, uuid)}/${language}`;
					}
					break;
				}
				case 'home':
				case 'registration': {
					routeTo = `/${eventPath(eventNamePass, uuid)}/${language}/${routeOptions}`;
					break;
				}
			}

			if (routeTo) {
				history.push(routeTo);
				showAlert({
					message: "Error loading page",
					description: "Something went wrong on our end, please try again in a few minutes",
					duration: 7000,
					type: "error"
				});
			}

			TEST_ERROR_BOUNDARIES && dispatch(clearErrorBoundaryComponents());
		};
	}, [dispatch, eventBundle?.homepage, eventBundle?.sessions, eventNamePass, language, history, uuid]);

	const renderRegistrationPage = useCallback(() => {
		if (!eventBundle) return null;
		// if no reg required, redirect to event
		if (hasEventAccessMemoized(eventBundle)) {
			if (enableRecaptcha) {
				addOrRemoveRecaptchaBadge('hidden');
			}

			// navigate to event
			return renderRedirect();
		}

		// else, go to registration page
		return (
			<ErrorBoundary uniqueLabel="Registration page - Homepage ON Registration ON" onError={handlePageErrorReroute("support")} pageNotFound>
				{
					isRegistrationV2 ?
						<RegistrationWrappedV2 /> :
						<RegistrationPage />
				}
			</ErrorBoundary>
		);
	}, [eventBundle, hasEventAccessMemoized, handlePageErrorReroute, isRegistrationV2, enableRecaptcha, renderRedirect]);

	const renderEventPages = useCallback(() => {
		if (!eventBundle) return null;

		const bypassRegistration = hasEventAccessMemoized(eventBundle);
		// const [SocketContext, SocketProvider] = createCtx()

		// if no reg required, render event pages
		if (bypassRegistration) {
			return (
				<Navbar>
					<MarketingRegisteredRoutes debug={debug} onRenderError={handlePageErrorReroute} />
				</Navbar>
			);
		}

		if (displayLandingPage) {
			// if all fails, render landing page
			return (
				<ErrorBoundary uniqueLabel="Landing page - Homepage ON Registration ON" onError={handlePageErrorReroute("support")} pageNotFound>
					<MarketingPage />
				</ErrorBoundary>
			);
		}

		return renderRegistrationPage();
	}, [debug, displayLandingPage, eventBundle, handlePageErrorReroute, renderRegistrationPage, hasEventAccessMemoized]);

	useEffect(() => {
		const listener = () => {
			if (window.location.pathname !== location.pathname) {
				history.replace(
					window.location.pathname
				);
			}
		};

		window.addEventListener('popstate', listener);

		return () => {
			window.removeEventListener('popstate', listener);
		};
	}, [history, location.pathname]);

	if (registrationDeleted) {
		// Prevents screen flashing on load
		return <></>;
	}

	// these must go after all the hooks
	if (_404 || (fetchEventBundle === 'complete' && !eventBundle?.event)) {
		return (<PageNotFound event />);
	}

	if (!eventBundle || !templateLoaded) {
		return (
			<Loader />
		);
	}

	const isSingleSession = eventBundle.sessions?.length === 1;

	if (isModalPreview) {
		return <PreviewPasscodeChecker setIsModalPreview={setIsModalPreview} />;
	}

	return displayHomepage ? (
		<div className={classNames("live-event-container", `${templateClassname}-styles`, templateClassName, templateFileName, { "force-rtl": isRtlLanguage(language) })}>
			<LiveEventDocumentHead eventBundle={eventBundle} />
			<ScrollToTop />
			<LocationTracking debug={debug} />
			<Switch>
				<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> {/* Removes trailing slashes */}
				{(shouldRedirectToRegistration && !registrationDeleted && !registrationBypass && !loadingValidSessions) && (
					<Route path={[RouteMap.Home, RouteMap.Landing, RouteMap.Session, RouteMap.Breakout, RouteMap.Fireside, RouteMap.Leaderboard, RouteMap.CustomPage]} exact={true}>
						<Redirect to={{
							pathname: Redirects.toRegistration(getLanguage()),
							state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
						}} />
					</Route>
				)}
				<Route path={RouteMap.Breakout}>
					{!hasEventAccessMemoized(eventBundle)
						? <Redirect to={{
							pathname: Redirects.toRegistration(getLanguage()),
							state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
						}} />
						: <Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel={`Breakout room page - Homepage ON${eventBundle.registration_on ? " Registration ON" : ''}`} onError={handlePageErrorReroute("home")}>
								<BreakoutRoom />
							</ErrorBoundary>
						</Suspense>}
				</Route>
				<Route exact path={RouteMap.Fireside}>
					<ErrorBoundary uniqueLabel={`Fireside stage page - Homepage ON${eventBundle.registration_on ? " Registration ON" : ''}`} onError={handlePageErrorReroute("home")}>
						{!hasEventAccessMemoized(eventBundle)
							? <Redirect to={{
								pathname: Redirects.toRegistration(getLanguage()),
								state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
							}} />
							: eventBundle.sessions?.length > 0
								? (
									<Suspense fallback={<Loader />}>
										<Fireside singleSession={isSingleSession} isOnStage={true} interacted={interacted} isEditor={isEditor} />
									</Suspense>
								) : (
									<Loader />
								)}
					</ErrorBoundary>
				</Route>
				<Route exact path={RouteMap.Session}>
					<ErrorBoundary uniqueLabel={`Session page - Homepage ON Registration${eventBundle.registration_on ? " ON" : " OFF"}`} onError={handlePageErrorReroute("home")}>
						{(eventBundle.registration_on ? (
							<>
								{loadingEventBundle ? (
									<WaitingIndicator fillSpace />
								) : (
									<>
										{hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, registrationBypass, loadingValidSessions) ?
											<Navbar canHide displayMenu onSession>
												<Session interacted={interacted} debug={debug} />
											</Navbar>
											: <Redirect to={{
												pathname: `/${eventPath(eventNamePass, uuid)}/${language}/registration`,
												state: { referrer: window.location.pathname }
											}} />
										}
									</>
								)}
							</>
						) :
							<Navbar canHide displayMenu onSession>
								<Session interacted={interacted} debug={debug} />
							</Navbar>
						)}
					</ErrorBoundary>
				</Route>
				<Route path={RouteMap.Registration}>
					{renderRegistrationPage()}
				</Route>
				<Route path={RouteMap.PrivacyPolicy}>
					<Suspense fallback={<Loader />}>
						<ErrorBoundary uniqueLabel="privacy policy page - Homepage ON" onError={handlePageErrorReroute("landing")}>
							<PrivacyPolicy />
						</ErrorBoundary>
					</Suspense>
				</Route>
				<Route path={RouteMap.TermsConditions}>
					<Suspense fallback={<Loader />}>
						<ErrorBoundary uniqueLabel="terms and conditions page - Homepage ON" onError={handlePageErrorReroute("landing")}>
							<TermsConditions />
						</ErrorBoundary>
					</Suspense>
				</Route>
				<Route path={[RouteMap.Home, RouteMap.Sessions, RouteMap.Profile, RouteMap.AttendeeProfile, RouteMap.CustomPage, RouteMap.Directory, RouteMap.Leaderboard, RouteMap.Watchlist]}>
					{(
						// any path will just render the landing page if user has not regstered yet, redirect them to registration page if they are on landing
						eventBundle.registration_on &&
						!hasEventAccessMemoized(eventBundle) &&
						!canShowLoader
					) ? (
						<Redirect
							to={{
								pathname: Redirects.toRegistration(getLanguage()),
								state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
							}}
						/>
					) : (
						<>
							{renderEventPages()}
						</>
					)}
				</Route>
				<Route path={RouteMap.Landing}>
					{registrationId && (<MarketingRegisteredRoutes debug={debug} onRenderError={handlePageErrorReroute} />)}
					{!registrationId && displayLandingPage && (
						<ErrorBoundary uniqueLabel={`landing page - Homepage ON${eventBundle.registration_on ? " Registration ON" : ''}`} onError={handlePageErrorReroute("support")} pageNotFound>
							<MarketingPage />
						</ErrorBoundary>)}
					{!registrationId && !displayLandingPage && renderRegistrationPage()}
				</Route>
				<Route path={RouteMap.LandingRedirect}>
					<Redirect to={`/${eventPath(eventNamePass, uuid)}/${language}`} />
				</Route>
			</Switch>

			<Suspense fallback="">
				<ErrorBoundary uniqueLabel="Non registered user form - Homepage ON">
					<NonRegisteredUserForm
						event_uuid={eventBundle.uuid}
						event={eventBundle.event}
						channel={eventBundle.channel}
					/>
				</ErrorBoundary>
			</Suspense>

			<OptionalComponent display={!!TEST_ERROR_BOUNDARIES}>
				<Suspense fallback="">
					<TestErrorBoundary />
				</Suspense>
			</OptionalComponent>

			{eventChatEnabled && blProfile && blProfileUserToken && !excludeChat && registrationId && (
				<Suspense fallback="">
					<ErrorBoundary uniqueLabel="Event chat - Homepage ON">
						<EventChatMain
							template={templateName}
							eventBundle={eventBundle}
						/>
					</ErrorBoundary>
				</Suspense>
			)}

			{(displayProfile || displayLoadingProfileModal) ? (
				<Suspense fallback="">
					<ErrorBoundary uniqueLabel="Profile modal - Homepage ON">
						<ProfileModal
							open={true}
							onClose={() => dispatch(setDisplayProfileModal(null))}
							profile={displayProfile}
							template={template}
							eventBundle={eventBundle}
							language={language}
							history={history}
							displayLoadingProfileModal={displayLoadingProfileModal}
						/>
					</ErrorBoundary>
				</Suspense>
			) : null}

			{
				canShowLoader && (
					<div
						className={classNames("live-event-waiting", { "fade-out": (!loadingEventBundle && translationUpdates > 0) })}
						onTransitionEnd={fadeOutLoader}
						ref={ref => {
							// if this starts rendering with the fade-out class we need to hide immediately
							if (!loadingEventBundle && translationUpdates > 0) {
								fadeOutLoader();
							}
						}}
					>
						<WaitingIndicator fillSpace />
					</div>
				)
			}
			<Alerts />

			{/* sticky position on live event was creating extra space at bottom of page, so setting fixed position for live event */}
			<CookieBannerStickyWrapper fixed>
				<CookieBanner
					language={language}
					eventName={eventNamePass}
					forceHideBanner={!!registrationId} // because we are defaulting registrations to opting out, we don't need to show the banner	after registration
				/>
			</CookieBannerStickyWrapper>

			<MousePopup />
		</div>
	) : (
		<div className={classNames("live-event-container", `${templateClassname}-styles`, templateClassName, templateFileName, { "force-rtl": isRtlLanguage(language) })}>
			<LiveEventDocumentHead eventBundle={eventBundle} />
			<ScrollToTop />
			<LocationTracking debug={debug} />
			{eventBundle.registration_on ? (
				<Switch>
					<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> {/* Removes trailing slashes */}
					{(shouldRedirectToRegistration && !registrationDeleted && !registrationBypass && !loadingValidSessions) && (
						<Route path={displayLandingPage ? [RouteMap.Breakout] : [RouteMap.Landing, RouteMap.Breakout]} exact={true}>
							<Redirect to={Redirects.toRegistration(getLanguage())} />
						</Route>
					)}
					<Route path={RouteMap.Breakout}>
						{!hasEventAccessMemoized(eventBundle)
							? <Redirect to={{
								pathname: Redirects.toRegistration(getLanguage()),
								state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
							}} />
							: <Suspense fallback={<Loader />}>
								<ErrorBoundary uniqueLabel="Breakout room page - Homepage OFF Registration ON" onError={handlePageErrorReroute("singleSessionPage")}>
									<BreakoutRoom />
								</ErrorBoundary>
							</Suspense>}
					</Route>
					<Route exact path={RouteMap.FiresideNoHomepage}>
						{!hasEventAccessMemoized(eventBundle)
							? <Redirect to={{
								pathname: Redirects.toRegistration(getLanguage()),
								state: { referrer: Redirects.postRegistrationReferrer(eventBundle) }
							}} />
							: eventBundle.sessions?.length > 0
								? (
									<Suspense fallback={<Loader />}>
										<ErrorBoundary uniqueLabel="Fireside stage page - Homepage OFF Registration ON" onError={handlePageErrorReroute("singleSessionPage")}>
											<Fireside singleSession={isSingleSession} isOnStage={true} interacted={interacted} isEditor={isEditor} />
										</ErrorBoundary>
									</Suspense>
								) : (
									<Loader />
								)}
					</Route>
					<Route path={RouteMap.Registration}>
						<ErrorBoundary uniqueLabel="Registration page - Homepage OFF" onError={handlePageErrorReroute("support")} pageNotFound>
							{
								isRegistrationV2 ?
									<RegistrationWrappedV2 /> :
									<RegistrationPage />
							}
						</ErrorBoundary>
					</Route>
					<Route path={RouteMap.Profile}>
						<Navbar>
							<MarketingRegisteredRoutes debug={debug} onRenderError={handlePageErrorReroute} />
						</Navbar>
					</Route>
					<Route exact path={RouteMap.LandingRedirect}>
						<Redirect to={`/${eventPath(eventNamePass, uuid)}/${getLanguage()}`} />
					</Route>
					<Route path={RouteMap.PrivacyPolicy}>
						<Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel="Privacy policy page - Homepage OFF Registration ON" onError={handlePageErrorReroute("registration")}>
								<PrivacyPolicy />
							</ErrorBoundary>
						</Suspense>
					</Route>
					<Route path={RouteMap.TermsConditions}>
						<Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel="Terms and conditions page - Homepage OFF Registration ON" onError={handlePageErrorReroute("registration")}>
								<TermsConditions />
							</ErrorBoundary>
						</Suspense>
					</Route>

					<Route path={RouteMap.Landing}>
						<ErrorBoundary uniqueLabel="Session page - Homepage OFF Registration ON" onError={handlePageErrorReroute("support")} pageNotFound>
							{loadingEventBundle ? (
								<></>
							) : (

								<>
									{(registrationId) ? (
										<Navbar onSession={true} canHide displayMenu>
											<Session singleSession={true} interacted={interacted} debug={debug} />
										</Navbar>
									) : (
										<>
											{
												displayLandingPage ? (
													<MarketingPage />
												) : (
													<>{!!eventBundle && <Redirect to={Redirects.toRegistration(getLanguage())} />}</>
												)
											}
										</>
									)}
								</>
							)}
						</ErrorBoundary>
					</Route>
				</Switch>
			) : (
				<Switch>
					<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> {/* Removes trailing slashes */}
					<Route path={RouteMap.Breakout}>
						<Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel="Breakout room page - Homepage OFF Registration OFF" onError={handlePageErrorReroute("singleSessionPage")}>
								<BreakoutRoom />
							</ErrorBoundary>
						</Suspense>
					</Route>
					<Route exact path={RouteMap.Session}>
						<ErrorBoundary uniqueLabel={`Session page - Homepage ON Registration${eventBundle.registration_on ? " ON" : " OFF"}`} onError={handlePageErrorReroute("home")}>
							{(loadingEventBundle ? (
								<WaitingIndicator fillSpace />
							) :
								<Navbar canHide displayMenu onSession>
									<Session interacted={interacted} debug={debug} />
								</Navbar>
							)}
						</ErrorBoundary>
					</Route>
					<Route exact path={RouteMap.Fireside}>
						<ErrorBoundary uniqueLabel="Fireside stage - Homepage OFF Registration OFF" onError={handlePageErrorReroute("singleSessionPage")}>
							{eventBundle.sessions?.length > 0 ? (
								<Suspense fallback={<Loader />}>
									<Fireside singleSession={isSingleSession} isOnStage={true} interacted={interacted} isEditor={isEditor} />
								</Suspense>
							) : (
								<Loader />
							)}
						</ErrorBoundary>
					</Route>
					<Route path={RouteMap.PrivacyPolicy}>
						<Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel="Privacy policy page - Homepage OFF Registration OFF" onError={handlePageErrorReroute("singleSessionPage")}>
								<PrivacyPolicy />
							</ErrorBoundary>
						</Suspense>
					</Route>
					<Route path={RouteMap.TermsConditions}>
						<Suspense fallback={<Loader />}>
							<ErrorBoundary uniqueLabel="Terms and conditions page - Homepage OFF Registration OFF" onError={handlePageErrorReroute("singleSessionPage")}>
								<TermsConditions />
							</ErrorBoundary>
						</Suspense>
					</Route>
					<Route path={RouteMap.Landing}>
						{displayLandingPage ? (
							<ErrorBoundary uniqueLabel="Landing page - Homepage OFF Registration OFF" onError={handlePageErrorReroute("support")} pageNotFound>
								<MarketingPage />
							</ErrorBoundary>
						) : (
							<ErrorBoundary uniqueLabel="Session page - Homepage OFF Registration OFF" onError={handlePageErrorReroute("support")} pageNotFound>
								<Navbar canHide displayMenu onSession>
									<Session singleSession={true} interacted={interacted} debug={debug} />
								</Navbar>
							</ErrorBoundary>
						)}
					</Route>
					<Route path={RouteMap.LandingRedirect}>
						<Redirect to={`/${eventPath(eventNamePass, uuid)}/${getLanguage()}`} />
					</Route>
				</Switch>
			)}
			{(displayProfile || displayLoadingProfileModal) ? (
				<Suspense fallback="">
					<ErrorBoundary uniqueLabel="Profile modal - Homepage OFF">
						<ProfileModal
							open={true}
							onClose={() => dispatch(setDisplayProfileModal(null))}
							profile={displayProfile}
							template={template}
							eventBundle={eventBundle}
							language={language}
							history={history}
							displayLoadingProfileModal={displayLoadingProfileModal}
						/>
					</ErrorBoundary>
				</Suspense>
			) : null}

			{eventBundle && eventChatEnabled && blProfile && blProfileUserToken && !excludeChat && registrationId && (
				<Suspense fallback="">
					<ErrorBoundary uniqueLabel="Event chat - Homepage OFF">
						<EventChatMain
							template={templateName}
							eventBundle={eventBundle}
						/>
					</ErrorBoundary>
				</Suspense>
			)}

			<Suspense fallback="">
				<ErrorBoundary uniqueLabel="Non-registered user modal - Homepage OFF">
					<NonRegisteredUserForm
						event_uuid={eventBundle.uuid}
						event={eventBundle.event}
						channel={eventBundle.channel}
					/>
				</ErrorBoundary>
			</Suspense>
			<OptionalComponent display={!!TEST_ERROR_BOUNDARIES}>
				<Suspense fallback="">
					<TestErrorBoundary />
				</Suspense>
			</OptionalComponent>

			{canShowLoader && (
				<div
					className={classNames("live-event-waiting", {
						"fade-out": (!loadingEventBundle && translationUpdates > 0)
					})}
					ref={ref => {
						// if this starts rendering with the fade-out class we need to hide immediately
						if (!loadingEventBundle && translationUpdates > 0) {
							fadeOutLoader();
						}
					}}
					onTransitionEnd={fadeOutLoader}
				>
					<WaitingIndicator fillSpace />
				</div>
			)}
			<Alerts />

			{/* sticky position on live event was creating extra space at bottom of page, so setting fixed position for live event */}
			<CookieBannerStickyWrapper fixed>
				<CookieBanner
					language={language}
					eventName={eventNamePass}
					forceHideBanner={!!registrationId} // because we are defaulting registrations to opting out, we don't need to show the banner	after registration
				/>
			</CookieBannerStickyWrapper>
			<MousePopup />
		</div>
	);
};

export default LiveEvent;
