import { AsyncAction, Action } from "../../../types/actions";
import {
	GetPeople,
	SearchPeople,
	GetEventRegistrations,
	GetEventRegistrationsCount,
	SearchEventRegistrations,
	GetPeopleCount,
	GetPeopleSearchCount,
	GetEventRegisteredUsers,
	UpdateRegistrationsAttendedStatus
} from "../../../connection/people";
import { MarkedAttendedRegistration } from "../../../types/working-model";

export const GET_PEOPLE = "GET_PEOPLE";
export function getPeople(
	token: string,
	channel: number,
	offset: number,
	order: number
): AsyncAction {
	return {
		type: GET_PEOPLE,
		promise: GetPeople(token, channel, offset * 50, order)
	};
}

export const SEARCH_PEOPLE = "SEARCH_PEOPLE";
export function searchPeople(
	token: string,
	channel: number,
	value: string,
	offset: number,
	order: number
): AsyncAction {
	return {
		type: SEARCH_PEOPLE,
		promise: SearchPeople(token, channel, value.toLowerCase(), offset * 50, order)
	};
}

export const ADD_SELECTED_PERSON = "ADD_SELECTED_PERSON";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addSelectedPerson(person: any): Action {
	return {
		type: ADD_SELECTED_PERSON,
		payload: person
	};
}

export const REMOVE_SELECTED_PERSON = "REMOVE_SELECTED_PERSON";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function removeSelectedPerson(person: any): Action {
	return {
		type: REMOVE_SELECTED_PERSON,
		payload: person
	};
}

export const CLEAR_SELECTED_PEOPLE = "CLEAR_SELECTED_PEOPLE";
export function clearSelectedPeople(): Action {
	return {
		type: CLEAR_SELECTED_PEOPLE,
		payload: ""
	};
}

export const GET_EVENT_REGISTRATIONS = "GET_EVENT_REGISTRATIONS";
export function getEventRegistrations(
	token: string,
	channel: number,
	event: number,
	offset: number,
	order: number,
	getDeleted: boolean = false
): AsyncAction {
	return {
		type: GET_EVENT_REGISTRATIONS,
		promise: GetEventRegistrations(token, channel, event, offset * 50, order, getDeleted)
	};
}

export const SEARCH_EVENT_REGISTRATIONS = "SEARCH_EVENT_REGISTRATIONS";
export function searchEventRegistrations(
	token: string,
	channel: number,
	value: string,
	offset: number,
	order: number,
	event: number,
	getDeleted: boolean = false
): AsyncAction {
	return {
		type: SEARCH_EVENT_REGISTRATIONS,
		promise: SearchEventRegistrations(token, channel, value.toLowerCase(), offset * 50, order, event, getDeleted)
	};
}

export const GET_EVENT_REGISTRATIONS_COUNT = "GET_EVENT_REGISTRATIONS_COUNT";
export function getEventRegistrationsCount(token: string, channel: number, value: string, event: number, getDeleted = false):
	AsyncAction {
	return {
		type: GET_EVENT_REGISTRATIONS_COUNT,
		promise: GetEventRegistrationsCount(token, channel, value, event, getDeleted)
	};
}

export const GET_PEOPLE_COUNT = "GET_PEOPLE_COUNT";
export function getPeopleCount(token: string, channel: number): AsyncAction {
	return {
		type: GET_PEOPLE_COUNT,
		promise: GetPeopleCount(token, channel)
	};
}

export const GET_PEOPLE_SEARCH_COUNT = "GET_PEOPLE_SEARCH_COUNT";
export function getPeopleSearchCount(
	token: string,
	channel: number,
	value: string
): AsyncAction {
	return {
		type: GET_PEOPLE_SEARCH_COUNT,
		promise: GetPeopleSearchCount(token, channel, value)
	};
}

export const UPDATE_EVENT_REGISTRATIONS_SEARCH = "UPDATE_EVENT_REGISTRATIONS_SEARCH";
export function updateEventRegistrationsSearch(value: string): Action {
	return {
		type: UPDATE_EVENT_REGISTRATIONS_SEARCH,
		payload: value
	};
}

export const UPDATE_PEOPLE_SEARCH = "UPDATE_PEOPLE_SEARCH";
export function updatePeopleSearch(value: string): Action {
	return {
		type: UPDATE_PEOPLE_SEARCH,
		payload: value
	};
}

export const GET_EVENT_REGISTERED_USERS = 'GET_EVENT_REGISTERED_USERS';
export function getEventRegisteredUsers(
	token: string,
	eventUuid: string,
	offset: number,
): AsyncAction {
	return {
		type: GET_EVENT_REGISTERED_USERS,
		promise: GetEventRegisteredUsers(token, eventUuid, offset),
	};
}

export const CLEAR_EVENT_REGISTERED_USERS = 'CLEAR_EVENT_REGISTERED_USERS';
export function clearEventRegisteredUsers(): Action {
	return {
		type: CLEAR_EVENT_REGISTERED_USERS,
		payload: []
	};
}

export const UPDATE_REGISTRATIONS_ATTENDED_STATUS = 'UPDATE_REGISTRATIONS_ATTENDED_STATUS';
export function updateRegistrationsAttendedStatus(
	token: string,
	registrationUUIDS: string[],
	markedAttendedByAdmin: MarkedAttendedRegistration
): AsyncAction {
	return {
		type: UPDATE_REGISTRATIONS_ATTENDED_STATUS,
		promise: UpdateRegistrationsAttendedStatus(token, registrationUUIDS, markedAttendedByAdmin),
	};
}
