import { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from 'react-router';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';

import { useAppDispatch, useThunkDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { CustomPage, EventSettings, PageGate, PageGating, PasscodeList, PASSCODE_LIST_TYPE } from "../../../../../../../types/working-model";
import { updateEventSettings } from "../../../../../../../store/actions/admin/create-event";
import { getPasscodeLists, addPasscodeList } from "../../../../../../../store/actions/admin/passcode-lists";
import { CreateNewPasscodeList } from "../../../../../../../connection/passcode-lists";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { reactSelectStyles, TReactSelectOption, adminModalMultiSelectStyling } from '../../../../../../general-ui/react-select/react-select-styles';
import { ParamsProps } from '../../../../../../live-event/live-event';
import { WorkingPasscodeList } from "../../../../registration/passcode-list";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import { Popover } from "../../../../../../general-ui/popover/popover";
import { useFieldRequired, useSetRequiredField } from '../../../../../../../utils/registration-required-fields';
import { PATHNAMES } from "../../../../../../../utils/admin-routing-utils";
import { isArray, uniqueId } from "underscore";
import { OptionalComponent } from "utils/optional-component";
import TagSelectInput from "@general-ui/tag-select/tag-select";

const defaultPageGating: PageGating = {
	isOn: false,
	pageGates: []
};
interface CardCustomPageGatingRequirementProps {
	setOpenPageGating: (value: boolean) => void;
	setIsAddNewPageGating: (value: boolean) => void;
	openPageGating: boolean;
	creatingNewList: boolean;
	setCreatingNewList: (value: boolean) => void;
}

const CardCustomPageGatingRequirement = ({
	setOpenPageGating,
	openPageGating,
	setIsAddNewPageGating,
	creatingNewList,
	setCreatingNewList

}: CardCustomPageGatingRequirementProps): JSX.Element => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const updating = useTypedSelector(state => state.CreateEventReducer.isSettingsUpdating);
	const allPasscodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);
	const availablePasscodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const passcodeListOptions = availablePasscodeLists.map((list: PasscodeList) => list.name);

	const { language } = useParams() as ParamsProps;
	const dispatch = useAppDispatch();
	const thunkDispatch = useThunkDispatch();

	const [showEditOptions, setShowEditOptions] = useState<number | null>(null);
	const [newGate, setNewGate] = useState<PageGate>({ page: 0, passcodeLists: [] });
	const [passcodeListToCreate, setPasscodeListToCreate] = useState<WorkingPasscodeList | undefined>(undefined);
	const [passcodeListNameError, setPasscodeListNameError] = useState<string | undefined>(undefined);
	const [passcodeListURLError, setPasscodeListURLError] = useState<string | undefined>(undefined);
	const [uploading, setUploading] = useState<boolean>(false);
	const [editGateIndex, setEditGateIndex] = useState<number | null>(null);

	const disableSave: boolean = updating || uploading || !newGate.page ||
		(!newGate.passcodeLists.length && !passcodeListToCreate);

	const customPages: CustomPage[] = useMemo(() => workingEvent?.custom_pages ?? [], [workingEvent?.custom_pages]);
	// if page gating has not yet been created for an event, insert default settings
	const pageGating: PageGating = useMemo(() => workingEvent?.settings.pageGating || defaultPageGating, [workingEvent?.settings.pageGating]);

	// monitoring gating changes to ensure passcode is on and required when gating is on
	const isFieldRequired = useFieldRequired();
	useSetRequiredField(isFieldRequired);

	const getPageName = (pageNumber: CustomPage["custom_page"]): string => {
		const page = customPages.find((page: CustomPage) => page.custom_page === pageNumber);
		if (!page) return "";
		return page.page_name[language] as string || page.page_name.base;
	};

	const openModal = useCallback((): void => {
		if (!user || !token) return;
		dispatch(getPasscodeLists(user.active_channel, token));
		setOpenPageGating(true);
	}, [dispatch, setOpenPageGating, token, user]);

	const closeModal = (): void => {
		setNewGate({ page: 0, passcodeLists: [] });
		setPasscodeListToCreate(undefined);
		setPasscodeListNameError(undefined);
		setPasscodeListURLError(undefined);
		setEditGateIndex(null);
		setOpenPageGating(false);
		setIsAddNewPageGating(false);
		setCreatingNewList(false);
	};

	const toggleGating = useCallback((_: string, isOn: boolean): void => {
		if (!workingEvent || !token) return;

		if (isOn) {
			!pageGating.pageGates.length && openModal();
		}

		const updatedSettings: EventSettings = {
			...workingEvent.settings,
			pageGating: { ...pageGating, isOn: isOn }
		};
		thunkDispatch(updateEventSettings(token, workingEvent.uuid, updatedSettings));
	}, [openModal, pageGating, thunkDispatch, token, workingEvent]);

	// if all custom pages are deleted or registration is turned off, toggle off gating (assuming we will eventually be able to delete custom pages)
	useEffect(() => {
		if (pageGating.isOn && (!customPages.length || !workingEvent?.registration_on)) {
			toggleGating("", false);
		}
	}, [customPages, pageGating.isOn, toggleGating, workingEvent?.registration_on]);

	const pageOptions: TReactSelectOption[] = useMemo(() => {
		const gatedPages = pageGating.pageGates.map((gate: PageGate) => gate.page);
		// only pages that aren't already gated and (if editing) the page in the gate being edited
		const pagesToGate = customPages.filter((page: CustomPage) => !gatedPages.includes(page.custom_page) || page.custom_page === newGate.page);
		return pagesToGate.map((page: CustomPage) => ({
			value: page.custom_page.toString(),
			label: page.page_name[language] as string || page.page_name.base
		}));
	}, [customPages, language, newGate.page, pageGating.pageGates]);


	const handlePage = (value: TReactSelectOption | null): void => {
		const newPage = value?.value ? parseInt(value.value) : 0;
		setNewGate({ ...newGate, page: newPage });
	};

	const handlePasscodeLists = (listNames: string[]): void => {
		const updatedLists: PasscodeList[] = allPasscodeLists.filter(list => listNames.includes(list.name));
		setNewGate({ ...newGate, passcodeLists: updatedLists });
	};

	const updatePageGates = (updatedGates: PageGate[]): void => {
		if (!workingEvent || !token) return;

		const updatedSettings: EventSettings = {
			...workingEvent.settings,
			pageGating: {
				...pageGating,
				pageGates: updatedGates,
				isOn: !!updatedGates.length // if there are any gates, gating is on, else off
			}
		};
		thunkDispatch(updateEventSettings(token, workingEvent.uuid, updatedSettings));
	};

	const onSave = async (): Promise<void> => {
		if (!pageGating || !user || !token || fetchingPublishedStatus) return;

		// Prevent attaching a magic link list to the event if event has never been published
		const hasMagicLinks = creatingNewList
			? passcodeListToCreate?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS
			: newGate.passcodeLists.some((list) => {
				return list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS;
			});
		if (hasMagicLinks && !publishedUrl) {
			showAlert({
				message: 'Please Publish Project First',
				description: `You need to publish ${workingEvent?.name || 'this project'} first before you can attach a magic links list`,
				type: 'error',
				duration: 5000,
			});
			return;
		}

		let newPasscodeList;
		if (passcodeListToCreate) {
			const noName = "Your list must have a name.";
			const noURL = "You must upload an audience list.";
			const duplicateName = `'${passcodeListToCreate.name}' is already in use. Please enter a unique name.`;

			setPasscodeListNameError(!passcodeListToCreate.name ? noName : undefined);
			setPasscodeListURLError(!passcodeListToCreate.url ? noURL : undefined);
			if (!passcodeListToCreate.name || !passcodeListToCreate.url) return;

			const isDuplicate: boolean = allPasscodeLists
				.some((list: PasscodeList) => list.name.toLowerCase() === passcodeListToCreate.name.toLowerCase());
			setPasscodeListNameError(isDuplicate ? duplicateName : undefined);
			if (isDuplicate) return;

			setUploading(true);

			try {
				newPasscodeList = await CreateNewPasscodeList(token, user.active_channel, passcodeListToCreate, workingEvent?.uuid);
				if (newPasscodeList?.error) {
					showAlert({
						message: 'Unable to Create',
						description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
						type: 'error',
						duration: 5000,
					});
					return;
				}
				if (isArray(newPasscodeList.failedEmails) && newPasscodeList.failedEmails.length > 0) {
					showAlert({
						message: 'Some emails were invalid',
						description: newPasscodeList.failedEmails.join(),
						type: 'error',
						duration: 5000
					});
				}
				dispatch(addPasscodeList(newPasscodeList));
			} catch {
				showAlert({
					message: 'Unable to Create',
					description: 'Failed to create new audience list',
					type: 'error',
					duration: 5000,
				});
				return;
			} finally {
				setUploading(false);
			}
		}

		const updatedGates: PageGate[] = [...pageGating.pageGates];
		const gateToUpdate: PageGate = newPasscodeList ? { ...newGate, passcodeLists: [newPasscodeList] } : newGate;

		if (editGateIndex !== null) {
			updatedGates.splice(editGateIndex, 1, gateToUpdate);
		} else {
			updatedGates.push(gateToUpdate);
		}

		updatePageGates(updatedGates);
		setOpenPageGating(false);
		closeModal();
	};

	const openEditOptions = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		e.preventDefault();
		setShowEditOptions(index);
	};

	const editPageGate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, gateIndex: number): void => {
		e.stopPropagation();

		setNewGate(pageGating.pageGates[gateIndex]);
		setEditGateIndex(gateIndex);
		setOpenPageGating(true);
		setShowEditOptions(null);
	};

	const deletePageGate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, gateIndex: number): void => {
		e.stopPropagation();

		if (!pageGating) return;
		const updatedGates: PageGate[] = [...pageGating.pageGates];
		updatedGates.splice(gateIndex, 1);

		updatePageGates(updatedGates);
		setShowEditOptions(null);
	};

	const defaultTags = pageOptions.map(option => option.label).filter(option => option === newGate.page.toString());

	return (
		<>
			{/* Displays the added gates */}
			{!openPageGating && !!pageGating.pageGates.length && pageGating.pageGates.map((gate: PageGate, index: number) => (
				<div className="display-options" key={`${gate.passcodeLists.join('')}-${index}`}>
					<div onClick={(e) => openEditOptions(e, index)} className="no-style options-button theme-btn">
						<Icon name={ICONS.THREE_DOTS_VERTICAL} color={COLORS.WHITE} size={16} />

						<Popover open={showEditOptions === index} onClose={() => setShowEditOptions(null)}>
							<div className="row">
								<Icon name={ICONS.EDIT} color={COLORS.WHITE} size={16} />
								<button onClick={(e) => editPageGate(e, index)}>Edit</button>
							</div>

							<div className="row">
								<Icon name={ICONS.DELETE} color={COLORS.WHITE} size={16} />
								<button onClick={(e) => {
									// stop propagation to parent element as to not fire that onClick as well.
									e.stopPropagation();
									deletePageGate(e, index);
								}}>Delete</button>
							</div>
						</Popover>
					</div>

					<div className="display-option-block">
						<div className="display-option-title">Gated Pages</div>
						<div className="display-option-content">{getPageName(gate.page)} </div>
					</div>

					<div className="display-option-block">
						<div className="display-option-title">Audience Lists</div>
						<div className="display-option-content">
							<ul className="tag-list">
								{gate?.passcodeLists?.map((list: PasscodeList) => (
									<Link
										role="button"
										key={list.passcode_list + uniqueId()}
										to={PATHNAMES.AudienceList.AudienceListLink(workingEvent ? workingEvent?.uuid : "", language, list.uuid)}
										className={`clear no-style ${list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? "cursor-type-normal" : ""}`}
										onClick={list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? (event) => event.preventDefault() : undefined}
									>
										<li className="tag" key={list.passcode_list + uniqueId()} >
											{list?.name}
										</li>
									</Link>
								))}
							</ul>
						</div>
					</div>
				</div>
			))}

			{/* Displays the exiting gate view*/}
			<OptionalComponent display={openPageGating && !creatingNewList}>
				<div className="display-options">
					<div className="display-option-block">
						<div className="display-option-title">
							<p>Gated pages</p>
							<button
								onClick={() => {
									setCreatingNewList(true);
									setIsAddNewPageGating(true);
								}}
								className="add-new no-style no-padding"
							>
								Add
							</button>
						</div>
						<div className="display-option-content">
							<ReactSelect
								aria-label="custom page select"
								className={"react-select"}
								tabSelectsValue={false}
								options={pageOptions}
								defaultValue={pageOptions.find((option: TReactSelectOption) => option.value === newGate.page.toString())}
								onChange={v => handlePage(v)}
								placeholder={"Choose pages to gate"}
								closeMenuOnSelect={true}
								styles={reactSelectStyles(adminModalMultiSelectStyling())}
								isClearable={true}
							/>
						</div>
					</div>


					<div className="display-option-block">
						<div className="display-option-title">Audience lists</div>
						<div className="display-option-content">
							<TagSelectInput
								onChange={handlePasscodeLists}
								defaultTags={passcodeListOptions}
								tags={newGate.passcodeLists?.map((list) => list.name) || []}
								restrictToList
								shouldSearch
							/>
						</div>
					</div>
				</div>

				<div className="requirement-footer">
					<button onClick={closeModal}>Cancel</button>
					<button onClick={onSave} className="primary rainbow">	{updating || fetchingPublishedStatus ? <WaitingIndicator /> : 'Save'}</button>
				</div>
			</OptionalComponent>

		</>
	);
};

export default CardCustomPageGatingRequirement;
