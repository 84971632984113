import { useCallback, useEffect, useState } from "react";
import { batch, useDispatch } from "react-redux";
import ReactSelect, { components } from 'react-select';
import { v4 as uuidV4 } from 'uuid';

import { setADropdownIsOpen, setEventTags, updateWorkingEventSession } from "../../../store/actions/admin/create-event";
import { updateSession } from "../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { IEventTag, Session } from "../../../types/working-model";
import { rgbToHex } from "../../../utils/utils";
import Icon, { COLORS, ICONS } from "../../general-ui/icon";
import ModalComponent from "../../general-ui/modal/modal";
import { reactSelectStyles } from "../../general-ui/react-select/react-select-styles";
import TextInput, { Validation } from "../../general-ui/text-input/text";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import { useGetColorOptionsFromColor } from "utils/colors/use-get-color-options-from-color";
import { hex, ColorValueIndexes } from "types/theme-packs";
import { QUILL_COLORS } from "@general-ui/editable-text/quill-editor-wrapped";

import './create-event-tag.scss';

// custom react select options
const ColorOption: React.FC<{ color: string; }> = ({ color }) => (
	<div style={{ display: 'flex', alignItems: 'center' }}>
		<div style={{ backgroundColor: color, height: '1.1rem', width: '1.1rem', borderRadius: '50%' }} />
		<span style={{ marginLeft: '8px' }}>{color}</span>
	</div>
);

const { Option } = components;
// leaving props as "any" because chaning it creates TS errors in the ReactComponent
const ColorIcon = (props: any): JSX.Element => {
	return (
		<Option {...props}>
			<ColorOption color={props.data.value} />
		</Option>
	);
};

// custom react select selected option
// leaving props as "any" because chaning it creates TS errors in the ReactComponent
const SingleValue = (props: any): JSX.Element => {
	return (
		<ColorOption color={props.data.value} />
	);
};

interface ICreateEventTagModal {
	open: boolean;
	onClose: () => void;
	openWithNewTagInput?: boolean;
}

const CreateEventTagModal: React.FC<ICreateEventTagModal> = ({ open, onClose, openWithNewTagInput = false }) => {

	const sessions = useTypedSelector(state => state.CreateEventReducer.workingEvent?.sessions);
	const designColors = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.design?.colors);
	const colorTheme = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.design?.color_theme);
	const savingEvent = useTypedSelector(state => state.CreateEventReducer.saving);
	const eventTags = useTypedSelector(state => state.CreateEventReducer.workingEvent?.tags);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const eventColors = useGetColorOptionsFromColor(designColors, colorTheme);

	const [tags, setTags] = useState<IEventTag[]>([]);
	const [initialized, setInitialized] = useState(false);
	const [deletedTags, setDeletedTags] = useState<Record<string, IEventTag>>({});

	const dispatch = useDispatch();

	useEffect(() => {
		if (open && !initialized) {
			setInitialized(true);
			if (eventTags) {
				setTags([
					...eventTags,
					...(openWithNewTagInput ? [{ name: '', color: '#000000', uuid: uuidV4() }] : [])
				]);
			}
		}
	}, [eventTags, open, openWithNewTagInput, initialized]);

	const handleClose = () => {
		setTags(eventTags ?? []);
		setInitialized(false);
		setDeletedTags({});
		onClose();
	};

	const handleSave = () => {
		if (!token) return;

		const _tags = tags.map(tag => ({
			...tag,
			name: tag.name.trim(),
		}));

		// remove all session tag references that no longer exist as an event level tag
		const sessionsToUpdate: Session[] = [];
		if (Object.keys(deletedTags).length) {
			(sessions ?? []).forEach(_session => {
				let requiresUpdate = false;
				const updatedSessionTags: string[] = [];
				_session?.tags?.forEach(tagUuid => {
					if (deletedTags[tagUuid]) {
						requiresUpdate = true;
					}
					if (!deletedTags[tagUuid]) {
						updatedSessionTags.push(tagUuid);
					}
				});
				if (requiresUpdate) {
					sessionsToUpdate.push({
						..._session,
						tags: updatedSessionTags
					});
				}
			});
		}

		batch(() => {
			dispatch(setEventTags(_tags));


			// update sessions that need to remove the tag association
			sessionsToUpdate.forEach(_session => {
				dispatch(updateSession(_session, token));
				dispatch(updateWorkingEventSession(_session));
			});
		});

		handleClose();
	};

	const handleDebounce = (key: string, idx: number, value: string) => {
		setTags(prev => {
			return prev.map((_tag, _idx) => {
				if (_idx !== idx) return _tag;
				return {
					..._tag,
					[key]: value,
				};
			});
		});
	};

	// get colors from quill and also from event design settings
	const availableColors = useCallback(() => {
		const baseColors: hex[] = [];
		const customColors: hex[] = [];
		// add theme colors (order all base colors first and custom colors second)
		if (eventColors) {
			for (const _key in eventColors) {
				const key = _key as keyof typeof eventColors;
				const colorArr = eventColors[key];

				if (!Array.isArray(colorArr)) continue;

				if (colorArr?.[ColorValueIndexes.hex]?.trim()?.length) {
					const newColor = colorArr[ColorValueIndexes.hex].trim();
					if (newColor) {
						if (key.includes('customColor')) {
							customColors.push(newColor.toLowerCase());
						} else {
							baseColors.push(newColor.toLowerCase());
						}
					}
				}
			}
		}
		const hexQuillColors: hex[] = [];

		QUILL_COLORS.forEach(color => {
			if (typeof color === 'boolean') return;
			if (color.trim().length) {
				const hexColor = rgbToHex(color.trim());
				hexQuillColors.push(hexColor);
			}
		});

		const _allColors = Array.from(new Set([...hexQuillColors, ...baseColors, ...customColors]));

		// need to add event colors
		return _allColors
			.filter(color => color?.trim())
			.map(color => ({ label: color, value: color }));

	}, [eventColors]);

	const handleDeleteTag = (idx: number) => () => {
		const deletedTag = tags.find((_tag, _idx) => idx === _idx);
		if (deletedTag) {
			setDeletedTags(prev => ({
				...prev,
				[deletedTag.uuid]: deletedTag,
			}));
		}

		setTags(prev => {
			return prev.filter((_tag, _idx) => {
				if (_idx === idx) return false;
				return true;
			});
		});
	};

	const handleAddTag = () => {
		const newTag: IEventTag = { name: '', color: '#000000', uuid: uuidV4() };
		setTags(prev => [...prev, newTag]);
	};

	const isValid = useCallback(() => {
		let _isValid = true;
		tags.forEach(tag => {
			if (!tag?.name?.trim()?.length) {
				_isValid = false;
			}
			if (!tag?.color?.trim()?.length) {
				_isValid = false;
			}
		});

		return _isValid;
	}, [tags]);

	return (
		<ModalComponent
			title="Manage Tags"
			open={open}
			onRequestClose={handleClose}
			closeable={false}
			cancellable
			footer={
				<div className="delete-modal-buttons">
					<button onClick={handleClose}>Cancel</button>
					<button
						onClick={handleSave}
						className="lemonade"
						disabled={!isValid() || savingEvent}
					>
						{savingEvent ? <WaitingIndicator /> : 'Save'}
					</button>
				</div>
			}
		>
			<div className="event-tags-container">
				{tags.map((tag, idx) => {
					const isNameValid = tags?.[idx]?.name?.trim()?.length;
					return (
						<div key={tag.uuid} className="event-tag-container">

							<div className="event-tag-name">
								<TextInput
									label="Tag name *"
									defaultValue={tag.name}
									placeholder=''
									onDebounce={value => handleDebounce('name', idx, value)}
									valid={isNameValid ? Validation.normal : Validation.error}
								/>
							</div>

							<div className="event-tag-color-container">
								<label>Color *</label>
								<ReactSelect
									tabSelectsValue={false}
									styles={reactSelectStyles({
										control: {
											borderRadius: '100px',
										}
									})}
									isSearchable={false}
									options={availableColors()}
									onChange={value => value?.value && handleDebounce('color', idx, value.value)}
									defaultValue={availableColors().find((color: { label: string; value: string; }) => color.value === tag.color)}
									menuPortalTarget={document.body}
									components={{ Option: ColorIcon, SingleValue: SingleValue }}
									onMenuOpen={() => dispatch(setADropdownIsOpen(true))}
									onMenuClose={() => setTimeout(() => dispatch(setADropdownIsOpen(false)), 100)}
								/>
							</div>

							<div className="event-tag-delete-container">
								<button onClick={handleDeleteTag(idx)} className="no-style round">
									<Icon name={ICONS.TRASH} size={16} color={COLORS.WHITE} />
								</button>
							</div>
						</div>
					);
				})}

				<button className="event-tag-add-tag" onClick={handleAddTag}>Add tag</button>
			</div>
		</ModalComponent>
	);
};

export default CreateEventTagModal;
