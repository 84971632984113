import { AsyncAction, Action } from '../../../types/actions';
import { GetEngagementResults, GetSurveys } from '../../../connection/surveys';
import { Survey, TextAnswer } from '../../../types/working-model';

export const GET_SURVEYS = 'GET_SURVEYS';
export function getSurveys(token: string): AsyncAction {
	return {
		type: GET_SURVEYS,
		promise: GetSurveys(token),
	};
}

export const ADD_SURVEY = 'ADD_SURVEY';
export function addSurvey(survey: Survey): Action {
	return {
		type: ADD_SURVEY,
		payload: survey,
	};
}

export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export function updateSurvey(survey: Survey): Action {
	return {
		type: UPDATE_SURVEY,
		payload: survey,
	};
}

export const GET_ENGAGEMENT_RESULTS = 'GET_ENGAGEMENT_RESULTS';
export function getEngagementResults(eventID: number, token: string): AsyncAction {
	return {
		type: GET_ENGAGEMENT_RESULTS,
		promise: GetEngagementResults(eventID, token)
	};
}

export const SET_ENGAGEMENT_RESULTS = 'SET_ENGAGEMENT_RESULTS';
export function setEngagementResults(engagements: {
	[engagementKey: string]: number;
} | null): Action {
	return {
		type: SET_ENGAGEMENT_RESULTS,
		payload: engagements,
	};
}

export const ADD_USER_ENGAGEMENT_PENDING = "ADD_USER_ENGAGEMENT_PENDING";
export function addUserEngagementPending(questionId: number, optionId: number[]): Action {
	return {
		type: ADD_USER_ENGAGEMENT_PENDING,
		payload: [questionId, optionId]
	};
}

export const UPDATE_ENGAGEMENT_RESULTS = 'UPDATE_ENGAGEMENT_RESULTS';
export function updateEngagementResults(updatedEngagements: {
	[engagementKey: string]: number;
}): Action {
	return {
		type: UPDATE_ENGAGEMENT_RESULTS,
		payload: updatedEngagements,
	};
}


export const SET_STATS_RESULTS = 'SET_STATS_RESULTS';
export function setStatsResults(
	question: number,
	statsResults: {
		total: number,
		counts: {
			[optionKey: number]: number
		}
	}
): Action {
	return {
		type: SET_STATS_RESULTS,
		payload: { question, statsResults },
	};
}

export const ADD_TEXT_ANSWER = "ADD_TEXT_ANSWER";
export function addTextAnswer(questionId: number, answer: TextAnswer): Action {
	return {
		type: ADD_TEXT_ANSWER,
		payload: { questionId, answer }
	};
}

export const SET_TEXT_ANSWERS = "SET_TEXT_ANSWERS";
export function setTextAnswers(questionId: number, answers: TextAnswer[], total: number): Action {
	return {
		type: SET_TEXT_ANSWERS,
		payload: { questionId, answers, total }
	};
}