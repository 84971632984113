import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { UpdateRegistrationSteps } from "../../../../../../../connection/create-event";
import { setRegistrationFeature, updateRegistrationSteps } from "../../../../../../../store/actions/admin/create-event";
import { REGISTRATION_STEP_NAME_MAP, RegistrationStep, RegistrationStepType } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import Switch from "../../../../../../general-ui/switch/switch";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";

import "./registration-panel.scss";

interface ISortableStepItem {
	step: RegistrationStep;
	disabled?: boolean;
}

const SortableStepItem: React.FC<ISortableStepItem> = ({ step, disabled }: ISortableStepItem) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const [isOn, setIsOn] = useState(step.isOn && !disabled);
	const registrationSteps = workingEvent?.registration_steps;
	const isGeneral = step.type === RegistrationStepType.general;
	const isTicketing = step.type === RegistrationStepType.ticketing;
	const isNotSortable = isGeneral || isTicketing;
	// registration steps cannot be toggled on or edited when custom SSO is in use

	const dispatch = useDispatch();

	const { attributes, listeners, setNodeRef, transform } = useSortable({
		id: step.type,
		disabled: isNotSortable
	});
	const sortableStyling: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	useEffect(() => {
		if (disabled && isOn) {
			setIsOn(false);
		}
	}, [disabled]);

	async function handleToggle(_value: RegistrationStepType, _isOn: boolean) {
		if (!registrationSteps?.length || !workingEvent || !token) return;
		const updatedSteps = registrationSteps.map(_step => {
			if (_step.type !== _value) return _step;
			return {
				..._step,
				isOn: _isOn
			};
		});

		setIsOn(_isOn);

		// if ticketing is being turned on, also make sure send email is on (email is required for ticketing)
		if (_value === RegistrationStepType.ticketing && _isOn) {
			const sendEmails = workingEvent.registration_settings?.sendEmailConfirmation;
			if (!sendEmails) {
				dispatch(setRegistrationFeature("sendEmailConfirmation", true));
			}
		}

		dispatch(updateRegistrationSteps(updatedSteps));
		await UpdateRegistrationSteps(token, workingEvent.uuid, updatedSteps);
	}

	return (
		<div
			className="card-container"
			ref={isGeneral ? null : setNodeRef}
			style={isGeneral ? {} : sortableStyling}
		>
			<div className="drag-icon" {...attributes} {...listeners} style={isNotSortable ? { opacity: 0 } : {}}>
				<Icon
					name={ICONS.DRAG_HANDLE}
					size={16}
					color={COLORS.GRAY} />
			</div>
			<div className="toggle-card in-padded-container">
				<label>{REGISTRATION_STEP_NAME_MAP[step.type]}</label>
				<div>
					{!isGeneral &&
						<Switch
							on={isOn}
							onClick={handleToggle}
							value={step.type}
							disabled={disabled}
						/>
					}
				</div>
			</div>
		</div>
	);
};

export default SortableStepItem;
