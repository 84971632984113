import classNames from 'classnames';
import { ReactNode, useContext, useMemo, useState } from 'react';

import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { Callout, ERegistrationLayoutTypes, LanguagesAbbr, PageModuleType, Registration } from '../../../../types/working-model';
import { getStylingOverrides, useLanguageParam } from '../../utils';
import { ProjectOverviewContent } from '../project-overview-content';
import images from '../../../../images';
import { layoutVariants } from '@admin/create/homepage/navigation/panel/registration/requirements-utils';
import { useGetRegistrationLayout } from 'hooks/registration-hooks';
import LandingPage from 'components/live-event/marketing-page/marketing-page';
import { TypographyItem } from '@general-ui/typography-item/typography-item';
import { Button } from '@general-ui/button/button';
import ModalComponent from '@general-ui/modal/modal';
import { useTranslate } from 'i18n/useTranslationModules';
import GrabYourSpot from '../grab-your-spot';
import { useScreenMediaQuery } from 'utils/use-screen-media-query';
import { getTextValue } from 'utils/utils';
import { OptionalComponent } from 'utils/optional-component';
import AppContext, { AppContexts } from 'components/app-context';
import { EditorSizes } from 'types/template-layouts';

interface Props {
	children: ReactNode;
	registration: Registration;
	inViewRef: (node?: Element | null | undefined) => void
	startDate?: Date;
	className?: string;
}

export const RegistrationLayouts = ({
	children,
	startDate,
	inViewRef,
	registration,
	className = '',
}: Props): JSX.Element => {
	const styling_overrides = registration.style?.styling_overrides;
	const registrationStyle = registration.style;
	const registrationLayout = useGetRegistrationLayout(registrationStyle);
	const language = useLanguageParam() as LanguagesAbbr;

	const appContext = useContext(AppContext);
	const isEditor = appContext === AppContexts.admin;

	const { t } = useTranslate(['registrations', 'homepage']);
	const isDesktop = !useScreenMediaQuery().isLessThan1024;
	const { backgroundColor, } = getStylingOverrides(styling_overrides);
	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(event => event.CreateEventReducer.workingEvent);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const event = workingEvent ?? eventBundle;
	const [openRegistrationModal, setOpenRegistrationModal] = useState(false);

	const logo = event?.registration_settings?.logo;
	const calloutModule = eventBundle?.homepage?.modules?.find(module => module.type === PageModuleType.callout);
	const content: Callout = calloutModule?.content;

	const { title } = getTextValue(content, language);

	const LayoutAboveTheFold =
		<>
			<div className="logo-container">
				<img
					src={logo}
					alt=""
					aria-hidden="true"
				/>
			</div>
			<div
				className="registration-heading-container"
				style={{ backgroundImage: `url(${registration.image})` }}
			>
				<OptionalComponent display={!registration.image}>
					<video
						src={images.OrbitsVid}
						poster={images.OrbitsStill}
						autoPlay
						muted
						loop
						controls={false}
						playsInline
						className="registration-background-video"
					/>
				</OptionalComponent>
				<ProjectOverviewContent startDate={startDate} registration={registration} />
			</div>
			<div className="registration-form-container" ref={inViewRef} >
				{children}
			</div>
		</>;

	const LayoutHero =
		<>
			<div
				className="registration-heading-container"
				style={{ backgroundImage: `url(${registration.image})` }}
			>
				<OptionalComponent display={!registration.image}>
					<video
						src={images.OrbitsVid}
						poster={images.OrbitsStill}
						autoPlay
						muted
						loop
						controls={false}
						playsInline
						className="registration-background-video"
					/>
				</OptionalComponent>
				<div className="logo-container">
					<img src={logo} alt="" aria-hidden="true" />
				</div>
			</div>

			<div className="registration-form-container">
				<div className="registration-panel-popout" ref={inViewRef}>
					{children}
				</div>
				<ProjectOverviewContent startDate={startDate} registration={registration} />
			</div>
		</>;

	const LayoutModal =
		<>
			<div
				className="registration-heading-container"
				style={{ backgroundImage: `url(${registration.image})` }}
			>
				<OptionalComponent display={!registration.image}>
					<video
						src={images.OrbitsVid}
						poster={images.OrbitsStill}
						autoPlay
						muted
						loop
						controls={false}
						playsInline
						className="registration-background-video"
					/>
				</OptionalComponent>
				<div className="registration-form-container">
					<div className="registration-panel-popout" ref={inViewRef}>
						{children}
					</div>
				</div>
			</div>
		</>;

	const LayoutLandingPage =
		<>
			<div className="logo-container">
				<img
					src={logo}
					alt=""
					aria-hidden="true"
				/>
			</div>
			<LandingPage isRegistrationPage={true} workingEvent={isEditor ? workingEvent : null} />

			<div className="registration-form-container">{children}</div>


			<GrabYourSpot
				title={title || t(`callout.${calloutModule?.id}.title`, t('homepage:registration.Grab your spot', 'homepage:registration.Register for the event'))}
				buttonText={content?.callToAction?.button_name[language] as string || t('homepage:registration.Register')}
				onclick={() => { setOpenRegistrationModal(true); }}
			/>

			<ModalComponent
				size="medium"
				open={openRegistrationModal}
				onRequestClose={() => setOpenRegistrationModal(false)}
				cancellable
				closeable
				isAdmin={false}
				eventPreview //needed to prevent admin modal coloring overides
				className={'registration-v2-modal'}
			>
				<div className="registration-v2">
					<div className="registration-form-container">
						<div className="registration-panel-popout">
							{children}
						</div>
					</div>
				</div>
			</ModalComponent>
		</>;
	let layoutComponent = LayoutAboveTheFold;

	switch (registrationLayout) {
		case ERegistrationLayoutTypes.heroL:
		case ERegistrationLayoutTypes.heroR:
			layoutComponent = LayoutHero;
			break;
		case ERegistrationLayoutTypes.landingL:
		case ERegistrationLayoutTypes.landingR:
			layoutComponent = LayoutLandingPage;
			break;
		case ERegistrationLayoutTypes.modal:
			layoutComponent = LayoutModal;
			break;
		case ERegistrationLayoutTypes.aboveTheFoldL:
		case ERegistrationLayoutTypes.aboveTheFoldR:
		default:
			layoutComponent = LayoutAboveTheFold;
	}

	const { isLeft, isBigHero, isOldLayout } = layoutVariants(event, isDesktop);

	// if isOldLayout and layout is form-left, then use aboveTheFoldL, else use aboveTheFoldR
	const regLayoutPostFix = useMemo(() => {
		if (!isOldLayout) return registrationLayout;

		if (registrationLayout === ERegistrationLayoutTypes.formLeft) {
			return ERegistrationLayoutTypes.aboveTheFoldL;
		}
		return ERegistrationLayoutTypes.aboveTheFoldR;
	}, [isOldLayout, registrationLayout]);

	return (
		<section
			className={classNames(
				`registration-v2 registration-${regLayoutPostFix}`,
				backgroundColor,
				'reg-image-overlay',
				// Specific usecase in the editor while editing themes registration. 'desktop' breaks a lot of stuff so leaving it out.
				editorSize && editorSize !== EditorSizes.desktop ? editorSize : '',
				workingEvent ? 'editor' : '',
				{
					'left-type-layout': isLeft,
					'big-hero-layout': isBigHero,
					'has-no-image': !event?.registration_settings?.image,
				},
				className
			)}
		>
			{layoutComponent}
		</section>
	);
};
