import { createStore, applyMiddleware } from "redux";
import { middleware as reduxPackMiddleware } from "redux-pack";
import thunk from "redux-thunk";
import appReducers from "./reducers/main";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
export let testStore: any;

const store = createStore(
	appReducers,
	composeEnhancers(applyMiddleware(thunk, reduxPackMiddleware))
);

if (process.env.NODE_ENV === 'test') {
	testStore = (testData: any) => {
		return createStore(appReducers,
			{ ...composeWithDevTools(applyMiddleware(thunk, reduxPackMiddleware)), ...testData });
	};
}

export default store;
