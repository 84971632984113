import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSocket } from "../../../../../../../connection/socket";
import { addTextAnswer } from "../../../../../../../store/actions/admin/surveys";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { Survey, SurveyType, TextAnswer } from "../../../../../../../types/working-model";
import { matchSurveyResponseKey } from "../../../../../../../utils/regex";
import StaggerChildren from "../../../../../../general-ui/animated/stagger-children";
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import PanelHeaderOptions from "../../panel/components/panel-header-options";
import { usePageModule } from "../../panel/hooks/panel.hooks";
import QuestionCard from "./components/question-card";
import './survey-results.scss';

type SurveyResultsTypeLabels = {
	label: string;
	type: string;
	icon: string;
}

const SurveyResults = () => {
	const pageModule = usePageModule();
	const survey: Survey | undefined = pageModule?.modules?.[0];
	const engagementResults = useTypedSelector(state => state.SurveysReducer.engagementResults);
	const [totalResponses, setTotalResponses] = useState(0);
	const { session: sessionUuid } = useParams<{ session: string }>();
	const questions = survey?.questions;
	const socket = useSocket(`session-${sessionUuid}-engagement-text`);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!questions) return;

		let totalResponses = 0;
		const res = Object.entries(engagementResults ?? {});
		const questionIds = questions.map(q => q.survey_question) ?? [];

		for (const [resultKey, value] of res) {
			const match = matchSurveyResponseKey(resultKey);

			if (match) {
				const { survey_question: surveyQuestion } = match;
				if (questionIds.includes(Number(surveyQuestion))) {
					totalResponses += value;
				}
			}
		}

		setTotalResponses(totalResponses);
	}, [engagementResults, questions]);

	const resLabel = totalResponses === 1 ? 'response' : 'responses';

	useEffect(() => {
		const handleText = (data: TextAnswer & { questionId: number }) => {
			dispatch(addTextAnswer(data.questionId, data));
		};

		socket.addListener('session-engagement-text', handleText);

		return () => {
			socket.removeListener('session-engagement-text', handleText);
		};
	}, [dispatch, socket]);

	const { type, icon, label }: SurveyResultsTypeLabels = useMemo(() => {
		let type = 'survey';
		let icon = ICONS.SURVEY;
		let label = 'Survey';
		if (survey?.survey_type === SurveyType.quiz) {
			type = 'quiz';
			icon = ICONS.QUIZ;
			label = 'Quiz';
		} else {
			if (survey?.show_results) {
				type = 'poll';
				icon = ICONS.POLL_FILLED;
				label = 'Poll';
			}
		}

		return { type, icon, label };
	}, [survey]);

	return (
		<>
			<PanelHeaderOptions>
				<span className={classNames('e-engage-label', type)}>
					<Icon name={icon} size={12} color="" />
					{label}
				</span>
			</PanelHeaderOptions>
			<div className="session-panel survey-results padding-24">
				{!!survey && (
					<>
						<aside>{totalResponses} {resLabel}</aside>
						<StaggerChildren className="survey-results-questions-list">
							{survey?.questions.map((question, index) => {
								return (
									<QuestionCard
										key={question.survey_question}
										question={question}
										questionNumber={index + 1}
										type={survey.survey_type ?? SurveyType.survey}
									/>
								);
							})}
						</StaggerChildren>
					</>

				)}
			</div>
		</>
	);
};

export default SurveyResults;