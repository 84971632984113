import { useMemo } from "react";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import ActiveEngageItemsList from "../../engage/active-engage-list";
import { usePageModuleGroup } from "../hooks/panel.hooks";
import { engageItems } from "./constants/empty-panel";
import EmptyStatePanel from "./empty-state-panel";

export default function EngagePanel(): JSX.Element {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const group = usePageModuleGroup();

	const modules = useMemo(() => {
		return workingSession?.modules?.filter(module => group?.modules?.includes(module.id as number) && module.modules?.length);
	}, [group, workingSession]);

	return modules?.length ? (
		<ActiveEngageItemsList />
	) : (
		<EmptyStatePanel
			title="Engage your audience"
			description="Add Questions, Polls, Surveys, or Quizzes for your audience"
			dropDownItems={engageItems}
			engage
		/>
	);
}
