import i18n, { ThirdPartyModule, TOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { BrandliveEvent, FeatureFlagMap, FeatureFlagsEnum } from "../types/working-model";
import store from '../store/main';
import { setTranslationUpdates } from "../store/actions/event/event-actions";
import { validate as validUuid } from "uuid";
import { getLogger } from '../utils/debug-logger';

const logger = getLogger('bl-admin:translations');

export type TranslationNamespaces = string;

const host = process.env.REACT_APP_UPLOAD_CLOUDFRONT_URL;

// be warned = there's no catch handler here 
const loadNamespaceRemote = async (
	lang: string,
	ns: string,
	uuid: string,
	version?: string,
	isPublished?: boolean
): Promise<Record<string, string>> => {
	const published = isPublished ? `published/` : '';
	logger('loadNamespaceRemote', lang, ns, uuid, version, isPublished);
	return await fetch(
		`${host}/translations/${published}${uuid}/${lang}/${ns}.json?v=${version}`
	)
		.then(res => {
			if (res.status === 200) {
				return res.json();
			} else {
				throw new Error(`${res.status} ${res.statusText}`);
			}
		}).then(json => {
			// In V3 we are occasionally uploading an empty object - if that is the case, throw an error 
			// rather than return an empty object and cause the app to have no visible text content.
			if (!Object.keys(json).length) {
				logger('remote contained no data', lang, ns);
				throw new Error(`Remote contained no data for ${lang}/${ns}`);
			}

			return json;
		});
};

const defaultNamespaces = ["homepage", "session", "email"];
const alwaysSkipNamespaces = ["email", "languages-list", "gdpr"];

export const translationNamespaces: TranslationNamespaces[] = [
	...defaultNamespaces
];

function convertGenerals(ns: string) {
	if (ns === 'homepage') {
		return 'general';
	}

	if (ns === 'session') {
		return 'session-general';
	}

	return ns;
}

/*

This is a custom i18next loader module. We want to defer loading in the translated
text until we know for certain what the state of the event is. This is slower in dev than it is
on the live site because the event data will already be available on page load.

*/
class StreamsLoader implements ThirdPartyModule {
	defaultNamespaces: string[] = defaultNamespaces;
	i18: typeof i18n | undefined;
	allNamespaces: Set<string> = new Set();
	languageMap: Map<string, Set<string>> = new Map();
	published?: boolean;
	type: '3rdParty' = '3rdParty' as const;
	useAllRemote?: boolean;
	useStaticRemote?: boolean;
	uuid?: string;
	version?: string;
	nsTimeout?: NodeJS.Timeout;
	defaultLanguage?: string;

	init(i18: typeof i18n) {
		this.i18 = i18;
		this.i18.on('languageChanged', this.languageChange);
	}

	addNamespace = (ns: string) => {
		// don't need to re-run this if we already have this language and namespace combo
		if (this.languageMap.get(i18n.language)?.has(ns)) {
			return;
		}

		this.allNamespaces.add(ns);

		if (this.nsTimeout) {
			clearTimeout(this.nsTimeout);
		}

		this.nsTimeout = setTimeout(() => {
			this.checkLoadStatus();
		}, 150);

		logger('addNamespace', ns);
	};

	languageChange = () => {
		this.checkLoadStatus();
	};

	shouldGetRemotes = (ns: string) => {
		// not fully loaded, skip
		if (!this.uuid) return false;

		// always skip email and languages list
		if (alwaysSkipNamespaces.includes(ns)) return false;

		// V3 should only load remotes for default namespaces
		if (this.useStaticRemote) {
			return this.defaultNamespaces.includes(ns);
		} else if (this.useAllRemote) {
			// if this is the default language in V2, only load default namespaces
			if (i18n.language === this.defaultLanguage) {
				return this.defaultNamespaces.includes(ns);
			} else {
				// if not default language in V2, load everything from remote
				return true;
			}
		}
	};

	checkLoadStatus = () => {
		const language = i18n.language;

		if (!this.languageMap.has(i18n.language)) {
			this.languageMap.set(i18n.language, new Set());
		}

		// iterate through selected namespaces
		for (const ns of this.allNamespaces.values()) {
			this.languageMap.get(i18n.language)?.add(ns);
			if (this.i18 && !this.i18.hasResourceBundle(language, ns)) {
				if (this.shouldGetRemotes(ns)) {
					loadNamespaceRemote(language, convertGenerals(ns), this.uuid as string, this.version, this.published)
						.then(translation => {
							logger('loaded remote', language, ns);
							this.i18?.addResourceBundle(language, ns, translation, undefined, true);
							store.dispatch(setTranslationUpdates(Date.now()));
						}).catch(this.handleRemoteFailure(ns, language));
				} else if (ns === 'languages-list' || ns === 'gdpr' || this.defaultNamespaces.includes(ns)) {
					import(`./${language}/${ns}.json`).then((result) => {
						logger('loaded local', language, ns);
						i18n.addResourceBundle(
							language,
							ns,
							result.default
						);
						store.dispatch(setTranslationUpdates(Date.now()));
					});
				}
			}
		}
	};

	loadStaticHome = (lang: string) => {
		import(`./${lang}/homepage.json`).then((result) => {
			i18n.addResourceBundle(
				lang,
				'homepage',
				result.default
			);
			store.dispatch(setTranslationUpdates(Date.now()));
			logger('loaded static homepage', lang);
		});
	};

	loadStaticSessions = (lang: string) => {
		import(`./${lang}/session.json`).then((result) => {
			i18n.addResourceBundle(
				lang,
				'session',
				result.default
			);
			store.dispatch(setTranslationUpdates(Date.now()));
			logger('loaded static sessions', lang);
		});
	};

	loadStaticGdpr = (lang: string) => {
		import(`./${lang}/gdpr.json`).then((result) => {
			i18n.addResourceBundle(
				lang,
				'gdpr',
				result.default
			);
			store.dispatch(setTranslationUpdates(Date.now()));
			logger('loaded static gdpr', lang);
		});
	};


	loadStaticEmails = (lang: string) => {
		import(`./${lang}/email.json`).then((result) => {
			i18n.addResourceBundle(
				lang,
				'email',
				result.default
			);
			store.dispatch(setTranslationUpdates(Date.now()));
			logger('loaded static emails', lang);
		});
	};

	loadStaticLanguagesList = (lang: string) => {
		import(`./${lang}/languages-list.json`).then((result) => {
			i18n.addResourceBundle(
				lang,
				'languages-list',
				result.default
			);
			store.dispatch(setTranslationUpdates(Date.now()));
			logger('loaded static languages-list', lang);
		});
	};

	// assuming that the admin has uploaded new files, we need to refresh all remote translations within the view
	refresh = () => {
		logger(`Refresh requested. useAllRemote: ${this.useAllRemote}, useStaticRemote: ${this.useStaticRemote}, uuid: ${this.uuid}`);
		if (!(this.useAllRemote || this.useStaticRemote) || !this.uuid) return;
		logger('refreshing...');
		for (const [lang, namespaces] of Array.from(this.languageMap.entries())) {
			for (const ns of namespaces.values()) {
				if (ns !== 'languages-list' && ns !== 'gdpr') {
					this.i18?.removeResourceBundle(lang, ns);
				}

				if (this.shouldGetRemotes(ns)) {
					loadNamespaceRemote(lang, convertGenerals(ns), this.uuid, new Date().toISOString(), this.published)
						.then(translation => {
							logger('refreshed remote', lang, ns);
							this.i18?.addResourceBundle(lang, ns, translation, undefined, true);
							store.dispatch(setTranslationUpdates(Date.now()));
						}).catch(this.handleRemoteFailure(ns, lang));
				} else {
					logger('skipping remote', lang, ns);
				}
			}
		}
	};

	postEventLoad = (eventBundle: BrandliveEvent & { feature_flags: FeatureFlagMap }, published = true) => {
		if (!this.i18) {
			return;
		}

		const uuidChange = this.uuid && validUuid(this.uuid) && this.uuid !== eventBundle.uuid;
		this.defaultLanguage = eventBundle.settings.i18n?.base;
		logger('event translation version', eventBundle.settings.translation_version);
		logger('app translation version', eventBundle.feature_flags[FeatureFlagsEnum.translations_v3] ? 3 : 2);

		// only use V3 mode if the event's translation version is 3 and the flag is on
		if (eventBundle.feature_flags[FeatureFlagsEnum.translations_v3]) {
			this.useStaticRemote = Number(eventBundle.settings.translation_version) === 3;
			logger('using static remote', this.useStaticRemote);
		}

		// if V2 is enabled and we are not already using V3, use V2 mode
		if (eventBundle.feature_flags[FeatureFlagsEnum.translations_v2] && !this.useStaticRemote) {
			this.useAllRemote = Number(eventBundle.settings.translation_version) >= 2;
			logger('using all remotes', this.useAllRemote);
		}

		this.version = String(eventBundle.modified_at);
		this.published = published;
		this.uuid = eventBundle.uuid;
		const lang = this.i18.language;
		this.i18.removeResourceBundle(lang, 'homepage');
		this.i18.removeResourceBundle(lang, 'session');
		this.i18.removeResourceBundle(lang, 'email');
		this.i18.removeResourceBundle(lang, 'languages-list');
		this.i18.removeResourceBundle(lang, 'gdpr');
		logger('event loaded');

		// the event has changed, we need to reload all resources
		// this means that the event has been changed from the admin view
		if (uuidChange && (this.useAllRemote || this.useStaticRemote)) {
			for (const [lang, namespaces] of this.languageMap.entries()) {
				for (const ns of namespaces.values()) {
					this.i18?.removeResourceBundle(lang, ns);
				}
			}

			this.allNamespaces = new Set(['homepage', 'session', 'languages-list', 'gdpr']);
			this.languageMap = new Map();
			this.languageMap.set(lang, new Set(['homepage', 'session', 'languages-list', 'gdpr']));
		}

		/*
	
		For the sake of speed, we're loading all these resources in parallel rather than 
		in a promise.all - we want things to become visible the moment they're loaded
	
		*/

		if (this.useAllRemote || this.useStaticRemote) {
			logger('should use some remotes');

			loadNamespaceRemote(lang, 'general', this.uuid, this.version ?? new Date().toISOString(), this.published)
				.then(translation => {
					if (Object.keys(translation).length) {
						this.i18?.addResourceBundle(lang, 'homepage', translation, undefined, true);
					} else {
						this.loadStaticHome(lang);
					}

					store.dispatch(setTranslationUpdates(Date.now()));
					logger('loaded general', lang);
				}).catch(() => this.loadStaticHome(lang));

			loadNamespaceRemote(lang, 'session-general', this.uuid, this.version ?? new Date().toISOString(), this.published)
				.then(translation => {
					if (Object.keys(translation).length) {
						this.i18?.addResourceBundle(lang, 'session', translation, undefined, true);
					} else {
						this.loadStaticSessions(lang);
					}

					store.dispatch(setTranslationUpdates(Date.now()));
					logger('loaded session-general', lang);
				}).catch(() => this.loadStaticSessions(lang));

			this.loadStaticLanguagesList(lang);
			this.loadStaticGdpr(lang);
		} else {
			this.loadStaticHome(lang);
			this.loadStaticSessions(lang);
			this.loadStaticEmails(lang);
			this.loadStaticLanguagesList(lang);
			this.loadStaticGdpr(lang);
		}
	};

	handleRemoteFailure = (ns: string, language: string) => {
		return (e: any) => {
			console.warn(`Missing ${language} translation for ${ns}, was likely not uploaded for this event.\n\n${e.toString()}`);
			if (ns === 'homepage') {
				this.loadStaticHome(language);
			} else if (ns === 'session') {
				this.loadStaticSessions(language);
			}
		};
	};
}

export const streamsLoader = new StreamsLoader();

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(streamsLoader)
	.init({
		lng: "en",
		ns: translationNamespaces,
		// debug: process.env.NODE_ENV === 'production' ? false : true,
		// debug: true,
		detection: {
			order: ["localStorage"],
			caches: ["localStorage"]
		},
		interpolation: {
			escapeValue: false
		},
		partialBundledLanguages: true
	});

export class StaticTranslator {
	namespaces = new Map<string, Map<string, Record<string, string>>>();
	loadStaticHome = (lang: string) => {
		import(`./${lang}/homepage.json`).then((result) => {
			if (!this.namespaces.has(lang)) {
				this.namespaces.set(lang, new Map([['homepage', result.default]]));
			} else {
				this.namespaces.get(lang)?.set('homepage', result.default);
			}
		});
	};

	loadStaticSessions = (lang: string) => {
		import(`./${lang}/session.json`).then((result) => {
			if (!this.namespaces.has(lang)) {
				this.namespaces.set(lang, new Map([['sessions', result.default]]));
			} else {
				this.namespaces.get(lang)?.set('sessions', result.default);
			}
		});
	};

	loadStaticEmails = (lang: string) => {
		import(`./${lang}/email.json`).then((result) => {
			if (!this.namespaces.has(lang)) {
				this.namespaces.set(lang, new Map([['email', result.default]]));
			} else {
				this.namespaces.get(lang)?.set('email', result.default);
			}
		});
	};

	loadStaticGdpr = (lang: string) => {
		import(`./${lang}/gdpr.json`).then((result) => {
			if (!this.namespaces.has(lang)) {
				this.namespaces.set(lang, new Map([['gdpr', result.default]]));
			} else {
				this.namespaces.get(lang)?.set('gdpr', result.default);
			}
		});
	};

	loadLanguage = async (lang: string) => {
		await Promise.all([
			this.loadStaticHome(lang),
			this.loadStaticSessions(lang),
			this.loadStaticEmails(lang),
			this.loadStaticGdpr(lang)
		]);
	};

	getValue = (language: string, namespace: string, key: string) => {
		return this.namespaces.get(language)?.get(namespace)?.[key] ?? key;
	};
}

export const staticTranslator = new StaticTranslator();
staticTranslator.loadLanguage('en');

export const tOptions: TOptions = {
	keySeparator: false,
	defaultValue: null
};
