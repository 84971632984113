import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { numberWithCommas } from "../../../../../utils/utils";

interface IPodiumScoreBadge {
	score?: number;
	language?: string;
}

const PodiumScoreBadge: React.FC<IPodiumScoreBadge> = ({ score, language }) => {
	const { t } = useTranslate(['homepage']);
	if (!score) return null;
	return (
		<div className="podium-entry-score">{numberWithCommas(score, language)} <span className="points-text">{t('leaderboard.points')}</span></div>
	);
};

export default PodiumScoreBadge;
