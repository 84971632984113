import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { LazyMotion, m, domAnimation } from 'framer-motion';
import classNames from 'classnames';

import { VideoPlayerReducerType, VideoStateContext } from '../../session-stream-provider';
import { getReactionPayload, getUserData } from '../utils';
import { TimeUpdate, VideoPlayerType } from '../types';
import hexToRGBA from '../../../../../../utils/hexToRGBA';
import { ActionEvent, ActionSource, ActionTargetType, ActionType, ReactionConfig } from '../../../../../../types/working-model';
import { Tooltip } from '../../../../../general-ui/tooltip/tooltip';
import { reactionIconMap } from '../../../../../../utils/reactions';
import { PutEmojiReaction } from '../../../../../../connection/event-chat';
import * as Signals from '../../../../../../utils/event-emitter';
import { useTracking } from '../../../../../../utils/tracking';

import './reactions.scss';

type Props = {
	open: boolean;
	onRequestClose: () => void;
}

const Reactions: React.FC<Props> = ({ open, onRequestClose }) => {
	const { state } = useContext(VideoStateContext);
	const {
		session: session,
		playerType,
		isMobile,
		chatIsOverlaid,
		chatClosed,
		reactions,
		onDemandVideo: onDemandVideo
	} = state as Required<VideoPlayerReducerType>;
	const { language } = useParams<{ language: string }>();
	const onRequestCloseRef = useRef(onRequestClose);
	const container = useRef<HTMLDivElement | null>(null);
	const currentSecondRef = useRef<number>(0);

	useEffect(() => {
		onRequestCloseRef.current = onRequestClose;
	}, [onRequestClose]);

	const { trackEvent } = useTracking({
		target_id_string: session.session_type,
		target_type: ActionTargetType.Video,
		current_language: language
	});

	const trackReaction = (reaction: ReactionConfig) => {
		const {
			blProfileUser,
			validPasscodeLists
		} = getUserData();

		if (!blProfileUser) return;

		const payload = getReactionPayload({
			reaction,
			blProfileUser,
			validPasscodeLists,
			currentSecond: currentSecondRef.current,
			onDemandVideo,
			session
		});

		trackEvent({
			action: ActionEvent.Click,
			action_type: ActionType.Active,
			source_type: ActionSource.Session,
			source_id: session?.session ?? null,
			target_type: ActionTargetType.Reaction,
			target_id_string: reaction.name,
			bl_profile: blProfileUser?.bl_profile,
			miscellaneous: JSON.stringify(payload)
		});
	};

	const handleReactionClick = (reaction: ReactionConfig) => {
		trackReaction(reaction);
		if (session && session.uuid) {
			PutEmojiReaction(session?.uuid, reaction.name);
			Signals.broadcastSignal('selfReaction', reaction);
		}
	};

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onRequestCloseRef.current();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		const handleProgress = (progress: TimeUpdate) => {
			currentSecondRef.current = progress.playedSeconds;
		};

		Signals.addEventListener('video-player-progress', handleProgress);

		return () => {
			Signals.removeEventListener('video-player-progress', handleProgress);
		};
	}, []);

	return (
		<div
			ref={container}
			className={classNames(
				'reaction-container',
				'brandlive-reactions',
				{ 'chat-overlay-enabled': (chatIsOverlaid && !chatClosed) },
				{ 'is-mobile': isMobile },
				{ 'is-iframe': playerType === VideoPlayerType.embed })}
		>
			{open && reactions.map(reaction => (
				<div
					key={reaction.name}
					className={classNames(
						`reaction brandlive-reactions ${reaction.name.replace(/,?\s+/g, '-')}`,
						{ 'background-color-present': reaction.opacity !== 0 },
						{ 'tooltip-present': session?.reaction_settings?.show_descriptions })}
					style={{ backgroundColor: hexToRGBA(reaction.color, reaction.opacity) ?? '' }} onClick={() => handleReactionClick(reaction)}
				>
					<Tooltip tooltip={reaction.description ?? ''} position={'left'} disable={!session?.reaction_settings?.show_descriptions}>
						<LazyMotion features={domAnimation}>
							<m.div
								transition={{
									type: "spring",
									stiffness: 500,
									damping: 5,
									velocity: 1.1
								}}
								whileTap={{ scale: 0.8 }}
								role={'img'}
							>
								<img src={reaction.icon ? reactionIconMap[reaction.icon] : reaction.url} alt="" />
							</m.div>
						</LazyMotion>
					</Tooltip>
				</div>
			))}
		</div>
	);
};

export default Reactions;