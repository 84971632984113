import React from 'react';

import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { ENavbarTypes } from '../../../../types/working-model';
import Icon, { ICONS } from '../../../general-ui/icon';
import { EIcon } from '../../../general-ui/icon/icon';

export default function ButtonOthers(): JSX.Element {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const event = eventBundle || workingEvent;

	const navbarType = event?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;

	if (isTopNavbar) {
		return <Icon name={ICONS.KEYBOARD_ARROW_DOWN} color="navdrop-arrow" size={14} />;
	}

	return <EIcon name="more-horiz" size={24} />;
}