import { Action } from "../../../types/actions";
import { getStorageItem } from "../../../utils/local-storage";
import LOCAL_STORAGE_KEYS from "../../../utils/local-storage-keys";

import {
	IVideoProviderInitParams
	, SET_CAMERA_ACTIVE
	, SET_CAMERA_DEVICE_ID
	, SET_MICROPHONE_ACTIVE
	, SET_MICROPHONE_DEVICE_ID
	, SET_SPEAKER_DEVICE_ID
	, SET_VIDEO_PROVIDER_INIT_PARAMS
	, SET_SCREEN_SHARE_ACTIVE
} from '../../actions/event/video-call-actions';

interface IVideoCallState {
	cameraActive: boolean;
	cameraDeviceId: string
	microphoneActive: boolean;
	microphoneDeviceId: string;
	speakerDeviceId: string;
	screenShareActive: boolean;
	videoProviderInitParams: IVideoProviderInitParams;
}

const defaultState: IVideoCallState = {
	cameraActive: getStorageItem('cameraActive') === 'false' ? false : true,
	cameraDeviceId: localStorage.getItem(LOCAL_STORAGE_KEYS.CAMERA_DEVICE_ID) || '',
	microphoneActive: getStorageItem('microphoneActive') === 'false' ? false : true,
	microphoneDeviceId: localStorage.getItem(LOCAL_STORAGE_KEYS.MICROPHONE_DEVICE_ID) || '',
	screenShareActive: false,
	speakerDeviceId: localStorage.getItem(LOCAL_STORAGE_KEYS.SPEAKER_DEVICE_ID) || '',
	videoProviderInitParams: {
		sessionId: '',
		token: '',
		isFiresides: false
	}
};

export default function VideoCallReducer(state = defaultState, action: Action): IVideoCallState {
	switch (action.type) {
		case SET_CAMERA_ACTIVE: {
			return {
				...state,
				cameraActive: action.payload,
			};
		}
		case SET_CAMERA_DEVICE_ID: {
			localStorage.setItem(LOCAL_STORAGE_KEYS.CAMERA_DEVICE_ID, action.payload);
			return {
				...state,
				cameraDeviceId: action.payload,
			};
		}
		case SET_MICROPHONE_ACTIVE: {
			return {
				...state,
				microphoneActive: action.payload,
			};
		}
		case SET_MICROPHONE_DEVICE_ID: {
			localStorage.setItem(LOCAL_STORAGE_KEYS.MICROPHONE_DEVICE_ID, action.payload);

			return {
				...state,
				microphoneDeviceId: action.payload,
			};
		}
		case SET_SPEAKER_DEVICE_ID: {
			localStorage.setItem(LOCAL_STORAGE_KEYS.SPEAKER_DEVICE_ID, action.payload);

			return {
				...state,
				speakerDeviceId: action.payload,
			};
		}
		case SET_SCREEN_SHARE_ACTIVE: {
			return {
				...state,
				screenShareActive: action.payload,
			};
		}
		case SET_VIDEO_PROVIDER_INIT_PARAMS: {
			return {
				...state,
				videoProviderInitParams: action.payload,
			};
		}
		default: {
			return state;
		}
	}
}