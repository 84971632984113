import { useMemo, useState } from 'react';
import cn from 'classnames';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { useParams } from 'react-router';

import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { EditorSizes, TemplateNames } from '../../../../../types/template-layouts';
import { RegistrationQuestion } from '../../../../../types/working-model';
import { OptionalComponent } from '../../../../../utils/optional-component';
import { useScreenMediaQuery } from '../../../../../utils/use-screen-media-query';
import { getTemplateClassName } from '../../../../../utils/utils';
import { EIcon } from '../../../../general-ui/icon/icon';
import Search from '../../../../general-ui/search-bar/search';
import FilterDropdown from '../../../modules/agenda/session-filter-dropdown';
import { getUniqueSessionTrackOptions, getRegistrationQuestionById } from '../leaderboard-utils';
import { ILeaderboardSearchCriteria } from '../../../../../types/leaderboard';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { parseFields } from '../../../registration/registration';
import { ParamsProps } from '../../../live-event';
import { SelectOption } from '../../../../general-ui/select/select';
import classNames from 'classnames';


interface ILeaderboardHeaderProps {
	searchCriteria: ILeaderboardSearchCriteria;
	onSearchCriteriaChange: (value: ILeaderboardSearchCriteria) => void;
	disabled: boolean;
}

function LeaderboardHeader(props: ILeaderboardHeaderProps) {
	const {
		searchCriteria,
		onSearchCriteriaChange,
		disabled
	} = props;

	const { language }: ParamsProps = useParams();

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);

	const [displayMobileFilters, setDisplayMobileFilters] = useState(false);

	const { t } = useTranslate(['homepage', 'registrations', 'home']);
	const { isLessThan768 } = useScreenMediaQuery();
	const isMobile = editorSize === EditorSizes.mobile || isLessThan768;

	const event = eventBundle ?? workingEvent;
	const template = event?.template?.name || TemplateNames.Classic;

	const registrationFilterQuestion = useMemo(() => getRegistrationQuestionById(event?.registration_steps, searchCriteria.fq), [event?.registration_steps, searchCriteria.fq]);

	function getRegistrationQuestionFilterOptions(registrationFilterQuestion: RegistrationQuestion | null) {
		if (!registrationFilterQuestion || !registrationFilterQuestion?.options) { return []; }

		// Should only return the one option in a list
		const [[question], selectFieldMap] = parseFields({
			questions: [registrationFilterQuestion],
			requiredFields: [],
			language,
			t
		});

		const options: SelectOption[] = [{ label: t('All'), value: '' }];

		question?.options?.forEach(option => {
			options.push({ label: option.label, value: selectFieldMap.get(option.label) ?? option.value });
		});

		return options;
	}

	const registrationQuestionFilterOptions = getRegistrationQuestionFilterOptions(registrationFilterQuestion);
	const trackOptions = [{ label: t('leaderboard.All Tracks'), value: '' }, ...getUniqueSessionTrackOptions(event?.sessions, t, language, event?.settings)];
	const displayTrackFilter = trackOptions.length > 1;

	function handleSearchCriteriaChange(key: keyof ILeaderboardSearchCriteria, value: string | number) {
		// reset page number to 1 when changing filters
		const newSearchCriteria = { ...searchCriteria, page: 1, [key]: value };
		onSearchCriteriaChange(newSearchCriteria);
	}

	const Filters = (
		<div className="leaderboard-filters">
			<OptionalComponent display={!!registrationFilterQuestion}>
				<>
					<FilterDropdown
						onChange={option => handleSearchCriteriaChange('fv', option.value)}
						options={registrationQuestionFilterOptions}
						template={getTemplateClassName(template)}
						value={searchCriteria.fv ?? ''}
						animateDropdown
						useArrowOutline
					/>
				</>
			</OptionalComponent>
			<OptionalComponent display={displayTrackFilter}>
				<FilterDropdown onChange={option => handleSearchCriteriaChange('track', option.value)} options={trackOptions} template={getTemplateClassName(template)} value={searchCriteria.track ?? ''} animateDropdown useArrowOutline />
			</OptionalComponent>
		</div>
	);

	return (
		<div className="leaderboard-header">
			<h2 className="evt-heading-3">{t('leaderboard.Leaderboard')}</h2>

			<div className={classNames("leaderboard-search-container", { disabled })}>
				<Search
					searchOnEmpty
					onSearch={criteria => handleSearchCriteriaChange('search', criteria)}
					onClear={() => handleSearchCriteriaChange('search', '')}
					isControlled
					controlledValue={searchCriteria.search}
					placeholder={t('leaderboard.Search')}
					minLength={2}
				/>
				{/* Mobile filter toggle */}
				<OptionalComponent display={isMobile && (!!registrationFilterQuestion || displayTrackFilter)}>
					<button onClick={() => setDisplayMobileFilters(prev => !prev)} className={cn("no-padding no-style leaderboard-filters-button", { on: displayMobileFilters })}>
						<EIcon
							name="settings-sliders"
							size="medium"
						/>
					</button>
				</OptionalComponent>

				{isMobile ? (
					<OptionalComponent display={!isMobile || (isMobile && displayMobileFilters)}>
						<AnimatePresence>
							<LazyMotion features={domAnimation}>
								<m.div
									initial={{ translateY: -100, opacity: 0 }}
									animate={{ translateY: 0, opacity: 1 }}
									exit={{ translateY: -100, opacity: 0 }}
									style={{ width: '100%' }}
								>
									{Filters}
								</m.div>
							</LazyMotion>
						</AnimatePresence>
					</OptionalComponent>
				) : (
					Filters
				)}

			</div>
		</div>
	);
}

export default LeaderboardHeader;
