export class CorsWorker {
	private readonly worker: Worker;

	constructor(url: string | URL) {
		if (process.env.REACT_APP_STAGE !== 'local') {
			const objectURL = URL.createObjectURL(
				new Blob([`importScripts(${JSON.stringify(url.toString())});`], {
					type: 'application/javascript'
				})
			);
			this.worker = new Worker(objectURL);
			URL.revokeObjectURL(objectURL);
		} else {
			this.worker = new Worker(url);
		}
	}

	getWorker() {
		return this.worker;
	}
}