import { ReactNode } from "react";

import './cookie-banner.scss';
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	className?: string;
	style?: React.CSSProperties;
	fixed?: boolean;
}

const CookieBannerStickyWrapper = ({ children, className = '', style = {}, fixed }: IProps) => (
	<div
		className={classNames('cookie-banner-sticky-wrapper', className, { fixed })}
		style={style}
	>
		{children}
	</div>
);

export default CookieBannerStickyWrapper;