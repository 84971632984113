import { PASSCODE_LIST_TYPE } from "../types/working-model";
import { GetMagicLinksListTemplate } from "../connection/passcode-lists";
export default async function GenerateExampleCsv(type: PASSCODE_LIST_TYPE = PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES, token?: string | null, event_uuid?: string | null): Promise<void> {
	let csv = '';

	switch (type) {
		case PASSCODE_LIST_TYPE.EMAILS_ONLY: {
			csv += 'email*,first_name,last_name\n';
			csv += 'user1@example.com,First 1, Last 1\nuser2@example.com,First 2,Last 2';
			break;
		}
		case PASSCODE_LIST_TYPE.PASSCODES_ONLY: {
			csv += 'passcode*\n';
			csv += 'first-passcode\nsecond-passcode';
			break;
		}
		case PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES: {
			csv += 'email*,passcode*,first_name,last_name\n';
			csv += 'user1@example.com,my-first-passcode,First 1,First 2\nuser2@example.com,my-second-passcode,Last 1,Last 2';
			break;
		}
		case PASSCODE_LIST_TYPE.MAGIC_LINKS: {
			if (token && event_uuid) {
				const { csv: incomingCSV } = await GetMagicLinksListTemplate(token, event_uuid);
				csv = incomingCSV;
			}
			break;
		}
		default: {
			csv += 'email,passcode,first_name,last_name\n';
			csv += 'user1@example.com,my-first-passcode,First 1,First 2\nuser2@example.com,my-second-passcode,Last 1,Last 2';
		}
	}

	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
	const link = document.createElement('a');
	link.setAttribute('href', URL.createObjectURL(blob));
	link.setAttribute('download', 'audience-example.csv');
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function GenerateExamplePaidListCsv(): void {
	let csv = 'email\n';
	csv +=
		'user1@example.com\nuser2@example.com';

	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
	const link = document.createElement('a');
	link.setAttribute('href', URL.createObjectURL(blob));
	link.setAttribute('download', 'paid-list-example.csv');
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}