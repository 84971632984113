import { Get } from './helpers';

export const GetHCArticles = async (token: string, search: string): Promise<any> => {
	return Get(`/v3/admin/events/help-center/${search}`, token, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			throw new Error('Unable to get help center articles');
		});
};
