import { useHistory } from 'react-router-dom';
import images from '../../../../images';

import './module-empty-state.scss';
import classNames from 'classnames';

type Props = {
	navigateOnClickTo?: string;
	navigateOnClickState?: Record<string, any>;
}

export default function ModuleEmptyState({ navigateOnClickTo, navigateOnClickState }: Props): JSX.Element {
	const history = useHistory();

	return (
		<div
			onClick={() => {
				if (navigateOnClickTo) {
					history.replace(navigateOnClickTo, navigateOnClickState ?? history.location.state);
				}
			}}
			className={classNames("empty-module", { clickable: !!navigateOnClickTo })}
		>
			<img className="empty-module-icon" src={images.TellyLogo} alt="" />
			<p className="empty-module-message evt-typography-h6">
				Sections without content will not be visible to the audience
			</p>
		</div>
	);
}