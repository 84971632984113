import { CSSProperties } from 'react';
import './basic-card.scss';

interface IBasicCardProps {
	style?: React.CSSProperties;
	p?: number;
	className?: string;
}

const BasicCard: React.FC<React.PropsWithChildren<IBasicCardProps>> = ({
	style,
	children,
	p = 5,
	className = '',
	...rest
}) => {

	return (
		<div
			className={`basic-card padding-${p} ${className}`}
			style={{
				...style,
				'--basic-card-padding': `${p}px`,
			} as CSSProperties}
			{...rest}
		>
			{children}
		</div>
	);
};

export default BasicCard;
