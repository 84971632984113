import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { LanguagesAbbr, PageModule, PageModuleType, TranslateString } from "../../../../../../../../types/working-model";
import { getBlankModuleHtml } from "../../../../../../../../utils/html-utils";
import CustomHTML from "../../../../../../../general-ui/edit-html/edit-html";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import ModalComponent from "../../../../../../../general-ui/modal/modal";
import { usePageModule } from "../../hooks/panel.hooks";
import { updateBlankModuleContent } from '../../../../../homepage/editor/blank-section';
import { updatePageModule } from "../../../../../../../../store/actions/admin/create-event/session";

const generateTranslateString = (languages: string[]): TranslateString => {
	const base = { base: "", changed: "" };
	const translations = languages.reduce((acc, cur) => {
		return { ...acc, [cur]: "" };
	}, {});

	return { ...base, ...translations };
};

const EmbedCard = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const pageModule = usePageModule();
	const [workingBlankModule, setWorkingBlankModule] = useState<PageModule>();
	const { language } = useParams<{ language: LanguagesAbbr }>();
	const dispatch = useDispatch();
	const html = getBlankModuleHtml(workingBlankModule, language);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const baseLanguage = workingSession?.default_language;
	const languages = workingSession?.languages;

	useEffect(() => {
		if (pageModule && languages) {
			const module = { ...pageModule };

			// if this module has not been updated to the blank type, update it
			if (module.type === PageModuleType.feed) {
				module.type = PageModuleType.blank;
				module.content = {
					...module.content,
					translated_html: generateTranslateString(languages)
				};

				dispatch(updatePageModule(module));
			} else if (!module.content.translated_html) {
				module.content = {
					...module.content,
					translated_html: generateTranslateString(languages)
				};

				dispatch(updatePageModule(module));
			}

			// if the working page module was undefined, set it to the page module
			// which will have just loaded
			setWorkingBlankModule(prev => {
				if (!prev) {
					return module;
				}

				return {
					...prev,
					content: {
						...prev.content,
						...module.content, // ensure our content is updated locally when we update content in other parts of the app
					}
				};
			});
		}
	}, [dispatch, languages, pageModule]);

	const handleModalClose = useCallback(() => {
		setEditModalOpen(false);
		setWorkingBlankModule(pageModule);
	}, [pageModule]);

	const handleEdit = useCallback((code: string) => {
		if (!pageModule || !baseLanguage) {
			return;
		}

		const newModule = updateBlankModuleContent(pageModule, code, language, baseLanguage);

		setWorkingBlankModule(newModule);
	}, [baseLanguage, language, pageModule]);

	const handleSave = useCallback(() => {
		if (workingBlankModule) {
			dispatch(updatePageModule(workingBlankModule));
		}

		setEditModalOpen(false);
	}, [dispatch, workingBlankModule]);

	return (
		<div className="session-panel-embed-card">
			<label>Custom HTML</label>
			<button className="no-style no-padding no-margin" onClick={() => setEditModalOpen(true)}>
				<Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} />
			</button>
			<ModalComponent
				cancellable={false}
				className="custom-html-modal"
				closeable={false}
				open={editModalOpen}
				onRequestClose={handleModalClose}
				title="Custom HTML"
				footer={(
					<>
						<button onClick={handleModalClose}>Cancel</button>
						<button className="lemonade" onClick={handleSave}>Save</button>
					</>
				)}
			>
				<CustomHTML
					defaultValue={html}
					onChange={handleEdit}
				/>
			</ModalComponent>
		</div>
	);
};

export default EmbedCard;