import { BrandliveEvent, SessionPreview } from '../types/working-model';

export function formatTime(_date: number | Date): string {
	const date = new Date(_date);

	return date.toLocaleString(navigator.language, { month: 'short', day: 'numeric', year: 'numeric' }) +
		' • ' +
		date.toLocaleString(navigator.language, { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
}

// we previously filtered sessions by whether the session type should have a timestamp THEN returned the timestamp
// faster just to extract the timestamp then drop all the undefined timestamps
const extractTimestamp = (session: SessionPreview) => session.timestamp;
const isDefined = (timestamp: number | undefined | null) => !!timestamp;

export function getStartTime(event: BrandliveEvent, formatted: boolean): string | null | number {
	if (event) {
		// sessions could be Session[] or SessionPreview[], but TS doesnt like that when filtering the sessions.
		// since all we need here is the timestamp, SessionPreview will work for both types
		const sessions: SessionPreview[] = (event.sessions ? event.sessions : event.sessionsPreviews) as SessionPreview[] ?? [];

		const allSessionTimestamps = sessions
			.map(extractTimestamp)
			.filter(isDefined);

		const startTime = allSessionTimestamps.length ? Math.min(...allSessionTimestamps) : null;

		if ((startTime === null) || (startTime === 0))
			return null;
		else {
			if (formatted) {
				// return start time as formatted string
				return formatTime(startTime);
			} else {
				// return starting time as unformated number
				return startTime;
			}
		}
	}

	return null;
}


export function getStartSession(event: BrandliveEvent): SessionPreview | null {
	if (event) {
		// sessions could be Session[] or SessionPreview[], but TS doesnt like that when filtering the sessions.
		// since all we need here is the timestamp, SessionPreview will work for both types
		const sessions: SessionPreview[] = (event.sessions ? event.sessions : event.sessionsPreviews) as SessionPreview[] ?? [];

		return [...sessions].filter(extractTimestamp).sort((a, b) => a.timestamp - b.timestamp)?.[0] ?? null;
	}

	return null;
}
