import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { OptionalComponent } from "../../../utils/optional-component";
import Icon, { COLORS, ICONS } from "../icon";
import "./session-type-select.scss";

interface SessionTypeSelectOption<T> {
	label: string;
	value: T;
	description: string;
	icon: string;
	disabled?: boolean;
}

interface SessionTypeSelectProps<T> {
	options: SessionTypeSelectOption<T>[];
	onChange?: (value: T) => void;
	selected: T;
	style?: React.CSSProperties;
	defaultValue?: T;
	selectOutline?: boolean;
	selectIconVisible?: boolean;
	disbleSessionTypeSwitching?: boolean;
}

export default function SessionTypeSelect<T>(
	props: SessionTypeSelectProps<T>
): JSX.Element {
	const {
		options,
		onChange,
		selected,
		style,
		defaultValue,
		selectOutline,
		selectIconVisible = true,
		disbleSessionTypeSwitching = false,
	} = props;
	const [selectedOption, setSelectedOption] = useState<
		SessionTypeSelectOption<T> | undefined
	>(options.find(({ value }) => value === defaultValue));
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const createChangeHandler = (option: SessionTypeSelectOption<T>) => () => {
		if (option?.disabled) return;
		onChange && onChange(option.value);
		setSelectedOption(option);
		setIsOpen(false);
	};

	const handleClick = useCallback(() => {
		if (disbleSessionTypeSwitching) return;
		setIsOpen(!isOpen);
	}, [disbleSessionTypeSwitching, isOpen]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.code === "Escape" || event.key === "Escape") {
				setIsOpen(false);
			}
		};
		const handleDropdownClose = (e: any) => {
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (dropdownRef.current === item) return;
			}
			setIsOpen(false);
		};

		document.addEventListener("keydown", handleKeyDown);
		window.addEventListener("click", handleDropdownClose);

		return () => {
			window.removeEventListener("click", handleDropdownClose);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<div
			className={classNames("session-type-select", {
				"session-type-select-outline": selectOutline
			})}
			style={style || {}}
			ref={dropdownRef}
		>
			<div
				className={classNames("session-type-selected")}
				onClick={handleClick}
			>
				<div className="select-option">
					<div
						className={classNames("session-selected-type", {
							selected: defaultValue === (selected || defaultValue)
						})}
					>
						{selectIconVisible ? (
							<div className="icon-gradient">
								<Icon name={selectedOption?.icon as string} size={20} color={COLORS.BLACK} />
							</div>
						) : (
							""
						)}
						<div className="session-selected-info">
							<label>{selectedOption?.label}</label>
							<span>
								{selectedOption?.description}
							</span>
						</div>
					</div>
				</div>
				<OptionalComponent display={!disbleSessionTypeSwitching}>
					<span className="arrow">
						<Icon
							name={ICONS.KEYBOARD_ARROW_DOWN}
							size={12}
							color={COLORS.WHITE}
						/>
					</span>
				</OptionalComponent>
			</div>
			<div className={classNames("session-type-selector", { open: isOpen })}>
				{options.map((option: SessionTypeSelectOption<T>) => (
					<div key={`${option.value}`} className="select-option">
						<div
							className={classNames("session-selected-type", {
								selected: option.value === (selected || defaultValue)
							})}
							onClick={option?.disabled ? () => null : createChangeHandler(option)}
							style={{
								cursor: option?.disabled ? 'not-allowed' : 'pointer',
								opacity: option?.disabled ? '0.4' : '1',
							}}
						>
							{selectIconVisible ? (
								<div className="icon-gradient">
									<Icon name={option.icon} size={20} color={COLORS.BLACK} />
								</div>
							) : (
								""
							)}
							<div className="session-selected-info">
								<label>{option.label}</label>
								<span>
									{option.description}
								</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}