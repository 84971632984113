import { CreateVideo, HomepageVideo, IVSRecording, SecondarySessionVideo } from "types/working-model";
import { isHomepageVideo } from "utils/is-homepage-video";
import { getImageLocation } from "utils/utils";

export const getMungedSecondaryVideo = (
	recordedVideos: (HomepageVideo | IVSRecording)[],
	secondaryVideo: SecondarySessionVideo | null,
	uploaded: HomepageVideo | undefined
) => {
	const recorded = recordedVideos.find(video => {
		if ("source" in video) {
			return video.source === secondaryVideo?.playback_url;
		} else {
			return video.playback_url === secondaryVideo?.playback_url;
		}
	});

	if (isHomepageVideo(recorded)) {
		const mungedVideo = uploaded
			? uploaded
			: recorded;
		return mungedVideo;
	} else {
		const recordedMunged: CreateVideo | undefined = recorded
			? {
				recording_id: recorded.id,
				type: "recorded",
				name: recorded.name,
				source: recorded.playback_url,
				hls_link: recorded.playback_url,
				image: getImageLocation(recorded.playback_url),
				use_thumbnail: false,
				original_image: getImageLocation(recorded.playback_url)
			}
			: undefined;

		const mungedVideo = uploaded
			? uploaded
			: recordedMunged;
		return mungedVideo;
	}
};