import { useContext } from "react";

import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import AppContext, { AppContexts } from "components/app-context";

import './session-settings.scss';

function PanelBlock(props: { children: React.ReactNode; title?: string; id?: string; openEdit?: () => void; toggle?: React.ReactNode, className?: string }): JSX.Element {

	const isAdmin = useContext(AppContext) === AppContexts.admin;

	return (
		<div className={`panel-block ${props.className ? props.className : ''} ${isAdmin ? 'isAdmin' : ''}`} id={props.id}>
			<div className="panel-block-header">
				{!!props.title && <span> {props.title} </span>}
				{props.openEdit && <button onClick={props.openEdit}><Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} /></button>}
				{!!props.toggle && props.toggle}
			</div>
			{props.children}
		</div>
	);
}


export default PanelBlock;

