const ColorVars = {
	// Grayscale
	gray100: '#fff',
	gray100rgb: [255, 255, 255],
	gray200: '#e5e4ec',
	gray200rgb: [229, 228, 236],
	gray300: '#bfbbcf',
	gray300rgb: [191, 187, 207],
	gray400: '#908ca0',
	gray400rgb: [144, 140, 160],
	gray500: '#625e70',
	gray500rgb: [98, 94, 112],
	gray600: '#4f4c57',
	gray600rgb: [79, 76, 87],
	gray700: '#3c3a42',
	gray700rgb: [60, 58, 66],
	gray800: '#312f38',
	gray800rgb: [49, 47, 56],
	gray900: '#26242b',
	gray900rgb: [38, 36, 43],
	gray1000: '#1a1a1f',
	gray1000rgb: [26, 26, 31],
	gray1100: '#121114',
	gray1100rgb: [18, 17, 20],
	gray1200: '#09090a',
	gray1200rgb: [9, 9, 10],
};

export { ColorVars };
export default ColorVars;