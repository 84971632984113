import { ConnectedAdmin, IBreakoutRoomProfile } from '../../types/working-model';
import { isMobile } from '../../utils/utils';
import { IncomingTypes, MessageTypes, SIGNATURE_REQUIRED_TYPES, SocketMessage, Listener, IncomingMessage } from './types';
import { getVisibilityListeners, verifyMessageSignature } from './utils';
import { assertIsAdmin, assertIsAdmins, assertIsBlProfile, assertIsBlProfiles, assertIsMessage, assertIsViewers, isChatMessage } from './assertions';
import { CorsWorker as Worker } from '../cors-worker';
import { getLogger } from '../../utils/debug-logger';
const log = getLogger('bl-socket:socket');
const silly = getLogger('bl-socket-silly:socket');
const worker = new Worker(new URL('../socket.worker.ts', import.meta.url)).getWorker();
const [hidden, visibilityChange] = getVisibilityListeners();

const RETRY_CONNECTION_COOLDOWN_MS = 2000;

export class SocketConnection {
	private breakoutProfile?: IBreakoutRoomProfile;
	private channel: string;
	private connectedAdmin?: ConnectedAdmin;
	private connectionWorker = worker;
	private hasHidden = false;
	private listeners: Listener = {};
	private onCurrentlyWatching?: (viewers: { viewers: string; }) => void;
	public ready = false;
	public disconnected = true;
	private visibilityListenerAdded = false;

	constructor(channel: string, admin?: ConnectedAdmin, breakoutProfile?: IBreakoutRoomProfile) {
		log("Creating new socket", channel);

		this.channel = channel;
		this.connectedAdmin = admin;
		this.breakoutProfile = breakoutProfile;
		this.setupConnection();
	}

	public getChannel = () => {
		return this.channel;
	};

	public setupConnection = () => {
		this.connectionWorker.removeEventListener('message', this.handleMessageFromWorker);

		// worker died - need to handle this
		this.connectionWorker.addEventListener('error', this.handleDisconnect);

		// the base channel connection with no namespace should not listen for incoming messages
		if (this.channel) {
			this.connectionWorker.addEventListener('message', this.handleMessageFromWorker);
		}

		if (!this.visibilityListenerAdded && isMobile) {
			// listen for browser event that fires with page has been put in background
			// mobile devices sometimes disconnect our socket when the user hits the home button
			// and we might need to reconnect as soon as the page becomes visible again
			document.addEventListener(visibilityChange, this.handleAppState, false);
			this.visibilityListenerAdded = true;
		}

		log('Sending getConnection message', this.channel, this.connectedAdmin, this.breakoutProfile);
		this.connectionWorker.postMessage({
			type: MessageTypes.getConnection,
			channel: this.channel,
			admin: this.connectedAdmin,
			profile: this.breakoutProfile
		});
	};

	private handleMessageFromWorker = (e: MessageEvent) => {
		this.handleMessage(e.data);
		silly('Received message from worker thread', this.channel, e.data);
	};

	private handleAppState = () => {
		if (document[hidden]) {
			this.hasHidden = true;
		} else {
			if (this.hasHidden) {
				const { channel, connectedAdmin, breakoutProfile } = this;
				silly('Sending getConnection message', channel);
				this.connectionWorker.postMessage({
					type: MessageTypes.getConnection,
					channel,
					admin: connectedAdmin,
					profile: breakoutProfile
				});
			}
		}
	};

	public getCurrentlyWatching = (callback: (viewers: { viewers: string; }) => void): void => {
		this.connectionWorker.postMessage({ type: MessageTypes.getCurrentlyWatching });
		this.onCurrentlyWatching = callback;
	};

	public updateWorkingEvent = (admin: ConnectedAdmin): void => {
		log('Update working event', admin);
		this.connectionWorker.postMessage({
			type: MessageTypes.updateWorkingEvent,
			channel: this.channel,
			admin
		});
	};

	private handleUpdatedUser = (admin: ConnectedAdmin): void => {
		this.listeners['update-admin']?.forEach((func) => func(admin));
	};

	private handleConnectedAdmin = (admin: ConnectedAdmin): void => {
		this.listeners['connected-single-admin']?.forEach((func) => func(admin));
	};

	private handleDisconnectedAdmin = (admin: ConnectedAdmin): void => {
		this.listeners['disconnected-admin']?.forEach((func) => func(admin));
	};

	private handleAdminsConnected = (admins: ConnectedAdmin[]): void => {
		this.listeners['connected-admins-list']?.forEach((func) => func(admins));
	};

	private handleUpdatedBRProfile = (blProfiles: IBreakoutRoomProfile[]): void => {
		this.listeners['update-breakout-room-profiles']?.forEach((func) => func(blProfiles));
	};

	private handleConnectedBRProfile = (blProfile: IBreakoutRoomProfile): void => {
		this.listeners['connected-single-breakout-room-profile']?.forEach((func) => func(blProfile));
	};

	private handleDisconnectedBRProfile = (blProfile: IBreakoutRoomProfile): void => {
		this.listeners['disconnect-profile-from-breakout-room']?.forEach((func) => func(blProfile));
	};

	private handleBRProfilesConnected = (blProfiles: IBreakoutRoomProfile[]): void => {
		this.listeners['connected-breakout-room-profiles-list']?.forEach((func) => func(blProfiles));
	};

	private handleDisconnect = (e: unknown): void => {
		this.ready = false;

		if (process.env.NODE_ENV !== 'development') {
			console.error("Worker thread died", e);
		}

		setTimeout(() => {
			this.connectionWorker = new Worker(new URL('../socket.worker.ts', import.meta.url)).getWorker();
			// the base channel connection with no namespace should not listen for incoming messages
			if (this.channel) {
				this.connectionWorker.addEventListener('message', this.handleMessageFromWorker);
			}
			this.setupConnection();
		}, RETRY_CONNECTION_COOLDOWN_MS);
	};

	private handleMessage = async (_message: IncomingMessage): Promise<void> => {
		try {
			const { type, _ns, admin, admins, blProfiles, blProfile, viewers, message } = _message;
			if (_ns !== this.channel) {
				return;
			}

			silly('handled message', _message);

			switch (type) {
				case IncomingTypes.message: {
					if (isChatMessage(message)) {
						this.listeners[message.type]?.forEach(func => func(message));
					} else {
						assertIsMessage(message);
						if ('message' in message) { message.data = message.message; }
						const isModeratorMessage = message?.moderator_reply;

						if (SIGNATURE_REQUIRED_TYPES.includes(message.type) || message.signature || isModeratorMessage) {
							if (message.signature) {
								const ver = await verifyMessageSignature(message.signature, message.message);
								if (ver) {
									this.listeners[message.message.type]?.forEach((func) => func(message.message));
								} else {
									console.error({ message }, "Invalid message signature");
								}
							} else {
								console.error({ message }, "Missing required signature");
							}
						} else {
							log('Should send message', message.type, message);
							this.listeners[message.type]?.forEach((func) => func(message?.data ?? message));
						}
					}

					break;
				}
				case IncomingTypes.handleUpdatedUser: {
					assertIsAdmin(admin);
					this.handleUpdatedUser(admin);
					break;
				}
				case IncomingTypes.handleConnectedAdmin: {
					assertIsAdmin(admin);
					this.handleConnectedAdmin(admin);
					break;
				}
				case IncomingTypes.handleDisconnectedAdmin: {
					assertIsAdmin(admin);
					this.handleDisconnectedAdmin(admin);
					break;
				}
				case IncomingTypes.handleAdminsConnected: {
					assertIsAdmins(admins);
					this.handleAdminsConnected(admins);
					break;
				}
				case IncomingTypes.handleUpdatedBRProfile: {
					assertIsBlProfiles(blProfiles);
					this.handleUpdatedBRProfile(blProfiles);
					break;
				}
				case IncomingTypes.handleConnectedBRProfile: {
					assertIsBlProfile(blProfile);
					this.handleConnectedBRProfile(blProfile);
					break;
				}
				case IncomingTypes.handleDisconnectedBRProfile: {
					assertIsBlProfile(blProfile);
					this.handleDisconnectedBRProfile(blProfile);
					break;
				}
				case IncomingTypes.handleBRProfilesConnected: {
					assertIsBlProfiles(blProfiles);
					this.handleBRProfilesConnected(blProfiles);
					break;
				}
				case IncomingTypes.currentlyWatching: {
					assertIsViewers(viewers);
					this.onCurrentlyWatching?.(viewers);
					break;
				}
				default: {
					console.warn(`Unknown message received from worker ${JSON.stringify(_message)}`);
				}
			}
		} catch (e: any) {
			if (e instanceof TypeError) {
				console.error(`Wrong message type received from socket worker ${this.channel} ${e.toString()}`, e);
			} else {
				console.error(e);
			}
		}
	};

	public addListener = (type: string, func: (...args: any[]) => any): void => {
		if (this.listeners[type]) {
			this.listeners[type] = [...this.listeners[type], func];
		} else {
			this.listeners[type] = [func];
		}
	};

	public removeListener = (type: string, func: (...args: any[]) => any): void => {
		this.listeners[type] =
			this.listeners[type]?.filter((_func) => _func !== func) ?? [];

		// no more listeners in the array, delete the listener
		if (this.listeners[type].length === 0) {
			delete this.listeners[type];
		}
	};

	public sendMessage = (message: SocketMessage): void => {
		log('Sending message to worker thread', message);
		this.connectionWorker.postMessage({
			type: MessageTypes.sendMessage,
			message,
			channel: this.channel,
			admin: this.connectedAdmin,
			profile: this.breakoutProfile
		});
	};

	public sendBreakoutData = (sessionUuid: string, breakoutUuid: string, opentokId: string, host: string): void => {
		log('Sending breakout data to worker thread', sessionUuid);
		this.connectionWorker.postMessage({
			type: MessageTypes.sendBreakoutData,
			channel: this.channel,
			message: {
				sessionUuid,
				breakoutUuid,
				opentokId,
				host
			},
			admin: this.connectedAdmin,
			profile: this.breakoutProfile
		});
	};

	public removeAllListeners = (): void => {
		this.listeners = {};
	};

	public getListeners = () => {
		return Object.keys(this.listeners);
	};

	public disconnect = (): void => {
		log("Disconnecting socket", this.channel);
		this.connectionWorker.postMessage({
			type: MessageTypes.disconnect,
			channel: this.channel,
			admin: this.connectedAdmin,
			profile: this.breakoutProfile
		});
		this.removeAllListeners();
		this.connectionWorker.removeEventListener('message', this.handleMessageFromWorker);
		this.disconnected = true;
	};

	public end = (): void => {
		log(`Visibility changed, disconnecting`);
		this.connectionWorker.postMessage({
			type: MessageTypes.end,
			channel: this.channel,
			admin: this.connectedAdmin,
			profile: this.breakoutProfile
		});
		this.connectionWorker.removeEventListener('message', this.handleMessageFromWorker);
		this.disconnected = true;
	};
}