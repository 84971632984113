import {
	GetSpeakers,
	SaveSpeaker,
	SearchSpeakers,
} from '../../../connection/speakers';
import { Action, AsyncAction } from '../../../types/actions';
import { Speaker } from '../../../types/working-model';

export const GET_SPEAKERS = 'GET_SPEAKERS';
export function getSpeakers(channel: number, token: string): AsyncAction {
	return {
		type: GET_SPEAKERS,
		promise: GetSpeakers(channel, token),
	};
}

export const SEARCH_SPEAKERS = 'SEARCH_SPEAKERS';
export function searchSpeakers(
	channel: number,
	token: string,
	value: string
): AsyncAction {
	return {
		type: SEARCH_SPEAKERS,
		promise: SearchSpeakers(channel, token, value),
	};
}

export const SAVE_SPEAKER = 'SAVE_SPEAKER';
export function saveSpeaker(speaker: Speaker, token: string): AsyncAction {
	return {
		type: SAVE_SPEAKER,
		promise: SaveSpeaker(token, speaker),
	};
}

export const CREATE_SPEAKER = 'CREATE_SPEAKER';
export function createSpeaker(speaker: Speaker): Action {
	return {
		type: CREATE_SPEAKER,
		payload: speaker,
	};
}

export const UPDATE_SPEAKER = 'UPDATE_SPEAKER';
export function updateSpeaker(speaker: Speaker): Action {
	return {
		type: UPDATE_SPEAKER,
		payload: speaker,
	};
}
