import { CommentBoxPrompt, CreateCommentBoxPrompt, CreateQuestionPrompt, LanguagesAbbr, PageModule, QuestionPrompt, sortQuestionsModuleFields } from "../types/working-model";
import { GetHv, HvHostMap, PatchJson, PostJson } from "./helpers";

export const FetchModuleQuestions = (module_id: number, language: LanguagesAbbr, token?: string, sortField = sortQuestionsModuleFields.created_at, direction = 'DESC'): Promise<any> => (
	GetHv(HvHostMap.chat, `/e3-get-module-questions/${module_id}/${language}?sort=${sortField}&direction=${direction}`, token)
);

export const FetchQuestionComments = (questionId: number, page = 0, token?: string): Promise<any> => (
	GetHv(HvHostMap.chat, `/e3-get-questions-comments/${questionId}?page=${page}`, token)
);

type CreateQuestionModuleProps = {
	questionPrompt: CreateQuestionPrompt & { channel: number }, // question module as a content module	pageModule: number,
	pageModule: number,
	token: string,
	baseLanguage: string | LanguagesAbbr,
	languages: string[] | LanguagesAbbr[]
}
export const CreateQuestionModule = (props: CreateQuestionModuleProps) => {
	return PostJson<Omit<CreateQuestionModuleProps, 'token'>, {
		questionPrompt: QuestionPrompt,
		pageModule: PageModule
	}>({
		path: '/v3/admin/channel/questions',
		token: props.token,
		data: {
			questionPrompt: props.questionPrompt,
			pageModule: props.pageModule,
			baseLanguage: props.baseLanguage,
			languages: props.languages
		}
	});
};


type CreateCommentBoxModuleProps = {
	commentBoxPrompt: CreateCommentBoxPrompt & { channel: number }, // CommentBox module as a content module	pageModule: number,
	pageModule: number,
	token: string,
	baseLanguage: string | LanguagesAbbr,
	languages: string[] | LanguagesAbbr[]
}
export const CreateCommentBoxModule = (props: CreateCommentBoxModuleProps) => {
	return PostJson<Omit<CreateCommentBoxModuleProps, 'token'>, {
		commentBoxPrompt: CommentBoxPrompt,
		pageModule: PageModule
	}>({
		path: '/v3/admin/channel/comment-box',
		token: props.token,
		data: {
			commentBoxPrompt: props.commentBoxPrompt,
			pageModule: props.pageModule,
			baseLanguage: props.baseLanguage,
			languages: props.languages
		}
	});
};



type UpdateQuestionModuleProps = {
	questionPrompt: QuestionPrompt,
	token: string,
	baseLanguage: string | LanguagesAbbr,
	languages: string[] | LanguagesAbbr[]
}
export const UpdateQuestionModule = (props: UpdateQuestionModuleProps) => {
	return PatchJson<Omit<UpdateQuestionModuleProps, 'token'>, QuestionPrompt>({
		path: '/v3/admin/channel/questions',
		token: props.token,
		data: {
			questionPrompt: props.questionPrompt,
			baseLanguage: props.baseLanguage,
			languages: props.languages
		}
	});
};



type UpdateCommentBoxModuleProps = {
	commentBoxPrompt: CommentBoxPrompt,
	token: string,
	baseLanguage: string | LanguagesAbbr,
	languages: string[] | LanguagesAbbr[]
}
export const UpdateCommentBoxModule = (props: UpdateCommentBoxModuleProps) => {
	return PatchJson<Omit<UpdateCommentBoxModuleProps, 'token'>, CommentBoxPrompt>({
		path: '/v3/admin/channel/comment-box',
		token: props.token,
		data: {
			commentBoxPrompt: props.commentBoxPrompt,
			baseLanguage: props.baseLanguage,
			languages: props.languages
		}
	});
};

