const keyOptions: [HmacImportParams, boolean, KeyUsage[]] = [
	{
		name: "HMAC",
		hash: "SHA-512",
	},
	true,
	["sign", "verify"]
];

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
	let binary = '';
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return btoa(binary);
};

const base64ToArrayBuffer = (base64: string) => {
	const binaryString = atob(base64);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
};

const getKey = async (): Promise<[CryptoKey, string]> => {
	const key = await window.crypto.subtle.generateKey(...keyOptions);
	const expKey = await window.crypto.subtle.exportKey("jwk", key);
	return [key, btoa(JSON.stringify(expKey))];
};

export const getSignature = async (): Promise<[string, string]> => {
	const agent = navigator.userAgent;
	const [key, keyString] = await getKey();
	const encoder = new TextEncoder();
	const data = encoder.encode(agent);
	const signature = await window.crypto.subtle.sign("HMAC", key, data);
	return [arrayBufferToBase64(signature), keyString];
};

export const checkSignature = async (signature: string, keyString: string): Promise<boolean> => {
	const agent = navigator.userAgent;
	const encoder = new TextEncoder();
	const data = encoder.encode(agent);
	const key = await window.crypto.subtle.importKey("jwk", JSON.parse(atob(keyString)), ...keyOptions);
	const result = await window.crypto.subtle.verify("HMAC", key, base64ToArrayBuffer(signature), data);
	return result;
};