import { Action } from '../../../types/actions';
import { ConnectedAdmin } from '../../../types/working-model';

export const SET_CONNECTED_ADMINS = "SET_CONNECTED_ADMINS";
export function setConnectedAdmins(admins: ConnectedAdmin[]): Action {
	return {
		type: SET_CONNECTED_ADMINS,
		payload: admins
	};
}

export const ADD_CONNECTED_ADMIN = "ADD_CONNECTED_ADMIN";
export function addConnectedAdmin(admin: ConnectedAdmin): Action {
	return {
		type: ADD_CONNECTED_ADMIN,
		payload: admin
	};
}

export const REMOVE_CONNECTED_ADMIN = "REMOVE_CONNECTED_ADMIN";
export function removeConnectedAdmin(admin: ConnectedAdmin): Action {
	return {
		type: REMOVE_CONNECTED_ADMIN,
		payload: admin
	};
}

export const UPDATE_CONNECTED_ADMIN = "UPDATE_CONNECTED_ADMIN";
export function updateConnectedAdmin(admin: ConnectedAdmin): Action {
	return {
		type: UPDATE_CONNECTED_ADMIN,
		payload: admin
	};
}