import { ResourceRequest } from "access/resource-grant";
import { DeleteJson, GetJson, PostJson, PutJson, getToken } from "./helpers";
import { BLAdmin, EPermissions } from "types/working-model";
import qs from 'qs';

const queryParams = (params: Record<string, string | undefined>) => qs.stringify(params);
const path = (part: string | number, params?: Record<string, string | undefined>) => {
	const baseUrl = process.env.REACT_APP_API_HOST;
	const path = `/v4/admin/access/${part}`;
	const url = new URL(path, baseUrl);
	if (params) {
		url.search = queryParams(params);
	}
	return url;
};

export type BLAdminWithPermissions =
	Pick<BLAdmin, 'id' | 'email' | 'profile' | 'verified'>
	& { user_access: string[], roles: EPermissions[] };

export type GetChannelAccessResponse = {
	data: BLAdminWithPermissions[];
	total: number;
}

export function GetChannelAccess(channel: number, page: number, limit = 20, search?: string, roleFilter?: EPermissions | '') {
	return GetJson<GetChannelAccessResponse>({
		path: path(channel, { page: page.toString(), limit: limit.toString(), search, roleFilter }),
		token: getToken()
	});
}

export function GrantAccess(channel: number, email: string, resources: ResourceRequest[]) {
	return PostJson<{ email: string, resources: ResourceRequest[] }, { message: string }>({
		path: path(`${channel}/user`),
		token: getToken(),
		data: {
			email,
			resources
		}
	});
}

export function RevokeAccess(channel: number, email: string) {
	return DeleteJson<{ email: string }, { message: string }>({
		path: path(`${channel}/user`),
		token: getToken(),
		data: {
			email
		}
	});
}

export function ReplaceUserAccess(channel: number, email: string, statements: string[], roles: EPermissions[]) {
	return PutJson<{ email: string, statements: string[], roles: EPermissions[] }, { message: string }>({
		path: path(`${channel}/user`),
		token: getToken(),
		data: {
			email,
			statements,
			roles
		}
	});
}

type ReplaceUserAccessBulkPayload = ({
	email: string,
	statements: string[],
	roles: EPermissions[]
})[];
export function ReplaceUserAccessBulk(channel: number, payload: ReplaceUserAccessBulkPayload) {
	return PutJson<ReplaceUserAccessBulkPayload, { message: string }>({
		path: path(`${channel}/bulk`),
		token: getToken(),
		data: payload
	});
}