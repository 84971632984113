import classNames from 'classnames';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';

import { useIsNewModuleGrouping } from "../../../../hooks/session.hooks";
import { getDefaultAgendaImage } from '../../../../store/utils/create-event';
import {
	LanguagesAbbr,
	PageModule,
	RegistrationStepType,
	Session,
	SessionPreview,
	SessionTypesEnum,
	Speaker,
	SpeakerAttribute as SpeakerAttributeType
} from '../../../../types/working-model';
import { hasSessionAccess } from '../../../../utils/registration-utils';
import { EIcon } from '../../../general-ui/icon/icon';
import ModalComponent from '../../../general-ui/modal/modal';
import { renderSocialLinks } from '../../../general-ui/social-links/social-links';
import { Tag } from '../../../general-ui/tag/tag';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { ParamsProps } from '../../live-event';
import { eventPath, stylingOverrideReturns } from '../../utils';
import { getTemplateClassName, getTextValue } from '../../../../utils/utils';
import useTranslationRoute from '../../hooks/use-translation-route';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { OptionalComponent } from '../../../../utils/optional-component';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';

import './speaker-details-modal.scss';
import '../../../../scss/live-event/base/containers/speaker-details-modal.scss';
import { ThemeContext } from 'components/live-event/theme-context';
import { EPaletteModes } from 'types/theme-packs';

interface Props {
	speaker: Speaker;
	template: string;
	language: LanguagesAbbr;
	stylingOverrides?: stylingOverrideReturns;
	onClose: () => void;
	relatedSessions?: number[];
	params: ParamsProps;
	onRelatedSessionClick: (path: string, state?: unknown) => void;
	module?: PageModule;
	setSpeaker: Dispatch<SetStateAction<Speaker | null>>;
}
export const SpeakerDetailsModal = ({
	speaker,
	language,
	stylingOverrides,
	template,
	onClose,
	relatedSessions,
	params,
	onRelatedSessionClick,
	module,
	setSpeaker = () => null,
}: Props): JSX.Element => {
	const { moduleTitleOverrides, primaryButtonOverrides } = stylingOverrides || {};

	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const sessions = useTypedSelector(state => state.LiveEventReducer.eventBundle?.sessions);
	const session = useMemo(() => {
		if (!sessions?.length) return workingSession;

		if (!params.sessionUuid && sessions?.length === 1) return sessions[0];

		return sessions?.find(session => session.uuid === params.sessionUuid);
	}, [params.sessionUuid, sessions, workingSession]);

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const longDescription = speaker?.description?.base?.length > 250;
	const [showMore, setShowMore] = useState(longDescription);

	const { description } = getTextValue(speaker, language);
	const translationRoute = useTranslationRoute();
	const { t } = useTranslate(translationRoute);
	const moduleId = speaker.module_id;

	const socialLinks = speaker.link_social ? renderSocialLinks(speaker.social_links || [], primaryButtonOverrides) : null;
	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const templateClassName = getTemplateClassName(template);

	const speakerAttributes = speaker?.attributes ? (
		speaker?.attributes.map((attribute, idx) => (
			<SpeakerAttribute key={idx} attribute={attribute} template={template} language={language} />
		))) : (
		null
	);

	const hasRoundedImage = !!module?.content?.speaker_image_radius;

	const handleClose = () => {
		onClose();
		setSpeaker(null);
	};

	return (
		<ModalComponent
			className={classNames(
				'ev-modal-wrapper live-event-container person-details-modal',
				{
					'session-v2-modal speaker-details-modal transparent-background': isModuleGroupingV2,
				},
				template,
				`${templateClassName}-styles`,
			)}
			title=""
			open={true}
			isAdmin={false}
			onRequestClose={handleClose}
			closeable
			cancellable
			eventPreview
			padTitle={false}
		>
			<div className="person-details-modal-content">
				<div className="person-details-image">
					<img
						src={speaker.image}
						alt={speaker.first_name}
						className={classNames({ 'rounded-speaker-image': hasRoundedImage })}
					/>
				</div>

				<div className="person-details-content">
					<TypographyItem
						tagName={isModuleGroupingV2 ? "h3" : "div"}
						className={classNames('person-details-name stable', { 'evt-typography-h3 ignore-modal-styling': isModuleGroupingV2 }, moduleTitleOverrides)}
						role="heading"
						aria-level={3}
					>
						{t(`speakers.${moduleId}.first_name`, speaker.first_name)} {t(`speakers.${moduleId}.last_name`, speaker.last_name)}
						{!isModuleGroupingV2 && <Tag text="Speaker" classNameTag="tag-container-speaker" />}
					</TypographyItem>
					<OptionalComponent display={isModuleGroupingV2}>
						<TypographyItem
							tagName="h4"
							className="person-details-job evt-typography-subtitle-2 ignore-modal-styling"
						>
							{speaker.job_title?.[language] as string ?? speaker.job_title?.base ?? ''}
						</TypographyItem>
					</OptionalComponent>
					<OptionalComponent display={!isModuleGroupingV2}>
						<TypographyItem className={classNames('evt-body-text-2 person-details-position')} tagName="p">
							{t(`speakers.${moduleId}.job_title`, speaker.job_title?.[language] as string ?? speaker.job_title?.base ?? '')}
						</TypographyItem>
					</OptionalComponent>
					{socialLinks}
					<TypographyItem
						className={classNames('person-details-description', {
							reduced: showMore && !isModuleGroupingV2,
							'evt-body-text-2': !isModuleGroupingV2,
							'evt-typography-subtitle-b2': isModuleGroupingV2,
							long: longDescription,
							'light-mode': !isDarkMode
						})}
						tagName="div"
					>
						{t(`speakers.${moduleId}.description`, description)}
					</TypographyItem>
					<OptionalComponent display={!isModuleGroupingV2}>
						{showMore ? (
							<div className={classNames('person-details-description-show-more')} onClick={() => setShowMore(!showMore)}>
								<button className={classNames('secondary evt-button', template)}>Show more</button>
							</div>
						) : (
							<></>
						)}
					</OptionalComponent>
					<OptionalComponent display={!isModuleGroupingV2}>
						<ul className="personal-data-list">
							{speakerAttributes}
						</ul>
					</OptionalComponent>
				</div>
				<OptionalComponent display={!isModuleGroupingV2 && !!relatedSessions}>
					<SessionList template={template} relatedSessions={relatedSessions || []} params={params} onRelatedSessionClick={onRelatedSessionClick} />
				</OptionalComponent>
			</div>
		</ModalComponent>
	);
};

interface SessionListProps {
	template: string;
	relatedSessions: number[];
	params: ParamsProps;
	onRelatedSessionClick: (path: string, state?: unknown) => void;
}
const SessionList = ({ template, relatedSessions, params, onRelatedSessionClick }: SessionListProps): JSX.Element => {
	return (
		<div className="person-details-sessions">
			<div className="person-details-sessions-header">
				{!!relatedSessions.length && (
					<TypographyItem
						className="session-label stable"
						tagName="h4"
					>
						Sessions ({relatedSessions.length})
					</TypographyItem>
				)}
			</div>
			<ul>
				{
					relatedSessions
						? relatedSessions.map((id, idx) => (
							<SessionItem
								key={id}
								template={template}
								sessionId={id}
								params={params}
								onRelatedSessionClick={onRelatedSessionClick}
								idx={idx}
							/>
						))
						: null
				}
			</ul>
		</div>
	);
};

interface SessionItemProps {
	template: string;
	sessionId: number;
	params: ParamsProps;
	onRelatedSessionClick: (path: string, state?: unknown) => void
	idx: number;
}

const SessionItem = ({ template, sessionId, params, onRelatedSessionClick, idx }: SessionItemProps): JSX.Element => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const registrationId = useTypedSelector(state => state.LiveEventReducer.registrationId);
	const validSessions = useTypedSelector(state => state.LiveEventReducer.validSessions);
	const paidSessions = useTypedSelector(state => state.LiveEventReducer.paidSessions);
	const loadingValidSessions = useTypedSelector(state => state.LiveEventReducer.loadingValidSessions);
	const registration_bypass = useTypedSelector(state => state.LiveEventReducer.registration_bypass);

	const { language, eventName, uuid } = params;

	const availableSessions = eventBundle?.sessions ?? eventBundle?.sessionsPreviews;

	const session = useMemo(() => {
		if (availableSessions) {
			//ts was complaining about this union, couldn't figure out why, it should work just fine.
			return (availableSessions as any[]).find((session: Session | SessionPreview) => session.session === sessionId);
		}
	}, [availableSessions, sessionId]);

	const sessionLink = useMemo(() => {
		if (!session) return "";
		const ticketingStep = eventBundle?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing && step.isOn);
		let sessionHasTicket = false;

		if (ticketingStep) {
			const sessionTickets = ticketingStep.ticketing?.find(ticket => ticket.session_uuids.includes(session.uuid));
			if (sessionTickets) {
				sessionHasTicket = true;
			}
		}

		// if user has access (or if open event)
		if (hasSessionAccess(eventBundle, session, validSessions, (!!registration_bypass && !loadingValidSessions), paidSessions)) {
			let baseUrl = `/${eventPath(eventName, uuid)}/${language}/registration`;
			// if no ticketing or ticketing and user has access to session
			if (!ticketingStep || (ticketingStep && sessionHasTicket)) {
				baseUrl = `/${eventPath(eventName, uuid)}/${language}/session/${session.uuid}`;
			}

			return baseUrl;
		} else {
			// user doesn't have access so we don't provide a link
			return "";
		}
	}, [session, eventBundle, validSessions, paidSessions, eventName, uuid, language]);

	const handleSessionClick = () => {
		if (eventBundle?.registration_on && !registrationId) {
			onRelatedSessionClick(`/${eventPath(eventName, uuid)}/${language}/registration`);
		} else {
			if (!sessionLink) return null;
			onRelatedSessionClick(sessionLink);
		}
	};

	return (
		<li className="session-item">
			<div className="session-item-image">
				{session?.image ?
					<img src={session.image} width="88" height="88" aria-hidden="true" />
					:
					<img src={getDefaultAgendaImage(template, idx)} width="88" height="88" aria-hidden="true" />
				}
			</div>
			<div className="session-item-content">
				<TypographyItem
					className="session-item-date stable"
					tagName="time"
				>
					{session && session.session_type === SessionTypesEnum.onDemand && session.timestamp && new Date(session.timestamp).toLocaleDateString()}
				</TypographyItem>
				<TypographyItem
					className={classNames('session-item-title text-ellipsis evt-heading-5 stable')} tagName="h4">
					{session?.title[language] || session?.title.base}
				</TypographyItem>
				<button disabled={!sessionLink} className="session-item-link no-style no-padding" onClick={handleSessionClick}>Session details</button>
			</div>
		</li>
	);
};

interface ISpeakerAttribute {
	template: string;
	attribute: SpeakerAttributeType;
	language: LanguagesAbbr | 'base';
}

const SpeakerAttribute: React.FC<ISpeakerAttribute> = ({ attribute, language }) => {
	const { icon, attribute: attributeTitle, value } = attribute;
	return (
		<li>
			<div className="personal-data-list-icon">
				<EIcon name={icon} />
			</div>
			<div className="personal-data-list-content">
				<TypographyItem className="personal-data-list-label">
					{attributeTitle?.[language] ?? attributeTitle.base}
				</TypographyItem>
				<TypographyItem className={classNames('evt-body-text-2 personal-data-list-value')}>
					{value?.[language] ?? value.base}
				</TypographyItem>
			</div>
		</li>
	);
};
