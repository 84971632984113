import React, { useRef } from 'react';

import classNames from 'classnames';

import { IEventTag } from '../../../../types/working-model';
import { OptionalComponent } from '../../../../utils/optional-component';
import { Tooltip } from '../../../general-ui/tooltip/tooltip';
import { useGetAdjustedTagsCount } from './hooks/sessionTags.hooks';
import { useIsNewModuleGrouping } from '../../../../hooks/session.hooks';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useParams } from 'react-router-dom';
import { ParamsProps } from '../../live-event';
import './session-tags.scss';

interface ISessionTagsProps {
	tags: IEventTag[];
	className?: string;
	shouldRecalculate?: boolean;
}

function SessionTags({ tags, className, shouldRecalculate }: ISessionTagsProps): JSX.Element {
	const tagsContainerRef = useRef<HTMLDivElement | null>(null);
	const { language } = useParams<ParamsProps>();
	const { showedTags, hiddenTags } = useGetAdjustedTagsCount({ tags, tagsContainerRef, language, shouldRecalculate });
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const eventBundleSettings = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings);
	const workingEventSettings = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings);
	const eventSettings = eventBundleSettings || workingEventSettings;

	const shouldShowTagsCountNumber = tags.length && hiddenTags.length;

	const translatedTag = (name: string) => {
		const translatedTag = eventSettings?.tracks_translations?.[name]?.[language];
		if (translatedTag && typeof translatedTag === 'string') {
			return translatedTag;
		}

		return name;
	};

	const tooltipTagsList = (<>{hiddenTags.map(({ uuid, name }) => <p key={uuid}>{translatedTag(name)}</p>)}</>);

	return (
		<div className={classNames('agenda-session-card-tags', className)} ref={tagsContainerRef}>
			{showedTags?.map(({ uuid, name, color }: IEventTag) => (
				<span
					key={uuid}
					className='session-tag'
					style={{ color, backgroundColor: `${color}${isModuleGroupingV2 ? '4d' : '1a'}` }}
				>
					{translatedTag(name)}
				</span>
			))}

			<OptionalComponent display={!!shouldShowTagsCountNumber}>
				<Tooltip tooltip={tooltipTagsList} position='bottom-center'>
					<div className={classNames('session-tags-number')}>
						{`+${hiddenTags?.length}`}
					</div>
				</Tooltip>
			</OptionalComponent>
		</div>
	);
}

export default SessionTags;
