import * as amplitude from '@amplitude/analytics-browser';
import { BLAdmin, BrandliveEvent, GateTypes, IHomepageMainNavItems, ModuleMap, NavMainItems, PageModule, ProfileDisplayType, RegistrationTypes } from "../types/working-model";
import { Action } from "../types/actions";
import * as ActionTypesCreateEvent from "../store/actions/admin/create-event";
import * as ActionTypesEvents from "../store/actions/admin/events";
import * as ActionTypesAuthentication from "../store/actions/authentication";
import * as ActionTypesSponsors from "../store/actions/admin/sponsors";
import * as ActionTypesContent from "../store/actions/admin/content-actions";
import * as ActionTypesBrandliveAdmin from "../store/actions/admin/bl-admin";
import * as ActionTypesQuestions from "../store/actions/admin/registration-questions";
import * as ActionTypesEventActions from "../store/actions/event/event-actions";
import * as ActionTypesEventChat from "../store/actions/admin/event-chat";
import * as ActionTypesEventSession from "../store/actions/admin/create-event/session";
import * as ActionTypesEventLeaderboard from "../store/actions/admin/leaderboard-action-point";
import { GetExchangeRate } from '../connection/integrations';
import { gateTypeOptions } from '../components/admin/create/homepage/navigation/panel/settings/registration-settings/registration-gating';
import { EventsState } from '../store/types';
const ActionTypes = { ...ActionTypesCreateEvent, ...ActionTypesEvents, ...ActionTypesAuthentication, ...ActionTypesSponsors, ...ActionTypesContent, ...ActionTypesBrandliveAdmin, ...ActionTypesQuestions, ...ActionTypesEventActions, ...ActionTypesEventChat, ...ActionTypesEventSession, ...ActionTypesEventLeaderboard };
const REACT_APP_AMPLITUDE_CLIENT_KEY = process.env.REACT_APP_AMPLITUDE_CLIENT_KEY;


interface Ticket {
	price: number;
	currency: string;
	multiplier: number;
}

interface GateTypeOption {
	label: string;
	value: GateTypes;
}

interface ActionType {
	actionName: string;
	platform: PlatformPageValues;
	inverseActionPayloadIsOn?: true;
	inverseActionPayload?: true;
	actionPayload?: true;
}

interface ActionMap {
	[key: string]: ActionType;
}

export enum PlatformPageValues {
	AdminConsole = 'Admin Console',
	Editor = 'Editor',
	Landing = 'Landing',
	Sessions = 'Sessions',
	Session = 'Session',
	Home = 'Home',
	Registration = 'Registration',
	Event = 'Event',
	Social = 'Social',
	Emails = 'Emails',
	Theme = 'Theme',
}

enum RegistrationFeatures {
	useBlockedDomainEmails = 'useBlockedDomainEmails',
	sendEmailConfirmation = 'sendEmailConfirmation',
	registrationConfirmation = 'registrationConfirmation',

}


enum ValueKeys {
	TicketPrice = "Ticket Price",
	TicketQuantity = "Ticket Quantity",
	TicketCurrencies = "Ticket Currencies",
	NewOptInType = "New Opt-in Type",
	Toggle = "Toggle",
	ProjectType = "Project Type",
	EditorPage = "Editor Page",
	ProjectUUID = "Project UUID",
	ProjectID = "Project ID",
	SessionUUIDs = "Session UUIDs",
}

interface Values {
	[ValueKeys.TicketPrice]?: number;
	[ValueKeys.TicketQuantity]?: number;
	[ValueKeys.TicketCurrencies]?: string[];
	[ValueKeys.NewOptInType]?: string;
	[ValueKeys.Toggle]?: string;
	[ValueKeys.ProjectType]?: string;
	[ValueKeys.EditorPage]?: string;
	[ValueKeys.ProjectUUID]?: string;
	[ValueKeys.ProjectID]?: number;
	[ValueKeys.SessionUUIDs]?: string[];
}

const valueKeysArray: string[] = Object.values(ValueKeys);

enum CurrentIdentifyProperties {
	AdminID = "Admin ID",
	ChannelID = "Channel ID",
	Platform = "Platform",
	BrandliveEmployee = "Brandlive Employee",
	Authenticated = "Authenticated",
	UserPermissions = "User Permissions",
}

const currentIdentifyProperties: string[] = Object.values(CurrentIdentifyProperties);


const pastIdentifyProperties = [ // needs to be kept up to date or amplitude will not properly track the properties. Can't find any way around this weird fix due to amplitude being weird, can be reset to get rid of properties listed here with identifyEvent.clearAll() but that's also weird and I don't trust it fully
	"Event ID",
	"Event UUID",
	"session-uuids"
];

const pastAndCurrentIdentifyProperties = pastIdentifyProperties.concat(currentIdentifyProperties, valueKeysArray);

const cachedInits: { [email: string]: boolean } = {};

export class EventTracking {
	adminId: number;
	channelId: number;
	eventType: string | undefined;
	eventId: number | undefined;
	email: string;
	actionName: string | undefined;
	sessionUUIDs: string[];
	eventUUID: string | undefined;
	userPermissions: { [key: string]: string };
	canUse: boolean;

	constructor(user: BLAdmin | null, workingEvent: BrandliveEvent | null) {
		this.adminId = user?.id || -1;
		this.email = user?.email || '';
		this.eventId = workingEvent?.event;
		this.sessionUUIDs = (workingEvent?.sessions || []).map(session => session.uuid);
		this.eventType = workingEvent?.event_type;
		this.channelId = user?.active_channel || -1;
		this.eventUUID = workingEvent?.uuid;
		this.userPermissions = Object(user?.channels) || {};
		// to test locally, remove the hostname restriction here
		this.canUse = window.location.hostname.startsWith('admin.brandlive') && REACT_APP_AMPLITUDE_CLIENT_KEY !== undefined;

		if (!this.canUse) return;

		if (!cachedInits[this.email]) {
			amplitude.init(REACT_APP_AMPLITUDE_CLIENT_KEY as string, this.email, {
				defaultTracking: {
					attribution: false,
					pageViews: false,
					sessions: false,
					formInteractions: false,
					fileDownloads: false,
				},
				cookieOptions: {
					secure: true,
					upgrade: true
				}
			});

			cachedInits[this.email] = true;
		}
	}

	trackEvent(actionName: string, platform: string, toggleStartsOn?: boolean, values?: Values): void {
		if (!this.canUse) return;

		values = values || {};
		if (toggleStartsOn === true) {
			values.Toggle = 'on to off';
		} else if (toggleStartsOn === false) {
			values.Toggle = 'off to on';
		}
		if (this.eventType) {
			values[ValueKeys.ProjectType] = this.eventType;
		}
		if (platform in PlatformPageValues && platform !== PlatformPageValues.AdminConsole) {
			values[ValueKeys.EditorPage] = platform;
		}
		if (this.eventUUID) {
			values[ValueKeys.ProjectUUID] = this.eventUUID;
		}
		if (this.eventId) {
			values[ValueKeys.ProjectID] = this.eventId;
		}
		if (this.sessionUUIDs?.length) {
			values[ValueKeys.SessionUUIDs] = this.sessionUUIDs;
		}
		const identifyEvent = new amplitude.Identify();
		for (const property of pastAndCurrentIdentifyProperties) {
			if (!currentIdentifyProperties.concat(Object.keys(values)).includes(property)) {
				identifyEvent.unset(property);
			}
		}
		identifyEvent.set(CurrentIdentifyProperties.AdminID, this.adminId);
		identifyEvent.set(CurrentIdentifyProperties.ChannelID, this.channelId);
		identifyEvent.set(CurrentIdentifyProperties.Platform, platform === PlatformPageValues.AdminConsole ? PlatformPageValues.AdminConsole : PlatformPageValues.Editor);
		identifyEvent.set(CurrentIdentifyProperties.BrandliveEmployee, this.email.includes('@brandlive.com'));
		identifyEvent.set(CurrentIdentifyProperties.Authenticated, Boolean(this.email));
		identifyEvent.set(CurrentIdentifyProperties.UserPermissions, this.userPermissions[String(this.channelId)]);
		if (values !== undefined) {
			for (const [key, value] of Object.entries(values)) {
				identifyEvent.set(key, value);
			}
		}
		amplitude.identify(identifyEvent);
		amplitude.track(actionName);
	}
}

const actionMap: ActionMap = {
	[ActionTypes.ADD_ADMIN]: { actionName: "Add Admin", platform: PlatformPageValues.AdminConsole, },
	[ActionTypes.ADD_NEW_SESSION]: { actionName: "Add Session", platform: PlatformPageValues.Sessions },
	[ActionTypes.ADD_TICKETING_SET]: { actionName: "Add Ticket", platform: PlatformPageValues.Registration },
	[ActionTypes.ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE]: { actionName: "Toggle In person Attendee Mode", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.CREATE_ADMIN_EVENT_CHAT]: { actionName: "Add Messaging Channel", platform: PlatformPageValues.Social },
	[ActionTypes.CREATE_TEMPLATE_EVENT]: { actionName: "Create Project Template", platform: PlatformPageValues.Editor },
	[ActionTypes.DELETE_ADMIN_EVENT_CHAT]: { actionName: "Delete Messaging Channel", platform: PlatformPageValues.Social },
	[ActionTypes.PUBLISH_EVENT]: { actionName: "Publish Project", platform: PlatformPageValues.Editor },
	[ActionTypes.REMOVE_ADMIN]: { actionName: "Remove Admin", platform: PlatformPageValues.AdminConsole, },
	[ActionTypes.REMOVE_EVENT]: { actionName: "Delete Project", platform: PlatformPageValues.AdminConsole },
	[ActionTypes.REMOVE_TICKETING_SET]: { actionName: "Remove Ticket", platform: PlatformPageValues.Registration },
	[ActionTypes.RENAME_EVENT_CHAT_CHANNEL]: { actionName: "Rename Messaging Channel", platform: PlatformPageValues.Social },
	[ActionTypes.RESET_REGISTRATIONS]: { actionName: "Reset Registration", platform: PlatformPageValues.Social },
	[ActionTypes.SET_CUSTOM_PASSCODE_LABEL]: { actionName: "Edit Custom Passcode Label", platform: PlatformPageValues.Registration },
	[ActionTypes.SET_EMAIL_LINK_LIMIT]: { actionName: "Toggle Limit email registration links to single use", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.SET_EVENT_CUSTOM_CSS]: { actionName: "Set Custom CSS", platform: PlatformPageValues.Theme },
	[ActionTypes.SET_EVENT_TAGS]: { actionName: "Edit Session Tags", platform: PlatformPageValues.Sessions },
	[ActionTypes.SET_EVENT_USE_SAFE_CSS]: { actionName: "Toggle Use Safe CSS", platform: PlatformPageValues.Theme, inverseActionPayload: true }, // triggers before save button is clicked
	[ActionTypes.SET_REGISTRATION_ON]: { actionName: "Toggle Registration", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.SET_REGISTRATION_SINGLE_SIGN_ON]: { actionName: "Toggle Single sign on", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.SET_REGISTRATION_SSO_DISPLAY_TOP]: { actionName: "Toggle Display SSO before general info questions", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.SET_REJECTED_PASSCODE_MESSAGE]: { actionName: "Customize Registration Error Message", platform: PlatformPageValues.Registration },
	[ActionTypes.SET_SELECTED_COLOR]: { actionName: "Select Color", platform: PlatformPageValues.Theme },
	[ActionTypes.SET_SELECTED_FONT]: { actionName: "Select Font", platform: PlatformPageValues.Theme },
	[ActionTypes.SET_SESSION_CSS]: { actionName: "Set Custom CSS", platform: PlatformPageValues.Session },
	[ActionTypes.SETUP_EVENT_PREVIEW]: { actionName: "Set up Project Preview", platform: PlatformPageValues.Editor, },
	[ActionTypes.SIGN_IN]: { actionName: "Sign In", platform: PlatformPageValues.AdminConsole },
	[ActionTypes.SIGN_OUT]: { actionName: "Sign Out", platform: PlatformPageValues.AdminConsole, },
	[ActionTypes.TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE]: { actionName: "Toggle Default to attendee's language", platform: PlatformPageValues.Event, inverseActionPayload: true },
	[ActionTypes.TOGGLE_BOOKMARK_ATTENDEES]: { actionName: "Toggle Attendee Bookmarking", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_CLOSE_REGISTRATION]: { actionName: "Toggle Close Registration", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_DIRECTORY]: { actionName: "Toggle Directory", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_HOMEPAGE]: { actionName: "Toggle Homepage", platform: PlatformPageValues.Home, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_LANDING_PAGE]: { actionName: "Landing Page Toggled", platform: PlatformPageValues.Landing, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_PROFILES]: { actionName: "Toggle Profile Details", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_SESSION_CHAT]: { actionName: "Toggle Audience Chat Within Session", platform: PlatformPageValues.Session, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_SESSION_FOOTER]: { actionName: "Toggle Display Session Footer", platform: PlatformPageValues.Event, inverseActionPayload: true },
	[ActionTypes.TOGGLE_DISPLAY_SESSIONS]: { actionName: "Toggle Hide Session", platform: PlatformPageValues.Event, actionPayload: true },
	[ActionTypes.TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING]: { actionName: "Toggle Hide Brandlive Logo", platform: PlatformPageValues.Emails, inverseActionPayload: true },
	[ActionTypes.TOGGLE_FILTERS]: { actionName: "Toggle Directory Filters", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_MESSAGING]: { actionName: "Toggle Direct Messaging", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_PERSONALIZED_ATTENDEE_LIST]: { actionName: "Toggle My List Agenda Builder", platform: PlatformPageValues.Event, inverseActionPayload: true },
	[ActionTypes.TOGGLE_REACTIONS]: { actionName: "Toggle reactions", platform: PlatformPageValues.Session, inverseActionPayload: true },
	[ActionTypes.TOGGLE_RECAPTCHA]: { actionName: "Toggle Recaptcha", platform: PlatformPageValues.Registration, inverseActionPayload: true },
	[ActionTypes.TOGGLE_REPLAY_ON]: { actionName: "Toggle Make session replay available automatically", platform: PlatformPageValues.Session, inverseActionPayload: true },
	[ActionTypes.TOGGLE_SESSION_FEEDBACK_SURVEY]: { actionName: "Toggle feedback survey", platform: PlatformPageValues.Session, inverseActionPayload: true },
	[ActionTypes.TOGGLE_SHOW_ADD_TO_CAL_BTN]: { actionName: "Toggle Add to calendar buttons", platform: PlatformPageValues.Event, inverseActionPayload: true },
	[ActionTypes.TOGGLE_TOPIC_GROUP_CHATS]: { actionName: "Toggle Messaging Channels", platform: PlatformPageValues.Social, inverseActionPayload: true },
	[ActionTypes.TOGGLE_TRACK_MODAL]: { actionName: "Toggle Display “My List” builder on first event entry", platform: PlatformPageValues.Event, inverseActionPayload: true },
	[ActionTypes.UPDATE_EMAIL_COLOR]: { actionName: "Select Color", platform: PlatformPageValues.Emails },
	[ActionTypes.UPDATE_NATIVE_REGISTRATION]: { actionName: "Edit Disable Native Registration", platform: PlatformPageValues.Registration },
	[ActionTypes.UPDATE_SESSION_FEEDBACK_SURVEY_TEXT]: { actionName: "Edit feedback survey", platform: PlatformPageValues.Session },
	[ActionTypes.UPDATE_SESSION]: { actionName: "Edit Session Display", platform: PlatformPageValues.Session },
};



export async function interpretAction(action: Action, state: EventsState): Promise<void> {
	const user = state.AuthReducer?.user;
	const workingEvent = state?.CreateEventReducer?.workingEvent;
	const token = state?.AuthReducer?.token || '';
	const workingSession = state?.CreateSessionReducer?.workingSession;
	const amplitudeTracker = new EventTracking(user, workingEvent);

	if (action.type in actionMap && action.payload !== undefined && !Object(action).error) {
		const { actionName, platform } = actionMap[action.type];
		if (actionMap[action.type].inverseActionPayload) {
			amplitudeTracker.trackEvent(actionName, platform, !action.payload);
		} else if (actionMap[action.type].actionPayload) {
			amplitudeTracker.trackEvent(actionName, platform, action.payload);
		} else {
			amplitudeTracker.trackEvent(actionName, platform);
		}
		return;
	}

	switch (action.type) {
		case ActionTypes.TOGGLE_POST_REGISTER_HOME_MODULE:
		case ActionTypes.TOGGLE_HOMEPAGE_MODULE: {
			let platform;
			if (action.type === ActionTypes.TOGGLE_HOMEPAGE_MODULE) {
				platform = PlatformPageValues.Landing;
			} else {
				platform = PlatformPageValues.Home;
				action = {
					...action,
					payload: {
						...action.payload,
						type: workingEvent?.homepage?.post_register_home_modules?.find((module: PageModule) => module.id === action.payload.moduleId)?.type,
						is_on: !action.payload.is_on
					}
				};
			}

			amplitudeTracker.trackEvent(`Toggle ${ModuleMap[action.payload.type]}`, platform, action.payload.is_on);
			break;
		}
		case ActionTypes.ADD_PAGE_MODULE:
			amplitudeTracker.trackEvent(`Add ${ModuleMap[action.payload.type]}`, PlatformPageValues.Editor); // to specify between Landing and homepage, need access to some way to store info (i.e. database)
			break;
		case ActionTypes.TOGGLE_DISPLAY_LANDING_PAGE:
			amplitudeTracker.trackEvent(`Toggle landing page`, PlatformPageValues.Editor, action.payload);
			break;
		case ActionTypes.SET_EDITOR_SIZE:
			amplitudeTracker.trackEvent(`Screen Size Changed to ${action.payload.slice(0, 1).toUpperCase() + action.payload.slice(1, action.payload.length)}`, PlatformPageValues.Editor);
			break;
		case ActionTypes.UPDATE_SPONSOR: // updating the saved sponsors, not the page, page is only HOMEPAGE_MODULES
			if ([undefined, true, false].includes(action.payload)) break;
			amplitudeTracker.trackEvent('Update Saved Sponsors', PlatformPageValues.Landing);
			break;
		case ActionTypes.SET_REGISTRATION_TYPE:
			switch (action.payload) {
				case RegistrationTypes.none: // not sure what this is for
					break;
				case RegistrationTypes.open:
					amplitudeTracker.trackEvent('Toggle Registration Gating', PlatformPageValues.Registration, true);
					break;
				case RegistrationTypes.gated:
					amplitudeTracker.trackEvent('Toggle Registration Gating', PlatformPageValues.Registration, false);
					break;
			}
			break;
		case ActionTypes.SET_REGISTRATION_FEATURE:
			switch (action.payload.feature) {
				case RegistrationFeatures.useBlockedDomainEmails:
					amplitudeTracker.trackEvent('Edit Blocked Domain Emails', PlatformPageValues.Registration);
					break;
				case RegistrationFeatures.sendEmailConfirmation:
					// when registrationConfirmation is triggered, sendEmailConfirmation also triggers, meaning:
					// if sendEmailConfirmation is on before registrationConfirmation is turned on, the tracker triggers unnecessarily,
					// if sendEmailConfirmation is off before registrationConfirmation is turned on, sendEmailConfirmation is turned on and the tracker triggers appropriately.
					amplitudeTracker.trackEvent('Toggle Send Email Confirmation', PlatformPageValues.Registration, !action.payload.on);
					break;
				case RegistrationFeatures.registrationConfirmation:
					amplitudeTracker.trackEvent('Toggle Require Email Confirmation', PlatformPageValues.Registration, !action.payload.on);
					break;
			}
			break;
		case ActionTypes.ADD_REGISTRATION_REQUIREMENT:
		case ActionTypes.REMOVE_REGISTRATION_REQUIREMENT:
			amplitudeTracker.trackEvent(`Toggle ${gateTypeOptions.find((gateType: GateTypeOption) => gateType.value === action.payload.type)?.label} requirement`, PlatformPageValues.Registration, action.type !== ActionTypes.ADD_REGISTRATION_REQUIREMENT);
			break;
		case ActionTypes.UPDATE_REGISTRATION_REQUIREMENT:
			amplitudeTracker.trackEvent(`Edit ${gateTypeOptions.find((gateType: GateTypeOption) => gateType.value === action.payload.type)?.label} requirement`, PlatformPageValues.Registration);
			break;
		case ActionTypes.UPDATE_TICKETING_SET: {// Reason this whole function is async
			const prices = action.payload.set.prices;
			const quantity = action.payload.set.quantity;
			const currencies = prices.map((ticket: Ticket) => ticket.currency);

			const exchangeRates = (await GetExchangeRate(token, "USD")).conversion_rates;
			const averageTicketPrice = prices.reduce((acc: number, ticket: Ticket) => {
				return acc + ticket.price / exchangeRates[ticket.currency];
			}, 0) / prices.length;
			const ticketValues = {
				"Ticket Price": Number(averageTicketPrice.toFixed(2)),
				"Ticket Quantity": quantity,
				"Ticket Currencies": currencies,
			};
			amplitudeTracker.trackEvent('Edit Ticket', PlatformPageValues.Registration, undefined, ticketValues);
			break;
		}
		case ActionTypes.UPDATE_REGISTRATION_STEPS: {
			if (!action.payload?.steps) break;
			const oldSteps = workingEvent?.registration_steps || [];
			const newSteps = action.payload?.steps || [];
			if (oldSteps.length !== newSteps.length) break;
			for (let i = 0; i < oldSteps.length; i++) {
				if (oldSteps[i].isOn !== newSteps[i].isOn) {
					const stepType = newSteps[i].type;
					const stepNames: Record<string, string> = {
						general: "General Info",
						profile: "Profile Info",
					};
					const stepName = stepNames[stepType] || (stepType[0].toUpperCase() + stepType.slice(1));

					amplitudeTracker.trackEvent(`Toggle ${stepName} Registration Step`, PlatformPageValues.Registration, !newSteps[i].isOn);
				}
			}
			break;
		}
		case ActionTypes.UPDATE_HOMEPAGE_MAIN_NAV:
			if (workingEvent?.homepage?.event_main_nav_items?.find((ni: IHomepageMainNavItems) => ni.name === NavMainItems.Leaderboard)?.is_on
				=== !action.payload?.find((ni: IHomepageMainNavItems) => ni.name === NavMainItems.Leaderboard)?.is_on) {
				amplitudeTracker.trackEvent('Toggle Leaderboard Page', PlatformPageValues.Social, !action.payload?.find((ni: IHomepageMainNavItems) => ni.name === NavMainItems.Leaderboard)?.is_on);
			}
			break;
		case ActionTypes.SET_SOCIAL_SETTINGS: // Rather than specifying unimportant stuff
		case ActionTypes.SET_SOCIAL_IN_STORE_ONLY:
			amplitudeTracker.trackEvent('Edit Displayed Profile Details', PlatformPageValues.Social);
			break;
		case ActionTypes.TOGGLE_PROFILE_APPEARANCE:
			switch (action.payload) {
				case ProfileDisplayType.popup:
					amplitudeTracker.trackEvent('Set Profile appearance as Popup', PlatformPageValues.Social);
					break;
				case ProfileDisplayType.page:
					amplitudeTracker.trackEvent('Set Profile appearance as Page', PlatformPageValues.Social);
					break;
			}
			break;
		case ActionTypes.UPDATE_MODERATION_SETTINGS: {
			const moderationBefore = workingSession?.moderation;
			const moderationAfter = action.payload;
			if (moderationAfter?.chat !== moderationBefore?.chat) {
				amplitudeTracker.trackEvent('Toggle Chat Messages Require Approval', PlatformPageValues.Session, !moderationAfter.chat);
			}
			if (moderationAfter?.questions !== moderationBefore?.questions) {
				amplitudeTracker.trackEvent('Toggle Questions Require Approval', PlatformPageValues.Session, !moderationAfter.questions);
			}
			break;
		}

		case ActionTypes.SET_MARKETING_OPT_IN_TYPE:
			amplitudeTracker.trackEvent('Change Marketing Opt-in Type', PlatformPageValues.Event, undefined, { "New Opt-in Type": String(action.payload) });
			break;
		case ActionTypes.UPDATE_EVENT_SETTINGS:
			if (workingEvent?.settings?.disable_session_thumbnails === !action.payload?.disable_session_thumbnails) {
				amplitudeTracker.trackEvent('Toggle Disable session thumbnails', PlatformPageValues.Event, !action.payload?.disable_session_thumbnails);
			}
			break;
		case ActionTypes.UPDATE_WORKING_EVENT: {
			const workingEventBefore = workingEvent;
			const workingEventAfter = action.payload;
			if (workingEventBefore?.name !== workingEventAfter?.name) {
				amplitudeTracker.trackEvent("Change Project Name", PlatformPageValues.Editor);
			}
			if (workingEventBefore?.settings?.gdpr?.enable_cookie_banner !== workingEventAfter?.settings?.gdpr?.enable_cookie_banner) {
				amplitudeTracker.trackEvent("Toggle Cookie banner", PlatformPageValues.Editor, !workingEventAfter?.settings?.gdpr?.enable_cookie_banner);
			}
			break;
		}
		case ActionTypes.SET_SESSION_LAYOUT: {
			const layoutBefore = workingSession?.layout;
			const layoutAfter = action.payload;
			if ([layoutBefore, layoutAfter].includes(undefined)) break;
			if (layoutBefore?.tabs.layout !== layoutAfter?.tabs.layout) {
				amplitudeTracker.trackEvent(`Change Session Page Layout to ${layoutAfter?.tabs.layout}`, PlatformPageValues.Editor);
			}
			break;
		}
		case ActionTypes.SET_SESSION_CHAT_DARK_MODE: {
			const darkModeBefore = workingSession?.session_chat_dark_mode_enabled;
			const darkModeAfter = action.payload;
			if (darkModeBefore !== darkModeAfter) {
				amplitudeTracker.trackEvent('Toggle Dark Mode in Video player', PlatformPageValues.Session, !darkModeAfter);
			}
		}
		// As of right now, stuff that involves uploading images is not working, there's a few implications of this and I haven't found them all, but two examples are: (within the Event tab in the Editor) Favicon Settings > Upload Image, Social Sharing Settings > Upload Image; (within the Session Tab within the Event Tab in the Editor): Session Display > Thumbnail > Add image thumbnail
	}
}