// REACT IMPORTS
import { useState, useRef, useMemo, useEffect } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

// REDUX IMPORTS 
import { GetUploadEndpoint, UploadFileToProvider } from '../../../connection/uploads';
import { CreateNewPasscodeList } from '../../../connection/passcode-lists';
import { useAppDispatch, useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { GetDefaultPasscodeList } from '../../../store/utils/create-event';
import { getPasscodeLists } from '../../../store/actions/admin/passcode-lists';
import { addRegistrationRequirement, getPublishedEventUrl, loadWorkingEvent, setRegistrationType } from '../../../store/actions/admin/create-event';

// FUNCTIONALITY IMPORTS 
import { PASSCODE_LIST_TYPE, WorkingPasscodeList, PasscodeList, FeatureFlagsEnum, GateTypes, RegistrationTypes, BrandliveEventListItem } from '../../../types/working-model';
import { getUploadFilename } from '../../../utils/utils';
import { OptionalComponent } from '../../../utils/optional-component';
import LargeButton from '../../general-ui/button/large-button';
import TextInput from '../../general-ui/text-input/text';
import { SelectOption } from '../../general-ui/select/select';
import Modal from '../../general-ui/modal/modal';
import { showAlert } from '../../general-ui/alert/alert-service';
import { DropdownOption } from "../../general-ui/dropdown/dropdown";
import { PrimaryTooltip } from '../create/session/session-modal';
import FileInput from '../../../utils/file-input';
import { reactSelectStyles, TReactSelectOption, adminModalMultiSelectStyling } from '../../general-ui/react-select/react-select-styles';
import { generateMagicLinksForEvent } from '../../../store/actions/admin/magic-links';
import { DownloadCsvButton } from '../create/registration/download-csv-button';
import { SetItemContentType } from '../../../connection/multicloud/upload';
import { isArray } from 'underscore';
import SelectInput from '../../general-ui/select/select';
import Icon, { ICONS, COLORS } from '../../general-ui/icon';
import { useIsAudienceListsUiQ32023 } from "./hooks/audience.hooks";

import './add-passcode-list.scss';

interface PasscodeListProps {
	setModalClosedSubmit: () => void;
	setModalClosedCancel: () => void;
	modalOpen: boolean;
	attachToEvent?: (list: PasscodeList) => void; // Adding for next ticket where we will connect the list on creation
}

const passcodeListTypeOptions: SelectOption[] = [
	{
		label: 'Email',
		value: PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(),
	},
	{
		label: 'Email & Passcode',
		value: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(),
	},
	{
		label: 'Passcode',
		value: PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(),
	}
];

const passcodeListTypeOptionsFFMagicLink: SelectOption[] = [
	{
		label: 'Email',
		value: PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(),
	},
	{
		label: 'Passcode',
		value: PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(),
	},
	{
		label: 'Email & Passcode',
		value: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(),
	},
	{
		label: 'Email & Magic Link',
		value: PASSCODE_LIST_TYPE.MAGIC_LINKS.toString(),
	}
];

const passcodeListTypeOptionsFFMagicLinkV2: SelectOption[] = [
	{
		label: 'Email',
		value: PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(),
	},
	{
		label: 'Email & Passcode',
		value: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(),
	},
	{
		label: 'Passcode',
		value: PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(),
	},
	{
		label: 'Email & Magic Link',
		value: PASSCODE_LIST_TYPE.MAGIC_LINKS.toString(),
	},
];

export default function PasscodeListCreator(props: PasscodeListProps): JSX.Element {
	const { setModalClosedSubmit, setModalClosedCancel, modalOpen } = props;
	const user = useTypedSelector(state => state.AuthReducer.user);
	const passcodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const events = useTypedSelector(state => state.EventsReducer.eventsList);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	const FF_MAGIC_LINK = !!featureFlags[FeatureFlagsEnum.magic_links];

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);

	const csvUpload = useRef<HTMLInputElement | null>(null);

	const [workingPasscodeList, setWorkingPasscodeList] = useState<WorkingPasscodeList>(GetDefaultPasscodeList());
	const [loading, setLoading] = useState(false);
	const [selectedEventUuid, setSelectedEventUuid] = useState<string>('');
	const [selectedEventName, setSelectedEventName] = useState<string>('');
	const dispatch = useAppDispatch();
	const isAudiencesUiQ32023 = useIsAudienceListsUiQ32023();

	const [displayListManipulation, setDisplayListManipulation] = useState<boolean>(true);

	useEffect(() => {
		const eventHasBeenPublished = Boolean(publishedUrl);
		const eventSelected = Boolean(selectedEventUuid);
		if (
			workingPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS
			&& !eventHasBeenPublished
			&& eventSelected
		) {
			setDisplayListManipulation(false);
		} else {
			setDisplayListManipulation(true);
		}
	}, [setDisplayListManipulation, publishedUrl, workingPasscodeList, selectedEventUuid]);

	useEffect(() => {
		if (selectedEventUuid && token && (!workingEvent || workingEvent.uuid !== selectedEventUuid)) {
			// Need to pulling workingEvent when a magic link list is being created
			dispatch(loadWorkingEvent(selectedEventUuid, token));
		}
	}, [selectedEventUuid, token, dispatch, workingEvent]);

	// Clear form on open/close
	useEffect(() => {
		setWorkingPasscodeList(GetDefaultPasscodeList());
	}, [modalOpen]);

	// Initiates setting the event for magic links
	const handleEventSelectionChange = (option: DropdownOption | null) => {
		if (option && token) {
			const eventUuid = option.value.toString();

			// Must check to see if the event has a published URL (during which Apply is disabled)
			dispatch(getPublishedEventUrl(token, eventUuid));

			// Both published and never-published events can sit in the select until the check is complete
			setSelectedEventUuid(eventUuid);
			setSelectedEventName(option.label);
		}
	};

	async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target?.files?.[0];

		if (file && token) {
			const endpoint = await GetUploadEndpoint(
				token,
				getUploadFilename(file.name)
			);
			const finalUrl = await UploadFileToProvider(
				endpoint,
				file,
			);
			SetItemContentType(token, finalUrl, file.type).catch(console.error);
			setWorkingPasscodeList((list: WorkingPasscodeList) => ({
				...list,
				url: finalUrl,
			}));
		}
	}

	async function onFile(file: File | FileList) {
		if (file instanceof File && token) {
			setLoading(true);
			const endpoint = await GetUploadEndpoint(
				token,
				getUploadFilename(file.name)
			);

			const finalUrl = await UploadFileToProvider(
				endpoint,
				file,
			);
			SetItemContentType(token, finalUrl, file.type).catch(console.error);
			setWorkingPasscodeList((list: WorkingPasscodeList) => ({
				...list,
				url: finalUrl,
			}));
			setLoading(false);
		}
	}

	function handlePasscodeListType(value: string | number) {
		switch (value) {
			case PASSCODE_LIST_TYPE.EMAILS_ONLY:
			case PASSCODE_LIST_TYPE.EMAILS_ONLY.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.EMAILS_ONLY,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.PASSCODES_ONLY:
			case PASSCODE_LIST_TYPE.PASSCODES_ONLY.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.PASSCODES_ONLY,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES:
			case PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES,
				}));
				break;
			}
			case PASSCODE_LIST_TYPE.MAGIC_LINKS:
			case PASSCODE_LIST_TYPE.MAGIC_LINKS.toString(): {
				setWorkingPasscodeList((list: WorkingPasscodeList) => ({
					...list,
					type: PASSCODE_LIST_TYPE.MAGIC_LINKS,
				}));
				break;
			}
		}
	}

	function handlePasscodeListName(e: React.ChangeEvent<HTMLInputElement>) {
		setWorkingPasscodeList((list: WorkingPasscodeList) => ({
			...list,
			name: e.target.value,
		}));
	}

	function handlePasscodeListMasterPasscode(e: React.ChangeEvent<HTMLInputElement>) {
		setWorkingPasscodeList((list: WorkingPasscodeList) => ({
			...list,
			master_passcode: e.target.value.toLocaleLowerCase(),
		}));
	}

	const eventOptions: TReactSelectOption[] = useMemo(() => {
		const list = events.filter(event => event.registration_on && event.required_registration_questions).map((event: BrandliveEventListItem) => {
			return {
				label: event.name.toString(),
				value: event.uuid.toString()
			};
		});
		return list;
	}, [events]);

	async function finished() {
		if (!token || !user?.active_channel) return;

		// A magic links list can't be applied to an event that doesn't have a URL
		const isMagicLinksList = workingPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS;
		const hasNeverBeenPublished = selectedEventUuid && !publishedUrl;
		if (isMagicLinksList && hasNeverBeenPublished) {
			return showAlert({
				message: 'Please Publish Project First',
				description: `You need to publish ${selectedEventName} first before you can attach a magic links list`,
				type: 'error',
				duration: 5000,
			});
		}

		const listToCreate = {
			...workingPasscodeList,
			name: workingPasscodeList.name.trim(),
		};

		// Check for empty name
		if (!listToCreate.name) {
			return showAlert({
				message: 'Name Required',
				description: 'Please enter a unique name for this audience list.',
				type: 'error',
				duration: 5000,
			});
		}

		// Check for duplicate names
		const lowerCaseName = listToCreate.name.toLowerCase();
		const isDuplicate = passcodeLists.some((list) => {
			return lowerCaseName === list.name?.toLowerCase();
		});
		if (isDuplicate) {
			return showAlert({
				message: 'Duplicate Audience List Name',
				description: `"${listToCreate.name}" is already in use. Please enter a unique name.`,
				type: 'error',
				duration: 5000,
			});
		}

		setLoading(true);
		const newPasscodeList = await CreateNewPasscodeList(
			token,
			user.active_channel,
			listToCreate,
			selectedEventUuid
		);

		const downloadErrorCSV = (newPasscodeList: any) => {
			// Downloads a csv file with all the broken users
			if (newPasscodeList.csv) {
				const blob = new Blob([newPasscodeList["csv"]], { type: 'text/csv;charset=utf-8' });
				const link = document.createElement('a');
				link.setAttribute('href', URL.createObjectURL(blob));
				link.setAttribute('download', 'magic-link-nonvalid-emails.csv');
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		};

		if ("csv" in newPasscodeList) {
			showAlert({
				message: 'Unable to Create',
				description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
				type: 'error',
				duration: 10000,
				onClick: () => downloadErrorCSV(newPasscodeList)
			});
			return setLoading(false);
		}
		else if ("error" in newPasscodeList) {
			showAlert({
				message: 'Unable to Create',
				description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
				type: 'error',
				duration: 5000,
			});
			return setLoading(false);
		}

		if (isArray(newPasscodeList.failedEmails) && newPasscodeList.failedEmails.length > 0) {
			showAlert({
				message: 'Some emails were invalid and failed to upload:',
				description: newPasscodeList.failedEmails.join(' '),
				type: 'error',
				duration: 5000
			});
		}

		dispatch(getPasscodeLists(user.active_channel, token));
		setLoading(false);
		setWorkingPasscodeList(GetDefaultPasscodeList());
		setModalClosedSubmit();

		// ATTACH EVENT 
		if (!(newPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS)) {
			return;
		}

		if (!workingEvent || !newPasscodeList.passcode_list) {
			return;
		}

		const sessionsIDs = workingEvent ? workingEvent?.sessions.map(sess => sess.session) : []; // Gets all the session ids in the event

		const workingGateRequirement = {
			type: GateTypes.passcode_list,
			passcodeList: [newPasscodeList.passcode_list],
			gatedSessions: [...sessionsIDs]
		};

		// Wait for the registration requirements to get added

		dispatch(addRegistrationRequirement(workingGateRequirement.type, workingGateRequirement));
		dispatch(setRegistrationType(RegistrationTypes.gated));
		if (token && user && newPasscodeList && newPasscodeList.passcode_list) {
			dispatch(generateMagicLinksForEvent(token, user?.active_channel, newPasscodeList?.passcode_list, selectedEventUuid));
		}
		showAlert({
			message: 'Attaching list to event',
			description: `Your list is attached to the event ${workingEvent?.name}`,
			type: 'text',
			duration: 5000,
		});
	}

	const noNameSet = workingPasscodeList.name?.trim() === '';
	const noEventSet = (FF_MAGIC_LINK && selectedEventUuid === '');

	const listTypeOptions = FF_MAGIC_LINK ? passcodeListTypeOptionsFFMagicLink : passcodeListTypeOptions;
	const listTypeOptionsV2 = isAudiencesUiQ32023 ? passcodeListTypeOptionsFFMagicLinkV2 : listTypeOptions;

	return (
		<Modal
			className={classNames('add-passcode-list-modal', { 'add-passcode-list-modal-v2': isAudiencesUiQ32023 })}
			open={modalOpen}
			onRequestClose={setModalClosedCancel}
			title="Add List"
			padTitle={false}
			subtitle="A list can be used give a group of people access to projects and sessions"
			cancellable={true}
			closeable={false}
			footer={
				<>
					<button onClick={setModalClosedCancel} style={{ marginRight: 16 }}>
						Cancel
					</button>
					<button
						disabled={
							loading ||
							noNameSet ||
							(workingPasscodeList.type === PASSCODE_LIST_TYPE.MAGIC_LINKS &&
								(noEventSet || fetchingPublishedStatus))
						}
						onClick={finished}
						className="lemonade"
					>
						Save
					</button>
				</>
			}
		>
			<div className="add-audience-list-form">
				<TextInput
					label={'List name'}
					onChange={handlePasscodeListName}
					placeholder={isAudiencesUiQ32023 ? 'Give your list a name' : 'List name'}
					value={workingPasscodeList.name}
				/>
				<SelectInput
					label={listTypeOptionsV2 ? 'List type' : ''}
					mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={14} color="white" />}
					iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={14} color={COLORS.WHITE} />}
					options={listTypeOptionsV2}
					selected={workingPasscodeList?.type.toString()}
					noMaxHeight
					onChange={handlePasscodeListType}
					highlightSelectedItem={isAudiencesUiQ32023}
				/>
				<OptionalComponent display={workingPasscodeList.type !== PASSCODE_LIST_TYPE.MAGIC_LINKS && workingPasscodeList.type !== PASSCODE_LIST_TYPE.EMAILS_ONLY}>
					<TextInput
						label={isAudiencesUiQ32023 ? 'Global Passcode' : 'Master Passcode'}
						tooltip={[PrimaryTooltip, 'Note: master passcodes are not case sensitive']}
						onChange={handlePasscodeListMasterPasscode}
						placeholder={isAudiencesUiQ32023 ? 'Enter global passcode' : 'Enter master passcode'}
						value={workingPasscodeList.master_passcode}
					/>
				</OptionalComponent>
				<OptionalComponent display={workingPasscodeList?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS}>
					<div className="invite-language-selector">
						{isAudiencesUiQ32023 && <label>Project</label>}
						<ReactSelect
							onChange={handleEventSelectionChange}
							options={eventOptions}
							placeholder={'Select event to attach'}
							closeMenuOnSelect={true}
							styles={reactSelectStyles(adminModalMultiSelectStyling({ plainTextValue: isAudiencesUiQ32023 }))}
							value={selectedEventUuid ? { label: selectedEventName, value: selectedEventUuid } : null}
							classNamePrefix="invite-language-select"
							className="invite-language-select"
						/>
					</div>
				</OptionalComponent>
			</div>
			<OptionalComponent display={displayListManipulation}>
				<div>
					<LargeButton
						title=""
						customTitle={<div className="upload-primary-text">
							<span className="plus">+ </span>
							<span>Upload an audience list</span>
						</div>}
						subtitle={'or drag and drop here in .csv file format'}
						onFile={onFile}
						allowedFileTypes={[
							'text/csv',
							'application/vnd.ms-excel',
						]}
						multiple={false}
					/>
					<FileInput
						type="file"
						onChange={handleFileChange}
						ref={csvUpload}
						accept={["application/csv"]}
						style={{ display: 'none' }}
					/>
					<div className="download-template-container">
						<p className="add-item info">
							{FF_MAGIC_LINK ? `If you're planning to generate magic links, please download the dynamic template` : ''}
						</p>
						<DownloadCsvButton
							eventUuid={selectedEventUuid}
							listType={workingPasscodeList.type}
							buttonText={isAudiencesUiQ32023 ? 'Download template' : undefined}
						/>
					</div>
				</div>
			</OptionalComponent>
			<OptionalComponent display={!displayListManipulation}>
				<div className="publish-error">Please publish this event before attaching an Email & Magic Link list</div>
			</OptionalComponent>
		</Modal>
	);
}
