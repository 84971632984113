import classNames from "classnames";

import { RegistrationQuestion } from "../../../../../../../types/working-model";
import { QuestionTypeMap } from "./sortable-registration-question-v2";

import "./registration-preview.scss";

type Props = {
	question: RegistrationQuestion;
	selected?: boolean;
	handleSelectQuestion?: (id: number, selected: boolean) => void;
	requiredQuestions: number[];
};
const AddQuestionCard = ({
	question,
	selected,
	handleSelectQuestion,
	requiredQuestions
}: Props) => {
	return (
		<div
			className={classNames("lemonade-wrapper", { selected })}
			key={question.registration_question}
			onClick={() => handleSelectQuestion?.(question.registration_question as number, !selected)}
		>
			<div className="registration-option-v2">
				<div className="registration-preview">
					<p className="registration-question-label">{question.name}</p>
					<div className="question-description">
						<div className="registration-question-type">{QuestionTypeMap[question.type]}</div>
						{requiredQuestions.includes(question.registration_question) ? <p>·</p> : null}
						{requiredQuestions.includes(question.registration_question) ? <div className="required"> Required</div> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddQuestionCard;