import React, { MouseEvent } from 'react';
import { Speaker } from '../../../../../../types/working-model';
import Checkbox from '../../../../../general-ui/checkbox/checkbox';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import IconUser from '../../../../../../images/icons/icon-user.svg';
import { resizedImage, ResizeParams } from '../../../../../../utils/utils';

interface Props {
	speaker: Speaker;
	isSelected: boolean;
	replacing: Speaker | boolean | null | undefined;
	handleChange: (s: Speaker) => void;
	handleDelete?: (s: Speaker) => void;
}

const CARD_WIDTH = 174;
const DPI_ADJUSTMENT = 2;
const thumbnailResizeOpts: ResizeParams = {
	height: CARD_WIDTH * DPI_ADJUSTMENT,
	width: CARD_WIDTH * DPI_ADJUSTMENT,
	fit: 'cover'
};

const AddSpeaker: React.FC<Props> = (props) => {
	const {
		speaker,
		replacing,
		handleChange,
		isSelected,
		handleDelete
	} = props;

	function addSpeakerToPageModule() {
		handleChange(speaker);
	}

	async function handleDeleteSpeaker(e: MouseEvent) {
		e.stopPropagation();
		handleDelete?.(speaker);
	}

	const replacingThisSpeaker = (replacing as Speaker)?.speaker === speaker.speaker;

	return (
		<div className="add-speaker">
			<div
				className={`image-container ${replacing ? (replacingThisSpeaker && "selected") : (isSelected && "selected")}`}
				style={{ backgroundImage: `url(${resizedImage(speaker.image?.replace(/\s/g, '%20'), thumbnailResizeOpts)}), url(${IconUser})` }}
				onClick={(e: MouseEvent) => {
					e.stopPropagation();
					addSpeakerToPageModule();
				}}
			>
				<div className="speaker-controls">
					<Checkbox
						checked={replacing ? replacingThisSpeaker : isSelected}
						onChange={addSpeakerToPageModule}
						value={Number(speaker.status)}
						transparent
						size={20}
						color={isSelected ? COLORS.CYAN : COLORS.WHITE}
						backgroundColor={isSelected ? COLORS.WHITE : undefined}
						selectedIconName={replacing ? ICONS.ON : ICONS.CHECKBOX_BORDERLESS}
						unselectedIconName={replacing ? ICONS.OFF : undefined}
					/>
					<button className="no-style" onClick={handleDeleteSpeaker}><Icon name={ICONS.TRASH} size={20} color={COLORS.WHITE} /></button>
				</div>
			</div>
			<div className="speaker-info">
				<h6>{speaker.first_name} {speaker.last_name}</h6>
				<p>{speaker.job_title?.base || speaker.description.base}</p>
			</div>
		</div>
	);
};

export default AddSpeaker;
