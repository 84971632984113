import { isDevEnvironment } from '../utils/utils';
import metricsApi from './metrics-api-hostname-tool';
import store from 'store/main';
import { FeatureFlagsEnum } from 'types/working-model';
import blAuth from './bl-auth/bl-auth';

export const MAX_RETRIES = 5;

export const handleFailedRequestWithMessage = async (res: Response) => {
	const cloned = res.clone();
	if ('json' in cloned) {
		try {
			const json = await cloned.json();
			const message = json?.error;
			if (message && typeof message === 'string') {
				return message;
			}
		} catch (e) {
			console.error(e);
		}
	}
	const clonedTwo = res.clone();
	if ('text' in clonedTwo) {
		try {
			const text = await clonedTwo.text();
			return text;
		} catch (e) {
			console.error(e);
		}
	}

	return cloned.statusText;
};

export const generateSignedRequest = async (path: string): Promise<string> => {
	if (!process.env.REACT_APP_SHARED_SECRET || !process.env.REACT_APP_SHARED_SECRET_EXPIRY || process.env.REACT_APP_STAGE === 'local') {
		return path;
	}

	const EXPIRY = +process.env.REACT_APP_SHARED_SECRET_EXPIRY;

	const url = new URL(path);
	const encoder = new TextEncoder();
	const secret = encoder.encode(process.env.REACT_APP_SHARED_SECRET);

	const key = await crypto.subtle.importKey('raw', secret, { name: 'HMAC', hash: 'SHA-256' }, false, ['sign']);

	const expiry = Date.now() + EXPIRY;
	const data = `${url.pathname}${expiry}`;

	const mac = await crypto.subtle.sign('HMAC', key, encoder.encode(data));
	const bytes = new Uint8Array(mac);

	let binary = '';
	for (let i = 0; i < bytes.length; ++i) {
		binary += String.fromCharCode(bytes[i]);
	}

	const base64 = btoa(binary);

	url.searchParams.set('mac', base64);
	url.searchParams.set('expiry', '' + expiry);

	return url.toString();
};

export const fetchFailTimeout = async (attempt: number) => {
	const interval = Math.pow(2, attempt) * 1000;
	await new Promise(r => setTimeout(r, interval));
};

export const useUnifiedAuth = () => {
	return store.getState().FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.unified_auth];
};

const removeSub = (hostname: string) => {
	return hostname.split('.').splice(-2).join('.');
};

export const isSameOrigin = () => {
	return removeSub(location.hostname) === removeSub(process.env.REACT_APP_API_HOST as string);
};

/*


New modes here:
- In the old system (non unified auth) the user's token would be passed
as an argument to the fetch function and included as the `bl-token` header

- In the new system there are two modes, same-origin and cross-origin

- In same-origin the token should be included as a cookie which will be automatically
appended to the request because `credentials: 'include'` is set

- In cross-origin the token should be included as a bearer token in the `Authorization` header
This is primarily used for development when the app may be running on localhost 
and the API is on a different domain


*/
const getHeaders = (token: string, headers = {}): Record<string, string> => {
	if (useUnifiedAuth()) {
		if (isSameOrigin() || !blAuth.accessToken) {
			// ignore the provided token - this is a same-origin request
			// and will include the token in the cookie
			return {
				"Content-Type": "application/json",
				...headers
			};
		} else {
			return {
				"Content-Type": "application/json",
				"bl-auth-method": "bearer",
				"Authorization": `Bearer ${blAuth.accessToken}`,
				...headers,
			};
		}
	} else {
		return {
			"Content-Type": "application/json",
			"BL-TOKEN": token,
			...headers,
		};
	}
};

function getParams(token: string, headers = {}) {
	const requestInit = {
		method: "GET",
		headers: getHeaders(token, headers),
		credentials: isSameOrigin() ? "include" as RequestCredentials : undefined
	};

	return requestInit;
}

function postParams(token: string, data: any, headers: { [key: string]: string }) {
	return {
		method: "POST",
		headers: getHeaders(token, headers),
		credentials: isSameOrigin() ? "include" as RequestCredentials : undefined,
		body: JSON.stringify(data)
	};
}

function patchParams(token: string, data: any, headers: { [key: string]: string }) {
	return {
		method: "PATCH",
		headers: getHeaders(token, headers),
		credentials: isSameOrigin() ? "include" as RequestCredentials : undefined,
		body: JSON.stringify(data)
	};
}

function putParams(token: string, data: any, headers = {}) {
	return {
		method: "PUT",
		headers: getHeaders(token, headers),
		credentials: isSameOrigin() ? "include" as RequestCredentials : undefined,
		body: JSON.stringify(data)
	};
}

function deleteParams(token: string, data?: any, headers = {}) {
	return {
		method: "DELETE",
		headers: getHeaders(token, headers),
		credentials: isSameOrigin() ? "include" as RequestCredentials : undefined,
		body: data ? JSON.stringify(data) : null
	};
}

function getHvParams(token?: string) {
	return {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? ""
		}
	};
}

function getHvParamsWithHeaders(headers: { [key: string]: string } = {}) {
	return {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			...headers
		}
	};
}

function postHvParams(data: any, token?: string, headers = {}) {
	return {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? "",
			...headers,
		},
		body: JSON.stringify(data)
	};
}

function putHvParams(data: any, token?: string) {
	return {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? ""
		},
		body: JSON.stringify(data)
	};
}

function putHvParamsWithHeaders(data: any, headers: { [key: string]: string }, token?: string) {
	return {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? "",
			...headers
		},
		body: JSON.stringify(data)
	};
}

function postHvParamsWithHeaders(data: any, headers: { [key: string]: string }, token?: string) {
	return {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? "",
			...headers
		},
		body: JSON.stringify(data)
	};
}

function deleteHvParams(data?: any, token?: string) {
	return {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? ""
		},
		body: JSON.stringify(data)
	};
}

function patchHvParams(data: any, token?: string) {
	return {
		method: "PATCH",
		headers: {
			"Content-Type": "application/json",
			"Bl-Profile-Token": token ?? ""
		},
		body: JSON.stringify(data)
	};
}

function parsePath(path: string) {
	return generateSignedRequest(`${process.env.REACT_APP_API_HOST}${path}`);
}

function parsePathMetrics(path: string) {
	return generateSignedRequest(`${metricsApi.hostname}${path}`);
}

export function getGreenroomEnvironmentUrl() {
	if (process.env.REACT_APP_STAGE) {
		switch (process.env.REACT_APP_STAGE) {
			case 'staging':
			case 'qa': {
				return `live-${process.env.REACT_APP_STAGE}.com`;
			}
			case 'local':
			case 'sandbox':
			case 'dev': {
				return 'live-dev.com';
			}
			case 'prod': {
				return '.live';
			}
			case 'hotfix': {
				return `live-hot.com`;
			}
		}
	} else if (isDevEnvironment()) {
		return 'live-dev.com';
	}
}

function parseGreenroomPath(path: string) {
	return generateSignedRequest(`${process.env.REACT_APP_GREENROOM_API_HOST}${path}`);
}

export const HvHostMap = {
	authorization: String(process.env.REACT_APP_HOSTS_AUTHORIZATION),
	chat: String(process.env.REACT_APP_HOSTS_CHAT),
	datalake: String(process.env.REACT_APP_HOSTS_DATALAKE),
	eventData: String(process.env.REACT_APP_HOSTS_EVENT_DATA),
	registration: String(process.env.REACT_APP_HOSTS_REGISTRATION),
	imageResizer: String(process.env.REACT_APP_HOSTS_IMAGE_RESIZER),
	sessionTracking: String(process.env.REACT_APP_HOSTS_SESSION_TRACKING),
	streamSso: String(process.env.REACT_APP_HOSTS_STREAM_SSO),
	stripe: String(process.env.REACT_APP_HOSTS_STRIPE),
	surveys: String(process.env.REACT_APP_HOSTS_SURVEYS)
};

type HvKey = keyof typeof HvHostMap;
type HvHost = typeof HvHostMap[HvKey];
export const parseHvPath = (host: HvHost, path: string) => {
	return `${host}${path}`;
};

export function parseTranslationsServicePath(path: string) {
	return `${process.env.REACT_APP_TRANSLATIONS_SERVICE_HOST}${path}`;
}

export class FetchError {
	status: number;
	error: string;
	constructor(err: { status: number, error: string }) {
		this.status = err.status;
		this.error = err.error;
	}

	toString() {
		return `${this.status}: ${this.error}`;
	}
}

const returnJson = async <ReturnType>(res: Response, allowText?: boolean): Promise<ReturnType> => {
	// content-type header might include a charset - just check that it begins with application/json
	if (res.headers.get('content-type')?.startsWith('application/json')) {
		const json = await res.json();
		if (res.ok) {
			return json as ReturnType;
			// fetch requests do not natively throw on non-2XX status codes. Throw our own error.
		} else {
			if ('error' in json) {
				throw new FetchError({
					status: res.status,
					error: json.error
				});
			} else {
				throw new FetchError({
					status: res.status,
					error: res.statusText
				});
			}
		}
	} else {
		const text = await res.text();

		if (allowText) {
			return { response: text } as unknown as ReturnType;
		}

		// since this is supposed to receive json and it did not, get the response as text and throw that as an error message
		throw new FetchError({
			status: res.status,
			error: text
		});
	}
};

const returnOk = async (res: Response): Promise<boolean> => {
	return res.ok;
};

export type GetJsonPayload = {
	path: string | URL,
	token: string,
	headers?: { [key: string]: string },
	isGreenroom?: boolean,
};

// get has too many args and is annoying
export const GetJson = async <ReturnType = Record<string, unknown>>(payload: GetJsonPayload): Promise<ReturnType> => {
	const { path, token, headers, isGreenroom } = payload;
	const _path = path instanceof URL ? path.href : isGreenroom ? await parseGreenroomPath(path) : await parsePath(path);

	try {
		const res = await fetch(_path, getParams(token, headers));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			// threw before the request could properly be sent - this is a code error
			console.error(e);
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export const Get = async (
	path: string,
	token: string,
	headers?: { [key: string]: string },
	returnRaw?: boolean,
	isGreenroom?: boolean,
	options?: { throwOnErrorBody?: boolean; },
): Promise<any> => {
	let _path;
	if (isGreenroom) {
		_path = await parseGreenroomPath(path);
	} else {
		_path = await parsePath(path);
	}

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, getParams(token, headers));

			const response = returnRaw ? res : await res.json();

			if (options?.throwOnErrorBody && response?.error && res.status >= 400 && res.status <= 599) {
				return Promise.reject(response.error);
			}

			return response;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const GetMetrics = async (
	path: string,
	token: string,
	headers?: { [key: string]: string },
	returnRaw?: boolean,
	isGreenroom?: boolean,
	options?: { throwOnErrorBody?: boolean; },
): Promise<any> => {
	let _path;
	if (isGreenroom) {
		_path = await parseGreenroomPath(path);
	} else {
		_path = await parsePathMetrics(path);
	}

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, getParams(token, headers));

			const response = returnRaw ? res : await res.json();

			if (options?.throwOnErrorBody && response?.error && res.status >= 400 && res.status <= 599) {
				return Promise.reject(response.error);
			}

			return response;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

/**
 * @remarks Token might be optional for end user code - uses different header for token
 * @param path Path to lambda function
 * @param token Optional for End User
 * @returns any
 */
export const GetHv = async <T = any>(service: HvHost, path: string, token?: string): Promise<T> => {
	const _path = await parseHvPath(service, path);

	try {
		const res = await fetch(_path, getHvParams(token));
		return await res.json();
	} catch (e: unknown) {
		// failure throws TypeError
		if (e instanceof TypeError) {
			console.error(path, e.message);
			throw e;
		}

		throw new Error(`Unable to fetch ${path}: ${e}`);
	}
};

export type GetJsonHvPayload = {
	path: string,
	headers?: { [key: string]: string },
	service: HvHost
};

// get has too many args and is annoying
export const GetHvJson = async <ReturnType = Record<string, unknown>>(payload: GetJsonHvPayload): Promise<ReturnType> => {
	const { path, service, headers } = payload;
	const _path = await parseHvPath(service, path);

	try {
		const res = await fetch(_path, getHvParamsWithHeaders(headers));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			// threw before the request could properly be sent - this is a code error
			console.error(e);
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export type PutPostJsonHvPayload = {
	path: string,
	headers?: { [key: string]: string },
	service: HvHost,
	data: any,
	allowText?: boolean,
	blProfileToken?: string
};

export const PutHvJson = async <ReturnType = Record<string, unknown>>(payload: PutPostJsonHvPayload): Promise<ReturnType> => {
	const { path, service, headers, data, blProfileToken, allowText } = payload;
	const _path = await parseHvPath(service, path);

	try {
		const res = await fetch(_path, putHvParamsWithHeaders(data, headers ?? {}, blProfileToken));
		return await returnJson<ReturnType>(res, allowText);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			// threw before the request could properly be sent - this is a code error
			console.error(e);
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export const PostHvJson = async <ReturnType = Record<string, unknown>>(payload: PutPostJsonHvPayload): Promise<ReturnType> => {
	const { path, service, headers, data, blProfileToken, allowText } = payload;
	const _path = await parseHvPath(service, path);

	try {
		const res = await fetch(_path, postHvParamsWithHeaders(data, headers ?? {}, blProfileToken));
		return await returnJson<ReturnType>(res, allowText);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			// threw before the request could properly be sent - this is a code error
			console.error(e);
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export const GetTranslationService = async (path: string, token: string, headers?: { [key: string]: string }): Promise<any> => {
	const _path = await parseTranslationsServicePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, getParams(token, headers));

			return res;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

type AnySerializable = Record<string, unknown> | Record<string, unknown>[];

export const PutTranslationService = async (path: string, token: string, body: AnySerializable = {}): Promise<boolean> => {
	const _path = await parseTranslationsServicePath(path);

	try {
		const res = await fetch(_path, putParams(token, body));
		return await returnOk(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export const Patch = async <T>(path: string, token: string, data: T, returnResults?: boolean, returnRaw?: boolean): Promise<any> => {
	const _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, patchParams(token, data, {}));

			if (returnRaw) {
				return res;
			}

			return returnResults ? await res.json() : res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PatchJson = async <InputType, ReturnType>(payload: PostJsonPayload<InputType>): Promise<ReturnType> => {
	const { path, data, token, headers, isGreenroom } = payload;
	const _path = path instanceof URL ? path.href : isGreenroom ? await parseGreenroomPath(path) : await parsePath(path);

	try {
		const res = await fetch(_path, patchParams(token, data, headers ?? {}));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

export type PutJsonPayload<T> = {
	path: string | URL,
	token: string,
	data: T,
	returnResults?: boolean,
	returnRaw?: boolean,
	returnErrorMsg?: boolean,
	headers?: Record<string, string>,
}

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PutJson = async <InputType, ReturnType>(payload: PutJsonPayload<InputType>): Promise<ReturnType> => {
	const { path, data, token, headers } = payload;
	const _path = path instanceof URL ? path.href : await parsePath(path);

	try {
		const res = await fetch(_path, putParams(token, data, headers));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Put = async <T>(
	path: string,
	token: string,
	data: T,
	returnResults?: boolean,
	returnRaw?: boolean,
	returnErrorMsg?: boolean,
	headers?: Record<string, string>,
): Promise<any> => {
	const _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, putParams(token, data, headers));

			if (returnErrorMsg && !res.ok) {
				const result = await handleFailedRequestWithMessage(res);
				return Promise.reject(result);
			}

			if (returnRaw) {
				return res;
			}

			return returnResults ? await res.json() : res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

/**
 * @remarks Token might be optional for end user code - uses different header for token
 * @param path Path at AWS
 * @param data Any js object
 * @param token Technically optional, but might return failure by API side
 * @returns Promise either JSON or boolean depending on returnResults
 */
export const PutHv = async <T>(service: HvHost, path: string, data: T, token?: string): Promise<any> => {
	const _path = await parseHvPath(service, path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, putHvParams(data, token));

			return await res.json();
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const PatchHv = async <T>(service: HvHost, path: string, data: T, token?: string, noReturn?: boolean, returnRaw?: boolean): Promise<any> => {
	const _path = await parseHvPath(service, path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res: Response = await fetch(_path, patchHvParams(data, token));

			if (noReturn) {
				return res.ok;
			}

			return returnRaw ? res : await res.json();
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const PostAbortable = <T>(path: string, token: string, data: T, noReturn?: boolean, headers = {}): [AbortController, Promise<any>] => {
	const controller = new AbortController();
	const signal = controller.signal;

	return [
		controller,
		parsePath(path).then(_path => fetch(_path, { ...postParams(token, data, headers), signal }).then(res => res.json())),
	];
};

export type PostJsonPayload<T> = {
	path: string | URL,
	token: string,
	data: T,
	headers?: Record<string, string>,
	isGreenroom?: boolean
}

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PostJson = async <InputType, ReturnType>(payload: PostJsonPayload<InputType>): Promise<ReturnType> => {
	const { path, data, token, headers, isGreenroom } = payload;
	const _path = path instanceof URL ? path.href : isGreenroom ? await parseGreenroomPath(path) : await parsePath(path);

	try {
		const res = await fetch(_path, postParams(token, data, headers ?? {}));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Post = async <T>(path: string, token: string, data: T, noReturn?: boolean, headers = {}, returnRaw?: boolean, returnErrorMsg?: boolean, isGreenroom?: boolean): Promise<any> => {
	let _path;
	if (isGreenroom) {
		_path = await parseGreenroomPath(path);
	} else {
		_path = await parsePath(path);
	}

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, postParams(token, data, headers));

			if (returnErrorMsg && !res.ok) {
				const result = await handleFailedRequestWithMessage(res);
				return Promise.reject(result);
			}

			if (noReturn) {
				return res.ok;
			}

			return returnRaw ? res : await res.json();
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

/**
 * @remarks Token might be optional for end user code - uses different header for token
 * @param path Path at AWS
 * @param data Any js object
 * @param token Technically optional, but might return failure by API side
 * @param noReturn If true will resolve boolean
 * @param headers Additional headers to pass into therequest
 * @returns Promise either JSON or boolean depending on noReturn
 */
export const PostHv = async <T>(service: HvHost, path: string, data: T, token?: string, noReturn?: boolean, headers?: Record<string, string>): Promise<any> => {
	const _path = await parseHvPath(service, path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, postHvParams(data, token, headers));

			if (!res.ok) {
				if (noReturn) {
					return false;
				}

				if (res.headers.get('content-type')?.includes('application/json')) {
					const err = await res.json();
					throw new FetchError({ status: res.status, error: err.error || err.reason || err.message });
				} else {
					const err = await res.text();
					throw new FetchError({ status: res.status, error: err });
				}
			}

			return noReturn ? (res.ok) : await res.json();
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

/**
 * @remarks No token needed for posting analytics data
 * @param path Path at AWS
 * @param data Any js object
 * @returns Promise boolean -> user agent successfully queued data
 */
export const Beacon = async <T>(service: HvHost, path: string, data: T): Promise<boolean> => {
	const _path = await parseHvPath(service, path);

	return await navigator.sendBeacon(_path, JSON.stringify(data));
};

/**
 * @remarks Token might be optional for end user code - uses different header for token
 * @param path Path at AWS
 * @param data Any js object
 * @param token Technically optional, but might return failure by API side
 * @returns Promise either JSON or boolean depending on returnResults
 */
export const DeleteHv = async <T>(service: HvHost, path: string, data?: T, token?: string): Promise<any> => {
	const _path = await parseHvPath(service, path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, deleteHvParams(data, token));

			return res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export const Delete = async <T>(path: string, token: string, data?: T, returningDeleted?: boolean, returnRaw?: boolean): Promise<any> => {
	const _path = await parsePath(path);

	let attempt = 0;
	for (let i = 0; i < MAX_RETRIES; ++i) {
		try {
			const res = await fetch(_path, deleteParams(token, data));

			if (returnRaw) {
				return res;
			}

			return returningDeleted ? await res.json() : res.ok;
		} catch (e: any) {
			if (e.message === 'Failed to fetch') {
				++attempt;

				if (i + 1 === MAX_RETRIES) {
					throw e;
				}

				await fetchFailTimeout(attempt);
			}

			throw e;
		}
	}
};

export type DeleteJsonPayload<T> = {
	path: string | URL,
	token: string,
	data: T,
	returnResults?: boolean,
	returnRaw?: boolean,
	returnErrorMsg?: boolean,
	headers?: Record<string, string>,
}

// any type is appropriate for a newly added generic not adopted anywhere
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeleteJson = async <InputType, ReturnType>(payload: DeleteJsonPayload<InputType>): Promise<ReturnType> => {
	const { path, data, token, headers } = payload;
	const _path = path instanceof URL ? path.href : await parsePath(path);

	try {
		const res = await fetch(_path, deleteParams(token, data, headers));
		return await returnJson<ReturnType>(res);
	} catch (e: unknown) {
		if (e instanceof FetchError) {
			throw e;
		} else {
			throw new FetchError({ error: 'Unknown error occurred', status: 500 });
		}
	}
};


export function getToken() {
	const state = store.getState();
	return state.AuthReducer.token ?? "";
}
