import store from '../store/main';
import * as EventEmitter from './event-emitter';
import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from "./session-storage";

// ten minutes
const INACTIVE_TIME = 1000 * 60 * 10;

const hiddenKey = 'hiddenAt';

// search entire DOM for playing videos with sound
const isAnyVideoPlaying = () => {
	const videos = document.getElementsByTagName('video');
	for (const video of videos) {
		if (!video.paused && video.volume > 0) {
			return true;
		}
	}

	return false;
};

function handleActivity() {
	if (document.visibilityState === 'hidden') {
		setSessionStorageItem(hiddenKey, (Date.now() + INACTIVE_TIME).toString());
	} else {
		reconnect();
	}
}

const reconnect = () => {
	EventEmitter.broadcastSignal('socket-reconnect');
	removeSessionStorageItem(hiddenKey);
};

const disconnect = () => {
	EventEmitter.broadcastSignal('socket-disconnect');
	removeSessionStorageItem(hiddenKey);
};

export default function MonitorPageVisibility(): void {
	document.addEventListener("visibilitychange", handleActivity);

	setInterval(() => {
		const now = Date.now();
		const thirtyAgo = now - (1000 * 60 * 30);
		const inFifteen = now + (1000 * 60 * 15);
		if (document.visibilityState === 'hidden') {
			const sessions = store.getState().LiveEventReducer.eventBundle?.sessions;

			const hiddenTime = getSessionStorageItem(hiddenKey);

			if (Number(hiddenTime) < now) {

				// first check if any session is inside the live timestamp
				const sessionCanBeLive = sessions?.some(session => {
					if (session.timestamp && session.end_timestamp) {
						// if the session timestamp is within thirty minutes and the end is more than 15 minutes after the end timestamp
						return session.timestamp > thirtyAgo && session.end_timestamp < inFifteen;
					}
				});

				// could be on demand, check for any video element that is playing and has volume
				if (!isAnyVideoPlaying() && !sessionCanBeLive) {
					disconnect();
				}
			}
		}
	}, 10000);
}