import {
	ContinueStripeLink,
	createSolutionInstance,
	DisableGoogleAnalytics,
	toggleInstance,
	GetGoogleAnalytics,
	getSolutionInstances,
	getSolutions,
	GetStripeIntegration,
	GetStripeLink,
	SetGoogleAnalytics,
	SetStripeContact,
	getTrayUserId,
	GetSSOIntegrationsForChannel,
	GetOauth2IntegrationForChannel,
	CreateOauth2IntegrationForChannel,
	UpdateOauth2Integration,
	ToggleOauth2Integration,
	DeleteOauth2Integration,
	GetPublicOauth2IntegrationForChannel,
	GetEventInstances,
	createSolutionInstanceMapped,
	getChannelInstances,
	toggleEventIntegration,
	ToggleAdminUseOauth2Integration
} from "../../../connection/integrations";
import { Action, AsyncAction } from "../../../types/actions";
import { EIntegrationType, InsertIntegrationsMappedBody, ITrayUser } from "../../../types/integrations";
import { CreateOauth2Integration, Oauth2Integration } from "../../../types/working-model";

export const INTEGRATIONS_STRIPE_STATUS = "INTEGRATIONS_STRIPE_STATUS";
export function getStripeStatus(token: string): AsyncAction {
	return {
		type: INTEGRATIONS_STRIPE_STATUS,
		promise: GetStripeIntegration(token)
	};
}

export const INTEGRATIONS_STRIPE_STARTUP = "INTEGRATIONS_STRIPE_STARTUP";
export function startStripeIntegration(token: string): AsyncAction {
	return {
		type: INTEGRATIONS_STRIPE_STARTUP,
		promise: GetStripeLink(token)
	};
}

export const INTEGRATIONS_CONTINUE_STRIPE = "INTEGRATIONS_CONTINUE_STRIPE";
export function continueStripeIntegration(token: string): AsyncAction {
	return {
		type: INTEGRATIONS_CONTINUE_STRIPE,
		promise: ContinueStripeLink(token)
	};
}

export const INTEGRATIONS_STRIPE_CONTACT = "INTEGRATIONS_STRIPE_CONTACT";
export function setStripeContact(token: string, email: string): AsyncAction {
	return {
		type: INTEGRATIONS_STRIPE_CONTACT,
		promise: SetStripeContact(token, email)
	};
}

export const GET_GOOGLE_ANALYTICS = "GET_GOOGLE_ANALYTICS";
export function getGoogleAnalytics(token: string, channel: number): AsyncAction {
	return {
		type: GET_GOOGLE_ANALYTICS,
		promise: GetGoogleAnalytics(token, channel)
	};
}

export const SET_GOOGLE_ANALYTICS = "SET_GOOGLE_ANALYTICS";
export function setGoogleAnalytics(token: string, channel: number, ga: string): AsyncAction {
	return {
		type: SET_GOOGLE_ANALYTICS,
		promise: SetGoogleAnalytics(token, channel, ga)
	};
}

export const DISABLE_GOOGLE_ANALYTICS = "DISABLE_GOOGLE_ANALYTICS";
export function disableGoogleAnalytics(token: string, channel: number): AsyncAction {
	return {
		type: DISABLE_GOOGLE_ANALYTICS,
		promise: DisableGoogleAnalytics(token, channel)
	};
}

export const GET_USER_SOLUTION_INSTANCES = "GET_USER_SOLUTION_INSTANCES";
export function getUserSolutionInstances(token: string, userId: string): AsyncAction {
	return {
		type: GET_USER_SOLUTION_INSTANCES,
		promise: getSolutionInstances(token, userId)
	};
}

export const GET_TRAY_SOLUTIONS = "GET_TRAY_SOLUTIONS";
export function getTraySolutions(token: string, channel: number): AsyncAction {
	return {
		type: GET_TRAY_SOLUTIONS,
		promise: getSolutions(token, channel)
	};
}

export const GET_CHANNEL_MAPPED_INSTANCES = "GET_CHANNEL_MAPPED_INSTANCES";
export function getChannelMappedInstances(token: string, channel: number): AsyncAction {
	return {
		type: GET_CHANNEL_MAPPED_INSTANCES,
		promise: getChannelInstances(token, channel)
	};
}

export const CREATE_TRAY_INSTANCE = "CREATE_TRAY_INSTANCE";
export function createTrayInstance(token: string, channel: number, userId: string, solutionId: string, solutionName: string, type: EIntegrationType, trayUser: ITrayUser): AsyncAction {
	return {
		type: CREATE_TRAY_INSTANCE,
		promise: createSolutionInstance(token, channel, userId, solutionId, solutionName, type, trayUser)
	};
}

export const CREATE_TRAY_INSTANCE_MAPPED = "CREATE_TRAY_INSTANCE_MAPPED";
export function createTrayInstanceMapped(token: string, createInstanceBody: InsertIntegrationsMappedBody): AsyncAction {
	return {
		type: CREATE_TRAY_INSTANCE_MAPPED,
		promise: createSolutionInstanceMapped(token, createInstanceBody)
	};
}

export const TOGGLE_TRAY_INSTANCE = "TOGGLE_TRAY_INSTANCE";
export function toggleTrayInstance(token: string, userId: string, channel: number, type: EIntegrationType, instanceId: string, enabled: boolean): AsyncAction {
	return {
		type: TOGGLE_TRAY_INSTANCE,
		promise: toggleInstance(token, userId, channel, instanceId, enabled, type)
	};
}

export const TOGGLE_CUSTOM_MAPPED_INSTANCE = "TOGGLE_CUSTOM_MAPPED_INSTANCE";
export function toggleCustomMappedInstance(token: string, userId: string, channel: number, instanceId: string, enabled: boolean): AsyncAction {
	return {
		type: TOGGLE_CUSTOM_MAPPED_INSTANCE,
		promise: toggleInstance(token, userId, channel, instanceId, enabled, undefined, true)
	};
}

export const TOGGLE_EVENT_OVERRIDES_INSTANCE = "TOGGLE_EVENT_OVERRIDES_INSTANCE";
export function toggleEventInstance(token: string, instance_uuid: string, event_uuid: string, channel: number, enable: boolean): AsyncAction {
	return {
		type: TOGGLE_EVENT_OVERRIDES_INSTANCE,
		promise: toggleEventIntegration(token, instance_uuid, event_uuid, channel, enable)
	};
}

export const GET_TRAY_USER = 'GET_TRAY_USER';
export function getTrayUser(token: string, channel: number, name: string, uuid: string): AsyncAction {
	return {
		type: GET_TRAY_USER,
		promise: getTrayUserId(token, channel, name, uuid)
	};
}

export const CLEAR_INSTANCE_TO_CREATE = "CLEAR_INSTANCE_TO_CREATE";
export function clearInstanceToCreate(): Action {
	return {
		type: CLEAR_INSTANCE_TO_CREATE,
		payload: null
	};
}

export const GET_SSO_INTEGRATIONS = "GET_SSO_INTEGRATIONS";
export function getSSOIntegrations(token: string, channel: number): AsyncAction {
	return {
		type: GET_SSO_INTEGRATIONS,
		promise: GetSSOIntegrationsForChannel(token, channel)
	};
}

export const GET_OAUTH2_INTEGRATION = "GET_OAUTH2_INTEGRATION";
export function getOauth2Integration(token: string, channel: number): AsyncAction {
	return {
		type: GET_OAUTH2_INTEGRATION,
		promise: GetOauth2IntegrationForChannel(token, channel)
	};
}

export const GET_PUBLIC_OAUTH2_INTEGRATION = "GET_PUBLIC_OAUTH2_INTEGRATION";
export function getPublicOauth2Integration(token: string, channel: number): AsyncAction {
	return {
		type: GET_PUBLIC_OAUTH2_INTEGRATION,
		promise: GetPublicOauth2IntegrationForChannel(token, channel)
	};
}

export const CREATE_OAUTH2_INTEGRATION = "CREATE_OAUTH2_INTEGRATION";
export function createOauth2Integration(token: string, channel: number, integration: CreateOauth2Integration): AsyncAction {
	return {
		type: CREATE_OAUTH2_INTEGRATION,
		promise: CreateOauth2IntegrationForChannel(token, channel, integration)
	};
}

export const UPDATE_OAUTH2_INTEGRATION = "UPDATE_OAUTH2_INTEGRATION";
export function updateOauth2Integration(token: string, channel: number, integration: Oauth2Integration): AsyncAction {
	return {
		type: UPDATE_OAUTH2_INTEGRATION,
		promise: UpdateOauth2Integration(token, channel, integration)
	};
}

export const TOGGLE_OAUTH2_INTEGRATION = "TOGGLE_OAUTH2_INTEGRATION";
export function toggleOauth2Integration(token: string, channel: number, uuid: string, on: boolean): AsyncAction {
	return {
		type: TOGGLE_OAUTH2_INTEGRATION,
		promise: ToggleOauth2Integration(token, channel, uuid, on)
	};
}

export const TOGGLE_OAUTH2_ADMIN_USE = "TOGGLE_OAUTH2_ADMIN_USE";
export function toggleAdminUseOauth2Integration(token: string, channel: number, uuid: string, on: boolean): AsyncAction {
	return {
		type: TOGGLE_OAUTH2_ADMIN_USE,
		promise: ToggleAdminUseOauth2Integration(token, channel, uuid, on)
	};
}


export const DELETE_OAUTH2_INTEGRATION = "DELETE_OAUTH2_INTEGRATION";
export function deleteOauth2Integration(token: string, channel: number, uuid: string): AsyncAction {
	return {
		type: DELETE_OAUTH2_INTEGRATION,
		promise: DeleteOauth2Integration(token, channel, uuid)
	};
}

export const GET_EVENT_INSTANCES = "GET_EVENT_INSTANCES";
export function getEventInstances(token: string, event_uuid: string, channel: number): AsyncAction {
	return {
		type: GET_EVENT_INSTANCES,
		promise: GetEventInstances(token, event_uuid, channel)
	};
}
