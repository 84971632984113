import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useSessionUuidParamAdmin = () => {
	const session = useParams<{ session: string }>().session;
	return useMemo(() => session, [session]);
};

export const useSessionUuid = () => {
	const params = useParams<{ sessionUuid: string }>();
	return useMemo(() => params?.sessionUuid, [params?.sessionUuid]);
};