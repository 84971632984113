import { useMemo } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useIsNewModuleGrouping } from "../../../../../../hooks/session.hooks";

import {
	LanguagesAbbr, SurveyQuestion, SurveyQuestionType
} from '../../../../../../types/working-model';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import classNames from 'classnames';

interface SortableQuestion {
	id: string;
	items?: number;
	editQuestion: (question: SurveyQuestion) => void;
	question: SurveyQuestion;
	handleDeleteQuestion: (question: SurveyQuestion) => () => void;
	language: LanguagesAbbr;
	className?: string;
}

export function SurveyQuestionComponent(props: SortableQuestion) {
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const { attributes, listeners, setActivatorNodeRef, setNodeRef, transform } = useSortable({
		id: props.id,
	});

	const { items, question, editQuestion, handleDeleteQuestion, language, className } = props;

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	const validMultiple = useMemo(() => {
		if (question?.question_type !== SurveyQuestionType.text) {
			return question?.options?.length > 0;
		}

		return true;
	}, [question]);

	const type = useMemo(() => {
		switch (question.question_type) {
			case SurveyQuestionType.text:
				return isModuleGroupingV2 ? 'Comment box' : 'Text area';
			case SurveyQuestionType.radio:
				return isModuleGroupingV2 ? 'Single choice' : 'Select one';
			case SurveyQuestionType.checkbox:
				return isModuleGroupingV2 ? 'Multiple choice' : 'Select multiple';
		}
	}, [isModuleGroupingV2, question.question_type]);

	const showDragIcon = isModuleGroupingV2 && (items || 0) > 1;

	if (!question) return null;

	return (
		<div
			className={classNames('survey-question', className)}
			ref={setNodeRef}
			style={style}
		>
			{showDragIcon && (
				<div
					className="drag-handle"
					ref={setActivatorNodeRef}
					{...attributes}
					{...listeners}
				>
						<Icon
							name={ICONS.DRAG_HANDLE}
							size={18}
							color={COLORS.DEFAULT_GRAY}
						/>
				</div>
			)}
			<div className="survey-question-info">
				<label>{question?.title?.[language] ?? question?.title?.base}</label>
				<span>
					{type}{' '}
					{!validMultiple && (
						<span className="invalid-multiple">
							(at least one option required)
						</span>
					)}
					{(isModuleGroupingV2 && question.question_type !== SurveyQuestionType.text) && (
						<span className="invalid-multiple">
							&bull;&nbsp;
							{question.options.length} options
						</span>
					)}
				</span>
			</div>
			<div className="survey-question-actions">
				<button className="no-style" onClick={() => editQuestion(question)}>
					<Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} />
				</button>
				<button onClick={handleDeleteQuestion(question)} className="no-style">
					<Icon name={ICONS.TRASH} size={16} color={COLORS.WHITE} />
				</button>
			</div>
		</div>
	);
}
