import chroma from "chroma-js";
import { ColorResult } from "react-color";

const hexToReactColorResult = (hex: string, opacity = 1): ColorResult => {
	// make sure chroma doesn't crash the app with invalid hex
	if (!chroma.valid(hex)) {
		return {
			hex: "#000000",
			rgb: { r: 0, g: 0, b: 0, a: 1 },
			hsl: { h: 0, s: 0, l: 0, a: 1 }
		};
	}
	const rgb = chroma(hex).rgba();
	const hsl = chroma(hex).hsl();

	return {
		hex: hex,
		rgb: {
			r: rgb[0],
			g: rgb[1],
			b: rgb[2],
			a: opacity,
		},
		hsl: {
			h: hsl[0],
			s: hsl[1],
			l: hsl[2],
			a: opacity,
		},
	};
};

export default hexToReactColorResult;
