import { useCallback, useState } from 'react';

import Textarea from '../../../../../general-ui/textarea/textarea';
import ModalComponent from '../../../../../general-ui/modal/modal';
import { updateSessionCalendarSettings } from '../../../../../../store/actions/admin/create-event/session';
import { useAppDispatch, useTypedSelector } from '../../../../../../store/reducers/use-typed-selector';
import languageMap from '../../../../../../utils/translate-langs-map.json';
import TextInput from '../../../../../general-ui/text-input/text';
import { PageModuleType, PageModule } from '../../../../../../types/working-model';

const langMap = languageMap as Record<string, string>;

interface CustomCssProps {
	open: boolean;
	onClose: () => void;
}

export default function CalendarTextModal({
	open,
	onClose
}: CustomCssProps): JSX.Element {

	const dispatch = useAppDispatch();
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);

	const [calendarTextContentSettings, setCalendarTextSettings] = useState(workingSession?.calendar_settings?.content || {});

	const getDefaultTitle = useCallback((language: string) => {
		return workingEvent?.name + ': ' + (workingSession?.title?.[language] ?? workingSession?.title?.base);
	}, [workingEvent, workingSession]);

	const getDefaultDescription = useCallback((language: string) => {
		const descriptionModule = workingSession?.modules?.find((module: PageModule) => module.type === PageModuleType.description && module.is_on);
		const sessionDescription = descriptionModule && ('changed' in (descriptionModule?.content?.description ?? {})) ?
			(descriptionModule?.content?.description?.[language] ?? descriptionModule?.content?.description?.base) : '';

		return sessionDescription || workingSession?.description?.[language] as string || (workingSession?.description?.base || '');
	}, [workingSession]);

	const updateCalendarText = (language: string, title?: string, description?: string) => {
		setCalendarTextSettings({
			...calendarTextContentSettings,
			[language]: {
				title: title ?? (calendarTextContentSettings[language]?.title || undefined),
				description: description ?? (calendarTextContentSettings[language]?.description || undefined)
			}
		});
	};

	const save = () => {
		dispatch(updateSessionCalendarSettings({
			...(workingSession?.calendar_settings || {}),
			content: calendarTextContentSettings
		}));
		onClose();
	};

	return (
		<ModalComponent
			title="Update Calendar Text"
			className="calendar-text-modal"
			footer={(
				<>
					<div>
						<button onClick={onClose}>Cancel</button>
						<button onClick={save} className="lemonade">
							Save
						</button>
					</div>
				</>
			)}
			cancellable
			closeable={false}
			open={open}
			onRequestClose={close}
		>
			<div className="calendar-text-modal-body">
				{workingSession?.languages.map(language => (
					<div className="language-container" key={language}>
						<div className="language-label">{langMap[language]}</div>
						<TextInput
							label="Calendar title"
							onChange={event => updateCalendarText(language, event.target.value)}
							defaultValue={calendarTextContentSettings[language]?.title ?? getDefaultTitle(language)}
							value={calendarTextContentSettings[language]?.title ?? getDefaultTitle(language)}
						/>
						<Textarea
							defaultValue={calendarTextContentSettings[language]?.description || getDefaultDescription(language)}
							placeholder="Enter a description for your calendar invite"
							label="Calendar description"
							value={calendarTextContentSettings[language]?.description || undefined}
							onChange={event => updateCalendarText(language, undefined, event.target.value)}
						/>
					</div>
				))}
			</div>
		</ModalComponent>
	);
}