import { useContext, useEffect } from "react";

import useDetermineWhichColorThemeToUse from "components/live-event/hooks/use-determine-which-color-theme-to-use";
import { ThemeContext } from "components/live-event/theme-context";
import { useTypedSelector } from "store/reducers/use-typed-selector";

interface IThemeUpdateProps {
	isAdmin?: boolean;
}

// detect theme change
export const useThemeUpdate = (props?: IThemeUpdateProps): void => {
	const { isAdmin = false } = props || {};

	const [_, setTheme] = useContext(ThemeContext);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle || workingEvent;

	const { theme: themeMode } = useDetermineWhichColorThemeToUse(event, { isAdmin });

	useEffect(() => {
		setTheme(themeMode);
	}, [themeMode, setTheme]);
};
