import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { DragEndEvent } from "@dnd-kit/core/dist/types";

import {
	CreateSurvey,
	EngageModule,
	QuestionPrompt,
	Survey,
	SurveyQuestion,
	SurveyQuestionOption,
	SurveyQuestionType, SurveyType,
} from "../../../../../../../types/working-model";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";
import { TTranslateKey } from "../hooks/engage.hooks";
import { ErrorsTypes } from "../types/engage";
import { toMap } from "../../../../../../../utils/utils";
import { SearchTypes } from "../../../../../../../connection/sessions-panel/types";

interface IOnCheckCorrectHandler {
	workingQuestion: SurveyQuestion | null;
	setWorkingQuestion: (question: SurveyQuestion | null) => void;
	clearErrors: (key: ErrorsTypes | null) => void;
}

export const onCheckCorrectHandler = ({
	workingQuestion,
	setWorkingQuestion,
	clearErrors,
}: IOnCheckCorrectHandler) => {
	return (_option: SurveyQuestionOption) => {
		if (!workingQuestion) return;
		clearErrors(ErrorsTypes.QuizCorrectOption);

		const isMultiple = workingQuestion.question_type === SurveyQuestionType.checkbox;

		const updatedOptions = workingQuestion.options.map((option) => {
			if (isMultiple) {
				if (option.survey_question_option === _option.survey_question_option) {
					return { ...option, is_correct: !option.is_correct };
				}

				return option;
			} else {
				return option.survey_question_option === _option.survey_question_option
					? { ...option, is_correct: true }
					: { ...option, is_correct: false };
			}
		});

		const updatedQuestion = {
			...workingQuestion,
			options: updatedOptions,
		};

		setWorkingQuestion(updatedQuestion);
	};
};

interface ISaveQuestionHandler {
	workingQuestion: SurveyQuestion | null;
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
	validateQuestionHandler: () => { [key: string]: string };
	clearErrors: (key: ErrorsTypes | null) => void;
}

export const saveQuestionHandler = ({
	workingQuestion,
	setWorkingSurvey,
	setWorkingQuestion,
	validateQuestionHandler,
	clearErrors,
}: ISaveQuestionHandler): VoidFunction => {
	return () => {
		const questionErrors = validateQuestionHandler();

		if (questionErrors[ErrorsTypes.QuestionOptions]
			|| questionErrors[ErrorsTypes.QuestionTitle]
			|| questionErrors[ErrorsTypes.QuestionOptionText]
			|| questionErrors[ErrorsTypes.QuizCorrectOption]
		) {
			if (questionErrors[ErrorsTypes.QuestionOptions]) {
				showAlert({
					message: questionErrors[ErrorsTypes.QuestionOptions],
					type: "error",
				});
			}

			return;
		}

		setWorkingSurvey((workingSurvey) => {
			if (!workingSurvey) return null;

			return {
				...workingSurvey,
				questions: workingSurvey.questions.map((question) =>
					question.survey_question === workingQuestion?.survey_question ? workingQuestion : question
				),
			};
		});

		setWorkingQuestion(null);
		clearErrors(null);
	};
};

interface ISetQuestionTypeHandler {
	survey_type: SurveyType;
	workingQuestion: SurveyQuestion | null;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
	addOption: () => Promise<void>;
}

export const setQuestionTypeHandler = ({
	survey_type,
	workingQuestion,
	setWorkingQuestion,
	addOption,
}: ISetQuestionTypeHandler): ((value: SurveyQuestionType) => Promise<void>) => {
	return async (value: SurveyQuestionType) => {
		if (value !== SurveyQuestionType.text && !workingQuestion?.options.length) {
			await addOption();
		}

		const isQuiz = survey_type === SurveyType.quiz;

		setWorkingQuestion((question) => {
			if (!question) return null;

			const options = isQuiz
				? question.options.map((option) => ({ ...option, is_correct: false }))
				: question.options;

			return { ...question, question_type: value, options };
		});
	};
};

export const removeOptionHandler = (setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>): ((optionId: number) => void) => {
	return (optionId: number) => {
		setWorkingQuestion((question) => {
			if (!question) return null;
			const updatedOptions = question.options.filter(option => option.survey_question_option !== optionId);

			return {
				...question,
				options: updatedOptions,
			};
		});
	};
};

export const toggleAllowRetakeHandler = (setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>) => {
	return (_: string, isOn: boolean) => {
		setWorkingSurvey((curWorkingSurvey) => {
			if (!curWorkingSurvey) return null;

			return {
				...curWorkingSurvey,
				allow_retake: isOn,
			};
		});
	};
};

interface IHandleDragEndHandler {
	setIsGrabbing: (isGrabbing: boolean) => void;
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>;
}

export const handleDragEndHandler = ({
	setIsGrabbing,
	setWorkingSurvey,
}: IHandleDragEndHandler): ((event: DragEndEvent) => void) => {
	return (event: DragEndEvent) => {
		setIsGrabbing(false);
		setWorkingSurvey((curSurvey) => {
			if (!curSurvey) return null;

			const questions = [...curSurvey.questions];
			const startIndex = questions.findIndex(
				(question: SurveyQuestion) =>
					Number(question.survey_question) === Number(event.active.id)
			);
			const newIndex = questions.findIndex(
				(question: SurveyQuestion) =>
					Number(question.survey_question) === Number(event.over?.id)
			);

			const [question] = questions.splice(startIndex, 1);
			questions.splice(newIndex, 0, question);

			return { ...curSurvey, questions };
		});
	};
};

interface ISetSurveyTitleHandler {
	clearErrors: (key: ErrorsTypes | null) => void;
	setWorkingSurveyTransKey: (key: TTranslateKey, value: string) => void;
}

export const setSurveyTitleHandler = ({
	clearErrors,
	setWorkingSurveyTransKey,
}: ISetSurveyTitleHandler): (({ target: { value } }: ChangeEvent<HTMLInputElement>) => void) => {
	return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		clearErrors(ErrorsTypes.SurveyTitle);
		setWorkingSurveyTransKey('name', value);
	};
};

export const loadFullEngageItems = async (
	data: EngageModule[],
	selectedItems: number[],
	fullItems: <T>(type: SearchTypes | "surveys-polls-quizzes", ids: number[]) => Promise<T[]>
): Promise<[
	surveys: Survey[],
	questions: QuestionPrompt[],
	itemMap: Map<number, EngageModule>
]> => {
	const itemMap = toMap<EngageModule>('module_id', data) as Map<number, EngageModule>;
	let surveys: Survey[] = [];
	let questions: QuestionPrompt[] = [];

	const fullModuleGroups = selectedItems.reduce<Record<string, number[]>>((acc, item) => {
		const eItem = itemMap.get(item);
		if (eItem) {
			switch (eItem.type) {
				case "question_prompt": {
					acc.question_prompts.push(eItem.id);
					break;
				}
				default: {
					acc.surveys.push(eItem.id);
				}
			}
		}
		return acc;
	}, { surveys: [], question_prompts: [], feedbacks: [] });

	if (fullModuleGroups.surveys.length) {
		surveys = await fullItems<Survey>('surveys-polls-quizzes', fullModuleGroups.surveys);
	}

	if (fullModuleGroups.question_prompts.length) {
		questions = await fullItems<QuestionPrompt>('question-prompts', fullModuleGroups.question_prompts);
	}

	return [surveys, questions, itemMap];
};