import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { generatePath, useHistory, useParams, useRouteMatch, matchPath } from "react-router";
import classNames from 'classnames';

import {
	BlProfile,
	BrandliveEvent,
	ENavbarTypes,
	LanguagesAbbr,
	RegFieldsEnum,
} from "../../../../types/working-model";
import { setStorageItem } from "../../../../utils/local-storage";
import { EIcon } from "../../../general-ui/icon/icon";
import { ALL_LANGS, ILang } from "../../../general-ui/language-dropdown/language-dropdown";
import { ParamsProps } from "../../live-event";
import useRouteMap from "../../hooks/use-route-map";
import { getEventNavbarStyling } from "../../utils";
import { deregister } from "../../../../utils/deregister";
import { useAppDispatch, useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { blurEditor, loadWorkingEvent } from "../../../../store/actions/admin/create-event";
import { ALERT_TYPES, showAlert } from "../../../general-ui/alert/alert-service";
import Switch from '../../../general-ui/switch/switch';
import Icon, { ICONS } from '../../../general-ui/icon';
import { editProfile, setEventNavOpen, toggleAttendeeInPersonMode } from '../../../../store/actions/event/event-actions';
import { useTranslate } from "../../../../i18n/useTranslationModules";
import '../../../../scss/live-event/base/marketing-page/profile-details-dropdown.scss';
import { capitalize } from '../../../../utils/utils';
import { OptionalComponent } from "../../../../utils/optional-component";
import { useAvatarIsEnabled } from "../../../../utils/avatar-is-enabled";
import { HvHostMap } from "../../../../connection/helpers";
import { useIsSingleSessionWithoutHome } from "../../../../hooks/session.hooks";
import useShowMyList from "hooks/show-my-list.hooks";
import { IProfileEditRequest } from "connection/registration";
import AppContext, { AppContexts } from "components/app-context";

interface IProfileDetailsProps {
	channel: number;
	profile: BlProfile | null;
	template: string;
	eventBundle: BrandliveEvent;
	isMobile?: boolean;
	isTablet?: boolean;
	isEditor?: boolean;
	cssPrefix?: string;
}

const ProfileDetailsDropdown: React.FC<IProfileDetailsProps> = ({
	profile,
	channel,
	eventBundle,
	isMobile = false,
	isTablet = false,
	cssPrefix = ''
}) => {
	const { language: pathLanguage, uuid, id, eventName, ...rest }: ParamsProps = useParams();
	const appContext = useContext(AppContext);

	const isEditor = appContext === AppContexts.admin;
	const routeMatch = useRouteMatch<any>();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const eventUuid = uuid || id;

	const [profileDetailsOpen, setProfileDetailsOpen] = useState(false);
	const [showLanguages, setShowLanguages] = useState(false);
	const [showSecondLevel, setShowSecondLevel] = useState(false);

	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const userToggledInPersonAttendeeOn = blProfileUser?.profile?.[channel]?.[RegFieldsEnum.attend_in_person] === 'Yes';
	const [attendeeInPersonToggleIsOn, setAttendeeInPersonToggleIsOn] = useState<boolean>(userToggledInPersonAttendeeOn);

	const displayAvatar = useAvatarIsEnabled(eventBundle);
	const pageSession = useTypedSelector(event => event.LiveEventReducer.pageSession);
	const adminInPersonAttendeeModeEnabled = useTypedSelector(state => state.LiveEventReducer.eventBundle?.registration_settings?.adminInPersonAttendeeModeEnabled);

	const token = useTypedSelector(event => event.AuthReducer.token);
	const blProfileToken = useTypedSelector(state => state?.LiveEventReducer.blProfileUserToken);
	const registrationId = useTypedSelector(state => state?.LiveEventReducer.registrationId);
	const debug = window.location.hostname === 'localhost';
	const showProfile = eventBundle.registration_on;
	const profileHasEmail = profile?.email;
	const RouteMap = useRouteMap({ debug });
	const { t } = useTranslate(['session', 'homepage', 'languages-list']);
	const isSingleSessionNoHomepage = useIsSingleSessionWithoutHome();

	// if admin has switched off in person attendance, then we should always set it to false so we don't end up out of sync
	useEffect(() => {
		if (!adminInPersonAttendeeModeEnabled) {
			setAttendeeInPersonToggleIsOn(false);
		} else {
			setAttendeeInPersonToggleIsOn(blProfileUser?.profile?.[channel]?.[RegFieldsEnum.attend_in_person] === "Yes");
		}
	}, [adminInPersonAttendeeModeEnabled, channel, blProfileUser]);

	const languages = useMemo(() => {
		const isSingleSession = eventBundle?.sessions?.length === 1;
		if (!rest?.sessionUuid && !isSingleSession) {
			return Array.from(new Set(eventBundle?.homepage?.languages as LanguagesAbbr[]));
		}
		// if single session, return all languages from that session
		if (isSingleSession) return eventBundle.sessions[0].languages;

		const session = eventBundle?.sessions?.find(session => session.uuid === rest.sessionUuid);

		if (!session) return [];
		return Array
			.from(new Set(eventBundle?.homepage?.languages as LanguagesAbbr[]))
			.filter(lang => session.languages.includes(lang));
	}, [eventBundle.sessions, eventBundle?.homepage?.languages, rest.sessionUuid]);

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.code === 'Escape' || event.key === 'Escape') {
				setProfileDetailsOpen(false);
			}
		};
		if (profileDetailsOpen) {
			window.addEventListener("keydown", handleKeyDown);
		}
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [profileDetailsOpen]);


	useEffect(() => {
		const handleClick = (e: any) => {
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (item.id === 'profile-details-dropdown-container') {
					return;
				}
			}

			setProfileDetailsOpen(false);
		};

		if (profileDetailsOpen) {
			window.addEventListener("click", handleClick);
		}

		return () => {
			window.removeEventListener("click", handleClick);
		};
	}, [profileDetailsOpen]);

	// close panel if window size changes
	useEffect(() => {
		setProfileDetailsOpen(false);
	}, [isMobile]);

	// Set redux state for attendee in person mode, all logic to conditionally render based on user setting will use this redux field
	useEffect(() => {
		dispatch(toggleAttendeeInPersonMode(attendeeInPersonToggleIsOn));
	}, [attendeeInPersonToggleIsOn, dispatch]);

	const handleLanguageChange = (langAbbr: LanguagesAbbr) => () => {
		if (!eventBundle?.event) return;
		if (!routeMatch?.params?.language) return;

		// language is set in storage so we can remember the users chosen language so if they enter
		// a session in another language and then go back to the home page, we can reset the language to their chosen language
		if (!isEditor) setStorageItem(`event.${eventBundle.event}.language`, langAbbr);

		if (langAbbr && langAbbr !== routeMatch?.params?.language) {
			// replace the language in the url with langAbbr
			const updatedParams = { ...routeMatch.params };
			updatedParams.language = langAbbr;
			let updatedUrl = routeMatch.path;
			for (const key in updatedParams) {
				updatedUrl = updatedUrl.replace(`:${key}`, updatedParams[key]);
			}
			if (isEditor && token) {
				showAlert({
					message: "Fetching translations",
					description: "Please wait a moment while we get new translations",
					duration: 4000,
					type: ALERT_TYPES.POSITIVE
				});
				dispatch(blurEditor(true));
				dispatch(loadWorkingEvent(id, token));
			}
			history.replace(updatedUrl);
		}

		handleToggleLanguage();
		setProfileDetailsOpen(false);
		dispatch(setEventNavOpen(false));
	};

	const allLanguages: ILang[] = languages
		? (languages
			.map((l) => ALL_LANGS.find((f) => f.code === l))
			.filter((f) => f) as any)
		: ALL_LANGS;


	const noMenuItems = !showProfile && !(allLanguages?.length > 1);

	const handleToggleLanguage = () => {
		setShowSecondLevel(prev => !prev);
		setShowLanguages(prev => !prev);
	};

	function handleOpenMenu() {
		if (noMenuItems) return;
		setProfileDetailsOpen((prev) => !prev);
	}

	const handleToggleAttendeeInPersonMode = async () => {
		setAttendeeInPersonToggleIsOn(!attendeeInPersonToggleIsOn);
		dispatch(toggleAttendeeInPersonMode(!attendeeInPersonToggleIsOn));

		// update registration and profile values with attendee in person mode
		if (
			!profile?.bl_profile
			|| !blProfileToken
			|| !blProfileUser?.profile?.[channel]
			|| !channel
			|| !registrationId
		) return;

		const profileChannelFields = blProfileUser.profile[channel];
		const profileRequest: IProfileEditRequest = {
			fields: {
				...profileChannelFields,
				[RegFieldsEnum.attend_in_person]: attendeeInPersonToggleIsOn ? 'No' : 'Yes',
			},
			avatar: profileChannelFields?.avatar,
			channel,
			registrationId
		};

		dispatch(editProfile(profileRequest, eventBundle.uuid, blProfileToken));
	};

	const firstName: string = profile?.profile?.[channel]?.[RegFieldsEnum.first_name] || '';
	const lastName: string = profile?.profile?.[channel]?.[RegFieldsEnum.last_name] || '';
	const toUseAvatarIcon = (firstName === '' && lastName === '');

	const { navItemColor } = getEventNavbarStyling(eventBundle?.settings.event_navbar?.styling_overrides);
	const pathToProfile = isEditor ? '#' : generatePath(RouteMap.Profile, { uuid: eventUuid, eventName, language: pathLanguage });
	const pathToWatchlist = isEditor ? '#' : generatePath(RouteMap.Watchlist, { uuid: eventUuid, eventName, language: pathLanguage });

	const isTopNavbar = eventBundle?.settings?.event_navbar?.navbarType === ENavbarTypes.Horizontal || isSingleSessionNoHomepage;

	const showMyList = useShowMyList();

	const registrationOn = eventBundle.registration_on;
	const profileAvatar = profile?.profile?.[channel]?.avatar;

	const isSessionPage = matchPath(location.pathname, {
		path: RouteMap.Session,
	});

	// Sometimes the avatar image gets saved as undefined
	// @TODO investigate this further
	const validAvatarImage = !!profileAvatar && profileAvatar !== 'undefined';

	return (
		<div
			className={classNames(`${cssPrefix}profile-details-dropdown-container`, { isMobile, isTablet })}
			id="profile-details-dropdown-container"
		>
			<button
				className={classNames(
					`${cssPrefix}profile-details-dropdown-trigger no-style`,
					navItemColor,
					{
						inactive: noMenuItems,
						'is-editor': isEditor,
						'top-nav': isTopNavbar
					}
				)}
				aria-label={
					profileDetailsOpen
						? 'Close profile pop-up'
						: 'Open profile pop-up'
				}
				onClick={handleOpenMenu}
			>
				{displayAvatar && validAvatarImage ? (
					<img
						src={profileAvatar}
						alt="Profile avatar"
						width="48"
						height="48"
						className={`${cssPrefix}profile-details-dropdown-avatar`}
					/>
				) : (
					<div className={classNames(`${cssPrefix}profile-details-dropdown-placeholder`)} >
						<OptionalComponent display={toUseAvatarIcon}>
							<Icon name={ICONS.PERSON} size={20} color={'var(--containerColor)'} />
						</OptionalComponent>
						<OptionalComponent display={!toUseAvatarIcon}>
							<span>{firstName.charAt(0)}{lastName.charAt(0)}</span>
						</OptionalComponent>
					</div>
				)}
				<OptionalComponent display={!isMobile && !noMenuItems}>
					<span className={classNames('profile-dropdown-arrow', { 'top-nav': isTopNavbar })}>
						<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={16} color={''} />
					</span>
				</OptionalComponent>
			</button>

			{profileDetailsOpen ? (
				<div className={classNames(`${cssPrefix}profile-details-dropdown-menu`, { 'is-session-page': !!isSessionPage })}>

					{/* place all first level nav items in this ternary: */}
					{(showSecondLevel) ? null : (
						<div className="profile-first-level-actions">
							{(showProfile && profileHasEmail) &&
								<Link to={pathToProfile} onClick={() => dispatch(setEventNavOpen(false))}>
									<button className="no-style nowrap space-between">
										<div className="icon-action-wrapper">
											<div className="left-action">
												{/* <Icon name={ICONS.PERSON} size={20} color={COLORS.BLACK} /> */}
												<div className="see-profile-wrapper">
													<p>{firstName + " " + lastName}</p>
													<p className="see-profile-label">{t("session:See your profile")}</p>
												</div>
											</div>
											<span ><EIcon name="keyboard-arrow-right" /></span>
										</div>
									</button>
								</Link>
							}

							{adminInPersonAttendeeModeEnabled &&
								<button
									className="no-style nowrap space-between"
									onClick={handleToggleAttendeeInPersonMode}
								>
									<p>{t("homepage:Attending in person")}</p>
									<div className="attendee-in-person-toggle-switch">
										<Switch
											value={"In person attendee mode"}
											on={attendeeInPersonToggleIsOn}
											onClick={() => null}
										/>
									</div>
								</button>
							}
							{(allLanguages.length > 1) ? (
								<button
									className={classNames('no-style nowrap space-between', { 'mobile-hidden': eventBundle.settings.display_session_language_dropdown && isSessionPage })}
									onClick={handleToggleLanguage}
								>
									<div className="icon-action-wrapper">
										<div className="left-action">
											{capitalize(t(`languages-list:Languages.${pathLanguage}`, allLanguages.find(lang => lang.code === pathLanguage)?.label))}
										</div>
										<span style={{ textAlign: 'right' }}><EIcon name="keyboard-arrow-right" /></span>
									</div>
								</button>
							) : null
							}

							<OptionalComponent display={showMyList}>
								<Link to={pathToWatchlist}>
									<button className="no-style nowrap space-between">
										<div className="icon-action-wrapper">
											<div className="left-action">
												{/* <Icon name={ICONS.CHECK} size={20} color={COLORS.BLACK} /> */}
												<p className="zero-margin">{t("homepage:Watchlist", "Watchlist")}</p>
											</div>
										</div>
									</button>
								</Link>
							</OptionalComponent>

							{registrationOn && <button className="no-style nowrap space-between" onClick={() => {
								if (isEditor) return;

								navigator.sendBeacon(`${HvHostMap.sessionTracking}/update-page-session-end-time`, JSON.stringify({ uuid: pageSession }));
								deregister(eventBundle);
							}}>
								<div className="icon-action-wrapper">
									<div className="left-action">
										{/* <Icon name={ICONS.LOG_OUT} size={20} color={COLORS.BLACK} /> */}
										<p>{t("homepage:Logout")}</p>
									</div>
								</div>
							</button>}
						</div>
					)}

					{(showLanguages) ? (
						<div className={`${cssPrefix}profile-nav-language-container`}>
							<button className="no-style btn-left-align profile-second-level-action" onClick={handleToggleLanguage}>
								<span><EIcon name="keyboard-arrow-left" />
									{capitalize(t('session:Back'))}
								</span>
							</button>
							{allLanguages.map((lang) => (
								<button
									className={classNames('no-style sharp profile-nav-language-item btn-left-align', {
										activeLanguage: pathLanguage === lang.code,
									})}
									onClick={handleLanguageChange(lang.code)}
									key={lang.code}
								>
									{t(`languages-list:Languages.${lang.code}`, lang.label)}
								</button>
							))}
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default ProfileDetailsDropdown;
