import { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { EPermissions } from "../../../types/working-model";
import Icon, { COLORS, ICONS } from '../../general-ui/icon';
import { AuthWrapper } from "../authentication/AuthWrapper";
import { PATHNAMES } from "../../../utils/admin-routing-utils";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { ProfileDetails } from '../../general-ui//profile-details/profile-details';
import images from '../../../images';
import { OptionalComponent } from '../../../utils/optional-component';
import { getStorageObject, setStorageObject } from '../../../utils/local-storage';

import './admin-top-nav.scss';
import { MarkdownItem } from '@general-ui/typography-item/typography-item';

const GradientNavItem = ({ width }: { width: number }) => <div className="gradient-nav-item" style={{ width: `${width}px` }} />;

function AdminTopNav(props: { noOptions?: boolean }): JSX.Element {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const superAdminNotifications = useTypedSelector(state => state.ChannelAdminReducer.superAdminNotifications);

	const location = useLocation();
	const { pathname } = location;

	const [profileDetailsOpen, setProfileDetailsOpen] = useState(false);
	const [brandliveNotificationsOpen, setBrandliveNotificationsOpen] = useState(false);

	const brandliveNotificationsRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		function checkClick(e: any) {
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (brandliveNotificationsRef.current === item) return;
			}
			setBrandliveNotificationsOpen(!brandliveNotificationsOpen);
		}

		if (brandliveNotificationsOpen) {
			window.addEventListener('click', checkClick);
		}

		return () => window.removeEventListener('click', checkClick);
	}, [brandliveNotificationsOpen]);

	const getInitials = useCallback(() => {
		if (user && (user.profile.first_name || user.profile.last_name)) {
			return `${user.profile.first_name?.[0] ?? ''}${user.profile.last_name?.[0] ?? ''}`;
		} else {
			return user?.email?.[0];
		}
	}, [user?.profile]);

	const checkNotificationUnseenCount = () => {
		if (superAdminNotifications.length) {
			// Find a storage object that doesn't exist
			return superAdminNotifications.filter(notif => !getStorageObject(`brandlive_super_admin_notification_seen.${notif.uuid}`)).length || 0;
		}

		return 0;
	};

	const toggleNotificationsDropdown = () => {
		setBrandliveNotificationsOpen(!brandliveNotificationsOpen);
		if (!brandliveNotificationsOpen && superAdminNotifications.length) {
			superAdminNotifications.forEach(notif => setStorageObject(`brandlive_super_admin_notification_seen.${notif.uuid}`, true));
		}
	};

	return user ? (
		<div className="admin-top-nav">
			<div className="left">
				<OptionalComponent display={!props.noOptions}>
					<AuthWrapper allowedRoles={[EPermissions.Moderation, EPermissions.Producer, EPermissions.Editor, EPermissions.GroupAdmin, EPermissions.Viewer, EPermissions.Presenter]}>
						<Link to={PATHNAMES.Events}>
							<div className={classNames('admin-top-nav-item', { active: pathname === '/admin/events' })}>
								Projects
								<GradientNavItem width={68} />
							</div>
						</Link>
					</AuthWrapper>
					<AuthWrapper allowedRoles={[EPermissions.PasscodeManager, EPermissions.GroupAdmin]}>
						<Link to={PATHNAMES.People}>
							<div className={classNames('admin-top-nav-item', { active: pathname.includes('people') })}>
								Audiences
								<GradientNavItem width={85} />
							</div>
						</Link>
					</AuthWrapper>
					<AuthWrapper allowedRoles={[EPermissions.Editor, EPermissions.GroupAdmin]}>
						<Link to={PATHNAMES.ContentSubLevels.Documents.All}>
							<div className={classNames('admin-top-nav-item', { active: pathname.includes('content') || pathname.includes('products') })}>
								Content
								<GradientNavItem width={65} />
							</div>
						</Link>
					</AuthWrapper>
					<AuthWrapper allowedRoles={[EPermissions.Analytics, EPermissions.GroupAdmin, EPermissions.Builder]}>
						<Link to={PATHNAMES.AnalyticsLink}>
							<div className={classNames('admin-top-nav-item', { active: pathname.includes('analytics') })}>
								Analytics
								<GradientNavItem width={78} />
							</div>
						</Link>
					</AuthWrapper>
					<AuthWrapper allowedRoles={[EPermissions.Owner, EPermissions.Admin]}>
						<Link to={PATHNAMES.SettingsLink}>
							<div className={classNames('admin-top-nav-item', { active: pathname.includes('settings') || pathname.includes('integrations') })}>
								Settings
								<GradientNavItem width={68} />
							</div>
						</Link>
					</AuthWrapper>
				</OptionalComponent>
			</div>
			<div className="right">
				<OptionalComponent display={!!superAdminNotifications.length}>
					<div className="brandlive-notification-button" ref={brandliveNotificationsRef} onClick={toggleNotificationsDropdown} data-testid="brandlive-notification-button">
						<Icon
							name={ICONS.BELL_EMPTY}
							size={20}
							color={COLORS.WHITE}
						/>
						<OptionalComponent display={checkNotificationUnseenCount() > 0}>
							<div className="notification-count">
								{checkNotificationUnseenCount()}
							</div>
						</OptionalComponent>
						<div className={classNames('notification-dropdown', { show: brandliveNotificationsOpen })} ref={brandliveNotificationsRef}>
							<div className="header">Recent</div>
							{superAdminNotifications.map((notification, idx) => (
								<div className="notification-item" key={idx}>
									<img src={images.TellyPresenting} />
									<div className="notification-item-content">
										<MarkdownItem>{notification?.config?.content}</MarkdownItem>
									</div>
								</div>
							))}
						</div>
					</div>
				</OptionalComponent>
				<button className="no-style profile-button" onClick={() => setProfileDetailsOpen(!profileDetailsOpen)}>
					<div className="profile-pic">
						{user?.profile?.picture ? (
							<img
								src={user.profile.picture}
								alt={user.profile.first_name + ' ' + user.profile.last_name}
							/>
						) : (
							<div className="user-initials">
								{getInitials()}
							</div>
						)}
					</div>
					<div className="profile-pic-arrow">
						<Icon
							name={profileDetailsOpen ? ICONS.KEYBOARD_ARROW_UP : ICONS.KEYBOARD_ARROW_DOWN}
							size={12}
							color={COLORS.WHITE}
						/>
					</div>
				</button>
				{user ? (
					<ProfileDetails
						open={profileDetailsOpen}
						account={user}
						onClose={() => setProfileDetailsOpen(false)}
					/>
				) : null}
			</div>
		</div>
	) : (
		<WaitingIndicator minHeight={'90vh'} fillSpace={true} />
	);
}

export default AdminTopNav;