
import { BreakoutRoom } from "../../../../../../types/working-model";


export const attendees = Array.from({ length: 14 }).map((_v, idx) => ({
	label: (idx + 2).toString(),
	value: (idx + 2).toString(),
}));

export const attendeesGoogleMeet = Array.from([2, 3, 4, 5, 10, 15, 20, 30, 50, 100, 150, 200]).map((value) => ({
	label: value.toString(),
	value: value.toString(),
}));

export const defaultRooms = async (uuid: string, type?: 'interest' | 'random' | 'assigned'): Promise<BreakoutRoom[]> => ([
	{
		session: 0,
		uuid: uuid,
		name: "",
		max_users: 15,
		type: type || 'interest',
		image: '',
		imageUploading: false,
		nameFocused: false,
		video_settings: {},
		closed: false
	}
]);
