import { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PageModuleType, SessionPanelLayoutsTypes } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS } from "../../../../../../general-ui/icon";
import { TItemsType, EExtrasGroups, IEmptyPanelItem } from "../empty-state-panel/constants/empty-panel";
import { useCreateModuleAndGo } from "../empty-state-panel/empty-state-panel";
import { usePageModule } from "../hooks/panel.hooks";
import { SessionPanelMap } from "../session-panel-route-map";

type Props = {
	scrollRef: React.RefObject<HTMLDivElement>;
	items: IEmptyPanelItem;
	small?: boolean;
	onMenuToggle?: (open: boolean) => void;
	dropdownPosition?: "top" | "bottom";
}
const SessionPanelAddFooter: React.FC<Props> = ({
	scrollRef,
	items,
	small,
	onMenuToggle = () => null,
	dropdownPosition = "bottom",
}) => {
	const [creatingModule, setCreatingModule] = useState(false);
	const createModuleAndGo = useCreateModuleAndGo(setCreatingModule);
	const getAdmin = useGetAdminUrl();
	const { customPath } = useParams<{ customPath?: string }>();
	const pageModule = usePageModule();

	if (!pageModule) return null;
	return (
		<NavigationDropdown
			title="+ Add"
			className="session-panel-dropdown flex-center"
			buttonClassName={small ? "small lemonade" : "large lemonade"}
			yPosition={dropdownPosition}
			isAsync={true}
			renderCustomIcon={() => <></>}
			root={scrollRef}
			onMenuToggle={open => {
				onMenuToggle?.(open);
			}}
		>
			{Object.entries(items).map(([group, groupItems]) => (
				<Fragment key={group}>
					{groupItems.map((item: {
						icon: string;
						name: string;
						pageModuleType: PageModuleType;
						type: TItemsType;
						mustCreateModule?: boolean | undefined;
						navTo?: SessionPanelLayoutsTypes | undefined;
					}, index) => {
						const { icon, name, navTo, mustCreateModule } = item;

						return (

							<Link
								key={`${index}.${name}`}
								className={creatingModule ? "disabled" : ""}
								onClick={(e) => {
									// this is a new custom tab with no content, create a new page module and append it to the group
									// before navigating there
									if (mustCreateModule && item.navTo) {
										e.preventDefault();
										createModuleAndGo(item.pageModuleType, item.navTo);
									}
								}}
								to={(navTo && !mustCreateModule) ? {
									pathname: getAdmin({ path: SessionPanelMap[navTo as SessionPanelLayoutsTypes], page_module: pageModule?.id, customPath })
								} : '#'}>
								<Icon
									name={icon}
									color={group === EExtrasGroups.UploadFromCloud ? "transparent" : COLORS.WHITE}
									size={12}
								/>
								{name}
							</Link>
						);
					})}
				</Fragment>
			))}
		</NavigationDropdown>
	);
};

export default SessionPanelAddFooter;