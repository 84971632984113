import { useEffect, useRef } from "react";

import db from '../../../../utils/datastore/indexed-db';

const INTERVAL_IN_SECONDS = 5;

interface IProps {
	isPlaying: boolean;
	sessionUuid: string | undefined;
}

const useTrackPlaying = ({ isPlaying, sessionUuid }: IProps) => {

	const lastTime = useRef((new Date()).getTime());
	const secondsWatched = useRef(0);

	const interval = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (!sessionUuid) {
			if (interval.current) {
				clearInterval(interval.current);
			}
			return;
		}

		// intervals stop running when computers sleep, so this will stop triggering when a user's computer sleeps
		// then in video.tsx after their computer opens again, if their video auto plays and they press pause it will use this
		// stored number instead of Date.now() in video.ts in order to ignore all the asleep time
		interval.current = setInterval(async () => {
			const currentTime = Date.now();
			if (isPlaying) {
				secondsWatched.current = secondsWatched.current + INTERVAL_IN_SECONDS;
				lastTime.current = currentTime;
				db.putItem({
					uuid: `${sessionUuid}-TIME_OF_LAST_STREAM`,
					type: 'TIME_OF_LAST_STREAM',
					data: Date.now(),
				});
			}
		}, INTERVAL_IN_SECONDS * 1000);

		return () => {
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, [isPlaying, sessionUuid]);

	return {
		lastTime: lastTime.current,
		secondsWatched: secondsWatched.current,
	};
};

export default useTrackPlaying;
