import classNames from 'classnames';
import React, { useRef, useState, useEffect } from 'react';

import Icon, { COLORS, ICONS } from '../icon';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import FileInput from '../../../utils/file-input';
import { fileType } from '../../../types/working-model';
import { showAlert } from '../alert/alert-service';
import { OptionalComponent } from '../../../utils/optional-component';

interface ILargeButtonProps {
	title: string;
	subtitle?: string;
	onClick?: () => void;
	onFile?: (e: File | FileList) => void;
	multiple?: boolean;
	allowedFileTypes: fileType[];
	style?: React.CSSProperties;
	uploading?: boolean;
	errorMessage?: string;
	errorOutline?: boolean;
	customTitle?: JSX.Element | string;
	/** validate the file extension instead of the file content. This can be used when you need to upload a certain file extention but not all files with that extensions type. For example: scss files have type application/octet-stream, but we dont want to allow all application/octet-stream files, so we just allow .scss extensions. */
	allowedFileExtensions?: string[];
	id?: string;
	fileMetadataCallback?: (fileName: string, fileSize: string) => void;
	label?: string;
	maxSizeInBytes?: number;
	description?: string;
	hideText?: boolean;
}
interface INonUploadLargeButtonProps extends Omit<ILargeButtonProps, 'allowedFileTypes' | 'onFile'> {
	/** if LargeButton is not used to upload files, you must include this prop. Otherwise you are forced to include allowedFileTypes */
	nonUpload: boolean;
}

function bytesToString(bytes: number): string {
	if (bytes > 1024 * 1024 * 1024) {
		return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`;
	}

	if (bytes > 1024 * 1024) {
		return `${(bytes / 1024 / 1024).toFixed(2)} MB`;
	}

	if (bytes > 1024) {
		return `${(bytes / 1024).toFixed(2)} KB`;
	}

	return `${bytes} B`;
}

export default function LargeButton(props: ILargeButtonProps | INonUploadLargeButtonProps): JSX.Element {
	const {
		title,
		subtitle,
		onClick,
		multiple,
		style = {},
		uploading,
		errorMessage,
		errorOutline,
		customTitle,
		allowedFileExtensions = [],
		id,
		fileMetadataCallback,
		label,
		maxSizeInBytes: maxSize,
		description,
		hideText = false,
	} = props;

	let allowedFileTypes: fileType[] | undefined = [];
	if ((props as ILargeButtonProps)?.allowedFileTypes) {
		allowedFileTypes = (props as ILargeButtonProps).allowedFileTypes;
	}

	let onFile: ILargeButtonProps['onFile'] | undefined;
	if ((props as ILargeButtonProps)?.onFile) {
		onFile = (props as ILargeButtonProps).onFile;
	}

	const [draggingOver, setDraggingOver] = useState(false);
	const [showTitle, setShowTitle] = useState(title);
	const [showDescription, setShowDescription] = useState(subtitle);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (fileMetadataCallback && showTitle && showDescription) {
			fileMetadataCallback(showTitle, showDescription);
		}
	}, [showTitle, showDescription, fileMetadataCallback]);

	function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
	}

	function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		if (onFile) { setDraggingOver(true); }
	}

	function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		if (onFile) { setDraggingOver(false); }
	}

	function handleDragDrop(e: React.DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();

		if (onFile) {
			const files = e.dataTransfer?.files;

			if (inputRef.current) {
				// Instead of calling processFiles(files) directly, we want to trigger a change event on the file input so that we can run validation. 
				// after validation, processFiles(files) will be called.
				const ev = new Event('change', { bubbles: true });
				inputRef.current.files = files;
				inputRef.current.dispatchEvent(ev);
			}
		}
	}

	function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files) {
			const fileCount = e.target.files.length;
			const maxFilesAllowed = 50;
			if (fileCount > maxFilesAllowed) {
				return showAlert({
					type: 'warning',
					description: `You have selected ${fileCount} files. You may upload a max of ${maxFilesAllowed} files at a time.`,
				});
			}

			processFiles(e.target.files);
		}
	}

	function processFiles(files: FileList) {
		const file = files?.[0];

		//if they didn't drop anything, leave it
		if (!files || files.length < 1) { return; }

		//if we allow multiple files and they've dropped more than one
		if (multiple && files.length > 1) {
			let totalBytes = 0;

			//there are multiple, get the size of all of them
			for (let i = 0; i < files.length; ++i) {
				totalBytes += files[i].size;
			}

			if (maxSize && totalBytes > maxSize) {
				showAlert({
					message: 'File size too large',
					description: `The total size of all files must be less than ${bytesToString(maxSize)}.`,
					type: 'error'
				});

				return;
			}

			//show feedback
			setShowTitle(`${files.length} files selected.`);
			setShowDescription(`Total size: ${bytesToString(totalBytes)}`);
			setDraggingOver(false);
			onFile?.(files);

			//return early, skip everything below
			return;
		}

		//if we have just one file
		if (file) {
			if (maxSize && file.size > maxSize) {
				showAlert({
					message: 'File size too large',
					description: `The file size must be less than ${bytesToString(maxSize)}.`,
					type: 'error'
				});

				return;
			}

			setShowTitle(file.name);
			setShowDescription(`Size: ${bytesToString(file.size)}`);
			onFile?.(file);
		}

		setDraggingOver(false);
	}

	function handleClick() {
		inputRef.current?.click();
	}

	function handleFileError(error: string, file: File) {
		console.error(error);
		setShowTitle(`File ${file.name} is not a valid type.`);
		setShowDescription('Please select a different file.');
	}

	return (
		<div
			className={classNames('field-group large-button', {
				'has-subtitle': !!subtitle,
				dragging: draggingOver,
				error: errorOutline,
				'has-label': !!label
			})}
			onClick={onClick ? onClick : handleClick}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDrop={handleDragDrop}
			onDragEnd={handleDragDrop}
			onDragOver={handleDragOver}
			style={style}
			id={id}
		>
			{label && (
				<div className="field-label-container">
					<label className="field-label">{label}</label>
				</div>
			)}
			<OptionalComponent display={!uploading && !hideText}>
				<label className="inner">
					{customTitle ? <>{customTitle}</> : (
						<>
							{title === showTitle && (
								<Icon name={ICONS.ADD} size={14} color={COLORS.CYAN} />
							)}{' '}
							{showTitle}
						</>
					)}
				</label>
				<OptionalComponent display={!!description}>
					<span className='inner-description'>{description}</span>
				</OptionalComponent>
			</OptionalComponent>
			{subtitle && <span>{showDescription}</span>}
			<FileInput
				accept={allowedFileTypes}
				ref={inputRef}
				style={{ display: 'none' }}
				onChange={handleFileChange}
				onFileError={handleFileError}
				acceptFileExtensions={allowedFileExtensions}
			/>
			{uploading && (
				<div className="uploading">
					<WaitingIndicator />
				</div>
			)}
			{errorMessage && <label className="error-message">{errorMessage}</label>}
		</div>
	);
}
