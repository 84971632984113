import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { uniq } from 'underscore';

import { isValidEmail } from '../../../utils/utils';
import { showAlert } from '../alert/alert-service';
import Icon, { COLORS, ICONS } from '../icon';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import { OptionalComponent } from '../../../utils/optional-component';
import { setADropdownIsOpen } from '../../../store/actions/admin/create-event';
import { useAppDispatch } from '../../../store/reducers/use-typed-selector';

import './tag-select.scss';

interface Props {
	capSensitive?: boolean;
	defaultTags?: string[];
	jsxTags?: { value: string, label: JSX.Element }[];
	disableFocus?: boolean
	dropdownContainerStyles?: React.CSSProperties;
	errorMessage?: string;
	initializeScrolledToBottom?: boolean;
	label?: string;
	loading?: boolean;
	maxHeight?: number;
	maxTags?: number;
	noWhitespace?: boolean;
	onChange: (tags: string[]) => void;
	onBlur?: (tags: string[]) => void;
	onError?: (err: string) => void;
	padIn?: boolean;
	placeholder?: string;
	renderTooltip?: () => JSX.Element;
	restrictToList?: boolean;
	scrollbarOutsideContainer?: boolean;
	shouldSearch?: boolean;
	tagImage?: string;
	tags?: string[];
	tooltip?: [string | (() => JSX.Element), string];
	validateEmail?: boolean;
	className?: string;
	allowWithoutEnter?: boolean;
	allowClearAll?: boolean;
	showArrow?: boolean;
	hidePlaceholderOnContent?: boolean;
	closeOnSelect?: boolean; // When you select one option, it closes the dropdown
	lowercaseTags?: boolean;
}

interface TagProps {
	tag: string;
	remove: (tag: string) => void;
	tagImage?: string;
	capSensitive?: boolean;
}

function Tag(props: TagProps) {
	return useMemo(() => (
		<div className="tag" style={props?.capSensitive ? { textTransform: 'none' } : {}}>
			<span>{props.tag} <button className="no-style tag-select-remove-btn" onClick={() => props.remove(props.tag)}><Icon name={ICONS.CLOSE} size={10} color={COLORS.DEFAULT_GRAY} /></button></span>
		</div>
	), [props]);
}

//https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads/17/documents/e1s08b9f73/Screen_Shot_2020-11-02_at_9.04.59_AM.png

export default function TagSelectInput(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const {
		allowClearAll = false,
		allowWithoutEnter = false,
		capSensitive = false,
		className,
		closeOnSelect, // When you select one option, it closes the dropdown
		defaultTags = [],
		jsxTags,
		disableFocus = false,
		dropdownContainerStyles = {},
		errorMessage,
		hidePlaceholderOnContent = false,
		initializeScrolledToBottom, // Added for breakout rooms modal initial hosts emails
		label,
		loading = false,
		maxHeight,
		maxTags,
		noWhitespace = false,
		onBlur,
		onChange,
		onError,
		padIn = false,
		placeholder,
		restrictToList,
		scrollbarOutsideContainer,
		shouldSearch = false,
		showArrow = false,
		tagImage,
		tags = [],
		tooltip,
		validateEmail, // Added for breakout rooms when entering hosts emails
		lowercaseTags = false,
	} = props;
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const tagSelector = useRef<HTMLDivElement | null>(null);
	const tagsInput = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [image, text] = props.tooltip || [];
	const entered = tags?.length > 0;

	// The list of tags in which to search
	const unusedDefaultTags = jsxTags
		? jsxTags.filter(tag => !tags.includes(tag.value)).map(tag => tag.value)
		: defaultTags.filter((tag: string) => !tags.includes(tag));
	// The list of tags in the tag search dropdown
	const searchTerm = inputValue.toLowerCase();
	const visibleTags = shouldSearch && inputValue
		? unusedDefaultTags.filter((tag: string) => tag.toLowerCase().includes(searchTerm))
		: unusedDefaultTags;
	const additionalClasses = initializeScrolledToBottom ? 'scrollable-tag-select' : '';

	const scrollToBottom = () => {
		if (tagsInput.current) {
			tagsInput.current.scrollTop = tagsInput.current.scrollHeight;
		}
	};

	// Keeps scrollable tag lists scrolled to the bottom as tags are being added
	useEffect(() => {
		if (initializeScrolledToBottom && tags?.length) {
			scrollToBottom();
		}
	}, [initializeScrolledToBottom, tags]);

	const addTag = useCallback((tagToAdd: string) => {
		let trimmedTag = tagToAdd.trim();

		// Silently block add if tag appears empty
		if (!trimmedTag) {
			return setInputValue('');
		}

		// Block add if past maximum
		if (maxTags && tags?.length >= maxTags) {
			return showAlert({
				message: `You've reached the maximum of ${maxTags} items.`,
				duration: 5000,
				type: "error",
			});
		}

		// Block add if restricted to the dropdown list and there's no search results
		if (restrictToList && visibleTags?.length === 0) {
			return showAlert({
				message: `No search results for "${inputValue}"; please try a different search term.`,
				duration: 5000,
				type: "error",
			});
		}

		// Take the result if restricted to the dropdown list and it's the only one
		if (restrictToList && visibleTags?.length === 1) {
			trimmedTag = visibleTags[0];
		}

		// Additional cases when restricted to the dropdown list
		if (restrictToList && visibleTags?.length > 1) {
			const similarTags = visibleTags.filter((tag) => tagToAdd.toLowerCase() === tag.toLowerCase());

			// Block the add if search term doesn't completely match (case-insensitive) anything in the list
			if (!similarTags?.length) {
				const message = `Please ${inputValue ? '' : 'enter a search term or '}choose from the dropdown list.`;
				return showAlert({
					message,
					duration: 5000,
					type: "error",
				});
			}

			// Otherwise, allow add, accepting the first case-insensitive match if no exact matches exist...
			if (!similarTags.some((tag) => trimmedTag === tag)) {
				trimmedTag = similarTags[0];
			}

			// ...but warn of potential duplicates if there are any
			if (similarTags?.length > 1) {
				const hasExactDuplicates = uniq(similarTags)?.length < similarTags?.length;
				const message = `Heads up: The item you've chosen has a ${hasExactDuplicates ? 'duplicate or ' : ''}very similar name to another item. Please ensure it is the correct item.`;
				showAlert({
					message,
					duration: 5000,
					type: "warning",
				});
			}
		}

		if (validateEmail && !isValidEmail(trimmedTag)) {
			return showAlert({
				message: "Email entered is not a valid email address. Please try again.",
				duration: 5000,
				type: "error",
			});
		}

		const unique = uniq([...tags ?? [], trimmedTag]);
		if (maxTags && unique?.length >= maxTags) {
			onError?.('MAX TAGS');
		}

		onChange(unique);
		setInputValue('');
	}, [inputValue, maxTags, onChange, onError, restrictToList, tags, validateEmail, visibleTags]);

	// On tab or escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (allowWithoutEnter && (event.code === 'Tab' || event.key === 'Tab')) {
				if (inputValue.trim().length) {
					addTag(inputValue.trim());
				}
			}
			if (
				['Tab', 'Escape'].includes(event.code) ||
				['Tab', 'Escape'].includes(event.key) ||
				[9, 27].includes(event.keyCode) ||
				[9, 27].includes(event.which)
			) {
				dispatch(setADropdownIsOpen(false));
				setOpen(false);
			}
		};
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, [addTag, allowWithoutEnter, inputValue, dispatch]);

	const removeTag = (tagToRemove: string) => {
		const updatedTags = tags.filter((tag: string) => tag !== tagToRemove);
		onChange(updatedTags);
		inputRef?.current?.focus(); // focus the input after removing
	};

	const removeAllTags = () => {
		onChange([]);
	};

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		if (lowercaseTags) {
			setInputValue(target.value.toLowerCase());
		} else {
			setInputValue(target.value);
		}
	};

	const handleBlur = useCallback(() => {
		const unique = uniq([...tags ?? []]);
		if (maxTags && unique?.length >= maxTags) {
			onError?.('MAX TAGS');
		}
		onBlur?.(unique);
	}, [maxTags, onBlur, onError, tags]);

	// Add tag on hitting enter or search onKeyUp
	const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			addTag(inputValue);
		}
	};

	const handleOptionClick = (tag: string) => () => {
		addTag(tag);
		dispatch(setADropdownIsOpen(false));
		closeOnSelect && setOpen(false);
	};

	// Opens the dropdown
	const handleFocus = () => {
		if (disableFocus) {
			return;
		}
		dispatch(setADropdownIsOpen(true));
		setOpen(true);
	};

	// This mimics a blur, closing the dropdown as if it's all one select element
	const handleClick = useCallback((e: any) => {
		const path = e.path || e.composedPath();
		for (const item of path) {
			if (tagSelector.current === item) {
				if (allowWithoutEnter && e.target.value?.trim()?.length) {
					addTag(e.target.value.trim());
				}
				return;
			}
		}
		if (allowWithoutEnter && inputValue.trim().length) {
			addTag(inputValue.trim());
		}
		onBlur && handleBlur();
		dispatch(setADropdownIsOpen(false));
		setOpen(false);
	}, [addTag, allowWithoutEnter, handleBlur, inputValue, onBlur, dispatch]);

	useEffect(() => {
		if (open) { window.addEventListener("click", handleClick); }

		return () => window.removeEventListener("click", handleClick);
	}, [handleClick, open]);

	return (
		<div
			className={classNames("field-group tag-select-container", className, { entered })}
			ref={tagSelector}
		>
			{(label || tooltip) &&
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{label && <label>{label}</label>}
					<div className="tooltip">
						{
							tooltip
								? typeof image === 'function'
									? image()
									: <img src={image} alt="tooltip" height="18px" width="auto" />
								: null
						}{" "} <span className="tooltip-text">{tooltip && text}</span>
					</div>
				</div>
			}

			<div style={{ overflow: 'hidden', borderRadius: '22px' }}>
				<div
					ref={tagsInput}
					style={{ maxHeight }}
					className={classNames("tag-select", { error: errorMessage }, additionalClasses)}
				>
					{tags?.map((tag: string, index) => {
						if (noWhitespace) return tag.trim() ? <Tag capSensitive={capSensitive} tag={tag} key={tag} remove={removeTag} /> : null;
						return <Tag capSensitive={capSensitive} tag={tag} key={`tag-${tag}-${index}`} remove={removeTag} />;
					})}

					{loading ? (
						<WaitingIndicator />
					) : (
						<input
							type="text"
							onFocus={handleFocus}
							onChange={handleChange}
							placeholder={(hidePlaceholderOnContent && tags?.length) ? '' : placeholder}
							onKeyUp={handleKeyUp}
							value={inputValue}
							ref={inputRef}
						/>
					)}
					<OptionalComponent display={showArrow}>
						<div className='dropdown-arrow-icon'>
							<Icon name={open ? ICONS.KEYBOARD_ARROW_UP : ICONS.KEYBOARD_ARROW_DOWN} size={12} color={COLORS.WHITE} />
						</div>
					</OptionalComponent>
				</div>
				<OptionalComponent display={!!(tags?.length && allowClearAll)}>
					<button
						style={{
							position: 'absolute',
							right: '34px',
							top: '50%',
						}}
						onClick={removeAllTags}
						className="round"
					>
						<Icon name={ICONS.CLOSE} color={COLORS.DEFAULT} size={16} />
					</button>
				</OptionalComponent>
			</div>

			{scrollbarOutsideContainer &&
				// In order to render the scrollbar outside the border, and off to the right like the rooms section in the
				// create BR session modal,  we need to remove the normal border and place this custom absolute positioned border
				<div className="scroll-outside-border"></div>
			}

			{errorMessage && <label className="error-message">{errorMessage}</label>}

			{((!!defaultTags && defaultTags.length > 0) || (!!jsxTags && jsxTags.length > 0)) && (
				<div
					className={
						classNames(
							"tag-select-dropdown",
							{
								// checking for "visibleTags.length" removes the box shadow when all tags have been selected and the input is focused
								open: open && visibleTags.length,
								pad: padIn,
							}
						)
					}
					style={dropdownContainerStyles}
				>
					{visibleTags?.map((_tag: string, index: number) => {
						const tag = jsxTags
							? jsxTags.find(jsxTag => jsxTag.value === _tag)
							: _tag;

						return (
							<div
								key={`tag-option-${tag}-${index}`}
								className="tag-option"
								onClick={handleOptionClick(typeof tag === 'string' ? tag : tag?.value || '')}
							>
								<span>{tagImage && <img src={tagImage} />} {typeof tag === 'string' ? tag : tag?.label || ''}</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}