import { createContext, useEffect, useReducer } from "react";

import { PageModuleGroupModules } from "../../../../../../types/working-model";

type SessionPanelState = {
	activeTabModule: PageModuleGroupModules | undefined;
	selectedSurveys: any[] | undefined;
	title: string;
	selectedSuggested: any[] | undefined;
}

const sessionPanelInitialState: SessionPanelState = {
	activeTabModule: undefined as PageModuleGroupModules | undefined,
	selectedSurveys: undefined,
	title: "Content",
	selectedSuggested: undefined,
};

export enum SessionPanelActions {
	SetTabModule = 'setTabModule',
	SetSelectedSurveys = 'setSurveys',
	RemoveSelectedSurvey = 'removeSurvey',
	SetTitle = 'setTitle',
	SetSelectedSuggested = 'setSuggested',
	RemoveSelectedSuggested = 'removeSuggested',
}

type DispatchedAction<T, P> = { type: T, payload: P };
type SetTabModuleAction = DispatchedAction<SessionPanelActions.SetTabModule, PageModuleGroupModules | undefined>;
type SetSelectedSurveyAction = DispatchedAction<SessionPanelActions.SetSelectedSurveys, any[] | undefined>;
type RemoveSelectedSurveyAction = DispatchedAction<SessionPanelActions.RemoveSelectedSurvey, number>;
type SetTitleAction = DispatchedAction<SessionPanelActions.SetTitle, string>;
type SetSelectedSuggestedAction = DispatchedAction<SessionPanelActions.SetSelectedSuggested, any[] | undefined>;
type RemoveSelectedSuggestedAction = DispatchedAction<SessionPanelActions.RemoveSelectedSuggested, number>;

type SessionPanelAction = SetTabModuleAction
	| SetSelectedSurveyAction
	| RemoveSelectedSurveyAction
	| SetSelectedSuggestedAction
	| RemoveSelectedSuggestedAction
	| SetTitleAction;

export const setActiveTabModule = (module: PageModuleGroupModules | undefined): SetTabModuleAction => ({ type: SessionPanelActions.SetTabModule, payload: module });
export const setSelectedSurveys = (surveys: any[] | undefined): SetSelectedSurveyAction => ({ type: SessionPanelActions.SetSelectedSurveys, payload: surveys });
export const removeSelectedSurvey = (id: number): RemoveSelectedSurveyAction => ({ type: SessionPanelActions.RemoveSelectedSurvey, payload: id });
export const setPanelTitle = (title: string): SetTitleAction => ({ type: SessionPanelActions.SetTitle, payload: title });
export const setSelectedSuggested = (suggested: any[] | undefined): SetSelectedSuggestedAction => ({ type: SessionPanelActions.SetSelectedSuggested, payload: suggested });
export const removeSelectedSuggested = (id: number): RemoveSelectedSuggestedAction => ({ type: SessionPanelActions.RemoveSelectedSuggested, payload: id });

const sessionPanelReducer = (state: SessionPanelState, action: SessionPanelAction) => {
	switch (action.type) {
		case SessionPanelActions.SetTitle:
			return { ...state, title: action.payload };
		case SessionPanelActions.SetTabModule:
			return { ...state, activeTabModule: action.payload };
		case SessionPanelActions.SetSelectedSurveys:
			return { ...state, selectedSurveys: action.payload };
		case SessionPanelActions.RemoveSelectedSurvey:
			return {
				...state,
				selectedSurveys: state.selectedSurveys?.filter(item => item.survey !== action.payload),
			};
		case SessionPanelActions.SetSelectedSuggested:
			return { ...state, selectedSuggested: action.payload };
		case SessionPanelActions.RemoveSelectedSuggested:
			return {
				...state,
				selectedSuggested: state.selectedSuggested?.filter(item => item.session !== action.payload),
			};
		default:
			return state;
	}
};

type SessionPanelStateContextType = {
	state: SessionPanelState,
	dispatch: React.Dispatch<SessionPanelAction>
};

export const SessionPanelContext = createContext({
	state: sessionPanelInitialState,
	dispatch: () => null
} as SessionPanelStateContextType);

const getSessionPanelInitialState = ({
	activeTabModule,
	selectedSurveys,
	selectedSuggested
}: SessionPanelState): SessionPanelState => {
	return {
		activeTabModule,
		selectedSurveys,
		title: "Content",
		selectedSuggested,
	};
};

export const SessionPanelStateProvider: React.FC<SessionPanelState & { children: JSX.Element }> = (props) => {
	const [state, dispatch] = useReducer(sessionPanelReducer, getSessionPanelInitialState(props));

	useEffect(() => {
		dispatch(setPanelTitle(props.title));
	}, [props.title]);

	return <SessionPanelContext.Provider value={{ state, dispatch }}>{props.children}</SessionPanelContext.Provider>;
};