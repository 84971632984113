import classNames from 'classnames';
import React from 'react';

interface Props {
	selected: number;
	onClick: (value: any, selected: number) => void;
	value: any;
	disabled?: boolean;
	leftText?: string;
	rightText?: string;
}

export default function PillToggleRound(props: Props): JSX.Element{
	const { selected, onClick, value, disabled = false, leftText="Required", rightText="Optional"} = props;
	return (
		<div
			className={classNames('pill-toggle-round', { disabled })}
			onClick={() => {
				const newValue = selected === 1 ? 0 : 1;
				onClick(value, newValue);
			}}
		>
			<div className={`slider position-${selected}`}>
			</div>
			<button
				className={selected === 1 ? 'selected' : ''}
			>
				{ leftText }
			</button>
			<button
				className={selected === 0 ? 'selected' : ''}
			>
				{ rightText }
			</button>
		</div>
	);
}