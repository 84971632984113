import classNames from 'classnames';

import { LeaderboardEntry } from "../../../../../types/leaderboard";
import LeaderbordAvatar from "../leaderboard-avatar";
import PodiumDetails from "./podium-details";

interface IPodiumEntry {
	entry: LeaderboardEntry;
	position?: 'gold' | 'silver' | 'bronze';
	viewProfile?: (uuid: string) => void;
	isMobile?: boolean;
}

const PodiumEntry: React.FC<IPodiumEntry> = ({ entry, position = '', viewProfile, isMobile }) => {

	const wrapperClasses = `podium-entry ${position}`;

	// if no entry, return the wrapper so we can keep the width/height and flex positioning of each element
	if (!entry) {
		if (isMobile) {
			return null;
		}
	
		return <div className={wrapperClasses} />;
	}

	const handleClick = () => {
		if (!viewProfile) return;
		viewProfile(entry.bl_profile_uuid);
	};

	return (
		<div className={classNames(wrapperClasses, { 'clickable': viewProfile })} role='link' onClick={handleClick}>
			<LeaderbordAvatar entry={entry} badge={true} />
			<PodiumDetails entry={entry} />
		</div>
	);
};

export default PodiumEntry;
