import { GetHv, HvHostMap, PostHv } from './helpers';

/**
 *
 * @param session the ID of the session
 * @param token bl-profile-token only optional if registration is OFF
 * @returns uuid of the user sessions
 */
export async function GetUserSession(
	session: number,
	event: number,
	channel: number,
	token?: string,
	registration_id?: string,
	registeredLanguage?: string,
	referrer?: string,
	validPasscodeLists?: number[],
): Promise<{ uuid: string | null, session: number, getUserSessionError: string | null }> {
	const params = new URLSearchParams();

	if (registeredLanguage) {
		params.set('lang', registeredLanguage);
	} else {
		params.set('lang', navigator.language);
	}

	if (registration_id) {
		params.set('registration_id', registration_id);
	}

	if (referrer) {
		params.append('referrer', referrer);
	}

	params.set('event', String(event));
	params.set('channel', String(channel));
	params.set('valid_passcode_lists', String(validPasscodeLists));

	const data = await GetHv(HvHostMap.sessionTracking, `/e3-generate-session/${session}?${params.toString()}`, token);

	if (data?.message && !data?.uuid) {
		return { uuid: null, session, getUserSessionError: data.message };
	}

	return { uuid: data.uuid, session, getUserSessionError: null };
}

/**
 * Updates the end time for the current session. Fires and forgets.
 * @param uuid The uuid of the current session
 * @returns empty object
 */
export async function UpdateUserSessionEndTime(uuid: string): Promise<any> {
	PostHv(HvHostMap.sessionTracking, '/e3-update-end-time', { uuid }, undefined, true);

	return {};
}

/**
 *
 * @param event the ID of the event
 * @param token bl-profile-token only optional if registration is OFF
 * @returns uuid of the page session
 */
export async function GetPageSession(
	event: number,
	token?: string,
	registration_id?: string,
	referrer?: string
): Promise<{ uuid: string | null, event: number, getPageSessionError: string | null }> {
	const params = new URLSearchParams();

	if (navigator.language) {
		params.set('lang', navigator.language);
	}

	if (registration_id) {
		params.set('registration_id', registration_id);
	}

	if (referrer) {
		params.set('referrer', referrer);
	}

	const data = await GetHv(HvHostMap.sessionTracking, `/e3-generate-page-session/${event}?${params.toString()}`, token);

	if (data?.message && !data?.uuid) {
		return { uuid: null, event, getPageSessionError: data.message };
	}

	return { uuid: data.uuid, event, getPageSessionError: null };
}

/**
 * Updates the end time for the current page session. Fires and forgets.
 * @param uuid The uuid of the current page session
 * @returns empty object
 */
export async function UpdatePageSessionEndTime(uuid: string): Promise<any> {
	PostHv(HvHostMap.sessionTracking, '/update-page-session-end-time', { uuid }, undefined, true);

	return {};
}
