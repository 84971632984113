import React from 'react';

import './panel-wrapper-v2.scss';


const TopPanelWrapper: React.FC<{ children: JSX.Element, title?: string, id?: string }> = ({ children, id }) => {
	return (
		<div className="panel-wrapper-v2" style={{ zIndex: 1 }} id={id}>
			<div className="panel-body-v2">
				{children}
			</div>
		</div>
	);
};

export default TopPanelWrapper;