import classNames from "classnames";
import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { useDispatch, batch } from "react-redux";
import { useParams } from 'react-router';

import {
	saveWorkingSession,
	setOnDemandVideo,
	setSessionBroadcastType,
	updateSecondarySessionVideo,
	updateWorkingSession,
} from "../../../../../store/actions/admin/create-event/session";
import { createUploadJob, getChannelRecordings, getChannelUpVideos } from "../../../../../store/actions/admin/videos";
import { GetDefaultVideo } from "../../../../../store/utils/create-event";
import { HomepageVideo, CreateVideo, SessionTypesEnum, IVSRecording, EBroadcastTypes, VideoConvertStatus, SecondarySessionVideo, BLAdmin } from "../../../../../types/working-model";
import CustomHTML from "../../../../general-ui/edit-html/edit-html";
import Icon, { ICONS, COLORS } from "../../../../general-ui/icon";
import Modal from "../../../../general-ui/modal/modal";
import TextInput from "../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../general-ui/waiting-indicator/waiting-indicator";
import images from "../../../../../images";
import { getImageLocation } from "../../../../../utils/utils";
import { ChecklistTitles } from "../../../../../store/reducers/admin/assistant";
import { addChecklistItem } from "../../../../../store/actions/authentication";
import { AnimatePresence, motion } from "framer-motion";
import { ParamsProps } from '../../../../live-event/live-event';
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { setADropdownIsOpen, setSessionPlaceholderVideo } from "../../../../../store/actions/admin/create-event";
import { useGetRecordingJWT, useGetRecordingUrl } from "hooks/recording-jwt.hooks";
import { OptionalComponent } from "utils/optional-component";
import { isHomepageVideo } from "utils/is-homepage-video";
import { putVideoThumbnail } from "utils/video-thumbnail-generator";
import { UpdateVideo } from "connection/videos";
import { useCanEnableMeetFiresides } from "hooks/channel.hooks";
import { Case, DefaultCase, SwitchCase } from "@general-ui/switch-case/switch-case";

interface IVideoModal {
	open: boolean;
	onClose: () => void;
	isSecondaryVideo?: boolean;
	workingSecondaryVideo?: CreateVideo | HomepageVideo;
}

interface OnDemandSourceStrings {
	urlSource: string;
	embedSource: string;
}

const sortVideos = (a: HomepageVideo | IVSRecording, b: HomepageVideo | IVSRecording) => {
	const timeA = ('video' in a) ? new Date(a.created_at) : new Date(a.timestamp);
	const timeB = ('video' in b) ? new Date(b.created_at) : new Date(b.timestamp);

	if (timeA > timeB) {
		return -1;
	} else {
		return 1;
	}
};

const VideoModal: React.FC<IVideoModal> = ({ open, onClose, isSecondaryVideo, workingSecondaryVideo }: IVideoModal) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const videos = useTypedSelector(state => state.VideosReducer.videos);
	const loadingVideos = useTypedSelector(state => state.VideosReducer.loadingVideos);
	const recordedVideos = useTypedSelector(state => state.VideosReducer.recordedVideos);
	const loadingRecordings = useTypedSelector(state => state.VideosReducer.loadingRecordings);
	const recordingJWT = useGetRecordingJWT(); // For thumbnail
	const getRecordingUrl = useGetRecordingUrl();

	const dispatch = useDispatch();
	const { language } = useParams<ParamsProps>();
	const [modalOpen, setModalOpen] = useState(false);
	const [workingOnDemandVideo, setWorkingOnDemandVideo] = useState<HomepageVideo | CreateVideo | null>(null);
	const [onDemandSourceStrings, setOnDemandSourceStrings] = useState<OnDemandSourceStrings>({ urlSource: '', embedSource: '' });
	const [selectingType, setSelectingType] = useState(false);
	const [visibleVideos, setVisibleVideos] = useState<(HomepageVideo | IVSRecording)[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [generatingThumbnailFor, setGeneratingThumbnailFor] = useState<HomepageVideo | undefined>();
	const [failedThumbnails, setFailedThumbnails] = useState<number[]>([]);
	const videoTypeSelectRef = useRef(null);
	const canEnableMeet = useCanEnableMeetFiresides();

	const meetFireside = workingSession?.fireside_session_settings?.settings?.use_google_meet;
	// because we actually update the database when a type is selected instead of when we click "done", we need to store the
	// original value somewhere, so if a user clicks "cancel" instead of "done", we can reset it back to the original.
	// The best way to handle this would be to re-write the entire component so that we store a temp local state when a user selects
	// and we only update the database when the user clicks "done", but this was updated during a bugbash and that's too dangerous of a lift
	// to push to staging
	const originalBroadcastType = useRef(workingSession?.broadcast_type);

	const mounted = useRef(false);

	useEffect(() => {
		function checkClick(e: MouseEvent) {
			const path = e.composedPath();
			for (const item of path) {
				if (videoTypeSelectRef.current === item) { return; }
			}
			setSelectingType(false);
			dispatch(setADropdownIsOpen(false));
		}

		if (selectingType) {
			window.addEventListener('click', checkClick);
		}

		return () => window.removeEventListener('click', checkClick);
	}, [dispatch, selectingType, setSelectingType]);

	function selectRecordedVideo() {
		if (!token) return;
		// if on demand OR if broadcast and embed, then set the on demand video
		if (
			workingSession?.session_type === SessionTypesEnum.onDemand
			|| (workingSession?.session_type === SessionTypesEnum.broadcast && workingSession.broadcast_type === EBroadcastTypes.embed)
		) {
			if (workingOnDemandVideo) {
				if (workingOnDemandVideo.session_language === undefined) {
					workingOnDemandVideo.session_language = language;
				}
				dispatch(setOnDemandVideo(workingOnDemandVideo));
			}
		}

		if (isSecondaryVideo && workingOnDemandVideo?.source && !!workingSession && !!workingEvent) {
			const source = workingOnDemandVideo.hls_link?.endsWith('m3u8')
				? workingOnDemandVideo.hls_link
				: workingOnDemandVideo.source;

			const secondaryVideo: SecondarySessionVideo = {
				playback_url: source || '',
				session: workingSession.session,
			};
			batch(() => {
				dispatch(updateSecondarySessionVideo(secondaryVideo, token));
				dispatch(setSessionPlaceholderVideo({
					session_uuid: workingSession.uuid,
					playback_url: source,
					language: 'all',
					type: 'placeholder_video'
				}));
			});
		}

		// ASSISTANT CHECKLIST ITEM: complete users assistant checklist item for updating video settings
		if (
			user &&
			!user.onboarded.includes(ChecklistTitles.updateVideo)
		) {
			dispatch(addChecklistItem(token, user.id, user.onboarded, ChecklistTitles.updateVideo));
		}

		dispatch(saveWorkingSession());

		originalBroadcastType.current = workingSession?.broadcast_type;

		close();
	}

	function getOnDemandSourceString(type: string) {
		return type === 'embed'
			? onDemandSourceStrings.embedSource
			: type === 'url'
				? onDemandSourceStrings.urlSource
				: workingOnDemandVideo?.source ?? '';
	}

	function setSelectedType(type: "uploaded" | "embed" | "url" | "recorded" | "meet" | "native") {
		if (["meet", "native"].includes(type)) {
			if (!workingSession) {
				return;
			}

			dispatch(updateWorkingSession({
				...workingSession,
				fireside_session_settings: workingSession.fireside_session_settings ? {
					...workingSession.fireside_session_settings,
					settings: {
						...workingSession.fireside_session_settings?.settings,
						use_google_meet: type === "meet"
					}
				} : undefined
			}));
		} else {
			if (!workingOnDemandVideo) return null;

			setWorkingOnDemandVideo((workingOnDemandVideo) => ({
				...GetDefaultVideo(),
				...workingOnDemandVideo,
				source: getOnDemandSourceString(type),
				session_language: language,
				type: type as "uploaded" | "embed" | "url" | "recorded"
			}));

			if (type === 'url') {
				setOnDemandSourceStrings({ ...onDemandSourceStrings, embedSource: workingOnDemandVideo.source || '' });
			} else if (type === 'embed') {
				setOnDemandSourceStrings({ ...onDemandSourceStrings, urlSource: workingOnDemandVideo.source || '' });
			}
		}

		setSelectingType(false);
		dispatch(setADropdownIsOpen(false));
	}

	function setSelectedBroadcastType(type: EBroadcastTypes) {
		return () => {
			if (type === 'embed' && workingSession?.layout.overlayChat) {
				// embeded video can not have overlay chat
				dispatch(updateWorkingSession({
					...workingSession,
					layout: {
						...workingSession?.layout,
						overlayChat: false
					}
				}));
			}
			dispatch(setSessionBroadcastType(type));
			setSelectingType(false);
			dispatch(setADropdownIsOpen(false));
		};
	}

	function setSelectedVideo(video: HomepageVideo | IVSRecording) {
		return () => {
			// if selecting the same video, toggle workingOnDemandVideo back to default
			if (workingOnDemandVideo?.source === getVideoSource(video)) {
				setWorkingOnDemandVideo(GetDefaultVideo());
				return;
			}

			if ('video' in video) {
				setWorkingOnDemandVideo(workingOnDemandVideo => {
					if (!workingOnDemandVideo) return null;

					return {
						...video
					};
				});
			} else {
				const _video: CreateVideo = {
					type: "recorded",
					name: video.name,
					source: video.playback_url,
					hls_link: video.playback_url,
					image: getImageLocation(video.playback_url),
					use_thumbnail: false,
					original_image: getImageLocation(video.playback_url),
					session_language: language,
					duration: video?.duration ?? 0,
					recording_duration_ms: (video.recording_duration_ms ?? 0),
					recording_id: video.id
				};

				setWorkingOnDemandVideo(workingOnDemandVideo => {
					if (!workingOnDemandVideo) return null;

					return {
						..._video
					};
				});
			}
		};
	}

	const handleSearch = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(value);
	}, []);

	function clickUpload() {
		if (!token || !user) return;

		dispatch(createUploadJob(() => {
			if (mounted.current) {
				dispatch(getChannelUpVideos(token, user.active_channel));
			}
		}));
	}

	function handleVideoUrl({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		setWorkingOnDemandVideo(workingOnDemandVideo => {
			if (!workingOnDemandVideo) return null;

			return {
				...workingOnDemandVideo,
				source: value,
				session_language: language
			};
		});
		setOnDemandSourceStrings({ ...onDemandSourceStrings, urlSource: value });
	}

	function handleVideoEmbed(code: string) {
		setWorkingOnDemandVideo(workingOnDemandVideo => {
			if (!workingOnDemandVideo) return null;

			return {
				...workingOnDemandVideo,
				source: code,
				session_language: language
			};
		});
		setOnDemandSourceStrings({ ...onDemandSourceStrings, embedSource: code });
	}

	const renderVideoSelect = useMemo(() => {
		if (workingSession?.session_type === SessionTypesEnum.onDemand || isSecondaryVideo) {
			if (!workingOnDemandVideo) return null;

			switch (workingOnDemandVideo.type) {
				case 'uploaded':
				case 'recorded': {
					return (
						<>
							<div className="recorded-header">
								<TextInput
									onChange={handleSearch}
									placeholder={'Search video...'}
									icon={ICONS.SEARCH}
									size={'small'}
								/>
								<span>or</span>
								<button className="small" onClick={clickUpload}>Upload video</button>
							</div>
							<div className="recorded-videos">
								{(loadingVideos || loadingRecordings) ? (
									<WaitingIndicator />
								) : (
									<>
										{visibleVideos.map(video => {
											if ('video' in video) {
												const thumbnailUrl = getRecordingUrl(video.image, recordingJWT);
												const thumbnail = thumbnailUrl ? `url(${thumbnailUrl})` : 'none';
												return (
													<div key={'up' + video.uuid} onClick={setSelectedVideo(video)} className={classNames("recorded-video", { selected: video.source === workingOnDemandVideo.source })}>
														<div
															data-status={video.convert_status}
															data-video={video.video}
															style={{ backgroundImage: thumbnail }} className="video-banner">
															<AnimatePresence>
																{(video.convert_status === VideoConvertStatus.progressing || (video.video && video.video === generatingThumbnailFor?.video)) && (
																	<motion.div
																		key={video.video}
																		initial={{ opacity: 0 }}
																		animate={{ opacity: 1 }}
																		exit={{ opacity: 0 }}
																		className="processing-overlay">
																		{video.convert_progress && <span>Your upload is currently processing.<br /><br />Progress: {video.convert_progress ?? 0}%</span>}
																	</motion.div>
																)}
															</AnimatePresence>
														</div>
														<label>{video.name}</label>
														{video.duration && <span className="duration">{new Date(video.duration * 1000).toISOString().substr(11, 8)}</span>}


													</div>
												);
											} else if ('id' in video) {
												const thumbnailUrl = getRecordingUrl(getImageLocation(video.playback_url), recordingJWT);
												const thumbnail = thumbnailUrl ? `url(${thumbnailUrl})` : 'none';
												return (
													<div key={'rec' + video.uuid} onClick={setSelectedVideo(video)} className={classNames("recorded-video", { selected: video.playback_url === workingOnDemandVideo.hls_link })}>
														<div style={{ backgroundImage: thumbnail }} className="video-banner">
														</div>
														<label>{video.name}</label>
														{video.recording_duration_ms && <span className="duration">{new Date(Number(video.recording_duration_ms)).toISOString().substr(11, 8)}</span>}
													</div>
												);
											}
										})}
									</>
								)}
							</div>
						</>
					);
				}
				case 'url': {
					return (
						<TextInput
							placeholder="Enter video URL"
							label="Add video URL"
							onChange={handleVideoUrl}
							style={{ marginTop: 16 }}
							defaultValue={getOnDemandSourceString(workingOnDemandVideo?.type)}
						/>
					);
				}
				case 'embed': {
					return (
						<div className="video-embed-code">
							<label>Embed Code</label>
							<CustomHTML
								onChange={handleVideoEmbed}
								defaultValue={getOnDemandSourceString(workingOnDemandVideo?.type)}
							/>
						</div>
					);
				}
			}
		} else {
			switch (workingSession?.broadcast_type) {
				case EBroadcastTypes.greenroom: {
					// greenroom creation for broadcast session streaming happens in greenroom.
					// getGreenroomBroadcastSessionsByChannel in events-api pulls session data into greenroom to populate the list,
					// but the greenroom isn't actually created until the producer in greenroom creates it
					return null;
				}
				case EBroadcastTypes.rtmps: {
					return null; // moved rtmp info into panel
				}
				case EBroadcastTypes.embed: {
					return (
						<div className="video-embed-code">
							<label>Embed Code</label>
							<CustomHTML
								onChange={handleVideoEmbed}
								defaultValue={workingOnDemandVideo?.source ?? ''}
							/>
						</div>
					);
				}
				case EBroadcastTypes.fireside: {
					return null;
				}
				default: {
					return <h3>Nothing selected</h3>;
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [workingOnDemandVideo, visibleVideos, loadingVideos, handleSearch, workingSession, isSecondaryVideo, generatingThumbnailFor]);

	const selectedWorkingType = useMemo(() => {
		if (workingSession?.session_type === SessionTypesEnum.onDemand || isSecondaryVideo) {
			if (workingOnDemandVideo) {
				switch (workingOnDemandVideo.type) {
					case 'embed':
						return <EmbedIframe />;
					case 'recorded':
					case 'uploaded':
						return <AddVideo />;
					case 'url':
						return <LinkVideo />;
					default:
						return (<></>);
				}
			}
		} else if (workingSession?.session_type === SessionTypesEnum.broadcast) {
			switch (workingSession?.broadcast_type) {
				case EBroadcastTypes.greenroom: {
					return <GreenroomStreaming />;
				}
				case EBroadcastTypes.rtmps: {
					return <RTMPStreaming />;
				}
				case EBroadcastTypes.embed: {
					return <EmbedIframe />;
				}
				default: return <></>;
			}
		} else if (workingSession?.session_type === SessionTypesEnum.fireside) {
			if (canEnableMeet && meetFireside) {
				return <MeetFiresides />;
			} else {
				return <NativeFiresides />;
			}
		}

	}, [workingSession?.session_type, workingSession?.broadcast_type, isSecondaryVideo, workingOnDemandVideo, canEnableMeet, meetFireside]);

	function close(resetOriginalBroadcastType?: boolean) {
		// resetOriginalBroadcastType is only passed in when the modal is cancelled, not when "done" is clicked
		// this way we can reset the original value
		if (resetOriginalBroadcastType && originalBroadcastType?.current && workingSession?.session_type === SessionTypesEnum.broadcast) {
			setSelectedBroadcastType(originalBroadcastType.current)();
		}

		setModalOpen(false);
		onClose();
	}

	useEffect(() => {
		if (modalOpen && token && user) {
			batch(() => {
				dispatch(getChannelUpVideos(token, user.active_channel));
				dispatch(getChannelRecordings(token, user.active_channel));
			});
		}
	}, [dispatch, modalOpen, token, user]);

	useEffect(() => {
		if (!searchTerm) {
			const uniqueVideos = Array.from(new Map<string, HomepageVideo | IVSRecording>(
				[...recordedVideos, ...videos].map(video => [video.uuid, video])
			).values());
			setVisibleVideos(uniqueVideos.sort(sortVideos));
		} else {
			setVisibleVideos([
				...recordedVideos.filter(video => video.name.toLowerCase().includes(searchTerm.toLowerCase())),
				...videos.filter(video => video.name.toLowerCase().includes(searchTerm.toLowerCase()))
			].sort(sortVideos));
		}
	}, [videos, searchTerm, recordedVideos]);

	useEffect(() => {
		if (!open || generatingThumbnailFor) {
			return;
		}

		for (const video of visibleVideos) {
			if (isHomepageVideo(video) &&
				video.type === 'recorded' &&
				!video.image &&
				video.source?.endsWith('.mp4') &&
				!failedThumbnails.includes(video.video)
			) {
				setGeneratingThumbnailFor(video);
				break;
			}
		}
	}, [visibleVideos, generatingThumbnailFor, open, failedThumbnails]);

	const generateThumbnail = useCallback(async () => {
		try {
			if (!generatingThumbnailFor || !token || !user) {
				return;
			}

			const url = await putVideoThumbnail(generatingThumbnailFor.source as string, token, user);
			if (url) {
				const newVideo = {
					...generatingThumbnailFor,
					image: url,
					original_image: url,
					convert_status: VideoConvertStatus.complete
				};
				await UpdateVideo(token, newVideo);
				setVisibleVideos(visibleVideos => visibleVideos.map(video => {
					if (video.uuid === generatingThumbnailFor.uuid) {
						return newVideo;
					}
					return video;
				}));
			}
		} catch (e) {
			console.error(e);
			if (generatingThumbnailFor) {
				setFailedThumbnails(failedThumbnails => [...failedThumbnails, generatingThumbnailFor.video]);
			}
		} finally {
			setGeneratingThumbnailFor(undefined);
		}
	}, [generatingThumbnailFor, token, user]);

	useEffect(() => {
		generateThumbnail();
	}, [generateThumbnail]);

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
			setOnDemandSourceStrings({ urlSource: '', embedSource: '' });
		};
	}, []);

	useEffect(() => {
		if (open) {
			if (isSecondaryVideo && workingSecondaryVideo) {
				setWorkingOnDemandVideo(workingSecondaryVideo);
			}

			if (workingSession?.on_demand_video && Array.isArray(workingSession.on_demand_video) && workingSession?.on_demand_video.length > 0 && workingSession?.on_demand_video?.[0] !== null) {
				let video;
				if (workingSession?.on_demand_video !== null && typeof workingSession?.on_demand_video === 'object' && !Array.isArray(workingSession?.on_demand_video)) {
					video = workingSession?.on_demand_video;
				} else {
					video = workingSession?.on_demand_video?.find(video => video.session_language === workingSession?.current_language) ??
						workingSession?.on_demand_video?.[0];
				}
				if (video) {
					setWorkingOnDemandVideo(video);
					setOnDemandSourceStrings({
						urlSource: video?.type === 'url' ? video.source ?? '' : '',
						embedSource: video?.type === 'embed' ? video.source ?? '' : ''
					});
				}
			} else {
				setWorkingOnDemandVideo(GetDefaultVideo());
			}

			if (!modalOpen) { // Prevent resetting the variable
				setModalOpen(true);
			}
		}
	}, [
		open,
		workingSession,
		isSecondaryVideo,
		workingSecondaryVideo,
		modalOpen,
	]);

	const title = () => {
		switch (workingSession?.session_type) {
			case SessionTypesEnum.onDemand:
				return "On-demand Session Stream Settings";
			case SessionTypesEnum.broadcast:
				return "Broadcast Session Stream Settings";
			case SessionTypesEnum.fireside:
				return "Fireside Session Stream Settings";
			default:
				return "Session Stream Settings";
		}
	};

	return (
		<Modal
			open={modalOpen}
			onRequestClose={() => close(true)}
			title={title()}
			cancellable={true}
			closeable={false}
			padTitle={true}
			footer={(
				<>
					<button onClick={() => close(true)} style={{ marginRight: 16 }}>Cancel</button>
					<button onClick={selectRecordedVideo} className="lemonade">Done</button>
				</>
			)}
		>
			<div className="add-video-modal">
				<div className="video-type-select" ref={videoTypeSelectRef}>
					<div className={classNames('video-type-selected', 'select-streaming-type', { open: selectingType })} onClick={() => { setSelectingType(true); dispatch(setADropdownIsOpen(true)); }}>
						{selectedWorkingType}
						<span className="arrow">
							<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={24} color={COLORS.WHITE} />
						</span>
					</div>
					<div className={classNames("video-type-selector", { open: selectingType })}>
						<SwitchCase input={workingSession?.session_type}>
							<Case value={SessionTypesEnum.onDemand}>
								<>
									<OptionalComponent display={workingOnDemandVideo?.type !== 'uploaded'}>
										<div onClick={() => setSelectedType('uploaded')} className="select-option">
											<AddVideo />
										</div>
									</OptionalComponent>
									<OptionalComponent display={workingOnDemandVideo?.type !== 'url'}>
										<div onClick={() => setSelectedType('url')} className="select-option">
											<LinkVideo />
										</div>
									</OptionalComponent>
									<OptionalComponent display={workingOnDemandVideo?.type !== 'embed'}>
										<div onClick={() => setSelectedType('embed')} className="select-option">
											<EmbedIframe />
										</div>
									</OptionalComponent>
								</>
							</Case>
							<Case value={SessionTypesEnum.broadcast}>
								<>
									<OptionalComponent display={workingSession?.broadcast_type !== EBroadcastTypes.greenroom}>
										<div className="select-option" onClick={setSelectedBroadcastType(EBroadcastTypes.greenroom)}>
											<GreenroomStreaming />
										</div>
									</OptionalComponent>
									<OptionalComponent display={workingSession?.broadcast_type !== EBroadcastTypes.rtmps}>
										<div className="select-option" onClick={setSelectedBroadcastType(EBroadcastTypes.rtmps)}>
											<RTMPStreaming />
										</div>
									</OptionalComponent>
									<OptionalComponent display={workingSession?.broadcast_type !== EBroadcastTypes.embed}>
										<div className="select-option" onClick={setSelectedBroadcastType(EBroadcastTypes.embed)}>
											<EmbedIframe />
										</div>
									</OptionalComponent>
								</>
							</Case>
							<Case value={SessionTypesEnum.fireside}>
								<>
									<OptionalComponent display={canEnableMeet && !meetFireside}>
										<div className="select-option" onClick={() => setSelectedType('meet')}>
											<MeetFiresides />
										</div>
									</OptionalComponent>
									<OptionalComponent display={!canEnableMeet || meetFireside}>
										<div className="select-option" onClick={() => setSelectedType('native')}>
											<NativeFiresides />
										</div>
									</OptionalComponent>
								</>
							</Case>
							<DefaultCase>
								<div className="select-option">
									<label>No options</label>
								</div>
							</DefaultCase>
						</SwitchCase>
					</div>
				</div>

				{renderVideoSelect}
			</div>
		</Modal>
	);
};

function AddVideo() {
	return (
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.ADD} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>Add Video</label>
				<span>Add video from content gallery or upload .mp4 files up to 5 GB</span>
			</div>
		</div>
	);
}

function LinkVideo() {
	return (
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.PAPERCLIP} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>Link video</label>
				<span>Use YouTube, Vimeo, Wistia, Mixcloud, Dailymotion, or Twitch link</span>
			</div>
		</div>
	);
}

const EmbedIframe = () => (
	<>
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.CODE} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>Embedded Iframe</label>
				<span>Copy & paste the code of embed HTML code</span>
			</div>
		</div>
	</>
);

function GreenroomStreaming() {
	return (
		<div className="video-selected-type">
			<div className="icon-greenroom">
				<img src={images.GreenroomLogo} alt="greenroom" />

			</div>
			<div className="video-selected-info">
				<label>Greenroom Streaming</label>
				<span>Stream directly from Greenroom</span>
			</div>
		</div>
	);
}

function RTMPStreaming() {
	return (
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.CAMERA_ON} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>RTMP streaming</label>
				<span>Stream from the software of your choice</span>
			</div>
		</div>
	);
}

function MeetFiresides() {
	return (
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.CAMERA_ON} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>Google Meet</label>
				<span>Use Google Meet as your video delivery for your session</span>
			</div>
		</div>
	);
}

function NativeFiresides() {
	return (
		<div className="video-selected-type">
			<div className="icon-gradient">
				<Icon name={ICONS.CAMERA_ON} size={20} color={COLORS.BLACK} />
			</div>

			<div className="video-selected-info">
				<label>Native Firesides</label>
				<span>Use Brandlive's fireside stage as your video delivery for your session</span>
			</div>
		</div>
	);
}

export default VideoModal;

function getVideoSource(video: HomepageVideo | IVSRecording | CreateVideo) {
	// playback_url gets saved as the sessions.on_demand_video.source if selected
	if (isIvsRecording(video)) return video.playback_url;
	return video.source;
}


function isIvsRecording(video: HomepageVideo | IVSRecording | CreateVideo): video is IVSRecording {
	// recording_duration_ms exists only on IVSRecording type
	return (video as IVSRecording)?.recording_duration_ms !== undefined;
}
