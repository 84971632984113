import { useEffect, useRef } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { BrandliveEvent } from '../../../types/working-model';
import { buildTrackingPayload, sendTrackingPayload, TrackingPayloadEventTypes } from '../../../utils/custom-event-tracking';
import usePrevious from '../../../utils/use-previous';
import { reverseObject } from '../../../utils/utils';
import useRouteMap from './use-route-map';

const shouldLogEvents = !!localStorage.getItem('DEBUG_PAGELOAD_EVENTS');

export default function useLocationEvents(debug?: boolean) {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const hasSubmittedRegistrationForm = useTypedSelector(state => state.LiveEventReducer.hasSubmittedRegistrationForm);
	const finishedRegistering = useTypedSelector(state => state.LiveEventReducer.finishedRegistering);
	const location = useLocation();
	const RouteMap = useRouteMap({ debug });
	const lastLocation = usePrevious(location.pathname);
	const regPath = useRef<string>('');
	const hasSentRegPayload = useRef(false);

	useEffect(() => {
		const listener = (e: CustomEvent) => {
			console.log('Received:\n\n', JSON.stringify(e.detail, undefined, 2));
		};

		if (shouldLogEvents) {
			document.addEventListener(TrackingPayloadEventTypes.pageLoad, listener as EventListener);
		}

		return () => {
			document.removeEventListener(TrackingPayloadEventTypes.pageLoad, listener as EventListener);
		};
	}, [debug]);

	useEffect(() => {
		if (!eventBundle) return;
		if (lastLocation === location.pathname) return;

		const anyMatch = matchPath(location.pathname, Object.values(RouteMap));
		const regMatch = matchPath(location.pathname, [RouteMap.Registration]);

		if (regMatch) {
			regPath.current = regMatch.url;
		}

		const reversedRoutes = reverseObject(RouteMap);
		if (anyMatch) {
			const payload = buildTrackingPayload(
				eventBundle.name,
				reversedRoutes[anyMatch.path],
				location.pathname
			);
			sendTrackingPayload(payload);
		}
	}, [RouteMap, debug, eventBundle, location, lastLocation]);

	useEffect(() => {
		const sane = eventBundle?.name && regPath.current;
		const regFormCompleted = finishedRegistering && hasSubmittedRegistrationForm;

		// not easy to determine if user has just submitted the final registration field and successfully registered
		// here we check if they've finished registering, have hit the button on the form, we know what the registration form
		// path is, and we haven't already sent the notification. 
		if (sane && regFormCompleted && !hasSentRegPayload.current) {
			hasSentRegPayload.current = true;
			const payload = buildTrackingPayload(
				(eventBundle as BrandliveEvent).name,
				'Registration',
				// event actually fires from page redirected to, just force it to be /registration
				regPath.current,
				"Event Registration Complete"
			);
			sendTrackingPayload(payload);
		}
	}, [finishedRegistering, hasSubmittedRegistrationForm, eventBundle]);
}
