import { useCallback, useEffect, useMemo, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { useHistory, useParams } from 'react-router';

import "./session-settings.scss";

import {
	CreateVideo,
	EBroadcastTypes,
	FeatureFlagsEnum,
	HomepageVideo,
	ITranslateLangs,
	LanguagesAbbr,
	Session,
	SessionTypesEnum
} from "../../../../../../types/working-model";
import { copyStringToClipboard, getImageLocation } from "../../../../../../utils/utils";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import VideoModal from "../../videos/video-modal";
import images from '../../../../../../images';
import {
	deleteSecondarySessionVideo,
	loadWorkingSession,
	setCurrentLanguage,
	setSessionLoading
} from "../../../../../../store/actions/admin/create-event/session";
// TEMP comment out. This is supposed to be for breakout chat background
// import BreakoutRoomBackground from "./breakout-room-background";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { getChannelRecordings, getChannelUpVideos } from "../../../../../../store/actions/admin/videos";
import TranslateLangs from '../../../../../../utils/translate-langs.json';
import Tags from "./tags";
import { GetSessionRtmp, GetSingleSession } from "../../../../../../connection/sessions";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";
import { availableLanguages, LanguageOption } from '../../editor/agenda-editor/select-languages';
import SelectInput, { SelectOption } from '../../../../../general-ui/select/select';
import { switchingLanguageAlert } from '../../editor/header/header';
import useTranslationsV2 from "../../../shared/use-translations-v2";
import OpenGRButton from "../../../../../general-ui/open-greenroom-btn/open-greenroom-btn";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import { setGreenroomLoginError } from "../../../../../../store/actions/authentication";

import './session-settings.scss';
import { useGetRecordingJWT, useGetRecordingUrl } from "hooks/recording-jwt.hooks";
import { OptionalComponent } from "utils/optional-component";
import { isHomepageVideo } from "utils/is-homepage-video";
import { getMungedSecondaryVideo } from "../get-munged-secondary-video";
import { useCanEnableMeetFiresides } from "hooks/channel.hooks";



const DurationLabel = ({ duration, recording_duration_ms }: { duration?: number, recording_duration_ms?: number }) => {
	if (duration) {
		return <time className="duration">{new Date(duration * 1000).toISOString().substr(11, 8)}</time>;
	} else if (recording_duration_ms) {
		return <time className="duration">{new Date(recording_duration_ms).toISOString().substr(11, 8)}</time>;
	} else {
		return null;
	}
};

function PanelBlockStreaming(): JSX.Element {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const videos = useTypedSelector(state => state.VideosReducer.videos);
	const recordedVideos = useTypedSelector(state => state.VideosReducer.recordedVideos);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const greenroomLoginError = useTypedSelector(state => state.AuthReducer.greenroomLoginError);
	const secondaryVideo = useTypedSelector(state => state.CreateSessionReducer.secondarySessionVideo);
	const currentSessionLang = useTypedSelector(state => state.CreateSessionReducer.workingSession?.current_language);
	const greenroomProducerUrlFlagEnabled = useTypedSelector(state => !!state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.greenroom_producer_url]);

	const [modalOpen, setModalOpen] = useState(false);

	const [mungedSecondaryVideo, setMungedSecondaryVideo] = useState<HomepageVideo | CreateVideo | undefined>(undefined);
	const [secondaryVideoModalOpen, setSecondaryVideoModalOpen] = useState(false);
	const [fetchedChannelUpVideos, setFetchedChannelUpVideos] = useState(false);
	const [fetchedChannelRecordings, setFetchedChannelRecordings] = useState(false);
	const [viewRtmps, setViewRtmps] = useState(false);
	const [rtmpsAddress, setRtmpsAddress] = useState('');
	const [streamKey, setStreamKey] = useState('');
	const [languageUsedForRTMP, setLanguageUsedForRTMP] = useState<LanguagesAbbr | null>(null);
	const [loadingRtmps, setLoadingRtmps] = useState(false);
	const [copiedText, setCopiedText] = useState('');
	const { language }: { language: LanguagesAbbr } = useParams();
	const useV2 = useTranslationsV2();
	const recordingJWT = useGetRecordingJWT(); // For thumbnail
	const getRecordingUrl = useGetRecordingUrl();
	const canEnableMeet = useCanEnableMeetFiresides();

	const meetFireside = workingSession?.fireside_session_settings?.settings?.use_google_meet;
	const [languages] = useState<{ [x: string]: string }>(TranslateLangs.reduce((pre: ITranslateLangs, cur) => {
		pre[cur.code] = cur.label;
		return pre;
	}, {}));

	useEffect(() => {
		if (copiedText) setTimeout(() => setCopiedText(""), 2000);
	}, [copiedText]);

	const dispatch = useDispatch();
	const params = useParams<Record<string, string>>();
	const history = useHistory();
	const editVideo = () => {
		setModalOpen(true);
	};

	const languageOptions: LanguageOption[] = useMemo(() => {
		return availableLanguages([workingSession as Session], SessionTypesEnum.onDemand);
	}, [workingSession]);

	// We should always have a default language set on the session, but if for some reason we don't, set the first
	const [currentInputLang, setCurrentInputLang] = useState<string>(languageOptions?.[0]?.label ?? workingSession?.languages[0]);

	useEffect(() => {
		if (!user || !token) return;

		if (!videos.length && !fetchedChannelUpVideos && user && token) {
			setFetchedChannelUpVideos(true);
			dispatch(getChannelUpVideos(token, user.active_channel));
		}

		const uploaded = videos.find(video => (
			video.source === secondaryVideo?.playback_url || video.hls_link === secondaryVideo?.playback_url
		));

		if (!recordedVideos.length && !fetchedChannelRecordings && token && user) {
			setFetchedChannelRecordings(true);
			dispatch(getChannelRecordings(token, user.active_channel));
		}

		const mungedVideo = getMungedSecondaryVideo(recordedVideos, secondaryVideo, uploaded);
		setMungedSecondaryVideo(mungedVideo);
	}, [
		videos,
		recordedVideos,
		secondaryVideo,
		user,
		dispatch,
		token,
		fetchedChannelUpVideos,
		fetchedChannelRecordings,
	]);

	useEffect(() => {
		if (greenroomLoginError && workingSession?.broadcast_type === EBroadcastTypes.greenroom) {
			showAlert({
				message: "Login Failed",
				description: greenroomLoginError,
				type: "error",
				afterClose: () => {
					dispatch(setGreenroomLoginError(false));
				}
			});
		}
	}, [dispatch, greenroomLoginError, workingSession?.broadcast_type]);

	const revealRtmps = useCallback(async () => {
		if (workingSession && token) {
			setViewRtmps(true);
			setLoadingRtmps(true);
			const {
				rtmps_address,
				stream_key
			} = await GetSessionRtmp(
				token,
				workingSession.uuid,
				workingSession.streaming_options.single_stream ? workingSession.default_language : language
			);

			setLanguageUsedForRTMP(workingSession.streaming_options.single_stream ? workingSession.default_language as LanguagesAbbr : language);
			setRtmpsAddress(rtmps_address);
			setStreamKey(stream_key);
			setLoadingRtmps(false);
		}
	}, [language, token, workingSession]);


	const copy = useCallback((str: string) => {
		return () => {
			setCopiedText(str);
			copyStringToClipboard(str);
		};
	}, []);

	const getCopyButton = useCallback((text: string) => (
		<button className="no-style no-padding read-only-exception" onClick={copy(text)}>
			{copiedText === text
				? <>Copied!</>
				: <><Icon name={ICONS.COPY_FILLED} size={16} color={COLORS.WHITE} /></>
			}
		</button>
	), [copiedText, copy]);

	const handleLangInputChange = useCallback(async (selectedCode: string) => {
		const filteredSelectedLang = languageOptions.filter(option => option.code === selectedCode)[0];
		setCurrentInputLang(filteredSelectedLang.label);
		if (params.id && params.session && workingSession && token) {
			if (!useV2) {
				dispatch(setSessionLoading(true));
				switchingLanguageAlert();

				const _session = await GetSingleSession(token, workingSession.session);

				if (_session) {
					dispatch(loadWorkingSession(_session));
				}
			}

			history.replace(`/admin/events/create/${params.id}/session/${params.session}/${filteredSelectedLang.code}`);
			batch(() => {
				dispatch(setCurrentLanguage(filteredSelectedLang.code));
				dispatch(setSessionLoading(false));
			});
		}

	}, [dispatch, history, languageOptions, params.id, params.session, token, workingSession, useV2]);

	const renderLangSelectDropdown = useCallback(() => {
		if (workingSession &&
			workingSession.session_type === SessionTypesEnum.onDemand &&
			!workingSession.streaming_options?.single_stream &&
			workingSession.languages.length > 1) {
			return (
				<div className='session-language-select'>
					<label className='session-language-label'>Session language</label>
					<SelectInput
						options={languageOptions as SelectOption[]}
						selected={params.language}
						onChange={handleLangInputChange}
						small
						placeholder={languageOptions.filter(lang => lang.code === params.language)?.[0].label}
						keyType={'code'}
						mainIcon={
							<Icon
								name={ICONS.KEYBOARD_ARROW_DOWN}
								size={14}
								color="white"
							/>
						}
					/>
				</div>
			);
		} else return;
	}, [handleLangInputChange, languageOptions, params.language, workingSession]);

	const renderAddedVideoTag = useCallback((video: HomepageVideo | CreateVideo) => {
		switch (video.type) {
			case "recorded":
			case "uploaded": {
				return (
					<>
						<div className="settings-type" style={{ marginTop: 16 }}>
							<div className="icon-gradient">
								<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
							</div>

							<label>Added video</label>
						</div>
						{renderLangSelectDropdown()}
						<div className="video-display">
							{video.image ? (
								<img src={getRecordingUrl(video.image, recordingJWT)} alt={video.name} />
							) : (
								<div className="video-placeholder" />
							)}
							<div className="video-data">
								<label>{video.name}</label>
								<DurationLabel
									duration={'duration' in video && video.duration ? video.duration : undefined}
									recording_duration_ms={'recording_duration_ms' in video && video.recording_duration_ms ? video.recording_duration_ms : undefined}
								/>
							</div>
						</div>
					</>
				);
			}
			case "url": {
				return (
					<>
						<div className="settings-type" style={{ marginTop: 16 }}>
							<div className="icon-gradient">
								<Icon name={ICONS.PAPERCLIP} size={12} color={COLORS.BLACK} />
							</div>

							<label>Linked video</label>
						</div>

						<div className="settings-section">
							<h6>Link</h6>
							<div className="video-link">
								<p>{video.source}</p>

								<button onClick={copy(video.source ?? '')}><Icon name={ICONS.COPY} size={12} color={COLORS.LIGHT_GRAY} /></button>
							</div>
							{renderLangSelectDropdown()}
						</div>
					</>
				);
			}
			case "embed": {
				return (
					<>
						<div className="settings-type" style={{ marginTop: 16 }}>
							<div className="icon-gradient">
								<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
							</div>

							<label>Embedded iframe</label>
						</div>
						<div className="settings-section">
							<h6>Code</h6>
							<div className="video-link">
								<p>{video.source}</p>

								<button onClick={copy(video.source ?? '')}><Icon name={ICONS.COPY} size={12} color={COLORS.LIGHT_GRAY} /></button>
							</div>
							{renderLangSelectDropdown()}
						</div>
					</>
				);
			}
			default: {
				return (
					<>
						<div className="settings-type" style={{ marginTop: 16 }}>
							<div className="icon-gradient">
								<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
							</div>

							<label>Add video</label>
							{renderLangSelectDropdown()}
						</div>
					</>
				);
			}
		}
	}, [renderLangSelectDropdown, copy, recordingJWT]);

	const renderVideoType = useMemo(() => {
		if (workingSession?.session_type === SessionTypesEnum.onDemand) {
			//on demand sessions can have several types or not be defined yet

			if (workingSession?.on_demand_video && Array.isArray(workingSession.on_demand_video) && workingSession?.on_demand_video.length > 0 && workingSession.on_demand_video[0] !== null) {
				const video =
					workingSession?.on_demand_video.find(video => video?.session_language === workingSession?.current_language) ?? workingSession?.on_demand_video?.[0];
				if (video) return renderAddedVideoTag(video);
			} else {
				return (
					<>
						<div className="settings-type" style={{ marginTop: 16 }}>
							<div className="icon-gradient">
								<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
							</div>

							<label>Add video</label>
						</div>
						{workingSession.session_type === SessionTypesEnum.onDemand &&
							!workingSession.streaming_options?.single_stream &&
							workingSession.languages.length > 1 &&
							<div className='session-language-select'>
								<label className='session-language-label'>Session language</label>

								<SelectInput
									options={languageOptions as SelectOption[]}
									selected={currentInputLang}
									onChange={handleLangInputChange}
									small
									placeholder={currentInputLang}
									mainIcon={
										<Icon
											name={ICONS.KEYBOARD_ARROW_DOWN}
											size={14}
											color="white"
										/>
									}
								/>
							</div>
						}
					</>
				);
			}
		} else {
			switch (workingSession?.broadcast_type) {
				case EBroadcastTypes.greenroom: {
					return (
						<>
							<div className="settings-type" style={{ marginTop: 16 }}>
								<div className="icon-greenroom">
									<img src={images.GreenroomLogo} alt="greenroom" />
								</div>

								<label>Greenroom Streaming</label>
							</div>
						</>
					);
				}
				case EBroadcastTypes.fireside: {
					return (
						<>
							<div className="settings-type" style={{ marginTop: 16 }}>
								<div className="icon-gradient">
									<Icon name={ICONS.CAMERA_ON} size={12} color={COLORS.BLACK} />
								</div>

								<label>{meetFireside ? "Google Meet" : "Native Fireside Video"}</label>
							</div>
						</>
					);
				}
				case EBroadcastTypes.rtmps: {
					return (
						<>
							<div className="settings-type" style={{ marginTop: 16 }}>
								<div className="icon-gradient">
									<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
								</div>

								<label>RTMP streaming</label>
							</div>
						</>
					);
				}

				case "embed": {
					let video;
					if (workingSession?.on_demand_video !== null && typeof workingSession?.on_demand_video === 'object' &&
						!Array.isArray(workingSession?.on_demand_video)) {
						video = workingSession?.on_demand_video;
					} else {
						video = workingSession?.on_demand_video?.find(video => video.session_language === currentSessionLang) ??
							workingSession?.on_demand_video?.[0];
					}
					return (
						<>
							<div className="settings-type" style={{ marginTop: 16 }}>
								<div className="icon-gradient">
									<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
								</div>

								<label>Embedded iframe</label>
							</div>
							<div className="settings-section">
								<h6>Code</h6>
								<div className="video-link">
									<p>{video?.source}</p>

									<button onClick={copy(video?.source ?? '')}><Icon name={ICONS.COPY} size={12} color={COLORS.LIGHT_GRAY} /></button>
								</div>
							</div>
						</>
					);
				}
			}

			return (
				<>
					<div className="settings-type" style={{ marginTop: 16 }}>
						<div className="icon-gradient">
							<Icon name={ICONS.ADD} size={12} color={COLORS.BLACK} />
						</div>

						<label>Events streaming</label>
					</div>
				</>
			);
		}
	}, [workingSession, renderAddedVideoTag, languageOptions, handleLangInputChange, currentInputLang, copy, currentSessionLang, meetFireside]);

	const handleRemoveSecondaryVideo = () => {
		if (!workingSession || !workingEvent || !token) return;
		dispatch(deleteSecondarySessionVideo(workingSession.session, token));
	};

	const openSecondaryVideoModal = () => {
		//set video modal open with the secondary video boolean set to true
		setSecondaryVideoModalOpen(true);
		setModalOpen(true);
	};


	const closeVideoModal = useCallback(() => {
		setModalOpen(false);
		setSecondaryVideoModalOpen(false);
	}, []);

	const showSecondarySection = useMemo(() => {
		const allowedType = [SessionTypesEnum.broadcast, SessionTypesEnum.fireside];
		return workingSession?.session_type && allowedType.includes(workingSession.session_type);
	}, [workingSession?.session_type]);

	useEffect(() => {
		if (viewRtmps && languageUsedForRTMP !== language && !workingSession?.streaming_options.single_stream) revealRtmps();
	}, [language, languageUsedForRTMP, revealRtmps, viewRtmps, workingSession]);


	if (!workingSession) return <></>;
	return (
		<>
			<div className="panel-block settings-block">
				<div className="settings-block-header">
					<h5>Streaming</h5>
					{(workingSession.session_type !== SessionTypesEnum.fireside || canEnableMeet) && (
						<button onClick={editVideo}><Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} /></button>
					)}
				</div>
				{renderVideoType}
				{workingSession?.session_type === SessionTypesEnum.broadcast &&
					<>
						{workingSession.broadcast_type === EBroadcastTypes.rtmps ?
							(
								viewRtmps ? (
									<>
										{loadingRtmps ? (
											<WaitingIndicator />
										) : (
											<>
												<div className="rtmp-data">
													{workingSession.streaming_options.single_stream ? (
														<>
															<div className="rtmp-label">{`Session language${workingSession.languages.length > 1 ? 's' : ''}`}</div>
															<Tags heading="" data={workingSession.languages.map(v => languages[v])} threshold={3} />
														</>
													) : (
														<>
															<div className="rtmp-label">{`Session language`}</div>
															<Tags heading="" data={[languages[language]]} threshold={1} />
														</>
													)}
													<div className={'rtmp-label'}>RTMP address</div>
													<div className="rtmp-value-row">
														<div className="rtmp-value">{rtmpsAddress}</div>
														<div>{getCopyButton(rtmpsAddress)}</div>
													</div>
												</div>
												<div className="rtmp-data">
													<div className={'rtmp-label'}>RTMP stream key</div>
													<div className="rtmp-value-row">
														<div className="rtmp-value">{streamKey}</div>
														<div>{getCopyButton(streamKey)}</div>
													</div>
												</div>
											</>
										)}
									</>
								) : (
									<div className="show-rtmp-button">
										<button onClick={revealRtmps} className="read-only-exception">Show RTMP info</button>
									</div>
								)
							) : (<></>)}
					</>
				}
				{showSecondarySection && <>
					{mungedSecondaryVideo && renderAddedVideoTag(mungedSecondaryVideo)}

					<div className="secondary-video-buttons">
						<OptionalComponent display={workingSession.session_type === SessionTypesEnum.broadcast && greenroomProducerUrlFlagEnabled}>
							<div className="open-gr-btn-wrapper">
								<OpenGRButton session={workingSession} />
							</div>
						</OptionalComponent>

						<button
							className="small"
							onClick={openSecondaryVideoModal}
						>
							{secondaryVideo ? "Edit placeholder video" : "Add placeholder video"}
						</button>

						<OptionalComponent display={!!mungedSecondaryVideo}>
							<button disabled={!secondaryVideo} className="small" onClick={handleRemoveSecondaryVideo}>Remove placeholder video</button>
						</OptionalComponent>
					</div>
				</>
				}
			</div>

			<VideoModal
				open={modalOpen}
				onClose={closeVideoModal}
				isSecondaryVideo={secondaryVideoModalOpen}
				workingSecondaryVideo={mungedSecondaryVideo}
			/>
		</>
	);
}


export default PanelBlockStreaming;

