import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch, useDispatch } from "react-redux";

import "./session-settings.scss";

import { updateSessionInfo } from "../../../../../../store/actions/admin/create-event";
import {
	ISessionTypesFeaturesItems,
	ITranslateLangs,
	LanguagesAbbr,
	Session,
	SessionTypesEnum,
	SessionTypesFeatures
} from "../../../../../../types/working-model";
import Icon, { COLORS } from "../../../../../general-ui/icon";
import EditSessionModal from "../../../session/edit-session-modal";
import {
	updateWorkingSession} from "../../../../../../store/actions/admin/create-event/session";
// TEMP comment out. This is supposed to be for breakout chat background
// import BreakoutRoomBackground from "./breakout-room-background";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import TranslateLangs from '../../../../../../utils/translate-langs.json';
import Tags from "./tags";

function dateTimeRange(start: number, end: number) {
	const startDate = new Date(start);
	const endDate = new Date(end);

	return `${startDate.toLocaleDateString()}, ${startDate.toLocaleTimeString()}-${endDate.toLocaleTimeString()}`;
}

const PanelBlockStreaming: React.FC = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);

	if(!workingSession) return null;

	const [isEditing, setIsEditing] = useState(false);


	const [copiedText, setCopiedText] = useState("");

	const [languages] = useState<{ [x: string]: string }>(TranslateLangs.reduce((pre: ITranslateLangs, cur) => {
		pre[cur.code] = cur.label;
		return pre;
	}, {}));

	useEffect(() => {
		if (copiedText) setTimeout(() => setCopiedText(""), 2000);
	}, [copiedText]);

	const dispatch = useDispatch();

	const baseFeatures = (sessionFeatures: ISessionTypesFeaturesItems) => {
		return (
			<div className="settings-section">
				<h6>Session type</h6>
				<div className="settings-type">
					<div className="icon-gradient">
						<Icon name={sessionFeatures.icon_name} size={12} color={COLORS.BLACK} />
					</div>
					<label>{sessionFeatures.display_name}</label>
				</div>
			</div>
		);
	};


	const renderSessionType = useMemo(() => {
		if (workingSession) {
			const sessionFeatures = SessionTypesFeatures?.[workingSession?.session_type];
			if (!sessionFeatures) return null;
			if (sessionFeatures.has_start_time) {
				return (
					<>
						{baseFeatures(sessionFeatures)}
						<div className="settings-section">
							<h6>Date & Time</h6>
							<p>{dateTimeRange(workingSession.timestamp as number, workingSession.end_timestamp as number)}</p>
						</div>
					</>
				);
			} else {
				return baseFeatures(sessionFeatures);
			}
		}
	}, [workingSession]);


	
	const closeEdit = useCallback(() => setIsEditing(false), []);


	const handleEdit = useCallback((session: Session) => {
		if (!token) return;

		batch(() => {
			if (!token) return;
			dispatch(updateSessionInfo(session, token));
			dispatch(updateWorkingSession(session));
		});
		setIsEditing(false);
	}, [dispatch, token]);



	return (
		<>
			
				{renderSessionType}
				{workingSession.tracks && workingSession.tracks.length > 0 && (
					<Tags heading="Tracks" data={workingSession.tracks} threshold={3} />
				)}
				<Tags heading="Languages" data={workingSession.languages.map(v => languages[v])} threshold={3} />

				{workingSession.session_type === SessionTypesEnum.fireside && (
					<Tags heading="Session hosts" data={workingSession.firesides_hosts ? workingSession.firesides_hosts : []} threshold={3} />
				)}
			

			<EditSessionModal
				isOpen={isEditing}
				onClose={closeEdit}
				startingSession={{ ...workingSession }}
				onSave={handleEdit}
				defaultLanguage={workingSession.default_language as LanguagesAbbr}
			/>
		</>
	);

	
};

export default PanelBlockStreaming;
