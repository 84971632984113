import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { Product } from "../../../types/working-model";
import { toMap } from "../../../utils/utils";
import {
	ADD_PRODUCT,
	CHECK_ALL_PRODUCTS,
	CLEAR_ALL_PRODUCTS,
	CLEAR_ALL_TAGS,
	CLEAR_PRODUCTS_ERROR,
	DELETE_CHILD_PRODUCTS,
	DELETE_PRODUCTS,
	LOAD_PRODUCTS,
	PROMOTE_CHILD_PRODUCT,
	TOGGLE_PRODUCT_CHECK,
	TOGGLE_TAG_FILTER,
	UPDATE_MULTIPLE_PRODUCTS,
	UPDATE_PRODUCT,
	UPDATE_EVENTS_FILTER,
	UPDATE_ATTRIBUTES_FILTER,
	UPDATE_PRODUCT_ATTRIBUTES_LIST
} from "../../actions/admin/products";

export interface ProductsState {
	products: Product[];
	loadingProducts: boolean;
	productsError: string;
	errorLoadingProducts: string;
	checkedSet: Set<number>;
	tagSet: Set<string>;
	deletingProduct: boolean;
	promotingProduct: boolean;
	eventsFilter: number[];
	attributesFilter: string[];
	productAttributesList: string[];
}

const initialState: ProductsState = {
	checkedSet: new Set(),
	deletingProduct: false,
	errorLoadingProducts: "",
	loadingProducts: false,
	products: [],
	productsError: "",
	promotingProduct: false,
	tagSet: new Set(),
	eventsFilter: [],
	attributesFilter: [],
	productAttributesList: []
};

export default function ProductsReducer(
	state: ProductsState = initialState,
	action: Action
): ProductsState {
	const { products, checkedSet, tagSet } = state;
	const { payload } = action;
	switch (action.type) {
		case LOAD_PRODUCTS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingProducts: true }),
				failure: state => ({
					...state,
					errorLoadingProducts: "Could not load products.",
					loadingProducts: false
				}),
				success: state => ({
					...state,
					products: action.payload,
					errorLoadingProducts: "",
					loadingProducts: false
				}),
				finish: state => ({ ...state, loadingProducts: false })
			});
		}
		case ADD_PRODUCT: {
			return { ...state, products: [payload, ...products] };
		}
		case UPDATE_PRODUCT: {
			return {
				...state,
				products: state.products.map((product: Product) => {
					if (product.product === payload.product) {
						return payload;
					}

					return product;
				})
			};
		}
		case UPDATE_MULTIPLE_PRODUCTS: {
			const prodMap = toMap<Product>('product', action.payload);
			return {
				...state,
				products: state.products.map(prod => {
					const replaced = prodMap.get(prod.product);
					if (replaced) {
						return replaced;
					} else {
						return prod;
					}
				})
			};
		}
		case TOGGLE_PRODUCT_CHECK: {
			//If id exists in the Set, delete. Otherwise add that id.
			const copySet = new Set(checkedSet);
			copySet.has(payload) ? copySet.delete(payload) : copySet.add(payload);
			return {
				...state,
				checkedSet: copySet
			};
		}
		case CHECK_ALL_PRODUCTS: {
			const allChecked = new Set<number>();
			payload.forEach((id: number) => {
				allChecked.add(id);
			});
			return {
				...state,
				checkedSet: allChecked
			};
		}
		case CLEAR_ALL_PRODUCTS: {
			return {
				...state,
				checkedSet: new Set()
			};
		}
		case TOGGLE_TAG_FILTER: {
			const copySet = new Set(tagSet);
			copySet.has(payload) ? copySet.delete(payload) : copySet.add(payload);
			return {
				...state,
				tagSet: copySet,
				checkedSet: new Set()
			};
		}
		case CLEAR_ALL_TAGS: {
			return {
				...state,
				tagSet: new Set(),
				checkedSet: new Set()
			};
		}
		case DELETE_PRODUCTS: {
			return handle(state, action, {
				success: state => {
					const {
						cannotDelete,
						deletedProducts
					}: {
						cannotDelete: number[];
						deletedProducts: number[];
					} = payload;

					let updatedProducts = [...products];
					const copySet = new Set(checkedSet);

					if (deletedProducts?.length) {
						deletedProducts.forEach(doc => copySet.delete(doc));

						if (deletedProducts?.length === 1) {
							const docIndex = updatedProducts.findIndex(
								prod => prod.product === deletedProducts[0]
							);
							if (docIndex >= 0) updatedProducts.splice(docIndex, 1);
						} else {
							updatedProducts = updatedProducts.filter(prod => {
								return !deletedProducts.includes(prod.product!);
							});
						}
					}

					const errorMessage = cannotDelete?.length
						? updatedProducts
							.filter(product => cannotDelete.includes(product.product!))
							.map(product => product.product_title.base)
							.join(", ")
						: "";

					return {
						...state,
						products: updatedProducts,
						productsError: errorMessage,
						checkedSet: copySet
					};
				}
			});
		}
		case DELETE_CHILD_PRODUCTS: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						deletingProduct: true
					};
				},
				success: state => {
					return {
						...state,
						products: state.products.filter((product) => {
							return !action.payload.products.some((p: Product) => p.product === product.product);
						})
					};
				},
				finish: state => {
					return {
						...state,
						deletingProduct: false
					};
				}
			});
		}
		case PROMOTE_CHILD_PRODUCT: {
			return handle(state, action, {
				start: state => { return { ...state, promotingProduct: true }; },
				success: state => {
					return {
						...state,
						products: [...action.payload.products, ...state.products]
					};
				},
				finish: state => { return { ...state, promotingProduct: false }; },
			});
		}
		case CLEAR_PRODUCTS_ERROR: {
			return {
				...state,
				productsError: ""
			};
		}
		case UPDATE_EVENTS_FILTER: {
			return {
				...state,
				eventsFilter: action.payload
			};
		}
		case UPDATE_ATTRIBUTES_FILTER: {
			return {
				...state,
				attributesFilter: action.payload
			};
		}
		case UPDATE_PRODUCT_ATTRIBUTES_LIST: {
			return {
				...state,
				productAttributesList: action.payload
			};
		}
		default:
			return state;
	}
}
