import { useEffect, useState } from "react";
import { isObject } from "underscore";

const useOrientation = () => {

	const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');

	useEffect(() => {
		const portrait = window.matchMedia('(orientation: portrait)');

		const handleOrientationChange = (e: unknown) => {
			if (!isObject(e)) return;
			if (e?.matches) {
				// Portrait mode
				setOrientation('portrait');
			} else {
				// Landscape
				setOrientation('landscape');
			}
		};

		// initialize
		handleOrientationChange(portrait);

		// listen for changes
		portrait.addEventListener('change', handleOrientationChange);

		return () => {
			portrait?.removeEventListener?.('change', handleOrientationChange);
		};
	}, []);

	return orientation;
};

export default useOrientation;
