import { QuestionBan } from "types/socket-messages";

export const sortBannedQuestions = (bannedQuestions: QuestionBan[]): [Set<number>, Map<number, number>] => {
	return bannedQuestions.reduce<[Set<number>, Map<number, number>]>((acc, ban) => {
		if (ban.type === 'question') {
			acc[0].add(ban.id);
		} else {
			acc[1].set(ban.question_id, ban.id);
		}
		return acc;
	}, [new Set(), new Map()] as [Set<number>, Map<number, number>]);
};