import { CreateOrSignInAttendeeOidcUserProps, FiresidesOidcAuth, PutMeetCohostSettingsProps } from 'types/firesides-oidc';
import { HvHostMap, PutHvJson, PostHvJson } from './helpers';
import { FetchError } from './helpers';

export const CreateAttendeeOidcUser = ({
	email,
	userCode,
	eventUuid
}: CreateOrSignInAttendeeOidcUserProps) => {
	return PutHvJson({
		path: `/auth/create-attendee-oidc-user/${eventUuid}`,
		service: HvHostMap.authorization,
		data: {
			email,
			userCode
		},
		allowText: true
	});
};

export const SignInAttendeeOidcUser = ({
	email,
	userCode,
	eventUuid
}: CreateOrSignInAttendeeOidcUserProps) => {
	return PostHvJson<FiresidesOidcAuth>({
		path: `/auth/sign-in-attendee-oidc-user/${eventUuid}`,
		service: HvHostMap.authorization,
		data: {
			email,
			userCode
		}
	});
};

export const PutMeetCohostSettings = ({
	email,
	userCode,
	sessionUuid,
	spaceId
}: PutMeetCohostSettingsProps) => {
	return PutHvJson({
		path: `/e3-firesides-meet-cohost/${sessionUuid}`,
		service: HvHostMap.eventData,
		data: {
			email,
			userCode,
			spaceId
		},
	});
};