import RadioGroup from "../../../general-ui/radio-group/radio-group";

interface Props {
	setCreatingNewPasscodeList: React.Dispatch<React.SetStateAction<boolean>>;
	creatingNewPasscodeList: boolean;
}

export function AudienceListRadios({ creatingNewPasscodeList, setCreatingNewPasscodeList }: Props) {
	const options = [
		{
			label: 'Upload a new list',
			value: 'uploading',
		},
		{
			label: 'Connect existing',
			value: 'select',
		},
	];

	return (
		<RadioGroup
			options={options}
			onChange={(value: string | number) => {
				setCreatingNewPasscodeList(value === 'uploading');
			}}
			selected={creatingNewPasscodeList ? 'uploading' : 'select'}
			style={{ margin: 0 }}
		/>
	);
}
