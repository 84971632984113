import { TFunction } from 'react-i18next';

import { IRegistrationQuestionTranslations, RegFieldsEnum, RegistrationQuestion, TranslateString } from '../../../types/working-model';
import { SelectOption } from '../../general-ui/select/select-native';
import { TReactSelectOption } from '../../general-ui/react-select/react-select-styles';

const getOptions = (options: string[]): SelectOption[] => {
	return options.map((o) => ({
		label: o,
		value: o,
	}));
};

export interface IRegistrationFormField {
	field_id: number;
	global: boolean;
	label: string;
	optionMap?: Map<number, string>;
	options?: SelectOption[];
	persistent: boolean;
	placeholder: string | undefined;
	required: boolean;
	translation?: TranslateString;
	type: string;
}

export const parseFields = ({
	questions,
	requiredFields,
	language,
	t,
	eventRegistrationTranslations
}: {
	questions: RegistrationQuestion[];
	requiredFields: number[];
	language: string;
	t: TFunction;
	eventRegistrationTranslations?: IRegistrationQuestionTranslations;
}): [IRegistrationFormField[], Map<string, string>] => {
	const selectFieldMap = new Map<string, string>();

	const data = questions?.map(({
		global,
		name_translation,
		name,
		options_translation,
		options,
		persistent,
		placeholder,
		registration_question,
		type,
	}) => {

		// initialize label and placeholder values with v1 or v2 translations
		let translatedLabel = global ?
			(t(`question.${registration_question}.name`, t(`homepage:registration.registration_fields.${registration_question}`, ''))) as string
			: t(`question.${registration_question}.name`, name_translation?.[language] as string || name);

		// first check for a translation json placeholder, fall back to a user-set string, fall back from there to the translated field from static, fall back from there to name
		let translatedPlaceholder = t(
			`registrations:question.${registration_question}.placeholder`,
			(placeholder?.[language] as string || placeholder?.base) ?? t(`homepage:registration.registration_fields.${registration_question}`, name_translation?.[language] as string || name)
		);

		// check if v3 translations exists, if so, override the label and placeholder values
		if (eventRegistrationTranslations) {
			const dbLabelTranslation = eventRegistrationTranslations[registration_question]?.name?.[language];
			if (dbLabelTranslation && typeof dbLabelTranslation === 'string') {
				translatedLabel = dbLabelTranslation;
			}
			const dbPlaceholderTranslation = eventRegistrationTranslations[registration_question]?.placeholder?.[language];
			if (dbPlaceholderTranslation && typeof dbPlaceholderTranslation === 'string') {
				translatedPlaceholder = dbPlaceholderTranslation;
			}
		}


		const translatedOptions = (): string[] | undefined => {
			if (!options) return;

			// first pass - if it's global first check the user uploaded translations, then fall back to the static translations, then fall back to the option in plain text
			if (global && registration_question !== RegFieldsEnum.state) {
				const translated = options.map((option, index) => {
					let translation = t(
						`registrations:question.${registration_question}.options.${index}`,
						t(`homepage:registration.registration_fields.options.${option.replace(/\./g, "")}`, option)
					);
					// check if v3 available
					const v3Option = eventRegistrationTranslations?.[registration_question]?.options?.[index]?.[language];
					if (v3Option && typeof v3Option === 'string') {
						translation = v3Option;
					}

					selectFieldMap.set(translation, option);
					return translation;
				});
				return registration_question === RegFieldsEnum.country ? translated.sort() : translated;

				// second pass - if the option has been passed in uploaded translations use it, fall back to the translated options map, fall back to the option in plain text
			} else {
				let optsMap: Map<number, TranslateString>;
				if (options_translation) {
					optsMap = new Map(options_translation.map((opt, i) => [i, opt]));
				}

				return options.map((option, index) => {
					let translation = t(`registrations:question.${registration_question}.options.${index}`, optsMap?.get(index)?.[language] ?? option);
					// check if v3 available
					const v3Option = eventRegistrationTranslations?.[registration_question]?.options?.[index]?.[language];
					if (v3Option && typeof v3Option === 'string') {
						translation = v3Option;
					}
					selectFieldMap.set(translation, option);
					return translation;
				});
			}
		};

		const translatedOptionsWithBaseValue = (): TReactSelectOption[] | undefined => {
			if (!options) return;

			const regQn = eventRegistrationTranslations?.[registration_question];

			if (!regQn?.options) {
				return getOptions(translatedOptions() ?? []);
			}

			const mappedOptions = Object.values(regQn.options).map(option => ({
				label: (option[language] ?? option.base) as string,
				value: option.base
			})).filter(option => option.label);

			return registration_question === RegFieldsEnum.country
				? mappedOptions.sort((a, b) => a.label > b.label ? 1 : -1)
				: mappedOptions;
		};

		const result: IRegistrationFormField = {
			field_id: registration_question,
			global,
			label: translatedLabel,
			options: options ? translatedOptionsWithBaseValue() : undefined,
			persistent,
			placeholder: translatedPlaceholder,
			required: requiredFields?.includes(registration_question),
			type,
		};
		return result;
	}) || [];
	return [[...data], selectFieldMap];
};