import { EmailDomainFlag, EmailDomainFlagEntry } from 'types/email-domain-flags';
import { ChannelRedirect } from '../components/admin/settings/channel-redirects';
import { ChannelPermission, Channel, Profile, BLAdmin, PresenterAuthType, AuthConsumer, ReducedUser, ChannelSSOIntegration, ChannelOauthIntegration } from '../types/working-model';
import { Get, Put, Post, Delete, PostHv, HvHostMap, PostJson, GetJson } from './helpers';
import { FetchError } from './helpers';
import { getSignature } from '@admin/authentication/utils';

export const authLogin = async (
	email: string,
	password: string,
	two_factor_stored: string | null,
	authConsumer: AuthConsumer,
	allowNoChannels = false,
	redirect_uri?: string,
	state?: string,
	client_id?: string
): Promise<any> => {
	try {
		const passwordResponse = await PostJson({
			path: '/v3/admin/auth/sign-in',
			token: '',
			data: {
				email,
				password,
				two_factor_stored: (two_factor_stored ? two_factor_stored : ""),
				auth_consumer: authConsumer,
				allow_no_channels: allowNoChannels,
				redirect_uri,
				state,
				client_id
			},
		});
		return passwordResponse;
	} catch (e) {
		if (e instanceof FetchError) {
			if (e.error === 'needs_verification') {
				throw new Error('no password');
			}

			if (e.error === 'reset_required') {
				throw new Error('a password that is old');
			}

			if (e.error === 'no_channels') {
				throw new Error('no channels');
			}

			if (e.error.includes('email_temporarily_locked')) {
				throw new Error(e.error);
			}

			if (e.status === 403) {
				throw new Error('incorrect email/password');
			}
		}

		// Unknown error
		console.error('Error signing in');
		console.error(e);

		throw new Error('Unknown signin error');
	}
};

export const tempPhone = async (phone: string, temp_profile: string): Promise<any> => {
	return Post('/v3/admin/auth/set-temp-phone', '', { temp_profile, phone });
};

export const authCode = async (temp_profile: string, code: string, should_confirm: boolean, request_storage: boolean): Promise<any> => {
	return Post('/v3/admin/auth/confirm-code', '', { temp_profile, code, should_confirm, request_storage }, false, {}, true)
		.then(res => {
			if (res.status === 403) {
				throw new Error('incorrect confirmation code given');
			}

			const response = res.json();

			return response;
		});
};

export enum AuthCodeFailures {
	expired = 'expired',
	locked = 'locked',
	incorrect = 'incorrect',
	error = 'error',
	invalid = ''
}

export type AuthCodeFromEmailRequest = {
	temp_profile: string;
	code: string;
	request_storage: boolean;
}

export type AuthCodeFromEmailFailure = {
	reason: AuthCodeFailures;
	remaining_attempts?: number;
}

export type AuthCodeFromEmailResponse = {
	channels: Record<string, unknown>[];
	storage_token: string | null;
	channels_using_sso: number[];
}

export const AuthCodeFromEmail = async (body: AuthCodeFromEmailRequest): Promise<AuthCodeFromEmailResponse | AuthCodeFromEmailFailure> => {
	return Post(`/v3/admin/auth/auth-code`, '', body);
};

export interface IRedirectOptions {
	redirect?: string;
	noRedirect?: string;
	authConsumer?: string;
	oauthSSOToken?: string;
	oauthFlowTokenForRedirect?: string;
}

export const ForgotPassword = async (email: string, hostname: string, redirectOptions?: IRedirectOptions): Promise<any> => {
	return Post('/v3/admin/auth/forgot-password', '', { email, hostname, redirectOptions }, false, {}, true)
		.then(res => {
			if (res.ok) {
				return res.ok;
			}

			throw new Error('Forgot Password Failure');
		});
};

export const UpdatePassword = async (password: string, code: string): Promise<any> => {
	return Put('/v3/admin/auth/forgot-password', '', { password, code }, false, true)
		.then(async res => {
			if (res.ok) {
				return res.json();
			}

			throw new Error('Password Reset Failure');
		});
};

export const RefreshToken = async (token: string): Promise<any> => {
	return Get('/v3/admin/auth/refresh-token', token, {}, true)
		.then(res => {
			if (res.ok) {
				return res.json();
			}

			throw new Error('Unable To Refresh Token');
		});
};

export function GetChannels(token: string): Promise<Channel[]> {
	return GetJson({ path: '/v3/admin/auth/channels', token });
}

export function SelectChannel(token: string, channel: number): Promise<{ token: string }> {
	return Post('/v3/admin/auth/channels', token, { channel });
}

export function SelectChannelLogin(stored_token: string, channel: number): Promise<void> {
	return Post('/v3/admin/auth/channels-login', '', { channel }, true, { 'Authorization': `Bearer ${stored_token}` });
}

export function GetAdmins(token: string, channel: number, includeLockedEmails = false): Promise<(BLAdmin & { flags: EmailDomainFlag[] })[]> {
	return Get(`/v3/admin/auth/admins?onlyChannel=${channel}${includeLockedEmails ? '&option=includeLockedEmails' : ''}`, token);
}

export function UnlockEmail(token: string, email_to_unlock: string) {
	return Put('/v3/admin/auth/unlock-profile', token, { email_to_unlock }, true);
}

export function UpdateUserChannelSettings(token: string, blAdminID: number, channel: number, permissions: string[], ssoLogin: boolean): Promise<any> {
	return Put(`/v3/admin/auth/update-user-channel-settings`, token, { blAdminID, channel, permissions, ssoLogin }, true);
}

export function UpdateChannel(token: string, channel: Channel): Promise<Channel> {
	return Put(`/v3/admin/auth/update-channel`, token, { channel }, true);
}

export const UpdateOnboarding = async (token: string, id: number, onboarding: string[], newItem: string): Promise<any> => {
	onboarding.push(newItem);

	return Put('/v3/admin/auth/update-onboarding', token, { id, onboarding }, false, true)
		.then(res => {
			if (res.ok) {
				return res.json();
			}

			throw new Error('Unable To Get Response');
		});
};

export function ResetOnboarding(token: string, id: number): Promise<any> {
	return Put(`/v3/admin/auth/clear-onboarding`, token, { id });
}

export function AddNewAdmin(token: string, email: string, permissions: string[], isSSO: boolean): Promise<any> {
	return Post(`/v3/admin/auth/add-admin`, token, { email, permissions, isSSO });
}

export function RemoveUserFromChannel(token: string, blAdminID: number, channel: number): Promise<any> {
	return Post(`/v3/admin/auth/remove-user-from-channel`, token, { blAdminID, channel });
}

export const SetAdminPassword = async (password: string, hashCode: string, cb: () => void): Promise<any> => {
	return Post('/v3/admin/auth/set-password', '', { hashCode, password }, false, {}, true)
		.then(res => {
			if (res.ok) {
				cb();

				return res.ok;
			}

			throw new Error('Password Set Failure');
		});
};

export const UpdateAdminProfile = async (token: string, profile: Profile): Promise<any> => {
	return Put('/v3/admin/auth/update-profile', token, { profile });
};

export function GetChannelRedirects(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/channel/redirects/${channel}`, token);
}

export function CreateChannelRedirect(token: string, redirect: Omit<ChannelRedirect, 'id'>): Promise<any> {
	return Post(`/v3/admin/channel/redirect`, token, redirect);
}

export function UpdateChannelRedirect(token: string, redirect: ChannelRedirect): Promise<any> {
	return Put(`/v3/admin/channel/redirect`, token, redirect, true);
}

export function DeleteChannelRedirect(token: string, redirect: ChannelRedirect): Promise<any> {
	return Delete(`/v3/admin/channel/redirect`, token, redirect);
}

export function CheckSignatureStatus(token: string, signature: number): Promise<boolean> {
	return Get(`/v3/admin/auth/check-signature-status/${signature}`, token);
}

export function ResendSignatureConfirmation(token: string, id: number): Promise<{ ErrorCode: number, Message: string; }> {
	return Post('/v3/admin/auth/resend-confirm-sender-signature', token, { id });
}

export function AddAdminToGroup(token: string, admin_id: number, group_uuid: string, channel: number): Promise<{ admin_id: number, group_uuid: string }> {
	return Put(`/v3/admin/auth/event-groups`, token, { admin_id, group_uuid, channel }, true);
}

export function AddAdminToGroups(token: string, admin_id: number, group_uuids: string[], channel: number): Promise<{ admin_id: number, group_uuids: string[] }> {
	return Put(`/v3/admin/auth/multiple-event-groups`, token, { admin_id, group_uuids, channel }, true);
}

export function RemoveAdminFromGroup(token: string, admin_id: number, group_uuid: string, channel: number): Promise<{ admin_id: number, group_uuid: string }> {
	return Delete(`/v3/admin/auth/event-groups`, token, { admin_id, group_uuid, channel }, true);
}

export function RemoveAdminFromAllGroups(token: string, admin_id: number, channel: number): Promise<{ admin_id: number, group_uuid: string }> {
	return Delete(`/v3/admin/auth/event-groups/all`, token, { admin_id, channel }, true);
}

export function PreviewEventSignIn(eventPreviewUuid: string, previewPasscode: string): Promise<{ error?: string, authenticated?: string }> {
	return Post(`/v3/admin/events/${eventPreviewUuid}/preview/sign-in`, "", { passcode: previewPasscode });
}

export const AcknowledgeDMA = async (token: string): Promise<{ acknowledgementDate: string }> => {
	return Put('/v3/admin/dma-law-acknowledgement', token, {}, true);
};

export type AdminAuthRequest = {
	auth_code: string;

	oauth2?: boolean;
	channel?: number;
	user_pool_client_id?: string;
	domain?: string;
};

export function SSOAdminAuth(payload: AdminAuthRequest) {
	return PostHv(HvHostMap.authorization, `/auth/admin/sso`, {
		...payload,
		redirect_uri: `${window.location.origin}${window.location.pathname}`
	});
}

export function requestEmailAuthCode(email: string): Promise<{ temp_profile: string }> {
	const params = new URLSearchParams({ email });
	return Get(`/v3/admin/auth/auth-code?${params.toString()}`, '');
}

export function GetChannelsFromStoredSession(stored_token: string) {
	return GetJson<Channel[]>({
		path: `/v3/admin/auth/available-channels`,
		token: '',
		headers: { 'Authorization': `Bearer ${stored_token}` }
	});
}

export function GetAuthTypeForPresenter(email: string, channel?: number): Promise<PresenterAuthType> {
	const route = channel
		? `/v3/auth/service/auth-type/${email}/${channel}`
		: `/v3/auth/service/auth-type/${email}`;
	return Get(route, '');
}

export function ReformatOAuthToken(token: string, targetAuthConsumer: AuthConsumer): Promise<{ token: string }> {
	// Route has to authorize the token with the specified type
	// the reformats and returns re-encoded with the same token
	// No need to authorize
	return Post('/v3/admin/service/reformat-token', '', { token, auth_consumer: targetAuthConsumer });
}

export function RefreshOAuthToken(token: string): Promise<{ token: string }> {
	return Post('/v3/admin/service/refresh-oauth-token', '', { token });
}

export function GetAccountFromStoredSession(stored_token: string): Promise<{ account: ReducedUser }> {
	return Get(`/v3/admin/auth/account-from-token`, '', { 'Authorization': `Bearer ${stored_token}` });
}

export function GetChannelApiKeys(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/auth/channel-keys/${channel}`, token, {});
}

export function DeactivateChannelApiKey(token: string, channel: number, key: string): Promise<any> {
	return Delete(`/v3/admin/auth/channel-keys/${channel}/${key}`, token, {});
}

export function GetProjectAlertEmails(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/auth/project-alert-emails/${channel}`, token, {});
}

export function UpdateProjectAlertEmails(token: string, channel: number, emails: string[]): Promise<any> {
	return Post(`/v3/admin/auth/project-alert-emails/${channel}`, token, { emails });
}

export function GetDomainFlagsForEmails(token: string, emails: string[], scope: string | number) {
	const params = new URLSearchParams({ emails: emails.join(','), scope: String(scope) });
	return GetJson<{ email: string, flags: EmailDomainFlag[] }[]>({
		path: `/v3/admin/auth/email-domain-flags?${params.toString()}`,
		token
	});
}

export type GetLoginMethodResponse = {
	method: 'sso' | 'password',
	config?: ChannelSSOIntegration | ChannelOauthIntegration
};

export async function GetLoginMethod(email: string): Promise<GetLoginMethodResponse> {
	const [hmac, key] = await getSignature();
	const params = new URLSearchParams({
		email
	});
	return GetJson({
		path: `/v3/admin/auth/login-method?${params.toString()}`,
		headers: {
			'bl-sig': hmac,
			'bl-key': key
		},
		token: ''
	});
}

export function GetAccessTokenFromCode(code: string) {
	return GetJson<{ id_token: string, refresh_token: string, access_token?: string }>({
		path: `/authenticate/access-token?code=${code}`,
		token: ''
	});
}

export function GetAccessTokenFromRefreshToken(refresh_token: string) {
	return GetJson<{ id_token: string, access_token?: string }>({
		path: `/authenticate/refresh-token?refresh_token=${refresh_token}`,
		token: ''
	});
}
