import React from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import {
	BlProfile,
	BrandliveEvent,
	RegistrationStep,
	RegistrationStepType,
	TicketingSet,
} from '../../../types/working-model';
import { RegistrationFooterProps } from '../registration/registration-footer';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { ParamsProps } from '../live-event';
import { TicketingContent } from '../registration/steps/ticketing';
import { AvatarContent } from '../registration/steps/avatar';
import { GeneralContent } from '../registration/steps/generalContent';
import ErrorBoundary from '../../../utils/error-boundary';
import { IRegistrationGDPR } from '../../../connection/registration';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { parseFields } from './parse-fields';
import { IPasswordGatingProps } from './registration-v2';

import './registration-panels.scss';
import '../../../scss/live-event/base/general-ui/input.scss';

const stepsMap = ["one", "two", "three", "four", "five"];
interface RegistrationPanelsProps {
	activeStep: RegistrationStep;
	channel: number;
	defaultValues: SubmissionValues;
	eventBundle?: BrandliveEvent | null;
	footerProps: RegistrationFooterProps;
	handleSignIn?: (e: React.MouseEvent) => void;
	isMarketingOptInChecked: boolean;
	onSaveData: (props: SubmissionValues) => void;
	profile: BlProfile | null;
	registrationStep: number;
	renderRecaptcha?: () => JSX.Element;
	requiredFields: number[];
	selectedTicket?: TicketingSet;
	setIsMarketingOptInChecked: React.Dispatch<React.SetStateAction<boolean>>;
	steps: RegistrationStep[];
	template: string;
	userRegGDPR?: IRegistrationGDPR;
	passwordGatingProps: IPasswordGatingProps
}
const RegistrationPanels = ({
	activeStep,
	channel,
	defaultValues,
	eventBundle,
	footerProps,
	handleSignIn,
	isMarketingOptInChecked,
	onSaveData,
	profile,
	registrationStep,
	renderRecaptcha,
	requiredFields,
	setIsMarketingOptInChecked,
	steps,
	template,
	userRegGDPR,
	passwordGatingProps
}: RegistrationPanelsProps): JSX.Element => {
	const { questions, type } = activeStep;
	const { language } = useParams<ParamsProps>();
	const { t } = useTranslate(['registrations', 'homepage']);

	const liveEventRegTranslations = useTypedSelector(state => state.LiveEventReducer.eventBundle?.registration_settings?.registration_questions_translations);
	const workingEventRegTranslations = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_settings?.registration_questions_translations);
	const regTranslations = liveEventRegTranslations ?? workingEventRegTranslations;

	const logo = eventBundle?.registration_settings?.logo;

	const [fields, selectBaseLanguageFieldMap] = parseFields({
		questions: questions || [],
		requiredFields: requiredFields || [],
		language,
		t,
		eventRegistrationTranslations: regTranslations,
	});

	const ticketingStep = steps.find(step => step.type == RegistrationStepType.ticketing && step.isOn);

	const getStep = (step: RegistrationStep) => {
		switch (step.type) {
			case RegistrationStepType.ticketing:
				return (
					<ErrorBoundary uniqueLabel="Ticketing registration step">
						<TicketingContent
							channel={channel}
							defaultValues={defaultValues}
							fields={fields}
							footerProps={footerProps}
							language={language}
							onSaveData={onSaveData}
							profile={profile}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							template={template}
							ticketing={ticketingStep?.ticketing}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.avatar:
				return (
					<ErrorBoundary uniqueLabel="Avatar registration step">
						<AvatarContent
							channel={channel}
							defaultValues={defaultValues}
							fields={fields}
							footerProps={footerProps}
							language={language}
							onSaveData={onSaveData}
							profile={profile}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							template={template}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.profile:
				return (
					<ErrorBoundary uniqueLabel="Profile registration step">
						<GeneralContent
							channel={channel}
							currentStep={step}
							defaultValues={defaultValues}
							eventBundle={eventBundle}
							fields={fields}
							footerProps={footerProps}
							gdprStatus={userRegGDPR}
							handleSignIn={handleSignIn}
							isMarketingOptInChecked={isMarketingOptInChecked}
							language={language}
							onSaveData={onSaveData}
							profile={profile}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							setIsMarketingOptInChecked={setIsMarketingOptInChecked}
							template={template}
							passwordGatingProps={passwordGatingProps}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.general:
				return (
					<ErrorBoundary uniqueLabel="General registration step">
						<GeneralContent
							channel={channel}
							currentStep={step}
							defaultValues={defaultValues}
							eventBundle={eventBundle}
							fields={fields}
							footerProps={footerProps}
							gdprStatus={userRegGDPR}
							handleSignIn={handleSignIn}
							isMarketingOptInChecked={isMarketingOptInChecked}
							language={language}
							onSaveData={onSaveData}
							profile={profile}
							renderRecaptcha={renderRecaptcha}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							setIsMarketingOptInChecked={setIsMarketingOptInChecked}
							template={template}
							ticketing={ticketingStep?.ticketing}
							passwordGatingProps={passwordGatingProps}
						/>
					</ErrorBoundary>
				);
			default:
				return (<>Not implemented</>);
		}
	};


	return (
		<div className={classNames("registration-slider", stepsMap[registrationStep])}>
			<div className="registration-logo">
				<img
					src={logo}
					alt=""
					aria-hidden="true"
				/>
			</div>
			{steps.map((step, index) => {
				const currentStep = activeStep.type === step.type;

				return (
					<fieldset
						className={classNames("registration-panel-v2", { active: currentStep })}
						key={index}
						disabled={!currentStep}
						tabIndex={currentStep ? 0 : -1}
						aria-hidden={!currentStep}
					>
						{getStep(step)}
					</fieldset>
				);
			})}
		</div>
	);
};

export default RegistrationPanels;

export interface SubmissionValues {
	[key: string]: string | boolean | number | Array<string> | undefined;
}
