import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { BlProfile, IDirectMessage, IGif } from "../../../types/working-model";
import { ADD_DIRECT_MESSAGE, ADD_SENDER_DIRECT_MESSAGE, CLEAR_CHAT_NOTIFICATION, CLEAR_DM_NOTIFICATION, LOAD_STORED_MESSAGES, NEW_BREAKOUT_CHAT_MESSAGE, SET_ACTIVE_DM_ATTENDEE, SHOW_CHAT_NOTIFICATION, SHOW_DM_NOTIFICATION } from "../../actions/event/breakout-chat-actions";

export interface RoomChatMessage {
	roomId: string;
	id?: number;
	user: BlProfile;
	createDate: Date;
	message: {
		text?: string;
		image?: string;
		gif: IGif;
		moderator?: boolean;
	}
}

export interface DirectChat {
	roomId: string;
	id?: number;
	socketId: string;
	groupId: string;
	user: BlProfile;
	messages: RoomChatMessage[];
}

export interface BreakoutChatState {
	roomMessages: RoomChatMessage[];
	// directMessages:
	// the outer key is the opentok id, the inner key is the id of the attendee you are chatting with
	directMessages: { [key: string]: { [key: string]: IDirectMessage[] } };
	active_dm_attendee: number | null; // id of the person you're currently dm'ing.
	showDMNotification: { [key: string]: boolean };
	showChatNotification: { [key: string]: boolean }
}

const initialState: BreakoutChatState = {
	active_dm_attendee: null,
	directMessages: {},
	roomMessages: [],
	showChatNotification: {},
	showDMNotification: {},
};

export default function BreakoutChatReducer(state: BreakoutChatState = initialState, action: Action): BreakoutChatState {
	switch (action.type) {
		case NEW_BREAKOUT_CHAT_MESSAGE: {
			return handle(state, action, {
				success: (state) => ({ ...state, roomMessages: [...state.roomMessages, action.payload] })
			});
		}

		case LOAD_STORED_MESSAGES: {
			return handle(state, action, {
				success: (state) => ({ ...state, roomMessages: action.payload })
			});
		}

		case SET_ACTIVE_DM_ATTENDEE: {
			return {
				...state,
				active_dm_attendee: action.payload,
			};
		}

		case ADD_DIRECT_MESSAGE: {
			const { opentokId, data } = action.payload;
			let updatedDms = { ...state.directMessages };
			if (updatedDms?.[opentokId]) {
				if (updatedDms[opentokId]?.[data.from.bl_profile]) {
					updatedDms[opentokId][data.from.bl_profile] = [...updatedDms[opentokId][data.from.bl_profile], data];
				} else {
					updatedDms[opentokId][data.from.bl_profile] = [data];
				}
			} else {
				updatedDms = {
					...updatedDms,
					[opentokId]: {
						[data.from.bl_profile]: [data],
					},
				};
			}
			return {
				...state,
				directMessages: updatedDms
			};
		}

		case ADD_SENDER_DIRECT_MESSAGE: {
			const { opentokId, data } = action.payload;
			let updatedDms = { ...state.directMessages };
			if (updatedDms?.[opentokId]) {
				if (updatedDms[opentokId]?.[data.to.bl_profile]) {
					updatedDms[opentokId][data.to.bl_profile] = [...updatedDms[opentokId][data.to.bl_profile], data];
				} else {
					updatedDms[opentokId][data.to.bl_profile] = [data];
				}
			} else {
				updatedDms = {
					...updatedDms,
					[opentokId]: {
						[data.to.bl_profile]: [data],
					},
				};
			}
			return {
				...state,
				directMessages: updatedDms,
			};
		}

		case SHOW_DM_NOTIFICATION: {
			return {
				...state,
				showDMNotification: {
					...state.showDMNotification,
					[action.payload.bl_profile]: true,
				},
			};
		}

		case CLEAR_DM_NOTIFICATION: {
			return {
				...state,
				showDMNotification: {
					...state.showDMNotification,
					[action.payload.bl_profile]: false,
				},
			};
		}

		case SHOW_CHAT_NOTIFICATION: {
			return {
				...state,
				showChatNotification: {
					...state.showChatNotification,
					[action.payload]: true
				}
			};
		}

		case CLEAR_CHAT_NOTIFICATION: {
			return {
				...state,
				showChatNotification: {
					...state.showChatNotification,
					[action.payload]: false
				}
			};
		}
		default: return state;
	}
}

