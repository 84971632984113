import { FontPack, CreateFontPack } from 'types/theme-packs';
import { Delete, Get, Put, Post } from './helpers';

export const GetFonts = async (channel: number, token: string): Promise<FontPack[]> => {
	return Get(`/v3/admin/channel/fonts/${channel}`, token, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const GetFontName = async (url: string): Promise<any> => {
	return Put('/v3/admin/channel/fonts/parse', '', { url }, false, true)
		.then(async res => {
			return await res.json();
		});
};

export const CreateFont = async (token: string, createFontParams: CreateFontPack): Promise<FontPack> => {
	return Post('/v3/admin/channel/fonts', token, createFontParams, false, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export interface DeleteFontsReturn {
	cannotDelete: number[];
	deletedFonts: number[];
}
export function DeleteFonts(fontPackIDs: number[], token: string, channel: number): Promise<DeleteFontsReturn> {
	return Delete('/v3/admin/channel/fonts', token, { fontPackIDs, channel }, true);
}
