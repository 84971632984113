import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { Button } from '../../../general-ui/button/button';
import { OptionalComponent } from '../../../../utils/optional-component';
import { checkPasswordGatingPasswordRequirement, useLanguageParam } from 'components/live-event/utils';
import { useAppDispatch, useTypedSelector } from 'store/reducers/use-typed-selector';
import { setRegistrationError, updateRegistrationPassword } from 'store/actions/event/event-actions';
import { getQueryParams } from 'utils/utils';
import { RegFailureReasons } from 'utils/use-get-registration-error-message';
import { Redirects } from 'utils/redirects';

interface ISetNewPassword {
	template: string;
	handleExitSetNewPassword: () => void;
	queryString?: string;
}

export default function SetNewPassword({ template, handleExitSetNewPassword }: ISetNewPassword): JSX.Element {
	const dispatch = useAppDispatch();
	const { t } = useTranslate('homepage');

	const registrationSignInSuccess = useTypedSelector(state => state.LiveEventReducer.registrationSignInSuccess);
	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const mockError = location.search ? new URLSearchParams(location.search).get('mockError') : null;
	const registrationError = mockError || useTypedSelector(state => state.LiveEventReducer.registrationError);
	const language = useLanguageParam();
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const urlParams = getQueryParams(location.search);
	const pwResetCode = urlParams['reset-code'];

	const passwordRequirementsMet = useMemo(() => checkPasswordGatingPasswordRequirement(password), [password]);

	useEffect(() => {
		if (registrationSignInSuccess) {
			location.replace(Redirects.fromRegistration(eventBundle));
		}
	}, [registrationSignInSuccess, eventBundle]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		try {
			if (!passwordRequirementsMet) return;

			if (pwResetCode && eventBundle?.uuid) {
				const path = window.location.href.split("/");
				path.pop();
				const source = path.join("/");

				dispatch(updateRegistrationPassword(
					eventBundle.uuid,
					pwResetCode,
					password,
					source,
					language,
					Intl.DateTimeFormat().resolvedOptions().timeZone,
				));
			} else {
				dispatch(setRegistrationError(RegFailureReasons.invalidPasswordResetCode));
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div className="registration-slider">
			<div className="registration-panel-v2 fixed active">
				<form className="registration-form" onSubmit={handleSubmit}>
					<div className="registration-form-inner verification" onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
						<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", template)} tagName="h2">
							{t("homepage:registration.Set new password", 'Set new password')}
						</TypographyItem>
						<br />
						<div className={classNames('evt-field-wrapper', template)}>
							<TextInput
								key={'reset-password'}
								required
								label={t('homepage:registration.registration_fields.Set password', 'Set password')}
								placeholder={t('homepage:registration.registration_fields.Set password', 'Set password')}
								onChange={e => setPassword(e.target.value)}
								password
								showPasswordIcon
								autoCorrect="off"
								autoCapitalize="off"
							/>
							<OptionalComponent display={!!password && !passwordRequirementsMet}>
								<p className="registration-error" role="alert">{t("homepage:registration.Password Requirement", 'Password must be at least 8 characters long.')}</p>
							</OptionalComponent>
							<TextInput
								key={'reset-confirm-password'}
								required
								label={t('homepage:registration.registration_fields.Confirm password', 'Confirm password')}
								placeholder={t('homepage:registration.registration_fields.Confirm password', 'Confirm password')}
								onChange={e => setPasswordConfirmation(e.target.value)}
								valid={passwordConfirmation.length && password !== passwordConfirmation ? Validation.error : Validation.normal}
								password
								showPasswordIcon
								autoCorrect="off"
								autoCapitalize="off"
							/>
						</div>
					</div>
					<OptionalComponent display={!!registrationError}>
						<p className="registration-error" role="alert">
							{t(`registration.PasswordResetError.${registrationError}`, 'There was an error resetting your password. Please try again.')}
						</p>
					</OptionalComponent>
					<OptionalComponent display={!!(password && passwordConfirmation && (password !== passwordConfirmation))}>
						<p className="registration-error" role="alert">
							{t('homepage:registration.unmatchedPassword', 'Passwords do not match')}
						</p>
					</OptionalComponent>
					<div className="registration-actions secondary">
						<Button
							classButton={classNames('registration-button secondary')}
							template={template}
							onClick={handleExitSetNewPassword}
							typeBtn="button"
						>
							{t("Cancel")}
						</Button>
						<Button
							classButton={classNames('registration-button primary')}
							template={template}
							typeBtn="submit"
							isDisabled={!password || !passwordConfirmation || !passwordRequirementsMet || password !== passwordConfirmation}
						>
							{t("Save")}
						</Button>
					</div>
				</form>
			</div>
		</div>
	);
}
