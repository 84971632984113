import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { RegistrationStepType } from "../../../../../../../types/working-model";

import SortableStepItem from "./sortable-step-item";

type Props = {
	withinTab?: boolean;
}
const PanelAvatar: React.FC<Props> = ({ withinTab }) => {
	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);
	const registrationSteps = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_steps);
	const isOn = registrationSteps?.find(step=> step.type===registrationStep)?.isOn;

	return (
		<div className="registration-options-container-v2">
			{/* This is the toggle card for turning on or off the step */}
			<div className="registration-option-v2 avatar-registration-option-v2">
				<SortableStepItem step={{
					type: RegistrationStepType.avatar,
					isOn: !!isOn,
					}} />
			</div>
		</div>
	);
};

export default PanelAvatar;