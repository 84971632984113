
import { GateTypes } from "types/working-model";
import LargeButton from "../../../../../../general-ui/button/large-button";

interface CardAddRequirementProps {
	addNewGatingOption: (value: GateTypes) => void;
}

const CardAddRequirement: React.FC<CardAddRequirementProps> = (props) => {
	const { addNewGatingOption } = props;
	return (
		<div className="add-requirement">
			<LargeButton
				title={"Add Requirements"}
				style={{ width: "100%" }}
				onClick={() => { addNewGatingOption(GateTypes.passcode_list); }}
				nonUpload
			/>
		</div>
	);
};


export default CardAddRequirement;

