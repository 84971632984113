import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import {
	GET_QUIZZES,
	ADD_QUIZ,
	UPDATE_QUIZ,
} from '../../actions/admin/quizzes';
import { Quiz } from '../../../types/working-model';

export interface QuizzesState {
	quizzes: Quiz[];
}

const initialState: QuizzesState = {
	quizzes: [],
};

export default function QuizzesReducer(
	state: QuizzesState = initialState,
	action: Action
): QuizzesState {
	switch (action.type) {
		case GET_QUIZZES: {
			return handle(state, action, {
				success: (state) => ({ ...state, quizzes: action.payload }),
			});
		}
		case ADD_QUIZ: {
			return { ...state, quizzes: [action.payload, ...state.quizzes] };
		}
		case UPDATE_QUIZ: {
			return {
				...state,
				quizzes: state.quizzes.map((quiz) => {
					if (quiz.quiz === action.payload.quiz) {
						return action.payload;
					} else {
						return quiz;
					}
				}),
			};
		}
		default:
			return state;
	}
}
