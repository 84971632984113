import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { updateHomepageModule } from '../../../../../store/actions/admin/create-event';
import { updatePageModule } from '../../../../../store/actions/admin/create-event/session';
import { LanguagesAbbr, PageModule } from '../../../../../types/working-model';
import HomepageControls, { InitialControlsDisplay } from '../../session/editor/moduleControls/controls';
import ModalComponent from '../../../../general-ui/modal/modal';
import CustomHTML from '../../../../general-ui/edit-html/edit-html';
import SessionControls from '../../session/session-module-controls/controls';
import { ParamsProps } from '../../../../live-event/live-event';
import { getHomepageDefaultLanguage } from '../../../../../utils/get-language';
import { getBlankModuleHtml } from '../../../../../utils/html-utils';
import useTranslationRoute from '../../../../live-event/hooks/use-translation-route';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import '../../../../../scss/live-event/base/modules/blank-section.scss';
import { useIsNewModuleGrouping } from '../../../../../hooks/session.hooks';


interface Props {
	page_module: PageModule;
	template?: string;
	isSession?: boolean;
}

const BlankSection = React.forwardRef<HTMLDivElement, Props>(({ page_module, isSession = false }, ref) => {
	const [workingBlankModule, setWorkingBlankModule] = useState<PageModule | null>({ ...page_module });
	const [isEditing, setIsEditing] = useState(false);

	const dispatch = useDispatch();
	const { language } = useParams() as ParamsProps;
	const namespace = useTranslationRoute();
	const { t } = useTranslate(namespace);

	useEffect(() => {
		// make sure our local state stays in sync with the page module
		setWorkingBlankModule(page_module);
	}, [page_module]);

	const baseLanguage = getHomepageDefaultLanguage();

	const isModuleGroupingV2 = useIsNewModuleGrouping();

	function closeBlankModule() {
		setIsEditing(false);
	}

	function openBlankModule() {
		setIsEditing(true);
	}

	async function finishedEditingBlank() {
		if (workingBlankModule?.id) {
			if (isSession) {
				dispatch(updatePageModule(workingBlankModule));
			} else {
				dispatch(updateHomepageModule(workingBlankModule));
			}
		}
		closeBlankModule();
	}

	function updateWorkingBlankModule(code: string) {
		setWorkingBlankModule((module: PageModule | null) => {
			if (!module) return null;

			const newModule = updateBlankModuleContent(module, code, language, baseLanguage);

			return newModule;
		});
	}

	const controlsDisplay: InitialControlsDisplay = {
		changeSectionPosition: true,
		addContent: true,
	};

	const html = getBlankModuleHtml(page_module, language);

	return page_module.is_on ? (
		<div
			id={`blank-section-${page_module.id}`}
			className={`editor-wrapper ${isModuleGroupingV2 ? "blank-section-v2" : "blank-section"}`}
			ref={ref}>
			{isSession
				? <SessionControls
					page_module={page_module}
					openEditModal={openBlankModule}
					display={controlsDisplay}
				/>
				: <HomepageControls
					module={page_module}
					display={controlsDisplay}
					openContentModal={openBlankModule}
					addContentIconAsEditIcon
					baseLanguage={getHomepageDefaultLanguage() as LanguagesAbbr}
					language={language}
				/>
			}
			<div className={isModuleGroupingV2 ? `blank-module-container` : `module-container`} dangerouslySetInnerHTML={{ __html: t(`blank.${page_module.id}.content`, html) }}>
			</div>
			<ModalComponent
				cancellable={false}
				closeable={false}
				open={isEditing}
				onRequestClose={closeBlankModule}
				className="custom-html-modal"
				title={isModuleGroupingV2 ? "Custom HTML" : "Blank Module"}
				footer={(
					<>
						<button onClick={closeBlankModule} >Cancel</button>
						<button onClick={finishedEditingBlank} className="lemonade">Done</button>
					</>
				)}
			>
				<div>
					{!isModuleGroupingV2 && (<label className="subtitle">Add custom HTML Code</label>)}

					<CustomHTML
						defaultValue={html}
						onChange={updateWorkingBlankModule}
					/>
				</div>
			</ModalComponent>
		</div>
	) : null;
});

BlankSection.displayName = 'BlankSection';

export default BlankSection;

export function updateBlankModuleContent(module: PageModule, code: string, language: string, baseLanguage: string): PageModule {

	const newModule = { ...module, content: module.content ?? { translated_html: { base: '', changed: '' } } };

	if (newModule.content && 'html' in newModule.content) {
		newModule.content = {
			...newModule.content,
			html: code
		};
	} else if (newModule.content?.translated_html) {
		if (language === baseLanguage) {
			newModule.content = {
				...newModule.content,
				translated_html: {
					...newModule.content.translated_html,
					base: code,
					[language]: code,
					changed: 'true'
				}
			};
		} else {
			newModule.content = {
				...newModule.content,
				translated_html: {
					...newModule.content.translated_html,
					[language]: code,
					changed: 'true',
					overrides: Array.from(new Set([...newModule.content?.translated_html?.overrides ?? [], language]))
				}
			};

		}
	}
	return newModule;
}