import React from 'react';

import { Channel } from '../../../../types/working-model';
import { ChannelAvatar } from '../../../general-ui/avatar/channel-avatar';
import images from '../../../../images';


interface IEventLogoProps {
	logo?: string;
	channel?: Channel;
}

export default function EventLogo({ logo, channel }: IEventLogoProps): JSX.Element {
	if (channel) {
		return <ChannelAvatar channel={channel} />;
	}

	return <img className="navigation-logo" src={logo ?? images.HeaderImage} alt={"navigation logo"} style={{ maxHeight: 48, maxWidth: 48 }} />;
}