import { useEffect, useState } from "react";

import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { AllLayoutTypes } from "../../../../../../../types/template-layouts";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";

interface LayoutSelectorProps {
	selectedType: AllLayoutTypes;
	currentType: string;
	handleSelectLayout: ((selectedType: AllLayoutTypes) => void);
	disabled?: boolean;
}

const LayoutSelector: React.FC<React.PropsWithChildren<LayoutSelectorProps>> = ({ selectedType, currentType, handleSelectLayout, children, disabled = false }) => {
	const saving = useTypedSelector(state => state.CreateEventReducer.saving);

	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (!saving) {
			setClicked(false);
		}
	}, [saving]);

	const handleClick = () => {
		if (isSelected || (saving && clicked)) { return; }
		setClicked(true);
		handleSelectLayout(selectedType);
	};

	const isSelected = selectedType === currentType;
	return (
		<div className={`module-layout-item ${isSelected ? 'selected-module-layout' : ''} ${saving && clicked ? 'saving-module-layout' : ''}`}>
			<button
				className={`no-style ${isSelected || (saving && clicked) ? 'selected-module-layout-button' : ''}`}
				disabled={disabled}
				onClick={handleClick}
			>
				<div className="module-layout-selector-content">
					{children}
					{saving && clicked ? (
						<div className="module-layout-selector-content-waiting-indicator">
							<WaitingIndicator />
						</div>
					) : null}
				</div>
			</button>
		</div>
	);
};

export default LayoutSelector;
