import { useEffect, useRef } from "react";
import { useTranslate } from "../../i18n/useTranslationModules";

import { showAlertLong } from "../general-ui/alert/alert-service";
import images from '../../images';
import useTranslationModules from "../../i18n/useTranslationModules";
import Alerts from '../general-ui/alert/alert';
import { jwtDecode } from '../../utils/utils';

import './google-auth.scss';

const GOOGLE_AUTH_REDIRECT_STORAGE = 'google-auth-redirect';
const GOOGLE_AUTH_REDIRECT_PARAM = 'googleAuthRedirect';

const GoogleAuth = (): JSX.Element => {
	const gapi = useRef<any>();
	useTranslationModules({ language: window.navigator.language.substr(0, 2) });

	const { t } = useTranslate("homepage");

	useEffect(() => {
		const script = document.createElement('script');
		script.onload = () => {
			try {
				gapi.current = (window as any)?.google;
				(window as any).onGoogleLibraryLoad = async () => {
					gapi?.current?.accounts?.id?.initialize?.({
						client_id: "175600761872-lhuunnd3ain66608mm0v3kvg7bf36fcb.apps.googleusercontent.com",
						callback: (credentialResponse: any) => {
							const googleToken = credentialResponse.credential;
							const decoded = jwtDecode<any>(googleToken);

							const firstName = decoded.given_name;
							const lastName = decoded.family_name;
							const image = decoded.picture;
							const email = decoded.email;
							const googleId = decoded.sub;

							const inputParams = new URLSearchParams(location.search);
							// parse redirect out of uri input
							const redirectUrl = (() => {
								if (localStorage.getItem(GOOGLE_AUTH_REDIRECT_STORAGE)) {
									return localStorage.getItem(GOOGLE_AUTH_REDIRECT_STORAGE);
								} else {
									const url = inputParams.get(GOOGLE_AUTH_REDIRECT_PARAM);
									if (url) {
										return decodeURIComponent(url);
									}
								}
							})();

							//set in localStorage for reading by the registration form
							const params = new URLSearchParams({ firstName, lastName, image, email, googleId, googleToken });
							//SUCCESS CLAUSE
							//redirect back to target page with signed in user creds
							if (redirectUrl) {

								//remove the storage item to prevent collision with next event if user is already signed in
								localStorage.removeItem(GOOGLE_AUTH_REDIRECT_STORAGE);
								window.location.href = `${redirectUrl}?${params.toString()}`;
							} else {
								//no redirect key, user is stuck here, show error toast
								showAlertLong({
									message: "Sign in Error",
									description: "We ran into an issue redirecting you back to your event page. Please go back and try again.",
									type: "error"
								});
							}
						}
					});

					(window as any)?.google?.accounts?.id?.prompt?.(async (notification: any) => {
						// isNotDisplayed means user is not signed into google, isSkippedMoment means user closed the one tap popup
						if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
							(window as any)?.google.accounts.id.renderButton(
								document.querySelector('.google-signin-auth'),
								{ theme: "outline", size: "large" }
							);
						}
					});

				};

			} catch (e: any) {
				console.error(e);

				//user could not sign in for one reason or another. Likely sign in was not successful or cookies are disabled on their machine.
				console.error(e);

				//TODO: show user error when they're redirected back to the registration form.
				const errorParams = new URLSearchParams(e);

				const inputParams = new URLSearchParams(location.search);

				const redirectUrl = decodeURIComponent(inputParams.get(GOOGLE_AUTH_REDIRECT_PARAM) as string);

				//user closed popup would be { error: "popup_closed_by_user" }

				if (redirectUrl) {
					window.location.href = `${redirectUrl}?${errorParams.toString()}`;
				} else {
					//no redirect url, show user error toast
					showAlertLong({
						message: "Sign in Error",
						description: "We ran into an issue redirecting you back to your event page. Please go back and try again.",
						type: "error"
					});
				}
			}
		};

		script.src = 'https://accounts.google.com/gsi/client';
		document.body.appendChild(script);
	}, []);

	return (
		<div className="google-auth-wrapper">
			<img src={images.Google} />
			<h3>{t("registration.Signing in with Google", "")}</h3>
			<div className="google-signin-auth" />
			<Alerts />
		</div>
	);
};

export default GoogleAuth;
