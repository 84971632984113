import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CommentBoxPrompt, CreateCommentBoxPrompt, CreateQuestionPrompt, LanguagesAbbr, QuestionPrompt, SessionPanelLayoutsTypes } from "../../../../../../types/working-model";
import ToggleOption from "../../../../../general-ui/switch/toggle-option";
import TextInput, { Validation } from "../../../../../general-ui/text-input/text";
import { ErrorsTypes } from "../engage/types/engage";
import {
	clearErrorsHandler,
	setErrorHandler,
	validateCommentBox,
	validateQAndA,
} from "../engage/utils/engage-validate.utils";
import PanelBlock from "../../../homepage/navigation/panel/panel-block";
import { GetDefaultCommentBoxPrompt, GetDefaultQuestionPrompt } from "../../../../../../store/utils/create-event";
import { updateTranslateKey } from "../../../../../../utils/utils";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { useParams } from "react-router-dom";
import { CreateQuestionModule, UpdateQuestionModule, CreateCommentBoxModule, UpdateCommentBoxModule } from "../../../../../../connection/questions";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import { useDispatch } from "react-redux";
import { updatePageModuleAndSave } from "../../../../../../store/actions/admin/create-event/session";
import { useGetAdminUrl } from "../../../../../../utils/admin-routing-utils";
import { SessionPanelMap } from "../panel/session-panel-route-map";
import { useFinishNavigate, usePageModule } from "../panel/hooks/panel.hooks";
import { OptionalComponent } from "utils/optional-component";

export default function CreateQuestion({ isCommentBox }: { isCommentBox?: boolean }): JSX.Element {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const adminPath = useGetAdminUrl();
	const [question, setQuestion] = React.useState<CreateQuestionPrompt | QuestionPrompt>(GetDefaultQuestionPrompt());
	const [commentBox, setCommentBox] = React.useState<CreateCommentBoxPrompt | CommentBoxPrompt>(GetDefaultCommentBoxPrompt());

	const [validationErrors, setValidationErrors] = useState<{ [key: string]: string } | null>(null);
	const [saving, setSaving] = useState(false);
	const { language, page_module } = useParams<{ language: LanguagesAbbr, page_module: string }>();
	const { likes, replies, anonymous_replies, private_responses } = question.settings;
	const { likes: likesCommentBox, replies: repliesCommentBox } = commentBox.settings;

	const dispatch = useDispatch();
	const setError = setErrorHandler(setValidationErrors);
	const clearErrors = clearErrorsHandler(setValidationErrors);
	const ePageModule = usePageModule();
	const finish = useFinishNavigate();


	useEffect(() => {
		if (isCommentBox && ePageModule?.modules?.[0] && 'comment_box_prompt' in ePageModule.modules[0]) {
			setCommentBox(ePageModule.modules[0]);
		}

		else if (!isCommentBox && ePageModule?.modules?.[0] && 'question_prompt' in ePageModule.modules[0]) {
			setQuestion(ePageModule.modules[0]);
		}
	}, [ePageModule, isCommentBox]);

	const validateQAndAHandler = useCallback(() => {
		return validateQAndA({ question, setError });
	}, [question, setError]);

	const validateCommentBoxHandler = useCallback(() => {
		return validateCommentBox({ commentBox, setError });
	}, [commentBox, setError]);

	const setTitle = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		if (!workingSession) return;

		if (isCommentBox) {
			clearErrors(ErrorsTypes.CommmentBoxTitle);
			setCommentBox({
				...commentBox,
				title: updateTranslateKey({
					translateString: commentBox.title,
					input: value,
					baseLanguage: workingSession.default_language,
					language: language
				})
			});
		} else if (!isCommentBox) {
			clearErrors(ErrorsTypes.QAndATitle);
			setQuestion({
				...question,
				title: updateTranslateKey({
					translateString: question.title,
					input: value,
					baseLanguage: workingSession.default_language,
					language: language
				})
			});
		}
	};

	const setOptions = (key: keyof CreateQuestionPrompt['settings'], value: boolean) => {
		if (isCommentBox) {
			setCommentBox({ ...commentBox, settings: { ...commentBox.settings, [key]: !value } });
		} else if (!isCommentBox) {
			setQuestion({ ...question, settings: { ...question.settings, [key]: !value } });
		}
	};

	const handleCancel = () => {
		finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
	};

	const onSaveCommentBox = async () => {
		const errors = validateCommentBoxHandler();
		if (errors[ErrorsTypes.CommmentBoxTitle]) return;

		if (!token || !workingSession) {
			return;
		}

		try {
			setSaving(true);

			// we are editing this because it already has an ID
			if ('comment_box_prompt' in commentBox && commentBox.comment_box_prompt) {
				if (!ePageModule) return;

				const updated = await UpdateCommentBoxModule({
					token,
					commentBoxPrompt: { ...commentBox, channel: workingSession.channel },
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...ePageModule, modules: [updated] };

				dispatch(updatePageModuleAndSave(mod));
				// if it does not have an ID then this is a new commentBox to create
			} else {
				const {
					pageModule,
					commentBoxPrompt
				} = await CreateCommentBoxModule({
					token,
					commentBoxPrompt: { ...commentBox, channel: workingSession.channel },
					pageModule: Number(page_module),
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...pageModule, modules: [commentBoxPrompt] };

				dispatch(updatePageModuleAndSave(mod));
			}

			finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Unable to save commentBox",
				description: "We ran into an issue saving that commentBox, please try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	const onSaveQuestions = async () => {
		const errors = validateQAndAHandler();
		if (errors[ErrorsTypes.QAndATitle]) return;

		if (!token || !workingSession) {
			return;
		}

		try {
			setSaving(true);

			// we are editing this because it already has an ID
			if ('question_prompt' in question && question.question_prompt) {
				if (!ePageModule) return;

				const updated = await UpdateQuestionModule({
					token,
					questionPrompt: { ...question, channel: workingSession.channel },
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...ePageModule, modules: [updated] };

				dispatch(updatePageModuleAndSave(mod));
				// if it does not have an ID then this is a new question to create
			} else {
				const {
					pageModule,
					questionPrompt
				} = await CreateQuestionModule({
					token,
					questionPrompt: { ...question, channel: workingSession.channel },
					pageModule: Number(page_module),
					baseLanguage: workingSession.default_language,
					languages: workingSession.languages
				});

				const mod = { ...pageModule, modules: [questionPrompt] };

				dispatch(updatePageModuleAndSave(mod));
			}

			finish(adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.Engage] }));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Unable to save question",
				description: "We ran into an issue saving that question, please try again.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	return (
		<>
			<TextInput
				id="question-title"
				className="padding-24"
				label={'Title*'}
				placeholder={isCommentBox ? 'Type comment box title' : 'Type question title'}
				valid={validationErrors?.[ErrorsTypes.QAndATitle] ? Validation.error : Validation.normal}
				onChange={setTitle}
				maxLength={1024}
				value={isCommentBox ? commentBox.title[language] as string ?? commentBox.title.base : question.title[language] as string ?? question.title.base}
				isAdmin
			/>
			{validationErrors?.[ErrorsTypes.QAndATitle] && (
				<span className="session-panel-field-error admin-typography-label-2 padding-24">
					{validationErrors?.[ErrorsTypes.QAndATitle]}
				</span>
			)}

			<PanelBlock className="margin-24" title="Engagement Options">
				<ToggleOption
					id="likes"
					title="Likes"
					tooltip="Allow attendees to like other responses"
					switchName="likes"
					toggleValue={isCommentBox ? likesCommentBox : likes}
					onSwitchToggle={() => setOptions('likes', isCommentBox ? likesCommentBox : likes)}
				/>
				<ToggleOption
					id="replies"
					title="Replies"
					tooltip="Allow attendees to reply to other responses"
					switchName="replies"
					toggleValue={isCommentBox ? repliesCommentBox : replies}
					onSwitchToggle={() => setOptions('replies', isCommentBox ? repliesCommentBox : replies)}
				/>

				<OptionalComponent display={!isCommentBox}>
					<ToggleOption
						id="anonymous-replies"
						title="Anonymous"
						tooltip="Allow attendees to submit their responses anonymously"
						switchName="anonymousReplies"
						toggleValue={anonymous_replies}
						onSwitchToggle={() => setOptions('anonymous_replies', anonymous_replies)}
					/>
					<ToggleOption
						id="private-responses"
						title="Enable private responses"
						tooltip="Enabling will only display questions to the submitting user"
						switchName="privateResponses"
						toggleValue={!!private_responses}
						onSwitchToggle={() => setOptions('private_responses', !!private_responses)}
					/>
				</OptionalComponent>
			</PanelBlock>

			<div className="session-panel-actions margin-0">
				<button onClick={handleCancel}>Cancel</button>
				<button className="lemonade" disabled={saving} onClick={isCommentBox ? onSaveCommentBox : onSaveQuestions}>Save</button>
			</div>
		</>
	);
}