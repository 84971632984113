import React from "react";
import { Redirect } from "react-router-dom";

export default function Home(): JSX.Element {
	return (
		<div className="home-container">
			{/* Not sure if there's going to be a homepage or not here, just redirect to /admin for now */}
			<Redirect to={"/admin"} />
		</div>
	);
}
