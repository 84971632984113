import { useCallback, useMemo } from "react";
import {
	AgendaLayoutTypesClassic,
	AllLayoutTypes, LayoutTypesMap, TemplateClassNames, TemplateNames,
} from '../../../../../../../types/template-layouts';
import {
	ModuleMap,
	PageModuleType,
	PageModule,
	NavMainItems,
} from '../../../../../../../types/working-model';
import LayoutSelector from "./layout-selector";
import Images from '../../../../session/editor/moduleControls/layout-images.json';
import classNames from "classnames";
import { replaceSpaceWithUnderscore } from "../../../../../../../utils/utils";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { getCurrentCustomPage } from "../../../../../../../utils/admin-routing-utils";
import { useIsNewNavigation } from "../../../../../../../hooks/navigation.hooks";

interface Props {
	selectedModule?: PageModuleType | null;
	selectedLayout: string | null;
	setSelectedLayout?: React.Dispatch<React.SetStateAction<AllLayoutTypes | null>>;
	page?: string;
	speakerImageRadius?: boolean | null;
}

const SelectModuleLayout: React.FC<Props> = ({
	selectedModule,
	selectedLayout,
	setSelectedLayout,
	speakerImageRadius,
	page,
}) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const currentPage = useTypedSelector(state => state.CreateEventReducer.currentPostRegisterPage);

	const currentCustomPage = useMemo(
		() => getCurrentCustomPage(workingEvent?.custom_pages, currentPage),
		[workingEvent?.custom_pages, currentPage]
	);

	const moduleMemoized = useCallback(() => {
		if (currentPage === NavMainItems.Home || page === NavMainItems.Sessions) {
			// get module from post_register_home_modules
			return workingEvent?.homepage?.post_register_home_modules.find(module => module.id === selectedModule);
		} else if (currentPage) {
			// get the correct module from custom_pages
			return currentCustomPage?.modules?.find(module => module.id === selectedModule);
		} else {
			// else get is from homepage.modules
			return workingEvent?.homepage?.modules.find(module => module.id === selectedModule);
		}
	}, [currentPage, workingEvent?.homepage?.modules, workingEvent?.homepage?.post_register_home_modules, selectedModule, currentCustomPage?.modules, page]);

	const module: PageModule | undefined = moduleMemoized();

	const handleSelectLayout = (selectedType: AllLayoutTypes) => {
		if (!workingEvent || !workingEvent?.homepage) { return; }
		setSelectedLayout?.(selectedType);
	};


	function makeLayoutSelectors(
		moduleName: string,
		module: PageModule | undefined,
		handleSelectLayout: ((selectedType: AllLayoutTypes) => void),
		layout: { [key: string]: string },
		template: string
	): JSX.Element | null {
		const isNewNavigation = useIsNewNavigation();

		const isAgendaModule = moduleName === ModuleMap[PageModuleType.agenda];
		let moduleNameStandardized = replaceSpaceWithUnderscore(moduleName.toLowerCase());

		// "Agenda" was renamed "Sessions & Content"
		if (isAgendaModule) {
			moduleNameStandardized = 'agenda';
		}

		// TODO: create new thumbnails for Limelight if we determine that it has different layouts
		const isCustomTemplate = !TemplateClassNames[template] || TemplateClassNames[template] === 'Limelight';

		// keeping around in case we want to disable the non-agenda calendar layouts when toggling on no thumbnails
		// if agenda layout && disableSessionThumbnails && not calendar view
		// const disableSessionThumbnails = workingEvent?.settings.disable_session_thumbnails;

		const filteredLayoutList = useMemo(() => {
			// The "Standard" layout is no longer supported in the Agenda module for new events
			if (isNewNavigation && isAgendaModule) {
				return Object.values(layout).filter(layout => layout !== AgendaLayoutTypesClassic.standard);
			}

			return Object.values(layout);
		}, [layout, isNewNavigation, isAgendaModule]);

		if (!module) { return null; }
		return (
			<>
				{filteredLayoutList.map((layout) => {
					const templateAssetsToUse = isCustomTemplate ? TemplateNames.Apollo : template;
					const key = `${replaceSpaceWithUnderscore(templateAssetsToUse)}-${moduleNameStandardized}-${layout}`;
					const src = `${process.env.REACT_APP_ASSET_SERVER}/template-previews/${(Images as { [key: string]: string })[key]}`;

					// keeping around in case we want to disable the non-agenda calendar layouts when toggling on no thumbnails
					// let disabledCard = false;
					// if (
					// 	module.type === PageModuleType.agenda
					// 	&& disableSessionThumbnails
					// 	&& module.content.layout_type === AgendaLayoutTypesClassic.calendar
					// 	&& layout !== AgendaLayoutTypesClassic.calendar
					// ) {
					// 	disabledCard = true;
					// }
					return (
						<LayoutSelector
							key={template + moduleName + layout}
							selectedType={layout as AllLayoutTypes}
							currentType={selectedLayout ? selectedLayout : module?.content?.layout_type}
							handleSelectLayout={handleSelectLayout}
							disabled={layout === 'solid' && !!speakerImageRadius}
						>

							<div
								className={classNames('layout-thumbnail', moduleNameStandardized, layout)}
								data-layout={layout}
							>
								<img
									src={src}
									alt={layout}
								/>
							</div>
						</LayoutSelector>
					);
				})}
			</>
		);
	}

	const renderList = (moduleType: PageModuleType) => {
		const templateName = workingEvent?.template?.name ?? TemplateNames.Apollo;
		const templateKey = TemplateClassNames[templateName] ? templateName : TemplateNames.Apollo;

		if (!templateName) { return null; }
		return makeLayoutSelectors(
			ModuleMap[moduleType],
			module,
			handleSelectLayout,
			LayoutTypesMap[templateKey][moduleType],
			templateName);
	};

	if (!selectedModule || !module) { return <div />; }
	return (
		<div className="module-layout-items">
			{renderList(module.type)}
		</div>
	);
};

export default SelectModuleLayout;
