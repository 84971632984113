import React, { CSSProperties, useMemo } from "react";
import classNames from "classnames";
import { useParams } from "react-router";
import NavigationDropdown from "../../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";

import { ParamsProps } from "../../../../../../../live-event/live-event";

import "./suggested-card.scss";
import mTimestamps from "../../../../../../../../utils/timestamps";
import { TranslateString } from "../../../../../../../../types/working-model";

interface IEngageCardProps {
	id: number;
	title: TranslateString;
	timestamp: string | number | null;
	image: string;
	checked?: boolean;
	onCheckboxChange?: (value: number) => void;
	clickHandler?: (key: 'edit' | 'delete', id: number) => void;
}

export default function SuggestedCard({
	id,
	title,
	timestamp,
	image,
	checked,
	onCheckboxChange,
	clickHandler,
}: IEngageCardProps): JSX.Element {
	const { language } = useParams<ParamsProps>();

	const time = useMemo(() => {
		if (timestamp) {
			return mTimestamps.getPrettyDateTime(timestamp);
		}

		return "On Demand";
	}, [timestamp]);

	return (
		<div
			className={classNames('e-suggested-card cursor-pointer panel-card', { selected: checked })}
			style={{ '--image-url': `url(${image})` } as CSSProperties}
			onClick={() => onCheckboxChange?.(id)}
		>
			<time className={classNames('e-suggested-card-time admin-typography-paragraph-1')}>{time}</time>
			<p className="e-suggested-card-title admin-typography-heading-5">{title[language] ?? title.base}</p>

			{clickHandler && (
				<NavigationDropdown
					title={<Icon name={ICONS.THREE_DOTS_VERTICAL} color={COLORS.WHITE} size={20} />}
					className="session-panel-dropdown lighter"
					buttonClassName="no-style no-padding"
					edge="right"
					isArrow={false}
				>
					<button onClick={() => clickHandler?.("delete", id)}>
						Delete
					</button>
				</NavigationDropdown>
			)}
		</div>
	);
}
