import React, { useState, useRef, useCallback } from 'react';

import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import Modal from '../modal/modal';
import Icon, { COLORS, ICONS } from "../icon";
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import { UploadFile } from '../../../connection/uploads';
import ImageEditor from '../image-editor/image-editor';
import LargeButton from '../button/large-button';
import { showAlert } from '../alert/alert-service';
import { fileType, ImageUploaderOnSaveParam, InitialAspectRatioOptionsEnum } from '../../../types/working-model';

export type ImageUploadOptions = 'upload' | 'edit' | null;

export enum EUploadOptions {
	upload = 'upload',
	edit = 'edit'
}

interface ImageUploaderProps {
	open: ImageUploadOptions;
	originalImage?: string | null;
	fileTitle?: string;
	allowedTypes?: fileType[];
	allowedSize?: string;
	disableEditingAspectRatio?: boolean;
	initialAspectRatio?: InitialAspectRatioOptionsEnum;
	onSave: (image: ImageUploaderOnSaveParam) => void;
	onClose: () => void;
}

// combination modal to upload and edit an image
export const ImageUploader = (props: ImageUploaderProps): JSX.Element => {
	const {
		open,
		originalImage,
		fileTitle = "Image",
		allowedTypes = ["image/png", "image/jpeg", "image/gif"],
		allowedSize = "10mb",
		disableEditingAspectRatio = false,
		initialAspectRatio,
		onSave,
		onClose,
	} = props;

	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const [uploadedImage, setUploadedImage] = useState<string | null | undefined>(originalImage);
	const [workingFile, setWorkingFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [editing, setEditing] = useState<boolean>(false);

	// prevents user from hitting save before the current edits have been generated into a file
	const handleEditing = useCallback((inProcess: boolean) => {
		setEditing(inProcess);
	}, []);

	const uploadImage = async (file: File | FileList): Promise<string | undefined> => {
		if (!user || !token) return;
		setUploading(true);
		setWorkingFile(file as File);
		const uploaded = await UploadFile(user, token, file as File);
		setUploading(false);
		return uploaded;
	};

	const handleFile = async (file: File | FileList): Promise<void> => {
		// TODO: error if file is larger than 10mb
		const uploaded = await uploadImage(file);
		setUploadedImage(uploaded);
	};

	const handleSaveUpload = () => {
		if (uploadedImage) {
			onSave({ original: uploadedImage, edited: uploadedImage });
		}
		setEditing(false);
		onClose();
	};

	const handleReplace = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.files) {
			setWorkingFile(null);
			setUploadedImage(null);
			handleFile(e.target.files[0]);
		}
	};

	const handleCancel = (): void => {
		setWorkingFile(null);
		setUploadedImage(originalImage);
		setEditing(false);
		onClose();
	};

	const handleSave = async (): Promise<void> => {
		try {
			if (!workingFile || !uploadedImage) return;

			const uploaded = await uploadImage(workingFile);
			if (uploaded) {
				onSave({ original: uploadedImage, edited: uploaded });
			}
			onClose();
		} catch (e) {
			showAlert({
				message: "Error saving image",
				description: "There was an error uploading the image. Please try again.",
				duration: 5000,
				type: "error"
			});
		} finally {
			setEditing(false);
		}
	};

	const fileTypeString: string = allowedTypes.map(type => type.split('/').pop()).join(', ').toUpperCase();
	const subtitle = `Use ${fileTypeString} files up to ${allowedSize} size.`;


	const footer = (
		<div className="image-modal-footer">
			<div>
				{open === 'upload' && uploadedImage && (
					<button className="no-style left-button" onClick={() => inputRef.current?.click()} >
						<Icon name={ICONS.UPLOAD} color={COLORS.CYAN} size={12} />
						{`Replace ${fileTitle}`}
					</button>
				)}
			</div>
			<div>
				<button className="button" onClick={handleCancel}>Cancel</button>
				<button
					className="button save lemonade"
					onClick={open === 'upload' ? handleSaveUpload : handleSave}
					disabled={!uploadedImage || !workingFile || uploading || editing}
				>
					{uploading ? <WaitingIndicator /> : "Save"}
				</button>
			</div>
		</div>
	);

	return (
		<Modal
			closeable={false}
			cancellable={true}
			title={open === 'edit' ? `Edit ${fileTitle}` : `Upload ${fileTitle}`}
			open={!!open}
			onRequestClose={handleCancel}
			footer={footer}
		>
			{open === 'edit' && originalImage ? (
				<ImageEditor
					originalImage={originalImage}
					disableEditingAspectRatio={disableEditingAspectRatio}
					initialAspectRatio={initialAspectRatio}
					onFinished={(file: File) => setWorkingFile(file)}
					setEditing={handleEditing}
				/>
			)
				: null}
			<div
				style={{
					backgroundImage: open === 'upload' && uploadedImage ? `url(${uploadedImage})` : '',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					borderRadius: '12px'
				}}
			>
				{open === 'upload' ? (
					<LargeButton
						title={`Upload ${fileTitle}`}
						subtitle={subtitle}
						allowedFileTypes={allowedTypes}
						onFile={handleFile}
					/>
				) : null}
			</div>
			<input
				type="file"
				accept={allowedTypes.join(',') ?? '*'}
				ref={inputRef}
				style={{ display: 'none' }}
				onChange={handleReplace}
			/>
		</Modal>
	);
};