import { GetEventChatMessages, UpdateLastReadEventChatMessage, SendEventChatMessage, EditEventChatMessage, DeleteEventChatMessage, CreateEventChat, LeaveEventChat } from '../../../connection/event-chat';
import { SearchProfiles } from '../../../connection/social';
import { Action, AsyncAction } from '../../../types/actions';
import db from '../../utils/indexed-db';
import { EventChatMessageType, IBlockedChatParticipant, ISocketEventChatProps, QuestionType } from '../../../types/working-model';
import { IEventChat } from '../../reducers/event/event-chat-reducer';

export const LOAD_EVENT_CHAT_MESSAGES = 'LOAD_EVENT_CHAT_MESSAGES';
export function loadEventChatMessages(token: string, eventId: number, profileId: number, channel: number): AsyncAction {
	return {
		type: LOAD_EVENT_CHAT_MESSAGES,
		promise: GetEventChatMessages(token, eventId, profileId, channel)
	};
}

export const SEARCH_NEW_CHAT_PARTICIPANTS = "SEARCH_NEW_CHAT_PARTICIPANTS";
export function searchProfilesForNewChat(
	channelId: number,
	eventId: number,
	criteria: string,
	paginationLimit: number,
	paginationOffset: number,
	token: string
): AsyncAction {
	return {
		type: SEARCH_NEW_CHAT_PARTICIPANTS,
		promise: SearchProfiles(channelId, eventId, criteria, paginationLimit, paginationOffset, token, null, [{ registration_question: 'enable_messaging' as any, type: QuestionType.text }], null, 'ASC')
	};
}

export const UPDATE_LAST_READ_EVENT_CHAT_MESSAGE = 'UPDATE_LAST_READ_EVENT_CHAT_MESSAGE';
export function updateLastReadEventChatMessage(chatId: number, profileId: number): AsyncAction {
	return {
		type: UPDATE_LAST_READ_EVENT_CHAT_MESSAGE,
		promise: UpdateLastReadEventChatMessage(chatId, profileId)
	};
}

export const INCOMING_EVENT_CHAT_MESSAGE = 'INCOMING_EVENT_CHAT_MESSAGE';
export function incomingEventChatMessage(message: ISocketEventChatProps["content"], currentChat: IEventChat["id"] | null): Action {
	return {
		type: INCOMING_EVENT_CHAT_MESSAGE,
		payload: { message, currentChat }
	};
}

export const OUTGOING_EVENT_CHAT_MESSAGE = 'OUTGOING_EVENT_CHAT_MESSAGE';
export function outgoingEventChatMessage(token: string, socketChannel: string, channel: number, chatId: number, chatParticipantId: number, content: string, type: EventChatMessageType): AsyncAction {
	return {
		type: OUTGOING_EVENT_CHAT_MESSAGE,
		promise: SendEventChatMessage(token, socketChannel, channel, chatId, chatParticipantId, content, type)
	};
}

export const INCOMING_EDIT_EVENT_CHAT_MESSAGE = 'INCOMING_EDIT_EVENT_CHAT_MESSAGE';
export function incomingEditEventChatMessage(message: ISocketEventChatProps): Action {
	return {
		type: INCOMING_EDIT_EVENT_CHAT_MESSAGE,
		payload: message
	};
}

export const OUTGOING_EDIT_EVENT_CHAT_MESSAGE = 'OUTGOING_EDIT_EVENT_CHAT_MESSAGE';
export function outgoingEditEventChatMessage(channel: string, chatMessageId: number, newContent: string): AsyncAction {
	return {
		type: OUTGOING_EDIT_EVENT_CHAT_MESSAGE,
		promise: EditEventChatMessage(channel, chatMessageId, newContent)
	};
}

export const INCOMING_DELETE_CHAT_MESSAGE = 'INCOMING_DELETE_EVENT_CHAT_MESSAGE';
export function incomingDeleteEventChatMessage(message: ISocketEventChatProps): Action {
	return {
		type: INCOMING_DELETE_CHAT_MESSAGE,
		payload: message
	};
}

export const OUTGOING_DELETE_CHAT_MESSAGE = 'OUTGOING_DELETE_EVENT_CHAT_MESSAGE';
export function outgoingDeleteEventChatMessage(channel: string, chatMessageId: number): AsyncAction {
	return {
		type: OUTGOING_DELETE_CHAT_MESSAGE,
		promise: DeleteEventChatMessage(channel, chatMessageId)
	};
}

export const INCOMING_CREATE_EVENT_CHAT = 'INCOMING_CREATE_EVENT_CHAT';
export function incomingCreateEventChat(message: ISocketEventChatProps): Action {
	return {
		type: INCOMING_CREATE_EVENT_CHAT,
		payload: message
	};
}

export const OUTGOING_CREATE_EVENT_CHAT = 'OUTGOING_CREATE_EVENT_CHAT';
export function outgoingCreateEventChat(channel: number, eventId: number, eventUuid: string, title: string | null, creatorProfileUuid: string, participantProfileUuids: string[], isOpen: boolean): AsyncAction {
	return {
		type: OUTGOING_CREATE_EVENT_CHAT,
		promise: CreateEventChat(channel, eventId, eventUuid, title, creatorProfileUuid, participantProfileUuids, isOpen)
	};
}

export const OUTGOING_CREATE_EVENT_DM = 'OUTGOING_CREATE_EVENT_DM';
export function outgoingCreateEventDM(channel: number, eventId: number, eventUuid: string, title: string | null, creatorProfileUuid: string, participantProfileUuids: string[], isOpen: boolean, dmTitle?: string, avatar?: string): AsyncAction {
	return {
		type: OUTGOING_CREATE_EVENT_DM,
		promise: CreateEventChat(channel, eventId, eventUuid, title, creatorProfileUuid, participantProfileUuids, isOpen, dmTitle, avatar)
	};
}

export const INCOMING_LEAVE_EVENT_CHAT = 'INCOMING_LEAVE_EVENT_CHAT';
export function incomingLeaveEventChat(message: ISocketEventChatProps): Action {
	return {
		type: INCOMING_LEAVE_EVENT_CHAT,
		payload: message
	};
}

export const OUTGOING_LEAVE_EVENT_CHAT = 'OUTGOING_LEAVE_EVENT_CHAT';
export function outgoingLeaveEventChat(socketChannel: string, chatId: number, chatParticipantId: number): AsyncAction {
	return {
		type: OUTGOING_LEAVE_EVENT_CHAT,
		promise: LeaveEventChat(socketChannel, chatId, chatParticipantId)
	};
}

export const SET_LAST_READ_OPEN_CHAT = 'SET_LAST_READ_OPEN_CHAT';
export function setLastReadOpenChat(roomId: number): AsyncAction {
	return {
		type: SET_LAST_READ_OPEN_CHAT,
		promise: db.setLastReadOpenChat(roomId)
	};
}

export const GET_LAST_READ_OPEN_CHAT = 'GET_LAST_READ_OPEN_CHAT';
export function getLastReadOpenChat(roomIds: number[]): AsyncAction {
	return {
		type: GET_LAST_READ_OPEN_CHAT,
		promise: db.getLastReadOpenChat(roomIds),
		payload: null
	};
}

export const UPDATE_CHAT_BLOCKED_STATUS = 'UPDATE_CHAT_BLOCKED_STATUS';
export function updateChatBlockedStatus(data: IBlockedChatParticipant): Action {
	return {
		type: UPDATE_CHAT_BLOCKED_STATUS,
		payload: data,
	};
}

export const UPDATE_BANNED_USER_LIST = 'UPDATE_BANNED_USER_LIST';
export function updateBannedUserList(profileUuid: string): Action {
	return {
		type: UPDATE_BANNED_USER_LIST,
		payload: profileUuid,
	};
}

export const SET_DISPLAY_CHAT_GROUP = 'SET_DISPLAY_CHAT_GROUP';
export function setDisplayChatGroup(displayChat: { chat?: IEventChat; title?: string; avatar?: string } | null): Action {
	return {
		type: SET_DISPLAY_CHAT_GROUP,
		payload: displayChat
	};
}

export const RESET_NEW_PARTICIPANT_SEARCH = 'RESET_NEW_PARTICIPANT_SEARCH';
export function resetNewParticipantSearch(): Action {
	return {
		type: RESET_NEW_PARTICIPANT_SEARCH,
		payload: []
	};
}