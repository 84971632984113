import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { batch, useDispatch } from "react-redux";
import ReactSelect from 'react-select';
import { CSS } from '@dnd-kit/utilities';
import classNames from "classnames";

import { updateSession, updateWorkingSession } from "../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { IEventTag, Session } from "../../../../../../types/working-model";
import Chip from "../../../../../general-ui/chip/chip";

import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import ModalComponent from "../../../../../general-ui/modal/modal";
import { reactSelectStyles } from "../../../../../general-ui/react-select/react-select-styles";
import CreateEventTagModal from "../../../../events/create-event-tag-modal";
import { toDictTyped } from "../../../../../../utils/utils";

interface ISessionTagsProps {
	session: Session;
}

const SessionTags: React.FC<ISessionTagsProps> = ({ session }) => {

	const token = useTypedSelector(state => state.AuthReducer.token);
	const eventTags = useTypedSelector(state => state.CreateEventReducer.workingEvent?.tags);
	const savingEvent = useTypedSelector(state => state.CreateEventReducer.saving);

	const [openCreateEventTagModal, setOpenCreateEventTagModal] = useState(false);
	const [openSelectSessionTags, setOpenSelectSessionTagsModal] = useState(false);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	const dispatch = useDispatch();

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const initialTags = useCallback(() => {
		if (!eventTags) return [];
		const _initialTags: { label: string; value: string; }[] = [];
		eventTags.forEach(tag => {
			_initialTags.push({ label: tag.name, value: tag.uuid });
		});
		return _initialTags;
	}, [eventTags]);

	const eventTagsMap = useMemo(() => toDictTyped('uuid', eventTags ?? []), [eventTags]);

	const defaultValues = useCallback(() => {
		const _defaultValues: { label: string; value: string; color: string; }[] = [];

		if (!session.tags?.length) return _defaultValues;

		session.tags.forEach(tagUuid => {
			const eventTag = eventTagsMap[tagUuid];
			if (eventTag) {
				_defaultValues.push({ label: eventTag.name, value: eventTag.uuid, color: eventTag.color });
			}
		});
		return _defaultValues;
	}, [eventTagsMap, session.tags]);

	useEffect(() => {
		// keep session tags in sync with selectedTags
		if (session.tags && openSelectSessionTags === true) {
			setSelectedTags(session.tags);
		}
	}, [openSelectSessionTags, session.tags]);

	const handleAddEventTag = () => {
		setOpenCreateEventTagModal(true);
		handleCloseSessionTagsModal();
	};

	const handleCloseCreateEventTagModal = () => {
		setOpenCreateEventTagModal(false);
		editSessionTags();
	};

	const editSessionTags = () => {
		setOpenSelectSessionTagsModal(true);
	};

	const handleCloseSessionTagsModal = () => {
		setOpenSelectSessionTagsModal(false);
	};

	const handleSessionTagSelect = (values: any) => {
		setSelectedTags(values.map((value: any) => value?.value).filter((i: any) => i));
	};

	const handleSaveSessionTags = () => {
		if (!token) return;

		// remove event tags that were added to local state, then the event tag is deleted but still exists in local state.
		const _tags = selectedTags.filter(t => eventTagsMap[t]);

		const updatedSession: Session = {
			...session,
			tags: _tags,
		};

		batch(() => {
			dispatch(updateSession(updatedSession, token));
			dispatch(updateWorkingSession(updatedSession));
		});
		handleCloseSessionTagsModal();
	};

	const handleDragEnd = (event: any) => {
		const _tags = [...selectedTags];

		const startIndex = _tags.findIndex((t) => t === event.active.id);
		const newIndex = _tags.findIndex((t) => t === event.over.id);
		if (startIndex === newIndex) return;

		const [tag] = _tags.splice(startIndex, 1);
		_tags.splice(newIndex, 0, tag);

		setSelectedTags(_tags);
	};

	const handleRemoveTag = (id: string) => {
		setSelectedTags(_prev => _prev.filter(t => t !== id));
	};

	return (
		<div className="settings-block session-tags-block">
			<div className="settings-block-header">
				<h5>Tags</h5>
			</div>
			<div className="field-group">
				<label>Add session tags to organize your event</label>
				<div className="tag-chips-container">
					{defaultValues().map(tag => {
						return (
							<Chip key={tag.value} className="tag-chip">
								{tag.label}
							</Chip>
						);
					})}
				</div>
			</div>
			<div>
				<button className="round" onClick={editSessionTags}><Icon name={ICONS.ADD} size={16} color={COLORS.CYAN} /></button>
			</div>

			<CreateEventTagModal
				open={openCreateEventTagModal}
				onClose={handleCloseCreateEventTagModal}
				openWithNewTagInput
			/>

			<ModalComponent
				title="Manage Tags"
				open={openSelectSessionTags}
				onRequestClose={handleCloseSessionTagsModal}
				closeable={false}
				cancellable
				className="session-tags-block-modal"
				footer={
					<div className="delete-modal-buttons">
						<button onClick={handleCloseSessionTagsModal}>Cancel</button>
						<button
							disabled={savingEvent}
							onClick={handleSaveSessionTags}
							className="lemonade"
						>
							Save
						</button>
					</div>
				}
			>
				<div>
					<div>
						<ReactSelect
							styles={reactSelectStyles({
								control: {
									borderRadius: '100px',
								}
							})}
							value={selectedTags.map(t => eventTagsMap[t] && ({ label: eventTagsMap[t].name, value: t }))}
							tabSelectsValue={false}
							closeMenuOnSelect
							isSearchable={false}
							isClearable={false}
							isMulti
							options={initialTags()}
							onChange={handleSessionTagSelect}
							defaultValue={defaultValues()}
							menuPortalTarget={document.body}
							isDisabled={savingEvent}
							controlShouldRenderValue={false}
						/>
					</div>
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={handleDragEnd}
						modifiers={[restrictToParentElement]}
						autoScroll={false}
					>
						<ul className="sortable-session-tag-container">
							<SortableContext
								items={selectedTags}
								strategy={rectSortingStrategy}
							>
								{selectedTags.map(t => eventTagsMap[t] && (
									<SortableSessionTag key={t} id={t} tag={eventTagsMap[t]} onRemoveTag={handleRemoveTag} />
								))}
							</SortableContext>
						</ul>
					</DndContext>
					<div>
						<button disabled={savingEvent} className="event-tag-add-tag" onClick={handleAddEventTag}>Add tag</button>
					</div>
				</div>
			</ModalComponent>
		</div>
	);
};

export default SessionTags;

interface ISortableSessionTag {
	id: string;
	tag: IEventTag;
	onRemoveTag: (id: string) => void;
}

const SortableSessionTag = (props: ISortableSessionTag) => {
	const { id, tag, onRemoveTag } = props;

	const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

	const { name, color } = tag;
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	const handleRemoveTag = () => onRemoveTag(id);

	return (
		<li
			className={classNames("sortable-session-tag", { dragging: attributes["aria-pressed"] })}
			ref={setNodeRef}
			style={style}
		>
			<div className="container-left">
				<div
					className="drag"
					{...attributes}
					{...listeners}
				>
					<Icon name={ICONS.DRAG_HANDLE} size={14} color="" />
				</div>
				<div className="tag-color-preview" style={{ backgroundColor: color }} />
				<p>{name}</p>
			</div>
			<button className="no-style no-padding remove-tag" onClick={handleRemoveTag}>
				<Icon name={ICONS.TRASH} size={14} color="" />
			</button>
		</li>
	);

};