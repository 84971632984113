import { Dispatch } from "redux";

import { LanguagesAbbr, SessionTypesEnum, Session, SessionPreview } from "../types/working-model";
import { niceDateTime, niceDateTimeWithoutTimeZone } from "../utils/utils";
import { useTranslate } from "../i18n/useTranslationModules";
import { AgendaLayoutTypesClassic } from "../types/template-layouts";
import { useIsNewNavigation } from "./navigation.hooks";
import { useGetEvent } from './session.hooks';
import * as EventActions from '../store/actions/event/event-actions';
import { getDurationStartEndTimes, getDurationWithType } from "components/live-event/modules/agenda/utils/session-card.utils";
import db from 'store/utils/indexed-db';

export const useGetSessionTypeName = (session_type?: SessionTypesEnum): string => {
	const { t } = useTranslate(["homepage", "landing"]);

	if (!session_type) {
		return "";
	}

	switch (session_type) {
		case SessionTypesEnum.broadcast: {
			return t("homepage:Broadcast", "");
		}
		case SessionTypesEnum.breakoutRooms: {
			return t("homepage:Breakout Rooms", "");
		}
		case SessionTypesEnum.fireside: {
			return t("homepage:Fireside", "");
		}
		default: {
			return t("homepage:On Demand", "");
		}
	}
};

type TUseTimestampText = (params: {
	shouldShowTimestamp: boolean;
	session: Session;
	language: LanguagesAbbr;
	tracks: string[] | null;
}) => string;

export const useTimestampText: TUseTimestampText = ({
	shouldShowTimestamp,
	language,
	session,
	tracks
}) => {
	const { t } = useTranslate('session');
	const event = useGetEvent();
	const duration = getSessionDuration(session);

	const tracksTranslations = event?.settings.tracks_translations;

	const translatedTracks = tracks?.map(track => {
		const translation = tracksTranslations?.[track]?.[language];
		if (translation && typeof translation === 'string') {
			return translation;
		}
		return track;
	});

	const sessionTypeName = useGetSessionTypeName(session.session_type);
	const niceTimestamp = session.timestamp && niceDateTime(session.timestamp, language);
	const tracksList = translatedTracks?.length ? ` \u00B7 ${translatedTracks.join(', ')}` : '';
	const typeName = sessionTypeName ? `\u00B7 ${sessionTypeName}` : '';

	const now = Date.now();
	const sessionHasStarted = session.timestamp ? (now - session.timestamp) > 0 : false;
	const sessionHasEnded = session.end_timestamp ? (now - session.end_timestamp) > 0 : false;

	return shouldShowTimestamp
		? sessionHasStarted && !sessionHasEnded
			? `${t('Runtime')}: ${duration}  ·  ${niceTimestamp} ${typeName}${tracksList}`
			: `${niceTimestamp} ${typeName}${tracksList}  ·  ${t('Runtime')}: ${duration}`
		: sessionTypeName;
};

type TUseSessionTimestampText = (params: {
	timestamp: number | null;
	session: Session;
	language: LanguagesAbbr;
	oneDigit?: boolean;
}) => string;

export const useSessionTimestampText: TUseSessionTimestampText = ({
	timestamp,
	session,
	language,
	oneDigit
}) => {
	const { t } = useTranslate('session');

	const niceTimestamp = niceDateTimeWithoutTimeZone({
		timestamp: (timestamp || null) as number,
		language: language as LanguagesAbbr,
		short: true,
		dateOnly: false,
		timeOnly: false,
		useDot: true,
		oneDigit: oneDigit
	});

	const duration = getSessionDuration(session);

	if (!timestamp) {
		return niceTimestamp;
	} else {
		const now = Date.now();
		const sessionHasStarted = (now - timestamp) > 0;
		const sessionHasEnded = session.end_timestamp ? (now - session.end_timestamp) > 0 : false;

		return sessionHasStarted && !sessionHasEnded
			// Adds: "Runtime: 1h30m"
			? `${t('Runtime')}: ${duration}  ·  ${niceTimestamp}`
			: `${niceTimestamp}  ·  ${t('Runtime')}: ${duration}`;
	}
};

export const getSessionDuration = (session: Session | SessionPreview | null): string => {
	if (!session) return '';

	const durationOD = session && session.session_type === SessionTypesEnum.onDemand && getDurationWithType(session as Session);
	const durationBroadcast = getDurationStartEndTimes(session);
	const duration = durationOD || durationBroadcast || '';

	return duration;
};


type TUseSessionTrackText = (params: {
	language: LanguagesAbbr;
	tracks: string[] | null;
}) => string;
export const useSessionTrackText: TUseSessionTrackText = ({
	language,
	tracks
}) => {
	const event = useGetEvent();

	const tracksTranslations = event?.settings.tracks_translations;

	const translatedTracks = tracks?.map(track => {
		const translation = tracksTranslations?.[track]?.[language];
		if (translation && typeof translation === 'string') {
			return translation;
		}
		return track;
	});


	const tracksList = translatedTracks?.length ? `${translatedTracks.join(' \u00B7 ')}` : '';
	return tracksList;
};


export const useGetLayoutType = (layout_type?: AgendaLayoutTypesClassic) => {
	const isNewNavigation = useIsNewNavigation();

	if (isNewNavigation && layout_type === AgendaLayoutTypesClassic.standard) {
		return AgendaLayoutTypesClassic.ltr;
	}

	if (layout_type) return layout_type;

	return AgendaLayoutTypesClassic.standard;
};


export const helperGetDBOndemandWatchtime = async (sessionId: number, source: string) => {
	try {
		const payload = await db.getOnDemandWatchTime(sessionId, source);
		if (payload && payload.length > 0) {
			const progressData = payload[0];
			return progressData;
		}

	} catch (error) {
		console.error(error);
	}
	return;
};
