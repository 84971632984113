import { Action } from '../../types/actions';
import { FeatureFlagMap, FeatureFlagsEnum, ChannelFeatures } from '../../types/working-model';
import { SET_CHANNEL_FEATURES, SET_FEATURE_FLAGS, TOGGLE_FEATURE_FLAG } from '../actions/feature-flags';

const isProd = process.env.REACT_APP_STAGE === 'prod';
const isHot = process.env.REACT_APP_STAGE === 'hotfix';

export interface IFeatureFlagsReducer {
	featureFlags: FeatureFlagMap;
	channelFeatures: ChannelFeatures;
	loading: boolean;
}

const initialState: IFeatureFlagsReducer = {
	channelFeatures: {},
	featureFlags: {},
	loading: true,
};

export default function FeatureFlagsReducer(state = initialState, action: Action): IFeatureFlagsReducer {
	switch (action.type) {
		case SET_FEATURE_FLAGS: {
			return {
				...state,
				featureFlags: action.payload,
				loading: false,
			};
		}
		case TOGGLE_FEATURE_FLAG: {
			// do not allow this reducer to do anything in production.
			if (isProd || isHot) {
				return state;
			}

			return {
				...state,
				featureFlags: {
					...state.featureFlags,
					[action.payload as FeatureFlagsEnum]: !state.featureFlags[action.payload as FeatureFlagsEnum]
				}
			};
		}
		case SET_CHANNEL_FEATURES: {
			return {
				...state,
				channelFeatures: action.payload
			};
		}
		default: return state;
	}
}
