import Quill from "quill";
import { forwardRef } from "react";

import Portal from "utils/react-portal";
import Icon, { ICONS, COLORS } from "@general-ui/icon";
import classNames from "classnames";

type QuillToolbarProps = {
	quill?: Quill;
	colors: (string | boolean)[];
	fontSizes: (string | boolean)[];
	lineHeights: (string | boolean)[];
	textIndents: (string | boolean)[];
	disableAnchorTargets?: boolean;
};

const QuillToolbar = forwardRef<HTMLDivElement, QuillToolbarProps>(({
	quill,
	colors = [],
	fontSizes = [],
	lineHeights = [],
	textIndents = [],
	disableAnchorTargets = false,
}, ref) => {

	const handleUndo = () => {
		return quill?.history?.undo?.();
	};

	const handleRedo = () => {
		return quill?.history?.redo?.();
	};

	return (
		<Portal className="ql-toolbar-portal">
			<div ref={ref} className='ql-toolbar' data-testid="quill-toolbar">

				<span className="ql-formats extra-space">
					<select defaultValue="" className="ql-header">
						<option className="disabled" value="Heading" disabled>Heading</option>
						<option value="1">Heading 1</option>
						<option value="2">Heading 2</option>
						<option value="3">Heading 3</option>
						<option value="4">Heading 4</option>
						<option value="5">Heading 5</option>
						<option value="6">Heading 6</option>
						<option value="">Unstyled</option>
					</select>
				</span>

				<span className="ql-formats extra-space">
					<select defaultValue="" className="ql-size">
						<option className="disabled" value="Font size" disabled />
						{fontSizes.map(val => (
							<option key={val.toString()} value={(val || "") as string} />
						))}
					</select>
				</span>

				<span className="ql-formats">
					<select className="ql-line-height lineheight">
						<option className="disabled" value="Line height" disabled />
						{lineHeights.map(val => (
							<option key={val.toString()} value={(val || "") as string} />
						))}
					</select>
				</span>

				<span className="ql-formats">
					<select className="ql-text-indent textindent">
						<option className="disabled" value="Text indent" disabled />
						{textIndents.map(val => (
							<option key={val.toString()} value={(val || "") as string} />
						))}
					</select>
				</span>

				<span className="ql-formats">
					<button className="ql-bold"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-italic"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-underline"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-strike"></button>
				</span>

				<span className="ql-formats ql-formats-text-align">
					<select className="ql-text-align">
						<option className="ql-left-align" value="">
							{/* <svg width="18" height="18" viewBox="0 0 18 18"><line stroke="#ffffff" x1="3" x2="15" y1="9" y2="9"></line><line stroke="#ffffff" x1="3" x2="13" y1="14" y2="14"></line><line stroke="#ffffff" x1="3" x2="9" y1="4" y2="4"></line></svg> */}
						</option>
						<option className="ql-center-align" value="center">
							{/* <svg width="18" height="18" viewBox="0 0 18 18"><line stroke="#ffffff" x1="15" x2="3" y1="9" y2="9"></line><line stroke="#ffffff" x1="14" x2="4" y1="14" y2="14"></line><line stroke="#ffffff" x1="12" x2="6" y1="4" y2="4"></line></svg> */}
						</option>
						<option className="ql-right-align" value="right">
							{/* <svg width="18" height="18" viewBox="0 0 18 18"><line stroke="#ffffff" x1="15" x2="3" y1="9" y2="9"></line><line stroke="#ffffff" x1="15" x2="5" y1="14" y2="14"></line><line stroke="#ffffff" x1="15" x2="9" y1="4" y2="4"></line></svg> */}
						</option>
						<option className="ql-justify-align" value="justify">
							{/* <svg width="18" height="18" viewBox="0 0 18 18"><line stroke="#ffffff" x1="15" x2="3" y1="9" y2="9"></line><line stroke="#ffffff" x1="15" x2="3" y1="14" y2="14"></line><line stroke="#ffffff" x1="15" x2="3" y1="4" y2="4"></line></svg> */}
						</option>
					</select>
				</span>

				<span className="ql-formats" style={{ position: 'relative' }}>
					<select className="ql-color">
						{colors.map(val => (
							<option key={(val || Math.random).toString()} value={(val || '').toString()} />
						))}
					</select>
				</span>

				<span className="ql-formats">
					<select className="ql-background">
						{colors.map(val => (
							<option key={(val || Math.random).toString()} value={(val || '').toString()} />
						))}
					</select>
				</span>

				<span className="ql-formats">
					<button className="ql-list" value="ordered"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-list" value="bullet"></button>
				</span>

				{/* This doesn't render properly because of a bunch of ul/li list style type overrides */}
				{/* <span className="ql-formats">
					<button className="ql-list" value="check"></button>
				</span> */}

				<span className="ql-formats">
					<button className="ql-blockquote"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-code-block"></button>
				</span>

				<span className={classNames("ql-formats links-container", { 'disable-anchor-tag': disableAnchorTargets })}>
					<button className="ql-link"></button>
					<button className="ql-target"><Icon name={ICONS.NEW_WINDOW} color={COLORS.WHITE} size={20} /></button>
				</span>

				<span className="ql-formats">
					<button className="ql-script" value="sub"></button>
				</span>

				<span className="ql-formats">
					<button className="ql-script" value="super"></button>
				</span>

				<span className="ql-formats">
					<button onClick={handleUndo}><Icon name={ICONS.UNDO} color={COLORS.WHITE} size={20} /></button>
				</span>

				<span className="ql-formats">
					<button onClick={handleRedo}><Icon name={ICONS.REDO} color={COLORS.WHITE} size={20} /></button>
				</span>

				<span className="ql-formats">
					<button className="ql-clean"></button>
				</span>
			</div>
		</Portal>
	);
});

export default QuillToolbar;
