import React, { Dispatch, SetStateAction, useState } from 'react';
import classNames from 'classnames';

import { HomepageFooter, HomepageHeader, PageModule, PageModuleType } from '../../../../../../../types/working-model';
import { hasHeaderControls } from '../../../../../../../utils/controls-utils';
import Icon, { COLORS, ICONS } from '../../../../../../general-ui/icon';
import HomepageHeaderAdvancedLayout from './advanced-settings-options/homepage-header-advanced-layout';
import MainEventBannerAdvancedLayout from './advanced-settings-options/main-event-banner';
import AdvancedAgendaLayout from './advanced-settings-options/agenda';
import AdvancedSpeakersLayout from './advanced-settings-options/advanced-speakers-layout';
import AdvancedProductsLayout from './advanced-settings-options/advanced-products-layout';
import { AllLayoutTypes } from '../../../../../../../types/template-layouts';

import './advanced-settings.scss';

interface IAdvancedLayoutSettingsProps {
	selectedModule: PageModuleType | HomepageHeader | HomepageFooter;
	onUpdate?: (data: { sticky: boolean; signInBtn: boolean; }) => void;
	module?: PageModule;
	moduleProps?: {
		setSelectedDisplayOptions: Dispatch<SetStateAction<number | null>>,
		selectedDisplayOptions: number | null,
		selectedLayout: AllLayoutTypes | null,
		speakerImageRadius: boolean | null,
		setSpeakerImageRadius: Dispatch<SetStateAction<boolean | null>>,
	}
}

const AdvancedLayoutSettings: React.FC<IAdvancedLayoutSettingsProps> = (props) => {
	const {
		selectedModule,
		onUpdate,
		module,
		moduleProps,
	} = props;

	const {
		setSelectedDisplayOptions,
		selectedDisplayOptions,
		selectedLayout,
		speakerImageRadius,
		setSpeakerImageRadius,
	} = moduleProps || {};

	const [displayAdvancedSettings, setDisplayAdvancedSettings] = useState(false);

	function getLayoutAdvancedSettings() {
		if (hasHeaderControls(selectedModule)) {
			return (<HomepageHeaderAdvancedLayout module={selectedModule} onUpdate={onUpdate} />);
		}

		if (!module) return null;

		switch (selectedModule) {
			case PageModuleType.main_event_banner:
				return <MainEventBannerAdvancedLayout module={module} />;
			case PageModuleType.agenda:
				return <AdvancedAgendaLayout module={module} />;
			case PageModuleType.speakers:
				return <AdvancedSpeakersLayout
					module={module}
					setSelectedDisplayOptions={setSelectedDisplayOptions}
					selectedDisplayOptions={selectedDisplayOptions}
					selectedLayout={selectedLayout}
					speakerImageRadius={speakerImageRadius}
					setSpeakerImageRadius={setSpeakerImageRadius}
				/>;
			case PageModuleType.products:
				return <AdvancedProductsLayout
					module={module}
					setSelectedDisplayOptions={setSelectedDisplayOptions}
					selectedDisplayOptions={selectedDisplayOptions}
				/>;
			default: return null;
		}
	}

	const layoutAdvancedSettings = getLayoutAdvancedSettings();

	if (!layoutAdvancedSettings) {
		return <></>;
	}

	return (
		<div className={classNames("advanced-settings", { 'open': displayAdvancedSettings })}>
			<div className="text-container">
				<h5 className="title">Advanced settings</h5>
				<button
					className={`no-style ${!displayAdvancedSettings ? 'arrow-down' : ''}`}
					onClick={() => setDisplayAdvancedSettings(!displayAdvancedSettings)}
				>
					<Icon
						name={ICONS.KEYBOARD_ARROW_UP}
						color={COLORS.WHITE}
						size={16}
					/>
				</button>
			</div>
			{/* using style visibility instead of turning the section on/off so that it will hold it's space
			even when hidden and prevent user having to scroll down again when opening up the section */}
			<div style={{ visibility: displayAdvancedSettings ? "visible" : "hidden" }} className="content-container">
				{layoutAdvancedSettings}
			</div>
		</div>
	);
};

export default AdvancedLayoutSettings;
