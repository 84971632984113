import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { useGetEditorScale } from "hooks/use-get-editor-scale";

interface ISetBannerHeightProps {
	bannerTextRef: React.RefObject<HTMLDivElement>;
	hasAnnouncements: boolean;
}

const useSetBannerHeight = ({ bannerTextRef, hasAnnouncements }: ISetBannerHeightProps) => {
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const scale = useGetEditorScale();
	const [forceRerender, setForceRerender] = useState(0);
	useEffect(() => {
		setForceRerender(prev => prev + 1);
	}, [editorSize]);

	// The banner height has to be set at the global level  because of how the event page is nested together.
	// but because the banner and the player are siblings we need the scope of the variable to be for the  whole page. 
	useLayoutEffect(() => {
		if (bannerTextRef.current?.getBoundingClientRect()?.height) {
			const bannerHeight = bannerTextRef.current?.getBoundingClientRect()?.height;
			const height = bannerHeight <= 1 || !hasAnnouncements ? '0px' : `${bannerHeight}px`;
			document.documentElement.style.setProperty('--banner-height', height);
		}
		// reset to zero on unmount, because this hook is called within announcements-v2.tsx which means
		// if it's unmounted there are no announcements and we must reset the height to zero to reposition the navbar correctly
		return () => {
			// Note: must be `0px` not `0` or it will do funky things
			document.documentElement.style.setProperty('--banner-height', '0px');
		};
	}, [bannerTextRef, forceRerender, hasAnnouncements, scale]);
};

export default useSetBannerHeight;
