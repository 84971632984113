import { useSelector } from 'react-redux';
import { PropsWithChildren, useEffect, useState } from 'react';

import { EventsState } from '../../../store/types';
import { EPermissions } from '../../../types/working-model';
import { userHasRoleOnActiveChannel } from '../../../utils/utils';

interface AuthWrapperProps {
	allowedRoles?: string[]
	blockedRoles?: EPermissions[];
}

export function AuthWrapper({ allowedRoles = [], blockedRoles = [], children }: PropsWithChildren<AuthWrapperProps>): JSX.Element {
	const { user } = useSelector((state: EventsState) => state.AuthReducer);

	const [hasPermission, setHasPermission] = useState<boolean>(false);

	useEffect(() => {
		const defaultAllowRoles = [EPermissions.Owner, EPermissions.Admin, EPermissions.Builder].filter(role => !blockedRoles.includes(role));

		const hasRole = userHasRoleOnActiveChannel([...allowedRoles, ...defaultAllowRoles], user);
		setHasPermission(hasRole);
	}, [user, allowedRoles, blockedRoles]);

	if (hasPermission) {
		return <>{children}</>;
	}

	return <></>;
}