import React, { Suspense, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { EventsState } from '../../../../store/types';
import { TemplateClassNames, TemplateNames } from '../../../../types/template-layouts';
import { EPreviewTypes, EventHomepage, PageModuleType } from '../../../../types/working-model';
import { checkIsLive } from '../../../../utils/live-checker';
import MainEventBannerLive from '../../modules/main-event-banner-live/main-event-banner-live';
import Footer from '../footer/footer';
import { OptionalComponent } from '../../../../utils/optional-component';
import { getTemplateClassName, sortSessionsByTime } from '../../../../utils/utils';
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from '../../../../utils/error-boundary';
import { clearErrorBoundaryComponents } from '../../../../store/actions/event/event-actions';
import '../../../../scss/live-event/base/marketing-page/marketing-registered-agenda.scss';
const Agenda = React.lazy(() => import('../../modules/agenda/agenda'));

const MarketingRegisteredAgenda: React.FC = () => {
	const {
		LiveEventReducer: { eventBundle },
	} = useTypedSelector((event: EventsState) => ({
		LiveEventReducer: event.LiveEventReducer
	}));
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const isPreview = previewMode === EPreviewTypes.Agenda;

	const homepage = isPreview ? workingEvent?.homepage : eventBundle?.homepage;
	const template = (isPreview ? workingEvent?.template.name : eventBundle?.template.name) || TemplateNames.Classic;
	let sessions = isPreview ? workingEvent?.sessions : eventBundle?.sessions;
	if (!sessions) sessions = []; // fixes crashing app during the loading phase

	const dispatch = useDispatch();

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const renderAgendaModules = useMemo(() => {
		if (isPreview) {
			if (!workingEvent || !homepage) return null;
			// FUTURE DEV: see comment below about live banner in preview modal
			// const liveSessionList = workingEvent.sessions?.filter(session => SessionTypesFeatures[session.session_type].has_start_time);

			// find and copy homepage agenda module to be reused for Agenda page
			// using post-registered module since this is a post-registered agenda view
			const homepageAgenda = homepage.post_register_home_modules.find(_m => _m.type === PageModuleType.agenda);
			let agenda = homepageAgenda && { ...homepageAgenda };

			if (agenda?.content?.layout_type) {
				if (getTemplateClassName(template) === TemplateClassNames.O2) {
					agenda = {
						...agenda,
						content: {
							...agenda.content,
							layout_type: 'vertical_card'
						}
					};
				} else {
					agenda = {
						...agenda,
						content: {
							...agenda.content,
							layout_type: 'standard'
						}
					};
				}
			}

			return agenda && sessions ? (
				<Suspense fallback="">
					<Agenda
						isLive={true}
						module={agenda}
						template={getTemplateClassName(template)}
						sessions={sessions}
						key={agenda.id}
						eventBundle={workingEvent}
						showTitle={true}
						showMyList
					/>
				</Suspense>
			) : null;

		} else {
			if (!eventBundle || !homepage) return null;

			const sortedSessions = eventBundle.sessions?.sort(sortSessionsByTime) ?? [];

			const liveSessionList = sortedSessions?.filter(checkIsLive);

			// find and copy homepage aganda module to be reused for Agenda page
			// using post-registered module since this is a post-registered agenda view
			const homepageAgenda = homepage.post_register_home_modules.find(_m => _m.type === PageModuleType.agenda);

			let agenda = homepageAgenda && { ...homepageAgenda };

			// for all layouts except calendar the agenda page displays differently than the agenda module views
			if (agenda?.content?.layout_type && agenda?.content?.layout_type !== 'calendar') {
				if (getTemplateClassName(template) === TemplateClassNames.O2) {
					agenda = {
						...agenda,
						content: {
							...agenda.content,
							layout_type: 'vertical_card'
						}
					};
				} else {
					agenda = {
						...agenda,
						content: {
							...agenda.content,
							layout_type: 'standard'
						}
					};
				}
			}

			const mainEventBanner = homepage.post_register_home_modules.find(_m => _m.type === PageModuleType.main_event_banner);
			const enableHighlightLiveBanner = mainEventBanner?.content?.enable_live_banner ?? true;

			return agenda && mainEventBanner ? (
				<>
					<OptionalComponent display={!!liveSessionList.length && !!mainEventBanner && enableHighlightLiveBanner}>
						<ErrorBoundary uniqueLabel="Agenda page live banner">
							<MainEventBannerLive
								module={mainEventBanner}
								template={getTemplateClassName(template)}
								key={mainEventBanner?.id}
								liveSessionList={liveSessionList}
							/>
						</ErrorBoundary>
					</OptionalComponent>

					<Suspense fallback="">
						<ErrorBoundary uniqueLabel="Agenda page agenda module">
							<Agenda
								isLive={true}
								module={agenda}
								template={getTemplateClassName(template)}
								sessions={sessions ?? []}
								key={agenda.id}
								eventBundle={eventBundle}
								showTitle={true}
								showMyList
								editable={false}
							/>
						</ErrorBoundary>
					</Suspense>
				</>
			) : null;
		}
	}, [eventBundle, homepage, sessions, template, isPreview, workingEvent]);

	return (
		<div className={'marketing-registered-page agenda-wrapper'}>
			<div className="registered-main-content">
				{renderAgendaModules}
				{homepage?.footer && workingEvent?.settings.display_session_footer !== false && (
					<ErrorBoundary uniqueLabel="Agenda page footer">
						<Footer footer={homepage.footer} template={getTemplateClassName(template)} />
					</ErrorBoundary>
				)}

			</div>
		</div>
	);
};

export default MarketingRegisteredAgenda;
