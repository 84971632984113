import { CreateSpeaker, LanguagesAbbr, Speaker } from "../types/working-model";
import { Delete, Get, Post, Put } from "./helpers";

export const GetSpeakers = async (channel: number, token: string): Promise<Speaker[]> => {
	return Get(`/v3/admin/events/speakers/${channel}`, token, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const SearchSpeakers = async (channel: number, token: string, value: string): Promise<any> => {
	return Post(`/v3/admin/events/speakers/search/${channel}`, token, { value }, false, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const InsertSpeaker = async (
	token: string,
	speaker: CreateSpeaker | Speaker,
	languages?: string[],
	baseLanguage?: LanguagesAbbr,
): Promise<any> => {
	return Post('/v3/admin/events/speakers', token, { speaker, languages, baseLanguage }, false, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const SaveSpeaker = async (token: string, speaker: Speaker, languages?: string[], baseLanguage?: LanguagesAbbr): Promise<any> => {
	return Put('/v3/admin/events/speakers', token, { speaker, languages, baseLanguage }, false, true)
		.then(res => {
			return res.ok;
		});
};

export const DeleteSpeaker = async (token: string, channel: number, speakerId: number): Promise<any> => {
	return Delete(`/v3/admin/events/speakers/${speakerId}`, token, { channel }, true);
};
