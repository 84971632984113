import { Session, SessionTypesEnum } from "../../types/working-model";

export function getLiveBreakoutSessions(sessions: Session[]) {
	if (!sessions) return [];

	const now = Date.now();
	const isBreakoutRoomLive = (s: Session) => {
		return s.session_type === SessionTypesEnum.breakoutRooms
			&& s.timestamp
			&& s.end_timestamp
			&& s.timestamp <= now
			&& s.end_timestamp >= now;
	};

	return sessions
		.filter(isBreakoutRoomLive)
		.map(s => s.uuid);
}