import images from '../images';
import { ReactionConfig } from '../types/working-model';


export const reactionIconMap: Record<string, string> = {
	'heart': images.heart,/**@deprecated*/
	'shocked': images.shocked,/**@deprecated*/
	'thumbsUp': images.thumbsUp,/**@deprecated*/
	'100%': images.oneHundred,
	'Bottle': images.bottle,
	'Brainbuster': images.brainbuster,
	'Cap': images.cap,
	'Clap': images.clap,
	'Cowboy': images.cowboy,
	'Cute': images.cute,
	'Fan': images.fan,
	'Flame': images.flame,
	'Flirt': images.flirt,
	'Folded-Hands': images.foldedHands,
	'Heart-Pink': images.heartPink,
	'Heart-Eyes': images.heartEyes,
	'Heart-Wink': images.heartWink,
	'LOL': images.lol,
	'Party': images.party,
	'Party-Popper': images.partyPopper,
	'Praise': images.praise,
	'Rock-On': images.rockOn,
	'Sad': images.sad,
	'Smile-1': images.smile1,
	'Smile-2': images.smile2,
	'Smile-3': images.smile3,
	'Smile-4': images.smile4,
	'Star-Eyes-1': images.starEyes1,
	'Star-Eyes-3': images.starEyes3,
	'Sunglasses-1': images.sunglasses1,
	'Sunglasses-2': images.sunglasses2,
	'Thumbs-Up2': images.thumbsUp2,
	'Thumbs-Up-Smiley': images.thumbsUpSmiley
};

const excludedFromDefaults = ['heart', 'shocked', 'thumbsUp'];

const getDefaultReactions = (): ReactionConfig[] => {
	const defaults = Object.keys(reactionIconMap).filter(key => !excludedFromDefaults.includes(key));
	return defaults.map(key => {
		return {
			enabled: true,
			color: '#FFF',
			opacity: 0,
			icon: key,
			name: key?.replaceAll('-', ' '),
			description: key,
		};
	});
};

export const defaultReactions: ReactionConfig[] = getDefaultReactions();