import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SetItemContentType } from '../../../connection/multicloud/upload';
import { GetUploadEndpoint, UploadFile, UploadFileToProvider } from '../../../connection/uploads';
import { UploadedVideo } from '../../../connection/videos';
import { finishUploadJob } from '../../../store/actions/admin/videos';
import { UploadJob } from '../../../store/reducers/admin/videos';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import FileInput from '../../../utils/file-input';
import { getVidDuration, removeIllegalChars } from '../../../utils/utils';
import getVideoThumbnail from '../../../utils/video-thumbnail-generator';
import { showAlertLong } from '../alert/alert-service';
import './uploader.scss';

interface UploadingJob extends UploadJob {
	progress: number;
	done: boolean;
	totalUploadedBytes: number;
	lastUploadedBytes: number;
	totalBytesToUpload: number;
	filename: string;
}

interface InputRefs {
	[key: string]: HTMLInputElement | null;
}

const Uploader: React.FC = () => {
	const token = useTypedSelector(state => state?.AuthReducer?.token);
	const user = useTypedSelector(state => state?.AuthReducer?.user);
	const uploadJobs = useTypedSelector(state => state?.VideosReducer?.uploadJobs);

	const [jobsInProgress, setJobsInProgress] = useState<UploadingJob[]>([]);
	const inputElements = useRef<InputRefs>({});
	const dispatch = useDispatch();

	useEffect(() => {
		const currentJobs = jobsInProgress.map(job => job.uuid);

		//here we want to track the newly added ones so we can trigger their input elements
		const newJobs: UploadingJob[] = uploadJobs.filter(job => {
			return !currentJobs.includes(job.uuid);
		}).map(job => {
			const uuid = job.uuid;

			//wait until after the next render to show the element
			setTimeout(() => {
				inputElements.current[uuid]?.click();
			}, 500);

			return {
				...job,
				progress: 0,
				done: false,
				totalUploadedBytes: 0,
				lastUploadedBytes: 0,
				totalBytesToUpload: 0,
				filename: ''
			};
		});

		setJobsInProgress([...jobsInProgress, ...newJobs]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadJobs]);

	function handleUploadProgress(uuid: string) {
		return (total: number) => {
			setJobsInProgress(_jobs => _jobs.map(job => {
				if (job.uuid === uuid) {
					//handle progress
					job.totalUploadedBytes += (total - job.lastUploadedBytes);
					job.progress = Math.floor(job.totalUploadedBytes / job.totalBytesToUpload * 100);
					job.lastUploadedBytes = total;
				}
				return job;
			}));
		};
	}

	function handleFileChange(job: UploadingJob) {
		return async ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
			let uuid: string;
			let filename = '';
			try {
				if (!files?.length || !token || !user) return;

				const fileToUpload = files[0];
				uuid = job.uuid;
				const cleanedName = removeIllegalChars(fileToUpload.name);
				filename = fileToUpload.name;
				if (job.onStarted) job.onStarted(uuid);

				setJobsInProgress(jobsInProgress => jobsInProgress.map(_job => {
					if (_job.uuid === uuid) {
						_job.uploading = true;
						_job.totalBytesToUpload = fileToUpload.size;
						_job.filename = cleanedName;
					}

					return _job;
				}));

				//TODO: add to video object
				const duration = await getVidDuration(fileToUpload);

				//get endpoint for upload
				const endpoint = await GetUploadEndpoint(token, cleanedName);

				//upload to S3 - handle upload progress in separate function
				const finalUrl = await UploadFileToProvider(endpoint, fileToUpload, handleUploadProgress(uuid), cleanedName);

				// updated to be endpoint.fields.Key to handle failed content type update in S3
				// if this fails in GCP, we'll need to create a conditional to handle the second param in this
				// function specifically for GCP as this has only been tested on AWS
				SetItemContentType(token, endpoint.fields.Key, fileToUpload.type).catch(console.error);

				const thumb = await getVideoThumbnail(fileToUpload) as Blob;

				//we've altered the UploadFile function to no longer support Blobs, convert to a File
				const thumbFile = new File([thumb], 'thumbnail.jpg');

				// video's are being sent in as Blobs instead of Files, so there will be no thumbndail
				// it will just return an empty string
				const thumbnail = await UploadFile(user, token, thumbFile);

				//start conversion to HLS
				await UploadedVideo(token, {
					type: "uploaded",
					name: cleanedName,
					source: finalUrl,
					use_thumbnail: false,
					image: thumbnail,
					original_image: thumbnail,
					duration: duration
				});

				showAlertLong({
					message: `${cleanedName} Uploaded`,
					description: "Please allow several minutes for the video to be available for playback.",
					duration: 10000
				});

				//call callback
				setJobsInProgress(_jobs => _jobs.map(_job => {
					if (_job.uuid === uuid) {
						_job.done = true;
						_job.onComplete({
							type: "uploaded",
							name: cleanedName,
							source: endpoint.url + "/" + endpoint.fields.Key,
							use_thumbnail: false,
							image: "",
							original_image: ""
						});
					}
					return _job;
				}));

				//allow "Done" message for 5 seconds, then remove from list
				setTimeout(() => {
					setJobsInProgress(jobs => jobs.filter(_job => {
						return _job.uuid !== uuid;
					}));
					dispatch(finishUploadJob(uuid));
				}, 5000);
			} catch (e) {
				console.error(e);
				setJobsInProgress(jobs => jobs.filter(_job => {
					return _job.uuid !== uuid;
				}));
				showAlertLong({
					message: "Upload error",
					description: `There was an error uploading ${filename ? filename : 'your content'}, please try again.`,
					type: "error"
				});
			}
		};
	}

	return (
		<div className="uploader-container">
			{jobsInProgress.map((job) => {
				return (
					<React.Fragment key={job.uuid}>
						{job.filename && (
							<div className="uploading-job">
								{job.done ? (
									<span>Uploading {job.filename} <span className="progress">Done!</span></span>
								) : (
									<span>Uploading {job.filename} <span className="progress">{job.progress}%</span></span>
								)}
							</div>
						)}

						<FileInput type="file" accept={["video/mp4"]} style={{ display: 'none' }} onChange={handleFileChange(job)} ref={ref => inputElements.current[job.uuid] = ref} />
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Uploader;