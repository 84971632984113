import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { LeaderboardEntry } from "../../../../types/leaderboard";
import { useAvatarIsEnabled } from "../../../../utils/avatar-is-enabled";
import { OptionalComponent } from '../../../../utils/optional-component';
import { getInitialsFromProfile } from '../../../../utils/utils';

const LeaderboardAvatar: React.FC<{ entry: LeaderboardEntry; badge?: boolean }> = ({ entry, badge = false }) => {

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const event = eventBundle ?? workingEvent;

	const profile = entry.profile;
	const avatar = profile?.avatar;
	const avatarEnabled = useAvatarIsEnabled(event);
	const displayAvatar = avatarEnabled && avatar;
	const avatarStyle = (displayAvatar ? { backgroundImage: `url(${avatar})` } : {});
	const initials = getInitialsFromProfile(profile);

	return (
		<div className="entry-avatar-wrapper" >
			<div className="entry-avatar" style={{ ...avatarStyle }}>
				<OptionalComponent display={badge}>
					<div className="badge">{entry.rank}</div>
				</OptionalComponent>
				{!displayAvatar ? initials : null}
			</div>
		</div>
	);
};

export default LeaderboardAvatar;
