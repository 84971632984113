import React, { lazy, Suspense, useEffect, useState } from "react";
import classNames from 'classnames';
import { useParams } from "react-router";

import { useSessionTrackText } from '../../../../../hooks/agenda.hooks';
import { useIsNewModuleGrouping } from '../../../../../hooks/session.hooks';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import {
	getSessionUserSurveyRating,
	setSessionUserSurveyRating,
} from "../../../../../store/actions/event/event-actions";
import {
	ContentEditors,
	PageModule,
	Session,
	SessionTypesEnum,
} from "../../../../../types/working-model";
import { useScreenMediaQuery } from "../../../../../utils/use-screen-media-query";
import { ParamsProps } from '../../../live-event';
import { getStylingOverrides } from '../../../utils';
import { TypographyItem } from '../../../../general-ui/typography-item/typography-item';
import { getDefaultModuleAlignment, getTemplateClassName, getTextValue } from "../../../../../utils/utils";
import RenderEditorDescription from '../../../../general-ui/editable-text/render-editor-description';
import { useAppDispatch, useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { OptionalComponent } from '../../../../../utils/optional-component';
import { useTextLinesCounter } from "../../hooks/session-hooks";
import * as Signals from '../../../../../utils/event-emitter';
import DescriptionActions from "./description-actions";
import { DurationText, StreamedDate } from "../session-details-modal/session-details-modal";

const UserSurveyModal = lazy(() => import("../../../../general-ui/user-survey-modal/user-survey-modal"));

import '../../../../../scss/live-event/base/session/description.scss';

interface DescriptionProps {
	module: PageModule;
	template: string;
	session: Session;
	isEditor?: boolean;
}

const SessionDescription: React.FC<DescriptionProps> = ({ module, template, session, isEditor }) => {
	const { t: sessionT } = useTranslate(`session.${session.session}`);
	const { t } = useTranslate("session");
	const params: ParamsProps = useParams();
	const dispatch = useAppDispatch();
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const { isLessThan640 } = useScreenMediaQuery();
	const [surveyId, setSurveyId] = useState<number | null>(null);
	const [showFeedbackSurveyAlert, setShowFeedbackSurveyAlert] = useState(false);
	const [sessionStarted, setSessionStarted] = useState(false);

	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const sessionUuid = session?.uuid;
	const language = params?.language || 'en';
	const sessionUserSurveyRatings = useTypedSelector(state => state.LiveEventReducer.sessionFeedbackSurveyRatings);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const eventBundleContentEditor = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.content_editor);
	const workingEventContentEditor = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.content_editor);
	const ratingForSurvey: number | undefined = sessionUserSurveyRatings[sessionUuid];
	const contentEditor = eventBundleContentEditor || workingEventContentEditor;

	const setFeedbackRating = (rating: number) => {
		if (session.uuid) {
			return dispatch(setSessionUserSurveyRating(session.uuid, rating));
		}
	};

	useEffect(() => {
		if (!sessionStarted) {
			const pastStartTime = session?.timestamp
				? session.timestamp - Date.now() < 0
				: true; // null timestamp for on demand, so it's always past the start time

			if (pastStartTime) {
				setSessionStarted(true);
			}
		}
	}, [eventBundle, session, sessionStarted]);

	useEffect(() => {
		if (showFeedbackSurveyAlert && sessionUuid && blProfileUser?.uuid && ratingForSurvey === undefined && token) {
			dispatch(getSessionUserSurveyRating(sessionUuid, blProfileUser.uuid, token));
		}

	}, [sessionUuid, blProfileUser, token, ratingForSurvey, dispatch, showFeedbackSurveyAlert]);

	const { styling_overrides, content } = module;
	const { custom_heading, title, description } = getTextValue(content, language);
	const sessionDescription = sessionT(`session.${session.session}:description.${module.id}.description`, sessionT(`session.${session.session}:description.description`, description));

	const { containerRef, linesCount } = useTextLinesCounter(sessionDescription);

	const trackText = useSessionTrackText({
		language,
		tracks: session?.tracks || [],
	});

	const stylingOverrides = getStylingOverrides(styling_overrides);
	const {
		titleOverrides,
		customHeadingOverrides,
		descriptionOverrides,
	} = stylingOverrides;

	const handleDescriptionClick = () => {
		Signals.broadcastSignal('session-details-modal:open');
	};

	const isMobile = isLessThan640;
	const shouldShowMoreButton = isMobile && linesCount > 4 || !isMobile && linesCount > 2;

	const shouldUseQuill = contentEditor === ContentEditors.quill;

	if (!module.is_on) return null;
	return (
		<>
			<section className={classNames(
				'session-module description',
				stylingOverrides.backgroundColor,
				template
			)}
				style={stylingOverrides.backgroundImg}
			>
				<OptionalComponent display={!isModuleGroupingV2}>
					<TypographyItem
						tagName="h5"
						className={classNames('evt-heading-5 custom-title', customHeadingOverrides)}
						template={template}
					>
						{sessionT(`session.${session.session}:description.${module.id}.heading`, sessionT(`session.${session.session}:description.heading`, custom_heading))}
					</TypographyItem>
				</OptionalComponent>

				<div className="description-content">
					<OptionalComponent display={isModuleGroupingV2 && !!trackText}>
						<p className="description-track evt-typography-subtitle-3">
							{trackText}
						</p>
					</OptionalComponent>
					<TypographyItem
						tagName="div"
						className={
							classNames(
								'title',
								{
									'evt-heading-3': !shouldUseQuill && !isModuleGroupingV2,
									'evt-typography-h3': !shouldUseQuill && isModuleGroupingV2
								},
								titleOverrides
							)
						}
						template={template}
					>
						<div dangerouslySetInnerHTML={{ __html: sessionT(`session.${session.session}:description.${module.id}.title`, sessionT(`session.${session.session}:description.title`, title)) }} />
					</TypographyItem>
					{session.session_type !== SessionTypesEnum.onDemand && <StreamedDate session={session} language={language} t={t} />}
					{session.session_type === SessionTypesEnum.onDemand && <DurationText session={session} />}

					<TypographyItem
						tagName="div"
						className={classNames({ 'description evt-body-text-1': !isModuleGroupingV2, 'description-text evt-typography-subtitle-b3': isModuleGroupingV2 })}
						template={template}
					>
						{isModuleGroupingV2 ? (
							<div className={classNames(descriptionOverrides)} ref={containerRef} dangerouslySetInnerHTML={{ __html: sessionDescription }} />
						) : (
							<RenderEditorDescription
								description={sessionDescription}
								descriptionOverrides={classNames(descriptionOverrides)}
								useNew={shouldUseQuill}
								defaultTextAlignment={getDefaultModuleAlignment(module, template)}
							/>
						)}
						{(shouldShowMoreButton && isModuleGroupingV2) && (
							<button
								className="description-more evt-typography-h7 no-style no-padding"
								onClick={handleDescriptionClick}
							>
								{t('Show more')}
							</button>
						)}
					</TypographyItem>
				</div>

				<OptionalComponent display={isModuleGroupingV2}>
					<DescriptionActions
						template={template}
						session={session}
						setShowFeedbackSurveyAlert={setShowFeedbackSurveyAlert}
						isEditor={isEditor}
					/>
				</OptionalComponent>
			</section>

			<OptionalComponent display={isModuleGroupingV2 && showFeedbackSurveyAlert}>
				<Suspense fallback="">
					<UserSurveyModal
						setFeedbackRating={setFeedbackRating}
						setShowFeedbackSurveyAlert={setShowFeedbackSurveyAlert}
						onClose={() => {
							setSurveyId(null);
							setShowFeedbackSurveyAlert(false);
						}}
						template={getTemplateClassName(template)}
						feedbackRating={sessionUserSurveyRatings[sessionUuid] || 0}
						surveyID={surveyId || null}
						language={language}
						sessionUuid={sessionUuid}
					/>
				</Suspense>
			</OptionalComponent>
		</>
	);
};

export default SessionDescription;
