import React from 'react';
import ReactDOM from 'react-dom';

interface IModalPortal {
	children: React.ReactNode;
	className?: string;
}

export default class ModalPortal extends React.Component<IModalPortal> {
	node = document.getElementById('modal-global-container');

	componentDidMount() {
		if (this.props.className) {
			if (this.node) {
				const classes = this.props.className.split(' ');
				this.node.classList.add(...classes);
			}
		}
	}

	componentWillUnmount(): void {
		if (this.node && this.props.className) {
			const classes = this.props.className.split(' ');
			this.node.classList.remove(...classes);
		}
	}

	render(): any {
		return ReactDOM.createPortal(
			this.props.children,
			this.node as HTMLElement
		);
	}
}
