import Icon, { COLORS } from "../icon";
import './button-plain.scss';

interface IButtonPlainProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	icon?: string;
	color?: string;
	iconSize?: number;
	iconAfterText?: boolean;
	className?: string;
	style?: React.CSSProperties;
	onclick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonPlain: React.FC<React.PropsWithChildren<IButtonPlainProps>> = ({
	icon,
	iconSize = 16,
	iconAfterText,
	color = '',
	onclick,
	children,
	style = { color: COLORS.CYAN },
	className = '',
	...rest
}) => {
	return (
		<button
			className={`clear no-style button-plain ${className}`}
			onClick={onclick}
			style={style}
			{...rest}
		>
			{iconAfterText ?
				<>
					{children}
					{icon ? <div className="button-plain-svg-container right-icon"><Icon name={icon} size={iconSize} color={color || COLORS.CYAN} /></div> : null}
				</> : <>
					{icon ? <div className="button-plain-svg-container"><Icon name={icon} size={iconSize} color={color || COLORS.CYAN} /></div> : null}
					{children}
				</>}

		</button>
	);
};

export default ButtonPlain;
