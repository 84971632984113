import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Icon, { COLORS, ICONS, IconType } from '../icon';
import { LazyMotion, domAnimation, m } from "framer-motion";
import '../../../scss/live-event/base/general-ui/button.scss';

type TTagName =
	| 'a'
	| 'button'
	| 'span'
	| 'div';

interface Props {
	children: ReactNode;
	classButton?: string;
	url?: string;
	template?: string;
	isRound?: boolean;
	isSmall?: boolean;
	tagName?: TTagName;
	type?: 'primary';
	typeBtn?: string;
	isDisabled?: boolean;
	onClick?: (e?: any) => void;
	icon?: { name: IconType };
	ariaLabel?: string;
	animate?: boolean;
	newTab?: boolean;
	title?: string;
	style?: React.CSSProperties;
}

export const Button = ({
	children,
	classButton,
	url,
	template,
	isRound,
	isSmall,
	tagName = 'button',
	type,
	typeBtn,
	isDisabled,
	onClick,
	icon,
	ariaLabel,
	animate = false,
	newTab = true,
	title,
	style
}: Props): JSX.Element => {
	const TagName = `${tagName}` as keyof JSX.IntrinsicElements;

	if (animate && url) {
		console.warn('Cannot animate <Button /> as an anchor tag');
	}

	return animate ? (
		<LazyMotion features={domAnimation}>
			<m.button
				transition={{
					type: "spring",
					stiffness: 500,
					damping: 10,
					velocity: 1.1
				}}
				style={style}
				whileTap={{ scale: 0.9 }}
				onClick={onClick}
				type={typeBtn as any}
				role={typeBtn === 'button' ? 'button' : undefined}
				aria-label={ariaLabel}
				className={classNames({
					round: isRound,
					small: isSmall,
					primary: type === 'primary',
				}, classButton, template ? template + ' evt-button' : '', isDisabled ? ' disabled' : '')}
				disabled={isDisabled}
				title={title}
			>{icon && (
				<Icon name={ICONS[icon.name]} size={16} color={COLORS.WHITE} />
			)}
				{children}
			</m.button>
		</LazyMotion>
	) : (
		<TagName
			onClick={onClick}
			href={url ? url : undefined}
			type={typeBtn as any}
			role={typeBtn === 'button' ? 'button' : undefined}
			aria-label={ariaLabel}
			target={tagName === "a" && newTab ? "_blank" : undefined}
			title={title}
			style={style}
			className={classNames({
				round: isRound,
				small: isSmall,
				primary: type === 'primary',
			}, classButton, template ? template + ' evt-button' : '', isDisabled ? ' disabled' : '')}
		>
			{icon && (
				<Icon name={ICONS[icon.name]} size={16} color={COLORS.WHITE} />
			)}
			{children}
		</TagName>
	);
};
