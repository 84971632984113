import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { debounce } from "underscore";
import {
	ColorResult,
	SketchPicker,
} from 'react-color';

import EyeDropper from "@admin/create/settings/design/colors/eye-dropper/eye-dropper";
import { ColorValueIndexes } from "types/theme-packs";
import hexToReactColorResult from "utils/colors/hex-to-react-color-result";
import { OptionalComponent } from "utils/optional-component";

import "./color-picker.scss";

const isEyeDropperAvailable = !!window?.EyeDropper;

interface IColorPickerProps {
	/** color is a hex value, including the hash */
	color?: Array<string | number>;
	onChangeComplete?: (color: ColorResult) => void;
	width?: string;
	/** If true, the color picker will be displayed as a "card container" */
	cardContainer?: boolean;
	presetColors?: string[];
}

const ColorPicker = ({
	color,
	onChangeComplete,
	width = '100%',
	cardContainer = false,
	presetColors = [],
}: IColorPickerProps) => {

	const containerRef = useRef<HTMLDivElement>(null);
	const pickerRef = useRef<SketchPicker>(null);

	const defaultPickerColor = useMemo(() => {
		return hexToReactColorResult(
			(color?.[ColorValueIndexes.hex] ?? '#000000') as string,
			(color?.[ColorValueIndexes.opacity] ?? 1) as number
		);
	}, [color]);

	const [pickedColor, setPickedColor] = useState<ColorResult>(defaultPickerColor);

	useEffect(() => {
		if (!pickedColor) {
			setPickedColor(defaultPickerColor);
		}
	}, [pickedColor, defaultPickerColor]);

	// sketch picker is frustratingly lacking classes to target,
	// let's add some classes to make it easier to style
	useEffect(() => {
		const container = containerRef.current;
		if (!pickerRef?.current && !container) return;

		// get element by class 'hue-horizontal'
		const hueHorizontal = container?.querySelector('.hue-horizontal');
		// if hueHorizontal exists, get its immediate parent div
		const hueParent = hueHorizontal?.parentElement;
		if (!hueParent) return;
		hueParent.classList.add('hue-horizontal-parent');

		// then go up another level and select the sibling div
		const hueHorizontalWrapper = hueParent?.parentElement;
		if (!hueHorizontalWrapper) return;
		// get hueHorizontalWrapper sibling div
		const alphaWrapper = hueHorizontalWrapper?.nextElementSibling;
		if (!alphaWrapper) return;
		alphaWrapper.classList.add('alpha-wrapper');
	}, [pickerRef, containerRef]);

	const debounced = useRef(debounce((color: ColorResult) => {
		onChangeComplete?.(color);
	}, 300)).current;

	const handleColorChange = useCallback((color: ColorResult) => (callDebounce: boolean) => {
		setPickedColor(color);
		if (callDebounce) {
			debounced(color);
		}
	}, []);

	if (!pickedColor) return null;
	return (
		<div className={classNames("color-picker", { 'card-container': cardContainer })} ref={containerRef}>
			<SketchPicker
				ref={pickerRef}
				width={width}
				className="customized-color-picker"
				color={pickedColor.rgb}
				onChange={color => handleColorChange(color)(false)}
				presetColors={[]}
				onChangeComplete={color => handleColorChange(color)(true)}
				styles={{
					default: {
						controls: {
							width: '100%',
							margin: '10px 0 0 auto',
							justifyContent: 'flex-end',
						},
						hue: {
							borderRadius: '4px',
							width: 'calc(100% - 4px)',
							height: '20px',
							overflow: 'visible',
						},
						alpha: {
							borderRadius: '4px',
							marginTop: '2px',
							height: '12px',
							overflow: 'visible',
							width: 'calc(100% - 4px)',
						},
						sliders: {
							height: '32px',
							padding: '0',
							maxWidth: isEyeDropperAvailable ? 'calc(100% - 70px)' : '100%',
							marginTop: '2px',
						},
						saturation: {
							height: '292px',
						},
						picker: {
							width: 'auto',
							height: '410px',
							backgroundColor: 'inherit',
							boxShadow: 'none',
							padding: '0',
						},
						activeColor: {
							height: '32px',
							width: '32px',
							borderRadius: '4px',
						}
					}
				}}
			/>
			<OptionalComponent display={isEyeDropperAvailable}>
				<div className="color-picker-eye-dropper-container">
					<EyeDropper
						size={20}
						onSelectColor={hex => {
							const colorResult: ColorResult = hexToReactColorResult(hex, pickedColor.rgb.a ?? 1);
							handleColorChange?.(colorResult)(true);
						}}
					/>
				</div>
			</OptionalComponent>

			{presetColors.length > 0 && (
				<div className="color-picker-recent-colors-container">
					<div className="C1 color-picker-recent-colors-title">Recent</div>

					<div className="color-picker-recent-colors-list-container">
						{presetColors.map(color => (
							<button
								key={color}
								className="clear no-style color-picker-recent-colors-list-item"
								style={{ backgroundColor: color }}
								onClick={() => {
									const colorResult: ColorResult = hexToReactColorResult(color, 1);
									handleColorChange?.(colorResult)(true);
								}}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default ColorPicker;
