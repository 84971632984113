import { ChangeEvent, useState, } from 'react';

import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";

import { validateUrl } from '../../../../../../../utils/utils';

// GENERAL UI IMPORTS
import Switch from "../../../../../../general-ui/switch/switch";
import TextInput, { Validation } from "../../../../../../general-ui/text-input/text";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { updateNativeRegistration } from "../../../../../../../store/actions/admin/create-event";

import './disable-native-reg-toggle.scss';

export default function DisableNativeRegistrationToggleV2() {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const [isOn, setIsOn] = useState(!!workingEvent?.registration_settings?.externalRegistration?.isOn);
	const [externalRegUrl, setExternalRegUrl] = useState(workingEvent?.registration_settings?.externalRegistration?.url ?? '');
	const [urlError, setUrlError] = useState("");

	const dispatch = useAppDispatch();

	const onToggle = (_value: string, _isOn: boolean) => {
		setIsOn(_isOn);
		dispatch(updateNativeRegistration(_isOn, externalRegUrl));
	};

	const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setExternalRegUrl(e.target.value);
	};

	const handleClose = () => {
		setIsOn(!!workingEvent?.registration_settings?.externalRegistration?.isOn);
		setExternalRegUrl(workingEvent?.registration_settings?.externalRegistration?.url ?? '');
	};

	const handleSave = () => {
		if (!!externalRegUrl && !validateUrl(externalRegUrl)) {
			setUrlError('Invalid URL');
			return;
		}
		setUrlError("");
		dispatch(updateNativeRegistration(isOn, externalRegUrl));
	};

	return (
		<div className="settings-card-stacked">
			<div className="top-row">
				<div>
					<label>
						Disable Native Registration
					</label>
				</div>

				<Switch
					value={"In person attendee mode"}
					on={isOn ?? false}
					onClick={onToggle}
				/>
			</div>

			{isOn && (
				<div className="top-row">
					<TextInput
						placeholder="Registration URL"
						className="external-url-input"
						onChange={handleUrlChange}
						value={externalRegUrl}
						valid={urlError ? Validation.error : Validation.ok}
					/>

					<div className="registration-option-hover">
						<div className="registration-option-hover-icon" onClick={handleClose}>
							<Icon name={ICONS.CLOSE} size={16} color={COLORS.LIGHTER_GRAY} />
						</div>
						<div className="registration-option-hover-icon save" onClick={handleSave}>
							<Icon name={ICONS.CHECK} size={16} color={COLORS.LIGHTER_GRAY} />
						</div>
					</div>
				</div>
			)}
		</div>
	);
}