import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { RegistrationQuestion } from "../../../types/working-model";
import { toDict } from "../../../utils/utils";
import {
  GET_REGISTRATION_QUESTIONS,
  UPDATE_REGISTRATION_QUESTIONS,
  ADD_NEW_REGISTRATION_QUESTION,
  DELETE_REGISTRATION_QUESTION
} from "../../actions/admin/registration-questions";

export interface RegistrationQuestionsState {
  registrationQuestions: RegistrationQuestion[];
}

const initialState: RegistrationQuestionsState = {
  registrationQuestions: []
};

export default function RegistrationQuestionsReducer(
  state: RegistrationQuestionsState = initialState,
  action: Action
): RegistrationQuestionsState {
  switch (action.type) {
    case GET_REGISTRATION_QUESTIONS: {
      return handle(state, action, {
        success: state => ({ ...state, registrationQuestions: action.payload })
      });
    }
    case UPDATE_REGISTRATION_QUESTIONS: {
      const qs = toDict("registration_question", state.registrationQuestions);
      for (const question of action.payload) {
        qs[question.registration_question] = question;
      }

      return { ...state, registrationQuestions: Object.values(qs) };
    }
    case ADD_NEW_REGISTRATION_QUESTION: {
      return {
        ...state,
        registrationQuestions: [...state.registrationQuestions, action.payload]
      };
    }
    case DELETE_REGISTRATION_QUESTION: {
      return handle(state, action, {
        success: state => {
          const questionId = Number(action.payload.questionId);

          return {
            ...state,
            registrationQuestions: state.registrationQuestions.map(
              question => question.registration_question === questionId
                ? { ...question, deleted: true }
                : question
            )
          };
        }
      });
    }
    default:
      return state;
  }
}
