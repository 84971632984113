export const replaceTextAlignAttribute = ({
	htmlString,
	newValue,
	bypassIfNodeElement = false
}: {
	htmlString: string;
	newValue: string;
	bypassIfNodeElement?: boolean;
}): string => {

	// add new text align to all nodes
	let newHtml = new DOMParser().parseFromString(htmlString, 'text/html');

	const _childNodes = newHtml.body.childNodes;

	if (bypassIfNodeElement) {
		for (let i = 0; i < _childNodes.length; i++) {
			if (_childNodes[i].nodeType === Node.ELEMENT_NODE) {
				return htmlString; // return out of whole function with the same string that was passed in
			}
		}
	}

	if (_childNodes.length === 1 && _childNodes[0].nodeType !== Node.ELEMENT_NODE) {
		const _htmlString = `<div>${htmlString}</div>`;
		newHtml = new DOMParser().parseFromString(_htmlString, 'text/html');
	}

	const childNodes = newHtml.body.childNodes;
	childNodes.forEach(node => {
		if (node.nodeType === Node.ELEMENT_NODE) {
			(node as HTMLElement).style.textAlign = newValue;
		}
	});

	return newHtml.body.innerHTML;
};
