import { useEffect, useMemo, useState } from 'react';

export function useScreenMediaQuery(): {
	isLessThan480: boolean;
	isLessThan640: boolean;
	isLessThan768: boolean;
	isLessThan1024: boolean;
	isLessThan1280: boolean;
	isHLessThan480: boolean;
	isHLessThan640: boolean;
	isHLessThan768: boolean;
	isHLessThan1024: boolean;
	isHLessThan1280: boolean;
} {
	const [isLessThan480, setIsLessThan480] = useState(window.innerWidth < 480);
	const [isLessThan640, setIsLessThan640] = useState(window.innerWidth < 640);
	const [isLessThan768, setIsLessThan768] = useState(window.innerWidth < 768);
	const [isLessThan1024, setIsLessThan1024] = useState(window.innerWidth < 1024);
	const [isLessThan1280, setisLessThan1280] = useState(window.innerWidth < 1280);
	const [isHLessThan480, setIsHLessThan480] = useState(window.innerHeight <= 480);
	const [isHLessThan640, setIsHLessThan640] = useState(window.innerHeight <= 640);
	const [isHLessThan768, setIsHLessThan768] = useState(window.innerHeight <= 768);
	const [isHLessThan1024, setIsHLessThan1024] = useState(window.innerHeight <= 1024);
	const [isHLessThan1280, setIsHLessThan1280] = useState(window.innerHeight <= 1280);

	useEffect(() => {
		// use timeout to prevent recaluclating on every single frame while window is being resized. 
		// wait until user pauses in their resize to recalculate

		const handleScreenUpdate = (setter: (isOn: boolean) => void) => {
			return (e: MediaQueryListEvent | MediaQueryList) => {
				setter(e.matches);
			};
		};

		const m480matcher = handleScreenUpdate(setIsLessThan480);
		const m640matcher = handleScreenUpdate(setIsLessThan640);
		const m768matcher = handleScreenUpdate(setIsLessThan768);
		const m1024matcher = handleScreenUpdate(setIsLessThan1024);
		const m1280matcher = handleScreenUpdate(setisLessThan1280);
		const h480matcher = handleScreenUpdate(setIsHLessThan480);
		const h640matcher = handleScreenUpdate(setIsHLessThan640);
		const h768matcher = handleScreenUpdate(setIsHLessThan768);
		const h1024matcher = handleScreenUpdate(setIsHLessThan1024);
		const h1280matcher = handleScreenUpdate(setIsHLessThan1280);

		const m480 = window.matchMedia('(max-width: 480px)');
		m480matcher(m480); // initializer
		m480.addEventListener("change", m480matcher); // listener

		const m640 = window.matchMedia('(max-width: 640px)');
		m640matcher(m640); // initializer
		m640.addEventListener("change", m640matcher); // listener

		const m768 = window.matchMedia('(max-width: 768px)');
		m768matcher(m768); // initializer
		m768.addEventListener("change", m768matcher); // listener

		const m1024 = window.matchMedia('(max-width: 1024px)');
		m1024matcher(m1024); // initializer
		m1024.addEventListener("change", m1024matcher); // listener

		const m1280 = window.matchMedia('(max-width: 1280px)');
		m1280matcher(m1280); // initializer
		m1280.addEventListener("change", m1280matcher); // listener

		const h480 = window.matchMedia('(max-height: 480px)');
		h480matcher(h480); // initializer
		h480.addEventListener("change", h480matcher); // listener

		const h640 = window.matchMedia('(max-height: 640px)');
		h640matcher(h640); // initializer
		h640.addEventListener("change", h640matcher); // listener

		const h768 = window.matchMedia('(max-height: 768px)');
		h768matcher(h768); // initializer
		h768.addEventListener("change", h768matcher); // listener

		const h1024 = window.matchMedia('(max-height: 1024px)');
		h1024matcher(h1024); // initializer
		h1024.addEventListener("change", h1024matcher); // listener

		const h1280 = window.matchMedia('(max-height: 1280px)');
		h1280matcher(h1280); // initializer
		h1280.addEventListener("change", h1280matcher); // listener

		return () => {
			m480.removeEventListener("change", m480matcher);
			m640.removeEventListener("change", m640matcher);
			m768.removeEventListener("change", m768matcher);
			m1024.removeEventListener("change", m1024matcher);
			m1280.removeEventListener("change", m1280matcher);
			h480.removeEventListener("change", h480matcher);
			h640.removeEventListener("change", h640matcher);
			h768.removeEventListener("change", h768matcher);
			h1024.removeEventListener("change", h1024matcher);
			h1280.removeEventListener("change", h1280matcher);
		};
	}, []);

	return {
		isLessThan480,
		isLessThan640,
		isLessThan768,
		isLessThan1024,
		isLessThan1280,
		isHLessThan480,
		isHLessThan640,
		isHLessThan768,
		isHLessThan1024,
		isHLessThan1280,
	};
}

// useMediaScreenQuery is difficult to memoize and triggers a lot of useless renders
// because it returns an object which is new each time rather than a string that can 
// be easily compared for value equality
export const useScreenLayout = (): "mobile" | "tablet" | "desktop" => {
	const sizes = useScreenMediaQuery();

	const isMobile = sizes.isLessThan640;
	const isTablet = sizes.isLessThan1024 && !isMobile;

	return useMemo(() => {
		if (isMobile) {
			return "mobile";
		}

		if (isTablet) {
			return "tablet";
		}

		return "desktop";
	}, [isMobile, isTablet]);
};