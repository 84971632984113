import { EPermissions, PERMISSIONS } from "types/working-model";

export const rolePermissionSortOrder = {
	[PERMISSIONS.Admin]: 1,
	[PERMISSIONS.GroupAdmin]: 2, // Group Manager
	[PERMISSIONS.Editor]: 3,
	[PERMISSIONS.Moderation]: 5, // Moderator
	[PERMISSIONS.Analytics]: 5,  // Analyst
	[PERMISSIONS.Producer]: 6,
	[PERMISSIONS.Builder]: 7     // Developer
};

export const allAccessRoles = [EPermissions.Owner, EPermissions.Admin, EPermissions.Builder];