import ActiveProductsList from "../extras/products/active-products-list";
import { usePageModule } from "../hooks/panel.hooks";

import { customProductsItems, extrasProductsItems } from "./constants/empty-panel";
import EmptyStatePanel from "./empty-state-panel";

export default function ExtrasProductsPanel({ custom = false }: { custom?: boolean }): JSX.Element {
	const pageModule = usePageModule();
	const hasContent = pageModule?.modules?.length;

	return hasContent ? (
		<ActiveProductsList />
	) : (
		<EmptyStatePanel
			title="Add Products"
			description="Tabs without content will not be visible to the audience"
			dropDownItems={custom ? customProductsItems : extrasProductsItems}
		/>
	);
}