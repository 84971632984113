import { useEffect, useState } from 'react';
import { Validation } from '../components/general-ui/text-input/text';

interface IUseRequiredFieldsReturn {
	requiredFields: { [key: string]: Validation };
	hasCompletedRequiredFields: (workingObject: { [key: string]: any }) => boolean;
	updateRequiredField: (key: string, value: any) => void;
	resetRequiredFields: () => void;
	shouldDisplayFieldError: (field: string) => boolean;
}

// takes an array of all required fields in working object: i.e ['name', 'description']
function useRequiredFields(fields: string[], fieldRequirementFunctions?: { [field: string]: (value: any) => boolean }): IUseRequiredFieldsReturn {

	const [requiredFields, setRequiredFields] = useState<{ [key: string]: Validation }>({ fields: Validation.normal });

	useEffect(() => {
		const initialRequiredFields = fields.reduce((a: { [key: string]: Validation }, c: string) => {
			a[c] = Validation.normal;
			return a;
		}, {});

		setRequiredFields(initialRequiredFields);
		// only run this on mount of the hook
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// update a requiredField 
	function updateRequiredField(key: string, value: any) {
		const useRequiredFunction = !!fieldRequirementFunctions?.[key];
		const validFieldValue = useRequiredFunction ? fieldRequirementFunctions?.[key](value) : !!value;

		setRequiredFields(prevState => ({
			...prevState,
			// if function isnt passed for a given key,
			// anything but an empty string passes here.
			[key]: validFieldValue ? Validation.normal : Validation.error
		}));
	}

	// Function for checking all fields before letting user submit
	function hasCompletedRequiredFields(workingObject: any) {
		const _requiredFields = { ...requiredFields };

		let fieldsCompleted = true;
		for (const field in _requiredFields) {
			// check if this field has a requirementFunction,
			// otherwise any non-empty string passes.
			const useRequiredFunction = !!fieldRequirementFunctions?.[field];
			const validFieldValue = useRequiredFunction ? fieldRequirementFunctions?.[field](workingObject[field]) : !!workingObject[field];

			if (!validFieldValue) {
				_requiredFields[field] = Validation.error;
				fieldsCompleted = false;
			}
		}
		setRequiredFields(_requiredFields);
		return fieldsCompleted;
	}

	function resetRequiredFields() {
		const _requiredFields = { ...requiredFields };

		for (const field in _requiredFields) {
			_requiredFields[field] = Validation.normal;
		}
		setRequiredFields(_requiredFields);
	}

	function shouldDisplayFieldError(field: string): boolean {
		return requiredFields[field] === Validation.error;
	}

	return {
		requiredFields,
		hasCompletedRequiredFields,
		updateRequiredField,
		resetRequiredFields,
		shouldDisplayFieldError
	};
}

export default useRequiredFields;
