import Icon, { COLORS, ICONS } from '../icon';

// these Arrows are basically wrapped functions for the Slider component, which does not handle the props on these well
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function PrevArrow(props: any): JSX.Element {
	const { className, style, onClick } = props;

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				onClick(e);
			}}
			className={className}
			style={{ ...style }}
		>
			<Icon name={ICONS.KEYBOARD_ARROW_LEFT} size={20} color={COLORS.WHITE} />
		</div>
	);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function NextArrow(props: any): JSX.Element {
	const { className, style, onClick } = props;

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				onClick(e);
			}}
			className={className}
			style={{ ...style }}
		>
			<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} size={20} color={COLORS.WHITE} />
		</div>
	);
}