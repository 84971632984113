import classNames from 'classnames';
export interface RadioOption {
	label: string;
	value: string | number;
}

interface RadioGroupProps {
	options: RadioOption[];
	onChange: (value: string | number) => void;
	selected: string | number;
	style?: React.CSSProperties;
	defaultValue?: string | number;
	allowKeyboard?: boolean;
	customClassName?: string;
}

export default function RadioGroup(props: RadioGroupProps): JSX.Element {
	const { options, onChange, selected, style, defaultValue, allowKeyboard } = props;

	const handleClick = (value: string | number) => () => onChange(value);

	const handleKeyPress = (value: string | number) => (e: React.KeyboardEvent) => {
		if (!allowKeyboard) return;
		if ([' ', 'Enter'].includes(e.key)) {
			e.preventDefault();
			onChange(value);
		}
	};

	return (
		<div className={classNames("field-group radio-group", props.customClassName ? props.customClassName : "")} style={style ? style : {}}>
			{options.map((option: RadioOption) => (
				<div
					key={option.value}
					className={
						classNames("radio-option", {
							selected: option.value === (selected || defaultValue),
							'allow-keyboard': allowKeyboard,
						})
					}
					onClick={handleClick(option.value)}
					onKeyPress={handleKeyPress(option.value)}
					tabIndex={allowKeyboard ? 0 : -1}
				>
					<div className="radio"><span /></div>
					<label>{option.label}</label>
				</div>
			))}
		</div>
	);
}