import { TooltipPortalContentDirection, TooltipPortalPosition } from "./tooltip-portal-types";

interface ISetTailPositionProps {
	tooltipChildrenRef: React.RefObject<HTMLDivElement> | null;
	portalContentRef: React.RefObject<HTMLDivElement> | null;
	tailRef: React.RefObject<HTMLSpanElement> | null;
	position?: TooltipPortalPosition;
	contentDirection?: TooltipPortalContentDirection;
	horizontalOffset?: number;
	tailSize: number;
}

export const setTailPosition = ({
	tooltipChildrenRef,
	portalContentRef,
	tailRef,
	position = TooltipPortalPosition.TOP,
	contentDirection = TooltipPortalContentDirection.LTR,
	horizontalOffset = 1,
	tailSize,
}: ISetTailPositionProps) => {
	const tooltipChildrenRect = tooltipChildrenRef?.current?.getBoundingClientRect();
	const portalContentRefHeight = portalContentRef?.current?.offsetHeight || 0;
	const _tailRef = tailRef?.current;

	if (!_tailRef) return;

	if (!tooltipChildrenRect) return;

	_tailRef.style.display = 'block';

	// reset all styles to default
	// we need to do this so that the tail does not inherit styles from previous prop value if we are dynamically
	// changing the position of the tooltip
	_tailRef.style.left = '50%';
	_tailRef.style.right = `auto`;
	_tailRef.style.bottom = `-${tailSize}px`;
	_tailRef.style.top = `auto`;
	_tailRef.style.transform = 'translate(-50%) rotate(180deg)';

	// if contentDirection is left, adjust left position to be 0 - half of the width of the tooltip
	if (position === TooltipPortalPosition.TOP) {
		if (contentDirection === TooltipPortalContentDirection.LTR) {
			_tailRef.style.left = `${(tooltipChildrenRect.width / 2) - 1}px`;
			_tailRef.style.transform = `translate(calc(-50% + ${horizontalOffset}px)) rotate(180deg)`;
			return;
		}
		// if contentDirection is right, adjust right position to be 0 + half of the width of the tooltip
		if (contentDirection === TooltipPortalContentDirection.RTL) {
			_tailRef.style.left = `calc(100% - ${(tooltipChildrenRect.width / 2) - 1 + horizontalOffset}px)`;
			return;
		}

		return;
	}

	if (position === TooltipPortalPosition.BOTTOM) {
		if (contentDirection === TooltipPortalContentDirection.LTR) {
			_tailRef.style.left = `${(tooltipChildrenRect.width / 2) - 1}px`;
			_tailRef.style.bottom = '100%';
			_tailRef.style.transform = `translate(calc(-50% + ${horizontalOffset}px)) rotate(0deg)`;
			return;
		}
		// if contentDirection is right, adjust right position to be 0 + half of the width of the tooltip
		if (contentDirection === TooltipPortalContentDirection.RTL) {
			_tailRef.style.left = `calc(100% - ${(tooltipChildrenRect.width / 2) - 1 + horizontalOffset}px)`;
			_tailRef.style.bottom = '100%';
			_tailRef.style.transform = 'translate(-50%) rotate(0deg)';
			return;
		}

		_tailRef.style.bottom = '100%';
		_tailRef.style.transform = 'translate(-50%) rotate(0deg)';
		return;
	}

	if (position === TooltipPortalPosition.RIGHT) {
		if (contentDirection === TooltipPortalContentDirection.LTR) {
			_tailRef.style.top = `${(portalContentRefHeight / 2) - 1}px`;
			_tailRef.style.left = `-${Math.floor(tailSize * 1.5)}px`; // adust tail position by half of its size
			_tailRef.style.right = `auto`;
			_tailRef.style.transform = 'translate(0, -50%) rotate(-90deg)';
			return;
		}

		_tailRef.style.display = 'none';
		return;
	}

	if (position === TooltipPortalPosition.LEFT) {
		if (contentDirection === TooltipPortalContentDirection.RTL) {
			_tailRef.style.top = `${(portalContentRefHeight / 2) - 1}px`;
			_tailRef.style.bottom = 'auto';
			_tailRef.style.right = `-${Math.floor(tailSize * 1.5)}px`; // adust tail position by half of its size
			_tailRef.style.left = 'auto';
			_tailRef.style.transform = 'translate(0, -50%) rotate(90deg)';
			return;
		}

		_tailRef.style.display = 'none';
		return;
	}

	return;
};
