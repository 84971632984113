import classNames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Field, FieldProps } from '../field/field';
import Icon, { ICONS } from '../icon';

export interface CustomCheckboxProps extends FieldProps<boolean> {
	checked?: boolean;
	size?: number;
	onChange?: (isChecked: boolean, name?: string) => void;
	onBlur?: (e: any) => void;
	unselectedIconName?: string;
	selectedIconName?: string;
	className?: string;
	color?: string;
	persistent?: boolean;
	disabled?: boolean;
	useMarkdown?: boolean;
}
// eslint-disable-next-line react/display-name
const CustomCheckbox: React.FC<CustomCheckboxProps> = React.forwardRef<
	HTMLDivElement,
	CustomCheckboxProps
>(
	(props, ref): JSX.Element => {
		const {
			checked,
			size = 16,
			onChange,
			onBlur,
			name,
			unselectedIconName = ICONS.SQUARE_CHECKBOX_UNSELECTED,
			selectedIconName = ICONS.SQUARE_CHECKBOX_SELECTED,
			className,
			label = '',
			color = '',
			disabled = false,
			useMarkdown = false
		} = props;

		const handleChange = () => {
			if (disabled) return;
			onChange?.(!checked, name);
		};
		const handleKeyDown = (e: React.KeyboardEvent) => {
			if (disabled) return;
			// if enter or spacebar, trigger click
			if (e.key === 'Enter' || e.key === " ") {
				e.stopPropagation();
				onChange?.(!checked, name);
			}
		};
		const handleBlur = (e: React.FocusEvent) => {
			if (disabled) return;
			onBlur && onBlur({ ...e, target: { name: name || '' } });
		};
		return (
			<Field {...props}>
				<div
					ref={ref}
					tabIndex={disabled ? -1 : 0}
					onBlur={handleBlur}
					className={classNames('custom-checkbox', className)}
					onClick={handleChange}
					onKeyDown={handleKeyDown}
				>
					{label
						? useMarkdown
							? <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
								{label}
							</ReactMarkdown>
							: <label style={{ color: 'white' }}>{label}</label>
						: null}
					<span style={size ? { height: size, width: size } : {}}>
						{checked ? (
							<Icon name={selectedIconName} size={size} color={color} />
						) : (
							<Icon name={unselectedIconName} size={size} color={color} />
						)}
					</span>
				</div>
			</Field>
		);
	}
);
export default CustomCheckbox;
