import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { getDefaultAgendaImage } from "../../../../../../../../store/utils/create-event";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { Session, SessionPanelLayoutsTypes, SessionTypesEnum, TranslateString } from "../../../../../../../../types/working-model";
import { getTemplateClassName } from "../../../../../../../../utils/utils";
import SuggestedCard from "./suggested-card";
import classNames from "classnames";
import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import PanelSearch from "../../components/panel-search";
import { useFinishNavigate, usePageModule } from "../../hooks/panel.hooks";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import { useDispatch } from "react-redux";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { useParams } from "react-router";
import { SessionPanelMap } from "../../session-panel-route-map";

const sortOptions = [
	{ label: 'Date', value: ESort.dateDesc },
	{ label: 'Date Reverse', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const filterOptions = [
	{ label: 'All', value: '' },
	{ label: 'Broadcast', value: SessionTypesEnum.broadcast },
	{ label: 'On Demand', value: SessionTypesEnum.onDemand },
	{ label: 'Fireside', value: SessionTypesEnum.fireside },
	{ label: 'Breakout Rooms', value: SessionTypesEnum.breakoutRooms },
];

type FilterOptions = SessionTypesEnum | '';

const translateStringToSearchString = (tString: TranslateString): string => {
	let sString = '';
	for (const key in tString) {
		if (key !== 'changed' && typeof tString[key] === 'string') {
			sString += (" " + tString[key]);
		}
	}
	return sString;
};

const sessionFilter = (sessions: Session[], filter: FilterOptions) => {
	if (!filter) {
		return sessions;
	} else {
		return [...sessions].filter(session => session.session_type === filter);
	}
};

const sessionSearch = (sessions: Session[], searchTerm: string) => {
	return [...sessions].filter(session =>
		translateStringToSearchString(session.title).toLowerCase().includes(searchTerm.toLowerCase())
	);
};

const sortSessions = (sort: ESort, sessions: Session[]) => {
	return [...sessions].sort((a: Session, b: Session) => {
		const aTimestamp = a.timestamp || a.session;
		const bTimestamp = b.timestamp || b.session;
		switch (sort) {
			case ESort.date: {
				return aTimestamp < bTimestamp ? -1 : 1;
			}
			case ESort.dateDesc: {
				return aTimestamp > bTimestamp ? -1 : 1;
			}
			case ESort.name: {
				return a.title.base.toLowerCase() < b.title.base.toLowerCase() ? -1 : 1;
			}
			case ESort.nameDesc: {
				return a.title.base.toLowerCase() > b.title.base.toLowerCase() ? -1 : 1;
			}
			default: {
				return aTimestamp < bTimestamp ? -1 : 1;
			}
		}
	});
};

type Props = {
	withinTab?: boolean;
}

const AddSuggested: React.FC<Props> = ({ withinTab }) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSessionUUID = useTypedSelector(state => state.CreateSessionReducer.workingSession?.uuid);
	const template = getTemplateClassName(workingEvent?.template.name);
	const [selectedSessions, setSelectedSessions] = useState<number[]>([]);
	const [sortOrder, setSortOrder] = useState(ESort.dateDesc);
	const [searchTerm, setSearchTerm] = useState('');
	const [saving, setSaving] = useState(false);
	const [filteredSessions, setFilteredSessions] = useState(workingEvent?.sessions.filter(s => s.uuid != workingSessionUUID) || []);
	const eventLoadedRef = useRef(false);
	const [userFilter, setUserFilter] = useState<FilterOptions>('');
	const containerRef = useRef<HTMLDivElement | null>(null);
	const { customPath } = useParams<{ customPath?: string }>();
	const pageModule = usePageModule();
	const dispatch = useDispatch();
	const adminPath = useGetAdminUrl();
	const finish = useFinishNavigate();

	useEffect(() => {
		if (pageModule?.content_modules) {
			setSelectedSessions(pageModule.content_modules);
		}
	}, [pageModule?.content_modules]);

	useEffect(() => {
		if (workingEvent?.sessions && !eventLoadedRef.current) {
			eventLoadedRef.current = true;
		}
	}, [workingEvent?.sessions]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(ESort.dateDesc);
		}
	}, []);

	const handleSearch = (term: string) => {
		setSearchTerm(term);
	};

	const handleDone = async () => {
		try {
			if (!pageModule) {
				return;
			}

			setSaving(true);

			dispatch(updatePageModuleAndSave({
				...pageModule,
				content_modules: selectedSessions,
				modules: workingEvent?.sessions?.filter(session => selectedSessions.includes(session.session)) || []
			}));

			const panelKey = customPath
				? SessionPanelLayoutsTypes.CustomSuggested
				: SessionPanelLayoutsTypes.ExtraSuggested;

			finish(adminPath({
				path: SessionPanelMap[panelKey],
				page_module: pageModule.id,
				customPath: customPath
			}));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error saving suggested sessions",
				description: "We ran into an issue saving your suggested sessions. Please try again later.",
				type: "error"
			});
		} finally {
			setSaving(false);
		}
	};

	const handleCheckboxChange = useCallback((value: number) => {
		setSelectedSessions(prev => {
			if (prev.includes(value)) {
				return prev.filter(item => item !== value);
			} else {
				return [...prev, value];
			}
		});
	}, []);

	useEffect(() => {
		if (workingEvent?.sessions && workingSessionUUID) {
			const sessions = workingEvent.sessions.filter(s => s.uuid != workingSessionUUID);
			const filteredSessions = sessionFilter(sessions, userFilter);
			const searchedSessions = sessionSearch(filteredSessions, searchTerm);
			const sortedSessions = sortSessions(sortOrder, searchedSessions);

			setFilteredSessions(sortedSessions);
		}
	}, [searchTerm, sortOrder, userFilter, workingEvent?.sessions, workingSessionUUID]);

	return (
		<div className={classNames("session-panel suggested-list", { 'has-selections': selectedSessions.length > 0 })} ref={containerRef}>
			<PanelSearch
				sortOptions={sortOptions}
				sortOrder={sortOrder}
				handleSort={handleSort}
				filterOptions={filterOptions}
				userFilter={userFilter}
				handleFilter={(filter) => setUserFilter(filter as FilterOptions)}
				handleSearch={handleSearch}
			/>

			{!withinTab && <label className="session-panel-section-label">Library</label>}

			{filteredSessions.length ? (
				<StaggerChildren
					className="session-panel-content"
				>
					{filteredSessions.map(session => (
						<SuggestedCard
							key={session.uuid}
							id={session.session}
							timestamp={session.timestamp}
							title={session.title}
							image={session.image || getDefaultAgendaImage(template, session.session)}
							checked={selectedSessions.includes(session.session)}
							onCheckboxChange={handleCheckboxChange}
						/>
					))}
				</StaggerChildren>
			) : (
				<div className="session-panel-no-results">
					<section>No Sessions</section>
				</div>
			)}

			<div className="session-panel-footer">
				<button onClick={() => setSelectedSessions([])}>Clear Selections</button>
				<button
					className="lemonade"
					onClick={handleDone}
					disabled={saving}
				>{saving ? <WaitingIndicator /> : 'Add to page'}</button>
			</div>
		</div>
	);
};

export default AddSuggested;