import { ThemePack, CreateThemePack, UpdateThemePack } from "types/theme-packs";
import { Delete, Get, Post, Put } from "./helpers";

export function GetColors(token: string, channel: number): Promise<ThemePack[]> {
	return Get(`/v3/admin/channel/colors/${channel}`, token);
}

export function CreateNewColorPack(
	token: string,
	colors: CreateThemePack
): Promise<ThemePack & { error?: string; }> {
	return Post(`/v3/admin/channel/colors`, token, colors);
}

export function SaveColor(token: string, colors: UpdateThemePack): Promise<ThemePack> {
	return Put(`/v3/admin/channel/colors`, token, colors, true);
}

export interface DeleteColorsReturn {
	cannotDelete: number[];
	deletedColors: number[];
}
export function DeleteColors(colorPackIDs: number[], token: string, channel: number): Promise<DeleteColorsReturn> {
	return Delete('/v3/admin/channel/colors', token, { colorPackIDs, channel }, true);
}
