import { useHistory, useParams } from "react-router";

import { LanguagesAbbr, ModuleMap, PageLinkType, PageModuleType } from "../../../types/working-model";
import { parseUrl, shouldDisplayHomepage } from "../../../utils/utils";
import { eventPath, useLanguageParam } from "../../live-event/utils";
import { Button } from "../button/button";
import { validate } from 'uuid';
import classNames from 'classnames';
import '../../../scss/live-event/base/general-ui/live-call-to-action.scss';
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { ParamsProps } from "../../live-event/live-event";

interface LiveCTAProps {
	classname: string;
	template: string;
	buttonText: string | string[];
	pageLink: PageLinkType | null;
	sectionLink: PageModuleType | null;
	urlLink: string | null;
	sessionLink?: string | null;
	eventName: string | undefined;
	uuid: string | undefined;
	selectedLanguage: LanguagesAbbr;
	registration_on: boolean;
}

const LiveCallToAction: React.FC<LiveCTAProps> = ({
	classname,
	template,
	buttonText,
	pageLink,
	sectionLink,
	urlLink,
	sessionLink,
	uuid,
	selectedLanguage,
	registration_on
}) => {
	const history = useHistory();
	const { eventName } = useParams() as ParamsProps;
	const language = useLanguageParam();
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);

	// FUTURE DEV: ideally, this function will not be needed because localhost dev will not have an event name
	// current brandlive-dev.com is not pulling the event name from the params correctly, so we're forcing it through the props
	// This ensures that an event name is always present, nullifying the eventPath() function. Once resolved, remove this function,
	// and replace the getEventName() calls with eventPath() calls
	function getEventName() {
		return eventPath(eventName, uuid);
	}

	function getCustomPagePath(customPath: string) {
		return `/${eventPath(eventName, uuid)}/${language}/pages/${customPath}`;
	}

	const displayHomepage = shouldDisplayHomepage(eventBundle);
	const mustGoToSession = !displayHomepage && eventBundle?.sessionsPreviews?.length === 1;

	const handleClick = () => {
		if (pageLink) {
			//Custom Page Links are stored as uuids
			if (validate(pageLink)) {
				const customPage = eventBundle?.custom_pages?.find(page => page.uuid === pageLink);
				if (customPage) {
					history.push(getCustomPagePath(customPage.path));
					return;
				}
			}
			switch (pageLink) {
				case PageLinkType.termsAndConditions: {
					window.open(`/${getEventName()}/${selectedLanguage}/terms-conditions`, '_blank');
					return;
				}
				case PageLinkType.registration: {
					if (registration_on) {
						history.push(`/${getEventName()}/${selectedLanguage}/registration`);
					} else {
						if (mustGoToSession) {
							history.push(`/${getEventName()}/${selectedLanguage}/session/${eventBundle?.sessionsPreviews?.[0].uuid}`);
						} else {
							history.push(`/${getEventName()}/${selectedLanguage}/home`);
						}
					}
					return;
				}
				case PageLinkType.privacyPolicy: {
					window.open(`/${getEventName()}/${selectedLanguage}/privacy-policy`, '_blank');
					return;
				}
				case PageLinkType.landing: {
					history.push(`/${getEventName()}/${selectedLanguage}/`);
					return;
				}
				case PageLinkType.homepage: {
					if (registration_on) {
						history.push(`/${getEventName()}/${selectedLanguage}/registration`);
					} else {
						if (mustGoToSession) {
							history.push(`/${getEventName()}/${selectedLanguage}/session/${eventBundle?.sessionsPreviews?.[0].uuid}`);
						} else {
							history.push(`/${getEventName()}/${selectedLanguage}/home`);
						}
					}
					return;
				}
				case PageLinkType.agenda: {
					if (registration_on && !blProfileUser) {
						history.push(`/${getEventName()}/${selectedLanguage}/registration`);
					} else {
						if (mustGoToSession) {
							history.push(`/${getEventName()}/${selectedLanguage}/session/${eventBundle?.sessions[0].uuid}`);
						} else {
							history.push(`/${getEventName()}/${selectedLanguage}/sessions`);
						}
					}

					return;
				}
				default: return;
			}
		}
		if (sectionLink) {
			const moduleType = eventBundle?.homepage?.modules.find(module => module.id === sectionLink)?.type || 0;
			document.getElementById(`${ModuleMap[moduleType]}`)?.scrollIntoView({ behavior: 'smooth' });
			return;
		}
		if (sessionLink) {
			history.push(`/${getEventName()}/${selectedLanguage}/session/${sessionLink}`);
		}
	};

	// if no button text, do not show the button
	if (!buttonText) return null;
	return (
		<>
			{urlLink ? (
				<a
					href={parseUrl(urlLink)}
					className={
						classNames(
							'call-to-action-url',
							classname,
							template
						)
					}
				>
					{buttonText}
				</a>
			) : (
				<Button
					classButton={classname}
					template={template}
					onClick={handleClick}
				>{buttonText}</Button>
			)}
		</>

	);
};

export default LiveCallToAction;
