import { useState } from "react";
import { CreateEmptyPageModule } from "../../../../../../connection/page-modules";
import { addSessionPageModule, updatePageModule } from "../../../../../../store/actions/admin/create-event/session";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { PageModule } from "../../../../../../types/working-model";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import CustomHTML from "../../../../../general-ui/edit-html/edit-html";
import ModalComponent from "../../../../../general-ui/modal/modal";
import WaitingIndicator from "../../../../../general-ui/waiting-indicator/waiting-indicator";
import CustomOptions from "../../../session/navigation/custom-options/custom-options";
import SessionModuleGroupingListV2 from './session-module-grouping-list-v2';

function SessionContentV2(): JSX.Element {
	const token = useTypedSelector((state) => state.AuthReducer.token);
	const workingSession = useTypedSelector((state) => state.CreateSessionReducer.workingSession);
	const [addModuleOpen, setAddModuleOpen] = useState(false);
	const [blankModalOpen, setBlankModalOpen] = useState(false);
	const [workingBlankModule, setWorkingBlankModule] = useState<PageModule | null>(null);

	const dispatch = useAppDispatch();

	function closeBlankModule() {
		setBlankModalOpen(false);
	}

	function updateWorkingBlankModule(code: string) {
		setWorkingBlankModule((module: PageModule | null) => {
			if (!module) { return null; }
			return {
				...module,
				content: {
					html: code,
				},
			};
		});
	}

	async function finishedEditingBlank() {
		if (!token) return;

		try {
			if (workingBlankModule) {
				if (workingBlankModule.id) {
					dispatch(updatePageModule(workingBlankModule));
				} else {
					const newModule = await CreateEmptyPageModule(token, workingBlankModule);
					dispatch(addSessionPageModule(newModule));
				}
			}
		} catch (error) {
			console.error(error);
			showAlert({
				message: "Error Creating Module",
				description: "We ran into an error creating the module. Please try again.",
				duration: 5000,
				type: "error",
			});
		}

		closeBlankModule();
	}

	if (!workingSession) return <WaitingIndicator />;
	return (
		<>
			<SessionModuleGroupingListV2 />

			<CustomOptions
				addModuleOpen={addModuleOpen}
				blankModalOpen={blankModalOpen}
				setAddModuleOpen={setAddModuleOpen}
				setBlankModalOpen={setBlankModalOpen}
				workingSession={workingSession}
			/>
			<ModalComponent
				cancellable={true}
				closeable={false}
				open={blankModalOpen}
				onRequestClose={closeBlankModule}
				title={"Blank Module"}
				footer={
					<>
						<button onClick={closeBlankModule} style={{ marginRight: 16 }}>
							Cancel
						</button>
						<button onClick={finishedEditingBlank} className="lemonade">
							Done
						</button>
					</>
				}
			>
				<div>
					<label className="subtitle">Add custom HTML Code</label>

					<CustomHTML
						defaultValue={workingBlankModule?.content.html ?? ""}
						onChange={updateWorkingBlankModule}
					/>
				</div>
			</ModalComponent>
		</>
	);
}

export default SessionContentV2;
