import React from 'react';
import { TFunction } from 'react-i18next';
import classNames from 'classnames';

import {
	BLChannelProfile,
	BrandliveEvent,
	EProfileSectionTypes,
	ProfileSection,
	RegistrationQuestion,
	RegistrationStep,
	SocialLink,
	SocialPlatforms,
	BlProfileValues,
	RegistrationStepType,
	Question,
	PERSONAL_INFO_FIELDS,
	QuestionType, RegFieldsEnum, LanguagesAbbr, IRegistrationQuestionTranslations, TranslateString,
} from '../../../types/working-model';
import Icon, { ICONS } from '../../general-ui/icon';
import { isDevEnvironment } from '../../../utils/utils';
import { GetAttendeeProfile } from '../../../connection/social';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';

export interface MungedSection extends Omit<ProfileSection, 'fields'> {
	fields: RegistrationQuestion[];
}
interface MungedProfileSections {
	generalSection: MungedSection;
	otherSections: MungedSection[];
}

export function getMungedProfileSections(eventBundle: BrandliveEvent | null, personal?: boolean, allRegistrationQuestions?: RegistrationQuestion[]): MungedProfileSections {
	const sections: ProfileSection[] = [...eventBundle?.social_settings?.profiles.sections || []];
	// if we are in admin we get the registration questions passed in because the event bundle questions won't be updated until published
	const registrationQuestions: RegistrationQuestion[] = allRegistrationQuestions || eventBundle?.registration_questions || [];
	const generalInfoStep: RegistrationStep | undefined = eventBundle?.registration_steps?.find(step => step.type === RegistrationStepType.general);
	const profileStep: RegistrationStep | undefined = eventBundle?.registration_steps?.find(step => step.type === RegistrationStepType.profile);
	// PersonalInfoFilter to prevent displaying personal fields to other users, if this is a personal profile the filter is empty
	const personalInfoFilter = personal ? [] : PERSONAL_INFO_FIELDS;

	// general section has to be separated out because it is typically formatted differently on profile pages
	const generalSectionIndex = sections.findIndex(section => section.type === EProfileSectionTypes.GeneralInformation);
	const generalSectionUnmodified: ProfileSection = sections.splice(generalSectionIndex, 1)[0];
	// general section does not contain it's own independent fields - it must be completed from the General Info step of registration
	const generalSectionFields: RegistrationQuestion[] = generalInfoStep?.questions?.filter(question => !personalInfoFilter.includes(question.registration_question)) || [];
	const generalSection: MungedSection = { ...generalSectionUnmodified, fields: generalSectionFields };

	// for all other sections... only return sections that are turned on and have fields
	const otherSections: MungedSection[] = sections.filter(section => section.isOn && section.fields.length).map(section => {
		const fieldsUpdated: RegistrationQuestion[] = [];

		// special handling of About section dependent on Profile registration step
		if (section.type === EProfileSectionTypes.About && profileStep?.isOn) {
			const filteredProfileFields = profileStep.questions?.filter(question => !personalInfoFilter.includes(question.registration_question)) || [];
			fieldsUpdated.push(...filteredProfileFields);
		} else {
			section.fields.forEach((field: Question) => {
				const findQuestion: RegistrationQuestion | undefined = registrationQuestions.find(question => question.registration_question === field.questionKey);
				if (findQuestion && !personalInfoFilter.includes(findQuestion.registration_question)) {
					fieldsUpdated.push(findQuestion);
				}
			});
		}

		return { ...section, fields: fieldsUpdated };
	});

	return { generalSection, otherSections };
}

function getFieldName(question: RegistrationQuestion, t: TFunction<'homepage', undefined>, language: LanguagesAbbr, registrationSettingsTranslations?: IRegistrationQuestionTranslations) {
	// first check registration_settings.registration_questions_translations[question.registration_question].name[language]
	const translatedName = registrationSettingsTranslations?.[question.registration_question]?.name?.[language];
	if (translatedName && typeof translatedName === 'string') {
		return translatedName;
	}

	// then go back to fallback
	return question.global
		? t(`registration.registration_fields.${question.registration_question}` as string)
		: (question.name_translation?.[language] as string ?? question.name);
}

export const showField = (question: RegistrationQuestion, profile: BLChannelProfile): boolean => {
	const id = String(question.registration_question);

	if (question.registration_question === RegFieldsEnum.passcode) {
		return false;
	}

	if (question.type === QuestionType.checkbox && profile?.[id] !== undefined) {
		return true;
	}

	if (profile?.[id] && profile[id]?.length) {
		return true;
	}

	return false;
};

interface ProfileFieldsProps {
	questions: RegistrationQuestion[] | undefined;
	profile: BLChannelProfile;
	t: TFunction<'homepage', undefined>;
	language?: LanguagesAbbr;
	showPersonalProfile?: boolean;
}

const alwaysBannedFields = [
	RegFieldsEnum.passcode,
	RegFieldsEnum.attend_in_person
];

export const ProfileFields: React.FC<ProfileFieldsProps> = ({
	questions,
	profile,
	t,
	language,
	showPersonalProfile,
}) => {
	const liveTranslations = useTypedSelector(state => state.LiveEventReducer?.eventBundle?.registration_settings?.registration_questions_translations);
	const editorTranslations = useTypedSelector(state => state.CreateEventReducer?.workingEvent?.registration_settings?.registration_questions_translations);
	const registrationSettingsTranslations = liveTranslations || editorTranslations;
	const currentUserBlProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser?.bl_profile);
	const fields: JSX.Element[] = [];
	if (!language) return <></>;

	questions?.forEach(question => {
		const id = String(question.registration_question);

		const isEmailField = question.registration_question === RegFieldsEnum.email || question.type === 'email';
		const isOwnProfile = currentUserBlProfile && profile?.bl_profile && currentUserBlProfile === profile?.bl_profile;
		const shouldShowEmailField = !isEmailField || (isEmailField && isOwnProfile);
		// unless it is personal editable profile don't show empty fields or sections
		// We don't need to show if they're attending in person.
		// And always hide the passcode field
		if (
			shouldShowEmailField
			&& (showPersonalProfile || showField(question, profile))
			&& !alwaysBannedFields.includes(question.registration_question)
		) {
			const name = getFieldName(question, t, language);
			fields.push(<CardItem registrationQuestionId={question.registration_question} key={id} title={name} content={profile?.[id]} t={t} multiLine={question.type === 'textarea'} language={language} />);
		}
	});

	return <>{fields}</>;
};

export function getSocialsFromProfile(profile: BLChannelProfile): SocialLink[] {
	const socials: SocialLink[] = [];

	for (const platform of Object.values(SocialPlatforms)) {
		// if the social link exists and it has content
		if (profile?.[platform]) {
			const formatted = {
				url: profile[platform],
				platform
			};
			socials.push(formatted);
		}
	}

	return socials;
}

interface CardItemProps {
	title: string;
	content: string | string[];
	t: TFunction<'homepage', undefined>;
	multiLine?: boolean;
	language?: string;
	registrationQuestionId: number;
}
const CardItem = ({ title, content, t, multiLine = false, registrationQuestionId, language }: CardItemProps) => {
	const liveTranslations = useTypedSelector(state => state.LiveEventReducer?.eventBundle?.registration_settings?.registration_questions_translations);
	const editorTranslations = useTypedSelector(state => state.CreateEventReducer?.workingEvent?.registration_settings?.registration_questions_translations);
	const registrationSettingsTranslations = liveTranslations || editorTranslations;

	const renderContent = () => {
		if (typeof content === 'boolean') {
			return <p className={classNames({ 'multi-line': multiLine })}>
				{
					content
						? t('registration.Yes')
						: t('registration.No')
				}
			</p>;
		} else if (Array.isArray(content)) {
			const options = registrationSettingsTranslations?.[registrationQuestionId]?.options;
			const optionMapByBaseName = options && Object.values(options).reduce((_options, option) => {
				_options[option.base] = option;
				return _options;
			}, {} as { [key: string]: TranslateString });
			return (
				<ul className={classNames('card-topic-tags', { 'multi-line': multiLine })}>
					{content.map(tag => {
						let _tag = tag;
						const dbTranslation = language && optionMapByBaseName?.[tag]?.[language];
						if (dbTranslation && typeof dbTranslation === 'string') {
							_tag = dbTranslation;
						}
						return <li key={_tag}>{_tag}</li>;
					})}
				</ul>
			);
		} else {
			// if registrationQuestionId has options, find it
			const options = registrationSettingsTranslations?.[registrationQuestionId]?.options;
			let _content = content;
			if (options) {
				const matchedOption = Object.values(options).find(option => option.base === content);
				const optionTranslation = matchedOption && language && matchedOption[language];
				if (optionTranslation && typeof optionTranslation === 'string') {
					_content = optionTranslation;
				}
			}
			return <p className={classNames({ 'multi-line': multiLine })}>{_content}</p>;
		}
	};

	return (
		<div className="profile-card-item">
			<div className="card-item-content">
				<h5>{title}</h5>
				{renderContent()}
			</div>
		</div >
	);
};

interface CardTitleProps {
	title: string;
	handleClick: () => void;
	disabled: boolean;
	canEdit?: boolean;
	className?: string;
}
export const CardTitle = ({ title, handleClick, disabled, canEdit = true, className = '' }: CardTitleProps): JSX.Element => {
	const { t } = useTranslate('homepage');

	return (
		<div className={'card-title ' + className}>
			<h3>{title}</h3>
			{canEdit ? (
				<button className="edit-button no-style" onClick={handleClick} disabled={disabled}>
					<Icon name={ICONS.EDIT} size={12} color="" />
					{t('homepage:Edit', t('Edit', ''))}
				</button>
			) : null}
		</div>
	);
};

export const placeHolderAttendee = {
	[BlProfileValues.firstName]: '',
	[BlProfileValues.lastName]: '',
};

export function getAttendeeProfile(
	attendeeId: string,
	eventUuid: string,
	blProfileUserToken: string,
	setAttendee: React.Dispatch<React.SetStateAction<BLChannelProfile>>,
	onError: () => void,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
): void {
	if (attendeeId && eventUuid && blProfileUserToken) {
		if (!isDevEnvironment()) {
			const eventNamePass = window.location.pathname.split("/")?.[1];
			if (eventNamePass) {
				const host = window.location.hostname;
				setLoading(true);
				GetAttendeeProfile(attendeeId, blProfileUserToken, host.replace(/\.brandlive.*\.com/, '.brand.live'), eventNamePass)
					.then(res => setAttendee(res))
					.catch(e => {
						console.error(e);
						onError();
					})
					.finally(() => setLoading(false));
			}
		} else {
			setLoading(true);
			GetAttendeeProfile(attendeeId, blProfileUserToken, 'dev', eventUuid)
				.then(res => setAttendee(res))
				.catch(e => {
					console.error(e);
					onError();
				})
				.finally(() => setLoading(false));
		}
	}
}

export const getDirectoryFilters = (event: BrandliveEvent | null): RegistrationQuestion[] => {
	if (!event) return [];

	const filtersMap = event?.social_settings?.directory?.filters?.reduce((accum: { [key: number]: number }, curr) => {
		if (!curr.registrationQuestionId) return accum;
		return { ...accum, [curr.registrationQuestionId]: curr.registrationQuestionId };
	}, {});

	const registrationFilterQuestions = event?.registration_steps?.reduce((accum: RegistrationQuestion[], curr: RegistrationStep): RegistrationQuestion[] => {
		curr?.questions?.forEach(qn => {
			if (!filtersMap?.[qn.registration_question]) return accum;
			accum.push(qn);
		});
		return accum;
	}, []) ?? [];

	return registrationFilterQuestions;
};

interface LeaderboardPointTotalProps {
	headerText: string;
	points: number;
}
export const LeaderboardPointTotal = ({ headerText, points }: LeaderboardPointTotalProps): JSX.Element => (
	<div className="leaderboard-points-total">
		<h5>{headerText}</h5>
		<h2 className="leaderboard-points-total-amount">{points}</h2>
	</div>
);

interface LeaderboardActionProps {
	actionName: string;
	pointsEarned: number;
	pointMultiplier: number | null;
}
export const LeaderboardAction = ({ actionName, pointsEarned, pointMultiplier }: LeaderboardActionProps): JSX.Element => {
	const { t } = useTranslate('homepage');

	return (
		<div className="leaderboard-action">
			<div className="left">
				<div className="leaderboard-action-name">{actionName}</div>
				{pointMultiplier && <h5>{pointMultiplier} {pointMultiplier > 1 ? t('leaderboard.Points Per Action') : t('leaderboard.Point Per Action')}</h5>}
			</div>
			<div>
				<h5>{t('leaderboard.Points Earned')}</h5>
				<div className="leaderboard-action-points-earned-amount">{pointsEarned}</div>
			</div>
		</div>
	);
};