import LeaderboardCard from "./leaderboard-card";
import { LeaderboardEntry } from '../../../../types/leaderboard';
import Paging from '../../../general-ui/paging/paging';
import { useTranslate } from "../../../../i18n/useTranslationModules";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";


interface LeaderboardListProps {
	list: LeaderboardEntry[];
	page: number;
	setPage: (page: number) => void;
	totalPages: number;
	jumpToPage: () => void;
	viewProfile?: (uuid: string) => void;
}
const LeaderboardList = ({ list, page, setPage, totalPages, jumpToPage, viewProfile }: LeaderboardListProps): JSX.Element => {

	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);

	const { t } = useTranslate(['homepage']);
	if (!list?.length) return (<></>);

	const attendeeOnThisPage = !!list.find(entry => entry.bl_profile === blProfileUser?.bl_profile);

	return (
		<div className='leaderboard-list'>
			{list.map(attendee => (
				<LeaderboardCard key={attendee.bl_profile_uuid} attendee={attendee} viewProfile={viewProfile} />
			))}
			<Paging page={page} setPage={setPage} totalPages={totalPages} />
			{totalPages > 2 && (
				<div className="to-my-page-container">
					<button onClick={jumpToPage} disabled={attendeeOnThisPage} className="to-my-page small">{t("leaderboard.Jump to my page", "Jump to my page")}</button>
				</div>)}
		</div>
	);
};

export default LeaderboardList;
