import { useMemo } from 'react';
import { BrandliveEvent, Session, SessionPreview } from '../types/working-model';
import { toDictTyped } from './utils';

function useGetSessionTags(event: BrandliveEvent | null, session?: Session | SessionPreview | null) {

	const sessionTags = useMemo(() => {
		if (!event || !session) return [];
		const tagMap = toDictTyped('uuid', event.tags ?? []);
		return session?.tags?.map(t => tagMap[t]).filter(t => !!t) ?? [];
	}, [event, session]);

	return sessionTags;
}

export default useGetSessionTags;
