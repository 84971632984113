import { useContext, useMemo } from "react";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { BrandliveEvent, CustomPage, EventNavbar, NavMainItems } from "types/working-model";
import { useMatchedPath } from "utils/use-matched-path";
import { getEventNavbarStyling } from "../utils";
import { DefaultRouteMap, DebugDefaultRouteMap } from '../hooks/use-route-map';
import { PATHNAMES as AdminPaths } from "utils/admin-routing-utils";
import AppContext, { AppContexts } from "components/app-context";

export type NavbarStyles = ReturnType<typeof getEventNavbarStyling>;
export const useNavbarStyles = (eventBundle: BrandliveEvent | undefined | null): NavbarStyles => {
	return useMemo(() => {
		const navbarOptions: EventNavbar | undefined = eventBundle?.settings.event_navbar;
		return getEventNavbarStyling(navbarOptions?.styling_overrides);
	}, [eventBundle?.settings.event_navbar]);
};

export const useEnabledFeatures = (eventBundle: BrandliveEvent | undefined | null) => {
	const leaderboardIsOnSetting = useTypedSelector(state => state.LiveEventReducer.leaderboardIsOn);
	const adminLeaderboardIsOnSetting = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.leaderboard_settings?.enabled);
	const numSessions = eventBundle?.sessionsPreviews?.length;
	const hasHomepage = eventBundle?.settings?.display_homepage;
	const hasLandingPage = eventBundle?.settings?.display_landing_page;
	const hasDirectory = eventBundle?.social_settings?.directory?.isOn;
	const agenda = eventBundle?.settings?.display_sessions ?? true;

	return {
		directory: hasDirectory,
		leaderboard: adminLeaderboardIsOnSetting || leaderboardIsOnSetting,
		webinar: numSessions === 1 && !hasHomepage,
		landing: hasLandingPage,
		homepage: hasHomepage,
		agenda
	};
};

export type CurrentPageState = {
	isSessionPage: boolean;
	isHomepage: boolean;
	isDirectory: boolean;
	isAgenda: boolean;
	isProfile: boolean;
	isWatchlist: boolean;
	isLeaderboard: boolean;
	isAttendeeProfile: boolean;
	isLanding: boolean;
	isLandingRedirect: boolean;
	matchedPath: string;
	routeMapToNavItem: { [x: string]: NavMainItems };
	navItemToRouteMap: { [x: string]: string };
	routeMap: typeof DefaultRouteMap;
};

const routeMapToNavItemTypeMap = (routeMap: typeof DefaultRouteMap) => {
	return {
		[routeMap.Home]: NavMainItems.Home,
		[routeMap.Sessions]: NavMainItems.Sessions,
		[routeMap.Landing]: NavMainItems.Session,
		[routeMap.Directory]: NavMainItems.Directory,
		[routeMap.Leaderboard]: NavMainItems.Leaderboard,
		[routeMap.Session]: NavMainItems.Session
	};
};

const navItemToRouteMap = (routeMap: typeof DefaultRouteMap) => {
	return {
		[NavMainItems.Home]: routeMap.Home,
		[NavMainItems.Sessions]: routeMap.Sessions,
		[NavMainItems.Session]: routeMap.Landing,
		[NavMainItems.People]: routeMap.Directory,
		[NavMainItems.Directory]: routeMap.Directory,
		[NavMainItems.Leaderboard]: routeMap.Leaderboard
	};
};

export const adminNavItemToRouteFuncMap = {
	[NavMainItems.Home]: AdminPaths.Event[NavMainItems.Home],
	[NavMainItems.Sessions]: AdminPaths.Event[NavMainItems.Sessions],
	[NavMainItems.Session]: AdminPaths.Session.SessionLink,
	[NavMainItems.People]: AdminPaths.Event.DirectoryLink,
	[NavMainItems.Directory]: AdminPaths.Event.DirectoryLink,
	[NavMainItems.Leaderboard]: AdminPaths.Event[NavMainItems.Leaderboard]
};

export const adminNavItemToRouteMap: Record<string, string[]> = {
	[NavMainItems.Home]: [AdminPaths.Event.HomePath, AdminPaths.Event.Event],
	[NavMainItems.Sessions]: [AdminPaths.Event.SessionsPath],
	[NavMainItems.Session]: [AdminPaths.Session.Session],
	[NavMainItems.People]: [AdminPaths.Event.Directory],
	[NavMainItems.Directory]: [AdminPaths.Event.Directory],
	[NavMainItems.Leaderboard]: [AdminPaths.Event.LeaderboardPath]
};

export const useCurrentPageState = (eventBundle: BrandliveEvent | undefined | null): CurrentPageState => {
	const matched = useMatchedPath();
	const routeMap = matched.startsWith('/event/:uuid') ? DebugDefaultRouteMap : DefaultRouteMap;
	const numSessions = eventBundle?.sessionsPreviews?.length;
	const hasHomepage = eventBundle?.settings?.display_homepage;
	const hasLandingPage = eventBundle?.settings?.display_landing_page;
	const isWebinar = numSessions === 1 && !hasHomepage && !hasLandingPage;

	return useMemo(() => {
		return {
			isSessionPage: matched === routeMap.Session || isWebinar && matched === routeMap.Landing,
			isHomepage: (matched === routeMap.Home) || (matched === AdminPaths.Event.HomePath) || (matched === AdminPaths.Event.Event),
			isDirectory: (matched === routeMap.Directory) || (matched === AdminPaths.Event.Directory),
			isAgenda: (matched === routeMap.Sessions) || (matched === AdminPaths.Event.SessionsPath),
			isProfile: (matched === routeMap.Profile) || (matched === AdminPaths.Event.SocialProfile),
			isWatchlist: (matched === routeMap.Watchlist),
			isLeaderboard: (matched === routeMap.Leaderboard) || (matched === AdminPaths.Event.LeaderboardPath),
			isAttendeeProfile: (matched === routeMap.AttendeeProfile) || (matched === AdminPaths.Event.SocialProfile),
			isLanding: (matched === routeMap.Landing) || (matched === AdminPaths.Event.Landing),
			isLandingRedirect: matched === routeMap.LandingRedirect,
			matchedPath: matched,
			routeMapToNavItem: routeMapToNavItemTypeMap(routeMap),
			navItemToRouteMap: navItemToRouteMap(routeMap),
			routeMap
		};
	}, [matched, routeMap, isWebinar]);
};

export const useAuthorizedCustomNavItems = (eventBundle: BrandliveEvent | undefined | null) => {
	const appContext = useContext(AppContext);
	const isEditor = appContext === AppContexts.admin;
	const authorizedPages = useTypedSelector(state => state.LiveEventReducer.authorizedPages);
	const editorCustomPages = useTypedSelector(state => state.CreateEventReducer.workingEvent?.custom_pages);
	const customPages = isEditor ? editorCustomPages : eventBundle?.custom_pages;

	const activeCustomNavItems = useMemo(() => {
		return customPages?.filter(page => page.is_on);
	}, [customPages]);

	return useMemo(() => {
		if (!isEditor && eventBundle?.registration_on) {
			return activeCustomNavItems?.filter((page: CustomPage) => authorizedPages.includes(page.custom_page));
		} else {
			return activeCustomNavItems;
		}
	}, [activeCustomNavItems, authorizedPages, eventBundle?.registration_on, isEditor]);
};