import { useEffect, useRef } from "react";

interface IRecaptchaV2Props {
	getWidgetId: (widtgetId: number) => void;
}

const RecaptchaV2: React.FC<IRecaptchaV2Props> = ({ getWidgetId }) => {

	const recaptchaRef = useRef<HTMLDivElement>(null);

	useEffect(() => {

		// this is how to generate a v2 token to send to server for validation:
		// const token = window?.grecaptcha?.getResponse?.(widgetId);

		if (window.grecaptcha?.render && recaptchaRef.current) {
			const widgetId = window.grecaptcha.render(recaptchaRef.current, {
				'sitekey': process.env.REACT_APP_RECAPTCHA_V2_CLIENT_SITE_KEY,
			});
			getWidgetId(widgetId);
		}

		// this keeps throwing a "client does not exist error...???"
		// return () => {
		// 	window?.grecaptcha?.reset?.();
		// };
	}, [getWidgetId]);

	return <div ref={recaptchaRef} />;
};

export default RecaptchaV2;
