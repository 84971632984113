import React, { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, batch } from 'react-redux';
import classNames from 'classnames';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, KeyboardSensor, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { omit } from 'underscore';

import './reaction-configuration-modal.scss';

import ColorEditor from '../../../settings/design/colors/color-editor';
import { addCustomReaction } from '../../../../../../store/actions/admin/reactions';
import { OptionalComponent } from '../../../../../../utils/optional-component';
import ModalComponent from '../../../../../general-ui/modal/modal';
import LargeButton from '../../../../../general-ui/button/large-button';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import { UploadFile } from '../../../../../../connection/uploads';
import { Session, ReactionConfig, CustomReaction } from "../../../../../../types/working-model";
import Switch from '../../../../../general-ui/switch/switch';
import { updateSession, updateWorkingSession } from '../../../../../../store/actions/admin/create-event/session';
import { useTypedSelector } from '../../../../../../store/reducers/use-typed-selector';
import { showAlert } from '../../../../../general-ui/alert/alert-service';
import { reactionIconMap, defaultReactions } from '../../.../../../../../../utils/reactions';


interface ReactionConfigModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: () => void;
}

interface UploadModalState {
	open: boolean;
	name?: string;
	uploading?: boolean;
	url?: string;
}

const ReactionConfigurationModal: React.FC<ReactionConfigModalProps> = ({ isOpen, onClose }) => {
	const dispatch = useDispatch();

	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const customReactions: CustomReaction[] = useTypedSelector(state => state.ReactionsReducer.reactions);

	const [open, setOpen] = useState(false);
	const [reactionState, setReactionState] = useState<ReactionConfig[]>(useTypedSelector(state => state.CreateSessionReducer.workingSession?.reaction_settings?.reactions || []));
	const [newReactionState, setNewReactionState] = useState<ReactionConfig>({
		enabled: true,
		color: '',
		opacity: 0,
		icon: '',
		url: '',
		name: '',
		description: ''
	});

	const [uploadModalState, setUploadModalState] = useState<UploadModalState>({ open: false });
	const [showDescriptions, setShowDescriptions] = useState(!!useTypedSelector(state => state.CreateSessionReducer.workingSession?.reaction_settings?.show_descriptions));

	const handleClose = () => {
		setOpen(false);

		onClose();
	};

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = useCallback(async (event: DragEndEvent) => {
		const _reactionState = [...reactionState];

		const startIndex = _reactionState.findIndex((reaction: ReactionConfig, idx) => (reaction.name + idx) === event.active.id);
		const newIndex = _reactionState.findIndex((reaction: ReactionConfig, idx) => (reaction.name + idx) === event.over?.id);

		const [item] = _reactionState.splice(startIndex, 1);
		_reactionState.splice(newIndex, 0, item);

		setReactionState(_reactionState);
	}, [reactionState]);

	const handleSave = async () => {
		try {
			if (!token || !workingSession || !workingEvent) return;

			// Note: we won't really ever hit this if statement because we are blocking the "add" button if there's no text
			// but it's here just in case someone somehow manages to bypass those checks... you never know
			if (reactionState.some(reaction => !reaction.name.trim().length)) {
				return showAlert({
					message: "One or more emoji names are empty. Empty spaces are not valid.",
					type: "error",
					duration: 3000
				});
			}

			const updatedSession: Session = {
				...workingSession,
				reaction_settings: {
					...(workingSession?.reaction_settings || {}),
					enabled: workingSession?.reaction_settings?.enabled || false,
					show_descriptions: showDescriptions,
					reactions: reactionState.map(reaction => ({ ...reaction, name: reaction.name.trim() })),
				}
			};

			batch(() => {
				dispatch(updateSession(updatedSession, token));
				dispatch(updateWorkingSession(updatedSession));
			});
		} catch (e) {
			console.error(e);
		}
		onClose();
	};

	async function handleNewReactionUpload(file: File | FileList) {
		if (file instanceof File) {
			setUploadModalState({ ...uploadModalState, uploading: true });

			let uploaded = '';

			if (user && token) {
				try {
					uploaded = await UploadFile(user, token, file);
				} catch (e) {
					console.error(e);
				}
			}

			setUploadModalState({ ...uploadModalState, uploading: false, url: uploaded });
		}
	}

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<>
			<ModalComponent
				className="reaction-configuration-modal"
				open={open}
				closeable={false}
				title="Manage Reactions"
				onRequestClose={handleClose}
				trapFocus={false}
				cancellable
				showOverflow
				footer={
					<>
						<div className="reaction-description-toggle-container">
							<Switch
								value="showDescriptions"
								on={showDescriptions}
								onClick={() => setShowDescriptions(!showDescriptions)}
							/>
							<span>Set hover text description</span>
						</div>
						<div>
							<button onClick={handleClose}>Cancel</button>
							<button disabled={reactionState.some(reaction => !reaction.name.trim().length)} className="lemonade" onClick={handleSave}>Save</button>
						</div>
					</>
				}
			>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
					modifiers={[restrictToParentElement]}
					autoScroll={false}
				>
					<SortableContext
						items={reactionState.map((reaction: ReactionConfig, idx: number) => reaction.name + idx)}
						strategy={verticalListSortingStrategy}
					>
						{reactionState.map((reaction: ReactionConfig, idx: number) => (
							<ConfigureReactionRow
								key={reaction.color + reaction.name}
								idx={idx}
								reaction={reaction}
								showDescriptions={showDescriptions}
								setReactionState={setReactionState}
								reactionState={reactionState}
								customReactions={customReactions}
							/>
						))}
						<AddNewReactionRow
							showDescriptions={showDescriptions}
							setNewReactionState={setNewReactionState}
							setReactionState={setReactionState}
							reactionState={reactionState}
							newReactionState={newReactionState}
							customReactions={customReactions}
						/>
						<div className="add-custom-reaction" onClick={() => setUploadModalState({ open: true })}>
							+ Add custom reaction
						</div>
					</SortableContext>
				</DndContext>
			</ModalComponent>

			<ModalComponent
				open={uploadModalState.open}
				closeable={false}
				title="Upload new reaction image"
				onRequestClose={() => null}
				trapFocus={false}
				cancellable
				footer={
					<>
						<div>
							<button onClick={() => setUploadModalState({ open: false })}>
								Cancel
							</button>
							<button
								className="lemonade"
								disabled={!(uploadModalState.url && uploadModalState.name?.trim()?.length)}
								onClick={() => {
									if (!token || !workingEvent || !uploadModalState.name || !uploadModalState.url) return;
									dispatch(addCustomReaction(token, workingEvent.channel, uploadModalState.name.trim(), uploadModalState.url));
									setUploadModalState({ open: false });
								}}
							>
								Save
							</button>
						</div>
					</>
				}
			>
				<input
					className="upload-reaction-name"
					onChange={e => setUploadModalState({ ...uploadModalState, name: e.target.value })}
					onBlur={() => {
						setUploadModalState(prev => ({ ...prev, name: prev.name?.trim() }));
					}}
					placeholder="Enter a name (required)"
					value={uploadModalState.name}
				/>
				<LargeButton
					allowedFileTypes={['image/png']}
					onFile={handleNewReactionUpload}
					title="Upload Reaction Image"
					subtitle="Only .png files allowed"
					multiple={false}
					uploading={uploadModalState.uploading}
				/>
			</ModalComponent>
		</>
	);
};


interface ConfigureReactionRowProps {
	reaction: ReactionConfig;
	setReactionState: Dispatch<SetStateAction<ReactionConfig[]>>;
	reactionState: ReactionConfig[];
	customReactions: CustomReaction[];
	showDescriptions: boolean;
	idx: number;
}

const ConfigureReactionRow = ({
	reaction,
	showDescriptions,
	reactionState,
	setReactionState,
	customReactions,
	idx
}: ConfigureReactionRowProps): JSX.Element => {
	const [selectReactionOpen, setSelectReactionOpen] = useState(false);
	const [name, setName] = useState(reaction.name ?? '');

	const {
		attributes,
		listeners,
		setNodeRef,
		transform
	} = useSortable({ id: reaction.name + idx });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	return (
		<div ref={setNodeRef} className="configure-reaction-row" style={style}>
			<div className="drag-handle" {...attributes} {...listeners}>
				<Icon name={ICONS.DRAG_HANDLE} size={12} color={COLORS.GRAY} />
			</div>
			<Switch
				value={reaction.name}
				on={reaction.enabled}
				onClick={() => setReactionState(reactionState.map((r, i) => i === idx ? { ...r, enabled: !reaction.enabled } : r))}
			/>
			<div className="color-editor-container">
				<ColorEditor
					color={[reaction.color || '#000000', reaction.opacity || 0]}
					title=""
					onChange={(color: string, opacity: number) => (
						setReactionState(reactionState.map((r, i) => i === idx ? { ...r, color, opacity } : r)))}
				/>
			</div>
			<div
				className="reaction-select-container reaction-icon"
				onClick={() => setSelectReactionOpen(!selectReactionOpen)}
			>
				<img src={reaction.icon ? reactionIconMap[reaction.icon] : reaction.url} />
				<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={16} color={COLORS.WHITE} />
				{selectReactionOpen &&
					<ReactionSelect
						customReactions={customReactions}
						onCustomReactionSelect={selectedReaction => setReactionState(
							reactionState.map((r, i) => idx === i ? { ...omit(r, 'icon'), url: selectedReaction.url, name: selectedReaction.name } : r)
						)}
						onDefaultReactionSelect={selectedReaction => setReactionState(
							reactionState.map((r, i) => idx === i ? { ...omit(r, 'url'), name: selectedReaction.name, icon: selectedReaction.icon } : r)
						)}
					/>}
			</div>
			<input
				className="reaction-select-container reaction-name"
				onChange={e => setName(e.target.value)}
				onBlur={() => setReactionState(reactionState.map((r, i) => idx === i ? { ...r, name } : r))}
				value={name}
			/>
			<OptionalComponent display={showDescriptions}>
				<input
					className="reaction-select-container reaction-name"
					onChange={e => setReactionState(reactionState.map((r, i) => idx === i ? { ...r, description: e.target.value } : r))}
					value={reaction.description}
				/>
			</OptionalComponent>
			<div
				className="delete-reaction"
				onClick={() => setReactionState(reactionState.filter((_, i) => idx !== i))}
			>
				<Icon name={ICONS.TRASH} size={16} color={COLORS.WHITE} />
			</div>
		</div>
	);
};

interface AddNewReactionRowProps {
	showDescriptions: boolean;
	setNewReactionState: Dispatch<SetStateAction<ReactionConfig>>;
	setReactionState: Dispatch<SetStateAction<ReactionConfig[]>>;
	newReactionState: ReactionConfig;
	reactionState: ReactionConfig[];
	customReactions: CustomReaction[];
}

const AddNewReactionRow = ({
	showDescriptions,
	newReactionState,
	reactionState,
	setReactionState,
	setNewReactionState,
	customReactions
}: AddNewReactionRowProps): JSX.Element => {
	const [selectReactionOpen, setSelectReactionOpen] = useState(false);

	return (
		<div className="configure-reaction-row new-reaction">
			<div className="drag-handle">
				<Icon name={ICONS.DRAG_HANDLE} size={12} color={COLORS.GRAY} />
			</div>
			<Switch
				value="add-new-reaction"
				on={false}
				onClick={() => null}
				disabled={true}
			/>
			<div className="color-editor-container">
				<ColorEditor
					color={[newReactionState.color || '#000000', newReactionState.opacity || 0]}
					title=""
					onChange={(color: string, opacity: number) => setNewReactionState({ ...newReactionState, color, opacity })}
				/>
			</div>
			<div
				className="reaction-select-container reaction-icon"
				onClick={() => setSelectReactionOpen(!selectReactionOpen)}
			>
				{newReactionState.icon || newReactionState.url ? <img src={newReactionState.icon ? reactionIconMap[newReactionState.icon] : newReactionState.url} /> : <Icon name={ICONS.QUESTION} size={16} color={COLORS.GRAY} />}
				<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={16} color={COLORS.WHITE} />
				{selectReactionOpen &&
					<ReactionSelect
						customReactions={customReactions}
						onCustomReactionSelect={selectedReaction => setNewReactionState({
							...omit(newReactionState, 'icon'),
							name: selectedReaction.name,
							url: selectedReaction.url
						})}
						onDefaultReactionSelect={selectedReaction => setNewReactionState({
							...omit(newReactionState, 'url'),
							name: selectedReaction.name,
							description: selectedReaction.description,
							icon: selectedReaction.icon
						})}
					/>}
			</div>
			<input
				className={classNames('reaction-select-container reaction-name')}
				onChange={e => setNewReactionState({ ...newReactionState, name: e.target.value })}
				placeholder="Set name"
				value={newReactionState.name}
			/>
			<OptionalComponent display={showDescriptions}>
				<input
					className={classNames('reaction-select-container reaction-name')}
					onChange={e => setNewReactionState({ ...newReactionState, description: e.target.value })}
					placeholder="Set description"
					value={newReactionState.description}
				/>
			</OptionalComponent>
			<OptionalComponent display={!!(newReactionState.name.trim().length && (newReactionState.icon || newReactionState.url))}>
				<div
					className={classNames("delete-reaction", { disabled: !newReactionState?.name?.trim()?.length })}
					onClick={() => {
						setReactionState([...reactionState, {
							enabled: true,
							color: newReactionState.color,
							opacity: newReactionState.opacity,
							name: newReactionState.name.trim(),
							icon: newReactionState.icon,
							url: newReactionState.url,
							description: newReactionState.description
						}]);
						setNewReactionState({
							enabled: true,
							color: '',
							opacity: 0,
							icon: '',
							url: '',
							name: '',
							description: ''
						});
					}}
				>
					Add
				</div>
			</OptionalComponent>
		</div>
	);
};

interface ReactionSelectProps {
	customReactions: CustomReaction[];
	onCustomReactionSelect: (r: CustomReaction) => void;
	onDefaultReactionSelect: (r: ReactionConfig) => void;
}

const ReactionSelect = ({ customReactions, onCustomReactionSelect, onDefaultReactionSelect }: ReactionSelectProps): JSX.Element => {
	return (
		<div className="reaction-select">
			{customReactions.map(r => (
				<div className="reaction-select-item" key={r.name} onClick={() => onCustomReactionSelect(r)}>
					<img src={r.url} />
					<div>
						{r.name}
					</div>
				</div>
			))}
			{defaultReactions.map(r => (
				<div className="reaction-select-item" key={r.name} onClick={() => onDefaultReactionSelect(r)}>
					{r.icon && <img src={reactionIconMap[r.icon]} />}
					<div>
						{r.name}
					</div>
				</div>
			))}
		</div>
	);
};


export default ReactionConfigurationModal;
