import { Component } from 'react';
import classNames from 'classnames';
// import { useParams } from 'react-router';

import { random } from '../../../utils/random';
import { addListener, clearListeners } from './alert-service';
import Icon, { COLORS, ICONS } from '../icon';
import { MarkdownItem } from "../typography-item/typography-item";
import { ActionEvent, ActionTargetType, ActionType, MiscellaneousActionData, Profile } from '../../../types/working-model';
import { withTrackingHook } from '../../../utils/tracking/tracking-hoc';
import { OptionalComponent } from '../../../utils/optional-component';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import { announcementAvatar } from '../../../images';
// import { ParamsProps, } from '../../live-event/live-event';
// import { getDefaultLanguage } from "../../live-event/utils";

import './alert.scss';
// import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
// import { getCurrentPageSourceInfo } from '../../../utils/tracking';

const adminDomainRegex = /^admin\..*/;
const isAdmin = adminDomainRegex.test(window.location.hostname) || window.location.hostname === 'localhost';

export const ALERT_TYPES = {
	WARNING: 1,
	ERROR: 2,
	NEUTRAL: 3,
	POSITIVE: 4,
	CHAT: 5,
	ANNOUNCE: 6,
};

interface ITrackingHOCProps {
	trackEvent: (data: any) => void;
	eventName: string;
	email: string;
}

export type ToastAlert = {
	id?: number | string;
	announcementId?: number;
	announcement?: boolean;
	duration?: number | null;
	type?: string | number;
	spinner?: boolean;
	avatar?: string | null;
	messageText?: string | null;
	buttonOn?: boolean | null;
	buttonText?: string | null;
	buttonLink?: string | null;
	scheduleDate?: Date | null;
	recipients?: string | null;
	buttonLinkSameTab?: boolean | null;
	source_id?: number | string | null;
	source_type?: string | null;
	language?: string | null;
	baseLanguage?: string | null;
	description?: string | null;
	animationState?: string;
	message?: string;
	show?: boolean;
	showButtons?: boolean;
	requirementsButton?: boolean;
	children?: React.ReactChild | React.ReactChild[] | null;
	onClick?: null | (() => void);
	afterClose?: null | (() => void);
	signOut?: boolean;
	extraContent?: null | React.ReactChild;
	descriptionStyle?: React.CSSProperties;
	className?: string;
	image?: string;
	chat?: boolean;
	user?: string;
	opentokMuted?: boolean;
	from?: string | null;
	noClamp?: boolean;
}

type AlertsState = {
	alerts: ToastAlert[];
}

class Alerts extends Component<ITrackingHOCProps> {
	state: AlertsState = {
		alerts: [],
	};

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	shouldComponentUpdate(_props: unknown, nextState: AlertsState): boolean {
		if (nextState.alerts.length !== this.state.alerts.length) {
			return true;
		}

		const len = nextState.alerts.length;
		for (let i = 0; i < len; ++i) {
			if (
				nextState.alerts[i].animationState !==
				this.state.alerts[i].animationState
			) {
				return true;
			}

			if (nextState.alerts[i].show !== this.state.alerts[i].show) {
				return true;
			}
		}

		return false;
	}

	componentDidMount(): void {
		addListener('showAlert', this.show);
		addListener('showAlertLong', this.showLong);
		addListener('hideAlert', this.hide);
		addListener('noConnectionAlert', this.noConnection);
		addListener('showAlertOpentokMuted', this.showOpentokMuted);
		addListener('showAnnouncement', this.showAnnouncement);
		addListener('getAlertById', this.getAlertById);
		addListener('getAllAlerts', this.getAllAlerts);
	}

	componentWillUnmount(): void {
		clearListeners();
	}

	parseType(type: unknown): string {
		if (type) {
			if (typeof type === 'string') {
				switch (type) {
					case 'warning':
						return type;
					case 'error':
						return type;
					case 'neutral':
						return type;
					case 'chat':
						return 'neutral';
					case 'announce':
						return 'announce';
					case 'positive':
						return type;
					default: {
						console.warn(
							`Was passed ${type}, Invalid alert type, valid types = 'warning', 'error', 'neutral', 'chat', 'announce', 'positive' or undefined`
						);
						return 'positive';
					}
				}
			} else {
				switch (type) {
					case ALERT_TYPES.WARNING:
						return 'warning';
					case ALERT_TYPES.ERROR:
						return 'error';
					case ALERT_TYPES.NEUTRAL:
						return 'neutral';
					case ALERT_TYPES.POSITIVE:
						return 'positive';
					case ALERT_TYPES.CHAT:
						return 'chat';
					case ALERT_TYPES.ANNOUNCE:
						return 'announce';
					default:
						return 'neutral';
				}
			}
		} else {
			return 'positive';
		}
	}

	trigger = (alert: ToastAlert): unknown => {
		this.setState({ alerts: [...this.state.alerts, alert] }, () => {
			setTimeout(() => {
				this.setState(
					{
						alerts: this.state.alerts.map((al) => {
							if (al.id === alert.id) {
								al.animationState = 'show';
							}

							return al;
						}),
					},
					this.forceUpdate
				);
			}, 50);

			if (alert.duration) {
				setTimeout(() => {
					this.hide(alert);
				}, alert.duration);
			}
		});

		return alert.id;
	};

	getAlertById = (id: string) => {
		return this.state.alerts.find(alert => alert.id === id);
	};

	getAllAlerts = () => {
		return this.state.alerts;
	};

	show = ({
		message = '',
		duration = 5000,
		type = 'positive',
		spinner = false,
		description = null,
		requirementsButton = false,
		children = null,
		onClick = null,
		afterClose = () => null,
		id = '',
		extraContent = null,
		descriptionStyle = {},
		className = '',
		noClamp = false
	}): unknown => {
		const _id = id || random(10);

		const alert: ToastAlert = {
			id: _id,
			duration: duration,
			type: this.parseType(type),
			spinner: spinner,
			description: description,
			animationState: 'hide',
			message: message,
			show: true,
			showButtons: false,
			requirementsButton: requirementsButton,
			children: children,
			onClick: onClick,
			afterClose,
			extraContent,
			descriptionStyle,
			className,
			noClamp,
		};

		return this.trigger(alert);
	};

	showLong = ({
		message = '',
		type = 'positive',
		spinner = false,
		description = null,
		signOut = false,
		children = null,
		onClick = null,
		afterClose = () => null,
		id = '',
		extraContent = null,
		descriptionStyle = {},
		noClamp = false
	}): unknown => {
		const _id = id || random(10);
		const alert: ToastAlert = {
			id: _id,
			type: this.parseType(type),
			spinner: spinner,
			description: description,
			animationState: 'hide',
			message: message,
			showButtons: false,
			show: true,
			duration: 1000000,
			signOut: signOut,
			children: children,
			onClick: onClick,
			afterClose,
			extraContent,
			descriptionStyle,
			noClamp,
		};
		return this.trigger(alert);
	};

	showChat = ({
		name = '',
		message = '',
		duration = 6000,
		image = '',
		user = '',
		id = '',
	}) => {
		const _id = id || random(10);
		const alert: ToastAlert = {
			id: _id,
			message: name,
			description: message,
			duration: duration,
			type: 'neutral',
			showButtons: false,
			show: true,
			image: image,
			chat: true,
			user: user,
		};

		return this.trigger(alert);
	};

	showOpentokMuted = ({ message = '', description = '' }): unknown => {
		const id = random(10);
		const alert: ToastAlert = {
			id: id,
			message: message,
			description: description,
			duration: null,
			type: 'neutral',
			show: true,
			animationState: 'hide',
			opentokMuted: true,
		};

		return this.trigger(alert);
	};

	showAnnouncement = ({
		announcementId = 0,
		messageText = '',
		buttonOn = false,
		buttonText = '',
		buttonLink = '',
		moderatorProfile = {} as Profile,
		scheduleDate = new Date(),
		recipients = '',
		buttonLinkSameTab = true,
		source_id = '',
		source_type = '',
		language = '',
		baseLanguage = ''
	}) => {
		const id = random(10);
		const avatar: string = (moderatorProfile.picture || moderatorProfile.avatar) ?? announcementAvatar;
		const alert: ToastAlert = {
			id,
			announcementId,
			announcement: true,
			type: 'announce',
			show: true,
			duration: 1000000,
			from: moderatorProfile.first_name,
			avatar,
			messageText,
			buttonOn,
			buttonText,
			buttonLink,
			scheduleDate,
			recipients,
			buttonLinkSameTab,
			source_id,
			source_type,
			language,
			baseLanguage
		};

		return this.trigger(alert);
	};


	noConnection = (): unknown => {
		const id = 'no connection';

		const alert = {
			id: id,
			type: 'warning',
			description:
				'Could not get a connection. Please check your internet and try again.',
			animationState: 'hide',
			message: 'Connection Problem',
			show: true,
			duration: 1000,
		};

		if (this.state.alerts.find((al) => al.id === 'no connection')) {
			return;
		} else {
			return this.trigger(alert);
		}
	};

	showException = (): unknown => {
		const id = 'exception';

		const alert = {
			id: id,
			type: 'error',
			description:
				"We're sorry, Brandlive Presenter ran into an unexpected error. An error report has been sent to our engineers.",
			animationState: 'hide',
			message: 'Unexpected Error',
			show: true,
			duration: 6000,
			exception: true,
		};

		if (this.state.alerts.find((al) => al.id === 'exception')) {
			return;
		} else {
			return this.trigger(alert);
		}
	};

	hide = (alert: ToastAlert): void => {

		if (!alert) {
			if (this.state.alerts.length > 0) {
				alert = this.state.alerts[0];
			} else {
				return;
			}
		}

		alert?.afterClose?.();

		this.setState(
			{
				alerts: this.state.alerts.map((al) => {
					if (al.id === alert.id) {
						al.animationState = 'hide';
					}

					return al;
				}),
			},
			() => {
				this.forceUpdate();
				setTimeout(() => {
					this.setState(
						{
							alerts: this.state.alerts.map((al) => {
								if (al.id === alert.id) {
									al.show = false;
								}

								return al;
							}),
						},
						this.forceUpdate
					);
				}, 300);

				setTimeout(() => {
					this.purgeHidden();
				}, 10000);
			}
		);
	};

	purgeHidden = (): void => {
		this.setState(
			{
				alerts: this.state.alerts.filter((al) => al.show === true),
			},
			this.forceUpdate
		);
	};

	showDate = (): string => {
		return new Date().toLocaleTimeString('en-US', {
			hour: 'numeric',
			minute: '2-digit',
		});
	};

	unmuteOpentok = (alert: ToastAlert): void => {
		// Display the mute buttons in the thumbnails now that browser audio is unmuted
		document.documentElement.className = document.documentElement.className.replace(
			'hide-opentok-mute-buttons',
			''
		);
		// window.OT.unblockAudio();
		this.hide(alert);
	};

	signOut = (): void => {
		// Storage.removeStorageItem('token');
		// window.location.reload();
	};

	renderAlert = (alert: ToastAlert, i: number, trackEvent: (data: any) => void, eventName: string, email: string) => {
		return alert.show ? (
			<div
				key={i}
				onClick={alert.onClick ? alert.onClick : () => null}
				className={classNames(
					'alert',
					isAdmin ? 'admin-alert' : '',
					alert.type,
					alert.animationState,
					alert.className,
					{
						'has-children': !!alert.children,
						clickable: !!alert.onClick,
						'no-avatar-announcement': !alert.avatar,
					}
				)}
			>
				{/* Opentok unmute toaster requires user to click unmute button (no X close button) */}

				<div className="alert-content">
					<a className="clear-alert" onClick={() => this.hide(alert)}>
						<Icon name={ICONS.CLOSE} size={12} color={COLORS.BLACK} />
					</a>

					<span className="alert-title">{alert.message}</span>

					{(alert.description || alert.spinner) && (
						<div className={classNames("alert-description-wrap", { 'no-clamp': !!alert?.noClamp })} style={alert.descriptionStyle}>
							{alert.description && (
								<MarkdownItem>{alert.description}</MarkdownItem>
							)}

							{alert.spinner && (
								<WaitingIndicator />
							)}
						</div>
					)}

					{alert.signOut && (
						<div className="alert-buttons">
							<button onClick={this.signOut} className="xsmall">
								Sign Out
							</button>
						</div>
					)}

					{alert.opentokMuted ? (
						<div className="alert-buttons">
							<button
								onClick={() => this.unmuteOpentok(alert)}
								id="unmute-streams-btn"
								className="xsmall positive"
							>
								Unmute Streams
							</button>
						</div>
					) : null}

					{alert.announcement && (<div>
						<OptionalComponent display={!!alert.avatar}>
							<div className="avatar"><img src={alert.avatar ?? undefined} alt="sender avatar" /></div>
						</OptionalComponent>
						<h6>{alert.from}
							<span className="unbold">
								{/* passing empty array for locale so it will be in default format for user locale */}
								{` ${(alert.scheduleDate ? new Date(alert.scheduleDate) : new Date()).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', })}`}
							</span>
						</h6>
						<p>{alert.messageText}</p>
						{alert.buttonOn === true && <div className="alert-buttons">
							<a
								onClick={() => trackEvent({
									action: ActionEvent.Click,
									action_type: ActionType.Active,
									target_type: ActionTargetType.Announcement,
									source_id: alert.source_id,
									source_type: alert.source_type,
									target_id_string: alert.buttonLink,
									current_language: alert.language,
									miscellaneous: JSON.stringify({ baseLanguage: alert.baseLanguage, messageText: alert.messageText, email })
								})}
								className="theme-btn link-btn"
								href={alert.buttonLink ?? undefined}
								rel="noreferrer"
								target={alert.buttonLinkSameTab ? '' : '_blank'}
							>{alert.buttonText}</a>
						</div>}
					</div>)}

					{alert.extraContent && <div onClick={() => this.hide(alert)}>
						{alert.extraContent}
					</div>}

					{alert.children && <>{alert.children}</>}
				</div>
			</div>
		) : null;
	};

	render(): JSX.Element {
		return (
			<div className="alerts-container">
				{this.state.alerts.map((alert, i) => {
					return this.renderAlert(alert, i, this.props.trackEvent, this.props.eventName, this.props.email);
				})}
			</div>
		);
	}
}

export default withTrackingHook(Alerts);
