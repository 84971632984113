import { LeaderboardEntry } from "../../../../../types/leaderboard";
import { EditorSizes } from "../../../../../types/template-layouts";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { useScreenMediaQuery } from "../../../../../utils/use-screen-media-query";
import PodiumContainer from "./podium-container";
import PodiumEntry from "./podium-entry";

interface IPodiumProps {
	leaderboardEntries: LeaderboardEntry[];
	viewProfile?: (uuid: string) => void;
}

const Podium: React.FC<IPodiumProps> = ({ leaderboardEntries, viewProfile }) => {
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);

	const { isLessThan768 } = useScreenMediaQuery();

	const isMobile = editorSize === EditorSizes.mobile || isLessThan768;

	const order = isMobile ? [0, 1, 2] : [1, 0, 2];

	return (
		<PodiumContainer containerClass={isMobile ? "mobile-podium" : ""}>
			<PodiumEntry position={isMobile ? undefined : "silver"} entry={leaderboardEntries?.[order[0]]} viewProfile={viewProfile} isMobile={isMobile} />
			<PodiumEntry position={isMobile ? undefined : "gold"} entry={leaderboardEntries?.[order[1]]} viewProfile={viewProfile} isMobile={isMobile} />
			<PodiumEntry position={isMobile ? undefined : "bronze"} entry={leaderboardEntries?.[order[2]]} viewProfile={viewProfile} isMobile={isMobile} />
		</PodiumContainer>
	);
};

export default Podium;
