import { Action, AsyncAction } from "../../../types/actions";
import {
	PasscodeList,
} from "../../../types/working-model";
import { DeletePasscodeList, GetPasscodeLists, GetAssociatedEvents, GetAssociatedEventsDetails, GetLinkedEmailToEvents } from "../../../connection/passcode-lists";

export const GET_ASSOCIATED_EVENTS = "GET_ASSOCIATED_EVENTS";
export function getAssociatedEvents(list: number, token: string, channel: number): AsyncAction {
	return {
		type: GET_ASSOCIATED_EVENTS,
		promise: GetAssociatedEvents(token, list, channel)
	};
}

export const GET_ASSOCIATED_EVENTS_DETAILS = "GET_ASSOCIATED_EVENTS_DETAILS";
export function getAssociatedEventsDetails(events: string[], token: string, channel: number): AsyncAction {
	return {
		type: GET_ASSOCIATED_EVENTS_DETAILS,
		promise: GetAssociatedEventsDetails(token, events, channel)
	};
}

export const GET_LINKED_EMAIL_TO_EVENTS = "GET_LINKED_EMAIL_TO_EVENTS";
export function getLinkedEmailToEvents(token: string, email: string, channel: number): AsyncAction {
	return {
		type: GET_LINKED_EMAIL_TO_EVENTS,
		promise: GetLinkedEmailToEvents(token, email, channel)
	};
}


export const GET_PASSCODE_LISTS = "GET_PASSCODE_LISTS";
export function getPasscodeLists(channel: number, token: string): AsyncAction {
	return {
		type: GET_PASSCODE_LISTS,
		promise: GetPasscodeLists(channel, token)
	};
}

export const ADD_PASSCODE_LIST = "ADD_PASSCODE_LIST";
export function addPasscodeList(list: PasscodeList): Action {
	return {
		type: ADD_PASSCODE_LIST,
		payload: list
	};
}

export const DELETE_PASSCODE_LIST = "DELETE_PASSCODE_LIST";
export function deletePasscodeList(token: string, channel: number, list: PasscodeList): AsyncAction {
	return {
		type: DELETE_PASSCODE_LIST,
		promise: DeletePasscodeList(token, channel, list)
	};
}

export const RESET_DELETE_ERROR = "RESET_DELETE_ERROR";
export function resetDeleteError(): Action {
	return {
		type: RESET_DELETE_ERROR,
		payload: null
	};
}
