import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import classNames from 'classnames';

import { PageModule, Session, SessionTypesEnum } from '../../../../types/working-model';
import { eventPath, getStylingOverrides } from '../../utils';
import { MainEventBannerLayoutTypesClassic, TemplateClassNames } from '../../../../types/template-layouts';
import { Tag } from '../../../general-ui/tag/tag';
// import { TIconName } from '../../../general-ui/icon/icon.types';
import { Button } from '../../../general-ui/button/button';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { ParamsProps } from '../../live-event';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { getLiveSessionBackground, niceDateTimeTz } from '../../../../utils/utils';
import { hasEventAccess, hasSessionAccess } from '../../../../utils/registration-utils';
import { COLORS, ICONS } from '../../../general-ui/icon';
import { checkIsLive } from "../../../../utils/live-checker";
import MainEventBannerLiveCarousel from './main-event-banner-live-carousel';
import MainEventBannerBreakout from '../main-event-banner-breakout/main-event-banner-breakout';
import '../../../../scss/live-event/base/modules/live-session-banner.scss';
import { useTranslate } from '../../../../i18n/useTranslationModules';


interface MainEventBannerLiveProps {
	module: PageModule
	template: string;
	liveSession: Session;
	useSessionImage?: boolean;
}

export const MainEventBannerLiveSession: React.FC<MainEventBannerLiveProps> = ({ module, template, liveSession, useSessionImage = false }) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle ?? workingEvent;

	const validSessions = useTypedSelector(state => state.LiveEventReducer.validSessions);
	const finishedRegistering = useTypedSelector(state => state.LiveEventReducer.finishedRegistering);
	const userVerified = useTypedSelector(state => state.LiveEventReducer.userVerified);
	const paidSessions = useTypedSelector(state => state.LiveEventReducer.paidSessions);
	const liveSessions = useTypedSelector(state => state.LiveEventReducer.liveSessions);
	const firesidesHostSessions = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const registration_bypass = useTypedSelector(state => !!state.LiveEventReducer.registration_bypass);
	const loadingValidSessions = useTypedSelector(state => state.LiveEventReducer.loadingValidSessions);
	const [isLive, setIsLive] = useState(liveSessions.includes(liveSession.uuid));
	const { language, eventName, uuid }: ParamsProps = useParams();

	const { content, styling_overrides } = module;
	// const { image, originalImage } = content;
	const layout_type = content?.layout_type || MainEventBannerLayoutTypesClassic.standard;
	const { t } = useTranslate(['home', 'homepage', 'session']);
	const history = useHistory();

	const overrides = getStylingOverrides(styling_overrides);
	const startDate = liveSession?.timestamp ? new Date(liveSession?.timestamp) : null;

	const backgroundImg = useCallback(() => {
		const disableSessionImages = event?.settings.disable_session_thumbnails === true;
		if (disableSessionImages) {
			const streamPlaceholderBGColor = liveSession?.layout?.styling_overrides?.content?.stream_placeholder_background_color;
			return streamPlaceholderBGColor?.length ? { background: streamPlaceholderBGColor[0] } : {};
		}

		const sessionThumbnail = getLiveSessionBackground(liveSession);
		if (useSessionImage && sessionThumbnail) {
			return { backgroundImage: `url(${sessionThumbnail})` };
		}

		if (overrides?.backgroundImg?.backgroundImage) {
			return overrides.backgroundImg;
		}

		return { backgroundImage: `url(${content.image})` };
	}, [content.image, event?.settings.disable_session_thumbnails, liveSession, overrides.backgroundImg, useSessionImage]);

	const goToLiveSession = () => {
		if (hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, registration_bypass, loadingValidSessions) && hasSessionAccess(eventBundle, liveSession, validSessions, (!!registration_bypass && !loadingValidSessions), paidSessions)) {
			if (liveSession.custom_url_enabled && liveSession.custom_url) {
				window.open(liveSession.custom_url, '_blank');
			} else {
				const sessionPath = `/${eventPath(eventName, uuid)}/${language}/session/${liveSession.uuid}`;
				history.push({ pathname: sessionPath, state: { from: location.pathname } });
			}
		} else {
			const registrationPath = `/${eventPath(eventName, uuid)}/${language}/registration`;
			history.push(registrationPath);
		}
	};

	const isOnDemand = liveSession.session_type === SessionTypesEnum.onDemand;
	const isBroadcast = liveSession.session_type === SessionTypesEnum.broadcast;
	const isBreakoutRooms = liveSession.session_type === SessionTypesEnum.breakoutRooms;
	const isFireside = liveSession.session_type === SessionTypesEnum.fireside;

	useEffect(() => {
		if (isBroadcast) {
			// Broadcast sessions are live if the broadcast is actually live on the stream, not the date
			setIsLive(liveSessions.includes(liveSession.uuid));
		} else {
			setIsLive(checkIsLive(liveSession));
		}

	}, [liveSessions, liveSession, isBroadcast]);


	const tagIcon = () => {
		if (isOnDemand) {
			return ICONS.ON_DEMAND;
		} else if (isBroadcast) {
			return ICONS.VIEWERS_EYE;
		} else if (isBreakoutRooms) {
			return ICONS.BREAKOUT_ROOMS;
		} else if (isFireside) {
			return ICONS.FIRESIDE;
		} else {
			return "";
		}
	};

	const tagText = (): string => {
		if (isOnDemand) {
			return t("homepage:On Demand");
		} else if (isBroadcast) {
			return t("homepage:Broadcast");
		} else if (isBreakoutRooms) {
			return t("homepage:Breakout Rooms");
		} else if (isFireside) {
			return t("homepage:Fireside");
		} else {
			return "";
		}
	};

	const tagIconColor = () => {
		switch (template) {
			case TemplateClassNames["Apollo"]:
			case TemplateClassNames['Limelight']:
				return COLORS.WHITE;
			case TemplateClassNames["Studio 54"]:
			case TemplateClassNames["O2"]:
				return COLORS.BLACK;
			default:
				return COLORS.WHITE;
		}
	};

	if (!startDate) return null;
	return (
		<section className={
			classNames(
				'live-session-banner',
				`live-session-banner-${layout_type}`,
				template,
			)}
			style={backgroundImg()}
		>
			<div className="live-session-banner-header">
				<div>
					<TypographyItem className="evt-heading-5" tagName="p">
						{t(`home:mainEventBanner.${module.id}.title`, t('home:mainEventBanner.title', content.title[language]))}
					</TypographyItem>
				</div>
			</div>
			<div className="module-container">
				<div className="live-session-banner-tags">
					<Tag
						classNameTag={classNames('tag-container-type')}
						text={tagText()}
						icon={tagIcon()}
						iconColor={tagIconColor()}
					/>
					{isLive && (
						<Tag
							classNameTag={classNames('tag-container-live')}
							text={t('session:Live')}
						/>
					)}
					{/*

					need to get real time viewer metrics for this to make sense

					<Tag
						classNameTag={classNames('tag-container-views')}
						text="340"
						icon={"view" as TIconName}
					/>

					*/}
				</div>
				<TypographyItem
					className="live-session-tag-date stable"
					tagName="time"
				>
					{niceDateTimeTz(startDate.valueOf(), language)}
				</TypographyItem>
				<TypographyItem className="live-session-banner-title evt-heading-2" tagName="h1">
					{/* TODO: figure out how we want to handle session titles */}
					{liveSession.title[language] ?? liveSession?.title?.base}
				</TypographyItem>
				<Button
					onClick={goToLiveSession}
					classButton="primary"
					template={template}
				>{t(isLive ? 'homepage:Watch Session' : 'homepage:JoinSession')}</Button>
			</div>
		</section>
	);
};

interface IMainEventBannerLiveProps {
	module: PageModule
	template: string;
	liveSessionList: Session[];
	isAgendaPageEditor?: boolean;
}

// handles logic if to show the carousel or just a single live session.
const MainEventBannerLive: React.FC<IMainEventBannerLiveProps> = (props) => {
	const { module, template, liveSessionList, isAgendaPageEditor = false } = props;

	if (liveSessionList.length === 1) {
		const session = liveSessionList[0];

		if (session.session_type === SessionTypesEnum.breakoutRooms) {
			return (
				<div style={{ maxHeight: '460px' }} key={module.id}>
					<MainEventBannerBreakout session={session} template={template} hideMobileHeader useSessionThumbnail isOnHomepage />
				</div>
			);
		}

		return (
			<MainEventBannerLiveSession
				module={module}
				template={template}
				key={module.id}
				liveSession={session}
				useSessionImage
			/>
		);

	} else {
		return (
			<MainEventBannerLiveCarousel
				module={module}
				template={template}
				key={module.id}
				liveSessionList={liveSessionList}
				isAgendaPageEditor={isAgendaPageEditor}
			/>
		);
	}
};

export default MainEventBannerLive;
