import { matchPath } from "react-router";

import { showAlert } from "../../components/general-ui/alert/alert-service";
import { fetchLiveEventBundle } from "../../connection/live-events/event";
import { GetRecordingJWT } from "../../connection/sessions";
import { IRegistrationGDPR, IRegistrationResponse, MagicLinkCheck, ReRegisterCheck, updateMarketingEmailSelection, ValidateRegistration } from "../../connection/registration";
import { BlProfile, BrandliveEvent, Dictionary, OptInStatus, SessionPlaybackVideo, SessionVideo } from "../../types/working-model";
import { getStorageItem, getStorageObject, removeStorageItem, setStorageItem } from "../../utils/local-storage";
import { findFirstIncompleteStep, hasEventAccess } from "../../utils/registration-utils";
import { DebugDefaultRouteMap, DefaultRouteMap } from '../../components/live-event/hooks/use-route-map';
import { getAllAvailableLanguages, getAttendeeLocalLanguage } from "../../components/live-event/utils";
import store from '../main';
import { setChannelFeatures, setFeatureFlags } from "../actions/feature-flags";
import { getChannelFlagMapFromList, getPathKey, jwtDecode, sortRegistrationSteps } from "../../utils/utils";
import { streamsLoader as translationService } from "../../i18n/config";
import { reRegister } from "../actions/event/event-actions";
import { EPaletteModes } from "types/theme-packs";
import { mutateV1ColorsToV2Colors } from "utils/colors/mutate-v1-colors-to-v2-colors";
import { getRecordingJWT } from "hooks/recording-jwt.hooks";

interface PageLoadResponse {
	registrationId: string | null;
	registeredLanguage?: string | null;
	eventBundleError: string;
	playbackUrls: Dictionary;
	secondaryVideos: Dictionary;
	userVerified: boolean;
	registrationStep: number;
	validationFailure: boolean;
	blProfileUser: BlProfile | null;
	blProfileUserToken: string | null;
	shouldRedirectToRegistration: boolean;
	shouldRedirectToAlreadyRegisteredEmail: string | null;
	_404: boolean;
	eventBundle?: BrandliveEvent;
	validSessions: (string | number)[];
	paymentRequired: boolean;
	paidSessions?: string[];
	finishedRegistering: boolean;
	verified: boolean;
	firesidesHostSessions: number[];
	userSavedSessions: string[] | null;
	authorizedPages: number[];
	hasIncompleteRegistrationStep?: boolean;
	registered_language?: string;
	leaderboardIsOn: boolean;
	gdpr?: IRegistrationGDPR;
	registrationBypass: boolean;
	registrationDeleted: boolean;
	sessionVideos?: SessionPlaybackVideo[];
}

export async function LoadEventBundle(channelHost: string, eventUrl: string): Promise<{ eventBundle: BrandliveEvent | null, _404: boolean }> {
	try {
		const eventBundle = await fetchLiveEventBundle(channelHost, eventUrl);

		const initialState: { eventBundle: BrandliveEvent | null; _404: boolean; } = {
			eventBundle: null,
			_404: false
		};

		if (('message' in eventBundle && eventBundle.message === 'Internal Server Error') || 'error' in eventBundle) {
			console.error(eventBundle);
			initialState._404 = true;
			return initialState;
		}

		translationService.postEventLoad(eventBundle);
		initialState.eventBundle = {
			...eventBundle,
			...{ registration_steps: sortRegistrationSteps(eventBundle.registration_steps) },
		};

		if (initialState.eventBundle) {
			initialState.eventBundle = {
				...initialState.eventBundle,
				settings: {
					...initialState.eventBundle.settings,
					design: {
						...initialState.eventBundle.settings.design,
						color_theme: initialState.eventBundle.settings?.design?.color_theme || EPaletteModes.Light,
						colors: {
							...initialState.eventBundle.settings.design.colors,
							colors: mutateV1ColorsToV2Colors(initialState.eventBundle?.settings?.design?.colors?.colors),
						},
					}
				}
			};
		}
		store.dispatch(setFeatureFlags(eventBundle.feature_flags));
		store.dispatch(setChannelFeatures(getChannelFlagMapFromList(eventBundle.channel_features)));

		return initialState;
	} catch (e) {
		console.error(e);
		return { _404: true, eventBundle: null };
	}

}

export async function LoadPage(eventBundle: BrandliveEvent, t: any): Promise<PageLoadResponse> {
	const initialState: PageLoadResponse = {
		registrationId: null,
		registeredLanguage: null,
		eventBundleError: '',
		playbackUrls: {},
		secondaryVideos: {},
		userVerified: false,
		registrationStep: 0,
		validationFailure: false,
		blProfileUserToken: null,
		blProfileUser: null,
		shouldRedirectToRegistration: false,
		shouldRedirectToAlreadyRegisteredEmail: null,
		_404: false,
		validSessions: [],
		paymentRequired: false,
		finishedRegistering: false,
		verified: false,
		firesidesHostSessions: [],
		userSavedSessions: null,
		authorizedPages: [],
		hasIncompleteRegistrationStep: false,
		leaderboardIsOn: false,
		registrationBypass: false,
		registrationDeleted: false
	};

	try {
		const registrationId = getStorageItem(`reg.${eventBundle.uuid}`);
		const blToken = getStorageItem('bl-profile');
		const blProfileUser: BlProfile | null = blToken ? jwtDecode(blToken) as BlProfile : null;
		const storedParams = getStorageObject(`search_params-${getPathKey()}`, 0);
		const queryParams = new URLSearchParams(window.location.search);

		// Used if a user has a link to the event (i.e. calendar invite) and their token is either expired
		// or they are accessing the event on a different device. This will direct them to the "already registered" flow.
		// Note this is a string and not a boolean so we can autofill their email
		// Will only trigger if the user doesn't have a bl-token
		const previouslyRegisteredEmail = queryParams.get('previouslyRegisteredEmail');
		if (previouslyRegisteredEmail) {
			initialState.shouldRedirectToAlreadyRegisteredEmail = previouslyRegisteredEmail;
		}

		initialState.blProfileUser = blProfileUser as BlProfile;
		initialState.blProfileUserToken = blToken;

		const userCode = getStorageItem(`user-code`);

		let alreadyValidRegistration = false;

		const allAvailableLanguages = getAllAvailableLanguages(eventBundle);

		//we have a stored registration id for this user for this session
		if (registrationId && eventBundle.registration_on) {
			try {
				//validates that the registration id is valid and hasn't been messed with - if token is not null, send it, otherwise leave it undefined
				const valid = await ValidateRegistration(eventBundle.uuid, registrationId, userCode, blToken ?? undefined);

				const statusCode = Number(valid?.statusCode) || 200; // we don't send status code a good 

				if (!valid || valid.error === 'invalid' || statusCode >= 400) {
					throw new Error('Validate Registration failed');
				}

				// if user saved sessions returned, set in initial state to pass on to redux
				if (valid?.userSavedSessions) {
					initialState.userSavedSessions = valid.userSavedSessions;
				}
				if (valid?.eventBundle) {
					initialState.eventBundle = valid.eventBundle;
				}

				if (valid?.validSessions) {
					initialState.validSessions = valid.validSessions;
				}

				if (valid?.gdpr) {
					initialState.gdpr = valid.gdpr;
				}

				if (valid?.registrationDeleted) {
					initialState.registrationDeleted = valid.registrationDeleted;
				}

				if (valid?.registration_bypass) {
					initialState.registrationBypass = valid.registration_bypass;
				}

				setRegResponseFields(initialState, eventBundle, valid);
				//set registration id
				initialState.registrationId = registrationId;

				if (valid.registered_language && allAvailableLanguages.includes(valid.registered_language)) {
					initialState.registeredLanguage = valid.registered_language;
				}

				if (valid.sessionVideos) {
					(initialState.eventBundle as BrandliveEvent).sessionVideos = valid.sessionVideos;
				}

				initialState.leaderboardIsOn = valid.leaderboardIsOn;

				if (valid) {
					alreadyValidRegistration = true;
				}
			} catch (e) {
				console.error(e);
				// I don't think this is used anywhere
				initialState.validationFailure = true;
			}
		} else if (eventBundle.playbackUrls) {
			initialState.playbackUrls = eventBundle.playbackUrls;
			initialState.secondaryVideos = eventBundle.secondaryVideos ?? {};
		}

		const reRegisterCode = queryParams.get('reg-code') ?? storedParams?.['reg-code'];
		const magicLinkCode = queryParams.get('magic-link') ?? storedParams?.['magic-link'];
		const marketingOptInCode = queryParams.get('marketing-opt-in') ?? storedParams?.['marketing-opt-in'];

		// // useCheckMarkeketingParams();
		// // const queryParams = new URLSearchParams(window.location.search);
		// const event: BrandliveEvent | null = eventBundle;
		// const channel = event?.channel ?? 0;
		if (marketingOptInCode) {
			const [eventUUID, blProfileUUID] = marketingOptInCode.split('|');
			if (eventUUID && blProfileUUID) {
				const channel = eventBundle.channel;
				// THIS UPDATES THE SELECTION TO DOUBLE OPTED IN
				const updateMarketingEmailSelectionBody = { token: '', eventUUID: eventUUID, profileUUID: blProfileUUID, optinTextStatus: OptInStatus.doubleOptedIn, channel };
				updateMarketingEmailSelection(updateMarketingEmailSelectionBody);
			}
			window.history.replaceState(undefined, document.title, window.location.href.split("?")[0]);
		}

		const magicLinkLanguage = getAttendeeLocalLanguage(eventBundle);

		let response;
		if (reRegisterCode
			// Ignore magic link for revisiting users who have already validated their registration
			// Magic links do not work when SSO is enabled
			|| (!alreadyValidRegistration && magicLinkCode && !eventBundle.registration_settings?.singleSignOn?.isOn)
		) {
			try {
				//checks the re-register query param and returns the first registration used by user and their token
				//works essentially the same as the registration call
				response = reRegisterCode ?
					await ReRegisterCheck(eventBundle.uuid, reRegisterCode) :
					await MagicLinkCheck(eventBundle.uuid, magicLinkCode as string, magicLinkLanguage);

				// Remove search params if regReg or magic link key has been checked
				removeStorageItem(`search_params-${getPathKey()}`);

				initialState.blProfileUser = magicLinkCode ? response.blProfile as BlProfile : initialState.blProfileUser;
				setRegResponseFields(initialState, eventBundle, response);

				if ("used" in response && response.newLinkGenerated === true) {
					//display alert telling user that the code is used up
					showAlert({
						message: t(`live.registration_link_used_message`, "Registration Link Used"),
						description: t(`live.registration_link_used_desc`, "Sorry, it looks like that email link has already been used. A new link has been sent to your email, please use that link or register again."),
						type: "neutral",
						duration: 10000
					});
					// these two states must be set together - setting redirect to registration without user verified could cause an endless loop
					initialState.userVerified = false;
					initialState.shouldRedirectToRegistration = true;

					return initialState;
				}
				else if ("used" in response) {
					//display alert telling user that the code is used up
					showAlert({
						message: t(`live.registration_link_deleted_message`, "Registration Link Used"),
						description: t(`live.registration_link_deleted_desc`, "Sorry, it looks like that email link has been deleted. Please register again or click the 'Already Registered' link."),
						type: "error",
						duration: 10000
					});
					// these two states must be set together - setting redirect to registration without user verified could cause an endless loop
					initialState.userVerified = false;
					initialState.shouldRedirectToRegistration = true;

					return initialState;
				}

				const email = initialState.blProfileUser?.email;
				const lang = initialState.registeredLanguage;
				const ACTION = 'login';
				if (magicLinkCode && email && lang) {
					reRegister(
						{
							eventUuid: eventBundle.uuid,
							email: email,
							lang,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							action: ACTION, // for recaptcha v3
							hostname: window.location.hostname, // for recaptcha
							isMagicLink: true,
						},
						{},
					);
				}
				// if user had existing saved sessions, set them here
				if (response?.userSavedSessions) {
					initialState.userSavedSessions = response.userSavedSessions;
				}

				if (response?.gdpr) {
					initialState.gdpr = response.gdpr;
				}

				// get leaderboardIsOn state from outside publish
				initialState.leaderboardIsOn = response.leaderboardIsOn;

				//drop the code from the url in case they refresh, they shouldn't have to be re-checked.
				window.history.replaceState(undefined, document.title, window.location.href.split("?")[0]);
			} catch (e) {

				initialState.userVerified = false;
				initialState.shouldRedirectToRegistration = true;
			}
		}

		//registration is on and user has never registered
		// and does not have an incomplete registration step
		if (!hasEventAccess(eventBundle, initialState.validSessions, initialState.finishedRegistering, initialState.userVerified, initialState.firesidesHostSessions, initialState.registrationBypass, false) &&
			!initialState.hasIncompleteRegistrationStep) {
			//should check a set of pages that should only be accessible post registration
			const match = matchPath(window.location.pathname, {
				path: [
					DebugDefaultRouteMap.Session,
					DefaultRouteMap.Session,
					DebugDefaultRouteMap.Home,
					DefaultRouteMap.Home,
					DebugDefaultRouteMap.Sessions,
					DefaultRouteMap.Sessions,
					DebugDefaultRouteMap.Breakout,
					DefaultRouteMap.Breakout,
					DebugDefaultRouteMap.Fireside,
					DefaultRouteMap.Fireside,
				],
				exact: true
			});

			//only redirect if the current path matches a session page
			initialState.shouldRedirectToRegistration = !!match;

			if (magicLinkCode && response?.error === 'missing-required-field') {
				initialState.shouldRedirectToRegistration = true;
			}
		}

		await getRecordingJWT();

		return initialState;
	} catch (e) {
		console.error(e);
		return initialState;
	}
}

function setRegResponseFields(initialState: PageLoadResponse, eventBundle: BrandliveEvent, registrationResponse: IRegistrationResponse) {
	//validation error - remove registration id
	if ("message" in registrationResponse || "error" in registrationResponse || "used" in registrationResponse) {
		initialState.validationFailure = true;
		removeStorageItem(`reg.${eventBundle.uuid}`);
		return; // Short circuit if there's an error
	}

	if (registrationResponse?.paidSessions) {
		initialState.paidSessions = registrationResponse.paidSessions;
	}

	const registrationSteps = eventBundle?.registration_steps?.filter(step => step.isOn);
	const incompleteRegistrationStep = findFirstIncompleteStep(registrationSteps ?? [], eventBundle?.required_registration_questions, registrationResponse.stepsCompleted);

	initialState.playbackUrls = registrationResponse.playbackUrls;
	initialState.validSessions = registrationResponse.validSessions;
	initialState.paymentRequired = registrationResponse.paymentRequired;
	initialState.finishedRegistering = !registrationResponse.paymentRequired && !incompleteRegistrationStep;
	initialState.userVerified = registrationResponse.userVerified;
	initialState.secondaryVideos = registrationResponse?.secondaryVideos ?? {};
	initialState.firesidesHostSessions = registrationResponse?.firesidesHost ?? [];
	initialState.authorizedPages = registrationResponse?.authorizedPages ?? [];
	initialState.shouldRedirectToRegistration = !!incompleteRegistrationStep;

	//update event bundle with sessions from validate registration
	initialState.eventBundle = {
		...eventBundle,
		sessions: registrationResponse.eventBundle.sessions,
	};

	//set registration id
	initialState.registrationId = registrationResponse.registrationId;

	const availableLanguages = getAllAvailableLanguages(eventBundle);

	if (registrationResponse.registered_language && availableLanguages.includes(registrationResponse.registered_language)) {
		initialState.registeredLanguage = registrationResponse.registered_language;
	}

	const blToken = registrationResponse.token;
	const blProfileUser: BlProfile | null = blToken ? jwtDecode(blToken) as BlProfile : null;

	initialState.blProfileUser = blProfileUser as BlProfile;
	initialState.blProfileUserToken = blToken;


	// Registration ID already exists, so skip to next step
	if (registrationSteps && registrationSteps.length >= 2) {
		initialState.registrationStep = 1;
	}

	//user is essentially verified entirely by this email
	//and the full event is now loaded
	setStorageItem('bl-profile', registrationResponse.token);
	setStorageItem('Bl-Profile-Token', registrationResponse.token);

	if (incompleteRegistrationStep) { // user has an incomplete registration step. don't set reg token and send to pertinent registration step
		setStorageItem(`incompleteRegistration.${eventBundle.uuid}`, registrationResponse.registrationId);
		removeStorageItem(`reg.${eventBundle.uuid}`);
		initialState.registrationStep = registrationSteps?.findIndex(step => step.type === incompleteRegistrationStep) || 0;
		initialState.hasIncompleteRegistrationStep = true;
	} else {
		removeStorageItem(`incompleteRegistration.${eventBundle.uuid}`);
		setStorageItem(`reg.${eventBundle.uuid}`, registrationResponse.registrationId);
		initialState.hasIncompleteRegistrationStep = false;
	}

	if (registrationResponse.userCode) {
		setStorageItem('user-code', registrationResponse.userCode);
	}
}
