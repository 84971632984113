import React from "react";
import { useDispatch } from "react-redux";
import BasicCard from "../components/general-ui/basic-card/basic-card";
import CollapsingList from "../components/general-ui/collapsing-list/collapsing-list";
import { forceComponentError } from "../store/actions/event/event-actions";
import { useTypedSelector } from "../store/reducers/use-typed-selector";

function TestErrorBoundary() {

	const components = useTypedSelector(state => state.LiveEventReducer.errorBoundaries.components);

	const dispatch = useDispatch();

	return (
		<div style={{
			position: 'fixed',
			top: '50px',
			right: '50px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			zIndex: '100'
		}}>
			<CollapsingList
				label="Testable components"
				className="error-boundary-list"
			>
				{components.map(component => {
					return (
						<BasicCard p={0} key={component.value}>
							<div className="top-level-card" onClick={() => dispatch(forceComponentError(component.value))}>
								<div className="top-level-card-content">
									<div className="title">
										{component.label}
									</div>
								</div>
							</div>
						</BasicCard>
					);
				})}
			</CollapsingList>
		</div >
	);
}

export default TestErrorBoundary;
