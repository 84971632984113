type TRgbaToHex = (rgba: { r: number; g: number; b: number; }) => string;

export const rgbaToHex: TRgbaToHex = ({ r, g, b }) => {
	return "#" + ((1 << 24) + (r << 16) + (g << 8) + b)
		.toString(16)
		.slice(1)
		.toUpperCase();
};

export const hexToRgba = (hex: string): { r: number; g: number; b: number; a: number; } => {
	const hexAsInt = parseInt(hex.replace("#", ""), 16);
	const r = (hexAsInt >> 16) & 255;
	const g = (hexAsInt >> 8) & 255;
	const b = hexAsInt & 255;

	return { r, g, b, a: 1 };
};
