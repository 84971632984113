import { useEffect } from "react";
import { showAlert } from "@general-ui/alert/alert-service";

import { clearErrorUpdatingProfile } from "store/actions/event/event-actions";
import { useAppDispatch, useTypedSelector } from "store/reducers/use-typed-selector";
import { useTranslate } from "i18n/useTranslationModules";

const useErrorUpdatingProfileAlert = () => {
	const hasError = useTypedSelector(state => state.LiveEventReducer.errorUpdatingProfile);
	const translationUpdates = useTypedSelector(state => state.LiveEventReducer.translationUpdates);

	const { t } = useTranslate('homepage');

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (hasError) {
			showAlert({
				message: t(
					"There was an error updating your profile. Please refresh the page and try again.",
					"There was an error updating your profile. Please refresh the page and try again.",
				),
				type: 'error',
				duration: 10000,
				afterClose: () => {
					dispatch(clearErrorUpdatingProfile());
				}
			});
		}
	}, [dispatch, hasError, t, translationUpdates]);
};

export default useErrorUpdatingProfileAlert;
