import classNames from "classnames";
import { useEffect, useMemo, useRef } from "react";

import { PageModuleType, TranslateString } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { usePageModule } from "../hooks/panel.hooks";
import useTrackHeight from "../../../../../../../utils/use-track-height";
import { useAppDispatch } from "../../../../../../../store/reducers/use-typed-selector";
import { setAdminSubPanelHeaderHeight } from "../../../../../../../store/actions/admin/create-event";
import { useLanguageParam } from "components/live-event/utils";
import { useHistoryState } from "../../../../../../../utils/use-history-state";

import './sub-panel-wrapper.scss';

const SubPanelWrapper: React.FC<{
	children: JSX.Element,
	title?: string,
	id?: string,
	rightHeader?: boolean,
	enforceLabelTitle?: boolean
}> = ({
	children,
	title,
	id,
	rightHeader,
	enforceLabelTitle = false,
}) => {
		const pageModule = usePageModule();
		const language = useLanguageParam();
		const historyState = useHistoryState<{ panelTitle: TranslateString | string }>();

		const dispatch = useAppDispatch();

		// set admin header height in redux so we can use it to adjust heights of other components
		const subPanelHeaderWrapperRef = useRef<HTMLDivElement>(null);
		const { height: adminHeaderHeight } = useTrackHeight({ ref: subPanelHeaderWrapperRef.current });
		useEffect(() => {
			dispatch(setAdminSubPanelHeaderHeight(adminHeaderHeight));
		}, [adminHeaderHeight, dispatch]);

		// custom tab titles live within the page module
		// so if this is a custom sub tab, first use translate string
		// custom heading, fall back to selected title
		const renderTitle = useMemo(() => {
			if (historyState?.panelTitle) {
				if (typeof historyState.panelTitle === 'string') {
					return historyState.panelTitle;
				} else {
					return historyState.panelTitle[language] ?? historyState.panelTitle.base;
				}
			}

			if (enforceLabelTitle) return title;

			if (pageModule?.content?.custom_tab) {
				if (pageModule?.content?.custom_heading[language]) {
					return pageModule.content.custom_heading[language];
				} else if (pageModule?.content?.custom_heading.base) {
					return pageModule.content.custom_heading.base;
				}
			}

			if ((pageModule?.type === PageModuleType.questions || pageModule?.type === PageModuleType.comment_box_prompt) && pageModule?.modules?.length) {
				return pageModule?.modules[0]?.title[language] || pageModule?.modules[0]?.title.base;
			}

			if (pageModule?.type === PageModuleType.survey && pageModule?.modules?.length) {
				return pageModule?.modules[0]?.name[language] || pageModule?.modules[0]?.name.base;
			}

			return title;
		}, [
			historyState?.panelTitle,
			enforceLabelTitle,
			title,
			pageModule?.content?.custom_tab,
			pageModule?.content?.custom_heading,
			pageModule?.type,
			pageModule?.modules,
			language
		]);

		return (
			<div className="sub-panel-wrapper" id={id}>
				<div className={classNames('sub-panel-header', { 'right-header': rightHeader })}>
					<div className="title">
						<div className="title-left">
							<button
								className="no-style"
								onClick={() => history.back()}>
								<Icon name={ICONS.ARROW_LEFT} size={16} color={COLORS.WHITE} />
							</button>
							<h6>{renderTitle}</h6>
						</div>
					</div>
				</div>
				<div className="panel-body-v2">
					{children}
				</div>
			</div>
		);
	};

export default SubPanelWrapper;