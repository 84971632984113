import { setMarketingOptInType } from '../../../../../../../store/actions/admin/create-event';
import { useAppDispatch, useTypedSelector } from '../../../../../../../store/reducers/use-typed-selector';
import { MarketingOptInTypes } from '../../../../../../../types/working-model';
import { ConditionalWrapper } from '../../../../../../../utils/conditional-wrapper';
import Switch from '../../../../../../general-ui/switch/switch';
import { Tooltip } from '../../../../../../general-ui/tooltip/tooltip';
import { useReadonlyMode } from '../../../../shared/use-readonly-mode';
import Icon, { COLORS, ICONS } from '@general-ui/icon';

import './marketing-email-opt-in-switches.scss';

export default function MarketingEmailOptInSwitches() {
	const registration_steps = useTypedSelector(state =>
		state.CreateEventReducer.workingEvent?.registration_steps
	);
	const marketingOptInType = useTypedSelector(state =>
		state.CreateEventReducer.workingEvent?.registration_settings?.marketing_email_options
	);

	const optInDoubleFeatureFlag = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags["streams.gdpr_marketing_email_double_optin"]);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	// email marketing cannot be toggled on when custom SSO is in use
	const isSingleSignOn = !!workingEvent?.registration_settings?.singleSignOn?.isOn;

	const dispatch = useAppDispatch();

	const isReadOnly = useReadonlyMode();
	const pointerEvents = isReadOnly ? 'none' : 'initial';
	const isMissingEmailStepQuestion = !registration_steps?.find(step => {
		return step.isOn && step.questions?.some(q => q.registration_question === 3);
	});
	const isDoubleOptInEnabled = marketingOptInType === MarketingOptInTypes.doubleOptIn;
	const isMarketingOptInEnabled = isDoubleOptInEnabled || marketingOptInType === MarketingOptInTypes.singleOptIn;

	const optInSingle = () => {
		const updated = isMarketingOptInEnabled
			? MarketingOptInTypes.noOptIn
			: MarketingOptInTypes.singleOptIn;
		dispatch(setMarketingOptInType(updated));
	};
	const optInDouble = () => {
		const updated = isDoubleOptInEnabled
			? MarketingOptInTypes.singleOptIn
			: MarketingOptInTypes.doubleOptIn;
		dispatch(setMarketingOptInType(updated));
	};

	const tooltipComponent = (children: JSX.Element) => <Tooltip position="bottom-left" tooltip="Disabled since SSO is enabled">
		{children}
	</Tooltip>;

	return (
		<>
			<div className="card-container marketing-email-opt-in" style={{ pointerEvents }}>
				<div className="toggle-card recaptcha-card">
					<label>Opt into marketing emails</label>
					<div>
						<ConditionalWrapper condition={isSingleSignOn} wrapper={children => tooltipComponent(children)}>
							<Switch
								on={isMarketingOptInEnabled}
								onClick={optInSingle}
								value="single-marketing-opt-in"
								disabled={isMissingEmailStepQuestion || isSingleSignOn}
							/>
						</ConditionalWrapper>
					</div>
				</div>
			</div>
			{isMarketingOptInEnabled && optInDoubleFeatureFlag && (
				<div className="card-container marketing-email-opt-in" style={{ pointerEvents, marginLeft: '24px' }}>
					<div className="toggle-card recaptcha-card">
						<label className="double-opt-in-label">
							Double opt-in
							<Tooltip
								maxWidth={220}
								tooltip="Enabling Double opt-in sends an email to attendees who opt-in at registration with a second link to confirm subscription to marketing emails"
							>
								<Icon name={ICONS.INFO_OUTLINE} size={14} color={COLORS.CYAN} />
							</Tooltip>
						</label>
						<div>
							<Switch
								on={isDoubleOptInEnabled}
								onClick={optInDouble}
								value="double-marketing-opt-in"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
