import { GetHCArticles } from '../../../connection/assistant';
import { Action, AsyncAction } from '../../../types/actions';
import { EAssistantPhase } from '../../reducers/admin/assistant';


export const UPDATE_PHASE = "UPDATE_PHASE";
export function updatePhase(newPhase: EAssistantPhase): Action {
	return {
		type: UPDATE_PHASE,
		payload: newPhase
	};
}

export const RESET_CHECKLIST = "RESET_CHECKLIST";
export function resetChecklist(): Action {
	return {
		type: RESET_CHECKLIST,
		payload: null
	};
}

export const GET_HC_ARTICLES = "GET_HC_ARTICLES";
export function getHCArticles(token: string, search: string): AsyncAction {
	return {
		type: GET_HC_ARTICLES,
		promise: GetHCArticles(token, search)
	};
}

export const CLEAR_ARTICLES = "CLEAR_ARTICLES";
export function clearArticles(): Action {
	return {
		type: CLEAR_ARTICLES,
		payload: null
	};
}

export const TOGGLE_ASSISTANT = "TOGGLE_ASSISTANT";
export function toggleAssistant(on: boolean): Action {
	return {
		type: TOGGLE_ASSISTANT,
		payload: on
	};
}