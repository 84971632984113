import { useCallback, useState } from "react";

import { Session } from "../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";
import { EUploadOptions, ImageUploadOptions, ImageUploader } from "../../../general-ui/image-uploader/image-uploader";

import { batch } from "react-redux";
import { useAppDispatch, useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { updateSession, updateWorkingSession } from "../../../../store/actions/admin/create-event/session";
import { updateWorkingEventSession } from "../../../../store/actions/admin/create-event";
import { useSessionDetailsV2 } from "../../../../hooks/channel.hooks";
import * as Signals from 'utils/event-emitter';
import TopRightButtonControlContainer from "@general-ui/button-control/button-control-wrapper";

interface ISessionThumbnailProps {
	session: Session;
	isEditor?: boolean;
}

const SessionThumbnail = ({ session, isEditor }: ISessionThumbnailProps) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const [largeImage, setLargeImage] = useState<string | null>(session.pre_broadcast_thumbnail || null);
	const [openLargeImageModal, setOpenLargeImageModal] = useState<ImageUploadOptions>(null);

	const sessionDetailsV2FeatureFlagOn = useSessionDetailsV2();

	const dispatch = useAppDispatch();

	const saveLargeImage = useCallback((image: { original: string, edited: string; }) => {
		if (!session || !token) return;
		const workingEventSession: Session | undefined = workingEvent?.sessions.find(session => session.uuid === session.uuid);
		const updatedSession: Session = {
			...session,
			pre_broadcast_thumbnail: image.edited,
			// we are temporarily disabling post session thumbnails
			use_different_thumbnails: false,
		};
		setLargeImage(image.edited);
		// update database and redux
		batch(() => {
			dispatch(updateSession(updatedSession, token));
			dispatch(updateWorkingSession(updatedSession));
			workingEventSession && dispatch(updateWorkingEventSession(updatedSession));
		});
	}, [dispatch, session, token, workingEvent?.sessions]);

	if (!session || !isEditor) return null;
	if (!sessionDetailsV2FeatureFlagOn) return null;
	return (
		<>


			<TopRightButtonControlContainer>
				<>
					{largeImage ? (
						<button onClick={() => setOpenLargeImageModal(EUploadOptions.edit)} className="round crop">
							<Icon name={ICONS.IMAGE_CROP} color={COLORS.WHITE} size={14} />
						</button>
					) : null}
				</>

				<button className="round" onClick={() => setOpenLargeImageModal(EUploadOptions.upload)}>
					<Icon name={ICONS.IMAGE} size={14} color={COLORS.WHITE} />
				</button>

				<button className="round" onClick={() => Signals.broadcastSignal('session-details-modal:open')}>
					<Icon name={ICONS.EDIT} size={14} color={COLORS.WHITE} />
				</button>
			</TopRightButtonControlContainer>

			<ImageUploader
				originalImage={largeImage}
				onClose={() => setOpenLargeImageModal(null)}
				fileTitle="Image"
				onSave={saveLargeImage}
				open={openLargeImageModal}
			/>
		</>
	);
};

export default SessionThumbnail;
