import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { getPageTrackingParams, isSessionPage } from '.';
import { SendTrackingEvent } from '../../connection/tracking';
import { useTypedSelector } from '../../store/reducers/use-typed-selector';
import { LanguagesAbbr } from '../../types/working-model';
import { getSessionStorageItem, setSessionStorageItem } from '../session-storage';
import usePrevious from '../use-previous';

/**
 * 
 * @param shouldBlockSessionPageViews necessary because we use this hook at the live-event level, and also in the nested session component to accurately track each type of page view. When this is passed in, the instance of the hook will block session level page views. 
 */
// shouldBlockSessionPageViews is not set to a default because if this needs to get used again we should be aware of the nested useEffects potentially conflicting each other.
function useTrackPageView(shouldBlockSessionPageViews: boolean, language: LanguagesAbbr) {

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const liveSessions = useTypedSelector(event => event.LiveEventReducer.liveSessions);
	const userSession = useTypedSelector(state => state.LiveEventReducer.userSession);
	const pageSession = useTypedSelector(state => state.LiveEventReducer.pageSession);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const registrationId = useTypedSelector(state => state.LiveEventReducer.registrationId);
	const registeredLanguage = useTypedSelector(state => state.LiveEventReducer?.registeredLanguage);

	const validPasscodeLists = useTypedSelector(event => event.LiveEventReducer.validPasscodeLists);
	const loadingValidSessions = useTypedSelector(event => event.LiveEventReducer.loadingValidSessions);
	const location = useLocation();
	const { pathname } = location;

	const hasTrackedInitialLoadPageView = useRef(false);
	const prevPathname = usePrevious(location.pathname);
	const prevLanguage = usePrevious(language);

	const _isSessionPage = isSessionPage(pathname, eventBundle);
	const waitForUserSession = _isSessionPage ? !userSession : !pageSession;
	// merge conflict tip: we want to keep loadingValidSessions here to support events that are partially gated
	const waitForValidPasscodeList = eventBundle?.registration_on && loadingValidSessions;
	const shouldWaitForTrackingVariables = waitForUserSession || waitForValidPasscodeList;

	/*
		this hook should send once whenever one of the following situations occur:
		1. user logs in
		2. user changes url after logging in
		3. user changes language after logging in
		4. user first lands on a page after logging in
	*/
	useEffect(() => {
		if (!eventBundle || !blProfileUser || shouldWaitForTrackingVariables || (shouldBlockSessionPageViews && _isSessionPage)) {
			return;
		}
		const hasAlreadyTriggered = getSessionStorageItem(`live_analytic_triggered.${eventBundle.event}`);
		// if already triggered and no change in pathname or language, also return
		// except for initial load once all variables are loaded
		if (
			hasAlreadyTriggered
			&& prevPathname === location.pathname
			&& prevLanguage === language
			&& hasTrackedInitialLoadPageView.current
		) {
			return;
		}

		hasTrackedInitialLoadPageView.current = true;
		setSessionStorageItem(`live_analytic_triggered.${eventBundle?.event}`, 'true');

		const trackingParams = getPageTrackingParams(
			blProfileUser.bl_profile,
			eventBundle,
			registrationId || '',
			_isSessionPage ? userSession : pageSession,
			location.pathname,
			liveSessions,
			registeredLanguage,
			language,
			JSON.stringify({ valid_passcode_lists: validPasscodeLists }),
		);

		if (trackingParams) {
			SendTrackingEvent(trackingParams);
		}

		// note, we only trigger this when the url, language or passcode list changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, language, validPasscodeLists, loadingValidSessions, prevLanguage, prevPathname, shouldWaitForTrackingVariables]);
}

export default useTrackPageView;
