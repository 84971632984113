import classNames from "classnames";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ApproveQuestion, BanChatUser, DeleteQuestion, DeleteQuestionComment, MarkQuestionsAsAnswered, ModReplyToQuestion, UnapproveQuestion } from "../../../../../../../../connection/moderator";
import { GetQuestionResponseComments } from "../../../../../../../../connection/sessions-panel/search";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { IncomingSocketQuestion, LanguagesAbbr, PaginatedQuestion, QuestionComment, SqsSocketTypes, Status } from "../../../../../../../../types/working-model";
import mTimestamps from "../../../../../../../../utils/timestamps";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import Avatar from "../../../../../../../general-ui/avatar/avatar";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import TextInput from "../../../../../../../general-ui/text-input/text";
import { questionAnswered, questionCommentDelete, questionApprove, questionDelete, QuestionsContext, questionUnapprove, setLoadingQuestionsComments, setQuestionsComments } from "../../../panel/contexts/engage/questions-state";
import { usePageModule } from "../../../panel/hooks/panel.hooks";
import { m, LazyMotion, domAnimation, AnimatePresence } from "framer-motion";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import AnonIconImage from '../../../../../../../../images/icons/icon-anonymous.svg';
import { QFilters } from "../question-prompt-view";
import NavigationDropdown from "../../../../../../../general-ui/dropdown/navigation-dropdown";
import ModalComponent from "../../../../../../../general-ui/modal/modal";
import Checkbox from "../../../../../../../general-ui/checkbox/checkbox";
import { OptionalComponent } from "../../../../../../../../utils/optional-component";
import { Tooltip } from "@general-ui/tooltip/tooltip";

import './question-card.scss';

type QuestionCardProps = {
	question: PaginatedQuestion;
	approvalRequired?: boolean;
	filter?: QFilters;
	selecting?: boolean;
	setSelectedQuestions?: Dispatch<SetStateAction<number[]>>;
	selectedQuestions?: number[];
	isCommentBox?: boolean;
}

const InternalFilter = (filter: QFilters, question: PaginatedQuestion) => {
	const dismissed = question.status === Status.Unapproved && !question.pending_moderator;

	switch (filter) {
		case QFilters.all:
			return !dismissed;
		case QFilters.answered:
			return question.answered;
		case QFilters.notAnswered:
			return !question.answered;
		case QFilters.pending:
			return question.pending_moderator;
		case QFilters.dismissed:
			return dismissed;
		default:
			return true;
	}
};

const ModeratorReply = ({ question }: QuestionCardProps) => {
	const [reply, setReply] = useState("");
	const [sending, setSending] = useState(false);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const { session } = useParams<{ session: string }>();
	const pageModule = usePageModule();
	const moduleId = pageModule?.id;

	const submit = useCallback(async () => {
		try {
			if (!user || !token || !moduleId || !reply.trim()) return;
			setSending(true);

			const message = {
				first_name: user.profile.first_name,
				last_name: user.profile.last_name,
				email: user.email,
				comment: reply,
				moderator: user.id,
				moderator_reply_to: { ...question, comments: undefined },
				moderator_reply_to_id: question.id,
				message_type: SqsSocketTypes.QUESTION_COMMENT
			};

			await ModReplyToQuestion(token, session, message);
			setReply("");
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error sending reply",
				description: "There was an error sending your reply. Please try again later.",
				type: "error"
			});
		} finally {
			setSending(false);
		}

	}, [question, reply, session, moduleId, token, user]);

	return (
		<div className={classNames("reply-input", { sending })}>
			<TextInput
				placeholder="Type message..."
				value={reply}
				onChange={(e) => {
					setReply(e.target.value);
				}}
				onEnterKey={submit}
				onEscape={() => {
					setReply("");
				}}
			/>
			<button onClick={submit} className="no-style no-padding no-margin send-question-reply">
				<Icon name={ICONS.SEND_FILL_RIGHT} size={12} color={COLORS.WHITE} />
			</button>

		</div>
	);
};

const Replies = ({ question, open }: QuestionCardProps & { open: boolean }) => {
	const [state, dispatch] = useContext(QuestionsContext);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const { session: session_uuid, page_module, language } = useParams<{ session: string, language: LanguagesAbbr, page_module: string }>();
	const replies = state.questionsComments[question.id];
	const loading = state.loadingQuestionsComments[question.id];
	const userActiveChannel = user?.active_channel;
	const [processing, setProcessing] = useState(false);
	const [blocking, setBlocking] = useState<QuestionComment | IncomingSocketQuestion | null>(null);

	const channelUuid = useMemo(() => {
		return channels.find(c => c.channel === userActiveChannel)?.uuid;
	}, [userActiveChannel, channels]);

	useEffect(() => {
		if (open && token && page_module && channelUuid) {
			dispatch(setLoadingQuestionsComments(question.id, true));
			GetQuestionResponseComments(token, {
				module_id: Number(page_module),
				channelUuid,
				question_id: question.id
			}).then(res => {
				dispatch(setQuestionsComments(question.id, res));
			}).catch(e => {
				console.error(e);
			});
		}
	}, [channelUuid, dispatch, open, page_module, question.id, token]);

	const handleBan = async () => {
		if (!token || !user || !session_uuid || !blocking) return;
		try {
			setProcessing(true);
			await BanChatUser(token, session_uuid, blocking.bl_profile, null, user.id);
			setBlocking(null);
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error banning user",
				description: "We ran into an issue banning this user. Please wait a moment and try again.",
				type: "error"
			});
		} finally {
			setProcessing(false);
		}
	};

	const handleDelete = (id: number) => async () => {
		if (!token || !user) return;
		try {
			setProcessing(true);
			await DeleteQuestionComment(token, session_uuid, language, id);
			dispatch(questionCommentDelete(id, question.id));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error deleting question",
				description: "We ran into an issue deleting this question. Please wait a moment and try again.",
				type: "error"
			});
		} finally {
			setProcessing(false);
		}
	};

	return (
		<LazyMotion features={domAnimation}>
			<AnimatePresence>
				{(open && (replies || loading)) && (
					<m.div
						className="question-prompt-response-replies"
						initial={{ opacity: 0, maxHeight: 0 }}
						animate={{ opacity: 1, maxHeight: loading ? 60 : replies?.length * 600 ?? 1000 }}
						exit={{ opacity: 0, maxHeight: 0 }}
					>
						{(!loading) && (
							<>
								<StaggerChildren className="question-prompt-response-replies-list">
									{replies?.map(reply => {
										const commentorName = reply?.moderator
											? reply.first_name || reply.last_name
												? `${reply.first_name?.[0] || ''}. ${reply.last_name || ''}`
												: 'Moderator'
											: `${reply.first_name?.[0] || ''}. ${reply.last_name || ''}`;

										return (<div className={classNames("question-reply-row")} key={reply.id}>
											<Avatar
												imageUrl={undefined}
												initials={`${reply.first_name?.[0] ?? ''} ${reply.last_name?.[0] ?? ''}`}
											/>
											<div className="question-prompt-response-reply-text">
												<div className="question-prompt-response-reply-text-header">
													<label>{commentorName}</label>
													<span>•</span>
													<time>{mTimestamps.getPrettyTime(reply.created_at as string)}</time>
												</div>
												<div className="question-reply-body">
													<p>{reply.comment}</p>
												</div>
											</div>
											<div className="question-prompt-response-reply-actions">
												<OptionalComponent display={!reply.moderator}>
													<button
														className="no-style no-padding no-margin"
														onClick={() => setBlocking(reply)}
														title="Block user"
													>
														<Icon name={ICONS.BAN} size={11} color={COLORS.BORDER_LIGHT_GRAY} />
													</button>
												</OptionalComponent>
												<button
													className="no-style no-padding no-margin"
													onClick={handleDelete(reply.id)}
													title="Delete reply"
												>
													<Icon name={ICONS.TRASH_OUTLINE} size={13} color={COLORS.BORDER_LIGHT_GRAY} />
												</button>
											</div>
										</div>
										);
									})}
								</StaggerChildren>
								<ModalComponent
									title="Block User"
									cancellable
									closeable
									open={!!blocking}
									onRequestClose={() => setBlocking(null)}
									padTitle={false}
									footer={(
										<>
											<button onClick={() => setBlocking(null)}>No, Cancel</button>
											<button className="lemonade" onClick={handleBan} disabled={processing}>Yes, block</button>
										</>
									)}
								>
									<p>Are you sure you want to block {blocking?.first_name} {blocking?.last_name}?</p>
								</ModalComponent>
							</>
						)}

						{(loading) && (
							<div className="question-replies-loading">
								<WaitingIndicator minHeight={60} />
							</div>
						)}
					</m.div>
				)}
			</AnimatePresence>
		</LazyMotion>
	);
};

const QuestionOptions = ({ question, approvalRequired, isCommentBox }: QuestionCardProps) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const { session: session_uuid, language } = useParams<{ id: string, session: string, language: string }>();
	const [, dispatch] = useContext(QuestionsContext);
	const [processing, setProcessing] = useState(false);
	const [blocking, setBlocking] = useState(false);

	const handleSetAnswered = async () => {
		if (!token || !language || !session_uuid) return;
		try {
			dispatch(questionAnswered(question.id, !question.answered));
			await MarkQuestionsAsAnswered({
				token,
				question_ids: [question.id],
				language,
				session_uuid,
				answered: !question.answered
			});
		} catch (e) {
			console.error(e);
		}
	};

	const handleBan = async () => {
		if (!token || !user || !session_uuid) return;
		try {
			setProcessing(true);
			await BanChatUser(token, session_uuid, question.bl_profile, null, user.id);
			dispatch(questionDelete(question.id));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error banning user",
				description: "We ran into an issue banning this user. Please wait a moment and try again.",
				type: "error"
			});
		} finally {
			setProcessing(false);
		}
	};

	const handleDelete = async () => {
		if (!token || !user) return;
		try {
			setProcessing(true);
			await DeleteQuestion(token, session_uuid, language, question.id);
			dispatch(questionDelete(question.id));
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error deleting question",
				description: "We ran into an issue deleting this question. Please wait a moment and try again.",
				type: "error"
			});
		} finally {
			setProcessing(false);
		}
	};

	const displayMarkAnswerSection = () => {
		if (isCommentBox) return false;
		return !approvalRequired || (approvalRequired && !question.pending_moderator);
	};



	return (
		<>

			<NavigationDropdown
				title={<Icon name={ICONS.THREE_DOTS_VERTICAL} size={12} color={COLORS.WHITE} />}
				className="session-panel-dropdown"
				isArrow={false}
				buttonClassName="question-card-options round no-style"
				edge="right"
			>
				<>
					<OptionalComponent display={displayMarkAnswerSection()} >
						<button
							onClick={handleSetAnswered}
							disabled={processing}
						>
							Mark {question.answered ? 'Unanswered' : 'Answered'}
						</button>
					</OptionalComponent>
					<button
						onClick={() => setBlocking(true)}
						disabled={processing}
					>
						Ban user
					</button>
					<button
						onClick={handleDelete}
						disabled={processing}
					>
						Delete
					</button>
				</>
			</NavigationDropdown>
			<ModalComponent
				title="Block User"
				cancellable
				closeable
				open={blocking}
				onRequestClose={() => setBlocking(false)}
				padTitle={false}
				footer={(
					<>
						<button onClick={() => setBlocking(false)}>No, Cancel</button>
						<button className="lemonade" onClick={handleBan} disabled={processing}>Yes, block</button>
					</>
				)}
			>
				<p>Are you sure you want to block {question.first_name} {question.last_name}?</p>
			</ModalComponent>
		</>
	);
};

const Pending = ({ question }: QuestionCardProps) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const { session: session_uuid, language } = useParams<{ session: string, language: string }>();
	const [, dispatch] = useContext(QuestionsContext);
	const [updating, setUpdating] = useState(false);

	const setStatus = (status: Status) => async () => {
		if (!token || !session_uuid || !language || updating) return;

		try {
			setUpdating(true);
			switch (status) {
				case Status.Approved: {
					await ApproveQuestion(token, session_uuid, question);
					dispatch(questionApprove(question.id));
					break;
				}
				case Status.Unapproved: {
					await UnapproveQuestion(token, session_uuid, question);
					dispatch(questionUnapprove(question.id));
					break;
				}
				default: {
					console.warn("Unimplemented status:", Status[status]);
				}
			}
		} catch (e) {
			console.error(e);
			showAlert({
				message: "Error updating question",
				description: "We ran into a problem updating the question. Please try again later.",
				type: "error"
			});
		} finally {
			setUpdating(false);
		}
	};

	return (
		<div className="question-approval-actions">
			<button disabled={updating} className="small" onClick={setStatus(Status.Unapproved)}>Dismiss</button>
			<button disabled={updating} className="small lemonade" onClick={setStatus(Status.Approved)}>Approve</button>
		</div>
	);
};

const QuestionCard = ({
	question,
	approvalRequired,
	filter,
	selecting,
	selectedQuestions,
	setSelectedQuestions,
	isCommentBox,
}: QuestionCardProps) => {
	const [showReplies, setShowReplies] = useState(false);
	const anonymous = question.first_name === "Anonymous";
	const pending = question.pending_moderator && approvalRequired;

	if (!InternalFilter(filter ?? QFilters.all, question)) return null;

	return (
		<div className={classNames("question-prompt-response-card", { anonymous })}>
			<div className="question-prompt-response-card-header">
				<Avatar
					imageUrl={anonymous ? AnonIconImage : question.avatar ?? undefined}
					initials={`${question.first_name?.[0] ?? ''} ${question.last_name?.[0] ?? ''}`}
				/>
				<div className="S2 question-prompt-response-card-header-text">
					<label>{anonymous ? "Anonymous" : `${question.first_name?.[0] ?? ''}. ${question.last_name ?? ''}`}</label>
					<span>•</span>
					<time>{mTimestamps.getPrettyTime(question.created_at)}</time>
				</div>
				<div className="question-prompt-response-card-header-actions">
					{!selecting || (approvalRequired && question.pending_moderator) ? (
						<>
							<OptionalComponent display={!pending && question.answered}>
								<Tooltip
									maxWidth={100}
									tooltip="Question is answered"
								>
									<div className="answer-check">
										<Icon
											name={ICONS.CHECK_CIRCLE_FILLED}
											size={18}
											color={COLORS.WHITE}
										/>
									</div>
								</Tooltip>
							</OptionalComponent>
							<QuestionOptions question={question} approvalRequired={approvalRequired} isCommentBox={isCommentBox} />
						</>
					) : (
						<Checkbox
							checked={!!selectedQuestions?.includes(question.id)}
							value={question.id}
							onChange={(_, checked) => {
								if (checked) {
									setSelectedQuestions?.(prev => [...prev, question.id]);
								} else {
									setSelectedQuestions?.(prev => prev.filter(q => q !== question.id));
								}
							}}
						/>
					)}
				</div>
			</div>
			<div className="question-body">
				<p>{question.question}</p>
			</div>
			{!pending ? (
				<>
					<div className="question-metadata S2">
						<div className="question-likes">
							<span><Icon name={ICONS.UPVOTE} size={16} color={COLORS.BORDER_LIGHT_GRAY} />{question.likes}</span>
						</div>
						<OptionalComponent display={!!question.comments}>
							<div
								className="question-replies-count"
								onClick={() => {
									setShowReplies(prev => !prev);
								}}
							>
								<span>{showReplies ? "Hide" : "See"} {question.comments} {question.comments === 1 ? 'reply' : 'replies'}</span>
							</div>
						</OptionalComponent>
					</div>
					<ModeratorReply question={question} />
					<Replies question={question} open={showReplies} />
				</>
			) : (
				<Pending question={question} />
			)}
		</div>
	);
};

export default QuestionCard;
