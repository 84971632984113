import { useCallback, useState, useEffect, useMemo, SetStateAction, Dispatch } from "react";
import classNames from 'classnames';
import { useParams } from "react-router";

import { Survey, PageModule, SurveyQuestion, SurveyQuestionOption, SurveyAnswerBody, SurveyType, SurveyQuestionType, TranslateString } from "../../../../../types/working-model";
import { TypographyItem } from "../../../../general-ui/typography-item/typography-item";
import { checkEngageType, getSurveyText } from '../../engage-section/utils/engage.utils';
import SurveyOption from "./survey-option";
import { getStylingOverrides } from "../../../utils";
import { ParamsProps } from "../../../live-event";
import { useAppDispatch, useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { SubmitSurveyAnswer } from "../../../../../connection/save-survey-answer";
import { getStorageObject, setStorageObject } from "../../../../../utils/local-storage";
import { useTranslate } from "../../../../../i18n/useTranslationModules";

import { getPollAnswerLocalStorageKey, getPollAnswerLocalStorageKeyBackwardsCompatible } from "./survey";
import useTranslationRoute from "../../../hooks/use-translation-route";
import WaitingIndicator from "../../../../general-ui/waiting-indicator/waiting-indicator";
import { OptionalComponent } from "../../../../../utils/optional-component";
import { useIsNewModuleGrouping } from "../../../../../hooks/session.hooks";
import { isSafari, updateTranslateKey } from "../../../../../utils/utils";
import Textarea from "../../../../general-ui/textarea/textarea";

import { checkQuizzesForCompletion } from "../../../../../store/actions/event/event-actions";
import { addUserEngagementPending } from "../../../../../store/actions/admin/surveys";
import { matchSurveyResponseKey } from "../../../../../utils/regex";
import { useIsAdmin } from "hooks/context.hooks";

interface IGenericSurveyQuestionProps {
	currentQuestion: number;
	isReviewMode?: boolean;
	handleShowResults?: () => void;
	maxQuestions: number;
	module: PageModule;
	question: SurveyQuestion;
	sessionUuid?: string;
	setCurrentQuestion: Dispatch<SetStateAction<number>>;
	setShowPrevious?: (showPrevious: boolean) => void
	survey: Survey;
	template: string;
	viewAll: boolean;
	viewResults?: boolean;
	isEditor?: boolean;
}

const GenericSurveyQuestion: React.FC<IGenericSurveyQuestionProps> = ({
	currentQuestion,
	isReviewMode,
	handleShowResults,
	maxQuestions,
	module,
	question,
	sessionUuid,
	setCurrentQuestion,
	setShowPrevious,
	survey,
	template,
	viewAll,
	viewResults,
	isEditor
}) => {
	const { show_results, survey_type, survey: surveyId } = survey;
	const { content, styling_overrides } = module;

	const question_type = question.question_type;
	const stylingOverrides = getStylingOverrides(styling_overrides);
	const isAdmin = useIsAdmin();

	const { primaryButtonOverrides } = stylingOverrides;
	const { language }: ParamsProps = useParams();
	const { isSurveyType } = checkEngageType(show_results, survey_type);

	const [isAnswered, setIsAnswered] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [selectedCheckbox, setSelectedCheckbox] = useState<number[]>([]);
	const [selectedRadio, setSelectedRadio] = useState(0);
	const [value, setValue] = useState('');
	const [totalResponses, setTotalResponses] = useState(0);
	const [questionResults, setQuestionResults] = useState<Record<number, number>>({});

	const eventId = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.event);
	const eventUuid = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.uuid);
	const sessions = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.sessions);
	const channel_id = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.channel);
	const registrationId = useTypedSelector((state) => state.LiveEventReducer.registrationId);
	const registration_on = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.registration_on);
	const workingSession = useTypedSelector((state) => state.CreateEventReducer.workingSession);
	const previewMode = useTypedSelector((state) => state.CreateEventReducer.previewMode);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const leaderboardEnabled = !!useTypedSelector(state => state.LiveEventReducer.leaderboardIsOn);
	const engagementResults = useTypedSelector(state => state.SurveysReducer.engagementResults);
	const engagementsPending = useTypedSelector(state => state.SurveysReducer.engagementsPending);

	const dispatch = useAppDispatch();
	const questionId = question.survey_question;

	useEffect(() => {
		let totalResponses = 0;
		const questionResults: Record<number, number> = {};
		const res = Object.entries(engagementResults ?? {});

		for (const [resultKey, value] of res) {
			const match = matchSurveyResponseKey(resultKey);

			if (match) {
				const { survey_question: surveyQuestion, option: optionId } = match;
				if (Number(surveyQuestion) === questionId) {
					totalResponses += value;
					questionResults[Number(optionId)] = value;
				}
			}
		}

		setTotalResponses(totalResponses);
		setQuestionResults(questionResults);
	}, [engagementResults, questionId]);

	const session = useMemo(() => {
		if (previewMode === 'session') return workingSession;
		return sessions?.find(session => session.uuid === sessionUuid);
	}, [previewMode, sessions, sessionUuid, workingSession]);

	const namespace = useTranslationRoute();
	const { t } = useTranslate([namespace, "session"]);
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const isLast = maxQuestions - currentQuestion === 1;
	const isNotQuiz = survey.survey_type !== SurveyType.quiz;

	// On rerender or reload, check the local storage for an existing answer to show which option was selected
	useEffect(() => {
		if (!eventId || isAdmin) return;

		// if the user refreshes we want to keep the answers they submitted shown
		const lsKey = getPollAnswerLocalStorageKey(question, surveyId, eventId);
		const lsKeyBackwardsCompatible = getPollAnswerLocalStorageKeyBackwardsCompatible(question, surveyId);
		const answer: SurveyAnswerBody | null = getStorageObject(lsKey) ?? getStorageObject(lsKeyBackwardsCompatible);

		// If question has been answered, show answer selected, disable submit and get stats
		if (answer?.checkbox_answer) {
			setSelectedCheckbox(answer.checkbox_answer.map(Number));
			setIsAnswered(true);
		}

		if (answer?.radio_answer) {
			setSelectedRadio(answer.radio_answer);
			setIsAnswered(true);
		}

		if (answer?.event === eventId && answer?.text_answer) {
			const text = answer.text_answer[language] as string | undefined || answer.text_answer.base;
			setValue(text);
			setIsAnswered(true);
		}
	}, [eventId, question, surveyId, show_results, language, isAdmin]);

	// Get question stats (answer submission totals for each option)
	const updateStatsResults = useCallback((selectedOption: number | number[]) => {
		if (Array.isArray(selectedOption)) {
			dispatch(addUserEngagementPending(questionId, selectedOption));
		} else {
			dispatch(addUserEngagementPending(questionId, [selectedOption]));
		}
	}, [dispatch, questionId]);

	// Handles selecting an option
	const handleChange = (value: string | number) => {
		question_type === SurveyQuestionType.text && setValue(value.toString());
		question_type === SurveyQuestionType.radio && setSelectedRadio(Number(value));
		question_type === SurveyQuestionType.checkbox &&
			// handles selecting an option
			setSelectedCheckbox(prev => {
				const optionId = Number(value);
				const isChecked = prev.includes(optionId);
				if (isChecked) {
					return prev.filter((id) => id !== optionId);
				} else {
					return [...prev, optionId];
				}
			});
	};

	const handleSelectNext = useCallback(() => {
		setCurrentQuestion(prev => prev + 1);
		if (currentQuestion + 1 === maxQuestions) {
			setShowPrevious?.(false);
			handleShowResults?.();
		}
	}, [currentQuestion, handleShowResults, maxQuestions, setCurrentQuestion, setShowPrevious]);

	const handleOnPreviousButtonClick = useCallback(() => {
		setShowPrevious?.(true);
		setCurrentQuestion(currentQuestion - 1);
	}, [setShowPrevious, setCurrentQuestion, currentQuestion]);

	const disableNextButton = isModuleGroupingV2
		? isNotQuiz && currentQuestion === maxQuestions
		: isNotQuiz && currentQuestion + 1 === maxQuestions;

	const surveyProgressText = getSurveyText({
		textToReplace: t("session:QuestionsCompletedCount"),
		replaceMapping: {
			'1': (survey.questions.findIndex(q => q.survey_question === question.survey_question) || 0) + 1,
			'0': maxQuestions,
		},
	});

	const isSubmitDisabled = isAnswered || (!value && selectedRadio === 0 && !selectedCheckbox.length) || isSubmitting;
	const isAnswerSelected = isSubmitDisabled && (Object.keys(selectedCheckbox).length === 0) || (!isSubmitDisabled && (Object.keys(selectedCheckbox).length !== 0));

	const shouldShowSubmit = () => {
		if (viewAll || isSubmitting || !isAnswered) return true;

		if (question_type === SurveyQuestionType.text) {
			return (value && !isSubmitDisabled);
		}
		else if (question_type === SurveyQuestionType.radio) {
			return selectedRadio === 0 || (!isSubmitDisabled && selectedRadio !== 0);
		}
		else if (question_type === SurveyQuestionType.checkbox) {
			return isAnswerSelected;
		}
	};

	const getQuestionResults = (option: SurveyQuestionOption) => {
		let responses = questionResults[option.survey_question_option] ?? 0;
		let total = totalResponses;

		// user has a temp cached response, and we are still waiting on the websocket update
		const pending = engagementsPending[questionId];
		if (pending && pending.includes(option.survey_question_option)) {
			responses += 1;
			total += 1;
		} else if (pending) {
			total += 1;
		}

		const canCalculate = total > 0 && responses > 0;
		const perc = canCalculate ? Math.floor((responses / total) * 100) : 0;
		return perc;
	};

	const correctAnswers = () => {
		if (question_type === SurveyQuestionType.radio) {
			return question.options.filter(({ survey_question_option }) => selectedRadio === survey_question_option);
		}
		else if (question_type === SurveyQuestionType.checkbox) {
			return question.options.filter(({ survey_question_option }) => selectedCheckbox.includes(survey_question_option));
		}
		else {
			return [];
		}
	};

	const showCorrectAnswer = !isAdmin && isSurveyType && viewResults && isModuleGroupingV2;
	const questionOptions = showCorrectAnswer ? correctAnswers() : question.options;
	const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value);
	};

	// RADIO, CHECKBOX, and TEXT
	// Submits answer to question
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (isAdmin) {
			if (!isLast && question_type === SurveyQuestionType.text) handleSelectNext();
			setIsAnswered(true);
			return;
		}

		if (!module.id || !eventId) return;
		const tempBody: {
			text_answer: TranslateString | null,
			radio_answer: number | null,
			checkbox_answer: string[] | null,
		} = {
			text_answer: null,
			radio_answer: null,
			checkbox_answer: null,
		};

		const translateKeyValues = updateTranslateKey({
			translateString: { base: '', changed: '' },
			input: value,
			baseLanguage: language, //because this will be end user submited, we want whichever language they are on to be the base
			language
		});

		if (question_type === SurveyQuestionType.radio) {
			tempBody.radio_answer = selectedRadio;
			if (question.required && !selectedRadio) return;
		}
		else if (question_type === SurveyQuestionType.checkbox) {
			tempBody.checkbox_answer = selectedCheckbox.map((id) => id.toString());
			if (question.required && !selectedCheckbox.length) return;
		}
		else if (question_type === SurveyQuestionType.text) {
			tempBody.text_answer = translateKeyValues;
			if (question.required && !value.trim().length) return;
		}

		const body: SurveyAnswerBody = {
			session: session?.session,
			survey_question: question.survey_question,
			module: module.id,
			event: eventId,
			...tempBody,
			session_uuid: session?.uuid,
			language: language,
			channel: channel_id,
			leaderboard_enabled: leaderboardEnabled,
			event_uuid: eventUuid,
			survey: surveyId
		};

		if (registration_on && registrationId) {
			body.registration_id = registrationId;
		}

		try {
			setIsSubmitting(true);

			if (show_results) {/*Attendees cannot get results for surveys with show_results turned off.*/
				if (question_type === SurveyQuestionType.radio) {
					updateStatsResults(selectedRadio);
				}
				else if (question_type === SurveyQuestionType.checkbox) {
					updateStatsResults(selectedCheckbox);
				}
			}

			// Send the message to microservices
			await SubmitSurveyAnswer(body, token);

			//set in local storage in case they refresh
			const lsKey = getPollAnswerLocalStorageKey(question, surveyId, eventId);
			setStorageObject(lsKey, body, 48);

			// Check quiz for completion (checks all, but hopefully we have a few microseconds to spare)
			// Quizzes currently only have radios, so this is only found here and in survey.tsx
			if (survey.survey_type === SurveyType.quiz) {
				dispatch(checkQuizzesForCompletion(module, eventId, sessions));
			}
			setIsAnswered(true);

			const shouldSkipNext = isModuleGroupingV2 && !isLast && question_type === SurveyQuestionType.text;
			if (shouldSkipNext) {
				handleSelectNext();
			}

		} catch (err) {
			console.error(err);
		} finally {
			setIsSubmitting(false);
		}
	};


	const showNext = () => {
		const isLast = maxQuestions - currentQuestion === 1;
		const shouldShowDoneText = isModuleGroupingV2 && isLast;
		const buttonText = shouldShowDoneText ? t("session:Done") : t("session:Next");

		return <button
			className={classNames({ 'evt-button primary btn-sm btn-font-tiny': isModuleGroupingV2 })}
			disabled={disableNextButton}
			onClick={handleSelectNext}
		>
			{buttonText}
		</button>;
	};

	const showSubmit = () => {
		return (
			<button
				className={classNames('primary next', primaryButtonOverrides, { 'evt-button btn-sm btn-font-tiny': isModuleGroupingV2 })}
				type="submit"
				onClick={handleSubmit}
				disabled={isSubmitDisabled}
			>
				{isSubmitting ? (
					<WaitingIndicator />
				) : t(`${namespace}:callToAction`, (content?.callToAction?.button_name?.[language] || t("session:Submit")))
				}
			</button>
		);
	};

	const displayButtonRow = () => {
		return (
			<div
				className={classNames(
					'survey-pagination',
					{
						'is-editor': isEditor,
						'is-safari': isSafari,
					}
				)}
			>
				{!viewAll &&
					<button
						className={classNames({ 'hide-button': currentQuestion === 0, 'evt-button secondary btn-sm btn-font-tiny': isModuleGroupingV2 })}
						disabled={currentQuestion === 0}
						onClick={handleOnPreviousButtonClick}
					>
						{t("session:Previous")}
					</button>
				}
				{isModuleGroupingV2 ? (
					<div className="question-num evt-typography-subtitle-4 text-ellipsis">
						{surveyProgressText}
					</div>
				) : (
					<div>
						<OptionalComponent display={!viewAll}>
							<span>{currentQuestion + 1}/{maxQuestions}</span>
						</OptionalComponent>
					</div>
				)}

				{shouldShowSubmit() ? showSubmit() : showNext()}
			</div >
		);
	};

	const renderOptions = () => {
		const selected = (option: SurveyQuestionOption): boolean => (selectedRadio === option.survey_question_option || selectedCheckbox.includes(option.survey_question_option));

		return questionOptions.map(option => {
			if (!option) return null;
			return (
				<div
					className={classNames('survey-option-wrapper', { 'result-option': showCorrectAnswer && isModuleGroupingV2 })}
					key={`survey-option-${option.survey_question_option}`}
				>
					<SurveyOption
						value={option.survey_question_option}
						title={t(`${namespace}:survey.${surveyId}.questions.${question?.survey_question}.options.${option.survey_question_option}`, option.label?.[language] as string ?? option.label.base)}
						selected={selected(option)}
						onClick={handleChange}
						percentage={(show_results && isAnswered) ? getQuestionResults(option) : null}
						disabled={isAnswered}
						surveyType={survey.survey_type}
						isCorrect={option.is_correct ? option.is_correct : false}
					/>
				</div>
			);
		});
	};

	return (
		<div className={classNames('question-container')}>
			<TypographyItem className={classNames('question-title evt-heading-4', template)} tagName="p">
				{t(`${namespace}:survey.${surveyId}.questions.${question?.survey_question}`, (question?.title?.[language] as string ?? question?.title?.base))}
			</TypographyItem>

			<div className="evt-field-wrapper">
				{question_type === SurveyQuestionType.text
					? <Textarea className="evt-field-wrapper" placeholder={t("Enter your response here")} defaultValue={value ?? ''} onChange={handleValueChange} disabled={isAnswered || (previewMode === 'session')} />
					: isModuleGroupingV2 ? renderOptions() : <div>{renderOptions()}</div>
				}
			</div>

			<OptionalComponent display={!isReviewMode}>
				{displayButtonRow()}
			</OptionalComponent>
		</div>
	);
};

export default GenericSurveyQuestion;
