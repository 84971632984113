import { CSSProperties } from 'react';

import { StylesConfig } from 'react-select';
import { TemplateClassNames } from "../../../types/template-layouts";

// mark as any to allow generic type to be passed in
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TReactSelectOption<T = any> = {
	label: string;
	value: string;
	meta?: T | null;
};

interface IReactStyles {
	input?: CSSProperties;
	menu?: CSSProperties;
	option?: CSSProperties;
	control?: CSSProperties;
	singleValue?: CSSProperties;
	container?: CSSProperties;
	menuPortal?: CSSProperties;
	indicatorSeparator?: CSSProperties;
	clearIndicator?: CSSProperties;
	dropdownIndicator?: CSSProperties;
	group?: CSSProperties;
	groupHeading?: CSSProperties;
	indicatorsContainer?: CSSProperties;
	loadingIndicator?: CSSProperties;
	loadingMessage?: CSSProperties;
	menuList?: CSSProperties;
	multiValue?: CSSProperties;
	multiValueLabel?: CSSProperties;
	multiValueRemove?: CSSProperties;
	noOptionsMessage?: CSSProperties;
	placeholder?: CSSProperties;
	valueContainer?: CSSProperties;
	focusedBorderColor?: string;
	optionFocusBackgroundColor?: string;
	optionFocusColor?: string;
	multiValueRemoveActiveColor?: string;
}

export const reactSelectStyles = <isMulti extends boolean = false>({
	input = {},
	menu = {},
	option = {},
	control = {},
	singleValue = {},
	container = {},
	menuPortal = {},
	indicatorSeparator = {},
	clearIndicator = {},
	dropdownIndicator = {},
	group = {},
	groupHeading = {},
	indicatorsContainer = {},
	loadingIndicator = {},
	loadingMessage = {},
	menuList = {},
	multiValue = {},
	multiValueLabel = {},
	multiValueRemove = {},
	noOptionsMessage = {},
	placeholder = {},
	valueContainer = {},
	focusedBorderColor = '#5AE4FF',
	optionFocusBackgroundColor = '#4F4C57',
	optionFocusColor = 'default',
	multiValueRemoveActiveColor = 'default',
}: IReactStyles): StylesConfig<TReactSelectOption, isMulti> => ({
	container: (provided: any) => ({
		...provided,
		width: '100%',
		...container,
	}),
	input: (provided: any) => ({
		...provided,
		color: 'inherit',
		...input,
	}),
	menu: (provided: any) => ({
		...provided,
		color: 'inherit',
		background: '#312F38',
		...menu,
	}),
	option: (provided: any, state: any) => {
		let color = 'inherit';
		let background = 'inherit';
		if (state.isFocused || state.isSelected) {
			background = optionFocusBackgroundColor;
			color = optionFocusColor;
		}
		return {
			...provided,
			color,
			padding: '14px 24px',
			cursor: 'pointer',
			background,
			opacity: state.isSelected ? 0.6 : 1,
			"&:hover": {
				background: 'default',
			},
			...option,
		};
	},
	control: (provided: any, state: any) => {
		let borderColor = '#908CA0';
		if (state.isFocused) {
			borderColor = focusedBorderColor;
		}
		return {
			...provided,
			color: 'inherit',
			width: '100%',
			background: 'inherit',
			boxShadow: 'none',
			minHeight: '40px',
			borderRadius: '12px',
			border: `1px solid ${borderColor}`,
			padding: '5px',
			"&:hover": {
				border: `1px solid ${borderColor}`,
			},
			...control,
		};
	},
	singleValue: (provided: any) => {
		return {
			...provided,
			color: 'inherit',
			...singleValue,
		};
	},
	menuPortal: (provided: any) => ({
		...provided,
		zIndex: 10000,
		...menuPortal,
	}),
	indicatorSeparator: (provided: any) => ({
		...provided,
		display: 'none',
		...indicatorSeparator,
	}),
	clearIndicator: (provided: any) => ({
		...provided,
		color: '#625E70',
		cursor: 'pointer',
		...clearIndicator,
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: '#625E70',
		cursor: 'pointer',
		...dropdownIndicator,
	}),
	group: (provided: any) => ({
		...provided,
		...group,
	}),
	groupHeading: (provided: any) => ({
		...provided,
		...groupHeading,
	}),
	indicatorsContainer: (provided: any) => ({
		...provided,
		...indicatorsContainer,
	}),
	loadingIndicator: (provided: any) => ({
		...provided,
		...loadingIndicator,
	}),
	loadingMessage: (provided: any) => ({
		...provided,
		...loadingMessage,
	}),
	menuList: (provided: any) => ({
		...provided,
		...menuList,
	}),
	multiValue: (provided: any) => ({
		...provided,
		backgroundColor: '#1B1A1D',
		color: '#908CA0',
		borderRadius: '32px',
		border: '2px solid #3C3A42',
		padding: '6px 12px',
		...multiValue,
	}),
	multiValueLabel: (provided: any) => ({
		...provided,
		color: '#908CA0',
		...multiValueLabel,
	}),
	multiValueRemove: (provided: any, state: any) => {
		let color = 'inherit';
		if (state.isFocused) {
			color = multiValueRemoveActiveColor;
		}

		return {
			...provided,
			cursor: 'pointer',
			color,
			'&:hover': {
				color: color,
				backgroundColor: 'inherit',
			},
			...multiValueRemove,
		};
	},
	noOptionsMessage: (provided: any) => ({
		...provided,
		...noOptionsMessage,
	}),
	placeholder: (provided: any) => ({
		...provided,
		...placeholder,
	}),
	valueContainer: (provided: any) => ({
		...provided,
		...valueContainer,
	})
});

// live event template conditional styling for multi-select
export const multiSelectStyling = (template: string): IReactStyles => {
	const conditionalTextTransform: CSSProperties = { textTransform: template === TemplateClassNames["Apollo"] ? "uppercase" : undefined };
	const conditionalBorderRadius: CSSProperties = { borderRadius: template === TemplateClassNames["Apollo"] ? "8px" : template === TemplateClassNames["Studio 54"] ? "20px" : "0" };
	return {
		focusedBorderColor: 'var(--accentColor)',
		optionFocusBackgroundColor: 'var(--backgroundColor)',
		optionFocusColor: 'var(--accentColor)',
		control: { ...conditionalBorderRadius, minHeight: "32px", maxHeight: "fit-content", display: "flex", alignContent: "center", padding: "0" },
		placeholder: { fontSize: "13px", fontWeight: "600px", ...conditionalTextTransform },
		multiValue: { padding: "0 4px", backgroundColor: "var(--bodyTextColor)", border: "none", color: "var(--containerColor)", ...conditionalTextTransform },
		multiValueLabel: { color: "var(--containerColor)", fontSize: "12px", fontWeight: "600", lineHeight: "18px" },
		menuList: { backgroundColor: "var(--containerColor)" },
		dropdownIndicator: { padding: "0 8px" },
		valueContainer: { padding: "0 8px" },
	};
};

interface IAdminSelectStyle {
	plainTextValue?: boolean;
}

// styling for react-select multi (or single) in admin modal to match (mostly) tag-select input
export const adminModalMultiSelectStyling = (props?: IAdminSelectStyle): IReactStyles => {
	const plainTextValue = !!props?.plainTextValue;
	return {
		control: {
			minHeight: "50px",
			maxHeight: "fit-content",
			display: "flex",
			alignContent: "center",
			padding: "0 6px 0 2px",
			border: "1px solid #625e70",
			borderRadius: "25px",
			marginBottom: "24px"
		},
		focusedBorderColor: "#625e70",
		placeholder: { fontSize: "14px", color: "#908ca0", paddingLeft: "10px" },
		menu: { borderRadius: "24px", zIndex: 5 },
		menuList: { backgroundColor: "#26242b", borderRadius: "24px", padding: 0, fontSize: "14px" },
		optionFocusBackgroundColor: "#3c3a42",
		singleValue: plainTextValue ? {} : {
			backgroundColor: '#1B1A1D',
			color: '#908CA0',
			borderRadius: '32px',
			border: '2px solid #3C3A42',
			padding: '6px 12px',
			fontSize: '12px',
			fontWeight: '500',
			textTransform: 'uppercase'
		},
		multiValue: { display: "flex", alignItems: "center", height: "32px" },
		multiValueLabel: { fontSize: "12px", textTransform: "uppercase", paddingLeft: "0" },
		multiValueRemove: { paddingRight: "0" },
		multiValueRemoveActiveColor: "#908CA0"
	};
};

export const analyticsHeaderStyle = (isMulti = false): IReactStyles => {
	return {
		menu: { right: 0, top: isMulti ? 0 : 38 }, // menu position is different for multi versus single select
		menuList: { fontSize: "14px" },
		control: { borderRadius: 100, fontSize: "14px", padding: '0', height: '38px', minHeight: 'unset' },
		focusedBorderColor: '#908CA0',
		container: { minWidth: '190px', width: 'fit-content', marginRight: '8px' },
		placeholder: { color: '#fff' },
		valueContainer: { whiteSpace: 'nowrap', padding: '0 0 0 12px' },
		option: { wordBreak: 'break-word' }
	};
};
