import React, { useEffect, useState } from 'react';

import { useParams } from "react-router";
import classNames from 'classnames';

import {
	PageModule,
	Survey,
	SurveyQuestion,
	QuizzesStatus,
	Session
} from "../../../../../types/working-model";
import { ParamsProps } from "../../../live-event";
import { getStylingOverrides } from "../../../utils";
import { TypographyItem } from "../../../../general-ui/typography-item/typography-item";
import EngageCard from '../../engage-section/engage-card';
import QuestionsCard from '../questions/questions-card';
import Question from './question';
import { getTextValue } from "../../../../../utils/utils";
import useTranslationRoute from '../../../hooks/use-translation-route';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { useTypedSelector, useAppDispatch } from "../../../../../store/reducers/use-typed-selector";
import { checkQuizzesForCompletion } from '../../../../../store/actions/event/event-actions';
import { OptionalComponent } from '../../../../../utils/optional-component';
import { useIsNewModuleGrouping } from '../../../../../hooks/session.hooks';

import '../../../../../scss/live-event/base/session/survey.scss';
import { getEngagementResults } from '../../../../../store/actions/admin/surveys';

interface SurveyProps {
	module?: PageModule;
	template: string;
	sessionUuid?: string;
	preview?: boolean;
	surveySubmoduleQuestions?: PageModule;
	session?: Session;
}

export interface CompletedQuizQuestion {
	question: number;
	option: number;
}

export const getPollAnswerLocalStorageKey = (
	question: SurveyQuestion,
	surveyId: number,
	event: number
): string => {
	return `poll-answer_type:${question.question_type}_id:${question.survey_question}_survey:${surveyId}_event:${event}`;
};

// This is needed so older surveys that have already been take will be able to pull the key from local storage (typo and all)
export const getPollAnswerLocalStorageKeyBackwardsCompatible = (
	question: SurveyQuestion,
	surveyId: number
): string => {
	return `poll-answer_type:${question.question_type}_id:${question.survey_question}_survey:${surveyId}}`;
};

export const calculateFinishedSurvey = (module: PageModule, surveyQuestionsCompleted: CompletedQuizQuestion[]) => {
	const quizzes = module.modules;

	const _surveyQuestionsCompleted = [...surveyQuestionsCompleted.flat(Infinity)];
	const quizzesStatus: Record<number, QuizzesStatus> = {};
	const correctAnswers: Record<number, { correctOptions: number[], selectedOption: number, quizId: number, isCorrect: boolean }> = {};
	//	{ 114938: {correctOptions: [8354], selectedOption: 8354} };

	quizzes?.forEach((quiz: Survey) => {
		if (quiz.survey_type !== "quiz") {
			return;
		}
		// Creates the correctAnswers object that pulls in the selected option and correct option for each question
		quiz.questions.forEach((q: SurveyQuestion) => {
			q.options.forEach(o => {
				if (o.is_correct) {
					if (!(q.survey_question in correctAnswers)) {
						correctAnswers[q.survey_question] = { correctOptions: [], selectedOption: 0, quizId: quiz.survey, isCorrect: false };
					}
					correctAnswers[q.survey_question].correctOptions.push(o.survey_question_option);
				}
			});
		});

		// maps through the completed questions and declares if the selected answer is correct
		_surveyQuestionsCompleted.flat(Infinity).forEach(q => {
			if (q.question in correctAnswers) {
				correctAnswers[q.question].selectedOption = q.option;
				if (correctAnswers[q.question].correctOptions.includes(q.option)) {
					correctAnswers[q.question].isCorrect = true;
				}
			}
		});

		const quizQuestionIDs = quiz.questions.map((q: SurveyQuestion) => q.survey_question);
		quizzesStatus[quiz.survey] = { completed: false, correctAnswers: 0, totalQuestions: quizQuestionIDs, totalAnswered: 0, passed: false };

		// Fills out the quizzesStatus object for each quiz
		quizzesStatus[quiz.survey].totalQuestions.forEach(q => {

			if (correctAnswers[q].selectedOption > 0) {
				quizzesStatus[quiz.survey].totalAnswered++;
			}

			if (correctAnswers[q].isCorrect) {
				quizzesStatus[quiz.survey].correctAnswers++;
			}

			if (quizzesStatus[quiz.survey].totalAnswered === quizzesStatus[quiz.survey].totalQuestions.length) {
				quizzesStatus[quiz.survey].completed = true;
			}
		});

		if (quiz?.passing_threshold && quizzesStatus[quiz.survey].correctAnswers >= quiz?.passing_threshold) {
			quizzesStatus[quiz.survey].passed = true;
		}
	});

	return quizzesStatus;
};

export default function SessionSurvey({
	module,
	template,
	sessionUuid,
	preview,
	surveySubmoduleQuestions,
	session,
}: SurveyProps): JSX.Element | null {
	const dispatch = useAppDispatch();
	const [activeEngageCard, setActiveEngageCard] = useState<number>();
	const { language } = useParams() as ParamsProps;
	const { content, styling_overrides } = module || {};
	const stylingOverrides = getStylingOverrides(styling_overrides);
	const namespace = useTranslationRoute();
	const { t } = useTranslate(namespace);
	const isModuleGroupingV2 = useIsNewModuleGrouping() && !!session;

	const sessions = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.sessions);
	const event = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.event);
	const questions = useTypedSelector(state => state.QuestionsReducer.questions);
	const engagementResults = useTypedSelector(state => state.SurveysReducer.engagementResults);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);

	const {
		backgroundColor,
		backgroundImg,
		customHeadingOverrides,
		moduleTitleOverrides,
		titleOverrides,
	} = stylingOverrides;

	const { custom_heading, title } = getTextValue(content, language);

	// This checks quizzes for completion; does not set completed answer values
	useEffect(() => {
		if (!event || !sessions || !module) return;
		dispatch(checkQuizzesForCompletion(module, event, sessions));
	}, [dispatch, event, module, sessions]);

	const modules = module?.modules;
	const surveysCustomHeaderTitle = t(`${namespace}:survey.${module?.id}.title`, t(`${namespace}:survey.title`, title));

	if (!module?.is_on || (!modules?.length && !isModuleGroupingV2)) return null;
	return (
		<section
			className={classNames(
				'surveys session-module surveys-content-home',
				template,
				backgroundColor
			)}
			style={backgroundImg}
		>
			<div className={classNames({ 'module-container': !sessionUuid, 'engage-grid': isModuleGroupingV2 })}>
				<OptionalComponent display={!isModuleGroupingV2}>
					<>
						<TypographyItem
							className={classNames(
								'surveys-custom-heading',
								customHeadingOverrides,
								{ 'surveys-custom-heading-event': !sessionUuid })}
							tagName="p"
						>
							{t(`${namespace}:survey.${module?.id}.heading`, t(`${namespace}:survey.heading`, custom_heading))}
						</TypographyItem>

						<OptionalComponent display={!!surveysCustomHeaderTitle}>
							<TypographyItem className={classNames('evt-heading-2 surveys-custom-header-title', titleOverrides)} tagName="h2">
								{surveysCustomHeaderTitle}
							</TypographyItem>
						</OptionalComponent>
					</>
				</OptionalComponent>

				{module && !!modules?.length && modules.map((survey: Survey, index) => {
					if (isModuleGroupingV2) {
						return (
							<EngageCard
								key={survey.survey}
								engageCardId={survey.survey}
								sessionUuid={sessionUuid}
								survey={survey}
								module={module}
								template={template}
								preview={preview}
								activeEngageCard={activeEngageCard}
								order={index + 2}
								setActiveEngageCard={setActiveEngageCard}
							/>
						);
					}

					const surveyTitle = t(`survey.${survey.survey}.name`, survey?.name?.[language] as string ?? survey?.name?.base);

					return (
						<div key={`survey-container-${survey.survey}`} className={classNames('survey-container')}>
							<TypographyItem
								className={classNames('survey-title evt-heading-4', template, moduleTitleOverrides)}
								tagName="p"
							>
								{surveyTitle}
							</TypographyItem>

							<Question
								sessionUuid={sessionUuid}
								survey={survey}
								module={module}
								template={template}
							/>
						</div>
					);
				})}
			</div>
		</section>
	);
}
