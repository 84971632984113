import { createQueryString } from "utils/utils";
import { ArchivedVideo, CreateDocument, CustomReaction } from "../types/working-model";
import { Delete, Get, Post, Put } from "./helpers";

export function fetchDocuments(
	activeClient: number,
	token: string
): Promise<any> {
	return Get(`/v3/admin/channel/documents/${activeClient}`, token);
}

export function updateDocumentName(
	newName: string,
	docId: number,
	token: string
): Promise<any> {
	return Put(
		`/v3/admin/channel/documents/name/${docId}`,
		token,
		{ newName },
		true
	);
}

export function postDocument(
	document: CreateDocument,
	token: string
): Promise<any> {
	return Post("/v3/admin/channel/documents", token, { document });
}
export function deleteDocumentsFetch(
	documentIDs: number[],
	token: string,
	channel: number
): any {
	return Delete(
		`/v3/admin/channel/documents`,
		token,
		{ documentIDs, channel },
		true
	);
}

export function updateDocumentThumbnail(
	url: string,
	document: number,
	token: string
): Promise<any> {
	return Put("/v3/admin/channel/documents/image", token, { url, document });
}

export function fetchUstreamVideos(
	activeClient: number,
	token: string
): Promise<any> {
	return Get(`/v3/admin/channel/videos/ustream-id/${activeClient}`, token).then(
		ustreamId => {
			const ustreamURL = `https://api.ustream.tv/channels/${ustreamId}/videos.json?pagesize=50&sort=recent`;
			return fetch(ustreamURL).then(res => res.json());
		}
	);
}

export function GetWistiaVideos(client: number, token: string): Promise<any> {
	return Get(`/v3/admin/channel/videos/legacy/uploaded/${client}`, token);
}

export function updateCustomReactions(channel: number, customReactions: CustomReaction[], token: string): Promise<any> {
	return Put('/v3/admin/channel/reactions/custom', token, { channel, customReactions });
}

export function GetVideoArchive(
	token: string,
	channel: number,
	search: string,
	offset: number,
	limit: number,
	sortBy: string
): Promise<{ videos: ArchivedVideo[], count: number }> {
	const queryParams = createQueryString({
		limit,
		offset,
		search: search || '',
		sortBy
	});

	return Get(`/v3/admin/video-archive/${channel}?${queryParams}`, token);
}
