import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { Sponsor } from '../../../types/working-model';
import {
	LOAD_SPONSORS,
	ADD_SPONSOR,
	UPDATE_SPONSOR,
} from '../../actions/admin/sponsors';

export interface SponsorsState {
	sponsors: Sponsor[];
	loadingSponsors: boolean;
	errorLoadingSponsors: string;
}

const initialState: SponsorsState = {
	errorLoadingSponsors: '',
	loadingSponsors: false,
	sponsors: [],
};

export default function SponsorsReducer(
	state: SponsorsState = initialState,
	action: Action
): SponsorsState {
	switch (action.type) {
		case LOAD_SPONSORS: {
			return handle(state, action, {
				start: (state) => ({ ...state, loadingSponsors: true }),
				failure: (state) => ({
					...state,
					errorLoadingSponsors: 'Could not load sponsors.',
				}),
				success: (state) => ({
					...state,
					sponsors: action.payload,
					errorLoadingSponsors: '',
				}),
				finish: (state) => ({ ...state, loadingSponsors: false }),
			});
		}
		case ADD_SPONSOR: {
			return { ...state, sponsors: [...state.sponsors, action.payload] };
		}
		case UPDATE_SPONSOR: {
			return {
				...state,
				sponsors: state.sponsors.map((sponsor: Sponsor) => {
					if (sponsor.sponsor === action.payload.sponsor) {
						return action.payload;
					}

					return sponsor;
				}),
			};
		}
		default:
			return state;
	}
}
