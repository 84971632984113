import { useState } from "react";
import CustomHTML from "@general-ui/edit-html/edit-html";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";

type Props = {
	withinTab?: boolean;
}
const PanelEmbedCode: React.FC<Props> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [html, setHtml] = useState('');

	return (
		<>
			<div className="settings-block" >
				<pre>{workingSession?.custom_css}</pre>
			</div>

			<CustomHTML
				defaultValue={html}
				onChange={setHtml}
			/>
		</>
	);
};

export default PanelEmbedCode;