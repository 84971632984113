import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { PageModule, PageModuleGroupModules, PageModuleType, Product, SessionPanelLayoutsTypes } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import ProductCard from "../../components/product-card";
import { customProductsItems, extrasProductsItems } from "../../empty-state-panel/constants/empty-panel";
import { usePageModule } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const productSearch = (term: string, products: Product[]): Product[] => {
	return [...products].filter(product => {
		// search across the entire stringified JSON
		// as a naive way of doing multi-language search
		const terms = JSON.stringify(product.product_title).toLowerCase() + JSON.stringify(product.description).toLowerCase();
		return terms.includes(term);
	});
};

const sortProducts = (sort: ESort | undefined, products: Product[]): Product[] => {
	return [...products].sort((a: Product, b: Product) => {
		// many products have the same creation date
		// use the product id as a tiebreaker
		const aId = (a.product as number);
		const bId = (b.product as number);
		switch (sort) {
			case ESort.date: {
				return (a.created_at.toString() + aId) > (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.dateDesc: {
				return (a.created_at.toString() + aId) < (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.name: {
				return a.product_title.base.toLowerCase() < b.product_title.base.toLowerCase() ? -1 : 1;
			}
			case ESort.nameDesc: {
				return a.product_title.base.toLowerCase() > b.product_title.base.toLowerCase() ? -1 : 1;
			}
			default: {
				return (a.product as number) < (b.product as number) ? -1 : 1;
			}
		}
	});
};

const ActiveProductsList: React.FC<unknown> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [products, setProducts] = useState<Product[]>([]);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const moduleGroupingRef = useRef<PageModuleGroupModules[] | undefined>(workingSession?.module_grouping);
	const pageModulesRef = useRef<PageModule[] | undefined>(workingSession?.modules);
	const pageModule = usePageModule();
	const moduleGrouping = workingSession?.module_grouping;
	const pageModules = workingSession?.modules;
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customProductsItems : extrasProductsItems;
	const { customPath } = useParams<{ customPath?: string }>();
	const history = useHistory<{ productToEdit?: Product, productToReplace?: Product }>();
	const getAdmin = useGetAdminUrl();
	const dispatch = useDispatch();

	useEffect(() => {
		if (moduleGrouping) {
			moduleGroupingRef.current = moduleGrouping;
		}

		if (pageModules) {
			pageModulesRef.current = pageModules;
		}
	}, [moduleGrouping, pageModules]);

	const getExistingProducts = useCallback(() => {
		if (pageModule?.modules) {
			return pageModule.modules as Product[];
		}

		return [];
	}, [pageModule]);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		const products = getExistingProducts();
		const filteredProducts = productSearch(searchTerm, products);
		const sortedProducts = sortProducts(sortOrder, filteredProducts);
		setProducts(sortedProducts);
	}, [searchTerm, getExistingProducts, sortOrder]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	useEffect(() => {
		setProducts(getExistingProducts());
	}, [getExistingProducts]);

	const handleEdit = useCallback((product: Product) => {
		if (!pageModule?.id) return;

		history.push(getAdmin({
			path: SessionPanelMap[SessionPanelLayoutsTypes.CreateExtraProduct],
			customPath,
			page_module: pageModule?.id
		}), { productToEdit: product });
	}, [customPath, getAdmin, history, pageModule?.id]);

	const handleDelete = useCallback((product: Product) => {
		if (!pageModule) return;

		dispatch(updatePageModuleAndSave({
			...pageModule,
			content_modules: pageModule.content_modules?.filter(module => module !== product.product),
			modules: pageModule.modules?.filter((module: Product) => module.product !== product.product),
			type: PageModuleType.products
		}));
	}, [dispatch, pageModule]);

	return (
		<div
			className="session-panel products-list"
			ref={containerRef}
		>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
					isAdmin
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>

			{products.length ? (
				<StaggerChildren
					ref={scrollRef}
					enableFades={true}
					footer={
						(
							<SessionPanelAddFooter
								items={extrasItems}
								scrollRef={scrollRef}
								dropdownPosition="top"
							/>
						)
					}
					className={classNames("session-panel-content added-items")}>
					{products.map((product: Product) => (
						<ProductCard
							key={product.product}
							product={product}
							handleDelete={handleDelete}
							handleEdit={handleEdit}
						/>
					))}
				</StaggerChildren>
			) : (
				<div className="session-panel-no-results">
					<section>{!workingSession ? <WaitingIndicator /> : 'No results'}</section>
				</div>
			)}
		</div>
	);
};

export default ActiveProductsList;