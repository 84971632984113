import { hideAlert, showAlertLong } from "@general-ui/alert/alert-service";
import { FeatureFlagMap, FeatureFlagsEnum } from "../types/working-model";
import { Get, Post } from "./helpers";

export async function GetFeatureFlags(): Promise<FeatureFlagMap> {
	let flags: FeatureFlagMap = {};
	let found = false;
	let attempts = 0;
	let alert = undefined;

	while (!found) {
		try {
			flags = await Get('/v3/feature-flags', '');
		} catch {
			if (attempts++ > 5 && !alert) {
				alert = showAlertLong({
					message: 'Connection Problem',
					description: 'We are having a problem connecting to the server. Please check your connection.',
					type: 'warning'
				}) as string;
			}

			await new Promise(resolve => setTimeout(resolve, 1000));
		}

		if (flags) {
			found = true;

			if (alert) {
				hideAlert({ id: alert });
			}
		}
	}

	return flags;
}

export function UpdateFeatureFlags(flag: FeatureFlagsEnum, value: boolean, token: string): Promise<FeatureFlagMap> {
	return Post('/v3/feature-flags', token, { flag, value }, false, {}, true);
}