import React, { useCallback, useMemo, useState, useEffect, Suspense, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';

import { completeRegistrationProfile, register, setRegistrationStep, finishedStripePayment, clearErrorBoundaryComponents, setRegistrationError, clearRegistrationError } from '../../../store/actions/event/event-actions';
import { TemplateNames } from '../../../types/template-layouts';
import {
	BlProfile,
	BrandliveEvent,
	Callout,
	ChannelFeaturesEnum,
	EPreviewTypes,
	ERegistrationLayoutTypes,
	LanguagesAbbr,
	PageModuleType,
	RegistrationStep,
	RegistrationStepType,
	TicketingSet,
	TranslateString,
} from '../../../types/working-model';
import { getStartTime } from '../../../utils/get-event-start-time';
import { SelectOption } from '../../general-ui/select/select-native';
import WaitingIndicator from '../../general-ui/waiting-indicator/waiting-indicator';
import { eventPath, getDefaultLanguage, useLanguageParam } from '../utils';
import { RegistrationFooterProps } from '../registration/registration-footer';
import { RegFailureReasons } from 'utils/use-get-registration-error-message';
import { getAllStoredQueryParams, getPathKey, getQueryParams, getStoredReferrer, getTextValue } from '../../../utils/utils';
import useTranslationModules, { useTranslate } from '../../../i18n/useTranslationModules';
import { ParamsProps } from '../live-event';
import { getStorageItem, getStorageObject } from '../../../utils/local-storage';
import { hasEventAccess } from '../../../utils/registration-utils';
import { SignIn } from '../registration/steps/signIn';
import ResetPassword from '../registration/steps/resetPassword';
import { VerificationView } from '../registration/steps/verificationView';
import { handleRecaptcha } from '../../../utils/execute-recaptcha';
import RecaptchaV2 from '../../general-ui/recaptcha-v2/recaptcha-v2';
import { OptionalComponent } from '../../../utils/optional-component';
import { TEST_ERROR_BOUNDARIES } from '../../../utils/error-boundary';
import { IRegistrationGDPR } from '../../../connection/registration';
import useCheckIfCookieAccepted from '../../general-ui/cookie-banner/use-check-if-cookie-accepted';
import { Redirects } from '../../../utils/redirects';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import useShowRecaptchaBadge from '../registration/use-show-recaptcha-badge';
import { RegistrationLayouts } from './registration-layouts/registration-layouts';
import RegistrationPanels from './registration-panels';
import { EventsState } from 'store/types';
import { useScreenMediaQuery } from 'utils/use-screen-media-query';
import { useGetRegistrationLayout } from 'hooks/registration-hooks';
import GrabYourSpot from './grab-your-spot';
import { useInView } from 'react-intersection-observer';

const LazyStripeWrapped = React.lazy(() => import('./lazy-stripe-wrapped'));

import '../../../scss/live-event/base/general-ui/input.scss';
import './registration-v2.scss';
import AppContext, { AppContexts } from 'components/app-context';
import SetNewPassword from '../registration/steps/setNewPassword';

export interface IRegistrationFormField {
	field_id: number;
	global: boolean;
	label: string;
	optionMap?: Map<number, string>;
	options?: SelectOption[];
	persistent: boolean;
	placeholder: string | undefined;
	required: boolean;
	translation?: TranslateString;
	type: string;
}

export interface IPasswordGatingProps {
	password: string;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
	passwordConfirmation: string;
	setPasswordConfirmation: React.Dispatch<React.SetStateAction<string>>;
}

const getUTMParams = (
	queryParams: Record<string, string>
): Record<string, string> | undefined => {
	if (!queryParams) {
		return {
			...getStorageObject(`utm_params-${getPathKey()}`)
		};
	}

	return {
		...Object.fromEntries(
			Object.entries(queryParams).filter(([key]) => key.toLowerCase().startsWith('utm'))
		),
		...getStorageObject(`utm_params-${getPathKey()}`)
	};
};

interface RegistrationPageProps {
	previewLanguage?: LanguagesAbbr;
	className?: string;
}

export const RegistrationPageV2: React.FC<RegistrationPageProps> = ({ previewLanguage, className = '' }): JSX.Element => {
	const blProfileUser = useTypedSelector(event => event.LiveEventReducer.blProfileUser);
	const blProfileUserToken = useTypedSelector(event => event.LiveEventReducer.blProfileUserToken);
	const completingProfile = useTypedSelector(event => event.LiveEventReducer.completingProfile);
	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const finishedRegistering = useTypedSelector(event => event.LiveEventReducer.finishedRegistering);
	const firesidesHostSessions = useTypedSelector(event => event.LiveEventReducer.firesidesHostSessions);
	const loadingValidSessions = useTypedSelector(event => event.LiveEventReducer.loadingValidSessions);
	const previewMode = useTypedSelector(event => event.CreateEventReducer.previewMode);
	const previewRegistrationStep = useTypedSelector(event => event.CreateEventReducer.registrationStep);
	const recaptchaActionRequired = useTypedSelector(event => event.LiveEventReducer.recaptchaActionRequired);
	const registering = useTypedSelector(event => event.LiveEventReducer.registering);
	const registration_bypass = useTypedSelector(event => event.LiveEventReducer.registration_bypass);
	const registrationError = useTypedSelector(event => event.LiveEventReducer.registrationError);
	const registrationId = useTypedSelector(event => event.LiveEventReducer.registrationId);
	const registrationStep = useTypedSelector(event => event.LiveEventReducer.registrationStep);
	const userVerified = useTypedSelector(event => event.LiveEventReducer.userVerified);
	const validSessions = useTypedSelector(event => event.LiveEventReducer.validSessions);
	const verifyingUser = useTypedSelector(event => event.LiveEventReducer.verifyingUser);
	const workingEvent = useTypedSelector(event => event.CreateEventReducer.workingEvent);
	const shouldRedirectToAlreadyRegisteredEmail = useTypedSelector(event => event.LiveEventReducer.shouldRedirectToAlreadyRegisteredEmail);

	const appContext = useContext(AppContext);
	const isEditor = appContext === AppContexts.admin;

	const { uuid, eventName }: ParamsProps = useParams();
	const { ref: inViewRef, inView, entry } = useInView({ threshold: .05 });

	const history = useHistory();
	const dispatch = useDispatch();
	const location: any = useLocation();
	const { t } = useTranslate(['registrations', 'homepage']);
	const { isLessThan1024 } = useScreenMediaQuery();
	const urlParams = getQueryParams(location.search);

	const [showSignInView, setShowSignInView] = useState(false);
	const [showPasswordResetView, setShowPasswordResetView] = useState(false);
	const [showSetNewPasswordView, setShowSetNewPasswordView] = useState(!!urlParams['reset-code']);
	const [widgetId, setWidgetId] = useState<number | null>(null);
	const [isMarketingOptInChecked, setIsMarketingOptInChecked] = useState(false);
	const [showMobileCTA, setShowMobileCTA] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	useShowRecaptchaBadge();

	const passwordGatingEnabled = useMemo(() => !!eventBundle?.registration_settings?.password_gating_enabled, [eventBundle?.registration_settings?.password_gating_enabled]);

	// Check to make sure language is in translated list, if not, push them to the default language
	// Need to force back to ACTUAL BASE lanuage, base is not always English.
	const language = useLanguageParam() as LanguagesAbbr;

	const languageExists = eventBundle?.sessionsPreviews?.some(session => session.languages.includes(language));

	// we are only going to override the path language IF the event bundle has actually loaded and it does not exist
	const notValidTranslation = eventBundle && !languageExists;

	const defaultLangage = eventBundle ? getDefaultLanguage(eventBundle) : 'en';
	const languageToUse = previewLanguage //if in preview module/portal use the passed language beacuse params is not avalable
		? previewLanguage
		: notValidTranslation ? defaultLangage : language;

	useTranslationModules({ language: languageToUse });

	const isPreview = isEditor || previewMode === EPreviewTypes.Registration;
	const event = eventBundle ?? workingEvent;
	const registration = event?.registration_settings;
	const registrationSteps = event?.registration_steps;
	const singleSignOns = event?.registration_settings?.singleSignOn?.singleSignOns;
	const requiredFields = event?.required_registration_questions;
	const template = event?.template.name || TemplateNames.Classic;
	const recaptchaEnabled = !!event?.registration_settings?.enableRecaptcha;
	const registrationLayout = useGetRegistrationLayout(event?.registration_settings?.style);

	const startDateTicks = event ? getStartTime(event, false) : undefined;
	const startDate = startDateTicks ? new Date(startDateTicks) : undefined;

	const activeSteps = useMemo(() => {
		return (
			registrationSteps?.filter((step) => step.isOn) || []
		);
	}, [registrationSteps]);

	const isFinalStep = registrationStep >= activeSteps.length - 1;
	const isFirstStep = registrationStep === 0;
	const activeStep = activeSteps?.[registrationStep];

	useEffect(() => {
		const hasSignInHash = window.location.hash.substring(1) === 'sign-in';
		if (hasSignInHash) {
			setShowSignInView(true);
		}
	}, []);

	useEffect(() => {
		// on first render of preview, set active step to match current reg step in editor
		if (isPreview && activeSteps.length) {
			const startingStep = activeSteps.findIndex(step => step.type === previewRegistrationStep);
			dispatch(setRegistrationStep(startingStep));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	const handlePrevStep = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		if (isFirstStep) return;
		dispatch(setRegistrationStep(registrationStep - 1));
	}, [isFirstStep, dispatch, registrationStep]);

	// used for registration integrations. We want to send out reg/profile data on last registration step,
	// but not if final step is ticketing. If this is the case, return true when on the final non ticketing step
	const isFinalNonTicketStep = useCallback(() => {
		// if final step and type not ticketing, is last non ticketing reg step
		if (isFinalStep && activeStep?.type !== RegistrationStepType.ticketing) {
			return true;
		}
		// if we are on the second to last reg step and the final reg question is of type ticketing
		if (
			registrationStep >= activeSteps.length - 2 &&
			activeSteps?.[activeSteps.length - 1]?.type === RegistrationStepType.ticketing
		) {
			return true;
		}
		return false;
	}, [registrationStep, activeStep, isFinalStep, activeSteps]);

	const { userRegGDPR } = useCheckIfCookieAccepted();

	const cookiesEnabled = !!eventBundle?.settings.gdpr?.enable_cookie_banner;
	const enableCookieOptIn = !!eventBundle?.channel_features?.includes(ChannelFeaturesEnum.cookie_opt_in);

	const handleSaveData = useCallback(async (data: SubmissionValues, authCode?: string) => {
		if (isEditor) return;

		// if event settings have cookies enabled, but user has not clicked accept, do not let them pass,
		// exception for cookie opt in channel setting
		if (!enableCookieOptIn && cookiesEnabled && !userRegGDPR) return;

		// if in preview mode don't submit but progress through registration
		if (isPreview) {
			if (isFinalStep) return;
			return dispatch(setRegistrationStep(registrationStep + 1));
		}
		//drop "/registration" from the current path to redirect the user back to the page.
		const path = window.location.href.split("/");
		path.pop();
		const source = path.join("/");

		if (!event) return;
		switch (activeStep?.type) {
			case RegistrationStepType.general: {
				const userCode = getStorageItem('user-code');
				const queryParams = getAllStoredQueryParams(event.uuid);

				try {
					if (passwordGatingEnabled) {
						if (password !== passwordConfirmation) {
							return dispatch(setRegistrationError(RegFailureReasons.unmatchedPassword));
						} else if (!password || !passwordConfirmation) {
							return dispatch(setRegistrationError(RegFailureReasons.noPassword));
						}
					}

					const ACTION = 'signup';

					// recaptcha (runs v3, if v3 fails, runs v2)
					const recaptchaResponse = recaptchaEnabled && await handleRecaptcha({
						action: ACTION,
						useV2: recaptchaActionRequired,
						widgetId,
					});

					const headers = recaptchaResponse?.headers ?? {};

					const utmParams = getUTMParams(queryParams);

					return dispatch(register(
						{
							action: ACTION, // for recaptcha v3
							allQueryParams: { ...queryParams, ...utmParams },
							authCode,
							fields: data,
							gdpr: userRegGDPR || (
								event?.settings?.gdpr?.enable_cookie_banner
									? { cookies_accepted: false }
									: undefined
							),
							hostname: window.location.hostname, // for recaptcha
							isFinalNonTicketStep: isFinalNonTicketStep(),
							isFinalStep: isFinalStep,
							isMarketingOptInChecked,
							lang: language,
							redirect_uri: authCode ? `${window.location.origin}/sso-redirect` : undefined,
							referrer: getStoredReferrer(event.uuid),
							registered_language: languageToUse,
							source: source,
							step: RegistrationStepType.general,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							userCode: userCode,
							utmParams,
							password,
							// if user has not made a cookie banner choice and event has cookie banner enabled, then we default them to opt out
						},
						event.uuid,
						blProfileUserToken,
						headers,
					));
				} catch (e) {
					console.error(e);
					return;
				}
			}
			case RegistrationStepType.profile: {
				const queryParams = getAllStoredQueryParams(event.uuid);
				//if this is the final step, we must send the email
				const options = {
					allQueryParams: queryParams,
					avatar: null,
					channel: event.channel,
					event_title: event.name || "",
					eventUuid: event.uuid,
					fields: data,
					isFinalNonTicketStep: isFinalNonTicketStep(),
					isFinalStep: isFinalStep,
					lang: language,
					registrationId: registrationId as string,
					source: source,
					step: RegistrationStepType.profile,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				};
				return dispatch(completeRegistrationProfile(options, blProfileUserToken));
			}
			case RegistrationStepType.avatar: {
				const queryParams = getAllStoredQueryParams(event.uuid);
				//if this is the final step, we must send the email
				const options = {
					allQueryParams: queryParams,
					avatar: null,
					channel: event.channel,
					event_title: event.name || "",
					eventUuid: event.uuid,
					fields: data,
					isFinalNonTicketStep: isFinalNonTicketStep(),
					isFinalStep: isFinalStep,
					lang: language,
					registrationId: registrationId as string,
					source: source,
					step: RegistrationStepType.avatar,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				};
				return dispatch(completeRegistrationProfile(options, blProfileUserToken));
			}
			case RegistrationStepType.ticketing: {
				return dispatch(finishedStripePayment());
			}
		}
	}, [
		activeStep?.type,
		blProfileUserToken,
		cookiesEnabled,
		dispatch,
		enableCookieOptIn,
		event,
		isFinalNonTicketStep,
		isFinalStep,
		isMarketingOptInChecked,
		language,
		languageToUse,
		isPreview,
		recaptchaActionRequired,
		recaptchaEnabled,
		registrationId,
		registrationStep,
		userRegGDPR,
		widgetId,
		isEditor,
		password,
		passwordConfirmation,
		passwordGatingEnabled,
	]);

	useEffect(() => {
		const descriptionPositionCheck = [ERegistrationLayoutTypes.modal, ERegistrationLayoutTypes.landingL, ERegistrationLayoutTypes.landingR];
		if (isLessThan1024 && !descriptionPositionCheck.includes(registrationLayout as ERegistrationLayoutTypes)) {
			if (inView) {
				setShowMobileCTA(false);
			} else {
				setShowMobileCTA(true);
			}
		}
	}, [entry, inView, isLessThan1024, registrationLayout]);

	const cancelSignIn = useCallback(() => {
		setShowSignInView(false);
		dispatch(clearRegistrationError());
		history.push(`${location.pathname}`);
	}, [history, location.pathname]);

	const handleSignIn = useCallback((e: React.MouseEvent) => {
		e?.preventDefault();
		if (!eventBundle) return;

		setShowSignInView(true);
		const defaultLanguage = getDefaultLanguage(eventBundle);

		// using a router hash instead of a new route so we can easily keep our animations
		history.push(`/${eventPath(eventName, uuid)}/${language || defaultLanguage}/registration#sign-in`);
	}, [eventBundle, history, eventName, uuid, language]);

	const handleShowPasswordResetView = () => {
		setShowSignInView(false);
		setShowPasswordResetView(true);
	};

	const handleExitPasswordResetView = () => {
		setShowSignInView(true);
		setShowPasswordResetView(false);
	};

	const handleExitSetNewPassword = () => {
		setShowSetNewPasswordView(false);
		setShowSignInView(true);
	};

	useEffect(() => {
		// Handles linking from a calendar invite link (or any link) for an already registered user
		// who's token is expired or visiting on a different device
		if (!eventBundle || !shouldRedirectToAlreadyRegisteredEmail) return;

		setShowSignInView(true);
		const defaultLanguage = getDefaultLanguage(eventBundle);

		// using a router hash instead of a new route so we can easily keep our animations
		history.push(`/${eventPath(eventName, uuid)}/${language || defaultLanguage}/registration#sign-in`);
	}, [eventBundle, eventName, history, language, shouldRedirectToAlreadyRegisteredEmail, uuid]);

	const footerProps = useMemo(() => {
		return {
			isFirstStep,
			isFinalStep,
			onPrevStepClick: handlePrevStep,
			singleSignOns: singleSignOns || [],
			waiting: registering || completingProfile,
			error: registrationError,
			eventBundle
		};
	}, [handlePrevStep, isFinalStep, isFirstStep, singleSignOns, registering, completingProfile, registrationError, eventBundle]);

	const getWidgetId = useCallback((widgetId) => {
		setWidgetId(widgetId);
	}, []);

	const fieldDefaults: { [key: string]: number | string; } = {};
	activeStep?.questions?.forEach((question) => {
		if (question.default_value) {
			fieldDefaults[question?.registration_question?.toString()] = question?.default_value;
		}
	});

	if (isPreview && workingEvent && registration) {
		return (
			<RegistrationLayouts
				registration={registration}
				startDate={startDate}
				inViewRef={inViewRef}
				className={className}
			>
				{activeStep && (
					<RegistrationPanels
						activeStep={activeStep}
						channel={workingEvent.channel}
						defaultValues={fieldDefaults ?? {}}
						eventBundle={workingEvent ? workingEvent : eventBundle}
						footerProps={footerProps}
						handleSignIn={handleSignIn}
						isMarketingOptInChecked={isMarketingOptInChecked}
						onSaveData={handleSaveData}
						profile={blProfileUser}
						registrationStep={registrationStep}
						requiredFields={requiredFields || []}
						setIsMarketingOptInChecked={setIsMarketingOptInChecked}
						steps={activeSteps}
						template={template}
						userRegGDPR={userRegGDPR}
						passwordGatingProps={{ password, setPassword, passwordConfirmation, setPasswordConfirmation }}
					/>
				)}
			</RegistrationLayouts>
		);
	}

	if (!registration || !eventBundle) {
		return (<WaitingIndicator />);
	}

	// Redirect to if user has access
	if (hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, !!registration_bypass, loadingValidSessions)) {
		return (
			<Redirect to={Redirects.fromRegistration(eventBundle)} />
		);
	}

	const defaults = { ...fieldDefaults, ...blProfileUser?.profile?.[eventBundle.channel] };
	const calloutModule = eventBundle?.homepage?.modules?.find(module => module.type === PageModuleType.callout);
	const content: Callout = calloutModule?.content;
	const { description, title } = getTextValue(content, language);

	return (
		<>
			<RegistrationLayouts
				registration={registration}
				startDate={startDate}
				inViewRef={inViewRef}
			>
				<OptionalComponent display={showSetNewPasswordView && !showPasswordResetView && !showSignInView}>
					<SetNewPassword template={template} handleExitSetNewPassword={handleExitSetNewPassword} />
				</OptionalComponent>
				<OptionalComponent display={showPasswordResetView && !showSetNewPasswordView && !showSignInView}>
					<ResetPassword template={template} handleExitPasswordResetView={handleExitPasswordResetView} />
				</OptionalComponent>
				<OptionalComponent display={showSignInView && !showSetNewPasswordView && !showPasswordResetView}>
					<>
						{verifyingUser && !userVerified ? (
							<VerificationView template={template} />
						) : (
							<SignIn onPrevStepClick={cancelSignIn} template={template} handleShowPasswordResetView={handleShowPasswordResetView} />
						)}
					</>
				</OptionalComponent>
				<OptionalComponent display={!showSetNewPasswordView && !showSignInView && !showPasswordResetView}>
					{!userVerified && finishedRegistering ? (
						<VerificationView template={template} />
					) : (
						<OptionalComponent display={!!activeStep}>
							<RegistrationPanels
								activeStep={activeStep}
								channel={eventBundle.channel}
								defaultValues={defaults}
								eventBundle={eventBundle}
								footerProps={footerProps}
								handleSignIn={handleSignIn}
								isMarketingOptInChecked={isMarketingOptInChecked}
								onSaveData={handleSaveData}
								profile={blProfileUser}
								registrationStep={registrationStep}
								requiredFields={requiredFields || []}
								setIsMarketingOptInChecked={setIsMarketingOptInChecked}
								steps={activeSteps}
								template={template}
								renderRecaptcha={() => (
									<OptionalComponent display={recaptchaActionRequired}>
										<RecaptchaV2
											// we need the widget id to pass to grecaptcha's `getResponse` method to ensure we're using the correct widget
											// just in case we have multiple recaptcha widget's on the screen at any time
											getWidgetId={getWidgetId}
										/>
									</OptionalComponent>
								)}
								userRegGDPR={userRegGDPR}
								passwordGatingProps={{ password, setPassword, passwordConfirmation, setPasswordConfirmation }}
							/>
						</OptionalComponent>
					)}
				</OptionalComponent>
			</RegistrationLayouts>
			<OptionalComponent display={showMobileCTA && isLessThan1024}>
				<GrabYourSpot
					title={title || t(`callout.${calloutModule?.id}.title`, t('homepage:registration.Grab your spot', 'homepage:registration.Register for the event'))}
					buttonText={content?.callToAction?.button_name[language] as string || t('homepage:registration.Register')}
					/* No scroll action in the editor because there is some buggy behavior with forced scrolling overriding overflow: hidden */
					onclick={() => { entry?.target?.scrollIntoView({ behavior: 'smooth' as ScrollBehavior }); }}
				/>
			</OptionalComponent>
		</>
	);
};

const RegistrationWrappedV2 = (props: RegistrationPageProps): JSX.Element => {
	const steps = useSelector((event: EventsState) => event.LiveEventReducer.eventBundle?.registration_steps);

	const hasTicketing = useMemo(() => steps?.some(step => step.ticketing && step.isOn), [steps]);

	if (hasTicketing) {
		return (
			<Suspense fallback={<WaitingIndicator />}>
				<LazyStripeWrapped {...props}>
					<RegistrationPageV2 {...props} />
				</LazyStripeWrapped>
			</Suspense>
		);
	}

	return <RegistrationPageV2 {...props} />;
};

export default RegistrationWrappedV2;


export interface ContentProps {
	channel: number;
	currentStep?: RegistrationStep;
	defaultValues: SubmissionValues;
	eventBundle?: BrandliveEvent | null;
	fields: IRegistrationFormField[];
	footerProps: RegistrationFooterProps;
	gdprStatus?: IRegistrationGDPR;
	handleSignIn?: (e: any) => void;
	isDisplayOnly?: boolean;
	isMarketingOptInChecked?: boolean;
	language: LanguagesAbbr;
	onSaveData: (data: SubmissionValues, authCode?: string) => void;
	profile: BlProfile | null;
	renderRecaptcha?: () => JSX.Element;
	selectBaseLanguageFieldMap: Map<string, string>;
	setIsMarketingOptInChecked?: React.Dispatch<React.SetStateAction<boolean>>;
	template: string;
	ticketing?: TicketingSet[];
}

export interface SubmissionValues {
	[key: string]: string | boolean | number | Array<string> | undefined;
}
