import { createContext, useReducer } from "react";

import { sortBannedQuestions } from "utils/questions-utils";
import { IncomingSocketQuestion, PaginatedQuestion, QuestionComment, Status } from "../../../../../../../../types/working-model";
import { QuestionBan } from "types/socket-messages";

type QuestionId = number;
type QuestionsCommentsMap = Record<QuestionId, (QuestionComment | IncomingSocketQuestion)[]>;
type QuestionsState = {
	questions: PaginatedQuestion[];
	questionIds: number[];
	loadingQuestions: boolean;
	questionsComments: QuestionsCommentsMap;
	loadingQuestionsComments: Record<QuestionId, boolean>;
	moduleId: number | undefined;
	hasEverLoaded: boolean;
}

export const initialState: QuestionsState = {
	questions: [],
	questionIds: [],
	loadingQuestions: false,
	questionsComments: {},
	loadingQuestionsComments: {},
	moduleId: undefined,
	hasEverLoaded: false,
};

export enum QuestionsActions {
	SetQuestions = 'setQuestions',
	PrependQuestions = 'prependQuestions',
	SetLoadingQuestions = 'setLoadingQuestions',
	SetQuestionsComments = 'setQuestionsComments',
	AppendOneQuestionsComment = 'appendOneQuestionsComment',
	SetLoadingQuestionsComments = 'setLoadingQuestionsComments',
	Reset = 'reset',
	QuestionLike = 'questionLike',
	QuestionUnlike = 'questionUnlike',
	QuestionAnswered = 'questionAnswered',
	QuestionsBulkAnswered = 'questionsBulkAnswered',
	QuestionComment = 'questionComment',
	QuestionApprove = 'questionApprove',
	QuestionUnapprove = 'questionUnapprove',
	QuestionDelete = 'questionDelete',
	HandleBanned = 'handleBanned',
	QuestionCommentApprove = 'questionCommentApprove',
	QuestionCommentUnapprove = 'questionCommentUnapprove',
	QuestionCommentDelete = 'questionCommentDelete',
	QuestionCommentUndelete = 'questionCommentUndelete',
}

type DispatchedAction<T, P> = { type: T, payload: P };

type SetQuestionsAction = DispatchedAction<QuestionsActions.SetQuestions, PaginatedQuestion[]>;
type PrependQuestionsAction = DispatchedAction<QuestionsActions.PrependQuestions, PaginatedQuestion[]>;
type SetLoadingQuestionsAction = DispatchedAction<QuestionsActions.SetLoadingQuestions, boolean>;
type SetQuestionsCommentsAction = DispatchedAction<QuestionsActions.SetQuestionsComments, { questionId: number, comments: QuestionComment[] }>;
type AppendOneQuestionsCommentAction = DispatchedAction<QuestionsActions.AppendOneQuestionsComment, { questionId: number, comments: IncomingSocketQuestion[] }>;
type SetLoadingQuestionsCommentsAction = DispatchedAction<QuestionsActions.SetLoadingQuestionsComments, { questionId: number, loading: boolean }>;
type ResetAction = DispatchedAction<QuestionsActions.Reset, number | undefined>;
type QuestionLikeAction = DispatchedAction<QuestionsActions.QuestionLike, number>;
type QuestionUnlikeAction = DispatchedAction<QuestionsActions.QuestionUnlike, number>;
type QuestionAnsweredAction = DispatchedAction<QuestionsActions.QuestionAnswered, [number, boolean]>;
type QuestionsAnsweredBulkAction = DispatchedAction<QuestionsActions.QuestionsBulkAnswered, [number[], boolean]>;
type QuestionCommentAction = DispatchedAction<QuestionsActions.QuestionComment, QuestionComment>;
type QuestionApproveAction = DispatchedAction<QuestionsActions.QuestionApprove, number>;
type QuestionUnapproveAction = DispatchedAction<QuestionsActions.QuestionUnapprove, number>;
type QuestionDeleteAction = DispatchedAction<QuestionsActions.QuestionDelete, number>;
type HandleBannedAction = DispatchedAction<QuestionsActions.HandleBanned, QuestionBan[]>;
type QuestionCommentApproveAction = DispatchedAction<QuestionsActions.QuestionCommentApprove, number>;
type QuestionCommentUnapproveAction = DispatchedAction<QuestionsActions.QuestionCommentUnapprove, number>;
type QuestionCommentDeleteAction = DispatchedAction<QuestionsActions.QuestionCommentDelete, [number, number]>;
type QuestionCommentUndeleteAction = DispatchedAction<QuestionsActions.QuestionCommentUndelete, number>;

type QuestionsAction =
	SetQuestionsAction |
	PrependQuestionsAction |
	AppendOneQuestionsCommentAction |
	SetLoadingQuestionsAction |
	SetQuestionsCommentsAction |
	SetLoadingQuestionsCommentsAction |
	ResetAction |
	QuestionLikeAction |
	QuestionUnlikeAction |
	QuestionAnsweredAction |
	QuestionsAnsweredBulkAction |
	QuestionCommentAction |
	QuestionApproveAction |
	QuestionUnapproveAction |
	QuestionDeleteAction |
	HandleBannedAction |
	QuestionCommentApproveAction |
	QuestionCommentUnapproveAction |
	QuestionCommentDeleteAction |
	QuestionCommentUndeleteAction;

export const setQuestions = (questions: PaginatedQuestion[]): SetQuestionsAction => ({ type: QuestionsActions.SetQuestions, payload: questions });
export const prependQuestions = (questions: PaginatedQuestion[]): PrependQuestionsAction => ({ type: QuestionsActions.PrependQuestions, payload: questions });
export const setLoadingQuestions = (loading: boolean): SetLoadingQuestionsAction => ({ type: QuestionsActions.SetLoadingQuestions, payload: loading });
export const setQuestionsComments = (questionId: number, comments: QuestionComment[]): SetQuestionsCommentsAction => ({ type: QuestionsActions.SetQuestionsComments, payload: { questionId, comments } });
export const appendOneQuestionsComment = (questionId: number, comments: IncomingSocketQuestion[]): AppendOneQuestionsCommentAction => ({ type: QuestionsActions.AppendOneQuestionsComment, payload: { questionId, comments } });
export const setLoadingQuestionsComments = (questionId: number, loading: boolean): SetLoadingQuestionsCommentsAction => ({ type: QuestionsActions.SetLoadingQuestionsComments, payload: { questionId, loading } });
export const resetQuestions = (moduleId: number | undefined): ResetAction => ({ type: QuestionsActions.Reset, payload: moduleId });
export const questionLike = (questionId: number): QuestionLikeAction => ({ type: QuestionsActions.QuestionLike, payload: questionId });
export const questionUnlike = (questionId: number): QuestionUnlikeAction => ({ type: QuestionsActions.QuestionUnlike, payload: questionId });
export const questionAnswered = (questionId: number, answered: boolean): QuestionAnsweredAction => ({ type: QuestionsActions.QuestionAnswered, payload: [questionId, answered] });
export const questionsAnsweredBulk = (questionIds: number[], answered: boolean): QuestionsAnsweredBulkAction => ({ type: QuestionsActions.QuestionsBulkAnswered, payload: [questionIds, answered] });
export const questionComment = (message: QuestionComment): QuestionCommentAction => ({ type: QuestionsActions.QuestionComment, payload: message });
export const questionApprove = (questionId: number): QuestionApproveAction => ({ type: QuestionsActions.QuestionApprove, payload: questionId });
export const questionUnapprove = (questionId: number): QuestionUnapproveAction => ({ type: QuestionsActions.QuestionUnapprove, payload: questionId });
export const questionDelete = (questionId: number): QuestionDeleteAction => ({ type: QuestionsActions.QuestionDelete, payload: questionId });
export const handleBan = (bans: QuestionBan[]): HandleBannedAction => ({ type: QuestionsActions.HandleBanned, payload: bans });
export const questionCommentApprove = (commentId: number): QuestionCommentApproveAction => ({ type: QuestionsActions.QuestionCommentApprove, payload: commentId });
export const questionCommentUnapprove = (commentId: number): QuestionCommentUnapproveAction => ({ type: QuestionsActions.QuestionCommentUnapprove, payload: commentId });
export const questionCommentDelete = (commentId: number, questionId: number): QuestionCommentDeleteAction => ({ type: QuestionsActions.QuestionCommentDelete, payload: [commentId, questionId] });
export const questionCommentUndelete = (commentId: number): QuestionCommentUndeleteAction => ({ type: QuestionsActions.QuestionCommentUndelete, payload: commentId });

const questionsReducer = (state: QuestionsState, action: QuestionsAction) => {
	switch (action.type) {
		case QuestionsActions.SetQuestions:
			return {
				...state,
				questions: action.payload,
				questionIds: action.payload.map(question => question.id),
				loadingQuestions: false,
				hasEverLoaded: true
			};
		case QuestionsActions.PrependQuestions: {
			const allowed = action.payload.filter(question => question.status !== Status.Banned);
			if (!allowed.length) {
				return state;
			}

			const questionsArr = [
				...allowed,
				...state.questions,
			];
			const questionIdsArr = [
				...allowed.map(question => question.id),
				...state.questionIds,
			];
			return {
				...state,
				questions: questionsArr,
				questionIds: questionIdsArr,
				loadingQuestions: false
			};
		}

		case QuestionsActions.SetLoadingQuestions:
			return {
				...state,
				loadingQuestions: action.payload
			};
		case QuestionsActions.SetQuestionsComments:
			return {
				...state,
				questionsComments: {
					...state.questionsComments,
					[action.payload.questionId]: action.payload.comments
				},
				loadingQuestionsComments: {
					...state.loadingQuestionsComments,
					[action.payload.questionId]: false
				}
			};
		case QuestionsActions.AppendOneQuestionsComment: {
			const exists = state.questionIds.includes(action.payload.questionId);

			if (!Array.isArray(action.payload.comments) || !action.payload.comments[0]) {
				return state;
			}

			const allowed = action.payload.comments.filter(comment => comment.status !== Status.Banned);

			if (!exists || allowed.length === 0) {
				return state;
			}

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === action.payload.questionId) {
						return { ...question, comments: question.comments + allowed.length };
					}
					return question;
				}),
				questionsComments: {
					...state.questionsComments,
					[action.payload.questionId]: [
						...allowed,
						...(state.questionsComments[action.payload.questionId] ?? []),
					]
				},
				loadingQuestionsComments: {
					...state.loadingQuestionsComments,
					[action.payload.questionId]: false
				}
			};
		}
		case QuestionsActions.SetLoadingQuestionsComments:
			return {
				...state,
				loadingQuestionsComments: {
					...state.loadingQuestionsComments,
					[action.payload.questionId]: action.payload.loading
				}
			};
		case QuestionsActions.Reset:
			if (action.payload !== state.moduleId) {
				return { ...initialState, moduleId: action.payload };
			} else {
				return state;
			}
		case QuestionsActions.QuestionLike: {
			const exists = state.questionIds.includes(action.payload);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === action.payload) {
						return { ...question, likes: question.likes + 1 };
					}
					return question;
				})
			};
		}
		case QuestionsActions.QuestionUnlike: {
			const exists = state.questionIds.includes(action.payload);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === action.payload) {
						return { ...question, likes: Math.max(question.likes - 1, 0) };
					}
					return question;
				})
			};
		}
		case QuestionsActions.QuestionAnswered: {
			const [questionId, answered] = action.payload;
			const exists = state.questionIds.includes(questionId);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === questionId) {
						return { ...question, answered };
					}
					return question;
				})
			};
		}
		case QuestionsActions.QuestionsBulkAnswered: {
			const [questionIds] = action.payload;

			return {
				...state,
				questions: state.questions.map(question => ({ ...question, answered: questionIds.includes(question.id) }))
			};
		}
		case QuestionsActions.QuestionComment: {
			const exists = state.questionIds.includes(action.payload.question_id);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === action.payload.question_id) {
						return { ...question, comments: question.comments + 1 };
					}
					return question;
				}),
				questionsComments: {
					...state.questionsComments,
					[action.payload.question_id]: [
						action.payload,
						...(state.questionsComments[action.payload.question_id] ?? []),
					]
				}
			};
		}
		case QuestionsActions.QuestionApprove: {
			const exists = state.questionIds.includes(action.payload);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.map(question => {
					if (question.id === action.payload) {
						return { ...question, approved: true, pending_moderator: false };
					}
					return question;
				})
			};
		}
		case QuestionsActions.QuestionUnapprove: {
			const exists = state.questionIds.includes(action.payload);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.filter(q => q.id !== action.payload).map(question => {
					if (question.id === action.payload) {
						return { ...question, approved: false, pending_moderator: false };
					}
					return question;
				})
			};
		}
		case QuestionsActions.QuestionDelete: {
			const exists = state.questionIds.includes(action.payload);

			// no need to update state if we do not have this question
			if (!exists) return state;

			return {
				...state,
				questions: state.questions.filter(question => question.id !== action.payload)
			};
		}

		case QuestionsActions.QuestionCommentDelete: {
			const qComments = { ...state.questionsComments };
			const [commentId, questionId] = action.payload;

			let existed = false;
			qComments[questionId] = qComments[questionId]?.filter(comment => {
				if (comment.id === commentId) {
					existed = true;
					return false;
				}

				return true;
			});

			return {
				...state,
				// we can get dup deletes from the websocket, so we only want to 
				// update the count if the comment existed
				questions: existed ? state.questions.map(question => {
					if (question.id === questionId) {
						return { ...question, comments: Math.max(0, question.comments - 1) };
					}

					return question;
				}) : state.questions,
				questionsComments: qComments
			};
		}

		case QuestionsActions.HandleBanned: {
			// first sort the banned ids by type
			const [questions, commentsMap] = sortBannedQuestions(action.payload);

			const filteredComments = { ...state.questionsComments };

			for (const [question, comment] of commentsMap) {
				filteredComments[question] = filteredComments[question]?.filter(c => c.id !== comment);
			}

			return {
				...state,
				questions: state.questions.filter(q => !questions.has(q.id)).map(q => {
					if (commentsMap.has(q.id)) {
						q.comments = Math.max(0, q.comments - 1);
					}

					return q;
				}),
				questionsComments: filteredComments
			};
		}

		default:
			return state;
	}
};

export const QuestionsContext = createContext<[QuestionsState, React.Dispatch<QuestionsAction>]>([initialState, () => { }]);
export const QuestionsProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(questionsReducer, initialState);

	return (
		<QuestionsContext.Provider value={[state, dispatch]}>
			{children}
		</QuestionsContext.Provider>
	);
};