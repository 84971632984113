import AppContext, { AppContexts } from "components/app-context";
import { useTranslate } from "i18n/useTranslationModules";
import { useContext, useMemo } from "react";
import { BrandliveEvent, ChannelCustomizedIcon, ExtendedHomepageMainNavItems, IHomepageMainNavItems, LanguagesAbbr, NavMainItems } from "types/working-model";
import { deregister } from "utils/deregister";
import { useScreenMediaQuery } from "utils/use-screen-media-query";
import CustomNavigationItems from "../marketing-page/navigation-v2/custom-navigation-items";
import { NavItem } from "../marketing-page/navigation/marketing-nav";
import { eventPath } from "../utils";
import { PATHNAMES as AdminPaths, PATHNAMES } from "utils/admin-routing-utils";
import { adminNavItemToRouteMap, NavbarStyles, useAuthorizedCustomNavItems, useCurrentPageState, useEnabledFeatures } from "./navbar.hooks";
import { directoryNavItem, extendedNavItems, getCustomSessionUrl, getNavItemLabel, isSessionsLabel } from "./navbar.utils";
import NavbarLanguageSelector from "./navbar-language-selector";
import Icon, { ICONS } from "@general-ui/icon";
import { useTypedSelector } from "store/reducers/use-typed-selector";

export const getBaseNavIcon = (name: NavMainItems | string, size = 24) => {
	switch (name) {
		case NavMainItems.Home:
			return <Icon name={ICONS.HOME} color="" size={size} />;
		case NavMainItems.Sessions:
		case NavMainItems.Session:
			return <Icon name={ICONS.TV_WATCH} color="" size={size} />;
		case NavMainItems.Directory:
		case NavMainItems.People:
			return <Icon name={ICONS.PEOPLE_NAV} color="" size={size} />;
		case NavMainItems.Leaderboard:
			return <Icon name={ICONS.LEADERBOARD_NAV} color="" size={size} />;
		case 'Logout': {
			return <Icon name={ICONS.LOG_OUT} color="" size={size} />;
		}
	}
};

export const getCustomNavIcon = (icons: ChannelCustomizedIcon[] | undefined, navItem: IHomepageMainNavItems | ExtendedHomepageMainNavItems) => {
	const customIcon = icons?.find(icon => icon.uuid === navItem.custom_icon_uuid);

	if (customIcon) {
		return <img src={customIcon.url} alt={navItem.name} />;
	}

	return getBaseNavIcon(navItem.name);
};

const isMainNavItem = (item: IHomepageMainNavItems | ExtendedHomepageMainNavItems): item is IHomepageMainNavItems => {
	return Object.keys(NavMainItems).includes(item.name);
};

const getAdminNavItemPath = (eventUuid: string | undefined, language: string | undefined) => (eventBundle: BrandliveEvent | undefined | null): Record<string, string> => {
	return {
		home: `${AdminPaths.Event.Home(eventUuid ?? '', language || '')}`,
		sessions: `${AdminPaths.Event.Sessions(eventUuid ?? '', language || '')}`,
		session: `${AdminPaths.Session.SessionLink(eventUuid ?? '', eventBundle?.sessions?.[0].uuid ?? '', language || '')}`,
		directory: `${AdminPaths.Event.DirectoryLink(eventUuid ?? '', language || '')}`,
		leaderboard: `${AdminPaths.Event.Leaderboard(eventUuid ?? '', language || '')}`,
		profile: `${AdminPaths.Event.SocialProfileLink(eventUuid ?? '', language || '')}`,
	};
};

const getMainNavItemPath =
	(eventName: string | undefined, uuid: string | undefined, language: string) =>
		(eventBundle: BrandliveEvent | undefined | null): Record<string, string> => {
			return {
				home: `/${eventPath(eventName, uuid)}/${language}/home`,
				sessions: `/${eventPath(eventName, uuid)}/${language}/sessions`,
				session: `/${eventPath(eventName, uuid)}/${language}/session/${eventBundle?.sessions?.[0]?.uuid}`,
				directory: `/${eventPath(eventName, uuid)}/${language}/directory`,
				leaderboard: `/${eventPath(eventName, uuid)}/${language}/leaderboard`,
				profile: `/${eventPath(eventName, uuid)}/${language}/profile`,
			};
		};

const getCustomPagePath = (eventName: string | undefined, uuid: string | undefined, language: string, isEditor = false) => (customPath: string) => {
	if (isEditor) {
		return PATHNAMES.Event.CustomPageLink(uuid ?? '', customPath, language);
	}

	return `/${eventPath(eventName, uuid)}/${language}/pages/${customPath}`;
};

type NavbarItemsProps = {
	event: BrandliveEvent | undefined | null;
	language: LanguagesAbbr;
	languages?: LanguagesAbbr[];
	styles: NavbarStyles;
	eventName: string | undefined;
	forceIcon?: boolean;
	fullWidth?: boolean;
};

const NavbarItems: React.FC<NavbarItemsProps> = ({
	event,
	language,
	styles,
	eventName,
	forceIcon,
	fullWidth,
	languages
}) => {
	const appContext = useContext(AppContext);
	const isEditor = appContext === AppContexts.admin;
	const allNavItems = [
		...event?.homepage?.event_main_nav_items ?? [],
		directoryNavItem
	];

	const pageState = useCurrentPageState(event);
	const enabledFeatures = useEnabledFeatures(event);

	const { t } = useTranslate(['homepage']);
	const { isLessThan1024 } = useScreenMediaQuery();
	const authorizedCustomNavItems = useAuthorizedCustomNavItems(event);
	const paths = isEditor ? getAdminNavItemPath(event?.uuid, language) : getMainNavItemPath(eventName, event?.uuid || '', language);
	const adminCustomIcons = useTypedSelector(state => state.CreateEventReducer.channelIcons);
	const eventCustomIcons = event?.channel_event_icons;
	const displayWatchTab = event?.settings?.display_sessions !== false;

	const handleLogout = () => {
		if (event && !isEditor) {
			deregister(event);
		}
	};

	const standardItems = () => allNavItems.reduce<JSX.Element[]>((items: JSX.Element[], item: IHomepageMainNavItems | ExtendedHomepageMainNavItems) => {
		if (item.name === NavMainItems.Leaderboard && !enabledFeatures.leaderboard) {
			return items;
		}

		if (item.name === NavMainItems.Directory && !enabledFeatures.directory) {
			return items;
		}

		// we originally wrote the navbar items as "Session" and "Sessions" which is how they're stored in the database
		// we only render it as "Watch" in the UI, so we need to check for "Session(s)"
		// it's not ideal, but it is what it is
		if (
			!displayWatchTab
			&& [NavMainItems.Session, NavMainItems.Sessions].includes(item.name as NavMainItems)
		) {
			return items;
		}

		const icon = (item.is_custom && event) ? getCustomNavIcon(isEditor ? adminCustomIcons : eventCustomIcons, item) : getBaseNavIcon(item.name);

		items.push(
			<NavItem
				key={item.uuid}
				isLessThan1024={isLessThan1024}
				path={paths(event)[item.name.toLowerCase()] || '#'}
				icon={icon}
				name={getNavItemLabel(item, t, language)}
				active={isEditor ? adminNavItemToRouteMap[item.name]?.includes(pageState.matchedPath) : pageState.routeMapToNavItem[pageState.matchedPath] === item.name}
				isSessionsView={pageState.isSessionPage && item.name === 'Sessions'}
				navItemColor={styles.navItemColor}
				navItemActiveAccent={styles.navItemActiveAccent}
				mainNavItem={isMainNavItem(item) ? item : undefined}
				custom_url={getCustomSessionUrl(event, item.name)}
				forceIcon={forceIcon}
				fullWidth={fullWidth}
			/>
		);

		return items;
	}, []);

	const itemCount = useMemo(() => {
		let len = standardItems.length;
		if (enabledFeatures.directory) len++;
		if (enabledFeatures.leaderboard) len++;
		return len;
	}, [standardItems.length, enabledFeatures.directory, enabledFeatures.leaderboard]);

	const customNavItems = () => [(
		<CustomNavigationItems
			key="others"
			generalNavItemsCount={itemCount}
			customNavItems={authorizedCustomNavItems}
			// previewLanguage={previewLanguage}
			navItemColor={styles.navItemColor}
			navItemActiveAccent={styles.navItemActiveAccent}
			getCustomPagePath={getCustomPagePath(eventName, event?.uuid || '', language, isEditor)}
			isDesktop={!isLessThan1024}
			forceIcon={forceIcon}
			fullWidth={fullWidth}
		/>
	)];

	const languageSelector = () => {
		if (event && languages?.length && languages.length > 1) {
			return (
				<NavbarLanguageSelector
					language={language}
					languages={languages}
					pageState={pageState}
					eventBundle={event}
				/>
			);
		}
	};

	const mobileOnlyNavItems = () => extendedNavItems.map((item) => {
		return (
			<NavItem
				key={item.name}
				isLessThan1024={isLessThan1024}
				path={paths(event)[item.name.toLowerCase()] || '#'}
				icon={getBaseNavIcon(item.name)}
				name={getNavItemLabel(item, t, language)}
				active={pageState.routeMapToNavItem[pageState.matchedPath] === item.name}
				isSessionsView={pageState.isSessionPage && isSessionsLabel(item.name)}
				navItemColor={styles.navItemColor}
				navItemActiveAccent={styles.navItemActiveAccent}
				onClick={item.uuid === "logout" ? handleLogout : undefined}
				forceIcon={forceIcon}
				fullWidth={fullWidth}
			/>
		);
	});

	return enabledFeatures.webinar ? (
		<>
			{languageSelector()}
			<div className="navbar__menu__mobile_only">
				{mobileOnlyNavItems()}
			</div>
		</>
	) : (
		<>
			{standardItems()}
			{customNavItems()}
			{languageSelector()}
			<div className="navbar__menu__mobile_only">
				{mobileOnlyNavItems()}
			</div>
		</>
	);
};

export default NavbarItems;
