import Icon, { ICONS } from '../icon';

import './rating-scale.scss';

interface IRatingScaleProps {
	onChange?: (value: number) => void;
	rating: number | null;
	unselectedIcon?: string;
	selectedIcon?: string;
	iconSize?: number;
	range?: number;
	spacing?: number;
	align?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
	readonly?: boolean;
}

export const RatingScale = ({
	onChange,
	rating,
	unselectedIcon = ICONS.STAR_OUTLINE,
	selectedIcon = ICONS.STAR,
	iconSize = 22,
	range = 5,
	spacing = 0,
	align = 'center',
	readonly = false,
}: IRatingScaleProps) => (
	<div className='rating-scale' style={{ justifyContent: align }}>
		{[...Array(range)].map((_, i) => {
			const selected = rating && rating > i;

			if (readonly) {
				return (
					<Icon
						key={i}
						name={selected ? selectedIcon : unselectedIcon}
						size={iconSize}
						color="" // Determined in CSS file
					/>
				);
			}

			return (
				<button key={i} onClick={onChange ? () => onChange(i + 1) : () => ''} style={{ marginLeft: i === 0 ? 0 : `${spacing}px` }}>
					<Icon
						name={selected ? selectedIcon : unselectedIcon}
						size={iconSize}
						color="" // Determined in CSS file
					/>
				</button>
			);
		})}
	</div>
);