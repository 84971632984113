import { useMemo } from "react";
import { intersection } from "underscore";

import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { EPermissions, FeatureFlagsEnum } from "../../../../types/working-model";
import { useLanguageParam } from "components/live-event/utils";

const canEditEvents = [EPermissions.Admin, EPermissions.Owner, EPermissions.Editor, EPermissions.GroupAdmin, EPermissions.Producer, EPermissions.Builder];

// Read only mode is only applicable to Translations V2
// Other versions, including v3 allow editing in any language
export const useReadonlyMode = (isSession = false) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const v3flag = featureFlags[FeatureFlagsEnum.translations_v3];
	const usingV2orV3 = Number(workingEvent?.settings.translation_version) >= 2;
	const language = useLanguageParam();

	const isReadOnly = useMemo(() => {
		if (v3flag) return false;

		if (!workingEvent || !language || !user) return false;

		// if this user is not of a user type that can edit events, they can only view them.
		if (!intersection(user.channels[user.active_channel], canEditEvents).length) {
			return true;
		}

		if (isSession) {
			if (!workingSession) return true;

			// if this event is using V2 translations
			// V3 supports inline editing
			return usingV2orV3
				// not the session default language
				&& workingSession.default_language !== language;
		}

		// if this event is using V2 translations
		// V3 supports inline editing
		return Boolean(usingV2orV3
			// event default language exists
			&& workingEvent.settings.i18n
			// event default language is not the selected language
			&& workingEvent.settings.i18n?.base !== language);
	}, [v3flag, workingEvent, language, user, isSession, usingV2orV3, workingSession]);

	return isReadOnly;
};