import NavigationDropdown from '../dropdown/navigation-dropdown';
import classNames from 'classnames';
import { useMemo } from 'react';

interface SmallSelectProps {
	options: {
		label: string;
		value: string | number;
	}[];
	selected: number | string;
	placeholder?: string | JSX.Element;
	onChange: (value: any) => void;
	className?: string;
	isArrow?: boolean;
}

export default function SmallSelect({
	options,
	selected,
	placeholder = '',
	onChange,
	className,
	isArrow,
}: SmallSelectProps): JSX.Element {
	const labelMap = useMemo(() => {
		return options.reduce((acc, cur) => {
			acc[String(cur.value)] = cur.label;
			return acc;
		}, {} as { [key: string]: string });
	}, [options]);

	const isSelected = !!labelMap[selected];

	const title = isSelected ? labelMap[selected] : placeholder;

	return (
		<NavigationDropdown title={title} className={classNames("small-select-container", className)} isArrow={isArrow}>
			{options.map(({ label, value }) => (
				<p
					key={value}
					className={classNames({ on: selected === value })}
					onClick={() => onChange(value)}
				>
					{label}
				</p>
			))}
		</NavigationDropdown>
	);
}
