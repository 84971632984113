import { Template } from "types/working-model";
import { IEmptyTheme } from "../components/admin/content/upload-scss-theme";
import { Get, Post, Put } from "./helpers";

export const GetTemplates = async (channel: number, token: string): Promise<Template[]> => {
	return Get(`/v3/admin/events/templates/${channel}`, token, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const UploadCustomScssTheme = async (token: string, channel: number, data: IEmptyTheme): Promise<any> => {
	return Post(`/v3/admin/custom-scss-theme/${channel}`, token, data, false, {}, false, true);
};

export const UpdateCustomScssTheme = async (token: string, channel: number, theme: number, data: IEmptyTheme): Promise<any> => {
	return Put(`/v3/admin/custom-scss-theme/${channel}/${theme}`, token, data, true, false, true);
};

export const DeleteCustomThemes = async (token: string, channel: number, themes: number[]): Promise<number[]> => {
	return Post(`/v3/admin/delete/custom-scss-theme/${channel}`, token, { themes }, false, {}, false, true);
};


export const DeleteCustomTemplates = async (token: string, channel: number, templates: string[]) => {
	return Post(`/v3/admin/delete/custom-event-template/${channel}`, token, { templates }, false, {}, false, true);
};
