import { useState, ChangeEvent, useEffect } from "react";
import { useDispatch } from 'react-redux';

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { UpdateAdminProfile } from "../../../connection/auth";
import { updateAdminProfile } from "../../../store/actions/authentication";
import { Profile, AdminProfileFields } from "../../../types/working-model";
import ModalComponent from "../../general-ui/modal/modal";
import TextInput from "../../general-ui/text-input/text";
import { adminProfileItems, EProfileItem } from "./admin-profile";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import { showAlert } from "../../general-ui/alert/alert-service";

interface EditProfileModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const EditProfileModal = ({ isOpen, onClose }: EditProfileModalProps): JSX.Element => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);

	// for now remove phone from the editable list because verification steps will be required to edit phone number
	const [editProfile, setEditProfile] = useState<Profile | undefined>(undefined);
	const [updating, setUpdating] = useState<boolean>(false);

	useEffect(() => {
		if (user) setEditProfile({ ...user.profile });
	}, [user, user?.profile]);

	const dispatch = useDispatch();

	if (!user || !token || !editProfile) return <></>;

	// hiding temp phone for now - will require additional verification steps to edit
	const hideFields: AdminProfileFields[] = [AdminProfileFields.temp_phone];
	const displayProfileItems: EProfileItem[] = adminProfileItems.filter(item => !hideFields.includes(item.field));

	const handleEdit = (e: ChangeEvent<HTMLInputElement>): void => {
		setEditProfile(prev => {
			if (!prev) return;
			return { ...prev, [e.target.id]: e.target.value };
		});
	};

	const handleSave = async (): Promise<void> => {
		setUpdating(true);
		try {
			dispatch(updateAdminProfile(editProfile));
			await UpdateAdminProfile(token, editProfile);
			onClose();
		} catch (e: any) {
			showAlert({
				message: "Error updating profile",
				duration: 4000,
				type: "error"
			});
			console.log(e);
		} finally {
			setUpdating(false);
		}
	};

	return (
		<ModalComponent
			title="Edit profile"
			open={isOpen}
			closeable={false}
			cancellable={true}
			onRequestClose={onClose}
			footer={
				<>
					<button onClick={onClose}>Cancel</button>
					<button onClick={handleSave} className="lemonade" disabled={updating}>
						{updating ? <WaitingIndicator /> : "Save"}
					</button>
				</>
			}
		>
			<div className="edit-modal">
				{displayProfileItems.map((item) => (
					<div key={item.field}>
						<TextInput
							label={item.title}
							id={item.field}
							defaultValue={editProfile[item.field] || ""}
							onChange={handleEdit}
						/>
					</div>
				))}
			</div>
		</ModalComponent>
	);
};