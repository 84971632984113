import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { debounce } from 'underscore';
import { useAppDispatch, useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { toggleNavigation } from '../../../../../store/actions/admin/content-actions';
import { ENavbarTypes } from '../../../../../types/working-model';
import { useGetEvent } from '../../../../../hooks/session.hooks';
import { useHistory } from 'react-router';
import AppContext from 'components/app-context';

type TUseCheckShowNavigation = () => boolean;

export const useCheckShowNavigation: TUseCheckShowNavigation = () => {
	const dispatch = useAppDispatch();
	const isNavigationVisible = useTypedSelector(state => state.ContentReducer.isNavigationVisible);
	const isNavigationOpen = useTypedSelector(state => state.ContentReducer.isNavigationOpen);
	const isNavigationOpenRef = useRef(isNavigationOpen);
	const history = useHistory();

	useEffect(() => {
		isNavigationOpenRef.current = isNavigationOpen;
	}, [isNavigationOpen]);

	useEffect(() => {
		const addCloseHandler = (e: MouseEvent) => {
			const path = (e as { path?: EventTarget[] }).path || e.composedPath();
			for (const el of path) {
				if (el instanceof HTMLElement) {
					// this either is the opener or is a child of the nav menu, do nothing
					if (el.classList?.contains?.('navigation-toggle')
						|| el.classList?.contains?.('navigation')
						|| el.classList?.contains?.('menu-button')) {
						return;
					}
				}
			}

			// otherwise, close
			if (isNavigationOpenRef.current) {
				dispatch(toggleNavigation(false));
			}
		};

		document.addEventListener('click', addCloseHandler);

		// always close on navigate
		const unlisten = history.listen(() => {
			if (isNavigationOpenRef.current) {
				dispatch(toggleNavigation(false));
			}
		});

		return () => {
			document.removeEventListener('click', addCloseHandler);
			unlisten();
		};
	}, [dispatch, history]);

	return isNavigationVisible;
};

const THROTTLE_TIME = 300;
const DEFAULT_ITEMS_COUNT = 6;

export const useGetNavItemsCount = (): number => {
	const event = useGetEvent();
	const appContext = useContext(AppContext);
	const isEditor = appContext === 'admin';
	const [itemsCount, setItemsCount] = useState<number>(DEFAULT_ITEMS_COUNT);

	const navbarType = event?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;
	const windowInnerHeight = window.innerHeight;

	useLayoutEffect(() => {
		if (isTopNavbar) return;

		if (isEditor) {
			setItemsCount(4);
			return;
		}

		const handleResize = debounce(() => {
			setItemsCount(Math.floor((window.innerHeight - 420) / 80));
		}, THROTTLE_TIME);

		if (document.readyState === 'complete') {
			handleResize();
		}

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isTopNavbar, windowInnerHeight, isEditor]);

	return itemsCount;
};
