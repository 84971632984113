import Dexie from 'dexie';

export type StoreItemInput = {
	uuid: string;
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	category?: string;
	expdate?: number;
};

export type StoreItem<T> = {
	uuid: string;
	type: string;
	data: T;
	key: string;
	timestamp: number;
	category?: string;
	expdate?: number;
}

type GetItemInput<T> = Pick<Partial<StoreItem<T>>, 'uuid' | 'type' | 'category'>;

const getKey = (uuid: string, type: string) => `${type}-${uuid}`;

const filterOutExpired = <T>(misc: Dexie.Table<StoreItem<T>, string>) => (item: StoreItem<T>) => {
	if (item?.expdate && item?.expdate < Date.now()) {
		misc.delete(item.key as string);
		return false;
	}

	return true;
};

/**
 * Example put:
 * 
 * {
 * 		uuid: 0000-0000-00000000-00000000,
 * 		type: 'event-name',
 * 		data: 'My Cool Event' OR { name: 'My Cool Event', favoriteColors: ['blue', 'green']},
 * 		category: 'event names'
 * }
 * 
 */

class Db extends Dexie {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private misc: Dexie.Table<StoreItem<any>, string>;

	constructor() {
		super("EventsStore");

		// these are indexed keys, not all keys available. Can use .get with these
		this.version(1).stores({
			misc: '&key, uuid, type, category'
		});

		this.misc = this.table('misc');
	}

	/**
	 * 
	 * @param type string type of item inserted
	 * @param uuid string uuid of session, event, user, whatever
	 * @param full boolean should return the full stored object including key and timestamp or just the stored data
	 * @returns Promise<object | string | undefined>
	 * 
	 * Overloaded to set return type as separate when full flag is used
	 */
	public async getItem<T>(type: string, uuid: string): Promise<T | undefined>
	public async getItem<T>(type: string, uuid: string, full?: boolean): Promise<StoreItem<T> | undefined> {
		const item = await this.misc.get(getKey(uuid, type));

		if (item?.expdate && item?.expdate < Date.now()) {
			this.misc.delete(item.key as string);
			return undefined;
		}

		if (full) {
			return item;
		}

		return item?.data;
	}

	/**
	 * 
	 * @param input GetItemInput search request 
	 * @param full boolean should return full object or just the data
	 * @returns Promise<object | string | undefined>
	 */
	getItems = async <T>({ uuid, type, category }: GetItemInput<T>, full = false): Promise<StoreItem<T>[] | T[]> => {
		const lookup: Record<string, string> = {};

		// keys in the where clause, if there, have to have a defined value. { type: 'foo', uuid: undefined } WILL throw
		// so only add the key if the data exists
		if (type) {
			lookup.type = type;
		}

		if (uuid) {
			lookup.uuid = uuid;
		}

		if (category) {
			lookup.category = category;
		}

		const items: StoreItem<T>[] = await this.misc.where(lookup).toArray();

		const nonExpired: StoreItem<T>[] = items.filter(filterOutExpired(this.misc));

		if (full) {
			return nonExpired;
		} else {
			return nonExpired.map(item => item.data) as T[];
		}
	};

	// ttl must contain one of the keys but not more than one - only seconds, minutes, hours, or days
	putItem = async (item: StoreItemInput, ttl?: Ttl) => {
		const { uuid, type, data, category } = item;
		return await this.misc.put({
			uuid,
			type,
			data,
			category,
			expdate: getTimestampFromTtl(ttl),
			key: getKey(uuid, type),
			timestamp: Date.now()
		}, getKey(uuid, type));
	};

	deleteItem = async (type: string, uuid: string) => {
		const item = await this.misc.get(getKey(uuid, type));
		if (item?.key) {
			await this.misc.delete(item.key as string);
		}
	};
}

export default new Db();

type TtlSeconds = {
	seconds: number;
	minutes?: never;
	hours?: never;
	days?: never;
}

type TtlMinutes = {
	seconds?: never;
	minutes: number;
	hours?: never;
	days?: never;
}

type TtlHours = {
	seconds?: never;
	minutes?: never;
	hours: number;
	days?: never;
}

type TtlDays = {
	seconds?: never;
	minutes?: never;
	hours?: never;
	days: number;
}

export type Ttl = TtlSeconds | TtlMinutes | TtlHours | TtlDays;

const getTimestampFromTtl = (ttl?: Ttl): number | undefined => {
	if (!ttl) return undefined;

	if (ttl.seconds) {
		return Date.now() + ttl.seconds * 1000;
	}

	if (ttl.minutes) {
		return Date.now() + ttl.minutes * 1000 * 60;
	}

	if (ttl.hours) {
		return Date.now() + ttl.hours * 1000 * 60 * 60;
	}

	if (ttl.days) {
		return Date.now() + ttl.days * 1000 * 60 * 60 * 24;
	}
};
