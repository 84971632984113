import { OptionalComponent } from "../../../../../../utils/optional-component";
import { useContext, useState, useEffect, useMemo } from 'react';
import { VideoStateContext } from '../../session-stream-provider';
import { VideoPlayerType } from "../types";
import * as Signals from '../../../../../../utils/event-emitter';

export enum CuesActions {
	addCue = 'add-cue',
	removeCue = 'remove-cue',
	replaceCues = 'replace-cues',
	updateCues = 'update-cues',
}

const Captions: React.FC<unknown> = () => {
	const {
		state: {
			showCaptions,
			selectedCaptions,
			playerType
		}
	} = useContext(VideoStateContext);
	const isInvalidPlayerType = [VideoPlayerType.embed, VideoPlayerType.hls].includes(playerType);
	const isPlaceholder = playerType === VideoPlayerType.placeholder;

	const [cues, setCues] = useState<VTTCue[]>([]);

	useEffect(() => {
		Signals.on(CuesActions.updateCues, ({ addCues }: { addCues: VTTCue[], removeCues: VTTCue[] }) => {
			setCues(addCues);
		});

		Signals.on(CuesActions.addCue, (cue: VTTCue) => {
			setCues(cues => [...cues, cue]);
		});

		Signals.on(CuesActions.removeCue, (cue: VTTCue) => {
			setCues(cues => cues.filter(c => c !== cue));
		});

		Signals.on(CuesActions.replaceCues, (cues: VTTCue[]) => {
			setCues(cues);
		});

		return () => {
			Signals.off(CuesActions.addCue);
			Signals.off(CuesActions.removeCue);
			Signals.off(CuesActions.replaceCues);
			Signals.off(CuesActions.updateCues);
		};
	}, []);

	return useMemo(() => (
		<OptionalComponent display={!isInvalidPlayerType && !isPlaceholder && showCaptions}>
			<div className="captions-container">
				<div className="captions-text">
					{/* using dangerously set inner html because webvtt cues can contain <v> voice tags and we do not want to render them as text. */}
					{cues.map(c => {
						const lines = c.text?.split('\n');
						return lines.map((line, index) => <div className="cue" key={`${c.id}+${index}`} dangerouslySetInnerHTML={{ __html: line }}></div>);
					})
					}
					{/* <span className="cue">{cues.length}</span> */}
					{/* <span className="cue" dangerouslySetInnerHTML={{ __html: cues.map(c => c.text).join(' ') }}></span> */}
				</div>
			</div>
		</OptionalComponent>
	), [cues, isInvalidPlayerType, isPlaceholder, showCaptions]);
};

export default Captions;