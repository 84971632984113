import { useMemo } from "react";
import { useRegistrationV2 } from "./channel.hooks";
import { useGetEvent } from "./session.hooks";
import { ERegistrationLayoutTypes, ERegistrationStyle } from "types/working-model";

export const useIsRegistrationV2 = (): boolean => {
	const event = useGetEvent();
	const registrationFlagV2 = useRegistrationV2();

	const isRegistrationV2 = true; //event?.settings?.is_registration_v2;

	return !!(isRegistrationV2 && registrationFlagV2);
};


export const useGetRegistrationLayout = (registrationStyle?: ERegistrationStyle) => {
	const isRegistrationV2 = useIsRegistrationV2();
	return useMemo(() => {
		// if reg v2, return the existing layout or aboveTheFoldR
		if (isRegistrationV2) {
			// if we have a valid v1 layout in the database and it's left, let's return aboveTheFoldL to keep it as close as possible
			// to the originally selected v1 layout
			if (registrationStyle?.layout === ERegistrationLayoutTypes.formLeft) {
				return ERegistrationLayoutTypes.aboveTheFoldL;
			}

			return registrationStyle?.layout || ERegistrationLayoutTypes.aboveTheFoldR;
		}

		// else if not reg v2
		// if we already have a valid v1 layout, return it
		if (registrationStyle?.layout && [ERegistrationLayoutTypes.formLeft, ERegistrationLayoutTypes.formRight].includes(registrationStyle.layout as ERegistrationLayoutTypes)) {
			return registrationStyle.layout;
		}

		// if we don't have a valid v1 layout, return formRight
		return ERegistrationLayoutTypes.formRight;
	}, [isRegistrationV2, registrationStyle?.layout]);
};
