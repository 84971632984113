import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';

import {
	addPageModule,
	setAddModuleModalOpen,
	setHomepageModules,
	toggleCustomPageModule,
	toggleHomepageModule,
	togglePostRegisterPreviewHomeModule,
	updateCustomPage,
	updateHomepageModule,
	updatePostRegisterHomeModules,
} from '../../../../../../store/actions/admin/create-event';
import { CallToAction, CustomPage, FeatureFlagsEnum, IPostRegisterHomeModule, LanguagesAbbr, ModuleMap, PageModule, PageModuleType } from '../../../../../../types/working-model';
import { PlaceholderSessionOption } from '../../../session/navigation/session-option';
import ModalComponent from '../../../../../general-ui/modal/modal';
import {
	GetDefaultPageModule,
} from '../../../../../../store/utils/create-event';
import { CreateEmptyPageModule, CreatePageModule, TogglePageModuleVisibility } from '../../../../../../connection/page-modules';
import CustomHTML from '../../../../../general-ui/edit-html/edit-html';
import EditModuleLayoutModal from './edit-module-layout-modal/edit-module-layout-modal';
import WaitingIndicator from '../../../../../general-ui/waiting-indicator/waiting-indicator';
import { UpdatePostRegisterPreviewModules } from '../../../../../../connection/events';
import CallToActionForm from '../../../../../general-ui/call-to-action/call-to-action';
import useCheckAndDuplicateModulesForPostRegisterHomePreview from '../../../../../../utils/use-check-and-duplicate-modules-for-post-register-home-preview';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import EditHeaderModal from '../../../session/editor/moduleControls/edit-header-modal';
import EditFooterModal from '../../../session/editor/moduleControls/edit-footer-modal';
import '../../../session/navigation/custom-options/custom-options.scss';
import { getHomepageDefaultLanguage } from '../../../../../../utils/get-language';
import BasicCard from '../../../../../general-ui/basic-card/basic-card';
import Switch from '../../../../../general-ui/switch/switch';
import { setScrollToModule } from '../../../../../../store/actions/admin/event-panel';
import { ParamsProps } from '../../../../../live-event/live-event';
import { useParams } from 'react-router';
import { UpdateCustomPage } from '../../../../../../connection/custom-page';
import { updateBlankModuleContent } from '../../editor/blank-section';
import { OptionalComponent } from '../../../../../../utils/optional-component';
import { shouldDisplayHomepage, shouldDisplayLandingPage } from '../../../../../../utils/utils';
import { getCurrentCustomPage } from '../../../../../../utils/admin-routing-utils';
import { getBlankModuleHtml } from '../../../../../../utils/html-utils';
import BasicInput from '../../../../../general-ui/text-input/basic-input';
import { Tooltip } from '../../../../../general-ui/tooltip/tooltip';
import { useReadonlyMode } from '../../../shared/use-readonly-mode';
import { useTypedSelector } from '../../../../../../store/reducers/use-typed-selector';
import TempCard from '../../../../../general-ui/temp-card/temp-card';

interface ISortableSessionModuleProps {
	id: string;
	module: PageModule;
	toggleModule: (module: PageModule) => () => Promise<void>;
	editModule: (module: PageModule) => void;
	showSettings: boolean;
	isLandingPage?: boolean;
	isRegistrationPage?: boolean;
}

const modulesWithoutSettings = [PageModuleType.accordion, PageModuleType.survey];

function SortableSessionModule(props: ISortableSessionModuleProps) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id: props.id });

	const isRegistrationPage = props.isRegistrationPage;
	const { module, toggleModule, editModule, showSettings } = props;

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	const dispatch = useDispatch();

	const scrollToModule = useCallback(() => {
		dispatch(setScrollToModule(module.id || 0));
	}, [dispatch, module.id]);

	const removeDragHandle = isRegistrationPage && module.type === PageModuleType.main_event_banner ? "hidden" : "visible";
	return (
		<div ref={setNodeRef} style={style} className="sortable-module" onClick={scrollToModule}>
			<BasicCard className={`session-option-container ${module.type === PageModuleType.main_event_banner ? "no-toggle" : ""}`}>
				<div className="title">
					<div className="drag-handle" {...attributes} {...listeners} style={{ visibility: removeDragHandle }}>
						<Icon name={ICONS.DRAG_HANDLE} size={12} color={COLORS.GRAY} />
					</div>
					{module?.content?.reference_title || ModuleMap[module.type]}
				</div>
				<div className="session-option-right">
					{showSettings ? (
						<button className="no-style options" onClick={() => editModule(module)}>
							<Icon name={ICONS.EDIT} size={20} color={'cyan'} />
						</button>
					) : (<></>)}
					<Switch
						value={ModuleMap[module.type]}
						onClick={toggleModule(module)}
						on={module.is_on}
					/>
				</div>
			</BasicCard>
		</div>
	);
}

export interface IStructureProps {
	postRegisterModules?: boolean;
	isLandingPage?: boolean;
	isCustomPage?: boolean;
	isHomepage?: boolean;
	isRegistrationPage?: boolean;
}

const Structure: React.FC<IStructureProps> = ({ postRegisterModules = false, isLandingPage, isCustomPage = false, isHomepage, isRegistrationPage }) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const addModuleModalOpen = useTypedSelector(state => state.CreateEventReducer.addModuleModalOpen);
	const token = useTypedSelector(state => state.AuthReducer.token);

	const dispatch = useDispatch();
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const [blankModalOpen, setBlankModalOpen] = useState(false);
	const [workingBlankModule, setWorkingBlankModule] = useState<PageModule | null>(null);
	const [activeType, setActiveType] = useState<PageModuleType | null>(null);
	const [openLayoutModal, setOpenLayoutModal] = useState<PageModuleType | null>(null);
	const [selectedModule, setSelectedModule] = useState<PageModule | null>(null);

	const [workingCTA, setWorkingCTA] = useState<CallToAction | null>(null);
	const [workingModule, setWorkingModule] = useState<PageModule | null>(null);
	const [openHeaderModal, setOpenHeaderModal] = useState(false);
	const [openFooterModal, setOpenFooterModal] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [addingBlankModule, setAddingBlankModule] = useState(false);

	const template = workingEvent?.template.template;
	const eventName = workingEvent?.name;
	const languages = workingEvent?.homepage?.languages;
	const displayLandingPage = shouldDisplayLandingPage(workingEvent);
	const displayHomepage = shouldDisplayHomepage(workingEvent);

	const displayModules = useMemo(() => {
		if (isRegistrationPage) {
			return true;
		}

		if (isLandingPage) {
			return displayLandingPage;
		}

		if (isHomepage) {
			return displayHomepage;
		}

		return true;
	}, [displayHomepage, displayLandingPage, isHomepage, isLandingPage, isRegistrationPage]);

	const { language, customPath } = useParams() as ParamsProps;
	const baseLanguage = getHomepageDefaultLanguage() as LanguagesAbbr;

	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const quizzesOn = featureFlags[FeatureFlagsEnum.quizzes];
	const accordionModuleOn = featureFlags[FeatureFlagsEnum.accordion_module]; 		// remove when no longer using the accordion module feature flag

	useCheckAndDuplicateModulesForPostRegisterHomePreview(postRegisterModules);

	const currentCustomPage = useMemo(
		() => getCurrentCustomPage(workingEvent?.custom_pages, customPath),
		[workingEvent?.custom_pages, customPath]
	);

	const workingBlankModuleHtml = getBlankModuleHtml(workingBlankModule, language);
	const [referenceTitle, setReferenceTitle] = useState(workingBlankModule?.content?.reference_title || '');

	useEffect(() => {
		if (blankModalOpen) {
			setReferenceTitle(workingBlankModule?.content?.reference_title || '');
		}
	}, [blankModalOpen, workingBlankModule?.content?.reference_title]);

	// if postRegisterModules, then use sort order (and is_on) from workingEvent.homepage.post_register_home_modules.
	// else use regular workingEvent.homepage.modules sort order

	const initModules = useCallback(() => {
		let initialModules: PageModule[] = [];
		if (!workingEvent?.homepage) return [];

		if (postRegisterModules) {
			// homepage
			initialModules = [...workingEvent.homepage.post_register_home_modules];
		} else if (isCustomPage) {
			initialModules = currentCustomPage?.modules?.length
				? [...currentCustomPage.modules]
				: [];
		} else {
			// landing page
			initialModules = [...workingEvent.homepage.modules];
		}

		if (!accordionModuleOn) {
			initialModules = initialModules.filter(module => module.type !== PageModuleType.accordion);
		}

		return initialModules;
	}, [workingEvent?.homepage, postRegisterModules, isCustomPage, accordionModuleOn, currentCustomPage?.modules]);

	const sortableContextModules = initModules().filter(elem => (isRegistrationPage && elem.type !== PageModuleType.main_event_banner) || (!isRegistrationPage));
	const sortableContextItems = sortableContextModules.map((module: PageModule) => String(module.id));
	const mainEventBanner = initModules().find(elem => elem.type === PageModuleType.main_event_banner);


	function toggleModule(module: PageModule) {
		return async () => {
			if (!module.id || !token) return;

			const updatedIsOn = !module.is_on;
			// update redux
			if (postRegisterModules) {
				dispatch(togglePostRegisterPreviewHomeModule(module.id, updatedIsOn));
			} else if (isCustomPage) {
				if (!currentCustomPage) return;
				dispatch(toggleCustomPageModule(currentCustomPage.custom_page, module.id, updatedIsOn));
			} else {
				dispatch(toggleHomepageModule(module));
			}
			try {
				// update database
				await TogglePageModuleVisibility(token, module.id, updatedIsOn);
			} catch (e: any) {
				console.error(e?.response || e);
			}
		};
	}

	async function handleDragEnd(event: DragEndEvent) {
		if (!event.over) return;
		const _modules = [...initModules()];
		const startIndex = _modules.findIndex((module: PageModule) => Number(module.id) === Number(event.active.id));
		const newIndex = _modules.findIndex((module: PageModule) => Number(module.id) === Number(event?.over?.id));

		const [module] = _modules.splice(startIndex, 1);
		_modules.splice(newIndex, 0, module);
		if (startIndex === newIndex) return;

		if (postRegisterModules) {
			const homepage = workingEvent?.homepage?.homepage;
			if (!token || !homepage) return;
			// update in redux
			const updatedPostRegisterModules = _modules.reduce((accum: IPostRegisterHomeModule[], module: PageModule) => {
				if (!module.id) return accum;
				return [
					...accum,
					{ is_on: module.is_on, module_id: module.id }
				];
			}, []);
			dispatch(updatePostRegisterHomeModules(_modules));
			// update in db
			try {
				await UpdatePostRegisterPreviewModules(homepage, token, updatedPostRegisterModules);
			} catch (e: any) {
				console.error(e?.response || e);
			}
		} else if (isCustomPage) {

			if (!currentCustomPage || !token) return;
			const updatedPage: CustomPage = {
				...currentCustomPage,
				modules: _modules
			};
			dispatch(updateCustomPage(updatedPage)); //update page in redux
			try {
				await UpdateCustomPage(updatedPage, token); //update individual page in db
			} catch (e: any) {
				console.error(e);
			}
		} else {
			dispatch(setHomepageModules(_modules));
		}
	}

	function addModule() {
		dispatch(setAddModuleModalOpen(true));
	}

	function closeModuleModal() {
		dispatch(setAddModuleModalOpen(false));
	}

	function selectModule(type: PageModuleType) {
		return () => {
			setActiveType(type);
		};
	}

	async function createBlank() {
		if (activeType) {
			const module = GetDefaultPageModule(activeType);
			module.content.translated_html = { base: '', changed: '' };
			closeModuleModal();
			setWorkingBlankModule(module);
			setBlankModalOpen(true);
		}
	}

	async function finishedEditingBlank() {
		if (!token) return;

		setAddingBlankModule(true);
		if (workingBlankModule) {
			if (workingBlankModule.id) {
				dispatch(
					updateHomepageModule({
						...workingBlankModule,
						content: {
							...workingBlankModule.content,
							reference_title: referenceTitle.trim(),
						}
					})
				);
			} else {
				const newModule = await CreateEmptyPageModule(token, {
					...workingBlankModule,
					content: {
						...workingBlankModule.content,
						reference_title: referenceTitle.trim(),
					},
				});

				// else update the working event modules as normal in database and redux:
				dispatch(addPageModule(newModule));

				if (isCustomPage) {
					if (!currentCustomPage || !token) return;

					const updatedModules = currentCustomPage?.modules ? [...currentCustomPage.modules, newModule] : [newModule];
					const updatedPage: CustomPage = {
						...currentCustomPage,
						modules: updatedModules
					};

					try {
						await UpdateCustomPage(updatedPage, token); //update individual page in db
					} catch (e: any) {
						setAddingBlankModule(false);
						console.error(e);
					}
				}
			}
		}
		setAddingBlankModule(false);
		closeBlankModule();
	}

	function updateWorkingBlankModule(code: string) {
		setWorkingBlankModule((module: PageModule | null) => {
			if (!module) return null;
			return updateBlankModuleContent(module, code, language, baseLanguage);
		});
	}

	const setCallToActionButton = useCallback(() => {
		if (!workingCTA || !workingModule) return;
		dispatch(
			updateHomepageModule({
				...workingModule,
				content: {
					...workingModule.content,
					callToAction: workingCTA
				}
			})
		);
	}, [workingCTA, dispatch, workingModule]);

	function closeBlankModule() {
		setBlankModalOpen(false);
	}

	async function createModule() {
		if (!token || !template || !eventName || !languages || !activeType) return;

		setIsUpdating(true);
		const baseLanguage = getHomepageDefaultLanguage();

		const newModule = await CreatePageModule(token, {
			template,
			eventName,
			languages,
			type: activeType,
			baseLanguage
		});

		if (isCustomPage) {
			try {
				if (!currentCustomPage) return;
				const updatedModules = currentCustomPage?.modules ? [...currentCustomPage.modules, newModule] : [newModule];
				const updatedPage: CustomPage = {
					...currentCustomPage,
					modules: updatedModules
				};
				await UpdateCustomPage(updatedPage, token); //update individual page in db
			} catch (e: any) {
				console.error(e);
			}
		}

		dispatch(addPageModule(newModule));
		setActiveType(null);
		setIsUpdating(false);
		closeModuleModal();
	}

	const editableContent = useMemo(() => {
		switch (workingModule?.type) {
			case PageModuleType.callout: {
				if (!workingCTA) return undefined;
				return {
					jsx: <CallToActionForm
						workingCTA={workingCTA}
						onWorkingCTAChange={setWorkingCTA}
						language={language}
						baseLanguage={baseLanguage}
					/>,
					onDone: setCallToActionButton
				};
			}
			default: return undefined;
		}
	}, [workingModule, workingCTA, setCallToActionButton, baseLanguage, language]);

	const isReadOnly = useReadonlyMode();

	const section = useMemo(() => {
		const buttonText = activeType && ModuleMap[activeType] ? `Pick ${ModuleMap[activeType]}` : 'Pick Layout';
		const buttonClick = activeType === PageModuleType.blank ? createBlank : createModule;

		return (
			<button disabled={!activeType} className="lemonade" onClick={buttonClick}>{buttonText}</button>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeType]);

	const handleReferenceTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setReferenceTitle(e.target.value);
	}, []);

	function editModule(module: PageModule) {
		//resetting these values so they don't carry over from a previous module
		setWorkingModule(null);
		setWorkingCTA(null);

		if (!module) return;
		if (module.type === PageModuleType.blank) {
			setWorkingBlankModule(module);
			setBlankModalOpen(true);
			setOpenLayoutModal(null);
		} else {
			setSelectedModule(module || 0);
			setOpenLayoutModal(module.type);

			if (module.type === PageModuleType.callout) {
				setWorkingModule(module);
				setWorkingCTA(module.content.callToAction);
			}
		}
	}

	return (
		initModules() ? (
			<div className="structure-container">
				<div className={classNames("structure-list", { 'read-only': isReadOnly })}>
					<OptionalComponent display={displayModules}>
						<>
							{/* display header/footer on landing page panel only */}
							{isLandingPage && <PlaceholderSessionOption label="Navigation" onEditIconClick={() => setOpenHeaderModal(true)} />}
							{mainEventBanner && isRegistrationPage &&
								<SortableSessionModule
									key={"main-event-banner"}
									module={mainEventBanner}
									toggleModule={toggleModule}
									editModule={editModule}
									id={String(mainEventBanner.id)}
									showSettings={!modulesWithoutSettings.includes(mainEventBanner.type)}
									isLandingPage={isLandingPage}
									isRegistrationPage={isRegistrationPage}
								/>
							}

							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragEnd={handleDragEnd}
								modifiers={[restrictToParentElement]}
								autoScroll={false}
							>
								<SortableContext
									items={sortableContextItems}
									strategy={verticalListSortingStrategy}
								>
									{sortableContextModules?.map?.((module: PageModule, index: number) => (
										<SortableSessionModule
											key={index}
											module={module}
											toggleModule={toggleModule}
											editModule={editModule}
											id={String(module.id)}
											showSettings={!modulesWithoutSettings.includes(module.type)}
											isLandingPage={isLandingPage}
											isRegistrationPage={isRegistrationPage}
										/>
									))}
								</SortableContext>
							</DndContext>
							{isLandingPage && <PlaceholderSessionOption label="Footer" onEditIconClick={() => setOpenFooterModal(true)} />}
							<button className="no-style session-option-container add-custom-section" onClick={addModule}>
								<Icon name={ICONS.ADD} size={16} color={COLORS.CYAN} />
								Custom Section
							</button>
						</>
					</OptionalComponent>
					<OptionalComponent display={!displayModules}>
						<TempCard message={"Turn on Homepage to customize registered user experience"} />
					</OptionalComponent>
				</div>

				<ModalComponent
					cancellable={true}
					closeable={false}
					open={addModuleModalOpen}
					onRequestClose={closeModuleModal}
					title={"Select Layout"}
				>
					<div className="custom-section-modal">
						<div className="custom-section-select">
							<ul>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.speakers })}><button onClick={selectModule(PageModuleType.speakers)} className="custom-section">Speakers</button></li>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.agenda })}><button onClick={selectModule(PageModuleType.agenda)} className="custom-section">{ModuleMap[PageModuleType.agenda]}</button></li>
								{quizzesOn && !isLandingPage && <li className={classNames("basic-card", { active: activeType === PageModuleType.survey })}><button onClick={selectModule(PageModuleType.survey)} className="custom-section">Surveys</button></li>}
								<li className={classNames("basic-card", { active: activeType === PageModuleType.about })}><button onClick={selectModule(PageModuleType.about)} className="custom-section">About</button></li>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.products })}><button onClick={selectModule(PageModuleType.products)} className="custom-section">Products</button></li>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.videos })}><button onClick={selectModule(PageModuleType.videos)} className="custom-section">Video</button></li>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.sponsors })}><button onClick={selectModule(PageModuleType.sponsors)} className="custom-section">Sponsors</button></li>
								<li className={classNames("basic-card", { active: activeType === PageModuleType.callout })}><button onClick={selectModule(PageModuleType.callout)} className="custom-section">Callout</button></li>
								{accordionModuleOn && <li className={classNames("basic-card", { active: activeType === PageModuleType.accordion })}><button onClick={selectModule(PageModuleType.accordion)} className="custom-section">Accordion</button></li>}
								<li className={classNames("basic-card", { active: activeType === PageModuleType.blank })}><button onClick={selectModule(PageModuleType.blank)} className="custom-section">+ Blank Section</button></li>
							</ul>
						</div>
						<div className="custom-section-layouts">
							<button onClick={closeModuleModal} style={{ marginRight: 16 }}>Cancel</button>
							{isUpdating ? <button className="lemonade" disabled><WaitingIndicator /></button> : section}
						</div>
					</div>
				</ModalComponent>

				<ModalComponent
					cancellable={true}
					closeable={false}
					open={blankModalOpen}
					onRequestClose={closeBlankModule}
					title="Blank Module"
					footer={(
						<>
							<button onClick={closeBlankModule} >Cancel</button>
							<button onClick={finishedEditingBlank} className="lemonade" disabled={addingBlankModule}>{addingBlankModule ? <WaitingIndicator /> : "Done"}</button>
						</>
					)}
				>
					<div>

						<div>
							<BasicInput
								autoFocus={false}
								focus={false}
								value={referenceTitle}
								label='Reference title'
								onChange={handleReferenceTitleChange}
								tooltip={() => (
									<Tooltip tooltip="This name is for your reference only. It will not display in the live event." position="bottom" textClassName="reference-title-tooltip">
										<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
									</Tooltip>
								)}
							/>
						</div>

						<label className="subtitle">Add custom HTML Code</label>
						<CustomHTML
							defaultValue={workingBlankModuleHtml ?? ''}
							onChange={updateWorkingBlankModule}
							maxHeight='50vh'
						/>
					</div>
				</ModalComponent>

				{
					selectedModule && (
						<EditModuleLayoutModal
							/* openLayoutModal is a number, and since zero is falsey we need to check for its existence as well */
							editModule={editableContent}
							open={!!openLayoutModal || openLayoutModal === PageModuleType.main_event_banner}
							setOpenLayoutModal={setOpenLayoutModal}
							pageModuleType={openLayoutModal}
							selectedModule={selectedModule?.id}
							title={ModuleMap[selectedModule.type]}
						/>
					)
				}
				<EditHeaderModal
					open={openHeaderModal}
					closeModal={() => setOpenHeaderModal(false)}
					baseLanguage={baseLanguage}
					language={language}
				/>
				<EditFooterModal
					open={openFooterModal}
					closeModal={() => setOpenFooterModal(false)}
					baseLanguage={baseLanguage}
					language={language}
				/>
			</div>
		) : (
			<WaitingIndicator />
		)
	);
};

export default Structure;
