import { FilteredImage, getFilteredImage } from "../../../../../../utils/image-filter";
import db from '../../../../../../utils/datastore/indexed-db';
import { resizedImage } from "../../../../../../utils/utils";

// polyfill requestidlecallback for safari
window.requestIdleCallback = window.requestIdleCallback || function(cb) {
	setTimeout(cb, 0);
};

// we want to precache the thumbnails for the video if possible
// this will help with the perceived performance of the video
class ImageLoader {
	doneUrls: Set<string> = new Set();
	loadingUrls: Set<string> = new Set();
	waitingUrls: Set<string> = new Set();
	imageCache: Cache | null = null;

	addUrls = (urls: string[]) => {
		for (const url of urls) {
			if (this.doneUrls.has(url)) continue;
			if (this.loadingUrls.has(url)) continue;

			this.waitingUrls.add(url);
		}

		this.beginLoad();
	};

	beginLoad = async () => {
		for (const url of this.waitingUrls) {
			this.loadingUrls.add(url);
			this.waitingUrls.delete(url);
			if (!this.doneUrls.has(url)) {
				await this.loadImage(url);
			}
		}
	};

	loadImage = async (url: string): Promise<boolean> => {
		return new Promise((resolve) => {
			// await an idle callback, meaning the JS thread won't bother requesting this cache until 
			// the browser hits a point where it has nothing else to do so we don't affect the user experience
			requestIdleCallback(() => {
				const img = new Image();
				img.onload = () => {
					this.doneUrls.add(url);
					this.loadingUrls.delete(url);
					resolve(true);
				};

				img.onerror = () => {
					this.loadingUrls.delete(url);
					resolve(false);
				};

				img.src = url;
			});
		});
	};
}

function loadImage(url: string) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = () => reject();
		img.src = url;
	});
}

// will iterate over the images and yield the images as they are loaded
// they should be loaded in order, and we will cache the responses as they arrive
// if all images are cached we don't want to yield them one-by-one super quickly because
// it will force a ton of fast re-renders, so yield them in blocks of cached images, then as they arrive
export async function* thumbnailGenerator(thumbnails: string[], uuid: string): AsyncGenerator<[string, number][]> {
	let index = 0;
	let images: [string, number][] = [];

	for (const thumbnail of thumbnails) {

		const newUrl = resizedImage(thumbnail, { width: 160, height: 160 * 0.5625 });

		const cached = await db.getItem<string>('thumbnail', thumbnail);

		if (cached) {
			// if precached, push into array
			images.push([cached, index]);
		} else {
			// await next url
			await loadImage(newUrl);

			images.push([newUrl, index]);

			// cache the image
			db.putItem({
				type: 'thumbnail',
				uuid: thumbnail,
				data: newUrl
			});

			// flush all images to this point react
			yield images;
			images = [];
		}

		index++;

		if (index === thumbnails.length) {
			// flush through any remaining images before ending
			yield images;
			return;
		}
	}
}

const imageLoader = new ImageLoader();
export { imageLoader };
export default imageLoader;