import { LoadActionCustomPoint } from '../../../connection/point-edits';
import { Action, AsyncAction } from '../../../types/actions';
import { LeaderboardMultiplier } from '../../../types/leaderboard';

export const UPDATE_ACTION_POINTS = 'UPDATE_ACTION_POINTS';
export function updateActionPoints(actions: LeaderboardMultiplier[]): Action {
	return {
		type: UPDATE_ACTION_POINTS,
		payload: actions
	};
}

export const LOAD_ACTION_POINTS = 'LOAD_ACTION_POINTS';
export function loadActionPoints(token: string, channel: number, eventId: number): AsyncAction {
	return {
		type: LOAD_ACTION_POINTS,
		promise: LoadActionCustomPoint(token, channel, eventId)
	};
}
