import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { flatten } from 'underscore';
import { Session } from '../../../types/working-model';
import Icon, { COLORS, ICONS } from '../icon';
import Checkbox from '../checkbox/checkbox';
import { Validation } from '../text-input/text';
import './session-select.scss';

interface Props {
	onChange: (sessionIds: number[]) => void;
	sessions: Session[];
	label?: string;
	placeholder?: string;
	preSelectedSessions?: number[];
	errorMessage?: string;
	valid?: Validation;
	ignoreInlineStyling?: boolean;
}

interface TagProps {
	session: Session;
	remove: (tag: number) => void;
}

function Tag(props: TagProps) {
	const { session, remove } = props;
	return (
		<div className="tag">
			<span>
				{session.title.base}{' '}
				<button
					className="no-style"
					onClick={() => remove(session.session)}
				>
					<Icon
						name={ICONS.CLOSE}
						size={10}
						color={COLORS.DEFAULT_GRAY}
					/>
				</button>
			</span>
		</div>
	);
}

interface SessionsByTrack {
	track: string;
	sessions: Session[];
}
//https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads/17/documents/e1s08b9f73/Screen_Shot_2020-11-02_at_9.04.59_AM.png
export default function SessionSelectInput(props: Props): JSX.Element {
	const {
		onChange,
		sessions,
		label = 'Gated Sessions *',
		placeholder,
		preSelectedSessions = [],
		errorMessage,
		valid,
		ignoreInlineStyling = false,
	} = props;

	const [selectedSessions, setSelectedSessions] = useState<number[]>(
		preSelectedSessions
	);
	const [open, setOpen] = useState(false);
	const [entered, setEntered] = useState(false);
	const sessionSelector = useRef<any>(null);
	// const [left] = useState(40);
	// const [top, setTop] = useState(40);
	const [width, setWidth] = useState(100);
	const selector = useRef<HTMLDivElement | null>(null);

	//they default to open, and it's harder to set this with all tracks already set up in it, so we're going to mark which ones are collapsed, not open
	// const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
	useEffect(() => setSelectedSessions(preSelectedSessions), [preSelectedSessions]);
	const sessionsByTag: SessionsByTrack[] = useMemo(() => {
		//pull all tracks from all sessions, flatten them into one array,
		let allTracks = Array.from(
			new Set(
				flatten(sessions.map((session: Session) => session.tracks))
			).values()
		).sort();
		allTracks = [...allTracks, 'Misc'];

		const sortedSessions = allTracks.map((track: string | null) => ({
			track: track ?? '',
			sessions: sessions.filter((session: Session) => {
				if (track === 'Misc') {
					return !session.tracks || session.tracks.length === 0;
				}
				return session.tracks?.includes(track ?? '');
			}),
		}));

		return sortedSessions.filter((value: any) => value.sessions.length > 0);
	}, [sessions]);

	const selectedSessionArr: Session[] = useMemo(() => {
		return sessions.filter((session: Session) =>
			selectedSessions.includes(session.session)
		);
	}, [selectedSessions, sessions]);

	function removeSession(session: number) {
		setSelectedSessions((sessions: number[]) =>
			sessions.filter((_session: number) => _session !== session)
		);
	}

	function addSession(session: number) {
		//make set to ensure that the tags are always unique - will prevent any duplicates
		const sessionsSet = new Set(selectedSessions);
		sessionsSet.add(session);

		setSelectedSessions(Array.from(sessionsSet.values()));
	}

	function toggleSession(session: string | number, on: boolean) {
		if (on) { addSession(session as number); }
		else { removeSession(session as number); }
	}

	function toggleTrack(track: string | number, on: boolean) {
		// if entire track has been deselected, toggle off all tracks in that session
		if (!on) {
			for (const sess of sessionsByTag) {
				if (sess.track === track) {
					for (const session of sess.sessions) {
						removeSession(session.session);
					}
				}
			}
		} else {
			if (track === 'Misc') { // sessions that don't belong to a track
				const miscTrack = sessionsByTag.find(session => session.track === 'Misc');
				if (!miscTrack) return;
				const miscSessions = miscTrack.sessions.map(track => track.session);
				const anyMiscSessionsSelected = selectedSessions.find(selectedSession => miscSessions.includes(selectedSession));
				if (anyMiscSessionsSelected) {
					// deselect all Misc sessions
					setSelectedSessions(selectedSessions.filter(selectedSession => !miscSessions.includes(selectedSession)));
				} else {
					setSelectedSessions([...selectedSessions, ...miscSessions]);
				}
			} else {
				// if entire track has been selected, toggle on all sessions in that track
				const sessionsWithTrack = sessions
					.filter((session: Session) => {
						return session.tracks?.includes(track as string);
					})
					.map((session: Session) => session.session);

				setSelectedSessions((selectedSessions: number[]) =>
					Array.from(
						new Set([
							...selectedSessions,
							...sessionsWithTrack,
						]).values()
					)
				);
			}
		}
	}

	function selectAllSessions(_: string | number, on: boolean) {
		if (on) {
			setSelectedSessions(
				sessions.map((session: Session) => session.session)
			);
		} else {
			setSelectedSessions([]);
		}
	}

	function handleFocus() {
		setOpen(true);
	}

	useEffect(() => {
		setEntered(selectedSessions.length > 0);
		onChange(selectedSessions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedSessions]);

	function handleClick(e: any) {
		const path = e.path || e.composedPath();
		for (const item of path) {
			if (sessionSelector.current === item) { return; }
		}
		setOpen(false);
	}

	useEffect(() => {
		if (open) { window.addEventListener('click', handleClick); }

		return () => window.removeEventListener('click', handleClick);
	}, [open]);

	useEffect(() => {
		const rect = selector.current?.getBoundingClientRect();

		if (rect) {
			// setLeft(rect.left ?? 0);
			// setTop((rect.top ?? 0) + 80);
			setWidth(rect.width ?? 0);
		}
	}, [open]);

	const allPartial = selectedSessions.length > 0;

	const allChecked = useMemo(() => {
		return sessions.every((session: Session) =>
			selectedSessions.includes(session.session)
		);
	}, [selectedSessions, sessions]);

	const someChecked = useMemo(() => {
		return sessionsByTag
			.filter((sessionsByTag: SessionsByTrack) =>
				sessionsByTag.sessions.some((session: Session) =>
					selectedSessions.includes(session.session)
				)
			)
			.map((sessionsByTag: SessionsByTrack) => sessionsByTag.track);
	}, [selectedSessions, sessionsByTag]);

	const allCheckedForTracks = useMemo(() => {
		return sessionsByTag
			.filter((sessionsByTag: SessionsByTrack) =>
				sessionsByTag.sessions.every((session: Session) =>
					selectedSessions.includes(session.session)
				)
			)
			.map((sessionsByTag: SessionsByTrack) => sessionsByTag.track);
	}, [selectedSessions, sessionsByTag]);

	return (
		<div
			ref={selector}
			className={classNames(
				'field-group tag-select-container session-select',
				{ entered, scrollableParent: ignoreInlineStyling },
				valid
			)}
		>
			<label>{label}</label>
			<div
				className={classNames("tag-select", { error: errorMessage })}
				onClick={handleFocus}
				ref={sessionSelector}
			>
				{selectedSessionArr.length > 0 ? (
					selectedSessionArr.map((session: Session) => (
						<Tag
							session={session}
							key={session.session}
							remove={removeSession}
						/>
					))
				) : (
					<>
						{placeholder && (
							<div className="placeholder">{placeholder}</div>
						)}
					</>
				)}
			</div>
			<div
				className={classNames('tag-select-dropdown session-select', { open })}
				style={{ ...(ignoreInlineStyling ? {} : { width }) }}
			>
				<div className="session-group-item">
					<Checkbox
						checked={allChecked}
						onChange={selectAllSessions}
						value={'all'}
						partial={allPartial}
					/>
					<label>All sessions</label>
				</div>
				{sessionsByTag.map((sessionByTag: SessionsByTrack) => {
					return (
						<div key={sessionByTag.track} className="session-group">
							<div className="session-group-item">
								<Checkbox
									checked={allCheckedForTracks.includes(
										sessionByTag.track
									)}
									value={sessionByTag.track}
									onChange={toggleTrack}
									partial={someChecked.includes(
										sessionByTag.track
									)}
								/>
								<label>{sessionByTag.track}</label>
							</div>
							{sessionByTag.sessions.map((session: Session) => (
								<div
									key={session.session}
									className="session-group-item inner"
								>
									<Checkbox
										checked={selectedSessions.includes(
											session.session
										)}
										onChange={toggleSession}
										value={session.session}
									/>
									<label>{session.title.base}</label>
								</div>
							))}
						</div>
					);
				})}
			</div>
			{errorMessage && <label className="error-message">{errorMessage}</label>}
		</div>
	);
}