import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import HtmlToReact, { getCleanedHtmlText } from "../../../../../general-ui/html-to-react/html-to-react";
import { getTextWidths } from "../../../hooks/session-hooks";


import './revealable-text.scss';
type RevealableTextProps = {
	text: string,
	revealed: boolean,
	className?: string,
	onExpand?: () => void,
	maxLines?: number,
	onClickShowAll?: boolean,
};

const RevealableText: React.FC<RevealableTextProps> = ({ text, className, revealed, onExpand, maxLines = 2, onClickShowAll }) => {
	const { t } = useTranslate(["homepage"]);
	const container = useRef<HTMLDivElement | null>(null);
	const [visible, setVisible] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const timer = useRef<NodeJS.Timeout | null>(null);
	const [showAll, setShowAll] = useState(false);


	useEffect(() => {
		if (container.current) {
			const checkSize = () => {
				if (!container.current) return;
				const wordWidths = getTextWidths(getCleanedHtmlText(text), container.current);
				const numWords = wordWidths.length;
				const box = container.current.getBoundingClientRect();

				// if the box has no size that means it's not visible (likely from 0 opacity), so we need to wait until it is
				if (!box.width) {
					timer.current = setTimeout(() => {
						checkSize();
					}, 200);
				}

				const total = wordWidths.reduce((acc, curr) => acc + curr, 0);
				const numLines = Math.ceil(total / box.width);
				const extraPadding = 150;

				const [, amountVisible] = wordWidths.reduce(([width, num], curr) => {
					if (!showAll && width + curr + extraPadding > Math.ceil(box.width * maxLines)) {
						return [width, num];
					} else {
						return [width + curr, num + 1];
					}
				}, [0, 0]);

				const numVisibleWords = amountVisible;
				setShowMore(numLines >= maxLines && numVisibleWords < numWords);
				setVisible(true);
			};

			timer.current = setTimeout(() => {
				checkSize();
			});
		}

		return () => {
			if (timer.current) clearTimeout(timer.current);
		};
	}, [text, maxLines, showAll]);

	const handleClick = () => {
		if (onClickShowAll) {
			setShowAll(true);
		} else {
			onExpand?.();
		}
	};

	return (
		<div style={{
			visibility: visible ? 'visible' : 'hidden',
		}}
			className={classNames(className, "revealable-text", !showAll ? `maxlines-${maxLines}` : '', { revealed, 'show-fade': showMore })}>

			<section
				ref={container}>
				<HtmlToReact
					html={text}
					tailElement={showMore ? (
						<>
							<button className="expand-description" onClick={handleClick}>
								{t('homepage:Show more')}
							</button>
						</>
					) : undefined}
				/>
			</section>
		</div>
	);
};
export default RevealableText;
