import { forwardRef, memo, PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type IPortalProps = PropsWithChildren<{
	className?: string;
}>

const Portal = forwardRef<HTMLDivElement, IPortalProps>(({ children, className = 'custom-portal' }, ref) => {
	const [container] = useState(document.createElement('div')); //TODO: investigate possible refactor replacing useState with useRef

	container.classList.add(...className.split(" "));

	useEffect(() => {
		document.body.appendChild(container);

		if (ref) {
			if (typeof ref === 'function') {
				ref(container);
			} else {
				ref.current = container;
			}
		}
		return () => {
			document.body.removeChild(container);
		};
	}, [container, ref]);

	// ensure container gets the className prop
	// this is necessary because the container won't re-render with the className prop
	// so we need to manually set it whenever the className prop changes
	useEffect(() => {
		if (container) {
			container.className = className || '';
		}
		// only re-run when className changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [className]);

	return ReactDOM.createPortal(children, container);
});

export default Portal;
