import { isObject } from "underscore";
import { TranslateString } from "../types/working-model";

export const isTranslateString = (obj: unknown): obj is TranslateString => {
	if (isObject(obj) && 'base' in obj && typeof obj.base === 'string') {
		return true;
	} else {
		return false;
	}
};

export const tString = (str: TranslateString, language: string) => {
	return str[language] || str.base;
};