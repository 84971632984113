import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';

import {
	GET_MAGIC_LINKS_FOR_EVENT,
	GENERATE_MAGIC_LINKS_FOR_EVENT,
	GENERATE_MAGIC_LINKS_FOR_LIST,
	INVALIDATE_MAGIC_LINKS_FOR_EVENT
} from '../../actions/admin/magic-links';

interface MagicLink {
	members: [];
}


export interface MagicLinksState {
	magicLinksForEvent: MagicLink;
	loadingMagicLinksForEvent: boolean;
	loadingUpdateMagicLinksForEvent: boolean;
}

const initialState: MagicLinksState = {
	loadingMagicLinksForEvent: true,
	loadingUpdateMagicLinksForEvent: false,
	magicLinksForEvent: { members: [] },
};

export default function MagicLinkReducer(
	state: MagicLinksState = initialState,
	action: Action
): MagicLinksState {
	switch (action.type) {
		case GET_MAGIC_LINKS_FOR_EVENT: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingMagicLinksForEvent: true
				}),
				success: (state) => ({
					...state,
					magicLinksForEvent: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingMagicLinksForEvent: false
				}),
				failure: (state) => ({
					...state,
					magicLinksForEvent: initialState.magicLinksForEvent,
				})
			});
		}

		case GENERATE_MAGIC_LINKS_FOR_LIST:
		case GENERATE_MAGIC_LINKS_FOR_EVENT: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingUpdateMagicLinksForEvent: true
				}),
				success: (state) => ({
					...state,
					magicLinksForEvent: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingUpdateMagicLinksForEvent: false
				}),
				failure: (state) => ({
					...state,
					magicLinksForEvent: initialState.magicLinksForEvent,
				})
			});
		}

		case INVALIDATE_MAGIC_LINKS_FOR_EVENT: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingUpdateMagicLinksForEvent: true
				}),
				success: (state) => ({
					...state,
					magicLinksForEvent: action.payload,
				}),
				finish: (state) => ({
					...state,
					loadingUpdateMagicLinksForEvent: false
				}),
				failure: (state) => ({
					...state,
					magicLinksForEvent: initialState.magicLinksForEvent,
				})
			});
		}



		default:
			return state;
	}
}
