import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { CustomReaction } from "../../../types/working-model";
import {
	LOAD_CUSTOM_REACTIONS,
	ADD_CUSTOM_REACTION
} from "../../actions/admin/reactions";

export interface ReactionsState {
	reactions: CustomReaction[];
	loadingReactions: boolean;
	reactionsError: string;
	errorLoadingReactions: string;
}

const initialState: ReactionsState = {
	errorLoadingReactions: "",
	loadingReactions: false,
	reactions: [],
	reactionsError: "",
};

export default function ReactionReducer(
	state: ReactionsState = initialState,
	action: Action
): ReactionsState {
	switch (action.type) {
		case LOAD_CUSTOM_REACTIONS: {
			return handle(state, action, {
				success: state => {
					return { ...state, reactions: action.payload };
				}
			});
		}

		case ADD_CUSTOM_REACTION: {
			return handle(state, action, {
				success: state => {
					return { ...state, reactions: [...state.reactions, action.payload] };
				}
			});
		}

		default:
			return state;
	}
}
