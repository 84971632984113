import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Icon, { COLORS, ICONS } from '@general-ui/icon';
import { Tooltip } from '@general-ui/tooltip/tooltip';
import { setRegistrationFeature } from '../../../../../../../../store/actions/admin/create-event';
import { useTypedSelector } from '../../../../../../../../store/reducers/use-typed-selector';
import { RegistrationStepType, GateTypes, SessionTypesEnum } from '../../../../../../../../types/working-model';
import { showAlert } from '../../../../../../../general-ui/alert/alert-service';
import Switch from '../../../../../../../general-ui/switch/switch';
import PanelBody from '../../panel-body';
import LimitRegistrationEmails from '../../registration/limit-registration-emails';
import TooltipPortal from '@general-ui/tooltip/tooltip-portal/tooltip-portal';
import { TooltipPortalPosition, TooltipPortalContentDirection } from '@general-ui/tooltip/tooltip-portal/tooltip-portal-types';

import "./email-confirmation.scss";

const EmailConfirmation: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer?.workingEvent);

	const dispatch = useDispatch();

	const registration_settings = workingEvent?.registration_settings;
	const ticketingOn = workingEvent?.registration_steps?.find(step => step.type === RegistrationStepType.ticketing)?.isOn;
	const passwordGatingEnabled = useMemo(() => !!workingEvent?.registration_settings?.password_gating_enabled, [workingEvent?.registration_settings?.password_gating_enabled]);

	const [emailConfirmation, setEmailConfirmation] = useState(registration_settings?.registrationConfirmation);

	const hasFiresideSession = useMemo(() => {
		const firesideSession = workingEvent?.sessions?.find(session => session.session_type === SessionTypesEnum.fireside);
		return !!firesideSession;
	}, [workingEvent?.sessions]);

	function handleFeatureChange(value: string, on: boolean) {
		// Send Email Confirmation is turned on whenever registrationConfirmation is toggled on.
		if (value === 'registrationConfirmation') {
			if (on) {
				setEmailConfirmation(true);
				dispatch(setRegistrationFeature('sendEmailConfirmation', on));
			} else {
				setEmailConfirmation(false);
			}
		}

		// Don't allow toggling send email confirmation off when email Confirmation is on or if ticketing is on
		if (value === 'sendEmailConfirmation' && !on && (emailConfirmation || ticketingOn)) {
			showAlert({
				message: 'Emails are required for other registration functions currently in use.',
				duration: 3000,
				type: 'error',
			});

			return;
		}

		dispatch(setRegistrationFeature(value, on));
	}

	return (
		<div className="settings-panel-container">
			<PanelBody>
				<div className="settings-card">
					<label>
						Send confirmation
					</label>
					<TooltipPortal
						content={
							<>
								{
									passwordGatingEnabled || registration_settings?.registrationConfirmation
										? <div>Confirmation emails are required while:</div>
										: <div>Confirmation emails will always be sent to fireside session hosts.</div>
								}
								<ul className="supress-email-duplicate-email-switch-tooltip-list">
									{passwordGatingEnabled ? <li>Password login is on.</li> : null}
									{registration_settings?.registrationConfirmation ? <li>Require confirmation is on.</li> : null}
								</ul>
							</>
						}
						position={TooltipPortalPosition.TOP}
						contentDirection={TooltipPortalContentDirection.CENTER}
						disabled={!registration_settings?.registrationConfirmation && !passwordGatingEnabled && !hasFiresideSession}
					>
						<Switch
							value="sendEmailConfirmation"
							on={registration_settings?.sendEmailConfirmation ?? false}
							onClick={handleFeatureChange}
							disabled={registration_settings?.registrationConfirmation || passwordGatingEnabled}
						/>
					</TooltipPortal>
				</div>

				<div className="settings-card">
					<label>
						Require confirmation
					</label>
					<Switch
						value="registrationConfirmation"
						on={registration_settings?.registrationConfirmation ?? false}
						onClick={handleFeatureChange}
					/>
				</div>

				<div className="settings-card">
					<label>
						Suppress duplicates
						<Tooltip tooltip="When disabled, registrants will receive a new confirmation email each time they submit the registration form.">
							<Icon name={ICONS.INFO_OUTLINE} size={14} color={COLORS.CYAN} />
						</Tooltip>
					</label>
					<TooltipPortal
						content={
							<>
								<div>Cannot suppress duplicate emails while:</div>
								<ul className="supress-email-duplicate-email-switch-tooltip-list">
									{!registration_settings?.sendEmailConfirmation ? <li>Send Email confirmation is off.</li> : null}
									{passwordGatingEnabled ? <li>Password login is on.</li> : null}
								</ul>
							</>
						}
						position={TooltipPortalPosition.TOP}
						contentDirection={TooltipPortalContentDirection.CENTER}
						disabled={registration_settings?.sendEmailConfirmation && !passwordGatingEnabled}
					>
						<Switch
							value="skipEmailOnDuplicateRegistration"
							on={registration_settings?.skipEmailOnDuplicateRegistration ?? true}
							onClick={handleFeatureChange}
							disabled={!registration_settings?.sendEmailConfirmation || passwordGatingEnabled}
						/>
					</TooltipPortal>
				</div>

				<LimitRegistrationEmails />
			</PanelBody>
		</div>
	);
};

export default EmailConfirmation;
