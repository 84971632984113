import { CreateProduct, Product } from "../types/working-model";
import { Delete, Get, GetJson, Post, Put } from "./helpers";

export function GetProducts(
	token: string,
	channel: number
): Promise<Product[]> {
	return Get(`/v3/admin/channel/products/${channel}`, token);
}

export function InsertProduct(
	token: string,
	product: CreateProduct,
	languages?: string[]
): Promise<Product> {
	return Post(`/v3/admin/channel/products/`, token, { product, languages });
}

export function UpdateProduct(
	token: string,
	product: Product,
	languages?: string[]
): Promise<Product> {
	return Put(
		`/v3/admin/channel/products/`,
		token,
		{ product, languages },
		true
	);
}

export function UpdateProductTemplate(
	token: string,
	product: Product,
	channel: number,
	globalApply = false
): Promise<{ products: Product[] }> {
	return Put(`/v3/admin/channel/products/template/${channel}`, token, { product, globalApply }, true, false, true);
}

export function DeleteProducts(
	productIDs: number[],
	token: string,
	channel: number
): any {
	return Delete(
		`/v3/admin/channel/products`,
		token,
		{ productIDs, channel },
		true
	);
}

type CreateChildrenResponse = Promise<{ products: Product[] }>;
export function CreateChildren(
	token: string,
	module_ids: number[],
	channel: number,
	event_id: number
): CreateChildrenResponse {
	return Post(
		`/v3/admin/products/child/${channel}`,
		token,
		{ module_ids, event_id }
	);
}

type CreateProductResponse = Promise<Product>;
export function CreateProductFromEvent(
	token: string,
	product: CreateProduct,
	languages: string[],
	channel: number
): CreateProductResponse {
	return Post(
		`/v3/admin/products/create/${channel}`,
		token,
		{
			product,
			languages
		}
	);
}

type DeleteChildrenResponse = Promise<{ products: Product[] }>;
export function DeleteChildren(
	token: string,
	module_ids: number[],
	event_id: number
): DeleteChildrenResponse {
	return Delete(
		`/v3/admin/products/child`,
		token,
		{ module_ids, event_id },
		true
	);
}

export type ProductWithEventName = (Product & { event_name: string });
type GetProductsWithEventNameResponse = Promise<{ products: ProductWithEventName[] }>;
export function GetProductsWithEventName(
	token: string,
	channel: number,
): GetProductsWithEventNameResponse {
	return Get(
		`/v3/admin/channel/products/templates/${channel}`,
		token
	);
}

type PromoteChildProductResponse = Promise<{ products: Product[] }>;
export function PromoteChildProduct(
	token: string,
	channel: number,
	module_ids: number[]
): PromoteChildProductResponse {
	return Put(
		`/v3/admin/channel/products/template/promote-orphaned-child/${channel}`,
		token,
		{ module_ids },
		true
	);
}

export function GetAllProductAttributesForChannel(
	token: string,
	channelUuid: string
) {
	return GetJson<string[]>({
		path: `/v3/admin/channel/products/attributes/${channelUuid}`,
		token
	});
}