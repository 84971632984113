import React, { useEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { on, off } from '../../../../utils/event-emitter';
import { ReactionConfig, ReactionConfigWithCount, ReactionSettings } from '../../../../types/working-model';
import { reactionIconMap } from '../../../../utils/reactions';
import hexToRgbA from '../../../../utils/hexToRGBA';
import { useSocket } from '../../../../connection/socket';

import '../../../../scss/live-event/base/reactions/floating-reaction.scss';

interface FloatingReactionProps {
	chatOverlayEnabled: boolean;
	sessionUuid: string;
	reactionsToUse?: ReactionSettings;
	enabledReactions: ReactionConfig[];
}

type IconImage = {
	icon: string;
	url: never;
}

type UploadedImage = {
	url: string;
	icon: never;
}

class ImageMemo {
	images: Map<string, HTMLImageElement> = new Map();
	getImage = (img: IconImage | UploadedImage): Promise<HTMLImageElement> => {
		return new Promise((resolve, reject) => {
			// check if the image is already loaded - if it is, just resolve it immediately
			// otherwise we have to wait for it to be loaded before we can render it
			const prev = this.images.get(img.icon || img.url);
			if (prev) {
				return resolve(prev);
			}

			// depending on image type, load image element internally or remotely
			if (img.icon) {

				// create an image element, size it, and resolve that the image is ready
				const el = document.createElement('img');
				el.src = reactionIconMap[img.icon];
				el.height = 30;
				el.width = 30;
				el.onload = () => {
					this.images.set(img.icon, el);
					resolve(el);
				};
				el.onerror = (e: any) => {
					reject(e);
				};
			} else {
				const el = document.createElement('img');
				el.src = img.url;
				el.height = 30;
				el.width = 30;
				el.onload = () => {
					this.images.set(img.url, el);
					resolve(el);
				};
				el.onerror = (e: any) => {
					reject(e);
				};
			}
		});
	};
}

const imageMemo = new ImageMemo();

class Emoji {
	xPos = 0;
	yPos = 0;
	emoji: any = {};
	loaded = false;
	ctx: CanvasRenderingContext2D;
	isEnded = false;
	id: string;
	height: number;
	width: number;
	ended: (id: string) => void;
	constructor(reaction: ReactionConfig, ctx: CanvasRenderingContext2D, id: string, ended: (id: string) => void, height: number, width: number) {
		this.ended = ended;
		this.id = id;
		this.ctx = ctx;
		this.height = height;
		this.width = width;
		this.xPos = (width - 120) + Math.random() * (65);
		this.yPos = height - 100 - (Math.random() * 100);
		this.emoji = {
			loaded: false,
			el: null,
			ref: reaction,
			background: reaction.opacity ? {
				fill: hexToRgbA(reaction.color, reaction.opacity)
			} : undefined
		};

		this.load();
	}

	load = async () => {
		try {
			const img = await imageMemo.getImage(this.emoji.ref);
			this.emoji.el = img;
			this.emoji.loaded = true;
		} catch (e) {
			console.error('image load error', e);
			this.ended(this.id);
		}
	};

	animate = () => {
		if (this.isEnded || !this.emoji.loaded) {
			return;
		}

		// subtract from y position to move emoji up
		this.yPos -= (5 + (this.yPos / 80));

		// add cosine to x position with extra interpolation to get the curve correct
		this.xPos += (Math.cos(this.yPos * 0.015) * (1.5 + (Math.random() * 1.5))) - (this.yPos / 300);

		// render using ints only, floats are slow on canvas
		const xPos = Math.floor(this.xPos);
		const yPos = Math.floor(this.yPos);
		const width = this.emoji.el.width;
		const height = this.emoji.el.height;

		// if the emoji has a background color (it's not entirely transparent) render a circle behind the image
		if (this.emoji.background) {

			// radius is half the width of the image with 10px padding, so adding 5 to radius
			const rad = width / 2 + 5;

			// draw the circle centered behind the image
			this.ctx.beginPath();
			this.ctx.arc(xPos + rad - 5, yPos + rad - 5, rad, 0, 2 * Math.PI);
			this.ctx.fillStyle = this.emoji.background.fill;
			this.ctx.fill();
		}

		// draw the image at the new position
		this.ctx.drawImage(this.emoji.el, xPos, yPos, width, height);

		// emoji is off canvas, end the animation and collect this out of the group
		if (this.yPos < 0) {
			this.isEnded = true;
			this.ended(this.id);
		}
	};
}

class EmojiManager {
	emojis: Map<string, Emoji> = new Map();
	ctx: CanvasRenderingContext2D;
	height: number;
	width: number;
	endTimeout: NodeJS.Timeout | undefined;
	ended = false;

	constructor(canvas: HTMLCanvasElement) {
		this.height = canvas.height;
		this.width = canvas.width;
		this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
		this.render();
	}

	handleEnded = (id: string) => {
		this.emojis.delete(id);
	};

	add = (reaction: ReactionConfig, id = uuid()) => {
		this.emojis.set(id, new Emoji(reaction, this.ctx, id, this.handleEnded, this.height, this.width));
	};

	render = () => {
		if (this.ended) {
			return;
		}

		if (this.emojis.size) {
			// in <canvas> you have to empty the view manually for an animation or the previous drawing will still appear
			this.ctx.clearRect(0, 0, this.width, this.height);

			// iterate over emojis and draw them to the canvas
			for (const emoji of this.emojis.values()) {
				emoji.animate();
			}

			// run again on the next frame paint (ideally in 16ms)
			window.requestAnimationFrame(this.render);
		} else {
			// no emojis, no need to run every frame, just recheck in half a second
			this.endTimeout = setTimeout(this.render, 500);
		}
	};

	end = () => {
		this.emojis = new Map();
		this.ended = true;
		if (this.endTimeout) {
			clearTimeout(this.endTimeout);
		}
	};
}

const FloatingReaction: React.FC<FloatingReactionProps> = ({
	sessionUuid,
	chatOverlayEnabled,
	enabledReactions
}) => {
	const canvasRef = useRef<HTMLCanvasElement>();
	const renderer = useRef<EmojiManager>();
	const socketGeneral = useSocket(`session-${sessionUuid}`);
	const selfReactions = useRef<Map<string, number>>(new Map());

	useEffect(() => {
		const handleEmojis = ({ reactions }: { reactions: Record<string, number>; }) => {

			const reactionsWithCount = enabledReactions?.reduce((prev: Array<ReactionConfigWithCount>, curr: ReactionConfig): Array<ReactionConfigWithCount> => {
				if (reactions[curr.name]) {
					return [...prev, { ...curr, count: reactions[curr.name] }];
				} else {
					return prev;
				}
			}, []);
			if (reactionsWithCount) {
				for (const reaction of reactionsWithCount) {
					if (renderer.current) {
						// only input at max 5 per second
						const count = Math.min(5, reaction.count);

						// create duplicates of this reaction up to the count number
						for (let i = 0; i < count; ++i) {
							// check if we've already animated this reaction
							const lastSelfReaction = selfReactions.current.get(reaction.name);
							if (lastSelfReaction) {
								selfReactions.current.set(reaction.name, Math.max(0, lastSelfReaction - 1));
							} else {
								setTimeout(() => {
									renderer.current?.add(reaction);

									// evenly send the reactions to the canvas over a period of 1 second
								}, i * (1000 / count));
							}
						}
					}
				}
			}
		};

		socketGeneral.addListener('emoji-reactions', handleEmojis);

		return () => {
			socketGeneral.removeListener('emoji-reactions', handleEmojis);
		};
	}, [enabledReactions, socketGeneral]);

	const stop = () => {
		// kill this animator
		renderer.current?.end();
		renderer.current = undefined;
		off('selfReaction');
	};

	useEffect(() => {
		if (canvasRef.current) {
			renderer.current = new EmojiManager(canvasRef.current);
			on('selfReaction', (e: ReactionConfig) => {
				const incrementedAmount = Math.min(5, (selfReactions.current.get(e.name) ?? 0) + 1);
				selfReactions.current.set(e.name, incrementedAmount);
				renderer.current?.add(e);
			});
		}

		return stop;
	}, [enabledReactions]);

	useEffect(() => {
		return stop;
	}, []);

	return useMemo(() => (
		<canvas
			className="float-container"
			height={720}
			width={1280}
			style={{
				right: chatOverlayEnabled ? 350 : 0
			}}
			ref={ref => {
				if (ref) {
					canvasRef.current = ref;
				}
			}} />
	), [chatOverlayEnabled]);
};

export default FloatingReaction;
