import { QFilters } from "../../components/admin/create/session/navigation/engage/question-prompts/question-prompt-view";
import { LanguagesAbbr } from "../../types/working-model";

export enum ESort {
	name = 'name',
	nameDesc = 'nameDesc',
	date = 'date',
	dateDesc = 'dateDesc'
}

export enum eFilters {
	quiz = 'quiz',
	poll = 'poll',
	survey = 'survey',
	question_prompt = 'question_prompt',
	feedback = 'feedback'
}

export type SearchProps = {
	token: string;
	channelUuid: string;
	sort?: ESort;
	limit?: number;
	offset?: number;
	search?: string;
	language?: LanguagesAbbr | string;
	filter?: QFilters;
	eFilter?: eFilters;
}

export type GetFullProps = {
	token: string;
	channelUuid: string;
	ids: number[];
}

export type SearchRequestQuestionsParams = {
	module_id: number;
	language?: LanguagesAbbr | 'all';
	channelUuid: string;
	filter?: QFilters;
	limit?: number;
	offset?: number;
	search?: string;
}

export type QuestionResponseCommentParams = {
	channelUuid: string;
	question_id: number;
	module_id: number;
}

export type SearchTypes = 'products' | 'speakers' | 'surveys' | 'quizzes' | 'polls' | 'sponsors' | 'videos' | 'documents' | 'engage' | 'question-prompts';