import { TicketSetMember } from '../store/reducers/admin/paid-list-members';
import { TicketingSet } from '../types/working-model';
import { Get, Put } from './helpers';

interface PaidListsResponse {
	[key: string]: {
		ticketingSet: TicketingSet;
		eventName: string;
		eventUuid: string;
		members: number;
	}
}

export const GetPaidLists = (token: string, channel: number): Promise<PaidListsResponse> => {
	return Get(`/v3/admin/paid-lists/${channel}`, token);
};

export const GetPaidListMembers = (token: string, paid_list: string, offset: number): Promise<{ members: TicketSetMember, total: number | null }> => {
	return Get(`/v3/admin/paid-lists/members/${paid_list}/${offset}`, token);
};

export const SearchPaidListMembers = (token: string, paid_list: string, offset: number, term: string): Promise<PaidListsResponse> => {
	return Get(`/v3/admin/paid-lists/members/search/${paid_list}/${offset}?search=${term}`, token);
};

export const AddPaidListMember = (token: string, paid_list: string, email: string): Promise<TicketSetMember> => {
	return Put(`/v3/admin/paid-lists/members/${paid_list}`, token, { email }, true);
};

export const AddPaidListMemberMultiple = (token: string, paid_list: string, url: string): Promise<{ added: number, invalid: string[] }> => {
	return Put(`/v3/admin/paid-lists/members/multiple/${paid_list}`, token, { url }, true);
};
