import { Get, Put, Post, Patch } from './live-event-to-api-helpers';

export const HandleJoinAccept = async (token: string, hostToken: string, userUuid: string, email: string, sessionUuid: string): Promise<any> => {
	return Put(`/v3/live-event/firesides-access/${sessionUuid}`, token, { uuid: userUuid, email, hostToken }, false);
};

export const HandleJoinDeny = async (token: string, hostToken: string, sessionUuid: string, userUuid: string, email: string): Promise<any> => {
	return Post(`/v3/live-event/firesides-access-deny/${sessionUuid}`, token, { hostToken, uuid: userUuid, email }, true);
};

export const HandleDenyAll = async (token: string, sessionUuid: string, hostToken: string): Promise<any> => {
	return Post(`/v3/live-event/firesides-access-deny-all/${sessionUuid}`, token, { hostToken }, false, {}, true);
};

export const ValidateFiresideAccess = async (token: string, sessionUuid: string): Promise<any> => {
	return Get(`/v3/live-event/firesides-access/${sessionUuid}`, token);
};

export async function RaiseHand(session_uuid: string, token: string): Promise<any> {
	return Post(`/v3/live-event/firesides-access/${session_uuid}`, token, {}, false, {}, true);
}

export async function LowerHand(session_uuid: string, token: string): Promise<any> {
	return Post(`/v3/live-event/firesides-access-deny-self/${session_uuid}`, token, { token }, false, {}, true);
}

export async function FiresidesHostPresent(session_uuid: string, token: string): Promise<any> {
	return Put(`/v3/firesides/broadcast/${session_uuid}/alive`, token, {}, false, true);
}

export async function ResetFiresideState(session_uuid: string, token: string): Promise<any> {
	return Patch(`/v3/firesides/broadcast/${session_uuid}/reset`, token, {}, false, true);
}

export type ToggleLiveFiresideProps = {
	meetingUrl: string,
	hostToken: string,
	meetName: string
}

export async function GoLiveMeetFireside(session_uuid: string, token: string, params: ToggleLiveFiresideProps): Promise<unknown> {
	return Post(`/v3/live-event/firesides-meet/state-change/${session_uuid}`, token, { ...params, state: 'live' }, true, true);
}

export async function EndLiveMeetFireside(session_uuid: string, token: string, params: ToggleLiveFiresideProps): Promise<unknown> {
	return Post(`/v3/live-event/firesides-meet/state-change/${session_uuid}`, token, { ...params, state: 'offline' }, true, true);
}