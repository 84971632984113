import { v4 as uuidv4 } from 'uuid';
import { matchPath } from "react-router";
import {
	ActionPayload,
	ActionSource,
	ActionEvent,
	ActionType,
	BrandliveEvent,
	LanguagesAbbr,
} from '../../types/working-model';

export { default as useTracking } from './use-tracking';
import { DebugDefaultRouteMap, DefaultRouteMap } from '../../components/live-event/hooks/use-route-map';
import { getLiveBreakoutSessions } from './tracking-utils';

const matchesPaths = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string, paths: string[]) => {
	return matchPath<T>(pathname, { path: paths, exact: true });
};

const isHome = (pathname: string) => {
	return matchesPaths(pathname, [
		DebugDefaultRouteMap.Home,
		DefaultRouteMap.Home
	]);
};

const isSessions = (pathname: string) => {
	return matchesPaths(pathname, [
		DebugDefaultRouteMap.Sessions,
		DefaultRouteMap.Sessions
	]);
};

// single session events without a home page use the landing page as the session page
// we will only trigger this if the event is a single session without a homepage
export const isLanding = (pathname: string) => {
	return matchesPaths(pathname, [
		DebugDefaultRouteMap.Landing,
		DefaultRouteMap.Landing
	]);
};

const isCustomPage = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string) => {
	return matchesPaths<T>(pathname, [
		DebugDefaultRouteMap.CustomPage,
		DefaultRouteMap.CustomPage
	]);
};

const isSelfProfile = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string) => {
	return matchesPaths<T>(pathname, [
		DebugDefaultRouteMap.Profile,
		DefaultRouteMap.Profile
	]);
};

const isProfile = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string) => {
	return matchesPaths<T>(pathname, [
		DebugDefaultRouteMap.AttendeeProfile,
		DefaultRouteMap.AttendeeProfile
	]);
};

const isDirectory = (pathname: string) => {
	return matchesPaths(pathname, [
		DebugDefaultRouteMap.Directory,
		DefaultRouteMap.Directory
	]);
};

export const isSessionPage = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string, eventBundle: BrandliveEvent | null) => {
	// single session and no homepage will trigger the landing page
	const isSingleSessionNoHomepage = isLanding(pathname) && eventBundle?.sessions?.length === 1 && !eventBundle?.settings.display_homepage;
	if (isSingleSessionNoHomepage) {
		// mock return from matchesPath
		return {
			params: { sessionUuid: eventBundle.sessions[0].uuid }
		};
	}

	return matchesPaths<T>(pathname, [
		DebugDefaultRouteMap.Session,
		DefaultRouteMap.Session
	]);
};

export const isLeaderboard = <T extends { [K in keyof T]?: string | undefined; }>(pathname: string) => {
	return matchesPaths(pathname, [
		DebugDefaultRouteMap.Leaderboard,
		DefaultRouteMap.Leaderboard
	]);
};

export const getCurrentPageSourceInfo = (url: string, eventBundle: BrandliveEvent, bl_profile: number): { source_id: number, source_type: string } => {
	if (isHome(url)) {
		return {
			source_id: eventBundle.homepage?.homepage || 0,
			source_type: ActionSource.Home
		};
	}

	// Agenda
	if (isSessions(url)) {
		return {
			source_id: 0,
			source_type: ActionSource.Agenda
		};
	}

	const sessionPage = isSessionPage<{ sessionUuid: string }>(url, eventBundle);
	if (sessionPage) {
		const sessionUuid = sessionPage.params?.sessionUuid;
		const session = eventBundle?.sessions?.find(s => s.uuid === sessionUuid);
		return {
			source_id: session?.session ?? 0,
			source_type: ActionSource.Session
		};
	}

	const customPage = isCustomPage<{ customPath: string }>(url);
	if (customPage) {
		return {
			source_id: eventBundle?.custom_pages?.find(page => page.path === customPage.params.customPath)?.custom_page || 0,
			source_type: ActionSource.CustomPage
		};
	}

	const profilePage = isProfile<{ attendeeId: string }>(url);
	if (profilePage) {
		return {
			// attendeeId is a string and source_id has to be a number - the bl_profile for this attendee doesn't exist in this context, so send a 0
			source_id: 0,
			source_type: ActionSource.Profile
		};
	}

	const selfProfilePage = isSelfProfile(url);
	// Looking at their own profile page
	if (selfProfilePage) {
		return {
			source_id: bl_profile,
			source_type: ActionSource.SelfProfile
		};
	}

	if (isDirectory(url)) {
		return {
			source_id: 0,
			source_type: ActionSource.Directory
		};
	}

	if (isLeaderboard(url)) {
		return {
			source_id: 0,
			source_type: ActionSource.Leaderboard
		};
	}

	return {
		source_id: 0,
		source_type: ActionSource.Landing
	};
};

export const getPageTrackingParams = (
	bl_profile: number,
	eventBundle: BrandliveEvent,
	registration_id: string,
	userSessionUuid: string,
	url: string,
	liveSessions: string[],
	registeredLanguage?: string,
	currentLanguage?: LanguagesAbbr,
	miscellaneous?: string,
): ActionPayload | null => {
	const liveBreakoutSessions = getLiveBreakoutSessions(eventBundle.sessions);

	const baseActionParams = {
		action: ActionEvent.PageView,
		action_type: ActionType.Active,
		action_id: uuidv4(),
		bl_profile,
		channel_id: eventBundle.channel,
		event_id: eventBundle.event || 0,
		registration_id,
		source_app: 'events',
		source_app_version: process.env.REACT_APP_VERSION ?? '0',
		// user_session_uuid can actually be the UserSession.uuid (when used from session pages) or PageSession.uuid (when used from non session pages)
		user_session_uuid: userSessionUuid,
		registered_language: registeredLanguage ? registeredLanguage : undefined,
		current_language: currentLanguage,
		// a breakout session is live based on time of session
		live_sessions: [...liveSessions, ...liveBreakoutSessions],
		miscellaneous
	};

	// Home page
	if (isHome(url)) {
		return {
			...baseActionParams,
			source_id: eventBundle.homepage?.homepage || 0,
			source_type: ActionSource.Home
		};
	}

	// Agenda
	if (isSessions(url)) {
		return {
			...baseActionParams,
			source_id: 0,
			source_type: ActionSource.Agenda
		};
	}

	const customPage = isCustomPage<{ customPath: string }>(url);
	// Custom pages
	if (customPage) {
		return {
			...baseActionParams,
			source_id: eventBundle?.custom_pages?.find(page => page.path === customPage.params.customPath)?.custom_page || 0,
			source_type: ActionSource.CustomPage
		};
	}

	const profilePage = isProfile<{ attendeeId: string }>(url);
	// Profile
	if (profilePage) {
		return {
			...baseActionParams,
			// attendeeId is a string and source_id has to be a number - the bl_profile for this attendee doesn't exist in this context, so send a 0
			source_id: 0,
			source_type: ActionSource.Profile
		};
	}

	const selfProfilePage = isSelfProfile(url);
	// Looking at their own profile page
	if (selfProfilePage) {
		return {
			...baseActionParams,
			source_id: bl_profile,
			source_type: ActionSource.SelfProfile
		};
	}

	// Directory
	if (isDirectory(url)) {
		return {
			...baseActionParams,
			source_id: 0,
			source_type: ActionSource.Directory
		};
	}

	// Leaderboard
	if (isLeaderboard(url)) {
		return {
			...baseActionParams,
			source_id: 0,
			source_type: ActionSource.Leaderboard
		};
	}

	const sessionPage = isSessionPage<{ sessionUuid: string }>(url, eventBundle);
	if (sessionPage) {
		const sessionUuid = sessionPage.params?.sessionUuid;
		const session = eventBundle?.sessions?.find(s => s.uuid === sessionUuid);
		return {
			...baseActionParams,
			source_id: session?.session ?? 0,
			source_type: ActionSource.Session,
			user_session_uuid: userSessionUuid
		};
	}

	// null if its a page we don't currently want to track
	return null;
};

export const VIDEO_TRACKING_MILESTONES: ReadonlyArray<number> = [10, 25, 50, 75, 90, 100];