import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";

import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { PageModule, PageModuleGroupModules, PageModuleType, RelatedSpeakerSessions, SessionPanelLayoutsTypes, Speaker } from "../../../../../../../../types/working-model";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import TextInput from "../../../../../../../general-ui/text-input/text";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { usePageModule } from "../../hooks/panel.hooks";
import SpeakerCard from "./speaker-card";
import AddMenuDropdown from "./add-menu-dropdown";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { SessionPanelMap } from "../../session-panel-route-map";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import { customSpeakersItems, extrasSpeakersItems } from "../../empty-state-panel/constants/empty-panel";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import Icon, { COLORS, ICONS } from "@general-ui/icon";

interface ISortableContentItemProps {
	speaker: Speaker;
	id: string;
}

const speakerSearch = (term: string, speakers: Speaker[]): Speaker[] => {
	const processedTerm = term.toLowerCase().replace(' ', '');
	return [...speakers].filter(speaker => {
		const terms = speaker?.first_name?.toLowerCase() || '' + speaker?.last_name?.toLowerCase() || '';
		return terms.includes(processedTerm);
	});
};

const ActiveSpeakersList: React.FC<unknown> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [speakers, setSpeakers] = useState<Speaker[]>([]);
	const searchTermRef = useRef<string>();
	const [searchTerm, setSearchTerm] = useState<string>('');
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const moduleGroupingRef = useRef<PageModuleGroupModules[] | undefined>(workingSession?.module_grouping);
	const pageModulesRef = useRef<PageModule[] | undefined>(workingSession?.modules);
	const pageModule = usePageModule();
	const { customPath } = useParams<{ customPath?: string }>();
	const moduleGrouping = workingSession?.module_grouping;
	const pageModules = workingSession?.modules;
	const history = useHistory<{ speakerToEdit: Speaker, relatedSessions: number[] }>();
	const getAdmin = useGetAdminUrl();
	const dispatch = useAppDispatch();
	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customSpeakersItems : extrasSpeakersItems;

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8
			}
		})
	);

	useEffect(() => {
		if (moduleGrouping) {
			moduleGroupingRef.current = moduleGrouping;
		}

		if (pageModules) {
			pageModulesRef.current = pageModules;
		}
	}, [moduleGrouping, pageModules]);

	const getExistingSpeakers = useCallback(() => {
		if (pageModule) {
			return pageModule.modules as Speaker[];
		}

		return [];
	}, [pageModule]);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		const speakers = getExistingSpeakers();
		const filteredSpeakers = speakerSearch(searchTerm, speakers);
		setSpeakers(filteredSpeakers);
	}, [searchTerm, getExistingSpeakers]);


	useEffect(() => {
		setSpeakers(getExistingSpeakers());
	}, [getExistingSpeakers]);

	const handleEdit = useCallback((speaker: Speaker) => {
		if (!pageModule?.id) return;

		const relatedSessions = pageModule.content?.related_sessions as RelatedSpeakerSessions[] | undefined | null;

		history.push(getAdmin({
			path: SessionPanelMap[SessionPanelLayoutsTypes.CreateExtraSpeaker],
			customPath,
			page_module: pageModule?.id
		}), {
			speakerToEdit: speaker,
			relatedSessions: relatedSessions?.find(r => r.speaker === speaker.speaker)?.sessions ?? []
		});
	}, [customPath, getAdmin, history, pageModule?.content?.related_sessions, pageModule?.id]);

	const handleDelete = useCallback((speaker: Speaker) => {
		if (!pageModule) return;

		dispatch(updatePageModuleAndSave({
			...pageModule,
			content_modules: pageModule.content_modules?.filter(module => module !== speaker.speaker),
			modules: pageModule.modules?.filter((module: Speaker) => module.speaker !== speaker.speaker),
			type: PageModuleType.speakers
		}));
	}, [dispatch, pageModule]);

	function handleDragEnd(event: DragEndEvent) {
		/**********************************************************************
		 * Getting the element index that is getting dragged and where it is being dropped
		 **********************************************************************/

		const startIndex = event?.active?.data?.current?.sortable.index;
		const overIndex = event?.over?.data?.current?.sortable.index;

		// Early escape if we never moved the module card
		if (!(startIndex >= 0 && overIndex >= 0 && startIndex !== overIndex)) return;

		const newSpeakerOrder = arrayMove(speakers, startIndex, overIndex);

		if (!pageModule) return;

		dispatch(updatePageModuleAndSave({
			...pageModule,
			content_modules: pageModule.content_modules,
			modules: [...newSpeakerOrder],
			type: PageModuleType.speakers
		}));
	}

	const SortableItem: React.FC<ISortableContentItemProps> = ({ speaker, id }) => {

		const {
			attributes,
			listeners,
			setNodeRef,
			transform,
		} = useSortable({ id: id });

		const style = {
			transform: CSS.Transform.toString(transform),
			display: 'flex',
			cursor: "grabbing",
		};

		return (
			<div
				key={speaker.speaker}
				ref={setNodeRef}
				style={style}
				{...attributes}
				{...listeners}
				className="registration-option-v2-container" // used for animating the hover open and close effect
			>
				<div className="drag-handle">
					<Icon
						name={ICONS.DRAG_HANDLE}
						size={16}
						color={COLORS.GRAY} />
				</div>

				<SpeakerCard
					speaker={speaker}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
				/>
			</div>
		);
	};


	return (
		<div
			className={"session-panel speakers-list"}
			ref={containerRef}
		>
			<div className="session-panel-header-options search-only">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
					isAdmin
				/>
			</div>

			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
				modifiers={[restrictToParentElement]}
				autoScroll={false}
			>
				{speakers.length ? (
					<StaggerChildren
						ref={scrollRef}
						footer={
							(
								<SessionPanelAddFooter
									items={extrasItems}
									scrollRef={scrollRef}
									dropdownPosition="top"
								/>
							)
						}
						enableFades={true}
						className={classNames("session-panel-content added-items")}
					>
						<SortableContext
							items={speakers.map((speaker: Speaker, index: number) => `${index}.${speaker.speaker}`)}
							strategy={verticalListSortingStrategy}
							disabled={!!searchTerm} // Disabled if we're searching
						>
							{speakers.map((speaker: Speaker, index: number) => (
								<SortableItem
									key={`${index}.${speaker.speaker}`}
									id={`${index}.${speaker.speaker}`}
									speaker={speaker}
								/>
							))}
						</SortableContext>
					</StaggerChildren>

				) : (
					<div className="session-panel-no-results">
						<section>{!workingSession ? <WaitingIndicator /> : 'No results'}</section>
						<AddMenuDropdown />
					</div>
				)}

			</DndContext>
		</div>
	);
};

export default ActiveSpeakersList;