import store from "store/main";
import { PermissionObject, parsePermission } from "./utils";
import { getLogger } from "utils/debug-logger";
import { EPermissions, EPermissionsDisplayNames } from "types/working-model";
import { BLAdminWithPermissions } from "connection/access";
import { intersection } from "underscore";
import { CheckboxTreeData } from "@general-ui/v2-checkbox-tree/types";
import { allAccessRoles, rolePermissionSortOrder } from "./constants";

const log = getLogger("bl-admin:access");

export class Access implements PermissionObject {
	constructor(
		public groupUuid?: string,
		public eventUuid?: string,
		public sessionUuid?: string,
		public allGroupsInChannel?: boolean,
		public allEventsInGroup?: boolean,
		public allSessionsInEvent?: boolean
	) {
	}

	public static fromPermissionStatement(statement: string): Access {
		const permissions = parsePermission(statement);
		return Access.fromPermissionObject(permissions);
	}

	public static fromPermissionObject(permissions: PermissionObject): Access {
		return new Access(
			permissions.groupUuid,
			permissions.eventUuid,
			permissions.sessionUuid,
			permissions.allGroupsInChannel,
			permissions.allEventsInGroup,
			permissions.allSessionsInEvent
		);
	}

	get allAccess() {
		return !!this.allGroupsInChannel;
	}

	public canAccessEvent(eventUuid: string): boolean {
		if (this.allAccess) {
			return true;
		}

		if (this.groupUuid && this.allEventsInGroup) {
			const eventGroups = store.getState().EventsReducer.eventGroups;
			const group = eventGroups.find(group => group.uuid === this.groupUuid);
			if (group) {
				return group.event_uuids.includes(eventUuid);
			}
		}

		return this.eventUuid === eventUuid;
	}

	public canAccessGroup(groupUuid: string): boolean {
		if (this.allGroupsInChannel) {
			return true;
		}

		return this.groupUuid === groupUuid;
	}

	public canAccessSession(sessionUuid: string): boolean {
		return this.sessionUuid === sessionUuid;
	}

	public toResourceRequest() {
		return {
			groupUuid: this.groupUuid,
			eventUuid: this.eventUuid,
			sessionUuid: this.sessionUuid
		};
	}

	public toString() {
		const parts = [];

		let groupSet = false;
		if (this.allGroupsInChannel !== undefined || this.groupUuid !== undefined) {
			if (this.allGroupsInChannel) {
				return "groups.*";
			} else if (this.groupUuid) {
				parts.push(`groups.${this.groupUuid}`);
			}

			groupSet = true;
		}

		let eventSet = false;
		if (this.allEventsInGroup !== undefined || this.eventUuid !== undefined) {
			if (!groupSet) {
				parts.push("*");
			}

			if (this.allEventsInGroup) {
				parts.push("events.*");
			} else if (this.eventUuid) {
				parts.push(`events.${this.eventUuid}`);
			}

			eventSet = true;
		}

		if (this.allSessionsInEvent !== undefined || this.sessionUuid !== undefined) {
			if (!eventSet) {
				parts.push("*");
			}

			if (this.allSessionsInEvent) {
				parts.push("sessions.*");
			} else if (this.sessionUuid) {
				parts.push(`sessions.${this.sessionUuid}`);
			}
		}

		const statement = parts.join(".").replace(/-/g, "");

		log(`Generated permission statement: ${statement}`);
		return statement;
	}
}

type CheckOptions = {
	inNext?: boolean;
}

export class UserAccess {
	private nextPermissions: Access[] = [];
	private nextRoles: Set<EPermissions>;
	public roles: EPermissions[] = [];

	constructor(
		public userId: string,
		public permissions: Access[],
		public user: BLAdminWithPermissions,
		nextPermissions?: Access[],
		nextRoles?: Set<EPermissions>
	) {
		this.nextPermissions = [...(nextPermissions ?? permissions)];
		this.nextRoles = nextRoles ?? new Set(user.roles);
		this.roles = user.roles;
	}

	public static from(access: UserAccess) {
		return new UserAccess(access.userId, access.permissions, access.user, access.nextPermissions, access.nextRoles);
	}

	private groupFromEvent(eventUuid: string): string | undefined {
		const eventGroups = store.getState().EventsReducer.eventGroups;
		const eventInGroup = eventGroups.find(group => {
			return group.event_uuids.includes(eventUuid);
		});
		if (eventInGroup) {
			return eventInGroup.uuid;
		}
	}

	private eventsInGroup(groupUuid: string): string[] | undefined {
		const eventGroups = store.getState().EventsReducer.eventGroups;
		const group = eventGroups.find(group => group.uuid === groupUuid);
		return group?.event_uuids;
	}

	// should return true if direct access to this uuid or user has access to all groups
	// or if the event is in a group that the user has access to
	private getEventGroup(groupUuid: string) {
		const eventGroups = store.getState().EventsReducer.eventGroups;
		return eventGroups.find(group => group.uuid === groupUuid);
	}

	private getUngroupedEvents() {
		const eventGroups = store.getState().EventsReducer.eventGroups;
		const groupedEvents = eventGroups.reduce<string[]>((acc, group) => {
			acc.push(...group.event_uuids);
			return acc;
		}, []);
		const allEvents = store.getState().EventsReducer.eventsList;
		return allEvents.filter(event => !groupedEvents.includes(event.uuid));
	}

	// should return true if direct access to this uuid or all groups
	public canAccessGroup(groupUuid: string, opts?: CheckOptions): boolean {
		const permissions = opts?.inNext ? this.nextPermissions : this.permissions;
		const canAccess = permissions.some(permission => permission.allAccess || permission.canAccessGroup(groupUuid));
		// log(`User ${this.userId} can access group ${groupUuid}: ${canAccess}`);
		return canAccess;
	}

	public canAccessEvent(eventUuid: string, opts?: CheckOptions): boolean {
		const permissions = opts?.inNext ? this.nextPermissions : this.permissions;
		const groupUuid = this.groupFromEvent(eventUuid);
		if (groupUuid && this.canAccessGroup(groupUuid, opts)) {
			// log(`User ${this.userId} can access event ${eventUuid} via group ${groupUuid}`);
			return true;
		}

		const canAccess = permissions.some(permission => permission.allAccess || permission.canAccessEvent(eventUuid));
		// log(`User ${this.userId} can access event ${eventUuid}: ${canAccess}`);
		return canAccess;
	}

	// TODO: create sync method of finding session uuid in events or groups user might be able to access
	// this data does not currently exist anywhere in redux
	public canAccessSession(sessionUuid: string, opts?: CheckOptions): boolean {
		const permissions = opts?.inNext ? this.nextPermissions : this.permissions;
		const canAccess = permissions.some(permission => permission.allAccess || permission.canAccessSession(sessionUuid));
		log(`User ${this.userId} can access session ${sessionUuid}: ${canAccess}`);
		return canAccess;
	}

	public canAccessAllGroups(): boolean {
		return this.nextPermissions.some(permission => permission.allAccess);
	}

	// if all-access has been added for this user then remove all other permissions
	// as they will be redundant
	public addAll() {
		this.nextPermissions = [Access.fromPermissionObject({ allGroupsInChannel: true })];
		log(`Adding global access to user ${this.userId}`);
		return this;
	}

	public removeAll() {
		this.nextPermissions = [];
		log(`Removing all access for user ${this.userId}`);
		return this;
	}

	// if all-access is already enabled this does nothing
	public addGroup(groupUuid: string) {
		const groupPermission = Access.fromPermissionObject({ groupUuid, allEventsInGroup: true });

		// user already has all access, do nothing
		if (this.nextPermissions.some(permission => permission.allAccess)) {
			log(`User ${this.userId} already has all access, not adding group ${groupUuid}`);
			return this;
		} else {
			log(`Adding group ${groupUuid} to user ${this.userId}`);
			const eventsInGroup = this.eventsInGroup(groupUuid);
			if (eventsInGroup) {
				for (const eventUuid of eventsInGroup) {
					this.removeEvent(eventUuid);
				}
			}
			this.nextPermissions = [...this.nextPermissions, groupPermission];
		}

		return this;
	}

	// adding an event - only needed if user can't already access it.
	public addEvent(eventUuid: string) {
		if (!this.canAccessEvent(eventUuid, { inNext: true })) {
			log(`Adding event ${eventUuid} to user ${this.userId}`);
			this.nextPermissions = [...this.nextPermissions, Access.fromPermissionObject({ eventUuid, allSessionsInEvent: true })];
		}

		return this;
	}

	public removeGroup(groupUuid: string) {
		// we are removing a group but user has all access
		// need to revoke all access and add all group uuids except the one we are removing
		if (this.nextPermissions.some(permission => permission.allAccess)) {
			log(`User ${this.userId} has all access, removing group ${groupUuid} and re-adding all other groups`);
			// add all groups except the removed one
			const eventGroups = store.getState().EventsReducer.eventGroups;
			const groupPermissions = eventGroups.reduce((acc: Access[], group) => {
				if (group.uuid !== groupUuid) {
					acc.push(Access.fromPermissionObject({ groupUuid: group.uuid, allEventsInGroup: true }));
				}

				return acc;
			}, []);

			// add access for all ungrouped events individually
			const ungroupedEvents = this.getUngroupedEvents();
			const eventPermissions = ungroupedEvents.reduce<Access[]>((permissions, event) => {
				permissions.push(Access.fromPermissionObject({ eventUuid: event.uuid, allSessionsInEvent: true }));
				return permissions;
			}, []);

			this.nextPermissions = [...groupPermissions, ...eventPermissions];
		} else {
			log(`Removing group ${groupUuid} from user ${this.userId}`);
			const eventsInGroup = this.eventsInGroup(groupUuid);
			log(`Events in group ${groupUuid}:`, eventsInGroup);

			this.nextPermissions = this.nextPermissions.filter(permission => {
				// remove all events in the group the user might access because user access to the group removed
				if (permission.eventUuid && eventsInGroup?.includes(permission.eventUuid)) {
					return false;
				}

				return permission.groupUuid !== groupUuid;
			});

			log(`Remaining permissions for user ${this.userId}:`, this.nextPermissions);
		}

		return this;
	}


	public removeEvent(eventUuid: string) {
		// first remove all direct access to this event
		this.nextPermissions = this.nextPermissions.filter(permission => permission.eventUuid !== eventUuid);

		// user has all access
		if (this.nextPermissions.some(permission => permission.allAccess)) {
			log(`User ${this.userId} has all access, removing event ${eventUuid} and re-adding all other groups and ungrouped events`);

			// rebuild tree with everything except the event we are removing
			// and the groups that contain it
			const groups = store.getState().EventsReducer.eventGroups;
			const groupPermissions = groups.reduce((acc: Access[], group) => {

				// if this group includes this eventUuid, all all events in this group individually
				if (group.event_uuids.includes(eventUuid)) {
					for (const uuid of group.event_uuids) {
						if (uuid !== eventUuid) {
							acc.push(Access.fromPermissionObject({ eventUuid: uuid, allSessionsInEvent: true }));
						}
					}
				} else { // otherwise just add the group
					acc.push(Access.fromPermissionObject({ groupUuid: group.uuid, allEventsInGroup: true }));
				}

				return acc;
			}, []);

			// add all ungrouped events except this one individually
			const ungroupedEvents = this.getUngroupedEvents();
			const eventPermissions = ungroupedEvents.reduce<Access[]>((permissions, event) => {
				if (event.uuid !== eventUuid) {
					permissions.push(Access.fromPermissionObject({ eventUuid: event.uuid, allSessionsInEvent: true }));
				}

				return permissions;
			}, []);

			this.nextPermissions = [...groupPermissions, ...eventPermissions];
			return this;
		}

		// if removing this event and user can access it via group access, need to revoke
		// all access to this group and re-add all events within it except the target event
		const groupUuid = this.groupFromEvent(eventUuid);

		log(`User ${this.userId} has an event ${eventUuid} that is in a group ${groupUuid}`);

		// debugger;

		if (groupUuid && this.nextPermissions.some(permission => permission.groupUuid === groupUuid && permission.allEventsInGroup)) {
			log(`User ${this.userId} has all events in group access, removing event ${eventUuid} and re-adding all other events in group ${groupUuid}`);
			const group = this.getEventGroup(groupUuid);

			// remove the group
			this.nextPermissions = this.nextPermissions.filter(permission => permission.groupUuid !== groupUuid);

			// re-add all events in group except the one we are removing
			const eventPermissions = group?.event_uuids.reduce<Access[]>((permissions, uuid) => {

				if (uuid !== eventUuid) {
					permissions.push(Access.fromPermissionObject({ eventUuid: uuid, allSessionsInEvent: true }));
				}

				return permissions;
			}, []) || [];

			this.nextPermissions.push(...eventPermissions);
		} else {
			log(`Removing event ${eventUuid} from user ${this.userId}`);
			this.nextPermissions = this.nextPermissions.filter(permission => permission.eventUuid !== eventUuid);
		}

		log(`Remaining permissions for user ${this.userId}:`, this.nextPermissions);

		return this;
	}

	public revert() {
		log(`Reverting permission changes for user ${this.userId}`);
		this.nextPermissions = [...this.permissions];
		this.nextRoles = new Set(this.user.roles);
		return this;
	}

	public commit() {
		log(`Committing permission changes for user ${this.userId}`);
		this.permissions = [...this.nextPermissions];
		this.roles = [...this.nextRoles];
		this.nextPermissions = this.permissions;
		this.nextRoles = new Set(this.roles);
		return this;
	}

	public getNextPermissions() {
		return this.nextPermissions;
	}

	public willChangeRoles() {
		return JSON.stringify(this.roles) !== JSON.stringify(Array.from(this.nextRoles));
	}

	public willChangePermissions() {
		return JSON.stringify(this.permissions) !== JSON.stringify(this.nextPermissions);
	}

	public getNextRoles() {
		return Array.from(this.nextRoles);
	}

	public willLoseAdminPrivileges() {
		return this.roles.includes(EPermissions.Admin) && !this.nextRoles.has(EPermissions.Admin);
	}

	public addRole(role: EPermissions) {
		if (role === EPermissions.Owner || role === EPermissions.Admin) {
			this.nextRoles.clear();
		}

		if (this.nextRoles.has(EPermissions.Admin) || this.nextRoles.has(EPermissions.Owner)) {
			this.nextRoles.clear();
		}

		this.nextRoles.add(role);

		if (allAccessRoles.includes(role)) {
			this.addAll();
		}

		log('Added role', role, 'to user', this.userId);
		return this;
	}

	public removeRole(role: EPermissions) {
		if (this.nextRoles.size === 1) {
			log(`User ${this.userId} only has one role and at least one role is required.`);
			return this;
		}

		this.nextRoles.delete(role);
		log('Removed role', role, 'from user', this.userId);
		return this;
	}

	public canChangeRoleTo(fromUser: EPermissions[], role: EPermissions) {
		const canChange = [EPermissions.Owner, EPermissions.Admin, EPermissions.Builder, EPermissions.GroupAdmin];
		if (!intersection(canChange, fromUser).length) {
			return false;
		}

		if (fromUser.includes(EPermissions.Owner)) {
			return true;
		}

		if (fromUser.includes(EPermissions.Admin)) {
			return role !== EPermissions.Owner;
		}

		if (intersection(fromUser, [EPermissions.Builder, EPermissions.GroupAdmin]).length) {
			return role !== EPermissions.Owner && role !== EPermissions.Admin;
		}

		return false;
	}

	public rolesList = (fromUser: EPermissions[]): CheckboxTreeData => {
		return {
			id: 'all',
			label: 'All',
			isOn: false,
			children: Object.entries(EPermissions).filter(filterSelectableRoles).map(([key, role]) => {
				return {
					id: key,
					label: EPermissionsDisplayNames[role] ?? role,
					disabled: !this.canChangeRoleTo(fromUser, role),
					metadata: {
						role
					},
					isOn: this.nextRoles.has(role)
				};
			}).sort((a, b) => {
				if (rolePermissionSortOrder[a.metadata.role as EPermissions] < rolePermissionSortOrder[b.metadata.role as EPermissions]) {
					return -1;
				} else {
					return 1;
				}
			})
		};
	};
}

function filterSelectableRoles([_, role]: [string, EPermissions]) {
	return !!rolePermissionSortOrder[role];
}
