
import React from "react";
import PanelBody from "../../panel-body";
import './settings-card.scss';
import SingleSignOn from "./single-sign-on";

function SSORegistrationSettings(): JSX.Element {

	return (
		<PanelBody>
			<SingleSignOn />
		</PanelBody>
	);
}

export default SSORegistrationSettings;
