import { createContext, useEffect, useReducer } from "react";

type RegistrationPanelState = {
	activeTab?: string;
	title: string;
}

const registrationPanelInitialState: RegistrationPanelState = {
	activeTab: undefined,
	title: "Content",
};

export enum RegistrationPanelActions {
	SetTitle = 'setTitle',
	SetTab = 'setTab',
}

type DispatchedAction<T, P> = { type: T, payload: P };
type SetTabModuleAction = DispatchedAction<RegistrationPanelActions.SetTab, string | undefined>;
type SetTitleAction = DispatchedAction<RegistrationPanelActions.SetTitle, string>;

type RegistrationPanelAction = SetTitleAction | SetTabModuleAction

export const setPanelTitle = (title: string): SetTitleAction => ({ type: RegistrationPanelActions.SetTitle, payload: title });
export const setActiveTab = (tab: string | undefined): SetTabModuleAction => ({ type: RegistrationPanelActions.SetTab, payload: tab });

const registrationPanelReducer = (state: RegistrationPanelState, action: RegistrationPanelAction) => {
	switch (action.type) {
		case RegistrationPanelActions.SetTitle:
			return { ...state, title: action.payload };
		case RegistrationPanelActions.SetTab:
			return { ...state, activeTabModule: action.payload };

		default:
			return state;
	}
};

type RegistrationPanelStateContextType = {
	state: RegistrationPanelState,
	dispatch: React.Dispatch<RegistrationPanelAction>
};

export const RegistrationPanelContext = createContext({
	state: registrationPanelInitialState,
	dispatch: () => null
} as RegistrationPanelStateContextType);

const getRegistrationPanelInitialState = ({ activeTab }: RegistrationPanelState): RegistrationPanelState => {
	return {
		title: "Content",
		activeTab: activeTab,
	};
};

export const RegistrationPanelStateProvider: React.FC<RegistrationPanelState & { children: JSX.Element }> = (props) => {
	const [state, dispatch] = useReducer(registrationPanelReducer, getRegistrationPanelInitialState(props));

	useEffect(() => {
		dispatch(setPanelTitle(props.title));
	}, [props.title]);

	return (
		<RegistrationPanelContext.Provider value={{ state, dispatch }}>{props.children}</RegistrationPanelContext.Provider>
	);
};