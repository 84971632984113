import { Action } from "../../../types/actions";
import { IQuestion, ISocketCommentProps } from "../../../types/working-model";

export const SET_MODULE_QUESTIONS = 'SET_MODULE_QUESTIONS';
export const setModuleQuestions = (questions: { [key: number]: Map<number, IQuestion> }): Action => ({
	type: SET_MODULE_QUESTIONS,
	payload: questions,
});

export const SET_MODULE_QUESTION_COMMENTS = 'SET_MODULE_QUESTION_COMMENTS';
export const setModuleQuestionsComments = (
	module_id: number,
	question_id: number,
	comments: Map<number, ISocketCommentProps>
): Action => ({
	type: SET_MODULE_QUESTION_COMMENTS,
	payload: { module_id, question_id, comments },
});

export const SET_ANONYMOUS_QUESTION = 'SET_ANONYMOUS_QUESTION';
export const setIsAnonymousQuestion = (
	isAnonymousQuestion: boolean,
): Action => ({
	type: SET_ANONYMOUS_QUESTION,
	payload: isAnonymousQuestion,
});

export const SET_ANONYMOUS_COMMENT = 'SET_ANONYMOUS_COMMENT';
export const setIsAnonymousComment = (
	isAnonymousComment: boolean,
): Action => ({
	type: SET_ANONYMOUS_COMMENT,
	payload: isAnonymousComment,
});

