import { PageModuleType } from "types/working-model";


export const moduleRequiresContentInV2: {
	[key in PageModuleType]?: boolean;
} = {
	[PageModuleType.products]: true,
	[PageModuleType.documents]: true,
	[PageModuleType.similar_sessions]: true,
	[PageModuleType.speakers]: true,
	[PageModuleType.blank]: false,
	[PageModuleType.feed]: true,
	[PageModuleType.survey]: true,
	[PageModuleType.questions]: true,
	[PageModuleType.comment_box_prompt]: true,
	[PageModuleType.quizzes]: true
};
