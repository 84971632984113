import { useEffect, useState } from 'react';

import Icon, { ICONS } from '@general-ui/icon';

import './eye-dropper.scss';

// NOTE:
// The EyeDropper API is still an experimental feature and is currently only supported in Chromium-based browsers
// it has been around for 3 years, so don't hold your breath for full browser support

// https://developer.mozilla.org/en-US/docs/Web/API/EyeDropper

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		EyeDropper?: any;
	}
}

interface IEyeDropperProps {
	onSelectColor: (color: string) => void;
	size?: number;
	/** providing this option so we don't get errors if trying to nest a button in a button */
	tagType?: 'button' | 'div';
}

const EyeDropper = ({ onSelectColor, tagType = 'button', size = 18 }: IEyeDropperProps) => {

	const TagNode = tagType;

	const [compatible, setCompatible] = useState(false);

	useEffect(() => {
		if (window?.EyeDropper) {
			setCompatible(true);
		}
	}, []);

	const handleClick = () => {
		if (!window?.EyeDropper) {
			console.error("Your browser does not support the EyeDropper API. To use this feature, you must use chrome or edge");
			return;
		}

		const eyeDropper = new window.EyeDropper();

		eyeDropper?.open()
			.then((result: { sRGBHex: string; }) => {
				if (!result?.sRGBHex) return;
				const hex = result.sRGBHex;
				onSelectColor(hex);
			})
			.catch((e: unknown) => {
				console.error(e);
			});
	};

	if (!compatible) return null;
	return (
		<TagNode
			className='clear no-style flex-center eye-dropper-btn'
			onClick={handleClick}
		>
			<Icon name={ICONS.EYE_DROPPER} size={size} color='default' />
		</TagNode>
	);
};

export default EyeDropper;
