import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
	onChange: (selection: string) => void;
	defaultOptions?: string[];
	label?: string;
	placeholder?: string;
	tooltip?: string[];
	defaultValue?: string;
	value?: string;
}

// interface TagProps {
// 	tag: string;
// 	remove: (tag: string) => void;
// }

// function Tag(props: TagProps) {
// 	return (
// 		<div className="tag">
// 			<span>{props.tag} <button className="no-style" onClick={() => props.remove(props.tag)}><Icon name={ICONS.CLOSE} size={10} color={COLORS.DEFAULT_GRAY} /></button></span>
// 		</div>
// 	)
// }

//https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads/17/documents/e1s08b9f73/Screen_Shot_2020-11-02_at_9.04.59_AM.png

export default function EnterSelectInput(props: Props): JSX.Element {
	const {
		defaultOptions = [],
		label,
		placeholder,
		tooltip,
		onChange,
		defaultValue,
		value,
	} = props;
	const [open, setOpen] = useState(false);
	const [entered, setEntered] = useState(false);
	const tagSelector = useRef<HTMLDivElement | null>(null);
	const textInput = useRef<HTMLInputElement | null>(null);
	const [image, text] = props.tooltip || [];

	const [visibleOptions, setVisibleOptions] = useState(defaultOptions);
	const [selectedText, setSelectedText] = useState(value || defaultValue || '');
	
	function handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
		setSelectedText(e.target.value);
	}

	function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
		const target = e.target as HTMLInputElement;
		setEntered(!!target.value);
	}
	
	function handleClick(tag: string) {
		setSelection(tag);
		setOpen(false);
	}

	useEffect(() => {
		if (selectedText.trim().length) {
			const filteredOptions = defaultOptions.filter((option) => option.toLowerCase().includes(selectedText.toLowerCase()));
			setVisibleOptions(filteredOptions);
		} else {
			setVisibleOptions(defaultOptions);
		}
	}, [selectedText, defaultOptions]);

	function setSelection(selected: string) {
		
		setSelectedText(selected);
		setEntered(true);
		if (textInput.current) { textInput.current.value = selected; }
	}

	function handleFocus() {
		setOpen(true);
	}

	useEffect(() => {
		// prevent initial load from clearing any default value
		if (defaultValue?.trim()?.length && !selectedText.trim().length) {
			// do nothing, because we already set defaultValue as the initial value in the useState above
			// and also as the defaultValue in the input tag below
		} else {
			onChange(selectedText);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedText, defaultValue]);

	useEffect(() => {
		const handleClick = (e: any) => {
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (tagSelector.current === item) { return; }
			}

			setOpen(false);
		};

		if (open) {
			window.addEventListener('click', handleClick);
		}

		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [open]);

	useEffect(() => {
		setVisibleOptions(defaultOptions);
	}, [defaultOptions]);

	return (
		<div
			className={classNames('field-group enter-select-container', {
				entered,
			})}
			ref={tagSelector}
		>
			{label && <label>{label}</label>}
			<div className="tooltip">
				{tooltip && (
					<img src={image} alt="tooltip" height="18px" width="auto" />
				)}{' '}
				<span className="tooltip-text">{tooltip && text}</span>
			</div>
			<div className="enter-select">
					<input
						value={selectedText}
						ref={textInput}
						type="text"
						onFocus={handleFocus}
						onBlur={handleBlur}
						placeholder={placeholder}
						onKeyUp={handleKeyUp}
						onChange={(e) => (setSelectedText(e.target.value))}
					/>
			</div>
			{!!defaultOptions && (
				<div className={classNames('enter-select-dropdown', { open })}>
					{visibleOptions.map((tag: string) => (
						<div
							key={tag}
							className="select-option"
							onClick={()=> handleClick(tag)}
						>
							<span>{tag}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
}