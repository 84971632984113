import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { PageModule, Survey, SurveyQuestion, SurveyType } from "../../../../../types/working-model";
import { useTranslate } from "../../../../../i18n/useTranslationModules";
import { removeStorageItem } from "../../../../../utils/local-storage";
import { getPollAnswerLocalStorageKey, getPollAnswerLocalStorageKeyBackwardsCompatible } from "./survey";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import { OptionalComponent } from "../../../../../utils/optional-component";
import QuizQuestionEndScreen from "./quiz-question-end-screen";
import GenericSurveyQuestion from "./generic-survey-question";


interface QuestionProps {
	survey: Survey;
	module: PageModule;
	template: string;
	preview?: boolean;
	sessionUuid?: string;
}

const Question: React.FC<QuestionProps> = ({
	survey,
	module,
	template,
	preview,
	sessionUuid,
}) => {
	const { t } = useTranslate("session");
	const event = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.event);
	const quizzesStatus = useTypedSelector((state) => state.LiveEventReducer.quizzesStatus);

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [viewAll, setViewAll] = useState(false);

	const maxQuestions = survey.questions.length;
	const isQuiz = survey.survey_type === SurveyType.quiz;

	const renderSurveyQuestion = (question: SurveyQuestion) =>
		<GenericSurveyQuestion
			currentQuestion={currentQuestion}
			maxQuestions={maxQuestions}
			module={module}
			question={question}
			sessionUuid={sessionUuid}
			setCurrentQuestion={setCurrentQuestion}
			handleShowResults={() => setCurrentQuestion(0)}
			survey={survey}
			template={template}
			viewAll={viewAll}
		/>;

	const handleRetake = () => {
		// This clears local storage and allows for retake
		if (event && !preview) {
			survey.questions.forEach((question: SurveyQuestion) => {
				//remove any previous answers from local storage
				const lsKey = getPollAnswerLocalStorageKey(question, survey.survey, event);
				const lsKeyBackwardsCompatible = getPollAnswerLocalStorageKeyBackwardsCompatible(question, survey.survey);
				removeStorageItem(lsKey);
				removeStorageItem(lsKeyBackwardsCompatible);
			});
		}
		setCurrentQuestion(-1);
	};

	// Hack to make reset() ("retake") work, basically a force re-mount of the question
	useEffect(() => {
		if (currentQuestion < 0) {
			setCurrentQuestion(0);
		}
	}, [currentQuestion]);

	useEffect(() => {
		// force view all if only one question AND if not a quiz
		// we want quiz to show Next button on single question instances to
		// be able to render QuizQuestionEndScreen component
		if (survey.questions.length <= 1 && !isQuiz) {
			setViewAll(true);
		}
	}, [isQuiz, survey]);

	return (<>
		{currentQuestion >= maxQuestions ? (
			<QuizQuestionEndScreen quizStatus={quizzesStatus[survey?.survey]} canRetake={survey.allow_retake} retake={handleRetake} />
		) : (
			currentQuestion >= 0 &&
			<div className="question-list">
				{(survey.questions.length > 1 || survey.allow_retake) &&
					<div className="view-all">
						{survey.questions.length > 1 ? (
							<button className="no-style clear" onClick={() => setViewAll(!viewAll)}>{viewAll ? t("Collapse Questions") : t("View All Questions")}</button>
						) : (<></>)}
						<OptionalComponent display={survey.allow_retake && survey.survey_type !== SurveyType.quiz}>
							<button className="no-style clear retake-button" onClick={handleRetake}>{t("Retake Survey")}</button>
						</OptionalComponent>
					</div>
				}
				{viewAll ? (
					survey.questions.map((question: SurveyQuestion) => {
						return (
							<div
								className={"question-wrapper"}
								key={`survey-question-${question.survey_question}`}
							>
								{renderSurveyQuestion(question)}
							</div>
						);
					})
				) : (
					<> {
						survey.questions[currentQuestion] && (
							<div
								key={`survey-question-${survey.questions[currentQuestion].survey_question}`}
							>
								{renderSurveyQuestion(survey.questions[currentQuestion])}
							</div>
						)
					}
					</>
				)}
			</div>
		)}
	</>);
};

export default Question;
