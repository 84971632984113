import { Fragment, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router";

import { useSessionTimestampText, useSessionTrackText, getSessionDuration } from "../../../../../hooks/agenda.hooks";
import { useGetEvent, useGetSession, useHasLiveStream, useIsNewModuleGrouping } from "../../../../../hooks/session.hooks";
import { TranslateFunction, useTranslate } from "../../../../../i18n/useTranslationModules";
import { useTypedSelector } from "../../../../../store/reducers/use-typed-selector";
import {
	ContentEditors,
	LanguagesAbbr,
	PageModule,
	PageModuleGroupModules,
	Session,
	SessionPreview,
	SessionTypesEnum,
	Speaker,
	TextOverrides,
} from "../../../../../types/working-model";
import { OptionalComponent } from "../../../../../utils/optional-component";
import useGetSessionTags from "../../../../../utils/use-get-session-tags";
import { getDefaultModuleAlignment, getTemplateClassName, getTextValue } from "../../../../../utils/utils";
import RenderEditorDescription from "../../../../general-ui/editable-text/render-editor-description";
import Modal from "../../../../general-ui/modal/modal";
import { ParamsProps } from "../../../live-event";
import SessionTags from "../../../modules/agenda/session-tags";
import { getStylingOverrides } from "../../../utils";
import ModalSocialButtons from "./modal-social-buttons";
import { getDurationStartEndTimes, getDurationWithType } from "../../../modules/agenda/utils/session-card.utils";
import MomentsAgo from "../../session-stream/moments-ago";
import { VideoControlPopup } from "../../session-stream/video/components/video-controls-popup";
import SpeakersLineup from "../speakers/speakers-lineup";
import images from '../../../../../images';
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";
import CustomColorOverridePicker from "../../../../general-ui/custom-color-override-picker/custom-color-override-picker";
import { EContentColorOverrideNames } from "@general-ui/edit-section-colors/edit-section-colors-modal";

import './session-details-modal.scss';
import './streamed-date.scss';

interface SessionDetailsModalProps {
	moduleGroup: PageModuleGroupModules;
	module: PageModule;
	template: string;
	portalClassName?: string;
	isPreview?: boolean;
	open: boolean;
	onClose: VoidFunction;
	setSpeaker: (speaker: Speaker) => void;
	showSpeakerDetailsModal: VoidFunction;
	speaker: Speaker | null;
}

interface StreamedDateProps {
	session: Session | SessionPreview;
	language: LanguagesAbbr;
	t: TranslateFunction;
	isAdmin?: boolean;
	handlePickCustomColor?: (themeMode: string, colorVariableName: string) => void;
	contentOverrideName?: EContentColorOverrideNames;
	defaultColorOverride?: string;
	classOverrides?: string;
}

interface DurationTextProps {
	session: Session;
}

const timeCheck = (session: Session | SessionPreview | undefined, hasLiveStream: boolean) => {
	const nowTime = Date.now();
	const startTime = session?.timestamp;
	const endTime = session?.end_timestamp;

	if (!startTime || !endTime) return {};

	// if has live stream, then ignore times and just send back that it's a current session
	if (hasLiveStream) {
		return {
			isFutureSession: false,
			isCurrentSession: true,
			isPastSession: false,
		};
	}

	const isFutureSession = nowTime < startTime;
	const isCurrentSession = nowTime >= startTime && nowTime < endTime;
	const isPastSession = nowTime > endTime;

	return { isFutureSession, isCurrentSession, isPastSession };
};

export function StreamedDate({
	session,
	language,
	t,
	isAdmin,
	handlePickCustomColor,
	contentOverrideName,
	defaultColorOverride,
	classOverrides,
}: StreamedDateProps): JSX.Element {
	const hasLiveStream = useHasLiveStream({ session, language });
	// This causes a rerender after the static session translations are loaded in
	const translationUpdates = useTypedSelector(event => event.LiveEventReducer.translationUpdates);

	// Originally Streamed 28 days ago. On hover, show tooltip Sep 27 * 11: 50PM * 35s
	const timeInterval = useRef<NodeJS.Timeout | null>(null);

	const [timeState, setTimeState] = useState(timeCheck(session, hasLiveStream));
	const timestampText = useSessionTimestampText({
		timestamp: session?.timestamp || 0,
		session: (session as Session),
		language,
		oneDigit: true
	});

	const duration = getSessionDuration(session);

	const momentsAgo = <MomentsAgo timestamp={session?.timestamp || 0} />;

	useEffect(() => {
		// Early return because we don't want to keep checking the time if the session is in the past
		if (timeState.isPastSession) return;

		// Recheck the time check every 5 seconds
		timeInterval.current = setInterval(() => {
			const { isFutureSession, isCurrentSession, isPastSession } = timeCheck(session, hasLiveStream);
			setTimeState(
				{
					isFutureSession: !!isFutureSession,
					isCurrentSession: !!isCurrentSession,
					isPastSession: !!isPastSession
				});
		}, 5000);

		return () => {
			if (timeInterval.current) {
				clearInterval(timeInterval.current);
			}
		};
	}, [hasLiveStream, session, timeState.isPastSession]);

	const currentStream = `${t("Now Streaming")}  ·  ${t('Runtime')}: ${duration}`;
	const futureStream = `${t("Streaming on")} ${timestampText}`;

	const PickerWrapper = isAdmin ? CustomColorOverridePicker : Fragment;

	const props = isAdmin ? {
		onFinish: handlePickCustomColor,
		contentOverrideName: contentOverrideName,
		defaultColorOverride: defaultColorOverride,
	} : {};

	return (
		<PickerWrapper {...props}>
			<div className="streamed-date-text ignore-modal-styling">
				<OptionalComponent display={!!translationUpdates}>
					<OptionalComponent display={!hasLiveStream && timeState.isFutureSession}>
						<p className={classOverrides}>{futureStream}</p>
					</OptionalComponent>

					<OptionalComponent display={hasLiveStream || timeState.isCurrentSession}>
						<p className={classOverrides}>{currentStream}</p>
					</OptionalComponent>

					<OptionalComponent display={!hasLiveStream && timeState.isPastSession}>
						<p className={`evt-typography-subtitle-3 ${classOverrides || ''}`}> {t("Originally Streamed")}</p>
						<div className="streamed-date-text-container">
							<span className={classOverrides || ''}>{momentsAgo}</span><VideoControlPopup text={`${timestampText}`} />
						</div>
						<p className={`evt-typography-subtitle-3 ${classOverrides || ''}`}>{duration && `${'\u00B7'} ${t('Runtime')}: `} {duration}</p>
					</OptionalComponent>
				</OptionalComponent>
			</div>
		</PickerWrapper>
	);
}

export function DurationText({ session }: DurationTextProps): JSX.Element {
	const { t } = useTranslate('session');

	const durationOD = session && getDurationWithType(session);
	const durationBroadcast = getDurationStartEndTimes(session);

	const duration = durationOD || durationBroadcast || "";

	return (
		<div className="streamed-date-text ignore-modal-styling">
			<p className='evt-typography-subtitle-3'>{t('Runtime')}: {duration}</p>
		</div>
	);
}

export default function SessionDetailsModal({
	module,
	template,
	portalClassName,
	isPreview,
	open,
	onClose,
	setSpeaker,
	showSpeakerDetailsModal,
	speaker,
}: SessionDetailsModalProps): JSX.Element {
	const session = useGetSession();
	const { t: sessionT } = useTranslate(`session.${session?.session}`);
	const { t } = useTranslate(`session`);
	const params = useParams<ParamsProps>();
	const { language } = params;
	const event = useGetEvent();
	const eventBundleContentEditor = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.content_editor);
	const workingEventContentEditor = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.content_editor);
	const sessionTags = useGetSessionTags(event, session);
	const isModuleGroupingV2 = useIsNewModuleGrouping();
	const disableSessionThumbnails = event?.settings?.disable_session_thumbnails === true;
	const contentEditor = eventBundleContentEditor || workingEventContentEditor;

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const trackText = useSessionTrackText({
		language,
		tracks: session?.tracks || [],
	});

	const { styling_overrides, content } = module;
	const sessionTitle = (session?.title[language] || session?.title.base || '') as string;
	const { description } = getTextValue(content, language);
	const stylingOverrides = getStylingOverrides(styling_overrides);
	const { descriptionOverrides } = stylingOverrides;

	const templateClassName = getTemplateClassName(event?.template.name);

	return (
		<Modal
			className={classNames('session-v2-modal session-details-modal ev-modal-wrapper live-event-container transparent-background', {
				'dark-mode': isDarkMode,
			}, template, `${templateClassName}-styles`)}
			title=""
			size="large"
			portalClassName={portalClassName}
			cancellable={true}
			closeable={true}
			open={open}
			trapFocus={false}
			padTitle={false}
			isAdmin={false}
			onRequestClose={speaker ? () => null : onClose}
		>
			<div className="session-details-modal-image">
				<OptionalComponent display={!disableSessionThumbnails}>
					{session?.image
						? <img src={session.image} alt={"session image"} />
						: <img src={images.LimelightBanner} alt={"session image"} />
					}
				</OptionalComponent>
				{session?.enable_share && (
					<ModalSocialButtons isPreview={isPreview} sessionTitle={sessionTitle} isDarkMode={isDarkMode} />
				)}
			</div>
			<div className="session-details-modal-content">

				{!!trackText && (
					<p className="session-details-modal-tracks evt-typography-subtitle-3 ignore-modal-styling">
						{trackText}
					</p>
				)}

				<h5 className={"session-details-modal-title evt-typography-h3 ignore-modal-styling"}>
					{sessionTitle}
				</h5>

				{session && session.session_type !== SessionTypesEnum.onDemand && <StreamedDate session={session} language={language} t={t} />}
				{session && session.session_type === SessionTypesEnum.onDemand && <DurationText session={session} />}

				<OptionalComponent display={isModuleGroupingV2}>
					<div
						className={classNames('session-details-modal-description evt-typography-subtitle-b3', descriptionOverrides)}
						dangerouslySetInnerHTML={{ __html: sessionT(`session.${session?.session}:description.${module.id}.description`, sessionT(`session.${session?.session}:description.description`, description)) }}
					/>
				</OptionalComponent>

				<OptionalComponent display={!isModuleGroupingV2}>
					<div className={classNames('session-details-modal-description evt-typography-subtitle-b3')}>
						<RenderEditorDescription
							description={sessionT(`session.${session?.session}:description.${module.id}.description`, sessionT(`session.${session?.session}:description.description`, description))}
							descriptionOverrides={classNames(descriptionOverrides)}
							useNew={contentEditor === ContentEditors.quill}
							defaultTextAlignment={getDefaultModuleAlignment(module, template)}
						/>
					</div>
				</OptionalComponent>

				<SpeakersLineup setSpeaker={setSpeaker} showSpeakerDetailsModal={showSpeakerDetailsModal} />

				<SessionTags tags={sessionTags} shouldRecalculate={false} />
			</div>
		</Modal>
	);
}
