import { isObject } from "underscore";
import { GetDefaultProduct } from "../../../store/utils/create-event";
import { CreateProduct, Product } from "../../../types/working-model";
import { updateTranslateKey } from "../../../utils/utils";

export enum Actions {
	SetProduct,
	NewProduct,
	RemoveImage,
	AddImage,
	SetTitle,
	SetBuyNowTitle,
	SetDescription,
	SetPrice,
	SetSalePrice,
	SetUseAttributes,
	SetCtaNewTab,
	SetCategories,
	SetAttributeName,
	SetAttributeValue,
	AddAttribute,
	SetBuyNowLink,
	SetBaseLanguage,
	SetLanguage,
	ClearProduct,
	FormatCurrency,
	SetBypassModal,
	SetIncludeUtms
}

type DispatchedAction<T, P> = { type: T, payload: P };
type SetProduct = DispatchedAction<Actions.SetProduct, Product & { language: string, baseLanguage: string }>;
type NewProduct = DispatchedAction<Actions.NewProduct, CreateProduct & { language: string, baseLanguage: string }>;
type AddImage = DispatchedAction<Actions.AddImage, string>;
type RemoveImage = DispatchedAction<Actions.RemoveImage, number>;
type SetTitle = DispatchedAction<Actions.SetTitle, string>;
type SetBuyNowTitle = DispatchedAction<Actions.SetBuyNowTitle, string>;
type SetDescription = DispatchedAction<Actions.SetDescription, string>;
type SetPrice = DispatchedAction<Actions.SetPrice, string>;
type SetSalePrice = DispatchedAction<Actions.SetSalePrice, string>;
type SetUseAttributes = DispatchedAction<Actions.SetUseAttributes, boolean>;
type SetCtaNewTab = DispatchedAction<Actions.SetCtaNewTab, boolean>;
type SetCategories = DispatchedAction<Actions.SetCategories, string[]>;
type SetAttributeName = DispatchedAction<Actions.SetAttributeName, [number, string]>;
type SetAttributeValue = DispatchedAction<Actions.SetAttributeValue, [number, string]>;
type AddAttribute = DispatchedAction<Actions.AddAttribute, unknown>;
type SetBuyNowLink = DispatchedAction<Actions.SetBuyNowLink, string>;
type SetBaseLanguage = DispatchedAction<Actions.SetBaseLanguage, string>;
type SetLanguage = DispatchedAction<Actions.SetLanguage, string>;
type ClearProduct = DispatchedAction<Actions.ClearProduct, unknown>;
type FormatCurrency = DispatchedAction<Actions.FormatCurrency, { field: 'price' | 'sale_price', value: string }>;
type SetBypassModal = DispatchedAction<Actions.SetBypassModal, boolean>;
type SetIncludeUtms = DispatchedAction<Actions.SetIncludeUtms, boolean>;

export type Action =
	SetProduct |
	NewProduct |
	AddImage |
	RemoveImage |
	SetTitle |
	SetBuyNowLink |
	SetDescription |
	SetBuyNowTitle |
	SetPrice |
	SetSalePrice |
	SetUseAttributes |
	SetCtaNewTab |
	SetCategories |
	SetAttributeName |
	SetAttributeValue |
	AddAttribute |
	SetBaseLanguage |
	SetLanguage |
	FormatCurrency |
	SetBypassModal |
	ClearProduct |
	SetIncludeUtms;

function assertState(state: unknown): asserts state is CreateProductState {
	if (!isObject(state)) {
		throw new Error(`state is null, illegal use of workingProduct, ${JSON.stringify(state)}`);
	}
}

type CreateProductState = ((CreateProduct | Product) & { baseLanguage: string, language: string });
export type CreateProductReducer = CreateProductState | null;

export const editProductReducer = (state: CreateProductReducer, action: Action): CreateProductReducer => {
	if (state === null) {
		switch (action.type) {
			case Actions.NewProduct:
			case Actions.SetProduct: {
				return action.payload;
			}
			default: return null;
		}
	}

	assertState(state);
	switch (action.type) {
		// selected a product to edit
		case Actions.NewProduct:
		case Actions.SetProduct: {
			return action.payload;
		}
		case Actions.AddImage: {
			return {
				...state,
				images: [...state.images, action.payload]
			};
		}
		case Actions.RemoveImage: {
			// remove image at index
			const newImages = [...state.images];
			newImages.splice(action.payload, 1);
			return {
				...state,
				images: newImages
			};
		}
		case Actions.SetTitle: {
			return {
				...state,
				product_title: updateTranslateKey({
					translateString: state.product_title,
					input: action.payload,
					baseLanguage: state.baseLanguage,
					language: state.language
				})
			};
		}
		case Actions.SetBuyNowLink: {
			return {
				...state,
				buy_now_link: action.payload
			};
		}
		case Actions.SetDescription: {
			return {
				...state,
				description: updateTranslateKey({
					translateString: state.description,
					input: action.payload,
					baseLanguage: state.baseLanguage,
					language: state.language
				})
			};
		}
		case Actions.SetBuyNowTitle: {
			return {
				...state,
				button_text: updateTranslateKey({
					translateString: state.button_text,
					input: action.payload,
					baseLanguage: state.baseLanguage,
					language: state.language
				})
			};
		}
		case Actions.SetPrice: {
			return {
				...state,
				price: updateTranslateKey({
					translateString: state.price,
					input: action.payload,
					baseLanguage: state.baseLanguage,
					language: state.language
				})
			};
		}
		case Actions.SetSalePrice: {
			return {
				...state,
				sale_price: updateTranslateKey({
					translateString: state.sale_price,
					input: action.payload,
					baseLanguage: state.baseLanguage,
					language: state.language
				})
			};
		}
		case Actions.SetUseAttributes: {
			return {
				...state,
				use_attributes: action.payload
			};
		}
		case Actions.SetCtaNewTab: {
			return {
				...state,
				cta_new_tab: action.payload
			};
		}
		case Actions.SetCategories: {
			return {
				...state,
				categories: action.payload
			};
		}
		case Actions.SetAttributeName: {
			const [index, type] = action.payload;
			return {
				...state,
				attributes: (state.attributes ?? []).map((attr, i) => {
					if (i === index) {
						return { ...attr, type };
					}

					return attr;
				})
			};
		}
		case Actions.SetAttributeValue: {
			const [index, value] = action.payload;
			return {
				...state,
				attributes: (state.attributes ?? []).map((attr, i) => {
					if (i === index) {
						return { ...attr, value };
					}

					return attr;
				})
			};
		}
		case Actions.AddAttribute: {
			return {
				...state,
				attributes: [...(state.attributes ?? []), { type: '', value: '' }]
			};
		}
		case Actions.SetBaseLanguage: {
			return {
				...state,
				baseLanguage: action.payload
			};
		}
		case Actions.SetLanguage: {
			return {
				...state,
				language: action.payload
			};
		}
		case Actions.FormatCurrency: {
			const { field, value } = action.payload;
			const valueAsNumber = Number(value);
			if (value.length && !isNaN(valueAsNumber)) {
				const localValue = valueAsNumber.toLocaleString('us', { style: 'currency', currency: 'USD' });
				const formatAsNumber = localValue.substring(1).replaceAll(',', '');
				return {
					...state,
					[field]: updateTranslateKey({
						translateString: state[field],
						input: formatAsNumber,
						baseLanguage: state.baseLanguage,
						language: state.language
					})
				};
			}

			return {
				...state,
				[action.payload.field]: action.payload.value
			};
		}
		case Actions.SetBypassModal: {
			return {
				...state,
				settings: { ...state.settings, bypass_modal: action.payload }
			};
		}
		case Actions.SetIncludeUtms: {
			return {
				...state,
				settings: { ...state.settings, include_utm_params: action.payload }
			};
		}
		case Actions.ClearProduct: {
			return null;
		}
		default: return state;
	}
};

export const getInitialState = (baseLanguage: string, language: string) =>
	({ ...GetDefaultProduct(), baseLanguage, language });