import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Icon, { COLORS, ICONS } from '../icon';
import { numClamp } from 'utils/utils';

interface TimepickerProps {
	label?: string;
	placeholder?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	style?: { [key: string]: any };
	initialValue?: number;
}

export default function Timepicker(props: TimepickerProps): JSX.Element {
	const { label, placeholder, onChange, style, initialValue } = props;
	const [value, setValue] = useState<string>(String(initialValue || ''));
	const [entered, setEntered] = useState(false);
	const input = useRef<HTMLInputElement | null>(null);

	function keyUp() {
		setEntered(!!input.current?.value);
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e);

		const value = Number(e.target.value);

		if (e.target.value && !isNaN(value)) {
			// max 6 months, min 5 minutes
			setValue(String(numClamp(value, 1, (60 * 24 * 365) / 2)));
		} else {
			// allow emptying to make typing the values easier
			setValue('');
		}
	};

	// if initial value, then set it
	useEffect(() => {
		if (initialValue && input?.current) {
			input.current.valueAsNumber = initialValue;
		}
	}, [initialValue]);

	function focusText() {
		input.current?.focus();
	}

	const error = !value || (value && Number(value) < 5);

	return (
		<div>
			{props.label && <label>{label}</label>}
			<div
				className={classNames('timepicker', { entered, error })}
				style={style}
			>
				<div className="timepicker-input">
					<input
						value={value}
						ref={input}
						type="number"
						placeholder={placeholder}
						onChange={handleChange}
						onKeyUp={keyUp}
						max={(60 * 24 * 365) / 2} // 6 months
					/>
				</div>
				<div onClick={focusText} style={{ cursor: "pointer", display: "flex" }}>
					<Icon
						name={ICONS.CLOCK}
						size={16}
						color={COLORS.WHITE}
					/>
				</div>
				{error && <span className="error-msg">Minimum duration 5 minutes</span>}
				{value === String(262800) && <span className="info-msg">Maximum duration 6 months</span>}
			</div>
		</div>
	);
}
