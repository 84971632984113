import { CreateVideo, Session, SessionPreview } from '../../../../../types/working-model';

const convertToLocalString = (expressionResult: number, digits = 1) => {
	return Math.floor(expressionResult).toLocaleString('en-US', {
		minimumIntegerDigits: digits,
		useGrouping: false

	});
};


export const getDurationStartEndTimes = (session: Session | SessionPreview | null | undefined): string => {
	if (!session || !session.end_timestamp) return '';

	/*
	if less than an hour: 30m
	if exactly an hour: 1h
	if greater than an hour: 1h 30m
	if exactly 2 hours: 2h
	*/

	const duration = ((session?.end_timestamp || 0) - (session?.timestamp || 0)) / 1000 ?? 0;

	const hours = convertToLocalString(duration / 3600);
	const remainingSeconds = duration % 3600;
	const minutes = convertToLocalString(remainingSeconds / 60);
	const seconds = convertToLocalString(remainingSeconds % 60);

	const hoursString = Number(hours) ? `${hours}h` : '';
	const minutesString = Number(minutes) ? `${minutes}m` : '';
	const secondsString = Number(seconds) ? `${seconds}s` : '';

	let timeString = '';
	if (+hours > 0) {
		timeString = `${hoursString} ${minutesString}`;
	}
	else {
		timeString = `${minutesString} ${secondsString}`;
	}

	return timeString;
};

export const getDurationWithType = (session: Session): string => {
	if (!session.on_demand_video) return '';

	const odVideo = session.on_demand_video as CreateVideo[];

	let timeString = '';

	if (!odVideo || !odVideo.length) {
		return timeString;
	}

	const duration = odVideo[0]?.duration ? odVideo[0]?.duration : (odVideo[0]?.recording_duration_ms ?? 0) / 1000 ?? 0;

	const hours = convertToLocalString(duration / 3600);
	const remainingSeconds = duration % 3600;
	const minutes = convertToLocalString(remainingSeconds / 60);
	const seconds = convertToLocalString(remainingSeconds % 60);

	const hoursString = Number(hours) ? `${hours}h` : '';
	const minutesString = Number(minutes) ? `${minutes}m` : '';
	const secondsString = Number(seconds) ? `${seconds}s` : '';

	if (+hours > 0) {
		timeString = `${hoursString} ${minutesString}`;
	}
	else {
		timeString = `${minutesString} ${secondsString}`;
	}

	return timeString;
};

export const getDuration = (session: Session): string => {
	if (!session.on_demand_video) return '';

	const odVideo = session.on_demand_video as CreateVideo[];

	let timeString = '';

	if (!odVideo || !odVideo.length) {
		return timeString;
	}

	const duration = odVideo[0]?.duration ? odVideo[0]?.duration : (odVideo[0]?.recording_duration_ms ?? 0) / 1000 ?? 0;

	const hours = convertToLocalString(duration / 3600, 2);
	const remainingSeconds = duration % 3600;
	const minutes = convertToLocalString(remainingSeconds / 60, 2);
	const seconds = convertToLocalString(remainingSeconds % 60, 2);

	if (+hours > 0) {
		timeString = `${hours}:${minutes}`;
	}
	else {
		timeString = `${minutes}:${seconds}`;
	}

	return timeString;
};