import { EListTypes } from '../components/admin/integrations/passcode-select';
import {
	EIntegrationType,
	InsertIntegrationsMappedBody,
	InsertIntegrationsMappedResponse,
	InsertMapOverridesBody,
	InsertMapOverridesResponse,
	InstanceMappingDB,
	IntegratedEventsDB,
	IntegrationActionDB,
	IntegrationsMapOverridesDB,
	IntegrationsMappedDB,
	IntegrationsMappedOptions,
	ITraySolution,
	ITraySolutionInstance,
	ITraySolutionMapped,
	ITrayUser,
	UpdateIntegrationsMappedBody,
	UpdateIntegrationsMappedResponse,
	UpdateMapOverridesBody,
	UpdateMapOverridesResponse
} from '../types/integrations';
import {
	CreateOauth2Integration,
	ICreateOIDCIntegration,
	ICreateSamlIntegration,
	Oauth2Integration,
	SingleSignOnTypes,
	ExchangeResponse,
	GlobalCurrency,
	SSOIntegrationProviderType,
	StripeResponse
} from '../types/working-model';
import { eCDNTypes, KollectiveConfiguration } from '../types/ecdn';
import { Put, Post, Get, Delete, Patch, GetJson } from './helpers';

export function GetStripeIntegration(token: string): Promise<StripeResponse> {
	return Get('/v3/admin/channel/stripe', token);
}

export interface StripeLinkResponse {
	url: string;
}

export function GetStripeLink(token: string): Promise<StripeLinkResponse> {
	return Post('/v3/admin/channel/stripe', token, {});
}

export function ContinueStripeLink(token: string): Promise<StripeLinkResponse> {
	return Put('/v3/admin/channel/stripe', token, {}, true);
}

export function SetStripeContact(token: string, email: string): Promise<{ email: string }> {
	return Post('/v3/admin/channel/stripe/contact', token, { email }, true).then((ok: boolean) => {
		if (ok)
			return ({ email });
		else
			return ({ email: "Could not set email" });
	});
}

export function GetGlobalCurrencies(token: string): Promise<GlobalCurrency[]> {
	return Get('/v3/admin/channel/global-currencies', token);
}

export function GetExchangeRate(token: string, currencyCode: string): Promise<ExchangeResponse> {
	return Get(`/v3/admin/channel/exchange-rate/${currencyCode}`, token);
}

export function GetGoogleAnalytics(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/google-analytics/${channel}`, token);
}

export function SetGoogleAnalytics(token: string, channel: number, ga: string): Promise<any> {
	return Post(`/v3/admin/google-analytics/${channel}`, token, { google_id: ga });
}

export function DisableGoogleAnalytics(token: string, channel: number): Promise<any> {
	return Delete(`/v3/admin/google-analytics/${channel}`, token);
}

export function getSolutions(token: string, channel: number): Promise<{ solutions: ITraySolution[], mappedSolutions: Record<string, ITraySolutionMapped> }> {
	return Get(`/v3/admin/channel/integrations/solutions/${channel}`, token);
}

export function getSolutionInstances(token: string, userId: string): Promise<any> {
	return Get(`/v3/admin/channel/integrations/solution-instances/${userId}`, token);
}

export function getChannelInstances(token: string, channel: number): Promise<IntegrationsMappedDB[]> {
	return Get(`/v3/admin/channel/integrations/instances/${channel}`, token);
}

export function createSolutionInstance(token: string, channel: number, userId: string, solutionId: string, solutionName: string, type: EIntegrationType, trayUser: ITrayUser): Promise<any> {
	return Post(`/v3/admin/channel/integrations/create/solution-instance`, token, { channel, userId, solutionId, solutionName, type, trayUser });
}

export function createSolutionInstanceMapped(
	token: string,
	body: InsertIntegrationsMappedBody
): Promise<InsertIntegrationsMappedResponse> {
	return Post(`/v3/admin/channel/integrations/mappings`, token, body);
}

export function updateSolutionInstanceMapped(
	token: string,
	body: UpdateIntegrationsMappedBody
): Promise<UpdateIntegrationsMappedResponse> {
	return Put(`/v3/admin/channel/integrations/mappings`, token, body);
}

export function createSolutionInstanceOverride(
	token: string,
	body: InsertMapOverridesBody
): Promise<InsertMapOverridesResponse> {
	return Post(`/v3/admin/channel/integrations/overrides`, token, body);
}

export function updateSolutionInstanceOverrides(
	token: string,
	body: UpdateMapOverridesBody
): Promise<UpdateMapOverridesResponse> {
	return Put(`/v3/admin/channel/integrations/overrides`, token, body, true);
}

export function toggleInstance(token: string, userId: string, channel: number, instanceId: string, enabled: boolean, type?: EIntegrationType, customMapping?: boolean, eventOverride?: boolean, event_uuid?: string): Promise<IntegrationsMappedDB | ITraySolutionInstance> {
	return Post(`/v3/admin/channel/integrations/toggle-instance`, token, { userId, channel, type, instanceId, enabled, customMapping, eventOverride, event_uuid });
}

export function toggleEventIntegration(token: string, instance_uuid: string, event_uuid: string, channel: number, enable: boolean): Promise<{ added?: IntegratedEventsDB[], removed?: IntegratedEventsDB[] }> {
	if (enable) {
		return Post(`/v3/admin/channel/integrations/integration-event`, token, { instance_uuid, event_uuid, channel });
	} else {
		return Delete(`/v3/admin/channel/integrations/integration-event`, token, { instance_uuid, event_uuid, channel }, true);
	}
}

export function sendTestRecord(token: string, channel: number, instance_mapped: IntegrationsMappedDB, instance_overrides?: IntegrationsMappedOptions): Promise<{ response: string }> {
	return Post(`/v3/admin/channel/integrations/test-record`, token, { instance_mapped, instance_overrides, channel });
}

export function getEmbedUrl(token: string, userId: string, instanceId: string): Promise<{ url: string }> {
	return Get(`/v3/admin/channel/integrations/auth-window/${userId}/${instanceId}`, token);
}

export function getTrayUserId(token: string, channel: number, name: string, uuid: string): Promise<ITrayUser> {
	return Post(`/v3/admin/channel/integrations/user`, token, { channel, name, uuid });
}

export function updateSolutionInstance(token: string, userId: string, instanceId: string, configName: string, configValue: string, mapping?: Record<string, number>, listType?: EListTypes): Promise<any> {
	return Post(`/v3/admin/channel/integrations/solution-instance/update`, token, { userId, instanceId, configName, configValue, mapping, listType }, true);
}

export function UpdateSAMLIntegration(token: string, data: ICreateSamlIntegration, ssoProvider: SSOIntegrationProviderType): Promise<any> {

	let route = `/v3/admin/channel/integrations/saml`;

	switch (ssoProvider) {
		case SSOIntegrationProviderType.JACKSON: {
			route = `/v3/admin/channel/integrations/saml-jackson`;
			break;
		}
		default: {
			break;
		}
	}

	// Bubble up the error message
	return Post(route, token, data, undefined, {}, undefined, true);
}

export function UpdateJacksonSAMLIntegration(token: string, data: ICreateSamlIntegration): Promise<any> {
	return Post(`/v3/admin/channel/integrations/saml`, token, data);
}

export function UpdateOIDCIntegration(token: string, data: ICreateOIDCIntegration): Promise<any> {
	return Post(`/v3/admin/channel/integrations/oidc`, token, data);
}

export function GetSSOIntegrationsForChannel(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/channel/sso/${channel}`, token);
}

export function GetSSOProviderDetails(token: string, channel: number, type: SingleSignOnTypes, ssoProvider: SSOIntegrationProviderType): Promise<any> {
	return GetJson({
		path: `/v3/admin/channel/sso-provider/${channel}/${type}?integrationType=${ssoProvider}`,
		token
	});
}

export function GetOauth2IntegrationForChannel(token: string, channel: number): Promise<Oauth2Integration | undefined> {
	return Get(`/v3/admin/channel/oauth2/${channel}`, token);
}

export function GetPublicOauth2IntegrationForChannel(token: string, channel: number): Promise<Oauth2Integration | undefined> {
	return Get(`/v3/admin/channel/oauth2/public/${channel}`, token);
}

export function CreateOauth2IntegrationForChannel(token: string, channel: number, integration: CreateOauth2Integration): Promise<Oauth2Integration> {
	return Post(`/v3/admin/channel/oauth2/${channel}`, token, { integration });
}

export function UpdateOauth2Integration(token: string, channel: number, integration: Oauth2Integration): Promise<Oauth2Integration> {
	return Patch(`/v3/admin/channel/oauth2/${channel}`, token, { integration }, true);
}

export function ToggleOauth2Integration(token: string, channel: number, uuid: string, on: boolean): Promise<boolean> {
	return Put(`/v3/admin/channel/oauth2/${channel}/${uuid}`, token, { on }, false);
}

export function ToggleAdminUseOauth2Integration(token: string, channel: number, uuid: string, on: boolean): Promise<boolean> {
	return Put(`/v3/admin/channel/oauth2AdminLogin/${channel}/${uuid}`, token, { on }, false);
}

export function DeleteOauth2Integration(token: string, channel: number, uuid: string): Promise<boolean> {
	return Delete(`/v3/admin/channel/oauth2/${channel}/${uuid}`, token, undefined, false);
}

export interface AvailableInstance extends IntegrationsMappedDB {
	integrations_event?: IntegratedEventsDB;
}

export function GetEventInstances(token: string, event_uuid: string, channel: number): Promise<{ available_instances: AvailableInstance[], enabled_instances: AvailableInstance[] }> {
	return Get(`/v3/admin/channel/integrations/event-instances/${channel}/${event_uuid}`, token);
}

export function GetSolutionActions(token: string, solution_uuid: string): Promise<IntegrationActionDB[]> {
	return Get(`/v3/admin/channel/integrations/solution-actions/${solution_uuid}`, token);
}

export function GetEventOverrides(token: string, instance_uuid: string, entity_uuid: string, channel: number): Promise<InstanceMappingDB> {
	return Get(`/v3/admin/channel/integrations/mappings/${instance_uuid}/${entity_uuid}/${channel}`, token);
}

export interface DeletedInstance {
	mapped: IntegrationsMappedDB[],
	overrides: IntegrationsMapOverridesDB[];
	events: IntegratedEventsDB[]
}

export function DeleteSolutionInstance(token: string, channel: number, instance_uuid: string, tray_id: string): Promise<DeletedInstance> {
	return Delete(`/v3/admin/channel/integrations/instances`, token, { channel, instance_uuid, tray_id }, true);
}

export function GetECDNIntegration(token: string, channel: number): Promise<{ [key in eCDNTypes]: KollectiveConfiguration }> {
	return GetJson({ path: `/v3/admin/events/eCDN-integration/${channel}`, token });
}

export function SetECDNIntegration(token: string, channel: number, type: eCDNTypes, data: any): Promise<void> {
	return Put(`/v3/admin/events/eCDN-integration/${channel}`, token, { type, data });
}
