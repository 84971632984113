import { m, LazyMotion, domAnimation, AnimatePresence } from "framer-motion";


type Props = {
	display?: boolean;
	children: JSX.Element | JSX.Element[];
	className?: string;

	// when set, uses a max-height animation to animate the component appearing in the Y axis
	// set to as close to but not under the ideal height for your component (if known)
	// because this is max height if the el contains more content than this max height it will cut off
	usingHeight?: number;
};

export function OptionalComponent({ display, children, usingHeight, className }: Props): JSX.Element | null {
	return (
		<LazyMotion features={domAnimation}>
			<AnimatePresence>
				{display && (
					<m.div
						initial={{
							opacity: 0,
							maxHeight: usingHeight ? 0 : undefined
						}}
						animate={{
							opacity: 1,
							maxHeight: usingHeight
						}}
						exit={{
							opacity: 0,
							maxHeight: usingHeight ? 0 : undefined
						}}
						className={className}
					>
						{children}
					</m.div>
				)}
			</AnimatePresence>
		</LazyMotion>
	);
}
