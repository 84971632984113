import { GetChat, GetChatPaginated } from "../../../connection/chat";
import { Action, AsyncAction } from "../../../types/actions";
import { IChatPromotion, ISocketCommentProps } from "../../../types/working-model";

export const INCOMING_CHAT_MESSAGE = "INCOMING_CHAT_MESSAGE";
export function incomingChatMessage(message: ISocketCommentProps): Action {
	return {
		type: INCOMING_CHAT_MESSAGE,
		payload: message
	};
}

export const UNAPPROVE_CHAT_MESSAGE = "UNAPPROVE_CHAT_MESSAGE";
export function unapproveChatMessage(id: number): Action {
	return {
		type: UNAPPROVE_CHAT_MESSAGE,
		payload: id
	};
}

export const DELETE_CHAT_MESSAGE = "DELETE_CHAT_MESSAGE";
export function deleteChatMessage(id: number): Action {
	return {
		type: DELETE_CHAT_MESSAGE,
		payload: id
	};
}

export const UNDELETE_CHAT_MESSAGE = "UNDELETE_CHAT_MESSAGE";
export function undeleteChatMessage(message: ISocketCommentProps): Action {
	return {
		type: UNDELETE_CHAT_MESSAGE,
		payload: message
	};
}

export const LOAD_CHAT_MESSAGES = "LOAD_CHAT_MESSAGES";
export function loadChatMessages(sessionUuid: string, lang: string, blProfile?: number): AsyncAction {
	return {
		type: LOAD_CHAT_MESSAGES,
		promise: GetChat(sessionUuid, lang, blProfile)
	};
}

export const LOAD_CHAT_MESSAGES_PAGE = "LOAD_CHAT_MESSAGES_PAGE";
type LoadChatMessagesPageProps = {
	sessionUuid: string;
	lang: string | null;
	blProfile?: number;
	cursor?: number;
	size?: number;
	moderator?: boolean;
	token?: string;
}

export function loadChatMessagesPage(props: LoadChatMessagesPageProps): AsyncAction {
	return {
		type: LOAD_CHAT_MESSAGES_PAGE,
		promise: GetChatPaginated(props),
		meta: {
			chatKey: `${props.sessionUuid}-${props.lang ?? 'all'}}`,
			reset: !props.cursor
		}
	};
}

export const SET_CHAT_PROMOTION = "SET_CHAT_PROMOTION";
export function setChatPromotion(chatPromotion: IChatPromotion | null): Action {
	return {
		type: SET_CHAT_PROMOTION,
		payload: chatPromotion
	};
}

export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
export function clearChatMessages(): Action {
	return {
		type: CLEAR_CHAT_MESSAGES,
		payload: null
	};
}

export const TOGGLE_CHAT_PANEL = "TOGGLE_CHAT_PANEL";
export function toggleChatPanel(open: boolean): Action {
	return {
		type: TOGGLE_CHAT_PANEL,
		payload: open,
	};
}

export const EMBED_CLEAR_HAS_NEW_MESSAGE = "EMBED_CLEAR_HAS_NEW_MESSAGE";
export function embedClearHasNewMessage(): Action {
	return {
		type: EMBED_CLEAR_HAS_NEW_MESSAGE,
		payload: null
	};
}

export const SET_CHAT_AS_MODERATOR = "SET_CHAT_AS_MODERATOR";
export function setChatAsModerator(moderator: boolean): Action {
	return {
		type: SET_CHAT_AS_MODERATOR,
		payload: moderator
	};
}