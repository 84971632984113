import { GetCustomReactions, InsertCustomReaction } from "../../../connection/reactions";
import { AsyncAction } from "../../../types/actions";

export const LOAD_CUSTOM_REACTIONS = "LOAD_CUSTOM_REACTIONS";
export function loadCustomReactions(token: string, channel: number): AsyncAction {
  return {
    type: LOAD_CUSTOM_REACTIONS,
    promise: GetCustomReactions(token, channel)
  };
}

export const ADD_CUSTOM_REACTION = "ADD_CUSTOM_REACTION";
export function addCustomReaction(token: string, channel: number, name: string, url: string): AsyncAction {
  return {
    type: ADD_CUSTOM_REACTION,
    promise: InsertCustomReaction(token, channel, name, url)
  };
}
