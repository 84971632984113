import chroma from 'chroma-js';
import { ColorValueIndexes, EPaletteModes, hex, opacity } from 'types/theme-packs';

/**
 * Checks the shade of a given color.
 * @param hex - The hexadecimal representation of the color.
 * @returns EPaletteModes ("light" or "dark").
 */
const isColorLightOrDark = (hex: string): EPaletteModes => {
	// make sure chroa doesn't crash the app with invalid hex
	if (!chroma.valid(hex)) {
		console.warn('isColorLightOrDark: invalid hex', hex);
		return EPaletteModes.Light;
	}
	return chroma(hex).luminance() > 0.5 ? EPaletteModes.Light : EPaletteModes.Dark;
};

export default isColorLightOrDark;

/**
 * Blends multiple colors with given opacities and checks if the resulting color is light or dark.
 * @param colors - An array of colors to blend.
 * @param opacities - An array of opacities corresponding to the colors.
 * @param checkedColor - The color we want to compare with the blended color.
 * @returns EPaletteModes ("light" or "dark").
 */
export const isColorLightOrDarkOnBlendedBackground = (
	colors: [hex, opacity][],
	checkedColor: string
): EPaletteModes => {
	if (!colors.length) {
		console.warn('isColorLightOrDarkOnBlendedBackground: no colors');
		return EPaletteModes.Light;
	}
	if (!chroma.valid(checkedColor)) {
		console.warn('isColorLightOrDarkOnBlendedBackground: invalid checkedColor');
		return EPaletteModes.Light;
	}

	const blendedColor = chroma(getBlendedColor(colors));

	// Calculate the luminance of the blended color
	const blendedLuminance = blendedColor.luminance();

	// Calculate the luminance of the text color
	const textLuminance = chroma(checkedColor).luminance();

	// Compare the luminance of the text color with the blended color
	return textLuminance > blendedLuminance ? EPaletteModes.Light : EPaletteModes.Dark;
};

/**
 * Blends multiple colors with given opacities.
 * @param colors - An array of colors to blend.
 * @returns The hexadecimal representation of the blended color.
 * @example
 * getBlendedColor([['#FF0000', 0.5], ['#00FF00', 0.5]]); // '#FFFF00'
 */
export const getBlendedColor = (colors: [hex, opacity][]): string => {
	// Ensure valid colors and opacities
	if (colors.some(color => !chroma.valid(color?.[ColorValueIndexes.hex]))) {
		console.warn('getBlendedColor: invalid color(s)');
		return colors[0][ColorValueIndexes.hex];
	}

	// Blend the colors sequentially
	let blendedColor = chroma(colors[0][ColorValueIndexes.hex]).alpha(colors[0][ColorValueIndexes.opacity]);
	for (let i = 1; i < colors.length; i++) {
		const hex = colors[i][ColorValueIndexes.hex];
		const opacity = colors[i][ColorValueIndexes.opacity];
		const colorWithOpacity = chroma(hex).alpha(opacity);
		blendedColor = chroma.mix(blendedColor, colorWithOpacity, 0.5, 'rgb');
	}

	return blendedColor.hex();
};
