import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'underscore';
import ReactDOM from 'react-dom';

import Icon, { COLORS, ICONS } from '../icon';
import Checkbox from '../checkbox/checkbox';
import Switch from '../switch/switch';
import TranslateLangs from '../../../utils/translate-langs.json';
import { useDefaultEventLanguage } from '../../../utils/use-default-event-language';
import { setADropdownIsOpen } from '../../../store/actions/admin/create-event';
import { useAppDispatch } from '../../../store/reducers/use-typed-selector';

export interface Language {
	label: string;
	code: string;
}

type code = string;

interface Props {
	onChange: (languages: Language[], defaultLang: Language) => void;
	languages: Language[];
	enabledLanguages?: code[];
	defaultLanguage?: Language,
	label?: string;
	placeholder?: string;
	tooltip?: [string | (() => JSX.Element), string];
	renderTooltip?: () => JSX.Element;
	limit?: number;
	disableDefault?: boolean;
	disableDefaultRemoval?: boolean;
	chooseableLanguages?: Language[];
	disableInput?: boolean;
	trackLeftPosition?: boolean;
	forceTop?: number;
	className?: string;
	disabled?: boolean;
}

interface TagProps {
	disableRemoval?: boolean;
	lang: Language;
	remove: (lang: Language) => void;
}

function Tag(props: TagProps) {
	return useMemo(() => (
		<div className="tag">
			<span>
				{props.lang.label}{' '}
				{!props.disableRemoval &&
					<button
						className="no-style"
						onClick={() => props.remove(props.lang)}
					>
						<Icon
							name={ICONS.CLOSE}
							size={10}
							color={COLORS.DEFAULT_GRAY}
						/>
					</button>
				}
			</span>
		</div>
	), [props]);
}

//https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads/17/documents/e1s08b9f73/Screen_Shot_2020-11-02_at_9.04.59_AM.png

export default function LanguageSelectInput(props: Props): JSX.Element {
	const dispatch = useAppDispatch();

	const {
		onChange,
		defaultLanguage,
		languages,
		label,
		placeholder,
		tooltip,
		limit,
		disableDefault,
		chooseableLanguages,
		disableDefaultRemoval,
		disableInput,
		trackLeftPosition,
		forceTop,
		className,
		disabled = false,
		enabledLanguages
	} = props;

	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const [width, setWidth] = useState(100);
	const [maxWidth, setMaxWidth] = useState('');
	const [renderItems, setRenderItems] = useState(false);
	const useForceTop = typeof forceTop === 'number';

	const tagSelector = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const defaultEventLanguage = defaultLanguage ?? useDefaultEventLanguage();

	const isDeleteable = useCallback((lang: Language): boolean => {
		if (lang.code === defaultEventLanguage.code) {
			return !disableDefaultRemoval ?? true;
		}
		return true;
	}, [disableDefaultRemoval, defaultEventLanguage]);

	const [image, text] = tooltip || [];
	const entered = languages.length > 0;
	// If this is the eventLanguage selector, we want to prefilter the list of languages that can be chosen from
	const listOfAvailableLanguages = chooseableLanguages?.length ? chooseableLanguages : TranslateLangs;
	// The list of tags in the tag search dropdown
	const dropdownList = searchTerm
		? listOfAvailableLanguages.filter((lang: Language) => lang.label.toLowerCase().includes(searchTerm.toLowerCase()))
		: listOfAvailableLanguages;

	const handleLangChange = (updatedLangs: Language[], updatedDefaultLang?: Language) => {
		if (disableInput) return;
		// If no selected languages, default to English (first in language list json array)
		if (!updatedLangs.length) updatedLangs = [listOfAvailableLanguages[0]];
		if (limit && updatedLangs.length > limit) {
			updatedLangs.shift();
		}
		// Prevent duplicates
		const unique = new Set(updatedLangs);
		if (updatedDefaultLang) {
			// Add new default language in case it's not in the set
			unique.add(updatedDefaultLang);
		} else {
			// Use existing, but only if it's in the list; default to first in list
			updatedDefaultLang = defaultLanguage && updatedLangs.includes(defaultLanguage)
				? defaultLanguage
				: updatedLangs[0];
		}

		onChange(Array.from(unique.values()), updatedDefaultLang);
	};

	const removeLang = (languageToRemove: Language) => {
		if (disableInput) return;
		const updatedLangs = languages.filter((lang: Language) => lang.code !== languageToRemove.code);
		handleLangChange(updatedLangs);
	};

	const addLang = (languageToAdd: Language) => {
		if (disableInput) return;
		handleLangChange([...languages, languageToAdd]);
		setSearchTerm('');

		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	const handleCheck = (language: Language, checked: boolean) => {
		if (disableInput) return;
		if (checked) {
			if (isDeleteable(language)) {
				removeLang(language);
			}
		} else {
			addLang(language);
		}
	};

	const handleSwitch = (language: Language, isOn: boolean) => {
		if (disableInput) return;
		// Can't switch off a default; can only switch on another language
		if (isOn) {
			handleLangChange(languages, language);
		}
	};

	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			const match = dropdownList.length === 1 ? dropdownList[0] : false;
			if (match) {
				addLang(match);
			}
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (disableInput) return;
		setSearchTerm(e.target.value);
	};

	function handleFocus() {
		if (disableInput) return;
		setOpen(true);
		dispatch(setADropdownIsOpen(true));
	}

	const handleClick = useCallback((e: MouseEvent) => {
		const path = e.composedPath();

		for (const item of path) {
			if (tagSelector.current === item) {
				inputRef.current?.focus();
				return;
			}
		}
		dispatch(setADropdownIsOpen(true));
		setOpen(false);
	}, []);

	const handleKeyDown = useCallback((event: KeyboardEvent) => {
		if (event.code === 'Tab' || event.key === 'Tab') {
			dispatch(setADropdownIsOpen(false));
			setOpen(false);
		}
	}, []);

	useEffect(() => {
		if (open) {
			document.addEventListener('click', handleClick);
			document.addEventListener('keydown', handleKeyDown);
		} else {
			document.removeEventListener('click', handleClick);
			document.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			document.removeEventListener('click', handleClick);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleClick, handleKeyDown, open]);

	useEffect(() => {
		ReactDOM.unstable_batchedUpdates(() => {
			if (!tagSelector.current) return;
			setTop(useForceTop ? forceTop : (tagSelector.current.offsetTop ?? 0) + tagSelector.current.offsetHeight + 28);
			setWidth(tagSelector.current.offsetWidth ?? 0);
			if (trackLeftPosition) {
				setLeft(tagSelector.current.offsetLeft ?? 0);
			}
		});
	}, [open, languages, trackLeftPosition]);

	useEffect(() => {
		const el = tagSelector?.current;

		// initialize positions
		if (open) {
			ReactDOM.unstable_batchedUpdates(() => {
				setRenderItems(true);
				setTop(useForceTop ? forceTop : (el?.offsetTop ?? 0) + (el?.offsetHeight ?? 0) + 28);
				setMaxWidth(el?.getBoundingClientRect().width + 'px');
				if (trackLeftPosition) {
					setLeft(el?.offsetLeft ?? 0);
				}
			});
		}

		// reset positions on resize
		const handleResize = throttle(() => {
			ReactDOM.unstable_batchedUpdates(() => {
				setTop(useForceTop ? forceTop : (el?.offsetTop ?? 0) + (el?.offsetHeight ?? 0) + 28);
				setMaxWidth(el?.getBoundingClientRect().width + 'px');
				if (trackLeftPosition) {
					setLeft(el?.offsetLeft ?? 0);
				}
			});
		}, 50);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [tagSelector, trackLeftPosition, open]);

	const handleClosed = () => {
		if (!open) {
			setRenderItems(false);
		}
	};

	return (
		<div
			className={classNames('field-group tag-select-container language-select', className, {
				entered,
				disable: disableInput,
				disabled
			})}
			ref={tagSelector}
		>
			<div style={{ display: 'flex', alignItems: 'center' }} className="label-container">
				{label && <label>{label}</label>}
				<div className="tooltip">
					{tooltip ? (
						typeof image === 'function' ? (
							image()
						) : (
							<img
								src={image}
								alt="tooltip"
								height="18px"
								width="auto"
							/>
						)
					) : null}{' '}
					<span className="tooltip-text">{tooltip && text}</span>
				</div>
			</div>
			<div className={classNames("tag-select", { disabled: disableInput })}>
				{languages.map((lang: Language) => (
					<Tag key={lang.code} lang={lang} remove={removeLang} disableRemoval={!isDeleteable(lang)} />
				))}
				<input
					type="text"
					onFocus={handleFocus}
					placeholder={placeholder}
					onKeyDown={handleInputKeyDown}
					onChange={handleInputChange}
					value={searchTerm}
					ref={inputRef}
				/>
			</div>
			<div
				className="select-dropdown-container"
				style={{ pointerEvents: open ? 'auto' : 'none' }}
			>
				<div
					className={classNames('tag-select-dropdown', { open })}
					onTransitionEnd={handleClosed}
					style={{
						left,
						top,
						width: trackLeftPosition ? maxWidth : width,
						maxWidth,
						// if we're tracking left position, we don't want to set the margin or it will be off
						...(trackLeftPosition ? { marginLeft: '0', marginRight: '0' } : {})
					}}
				>
					{renderItems && dropdownList.map((language: Language) => {
						const isSelectedDefault = language.code === defaultLanguage?.code;
						const isInSelected = languages.includes(language);
						if (enabledLanguages && !enabledLanguages.includes(language.code)) {
							return null;
						}
						return (
							<div key={language.code} className={classNames("tag-option", { disabled: disableInput })} onClick={() => handleCheck(language, isInSelected)}>

								<div
									className="tag-left"
								>
									<Checkbox
										onChange={() => handleCheck(language, isInSelected)}
										checked={isInSelected}
										value={language.code}
									/>
									<span>
										{language.label}
										{isSelectedDefault
											? ' (default)'
											: ''}
									</span>
								</div>
								{!disableDefault && <div
									className={classNames('tag-right', {
										'is-default': isSelectedDefault,
									})}
								>
									<span className="default-label">
										{isSelectedDefault
											? 'Default'
											: 'Make default'}
									</span>
									<Switch
										value={language.code}
										on={isSelectedDefault}
										onClick={(_, isOn) => handleSwitch(language, isOn)}
									/>
								</div>}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
