import { Get, Post, Patch } from './helpers';

export const getHeaders = (token = ''): Record<string, string> => {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'BL-TOKEN': `${token}`,
		//'X-URI': window?.location?.pathname ?? window?.location?.toString(),
	};
};

export function GetBroadcastServer(token: string, session_uuid: string): Promise<any> {
	return Post(`/v3/admin/broadcast/${session_uuid}`, token, {});
}

export function CheckBroadcastServer(token: string): Promise<any> {
	return Patch(`/v3/admin/broadcast/checkServerStatus`, token, {});
}

export function StartBroadcast(token: string, session_uuid: string, resolution: string): Promise<any> {
	return Patch(`/v3/admin/broadcast/${session_uuid}/start/`, token, { resolution });
}

export function StopBroadcast(token: string, session_uuid: string): Promise<any> {
	return Patch(`/v3/admin/broadcast/${session_uuid}/stop`, token, {});
}

export function GetEventsSessionRtmpEndpoint(token: string, session_uuid: string, language: string): Promise<any> {
	return Get(`/v3/admin/events/rtmp/${session_uuid}/${language}`, token);
}

export function GetEventsLegacyRtmpEndpoint(token: string, channel: number): Promise<any> {
	return Get(`/v3/admin/broadcast/events-legacy/endpoint/${channel}`, token);
}

export function GetRtmpEndpoint(token: string, session_uuid: string): Promise<any> {
	return Get(`/v3/admin/broadcast/endpoint/${session_uuid}`, token);
}

export async function GetBroadcastingErrorLogs(session_uuid: string, token: string): Promise<any> {
	return Get(`/v3/admin/broadcast/error-logs/${session_uuid}`, token);
}

export function ResetBroadcastServer(token: string, session_uuid: string): Promise<any> {
	return Post(`/v3/admin/broadcast/reset-broadcast-state/${session_uuid}`, token, {});
}

export const GetBroadcastStreamingConfiguration = (accessToken: string, sessionID: string): any => {
	return Get(`/v3/admin/broadcast/broadcastStreamingConfiguration/${sessionID}`, accessToken);
};

export const GetSessionServerState = (accessToken: string, sessionUUID: string): any => {
	return Get(`/v3/admin/broadcast/check-server-status/${sessionUUID}`, accessToken);
};
