import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router";
import { v4 as uuidV4 } from 'uuid';
import { useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";
import { isObject } from "underscore";
import { GoogleOAuthProvider } from '@react-oauth/google';

import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import ModalComponent from '../../../general-ui/modal/modal';
import TagSelectInput from '../../../general-ui/tag-select/tag-select';
import LanguageSelectInput, { Language } from '../../../general-ui/select/language-select';
import { BrandliveEvent, BreakoutSession, LanguagesAbbr, Session, SessionTypesEnum, SessionTypesFeatures } from "../../../../types/working-model";
import { GetChannelTracks } from "../../../../connection/create-event";
import TranslateLangs from '../../../../utils/translate-langs.json';
import { canShowLanguageSelector, numClamp, updateTranslateKey, validateUrl } from "../../../../utils/utils";
import { PrimaryTooltip } from "./session-modal";
import useRequiredFields from "../../../../utils/use-required-fields";
import { ParamsProps } from "../../../live-event/live-event";
import SessionTypeSelect from "../../../general-ui/session-type-select/session-type-select";
import { defaultRooms } from './breakout-session-modal/breakout-chat-room-modals/breakout-chat-room-modals.config';
import SessionStartTimeFields from "./session-start-time-fields";
import { getAdmins } from "../../../../store/actions/admin/bl-admin";
import Switch from "../../../general-ui/switch/switch";
import { Tooltip, TooltipPositions } from "../../../general-ui/tooltip/tooltip";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";
import BreakoutSessionsModal from "./breakout-session-modal/breakout-session-modal";
import { getSessionWithLanguagesFromWorkingEvent } from "../../utils/create-new-session";

import "react-datepicker/dist/react-datepicker.css";
import { OptionalComponent } from "utils/optional-component";

interface EditSessionModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (session: Session) => void;
	startingSession: Session;
	disbleSessionTypeSwitching?: boolean;
	isEditingExistingBreakout?: boolean;
	isEventEditor?: boolean;
	defaultLanguage: LanguagesAbbr;
	templateEvent?: BrandliveEvent;
}

const defaultDuration = 60;	// minutes

/**
 *	HEY THERE! ARE YOU MAKING EDITS TO THIS COMPONENT?
 *	Please consider adding your changes to session-modal.tsx
 */

const EditSessionModal: React.FC<EditSessionModalProps> = ({
	isOpen,
	onClose,
	onSave,
	startingSession,
	disbleSessionTypeSwitching = false,
	isEditingExistingBreakout = false,
	isEventEditor = true,
	defaultLanguage,
	templateEvent
}) => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const admins = useTypedSelector(state => state.ChannelAdminReducer.admins);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const dispatch = useDispatch();

	const [workingSession, setWorkingSession] = useState<Session>(startingSession);
	const [trackDropdownList, setTrackDropdownList] = useState<string[]>([]);
	const [languages, setLanguages] = useState<Language[]>([TranslateLangs.find(lang => lang.code === defaultLanguage) as Language]);
	const [duration, setDuration] = useState<number | undefined>(defaultDuration);
	const [errorMessage, setErrorMessage] = useState('');
	const [pastEventOpen, setPastEventOpen] = useState(false);
	const [showCustomUrlOption, setShowCustomUrlOption] = useState(false);
	const [showBreakoutRoomsModal, setShowBreakoutRoomsModal] = useState(false);

	// Defaults to the homepage default language if it exists and then 'en' because there is no language param on the template route
	const _defaultLanguage = useMemo(() => TranslateLangs.find(lang => lang.code === defaultLanguage) as Language, [defaultLanguage]);
	const canShowLanguageDropdown = canShowLanguageSelector(workingSession, _defaultLanguage);

	const { language = defaultLanguage } = useParams() as ParamsProps;
	const sessionNames: { name: string, id: number }[] = useMemo(() => {
		return workingEvent
			? workingEvent.sessions.map(session => ({ name: session.title.base, id: session.session }))
			: [];
	}, [workingEvent]);

	// Generates a date from the workingSession timestamp for the datepicker to use
	const workingStartDate = useMemo(() => {
		return workingSession.timestamp ? new Date(workingSession.timestamp) : undefined;
	}, [workingSession.timestamp]);

	const isFireside = workingSession.session_type === SessionTypesEnum.fireside;

	useEffect(() => {
		// cannot remove event default language from a session
		if (_defaultLanguage) {
			if (!languages.some(lang => lang.code === _defaultLanguage.code)) {
				setLanguages([_defaultLanguage, ...languages]);
			}
		}

	}, [_defaultLanguage, languages]);

	// Dates prior to today are disabled in the datepicker
	const minDate = useMemo(() => {
		const date = new Date();
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		return date;
	}, []);

	const {
		requiredFields,
		updateRequiredField,
		resetRequiredFields,
		hasCompletedRequiredFields
	} = useRequiredFields(['name', 'duplicateName'], {
		name: (string: string) => !!string.trim(),
		// if the name is duplicate then return false to trigger validation
		duplicateName: (isDuplicate: boolean) => {
			if (isDuplicate) setErrorMessage('Session name already used for this Event');
			if (!isDuplicate) setErrorMessage('');
			return !isDuplicate;
		},
	});

	// Duplicate names aren't allowed
	const checkDuplicateName = useCallback((sessionName: string) => {
		return sessionNames.some(({ name, id }) => {
			const isNotThisSession = id !== workingSession.session;
			return isNotThisSession && name.trim() === sessionName.trim();
		});
	}, [sessionNames, workingSession.session]);

	const handleSave = async (breakoutSession?: BreakoutSession | undefined) => {
		const event = workingEvent ?? templateEvent;
		if (!event) {
			console.warn(`Sanity check failed, no event available to select languages from!`);
			return;
		}

		const { title: { base } } = workingSession;
		const isDuplicate = checkDuplicateName(base);
		if (!hasCompletedRequiredFields({ name: base, duplicateName: isDuplicate })) return;

		// Show event-in-past modal without saving if the updated date is in the past
		const { timestamp: startingTimestamp } = startingSession;
		const { timestamp: workingTimestamp } = workingSession;
		const hasTimestampChanged = startingTimestamp !== workingTimestamp;
		if (hasTimestampChanged && workingTimestamp && workingTimestamp - Date.now() <= 0) {
			return setPastEventOpen(true);
		}

		/*
		if it's a Fireside session, change the default language to be the newly selected language, add english as a
		language option so that the admin console text consistently shows english, and force streaming options to be
		single stream since Firesides have just one language, and therefore, one stream
		 */
		const withLanguages = getSessionWithLanguagesFromWorkingEvent(event);
		let sessionToSave = withLanguages(workingSession);

		// if breakout room selected, and there are no breakout rooms, then create one by default
		const isBreakoutRoom = sessionToSave.session_type === SessionTypesEnum.breakoutRooms;
		if (isBreakoutRoom && breakoutSession && sessionToSave.breakout_session) {
			sessionToSave = {
				...sessionToSave,
				breakout_session: {
					...sessionToSave.breakout_session,
					create_room_when_full: !!breakoutSession?.create_room_when_full,
					use_zoom: !!breakoutSession?.use_zoom,
					use_google_meet: !!breakoutSession?.use_google_meet,
				},
				breakout_session_hosts: breakoutSession.hosts,
				breakout_rooms: breakoutSession.rooms
			};
		}
		if (isBreakoutRoom && !sessionToSave.breakout_rooms) {
			const breakoutRooms = await defaultRooms(uuidV4());
			sessionToSave.breakout_rooms = breakoutRooms;
			setWorkingSession(sessionToSave);
		}
		onSave(sessionToSave);
	};

	const handleGoToBreakoutRooms = async () => {
		setShowBreakoutRoomsModal(true);
		showBreakoutRooms();
	};

	const handleSetDate = (date: Date) => {
		// Can't remove a date from a session
		if (!date) return;

		const updatedSession = {
			...workingSession,
			timestamp: date.valueOf(),
			end_timestamp: duration
				? date.valueOf() + 1000 * 60 * duration
				: null,
		};

		setWorkingSession(updatedSession);
	};

	const handleSetDuration = (minutes: number | undefined) => {
		if (minutes !== undefined) {
			// max session duration is 6 months, min is 5 minutes
			setDuration(numClamp(minutes, 1, (60 * 24 * 365) / 2));
		} else {
			// allow setting as undefined to display a proper error message
			// and not interrupt user's typing
			setDuration(undefined);
		}

		if (workingSession.timestamp && minutes !== undefined) {
			setWorkingSession({
				...workingSession,
				end_timestamp: minutes
					? workingSession.timestamp + 1000 * 60 * minutes
					: null,
			});
		}
	};

	const handleSessionTypeChange = (sessionType: SessionTypesEnum) => {
		// Sessions should retain their timestamps, even when changing to and from a session type without them
		const startTime = workingSession.timestamp || startingSession.timestamp;
		const endTime = workingSession.end_timestamp || startingSession.end_timestamp;

		// Timestamps on Session can be null, and null evaluates to 0 in JS
		// NaN empties the duration field rather than populating it with a 0
		const updatedDuration = endTime && startTime ? (endTime - startTime) / 60000 : NaN;
		const updatedSession: Session = {
			...workingSession,
			session_type: sessionType,
			timestamp: startTime,
			end_timestamp: endTime,
		};

		if (sessionType === SessionTypesEnum.fireside) {
			// Adds default fireside hosts if none exist
			if (!updatedSession.firesides_hosts?.length) {
				updatedSession.firesides_hosts = startingSession.firesides_hosts?.length
					? startingSession.firesides_hosts
					: admins.map(({ email }) => email);
			}
			// fireside sessions can only have one language, this sets it to the first language in the list
			if (updatedSession.languages.length > 1) {
				updatedSession.languages = [updatedSession.languages[0]];
				setLanguages([languages[0]]);
			}
		}

		setWorkingSession(updatedSession);
		setDuration(updatedDuration);
		resetRequiredFields();
	};

	const setTracks = (tracks: string[]) => {
		setWorkingSession({ ...workingSession, tracks });
	};

	const handleChangeFiresideHosts = (hostEmails: string[]) => {
		setWorkingSession({
			...workingSession,
			firesides_hosts: hostEmails,
		});
	};

	const handleChangeLanguages = (languages: Language[]) => {
		// Working session requires language codes, not language objects
		const languageCodes = languages.map((lang: Language) => lang.code);
		setWorkingSession({
			...workingSession,
			languages: languageCodes,
			translate_all: true, //tells the API to translate modules
		});

		// The language selector now works with Language objects whereas session still uses language codes
		// Until there's a better workaround or Session is updated, they have to be controlled independently and stay in sync
		setLanguages(languages);
	};

	const handleSessionNameChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		updateRequiredField('duplicateName', checkDuplicateName(target.value));
		updateRequiredField('name', target.value);
		const newTranslateKey = updateTranslateKey({
			translateString: workingSession.title,
			input: target.value,
			language,
			baseLanguage: workingSession.default_language
		});

		setWorkingSession({ ...workingSession, title: newTranslateKey });
	};

	const handleToggleCustomUrl = () => {
		const updatedSession = {
			...workingSession,
			custom_url_enabled: !workingSession?.custom_url_enabled
		};

		setWorkingSession(updatedSession);
	};

	const handleToggleSingleLanguageChat = () => {
		const updatedSession = {
			...workingSession,
			session_chat_single_language: !workingSession?.session_chat_single_language
		};

		setWorkingSession(updatedSession);
	};

	useEffect(() => {
		if (workingSession?.custom_url_enabled) {
			setShowCustomUrlOption(true);
		}
		else {
			setShowCustomUrlOption(false);
		}
	}, [workingSession?.custom_url_enabled]);

	const handleSetCustomURL = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const updatedSession = {
			...workingSession,
			custom_url: target.value
		};

		setWorkingSession(updatedSession);
	};

	function formValid() {
		// Firesides require at least one host
		const validFiresideHosts = workingSession.session_type !== SessionTypesEnum.fireside
			|| !!workingSession.firesides_hosts?.length;

		// Somebody who understands useRequiredFields can refactor this
		const requiresTimestamps = SessionTypesFeatures[workingSession.session_type].has_start_time;
		const isMissingTimestamps = !workingSession.timestamp || !workingSession.end_timestamp;

		let customUrlValid;
		let customUrlPresented;

		//if the custom url is toggle off, we don't require the custom url field as a required field.
		if (!workingSession.custom_url_enabled) {
			customUrlPresented = true;
			customUrlValid = true;
		}
		//On the other hand, the custom url is a required field in the modal.
		else {
			//Make sure the input custom url is a valid url.
			customUrlValid = workingSession.custom_url ? validateUrl(workingSession.custom_url) : true;
			// The custom url is a required field, so the user has to enter some texts in the input.
			customUrlPresented = workingSession.custom_url ? ((workingSession.custom_url.length > 0 && customUrlValid) ? true : false) : false;
		}

		return !!workingSession.title && validFiresideHosts && !(requiresTimestamps && isMissingTimestamps) && customUrlValid && customUrlPresented;
	}

	const handleCancel = () => {
		onClose();
		resetRequiredFields();
	};

	// Gets track list to populate track search dropdown
	const loadTracks = useCallback(async function () {
		if (!token || !user) return;
		try {
			const tracks = await GetChannelTracks(user.active_channel, token);
			setTrackDropdownList(tracks);
		} catch (e) {
			console.error(e);
		}
	}, [token, user]);

	// On load, get admins in case needed for default session hosts (session-modal.tsx does this differently)
	useEffect(() => {
		if (!admins?.length && token && user) {
			dispatch(getAdmins(token, user?.active_channel));
		}
	}, [admins, dispatch, token, user]);

	// startingSession is what's in the db for a given session
	useEffect(() => {
		// startingSession updates automagically, so:
		// 1. When this modal is open, it should be ignored to avoid overwriting the working data
		// 2. When this modal is closed, it should keep the working data updated
		if (!isOpen) {
			const session: Session = {
				...startingSession,
			};
			const startingDuration = startingSession.timestamp && startingSession.end_timestamp
				? (startingSession.end_timestamp - startingSession.timestamp) / 60000
				: defaultDuration;

			setWorkingSession(session);
			setDuration(startingDuration);
			// Languages are set in a separate useEffect, because this runs a lot
		}
	}, [isOpen, startingSession]);

	useEffect(() => {
		if (isOpen) loadTracks();
	}, [isOpen, loadTracks]);

	useEffect(() => {
		// Get language objects from language codes on session
		const sessionLanguages = TranslateLangs.filter((langObj: Language) => {
			return startingSession.languages.includes(langObj.code);
		}) as Language[];

		const fireSessionLanguage = TranslateLangs.filter((langObj: Language) => {
			return startingSession.languages[0].includes(langObj.code);
		}) as Language[];

		// It's possible that the default language set at the event level
		// isn't in the list of session languages. In this case, we inject
		// the default event language at the beginning of the session's language list.
		(sessionLanguages.findIndex(lang => lang.code === _defaultLanguage?.code) === -1 && !isFireside) ?
			setLanguages([...Array(_defaultLanguage), ...sessionLanguages]) :
			setLanguages(sessionLanguages);

		if (isFireside) setLanguages(fireSessionLanguage);

		// Do not add dependencies to me
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, startingSession.default_language, startingSession.languages, defaultLanguage]);

	const closePastEvent = () => {
		setPastEventOpen(false);
	};

	const validName = (requiredFields.name === Validation.normal && requiredFields.duplicateName === Validation.normal) ? Validation.normal : Validation.error;
	const validCustomUrl = typeof workingSession.custom_url === 'string' && workingSession.custom_url.length && !validateUrl(workingSession.custom_url)
		? Validation.error
		: Validation.normal;

	// We decided to disable switching to/from BRs because there was some added complexity, and we wanted to get this feature done ASAP
	const isSessionTypeDisabled = (type: SessionTypesEnum): boolean => {
		const specialTypes = [SessionTypesEnum.breakoutRooms, SessionTypesEnum.fireside];

		const isStartingTypeSpecial = specialTypes.includes(startingSession.session_type);
		const isTypeSpecial = specialTypes.includes(type);
		const isFsToOd = startingSession.session_type === SessionTypesEnum.fireside && type === SessionTypesEnum.onDemand;

		// Special case: Must be able to convert fireside to on demand
		if (isFsToOd) {
			return false;
		}

		// if session type is special, then disable the other type options
		if (isStartingTypeSpecial && type !== startingSession.session_type) {
			return true;
		}

		// if dropdown option type is special and session type is not, then disable it
		if (isTypeSpecial && !isStartingTypeSpecial) {
			return true;
		}

		return false;
	};

	const handleSingleStreamToggle = () => {
		setWorkingSession({
			...workingSession,
			streaming_options: {
				...workingSession.streaming_options,
				single_stream: !workingSession.streaming_options.single_stream
			}
		});
	};

	const breakoutSessionHosts = useMemo(() => {
		return (workingSession.breakout_session_hosts || []).reduce((accum: string[], host: unknown) => {
			if (typeof host === 'string') return [...accum, host];
			if (isObject(host) && 'email' in host) return [...accum, host.email];
			return accum;
		}, []);
	}, [workingSession.breakout_session_hosts]);

	const googleOAuthClientId = process.env.REACT_APP_MEET_INTEGRATION_OAUTH_CLIENT_ID || '';

	const [showBreakoutRooms, hideBreakoutRooms] = useModal(() => (
		// BP-2155 GoogleOAuthProvider wrapper can be removd after phase 0 of meet integration
		<GoogleOAuthProvider clientId={googleOAuthClientId}>
			<BreakoutSessionsModal
				initialValues={{
					hosts: breakoutSessionHosts,
					create_room_when_full: !!workingSession.breakout_session?.create_room_when_full,	// Not used when Zoom BRs
					rooms: workingSession.breakout_rooms || [],
					use_zoom: !!workingSession.breakout_session?.use_zoom,
					use_google_meet: !!workingSession.breakout_session?.use_google_meet,
				}}
				onClose={async (done, breakoutSession) => {
					if (done && breakoutSession) {
						await handleSave(breakoutSession);
					}
					setShowBreakoutRoomsModal(false);
					hideBreakoutRooms();
					handleCancel();
				}}
				propsDefaultLanguage={defaultLanguage}
			/>
		</GoogleOAuthProvider>
	), [handleSave]);

	return (
		<>
			<ModalComponent
				addModalPadding={true}
				open={isOpen}
				onRequestClose={showBreakoutRoomsModal ? () => null : handleCancel}
				title="Edit Session"
				cancellable
				closeable={false}
				className="session-modal"
				size={'medium'}
				header={
					<>
						<div className="modal-header-select">
							{/* the different session types */}
							<div className="modal-header-select-title">Session type *</div>
							<SessionTypeSelect
								options={
									Object.values(SessionTypesFeatures).map(value => ({
										value: value.name,
										label: value.display_name,
										description: value.description,
										icon: value.icon_name,
										disabled: isSessionTypeDisabled(value.name) || disbleSessionTypeSwitching,
									}))
								}
								onChange={handleSessionTypeChange}
								selected={workingSession.session_type}
								defaultValue={startingSession.session_type}
								disbleSessionTypeSwitching={disbleSessionTypeSwitching}
							/>
						</div>
						<div className="header-line-break" />
					</>
				}
				footer={(
					<div className="session-modal-footer">
						<span className={classNames("error-message", { toast: errorMessage })}>{errorMessage}</span>
						<div className="action-buttons">
							<button onClick={handleCancel} style={{ marginRight: 16 }}>Cancel</button>
							<button
								disabled={!formValid()}
								onClick={() => isEditingExistingBreakout ? handleGoToBreakoutRooms() : handleSave()}
								className="lemonade"
							>{isEditingExistingBreakout ? 'Set rooms' : 'Save'}</button>
						</div>
					</div>
				)}
				portalClassName="session-modal-portal"
			>
				<div className={classNames('edit-session-modal-wrapper')}>
					<TextInput
						label={"Session Title *"}
						tooltip={[PrimaryTooltip, 'This is the first impression you make. Try to make it useful and convey the idea that the main benefit is somehow unique.']}
						onChange={handleSessionNameChange}
						placeholder={"Enter session title"}
						value={workingSession.title.base || ''}
						onBlur={(e) => updateRequiredField('name', e.target.value)}
						valid={validName}
						ignoreZIndex
					/>

					<TagSelectInput
						label={"Tracks"}
						tooltip={[PrimaryTooltip, 'Tracks help organize and distribute content in a meaningful way.']}
						placeholder={"Select or enter tracks"}
						onChange={setTracks}
						defaultTags={trackDropdownList}
						tags={workingSession.tracks ?? []}
						dropdownContainerStyles={{
							maxWidth: 'calc(100% - 80px)',
							margin: '0 auto',
						}}
						capSensitive
						noWhitespace
						maxTags={20}
						onError={(s) => {
							if (s === 'MAX TAGS') setErrorMessage('Twenty is the max number of tracks');
							setTimeout(() => setErrorMessage(''), 2500);
						}}
						shouldSearch
						allowWithoutEnter
					/>

					<OptionalComponent display={canShowLanguageDropdown}>
						<LanguageSelectInput
							label={"Languages"}
							tooltip={[PrimaryTooltip, isFireside ? "Fireside sessions have a limit of 1 language." : "Creating a session in multiple languages let's you reach the widest possible audience."]}
							placeholder={"Select languages"}
							onChange={handleChangeLanguages}
							languages={languages}
							defaultLanguage={_defaultLanguage}
							limit={isFireside ? 1 : undefined}
							disableDefault
							disableDefaultRemoval
							// event editor has different style that when combined with our async css imports causes margins to undo themselves so we must detect whether or not we're in the event editor or not
							trackLeftPosition={isEventEditor ? false : true}
						/>
					</OptionalComponent>

					{SessionTypesFeatures[workingSession.session_type].has_start_time && (
						<SessionStartTimeFields
							minDate={minDate}
							handleSetDate={handleSetDate}
							workingStartDate={workingStartDate}
							handleSetDuration={handleSetDuration}
							duration={duration}
						/>
					)}

					{workingSession.session_type === SessionTypesEnum.fireside && (
						<div style={{ marginTop: '24px' }}>
							<TagSelectInput
								label={"Session Hosts *"}
								tooltip={[PrimaryTooltip, 'Hosts will have the ability to moderate and end sessions. Channel Admins and Moderators have host permissions by default.']}
								placeholder={"Enter host emails"}
								onChange={hosts => handleChangeFiresideHosts(hosts)}
								tags={workingSession.firesides_hosts || []}
								capSensitive
								noWhitespace
								errorMessage={workingSession.firesides_hosts?.length ? undefined : "At least one session host required"}
								validateEmail
								initializeScrolledToBottom
								allowClearAll
							/>
						</div>
					)}

					<div style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}>
						{(workingSession.session_type === SessionTypesEnum.broadcast
							|| workingSession.session_type === SessionTypesEnum.onDemand) && workingSession.languages.length > 1 && (
								<div className={classNames("single-stream-toggle", "custom-url")}>
									<label>Shared Video Player</label>
									<Tooltip
										tooltip={"Same video player across all languages"}
										position={TooltipPositions.topRight}
										maxWidth={300}
									>
										<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
									</Tooltip>

									<Switch
										value={'single-stream-toggle'}
										onClick={handleSingleStreamToggle}
										on={!!workingSession?.streaming_options?.single_stream}
									/>
								</div>
							)}

						{workingSession.languages.length > 1 && (
							<div className="single-stream-toggle custom-url">
								<label>Multi-language chat</label>
								<Tooltip
									textClassName="enable-custom-url-tooltip"
									tooltip="Enabling multi-language chat will push all comments and questions into a single feed regardless of the user's language."
									position={TooltipPositions.topLeft}
									maxWidth={300}
								>
									<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
								</Tooltip>

								<Switch
									value="enable-custom-url"
									onClick={handleToggleSingleLanguageChat}
									on={!!workingSession.session_chat_single_language}
								/>
							</div>
						)}

						<div className="single-stream-toggle custom-url">
							<label>Custom URL</label>
							<Tooltip
								textClassName="enable-custom-url-tooltip"
								tooltip="Enabling a custom URL will replace the native session page and direct all new traffic to the URL set below. Users will be taken from the agenda view directly to the custom URL."
								position={TooltipPositions.topRight}
								maxWidth={300}
							>
								<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
							</Tooltip>

							<Switch
								value="enable-custom-url"
								onClick={handleToggleCustomUrl}
								on={!!workingSession.custom_url_enabled}
							/>
						</div>
					</div>

					{showCustomUrlOption && <TextInput
						label="Custom URL *"
						onChange={handleSetCustomURL}
						placeholder="Enter custom URL"
						value={workingSession.custom_url}
						defaultValue={workingSession.custom_url}
						valid={workingSession.custom_url?.length ? validCustomUrl : Validation.normal}
						className={classNames("custom-url")}
						isAdmin
					/>}
				</div>
			</ModalComponent>
			<ModalComponent
				open={pastEventOpen}
				onRequestClose={closePastEvent}
				cancellable={false}
				closeable={false}
				title={"Events in the past"}
				footer={
					<div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
						<button className={'lemonade'} onClick={closePastEvent}>Close</button>
					</div>
				}
			>
				<div className="past-events-modal" style={{ marginTop: 8 }}>
					<p>Events must be scheduled in the present or future.</p>
				</div>
			</ModalComponent>
		</>
	);
};

export default EditSessionModal;
