import { lazy, Suspense } from "react";

const _Color = lazy(() => import('./color-wrapped'));
interface ColorPickerProps {
	value: string;
	label: string;
	open: boolean;
	close: () => void;
}

export default function Color(props: ColorPickerProps | any) {
	return (
		<Suspense fallback="">
			<_Color {...props} />
		</Suspense>
	);
}