import React, { useCallback, useRef } from 'react';
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker';

import { Field, FieldLabel, FieldProps } from '../field/field';
import Icon, { COLORS, ICONS } from '../icon';
import { setADropdownIsOpen } from '../../../store/actions/admin/create-event';
import { useAppDispatch } from '../../../store/reducers/use-typed-selector';
import { useGetEvent } from 'hooks/session.hooks';
import { showAlert } from '@general-ui/alert/alert-service';

import "react-datepicker/dist/react-datepicker.css";
import './date-picker.scss';

export interface DatepickerProps extends FieldProps<Date> {
	minDate?: Date,	// Dates prior to this day are disabled
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange: (date: Date) => void;
	placeholder?: string,
	popperPlacement?: "auto" | "right" | "left" | "top" | "bottom" | "auto-start" | "auto-end" | "top-start" | "top-end" | "right-start" | "right-end" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | undefined;
	portalId?: string;
	value?: Date;
	shouldCloseOnSelect?: boolean;
	showTimeInput?: boolean;
	closeOnTab?: boolean;
	isClearable?: boolean;
	timeCaption?: string;
	icon?: string;
	iconSize?: number;
	datepickerProps?: Omit<ReactDatePickerProps, 'onChange'>;
	disabled?: boolean;
}

// eslint-disable-next-line react/display-name
const Datepicker: React.FC<DatepickerProps> = (props: DatepickerProps): JSX.Element => {
	const {
		label,
		minDate,
		name,
		onBlur,
		onChange,
		placeholder,
		popperPlacement,
		portalId,
		required,
		value,
		shouldCloseOnSelect = false,
		showTimeInput = false,
		closeOnTab = false,
		isClearable = false,
		timeCaption = 'Start time',
		icon = ICONS.CALENDAR,
		iconSize = 15,
		datepickerProps = {},
		disabled = false
	} = props;
	const dispatch = useAppDispatch();

	const workingEvent = useGetEvent();


	const picker = useRef<ReactDatepicker | null>(null);

	function focusField() {
		if (workingEvent && !workingEvent?.settings.is_module_grouping_v2) {
			showAlert({ message: 'This version of the platform is no longer supported.', description: 'Please create a new project.', duration: 5000, type: "error" });
		}
		picker.current?.setFocus();
	}

	const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
		if (closeOnTab && event.key === 'Tab') {
			picker.current?.setOpen(false);
		}
	}, [closeOnTab]);

	return (
		<Field {...props}>
			<FieldLabel label={label} required={required} />
			<div className="datepicker" onClick={focusField} data-testid="date-picker">
				<div className="datepicker-input">
					<ReactDatepicker
						ref={picker}
						selected={value}
						onChange={onChange}
						onBlur={onBlur}
						name={name}
						showTimeSelect
						timeFormat="hh:mm aa"
						dateFormat={'MM/dd/yyyy hh:mm aa'}
						timeIntervals={5}
						timeCaption={timeCaption}
						minDate={minDate}
						placeholderText={placeholder}
						popperPlacement={popperPlacement}
						portalId={portalId}
						popperClassName={`${portalId ? 'date-with-portal' : ''}`}
						shouldCloseOnSelect={shouldCloseOnSelect}
						showTimeInput={showTimeInput}
						onKeyDown={handleKeyDown}
						isClearable={isClearable}
						onCalendarOpen={() => dispatch(setADropdownIsOpen(true))}
						onCalendarClose={() => setTimeout(() => dispatch(setADropdownIsOpen(false)), 100)}
						disabled={disabled}
						{...datepickerProps}
					/>
				</div>
				<Icon name={icon} size={iconSize} color={COLORS.WHITE} />
			</div>
		</Field>
	);
};

export default Datepicker;
