import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setScrollToModule } from '../../../../../store/actions/admin/event-panel';
import { PageModule, PageModuleType } from '../../../../../types/working-model';
import Icon, { COLORS, ICONS } from '../../../../general-ui/icon';
import NavSwitchInput from '../../../../general-ui/switch/nav-switch';

interface Props {
	label: string;
	onClick: (value: string, on: boolean) => void;
	value: string;
	initial?: boolean | undefined;
	moduleType?: number;
	editModule: (module: PageModule) => void;
	module: PageModule;
	showSettings?: boolean;
}

export default function SessionOption(props: Props): JSX.Element {

	const {
		label,
		onClick,
		value,
		initial,
		editModule,
		module,
		showSettings = true,
	} = props;
	const [on, setOn] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (initial) {
			setOn(initial);
		}
	}, [initial]);

	const scrollToModule = useCallback(() => dispatch(setScrollToModule(module.type)), [dispatch, module.type]);

	function handleClick(value: string) {
		setOn(!on);
		return onClick(value, on);
	}

	function handleEdit() {
		editModule(module);
	}

	return (
		<div className={`session-option-container ${module.type === PageModuleType.main_event_banner ? "no-toggle" : ""}`} onClick={scrollToModule}>
			<NavSwitchInput
				value={value}
				onClick={handleClick}
				label={label}
				on={on}
				edit={handleEdit}
				custom={module.is_custom}
				showSettings={showSettings}
				// Main Event Banner is always on, but still sortable
				showToggle={module.type === PageModuleType.main_event_banner ? false : true}
			/>
		</div>
	);
}

interface IPlaceholderSessionOptionProps {
	label: string;
	onEditIconClick: () => void;
	className?: string;
}

export const PlaceholderSessionOption: React.FC<IPlaceholderSessionOptionProps> = ({ label, onEditIconClick, className }) => {
	return (
		<div
			className={classNames('session-option-container no-toggle no-drag', className)}
		>
			<label>{label}</label>
			<div className="switch-right">
				<button className="no-style options" onClick={onEditIconClick}><Icon name={ICONS.EDIT} size={20} color={COLORS.CYAN} /></button>
			</div>
		</div>
	);
};
