import { ChannelPermission, EPermissions } from "../../../types/working-model";
import { useMemo } from "react";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";


export const checkUserIsPresenterOnly = (
	userChannels: ChannelPermission | undefined,
	userActiveChannel: number | undefined,
) => {
	if (!userActiveChannel || !userChannels) return false;
	if (!Array.isArray(userChannels[userActiveChannel])) return false;

	const channelPermissions = userChannels[userActiveChannel];
	if (channelPermissions.includes(EPermissions.Presenter)
		&& channelPermissions.length === 1
	) {
		return true;
	}

	return false;
};

export const userIsPresenterOnlyForActiveChannel = () => {
	const userChannels = useTypedSelector(state => state.AuthReducer.user?.channels);
	const userActiveChannel = useTypedSelector(state => state.AuthReducer.user?.active_channel);

	return useMemo(() => checkUserIsPresenterOnly(userChannels, userActiveChannel),
		[userChannels, userActiveChannel]);
};