import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { AnalyticsEvent, AnalyticsEventSessionPageViews, AnalyticsSession, ISessionAttendeeMetricsList } from '../../../types/working-model';
import { IDateRange } from '../../../components/general-ui/datepicker/date-range';
import {
	GET_CHANNEL_AUDIENCE,
	GET_CHANNEL_BROWSERS,
	GET_CHANNEL_DEVICE_TYPES,
	GET_CHANNEL_DURATION,
	GET_CHANNEL_EVENTS,
	GET_CHANNEL_REGIONS,
	GET_CHANNEL_REGISTRATIONS,
	GET_CHANNEL_RETENTION_AVERAGE,
	GET_CHANNEL_RETENTION_BUCKETS,
	GET_CHANNEL_VIEWS,
	GET_CHANNEL_WATCHTIME,
	GET_CHANNEL_AVERAGE_WATCHTIME,
	GET_EVENT_BROWSERS,
	GET_EVENT_DEVICE_TYPES,
	GET_EVENT_DURATION,
	GET_EVENT_EMAILS,
	GET_EVENT_LANGUAGES,
	GET_EVENT_REGIONS,
	GET_EVENT_RETENTION_AVERAGE,
	GET_EVENT_RETENTION_BUCKETS,
	GET_EVENT_SESSIONS,
	GET_EVENT_VIEWS,
	GET_LIVE_AND_ON_DEMAND_EVENT_VIEWS,
	GET_EVENT_WATCHTIME,
	GET_SESSION_BROWSERS,
	GET_SESSION_DEVICE_TYPES,
	GET_SESSION_DURATION,
	GET_SESSION_EMAILS,
	GET_SESSION_LANGUAGES,
	GET_SESSION_REGIONS,
	GET_SESSION_REGISTRATIONS,
	GET_SESSION_RETENTION_AVERAGE,
	GET_SESSION_TIMELINE,
	GET_SESSION_VIEWS,
	GET_SESSION_WATCHTIME,
	GET_EVENT_REGISTRATION_ANALYTICS,
	GET_EVENT_COUNTRY_MAP,
	GET_SESSION_COUNTRY_MAP,
	GET_HIGHEST_CONCURRENT,
	GET_USER_SURVEY,
	SET_TIME_RANGE,
	SET_AUDIENCE_LISTS_FILTER,
	GET_CHANNEL_EVENT_COUNTRY_MAP,
	GET_AVERAGE_SESSION_WATCHTIMES,
	GET_CHANNEL_UNIQUE_ATTENDANCE,
	GET_SESSION_90_SEC_VIDEO_VIEWERS,
	GET_SESSION_RETENTION_TIMELINE,
	GET_AVERAGE_SESSION_ATTENDANCE,
	GET_SESSION_PAGE_VIEWS,
	GET_EVENT_UNIQUE_ATTENDANCE,
	SET_LANGUAGE_FILTER,
	GET_EVENT_AVERAGE_WATCHTIME,
	GET_SESSION_UNIQUE_ATTENDANCE,
	GET_SESSION_REACTIONS,
	GET_EVENT_SESSIONS_UNIQUE_ATTENDEES,
	GET_EVENT_SESSIONS_AVG_RETENTION,
	GET_SESSION_ENGAGEMENT,
	GET_PAGINATED_SESSION_ATTENDEES,
	CLEAR_PAGINATED_SESSION_ATTENDEES,
	GET_CHANNEL_VIEWS_PER_EVENT,
	GET_CHANNEL_LANGUAGES,
	GET_UNFILTERED_CHANNEL_EVENTS,
	GET_EVENT_SESSIONS_PAGE_VIEWS,
	GET_FIRST_SESSION_START_TIME,
	GET_ACTUAL_SESSION_START_TIME,
	GET_EVENT_ATTENDANCE_RATE,
	GET_MORE_CHANNEL_EVENTS,
	GET_CHANNEL_SESSIONS_TOTAL,
	GET_CHANNEL_EVENT
} from '../../actions/admin/analytics';

interface Wrapper<T> {
	loading?: boolean,
	error?: string | null,
	data: T
	initialRequestMade?: boolean;
}

export interface ScorecardData {
	value?: string | number | null;
	sparkline?: Array<number>;
	min?: number;
	max?: number;
	secondaryStats?: Array<{
		title: string;
		value: number | string;
	}>
}

interface ISessionWatchtime {
	total_seconds_played: number;
	total_unique_users: number;
	target_id_string: string;
}

interface ISessionWatchTimes {
	value: ISessionWatchtime[] | null;
}

export interface IChannelViewsPerEvent {
	event_id: number;
	views: string;
}

export interface PieChartData {
	value: number | string;
	label: string;
	emoji?: string;
}

export interface LineChartData {
	xAxis: {
		min?: number;
		max: number;
	},
	leftAxis: {
		min: number;
		max: number;
		lines: LineChartLine[];
	},
	rightAxis?: {
		min: number;
		max: number;
		lines: LineChartLine[];
	},
}

export interface LineChartLine {
	title: string;
	points: [number, number][];
	color?: string;
}

export interface AdminAnalyticsState {
	channelEvents: Wrapper<Array<AnalyticsEvent>>;
	unfilteredChannelEvents: Wrapper<Array<AnalyticsEvent>>;
	selectedAnalyticsEvent: Wrapper<AnalyticsEvent | null>;
	channelEventsMapData: Wrapper<Array<any>>;
	channelDevices: Wrapper<Array<any>>;
	channelBrowsers: Wrapper<Array<any>>;
	channelLanguages: Wrapper<Array<PieChartData>>;
	channelAudience: Wrapper<ScorecardData>;
	channelRegistrations: Wrapper<ScorecardData>;
	channelDuration: Wrapper<ScorecardData>;
	channelViews: Wrapper<ScorecardData>;
	channelViewsPerEvent: Wrapper<Record<number, IChannelViewsPerEvent>>
	channelWatchtime: Wrapper<ScorecardData>;
	channelRetentionAverage: Wrapper<ScorecardData>;
	channelRetentionBuckets: Wrapper<Array<any>>;
	channelSessions: Wrapper<number | null>;
	channelRegions: Wrapper<Array<any>>;
	channelUniqueAttendance: Wrapper<ScorecardData>;
	channelAverageUserWatchtime: Wrapper<ScorecardData>;
	eventDevices: Wrapper<Array<any>>;
	eventRegions: Wrapper<Array<any>>;
	eventLanguages: Wrapper<Array<any>>;
	eventViews: Wrapper<ScorecardData>;
	eventViewsBreakdown: Wrapper<ScorecardData>;
	eventBrowsers: Wrapper<Array<any>>;
	eventAudience: Wrapper<ScorecardData>;
	eventRegistrations: Wrapper<ScorecardData>;
	eventEmails: Wrapper<ScorecardData>;
	eventDuration: Wrapper<ScorecardData>;
	eventWatchtime: Wrapper<ScorecardData>;
	eventRetentionAverage: Wrapper<ScorecardData>;
	eventRetentionBuckets: Wrapper<Array<any>>;
	eventSessions: Wrapper<Array<AnalyticsSession>>;
	eventSessionsPageViews: Wrapper<Array<AnalyticsEventSessionPageViews>>;
	eventMapData: Wrapper<Array<any>>;
	eventUniqueAttendance: Wrapper<ScorecardData>;
	eventAttendanceRate: Wrapper<ScorecardData>;
	eventAverageUserWatchtime: Wrapper<ScorecardData | null>;
	/** `data: Record<string, number>` string is the session id (not uuid), number is the total unique attendees for that session */
	eventSessionsUniqueAttendees: Wrapper<Record<string, number> | null>;
	/** `data: Record<string, number>` string is the session id (not uuid), number is the total avg retention for that session */
	eventSessionsAvgRetention: Wrapper<Record<string, number> | null>;
	eventSessionsPaginatedAttendees: Wrapper<ISessionAttendeeMetricsList | null>;
	firstSessionStartTime: Wrapper<any>;
	sessionDevices: Wrapper<Array<any>>;
	sessionUserSurvey: Wrapper<Array<PieChartData>>;
	sessionRegions: Wrapper<Array<any>>;
	sessionLanguages: Wrapper<Array<any>>;
	sessionViews: Wrapper<ScorecardData>;
	sessionBrowsers: Wrapper<Array<any>>;
	sessionAudience: Wrapper<ScorecardData>;
	sessionRegistrations: Wrapper<ScorecardData>;
	sessionEmails: Wrapper<ScorecardData>;
	sessionDuration: Wrapper<ScorecardData>;
	sessionWatchtime: Wrapper<ScorecardData>;
	sessionAverageWatchtimes: Wrapper<ISessionWatchTimes>;
	sessionSessions: Wrapper<Array<any>>;
	sessionRetentionAverage: Wrapper<ScorecardData>;
	sessionTimeline: Wrapper<any>
	sessionMapData: Wrapper<Array<any>>;
	session90SecondViewers: Wrapper<ScorecardData>;
	sessionHighestConcurrent: Wrapper<any>;
	sessionRetentionTimeline: Wrapper<LineChartData>;
	sessionPageViews: Wrapper<ScorecardData>;
	sessionUniqueAttendance: Wrapper<Record<string, number>>;
	averageSessionAttendance: Wrapper<ScorecardData>;
	sessionReactions: Wrapper<PieChartData[]>;
	sessionEngagement: Wrapper<LineChartData>;
	actualSessionStartTime: Wrapper<any>;
	/* start filters */
	timeRange: Wrapper<IDateRange>;
	audienceLists: Wrapper<Array<string>>
	languageFilter: Wrapper<string>
	/* end filters */
	// [key: string]: Wrapper<any>
}

const initialState: AdminAnalyticsState = {
	actualSessionStartTime: { data: null },
	averageSessionAttendance: { data: { value: null } },
	channelAudience: { data: { value: null } },
	channelAverageUserWatchtime: { data: { value: null } },
	channelBrowsers: { data: [] },
	channelDevices: { data: [] },
	channelDuration: { data: { value: null } },
	channelEvents: { data: [] },
	selectedAnalyticsEvent: { data: null },
	channelEventsMapData: { data: [] },
	channelLanguages: { data: [] },
	channelRegions: { data: [] },
	channelRegistrations: { data: { value: null } },
	channelRetentionAverage: { data: { value: null } },
	channelRetentionBuckets: { data: [] },
	channelSessions: { data: null },
	channelUniqueAttendance: { data: {} },
	channelViews: { data: { value: null } },
	channelViewsPerEvent: { data: {} },
	channelWatchtime: { data: { value: null } },
	eventAttendanceRate: { data: {} },
	eventAudience: { data: { value: null } },
	eventAverageUserWatchtime: { data: null },
	eventBrowsers: { data: [] },
	eventDevices: { data: [] },
	eventDuration: { data: { value: null } },
	eventEmails: { data: { value: null } },
	eventLanguages: { data: [] },
	eventMapData: { data: [] },
	eventRegions: { data: [] },
	eventRegistrations: { data: { value: null } },
	eventRetentionAverage: { data: { value: null } },
	eventRetentionBuckets: { data: [] },
	eventSessions: { data: [] },
	eventSessionsAvgRetention: { data: null },
	eventSessionsPageViews: { data: [] },
	eventSessionsPaginatedAttendees: { data: null },
	eventSessionsUniqueAttendees: { data: null },
	eventUniqueAttendance: { data: {} },
	eventViews: { data: { value: null } },
	eventViewsBreakdown: { data: { value: null } },
	eventWatchtime: { data: { value: null } },
	/* start filters */
	timeRange: { data: { start: undefined, end: undefined } },
	audienceLists: { data: [] },
	languageFilter: { data: '' },
	/* end filters */
	firstSessionStartTime: { data: null },
	session90SecondViewers: { data: { value: null } },
	sessionAudience: { data: { value: null } },
	sessionAverageWatchtimes: { data: { value: null } },
	sessionBrowsers: { data: [] },
	sessionDevices: { data: [] },
	sessionDuration: { data: { value: null } },
	sessionEmails: { data: { value: null } },
	sessionEngagement: {
		data: {
			xAxis: { max: 0 },
			leftAxis: { min: 0, max: 1, lines: [] }
		}
	},
	sessionHighestConcurrent: { data: { value: null } },
	sessionLanguages: { data: [] },
	sessionMapData: { data: [] },
	sessionPageViews: { data: { value: null } },
	sessionReactions: { data: [] },
	sessionRegions: { data: [] },
	sessionRegistrations: { data: { value: null } },
	sessionRetentionAverage: { data: { value: null } },
	sessionRetentionTimeline: {
		data: {
			xAxis: { max: 0 },
			leftAxis: { min: 0, max: 1, lines: [] }
		}
	},
	sessionSessions: { data: [] },
	sessionTimeline: { data: { value: null } },
	sessionUniqueAttendance: { data: {} },
	sessionUserSurvey: { data: [] },
	sessionViews: { data: { value: null } },
	sessionWatchtime: { data: { value: null } },
	unfilteredChannelEvents: { data: [] },
};

const defaultHandler = (state: AdminAnalyticsState, action: Action, property: keyof AdminAnalyticsState) => {
	return handle(state, action, {
		success: (state) => ({
			...state,
			[property]: {
				...state[property],
				data: action.payload,
				error: null
			}
		}),
		start: (state) => ({ ...state, [property]: { ...state[property], loading: true, initialRequestMade: true } }),
		failure: (state) => ({ ...state, [property]: { ...state[property], error: 'Unable to load' } }),
		finish: (state) => ({ ...state, [property]: { ...state[property], loading: false } }),
	});
};

export default function AnalyticsReducer(
	state: AdminAnalyticsState = initialState,
	action: Action
): AdminAnalyticsState {
	switch (action.type) {
		case GET_CHANNEL_EVENTS: {
			return defaultHandler(state, action, 'channelEvents');
		}
		case GET_CHANNEL_EVENT: {
			return defaultHandler(state, action, 'selectedAnalyticsEvent');
		}
		case GET_MORE_CHANNEL_EVENTS: {
			return handle(state, action, {
				success: state => ({
					...state,
					channelEvents: {
						...state.channelEvents,
						data: [...state.channelEvents.data, ...action.payload],
					}
				}),
				failure: (state) => ({ ...state, channelEvents: { ...state.channelEvents, error: 'Unable to load' } }),
			});
		}
		case GET_UNFILTERED_CHANNEL_EVENTS: {
			return defaultHandler(state, action, 'unfilteredChannelEvents');
		}
		case GET_CHANNEL_SESSIONS_TOTAL: {
			return defaultHandler(state, action, 'channelSessions');
		}
		case GET_CHANNEL_DEVICE_TYPES: {
			return defaultHandler(state, action, 'channelDevices');
		}
		case GET_USER_SURVEY: {
			return defaultHandler(state, action, 'sessionUserSurvey');
		}
		case GET_CHANNEL_BROWSERS: {
			return defaultHandler(state, action, 'channelBrowsers');
		}
		case GET_CHANNEL_LANGUAGES: {
			return defaultHandler(state, action, 'channelLanguages');
		}
		case GET_CHANNEL_AUDIENCE: {
			return defaultHandler(state, action, 'channelAudience');
		}
		case GET_CHANNEL_REGISTRATIONS: {
			return defaultHandler(state, action, 'channelRegistrations');
		}
		case GET_CHANNEL_DURATION: {
			return defaultHandler(state, action, 'channelDuration');
		}
		case GET_CHANNEL_VIEWS: {
			return defaultHandler(state, action, 'channelViews');
		}
		case GET_CHANNEL_VIEWS_PER_EVENT: {
			return defaultHandler(state, action, 'channelViewsPerEvent');
		}
		case GET_CHANNEL_WATCHTIME: {
			return defaultHandler(state, action, 'channelWatchtime');
		}
		case GET_CHANNEL_RETENTION_AVERAGE: {
			return defaultHandler(state, action, 'channelRetentionAverage');
		}
		case GET_CHANNEL_RETENTION_BUCKETS: {
			return defaultHandler(state, action, 'channelRetentionBuckets');
		}
		case GET_CHANNEL_REGIONS: {
			return defaultHandler(state, action, 'channelRegions');
		}
		case GET_CHANNEL_UNIQUE_ATTENDANCE: {
			return defaultHandler(state, action, 'channelUniqueAttendance');
		}
		case GET_CHANNEL_AVERAGE_WATCHTIME: {
			return defaultHandler(state, action, 'channelAverageUserWatchtime');
		}
		case GET_EVENT_DEVICE_TYPES: {
			return defaultHandler(state, action, 'eventDevices');
		}
		case GET_EVENT_REGIONS: {
			return defaultHandler(state, action, 'eventRegions');
		}
		case GET_EVENT_VIEWS: {
			return defaultHandler(state, action, 'eventViews');
		}
		case GET_LIVE_AND_ON_DEMAND_EVENT_VIEWS: {
			return defaultHandler(state, action, 'eventViewsBreakdown');
		}
		case GET_EVENT_DURATION: {
			return defaultHandler(state, action, 'eventDuration');
		}
		case GET_EVENT_RETENTION_AVERAGE: {
			return defaultHandler(state, action, 'eventRetentionAverage');
		}
		case GET_EVENT_RETENTION_BUCKETS: {
			return defaultHandler(state, action, 'eventRetentionBuckets');
		}
		case GET_EVENT_REGISTRATION_ANALYTICS: {
			return defaultHandler(state, action, 'eventRegistrations');
		}
		case GET_EVENT_EMAILS: {
			return defaultHandler(state, action, 'eventEmails');
		}
		case GET_EVENT_WATCHTIME: {
			return defaultHandler(state, action, 'eventWatchtime');
		}
		case GET_EVENT_BROWSERS: {
			return defaultHandler(state, action, 'eventBrowsers');
		}
		case GET_EVENT_SESSIONS: {
			return defaultHandler(state, action, 'eventSessions');
		}
		case GET_EVENT_SESSIONS_PAGE_VIEWS: {
			return defaultHandler(state, action, 'eventSessionsPageViews');
		}
		case GET_FIRST_SESSION_START_TIME: {
			return defaultHandler(state, action, 'firstSessionStartTime');
		}
		case GET_EVENT_LANGUAGES: {
			return defaultHandler(state, action, 'eventLanguages');
		}
		case GET_CHANNEL_EVENT_COUNTRY_MAP: {
			return defaultHandler(state, action, 'channelEventsMapData');
		}
		case GET_EVENT_COUNTRY_MAP: {
			return defaultHandler(state, action, 'eventMapData');
		}
		case GET_EVENT_UNIQUE_ATTENDANCE: {
			return defaultHandler(state, action, 'eventUniqueAttendance');
		}
		case GET_EVENT_ATTENDANCE_RATE: {
			return defaultHandler(state, action, 'eventAttendanceRate');
		}
		case GET_EVENT_AVERAGE_WATCHTIME: {
			return defaultHandler(state, action, 'eventAverageUserWatchtime');
		}
		case GET_EVENT_SESSIONS_UNIQUE_ATTENDEES: {
			return defaultHandler(state, action, 'eventSessionsUniqueAttendees');
		}
		case GET_EVENT_SESSIONS_AVG_RETENTION: {
			return defaultHandler(state, action, 'eventSessionsAvgRetention');
		}
		case GET_PAGINATED_SESSION_ATTENDEES: {
			return defaultHandler(state, action, 'eventSessionsPaginatedAttendees');
		}
		case GET_SESSION_DEVICE_TYPES: {
			return defaultHandler(state, action, 'sessionDevices');
		}
		case GET_SESSION_REGIONS: {
			return defaultHandler(state, action, 'sessionRegions');
		}
		case GET_SESSION_VIEWS: {
			return defaultHandler(state, action, 'sessionViews');
		}
		case GET_SESSION_DURATION: {
			return defaultHandler(state, action, 'sessionDuration');
		}
		case GET_SESSION_RETENTION_AVERAGE: {
			return defaultHandler(state, action, 'sessionRetentionAverage');
		}
		case GET_SESSION_REGISTRATIONS: {
			return defaultHandler(state, action, 'sessionRegistrations');
		}
		case GET_SESSION_EMAILS: {
			return defaultHandler(state, action, 'sessionEmails');
		}
		case GET_SESSION_WATCHTIME: {
			return defaultHandler(state, action, 'sessionWatchtime');
		}
		case GET_AVERAGE_SESSION_WATCHTIMES: {
			return defaultHandler(state, action, 'sessionAverageWatchtimes');
		}
		case GET_SESSION_BROWSERS: {
			return defaultHandler(state, action, 'sessionBrowsers');
		}
		case GET_SESSION_LANGUAGES: {
			return defaultHandler(state, action, 'sessionLanguages');
		}
		case GET_SESSION_TIMELINE: {
			return defaultHandler(state, action, 'sessionTimeline');
		}
		case GET_SESSION_COUNTRY_MAP: {
			return defaultHandler(state, action, 'sessionMapData');
		}
		case GET_SESSION_90_SEC_VIDEO_VIEWERS: {
			return defaultHandler(state, action, 'session90SecondViewers');
		}
		case GET_HIGHEST_CONCURRENT: {
			return defaultHandler(state, action, 'sessionHighestConcurrent');
		}
		case GET_SESSION_RETENTION_TIMELINE: {
			return defaultHandler(state, action, 'sessionRetentionTimeline');
		}
		case GET_SESSION_PAGE_VIEWS: {
			return defaultHandler(state, action, 'sessionPageViews');
		}
		case GET_SESSION_UNIQUE_ATTENDANCE: {
			return defaultHandler(state, action, 'sessionUniqueAttendance');
		}
		case GET_AVERAGE_SESSION_ATTENDANCE: {
			return defaultHandler(state, action, 'averageSessionAttendance');
		}
		case GET_SESSION_REACTIONS: {
			return defaultHandler(state, action, 'sessionReactions');
		}
		case GET_SESSION_ENGAGEMENT: {
			return defaultHandler(state, action, 'sessionEngagement');
		}
		case GET_ACTUAL_SESSION_START_TIME: {
			return defaultHandler(state, action, 'actualSessionStartTime');
		}

		case SET_TIME_RANGE: {
			return {
				...state,
				['timeRange']: {
					...state['timeRange'],
					data: action.payload
				}
			};
		}
		case SET_AUDIENCE_LISTS_FILTER: {
			return {
				...state,
				audienceLists: {
					data: action.payload
				}
			};
		}
		case SET_LANGUAGE_FILTER: {
			return {
				...state,
				languageFilter: {
					data: action.payload
				}
			};
		}
		case CLEAR_PAGINATED_SESSION_ATTENDEES: {
			return {
				...state,
				eventSessionsPaginatedAttendees: {
					data: null,
				}
			};
		}
		default:
			return state;
	}
}
