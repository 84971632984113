import classNames from 'classnames';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { validate } from 'uuid';

import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { CallToAction, CallToActionType, LanguagesAbbr, PageLinkType, PageModule, PageModuleType } from '../../../types/working-model';
import { PATHNAMES } from '../../../utils/admin-routing-utils';
import { parseUrl } from '../../../utils/utils';
import { ParamsProps } from '../../live-event/live-event';
import { eventPath } from '../../live-event/utils';

interface INavItemProps {
	navItem: CallToAction;
	handleScroll: (moduleId?: number, p?: PageModuleType) => void;
	className?: string;
	isEditor?: boolean;
	previewLanguage?: LanguagesAbbr;
	labelOverride?: string;
}

export default function NavItem({
	navItem,
	handleScroll,
	className = 'navigation-link',
	isEditor = false,
	previewLanguage,
	labelOverride
}: INavItemProps): JSX.Element {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	// const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);

	const history = useHistory();
	const { path } = useRouteMatch();
	let link;
	const { language: pathLanguage, eventName, uuid }: ParamsProps = useParams();

	function getCustomPagePath(customPath: string) {
		return `/${eventPath(eventName, uuid)}/${language}/pages/${customPath}`;
	}

	const language = previewLanguage ? previewLanguage : pathLanguage; //when in preview modal portal we no longer have access to params
	function handlePageNav() {
		const pageLink = navItem.content.page;
		if (!pageLink) return;

		if (isEditor) {
			if (validate(pageLink) && workingEvent?.uuid) {
				const customPage = workingEvent?.custom_pages?.find(page => page.uuid === pageLink);
				if (customPage) {
					history.push(PATHNAMES.Event.CustomPageLink(workingEvent?.uuid, customPage.path, language));
					return;
				}
			}

			switch (pageLink) {
				case PageLinkType.landing: {
					history.push(`/admin/events/create/${workingEvent?.uuid}/landing/${language ?? "en"}`);
					break;
				}
				case PageLinkType.termsAndConditions: {
					history.push(`/admin/events/create/${workingEvent?.uuid}/event/${language ?? "en"}/terms-conditions`);
					break;
				}
				case PageLinkType.privacyPolicy: {
					history.push(`/admin/events/create/${workingEvent?.uuid}/event/${language ?? "en"}/privacy-policy`);
					break;
				}
				case PageLinkType.homepage: {
					history.push(`/admin/events/create/${workingEvent?.uuid}/event/${language ?? "en"}/home`);
					break;
				}
				case PageLinkType.registration: {
					history.push(`/admin/events/create/${workingEvent?.uuid}/registration/${language ?? "en"}/steps`);
					break;
				}
				default: {
					break;
				}
			}
		} else {
			if (validate(pageLink)) {
				const customPage = eventBundle?.custom_pages?.find(page => page.uuid === pageLink);
				if (customPage) {
					history.push(getCustomPagePath(customPage.path));
					return;
				}
			}

			switch (pageLink) {
				case PageLinkType.landing: {
					history.push(`/${eventPath(eventName, uuid)}/${language}`);
					break;
				}
				case PageLinkType.termsAndConditions: {
					window.open(`/${eventPath(eventName, uuid)}/${language}/terms-conditions`, '_blank');
					break;
				}
				case PageLinkType.privacyPolicy: {
					window.open(`/${eventPath(eventName, uuid)}/${language}/privacy-policy`, '_blank')?.focus();
					break;
				}
				case PageLinkType.homepage: {
					history.push(`/${eventPath(eventName, uuid)}/${language}/home`);
					break;
				}
				case PageLinkType.registration: {
					history.push(`/${eventPath(eventName, uuid)}/${language}/registration`);
					break;
				}
				default: {
					break;
				}
			}
		}

	}

	function getTextValue() {
		if (labelOverride) {
			return labelOverride;
		}

		const useBase = (navItem?.button_name?.changed === 'true' || !path.includes('/:language'));
		return navItem?.button_name[useBase ? 'base' : language];
	}

	switch (navItem.type) {
		case CallToActionType.page:
			link = <span className={className} onClick={handlePageNav}>{getTextValue()}</span>;
			break;
		case CallToActionType.section: {
			const { section, section_module_id } = navItem.content;
			link = (
				<a
					className={classNames(className, 'no-style')}
					onClick={() => handleScroll(section_module_id || 0, section)}
				>
					{getTextValue()}
				</a>
			);
			break;
		}
		case CallToActionType.url:
			link = navItem.content.url ?
				<a
					className={className}
					href={parseUrl(navItem.content.url)}
					target="_blank" rel="noreferrer"
				>
					{getTextValue()}
				</a>
				: getTextValue();
			break;
		default:
			link = "Link type not found";
	}

	return (
		<>
			{link}
		</>
	);
}

export function checkLinkedSectionIsOn(navItem: CallToAction, activePageModules: { type: PageModuleType; id: number; }[]): boolean {
	if (navItem.type === CallToActionType.section && navItem.content.section) {
		return activePageModules.some(pm => pm.type === navItem.content.section);
	}
	if (navItem.type === CallToActionType.section && navItem.content.section_module_id) {
		return activePageModules.some(pm => pm.id === navItem.content.section_module_id);
	}
	return true;
}

export function filterActiveModules(modules?: PageModule[]): { type: PageModuleType; id: number; }[] {
	return modules?.filter(m => m.is_on).map(m => ({ type: m.type, id: m?.id || 0 })) || [];
}
