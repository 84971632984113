import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
	SortableContext,
	useSortable,
	sortableKeyboardCoordinates,
	rectSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from "uuid";
import { updateHomepageFooter } from '../../../../../../store/actions/admin/create-event';
import { EventsState } from '../../../../../../store/types';
import { GetDefaultFooterNavItems, addIdToCallToAction } from '../../../../../../store/utils/create-event';
import { CallToAction, CallToActionType, LanguagesAbbr, SortableCallToAction } from '../../../../../../types/working-model';
import CallToActionForm from '../../../../../general-ui/call-to-action/call-to-action';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import ModalComponent from '../../../../../general-ui/modal/modal';
import TabHeader from '../../../../../general-ui/tab-header/tab-header';

interface ISortableFooterNavItemProps {
	navItem: SortableCallToAction;
	handleRemove: (id: string) => void;
	onWorkingNavItemChange: (workingCallToAction: CallToAction) => void;
	language: LanguagesAbbr;
	baseLanguage: LanguagesAbbr
}

const SortableFooterItem: React.FC<ISortableFooterNavItemProps> = ({ navItem, handleRemove, onWorkingNavItemChange, language, baseLanguage }) => {
	const { attributes, listeners, setNodeRef, transform } = useSortable({
		id: navItem.id
	});

	const sortableStyling: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	return (
		<div
			className={classNames("nav-container", { dragging: attributes["aria-pressed"] })}
			ref={setNodeRef}
			style={sortableStyling}

		>
			<div className="nav-item">
				<div
					className="drag"
					{...attributes}
					{...listeners}
				>
					<Icon
						name={ICONS.DRAG_HANDLE}
						size={16}
						color={COLORS.WHITE}
					/>
				</div>
				<CallToActionForm
					workingCTA={navItem}
					onWorkingCTAChange={onWorkingNavItemChange}
					baseLanguage={baseLanguage}
					language={language}
					ctaTypeOptions={[CallToActionType.page, CallToActionType.url]}
				/>
				<div className="remove" onClick={() => handleRemove(navItem.id)}>
					<Icon
						name={ICONS.TRASH_OUTLINE}
						size={16}
						color={COLORS.WHITE}
					/>
				</div>
			</div>
		</div>

	);
};

interface IEditFooterModalProps {
	open: boolean;
	closeModal: () => void;
	baseLanguage: LanguagesAbbr;
	language: LanguagesAbbr
}

enum EFooterModalTabs {
	menu = 'Menu'
}

const EditFooterModal: React.FC<IEditFooterModalProps> = (props) => {
	const {
		open,
		closeModal,
		baseLanguage,
		language
	} = props;

	const {
		CreateEventReducer: { workingEvent }
	} = useSelector((event: EventsState) => event);
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(EFooterModalTabs.menu);
	const [workingItemList, setWorkingItemList] = useState(workingEvent?.homepage?.footer?.navItems?.map(addIdToCallToAction) || GetDefaultFooterNavItems());

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10
			}
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	const handleDragEnd = useCallback((event: any) => {
		if (!event.over) return;
		const _pageItems = workingItemList?.slice() || [];

		const startIndex = _pageItems.findIndex((item: SortableCallToAction) => item.id === event.active.id);
		const newIndex = _pageItems.findIndex((item: SortableCallToAction) => item.id === event.over.id);

		const [item] = _pageItems.splice(startIndex, 1);
		_pageItems.splice(newIndex, 0, item);

		setWorkingItemList(_pageItems);
	}, [workingItemList]);

	function handleDone() {
		dispatch(updateHomepageFooter({
			...workingEvent!.homepage!.footer,
			navItems: workingItemList
		}));
		closeModal();
	}

	function handleCancel() {
		setWorkingItemList(workingEvent?.homepage?.footer?.navItems?.map(addIdToCallToAction) || []);
		closeModal();
	}

	const navigationItems = useMemo(() => {
		const handleRemove = (moduleId: string) => {
			const startIndex = workingItemList.findIndex(item => item.id === moduleId);
			if (startIndex >= 0) {
				const _workingList = [...workingItemList];
				_workingList.splice(startIndex, 1);
				setWorkingItemList(_workingList);
			}
		};

		function handleWorkingNavItemChange(workingNavItem: SortableCallToAction) {
			setWorkingItemList(workingItemList.map(item =>
				item.id === workingNavItem?.id ? workingNavItem : item
			));
		}

		return workingItemList.map((item: SortableCallToAction) => (
			<SortableFooterItem
				key={item.id}
				navItem={item}
				handleRemove={handleRemove}
				onWorkingNavItemChange={(cta) => handleWorkingNavItemChange(cta as SortableCallToAction)}
				baseLanguage={baseLanguage}
				language={language}
			/>
		));
	}, [workingItemList, baseLanguage, language]);

	const editFooterTab = useMemo(() => {
		switch (activeTab) {
			case EFooterModalTabs.menu:

				const handleAddItem = () => {
					if (workingItemList.length < 5) {
						const defaultNavItem: SortableCallToAction = {
							id: v4(),
							type: CallToActionType.page,
							button_name: { base: '', changed: '' },
							content: {}
						};
						setWorkingItemList([...workingItemList, defaultNavItem]);
					}
				};

				return (
					<>
						<div className="container-title">
							<p className="site-menu">Site menu</p>
							<button disabled={workingItemList.length >= 5} onClick={handleAddItem} className="no-style">
								<Icon name={ICONS.ADD} color={COLORS.CYAN} size={12} />
								Add Item
							</button>
						</div>
						<DndContext
							sensors={sensors}
							collisionDetection={closestCenter}
							onDragEnd={handleDragEnd}
							modifiers={[restrictToParentElement]}
							autoScroll={false}
						>
							<SortableContext
								items={workingItemList.map(item => item.id)}
								strategy={rectSortingStrategy}
							>

								{navigationItems}
							</SortableContext>
						</DndContext>
					</>
				);
			default:
				return <p>...</p>;
		}
	}, [activeTab, workingItemList, sensors, navigationItems, handleDragEnd]);

	const modalFooter = <div>
		<button onClick={handleCancel}>Cancel</button>
		<button className='footer-menu-done primary' onClick={handleDone}>Done</button>
	</div>;

	return (
		<ModalComponent
			cancellable={true}
			closeable={false}
			open={open}
			onRequestClose={handleCancel}
			title="Footer"
			size="large"
			footer={modalFooter}
		>
			<TabHeader
				tabs={[EFooterModalTabs.menu]}
				onClick={setActiveTab}
				currentTab={activeTab}
			/>

			<div className="edit-footer-modal-container">
				{editFooterTab}
			</div>
		</ModalComponent>
	);
};

export default EditFooterModal;
