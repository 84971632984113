import { useMemo } from 'react';
import classNames from 'classnames';

import { Button } from '../../general-ui/button/button';
import { EIcon } from '../../general-ui/icon/icon';
import { useParams } from 'react-router';
import { ParamsProps } from '../live-event';
import { BrandliveEvent, RegistrationStepType } from '../../../types/working-model';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { OptionalComponent } from '../../../utils/optional-component';
import registrationFirstStepButtonText from '../../../utils/registration-first-step-button-text';
import { RegFailureReasons, useGetRegistrationErrorMessage } from 'utils/use-get-registration-error-message';

export interface RegistrationFooterProps {
	isNextDisabled?: boolean;
	isFirstStep: boolean;
	isFinalStep: boolean;
	useCustomLogin?: boolean;
	template?: string;
	onPrevStepClick: (e: any) => void;
	waiting: boolean;
	error?: string;
	isSkippable?: boolean;
	eventBundle?: BrandliveEvent | null;
	renderRecaptcha?: () => JSX.Element;
}
export const RegistrationFooter = ({
	isNextDisabled,
	isFirstStep,
	isFinalStep,
	useCustomLogin,
	template,
	onPrevStepClick,
	waiting,
	error = '',
	isSkippable,
	eventBundle,
	renderRecaptcha
}: RegistrationFooterProps): JSX.Element => {
	const { t } = useTranslate(["homepage", "session"]);

	const isLiveClosed = useTypedSelector(state => !!state.LiveEventReducer?.eventBundle?.registration_settings?.isClosed);
	const isWorkingClosed = useTypedSelector(state => !!state.CreateEventReducer?.workingEvent?.registration_settings?.isClosed);
	const registrationStep = useTypedSelector(state => state.LiveEventReducer.registrationStep);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const eventbriteOrderDetailsRetry = useTypedSelector(state => state.LiveEventReducer?.eventbriteOrderDetailsRetry);

	const isRegistrationClosed = isLiveClosed || isWorkingClosed;
	const registrationSteps = eventBundle?.registration_steps;

	const isAvatarStep = useMemo(() => registrationSteps?.filter(step => step.isOn)?.[registrationStep].type === RegistrationStepType.avatar, [registrationStep, registrationSteps]);

	const channel = eventBundle?.channel ?? 0;
	const profile = blProfileUser?.profile?.[channel];
	const regErrorMessage = useGetRegistrationErrorMessage(error as RegFailureReasons);

	if (useCustomLogin && isFirstStep) return <></>;
	if (isFirstStep)
		return (
			<div className="registration-actions primary">
				{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
				{renderRecaptcha?.()}
				<Button
					classButton="primary full-width"
					template={template}
					typeBtn="submit"
					isDisabled={isNextDisabled || waiting || isRegistrationClosed || !!eventbriteOrderDetailsRetry}
				>
					{registrationFirstStepButtonText({ isRegistrationClosed, isFinalStep, t })}
				</Button>
			</div>
		);
	else if (isFinalStep)
		return (
			<div className="registration-actions secondary">
				{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
				<OptionalComponent display={!useCustomLogin}>
					<Button
						isRound={true}
						classButton={classNames('registration-button secondary')}
						template={template}
						onClick={onPrevStepClick}
						typeBtn="button"
					>
						<EIcon name="keyboard-arrow-left" />
					</Button>
				</OptionalComponent>

				<div>
					{isSkippable ? (
						<Button
							classButton={classNames('registration-button secondary')}
							template={template}
							typeBtn="submit"
							isDisabled={waiting || isRegistrationClosed}
						>
							{isRegistrationClosed ? t("homepage:registration.Registration is closed") : t("Skip")}
						</Button>
					) : null}
					<Button
						classButton={classNames('registration-button', { "primary": !isAvatarStep, "secondary": isAvatarStep })}
						template={template}
						typeBtn="submit"
						isDisabled={isNextDisabled || waiting || isRegistrationClosed}
					>
						{isRegistrationClosed ? t("homepage:registration.Registration is closed") : t(profile?.avatar && isAvatarStep ? "session:Continue" : "Save")}
					</Button>
				</div>
			</div>
		);
	return (
		<div className="registration-actions secondary">
			{error && <p className="registration-error" role="alert">{regErrorMessage}</p>}
			<OptionalComponent display={!useCustomLogin}>
				<Button
					isRound={true}
					classButton={classNames('registration-button secondary')}
					template={template}
					onClick={onPrevStepClick}
				>
					<EIcon name="keyboard-arrow-left" />
				</Button>
			</OptionalComponent>
			<div>
				{isSkippable ? (
					<Button
						classButton={classNames('registration-button secondary')}
						template={template}
						isDisabled={waiting}
						typeBtn="submit"
					>
						{t("Skip")}
					</Button>
				) : null}
				<Button
					classButton={classNames('registration-button', { "primary": !isAvatarStep, "secondary": isAvatarStep })}
					template={template}
					isDisabled={isNextDisabled || waiting}
					typeBtn="submit"
				>
					{t(profile?.avatar && isAvatarStep ? "session:Continue" : "Save")}
				</Button>
			</div>
		</div>
	);
};
