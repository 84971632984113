import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import classNames from "classnames";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import { updateHomepageHeader } from "../../../../../../store/actions/admin/create-event";
import { addChecklistItem } from "../../../../../../store/actions/authentication";
import { ChecklistTitles } from "../../../../../../store/reducers/admin/assistant";
import { GetDefaultHeaderNavItems, addIdToCallToAction } from "../../../../../../store/utils/create-event";
import { HeaderAllTemplates, TemplateClassNames, TemplateNames } from "../../../../../../types/template-layouts";
import { CallToAction, CallToActionType, LanguagesAbbr, SortableCallToAction } from "../../../../../../types/working-model";
import { replaceSpaceWithUnderscore } from "../../../../../../utils/utils";
import CallToActionForm from "../../../../../general-ui/call-to-action/call-to-action";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import ModalComponent from "../../../../../general-ui/modal/modal";
import TabHeader from "../../../../../general-ui/tab-header/tab-header";
import { checkLinkedSectionIsOn, filterActiveModules } from "../../../../../general-ui/nav-item/nav-item";
import AdvancedLayoutSettings from "../../../homepage/navigation/structure/edit-module-layout-modal/advanced-settings";
import Images from './layout-images.json';
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";

interface ISortableMainNavItemProps {
	id: string;
	navItem: SortableCallToAction;
	handleRemove: (id: string) => void;
	onWorkingNavItemChange: (workingCallToAction: CallToAction) => void;
	baseLanguage: LanguagesAbbr;
	language: LanguagesAbbr
}

const SortableMainNavItem: React.FC<ISortableMainNavItemProps> = ({ id, navItem, handleRemove, onWorkingNavItemChange, baseLanguage, language }) => {
	const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

	const sortableStyling: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	return (
		<div
			className={classNames("nav-container", { dragging: attributes["aria-pressed"] })}
			ref={setNodeRef}
			style={sortableStyling}

		>
			<div
				className="nav-item"
			>
				<div
					className="drag"
					{...attributes}
					{...listeners}
				>
					<Icon
						name={ICONS.DRAG_HANDLE}
						size={16}
						color={COLORS.WHITE}
					/>
				</div>
				<CallToActionForm
					workingCTA={navItem}
					onWorkingCTAChange={onWorkingNavItemChange}
					baseLanguage={baseLanguage}
					language={language}
				/>
				<div className="remove">
					<button className="no-style trash" onClick={() => handleRemove(navItem.id)}>
						<Icon
							name={ICONS.TRASH_OUTLINE}
							size={16}
							color={COLORS.WHITE}
						/>
					</button>
				</div>
			</div>
		</div>

	);
};

interface IEditHeaderModalProps {
	open: boolean;
	closeModal: () => void;
	baseLanguage: LanguagesAbbr;
	language: LanguagesAbbr;
}

enum EHeaderModalTabs {
	layout = 'Layout',
	menu = 'Menu'
}

const EditHeaderModal: React.FC<IEditHeaderModalProps> = (props) => {
	const {
		open,
		closeModal,
		baseLanguage,
		language
	} = props;

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);

	const dispatch = useAppDispatch();

	const [activeTab, setActiveTab] = useState(EHeaderModalTabs.layout);
	const [workingLayout, setWorkingLayout] = useState<HeaderAllTemplates | null>(null);
	const [workingSticky, setWorkingSticky] = useState<boolean | null>(null);
	const [workingSignInBtn, setWorkingSignInBtn] = useState<boolean | null>(null);
	// What's going on here is that navItems need a unique ID to
	// be dnd compliant. They get created/destroyed in this modal.
	const [workingItemList, setWorkingItemList] = useState<SortableCallToAction[]>(workingEvent?.homepage?.header?.navItems?.[0]?.content ? workingEvent?.homepage?.header?.navItems.map(addIdToCallToAction) : GetDefaultHeaderNavItems().map(addIdToCallToAction));

	// if module gets toggled off, and it was in the Header nav bar, turn it off here as well
	useEffect(() => {
		let shouldUpdateModule = false;
		const filteredActiveModules = filterActiveModules(workingEvent?.homepage?.modules);

		const filteredNavItems = workingEvent?.homepage?.header?.navItems?.filter((navItem) => {
			const sectionIsOn = checkLinkedSectionIsOn(navItem, filteredActiveModules);
			if (!sectionIsOn) shouldUpdateModule = true;
			return sectionIsOn;
		});

		// only do these if we need to, already iterating a lot in this useEffect
		if (shouldUpdateModule && workingEvent?.homepage?.header && filteredNavItems) {
			dispatch(updateHomepageHeader({ ...workingEvent.homepage.header, navItems: filteredNavItems }));
			setWorkingItemList(filteredNavItems?.map(addIdToCallToAction) || GetDefaultHeaderNavItems().map(addIdToCallToAction));
		}
	}, [dispatch, workingEvent]);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10
			}
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		if (!event.over) return;
		const _pageItems = workingItemList.slice() || [];

		const startIndex = _pageItems.findIndex((item: SortableCallToAction) => String(item?.id) === String(event.active.id));
		const newIndex = _pageItems.findIndex((p: SortableCallToAction) => String(p?.id) === String(event.over?.id));

		const [item] = _pageItems.splice(startIndex, 1);
		_pageItems.splice(newIndex, 0, item);

		setWorkingItemList(_pageItems);
	}, [workingItemList]);

	function handleDone() {
		if (!token) return;
		// ASSISTANT CHECKLIST ITEM: complete users checklist item for editing the navigation bar
		if (
			user &&
			!user.onboarded.includes(ChecklistTitles.editNavigationBar)
		) dispatch(addChecklistItem(token, user.id, user.onboarded, ChecklistTitles.editNavigationBar));

		if (activeTab === EHeaderModalTabs.layout && workingLayout) {
			handleSelectLayout(workingLayout);
		}

		if (
			(activeTab === EHeaderModalTabs.menu || workingSignInBtn !== null || workingSticky !== null)
			&& workingEvent?.homepage?.header
		) {
			dispatch(updateHomepageHeader({
				...workingEvent.homepage.header,
				// make sure workingItemList only includes modules that are is_on
				// remove id before putting back to redux and db
				...(activeTab === EHeaderModalTabs.menu ? { navItems: workingItemList } : {}),
				// if working signInBtn has changed at all, update redux
				...(workingSignInBtn !== null ? { sign_in_button: workingSignInBtn } : {}),
				// if working sticky has changed at all, update redux
				...(workingSticky !== null ? { is_sticky: workingSticky } : {}),
			}));
		}

		if (workingSticky !== null) {
			setWorkingSticky(null);
		}
		if (workingSignInBtn !== null) {
			setWorkingSignInBtn(null);
		}

		closeModal();
	}

	const handleSelectLayout = useCallback((layoutType: HeaderAllTemplates) => {
		if (!workingEvent?.homepage?.header) return;
		dispatch(updateHomepageHeader({
			...workingEvent.homepage.header,
			layout: HeaderAllTemplates[layoutType],
		}));
	}, [dispatch, workingEvent]);

	function handleCancel() {
		if (workingEvent?.homepage?.modules) {
			setWorkingItemList(workingEvent?.homepage?.header?.navItems?.map(addIdToCallToAction) || GetDefaultHeaderNavItems().map(addIdToCallToAction));
		}
		closeModal();
	}

	const handleRemove = useCallback((moduleId: string) => {
		const _workingList = workingItemList.slice();
		if (workingItemList.map(cat => cat?.id).includes(moduleId)) {
			const startIndex = _workingList.findIndex((cat: SortableCallToAction) => cat?.id === moduleId);
			_workingList.splice(startIndex, 1);
		}
		setWorkingItemList(_workingList);
	}, [workingItemList]);

	function handleWorkingNavItemChange(workingCallToAction: SortableCallToAction) {

		const _workingList = workingItemList.slice();

		setWorkingItemList(_workingList.map(cta => {
			if (cta.id === workingCallToAction?.id) return workingCallToAction;
			return cta;
		}));
	}

	const handleAddItem = useCallback(() => {
		if (workingItemList.length < 5) {
			const _changedList = workingItemList.slice();
			const defaultCallToAction: SortableCallToAction = {
				id: v4(),
				type: CallToActionType.section,
				button_name: { base: '', changed: '' },
				content: {}
			};

			_changedList.push(defaultCallToAction);
			setWorkingItemList(_changedList);
		}
	}, [workingItemList]);

	const navigationItems = useMemo(() => {
		return workingItemList.map((item: SortableCallToAction) => (
			<SortableMainNavItem
				navItem={item}
				id={String(item.id)}
				key={item.id}
				handleRemove={handleRemove}
				onWorkingNavItemChange={cta => handleWorkingNavItemChange(cta as SortableCallToAction)}
				baseLanguage={baseLanguage}
				language={language}
			/>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleRemove, workingItemList]);

	const renderLayoutSelectors = useCallback((template: string, selectedLayout: string) => {
		// TODO: create new thumbnails for Limelight if we determine that it has different layouts
		const isCustomTemplate = !TemplateClassNames[template] || TemplateClassNames[template] === 'Limelight';

		return [HeaderAllTemplates.right, HeaderAllTemplates.left, HeaderAllTemplates.center].map((layout, i) => {
			const templateAssetsToUse = isCustomTemplate ? TemplateNames.Apollo : template;
			const key = `${replaceSpaceWithUnderscore(templateAssetsToUse)}-header-${layout}`;
			const src = `${process.env.REACT_APP_ASSET_SERVER}/template-previews/${(Images as { [key: string]: string })[key]}`;
			const currentLayout = workingLayout ? workingLayout : selectedLayout;

			return (
				<button
					key={i}
					onClick={() => setWorkingLayout(layout)}
					className={`no-style layout-selector ${currentLayout === layout && 'isSelected'}`}
				>
					<div
						className={classNames('layout-thumbnail', 'header', layout)}
					>
						<img
							src={src}
							alt={layout}
						/>
					</div>
				</button>
			);
		});
	}, [workingLayout]);

	const editHeaderContent = useMemo(() => {
		switch (activeTab) {
			case EHeaderModalTabs.layout: {
				const selectedLayout = workingEvent?.homepage?.header.layout;
				return (
					<div className="module-layout-display-items">
						{workingEvent?.template?.name &&
							renderLayoutSelectors(workingEvent.template.name, selectedLayout || 'center')
						}
					</div>
				);
			}
			case EHeaderModalTabs.menu:
				return (
					<>
						<div className="container-title">
							<p className="site-menu">Site menu</p>
							<button disabled={workingItemList.length >= 5} onClick={handleAddItem} className="no-style">
								<Icon name={ICONS.ADD} color={COLORS.CYAN} size={12} />
								Add Item
							</button>
						</div>
						<DndContext
							sensors={sensors}
							collisionDetection={closestCenter}
							onDragEnd={handleDragEnd}
							modifiers={[restrictToParentElement]}
							autoScroll={false}
						>
							<SortableContext
								items={workingItemList.map(cat => cat.id)}
								strategy={rectSortingStrategy}
							>
								{navigationItems}
							</SortableContext>
						</DndContext>
					</>
				);
			default:
				return <p>...</p>;
		}
	}, [activeTab, workingEvent?.homepage?.header, workingEvent?.template.name, renderLayoutSelectors, workingItemList, handleAddItem, sensors, handleDragEnd, navigationItems]);

	const renderAdvancedSettings = useMemo(() => {
		if (activeTab === EHeaderModalTabs.layout && workingEvent?.homepage?.header) {
			return (
				<AdvancedLayoutSettings
					selectedModule={workingEvent?.homepage?.header}
					onUpdate={({ sticky, signInBtn }) => {
						setWorkingSticky(sticky);
						setWorkingSignInBtn(signInBtn);
					}}
				/>
			);
		}
	}, [activeTab, workingEvent?.homepage?.header]);

	const footer = (
		<>
			{renderAdvancedSettings}
			<button onClick={handleCancel}>Cancel</button>
			<button
				className='footer-menu-done lemonade'
				onClick={handleDone}
			>
				Done
			</button>
		</>
	);

	return (
		<ModalComponent
			cancellable={true}
			closeable={false}
			open={open}
			onRequestClose={handleCancel}
			title="Header"
			size="large"
			footer={footer}
			showOverflow
		>
			<TabHeader
				tabs={[
					EHeaderModalTabs.layout,
					EHeaderModalTabs.menu
				]}
				onClick={setActiveTab}
				currentTab={activeTab}
			/>
			<div className="edit-header-modal-container">
				{editHeaderContent}
			</div>
		</ModalComponent>
	);
};

export default EditHeaderModal;
