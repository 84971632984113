import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AnswerMap } from "../../../../../../../../store/reducers/admin/surveys";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { RegFieldsEnum, TextAnswer } from "../../../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../../general-ui/animated/optional-component";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import textAnswerLoader from "./text-answer-loader";

type QuestionCardTextRepliesProps = {
	language: string;
	questionId: number;
	sessionUuid: string;
}

type QuestionCardTextProps = {
	reply: TextAnswer;
	language: string;
}
const QuestionCardTextReply = (props: QuestionCardTextProps) => {
	const { reply, language } = props;
	const firstName = reply.profile[RegFieldsEnum.first_name] ?? "Anonymous";
	const lastName = reply.profile[RegFieldsEnum.last_name] ?? "";
	return (
		<div className="survey-results-question-card-reply">
			<div className="survey-results-question-card-reply-text">
				<label>{firstName} {lastName}</label>
				<p>{reply.text_answer[language] ?? reply.text_answer.base}</p>
			</div>
		</div>
	);
};

const QuestionCardTextReplies = ({ questionId, language, sessionUuid }: QuestionCardTextRepliesProps) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const textAnswers = useTypedSelector(state => state.SurveysReducer.textResults);
	const [loading, setLoading] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const dispatch = useDispatch();
	const answerMap = textAnswers[questionId];
	const total = answerMap?.total ?? 0;
	const answersRef = useRef<AnswerMap>(answerMap);
	const sessionUuidRef = useRef<string>(sessionUuid);
	const tokenRef = useRef<string | null>(token);
	const questionIdRef = useRef<number>(questionId);
	const channelRef = useRef<number>(user?.active_channel ?? 0);

	useEffect(() => {
		answersRef.current = answerMap;
		sessionUuidRef.current = sessionUuid;
		tokenRef.current = token;
		questionIdRef.current = questionId;
		channelRef.current = user?.active_channel ?? 0;
	}, [token, sessionUuid, questionId, user?.active_channel, answerMap]);

	useEffect(() => {
		if (!token || !user || answersRef.current?.answers?.size) return;

		setLoading(true);

		textAnswerLoader.load({
			token,
			sessionUuid,
			questionId,
			dispatch,
			channel: user.active_channel,
			limit: 3
		}).finally(() => {
			setLoading(false);
		});
	}, [token, user, sessionUuid, questionId, dispatch]);

	const handleShowMore = useCallback((showMore: boolean) => {
		if (!token || !user) return;

		setShowMore(showMore);

		if (showMore) {
			setLoading(true);

			textAnswerLoader.load({
				token,
				sessionUuid,
				questionId,
				dispatch,
				channel: user.active_channel,
				limit: 100
			}).finally(() => {
				setLoading(false);
			});
		}
	}, [token, user, sessionUuid, questionId, dispatch]);

	const responses = total !== 1 ? 'responses' : 'response';

	return (
		<div className="survey-results-question-card-replies" >
			{((loading && !answerMap?.answers?.size)) ? (
				<div style={{ height: 56 }
				}>
					<WaitingIndicator fillSpace transparentFill />
				</div>
			) : (
				<>
					{answerMap?.answers?.size ? (
						<StaggerChildren className="survey-results-question-card-replies-list" >
							{
								Array.from(answerMap.answers.values())
									.filter((_, index) => {
										if (showMore) return true;
										return index < 3;
									})
									.map((answer) => {
										return (
											<QuestionCardTextReply
												key={answer.id}
												reply={answer}
												language={language}
											/>
										);
									})}
						</StaggerChildren>
					) : (
						<label className="survey-results-question-card-empty">No responses yet</label>
					)}

					<OptionalComponent display={loading && showMore && answerMap.answers.size < total} usingHeight={50} >
						<div key="loading-more" style={{ height: 56 }}>
							<WaitingIndicator fillSpace transparentFill />
						</div>
					</OptionalComponent>
					<footer>
						{total > 3 ?
							(
								<button
									className="small"
									onClick={() => handleShowMore(!showMore)}
								>
									See {showMore ? "fewer" : "all"}
								</button>
							) : (
								<div /> // flex spacer
							)
						}
						<label>{total} {responses} </label>
					</footer>
				</>
			)
			}
		</div >
	);
};

export default QuestionCardTextReplies;