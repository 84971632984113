import { useCallback, useContext, useEffect, useRef } from "react";
import { numClamp } from "../../../../../../utils/utils";
import { Actions, VideoStateContext } from "../../session-stream-provider";

export const Volume = () => {
	const {
		state,
		dispatch
	} = useContext(VideoStateContext);
	const { volume } = state;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const gutterContainerRef = useRef<HTMLDivElement>(null);
	const trackThumbRef = useRef<HTMLDivElement>(null);
	const playedRef = useRef<HTMLDivElement>(null);
	const draggingRef = useRef(false);

	useEffect(() => {
		// pixels moved + or -
		let deltaX = 0;

		// pixels moved left of the gutter container
		let correctedX = 0;

		// starting x position of the cursor
		let startX = 0;

		// starting x transform position of the thumb
		let startTransformX = 0;

		const handlePointerDown = (e: PointerEvent) => {
			e.stopPropagation();
			// sanity check
			if (!wrapperRef.current || !trackThumbRef.current) {
				return;
			}

			// prevent react from handling the progress event
			draggingRef.current = true;

			// add event listener for moving and releasing the thumb
			document.addEventListener('pointerup', handlePointerUp);
			wrapperRef.current.addEventListener('pointermove', handlePointerMove);

			// set starting positions
			startX = e.clientX;
			startTransformX = Number(trackThumbRef.current.style.transform.replace(/[a-zA-Z()]/g, '')) || 0;
		};

		const handlePointerMove = (e: PointerEvent) => {
			// sanity check
			if (!trackThumbRef.current || !gutterContainerRef.current || !playedRef.current) {
				return;
			}

			// left x position of the cursor
			const clientX = e.clientX;

			// left x position of the gutter container and width of the gutter container
			const { left, width } = gutterContainerRef.current.getBoundingClientRect();

			const percentMoved = (correctedX - left) / width;

			const vol = numClamp(percentMoved, 0, 1);

			// if the mouse has moved left or right of the gutter container, don't do anything
			// this prevents the thumb from moving off the track
			if (clientX < left || clientX > left + width) {
				return;
			}

			// deltaX is the distance the mouse has moved from the starting position
			deltaX = clientX - startX;

			// correctedX is the number of pixels left of the gutter container the thumb is 
			correctedX = startX + deltaX;

			trackThumbRef.current.style.left = `${vol * 100}%`;
			playedRef.current.style.width = `${vol * 100}%`;
		};

		const handlePointerUp = () => {
			// sanity check
			if (!wrapperRef.current || !trackThumbRef.current || !gutterContainerRef.current) {
				return;
			}

			// allow react to handle the progress event
			draggingRef.current = false;

			// get coordinates of the gutter container
			const { left, width } = gutterContainerRef.current.getBoundingClientRect();

			// percent moved from the left of the gutter container

			const percentMoved = (correctedX - left) / width;

			const vol = numClamp(percentMoved, 0, 1);

			if (vol <= 0.1) {
				dispatch({ type: Actions.SetVolume, payload: 0 });
			} else {
				dispatch({ type: Actions.SetVolume, payload: vol });
			}

			// remove event listeners
			document.removeEventListener('pointerup', handlePointerUp);
			wrapperRef.current.removeEventListener('pointermove', handlePointerMove);
		};

		if (wrapperRef && trackThumbRef.current) {
			trackThumbRef.current.addEventListener('pointerdown', handlePointerDown);
		}

		return () => {
			if (wrapperRef.current && trackThumbRef.current) {
				trackThumbRef.current.removeEventListener('pointerdown', handlePointerDown);
				wrapperRef.current.removeEventListener('pointermove', handlePointerMove);
				document.removeEventListener('pointerup', handlePointerUp);
			}
		};
	}, [dispatch, wrapperRef]);

	useEffect(() => {
		// if the user is not dragging the thumb, update the thumb position
		if (!draggingRef.current && gutterContainerRef.current) {

			// not using standard react state here because we don't want to re-render uselessly
			// when moving the mouse - save the frames
			if (trackThumbRef.current) {
				trackThumbRef.current.style.left = `${volume * 100}%`;
			}
			if (playedRef.current) {
				playedRef.current.style.width = `${volume * 100}%`;
			}
		}
	}, [volume]);

	const handleVolumeClick = useCallback((e: React.MouseEvent) => {
		// do nothing if the user is currently dragging
		if (!gutterContainerRef.current || draggingRef.current) {
			return;
		}

		const clientX = e.clientX;
		const { left, width } = gutterContainerRef.current.getBoundingClientRect();
		const correctedX = clientX - left;
		const percentMoved = correctedX / width;
		const vol = numClamp(percentMoved, 0, 1);

		if (vol <= 0.1) {
			dispatch({ type: Actions.SetVolume, payload: 0 });
		} else {
			dispatch({ type: Actions.SetVolume, payload: vol });
		}
	}, [dispatch]);


	return (
		<div className="volume-slider-container" ref={wrapperRef} onClick={handleVolumeClick}>
			<div className="track-gutter-container">
				<div className="track-gutter" ref={gutterContainerRef}>
					<div className="played-track" ref={playedRef}></div>
					<div
						className="thumb-trigger"
						style={{ left: -18 }}
						ref={trackThumbRef}
					>
						<div className="thumb"></div>
					</div>
				</div>
			</div>
		</div>
	);
};
