import { useEffect, useState } from 'react';

import { ColorResult, SketchPicker } from 'react-color';

import { numClamp } from '../../../../../../utils/utils';
import ModalComponent from '../../../../../general-ui/modal/modal';
import { hexToRgba, rgbaToHex } from '../../../../../../utils/colors/color.utils';

import './custom-color-editor-modal.scss';
import { ColorValueIndexes } from 'types/theme-packs';

interface ICustomColorEditorModalProps {
	color: [string, number] | null;
	open: boolean;
	handleClose: VoidFunction;
	setCustomColor: (prev: [string, number] | null) => void;
	addCustomColor: VoidFunction;
	blurLevel: number;
	setBlurLevel: (level: number) => void;
}

export default function CustomColorEditorModal({
	color,
	open,
	handleClose,
	setCustomColor,
	addCustomColor,
	blurLevel,
	setBlurLevel,
}: ICustomColorEditorModalProps): JSX.Element {
	const rgbaColor = hexToRgba(color?.[ColorValueIndexes.hex] || '');
	const transparency = color?.[ColorValueIndexes.opacity] || 1;
	const defaultPickerColor = { ...rgbaColor, a: transparency };

	const [pickedRgbaColor, setPickedRgbaColor] = useState<{ r: number; g: number; b: number; a?: number }>(defaultPickerColor);
	const [transparencyLevel, setTransparencyLevel] = useState(1);

	const pickedColor = rgbaToHex(pickedRgbaColor);

	useEffect(() => {
		setCustomColor([pickedColor, transparencyLevel]);
	}, [pickedColor, pickedRgbaColor, setCustomColor, transparencyLevel]);

	function handleColorChange(color: ColorResult) {
		setPickedRgbaColor(color.rgb);
		setTransparencyLevel(color.rgb.a || 1);
	}

	function handleSetBlur(e: React.ChangeEvent<HTMLInputElement>) {
		setBlurLevel(numClamp(e.target.valueAsNumber, 0, 100));
	}

	const handleSave = () => {
		setCustomColor([pickedColor, transparencyLevel]);
		addCustomColor();
		handleClose();
	};

	const footer = (
		<>
			<button className="small" style={{ marginRight: '8px' }} onClick={handleClose}>
				Cancel
			</button>
			<button className="small lemonade" onClick={handleSave}>
				Save
			</button>
		</>
	);

	return (
		<ModalComponent
			className="transparent-nav-modal"
			cancellable={true}
			closeable={false}
			open={open}
			onRequestClose={handleClose}
			title=""
			footer={footer}
		>
			<div className="transparent-nav-modal-picker">
				<SketchPicker
					width="300px"
					className="sketch-picker"
					color={pickedRgbaColor}
					presetColors={[]}
					onChange={handleColorChange}
					onChangeComplete={handleColorChange}
				/>
				<div className="transparent-nav-modal-blur">
					<span>Background blur</span>
					<input
						type="number"
						min={0}
						max={100}
						step={1}
						value={blurLevel}
						onChange={handleSetBlur}
					/>
				</div>
			</div>
		</ModalComponent>
	);
}
