import { useCallback } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { removeStorageItem } from '../../../../utils/local-storage';
import { Button } from '../../../general-ui/button/button';
import { useIsRegistrationV2 } from 'hooks/registration-hooks';

export function VerificationView({ template }: { template: string; }): JSX.Element {
	const { t } = useTranslate('homepage');
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const isRegistrationV2 = useIsRegistrationV2();
	const history = useHistory();

	const registerAgain = useCallback(() => {
		removeStorageItem(`reg.${eventBundle?.uuid}`);
		removeStorageItem('bl-profile');
		removeStorageItem('Bl-Profile-Token');
		history.push(history.location.pathname.split(/\?|#/)[0]);
	}, [eventBundle?.uuid, history]);

	return (
		<div className="registration-slider">
			{/* this component is dynamically rendered so we can hard code "active" class to make sure it's visible when rendered */}
			<div className={classNames({ "registration-panel fixed active": !isRegistrationV2, "registration-panel-v2 fixed active": isRegistrationV2 })}>
				<form className="registration-form">
					<div className="registration-form-inner verification" style={{ overflow: 'hidden' }}>
						<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", template)} tagName="h2">
							{t('homepage:registration.verificationHeader')}
						</TypographyItem>
						<TypographyItem tagName="p" className={classNames(template)}>
							{t("homepage:registration.verificationBody")}
						</TypographyItem>
					</div>
					<div className="registration-actions secondary">
						<div>
							<Button
								classButton={classNames('registration-button primary')}
								template={template}
								typeBtn="submit"
								onClick={registerAgain}
							>
								{t("homepage:registration.RegisterAgain")}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}

