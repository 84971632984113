import { GetSponsors } from "../../../connection/sponsors";
import { Action, AsyncAction } from "../../../types/actions";
import { Sponsor } from "../../../types/working-model";

export const LOAD_SPONSORS = "LOAD_SPONSORS";
export function loadSponsors(channel: number, token: string): AsyncAction {
  return {
    type: LOAD_SPONSORS,
    promise: GetSponsors(token, channel),
  };
}

export const ADD_SPONSOR = "ADD_SPONSOR";
export function addSponsor(sponsor: Sponsor): Action {
  return {
    type: ADD_SPONSOR,
    payload: sponsor,
  };
}

export const UPDATE_SPONSOR = "UPDATE_SPONSOR";
export function updateSponsor(sponsor: Sponsor): Action {
  return {
    type: UPDATE_SPONSOR,
    payload: sponsor,
  };
}
