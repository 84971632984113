import '../../../../../../scss/live-event/base/session/video-control-popup.scss';

export type VideoControlPopupProps = {
	text: string;
	position?: 'left' | 'right' | 'center';
}
export const VideoControlPopup = ({
	text,
	position
}: VideoControlPopupProps) => {
	return (
		<label className={`video-control-popup ${position ? position + '-aligned' : ""}`}> {text}</label >
	);
};