import { Get, Post, Put, Delete, GetJson, PutJson } from './helpers';
import { EventGroup } from '../types/working-model';
import { EventGroupTreeEntry } from 'types/access';

export function GetEventGroups(token: string, channel: number): Promise<EventGroup[]> {
	return Get(`/v3/admin/channel/${channel}/event-groups`, token);
}

export function CreateEventGroup(token: string, channel: number, name: string, eventUuids: string[], adminIds: number[]): Promise<EventGroup> {
	return Post(`/v3/admin/channel/${channel}/event-groups`, token, { name, eventUuids, adminIds }, false, {}, false, true);
}

export function AddEventsToEventGroup(token: string, channel: number, groupUuid: string, eventUuids: string[]): Promise<EventGroup> {
	return Post(`/v3/admin/channel/${channel}/event-groups/add-event-to-event-group`, token, { channel, groupUuid, eventUuids }, false);
}

export function UpdateEventGroup(token: string, channel: number, groupUuid: string, name: string, eventUuids: string[], adminIds: number[]): Promise<EventGroup> {
	return Put(`/v3/admin/channel/${channel}/event-groups/${groupUuid}`, token, { name, eventUuids, adminIds }, true, false, true);
}

export function DeleteEventGroup(token: string, channel: number, groupUuid: string): Promise<EventGroup> {
	return Delete(`/v3/admin/channel/${channel}/event-groups/${groupUuid}`, token, false, true);
}

export function FetchEventGroupTree(token: string, channel: number): Promise<{ tree: EventGroupTreeEntry, nameMap: Record<string, string> }> {
	return GetJson({
		path: `/v3/admin/channel/${channel}/event-group-tree`,
		token
	});
}

export type ModifyEventGroupsForAdminProps = {
	channel: number;
	email: string;
	groupsToDelete: string[];
	groupsToAdd: string[];
}
export function ModifyEventGroupsForAdmin(token: string, params: ModifyEventGroupsForAdminProps) {
	return PutJson<ModifyEventGroupsForAdminProps, { added: string[], deleted: string[], adminId: number }>({
		path: `/v3/admin/channel/${params.channel}/event-groups/admin`,
		token,
		data: params
	});
}