import { useState } from "react";
import { batch } from "react-redux";

import { CreateNewColorPack, SaveColor } from "../../../../../../connection/colors";
import { addNewColorPack, saveColorPack, setSelectedColor } from "../../../../../../store/actions/admin/create-event";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { Session } from "../../../../../../types/working-model";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import ColorEditor from "../../../settings/design/colors/color-editor";
import { random } from "utils/random";
import { ColorKeys, CreateThemePack, EColorPackchannels, EPaletteModes, ThemePack } from "types/theme-packs";
// keeping around, see notes below
// import { updateSessionStyling } from "store/actions/admin/create-event/session";
// import { shouldDisplayHomepage, shouldDisplayLandingPage } from "utils/utils";
import './panel-theme-color-selector.scss';
import getAdjustedThemePackColor from "utils/colors/get-adjusted-theme-pack-color";

interface IPanelThemeColorSelectorProps {
	selectedTheme?: EPaletteModes;
	session?: Session;
}

const PanelThemeColorSelector = (props?: IPanelThemeColorSelectorProps) => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const activeChannel = useTypedSelector(state => state.AuthReducer.user?.active_channel);

	const colorPalette = workingEvent?.settings?.design?.colors;
	const colors = colorPalette?.colors;
	const colorTheme = props?.selectedTheme || workingEvent?.settings?.design?.color_theme || EPaletteModes.Light;

	const sessionStyling = props?.session?.layout?.styling_overrides || {};
	const sessionColors = sessionStyling?.colors || { [EPaletteModes.Light]: {}, [EPaletteModes.Dark]: {} };

	const sessionThemePrimary = sessionColors?.[colorTheme]?.accentColor
		|| colors?.[colorTheme]?.accentColor
		|| colors?.accentColor;
	const sessionThemeAccent = sessionColors?.[colorTheme]?.secondaryAccentColor
		|| colors?.[colorTheme]?.secondaryAccentColor || colors?.secondaryAccentColor;

	const [savingOnLoad, setSavingOnLoad] = useState(true);

	const dispatch = useAppDispatch();

	const handleChange = async (
		field: 'accentColor' | 'secondaryAccentColor',
		updatedColor: [string, number]
	) => {
		if (!token) return;
		if (!activeChannel) return;
		if (!colorPalette || !colors) return;
		if (savingOnLoad) {
			setSavingOnLoad(false);
			return; // this will prevent the initial load from triggering a save
		}

		// NOTE:
		// keeping this commented if/else block for future reference
		// in case product wants to disconnect session primary/secondary colors from the theme colors
		// this should work out of the box because the rendering logic is already in place

		// duplicate (if default theme) or edit color pack if
		// single session and no home/landing/reg pages
		// else update session styling_overrides -> colors instead of theme to disconnect session colors from theme colors
		// if (
		// 	workingEvent.sessions.length === 1
		// 	&& !shouldDisplayLandingPage(workingEvent)
		// 	&& !shouldDisplayHomepage(workingEvent)
		// 	&& !workingEvent.registration_on
		// ) {
		try {
			const { light: _light, dark: _dark, ...topLevelColors } = colors;
			let updatedColorPalette: ThemePack = {
				...colorPalette,
				colors: {
					...colors,
					[colorTheme]: {
						...(colors[colorTheme] || topLevelColors),
						[field]: updatedColor,
					}
				}
			};

			if (
				colorPalette.creation_type === 'auto'
				&& field === ColorKeys.accentColor
			) {
				const buttonTextColor = getAdjustedThemePackColor(
					updatedColorPalette,
					updatedColor,
					colorTheme,
					ColorKeys.buttonTextColor,
				);

				updatedColorPalette = {
					...updatedColorPalette,
					colors: {
						...updatedColorPalette.colors,
						[colorTheme]: {
							...updatedColorPalette.colors[colorTheme],
							...buttonTextColor,
						}
					}
				};
			}

			updatedColorPalette = {
				...updatedColorPalette,
				colors: {
					...updatedColorPalette.colors,
					light: updatedColorPalette.colors.light,
					dark: updatedColorPalette.colors.dark,
				},
			};

			// if editing a base color, create a new color pack
			if (colorPalette.channel === EColorPackchannels.base_colors) {
				// create new palette and apply to event
				updatedColorPalette = {
					...updatedColorPalette,
					channel: activeChannel,
					title: `${colorPalette.title} Copy - ${random(6)}`,
				};
				const updatedPack: CreateThemePack = {
					...updatedColorPalette,
					font_pack: typeof colorPalette.font_pack === 'number' ? colorPalette.font_pack : colorPalette.font_pack?.font_pack,
					theme_mode: colorTheme,
				};
				const newColor = await CreateNewColorPack(token, updatedPack);
				batch(() => {
					dispatch(setSelectedColor(newColor)); // sets and saves the new color pack onto the event
					dispatch(addNewColorPack(newColor)); // adds the color pack to redux color pack list
				});
				return;
			}

			// save the updated color pack
			await SaveColor(token, {
				...updatedColorPalette,
				font_pack: typeof colorPalette.font_pack === 'number' ? colorPalette.font_pack : colorPalette.font_pack?.font_pack,
				theme_mode: colorTheme, // should we update this if updating from the session page?
			});
			dispatch(saveColorPack(updatedColorPalette));
		} catch (e) {
			console.error(e);
			let message = 'Something went wrong when saving color pack. Please try again.';
			if (typeof e === 'string' && e.trim().length && e.toLowerCase() !== 'bad request') {
				message = e;
			}
			showAlert({
				type: 'error',
				message: message
			});
		}

		// } else {

		// 	// update session styling_overrides -> colors instead of theme
		// 	const updatedSessionColors: ISessionColors = {
		// 		...sessionColors,
		// 		[colorTheme]: {
		// 			...sessionColors?.[colorTheme],
		// 			[field]: updatedColor
		// 		}
		// 	};

		// 	dispatch(updateSessionStyling({
		// 		...sessionStyling,
		// 		colors: updatedSessionColors,
		// 	}));
		// }
	};

	const renderTitle = (title: string) => () => {
		return (
			<div className="panel-color-picker-label-wrapper">
				{title}
			</div>
		);
	};

	if (!workingEvent || !sessionThemePrimary || !sessionThemeAccent) return null;
	return (
		<div className="panel-color-picker">
			<ColorEditor
				title="Primary"
				renderTitle={renderTitle("Primary")}
				color={sessionThemePrimary}
				onChange={(color: string, opacity: number) => handleChange('accentColor', [color, opacity])}
			/>
			<ColorEditor
				title="Accents"
				renderTitle={renderTitle("Accents")}
				color={sessionThemeAccent}
				onChange={(color: string, opacity: number) => handleChange('secondaryAccentColor', [color, opacity])}
			/>
		</div>
	);
};

export default PanelThemeColorSelector;
