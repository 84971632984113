import { CSSProperties, ReactNode } from "react";
import classNames from 'classnames';

import "./tooltip.scss";

export enum TooltipPositions {
	topCenter = "top-center",
	topRight = "top-right",
	topLeft = "top-left",
	bottomCenter = "bottom-center",
	bottomLeft = "bottom-left",
	bottomRight = "bottom-right",
	left = "left",
	leftNoStyle = "left-no-style",
	right = "right",
	nonAbsolute = "non-absolute",
}
export interface ITooltipProps {
	tooltip: JSX.Element | string;
	children: ReactNode;
	disable?: boolean;
	position?: TooltipPositions | string;
	textClassName?: string;
	containerClassName?: string;
	maxWidth?: number;
	htmlElement?: 'div' | 'span';
	styles?: CSSProperties;
	asDangerouslySetInnerHTML?: boolean;
}

export const Tooltip = ({
	textClassName,
	tooltip,
	children,
	disable,
	position,
	containerClassName,
	maxWidth,
	htmlElement = 'div',
	styles
}: ITooltipProps): JSX.Element => {
	if (disable) return <>{children}</>;

	const style: CSSProperties = maxWidth ? {
		maxWidth,
		width: maxWidth,
		whiteSpace: 'normal'
	} : {};

	const HtmlElement = htmlElement;
	return (
		<HtmlElement className={classNames("smart-tooltip", containerClassName, position ? position : "top-center")} style={styles}>
			{children}
			<span className="smart-tooltip-content">
				<span className={classNames(textClassName, "smart-tooltip-text")} style={style}>
					{tooltip}
				</span>
			</span>
		</HtmlElement>
	);
};
