import { showAlert, showAlertLong } from '@general-ui/alert/alert-service';
import { IEventbriteTicketPurchaseData } from 'connection/registration';
import { useEffect, useState } from 'react';
import { eventbriteTicketPurchase } from 'store/actions/event/event-actions';
import { useAppDispatch, useTypedSelector } from 'store/reducers/use-typed-selector';
import { isObject } from 'underscore';

interface IEventbriteMessage {
	origin: string;
	data: {
		event: string;
		data: {
			message: {
				orderId: string;
			};
		};
	};
}
const EVENTBRITE_RETRY_MAX = 5;

export const useEventbriteListener = () => {
	const dispatch = useAppDispatch();
	const eventUUID = useTypedSelector((state) => state.LiveEventReducer?.eventBundle?.uuid);
	const eventbriteOrderDetails = useTypedSelector((state) => state.LiveEventReducer?.eventbriteOrderDetails);
	const eventbriteOrderDetailsRetry = useTypedSelector((state) => state.LiveEventReducer?.eventbriteOrderDetailsRetry);
	const eventbriteOrderDetailsRetryCount = useTypedSelector((state) => state.LiveEventReducer?.eventbriteOrderDetailsRetryCount);
	const [hasChangedFields, setHasChangedFields] = useState(false);

	const [eventData, setEventData] = useState<IEventbriteMessage | null>(null);
	const publicUrl = process.env.PUBLIC_URL;

	useEffect(() => {
		// Needed to automatically change the value in the registration fields.
		function changeValue(input: Element, value: string) {
			const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
				window.HTMLInputElement.prototype,
				"value"
			)?.set;

			nativeInputValueSetter?.call(input, value);
			const inputEvent = new Event("input", { bubbles: true });
			input.dispatchEvent(inputEvent);
		}

		if (!hasChangedFields && eventbriteOrderDetails?.email) {
			// Corresponding to the first, last, and email fields in the registration form
			const firstNameField = document.querySelector('#registration-field-1')?.querySelector('input');
			const lastNameField = document.querySelector('#registration-field-2')?.querySelector('input');
			const emailField = document.querySelector('#registration-field-3')?.querySelector('input');

			if (firstNameField) changeValue(firstNameField, eventbriteOrderDetails?.first_name || "");
			if (lastNameField) changeValue(lastNameField, eventbriteOrderDetails?.last_name || "");
			if (emailField) changeValue(emailField, eventbriteOrderDetails?.email || "");


			setHasChangedFields(true);
		}
		// Once the data has loaded, 
	}, [eventbriteOrderDetails, hasChangedFields]);


	// Called to retry calling the eventbrite order details
	useEffect(() => {
		if (!eventbriteOrderDetailsRetry) return;


		if (eventbriteOrderDetailsRetryCount === 1) {
			showAlert({
				message: `Please stay on this page while the ticket purchase is being processed`,
				description: 'This may take a few moments',
				type: 'neutral',
				duration: 3000,
			});
		}
		const eventDataCheck = eventData
			&& isObject(eventData?.data)			// data must be an object
			&& eventData.origin === publicUrl;	// origin of message must be static.brandlive[-stage].com

		if (
			eventDataCheck
			&& eventData.data.event === "orderCompleted" // event must be "orderCompleted"
		) {

			if (eventbriteOrderDetailsRetryCount <= EVENTBRITE_RETRY_MAX) {
				const ticketData: IEventbriteTicketPurchaseData = {
					eventUuid: eventUUID || "",
					orderId: eventData.data.data?.message.orderId || "",
				};

				setTimeout(() => {
					dispatch(eventbriteTicketPurchase(ticketData));
				}, 750);

			} else {
				showAlertLong({
					message: `The ticket purchase was successful but your email could not be added to the event`,
					description: 'Please contact support for assistance to ensure you have access to the event',
					type: 'error'
				});
			}
		}
	}, [dispatch, eventData, eventUUID, eventbriteOrderDetailsRetry, eventbriteOrderDetailsRetryCount, publicUrl]);


	useEffect(() => {
		if (!eventUUID) return;

		// configuring the iframe is tough and won't be easy for non-engineers to do 
		// because of the search params, so this tool can be called on the event page (not the admin page)
		// to generate a pasteable iframe for the eventbrite page
		(window as any).getEventbriteIframeHelper = (
			eventId: string | number,
			height: number | string
		) => {
			const urlParams = new URLSearchParams({
				origin: window.location.origin,
				eventId: eventId.toString(),
				height: height.toString(),
			});

			console.log(`
				<!-- Eventbrite iframe minimum height is 425px -->
				<!-- If you choose a height lower than that the iframe will scroll inside itself -->
				<iframe
					height="${height}px"
					width="100%"
					style="min-width: 100%;"
					src="${publicUrl}/eventbrite.html?${urlParams.toString()}"
				></iframe>
			`);
		};

		const handleEventbriteMessage = (event: IEventbriteMessage) => {
			setTimeout(() => {
				if (
					event.origin === publicUrl	// origin of message must be static.brandlive[-stage].com
					&& isObject(event.data)			// data must be an object
					&& event.data.event === "orderCompleted" // event must be "orderCompleted"
				) {

					setEventData(event);
					const data = event.data;

					// Send to microservice to update passcode list.
					const ticketData: IEventbriteTicketPurchaseData = {
						eventUuid: eventUUID || "",
						orderId: data.data?.message.orderId || "",
					};
					dispatch(eventbriteTicketPurchase(ticketData));
				}
			}, 750);
		};

		window.addEventListener('message', handleEventbriteMessage, false);

		return () => {
			window.removeEventListener('message', handleEventbriteMessage);
		};
	}, [dispatch, eventUUID, publicUrl]);

};
