// MODULE IMPORTS
import { SetStateAction, useMemo } from "react";
import { Action } from "redux";
import { isArray } from "underscore";

// UTIL IMPORTS
import { addRegistrationRequirement, setRegistrationType, updateRegistrationRequirement, } from "../../../../../../../store/actions/admin/create-event";
import { addChecklistItem } from "../../../../../../../store/actions/authentication";
import { ChecklistTitles } from "../../../../../../../store/reducers/admin/assistant";
import { generateMagicLinksForEvent } from "store/actions/admin/magic-links";
import { CreateNewPasscodeList } from "connection/passcode-lists";

// COMPONENT UI IMPORTS
import { BrandliveEvent, EHeroSizes, ERegistrationLayoutTypes, GateTypes, PasscodeList, PASSCODE_LIST_TYPE, RegistrationLayoutTypes, RegistrationTypes, Requirement, RequirementMap, WorkingPasscodeList } from "../../../../../../../types/working-model";
import addPasscodeList from "@admin/people/add-passcode-list";
import { showAlert } from "@general-ui/alert/alert-service";


export const downloadErrorCSV = (newPasscodeList: any, name?: string) => {
	// Downloads a csv file with all the broken users
	if (newPasscodeList.csv) {
		const blob = new Blob([newPasscodeList["csv"]], { type: 'text/csv;charset=utf-8' });
		const link = document.createElement('a');
		link.setAttribute('href', URL.createObjectURL(blob));
		link.setAttribute('download', 'magic-link-nonvalid-emails.csv');
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};


const checkHasMagicLinks = (
	creatingNewPasscodeList?: boolean,
	passcodeListToCreate?: WorkingPasscodeList,
	workingGateRequirement?: Requirement,
	passcodeListMap?: { [key: string]: any; }
) => {

	if (!creatingNewPasscodeList || !passcodeListToCreate || !passcodeListMap) return false;
	const passcodeListHasMagicLinks = !!workingGateRequirement?.passcodeList?.some((listId) => {
		return passcodeListMap[listId]?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS;
	});

	return creatingNewPasscodeList ? passcodeListToCreate?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS : passcodeListHasMagicLinks;

};


function magicLinkCheck(
	hasMagicLinks: boolean,
	publishedUrl: string | null | undefined,
	workingEvent: any) {
	// Prevent attaching a magic link list to the event if event has never been published

	if (hasMagicLinks && !publishedUrl) {
		return showAlert({
			message: 'Please Publish Project First',
			description: `You need to publish ${workingEvent?.name || 'this event'} first before you can attach a magic links list`,
			type: 'error',
			duration: 5000,
		});
	}
}


interface finishedProps {
	dispatch: (action: Action) => Action;
	fetchingPublishedStatus: boolean;
	passcodeGating?: Requirement;
	passcodeListGatings?: Requirement[];
	passcodeListToCreate?: WorkingPasscodeList;
	setFormError: (value: string) => void;
	setSaving: (value: boolean) => void;
	setWorkingGateRequirement: (value: SetStateAction<Requirement | undefined>) => void;
	token: string | null;
	user: any;
	workingGateRequirement?: Requirement;
	setEditRequirement?: (value: SetStateAction<boolean>) => void;

	// Variables only used when saving a passcode list requirement
	addingNewToOption?: boolean;
	availablePasscodeLists?: PasscodeList[]
	creatingNewPasscodeList?: boolean;
	emailGating?: Requirement | undefined
	reqIndex?: number;
	passcodeListMap?: { [key: string]: any; }
	publishedUrl?: string | null;
	setAddingNewToOption?: (value: boolean) => void;
	setLoading?: (value: boolean) => void;
	setPasscodeListToCreate?: (value: WorkingPasscodeList | undefined) => void;
	workingEvent?: BrandliveEvent | null

}



export async function finishedWithRequirement({
	dispatch,
	fetchingPublishedStatus,
	passcodeGating,
	passcodeListGatings,
	passcodeListToCreate,
	setFormError,
	setSaving,
	setWorkingGateRequirement,
	token,
	user,
	workingGateRequirement,
	setEditRequirement,

	// Variables only used when saving a passcode list requirement
	addingNewToOption,
	availablePasscodeLists,
	creatingNewPasscodeList,
	emailGating,
	reqIndex,
	passcodeListMap,
	publishedUrl,
	setAddingNewToOption,
	setLoading,
	setPasscodeListToCreate,
	workingEvent,

}: finishedProps) {
	if (!token || !user?.active_channel || fetchingPublishedStatus) return;

	const formErrorMessage = requirementFormErrorMessage({ workingGateRequirement, passcodeGating, passcodeListToCreate, passcodeListGatings });
	if (formErrorMessage) {
		setFormError(formErrorMessage);
		return;
	} else {
		setFormError("");
	}

	setSaving(true);

	// Prevent attaching a magic link list to the event if event has never been published
	const hasMagicLinks = checkHasMagicLinks(creatingNewPasscodeList, passcodeListToCreate, workingGateRequirement, passcodeListMap);
	magicLinkCheck(hasMagicLinks, publishedUrl, workingEvent);

	let _workingGateRequirement = workingGateRequirement;

	// Check for no passcode lists
	if (!passcodeListToCreate && !workingGateRequirement?.passcodeList?.length && workingGateRequirement?.type === GateTypes.passcode_list) {
		return showAlert({
			message: 'Audience Lists Required',
			description: 'Please choose one or more audience lists from the dropdown.',
			type: 'error',
			duration: 5000,
		});
	}

	if (passcodeListToCreate && workingGateRequirement?.type === GateTypes.passcode_list && availablePasscodeLists && setLoading) {
		const listToCreate = {
			...passcodeListToCreate,
			name: passcodeListToCreate.name.trim(),
		};

		// Check for empty name
		if (!listToCreate.name) {
			return showAlert({
				message: 'Name Required',
				description: 'Please enter a unique name for this audience list.',
				type: 'error',
				duration: 5000,
			});
		}

		// Check for duplicate names
		const lowerCaseName = listToCreate.name.toLowerCase();
		const isDuplicate = availablePasscodeLists.some((list) => {
			return lowerCaseName === list.name?.toLowerCase();
		});
		if (isDuplicate) {
			return showAlert({
				message: 'Duplicate Audience List Name',
				description: `"${listToCreate.name}" is already in use. Please enter a unique name.`,
				type: 'error',
				duration: 5000,
			});
		}

		setLoading(true);
		const newPasscodeList = await CreateNewPasscodeList(token, user.active_channel, listToCreate, workingEvent?.uuid, undefined, workingEvent?.dma_id);

		if ("csv" in newPasscodeList) {
			showAlert({
				message: 'Unable to Create',
				description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
				type: 'error',
				duration: 10000,
				onClick: () => downloadErrorCSV(newPasscodeList)
			});
			return setLoading(false);
		}
		else if ("error" in newPasscodeList) {
			showAlert({
				message: 'Unable to Create',
				description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
				type: 'error',
				duration: 5000,
			});
			return setLoading(false);
		}
		if (isArray(newPasscodeList.failedEmails) && newPasscodeList.failedEmails.length > 0) {
			showAlert({
				message: 'Some emails were invalid and failed to upload:',
				description: newPasscodeList.failedEmails.join(' '),
				type: 'error',
				duration: 5000
			});
		}
		dispatch(addPasscodeList(newPasscodeList));

		const previousList = workingGateRequirement?.passcodeList || [];
		_workingGateRequirement = {
			...workingGateRequirement,
			passcodeList: [
				...previousList,
				(newPasscodeList.passcode_list)
			]
		};

		if (token && user && newPasscodeList && newPasscodeList.passcode_list && workingEvent?.uuid && hasMagicLinks) {
			dispatch(generateMagicLinksForEvent(token, user?.active_channel, newPasscodeList?.passcode_list, workingEvent?.uuid));
		}
	}

	if (_workingGateRequirement) {
		const type = _workingGateRequirement?.type;
		// To avoid duplicate requirements, first check if requirement exists and update accordingly. If the type doesnt exist then add it
		if (type === GateTypes.passcode_list && reqIndex !== null && !addingNewToOption) {
			//must pass the passcodeList index here
			dispatch(updateRegistrationRequirement(GateTypes.passcode_list, _workingGateRequirement, reqIndex));
		} else if (type === GateTypes.domain_email && emailGating || type === GateTypes.shared_passcode && passcodeGating) {

			dispatch(updateRegistrationRequirement(type, _workingGateRequirement));
		} else {
			dispatch(addRegistrationRequirement(type, _workingGateRequirement));

			// ASSISTANT CHECKLIST ITEM: complete users checklist item for editing registration settings
			if (
				user &&
				!user.onboarded.includes(ChecklistTitles.editRegistration)
			) dispatch(addChecklistItem(token, user.id, user.onboarded, ChecklistTitles.editRegistration));
		}
	}

	dispatch(setRegistrationType(RegistrationTypes.gated));
	setWorkingGateRequirement(undefined);
	setSaving(false);

	if (setEditRequirement) setEditRequirement(false);
	if (setAddingNewToOption) setAddingNewToOption(false);
	if (setLoading) setLoading(false);
	if (setPasscodeListToCreate) setPasscodeListToCreate(undefined);
}


interface HandleCancelRequirementProps {
	setEditRequirement: (value: SetStateAction<boolean>) => void;
	setAllRequirements: (value: SetStateAction<RequirementMap | undefined>) => void;
	requirement: Requirement | undefined;
	setWorkingGateRequirement: (value: SetStateAction<Requirement | undefined>) => void;
	gatingType: GateTypes;
	allRequirements?: RequirementMap;
}
export function handleCancelRequirment({
	setEditRequirement,
	setAllRequirements,
	requirement,
	setWorkingGateRequirement,
	gatingType,
	allRequirements,
}: HandleCancelRequirementProps) {
	setEditRequirement(false);

	let isNewRequirement = false;

	let _requirement = requirement as Requirement | Requirement[] | undefined;


	switch (gatingType) {
		case GateTypes.domain_email:
			isNewRequirement = !requirement?.allowedDomains;
			break;
		case GateTypes.passcode_list:
			_requirement = allRequirements?.[GateTypes.passcode_list]?.filter(r => r.gatedSessions?.length);
			isNewRequirement = !_requirement?.length;
			break;
		case GateTypes.shared_passcode:
			isNewRequirement = !requirement?.sharedPasscode;
			break;
		default:
			break;
	}

	// If its a new requirement, remove it
	if (isNewRequirement) {
		setAllRequirements(prev => {
			return ({
				...prev,
				[gatingType]: undefined
			});
		});
	}
	// If there's an existing requirement, reset it
	else {
		setAllRequirements(prev => {
			return ({
				...prev,
				[gatingType]: _requirement,
			});
		});
	}

	setWorkingGateRequirement(undefined);
}

export const codeOrListError = "Your event cannot have a shared passcode AND an audience list.";
export const passcodeListNameError = "Your list must have a name.";
export const passcodeListURLError = "You must upload an audience list.";
export const noSessionsError = "You must connect at least one Session to this list.";


interface requirementFormErrorMessageProps {
	workingGateRequirement: Requirement | undefined;
	passcodeGating?: Requirement;
	passcodeListToCreate?: { url: string, name: string };
	passcodeListGatings?: Requirement[];
}


export const domainRegex = (domains: string[] | undefined) => {
	if (!domains?.length) return "Must include at least one email domain";
	//If the domain doesnt include either .** or .*** warn the user
	const domainRegex = /\.[a-z]{2,3}/i;
	for (const domain of domains) {
		if (!domainRegex.test(domain)) {
			return "Please include an extension e.g. '.com'";
		}
	}
	return "";
};


export const requirementFormErrorMessage = ({
	workingGateRequirement,
	passcodeGating,
	passcodeListToCreate,
	passcodeListGatings,
}: requirementFormErrorMessageProps) => {
	switch (workingGateRequirement?.type) {
		//only Passcode list OR shared pass code may exist at the same time
		case GateTypes.passcode_list: {
			if (passcodeGating) return codeOrListError;
			if (!workingGateRequirement.gatedSessions?.length) return noSessionsError;
			if (passcodeListToCreate && !passcodeListToCreate.url) return passcodeListURLError;
			if (passcodeListToCreate && !passcodeListToCreate.name) return passcodeListNameError;
			else return "";
		}

		case GateTypes.shared_passcode: {
			const hasNonWhiteSpaceCharacters = /\S/;
			if (passcodeListGatings?.length) return codeOrListError;
			else if (!workingGateRequirement.sharedPasscode) return "Passcode field must have a value";
			else if (!hasNonWhiteSpaceCharacters.test(workingGateRequirement.sharedPasscode)) return "Passcode must have at least one character";
			else return "";
		}

		case GateTypes.domain_email: {
			if (passcodeGating) return codeOrListError;
			return domainRegex(workingGateRequirement?.allowedDomains);
		}
		default: return "";
	}
};

export const layoutVariants = (event: BrandliveEvent | null, isDesktop: boolean) => {
	const registrationStyle = event?.registration_settings?.style;
	const layout = registrationStyle?.layout || ERegistrationLayoutTypes.aboveTheFoldR;

	const isBigHero = registrationStyle?.hero_size === EHeroSizes.large;

	// We're setting isLeft to false on non desktop layouts to help clean up the css specificity
	const variants = {
		[ERegistrationLayoutTypes.aboveTheFoldL]: {
			isLeft: isDesktop,
			isBigHero: false,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.aboveTheFoldR]: {
			isLeft: false,
			isBigHero: false,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.heroL]: {
			isLeft: isDesktop,
			isBigHero: isBigHero,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.heroR]: {
			isLeft: false,
			isBigHero: isBigHero,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.modal]: {
			isLeft: false,
			isBigHero: false,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.landingL]: {
			isLeft: isDesktop,
			isBigHero: false,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.landingR]: {
			isLeft: false,
			isBigHero: false,
			isOldLayout: false
		},
		[ERegistrationLayoutTypes.formLeft]: {
			isLeft: true,
			isBigHero: false,
			isOldLayout: true
		},
		[ERegistrationLayoutTypes.formRight]: {
			isLeft: false,
			isBigHero: false,
			isOldLayout: true
		},
	};

	return variants[layout];




};
