// MODULE IMPORTS
import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { isEqual } from "underscore";

// UTILS IMPORTS
import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { GateTypes, PasscodeList, PASSCODE_LIST_TYPE, Requirement, RequirementMap, Session } from "../../../../../../../types/working-model";
import { OptionalComponent } from "utils/optional-component";
import { toDict } from "utils/utils";
import { PATHNAMES } from "utils/admin-routing-utils";
import { finishedWithRequirement, handleCancelRequirment, requirementFormErrorMessage } from "./requirements-utils";
import { useLanguageParam } from "components/live-event/utils";

// GENERAL UI IMPORTS
import CardEditRequirementButton from "./card-edit-requirement-button";
import { WorkingPasscodeList } from "@admin/create/registration/passcode-list";
import SessionSelectInput from "@general-ui/select/session-select";
import TagSelectInput from "../../../../../../general-ui/tag-select/tag-select";
import WaitingIndicator from "../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { useIsSingleSession } from "hooks/session.hooks";

interface CardAudienceListRequirementProps {
	requirement: Requirement | undefined,
	setAllRequirements: (value: SetStateAction<RequirementMap | undefined>) => void;
	allRequirements: RequirementMap | undefined;
	reqIndex: number;
	addingNewToOption: boolean;
	setAddingNewToOption: (value: SetStateAction<boolean>) => void;
	setIsAddNewRequirement: (value: SetStateAction<boolean>) => void;
}

const CardAudienceListRequirement: React.FC<CardAudienceListRequirementProps> = (props) => {
	const { requirement, setAllRequirements, reqIndex, addingNewToOption, setAddingNewToOption, setIsAddNewRequirement } = props;

	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const availablePasscodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const isSingleSession = useIsSingleSession();

	const requirements = workingEvent?.registration_settings?.requirements;
	const emailGating = requirements?.[GateTypes.domain_email];
	const passcodeGating = requirements?.[GateTypes.shared_passcode];
	const passcodeListGatings = requirements?.[GateTypes.passcode_list];
	const sessionIds = workingEvent?.sessions.map(session => session.session);
	const language = useLanguageParam();

	// The copy of the original requirement to be edited
	const [workingGateRequirement, setWorkingGateRequirement] = useState<Requirement | undefined>({ ...requirement } as Requirement | undefined);

	const [editRequirement, setEditRequirement] = useState(!requirement?.passcodeList?.length && availablePasscodeLists.length !== 0);
	const [popoverOpen, setPopoverOpen] = useState<GateTypes | null>(null);
	const [formError, setFormError] = useState("");
	const [saving, setSaving] = useState<boolean>(false);

	const [loading, setLoading] = useState(false);
	const [passcodeListToCreate, setPasscodeListToCreate] = useState<WorkingPasscodeList>();
	const [creatingNewPasscodeList, setCreatingNewPasscodeList] = useState(!availablePasscodeLists);
	const [workingSessionIds, setWorkingSessionIds] = useState<number[] | undefined>(requirement?.gatedSessions ?? (isSingleSession ? sessionIds : undefined));

	const dispatch = useAppDispatch();

	const passcodeListMap = useMemo(() => toDict('passcode_list', availablePasscodeLists), [availablePasscodeLists]);

	// Because TagSelectInput is being used to manage password lists, we'll have to work around it
	// Converting between string[] name tags, number[] IDs and PassCodeLists[] is going to happen in a few places
	// Here, a string[] for TagSelectInput is generated from PassCodeLists[]
	const passcodeListNames = workingGateRequirement?.passcodeList?.map(id => passcodeListMap[id]?.name);
	// No longer filtering for unique because names are used like IDs and we don't want to cover up the duplicate problem
	// TagSelectInput does exclude used tags, though, which can hide duplicates
	const passcodeListOptions = availablePasscodeLists?.map((list: PasscodeList) => list.name);


	useEffect(() => {
		if (!requirement?.gatedSessions) {
			setEditRequirement(true);
		}

		if (editRequirement) {
			setWorkingGateRequirement(requirement);
		}
	}, [editRequirement, requirement]);


	function handleCancel() {
		handleCancelRequirment({
			setEditRequirement,
			setAllRequirements,
			requirement,
			setWorkingGateRequirement,
			gatingType: GateTypes.passcode_list,
			allRequirements: requirements
		});
	}

	function handleSave() {

		const _workingGateRequirement = {
			...workingGateRequirement,
			gatedSessions: [...(workingSessionIds || [])],
		} as Requirement | undefined;

		const formErrorMessage = requirementFormErrorMessage({ workingGateRequirement: _workingGateRequirement, passcodeGating, passcodeListToCreate, passcodeListGatings });

		if (formErrorMessage) {
			setFormError(formErrorMessage);
			return;
		} else {
			setFormError("");
		}

		setAllRequirements(prev => {
			const _allPasscodeListsRequirements = [...prev?.[GateTypes.passcode_list] || []];
			const index = _allPasscodeListsRequirements.findIndex((req) => req.id === _workingGateRequirement?.id);
			if (index >= 0 && _workingGateRequirement) {
				_allPasscodeListsRequirements[index] = _workingGateRequirement;
			}

			return ({
				...prev,
				[GateTypes.passcode_list]: _allPasscodeListsRequirements
			});
		});

		finishedWithRequirement({
			dispatch,
			fetchingPublishedStatus,
			passcodeGating,
			passcodeListGatings,
			passcodeListToCreate,
			setFormError,
			setSaving,
			setWorkingGateRequirement,
			token,
			user,
			workingGateRequirement: _workingGateRequirement,
			setEditRequirement,

			creatingNewPasscodeList,
			passcodeListMap,
			publishedUrl,
			workingEvent,
			availablePasscodeLists,
			setLoading,
			reqIndex,
			addingNewToOption,
			emailGating,
			setAddingNewToOption,
			setPasscodeListToCreate,
		});
	}

	const renderPasslists = (audienceListRequirement: Requirement) => {
		const lists = audienceListRequirement
			?.passcodeList
			?.map((listId: number) => passcodeListMap[listId])
			?.filter(list => !!list)
			?? [];
		return (

			<ul className="tag-list">
				{lists.map((list: PasscodeList, index: number) => (
					<React.Fragment key={list.uuid}>
						{
							<Link
								role="button"
								to={PATHNAMES.AudienceList.AudienceListLink(workingEvent ? workingEvent?.uuid : "", language, list.uuid)}
								className={`clear no-style ${list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? "cursor-type-normal" : ""}`}
								onClick={list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? (event) => event.preventDefault() : undefined}
							>
								<li className="tag" key={list?.passcode_list} >
									{list?.name}
								</li>
							</Link>
						}
						{lists.length - 1 !== index && lists.length > 1 && <span>{`  ·  `}</span>}
					</React.Fragment>

				))}
			</ul>
		);
	};

	const sessionMap = useMemo(() => {
		if (workingEvent?.sessions) {
			return toDict('session', workingEvent.sessions);
		}
		return {};
	}, [workingEvent]);


	const renderGatedSessions = (audienceListRequirement: Requirement) => {
		const sessions = audienceListRequirement
			?.gatedSessions
			?.map((sessionId: number) => sessionMap[sessionId])
			?.filter(session => !!session)
			?? [];
		return (
			<>
				{sessions?.map((session: Session) => (
					session.title?.base || ''
				)).join(', ')}
			</>
		);
	};

	// To consolidate the change handling, this now accepts string[] from TagSelectInput via PasscodeListCreator...
	function handleChangePasscodeLists(listNames: string[]) {
		// ...but we'll need to update the working gate requirement with a list of IDs...
		const lists = listNames.reduce((lists: PasscodeList[], name) => {
			if (availablePasscodeLists) {
				const found = availablePasscodeLists.find((list) => name === list.name);
				if (found) lists.push(found);
				return lists;
			}
			else {
				return [];
			}
		}, []);

		const passcodeList: number[] = lists
			.map(list => list.passcode_list)
			.filter(listNum => listNum) as number[];


		const _workingGateRequirement = {
			...workingGateRequirement,
			passcodeList,
		} as Requirement | undefined;

		setWorkingGateRequirement(_workingGateRequirement);
	}


	function setSessionIds(sessions: number[]) {
		if (!isEqual(workingSessionIds, sessions)) {
			setWorkingSessionIds(sessions);
		}
	}

	const preSelected = workingSessionIds;

	return (
		<div className="display-options">
			<OptionalComponent display={!editRequirement}>
				<>
					<CardEditRequirementButton
						type={GateTypes.passcode_list}
						setEditRequirement={setEditRequirement}
						popoverOpen={popoverOpen}
						setPopoverOpen={setPopoverOpen}
						setAllRequirements={setAllRequirements}
					/>

					<div className="display-option-block">
						<div className="display-option-title">Audience Lists</div>
						<div className="display-option-content">{requirement && renderPasslists(requirement)} </div>
					</div>

					<div className="display-option-block">
						<div className="display-option-title">Gated Sessions</div>
						<div className="display-option-content">{requirement && renderGatedSessions(requirement)}</div>
					</div>
				</>
			</OptionalComponent>

			<OptionalComponent display={editRequirement}>
				<>
					<div className="display-option-block">
						<div className="display-option-title">
							<p>Audience Lists</p>
							<button
								onClick={() => { setIsAddNewRequirement(true); }}
								className="add-new no-style no-padding"
							>
								Add
							</button>
						</div>
						<div className="display-option-content">{
							<TagSelectInput
								onChange={handleChangePasscodeLists}
								defaultTags={passcodeListOptions}
								tags={passcodeListNames}
								restrictToList
								shouldSearch
								errorMessage={isSingleSession ? formError : ""}
							/>
						} </div>
					</div>

					{!isSingleSession &&
						<div className="display-option-block">
							<div className="display-option-title">Gated Sessions</div>
							<div className="display-option-content">{requirement &&
								<SessionSelectInput
									sessions={workingEvent?.sessions ?? []}
									onChange={setSessionIds}
									placeholder={"Select at least one session"}
									preSelectedSessions={preSelected}
									errorMessage={formError}
									ignoreInlineStyling
									label=''
								/>
							}
							</div>
						</div>
					}

					<div className="requirement-footer">
						<button onClick={handleCancel}>Cancel</button>
						<button onClick={handleSave} className="primary rainbow">{saving ? <WaitingIndicator /> : "Save"}</button>
					</div>
				</>
			</OptionalComponent>

		</div>
	);
};
export default CardAudienceListRequirement;
