import { useMemo, useContext, useState, useEffect } from "react";
import { convertSecondsToHMS } from "../../../../../../utils/utils";
import * as Signals from '../../../../../../utils/event-emitter';
import { VideoStateContext } from "../../session-stream-provider";
import { TimeUpdate } from "../types";

const CurrentTimeIndicator: React.FC = () => {
	const { state: { durationInSeconds, isLive } } = useContext(VideoStateContext);
	const [currentSecond, setCurrentSecond] = useState(0);

	// we don't want to send the time update to the context because it fires at least
	// every second. Create a listener here to listen for time updates and render just this single component
	// rather than render the entire video player just to visually update this indicator
	useEffect(() => {
		const updateRefs = (progress: TimeUpdate) => {
			setCurrentSecond(progress.playedSeconds);
		};

		Signals.addEventListener('video-player-progress', updateRefs);

		return () => {
			Signals.removeEventListener('video-player-progress', updateRefs);
		};
	}, []);

	return useMemo(() => {
		if (durationInSeconds > 1 && !isLive) {
			return (
				<div className="current-time-indicator">
					<span><span id="current-second">{convertSecondsToHMS(currentSecond, true)}</span><span is="seconds-spacer"> / </span><span id="duration-seconds">{convertSecondsToHMS(durationInSeconds, true)}</span></span>
				</div>
			);
		}

		return null;
	}, [durationInSeconds, currentSecond, isLive]);
};

export default CurrentTimeIndicator;