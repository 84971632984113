import {
	CSSProperties,
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import classNames from "classnames";

import "./tooltip-portal.scss";

import Portal from "utils/react-portal";
import { setAnchorPosition } from "./set-anchor-position";
import { setTailPosition } from "./set-tail-position";
import { usePositionOnScroll } from "./use-position-on-scroll";
import { TooltipPortalContentDirection, TooltipPortalPosition } from "./tooltip-portal-types";


/* 
	TIPS:
		- if you want to apply hover styles on the parent component your tooltip lives in
			you can use css `&:has(.tooltip-portal-wrapper.active) { ... }` to apply styles.
			There is a good example of that in `theme-pack-overview-card-slim.scss`
*/

type ITooltipPortal = PropsWithChildren<{
	content: JSX.Element;
	position?: TooltipPortalPosition;
	contentDirection?: TooltipPortalContentDirection;
	styles?: {
		/** backgroundColor will override mode background-color */
		backgroundColor?: CSSProperties['backgroundColor'];
		/** color will override mode color */
		color?: CSSProperties['color'];
		width?: CSSProperties['width'];
		padding?: CSSProperties['padding'];
		borderRadius?: CSSProperties['borderRadius'];
		boxShadow?: CSSProperties['boxShadow'];
		fontSize?: CSSProperties['fontSize'];
	};
	portalWrapperStyles?: React.CSSProperties;
	/** custom style background and color will override this light/dark mode */
	mode?: 'light' | 'dark';
	/**
	 * A positive number to adjust the left/right tooltip tooltop position when using top/bottom positio with ltr/rtl contentDirection. 
	 * 
	 * Useful when increasing the border-radius of the tooltip.
	 * 
	 * Note: this is not used to "guess" the position. It shifts the tooltip content by the number of pixels provided while maintaining the correct tail position.
	 * */
	horizontalOffset?: number;
	tooltipWrapperClass?: string;
	childrenWrapperClass?: string;
	contentWrapperClass?: string;
	portalHover?: (isHovered: boolean) => void;
	stopPropagation?: boolean;
	disabled?: boolean;
	delayStart?: number;
	/** tailSize is a number that is converted to px */
	tailSize?: number;
	keepOpenOnContentHover?: boolean;
}>;

const TooltipPortal = ({
	children,
	content,
	position = TooltipPortalPosition.LEFT,
	contentDirection = TooltipPortalContentDirection.LTR,
	styles = {},
	portalWrapperStyles = {},
	horizontalOffset = 1, // using 1px to account for border radius
	mode = 'dark',
	childrenWrapperClass = '',
	contentWrapperClass = '',
	tooltipWrapperClass = '',
	portalHover,
	stopPropagation = true,
	disabled,
	delayStart = 0,
	tailSize = 7,
	keepOpenOnContentHover = true,
}: ITooltipPortal) => {
	const tooltipChildrenRef = useRef<HTMLDivElement>(null);
	const portalContentRef = useRef<HTMLDivElement>(null);
	const tooltipContainerRef = useRef<HTMLDivElement>(null);
	const tailRef = useRef<HTMLSpanElement>(null);

	const displayTimeout = useRef<NodeJS.Timeout>();
	const delayTimeout = useRef<NodeJS.Timeout>();

	const [display, setDisplay] = useState('none');
	const [show, setShow] = useState(false);

	const setPositions = useCallback(() => {
		setAnchorPosition({
			tooltipChildrenRef,
			portalContentRef,
			tooltipContainerRef,
			position,
			contentDirection,
			horizontalOffset,
			tailSize,
		});

		setTailPosition({
			tooltipChildrenRef,
			portalContentRef,
			tailRef,
			position,
			contentDirection,
			horizontalOffset,
			tailSize,
		});

	}, [position, contentDirection, horizontalOffset, tailSize]);

	usePositionOnScroll({ setPositions, show, tooltipContainerRef });

	const handleShow = useCallback(async () => {
		if (disabled) return;
		displayTimeout.current && clearTimeout(displayTimeout.current);
		delayTimeout.current && clearTimeout(delayTimeout.current);
		if (delayStart) {
			delayTimeout.current = setTimeout(() => {
				requestAnimationFrame(setPositions);
				setDisplay('block');
				setShow(true);
			}, delayStart);
			return;
		}
		requestAnimationFrame(setPositions);
		setDisplay('block');
		setShow(true);
	}, [delayStart, disabled, setPositions]);

	const handleHide = useCallback(() => {
		if (disabled) return;
		delayTimeout.current && clearTimeout(delayTimeout.current);
		displayTimeout.current && clearTimeout(displayTimeout.current);
		setShow(false);
		displayTimeout.current = setTimeout(() => {
			setDisplay('none');
		}, 200);
	}, [disabled]);

	const handlePortalEnter = useCallback(() => {
		if (disabled) return;
		portalHover?.(true);
	}, [disabled, portalHover]);

	const handlePortalLeave = useCallback(() => {
		if (disabled) return;
		portalHover?.(false);
	}, [disabled, portalHover]);

	// because this component finds fixed positions, when the inner content changes, the position jumps
	// for example, if something takes a second to load within the tooltip, it will re-render, change the size
	// of the tooltip and cause the tooltip position to be off
	// so we need to correct the position when the tooltip height/width changes
	// Note: this resize observer does not trigger on browser resize. So it doesn't overfire
	useEffect(() => {
		const portalEl = portalContentRef.current;
		if (!portalEl || !show || display === 'none') return;

		// track element position
		const resizeObserver = new ResizeObserver((entries) => {
			for (const _entry of entries) {
				setPositions();
			}
		});
		if (portalEl) {
			resizeObserver.observe(portalEl);
		}

		return () => {
			resizeObserver.disconnect();
		};

	}, [portalContentRef, show, display, setPositions]);

	return (
		<div
			className={`tooltip-portal-wrapper ${show ? 'active' : ''} ${tooltipWrapperClass ?? ''}`}
			ref={tooltipContainerRef}
			onPointerEnter={handleShow}
			onPointerLeave={handleHide}
			role="tooltip"
			style={portalWrapperStyles}
		>
			<div
				ref={tooltipChildrenRef}
				className={`tooltip-portal-children ${childrenWrapperClass} ${show ? 'active' : ''}`}
			>
				{children}
			</div>
			{/* Portal allows us to overflow any content on the page. It is set to position fixed using css */}
			<Portal className="tooltip-portal">
				<div
					onClick={stopPropagation ? e => e.stopPropagation() : undefined}
					className={classNames("tooltip-portal-content", contentWrapperClass, { 'light-mode': mode === 'light' })}
					style={{
						'--tooltip-portal-width': styles.width,
						'--tooltip-portal-background-color': styles.backgroundColor,
						'--tooltip-portal-padding': styles.padding,
						'--tooltip-portal-border-radius': styles.borderRadius,
						'--tooltip-portal-color': styles.color,
						'--tooltip-portal-box-shadow': styles.boxShadow,
						'--tooltip-portal-font-size': styles.fontSize,
						'--tooltip-portal-tail-size': `${tailSize}px`,
						display,
						opacity: show ? 1 : 0,
					} as CSSProperties}
					ref={portalContentRef}
					onPointerEnter={keepOpenOnContentHover ? handlePortalEnter : handleHide}
					onPointerLeave={handlePortalLeave}
				>
					{content}
					<span
						ref={tailRef}
						className="tooltip-portal-tail"
					/>
				</div>
			</Portal>
		</div>
	);
};

export default TooltipPortal;
