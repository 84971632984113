import "./sign-in-with-google.scss";
import WaitingIndicator from "@general-ui/waiting-indicator/waiting-indicator";
import classNames from "classnames";
import { useTranslate } from "i18n/useTranslationModules";
import React from "react";

export default function SignInWithGoogle(
	{ autoRegistering, soldOut, isRegClosed }: { autoRegistering: boolean, soldOut: boolean, isRegClosed: boolean }
): JSX.Element {

	const { t } = useTranslate(['registrations', 'homepage']);

	const handleSigninWithGoogle = (e: React.FormEvent) => {
		e.preventDefault();
		if (isRegClosed) return;
		window.history.pushState({}, document.title, window.location.pathname); // clear params before sending to Google
		const params = new URLSearchParams(window.location.search);
		params.set('googleAuthRedirect', encodeURIComponent(window.location.href));

		if (window.location.host.includes('localhost')) {
			window.location.href = "http://localhost:3000/google-auth?" + params.toString();
		} else {
			window.location.href = `https://google-auth.${(process.env.REACT_APP_BASE_URL as string).replace('https://', '')}?${params.toString()}`;
		}
	};

	// Bound radius of sign in button to 4 or 20px based on custom border radius
	const customButtonBorderRadius = getComputedStyle(document.body).getPropertyValue('--customButtonBorderRadius') ?? '12px';
	const customButtonBorderRadiusNumber = Number(customButtonBorderRadius.replace('px', ''));

	return (
		<button className={classNames("gsi-material-button", { "pill": customButtonBorderRadiusNumber >= 20 })}
			onClick={handleSigninWithGoogle}
			disabled={autoRegistering || soldOut || isRegClosed}
		>

			{autoRegistering ? <WaitingIndicator /> : (
				<>
					<div className="gsi-material-button-state"></div>
					<div className="gsi-material-button-content-wrapper">
						<div className="gsi-material-button-icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ "display": "block" }}>
								<path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
								<path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
								<path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
								<path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
								<path fill="none" d="M0 0h48v48H0z"></path>
							</svg>
						</div>
						<span className="gsi-material-button-contents">
							{t("homepage:registration.Sign in with Google")}
						</span>
						<span style={{ "display": "none" }}>
							{t("homepage:registration.Sign in with Google")}
						</span>
					</div>
				</>
			)}
		</button>
	);
}