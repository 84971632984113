import classNames from "classnames";
import { useMemo } from "react";

import { TranslateString } from "../../../../../../../types/working-model";
import { useHistoryState } from "../../../../../../../utils/use-history-state";
import { useLanguageParam } from "components/live-event/utils";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { usePageModule } from "../hooks/panel.hooks";

import './panel-wrapper.scss';

const PanelWrapper: React.FC<{ children: JSX.Element, title?: string, id?: string }> = ({ children, title, id }) => {
	const pageModule = usePageModule();
	const language = useLanguageParam();
	const historyState = useHistoryState<{ panelTitle: TranslateString | string }>();

	// custom tab titles live within the page module
	// so if this is a custom sub tab, first use translate string
	// custom heading, fall back to selected title
	const renderTitle = useMemo(() => {
		if (historyState?.panelTitle) {
			// if the panel title is a plain string just use it as-is, otherwise use it as a translateString
			// selecting the current path language for the heading
			if (typeof historyState.panelTitle === 'string') {
				return historyState.panelTitle;
			} else {
				return historyState.panelTitle[language] ?? historyState.panelTitle.base;
			}
		}

		if (pageModule?.content?.custom_tab) {
			if (pageModule?.content?.custom_heading[language]) {
				return pageModule.content.custom_heading[language];
			} else if (pageModule?.content?.custom_heading.base) {
				return pageModule.content.custom_heading.base;
			}
		}

		return title;
	}, [
		historyState?.panelTitle,
		pageModule?.content?.custom_tab,
		pageModule?.content?.custom_heading,
		title,
		language
	]);

	return (
		<div className="panel-wrapper-v2" id={id}>
			<div className={classNames('panel-header-v2')}>
				<div className="title">
					<div className="title-left">
						<button
							className="no-style"
							onClick={() => history.back()}>
							<Icon name={ICONS.ARROW_LEFT} size={16} color={COLORS.WHITE} />
						</button>
						<h6>{renderTitle}</h6>
					</div>
				</div>
			</div>
			<div className="panel-body-v2">
				{children}
			</div>
		</div>
	);
};

export default PanelWrapper;