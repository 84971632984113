import React, { useCallback, useEffect, useState } from 'react';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import useTimeout from '../../../../utils/use-timeout';

interface IMomentsAgoProps {
	timestamp: number | null;
}

const MomentsAgo: React.FC<IMomentsAgoProps> = ({ timestamp }) => {
	const [countdown, setCountdown] = useState('');
	const { t } = useTranslate("session");

	const dateCountdown = useCallback(() => {
		if (timestamp) {

			const date = new Date(timestamp);
			const now = new Date();
			const msBetween = date.valueOf() - now.valueOf();
			const day = 1000 * 60 * 60 * 24;
			const hour = 1000 * 60 * 60;
			const minute = 1000 * 60;

			let specialString = '';

			if (msBetween < -day) {
				const daysPast = Math.round(msBetween / -day);
				const desc = daysPast === 1 ? "day ago" : "days ago";
				specialString = `${daysPast} ${t(desc)}`;
				// Live 1 day ago OR Live 2 days ago
			}
			
			else if (msBetween < -hour) {
				const hoursPast = Math.round(msBetween / -hour);
				const desc = hoursPast === 1 ? "hour ago" : "hours ago";
				// Live 1 hour ago OR Live 2 hours ago
				specialString = `${hoursPast} ${t(desc)}`;
			}
			
			else if (msBetween < -minute) {
				const minsPast = Math.round(msBetween / -minute);
				const desc = minsPast === 1 ? "minute ago" : "minutes ago";
				specialString = `${minsPast} ${t(desc)}`;
				// Live 1 minute ago
			}

			setCountdown(specialString);
		}
	}, [timestamp, t]);

	const { runWithTimeout } = useTimeout(() => {
		dateCountdown();
		runWithTimeout();
	}, 1000);

	const isEnd = useCallback(() => {
		if (timestamp) {
			const today = new Date();
			const date = new Date(timestamp);
			const msBetween = date.valueOf() - today.valueOf();
			return msBetween <= 0;
		}
		return true;
	}, [timestamp]);

	useEffect(() => {
		dateCountdown();

		if (!isEnd()) {
			runWithTimeout();
		}
	}, [dateCountdown, isEnd, runWithTimeout, timestamp]);
	return (
		isEnd() ? <p className='evt-typography-subtitle-3'>{countdown}</p> : <></>
	);
};

export default MomentsAgo;
