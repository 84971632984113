import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getTicketQuantities } from "store/actions/event/event-actions";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { LiveEventProps } from "../live-event";

// previously loaded alongside the live event bundle on page load for every page load
// now checks for the existence of tickets before requesting
// that is why the props match the LiveEvent props. This was originally written
// to use the same props as the request to load the event bundle
export const useTicketHistory = (props: LiveEventProps, uuid?: string) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const prevLoaded = useRef<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const { eventNamePass, debug, host } = props;
		const ticketing = eventBundle?.registration_steps?.find(step => step.type === 'ticketing' && step.isOn === true)?.ticketing;

		if (ticketing && host && eventNamePass && !prevLoaded.current) {
			prevLoaded.current = true;
			if (!debug) {
				dispatch(getTicketQuantities(host.replace('brandlive-staging.com', 'brand.live'), eventNamePass));
			} else if (uuid) {
				dispatch(getTicketQuantities('dev', uuid));
			}
		}
	}, [eventBundle?.registration_steps, props, dispatch, uuid]);
};