import { handle } from 'redux-pack';
import { Action } from "../../../types/actions";
import {
	GET_PEOPLE,
	ADD_SELECTED_PERSON,
	REMOVE_SELECTED_PERSON,
	SEARCH_PEOPLE,
	CLEAR_SELECTED_PEOPLE,
	GET_EVENT_REGISTRATIONS,
	GET_PEOPLE_COUNT,
	GET_PEOPLE_SEARCH_COUNT,
	GET_EVENT_REGISTRATIONS_COUNT,
	SEARCH_EVENT_REGISTRATIONS,
	UPDATE_EVENT_REGISTRATIONS_SEARCH,
	UPDATE_PEOPLE_SEARCH,
	GET_EVENT_REGISTERED_USERS,
	CLEAR_EVENT_REGISTERED_USERS,
	UPDATE_REGISTRATIONS_ATTENDED_STATUS,
} from '../../actions/admin/people';
import { MarkedAttendedRegistration } from '../../../types/working-model';

export interface IRegisteredUser {
	registration_id: number,
	event: number,
	brandlive_profile: number | null,
	created: string,
	email: string,
	values: string[],
	legacy_reg_id: any,
	from_saml: boolean,
	uuid: string,
	valid_sessions: any[],
	bl_profile: number;
	locations?: any[];
	hidden?: boolean;
	marked_attended_by_admin?: MarkedAttendedRegistration;
	deleted?: boolean;
}

export interface RegisteredUsersState {
	eventUsers: IRegisteredUser[];
	users: IRegisteredUser[];
	selectedUsers: any[];
	eventSearchValue: string;
	searchValue: string;
	eventUsersCount: number;
	count: number;
	loadingUsers: boolean;
	loadingCount: boolean;
	registeredUsers: any[];
	fetchingRegisteredUsers: boolean;
}

const initialState: RegisteredUsersState = {
	count: 0,
	eventSearchValue: "",
	eventUsers: [],
	eventUsersCount: 0,
	fetchingRegisteredUsers: true,
	loadingCount: false,
	loadingUsers: false,
	registeredUsers: [],
	searchValue: "",
	selectedUsers: [],
	users: [],
};

export default function RegisteredUsersReducer(state: RegisteredUsersState = initialState, action: Action): RegisteredUsersState {
	switch (action.type) {
		case GET_PEOPLE: {
			return handle(state, action, {
				start: state => ({ ...state, loadingUsers: true }),
				success: state => ({ ...state, users: action.payload }),
				finish: state => ({ ...state, loadingUsers: false }),
			});
		}
		case SEARCH_PEOPLE: {
			return handle(state, action, {
				start: state => ({ ...state, loadingUsers: true }),
				success: state => ({ ...state, users: action.payload }),
				finish: state => ({ ...state, loadingUsers: false }),
			});
		}
		case ADD_SELECTED_PERSON: {
			return {
				...state,
				selectedUsers: [...state.selectedUsers, action.payload]
			};
		}
		case REMOVE_SELECTED_PERSON: {
			return {
				...state,
				selectedUsers: state.selectedUsers.filter((user) => user.uuid !== action.payload.uuid)
			};
		}
		case CLEAR_SELECTED_PEOPLE: {
			return {
				...state,
				selectedUsers: []
			};
		}
		case GET_EVENT_REGISTRATIONS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingUsers: true }),
				success: state => ({ ...state, eventUsers: action.payload }),
				finish: state => ({ ...state, loadingUsers: false })
			});
		}
		case GET_PEOPLE_COUNT: {
			return handle(state, action, {
				start: state => ({ ...state, loadingCount: true }),
				success: state => ({ ...state, count: Number(action.payload.count) }),
				finish: state => ({ ...state, loadingCount: false })
			});
		}
		case GET_EVENT_REGISTRATIONS_COUNT: {
			return handle(state, action, {
				start: state => ({ ...state, loadingCount: true }),
				success: state => ({ ...state, eventUsersCount: Number(action.payload.count) }),
				finish: state => ({ ...state, loadingCount: false })
			});
		}
		case SEARCH_EVENT_REGISTRATIONS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingUsers: true }),
				success: state => ({ ...state, eventUsers: action.payload }),
				finish: state => ({ ...state, loadingUsers: false })
			});
		}
		case UPDATE_EVENT_REGISTRATIONS_SEARCH: {
			return ({ ...state, eventSearchValue: action.payload });
		}
		case GET_PEOPLE_SEARCH_COUNT: {
			return handle(state, action, {
				start: state => ({ ...state, loadingCount: true }),
				success: state => ({ ...state, count: Number(action.payload.count) }),
				finish: state => ({ ...state, loadingCount: false })
			});
		}
		case UPDATE_PEOPLE_SEARCH: {
			return ({ ...state, searchValue: action.payload });
		}
		case GET_EVENT_REGISTERED_USERS: {
			return handle(state, action, {
				success: state => ({
					...state,
					registeredUsers: action.payload,
				}),
				finish: state => ({
					...state,
					fetchingRegisteredUsers: false,
				})
			});
		}
		case CLEAR_EVENT_REGISTERED_USERS: {
			return {
				...state,
				registeredUsers: action.payload
			};
		}

		case UPDATE_REGISTRATIONS_ATTENDED_STATUS: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						eventUsers: state.eventUsers.map((user) => {
							if (action.payload.registrationUUIDS.includes(user.uuid)) {
								return {
									...user,
									marked_attended_by_admin: action.payload.markedAttendedByAdmin
								};
							}
							return user;
						}),
					};
				}
			});
		}

		default: return state;
	}
}