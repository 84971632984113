import { useEffect, useRef, useState } from "react";

import { Session } from "../types/working-model";

export enum ETimestampStatus {
	preLive = 'pre-live',
	live = 'live',
	ended = 'ended'
}

const useTimestampStatus = (session: Session | null, interval = 5000): ETimestampStatus => {
	const [timestampStatus, setTimestampStatus] = useState<ETimestampStatus>(ETimestampStatus.preLive);
	const timestampRef = useRef<Date | null>(session?.timestamp ? new Date(session.timestamp) : null);
	const endTimestampRef = useRef<Date | null>(session?.end_timestamp ? new Date(session.end_timestamp) : null);
	const timeout = useRef<NodeJS.Timeout | null>(null);

	// only monitoring the numeric value of these so that 
	// we don't have to create a new Date object every render cycle
	const start = session?.timestamp;
	const end = session?.end_timestamp;

	// creating date objects is surprisingly slow, so we only set them when they need to change
	// because in almost all render cycles the timestamp of the session is the same
	// this solves for a persistent warning about setTimeout handler violations
	useEffect(() => {
		timestampRef.current = start ? new Date(start) : null;
	}, [start]);

	useEffect(() => {
		endTimestampRef.current = end ? new Date(end) : null;
	}, [end]);

	const sessionExists = !!session;

	useEffect(() => {
		// this will run every n seconds as long as the session is defined
		const check = () => {
			if (!sessionExists) return;
			if (timeout.current) {
				clearTimeout(timeout.current);
			}

			// use refs for timestamps so we don't have to continually redefine them (which is slow and they dont change often)
			// because they are refs we do not have to bother restarting the interval when they change
			if (timestampRef.current && endTimestampRef.current) {
				const date = Date.now();
				const timestamp = timestampRef.current;
				const end_timestamp = endTimestampRef.current;
				if (timestamp.getTime() < date && end_timestamp.getTime() > date) {
					setTimestampStatus(ETimestampStatus.live);
				} else if (date > end_timestamp.getTime()) {
					setTimestampStatus(ETimestampStatus.ended);
				} else {
					setTimestampStatus(ETimestampStatus.preLive);
				}
			}
			timeout.current = setTimeout(() => {
				check();
			}, interval);
		};

		check();

		return () => {
			if (timeout.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [
		sessionExists,
		interval,
	]);

	return timestampStatus;
};

export default useTimestampStatus;
