import { GetJson } from "../helpers";

export const GetEngagementsTotals = ({
	token,
	sessionUuid
}: {
	token: string,
	sessionUuid: string
}) => GetJson<{ module_id: number, responses: number }[]>({
	path: `/v3/admin/events/sessions/engagement/totals/${sessionUuid}`,
	token
});