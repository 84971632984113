import { useEffect } from 'react';

interface IClickAwayProps {
	ref: any;
	open: boolean;
	onClickAway?: () => void;
	onClickInside?: () => void;
	onEscape?: () => void;
	onTab?: () => void;
	onShiftTab?: () => void;
	onMousedownClickAway?: () => void;
	onMouseupClickAway?: () => void;
	onMousedownClickInside?: () => void;
}
/**
 * 
 * This hook listens for clicks outside of a given ref and calls the appropriate callback.
 * 
 * ***It does not support touch screens.***
 */
const useClickAwayListener = ({
	ref,
	onClickAway,
	onClickInside,
	onMousedownClickAway,
	onMouseupClickAway,
	onMousedownClickInside,
	onEscape,
	onTab,
	onShiftTab,
	open,
}: IClickAwayProps): void => {

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (!open) return;
			if (!ref?.current?.contains?.(event.target)) {
				onClickAway?.();
			} else {
				onClickInside?.();
			}
		};

		const handleMouseDownClickOutside = (event: MouseEvent) => {
			if (!open) return;
			if (!ref?.current?.contains?.(event.target)) {
				onMousedownClickAway?.();
				onMouseupClickAway?.();
			} else {
				onMousedownClickInside?.();
			}
		};

		const handleKeypress = (event: KeyboardEvent) => {
			// on escape
			if (open && event.key === 'Escape') {
				event.preventDefault();
				onEscape?.();
			}

			// on tab
			if (open && event.key === 'Tab' && !event.shiftKey) {
				onTab?.();
			}

			// on shift tab
			if (open && event.key === 'Tab' && event.shiftKey) {
				onShiftTab?.();
			}
		};

		document.addEventListener('click', handleClickOutside);
		if (onMousedownClickAway) {
			document.addEventListener('mousedown', handleMouseDownClickOutside);
		}
		if (onMouseupClickAway) {
			document.addEventListener('mouseup', handleMouseDownClickOutside);
		}
		document.addEventListener('keydown', handleKeypress);
		return () => {
			document.removeEventListener('click', handleClickOutside);
			document.removeEventListener('mousedown', handleMouseDownClickOutside);
			document.removeEventListener('mouseup', handleMouseDownClickOutside);
			document.removeEventListener('keydown', handleKeypress);
		};
	}, [ref, onClickAway, onClickInside, onEscape, open, onMousedownClickAway, onMousedownClickInside, onTab, onShiftTab, onMouseupClickAway]);
};

export default useClickAwayListener;
