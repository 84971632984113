import { showAlert } from "@general-ui/alert/alert-service";
import AppContext, { AppContexts } from "components/app-context";
import { useContext, useRef, useState, useEffect, useCallback, useMemo } from "react";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { getStorageItem } from "utils/local-storage";
import useLive from "utils/use-live";
import { PutMeetCohostSettings } from "connection/attendee-auth";
import { useEventCanUseMeetFiresides } from "hooks/channel.hooks";
import { useGoogleMeetFiresideSettings } from "./session-hooks";
import { FiresideSessionSettings, Session, SessionPlaybackVideo } from "types/working-model";
import { getLogger } from "utils/debug-logger";

const log = getLogger('bl:google-meet-player');

type SetAsCohostProps = {
	email: string,
	userCode: string,
	sessionUuid: string,
	spaceId: string
}
const setAsCohost = async (props: SetAsCohostProps) => {
	const res = await PutMeetCohostSettings(props);
	log('set as cohost', res);
};

export type UseFiresideMeetData = {
	firesideMeetJoinable: boolean,
	displayFiresideMeetPlaceholder: boolean,
	interacted: boolean,
	isFiresideLive: boolean,
	isFiresideMeet: boolean,
	firesideSettings?: FiresideSessionSettings | null,
	meetUrl: string | undefined,
	hostToken: string | undefined,
	fsJoined: boolean,
	firesideLiveSince: number | undefined,
	userLeft: boolean
};

export const useFiresideMeetData = ({
	session,
	currentDynamicVideo,
}: {
	session: Session,
	currentDynamicVideo: SessionPlaybackVideo | undefined
}): UseFiresideMeetData => {
	const blUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const [firesideSettings, hostToken] = useGoogleMeetFiresideSettings({
		session,
		settings: session?.fireside_session_settings,
		blUserToken
	});
	const isFiresideMeetEnabled = useEventCanUseMeetFiresides();
	const appContext = useContext(AppContext);
	const isEventContext = appContext === AppContexts.liveEvent;
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const fsJoined = useTypedSelector(state => state.FiresidesReducer.fsJoined);
	const userLeft = useTypedSelector(state => state.FiresidesReducer.userLeft);
	const settingsRef = useRef(session?.fireside_session_settings);
	const sessionUuidRef = useRef(session?.uuid);
	const blUserTokenRef = useRef(blUserToken);
	const mounted = useRef(true);
	const isFiresideMeet = !!firesideSettings?.settings?.use_google_meet;
	const showNativeFiresides = !isFiresideMeetEnabled || !isFiresideMeet;

	const [firesideMeetJoinable, setJoinable] = useState(false);

	// because the user MUST first interact for the meet sdk to request their camera
	// we have to make sure we don't attempt to load the SDK in until the user has interacted
	const [interacted, setInteracted] = useState(false);
	const [isFiresideLive, firesideLiveSince] = useLive(firesideSettings?.settings?.google_meet?.meetingUri);

	const email = blProfileUser?.email;

	// prevent over-firing effects
	useEffect(() => {
		settingsRef.current = session?.fireside_session_settings;
		sessionUuidRef.current = session?.uuid;
	}, [session]);

	const getMeetDetails = useCallback(async () => {
		if (
			isEventContext &&
			sessionUuidRef.current &&
			blUserTokenRef.current &&
			settingsRef.current?.settings?.use_google_meet &&
			email &&
			firesideSettings
		) {
			try {
				if (showNativeFiresides) {
					return;
				}

				if (hostToken && firesideSettings?.settings?.google_meet?.name) {
					const userCode = getStorageItem('user-code');

					await setAsCohost({
						email,
						userCode,
						sessionUuid: sessionUuidRef.current,
						spaceId: firesideSettings.settings.google_meet?.name
					});
				}

				if (!mounted.current) return;

				if (firesideSettings?.settings?.at_capacity) {
					showAlert({
						message: "Session Full",
						description: "The Google Meet session is full, please try again later",
						type: "error",
						duration: 5000
					});
					setJoinable(false);
				} else {
					setJoinable(true);
				}
			} catch (e) {
				console.error(e);
				showAlert({
					message: "Unable to launch Google Meet",
					description: "Unknown error occured launching Google Meet; no Meet URL found",
					type: "error",
					duration: 5000
				});
			}
		}
	}, [email, hostToken, firesideSettings, isEventContext, showNativeFiresides]);

	const displayFiresideMeetPlaceholder = useMemo(() => {
		if (showNativeFiresides) {
			return false;
		}

		if (userLeft) {
			return true;
		}

		if (!isEventContext && isFiresideLive) {
			return true;
		}

		if (hostToken && fsJoined) {
			return false;
		}

		if (isFiresideLive) {
			return false;
		}

		if (currentDynamicVideo) {
			return false;
		}

		return true;
	}, [hostToken, isFiresideLive, fsJoined, isEventContext, currentDynamicVideo, showNativeFiresides, userLeft]);

	// meet cannot be initialized until the user has interacted with the page.
	// this is a requirement of the meet sdk
	useEffect(() => {
		if (!("userActivation" in navigator)) {
			setInteracted(true);
			return;
		}

		if (navigator.userActivation?.hasBeenActive) {
			setInteracted(true);
		} else {
			const handleClick = () => {
				setInteracted(true);
			};

			window.addEventListener('click', handleClick, { once: true });
			return () => {
				window.removeEventListener('click', handleClick);
			};
		}
	}, []);

	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (isFiresideMeet && isFiresideMeetEnabled && isEventContext) {
			log('going to get meet details...');
			getMeetDetails();
		}

	}, [getMeetDetails, isFiresideMeet, isFiresideMeetEnabled, isEventContext]);

	return useMemo(() => ({
		firesideMeetJoinable,
		displayFiresideMeetPlaceholder,
		interacted,
		isFiresideLive,
		isFiresideMeet,
		firesideSettings,
		meetUrl: firesideSettings?.settings?.google_meet?.meetingUri,
		hostToken,
		fsJoined,
		firesideLiveSince,
		userLeft
	}), [displayFiresideMeetPlaceholder, firesideMeetJoinable, firesideSettings, fsJoined, hostToken, interacted, isFiresideLive, isFiresideMeet, firesideLiveSince, userLeft]);
};
