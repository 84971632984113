import { useEffect, useState } from "react";
import { Session } from "../../../types/working-model";

class ModalState extends EventTarget {
	session: Session | null | undefined = null;

	setSession = (session: Session | null | undefined) => {
		this.session = session;
		this.dispatchEvent(new CustomEvent('session', { detail: session }));
	};
}

export const modalState = new ModalState();

export const useModalState = () => {
	const [session, setSession] = useState<Session | null | undefined>(modalState.session);

	useEffect(() => {
		const handleSession = (e: CustomEvent<Session>) => {
			setTimeout(() => {
				setSession(e.detail);
			});
		};

		modalState.addEventListener('session', handleSession as EventListener);

		return () => {
			modalState.removeEventListener('session', handleSession as EventListener);
		};
	}, []);

	return {
		session
	};
};