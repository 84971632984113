function randomString(length: number, chars: string) {
	let result = '';
	for (let i = length; i > 0; --i) {
		result += chars[Math.floor(Math.random() * chars.length)];
	}
	return result;
}

export const random = (len: number): string => {
	return randomString(len, '0123456789abcdefghijklmnopqrstuvwxyz');
};
