import React, { CSSProperties, useEffect, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { ENavbarTypes, PageModule, Session, SessionTypesEnum } from '../../../../types/working-model';
const EditorMainEventBanner = React.lazy(() => import('../../../general-ui/main-event-banner/main-event-banner'));
import Icon, { COLORS, ICONS } from '../../../general-ui/icon';
import MainEventBannerBreakout from '../main-event-banner-breakout/main-event-banner-breakout';
import MainEventBanner from '../main-event-banner/main-event-banner';
import { MainEventBannerLiveSession } from './main-event-banner-live';
import { useParams } from 'react-router';
import { ParamsProps } from '../../live-event';
import '../../../../scss/live-event/base/modules/main-event-banner-live-carousel.scss';
import { getTemplateClassName } from '../../../../utils/utils';
import { getDefaultLanguage } from '../../utils';
import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';


interface IArrowProps {
	className?: string;
	style?: CSSProperties;
	onClick?: () => void;
}

export function PrevArrow(props: IArrowProps): JSX.Element {
	const { className, style, onClick } = props;

	return (
		<div onClick={onClick} className={className} style={{ ...style }}>
			<Icon name={ICONS.KEYBOARD_ARROW_LEFT} size={20} color={COLORS.WHITE} />
		</div>
	);
}

export function NextArrow(props: IArrowProps): JSX.Element {
	const { className, style, onClick } = props;

	return (
		<div onClick={onClick} className={className} style={{ ...style }}>
			<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} size={20} color={COLORS.WHITE} />
		</div>
	);
}


interface IMainEventBannerLiveCarouselProps {
	module: PageModule;
	template: string;
	liveSessionList: Session[];
	isAgendaPageEditor?: boolean;
}

const MainEventBannerLiveCarousel: React.FC<IMainEventBannerLiveCarouselProps> = (props: IMainEventBannerLiveCarouselProps) => {
	const { module, liveSessionList, template, isAgendaPageEditor = false } = props;

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle ?? workingEvent;

	const isHorizontalNavBar = useMemo(() => event?.settings?.event_navbar?.navbarType === ENavbarTypes.Horizontal, [event?.settings?.event_navbar?.navbarType]);
	const { language } = useParams<ParamsProps>();

	// we only want to enable autoplay on the carousel if the carousel is more than 50% visible
	const { inView, ref } = useInView({ threshold: 0.5 });
	const dynamicHeight = !!module.content?.enableDynamicMainEventBannerHeight;

	const sliderSettings: Settings = {
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		pauseOnHover: true,
		autoplay: inView,
		autoplaySpeed: 5000,
		// if banner module is off, start carousel on first slide, otherwise start on second slide and use banner module as first slide.
		initialSlide: module.is_on ? 1 : 0,
		dots: true,
		appendDots: dots => {
			return (
				<div>
					<ul>
						{dots}
					</ul>
				</div>
			);
		},
	};

	const RenderedLiveSessions = useMemo(() => {
		return liveSessionList.map(session => {

			// breakout session has its own live banner
			if (session.session_type === SessionTypesEnum.breakoutRooms) {
				const _template = workingEvent
					? getTemplateClassName(workingEvent.template.name)
					: template;

				return (
					<div key={module.id} className="session-banner-container">
						<div style={dynamicHeight ? undefined : { maxHeight: '460px' }} key={module.id}>
							<MainEventBannerBreakout session={session} template={_template} hideMobileHeader useSessionThumbnail hideCountdown={!!workingEvent} isOnHomepage />
						</div>
					</div>
				);
			}

			return (
				<div key={module.id} className="session-banner-container">
					<MainEventBannerLiveSession
						module={module}
						template={template}
						liveSession={session}
						useSessionImage
					/>
				</div>
			);
		});
	}, [liveSessionList, module, template, workingEvent, dynamicHeight]);

	return (
		<div
			className={
				classNames(
					"main-event-banner-live-carousel",
					{
						"horizontal-navbar": isHorizontalNavBar,
						'enable-dynamic-height': dynamicHeight,
					}
				)
			}
			ref={ref}
		>
			{/* display editable version in editor, but on agenda page use live-event version so its not editable */}
			{module.is_on ? (
				<Slider {...sliderSettings} adaptiveHeight={dynamicHeight}>
					{(workingEvent && !isAgendaPageEditor) ? (
						<React.Suspense fallback="">
							<EditorMainEventBanner
								key={module.id}
								page_module={module}
								template={getTemplateClassName(workingEvent.template.name)}
								language={language}
								baseLanguage={getDefaultLanguage(workingEvent)}
							/>
						</React.Suspense>
					) : (
						<MainEventBanner
							module={module}
							template={template}
							key={module.id}
							eventBundle={event}
						/>
					)}
					{RenderedLiveSessions}
				</Slider>
			) : (
				<Slider {...sliderSettings}>
					{RenderedLiveSessions}
				</Slider>
			)}

		</div>
	);
};

export default MainEventBannerLiveCarousel;
