import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import ModalComponent from '../../general-ui/modal/modal';
import { useAppDispatch, useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { Session } from '../../../types/working-model';
import { updateMyListSessions } from '../../../store/actions/event/event-actions';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { ParamsProps } from '../live-event';

interface MyListTrackSelectModalProps {
	open: boolean;
	requestClose: () => void;
	previewMode: boolean;
}

const MyListTrackSelectModal: React.FC<MyListTrackSelectModalProps> = ({ open, requestClose, previewMode }) => {
	const { t } = useTranslate(['homepage']);
	const dispatch = useAppDispatch();
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const blProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const sessions = workingEvent?.sessions ?? eventBundle?.sessions;
	const [tracksSelected, setTracksSelected] = useState<string[]>([]);

	const { language } = useParams() as ParamsProps;

	const event = eventBundle ?? workingEvent;

	const eventTracks = useMemo(() => {
		const tracksArr: string[] = [];
		sessions?.forEach((session: Session) => {
			if (session.tracks) {
				session.tracks.forEach((track: string) => {
					if (!tracksArr.includes(track)) tracksArr.push(track);
				});
			}
		});
		return tracksArr;
	}, [sessions]);

	const handleTrackSelect = (track: string) => () => {
		if (tracksSelected.includes(track)) {
			setTracksSelected(tracksSelected.filter(t => t !== track));
		} else {
			const updatedTracks = [...tracksSelected];
			updatedTracks.push(track);
			setTracksSelected(updatedTracks);
		}
	};

	const handleTrackSelectModalSubmit = () => {
		if (previewMode) {
			requestClose();
		}
		if (token && eventBundle?.uuid && blProfile?.uuid) {
			const sessionMap = new Map();
			sessions?.forEach((s: Session) => {
				s.tracks?.forEach((t: string) => {
					if (tracksSelected.includes(t)) {
						sessionMap.set(s.uuid, s);
					}
				});
			});
			const uuidList: string[] = [];
			sessionMap.forEach((s: Session) => {
				uuidList.push(s.uuid);
			});
			dispatch(updateMyListSessions(token, eventBundle.uuid, uuidList));
		}
		requestClose();
	};

	const skip = () => {
		// update user saved sessions with empty array to avoid modal on next page load
		if (token && eventBundle?.uuid) {
			dispatch(updateMyListSessions(token, eventBundle.uuid, []));
		}
		requestClose();
	};

	return (
		<ModalComponent
			open={open}
			onRequestClose={requestClose}
			cancellable
			closeable
			isAdmin={previewMode}
			className={classNames('my-list-editor-modal', { preview: previewMode })}
			hideHeader
			eventPreview
		>
			<div className="my-list-track-select-modal-container">
				<div className="modal-header">
					<h2>{t("Personalize Your Session List")}</h2>
					<div className="header-description">{t("Select track categories")}</div>
				</div>
				<div className="modal-body">
					{eventTracks.length > 0 ? (
						<div className={"tracks-container"}>
							{eventTracks.map((track: string) => {
								let translatedTrack = t(`home:tracks.${track}`, track ?? '');
								const dbTranslation = event?.settings?.tracks_translations?.[track]?.[language];
								if (dbTranslation && typeof dbTranslation === "string") {
									translatedTrack = dbTranslation;
								}
								return (
									<div
										key={track}
										className={classNames("track-label", { selected: tracksSelected.includes(track) })}
										onClick={handleTrackSelect(track)}
									>
										{translatedTrack}
									</div>
								);
							}
							)}
						</div>
					) : (
						<div className="empty-tracks-container">
							This event doesn't have any tracks
						</div>
					)}
				</div>
				<div className="modal-footer">
					<div className="modal-footer-skip-btn">
						<button onClick={skip}>
							{t("Skip").toLocaleUpperCase()}
						</button>
					</div>
					<div className="modal-footer-submit-btn">
						<button onClick={handleTrackSelectModalSubmit} disabled={tracksSelected.length === 0}>
							{t("Submit").toLocaleUpperCase()}
						</button>
					</div>
				</div>
			</div>
		</ModalComponent>
	);
};

export default MyListTrackSelectModal;
