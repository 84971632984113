
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Switch from '@general-ui/switch/switch';
import Icon, { ICONS, COLORS } from '@general-ui/icon';
import { QuestionType, RegistrationQuestion, CreateRegistrationQuestion } from 'types/working-model';

import './sortable-registration-question-v2.scss';
import TextInput from '@general-ui/text-input/text';
import SelectInput from '@general-ui/select/select';
import { OptionalComponent } from 'utils/optional-component';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, DragEndEvent } from '@dnd-kit/core';
import { useSortable, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import BasicInput from '@general-ui/text-input/basic-input';
import { TypographyItem } from '@general-ui/typography-item/typography-item';
import PillToggleRound from '@general-ui/pill-toggle/pill-toggle-round';
import { useDispatch } from 'react-redux';
import { toggleCustomizeFormModal, updateRegistrationSteps } from 'store/actions/admin/create-event';
import { showAlert } from '@general-ui/alert/alert-service';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { addNewRegistrationQuestion, updateRegistrationQuestions } from 'store/actions/admin/registration-questions';
import { CreateRegQuestion, SaveRegistrationQuestion } from 'connection/registration-questions';
import WaitingIndicator from '@general-ui/waiting-indicator/waiting-indicator';
import { useFinishNavigate } from '@admin/create/session/navigation/panel/hooks/panel.hooks';


const QuestionOptions = [
	{ label: "Text field", value: QuestionType.text },
	{ label: "Text area", value: QuestionType.textarea },
	{ label: "Dropdown list", value: QuestionType.select },
	{ label: "Checkbox", value: QuestionType.checkbox },
	{ label: "Numeric Field", value: QuestionType.numeric },
	{ label: "Email Field", value: QuestionType.email },
	{ label: "Multi Select", value: QuestionType.multiselect },
	{ label: "Custom Text", value: QuestionType.customtext }
];

const RegistrationOption = (props: {
	option: string;
	index: number;
	key: string;
	editOption: ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void
	removeOption: (index: number) => void
	id: string;
	rerenderCount: number;
}) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id: props.id });

	const { option, index, removeOption, editOption, rerenderCount } = props;

	const [text, setText] = useState(option);

	// rerender count is incremented whenever user attempts to input duplicate text
	// this way, we reset the text input to be the original value
	useEffect(() => {
		if (rerenderCount) {
			setText(option);
		}
	}, [rerenderCount, option]);

	const style = {
		transform: CSS.Transform.toString(transform),
		transition: 'none',
	};

	return (
		<div ref={setNodeRef} className="edit-registration-question-option" style={style}>
			<div className="drag-handle" {...attributes} {...listeners}>
				<Icon name={ICONS.DRAG_HANDLE} size={18} color={COLORS.DEFAULT_GRAY} />
			</div>
			<BasicInput
				value={text} // add value to make it a controlled component so we can handle updating when duplicate values
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setText(event.target.value);
				}}
				onBlur={editOption}
				placeholder="Enter field name"
			/>
			<button onClick={() => removeOption(index)} className="round"><Icon name={ICONS.TRASH} size={10} color={COLORS.WHITE} /></button>
		</div>
	);
};

interface IEditRegistrationQuestionV2Props {
	hideToggle?: boolean;
	requiredQuestions: number[];
	setQuestionRequired: (question: number, value: number) => void;
	setExpandedRegCard?: React.Dispatch<React.SetStateAction<number>>;
	editingQuestion: RegistrationQuestion | CreateRegistrationQuestion | null;
	setEditingQuestion: React.Dispatch<React.SetStateAction<RegistrationQuestion | CreateRegistrationQuestion | null>>;
	sendTo?: string
}

const EditRegistrationQuestionV2: React.FC<IEditRegistrationQuestionV2Props> = (props) => {
	const {
		requiredQuestions,
		setExpandedRegCard,
		setQuestionRequired,
		setEditingQuestion,
		editingQuestion,
		sendTo
	} = props;

	const [saving, setSaving] = useState<boolean>(false);

	const [questionType, setQuestionType] = useState<QuestionType>(editingQuestion?.type || QuestionType.text);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const registrationSteps = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_steps);
	const registrationQuestions = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_questions);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);

	const goBackToPreviousPage = useFinishNavigate();
	const isGlobal = useMemo(() => {
		if (editingQuestion && "global" in editingQuestion) return editingQuestion.global;
	}, [editingQuestion]);
	const dispatch = useDispatch();

	useEffect(() => {
		if (setExpandedRegCard && (editingQuestion as RegistrationQuestion)?.registration_question) {
			setExpandedRegCard((editingQuestion as RegistrationQuestion).registration_question);
		}
	}, [editingQuestion, setExpandedRegCard]);



	/******************************************************
	 * 
	 * UPDATING QUESTION FUNCTIONS 
	 * 
	 * ****************************************************/

	//certain questions cannot appear on the profile section as they're reqd to go on general
	//only allow them to be toggled OFF for backwards compat (they can turn them off but not back on ever)

	// rerender count is used to rerender RegistrationOption when a user tries to add duplicate options
	const [rerenderCount, setRerenderCount] = useState(0);

	const updateQuestionName = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
		if (!editingQuestion) return;
		const nameTranslation = { ...editingQuestion.name_translation ?? {}, base: value, changed: value };
		setEditingQuestion({ ...editingQuestion, name: value, name_translation: nameTranslation });
	};

	const changeQuestionType = (value: QuestionType): void => {
		if (!editingQuestion) return;
		setQuestionType(value);
		setEditingQuestion({ ...editingQuestion, type: value as QuestionType });

		// remove the question from requiredQuestions if it's a customtext question
		if (value === QuestionType.customtext) {
			if ('registration_question' in editingQuestion) {
				setQuestionRequired(editingQuestion.registration_question, 0);
			}
		}
	};

	const updateQuestionPlaceholder = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
		if (!editingQuestion) return;
		setEditingQuestion({
			...editingQuestion,
			placeholder: {
				...editingQuestion.placeholder,
				base: value,
				changed: value
			}
		});
	};

	const toggleDefaultChecked = (_: unknown, isOn: boolean) => {
		if (!editingQuestion) return;
		setEditingQuestion({ ...editingQuestion, default_value: isOn ? "checked" : "" });
	};

	/******************************************************
	 * 
	 * OPTION RELATED FUNCTIONS 
	 * 
	 * ****************************************************/
	const addOption = () => {
		if (!editingQuestion) return;
		const newOption = `New Option${editingQuestion.options?.length ? ` ${editingQuestion.options?.length + 1}` : ''}`;
		const options = [...editingQuestion.options ?? [], newOption];

		const optionTranslations = editingQuestion.options_translation ?
			[...editingQuestion.options_translation, { base: newOption, changed: newOption }]
			: options.map(option => ({ base: option, changed: option }));
		const newQuestion = { ...editingQuestion, options: options, optionTranslations: optionTranslations };
		setEditingQuestion(newQuestion);
	};

	const removeOption = (index: number) => {
		const _question = { ...editingQuestion } as RegistrationQuestion | CreateRegistrationQuestion | null;
		if (!_question?.options) return;

		_question.options = _question.options.filter((_, i: number) => i !== index);
		_question.options_translation = _question.options_translation?.filter((_, i: number) => i !== index);
		setEditingQuestion(_question);
	};

	const editOption = (index: number) => {
		return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			if (!editingQuestion?.options) return;

			const updateOptions = [...editingQuestion.options];
			const updateOptionTranslations = [...editingQuestion.options_translation ?? updateOptions.map(option => ({ base: option, changed: option }))];
			// if it already exists, then increment re-render count so we reset the text input to the original option value
			// and then return early so we don't update the data
			if (editingQuestion?.options?.includes(value.trim())) {
				setRerenderCount(prev => prev + 1);
				return;
			}

			updateOptions[index] = value;
			updateOptionTranslations[index] = { ...updateOptionTranslations[index], base: value, changed: value };
			setEditingQuestion({ ...editingQuestion, options: updateOptions, options_translation: updateOptionTranslations });
		};
	};

	const sensors = useSensors(
		useSensor(PointerSensor)
	);

	const handleDragEnd = (event: DragEndEvent) => {

		if (!editingQuestion?.options) return;

		const _modules = [...editingQuestion.options];
		const _translations = editingQuestion.options_translation ? [...editingQuestion.options_translation] : undefined;

		const startIndex = _modules.findIndex((option: string) => option === event.active.id);
		const newIndex = _modules.findIndex((option: string) => option === event?.over?.id);

		if (startIndex !== newIndex) {
			const [option] = _modules.splice(startIndex, 1);

			_modules.splice(newIndex, 0, option);

			if (_translations) {
				const [translation] = _translations.splice(startIndex, 1);
				_translations.splice(newIndex, 0, translation);
			}
			const updatedQuestion = { ...editingQuestion, options: _modules, options_translation: _translations };

			setEditingQuestion(updatedQuestion);
		}
	};

	// disable the button if options is an array and there's an empty or duplicate value
	const isInvalid = useCallback(() => {
		// if it's not an array, just let it through
		if (!Array.isArray(editingQuestion?.options)) return false;

		let hasInvalidField = false;

		// if there are no options
		if (!editingQuestion?.options?.length) {
			hasInvalidField = true;
		}
		// invalid if there is a duplicate
		if (new Set(editingQuestion?.options).size !== editingQuestion?.options?.length) {
			hasInvalidField = true;
		}
		// if there's an empty field
		if (editingQuestion?.options?.some(val => !val?.trim().length)) {
			hasInvalidField = true;
		}

		return hasInvalidField;
	}, [editingQuestion?.options]);



	/******************************************************
	 * 
	 * SUBMITTING RELATED FUNCTIONS 
	 * 
	 * ****************************************************/

	const handleCancel = (): void => {
		if (editingQuestion) setEditingQuestion(null);
		dispatch(toggleCustomizeFormModal(false));

		if (setExpandedRegCard) setExpandedRegCard(0);
		if (sendTo) {
			// NAVIGATE TO MAIN REGISTRATION PANEL
			goBackToPreviousPage(sendTo);
		}
	};

	const handleSave = async (): Promise<void> => {
		try {
			if (!workingEvent || !registrationSteps || !token) return;

			if (requiredQuestions.length === 0) {
				showAlert({
					message: "Cannot save registration form",
					description: "Your registration form must contain at least one required field",
					duration: 3000,
					type: 'error',
				});
				return;
			}

			if (
				(
					questionType === QuestionType.select || questionType === QuestionType.multiselect
				)
				&& (
					!editingQuestion?.options
					|| editingQuestion?.options.length === 0
					|| editingQuestion?.options.some(val => !val.trim().length)
				)
			) {
				showAlert({
					message: "Cannot save dropdown",
					description: "Your dropdown list needs to contain options with valid values",
					duration: 3000,
					type: 'error',
				});
				return;
			}

			setSaving(true);

			let updatedQuestions = [...(registrationQuestions ?? [])];

			let newQuestion: RegistrationQuestion | undefined;
			if (editingQuestion && !isGlobal) {
				if ("registration_question" in editingQuestion && editingQuestion.registration_question !== 0) {
					const saved = await SaveRegistrationQuestion(editingQuestion, workingEvent.uuid, token);

					dispatch(updateRegistrationQuestions([saved]));

					updatedQuestions = updatedQuestions.map(question => {
						if (question.registration_question === saved.registration_question) {
							return saved;
						} else {
							return question;
						}
					});

				}
				else {
					newQuestion = await CreateRegQuestion({ ...editingQuestion, type: questionType }, workingEvent.uuid, token);

					updatedQuestions.push(newQuestion);

					dispatch(addNewRegistrationQuestion(newQuestion));
				}
				setEditingQuestion(null);
			}

			const stepQuestionIds = (registrationSteps.find(step => step.type === registrationStep)?.questions ?? [])
				.map(question => question.registration_question);

			const stepQuestions = updatedQuestions?.filter(question => stepQuestionIds.includes(question.registration_question)) ?? [];

			const updatedSteps = registrationSteps.map(step => {
				if (step.type !== registrationStep) return step;
				if (newQuestion) {
					return { ...step, questions: [...stepQuestions, newQuestion] };
				}
				return { ...step, questions: stepQuestions };
			});


			const _requiredQuestions = [...requiredQuestions];
			if (editingQuestion && editingQuestion.is_required && newQuestion) {
				_requiredQuestions.push(newQuestion.registration_question);
			}

			const noDupRequiredQuestions = Array.from(new Set(_requiredQuestions));

			const registrationQuestionsToAppend = newQuestion ? {
				registrationQuestionsToAppend: {
					step: registrationStep,
					questions: [newQuestion],
				},
			} : {};
			dispatch(updateRegistrationSteps(updatedSteps, {
				requiredQuestions: noDupRequiredQuestions,
				...registrationQuestionsToAppend
			}));

			dispatch(toggleCustomizeFormModal(false));

			setSaving(false);

			if (setExpandedRegCard) setExpandedRegCard(0);

			if (sendTo) {
				// NAVIGATE TO MAIN REGISTRATION PANEL
				goBackToPreviousPage(sendTo);
			}

		} catch (error) {
			console.error(error);
			showAlert({
				message: 'Error Saving Registration Question',
				description: 'We ran into a problem saving this registration question. Please try again.',
				type: 'error',
				duration: 4000
			});
		}
	};


	return (
		<div className="edit-registration-fields-v2">

			<OptionalComponent display={isGlobal}>
				<p className="registration-question-label">{editingQuestion?.name}</p>
			</OptionalComponent>

			<OptionalComponent display={!isGlobal}>
				<TextInput
					id={(editingQuestion && "registration_question" in editingQuestion) ? String(editingQuestion?.registration_question) : "0"}
					defaultValue={editingQuestion?.name}
					placeholder={"Enter field name"}
					onChange={() => ({})}
					onBlur={updateQuestionName}
					label={"Field Title"}
					disabled={isGlobal}
				/>
			</OptionalComponent>

			<div className="registration-row">
				<OptionalComponent display={!isGlobal}>
					<div className="field-group select">
						<SelectInput
							options={QuestionOptions}
							selected={questionType}
							onChange={changeQuestionType}
							ignorePositioning
							ignoreSpacing
							ignoreInlineStyling
							medium
							disabled={isGlobal}
						/>
					</div>
				</OptionalComponent>
				{(questionType !== QuestionType.customtext && editingQuestion && 'registration_question' in editingQuestion) &&
					<PillToggleRound
						selected={requiredQuestions.includes(editingQuestion.registration_question) ? 1 : 0}
						value={editingQuestion.registration_question}
						disabled={false}
						onClick={setQuestionRequired}
					/>
				}
			</div>

			{questionType === QuestionType.checkbox && (
				<div className="field-group switch">
					<Switch
						on={editingQuestion?.default_value === 'checked'}
						value={editingQuestion && "registration_question" in editingQuestion ? editingQuestion.registration_question : 0}
						onClick={toggleDefaultChecked}
					/>
					<label>Checked by default</label>
				</div>
			)}


			{![QuestionType.checkbox, QuestionType.customtext].includes(questionType) && (
				<OptionalComponent display={!isGlobal}>
					<div className="edit-registration-question-placeholder">
						<TextInput
							defaultValue={editingQuestion?.placeholder?.base}
							placeholder="Enter placeholder text..."
							onChange={updateQuestionPlaceholder}
							onBlur={updateQuestionPlaceholder}
							label={"Placeholder"}
							disabled={isGlobal}
						/>
					</div>
				</OptionalComponent>
			)}

			{questionType === QuestionType.customtext && <div className="field-group">
				Display a message to registrants that does not require a response
			</div>}

			{[QuestionType.select, QuestionType.multiselect].includes(questionType) && (
				<OptionalComponent display={!isGlobal}>
					<>
						<div className="edit-registration-question-options">
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragEnd={handleDragEnd}
								modifiers={[restrictToParentElement]}
								autoScroll={false}
							>
								<SortableContext
									items={editingQuestion?.options ?? []}
									strategy={verticalListSortingStrategy}
								>
									{editingQuestion?.options?.map((option: string, index: number) => {
										return (
											<RegistrationOption
												option={option}
												index={index}
												key={option}
												editOption={editOption(index)}
												removeOption={removeOption}
												id={option}
												rerenderCount={rerenderCount}
											/>
										);
									})}
								</SortableContext>
							</DndContext>
						</div>
						{/* this is unlikely to hit since we are not updating the data when duplicates are found */}
						{/* but keeping around just in case a user presses space bar without any text and then blurs */}
						{isInvalid() ? (
							<TypographyItem error>Cannot have duplicate or empty values</TypographyItem>
						) : null}
						<button
							className="small"
							onClick={addOption}
							disabled={isInvalid()}
						>
							<Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} /><span>Add Option</span>
						</button>
					</>
				</OptionalComponent>
			)}

			<div className="question-footer">
				<button onClick={handleCancel}>Cancel</button>
				<button onClick={handleSave} className="primary">{saving ? <WaitingIndicator /> : "Save"}</button>
			</div>
		</div>


	);
};

export default EditRegistrationQuestionV2;
