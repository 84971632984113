import './panel-header-options.scss';

const PanelHeaderOptions: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
	return (
		<div className="sub-panel-header-options">
			{children}
		</div>
	);
};

export default PanelHeaderOptions;