import { EventChatMessageType, ISocketCommentProps, LanguagesAbbr } from '../types/working-model';
import { GetHv, PostHv, PutHv, DeleteHv, Post, Delete, Get, Patch, HvHostMap } from './helpers';

export async function GetEventChatMessages(token: string, eventId: number, profileId: number, channel: number): Promise<ISocketCommentProps[]> {
	const response = await GetHv(HvHostMap.chat, `/e3-load-all-event-chats/${eventId}/${profileId}/${channel}`, token);
	return { ...response, bl_profile: profileId };
}

export function UpdateLastReadEventChatMessage(chatId: number, profileId: number): Promise<ISocketCommentProps[]> {
	return PutHv(HvHostMap.chat, `/e3-event-chat-message/update-last-read`, { chatId, profileId });
}

export function SendEventChatMessage(token: string, socketChannel: string, channel: number, chatId: number, chatParticipantId: number, content: string, type: EventChatMessageType): Promise<ISocketCommentProps[]> {
	return PostHv(HvHostMap.chat, '/e3-send-event-chat-message', { socketChannel, channel, chatId, chatParticipantId, content, type }, token);
}

export function EditEventChatMessage(channel: string, chatMessageId: number, newContent: string): Promise<ISocketCommentProps[]> {
	return PutHv(HvHostMap.chat, `/e3-event-chat-message/${chatMessageId}`, { channel, newContent });
}

export function DeleteEventChatMessage(channel: string, chatMessageId: number): Promise<ISocketCommentProps[]> {
	return DeleteHv(HvHostMap.chat, `/e3-send-event-chat-message/${chatMessageId}`, { channel });
}

export async function CreateEventChat(channel: number, eventId: number, eventUuid: string, title: string | null, creatorProfileUuid: string, participantProfileUuids: string[], isOpen: boolean, dmTitle?: string, avatar?: string): Promise<ISocketCommentProps[]> {
	if (dmTitle || avatar) {
		const result = await PostHv(HvHostMap.chat, `/e3-event-chat`, { channel, eventId, eventUuid, title, creatorProfileUuid, participantProfileUuids, isOpen });
		return { ...result, dmTitle, avatar };
	}
	return PostHv(HvHostMap.chat, `/e3-event-chat`, { channel, eventId, eventUuid, title, creatorProfileUuid, participantProfileUuids, isOpen });
}

export function LeaveEventChat(socketChannel: string, chatId: number, chatParticipantId: number): Promise<ISocketCommentProps[]> {
	return PutHv(HvHostMap.chat, '/e3-leave-event-chat', { socketChannel, chatId, chatParticipantId });
}

export function BanUser(token: string, channel: number, profileUuid: string): Promise<ISocketCommentProps[]> {
	return PostHv(HvHostMap.chat, '/e3-event-chat-ban-user', { channel, profileUuid }, token);
}

export function AdminCreateEventChat(token: string, event: number, defaultLangauge: LanguagesAbbr, languages: LanguagesAbbr[], title: string, image: string | null): Promise<any> {
	return Post('/v3/admin/events/event-chat', token, { event, defaultLangauge, languages, title, image });
}

export function AdminDeleteEventChat(token: string, eventChat: number): Promise<any> {
	return Delete(`/v3/admin/events/event-chat/${eventChat}`, token, null, true);
}

export function AdminRenameEventChat(token: string, eventChat: number, name: string): Promise<unknown> {
	return Patch(`/v3/admin/events/event-chat/${eventChat}`, token, { name }, true);
}

export function AdminGetEventChats(token: string, eventUuid: string): Promise<any> {
	return Get(`/v3/admin/events/event-chat/${eventUuid}`, token);
}

export function PutEmojiReaction(sessionUuid: string, name: string): Promise<any> {
	return PutHv(HvHostMap.chat, `/e3-event-put-emoji-reaction/${sessionUuid}`, { name });
}