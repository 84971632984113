import { useContext } from "react";
import { useIsAboveTheFold } from "../../../../../../hooks/session.hooks";
import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import { VideoStateContext } from "../../session-stream-provider";

const ScrollForDetails = (): JSX.Element => {
	const { state: { session } } = useContext(VideoStateContext);
	const { t } = useTranslate(['session']);
	const above = useIsAboveTheFold(session);

	return (
		<div className={"scroll-for-details" + (above ? " hidden" : "")}>
			<p>{t("scroll-for-details", "Scroll for details")}</p><span className="new-dropdown-icon"><Icon name={ICONS.ANGLE_LEFT} size={10} color={COLORS.WHITE} /></span>
		</div>
	);
};

export default ScrollForDetails;