import { BrandliveEvent } from "../types/working-model";
import { clearStorage } from "./local-storage";
import { clearSessionStorage } from "./session-storage";
import { getSSOLogoutUrl } from "./sso-utils";

// this function will be moved into a larger registration utility file once registration refactor is completed
export const deregister = (eventBundle: BrandliveEvent): void => {
	const keepAdminKeys = window.location.hostname === 'localhost' && process.env.REACT_APP_STAGE === 'local';
	clearStorage(keepAdminKeys);
	clearSessionStorage();
	window.indexedDB.deleteDatabase('EventsStore');

	const logoutUrl = getSSOLogoutUrl(eventBundle);

	if (logoutUrl) {
		window.location.href = logoutUrl;
	} else {
		window.location.reload();
	}
};
