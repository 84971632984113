import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useModal } from "react-modal-hook";

import TextInput, { Validation } from '../../../general-ui/text-input/text';
import Modal from '../../../general-ui/modal/modal';
import TagSelectInput from '../../../general-ui/tag-select/tag-select';
import LanguageSelectInput, { Language } from '../../../general-ui/select/language-select';
import { BLAdmin, BreakoutSession, CreateSession, LanguagesAbbr, NavMainItems, Session, SessionTypesEnum, SessionTypesFeatures } from "../../../../types/working-model";
import TranslateLangs from '../../../../utils/translate-langs.json';
import { GetChannelTracks } from "../../../../connection/create-event";
import { EventsState } from "../../../../store/types";
import BreakoutSessionsModal from "./breakout-session-modal/breakout-session-modal";
import {
	editSessionAgenda,
	updateEditingSessionByUid,
	updateHomepageMainNav
} from "../../../../store/actions/admin/create-event";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";
import useRequiredFields from "../../../../utils/use-required-fields";
import { canShowLanguageSelector, numClamp, validateUrl } from "../../../../utils/utils";

import { getDefaultFiresideSessionSettings, GetDefaultMainNavItems, GetMultipleSessionsMainNavItems } from "../../../../store/utils/create-event";
import SessionTypeSelect from "../../../general-ui/session-type-select/session-type-select";
import SessionStartTimeFields from "./session-start-time-fields";
import { getAdmins } from "../../../../store/actions/admin/bl-admin";
import Switch from "../../../general-ui/switch/switch";
import { Tooltip } from "../../../general-ui/tooltip/tooltip";

import "react-datepicker/dist/react-datepicker.css";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { workingEventDefaultLanguage } from "../../utils/utils";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { useCanEnableMeetFiresides } from "hooks/channel.hooks";
import { OptionalComponent } from "utils/optional-component";
import FiresideHostsSection from "./fireside-hosts-section/fireside-hosts-section";


export const PrimaryTooltip = (): JSX.Element => <Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12}></Icon>;

interface SessionModalProps {
	addingSession: boolean;
	closeModal: () => void;
	createSession: (session: CreateSession) => Promise<any>;
	addingSessionType?: SessionTypesEnum;
	addingSessionFromSingleSessionEvent?: boolean;
	defaultLanguage: LanguagesAbbr;
}

function getReplayValue(sessionType: SessionTypesEnum): boolean | null {
	// used to initialize the replay toggle as true for determined session types
	// retains default null initial value for all other session types
	switch (sessionType) {
		case SessionTypesEnum.broadcast: {
			return true;
		}
		case SessionTypesEnum.fireside: {
			return true;
		}
		// returning null here to keep with the current data structure
		// new sessions are initially given a null value for this boolean until the toggle is interacted with
		default: return null;
	}
}

function getDefaultSession(user: BLAdmin | null, sessionType: SessionTypesEnum, defaultEventLang: Language): CreateSession {
	const defaultLanguageCode = defaultEventLang.code;
	return {
		title: '',
		channel: user?.active_channel || 0, // setting to zero because we'll never actually hit this fallback, but it satisfies typescript
		languages: [defaultLanguageCode],
		default_language: defaultLanguageCode,
		tracks: [],
		session_type: sessionType,
		streaming_options: {
			single_stream: true
		},
		session_chat_single_language: true,
		replay_on: null,
		current_language: defaultLanguageCode,
		fireside_session_settings: getDefaultFiresideSessionSettings()
	};
}

/** 
 *	HEY THERE! ARE YOU MAKING EDITS TO THIS COMPONENT?
 *	Please consider adding your changes to edit-session-modal.tsx
 */

const SessionModal: React.FC<SessionModalProps> = ({
	addingSession,
	closeModal,
	createSession,
	addingSessionType = SessionTypesEnum.broadcast,
	addingSessionFromSingleSessionEvent,
	defaultLanguage
}) => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const editingSessionAgenda = useTypedSelector(state => state.CreateEventReducer.editingSessionAgenda);
	const newEventRequest = useTypedSelector(state => state.CreateEventReducer.newEventRequest);
	const admins = useTypedSelector(state => state.ChannelAdminReducer.admins);
	const dispatch = useDispatch();

	const defaultSessionTypeValue = useMemo(() => {
		return editingSessionAgenda
			? editingSessionAgenda.session_type
			: addingSessionType;
	}, [addingSessionType, editingSessionAgenda]);

	const [showCustomUrlOption, setShowCustomUrlOption] = useState(false);
	const defaultLanguageOption = TranslateLangs.find(lang => lang.code === defaultLanguage) as Language;
	const [workingSession, setWorkingSession] = useState<CreateSession>(getDefaultSession(user, defaultSessionTypeValue, defaultLanguageOption));
	const [trackDropdownList, setTrackDropdownList] = useState<string[]>([]);
	const [languages, setLanguages] = useState<Language[]>([defaultLanguageOption]);
	const [duration, setDuration] = useState<number | undefined>(60);
	const [pastEventOpen, setPastEventOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [showBreakoutRoomsModal, setShowBreakoutRoomsModal] = useState(false);
	const isFireside = workingSession.session_type === SessionTypesEnum.fireside;
	const canEnableMeetFiresides = useCanEnableMeetFiresides();

	const canShowLanguageDropdown = canShowLanguageSelector(workingSession, defaultLanguageOption);

	// if editing a session, set language state so we properly populate language selector
	useEffect(() => {
		if (editingSessionAgenda) {
			const langaugesMap: Record<string, Language> = {};
			TranslateLangs.forEach(lang => {
				langaugesMap[lang.code] = lang;
			});

			const matchedLangs: Language[] = [];

			editingSessionAgenda.languages.forEach(lang => {
				const match = langaugesMap[lang];
				if (match) {
					matchedLangs.push(match);
				}
			});

			setLanguages(prev => matchedLangs?.length ? matchedLangs : prev);
		}
	}, [editingSessionAgenda]);

	useEffect(() => {
		if (addingSession && !editingSessionAgenda) {
			// modal has been opened for the first time,
			// need to reset the default language to current
			setWorkingSession(session => ({
				...session,
				default_language: defaultLanguageOption.code,
				current_language: defaultLanguageOption.code,
				languages: [defaultLanguageOption.code]
			}));

			setLanguages([defaultLanguageOption]);
		}

	}, [addingSession, editingSessionAgenda, defaultLanguageOption]);

	// keep session types in sync to render the correct form fields
	useEffect(() => {
		// if modal is open (this is so it only runs once when the modal first opens - we don't want it to run every time the user changes the session type dropdown)
		if (addingSession || !!editingSessionAgenda) {
			// if working session does not match passed down session type
			if (workingSession && addingSessionType && (workingSession.session_type !== addingSessionType)) {
				// then update it to be so
				setWorkingSession(prev => ({
					...prev,
					session_type: addingSessionType,
				}));
			}

			// Get admins if necessary to pre-populate session hosts
			if (!admins?.length && token && user) {
				dispatch(getAdmins(token, user?.active_channel));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addingSession, editingSessionAgenda]);

	// Generates a date from the workingSession timestamp for the datepicker to use
	const workingStartDate = useMemo(() => {
		return workingSession.start_timestamp ? new Date(workingSession.start_timestamp) : undefined;
	}, [workingSession.start_timestamp]);

	// Dates prior to today are disabled in the datepicker (not times prior to now, because that's how it's been)
	const minDate = useMemo(() => {
		const date = new Date();
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		return date;
	}, []);

	// List of existing session names to check against for duplicates
	const sessionNames: { name: string, id: string | undefined }[] = useMemo(() => {
		const existingNames = workingEvent ? workingEvent.sessions.map(session => ({ name: session.title.base, id: session.session.toString() })) : [];
		const requestNames = newEventRequest ? newEventRequest.sessions.map(session => ({ name: session.title, id: session?.uid })) : [];
		return [...existingNames, ...requestNames];
	}, [workingEvent, newEventRequest]);

	const {
		requiredFields,
		updateRequiredField,
		resetRequiredFields,
	} = useRequiredFields(['name', 'duplicateName'], {
		name: (string: string) => !!string.trim(),
		// if the name is duplicate then return false to trigger validation
		duplicateName: (isDuplicate: boolean) => {
			if (isDuplicate) setErrorMessage('Session name already used for this Event');
			if (!isDuplicate) setErrorMessage('');
			return !isDuplicate;
		},
	});

	// Gets track list to populate track search dropdown
	const loadTracks = useCallback(async function() {
		try {
			if (user?.active_channel && token) {
				const tracks = await GetChannelTracks(user.active_channel, token);
				setTrackDropdownList(tracks);
			}
		} catch (e) {
			console.error(e);
		}
	}, [token, user]);

	// Security blanket to ensure no rogue data stays in the form when it's closed
	const resetForm = useCallback(() => {
		dispatch(editSessionAgenda(null));
		setWorkingSession(getDefaultSession(user, defaultSessionTypeValue, defaultLanguageOption));
		setErrorMessage('');
		setLanguages([defaultLanguageOption]);
		setDuration(60);
	}, [defaultLanguageOption, defaultSessionTypeValue, dispatch, user]);

	const closePastEvent = useCallback(() => {
		setSubmitting(false);
		setPastEventOpen(false);
	}, []);

	// Duplicate names aren't allowed
	const checkDuplicateName = useCallback((sessionName: string) => {
		return sessionNames.some(({ name, id }) => {
			const isNotThisSession = id !== workingSession.uid;
			return isNotThisSession && name.trim() === sessionName.trim();
		});
	}, [sessionNames, workingSession.uid]);

	// Sets duration from start and end timestamps
	const updateDuration = (startTime?: number, endTime?: number) => {
		// NaN empties the duration field rather than populating it with a 0 (though console warns)
		const updatedDuration = startTime && endTime ? (endTime - startTime) / 60000 : NaN;
		setDuration(updatedDuration);
	};

	const handleSessionTypeChange = (sessionType: SessionTypesEnum) => {
		// Unlike edit-session-modal, timestamps should be removed from On Demand sessions here
		const sessionTypeHasStartTime = SessionTypesFeatures[sessionType].has_start_time;
		const startTime = sessionTypeHasStartTime ? workingSession.start_timestamp : undefined;
		const endTime = sessionTypeHasStartTime ? workingSession.end_timestamp : undefined;
		const updatedSession: CreateSession = {
			...workingSession,
			session_type: sessionType,
			start_timestamp: startTime,
			end_timestamp: endTime,
		};

		if (sessionType === SessionTypesEnum.fireside) {
			// Adds default fireside hosts if none exist
			if (!updatedSession.firesides_hosts?.length) {
				updatedSession.firesides_hosts = admins.map(({ email }) => email);
			}
			// fireside sessions can only have one language, this sets it to the first language in the list
			if (updatedSession.languages.length > 1) {
				updatedSession.languages = [defaultLanguageOption.code];
				setLanguages([defaultLanguageOption]);
			}
		}

		if (startTime && endTime) updateDuration(startTime, endTime);
		setWorkingSession(updatedSession);

		resetRequiredFields();
		// Resetting required causes empty to be valid, which should be addressed at some point; this fixes that
		const duplicateName = checkDuplicateName(workingSession.title);
		if (duplicateName || !workingSession?.title?.trim()?.length) {
			updateRequiredField('name', '');
		}
	};

	// This modal is opened when addingSession is set to true
	useEffect(() => {
		if (addingSession) {
			// Get the track dropdown list on modal open
			loadTracks();
		}
	}, [addingSession, loadTracks]);

	// Populate an existing session to edit (note that all sessions in this modal are in the create event workflow)
	useEffect(() => {
		if (editingSessionAgenda) {
			setWorkingSession(editingSessionAgenda);
			updateDuration(editingSessionAgenda.start_timestamp, editingSessionAgenda.end_timestamp);
			setTrackDropdownList(editingSessionAgenda.tracks);
		}
	}, [editingSessionAgenda]);

	const googleOAuthClientId = process.env.REACT_APP_MEET_INTEGRATION_OAUTH_CLIENT_ID || '';
	const [showBreakoutRooms, hideBreakoutRooms] = useModal(() => {
		let existingBreakoutSession: BreakoutSession | undefined;
		if (workingSession.breakout_session) {
			existingBreakoutSession = workingSession.breakout_session;
		}
		return (
			// BP-2155 GoogleOAuthProvider wrapper can be removd after phase 0 of meet integration
			<GoogleOAuthProvider clientId={googleOAuthClientId}>
				<BreakoutSessionsModal
					initialValues={existingBreakoutSession}
					onClose={async (done, breakoutSession) => {
						if (done) {
							workingSession.breakout_session = breakoutSession;
							await handleSave();
						}
						setShowBreakoutRoomsModal(false);
						hideBreakoutRooms();
					}}
					isSaving={submitting}
					propsDefaultLanguage={defaultLanguage}
				/>
			</GoogleOAuthProvider>
		);

	}, [handleSave, workingSession]);

	// Show event-in-past modal without saving if the new or updated date is in the past
	const notifyIfInPast = (session: CreateSession) => {
		const { start_timestamp: startTimestamp } = workingSession;
		const checkIfInPast = !editingSessionAgenda || editingSessionAgenda.start_timestamp !== startTimestamp;
		const isPast = checkIfInPast && session.start_timestamp && session.start_timestamp - Date.now() <= 0;
		if (isPast) {
			setPastEventOpen(isPast);
		}
		return isPast;
	};

	async function handleSave() {
		const sessionToSave = {
			...workingSession,
			on_demand: workingSession.session_type === SessionTypesEnum.onDemand, // deprecated
			replay_on: getReplayValue(workingSession.session_type)
		};

		if (notifyIfInPast(sessionToSave)) return;

		// Probably not necessary; nothing is being awaited
		setSubmitting(true);

		if (editingSessionAgenda) {
			dispatch(updateEditingSessionByUid(sessionToSave));
		} else {
			await createSession(sessionToSave);

			const leaderboardNavIcon = workingEvent?.homepage?.event_main_nav_items?.find(ni => ni.name === NavMainItems.Leaderboard);

			if (workingEvent?.sessions.length === 1 && workingEvent?.homepage?.homepage) {
				addingSessionFromSingleSessionEvent ?
					dispatch(updateHomepageMainNav(GetMultipleSessionsMainNavItems(workingEventDefaultLanguage(workingEvent), undefined, undefined, leaderboardNavIcon))) :
					dispatch(updateHomepageMainNav(GetDefaultMainNavItems(workingEventDefaultLanguage(workingEvent))));
			}
		}

		resetForm();
		resetRequiredFields();
		setSubmitting(false);
	}

	const handleSetDate = (date: Date) => {
		setWorkingSession({
			...workingSession,
			start_timestamp: date.valueOf(),
			end_timestamp: duration
				? date.valueOf() + 1000 * 60 * duration
				: undefined,
		});
	};

	function handleSetDuration(minutes: number | undefined) {
		if (minutes !== undefined) {
			// max session duration is 6 months, min is 5 minutes
			setDuration(numClamp(minutes, 1, (60 * 24 * 365) / 2));
		} else {
			// allow setting as undefined to display a proper error message
			// and not interrupt user's typing
			setDuration(undefined);
		}

		if (workingSession.start_timestamp && minutes !== undefined) {
			setWorkingSession({
				...workingSession,
				end_timestamp: minutes
					? workingSession.start_timestamp + 1000 * 60 * minutes
					: undefined,
			});
		}
	}

	const setTracks = (tracks: string[]) => {
		setWorkingSession({
			...workingSession,
			tracks,
		});
	};

	const handleChangeFiresideHosts = (hostEmails: string[]) => {
		setWorkingSession({
			...workingSession,
			firesides_hosts: hostEmails,
		});
	};

	const handleChangeLanguages = (languages: Language[]) => {
		// Working session requires language codes, not language objects
		const languageCodes = languages.map((lang: Language) => lang.code);
		setWorkingSession(prev => ({
			...prev,
			default_language: defaultLanguageOption.code,
			languages: languageCodes,
		}));

		// The language selector now works with Language objects whereas session still uses language codes
		// Until there's a better workaround or Session is updated, they have to be controlled independently and stay in sync
		setLanguages(languages);
	};

	const handleChangeSessionName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		updateRequiredField('duplicateName', checkDuplicateName(target.value));
		updateRequiredField('name', target.value);
		setWorkingSession({
			...workingSession,
			title: target.value,
		});
	};

	const validName = (requiredFields.name === Validation.normal && requiredFields.duplicateName === Validation.normal) ? Validation.normal : Validation.error;
	const validCustomUrl = workingSession.custom_url && validateUrl(workingSession.custom_url) ? Validation.normal : Validation.error;

	function formValid() {
		const validTitle = requiredFields.name === Validation.normal && requiredFields.duplicateName === Validation.normal;

		let customUrlValid;
		let customUrlPresented;

		//if the custom url is toggle off, we don't require the custom url field as a required field.
		if (!workingSession.custom_url_enabled) {
			customUrlPresented = true;
			customUrlValid = true;
		}
		//On the other hand, the custom url is a required field in the modal.
		else {
			//Make sure the input custom url is a valid url.
			customUrlValid = workingSession.custom_url ? validateUrl(workingSession.custom_url) : true;
			// The custom url is a required field, so the user has to enter some texts in the input.
			customUrlPresented = workingSession.custom_url ? ((workingSession.custom_url.length > 0 && customUrlValid) ? true : false) : false;
		}

		// Firesides require at least one host
		const validFiresideHosts = workingSession.session_type !== SessionTypesEnum.fireside
			|| !!workingSession.firesides_hosts?.length;

		// Somebody who understands useRequiredFields can refactor this
		const requiresTimestamps = SessionTypesFeatures[workingSession.session_type].has_start_time;
		const isMissingTimestamps = !workingSession.start_timestamp || !workingSession.end_timestamp;

		return validTitle && validFiresideHosts && !(requiresTimestamps && isMissingTimestamps) && customUrlValid && customUrlPresented;
	}

	const handleSetRooms = () => {
		if (validName && formValid() && !notifyIfInPast(workingSession)) {
			setShowBreakoutRoomsModal(true);
			showBreakoutRooms();
		}
	};

	const handleSetCustomURL = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const updatedSession = {
			...workingSession,
			custom_url: target.value
		};

		setWorkingSession(updatedSession);
	};

	const handleToggleCustomUrl = () => {
		const updatedSession = {
			...workingSession,
			custom_url_enabled: !workingSession?.custom_url_enabled
		};

		setWorkingSession(updatedSession);
	};

	const handleToggleSingleLanguageChat = () => {
		const updatedSession = {
			...workingSession,
			session_chat_single_language: !workingSession?.session_chat_single_language
		};

		setWorkingSession(updatedSession);
	};

	useEffect(() => {
		if (workingSession?.custom_url_enabled) {
			setShowCustomUrlOption(true);
		}
		else {
			setShowCustomUrlOption(false);
		}
	}, [workingSession?.custom_url_enabled]);

	const handleCancel = () => {
		resetForm();
		resetRequiredFields();
		closeModal();
	};

	const handleSingleStreamToggle = () => {
		setWorkingSession({
			...workingSession,
			streaming_options: {
				...workingSession.streaming_options,
				single_stream: !workingSession?.streaming_options?.single_stream
			}
		});
	};

	const toggleFiresidesUseGoogleMeet = useCallback((_: string, isOn: boolean) => {
		setWorkingSession(workingSession => ({
			...workingSession,
			fireside_session_settings: {
				...workingSession.fireside_session_settings,
				settings: {
					...workingSession.fireside_session_settings,
					use_google_meet: isOn
				}
			}
		}));
	}, []);

	return (
		<>
			<Modal
				open={addingSession || !!editingSessionAgenda}
				onRequestClose={showBreakoutRoomsModal ? () => null : closeModal}
				title={editingSessionAgenda ? "Edit Session" : "New Session"}
				cancellable={false}
				closeable={false}
				className="session-modal"
				header={
					// TEMP: if not dev, then return null
					<>
						<div className="modal-header-select">
							{/* the different session types */}
							<div className="modal-header-select-title">Session type *</div>
							<SessionTypeSelect
								options={
									Object.values(SessionTypesFeatures).map(value => ({
										value: value.name,
										label: value.display_name,
										description: value.description,
										icon: value.icon_name
									}))
								}
								onChange={handleSessionTypeChange}
								selected={workingSession.session_type}
								defaultValue={defaultSessionTypeValue}
							/>
						</div>
						<div className="header-line-break" />
					</>
				}
				footer={(
					<div className="session-modal-footer">
						<span className={classNames("error-message", { toast: errorMessage })}>{errorMessage}</span>
						<div className="action-buttons">
							<button onClick={handleCancel} style={{ marginRight: 16 }}>Cancel</button>
							{SessionTypesFeatures[workingSession.session_type].show_set_rooms ? (
								<button disabled={!formValid() || submitting} onClick={handleSetRooms} className="lemonade">
									Set rooms
								</button>
							) : (
								<button
									disabled={!formValid() || submitting}
									onClick={handleSave}
									className="lemonade"
								>
									{editingSessionAgenda ? 'Update' : 'Add'}
								</button>
							)}
						</div>
					</div>
				)}
				showOverflow
				useAdditionalWrapper
				portalClassName="session-modal-portal"
			>
				<TextInput
					label={"Session Title *"}
					tooltip={[PrimaryTooltip, 'This is the first impression you make. Try to make it useful and convey the idea that the main benefit is somehow unique.']}
					onChange={handleChangeSessionName}
					placeholder={"Enter session title"}
					defaultValue={workingSession.title}
					value={workingSession.title}
					onBlur={(e) => updateRequiredField('name', e.target.value)}
					valid={validName}
					ignoreZIndex
				/>

				<TagSelectInput
					label={"Tracks"}
					tooltip={[PrimaryTooltip, 'Tracks help organize and distribute content in a meaningful way.']}
					placeholder={"Select or enter tracks"}
					onChange={setTracks}
					defaultTags={trackDropdownList}
					tags={workingSession.tracks}
					dropdownContainerStyles={{
						maxWidth: 'calc(100% - 80px)',
						margin: '0 auto',
					}}
					capSensitive
					noWhitespace
					maxTags={20}
					onError={(s) => {
						if (s === 'MAX TAGS') setErrorMessage('Twenty is the max number of tracks');
						setTimeout(() => setErrorMessage(''), 2500);
					}}
					shouldSearch
					allowWithoutEnter={true}
				/>
				<OptionalComponent display={canShowLanguageDropdown}>
					<LanguageSelectInput
						label={"Languages"}
						tooltip={[PrimaryTooltip, isFireside ? "Fireside sessions have a limit of 1 language." : "Creating a session in multiple languages let's you reach the widest possible audience."]}
						placeholder={"Select languages"}
						onChange={handleChangeLanguages}
						languages={languages}
						defaultLanguage={defaultLanguageOption}
						limit={isFireside ? 1 : undefined}
						disableDefault
						disableDefaultRemoval
						trackLeftPosition
					/>
				</OptionalComponent>

				{SessionTypesFeatures[workingSession.session_type].has_start_time && (
					<SessionStartTimeFields
						minDate={minDate}
						handleSetDate={handleSetDate}
						workingStartDate={workingStartDate}
						handleSetDuration={handleSetDuration}
						duration={duration}
					/>
				)}

				{workingSession.session_type === SessionTypesEnum.fireside && (
					<GoogleOAuthProvider clientId={googleOAuthClientId}>
						<FiresideHostsSection
							workingSession={workingSession}
							handleChangeFiresideHosts={handleChangeFiresideHosts}
							setWorkingSession={setWorkingSession}
							admins={admins}
						/>
					</GoogleOAuthProvider>
				)}

				<div className="single-stream-toggles-container">
					{(workingSession.session_type === SessionTypesEnum.broadcast
						|| workingSession.session_type === SessionTypesEnum.onDemand)
						&& workingSession.languages.length > 1 && (
							<div className="single-stream-toggle left-align">
								<label>Shared Video Player</label>
								<Tooltip tooltip="Same video player across all languages" position="top-right">
									<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
								</Tooltip>
								<Switch
									value={'single-stream-toggle'}
									onClick={handleSingleStreamToggle}
									on={!!workingSession?.streaming_options?.single_stream}
								/>
							</div>
						)}

					{workingSession.languages.length > 1 && (
						<div className="single-stream-toggle left-align custom-url">
							<label>Multi-language chat</label>
							<Tooltip
								textClassName="enable-custom-url-tooltip"
								tooltip="Enabling multi-language chat will push all comments and questions into a single feed regardless of the user's language."
								position="top-right"
							>
								<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
							</Tooltip>
							<Switch
								value="enable-custom-url"
								onClick={handleToggleSingleLanguageChat}
								on={!!workingSession.session_chat_single_language}
							/>
						</div>
					)}
				</div>

				<div className="single-stream-toggle left-align custom-url" data-testid='down'>
					<label>Custom URL</label>
					<Tooltip
						textClassName="enable-custom-url-tooltip"
						tooltip="Enabling a custom URL will replace the native session page and direct all new traffic to the URL set below. Users will be taken from the agenda view directly to the custom URL."
						position="top-right"
					>
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
					</Tooltip>
					<Switch
						value="enable-custom-url"
						onClick={handleToggleCustomUrl}
						on={!!workingSession.custom_url_enabled}
					/>
				</div>

				{showCustomUrlOption && <TextInput
					label="Custom URL *"
					onChange={handleSetCustomURL}
					placeholder="Enter custom URL"
					value={workingSession.custom_url}
					defaultValue={workingSession.custom_url}
					valid={workingSession.custom_url?.length ? validCustomUrl : Validation.normal}
					className={classNames("custom-url")}
				/>}

			</Modal>
			<Modal
				open={pastEventOpen}
				onRequestClose={closePastEvent}
				cancellable={false}
				closeable={false}
				title={"Events in the past"}
				footer={
					<div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
						<button className={'lemonade'} onClick={closePastEvent}>Close</button>
					</div>
				}
			>
				<div className="past-events-modal" style={{ marginTop: 8 }}>
					<p>Events must be scheduled in the present or future.</p>
				</div>

			</Modal>
		</>
	);
};
export default SessionModal;
