import { useEffect, useState } from "react";
import Checkbox from "../../../../../../general-ui/checkbox/checkbox";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { Tooltip, TooltipPositions } from "../../../../../../general-ui/tooltip/tooltip";

interface IRoundedSpeakerImagesProps {
	hasImageRadius: boolean;
	onChange: (isOn: boolean) => void;
	layout: string;
}

const RoundedSpeakerImages = ({
	hasImageRadius,
	onChange,
	layout
}: IRoundedSpeakerImagesProps) => {
	const [isChecked, setIsChecked] = useState(hasImageRadius);

	const _onChange = (_: unknown, isOn: boolean) => {
		setIsChecked(isOn);
		onChange(isOn);
	};

	useEffect(() => {
		if (layout === 'solid') {
			setIsChecked(false);
			onChange(false);
		}
	}, [layout]);

	return (
		<div className="rounded-speaker-images-container">
			<Checkbox
				size={20}
				checked={isChecked}
				value="rounded-speaker-images"
				onChange={_onChange}
				backgroundColor={isChecked ? COLORS.WHITE : '#878793'}
				inactiveColor={COLORS.DEFAULT_GRAY}
				activeColor={COLORS.DEFAULT_GRAY}
				label="Rounded Speaker Images"
				disabled={layout === 'solid'}
			/>
			<Tooltip
				position={TooltipPositions.topRight}
				tooltip={<>This feature cannot be used<br />with the solid layout.</>}
			>
				<Icon name={ICONS.INFO_OUTLINE} color={COLORS.CYAN} size={12} />
			</Tooltip>
		</div>
	);
};

export default RoundedSpeakerImages;
