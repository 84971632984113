import { useDispatch, useSelector } from 'react-redux';
import { updateMessage } from '../../store/actions/messages/messages';
import { EventsState } from '../../store/types';
import {
	EMessageButtonType,
	EMessageStatus,
	EMessageType,
	EMessageVisibility,
	IMessage,
	IMessageCardActionClickEventArgs,
	IMessageCardReplyClickEventArgs,
} from '../general-ui/message-card/message-card';

export const DEFAULT_MESSAGES = [
	{
		id: 'message1',
		status: EMessageStatus.Waiting,
		type: EMessageType.Chat,
		author: { firstName: 'Tomas', lastName: 'Anderson' },
		text: 'Hi from Tomas (chat, waiting)',
		time: '6:27 PM',
	},
	{
		id: 'message2',
		status: EMessageStatus.Deleted,
		type: EMessageType.Question,
		author: { firstName: 'Adam', lastName: 'Sendler' },
		text: 'Hello from Adam (question, deleted)',
		time: '9:27 PM',
	},
	{
		id: 'message3',
		status: EMessageStatus.Approved,
		type: EMessageType.Post,
		author: { firstName: 'Petr', lastName: 'Don' },
		text: 'Hello from Petr (post, approved)',
		time: '9:27 PM',
	},
	...Array.from({ length: 10 }).map((_, index) => {
		return {
			id: 'message-chat' + index,
			status: EMessageStatus.Waiting,
			type: EMessageType.Chat,
			author: { firstName: 'John', lastName: 'Smith' },
			text: 'Chat message (chat, waiting)',
			time: '9:27 PM',
		};
	}),
];

interface IResult {
	replyMessage: (props: IMessageCardReplyClickEventArgs) => void;
	changeStatus: (props: IMessageCardActionClickEventArgs) => void;
}
export const useMessages = (): IResult => {
	const dispatch = useDispatch();

	const messages = useSelector<EventsState, IMessage[]>(
		(e) => e.MessagesReducer.messages
	);

	return {
		replyMessage: ({ message, text }: IMessageCardReplyClickEventArgs) => {
			const clone: IMessage = {
				...messages.find((f) => f.id === message.id),
			} as IMessage;
			clone.replyMessage = {
				id: message.id + '_clone',
				text: text,
				author: {
					firstName: 'Current',
					lastName: 'User',
				},
				time: '6:28 PM',
				type: clone.type,
				status: clone.status,
			};
			dispatch(updateMessage(clone));
		},
		changeStatus: ({ message, type }: IMessageCardActionClickEventArgs) => {
			const clone: IMessage = {
				...messages.find((f) => f.id === message.id),
			} as IMessage;
			switch (type) {
				case EMessageButtonType.Approve:
					clone.status = EMessageStatus.Approved;
					break;

				case EMessageButtonType.Delete:
					clone.status = EMessageStatus.Deleted;
					break;

				case EMessageButtonType.UnDelete:
					clone.status = EMessageStatus.Waiting;
					break;

				case EMessageButtonType.SendToModerator:
					clone.visibility = [
						...(clone.visibility || []),
						EMessageVisibility.Moderator,
					];
					break;

				case EMessageButtonType.RemoveFromModerator:
					clone.visibility = [...(clone.visibility || [])].filter(
						(f) => f !== EMessageVisibility.Moderator
					);
					break;

				case EMessageButtonType.SendToPresenter:
					clone.visibility = [
						...(clone.visibility || []),
						EMessageVisibility.Presenter,
					];
					break;

				case EMessageButtonType.RemoveFromPresenter:
					clone.visibility = [...(clone.visibility || [])].filter(
						(f) => f !== EMessageVisibility.Presenter
					);
					break;
			}
			dispatch(updateMessage(clone));
		},
	};
};
