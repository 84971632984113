import { showAlert } from "../components/general-ui/alert/alert-service";

declare global {
	interface Window {
		grecaptcha: any;
	}
}

interface IHandleRecaptcha {
	action: string;
	useV2: boolean;
	widgetId: number | null;
}

export const handleRecaptcha = async ({ action, useV2, widgetId }: IHandleRecaptcha): Promise<any> => {
	// only call recaptcha v3 if we're not callign v2
	const recaptchaToken = !useV2 && await executeRecaptcha({ action });

	if (!recaptchaToken && !useV2) {
		showAlert({
			message: "Error executing recaptcha",
			description: "Please try again.",
			type: "error",
			duration: 5000
		});
		throw new Error('Invalid recaptcha');
	}

	const headers: Record<string, string> = {};

	if (recaptchaToken) {
		headers['X-Recaptcha'] = recaptchaToken;
	}

	// if recaptcha v3 failed and we require further action, send recaptcha v2 token to server for validation
	// recaptcha v2
	if (useV2 && (widgetId || widgetId === 0)) {
		// remove v3 recaptcha so we don't trigger it again
		delete headers['X-Recaptcha'];
		const recaptchaResponse = window?.grecaptcha?.getResponse(widgetId);

		if (!recaptchaResponse) {
			// user has not completed check
			throw new Error('Invalid recaptcha response');
		} else {
			headers['X-Recaptcha-V2'] = recaptchaResponse;
		}
	}

	return {
		headers,
		recaptchaToken,
	};
};

const executeRecaptcha = ({ action = '' }: { action: string; }): Promise<string> => {
	return new Promise((resolve, reject) => {
		// if (process.env.NODE_ENV === 'development') resolve(''); // don't run in development
		const grecaptcha = window?.grecaptcha;
		if (!grecaptcha) reject(new Error('No window.grecaptcha'));
		grecaptcha.ready(async () => {
			try {
				const recaptchaToken = await grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_CLIENT_SITE_KEY, { action });
				resolve(recaptchaToken);
			} catch (e: any) {
				reject(new Error(e));
			}
		});
	});
};

export default executeRecaptcha;
