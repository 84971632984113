import chroma from 'chroma-js';

import { AAA_COTNRAST_RATIO } from 'types/theme-packs';

/* 
	****IMPORTANT****
 
 This method does NOT guarantee a AAA spec.
 It will try to get as close as possible to the AAA spec, but it may not always be possible.

 We have a lot of colors that will not reach spec, which is why we have debug console warnings disabled by default.
*/


// we move in .1 increments and need to match the contrast ratio of 7:1
// so the max iterations we'll ever need before know we cannot get a valid spec is 7 / .1 = 70
const MAX_ITERATIONS = 71;

// quick set/remove for local dev (really just to avoid needing to type it in console every time I want to toggle it on/off)
// localStorage.setItem('debug-color-contrasts', 'true');
// localStorage.removeItem('debug-color-contrasts');

const debug = localStorage.getItem('debug-color-contrasts');
const contrastColorMemo = new Map<string, string>();
let gcTimeout: NodeJS.Timeout;

const gcColorMemo = () => {
	clearTimeout(gcTimeout);
	gcTimeout = setTimeout(() => {
		if (contrastColorMemo.size > 5000) { // if user goes crazy with color changes we'll dump the memo to avoid memory leaks
			contrastColorMemo.clear();
		}
	}, 500);
};

const addColorToMemo = (colorKey: string, contrastColor: string) => {
	contrastColorMemo.set(colorKey, contrastColor);
	gcColorMemo();
};

const getRelativeContrastColor = (colorToChange: string, colorToContrastAgainst: string) => {
	const memoized = contrastColorMemo.get(colorToChange + colorToContrastAgainst);
	if (memoized) {
		return memoized;
	}

	let darkenedColor = colorToChange;
	let lightenedColor = colorToChange;
	let iterations = 0;

	if (!chroma.valid(colorToChange) || !chroma.valid(colorToContrastAgainst)) {
		console.warn('getRelativeContrastColor: invalid color');
		addColorToMemo(colorToChange + colorToContrastAgainst, colorToChange);
		return colorToChange;
	}

	if (chroma.contrast(colorToChange, colorToContrastAgainst) >= AAA_COTNRAST_RATIO) {
		addColorToMemo(colorToChange + colorToContrastAgainst, colorToChange);
		return colorToChange;
	}

	while (
		chroma.contrast(darkenedColor, colorToContrastAgainst) < AAA_COTNRAST_RATIO
		&& chroma.contrast(lightenedColor, colorToContrastAgainst) < AAA_COTNRAST_RATIO
		&& iterations < MAX_ITERATIONS
	) {

		// alter the color by darkening, but don't unnecessarily darken if we already have a valid contrast
		darkenedColor = chroma.contrast(darkenedColor, colorToContrastAgainst) < AAA_COTNRAST_RATIO
			? chroma(darkenedColor).darken(.1).hex()
			: darkenedColor;

		// alter the color by lightening, but don't unnecessarily lighten if we already have a valid contrast
		lightenedColor = chroma.contrast(lightenedColor, colorToContrastAgainst) < AAA_COTNRAST_RATIO
			? chroma(lightenedColor).brighten(.1).hex()
			: lightenedColor;

		iterations++;
	}

	if (debug && iterations >= MAX_ITERATIONS) {
		console.warn('getRelativeContrastColor: too many iterations:');
		console.log('colorToChange', colorToChange);
		console.log('colorToContrastAgainst', colorToContrastAgainst);
		console.log('darkenedColor', darkenedColor, 'ratio:', chroma.contrast(darkenedColor, colorToContrastAgainst).toFixed(2));
		console.log('lightenedColor', lightenedColor, 'ratio:', chroma.contrast(lightenedColor, colorToContrastAgainst).toFixed(2));
	}

	const contrastA = Number(chroma.contrast(darkenedColor, colorToContrastAgainst).toFixed(2));
	const contrastB = Number(chroma.contrast(lightenedColor, colorToContrastAgainst).toFixed(2));
	if (contrastA >= contrastB) {
		addColorToMemo(colorToChange + colorToContrastAgainst, darkenedColor);
		return darkenedColor;
	} else {
		addColorToMemo(colorToChange + colorToContrastAgainst, lightenedColor);
		return lightenedColor;
	}
};

export default getRelativeContrastColor;
