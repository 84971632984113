import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { QuizzesStatus, Survey, SurveyQuestion } from '../../../../../types/working-model';
import { getPollAnswerLocalStorageKey } from '../../session-modules/survey/survey';
import { getSurveyText } from '../utils/engage.utils';

type UseGetAnsweredQuestionCount = (survey?: Survey) => number;

export const useGetAnsweredQuestionCount: UseGetAnsweredQuestionCount = (survey) => {
	const eventId = useTypedSelector((state) => state.LiveEventReducer.eventBundle?.event);

	if (!survey) return 0;

	const { questions, survey: surveyId } = survey;

	const answeredQuestions: string[] = [];

	questions.forEach((question: SurveyQuestion) => {
		if (!eventId) return;

		const lsKey = getPollAnswerLocalStorageKey(question, surveyId, eventId);
		const getStoredAnswers = localStorage.getItem(lsKey);

		if (getStoredAnswers) {
			answeredQuestions.push(getStoredAnswers);
		}
	});

	return answeredQuestions.length;
};

type UseGetEndScreenDescription = (params: {
	quizStatus: QuizzesStatus;
	isQuizType: boolean;
	maxQuestions: number;
	preview?: boolean;
}) => string;

export const useGetEndScreenDescription: UseGetEndScreenDescription = ({
	quizStatus,
	isQuizType,
	maxQuestions,
	preview,
}) => {
	const { t } = useTranslate("session");

	if (isQuizType && preview) {
		return getSurveyText({
			textToReplace: t('session:QuizCorrectAnsweredCount'),
			replaceMapping: {
				'1': maxQuestions,
				'0': maxQuestions,
			},
		});
	}

	if (isQuizType && quizStatus) {
		const { correctAnswers, totalAnswered } = quizStatus;
		const calculatedPercentage = Math.round((correctAnswers / totalAnswered) * 100);

		return getSurveyText({
			textToReplace: t('session:QuizCorrectAnsweredCount'),
			replaceMapping: {
				'1': correctAnswers,
				'0': totalAnswered,
				'100': calculatedPercentage,
			},
		});
	}

	return t('session:We truly value the information you have provided', 'We truly value the information you have provided');
};

export const useGetHoveredStateButtonName = (isQuestionType: boolean, surveyCompleted: boolean, isCommentBoxType: boolean) => {
	const { t } = useTranslate("session");

	if (isQuestionType) {
		return t('Ask question', 'Ask question');
	}

	if (isCommentBoxType) {
		return t('Post a comment', 'Post a comment');
	}

	if (surveyCompleted) {
		return t('View results', 'View results');
	}

	return t('Answer', 'Answer');
};
