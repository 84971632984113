import { useMemo } from "react";
import { useTypedSelector } from "../../../store/reducers/use-typed-selector";

import { BrandliveEvent } from "../../../types/working-model";

const useTranslationsV2Attendee = (event: BrandliveEvent | null) => {
	// const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	return useMemo(() => {
		if (!event) {
			return false;
		}

		// Unlike other places, this is a specific check for v2, not >= 2
		const useV2 = (
			(featureFlags["streams.translations_v2"] && Number(event.settings.translation_version) === 2)
			// Or fallback to v2 if v3 is selected but FF gets flagged OFF
			|| (Number(event.settings.translation_version) === 3 && !featureFlags["streams.translations_v3"])
		);
		return useV2;
	}, [event, featureFlags]);
};

export default useTranslationsV2Attendee;