import { GetGoogleMeetRoomForFireside } from 'connection/sessions';
import { LowerHand, RaiseHand } from '../../../connection/firesides';
import Socket from '../../../connection/socket';
import { Action, AsyncAction } from '../../../types/actions';
import { BlProfile, EFiresidePanels, FiresideSessionSettings, IAttendeeVideoSettings, IBreakoutRoomProfile } from '../../../types/working-model';
import { VideoFeedAnalyzer } from '../../../utils/video-feed-analyzer';

export const FS_SET_CONNECTED_PROFILES = "FS_SET_CONNECTED_PROFILES";
export function setConnectedProfiles(profiles: IBreakoutRoomProfile[]): Action {
	return {
		type: FS_SET_CONNECTED_PROFILES,
		payload: profiles
	};
}

export const FS_ADD_CONNECTED_PROFILE = "FS_ADD_CONNECTED_PROFILE";
export function addConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: FS_ADD_CONNECTED_PROFILE,
		payload: profile
	};
}

export const FS_ADD_LIST_OF_PROFILES = "FS_ADD_LIST_OF_PROFILES";
export function addListOfProfiles(profile: IBreakoutRoomProfile[]): Action {
	return {
		type: FS_ADD_LIST_OF_PROFILES,
		payload: profile
	};
}

export const FS_UPDATE_CONNECTED_PROFILES = "FS_UPDATE_CONNECTED_PROFILES";
export function updateConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: FS_UPDATE_CONNECTED_PROFILES,
		payload: profile
	};
}

export const FS_REMOVE_CONNECTED_PROFILE = "FS_REMOVE_CONNECTED_PROFILE";
export function removeConnectedProfile(profile: IBreakoutRoomProfile): Action {
	return {
		type: FS_REMOVE_CONNECTED_PROFILE,
		payload: profile
	};
}

export const FS_SET_LOADING_PROFILES = "FS_SET_LOADING_PROFILES";
export function setLoadingProfiles(loading: boolean): Action {
	return {
		type: FS_SET_LOADING_PROFILES,
		payload: loading
	};
}

export const FS_SET_OPENTOK_ID = "FS_SET_OPENTOK_ID";
export function setOpenTokId(id: string | number | null): Action {
	return {
		type: FS_SET_OPENTOK_ID,
		payload: id
	};
}

export const SET_FIRESIDE_PANEL_ITEM = "SET_FIRESIDE_PANEL_ITEM";
export function setFiresidePanelItem(panel: EFiresidePanels): Action {
	return {
		type: SET_FIRESIDE_PANEL_ITEM,
		payload: panel,
	};
}

export const FS_SET_VIEW_ATTENDEE = "FS_SET_VIEW_ATTENDEE";
export function setViewAttendee(profileId: number | null): Action {
	return {
		type: FS_SET_VIEW_ATTENDEE,
		payload: profileId,
	};
}

export const FS_SET_ATTENDEE_VIDEO_SETTINGS = "FS_SET_ATTENDEE_VIDEO_SETTINGS";
export function setAttendeeVideoSettings(data: IAttendeeVideoSettings): Action {
	return {
		type: FS_SET_ATTENDEE_VIDEO_SETTINGS,
		payload: data,
	};
}

export const SET_FIRESIDE_USAGE = "SET_FIRESIDE_USAGE";
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setFiresideRoomUsage(data: any): Action {
	return {
		type: SET_FIRESIDE_USAGE,
		payload: data,
	};
}

export const SET_FIRESIDE_STAGE_SOCKET = 'SET_FIRESIDE_STAGE_SOCKET';
export function setFiresideStageSocket(socket: Socket | null): Action {
	return {
		type: SET_FIRESIDE_STAGE_SOCKET,
		payload: socket,
	};
}

export const FORCE_FIRESIDE_RENDER = 'FORCE_FIRESIDE_RENDER';
export function forceBreakoutCountRender(): Action {
	return {
		type: FORCE_FIRESIDE_RENDER,
		payload: null,
	};
}

export const FS_RAISE_HAND = 'FS_RAISE_HAND';
export function raiseHand(session_uuid: string, token: string): AsyncAction {
	return {
		type: FS_RAISE_HAND,
		promise: RaiseHand(session_uuid, token)
	};
}

export const FS_LOWER_HAND = 'FS_LOWER_HAND';
export function lowerHand(session_uuid: string, token: string): AsyncAction {
	return {
		type: FS_LOWER_HAND,
		promise: LowerHand(session_uuid, token)
	};
}

export const FS_JOIN_REQ_DENY = 'FS_JOIN_REQ_DENY';
export function joinRequestDeny(): Action {
	return {
		type: FS_JOIN_REQ_DENY,
		payload: null
	};
}

export const SET_FIRESIDE_SPEAKER_VIDEO_MAP = 'SET_FIRESIDE_SPEAKER_VIDEO_MAP';
export function setFiresideSpeakerVideos(speaker_video_map: Map<number, VideoFeedAnalyzer>): Action {
	return {
		type: SET_FIRESIDE_SPEAKER_VIDEO_MAP,
		payload: speaker_video_map
	};
}

export const ADD_FIRESIDE_SPEAKER_VIDEO_MAP = 'ADD_FIRESIDE_SPEAKER_VIDEO_MAP';
export function addFiresideSpeakerVideos(videoKey: number, newSpeaker: VideoFeedAnalyzer): Action {
	return {
		type: ADD_FIRESIDE_SPEAKER_VIDEO_MAP,
		payload: { videoKey, newSpeaker }
	};
}

export const DELETE_FIRESIDE_SPEAKER_VIDEO_MAP = 'DELETE_FIRESIDE_SPEAKER_VIDEO_MAP';
export function deleteFiresideSpeakerVideo(videoKey: number): Action {
	return {
		type: DELETE_FIRESIDE_SPEAKER_VIDEO_MAP,
		payload: videoKey
	};
}

export const FS_SET_JOIN_REQUESTS = "FS_SET_JOIN_REQUESTS";
export function setFiresideJoinRequests(joinRequests: Array<BlProfile>): Action {
	return {
		type: FS_SET_JOIN_REQUESTS,
		payload: joinRequests
	};
}

export const FS_ADD_JOIN_REQUEST = "FS_ADD_JOIN_REQUEST";
export function addFiresideJoinRequest(joinRequest: BlProfile): Action {
	return {
		type: FS_ADD_JOIN_REQUEST,
		payload: joinRequest
	};
}

export const FS_REMOVE_JOIN_REQUEST = "FS_REMOVE_JOIN_REQUEST";
export function removeFiresideJoinRequest(uuid: string): Action {
	return {
		type: FS_REMOVE_JOIN_REQUEST,
		payload: uuid
	};
}

export const SET_FIRESIDES_DEVICES = "SET_FIRESIDES_DEVICES";
export function setFiresidesDevices(videoDevices: MediaDeviceInfo[], audioDevices: MediaDeviceInfo[], audioOutputs: MediaDeviceInfo[]): Action {
	return {
		type: SET_FIRESIDES_DEVICES,
		payload: { videoDevices, audioDevices, audioOutputs }
	};
}

export const SET_SELF_STREAM = "SET_SELF_STREAM";
export function setSelfStream(stream: MediaStream): Action {
	return {
		type: SET_SELF_STREAM,
		payload: stream
	};
}

export const SET_FIRESIDE_LIVE = "SET_FIRESIDE_LIVE";
export function setFiresideLive(isLive: number | null): Action {
	return {
		type: SET_FIRESIDE_LIVE,
		payload: isLive
	};
}

export const SET_SCREENSHARE_DISABLED = "SET_SCREENSHARE_DISABLED";
export function setScreenshareDisabled(disabled: boolean): Action {
	return {
		type: SET_SCREENSHARE_DISABLED,
		payload: disabled
	};
}

export const SET_HAS_ACTIVE_STREAM = "SET_HAS_ACTIVE_STREAM";
export function setHasActiveStream(hasActiveStream: boolean): Action {
	return {
		type: SET_HAS_ACTIVE_STREAM,
		payload: hasActiveStream
	};
}

export const SET_FIRESIDE_GOOGLE_MEET_SETTINGS = 'SET_FIRESIDE_GOOGLE_MEET_SETTINGS';
export function setFiresideGoogleMeetSettings(sessionUuid: string, settings: FiresideSessionSettings): Action {
	return {
		type: SET_FIRESIDE_GOOGLE_MEET_SETTINGS,
		payload: { sessionUuid, settings }
	};
}

export const SET_FS_JOINED = "SET_FS_JOINED";
export function setFsJoined(joined: boolean): Action {
	return {
		type: SET_FS_JOINED,
		payload: joined
	};
}

export const SET_MEET_ENTERED = "SET_MEET_ENTERED";
export function setMeetEntered(entered: boolean): Action {
	return {
		type: SET_MEET_ENTERED,
		payload: entered
	};
}

export const FS_MEET_IN_WAITING_ROOM = "FS_MEET_IN_WAITING_ROOM";
export function inMeetWaitingRoom(inRoom: boolean): Action {
	return {
		type: FS_MEET_IN_WAITING_ROOM,
		payload: inRoom
	};
}

export const FS_MEET_GET_GOOGLE_MEET_FS_SETTINGS = "FS_MEET_GET_GOOGLE_MEET_FS_SETTINGS";
export function getGoogleMeetFsSettings(sessionUuid: string, serviceAccountEnabled: boolean | undefined, token: string): AsyncAction {
	return {
		type: FS_MEET_GET_GOOGLE_MEET_FS_SETTINGS,
		promise: GetGoogleMeetRoomForFireside(sessionUuid, serviceAccountEnabled, token)
	};
}

export const FS_MEET_SET_GOOGLE_MEET_FS_SETTINGS = "FS_MEET_SET_GOOGLE_MEET_FS_SETTINGS";
export function setGoogleMeetFsSettings(settings: FiresideSessionSettings): Action {
	return {
		type: FS_MEET_SET_GOOGLE_MEET_FS_SETTINGS,
		payload: settings
	};
}

export const FS_MEET_USER_LEFT = "FS_MEET_USER_LEFT";
export function userLeftMeet(): Action {
	return {
		type: FS_MEET_USER_LEFT,
		payload: undefined
	};
}
