import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";

import { Actions, VideoStateContext } from "../../session-stream-provider";
import { getThumbnailUrlFromIvs, getThumbnailUrlFromUpload } from "../utils";
import { TimeUpdate, VideoPlayerType } from "../types";
import { convertSecondsToHMS } from "../../../../../../utils/utils";
import db from 'store/utils/indexed-db';
import * as Signals from '../../../../../../utils/event-emitter';
import * as EventActions from '../../../../../../store/actions/event/event-actions';
import { imageLoader, thumbnailGenerator } from "./thumbnail-loader";
import { Session, SessionTypesEnum } from "../../../../../../types/working-model";
import { useAppDispatch } from "../../../../../../store/reducers/use-typed-selector";
import { useGetRecordingJWT, useGetRecordingUrl } from "hooks/recording-jwt.hooks";

declare global {
	interface CSSStyleDeclaration {
		contentVisibility: 'auto' | 'hidden' | 'visible'
	}
}

const getBackgroundImage = (thumbnails: string[], activeImage: [number | null, number | null]): string => {
	let backgroundImage = '';

	if (thumbnails) {
		const images: string[] = [];

		const [current, previous] = activeImage;

		// CSS background image order is front-to-back so we want the current image first, the last image second
		if (typeof current === 'number') {
			const img = thumbnails[current];
			if (img) {
				images.push(`url(${img})`);
			}
		}

		if (typeof previous === 'number') {
			const img = thumbnails[previous];
			if (img) {
				images.push(`url(${img})`);
			}
		}

		backgroundImage = images.join(', ');
	}

	return backgroundImage;
};

const ProgressTrack: React.FC<{ wrapperRef: HTMLDivElement | null }> = ({ wrapperRef }) => {
	const { dispatch, state } = useContext(VideoStateContext);
	const {
		loadedPercentage,
		onDemandVideo,
		currentDynamicVideo,
		durationInSeconds,
		playerType,
		isEditor,
		session,
		idle
	} = state;

	const gutterContainerRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const trackThumbRef = useRef<HTMLDivElement>(null);
	const loadedTrackRef = useRef<HTMLDivElement>(null);
	const playedTrackRef = useRef<HTMLDivElement>(null);
	const draggingRef = useRef(false);
	const hoveringRef = useRef(false);
	const thumbnailContainer = useRef<HTMLDivElement | null>(null);
	const [thumbnails, setThumbnails] = useState<string[] | null>(null);
	// [current, previous]
	const [activeImage, setActiveImage] = useState<[number | null, number | null]>([null, null]);
	const playedPercentageRef = useRef(0);
	const timestampRef = useRef<HTMLLabelElement | null>(null);
	const canRenderRef = useRef(true);
	const unmountedRef = useRef(false);
	const recordingJWT = useGetRecordingJWT(); // For thumbnail
	const getRecordingUrl = useGetRecordingUrl();

	const isOnDemand = (session as Session).session_type === SessionTypesEnum.onDemand;
	useEffect(() => {
		const updateRefs = (progress: TimeUpdate) => {
			playedPercentageRef.current = progress.played;

			if (isOnDemand && session?.session && onDemandVideo?.source) {
				// saving progress to reload at same point of video in future
				db.setOnDemandWatchTime(session.session, onDemandVideo.source, progress.playedSeconds, durationInSeconds).catch(console.error);
			}

			// if the user is not dragging the thumb, update the thumb position
			if (!draggingRef.current && gutterContainerRef.current && canRenderRef.current) {
				requestAnimationFrame(() => {
					// not using standard react state here because we don't want to re-render uselessly
					// when moving the mouse - save the frames
					if (trackThumbRef.current) {
						trackThumbRef.current.style.left = `${playedPercentageRef.current * 100}%`;
					}

					if (playedTrackRef.current) {
						playedTrackRef.current.style.width = `${playedPercentageRef.current * 100}%`;
					}
				});
			}
		};

		Signals.addEventListener('video-player-progress', updateRefs);

		return () => {
			Signals.removeEventListener('video-player-progress', updateRefs);
		};
	}, [durationInSeconds, isOnDemand, session?.session, onDemandVideo?.source]);

	useEffect(() => {
		const handleVisibility = (hidden: boolean) => {
			canRenderRef.current = !hidden;

			if (!hidden) {
				if (trackThumbRef.current && gutterContainerRef.current) {
					trackThumbRef.current.style.left = `${playedPercentageRef.current * 100}%`;
				}
			}
		};

		Signals.addEventListener('controls-hidden', handleVisibility);

		return () => {
			Signals.removeEventListener('controls-hidden', handleVisibility);
		};
	}, []);

	useEffect(() => {
		// pixels moved + or -
		let deltaX = 0;

		// pixels moved left of the gutter container
		let correctedX = 0;

		// starting x position of the cursor
		let startX = 0;

		// so we can ensure that we properly remove the listeners, we need to hold them
		// in this closure because by cleanup time we might have a new ref
		const trackThumb = trackThumbRef.current;
		const container = containerRef.current;
		const gutterContainer = gutterContainerRef.current;

		const handlePointerDown = (e: PointerEvent) => {
			// sanity check
			e.stopPropagation();
			if (!wrapperRef || !trackThumb) {
				return;
			}

			// prevent react from handling the progress event
			draggingRef.current = true;

			// add event listener for moving the thumb
			document.addEventListener('pointerup', handlePointerUp);
			wrapperRef.addEventListener('pointermove', handlePointerMove);

			// set starting positions
			startX = e.clientX;
		};

		const moveThumbnailAndTimeInidcator = (
			cursorPositionX: number,
			width: number,
			percentMoved: number,
			secondsMoved: number,
			leftDistanceToPageEdge: number
		) => {
			// thumbnail images should not move out of view to left or right of video container
			const inBoundsLeft = cursorPositionX - leftDistanceToPageEdge > 75;
			const inBoundsRight = cursorPositionX - leftDistanceToPageEdge < width - 75;

			// thumbnail images must move in a translated percentage of the whole
			if (inBoundsLeft && inBoundsRight && thumbnailContainer.current) {
				thumbnailContainer.current.style.transform = `translateX(${percentMoved * 100}%)`;
			}

			// Keeps the timestamp within the bounds of the slider
			if (timestampRef.current) {
				timestampRef.current.style.left =
					percentMoved > 0.98
						? '97%'
						: percentMoved < 0.015
							? '2.5%' :
							`${(percentMoved - (percentMoved > 0.5 ? 0.01 : -0.01)) * 100}%`;

				const cappedTime = onDemandVideo?.recording_duration_ms && (secondsMoved > (onDemandVideo?.recording_duration_ms / 1000))
					? onDemandVideo?.recording_duration_ms / 1000
					: secondsMoved;

				timestampRef.current.textContent = convertSecondsToHMS(Math.max(0, Math.floor(cappedTime)), true);
			}

			if (loadedTrackRef.current) {
				loadedTrackRef.current.style.width = percentMoved > 1 ? '100%' : `${percentMoved * 100}%`;
			}
		};

		const getPercentMoved = (cursorPositionX: number, left: number, width: number): [number, number, number] => {
			// amount moved in x axis as a percentage of the width of the track gutter
			const percentMoved = (cursorPositionX - left) / width;
			const secondsMoved = durationInSeconds * percentMoved;
			const minuteRange = Math.floor(secondsMoved / 60);
			return [percentMoved, secondsMoved, minuteRange];
		};

		const handlePointerMove = (e: PointerEvent) => {
			// sanity check
			if (!trackThumb || !gutterContainer || !container) {
				return;
			}

			draggingRef.current = true;

			// left x position of the cursor
			const cursorPositionX = e.clientX;

			// left x position of the gutter container and width of the gutter container
			const { left, width } = gutterContainer.getBoundingClientRect();

			const cursorOutOfBoundsLeft = cursorPositionX < left;
			const cursorOutOfBoundsRight = cursorPositionX > left + width;

			// if the mouse has moved left or right of the gutter container, don't do anything
			// this prevents the thumb from moving off the track
			if (cursorOutOfBoundsLeft || cursorOutOfBoundsRight) {
				return;
			}

			// deltaX is the distance the mouse has moved from the starting position
			deltaX = cursorPositionX - startX;

			// correctedX is the number of pixels left of the gutter container the thumb is 
			correctedX = startX + deltaX;
			trackThumb.style.left = `${correctedX - left}px`;

			if (thumbnailContainer.current) {
				// percent moved from the left of the gutter container
				const [percentMoved, secondsMoved, minuteRange] = getPercentMoved(cursorPositionX, left, width);

				// recordings have a thumbnail for each minute
				// we want to minimize flickering by using two background images - the previous layered under the current
				// but we only want to fire the update when there's a significant change.
				setActiveImage((last) => {
					const [prevCurrent, prevPrevious] = last;

					// no change, return the exact same reference
					if (prevCurrent === minuteRange) {
						return last;
					}

					return [minuteRange, prevCurrent !== minuteRange ? prevCurrent : prevPrevious];
				});

				moveThumbnailAndTimeInidcator(cursorPositionX, width, percentMoved, secondsMoved, left);
			}
		};

		const handlePointerUp = () => {
			// sanity check
			if (!wrapperRef || !trackThumb || !gutterContainer) {
				return;
			}

			// allow react to handle the progress event
			draggingRef.current = false;

			// get coordinates of the gutter container
			const { left, width } = gutterContainer.getBoundingClientRect();

			// percent moved from the left of the gutter container
			const percentMoved = (correctedX - left) / width;

			// seek to this percentage
			dispatch({ type: Actions.SeekTo, payload: percentMoved });

			// remove event listeners
			document.removeEventListener('pointerup', handlePointerUp);
			wrapperRef.removeEventListener('pointermove', handlePointerMove);
			trackThumb.style.transform = '';

			setActiveImage(([prevCurrent]) => [null, prevCurrent]);
		};

		const handlePointerEnter = (e: PointerEvent) => {
			hoveringRef.current = true;

			handleHover(e);
		};

		const handleHover = (e: PointerEvent) => {
			if (hoveringRef.current && !draggingRef.current && thumbnailContainer.current && gutterContainer && container) {
				// get distance from left-edge of track gutter to the left side of the page
				const { left, width } = gutterContainer.getBoundingClientRect();
				const cursorPositionX = e.clientX;

				// divide up the track gutter into regions representing 1 minute of video
				// |   1   |   2   |   3   |   4   |
				// ------------------•--------------

				// amount moved in x axis as a percentage of the width of the track gutter
				const [percentMoved, secondsMoved, minuteRange] = getPercentMoved(cursorPositionX, left, width);

				// recordings have a thumbnail for each minute
				setActiveImage((last) => {
					const [prevCurrent, prevPrevious] = last;

					// no change, return the exact same reference
					if (prevCurrent === minuteRange) {
						return last;
					}

					return [minuteRange, prevCurrent !== minuteRange ? prevCurrent : prevPrevious];
				});

				// move the time indicator and thumbnails if they exist
				moveThumbnailAndTimeInidcator(cursorPositionX, width, percentMoved, secondsMoved, left);
			}
		};

		const handlePointerLeave = () => {
			hoveringRef.current = false;
			if (!draggingRef.current) {
				setActiveImage(([prevCurrent]) => [null, prevCurrent]);
			}
		};

		if (wrapperRef && trackThumb && container) {
			// click and drag in the editor is difficult and not needed
			if (!isEditor) {
				// drag handlers
				trackThumb.addEventListener('pointerdown', handlePointerDown);
			}


			// hover handlers
			container.addEventListener('pointerenter', handlePointerEnter);
			container.addEventListener('pointerleave', handlePointerLeave);
			container.addEventListener('pointermove', handleHover);
		}

		return () => {
			if (wrapperRef && trackThumb && container) {
				// drag handlers
				trackThumb.removeEventListener('pointerdown', handlePointerDown);
				wrapperRef.removeEventListener('pointermove', handlePointerMove);
				document.removeEventListener('pointerup', handlePointerUp);

				// hover handlers
				container.removeEventListener('pointerenter', handlePointerEnter);
				container.removeEventListener('pointerleave', handlePointerLeave);
				container.removeEventListener('pointermove', handleHover);
			}
		};
	}, [dispatch, wrapperRef, durationInSeconds, isEditor, onDemandVideo?.recording_duration_ms]);

	const handleProgressClick = useCallback((e: React.MouseEvent) => {
		// do nothing if the user is currently dragging
		if (!gutterContainerRef.current || draggingRef.current) {
			return;
		}

		const clientX = e.clientX;
		const { left, width } = gutterContainerRef.current.getBoundingClientRect();
		const correctedX = clientX - left;
		const percentMoved = correctedX / width;
		dispatch({ type: Actions.SeekTo, payload: percentMoved });
	}, [dispatch]);

	const sessionUuid = session?.uuid;

	const checkThumbnailPreviews = useCallback(async () => {
		try {
			const url = currentDynamicVideo?.playback_url;

			// currently can only show thumbnails for HLS videos originating with IVS (recordings)
			if (url && durationInSeconds && playerType === VideoPlayerType.hls) {
				const isIvsRecording = (/((\/ivs\/v1).+(.m3u8))$/).test(url);
				const isUploaded = (/^https:\/\/uploads.brandlive(-.+|).com(.+)converted.m3u8$/).test(url);

				let exists: boolean | undefined;
				let imageUrl: string | undefined;
				// IVS recordings and our uploads should have a thumbnail generated every minute
				// and the URLs of those thumbnails are predictable based on the
				// URL of the recording m3u8 manifest, so we can check if they exist
				// and duration is greater than 5 minutes
				if (durationInSeconds > 60 * 5) {
					if (isIvsRecording) {
						imageUrl = getThumbnailUrlFromIvs(url);
					} else if (isUploaded) {
						imageUrl = getThumbnailUrlFromUpload(url);
					}

					if (imageUrl) {
						const imgEl = document.createElement('img');
						exists = await (() => {
							return new Promise((resolve) => {
								imgEl.onload = () => {
									resolve(true);
								};
								imgEl.onerror = () => {
									resolve(false);
								};
								imgEl.src = imageUrl as string;
							});
						})();
					}
				}

				if (exists && sessionUuid) {
					const minutes = Math.ceil(durationInSeconds / 60);

					const getThumbnailUrlWithJWT = (url: string, index: number) => {
						return getRecordingUrl(getThumbnailUrlFromIvs(url, index), recordingJWT) || '';
					};

					const thumbnailTransformer = isIvsRecording ? getThumbnailUrlWithJWT : getThumbnailUrlFromUpload;
					const imageArray = new Array(minutes).fill('').map((_, i) => {
						return thumbnailTransformer(url, i);
					});

					const resizedArray = new Array(minutes).fill(null);
					const thumbs = thumbnailGenerator(imageArray, sessionUuid);

					for await (const thumbSet of thumbs) {
						for (const [url, index] of thumbSet) {
							resizedArray[index] = url;
						}

						if (!unmountedRef.current) {
							setThumbnails(resizedArray);
						} else {
							break;
						}
					}

					return;
				}
			}

			setThumbnails(null);
		} catch (e) {
			console.error(e);
			setThumbnails(null);
		}
	}, [currentDynamicVideo?.playback_url, durationInSeconds, getRecordingUrl, playerType, recordingJWT, sessionUuid]);

	useEffect(() => {
		checkThumbnailPreviews();
	}, [checkThumbnailPreviews]);

	useEffect(() => {
		if (thumbnails?.length) {
			imageLoader.addUrls(thumbnails);
		}
	}, [thumbnails]);

	useEffect(() => {
		return () => {
			unmountedRef.current = true;
		};
	}, []);

	useEffect(() => {
		if (!draggingRef.current && loadedTrackRef.current) {
			loadedTrackRef.current.style.width = `${loadedPercentage * 100}%`;
		}
	}, [loadedPercentage]);

	return useMemo(() => (
		<div className="progress track-gutter-container" ref={containerRef} onClick={handleProgressClick}>
			<div data-testid="controls-vod-track-gutter" className="track-gutter" ref={gutterContainerRef}>
				<div className="loaded-track" ref={loadedTrackRef}></div>
				<div className="played-track" ref={playedTrackRef}></div>
				<div
					className={classNames("thumb-trigger", { hidden: idle })}
					ref={trackThumbRef}
				>
					<div className="thumb"></div>
				</div>
			</div>

			{thumbnails ? (
				<div className="thumbnails-container">
					<div
						ref={thumbnailContainer}
						className={classNames("thumbnail-pin", { active: activeImage[0] !== null })}>
						<div
							className="thumbnail-image"
							style={
								activeImage !== null ? {
									backgroundImage: getBackgroundImage(thumbnails, activeImage),
									backgroundColor: 'rgba(26, 26, 31, 0.25)' // gray-1000 * 25%
								} : {}
							}
						>
						</div>
					</div>
				</div>
			) : (
				<div className="thumbnails-container">
					<div
						ref={thumbnailContainer}
						className={classNames("thumbnail-pin", { active: activeImage !== null })}>
						<div
							className="thumbnail-image"
						>
						</div>
					</div>
				</div>
			)}

			<label ref={timestampRef}></label>
		</div>
	), [activeImage, handleProgressClick, idle, thumbnails]);
};

export default ProgressTrack;