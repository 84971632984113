import { useTypedSelector } from "store/reducers/use-typed-selector";

import './in-person-attendee-message.scss';
import { useTranslate } from "i18n/useTranslationModules";
import classnames from "classnames";

interface IInPersonAttendeeMessageProps {
	className?: string;
}

const InPersonAttendeeMessage = ({ className }: IInPersonAttendeeMessageProps) => {
	const { t } = useTranslate("session");

	const attendeeInPersonEnabled = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.attendeeInPersonEnabled);

	if (!attendeeInPersonEnabled) return null;
	return (
		<div className={classnames("in-person-message-container", className)}>
			<h4>{t("session:Video disabled due to in person mode. To view the stream, please update your profile page.", "")}</h4>
		</div>
	);
};

export default InPersonAttendeeMessage;
