import React, { useEffect, useState } from 'react';

import { Session, SessionTypesEnum } from '../../../../../../types/working-model';
import allLanguages from '../../../../../../utils/translate-langs.json';
import Icon, { ICONS } from '../../../../../general-ui/icon';
import NavigationDropdown from '../../../../../general-ui/dropdown/navigation-dropdown';


interface SelectLanguagesProps {
	onSelect?: (language: { label: string, code: string }) => void;
	sessions: Session[];
	sessionType?: SessionTypesEnum;
	resetCounter?: number;
	label?: string;
}

export interface LanguageOption {
	label: string;
	code: string;
}

const DEFAULT_LANGUAGE_OPTION: LanguageOption = {
	label: 'ALL LANGUAGES',
	code: 'all',
};

export const availableLanguages = (sessions: Session[], sessionType: SessionTypesEnum | undefined): LanguageOption[] => {
	const languagesMap: { [key: string]: LanguageOption } = {};
	allLanguages.forEach(lang => {
		languagesMap[lang.code] = lang;
	});
	return sessions.reduce((languages: LanguageOption[], session: Session) => {
		// if a session type is passed in; that is if the session lists are split between broadcast and on-demand as
		// opposed to listing all session as one long list, then be sure to only provide languages from sessions of that type
		if (session.languages?.length) {
			let allowBasedOnType = false;
			// if session is on demand, then only show languages from on demand sessions
			if (sessionType === SessionTypesEnum.onDemand && session.session_type === SessionTypesEnum.onDemand) {
				allowBasedOnType = true;
			}
			// if session is broadcast, then only show languages from broadcast sessions
			if (sessionType === SessionTypesEnum.broadcast && session.session_type === SessionTypesEnum.broadcast) {
				allowBasedOnType = true;
			}
			// if no sessionType passed in, then show all languages
			if (!sessionType) {
				allowBasedOnType = true;
			}
			const languagesSet = new Set(languages);
			if (allowBasedOnType) {
				session.languages.forEach((lang: string) => {
					if (languagesMap[lang]) {
						languagesSet.add(languagesMap[lang]);
					} else {
						languagesSet.add({ label: lang, code: lang });
					}
				});
			}
			return Array.from(languagesSet);
		}
		return languages;
	}, []);
};

const SelectLanguages: React.FC<SelectLanguagesProps> = ({
	onSelect = () => ({}),
	sessions,
	sessionType,
	resetCounter,
	label,
}) => {
	const [selected, setSelected] = useState<LanguageOption>(DEFAULT_LANGUAGE_OPTION);

	// if resetCounter increments, then we reset the selected value:
	useEffect(() => {
		setSelected(DEFAULT_LANGUAGE_OPTION);
	}, [resetCounter]);

	// use any language from every session
	const handleClick = (language: LanguageOption) => () => {
		setSelected(language);
		onSelect(language);
	};

	return (
		<div className="agenda-session-filter agenda-session-filter-languages">
			<NavigationDropdown
				title={label ? label : selected.label}
				buttonClassName="agenda-sessions-actions-select read-only-exception override-read-only-button"
				edge="center"
				renderCustomIcon={() => (
					<Icon name={ICONS.LITTLE_ARROW_DOWN} color="navdrop-arrow" size={11} />
				)}
			>
				<button className="sharp read-only-exception override-read-only-button" onClick={handleClick(DEFAULT_LANGUAGE_OPTION)}>{label ? label : 'ALL LANGUAGES'}</button>
				{availableLanguages(sessions, sessionType).map((language: LanguageOption) => (
					<button key={language.code} className="sharp read-only-exception override-read-only-button" onClick={handleClick(language)}>
						{language.label}
					</button>
				))}
			</NavigationDropdown>
		</div>
	);
};

export default SelectLanguages;