import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { LeaderboardActions, LeaderboardMultiplier } from "../../../types/leaderboard";
import { UPDATE_ACTION_POINTS, LOAD_ACTION_POINTS } from "../../actions/admin/leaderboard-action-point";

// initial leaderboard action data set
// If event hasn't set leaderboard actions before, we need a default empty state to intialize with
// default state is every standard action turned off with a multiplier assignment of 1
// can't default in reducer because we need an event ID to set the actions
export const getInitialData = (eventId: number | undefined): LeaderboardMultiplier[] => {
	if (eventId) {
		return ([{ action: LeaderboardActions.registered, is_on: false, event_id: eventId, multiplier: 1 }, { action: LeaderboardActions.quiz_answer_correct, is_on: false, event_id: eventId, multiplier: 1 }, { action: LeaderboardActions.watched_minute, is_on: false, event_id: eventId, multiplier: 1 }]);
	}
	return [];
};

export interface LeaderboardActionPointState {
	mainActionsOn: LeaderboardMultiplier[];
	loadingActions: boolean;
	fetchingError: boolean;
}

const initialState: LeaderboardActionPointState = {
	fetchingError: false,
	loadingActions: false,
	mainActionsOn: [],
};

export default function LeaderBoardActionPointReducer(
	state: LeaderboardActionPointState = initialState,
	action: Action
): LeaderboardActionPointState {
	switch (action.type) {
		case UPDATE_ACTION_POINTS: {
			if (!state.mainActionsOn) return state;
			return {
				...state,
				mainActionsOn: action.payload
			};
		}
		case LOAD_ACTION_POINTS: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						loadingActions: true
					};
				},
				success: state => {
					return {
						...state,
						mainActionsOn: action.payload,
					};
				},
				finish: state => {
					return {
						...state,
						loadingActions: false
					};
				},
				failure: state => {
					return {
						...state,
						fetchingError: true
					};
				}
			});
		}
		default: {
			return state;
		}
	}
}