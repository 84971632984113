import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { generateUID } from "../../../utils/generate-uid";

import "./switch.scss";

interface SwitchProps {
	name?: string;
	checked?: boolean;
	onChange?: (name: string | undefined, checked: boolean) => void;
	onFocus?: (isFocused: boolean) => void;
	content?: string | JSX.Element;
	labelBefore?: boolean;
	arialLabel?: string;
	focused?: boolean;
}

// TODO Discuss about refactoring previous switch component
// interface SwitchProps {
// 	value: any;
// 	on: boolean;
// 	onClick: (value: any, on: boolean) => void;
// }

// export const Switch = (props: SwitchProps): JSX.Element => {
// 	function handleClick() {
// 		props.onClick(props.value, !props.on);
// 	}

// 	return (
// 		<div className={classNames("form-control switch", { on: props.on })}>
// 			<div className={classNames("gutter")} onClick={handleClick}>
// 				<div className="thumb" />
// 			</div>
// 		</div>
// 	);
// };
// export default Switch;

export const SwitchNew = ({
	onChange,
	name,
	checked,
	focused,
	content: label,
	labelBefore,
	arialLabel,
}: SwitchProps): JSX.Element => {
	const id = useMemo( (): string => generateUID(), []);
	const [isChecked, setIsChecked] = useState(checked);
	const [isFocused, setIsFocused] = useState(focused);

	function handleChange() {
		const newVal = !isChecked;
		setIsChecked(newVal);
		onChange && onChange(name, newVal);
	}

	function handleFocus() {
		setIsFocused(true);
	}

	function handleBlur() {
		setIsFocused(false);
	}

	return (
		<label
			className={classNames("form-control switch ev-switch", {
				on: isChecked,
				focused: isFocused,
				"label-before": labelBefore,
			})}
			htmlFor={id}
		>
			<input
				className="visually-hidden"
				id={id}
				type="checkbox"
				role="switch"
				aria-checked={isChecked}
				aria-label={arialLabel}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<span className={classNames("gutter")}>
				<span className="thumb" />
			</span>
			{label && <span className="ev-switch-label">{label}</span>}
		</label>
	);
};
