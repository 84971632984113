import { GetHv, Get, HvHostMap } from "../helpers";

declare global {
	interface Window {
		eventBundle: any;
	}
}

export const _DEPRECATED_fetchLiveEventBundle = async (uuid: string): Promise<any> => {
	if (window.eventBundle) {
		return window.eventBundle;
	}

	return Get(`/v3/event/${uuid}`, '', {}, true)
		.then(async res => {
			return await res.json();
		});
};

export function fetchLiveEventBundle(channelHost: string, eventUrl: string): Promise<any> {
	if (window.eventBundle)
		return Promise.resolve(window.eventBundle);
	else {
		return GetHv(HvHostMap.eventData, `/e3-event-bundle/${channelHost}/${eventUrl}`);
	}
}

export function refreshLiveEventBundle(channelHost: string, eventUrl: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/refresh-e3-event-bundle/${channelHost}/${eventUrl}`);
}

export function GetTicketQuantities(channelHost: string, eventUrl: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-ticket-info/${channelHost}/${eventUrl}`);
}

export function GetEndedBreakouts(sessionUuid: string): Promise<{ ended_breakouts: number[] }> {
	return GetHv(HvHostMap.eventData, `/e3-breakout-room/ended/${sessionUuid}`);
}
