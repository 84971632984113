import classNames from "classnames";
import { useContext, useState } from "react";
import { BrandliveEvent, LanguagesAbbr } from "types/working-model";
import Icon, { ICONS } from '@general-ui/icon';
import { useTranslate } from "i18n/useTranslationModules";
import { CurrentPageState } from "./navbar.hooks";
import { useRouteMatch, generatePath } from "react-router-dom";
import AppContext, { AppContexts } from "components/app-context";
import { setStorageItem } from "utils/local-storage";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import store from "store/main";
import { showAlert } from "@general-ui/alert/alert-service";
import { blurEditor, loadWorkingEvent } from "store/actions/admin/create-event";
import { AnyAction, Dispatch } from "redux";
import { setEventNavOpen } from "store/actions/event/event-actions";

const updateAdminTranslations = (dispatch: Dispatch<AnyAction>, uuid: string) => {
	const token = store.getState().AuthReducer.token;

	if (!token) return;

	showAlert({
		message: "Fetching translations",
		description: "Please wait a moment while we get new translations",
		duration: 4000,
		type: 'positive'
	});
	dispatch(blurEditor(true));
	dispatch(loadWorkingEvent(uuid, token));
};

type Props = {
	language: LanguagesAbbr;
	languages: LanguagesAbbr[];
	pageState: CurrentPageState;
	eventBundle: BrandliveEvent;
}
const NavbarLanguageSelector: React.FC<Props> = ({ eventBundle, language, languages, pageState }) => {
	const appContext = useContext(AppContext);
	const [open, setOpen] = useState(false);
	const { t } = useTranslate('languages-list');
	const match = useRouteMatch();
	const history = useHistory();
	const dispatch = useDispatch();

	const changeLanguage = (lang: LanguagesAbbr) => {
		if (appContext !== AppContexts.admin) {
			setStorageItem(`event.${eventBundle.event}.language`, lang);
		} else {
			updateAdminTranslations(dispatch, eventBundle.uuid);
		}

		const newPath = generatePath(match.path, { ...match.params, language: lang });
		history.replace(newPath);
		dispatch(setEventNavOpen(false));
	};

	return (
		<div
			className={classNames(
				"navbar__language-selector",
				{
					"navbar__language-selector-dropdown-open": open
				}
			)}
		>
			<div
				className="navbar__language-selector-current"
				onClick={() => setOpen(!open)}
			>
				<div className="navbar__language-selector-current-label">
					<Icon name={ICONS.TRANSLATIONS} color={''} size={24} />
					<span>{t(`Languages.${language}`, language)} ({language})</span>
				</div>
				<Icon name={ICONS.ANGLE_RIGHT} color={''} size={16} />
			</div>
			<div
				className={
					classNames(
						"navbar__language-selector-dropdown"
					)
				}
			>
				<div className="navbar__language-selector-dropdown-inner">
					{
						languages.filter(lang => lang !== language).map((lang, i) => (
							<div
								key={i}
								className="navbar__language-selector-dropdown-item"
								onClick={() => changeLanguage(lang)}
							>
								<span>{t(`Languages.${lang}`)} ({lang})</span>
							</div>
						))
					}
				</div>
			</div>
		</div>
	);
};

export default NavbarLanguageSelector;