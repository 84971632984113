import React, { useState, useEffect } from "react";

import './tab-title-input.scss';

interface ITabTitleProps {
	name: string;
	onChange: (name: string) => void;
	onSubmit: (name: string) => void;
	maxLength?: number;
}

const TabTitle = ({
	name,
	onChange,
	onSubmit,
	maxLength = 24,
}: ITabTitleProps) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	const [title, setTitle] = useState(name);

	useEffect(() => {
		setTitle(name);
	}, [name]);


	const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// this will trigger both the submit and blur events
		if (inputRef.current) {
			inputRef.current.blur();
		}
	};

	const handleBlur = () => {
		handleSubmit(title);
	};

	const handleSubmit = (text: string) => {
		onSubmit(text);
	};

	return (
		<form onSubmit={onFormSubmit} className="tab-title-form">
			<input
				className="tab-title-input"
				onClick={e => e.stopPropagation()}
				onBlur={handleBlur}
				value={title}
				maxLength={maxLength}
				onChange={e => {
					const value = e.target.value.replace(/\p{Emoji_Presentation}+/gu, '');

					setTitle(value);
					onChange(value);
				}}
				ref={inputRef}
			/>
		</form>
	);
};

export default TabTitle;