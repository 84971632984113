import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router';

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { TemplateClassNames, TemplateNames } from '../../../../types/template-layouts';
import { ContentEditors, ERegistrationLayoutTypes, Registration } from '../../../../types/working-model';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { getTemplateClassName, getTextValue } from '../../../../utils/utils';
import { getAsset } from '../../modules/assets/asset-map';
import { ParamsProps } from '../../live-event';
import { getStylingOverrides } from '../../utils';
import RenderEditorDescription from '../../../general-ui/editable-text/render-editor-description';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useRegistrationV2 } from 'hooks/channel.hooks';
import { useGetRegistrationLayout } from 'hooks/registration-hooks';

interface Props {
	registration: Registration;
	startDate?: Date;
	template: string;
	children: ReactNode;
}

export const RegistrationLayout = ({
	children,
	startDate,
	template,
	registration,
}: Props): JSX.Element => {
	const { language }: ParamsProps = useParams();
	const { t } = useTranslate('landing');
	const eventBundleContentEditor = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.content_editor);
	const workingEventContentEditor = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.content_editor);
	const contentEditor = eventBundleContentEditor || workingEventContentEditor;

	const styling_overrides = registration.style?.styling_overrides;

	const registrationStyle = registration.style;
	const registrationLayout = useGetRegistrationLayout(registrationStyle);

	const {
		backgroundColor,
		titleOverrides,
		descriptionOverrides,
	} = getStylingOverrides(styling_overrides);

	const templateClass = getTemplateClassName(template);

	const { title, description } = getTextValue(registration, language);

	return (
		<section
			className={classNames(
				`registration ${registrationLayout}`,
				templateClass,
				backgroundColor
			)}
		>
			<div
				className="registration-heading-container"
				style={{ backgroundImage: `url(${registration.image ? registration.image : getAsset(Object.keys(TemplateClassNames).includes(template) ? template : TemplateNames.Apollo, 'registration_header')})` }}
			>
				<div className={classNames("registration-start-time", { hideStartTime: !startDate })}>
					<div className="registration-start-time-date">
						<TypographyItem className="evt-caption-2 month">
							{startDate?.toLocaleString([], { month: 'short' })}
						</TypographyItem>
						<TypographyItem className="stable">
							{startDate?.toLocaleString([], { day: 'numeric' })}
						</TypographyItem>
					</div>
					<TypographyItem className="registration-start-time-title evt-caption-1" tagName="time" dateTime={startDate?.toISOString()}>
						{startDate?.toLocaleString([], { hour: 'numeric', minute: 'numeric' })}
					</TypographyItem>
				</div>
				<TypographyItem className={classNames('evt-heading-2 registration-title', titleOverrides)} tagName="h1">
					{t('mainEventBanner.title', title)}
				</TypographyItem>
				<TypographyItem className='evt-body-text-1'>
					<RenderEditorDescription
						description={t('mainEventBanner.description', description)}
						descriptionOverrides={classNames('registration-description', descriptionOverrides)}
						useNew={contentEditor === ContentEditors.quill}
					/>
				</TypographyItem>
			</div>
			<div className="registration-form-container">{children}</div>
		</section>
	);
};
