import { useParams } from 'react-router-dom';

import { useIsNewNavigation } from '../../../../hooks/navigation.hooks';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { ChannelCustomizedIcon, CustomPage, ENavbarTypes, LanguagesAbbr } from '../../../../types/working-model';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import { iconNameList } from '../../../../utils/utils';
import Icon from '../../../general-ui/icon';
import { ParamsProps } from '../../live-event';
import { NavItem } from '../navigation/marketing-nav';

interface ICustomNavigationItemProps {
	customNavItems: CustomPage[],
	previewLanguage?: LanguagesAbbr;
	navItemActiveAccent: string;
	navItemColor: string;
	getCustomPagePath: (customPath: string) => string;
	forceIcon?: boolean;
	fullWidth?: boolean;
}

const getIcon = (icon: string, customChannelIcons: ChannelCustomizedIcon[] | undefined) => {
	const isBaseIcon = iconNameList().includes(icon);

	if (isBaseIcon) {
		return <Icon name={icon} color="" size={24} />;
	} else {
		const customIcon = customChannelIcons?.find(item => item.uuid === icon);

		if (customIcon) {
			return <img src={customIcon.url} alt={icon} />;
		}

		return icon;
	}
};

export default function CustomNavigationItem({
	customNavItems,
	previewLanguage,
	navItemActiveAccent,
	navItemColor,
	getCustomPagePath,
	forceIcon,
	fullWidth
}: ICustomNavigationItemProps): JSX.Element | null {
	const { t } = useTranslate(['homepage', 'home']);
	const { isLessThan1024 } = useScreenMediaQuery();
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const channelIcons = useTypedSelector(state => state.CreateEventReducer.channelIcons);
	const showNewNav = useIsNewNavigation();
	const { customPath, language: pathLanguage }: ParamsProps = useParams();

	const language = previewLanguage ? previewLanguage : pathLanguage;

	const navbarType = eventBundle?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;
	const shouldShowNavIcon = showNewNav && isTopNavbar;
	const customChannelIcons = eventBundle?.channel_event_icons || channelIcons;

	return (
		<>
			{
				customNavItems.map(page => {
					const customIcon = getIcon(page.icon, customChannelIcons);
					const navIcon = shouldShowNavIcon ? undefined : customIcon;

					let name = t(`home:nav.customPage.${page.custom_page}.page_name`, '')?.trim();
					if (!name) {
						name = t(`home:nav.customPage.${page.page_name.base}`, page.page_name?.[language] as string ?? page.page_name.base);
					}

					return (
						<NavItem
							key={page.uuid}
							path={getCustomPagePath(page.path)}
							isLessThan1024={isLessThan1024}
							icon={navIcon}
							name={name}
							navItemActiveAccent={navItemActiveAccent}
							active={customPath === page.path}
							isSessionsView={false}
							navItemColor={navItemColor}
							forceIcon={forceIcon}
							fullWidth={fullWidth}
						/>
					);
				})
			}
		</>
	);
}