import React, { useCallback, useEffect, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { v4 as uuidV4 } from 'uuid';
import { useParams } from "react-router";

import { togglePersonalizedAttendeeList, updateSessionFeedbackSurveyText, updateSessionInfo } from "../../../../../../store/actions/admin/create-event";
import {
	BreakoutRoom,
	CreateVideo,
	EBroadcastTypes,
	HomepageVideo,
	LanguagesAbbr,
	RegFieldsEnum,
	RegistrationStepType,
	Session,
	SessionTypesEnum
} from "../../../../../../types/working-model";
import { shouldDisableRecord } from "../../../../../../utils/utils";
import { OptionalComponent } from "../../../../../../utils/optional-component";
import EditSessionModal from "../../../session/edit-session-modal";
import VideoModal from "../../videos/video-modal";
import {
	setSessionCss,
	toggleReplayOn,
	updateModerationSettings,
	updateShouldRecord,
	updateWorkingSession,
	setSessionCompiledCss,
	setSessionUseSafeCss,
	toggleSessionShare,
	toggleSessionFeedbackSurvey,
	toggleAutoSendCalendarInviteOn,
	updatChatContentSettings
} from "../../../../../../store/actions/admin/create-event/session";
import CustomCSS from "../../../../../general-ui/edit-css/edit-css";
import BreakoutRoomSettings from "./breakout-room-settings";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { defaultRooms } from "../../../session/breakout-session-modal/breakout-chat-room-modals/breakout-chat-room-modals.config";
import { getChannelRecordings, getChannelUpVideos } from "../../../../../../store/actions/admin/videos";
import BreakoutRoomBackground from "./breakout-room-background";
import SessionTags from "./session-tags";
import { showAlert } from "../../../../../general-ui/alert/alert-service";
import { setGreenroomLoginError } from "../../../../../../store/actions/authentication";
import PanelBlock from "./panel-block";
import ToggleOption from "../../../../../general-ui/switch/toggle-option";
import PanelBlockBasicInfo from "./panel-block-basic-info";
import PanelBlockStreaming from "./panel-block-streaming";
import CalendarTextModal from "./calendar-text-modal";
import EditableTextSlim from "../../../../../general-ui/editable-text/editable-text-slim";
import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import { updateUserFeedbackSurveyTextChange } from "../../../../utils/utils";
import SessionInternalDescriptorModal from "./session-internal-descriptor-modal";
import { getMungedSecondaryVideo } from "../get-munged-secondary-video";

import "./session-settings.scss";

const SessionSettingsV2: React.FC = () => {
	const user = useTypedSelector(state => state.AuthReducer.user);
	const videos = useTypedSelector(state => state.VideosReducer.videos);
	const recordedVideos = useTypedSelector(state => state.VideosReducer.recordedVideos);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const greenroomLoginError = useTypedSelector(state => state.AuthReducer.greenroomLoginError);
	const secondaryVideo = useTypedSelector(state => state.CreateSessionReducer.secondarySessionVideo);

	const [modalOpen, setModalOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editingCss, setEditingCss] = useState(false);
	const [editingInternalDescriptor, setEditingInternalDescriptor] = useState(false);
	const [roomsForSettings, setRoomsForSettings] = useState<BreakoutRoom[]>([]);
	const [mungedSecondaryVideo, setMungedSecondaryVideo] = useState<HomepageVideo | CreateVideo | undefined>(undefined);
	const [secondaryVideoModalOpen, setSecondaryVideoModalOpen] = useState(false);
	const [fetchedChannelUpVideos, setFetchedChannelUpVideos] = useState(false);
	const [fetchedChannelRecordings, setFetchedChannelRecordings] = useState(false);
	const [showEditCalendarTextModal, setShowEditCalendarTextModal] = useState(false);

	const [copiedText, setCopiedText] = useState("");

	const { language }: { language: LanguagesAbbr } = useParams();
	const { t } = useTranslate('session');

	useEffect(() => {
		if (copiedText) setTimeout(() => setCopiedText(""), 2000);
	}, [copiedText]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!user || !token) return;

		if (!videos.length && !fetchedChannelUpVideos && user && token) {
			setFetchedChannelUpVideos(true);
			dispatch(getChannelUpVideos(token, user.active_channel));
		}

		const uploaded = videos.find(video => (
			video.source === secondaryVideo?.playback_url || video.hls_link === secondaryVideo?.playback_url
		));

		if (!recordedVideos.length && !fetchedChannelRecordings && token && user) {
			setFetchedChannelRecordings(true);
			dispatch(getChannelRecordings(token, user.active_channel));
		}

		const mungedVideo = getMungedSecondaryVideo(recordedVideos, secondaryVideo, uploaded);
		setMungedSecondaryVideo(mungedVideo);
	}, [
		videos,
		recordedVideos,
		secondaryVideo,
		user,
		dispatch,
		token,
		fetchedChannelUpVideos,
		fetchedChannelRecordings,
	]);

	useEffect(() => {
		const createRoomsForSettings = async () => {
			if (workingSession?.breakout_rooms?.filter(i => i)?.length) {
				setRoomsForSettings(workingSession?.breakout_rooms?.filter(i => i));
			} else {
				const newRoom = await defaultRooms(uuidV4());
				setRoomsForSettings(newRoom);
			}
		};
		createRoomsForSettings();
	}, [workingSession?.breakout_rooms]);


	useEffect(() => {
		if (greenroomLoginError && workingSession?.broadcast_type === EBroadcastTypes.greenroom) {
			showAlert({
				message: "Login Failed",
				description: greenroomLoginError,
				type: "error",
				afterClose: () => {
					dispatch(setGreenroomLoginError(false));
				}
			});
		}
	}, [dispatch, greenroomLoginError, workingSession?.broadcast_type]);

	const toggleModeration = useCallback((value: string, on: boolean) => {
		const settings = workingSession?.moderation ?? { chat: false, questions: false, posts: false };

		switch (value) {
			case "chatApprovalRequired": {
				dispatch(updateModerationSettings({ ...settings, chat: on }));
				break;
			}
			case "questionApprovalRequired": {
				dispatch(updateModerationSettings({ ...settings, questions: on }));
				break;
			}
		}
	}, [workingSession, dispatch]);

	const updateChatContenSettings = useCallback((value: string, on: boolean) => {
		const settings = workingSession?.chat_content_settings ?? { disable_images: false, disable_gifs: false, disable_emojis: false };

		switch (value) {
			case "images": {
				dispatch(updatChatContentSettings({ ...settings, disable_images: !on }));
				break;
			}
			case "gifs": {
				dispatch(updatChatContentSettings({ ...settings, disable_gifs: !on }));
				break;
			}
			case "emojis": {
				dispatch(updatChatContentSettings({ ...settings, disable_emojis: !on }));
				break;
			}
		}
	}, [workingSession, dispatch]);

	const toggleShouldRecord = useCallback((_: string, on: boolean) => {
		const isRecordToggleDisabled = shouldDisableRecord(workingSession);
		if (!isRecordToggleDisabled) {
			dispatch(updateShouldRecord(on));
		}
	}, [workingSession, dispatch]);


	const toggleReplay = (_: unknown, isOn: boolean) => {
		dispatch(toggleReplayOn(isOn));
	};

	const toggleAutoSendCalendarInvite = (_: unknown, isOn: boolean) => {
		dispatch(toggleAutoSendCalendarInviteOn(isOn));
	};

	const toggleShare = (_: unknown, isOn: boolean) => {
		dispatch(toggleSessionShare(isOn));
	};

	const toggleWatchlist = (_: unknown, isOn: boolean) => {
		dispatch(togglePersonalizedAttendeeList(isOn));
	};

	const toggleFeedback = (_: unknown, isOn: boolean) => {
		dispatch(toggleSessionFeedbackSurvey(isOn));
	};

	// to be re-enabled when the feature is ready
	// const toggleViewerCount = useCallback((_: string, on: boolean) => {
	// 	dispatch(toggleDisplayViewCount(on));
	// }, [dispatch]);

	const generalStep = workingEvent?.registration_steps?.find(step => step.type === RegistrationStepType.general);
	const hasEmailFieldEnabledInRegistration = generalStep?.questions?.find(question => question.registration_question === RegFieldsEnum.email);

	const editCustomCss = () => setEditingCss(true);
	const openEdit = useCallback(() => setIsEditing(true), []);
	const closeEdit = useCallback(() => setIsEditing(false), []);
	const closeVideoModal = useCallback(() => {
		setModalOpen(false);
		setSecondaryVideoModalOpen(false);
	}, []);

	const handleEdit = useCallback((session: Session) => {
		if (!token) return;

		batch(() => {
			if (!token) return;
			dispatch(updateSessionInfo(session, token));
			dispatch(updateWorkingSession(session));
		});
		setIsEditing(false);
	}, [dispatch, token]);

	const isRecordToggleDisabled = shouldDisableRecord(workingSession);

	const handleCustomCSS = useCallback(([css, compiled_scss]) => {
		batch(() => {
			dispatch(setSessionCss(css));
			dispatch(setSessionCompiledCss(compiled_scss));
		});
	}, [dispatch]);

	const handleToggleUseSafeCss = useCallback((_: unknown, on: boolean) => {
		dispatch(setSessionUseSafeCss(on));
	}, [dispatch]);

	const handleUserFeedbackSurveyTextChange = (value: string) => {
		if (workingEvent && token) {
			updateUserFeedbackSurveyTextChange(
				value,
				workingEvent,
				token,
				language,
				dispatch,
				updateSessionFeedbackSurveyText,
				t
			);
		}
	};

	const breakoutSession = workingSession?.breakout_session;

	return workingSession && (
		<>

			{/* BASIC INFO */}
			<PanelBlock title={"Basic info"} id={"settings-basic-info"} openEdit={openEdit}>
				<PanelBlockBasicInfo />
			</PanelBlock>

			{/* STREAMING */}
			{workingSession?.session_type !== SessionTypesEnum.breakoutRooms && (
				<PanelBlockStreaming />
			)}

			{/* <PanelBlock title={"Streaming"} id={"settings-streaming"} openEdit={editVideo}>

			</PanelBlock> */}

			{/* TAGS */}
			{/* <PanelBlock title={"Tags"} id={"settings-tags"}> */}
			<SessionTags session={workingSession} />
			{/* </PanelBlock> */}

			{/* SESSION OPTIONS */}
			<PanelBlock title={"Session Options"} id={"settings-session-options"}>
				<ToggleOption
					id='option-share'
					title='Share'
					tooltip='Allow attendees to easily share this session to social media'
					switchName='sessionShare'
					onSwitchToggle={toggleShare}
					toggleValue={!!workingSession?.enable_share}
				/>

				<ToggleOption
					id='option-watchlist'
					title='Add to Watchlist'
					tooltip='Allow attendees to add sessions to their watchlist'
					switchName='sessionWatchlist'
					onSwitchToggle={toggleWatchlist}
					toggleValue={!!workingEvent?.settings.personalizedAttendeeList}
				/>

				<ToggleOption
					id='option-automatic-replay'
					title='Automatic Replay'
					tooltip='Automatically play the recording after the session ends'
					switchName='sessionReplay'
					onSwitchToggle={toggleReplay}
					toggleValue={!!workingSession?.replay_on}
				/>

				{/* USER FEEDBACK */}
				<ToggleOption
					id='option-ratings'
					title='User Feedback Survey'
					tooltip='Allow attendees to give feedback on this session'
					switchName='sessionRatings'
					onSwitchToggle={toggleFeedback}
					toggleValue={!!workingSession?.enable_feedback_survey}
				>
					<OptionalComponent display={!!workingSession?.enable_feedback_survey && Number(workingEvent?.settings.translation_version) !== 2}>
						<EditableTextSlim
							tagType="p"
							value={workingEvent?.settings?.feedback_survey_text?.title?.[language] as string ?? t('userSurvey.ratingLabel')}
							onChange={handleUserFeedbackSurveyTextChange}
							hidePencil
							hideColors
						/>
					</OptionalComponent>
				</ToggleOption>

				<OptionalComponent display={workingSession?.session_type !== SessionTypesEnum.onDemand}>
					<ToggleOption
						id='send-calendar-invite'
						title='Send Calendar Invite'
						tooltip={!workingEvent?.registration_on || !hasEmailFieldEnabledInRegistration
							? 'Registration must be enabled with email to enable'
							: 'Include a calendar invite in the session invitation email'}
						switchName='calendarInvite'
						onSwitchToggle={toggleAutoSendCalendarInvite}
						toggleValue={!!workingSession?.calendar_settings?.auto_send_calendar_invite}
						disabled={!workingEvent?.registration_on || !hasEmailFieldEnabledInRegistration}
					/>
				</OptionalComponent>

				{/* 

				Commented out until the feature is ready
				
				<ToggleOption
					id='option-clips'
					title='Clips'
					tooltip='Allow attendees to create clips from the session.'
					switchName='sessionClips'
					onSwitchToggle={() => { }}
					toggleValue={false}
					disabled={true}
				/> */}

				{/* {workingSession?.session_type !== SessionTypesEnum.breakoutRooms &&

					Commented out until the feature is ready

					<ToggleOption
						id='option-viewer-count'
						title='Viewer Count'
						tooltip='Display viewer count publicly'
						switchName='displayViewerCount'
						onSwitchToggle={toggleViewerCount}
						toggleValue={workingSession?.layout?.display_viewer_count ?? false}
						disabled={true}
					/>
				} */}

				{workingSession.session_type === SessionTypesEnum.fireside && (
					<ToggleOption
						id='option-record-session'
						title='Record session'
						tooltip='Record the session and make it available for replay.'
						switchName='shouldRecord'
						onSwitchToggle={toggleShouldRecord}
						toggleValue={!!workingSession.should_record}
						disabled={isRecordToggleDisabled}
					/>
				)}
			</PanelBlock>

			{/* MODERATION */}
			<PanelBlock title={"Moderation"} id={"settings-moderation"}>
				<OptionalComponent display={workingSession?.session_type !== SessionTypesEnum.breakoutRooms}>
					<ToggleOption
						id='option-chat-approval'
						title='Moderate Session Chat'
						tooltip='Hide attendee session chat messages prior to moderator approval'
						switchName='chatApprovalRequired'
						onSwitchToggle={toggleModeration}
						toggleValue={!!workingSession.moderation && workingSession.moderation?.chat}
					/>
				</OptionalComponent>
				<ToggleOption
					id='option-question-approval'
					title='Moderate Q&A Responses'
					tooltip='Hide attendee questions prior to moderator approval'
					switchName='questionApprovalRequired'
					onSwitchToggle={toggleModeration}
					toggleValue={!!workingSession.moderation && workingSession.moderation?.questions}
				/>
				<ToggleOption
					id='allow-chat-images'
					title="Allow images"
					tooltip='Allow images in the chat'
					switchName='images'
					onSwitchToggle={updateChatContenSettings}
					toggleValue={workingSession?.chat_content_settings?.disable_images !== true}
				/>
				<ToggleOption
					id='allow-chat-gifs'
					title="Allow GIFs"
					tooltip='Allow GIFs in the chat'
					switchName='gifs'
					onSwitchToggle={updateChatContenSettings}
					toggleValue={workingSession?.chat_content_settings?.disable_gifs !== true}
				/>
				<ToggleOption
					id='allow-chat-emojis'
					title="Allow emojis"
					tooltip='Allow emojis in the chat'
					switchName='emojis'
					onSwitchToggle={updateChatContenSettings}
					toggleValue={workingSession?.chat_content_settings?.disable_emojis !== true}
				/>
			</PanelBlock>

			<PanelBlock title="Add to Calendar" id="add-to-calendar-settings" openEdit={() => setShowEditCalendarTextModal(true)}>
				<label>Customize the text content for attendees adding this session to their calendars</label>
			</PanelBlock>

			<PanelBlock title="Session Custom CSS" id="settings-session-custom-css" openEdit={editCustomCss}>
				<label>Add custom css to be applied to only this session</label>
			</PanelBlock>

			<PanelBlock title="Session Metadata" id="settings-session-internal-descriptor" openEdit={() => setEditingInternalDescriptor(true)}>
				<label>Add a searchable identifier for this session</label>
			</PanelBlock>

			<OptionalComponent display={workingSession?.session_type === SessionTypesEnum.breakoutRooms}>
				<>
					<BreakoutRoomSettings
						session={{
							rooms: roomsForSettings,
							hosts: workingSession?.breakout_session_hosts?.map((host: any) => host?.email)?.filter((i: any) => i) ?? [],
							create_room_when_full: !!breakoutSession?.create_room_when_full,
							use_zoom: breakoutSession?.use_zoom ?? true,
							use_google_meet: breakoutSession?.use_google_meet ?? true,
						}}
					/>
					{/* TODO: the data where the image gets stored in this component is wrong. This should be "chat" background */}
					{/* not banner background */}
					{!breakoutSession?.use_zoom && <BreakoutRoomBackground />}
				</>
			</OptionalComponent>

			<VideoModal
				open={modalOpen}
				onClose={closeVideoModal}
				isSecondaryVideo={secondaryVideoModalOpen}
				workingSecondaryVideo={mungedSecondaryVideo}
			/>

			<EditSessionModal
				isOpen={isEditing}
				onClose={closeEdit}
				startingSession={{ ...workingSession }}
				onSave={handleEdit}
				defaultLanguage={workingSession.default_language as LanguagesAbbr}
			/>

			<CalendarTextModal
				open={showEditCalendarTextModal}
				onClose={() => setShowEditCalendarTextModal(false)}
			/>

			<CustomCSS
				open={editingCss}
				onClose={() => setEditingCss(false)}
				onChange={handleCustomCSS}
				value={workingSession.custom_css ?? ""}
				useSafeCss={workingSession.use_safe_css}
				setUseSafeCss={handleToggleUseSafeCss}
			/>

			<SessionInternalDescriptorModal
				open={editingInternalDescriptor}
				onClose={() => setEditingInternalDescriptor(false)}
			/>
		</>
	);
};

export default SessionSettingsV2;
