import store from 'store/main';
import { FeatureFlagsEnum } from 'types/working-model';

// TODO: this is a temporary means to get the hostname for the metrics API
// using the feature flag. When the feature flag is deprecated this entire class
// can be removed and all accesses to the metrics API can be updated to use the
// env variable directly. Created Jan 23 2024 - if this is still here in 2025 it's
// time to deprecate this.

// this is not called from within a react component so we need to listen on 
// the redux store for changes to the feature flags manually
export class MetricsApi {
	isMetricsApiEnabled = false;
	idleCallback = 0;

	constructor() {
		// can't export a function that depends on the store being initialized
		// because the webpack exporter will panic if it tries to run before the store is initialized
		// so we have to delay the initialization of the class until the next tick
		setTimeout(() => {
			this.init();
		});
	}

	// get the initial state of the feature flags
	init = () => {
		const state = store.getState();
		const featureFlags = state.FeatureFlagsReducer.featureFlags;
		this.isMetricsApiEnabled = featureFlags[FeatureFlagsEnum.metrics_api] || false;
		this.idleCallback = 0;
		store.subscribe(this.handleReduxStateChange);
	};

	// listen for changes to the feature flags
	handleReduxStateChange = () => {
		// use an idle callback to make sure we don't overfire this in the middle of a render
		// redux event listeners will fire on every single dispatched action and this doesn't need 
		// to be that real time. Just wait for the CPU to settle before bothering with this update
		if (this.idleCallback) cancelIdleCallback(this.idleCallback);
		this.idleCallback = requestIdleCallback(() => {
			const state = store.getState();
			const featureFlags = state.FeatureFlagsReducer.featureFlags;
			this.isMetricsApiEnabled = featureFlags[FeatureFlagsEnum.metrics_api] || false;
		});
	};

	get hostname() {
		if (this.isMetricsApiEnabled) {
			return process.env.REACT_APP_METRICS_API_HOST || process.env.REACT_APP_API_HOST;
		} else {
			return process.env.REACT_APP_API_HOST;
		}
	}
}

export default new MetricsApi();