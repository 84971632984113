import { EmailTypeEnum, IInsertScheduledEmailRequest, IScheduledEmail, LanguagesAbbr } from "../types/working-model";
import { Delete, FetchError, Get, Post, Put, PutJson } from "./helpers";

export async function CreateScheduledEmail(token: string, email: IInsertScheduledEmailRequest) {
	return Post('/v3/admin/scheduled-emails', token, { scheduledEmail: email });
}

export async function EditScheduledEmail(token: string, email: IScheduledEmail) {
	const params = new URLSearchParams();
	if (localStorage.getItem('blp-force-fail') === 'true') {
		params.append('fail', '1');
	}
	return PutJson<{
		editScheduledEmail: IScheduledEmail
	}, IScheduledEmail>({ path: `/v3/admin/scheduled-emails?${params.toString()}`, token, data: { editScheduledEmail: email } });
}

export async function EditScheduledEmailWorkingContent(token: string, email: IScheduledEmail | IInsertScheduledEmailRequest) {
	return Put('/v3/admin/scheduled-emails/working-content', token, { editScheduledEmail: email }, true);
}

export async function DeleteScheduledEmail(token: string, scheduleUuid: IScheduledEmail['uuid']) {
	return Delete(`/v3/admin/scheduled-emails/${scheduleUuid}`, token, undefined, true);
}

export async function GetEventScheduledEmails(token: string, event_uuid: string) {
	return Get(`/v3/admin/scheduled-emails/event-emails/${event_uuid}`, token);
}

export async function GetChannelScheduledEmails(token: string, channel: number) {
	return Get(`/v3/admin/scheduled-emails/channel-emails/${channel}`, token);
}

export function GetEmailHTML(token: string, emailType: EmailTypeEnum, language: LanguagesAbbr, eventUUID: string, sessionUUID?: string): Promise<any> {
	return Get(`/v3/admin/email/${emailType}/${language}/${eventUUID}/${sessionUUID ? sessionUUID : ''}`, token);
}

export async function SendTestEmail(token: string, emailAddresses: string[], scheduledEmail: IScheduledEmail, eventUuid: string, channel: number) {
	return Post(`/v3/admin/scheduled-emails/test-email`, token, { emailAddresses, scheduledEmail, eventUuid, channel });
}