import { handle } from 'redux-pack';

import { AvailableInstance } from '../../../connection/integrations';
import { Action } from '../../../types/actions';
import { IntegratedEventsDB, IntegrationsMappedDB, ITraySolution, ITraySolutionInstance, ITraySolutionMapped, ITrayUser } from '../../../types/integrations';
import { ISSOIntegration, Oauth2Integration, StripeStatus } from "../../../types/working-model";
import {
	CLEAR_INSTANCE_TO_CREATE,
	CREATE_TRAY_INSTANCE,
	DISABLE_GOOGLE_ANALYTICS,
	GET_GOOGLE_ANALYTICS,
	GET_TRAY_SOLUTIONS,
	GET_TRAY_USER,
	GET_USER_SOLUTION_INSTANCES,
	INTEGRATIONS_STRIPE_CONTACT,
	INTEGRATIONS_STRIPE_STATUS,
	SET_GOOGLE_ANALYTICS,
	TOGGLE_TRAY_INSTANCE,
	GET_SSO_INTEGRATIONS,
	GET_OAUTH2_INTEGRATION,
	UPDATE_OAUTH2_INTEGRATION,
	TOGGLE_OAUTH2_INTEGRATION,
	TOGGLE_OAUTH2_ADMIN_USE,
	DELETE_OAUTH2_INTEGRATION,
	GET_PUBLIC_OAUTH2_INTEGRATION,
	CREATE_OAUTH2_INTEGRATION,
	GET_EVENT_INSTANCES,
	GET_CHANNEL_MAPPED_INSTANCES,
	CREATE_TRAY_INSTANCE_MAPPED,
	TOGGLE_CUSTOM_MAPPED_INSTANCE,
	TOGGLE_EVENT_OVERRIDES_INSTANCE
} from '../../actions/admin/integrations';

export interface IntegrationsState {
	stripe: {
		loading: boolean
		status: StripeStatus,
		contact: string | null,
		setting_contact: boolean
	},
	googleAnalytics: {
		id: number;
		google_id: string;
		channel: number;
		status: number;
	} | null;
	loadingGoogleAnalytics: boolean;
	creatingIntegrationsId: boolean;
	trayInstances: ITraySolutionInstance[];
	loadingInstances: boolean;
	traySolutions: ITraySolution[];
	loadingSolutions: boolean;
	solutionsError: boolean;
	loadingTrayUser: boolean;
	trayUser: ITrayUser | null;
	instanceToCreate: ITraySolutionInstance | null;
	customSSOs?: ISSOIntegration[];
	oauthIntegration?: Oauth2Integration;
	publicOauthIntegration?: Partial<Oauth2Integration>;
	loadingOauthIntegration: boolean;
	eventInstances: AvailableInstance[];
	loadingEventInstances: boolean;
	instancesMapped: IntegrationsMappedDB[];
	loadingInstancesMapped: boolean;
	failedInstancesMapped: boolean;
	creatingMappedInstance: boolean;
	failedCreateMappedInstance: boolean;
	traySolutionsMapped: Record<string, ITraySolutionMapped>;
}

const initialState: IntegrationsState = {
	creatingIntegrationsId: false,
	creatingMappedInstance: false,
	eventInstances: [],
	failedCreateMappedInstance: false,
	failedInstancesMapped: false,
	googleAnalytics: null,
	instancesMapped: [],
	instanceToCreate: null,
	loadingEventInstances: false,
	loadingGoogleAnalytics: false,
	loadingInstances: false,
	loadingInstancesMapped: false,
	loadingOauthIntegration: false,
	loadingSolutions: false,
	loadingTrayUser: false,
	solutionsError: false,
	stripe: {
		loading: false,
		status: StripeStatus.none,
		contact: null,
		setting_contact: false
	},
	trayInstances: [],
	traySolutions: [],
	traySolutionsMapped: {},
	trayUser: null
};

export default function IntegrationsReducer(state: IntegrationsState = initialState, action: Action): IntegrationsState {
	switch (action.type) {
		case INTEGRATIONS_STRIPE_STATUS: {
			return handle(state, action, {
				start: state => ({ ...state, stripe: { ...state.stripe, loading: true } }),
				success: state => ({
					...state,
					stripe: {
						...state.stripe,
						status: action.payload.stripe_status,
						contact: action.payload.primary_contact
					}
				}),
				finish: state => ({
					...state,
					stripe: {
						...state.stripe,
						loading: false
					}
				})
			});
		}
		case INTEGRATIONS_STRIPE_CONTACT: {
			return handle(state, action, {
				start: state => ({
					...state,
					stripe: {
						...state.stripe,
						setting_contact: true
					}
				}),
				success: state => ({
					...state,
					stripe: {
						...state.stripe,
						contact: action.payload.email
					}
				}),
				finish: state => ({
					...state,
					stripe: {
						...state.stripe,
						setting_contact: false
					}
				})
			});
		}
		case GET_GOOGLE_ANALYTICS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingGoogleAnalytics: true }),
				finish: state => ({ ...state, loadingGoogleAnalytics: false }),
				success: state => {
					if (action.payload.not_found) {
						return ({ ...state, googleAnalytics: null });
					} else {
						return ({ ...state, googleAnalytics: action.payload });
					}
				}
			});
		}
		case SET_GOOGLE_ANALYTICS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingGoogleAnalytics: true }),
				finish: state => ({ ...state, loadingGoogleAnalytics: false }),
				success: state => ({ ...state, googleAnalytics: action.payload })
			});
		}
		case DISABLE_GOOGLE_ANALYTICS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingGoogleAnalytics: true }),
				finish: state => ({ ...state, loadingGoogleAnalytics: false }),
				success: state => ({ ...state, googleAnalytics: null })
			});
		}
		case GET_USER_SOLUTION_INSTANCES: {
			return handle(state, action, {
				start: state => ({ ...state, loadingInstances: true }),
				finish: state => ({ ...state, loadingInstances: false }),
				success: state => ({ ...state, trayInstances: action.payload })
			});
		}
		case GET_TRAY_SOLUTIONS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingSolutions: true }),
				finish: state => ({ ...state, loadingSolutions: false }),
				failure: state => ({ ...state, solutionsError: true }),
				success: (state) => {
					return {
						...state,
						traySolutions: action.payload.solutions,
						traySolutionsMapped: action.payload.mappedSolutions,
						solutionsError: false
					};
				}
			});
		}
		case CREATE_TRAY_INSTANCE: {
			return handle(state, action, {
				start: state => ({ ...state, loadingInstances: true }),
				finish: state => ({ ...state, loadingInstances: false }),
				success: state => ({
					...state,
					trayInstances: [...state.trayInstances, action.payload.instance],
					trayUser: action.payload.user,
					instanceToCreate: action.payload.instance
				})
			});
		}
		case TOGGLE_TRAY_INSTANCE: {
			const updatedInstance = action.payload;
			// update matching instance enabled value with new value returned from update call
			return handle(state, action, {
				success: state => ({
					...state,
					trayInstances: state.trayInstances.map((instance: ITraySolutionInstance) => {
						if (instance.id === updatedInstance.id) {
							return {
								...instance,
								enabled: updatedInstance.enabled
							};
						} else {
							return instance;
						}
					})
				})
			});
		}
		case TOGGLE_CUSTOM_MAPPED_INSTANCE: {
			if (!action.payload) return state;
			const updatedInstance: IntegrationsMappedDB = action.payload;
			return handle(state, action, {
				success: state => ({
					...state,
					instancesMapped: state.instancesMapped.map((instance: IntegrationsMappedDB) => {
						if (instance.instance_uuid === updatedInstance.instance_uuid) {
							return updatedInstance;
						}
						return instance;
					})
				})
			});
		}
		case TOGGLE_EVENT_OVERRIDES_INSTANCE: {
			if (!action.payload) return state;
			return handle(state, action, {
				success: state => {
					const { added, removed }: { added?: IntegratedEventsDB[], removed?: IntegratedEventsDB[] } = action.payload;
					if (added?.length) {
						const newInstances = state.eventInstances.map(instance => {
							if (instance.instance_uuid === added[0].instance_uuid) {
								return {
									...instance,
									integrations_event: added[0]
								};
							}
							return instance;
						});
						return {
							...state,
							eventInstances: newInstances
						};
					}
					if (removed?.length) {
						const newInstances = state.eventInstances.map(instance => {
							if (instance.instance_uuid === removed[0].instance_uuid) {
								return {
									...instance,
									integrations_event: undefined
								};
							}
							return instance;
						});
						return {
							...state,
							eventInstances: newInstances
						};
					}
					return state;
				}
			});
		}

		case GET_TRAY_USER: {
			return handle(state, action, {
				start: state => ({ ...state, loadingTrayUser: true }),
				finish: state => ({ ...state, loadingTrayUser: false }),
				success: state => ({ ...state, trayUser: action.payload })
			});
		}
		case CLEAR_INSTANCE_TO_CREATE: {
			return { ...state, instanceToCreate: null };
		}
		case GET_SSO_INTEGRATIONS: {
			return handle(state, action, {
				success: state => ({
					...state,
					customSSOs: action.payload
				})
			});
		}
		case GET_OAUTH2_INTEGRATION: {
			return handle(state, action, {
				start: state => ({ ...state, loadingOauthIntegration: true }),
				success: state => ({ ...state, oauthIntegration: action.payload }),
				finish: state => ({ ...state, loadingOauthIntegration: false })
			});
		}
		case GET_PUBLIC_OAUTH2_INTEGRATION: {
			return handle(state, action, {
				start: state => ({ ...state, loadingOauthIntegration: true }),
				success: state => ({ ...state, publicOauthIntegration: action.payload }),
				finish: state => ({ ...state, loadingOauthIntegration: false })
			});
		}
		case CREATE_OAUTH2_INTEGRATION:
		case UPDATE_OAUTH2_INTEGRATION: {
			return handle(state, action, {
				success: state => ({ ...state, oauthIntegration: action.payload })
			});
		}
		case TOGGLE_OAUTH2_INTEGRATION: {
			return handle(state, action, {
				success: state => {
					if (state.oauthIntegration) {
						return ({ ...state, oauthIntegration: { ...state.oauthIntegration, active: !state.oauthIntegration.active } });
					} else {
						return state;
					}
				}
			});
		}

		case TOGGLE_OAUTH2_ADMIN_USE: {
			return handle(state, action, {
				success: state => {
					if (state.oauthIntegration) {
						return ({ ...state, oauthIntegration: { ...state.oauthIntegration, admin_login: !state.oauthIntegration.admin_login } });
					} else {
						return state;
					}
				}
			});
		}

		case DELETE_OAUTH2_INTEGRATION: {
			return handle(state, action, {
				success: state => ({ ...state, oauthIntegration: undefined })
			});
		}
		case GET_EVENT_INSTANCES:
			return handle(state, action, {
				start: state => ({ ...state, loadingEventInstances: true }),
				finish: state => ({ ...state, loadingEventInstances: false }),
				success: state => {
					return {
						...state,
						eventInstances: action.payload.all_instances
					};
				}
			});
		case GET_CHANNEL_MAPPED_INSTANCES: {
			return handle(state, action, {
				start: state => ({ ...state, loadingInstancesMapped: true }),
				success: state => ({ ...state, instancesMapped: action.payload, failedInstancesMapped: false }),
				finish: state => ({ ...state, loadingInstancesMapped: false }),
				failure: state => ({ ...state, failedInstancesMapped: true })
			});
		}
		case CREATE_TRAY_INSTANCE_MAPPED: {
			return handle(state, action, {
				start: state => ({ ...state, creatingMappedInstance: true }),
				success: state => ({ ...state, instancesMapped: [...state.instancesMapped, action.payload.instance], failedCreateMappedInstance: false }),
				finish: state => ({ ...state, creatingMappedInstance: false }),
				failure: state => ({ ...state, failedCreateMappedInstance: true })
			});
		}
		default: return state;
	}
}
