import { CustomReaction } from "../types/working-model";
import { Get, PostJson } from "./helpers";

export function GetCustomReactions(
	token: string,
	channel: number
): Promise<CustomReaction[]> {
	return Get(`/v3/admin/channel/reactions/custom/${channel}`, token);
}

export function InsertCustomReaction(
	token: string,
	channel: number,
	name: string,
	url: string
): Promise<CustomReaction> {
	return PostJson<unknown, CustomReaction>({
		path: '/v3/admin/channel/reactions/custom',
		token,
		data: {
			channel,
			name,
			url
		}
	});
}
