import { useRef, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { FetchError } from "../../../../../../../connection/helpers";
import { GetQuestionResponses, SearchResults } from "../../../../../../../connection/sessions-panel/search";
import { ESort } from "../../../../../../../connection/sessions-panel/types";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { LanguagesAbbr, PaginatedQuestion } from "../../../../../../../types/working-model";
import { prependQuestions, QuestionsContext, resetQuestions, setLoadingQuestions, setQuestions } from "../../panel/contexts/engage/questions-state";
import { usePageModule } from "../../panel/hooks/panel.hooks";
import { QFilters } from "../question-prompts/question-prompt-view";

export const useQuestionPrompts = (onError: (err: string) => void) => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const channels = useTypedSelector(state => state.AuthReducer.channels);
	const { language } = useParams<{ language: LanguagesAbbr }>();
	const page = useRef(1);
	const searchTerm = useRef<string | undefined>();
	const filter = useRef<QFilters | undefined>(QFilters.all);
	const onErrorRef = useRef<(err: string) => void>(onError);
	const userActiveChannel = user?.active_channel;
	const emptyResult = useRef<SearchResults<PaginatedQuestion>>({ total: null, results: null });
	const lastResults = useRef<SearchResults<PaginatedQuestion>>(emptyResult.current);
	const resetRef = useRef<(reload?: boolean) => void>();
	const pageModule = usePageModule();
	const [, dispatch] = useContext(QuestionsContext);

	useEffect(() => {
		onErrorRef.current = onError;
	}, [onError]);

	const module_id = pageModule?.id;

	useEffect(() => {
		dispatch(resetQuestions(module_id));
	}, [module_id, dispatch]);

	const loadFuncs = useMemo(() => {
		const load = async () => {
			if (!token || !userActiveChannel || !module_id) return emptyResult.current;
			const channelUuid = channels.find(c => c.channel === userActiveChannel)?.uuid;
			if (!channelUuid) return emptyResult.current;

			try {
				dispatch(setLoadingQuestions(true));

				const res = await GetQuestionResponses(token, {
					channelUuid,
					language,
					offset: (page.current - 1) * 10,
					limit: 10,
					search: searchTerm.current,
					filter: filter.current,
					module_id
				});
				lastResults.current = res as SearchResults<PaginatedQuestion>;
				if (page.current === 1) {
					dispatch(setQuestions(res.results ?? []));
				} else {
					dispatch(prependQuestions(res.results ?? []));
				}
			} catch (e) {
				console.error(e);

				if (e instanceof FetchError) {
					onErrorRef.current(e.error);
				} else {
					onErrorRef.current("Error loading content");
				}
			}
		};

		const clear = async (reload?: boolean) => {
			page.current = 1;
			searchTerm.current = undefined;
			if (reload) {
				return await load();
			}
		};

		const setFilter = async (userFilter?: QFilters) => {
			if (userFilter === filter.current) {
				return lastResults.current;
			}

			filter.current = userFilter;
			page.current = 1;
			return await load();
		};

		const next = async () => {
			page.current++;
			return await load();
		};

		const search = async (search: string) => {
			if (search === searchTerm.current) {
				return lastResults.current;
			}

			page.current = 1;
			searchTerm.current = search;
			return await load();
		};

		resetRef.current = clear;

		return {
			load,
			clear,
			setFilter,
			next,
			search,

		};
	}, [token, userActiveChannel, module_id, channels, dispatch, language]);

	return loadFuncs;
};