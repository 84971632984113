import { IMessage } from '../../../components/general-ui/message-card/message-card';
import { DEFAULT_MESSAGES } from '../../../components/moderation/use-messages';
import { Action, AsyncAction } from '../../../types/actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchMessages = (_token: string) => Promise.resolve(DEFAULT_MESSAGES);
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export function loadMessages(token: string): AsyncAction {
	return {
		type: LOAD_MESSAGES,
		promise: fetchMessages(token),
	};
}

export const ADD_MESSAGE = 'ADD_MESSAGE';
export function addMessage(message: IMessage): Action {
	return {
		type: ADD_MESSAGE,
		payload: message,
	};
}

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export function updateMessage(document: IMessage): Action {
	return {
		type: UPDATE_MESSAGE,
		payload: document,
	};
}
