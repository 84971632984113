import { Action } from "../../../types/actions";
import { Subscriber } from '../../../utils/opentok';

export interface IMediaDevice {
	name: string;
	id: string;
}

export interface IVideoProviderInitParams {
	sessionId: string;
	token: string;
	isFiresides?: boolean;
}

export interface IVideoProviderInitParamsFull extends IVideoProviderInitParams {
	name?: string;
	onVideoCreated?: (event: any) => void;
	onVideoDestroyed?: (event: any) => void;
	onVideoConnect?: (subscribers: Subscriber[]) => void;
	onVideoDisconnect?: (subscribers: Subscriber[]) => void;
	onInit?: (videoProvider: any) => void;
}

export const SET_CAMERA_ACTIVE = 'SET_CAMERA_ACTIVE';
export const setCameraActive = (active: boolean): Action => ({
	type: SET_CAMERA_ACTIVE,
	payload: active,
});

export const SET_CAMERA_DEVICE_ID = 'SET_CAMERA_DEVICE_ID';
export const setCameraDeviceId = (deviceId: string): Action => ({
	type: SET_CAMERA_DEVICE_ID,
	payload: deviceId,
});

export const SET_MICROPHONE_ACTIVE = 'SET_MICROPHONE_ACTIVE';
export const setMicrophoneActive = (active: boolean): Action => ({
	type: SET_MICROPHONE_ACTIVE,
	payload: active,
});

export const SET_MICROPHONE_DEVICE_ID = 'SET_MICROPHONE_DEVICE_ID';
export const setMicrophoneDeviceId = (deviceId: string): Action => ({
	type: SET_MICROPHONE_DEVICE_ID,
	payload: deviceId,
});

export const SET_SPEAKER_DEVICE_ID = 'SET_SPEAKER_DEVICE_ID';
export const setSpeakerDeviceId = (deviceId: string): Action => ({
	type: SET_SPEAKER_DEVICE_ID,
	payload: deviceId,
});

export const SET_SCREEN_SHARE_ACTIVE = 'SET_SCREEN_SHARE_ACTIVE';
export const setScreenShareActive = (active: boolean): Action => ({
	type: SET_SCREEN_SHARE_ACTIVE,
	payload: active,
});

export const SET_VIDEO_PROVIDER_INIT_PARAMS = 'SET_VIDEO_PROVIDER_INIT_PARAMS';
export const setVideoProviderInitParams = (videoProviderInitParams: IVideoProviderInitParams): Action => ({
	type: SET_VIDEO_PROVIDER_INIT_PARAMS,
	payload: videoProviderInitParams,
});