import classNames from 'classnames';
import { AnimatePresence, LazyMotion, domAnimation, m } from 'framer-motion';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';

import '../../../scss/live-event/base/general-ui/new-dropdown.scss';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { OptionalComponent } from '../../../utils/optional-component';

interface NewDropdownProps {
	children: any;
	renderTitle: (props: { isOpen: boolean }) => JSX.Element | string;
	className?: string;
	popupClassName?: string;
	valueClassName?: string;
	edge?: 'left' | 'right';
	showArrow?: boolean;
	template?: string;
	isNoScroll?: boolean;
	animateDropdown?: boolean;
	useArrowOutline?: boolean;
	limitHeight?: boolean;
	maxHeight?: string;
	onToggle?: (isOpen: boolean) => void;
	style?: CSSProperties
	ignoreNavigation?: boolean;
}
export default function NewDropdown({
	children,
	renderTitle,
	className,
	valueClassName,
	popupClassName,
	edge = 'left',
	showArrow = true,
	template,
	isNoScroll = true,
	animateDropdown = false,
	useArrowOutline = false,
	limitHeight = false,
	maxHeight = '50vh',
	onToggle = () => null,
	style,
	ignoreNavigation = false
}: NewDropdownProps): JSX.Element {
	const [isOpen, setOpen] = useState(false);
	const self = useRef<HTMLButtonElement>(null);
	const isNavigationOpen = useTypedSelector(state => state.ContentReducer.isNavigationOpen);

	// on escape, close dropdown
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.code === 'Escape' || event.key === 'Escape') {
				setOpen(false);
				onToggle?.(false);
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [onToggle]);

	const handleClose = useCallback((e: any) => {
		const path = e.path || e.composedPath();
		for (const item of path) {
			if (self.current === item) {
				return;
			}
		}

		setOpen(false);
		onToggle?.(false);
	}, [onToggle]);

	useEffect(() => {
		if (isOpen) {
			isNoScroll && document.body.classList.add('modal-open');
			window.addEventListener('click', handleClose); // using mouseup will prevent any children from being clickable
		}
		return () => {
			isNoScroll && document.body.classList.remove('modal-open');
			window.removeEventListener('click', handleClose); // using mouseup will prevent any children from being clickable
		};
	}, [isOpen, isNoScroll, handleClose]);

	useEffect(() => {
		if (!ignoreNavigation) {
			setOpen(isNavigationOpen);
		}
	}, [isNavigationOpen, ignoreNavigation]);

	const handleClick = () => {
		const updated = !isOpen;
		setOpen(updated);
		onToggle?.(updated);
	};

	return (
		<div
			id="new-dropdown"
			className={classNames('new-dropdown', className, template, {
				open: isOpen,
			})}
		>
			<button
				onClick={handleClick}
				className={classNames('new-dropdown-value-container', valueClassName)}
				ref={self}
				style={style}
			>
				<span className="new-dropdown-value">{renderTitle({ isOpen })}</span>
				{showArrow && <span className={classNames("new-dropdown-icon", { ["arrow-outline"]: useArrowOutline })}></span>}
			</button>
			<OptionalComponent display={isOpen}>
				{animateDropdown ? (
					<>
						<div className="new-dropdown-panel-background" />
						<AnimatePresence>
							<LazyMotion features={domAnimation}>
								<m.div
									initial={{ translateY: -100, opacity: 0 }}
									animate={{ translateY: 0, opacity: 1 }}
									exit={{ translateY: -100, opacity: 0 }}
									style={{
										zIndex: 999,
										...(limitHeight ? { maxHeight } : {})
									}}
									className={classNames('new-dropdown-panel', popupClassName, edge, { 'limit-height': limitHeight })}
								>
									{children}
								</m.div>
							</LazyMotion>
						</AnimatePresence>
					</>
				) : (
					<>
						<div className="new-dropdown-panel-background" />
						<div
							className={classNames('new-dropdown-panel', popupClassName, edge, { 'limit-height': limitHeight })}
							style={{ ...(limitHeight ? { maxHeight } : {}) }}
						>
							{children}
						</div>
					</>
				)}
			</OptionalComponent>
		</div>
	);
}
