import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';

import './scroll-overlay.scss';
import classNames from 'classnames';

interface IScrollOverlayProps {
	display: boolean;
	className?: string;
	isEditor?: boolean;
}

const ScrollOverlay = (props: IScrollOverlayProps) => {
	const {
		display,
		className = '',
		isEditor = false,
	} = props;

	if (isEditor) return null;
	return (
		<AnimatePresence>
			{display && (
				<LazyMotion features={domAnimation}>
					<m.div
						className={classNames("panel-scroll-overlay", className)}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
					</m.div>
				</LazyMotion>
			)}
		</AnimatePresence>
	);
};

export default ScrollOverlay;
