import { datadogLogs, LogsEvent } from '@datadog/browser-logs';

if (process.env.REACT_APP_STAGE) {
	datadogLogs.init({
		clientToken: 'pubba6358a62ff2141a5ad133911c4d5251',
		forwardErrorsToLogs: true,
		sampleRate: 100,
		service: process.env.REACT_APP_STAGE + '-events-3-client',
		useSecureSessionCookie: true,
		beforeSend: (event: LogsEvent) => {
			if (
				event.message.includes('.m3u8') ||
				event.message.includes('ResizeObserver')
			) {
				return false;
			}
		}
	});
}