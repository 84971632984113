import { useEffect, useMemo, useState } from "react";

import { ContentEditors, NavMainItems, PageModule, PageModuleType, SessionTypesEnum } from "../../../../../../../types/working-model";
import ModalComponent from "../../../../../../general-ui/modal/modal";
import SelectModuleLayout from "./select-module-layout";
// import TabHeader from "../../../../../../general-ui/tab-header/tab-header";
import { AllLayoutTypes, defaultModuleContentTextAlign, maxCount, TemplateClassNames } from "../../../../../../../types/template-layouts";
import AdvancedLayoutSettings from './advanced-settings';
import { moduleLayoutUpdate, updateWorkingEvent } from "../../../../../../../store/actions/admin/create-event";
import { IEditableContent } from "../../../../session/editor/moduleControls/controls";
import { useAppDispatch, useThunkDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import './edit-module-layout-modal.scss';
import { getCurrentCustomPage } from "../../../../../../../utils/admin-routing-utils";
import { replaceTextAlignAttribute } from "../../../../../../general-ui/editable-text/replace-text-align-attribute";
import allLanguages from '../../../../../../../utils/translate-langs.json';
import { LanguageOption } from "../../../editor/agenda-editor/select-languages";

interface Props {
	open: boolean;
	pageModuleType?: PageModuleType | null;
	setOpenLayoutModal: React.Dispatch<React.SetStateAction<PageModuleType | null>>;
	selectedModule: PageModule["id"] | null;
	title: string;
	editModule?: IEditableContent;
	page?: NavMainItems;
}

enum ETabOptions {
	layout = 'Layout',
	content = 'Content',
	animation = 'Animation',
	link = 'Link'
}

const EditLayoutModal: React.FC<Props> = ({
	open,
	setOpenLayoutModal,
	selectedModule,
	title: sectionName,
	pageModuleType,
	editModule,
	page,
}) => {
	const currentPage = useTypedSelector(event => event.CreateEventReducer.currentPostRegisterPage);
	const workingEvent = useTypedSelector(event => event.CreateEventReducer.workingEvent);
	const token = useTypedSelector(event => event.AuthReducer.token);

	const dispatch = useAppDispatch();
	const thunkDispatch = useThunkDispatch();

	const [selectedLayout, setSelectedLayout] = useState<AllLayoutTypes | null>(null);
	const [activeTab, setActiveTab] = useState(ETabOptions.layout);
	const [hasAdvancedSettings, setHasAdvancedSettings] = useState(false);
	const [selectedDisplayOptions, setSelectedDisplayOptions] = useState<null | number>(null);
	const [speakerImageRadius, setSpeakerImageRadius] = useState<boolean | null>(null);

	const handleClose = () => {
		setOpenLayoutModal(null);
		setSelectedDisplayOptions(null);
		setSpeakerImageRadius(null);
		setActiveTab(ETabOptions.layout);
		setHasAdvancedSettings(false);
	};

	const hasDisplayOptions = useMemo(() => {
		return !!(pageModuleType && [PageModuleType.products, PageModuleType.speakers].includes(pageModuleType));
	}, [pageModuleType]);

	const shouldUseImageRadius = useMemo(() => {
		return !!(pageModuleType && pageModuleType === PageModuleType.speakers);
	}, [pageModuleType]);

	const currentCustomPage = useMemo(
		() => getCurrentCustomPage(workingEvent?.custom_pages, currentPage),
		[workingEvent?.custom_pages, currentPage]
	);

	useEffect(() => {
		if (activeTab === ETabOptions.layout && pageModuleType === PageModuleType.main_event_banner) {
			const isBroadcastEvent = workingEvent?.sessions.some(session => session.session_type === SessionTypesEnum.broadcast);
			// if this event has no broadcast times dont show the advanced settings for main event banner as they only toggle the countdown on/off
			if (!isBroadcastEvent) {
				setHasAdvancedSettings(false);
			}
		}
	}, [activeTab, pageModuleType, workingEvent?.sessions]);

	const moduleEdits = useMemo(() => {
		switch (activeTab) {
			case ETabOptions.layout: {
				return (
					<>
						<SelectModuleLayout
							selectedModule={selectedModule}
							selectedLayout={selectedLayout}
							setSelectedLayout={setSelectedLayout}
							page={page}
							speakerImageRadius={speakerImageRadius}
						/>
					</>
				);
			}
			// case ETabOptions.content: {
			// 	return editModule?.jsx;
			// }
			case ETabOptions.link: {
				return editModule?.jsx;
			}
		}
	}, [activeTab, selectedModule, selectedLayout, page, speakerImageRadius, editModule?.jsx]);

	// FUTURE DEV: add back in when implementing animations
	// each module can have different editable content from here
	// const moduleEditTabs = useMemo(() => {
	// 	switch (pageModuleType) {
	// 		case PageModuleType.products:
	// 			setHasAdvancedSettings(true);
	// 			return [ETabOptions.layout];
	// 		case PageModuleType.main_event_banner:
	// 			setHasAdvancedSettings(true);
	// 			return [ETabOptions.layout];
	// 		case PageModuleType.callout:
	// 			return [ETabOptions.layout, ETabOptions.link];
	// 		case PageModuleType.agenda:
	// 			setHasAdvancedSettings(true);
	// 			return [ETabOptions.layout];
	// 		default:
	// 			return [ETabOptions.layout];
	// 	}
	// }, [pageModuleType]);

	// FUTURE DEV: wont need this block when uncommenting out the above switch statement
	useEffect(() => {
		switch (pageModuleType) {
			case PageModuleType.speakers:
			case PageModuleType.agenda:
			case PageModuleType.main_event_banner:
				setHasAdvancedSettings(true);
				break;
		}
	}, [pageModuleType]);

	async function handleDone() {
		if (!workingEvent || !workingEvent?.homepage) { return; }

		// transform descriptions to proper text alignment
		const templateName = workingEvent?.template?.name;
		const template = TemplateClassNames[templateName];
		const isQuill = workingEvent?.settings?.content_editor === ContentEditors.quill;

		const updateModules = (module: PageModule) => {
			if (module.id === selectedModule) {
				const updatedDescriptions: Record<string, string> = { ...(module.content?.description ?? {}) };
				const layout = selectedLayout ?? module.content?.layout_type ?? 'standard';

				// get the display option counts (for speakers and products modules)
				const displayOption = selectedDisplayOptions ?? module.content.display_options ?? maxCount(module.type, layout);
				const updatedDisplayOptions = hasDisplayOptions ? { display_options: displayOption } : {};

				const imageRadius = speakerImageRadius ?? !!module.content.speaker_image_radius;
				const updateImageRadius = shouldUseImageRadius ? { speaker_image_radius: imageRadius } : {};

				// the callout module uses the description field for the small bubble text above the title text - it is not rendered with raw HTML
				// so realigning it and then publishing causes the text to be rendered as raw HTML with visible tags
				const shouldRealign = module.type !== PageModuleType.callout;

				if (shouldRealign && module.content?.description && layout && isQuill) {
					// get the direction the text-align value should be for this newly selected layout
					const direction = defaultModuleContentTextAlign?.[template]?.[module.type]?.[layout]?.description || 'left' as string;

					const allLangsMap: Record<string, LanguageOption> = {};
					allLanguages.forEach(lang => {
						allLangsMap[lang.code] = lang;
					});

					Object.entries(module.content.description).forEach(([key, val]) => {
						if ((allLangsMap[key] || key === 'base') && typeof val === 'string') {
							// update the html with the new text align
							const updatedAlignment = replaceTextAlignAttribute({ newValue: direction, htmlString: val });
							updatedDescriptions[key] = updatedAlignment;
						}
					});
				}

				return {
					...module,
					content: {
						...module.content,
						...(module?.content?.description && isQuill ? { description: updatedDescriptions } : {}),
						layout_type: layout,
						...updatedDisplayOptions,
						...updateImageRadius
					},
				};
			}
			return module;
		};

		let _workingEvent = { ...workingEvent };
		if (currentPage === NavMainItems.Home || page === NavMainItems.Sessions) {
			if (_workingEvent.homepage) {
				_workingEvent = {
					..._workingEvent,
					homepage: {
						..._workingEvent.homepage,
						post_register_home_modules: workingEvent?.homepage?.post_register_home_modules?.map(updateModules)
					}
				};
			}
		} else if (currentPage) {
			// update customPage modules based on currentPage
			const updatedCustomPages = _workingEvent.custom_pages?.map(page => {
				if (page.custom_page !== currentCustomPage?.custom_page) return page;
				return {
					...page,
					modules: page?.modules?.map(updateModules)
				};
			});
			_workingEvent = {
				..._workingEvent,
				custom_pages: updatedCustomPages
			};
		} else {
			if (_workingEvent.homepage) {
				_workingEvent = {
					..._workingEvent,
					homepage: {
						..._workingEvent.homepage,
						modules: workingEvent?.homepage?.modules?.map(updateModules)
					}
				};
			}
		}

		try {
			// this await does work - because we're using an AsyncAction, therefore it's necessary
			// so we can wait for the api call to finish and then try and update our module layout defaults
			if (token) {
				thunkDispatch(updateWorkingEvent(_workingEvent, token));
			}
			dispatch(moduleLayoutUpdate());
		} catch (e) {
			console.error(e);
		}
		setSelectedDisplayOptions(null);
		setSpeakerImageRadius(null);
		setOpenLayoutModal(null);
		setActiveTab(ETabOptions.layout);
	}

	function handleDoneEditModule() {
		if (editModule?.onDone) {
			editModule.onDone();
			setOpenLayoutModal(null);
			setSelectedDisplayOptions(null);
			setSpeakerImageRadius(null);
		}
	}

	const renderAdvancedSettings = () => {
		if (activeTab === ETabOptions.layout) {
			let modules: PageModule[] | undefined = [];

			if (currentPage === NavMainItems.Home || page === NavMainItems.Sessions) {
				// update post_register_home_modules
				modules = workingEvent?.homepage?.post_register_home_modules;
			} else if (currentPage) {
				// update the modules of a custom_page using the value of currentPage
				modules = currentCustomPage?.modules;
			} else {
				// update landing page page_modules
				modules = workingEvent?.homepage?.modules;
			}

			const module = modules?.find((module: PageModule) => (module.id === selectedModule));
			if ((hasAdvancedSettings || hasDisplayOptions) && (pageModuleType || pageModuleType === 0)) {
				return (
					<AdvancedLayoutSettings
						selectedModule={pageModuleType}
						module={module}
						moduleProps={{
							setSelectedDisplayOptions,
							selectedDisplayOptions,
							selectedLayout,
							speakerImageRadius,
							setSpeakerImageRadius,
						}}
					/>
				);
			}
		}
		return null;
	};

	return (
		<div className="edit-layout-modal-container">
			<ModalComponent
				cancellable={true}
				closeable={false}
				open={open}
				onRequestClose={handleClose}
				title={`Change ${sectionName.toLowerCase()} layout`}
				size="wide"
				className="edit-layout-modal"
				footer={(
					<>
						{renderAdvancedSettings()}

						<div className="layout-modal-action-btns">
							<button
								style={{ marginRight: 16 }}
								onClick={handleClose}
							>
								Cancel
							</button>
							<button
								className="lemonade"
								onClick={activeTab === ETabOptions.layout ? handleDone : handleDoneEditModule}
							>
								Done
							</button>
						</div>
					</>
				)}
			>
				{/* 
					FUTURE DEV: add back in when implementing animations
					<TabHeader
						tabs={moduleEditTabs}
						onClick={setActiveTab}
						currentTab={activeTab}
					/> 
				*/}
				<div className="edit-layout-modal-content">
					{moduleEdits}
				</div>
			</ModalComponent>
		</div>
	);
};

export default EditLayoutModal;
