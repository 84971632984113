import { handle } from "redux-pack";
import { Action } from "../../../types/actions";
import { UPDATE_PHASE, RESET_CHECKLIST, GET_HC_ARTICLES, CLEAR_ARTICLES, TOGGLE_ASSISTANT } from '../../actions/admin/assistant';

export interface IChecklistItem {
	type: string;
	title: string,
	description: string,
}

export enum EAssistantPhase {
	MENU = 0,
	WELCOME,
	PROGRESS,
	HELP_CENTER,
	CONTACT
}

export const ProgressHeaders = {
	landingPage: "Landing page",
	registration: "Registration",
	eventHomepage: "Event homepage",
	eventSessions: "Event sessions",
	theme: "Theme",
	publishing: "Publishing"
};

// const variable to be added to user onboarded array if they select the
// "Do not show again" checkbox on the assistant warning popup on publishing
export const skipAssistantWarning = "skipWarning";

// the titles of checklist item to be displayed in the progress checklist 
// will be saved in the user profile when the task is completed
export const ChecklistTitles = {
	uploadLogo: "Upload logo",
	editDescription: "Edit description",
	editButtonName: "Edit button name",
	editAbout: "Enable & edit 'About' section",
	editRegistration: "Set up registration setting",
	setupRegistration: "Set up Registration setting",
	editNavigationBar: "Edit Navigation Bar",
	editSessions: "Edit Sessions",
	customizeNavigation: "Customize navigation",
	updateVideo: "Update video settings",
	editSection: "Edit section",
	editFonts: "Customize font",
	editColors: "Change colors",
	publishEvent: "Publish your event"
};

// checklist items in the progress checklist assistant
// adding a new item to this list will sort it into the correct header based on type
const checklistOptions: IChecklistItem[] = [
	{
		type: ProgressHeaders.landingPage,
		title: ChecklistTitles.uploadLogo,
		description: "Make some changes on the Homepage page now. Please try to upload a logo for your event website.",
	},
	{
		type: ProgressHeaders.landingPage,
		title: ChecklistTitles.editDescription,
		description: "Try to edit event description text.",
	},
	{
		type: ProgressHeaders.landingPage,
		title: ChecklistTitles.editButtonName,
		description: `Let's edit the button name to "Register to Event" and set it for the Register action.`,
	},
	{
		type: ProgressHeaders.landingPage,
		title: ChecklistTitles.editAbout,
		description: `Let's add one more section to our page. Add an "About" section and try to change the image there.`,
	},
	{
		type: ProgressHeaders.registration,
		title: ChecklistTitles.editRegistration,
		description: `Click 'Registration Setting' to ensure that your registration gating is turned on and select the type of registration you prefer.`,
	},
	{
		type: ProgressHeaders.eventHomepage,
		title: ChecklistTitles.editNavigationBar,
		description: `
			You can edit the elements on your navigation bar. 
			Try click 'Edit' next to Main Navigation to select the elements you want living in your Navigation Bar.
		`
	},
	{
		type: ProgressHeaders.eventHomepage,
		title: ChecklistTitles.editSessions,
		description: `Click 'Sessions' to view your list of sessions and start editing session details.`
	},
	{
		type: ProgressHeaders.eventSessions,
		title: ChecklistTitles.customizeNavigation,
		description: "Switch session details navigation from horizontal tabs to vertical."
	},
	{
		type: ProgressHeaders.eventSessions,
		title: ChecklistTitles.updateVideo,
		description: "Update video preferences in your session."
	},
	{
		type: ProgressHeaders.eventSessions,
		title: ChecklistTitles.editSection,
		description: "Customize your session by editing a section. Try to edit your session description, or add a quiz or poll"
	},
	{
		type: ProgressHeaders.theme,
		title: ChecklistTitles.editFonts,
		description: `Let's make some changes to the event design. Please change to a set of fonts you prefer to use.`,
	},
	{
		type: ProgressHeaders.theme,
		title: ChecklistTitles.editColors,
		description: "Change color theme to one that matches your brand style.",
	},
	{
		type: ProgressHeaders.publishing,
		title: ChecklistTitles.publishEvent,
		description: "You're done with event editing. Make your event website alive. Let's publish your event!",
	}
];

// basic partial support article structure that's returned from the Bradnlive Zendesk api
export interface ISupportArticle {
	title: string,
	html_url: string
}

export interface AssistantState {
	open: boolean,
	currentPhase: EAssistantPhase,
	checklistOptions: IChecklistItem[],
	articles: ISupportArticle[],
	loadingArticles: boolean,
	loadingArticlesError: string
}

const initialState: AssistantState = {
	articles: [],
	checklistOptions: checklistOptions,
	currentPhase: EAssistantPhase.MENU,
	loadingArticles: false,
	loadingArticlesError: '',
	open: false,
};

export default function AssistantReducer(state: AssistantState = initialState, action: Action): AssistantState {
	switch (action.type) {
		case TOGGLE_ASSISTANT: {
			return ({ ...state, open: action.payload });
		}
		case UPDATE_PHASE: {
			return ({ ...state, currentPhase: action.payload });
		}
		case RESET_CHECKLIST: {
			return ({ ...state });
		}
		case GET_HC_ARTICLES: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingArticles: true,
					loadingArticlesError: ''
				}),
				finish: (state) => ({
					...state,
					loadingArticles: false
				}),
				failure: (state) => ({
					...state,
					loadingArticlesError: 'Unables to load articles'
				}),
				success: (state) => {
					return ({ ...state, articles: action.payload.articles });
				}
			});
		}
		case CLEAR_ARTICLES: {
			return ({ ...state, articles: [] });
		}
		default: return state;
	}
}