import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { SendTrackingEvent } from '../../connection/tracking';
import { ActionPayload, ActionEvent } from '../../types/working-model';
import TrackingContext from './TrackingContext';
import { Options } from './types';

const dispatchActionWithDebounce = () => {
	// video ended events are over firing under mysterious circumstances
	// but there's no need for a video to end multiple times without playing again
	let videoEnded = false;

	return (actionPayload: ActionPayload) => {
		// if this is an end event...
		if (actionPayload.action === ActionEvent.End) {
			// check if the video is already ended, if it is warn and break
			if (videoEnded) {
				console.warn('Duplicate video end event', actionPayload);
				return;
			}

			// set the last action to videoEnded
			videoEnded = true;
		} else {
			// it's not an end event, so reset the videoEnded flag to false
			videoEnded = false;
		}

		// will have already broken out if the video has ended
		SendTrackingEvent(actionPayload);
	};
};

const actionDispatcher = dispatchActionWithDebounce();
/**
 * Default function that gets dispatched per tracking event.
 * @param data
 */
const dispatchTrackingEvent = (actionPayload: ActionPayload) => {
	try {
		actionDispatcher(actionPayload);
	} catch (e) {
		console.error('Error sending event action:', e);
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTrackingImpl = <P extends Partial<ActionPayload>>(trackingData?: P, options?: Partial<Options<any>>) => {
	const { tracking } = useContext(TrackingContext);
	const latestData = useRef(trackingData);
	const latestOptions = useRef(options);

	// store the latest data & options in a mutable ref to prevent
	// dependencies from changing when the consumer passes in non-memoized objects
	useEffect(() => {
		latestData.current = trackingData;
		latestOptions.current = options;
	});

	const {
		dispatch = dispatchTrackingEvent,
		dispatchOnMount = false,
		process,
	} = useMemo(() => latestOptions.current || {}, []);

	// Return a memoized version of the process function if passed into options
	const getProcessFn = useCallback(() => tracking?.process, [tracking]);

	// returns the current tracked data. Used as a param for an optional process function
	const getOwnTrackingData = useCallback(() => {
		const data = latestData.current;
		return data || {};
	}, []);

	const getTrackingDataFn = useCallback(() => {
		const contextGetTrackingData =
			(tracking?.data) || getOwnTrackingData;

		return () =>
			contextGetTrackingData === getOwnTrackingData
				? getOwnTrackingData()
				: { ...contextGetTrackingData, ...getOwnTrackingData() };
	}, [getOwnTrackingData, tracking]);

	// Helper for returning the dispatch function.
	// dispatchTrackingEvent is set as the default but one can be optionally passed in to bypass
	const getTrackingDispatcher = useCallback(() => {
		const contextDispatch = tracking?.dispatch || dispatch;
		return (data: any) => contextDispatch({ ...getOwnTrackingData(), ...data || {} });
	}, [getOwnTrackingData, tracking, dispatch]);

	const trackEvent = useCallback((data: Partial<ActionPayload> = {}) => {
		getTrackingDispatcher()(data);
	}, [getTrackingDispatcher]);

	useEffect(() => {
		const contextProcess = getProcessFn();
		const getTrackingData = getTrackingDataFn();

		if (contextProcess && process) {
			console.error('tracking options.process should be defined once on a top-level component');
		}

		if (typeof contextProcess === 'function' && typeof dispatchOnMount === 'function') {
			trackEvent({
				...contextProcess(getOwnTrackingData()) || {},
				...dispatchOnMount(getTrackingData()) || {}
			});
		} else if (typeof contextProcess === 'function') {
			const processed = contextProcess(getOwnTrackingData());
			if (processed || dispatchOnMount === true) {
				trackEvent(processed);
			}
		} else if (typeof dispatchOnMount === 'function') {
			trackEvent(dispatchOnMount(getTrackingData()));
		} else if (dispatchOnMount === true) {
			trackEvent();
		}
	}, [
		getOwnTrackingData,
		getProcessFn,
		getTrackingDataFn,
		trackEvent,
		dispatchOnMount,
		process,
	]);

	return useMemo(() => ({
		tracking: {
			dispatch: getTrackingDispatcher(),
			getTrackingData: getTrackingDataFn(),
			process: getProcessFn() || process,
		},
	}), [
		getTrackingDispatcher,
		getTrackingDataFn,
		getProcessFn,
		process
	]);
};
