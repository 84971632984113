export enum TooltipPortalPosition {
	TOP = 'top',
	RIGHT = 'right',
	BOTTOM = 'bottom',
	LEFT = 'left',
}

export enum TooltipPortalContentDirection {
	LTR = 'ltr',
	CENTER = 'center',
	RTL = 'rtl',
}
