import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { bytesToString } from '../../../utils/utils';
import Icon, { ICONS, COLORS } from '../icon';
import { Tooltip } from '../tooltip/tooltip';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';
import './file-card.scss';

type Props = {
	label: string;
	filename: string;
	filesize: number;
	className?: string;
	imageUrl?: string;
	readonly?: boolean;
	tooltip: JSX.Element;
	onEdit: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDelete: () => void;
}
export default function FileCard(props: Props): JSX.Element {
	const fileRef = useRef<HTMLInputElement | null>(null);
	const loadingRef = useRef<NodeJS.Timeout | null>(null);
	const [loaded, setLoaded] = useState(props.imageUrl ? 101 : 0);

	useEffect(() => {
		// we do not get progress updates from cloudConvert, so we fake it
		// 101% is considered renderable so we can fake the load percentage
		const addOne = () => {
			setLoaded(prev => {
				if (prev < 99) {
					return prev + 1;
				}

				return prev;
			});
		};

		const imageReady = () => {
			if (loadingRef.current) {
				clearInterval(loadingRef.current);
			}

			setLoaded(100);

			setTimeout(() => {
				setLoaded(101);
			}, 500);
		};

		if (!props.imageUrl) {
			setLoaded(0);
			loadingRef.current = setInterval(addOne, 2000);
		} else {
			const img = new Image();
			img.src = props.imageUrl;
			img.onload = imageReady;
		}

		return () => {
			if (loadingRef.current) {
				clearInterval(loadingRef.current);
			}
		};
	}, [props.imageUrl]);

	return (
		<div className={classNames("file-card", props.className)}>
			<label>{props.label}</label>
			<div className="file-card-inner">
				<div className="file-card-data">
					<div className="file-card-image">
						{loaded <= 100 ? (
							<div className="file-card-loading">
								<span className="file-card-waiting">
									<WaitingIndicator />
									<span className="file-card-loaded-perc">{loaded}%</span>
								</span>
							</div>
						) : (
							<div className="file-thumbnail" style={{ backgroundImage: `url(${props.imageUrl})` }} />
						)}
					</div>
					<div className="file-card-metadata">
						<p>{props.filename}</p>
						<span className="file-card-size">{bytesToString(props.filesize)}</span>
					</div>
				</div>

				<Tooltip position='top-right' tooltip={props.tooltip}>
					<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
				</Tooltip>

				<div className="file-card-actions" style={props.readonly ? { visibility: 'hidden' } : undefined}>
					<button disabled={props.readonly} className="round small" onClick={() => fileRef.current?.click()}>
						<Icon name={ICONS.EDIT} color={COLORS.WHITE} size={12} />
					</button>
					<button disabled={props.readonly} className="round small" onClick={props.onDelete}>
						<Icon name={ICONS.TRASH_OUTLINE} color={COLORS.WHITE} size={12} />
					</button>
					<input accept=".xlsx,.pdf,.pptx,.docx" ref={fileRef} style={{ display: 'none' }} type="file" onChange={props.onEdit} />
				</div>
			</div>
		</div>
	);
}