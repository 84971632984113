import AppContext, { AppContexts } from "components/app-context";
import { useContext } from "react";

export const useIsAdmin = () => {
	const context = useContext(AppContext);
	return context === AppContexts.admin;
};

export const useIsLiveEvent = () => {
	const context = useContext(AppContext);
	return context === AppContexts.liveEvent;
};
