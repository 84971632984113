import { useMemo } from "react";
import { useTypedSelector } from "../store/reducers/use-typed-selector";

import { Language } from "../types/working-model";
import TranslateLangs from './translate-langs.json';

export const useDefaultEventLanguage = (): Language => {
	const createEventDefault = useTypedSelector(state => state.CreateEventReducer.newEventRequest?.defaultLanguage);
	const defaultLanguage = useTypedSelector(state => state.CreateEventReducer.defaultLanguage);
	const eventSettings = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings);

	return useMemo(() => {
		if (defaultLanguage) {
			return defaultLanguage;
		}

		const { i18n } = eventSettings ?? {};
		const defaultEventLanguageCode = createEventDefault ?? i18n?.base;

		if (!defaultEventLanguageCode) {
			return TranslateLangs[0];
		}

		const matchingLang = TranslateLangs.find(lang => lang.code === defaultEventLanguageCode);
		return matchingLang ?? TranslateLangs[0];
	}, [eventSettings, createEventDefault, defaultLanguage]);
};