import Socket from "../connection/socket";
import socketManager from "../connection/socket-main-thread/socket-manager";
import { IEngagementSubmit } from "../types/working-model";
import store from '../store/main';
import { getEngagementResults, updateEngagementResults } from "../store/actions/admin/surveys";

export class SurveyResultsListenerService {
	private eventUuid: string | undefined | null;
	private eventId: number | undefined | null;
	private token: string | undefined | null;
	private socket: Socket | undefined;

	private handleEngageUpdate = (incomingEngagementResults: IEngagementSubmit) => {
		if (incomingEngagementResults) {
			// going through all engagementSubmits and updating the engagement question with the new results
			// adding the results into the redux store
			const engagementResults = store.getState().SurveysReducer.engagementResults ?? {};
			const tempEngagementResults = { ...engagementResults };

			for (const incomingEngagementResult in incomingEngagementResults.engagementSubmits) {
				// incomingEngagementResult is in the form: engagement-118245-12676
				const [, engagementQuestionId, optionId] = incomingEngagementResult.split('-');
				const key = `engagement_question:${engagementQuestionId}-answer:${optionId}`;

				const incomingTotal = incomingEngagementResults.engagementSubmits[incomingEngagementResult];

				tempEngagementResults[key] = (tempEngagementResults[key] || 0) + Number(incomingTotal);
			}

			// updating the state with the new results
			store.dispatch(updateEngagementResults(tempEngagementResults));
		}
	};

	public refresh = () => {
		if (this.eventId && this.token) {
			store.dispatch(getEngagementResults(this.eventId, this.token) as any);
		}
	};

	public in = (
		eventUuid: string | undefined | null,
		eventId: number | undefined | null,
		token: string | undefined | null
	) => {
		this.eventUuid = eventUuid;
		this.eventId = eventId;
		this.token = token;

		// all variables set and not listening, setup connection
		if (this.eventUuid && this.eventId && this.token && !this.socket) {
			store.dispatch(getEngagementResults(this.eventId, this.token) as any);
			this.socket = socketManager.get(`event-${eventUuid}-engagement`);
			this.socket.addListener('submit-engagement', this.handleEngageUpdate);

			// we are already set up but this was called anyway - refresh data from microservices
		} else if (this.eventUuid && this.eventId && this.token) {
			this.refresh();
		}
	};

	public out = () => {
		if (this.socket) {
			this.socket.removeListener('submit-engagement', this.handleEngageUpdate);
			socketManager.leave(`event-${this.eventUuid}-engagement`);
			this.socket = undefined;
		}
	};
}

// default export is a singleton so we don't need to create
// multiple instances of this service - one listener for all things
export default new SurveyResultsListenerService();