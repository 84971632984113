import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { debounce } from 'underscore';

import { useTranslate } from '../../../i18n/useTranslationModules';
import { getQueryParams } from '../../../utils/utils';
import Icon, { ICONS } from '../icon';

import './search.scss';

interface SearchProps {
	autoFocus?: boolean;
	className?: string;
	controlledValue?: string;
	isControlled?: boolean;
	minLength?: number;
	onChange?: (searchText: string) => void;
	onClear: () => void;
	onDebounce?: (searchText: string) => void;
	onSearch?: (searchText: string) => void;
	placeholder?: string;
	refsProp?: React.MutableRefObject<(HTMLDivElement | null)[]>;
	searchOnDebounce?: boolean;
	searchOnEmpty?: boolean;
	showIcon?: boolean;
	style?: React.CSSProperties;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function Search({
	autoFocus = false,
	className,
	controlledValue = '',
	isControlled = false,
	minLength = 0,
	onChange,
	onClear,
	onDebounce,
	onSearch = () => null,
	placeholder,
	refsProp,
	searchOnDebounce,
	searchOnEmpty = false,
	showIcon = true,
	style = {},
	onKeyDown,
}: SearchProps): JSX.Element {
	const [search, setSearch] = useState(controlledValue);
	const searchRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslate("homepage");

	const location = useLocation();
	const queryParams = getQueryParams(location.search);

	useEffect(() => {
		if (queryParams?.q) {
			setSearch(decodeURIComponent(queryParams.q));
		}
	}, [queryParams.q]);

	// if isControlled, meaning if the value comes from the parent component
	// then set the search value to be that value
	useEffect(() => {
		if (isControlled) {
			setSearch(controlledValue);
		}
	}, [isControlled, controlledValue]);

	const canSubmit = () => search.length >= minLength;

	const debounced = useRef(debounce((value: string) => {
		onDebounce && onDebounce(value);
		if (searchOnDebounce && canSubmit()) onSearch(value);
	}, 300)).current;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);

		if (onDebounce) {
			debounced(e.target.value);
		}

		if (onChange && canSubmit()) {
			onChange(e.target.value);
		}

		// if value is empty and searchOnEmpty automatically reset
		if (search.length && !e.target.value.length && searchOnEmpty) {
			handleClearSearch();
		}
	};

	const handleClearSearch = () => {
		setSearch('');
		searchRef.current?.focus();
		onClear();
	};
	const handleKeyPressSearch = ({ key }: any) => {
		switch (key) {
			case 'Escape':
				handleClearSearch();
				break;
			case 'Enter':
				if (canSubmit()) {
					onSearch(search);
				}
				break;
			default:
				return;
		}
	};

	return (
		<div
			className={classNames('search-input', className)}
			style={style} ref={(el) => refsProp ? refsProp.current[0] = el : null}
			onClick={() => searchRef.current?.focus()}
		>
			{showIcon && <button
				onClick={() => {
					if (canSubmit()) {
						onSearch(search);
					}
				}}
				className="no-style clear search-input-search-btn"
			>
				<Icon
					name={ICONS.SEARCH}
					color={"border-light-gray"}
					size={14}
				/>
			</button>}
			<input
				type="text"
				placeholder={placeholder ? placeholder : `${t("Search")}...`}
				ref={searchRef}
				onKeyUp={handleKeyPressSearch}
				value={search}
				onChange={handleChange}
				autoFocus={autoFocus}
				onKeyDown={onKeyDown}
			/>
			{!search ? (
				<div className="search-input-close-placeholder" />
			) : (
				<button
					onClick={handleClearSearch}
					className='no-style clear search-input-close-btn'
				>
					<Icon name={ICONS.CLOSE} color="gray" size={12} />
				</button>
			)}
		</div>
	);
}
