import { Dictionary, Session, SessionPreview } from "../types/working-model";

// TWO DIFFERENT CHECKS FOR "LIVE" SESSIONS
// breakout sessions always check date/time
// broadcast sessions will appear in the Events Live tab and in the homepage main event banner if they are scheduled to be live at that time
// however, broadcast sessions are only tagged as "live" if they have a live video stream

export function checkIsLive(session: Session | SessionPreview): boolean {
	return !!session.timestamp && session.timestamp < Date.now() && session.end_timestamp! > Date.now();
}

export const CheckPlaybackUrls = async (playbackUrls: Dictionary): Promise<string[]> => {
	const liveChannels: Set<string> = new Set([]);

	for (const session in playbackUrls) {
		try {
			// you can't remove the 404 log from the console. It's not possible. If you try, increment this counter:
			// Hours wasted: 10
			const res = await fetch(playbackUrls[session]);

			if (res.ok) {
				//need to drop language key from end of session uuid
				const sessionIdWithoutLanguageArr = session.split('-');
				sessionIdWithoutLanguageArr.pop();

				liveChannels.add(sessionIdWithoutLanguageArr.join("-"));
			}
		} catch {
			//channel is not live
		}
	}

	return Array.from(liveChannels.values());
};