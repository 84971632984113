import { useState, useEffect } from "react";

interface IProps {
	ref: HTMLElement | null;
	forceRerender?: number;
	trackOnResize?: boolean;
}

const useTrackHeight = ({ ref, forceRerender, trackOnResize }: IProps): { height: number } => {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		// create a mutation observer to track the height of the element
		if (ref) {
			// initialize on load:
			setHeight(ref.getBoundingClientRect()?.height || 0);

			// listen for changes:
			const observer = new MutationObserver(() => {
				setHeight(ref.getBoundingClientRect()?.height || 0);
			});
			observer.observe(ref, {
				attributes: true,
				characterData: true,
				childList: true,
				subtree: true,
			});

			// listen for resize:
			const resizeObserver = new ResizeObserver(() => {
				setHeight(ref.getBoundingClientRect()?.height || 0);
			});
			if (trackOnResize) {
				resizeObserver.observe(ref);
			}

			return () => {
				observer.disconnect();
				resizeObserver.disconnect();
			};
		}
	}, [ref, forceRerender, trackOnResize]);

	return {
		height
	};
};

export default useTrackHeight;
