import EmbedCard from "./embed-card";

const EmbedSection = () => {
	return (
		<div className="session-panel embed-section padding-24">
			<EmbedCard />
		</div>
	);
};

export default EmbedSection;