import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ColorVars from '../../../utils/colors/colors';
import { addListener, removeListener } from '../alert/alert-service';
import './mouse-popup.scss';

export type MousePopupOptions = {
	message: string;
	duration?: number;
	backgroundColor?: string;
	color?: string;
	position?: { top: number, left: number };
}

export const MousePopup = (): JSX.Element | null => {
	const [style, setStyle] = useState({
		left: 0,
		top: 0,
		backgroundColor: ColorVars.gray1000,
		color: ColorVars.gray100
	});
	const [visible, setVisible] = useState(false);
	const [transitioning, setTransitioning] = useState(false);
	const [message, setMessage] = useState('');
	const xRef = useRef(0);
	const yRef = useRef(0);
	const hideTimeout = useRef<NodeJS.Timeout | null>(null);

	const handleTransitionOut = () => {
		if (!visible) {
			setTransitioning(false);
		}
	};

	useEffect(() => {
		const handleCursorPosition = (e: PointerEvent) => {
			xRef.current = e.clientX;
			yRef.current = e.clientY;
		};

		window.addEventListener('pointermove', handleCursorPosition);

		addListener('mouse-popup', ({ message, duration = 1000, backgroundColor, color, position }: MousePopupOptions) => {
			// position the popup
			setStyle({
				left: position?.left ?? xRef.current,
				top: position?.top ?? yRef.current,
				color: color || ColorVars.gray100,
				backgroundColor: backgroundColor || ColorVars.gray1000
			});

			// allows us to set the background color of the popup AND the caret which is using this CSS variable
			document.body.style.setProperty('--mouse-popup-background-color', backgroundColor || ColorVars.gray1000);

			// sets the message and shows the popup
			setMessage(message);
			setVisible(true);
			setTransitioning(true);

			// allows us to trigger this multiple times before the last popup has hidden - keep the animation transition state clean
			if (hideTimeout.current) {
				clearTimeout(hideTimeout.current);
			}

			hideTimeout.current = setTimeout(() => {
				setVisible(false);
			}, duration);
		});

		return () => {
			removeListener('mouse-popup');
			window.removeEventListener('pointermove', handleCursorPosition);
		};
	}, []);

	// removed from DOM when animation out is complete
	return (visible || transitioning) ? (
		<div
			className={
				classNames(
					'mouse-popup',
					{
						'mouse-popup--visible': visible,
						'mouse-popup--transitioning': transitioning
					}
				)
			}
			style={style}
			onTransitionEnd={handleTransitionOut}>
			{message}
		</div>
	) : null;
};
