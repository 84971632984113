import { useMemo } from "react";
import { useGetEvent } from "./session.hooks";

const useShowMyList = () => {
	const event = useGetEvent();

	return useMemo(() => {
		// because the event might not have loaded the sessions yet (user is on the landing page)
		// we must use the `sessionsPreviews` key instead of `sessions`
		const numberOfSessions = event?.sessionsPreviews?.length || 0;
		return !!event?.settings?.personalizedAttendeeList && numberOfSessions > 1;
	}, [event]);
};

export default useShowMyList;
