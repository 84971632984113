interface IPodiumContainer {
	containerClass?: string;
}

const PodiumContainer: React.FC<IPodiumContainer> = ({ containerClass = '', children }) => {
	return (
		<div className={`podium-container ${containerClass}`}>
			<div className="podium-entries-container">
				{children}
			</div>
		</div>
	);
};

export default PodiumContainer;
