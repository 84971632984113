import Icon, { ICONS } from "@general-ui/icon";
import Switch from "@general-ui/switch/switch";
import { useTranslate } from "i18n/useTranslationModules";
import { useDispatch } from "react-redux";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { setEventNavOpen, toggleAttendeeInPersonMode } from "store/actions/event/event-actions";
import { BrandliveEvent } from "types/working-model";
import { useCurrentPageState } from "./navbar.hooks";

type Props = {
	userProfile: Record<number, string> | undefined;
	eventBundle: BrandliveEvent;
}
const NavbarProfileCard: React.FC<Props> = ({ userProfile, eventBundle }) => {
	const { t } = useTranslate('homepage');
	const match = useRouteMatch();
	const history = useHistory();
	const pageState = useCurrentPageState(eventBundle);
	const dispatch = useDispatch();
	const attendeeInPersonToggleIsOn = !!eventBundle.settings.attendeeInPersonEnabled;
	const attendeeInPersonEnabled = !!eventBundle?.registration_settings?.adminInPersonAttendeeModeEnabled;

	const goToProfile = () => {
		try {
			const path = generatePath(pageState.routeMap.Profile, { ...match.params });

			history.push(path);

			dispatch(setEventNavOpen(false));
		} catch (e) {
			console.error(`Unable to route to profile`, e);
		}
	};

	const toggleInPersonView = (_: unknown, on: boolean) => {
		dispatch(toggleAttendeeInPersonMode(on));
	};

	return (
		<div className="navbar__mobile-header-profile-card">
			<div className="navbar__mobile-header-profile-card-upper" onClick={goToProfile}>
				<div className="navbar__mobile-header-profile-card-upper-name">
					<label>{userProfile?.[1]} {userProfile?.[2]}</label>
					<aside>{t('See your profile', 'See your profile')}</aside>
				</div>
				<div className="navbar__mobile-header-profile-card-upper-icon">
					<Icon name={ICONS.ANGLE_RIGHT} color={''} size={16} />
				</div>
			</div>
			{attendeeInPersonEnabled && (
				<div className="navbar__mobile-header-profile-card-lower">
					<label>{t('In-person view', 'In-person view')}</label>
					<Switch
						value="in-person"
						on={attendeeInPersonToggleIsOn}
						onClick={toggleInPersonView}
					/>
				</div>
			)}

		</div>
	);
};

export default NavbarProfileCard;