import { useEffect } from "react";
import { HvHostMap, PostHv } from "../../connection/helpers";

import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { setStorageItem } from "../local-storage";

type UseTrackEndSessionTimesType = {
	runOneInterval?: boolean;
	interval?: number;
	beginAfter?: number;
	runOnLoad?: boolean;
	sessionId?: number
}
const useTrackEndSessionTimes = ({ runOneInterval = false, interval = 9000, beginAfter = 0, runOnLoad = false, sessionId = undefined }: UseTrackEndSessionTimesType): void => {

	const userSession = useTypedSelector(event => event.LiveEventReducer.userSession);

	useEffect(() => {
		let timeout: NodeJS.Timeout | undefined = undefined;
		let startTimeout: NodeJS.Timeout | undefined = undefined;
		let errorTimeout: NodeJS.Timeout | undefined = undefined;

		const runHeartbeat = async () => {
			if (timeout) {
				clearTimeout(timeout);
				timeout = undefined;
			}

			if (!userSession) {
				return;
			}

			if (runOnLoad) {
				try {
					// do not use UpdateUserSessionEndTime directly because that doesn't await the api call
					// I don't want to mess with that call because it gets used in other areas of the app that I don't want to accidentally break
					await PostHv(HvHostMap.sessionTracking, '/e3-update-end-time', { uuid: userSession }, undefined, true);
				} catch (e) {
					console.error(e);
				}
			}

			// use timeout instead of interval to prevent potential backlog of function calls if for some reason a user's browser slows down
			timeout = setTimeout(async () => {
				try {
					await PostHv(HvHostMap.sessionTracking, '/e3-update-end-time', { uuid: userSession }, undefined, true);
					if (sessionId) {
						setStorageItem(`ses.${sessionId}`, userSession, 0.25); // nudge user session ttl up 15 mins
					}
					if (runOneInterval) return;
					runHeartbeat();
				} catch (e) {
					// if we have an error it might be because we have too many clients trying to ping the api point at the same time
					// so we create a random delay between 30 seconds to 90 seconds and re-run the function
					console.error(e);
					const min = Math.ceil(1000 * 60 * 0.5);
					const max = Math.floor(1000 * 60 * 1.5);
					const randomTime = Math.floor(Math.random() * (max - min) + min);
					errorTimeout = setTimeout(() => {
						runHeartbeat();
					}, randomTime);
				}
			}, interval);
		};

		if (timeout) {
			clearTimeout(timeout);
		}
		if (startTimeout) {
			clearTimeout(startTimeout);
		}
		if (errorTimeout) {
			clearTimeout(errorTimeout);
		}
		if (userSession) {
			startTimeout = setTimeout(() => {
				runHeartbeat();
			}, beginAfter);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
			if (startTimeout) {
				clearTimeout(startTimeout);
			}
			if (errorTimeout) {
				clearTimeout(errorTimeout);
			}
		};
	}, [runOneInterval, userSession, interval, beginAfter, runOnLoad, sessionId]);

};

export default useTrackEndSessionTimes;
