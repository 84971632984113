import classNames from "classnames";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Product, TranslateString } from "../../../../../../../types/working-model";
import { resizedImage } from "../../../../../../../utils/utils";
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { settings } from "../../../../../../../utils/slider-utils";
import { NextArrow, PrevArrow } from "../../../../../../general-ui/arrows/arrows";
import { OptionalComponent } from "../../../../../../../utils/optional-component";

const tString = (str: TranslateString, language: string) => {
	return str[language] || str.base;
};

type Props = {
	product: Product;
	selected?: boolean;
	handleSelectProduct?: (id: number, selected: boolean) => void;
	handleEdit?: (product: Product) => void;
	handleDelete?: (product: Product) => void;
};
const ProductCard = ({
	product,
	selected,
	handleSelectProduct,
	handleDelete,
	handleEdit
}: Props) => {
	const { product: id } = product;
	const { language } = useParams<{ language: string }>();

	const buttonText = tString(product.button_text, language);

	return (
		<div
			className={classNames("panel-card product-card", { selected })}
			key={product.product}
			onClick={() => handleSelectProduct?.(id as number, !selected)}
		>
			{/* <div
				className="product-image"
				style={{ backgroundImage: `url(${product.images[0]})` }}
			/> */}
			<div className="product-image">
				<Slider
					prevArrow={<PrevArrow />}
					nextArrow={<NextArrow />}
					{...settings}
					dots={settings.dots}
					className="product-image"
				>
					{product.images.map((image: string, index: number) => (
						<Fragment key={index}>
							<img src={image} alt={image} aria-hidden="true" />
						</Fragment>
					))}
				</Slider>
			</div>

			<div className="product-data">
				<h4>{tString(product.product_title, language)}</h4>
				{product.price?.base && (
					<div className="product-price">
						<span className="price">{tString(product.price, language)}</span>
						{product.sale_price?.base && <span className="sale-price"> • {tString(product.sale_price, language)}</span>}
					</div>
				)}
				<div className="product-description">
					{tString(product.description, language)}
				</div>
				<OptionalComponent display={!!buttonText}>
					<div className="product-action">
						<button className="small">{buttonText}</button>
					</div>
				</OptionalComponent>
			</div>
			{(handleEdit && handleDelete) && (
				<NavigationDropdown
					title={<Icon name={ICONS.THREE_DOTS_VERTICAL} size={20} color={COLORS.WHITE} />}
					className="added-card-dropdown"
					buttonClassName="question-card-options round no-style"
					edge="right"
					isArrow={false}
				>
					<>
						<button onClick={() => handleEdit(product)}>Edit</button>
						<button onClick={() => handleDelete(product)}>Delete</button>
					</>
				</NavigationDropdown>
			)}
		</div>
	);
};

export default ProductCard;