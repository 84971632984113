import { Fragment, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { latinChars } from "../../../../../../i18n/transliteration";
import { PageModule, PageModuleType, SessionPanelLayoutsTypes, SessionTabNames, SurveyType } from "../../../../../../types/working-model";
import SessionContentV2 from "../../../homepage/navigation/panel/session-content-v2";
import SessionLayoutV2 from "../../../homepage/navigation/panel/session-layout-v2";
import SessionSettingsV2 from "../../../homepage/navigation/panel/session-settings-v2";
import CreateQuestion from "../create-question/create-question";
import AddEngagement from "../engage/add-engagement/add-engagement";
import CreateFeedback from "../engage/create-feedback/create-feedback";
import CreateEngagePanel from "../engage/create-survey/create-engage-panel";
import QuestionPromptView from "../engage/question-prompts/question-prompt-view";
import SurveyResults from "../engage/survey-results/survey-results";
import AddSuggested from "./extras/suggested/add-suggested";
import PanelWrapper from "./components/panel-wrapper";
import SubPanelWrapper from "./components/sub-panel-wrapper";
import TopPanelWrapper from "./components/top-panel-wrapper";
import CustomTabPanel, { CustomTab as CustomCustomTab } from "./empty-state-panel/custom-tab-panel";
import EngagePanel from "./empty-state-panel/engage-panel";
import ExtrasPanel, { CustomTab as CustomExtrasTab } from "./empty-state-panel/extras-panel";
import ExtrasProductsPanel from "./empty-state-panel/extras-products-panel";
import ExtrasResourcesPanel from "./empty-state-panel/extras-resources-panel";
import ExtrasSpeakersPanel from "./empty-state-panel/extras-speakers-panel";
import ExtrasSuggestedPanel from "./empty-state-panel/extras-suggested-panel";
import EmbedSection from "./extras/embed/embed-section";
import CreateNewProduct from "./extras/products/create-new";
import ProductsLibrary from "./extras/products/products-library";
import CreateNewResource from "./extras/resources/create-new";
import ResourcesList from "./extras/resources/resources-list";
import SpeakersLibrary from "./extras/speakers/speakers-library";
import BannedUsers from "./banned-users";
import ModuleTabs from "./module-tabs";
import CreateNewSpeaker from "./extras/speakers/create-new";

const sessionBaseRoute = "/admin/events/create/:id/session/:session/:language";

const path = (p: string) => `${sessionBaseRoute}${p}`;

export type SessionPanel = {
	key: string;
	label: string;
	/** ensures panel title always uses "label" instead of module content */
	enforceLabelTitle?: boolean;
	path: string;
	type: "root" | "group" | "page_module" | "page_module_edit",
	pageModuleType?: PageModuleType;
	deepPathPattern?: string;
	navKey?: SessionTabNames | SessionPanelLayoutsTypes;
	rightHeader?: boolean;
	// allowing any purposefully - we don't want to dictate what types of components
	// are allowed here, but passing props into them will need to be done carefully as
	// this component doesn't accept props itself. Ideally, child routes will get their state
	// from either redux, the context, or the path itself 
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component?: (props: any) => JSX.Element | React.FC<any> | null;
};

// only the top level may contain tabs
export type SessionPanelWithTabs = SessionPanel & {
	tabs?: SessionPanel[];
}

export type SessionPanelWithPages = SessionPanel & {
	pages?: SessionPanelWithPages[];
}

export const _SessionPanels: (SessionPanel | SessionPanelWithTabs | SessionPanelWithPages)[] = [
	{
		label: "Content",
		path: path('/content'),
		key: "content",
		type: "root",
		// use for lookups using the react router matcher - all paths must obey this pattern
		deepPathPattern: path("/content/:tabType/:customPath/:moduleKey/:page_module/:page_module_edit"),
		component: () => <SessionContentV2 />,
		navKey: SessionTabNames.Content_v2,
		pages: [
			{
				key: "extras",
				label: "Extras",
				path: path('/content/tabs/extras'),
				type: "group",
				component: () => <ExtrasPanel />,
				navKey: SessionPanelLayoutsTypes.Extras,
				pages: [
					{
						label: "Products",
						path: path(`/content/tabs/extras/products/:page_module`),
						key: "extras-products",
						type: "page_module",
						pageModuleType: PageModuleType.products,
						component: () => <ExtrasProductsPanel />,
						navKey: SessionPanelLayoutsTypes.ExtraProducts,
						pages: [
							{
								label: "Products Library",
								path: path(`/content/tabs/extras/products/:page_module/library`),
								key: "extras-products-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.products,
								navKey: SessionPanelLayoutsTypes.AddExtraProduct,
								component: () => <ProductsLibrary />,

							},
							{
								label: "Create Product",
								path: path(`/content/tabs/extras/products/:page_module/create`),
								key: "extras-products-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.products,
								navKey: SessionPanelLayoutsTypes.CreateExtraProduct,
								component: () => <CreateNewProduct />,
							}
						]
					},
					{
						label: "Speakers",
						key: "extras-speakers",
						type: "page_module",
						path: path(`/content/tabs/extras/speakers/:page_module`),
						pageModuleType: PageModuleType.speakers,
						component: () => <ExtrasSpeakersPanel />,
						navKey: SessionPanelLayoutsTypes.ExtraSpeaker,
						pages: [
							{
								label: "Speakers Library",
								path: path(`/content/tabs/extras/speakers/:page_module/library`),
								key: "extras-speakers-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.speakers,
								component: () => <SpeakersLibrary />,
								navKey: SessionPanelLayoutsTypes.AddExtraSpeaker
							},
							{
								label: "Create Speaker",
								enforceLabelTitle: true,
								path: path(`/content/tabs/extras/speakers/:page_module/create`),
								key: "extras-speakers-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.speakers,
								component: () => <CreateNewSpeaker />,
								navKey: SessionPanelLayoutsTypes.CreateExtraSpeaker
							}
						]
					},
					{
						label: "Resources",
						key: "extras-resources",
						type: "page_module",
						path: path(`/content/tabs/extras/resources/:page_module?`),
						pageModuleType: PageModuleType.documents,
						component: () => <ExtrasResourcesPanel />,
						navKey: SessionPanelLayoutsTypes.ExtraResources,
						pages: [
							{
								label: "Resources Library",
								path: path(`/content/tabs/extras/resources/:page_module/library`),
								key: "extras-resources-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.documents,
								navKey: SessionPanelLayoutsTypes.AddExtraResource,
								component: () => <ResourcesList />
							},
							{
								label: "Create Resource",
								path: path(`/content/tabs/extras/resources/:page_module/create`),
								key: "extras-resources-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.documents,
								navKey: SessionPanelLayoutsTypes.CreateExtraResource,
								component: () => <CreateNewResource />
							}
						]
					},
					{
						label: "Suggested",
						key: "extras-suggested",
						type: "page_module",
						path: path(`/content/tabs/extras/suggested/:page_module?`),
						pageModuleType: PageModuleType.similar_sessions,
						component: () => <ExtrasSuggestedPanel />,
						navKey: SessionPanelLayoutsTypes.ExtraSuggested,
						pages: [
							{
								label: "Add Session",
								path: path(`/content/tabs/extras/suggested/:page_module/library`),
								key: "extras-suggested-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.similar_sessions,
								navKey: SessionPanelLayoutsTypes.AddExtraSuggested,
								component: () => <AddSuggested />
							}
						]
					},
					{
						label: "Custom HTML",
						key: "extras-custom-embed",
						type: "page_module",
						path: path(`/content/tabs/extras/custom-embed/:page_module`),
						pageModuleType: PageModuleType.blank,
						component: () => <EmbedSection />,
						navKey: SessionPanelLayoutsTypes.ExtraEmbed
					},
					{
						label: "Custom",
						key: "extras-custom",
						type: "page_module",
						path: path(`/content/tabs/extras/custom/:page_module`),
						pageModuleType: PageModuleType.feed,
						navKey: SessionPanelLayoutsTypes.ExtraCustom,
						component: () => <CustomExtrasTab />
					}
				]
			},
			{
				key: "engage",
				label: "Engage",
				path: path('/content/tabs/engage'),
				type: "group",
				component: () => <EngagePanel />,
				navKey: SessionPanelLayoutsTypes.Engage,
				pages: [
					{
						label: "Engagements",
						path: path('/content/tabs/engage/q-and-a/:page_module'),
						key: "q-and-a",
						type: "page_module",
						component: () => <Fragment />,
						navKey: SessionPanelLayoutsTypes.AddEngagementQAndA,
						pages: [
							{
								label: "Add Engagement",
								path: path('/content/tabs/engage/add/modules/library'),
								key: "add-engagement",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.AddEngagement,
								component: () => <AddEngagement />
							},
							{
								label: "Create Question",
								path: path('/content/tabs/engage/q-and-a/:page_module/create'),
								key: "q-and-a-create",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.AddEngagementQAndA,
								component: () => <CreateQuestion />
							},
							{
								label: "Create Comment Box",
								path: path('/content/tabs/engage/comment-box/:page_module/create'),
								key: "comment-box-create",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.AddEngagementCommentBox,
								component: () => <CreateQuestion isCommentBox={true} />
							},
							{
								label: "Question Responses",
								path: path('/content/tabs/engage/q-and-a/:page_module/responses'),
								key: "q-and-a-responses",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.ViewSingleQAndAResponses,
								component: () => <QuestionPromptView isCommentBox={false} />,
								rightHeader: true
							},
							{
								label: "Comment Response",
								path: path('/content/tabs/engage/comment-box/:page_module/responses'),
								key: "comment-responses",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.ViewSingleCommentBoxResponses,
								component: () => <QuestionPromptView isCommentBox={true} />,
								rightHeader: true
							},
							{
								label: "Create Poll",
								path: path('/content/tabs/engage/polls/:page_module/create'),
								key: "poll-create",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.CreatePoll,
								pageModuleType: PageModuleType.survey,
								component: () => <CreateEngagePanel survey_type={SurveyType.poll} />
							},
							{
								label: "Create Survey",
								path: path('/content/tabs/engage/surveys/:page_module/create'),
								key: "surveys-create",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.CreateSurvey,
								pageModuleType: PageModuleType.survey,
								component: () => <CreateEngagePanel survey_type={SurveyType.survey} />
							},
							{
								label: "Create Quiz",
								path: path('/content/tabs/engage/quizzes/:page_module/create'),
								key: "quizzes-create",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.CreateQuiz,
								pageModuleType: PageModuleType.quizzes,
								component: () => <CreateEngagePanel survey_type={SurveyType.quiz} />
							},
							{
								label: "Quiz Results",
								path: path('/content/tabs/engage/quizzes/:page_module/results'),
								key: "quizzes-results",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.QuizResults,
								pageModuleType: PageModuleType.quizzes,
								component: () => <SurveyResults />,
								rightHeader: true
							},
							{
								label: "Poll Results",
								path: path('/content/tabs/engage/polls/:page_module/results'),
								key: "polls-results",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.PollResults,
								pageModuleType: PageModuleType.survey,
								component: () => <SurveyResults />,
								rightHeader: true
							},
							{
								label: "Survey Results",
								path: path('/content/tabs/engage/surveys/:page_module/results'),
								key: "surveys-results",
								type: "page_module_edit",
								navKey: SessionPanelLayoutsTypes.SurveyResults,
								pageModuleType: PageModuleType.survey,
								component: () => <SurveyResults />,
								rightHeader: true
							},
							{
								label: "Feedback",
								path: path('/content/tabs/engage/feedback/:page_module'),
								key: "feedback",
								type: "page_module",
								component: () => <CreateFeedback />,
								navKey: SessionPanelLayoutsTypes.AddEngagementFeedback,
								pages: [
									{
										label: "Create Feedback",
										path: path('/content/tabs/engage/feedback/:page_module/create'),
										key: "feedback-create",
										type: "page_module_edit",
										navKey: SessionPanelLayoutsTypes.CreateFeedback,
										component: () => <CreateFeedback />,
									}
								]
							},
							{
								label: "Banned users",
								path: path('/content/tabs/engage/banned-users'),
								key: "banned-users",
								type: "page_module_edit",
								component: () => <BannedUsers />,
								navKey: SessionPanelLayoutsTypes.BannedUsers,

							}
						]
					},
				]
			},
			// TODO: define details handlers
			// {
			// 	key: "details",
			// 	label: "Details",
			// 	path: path('/content/tabs/details'),
			// 	type: "group",
			// 	component: () => <Fragment />,
			// 	navKey: SessionPanelLayoutsTypes.details,
			// 	pages: [

			// 	]
			// },
			{
				key: "custom",
				label: "Custom",
				path: path('/content/custom-tabs/:customPath'),
				type: "group",
				component: () => <CustomTabPanel />,
				navKey: SessionPanelLayoutsTypes.Custom,
				pages: [
					{
						label: "Products",
						path: path(`/content/custom-tabs/:customPath/products/:page_module`),
						key: "custom-products",
						type: "page_module",
						pageModuleType: PageModuleType.products,
						component: () => <ExtrasProductsPanel custom={true} />,
						navKey: SessionPanelLayoutsTypes.CustomProducts,
						pages: [
							{
								label: "Products Library",
								path: path(`/content/custom-tabs/:customPath/products/:page_module/library`),
								key: "custom-products-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.products,
								navKey: SessionPanelLayoutsTypes.AddCustomProductFromLibrary,
								component: () => <ProductsLibrary />
							},
							{
								label: "Create Product",
								path: path(`/content/custom-tabs/:customPath/products/:page_module/create`),
								key: "custom-products-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.products,
								navKey: SessionPanelLayoutsTypes.AddCustomProductFromUpload,
								component: () => <CreateNewProduct />,
							}
						]
					},
					{
						label: "Speakers",
						key: "custom-speakers",
						type: "page_module",
						path: path(`/content/custom-tabs/:customPath/speakers/:page_module?`),
						pageModuleType: PageModuleType.speakers,
						component: () => <ExtrasSpeakersPanel custom={true} />,
						navKey: SessionPanelLayoutsTypes.CustomSpeakers,
						pages: [
							{
								label: "Speakers Library",
								path: path(`/content/custom-tabs/:customPath/speakers/:page_module/library`),
								key: "custom-speakers-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.speakers,
								navKey: SessionPanelLayoutsTypes.AddCustomSpeakersFromLibrary,
								component: () => <SpeakersLibrary />
							},
							{
								label: "Create Speaker",
								path: path(`/content/custom-tabs/:customPath/speakers/:page_module/create`),
								key: "custom-speakers-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.speakers,
								navKey: SessionPanelLayoutsTypes.AddCustomSpeakersFromUpload,
								component: () => <CreateNewSpeaker />,
								enforceLabelTitle: true
							}
						]
					},
					{
						label: "Resources",
						key: "custom-resources",
						type: "page_module",
						path: path(`/content/custom-tabs/:customPath/resources/:page_module?`),
						pageModuleType: PageModuleType.documents,
						component: () => <ExtrasResourcesPanel custom={true} />,
						navKey: SessionPanelLayoutsTypes.CustomResources,
						pages: [
							{
								label: "Resources Library",
								path: path(`/content/custom-tabs/:customPath/resources/:page_module/library`),
								key: "custom-resources-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.documents,
								navKey: SessionPanelLayoutsTypes.AddCustomResourceFromLibrary,
								component: () => <ResourcesList />
							},
							{
								label: "Create Resource",
								path: path(`/content/custom-tabs/:customPath/resources/:page_module/create`),
								key: "custom-resources-create",
								type: "page_module_edit",
								pageModuleType: PageModuleType.documents,
								navKey: SessionPanelLayoutsTypes.AddCustomResourceFromUpload,
								component: () => <CreateNewResource />
							}
						]
					},
					{
						label: "Suggested",
						key: "custom-suggested",
						type: "page_module",
						path: path(`/content/custom-tabs/:customPath/suggested/:page_module?`),
						pageModuleType: PageModuleType.similar_sessions,
						navKey: SessionPanelLayoutsTypes.CustomSuggested,
						component: () => <ExtrasSuggestedPanel custom={true} />,
						pages: [
							{
								label: "Add Session",
								path: path(`/content/custom-tabs/:customPath/suggested/:page_module/library`),
								key: "custom-suggested-library",
								type: "page_module_edit",
								pageModuleType: PageModuleType.similar_sessions,
								navKey: SessionPanelLayoutsTypes.AddCustomSuggested,
								component: () => <AddSuggested />
							}
						]
					},
					{
						label: "Custom HTML",
						key: "custom-custom-embed",
						type: "page_module",
						path: path(`/content/custom-tabs/:customPath/custom-embed/:page_module`),
						pageModuleType: PageModuleType.blank,
						component: () => <EmbedSection />,
						navKey: SessionPanelLayoutsTypes.AddCustomEmbed
					},
					{
						label: "Custom",
						key: "custom-custom",
						type: "page_module",
						path: path(`/content/custom-tabs/:customPath/custom/:page_module`),
						pageModuleType: PageModuleType.feed,
						navKey: SessionPanelLayoutsTypes.CustomCreate,
						component: () => <CustomCustomTab />
					}
				]
			},
		],
	},
	{
		label: "Layout",
		path: path('/layout'),
		key: "layout",
		type: "root",
		component: () => <SessionLayoutV2 />,
		navKey: SessionTabNames.Layout_v2,
		pages: []
	},
	{
		label: "Settings",
		path: path('/settings'),
		key: "settings",
		type: "root",
		component: () => <SessionSettingsV2 />,
		navKey: SessionTabNames.Settings_v2,
		pages: []
	}
];

export const SessionPanelMap = ((): Record<SessionPanelLayoutsTypes | SessionTabNames, string> => {
	const map: Record<string, string> = {};

	const addToMap = (panels: SessionPanelWithPages[]) => {
		for (const panel of panels) {
			if (panel.navKey) {
				map[panel.navKey] = panel.path;
			}

			if (panel.pages) {
				addToMap(panel.pages);
			}
		}
	};

	addToMap(_SessionPanels);
	return map;
})();

const PageModuleToGroupMap: Record<number, string> = {
	[PageModuleType.products]: "products",
	[PageModuleType.similar_sessions]: "suggested",
	[PageModuleType.documents]: "resources",
	[PageModuleType.speakers]: "speakers",
	[PageModuleType.feed]: "custom",
	[PageModuleType.survey]: "surveys",
	[PageModuleType.quizzes]: "quizzes",
	[PageModuleType.questions]: "q-and-a",
	[PageModuleType.comment_box_prompt]: "comment-box"
};

export const PageModuleTypeMap = (sub: string): { [key in PageModuleType]?: SessionPanelLayoutsTypes } => {
	const custom = sub === 'custom';
	return ({
		[PageModuleType.products]: custom ? SessionPanelLayoutsTypes.CustomProducts : SessionPanelLayoutsTypes.ExtraProducts,
		[PageModuleType.feed]: custom ? SessionPanelLayoutsTypes.CustomCreate : SessionPanelLayoutsTypes.ExtraCustom,
		[PageModuleType.blank]: custom ? SessionPanelLayoutsTypes.AddCustomEmbed : SessionPanelLayoutsTypes.ExtraEmbed, // TODO: make extras embed
		[PageModuleType.documents]: custom ? SessionPanelLayoutsTypes.CustomResources : SessionPanelLayoutsTypes.ExtraResources,
		[PageModuleType.similar_sessions]: custom ? SessionPanelLayoutsTypes.CustomSuggested : SessionPanelLayoutsTypes.ExtraSuggested,
		[PageModuleType.speakers]: custom ? SessionPanelLayoutsTypes.CustomSpeakers : SessionPanelLayoutsTypes.ExtraSpeaker,
		[PageModuleType.survey]: SessionPanelLayoutsTypes.AddEngagementSQP,
		[PageModuleType.quizzes]: SessionPanelLayoutsTypes.AddEngagementSQP,
		[PageModuleType.questions]: SessionPanelLayoutsTypes.AddEngagementQAndA,
		[PageModuleType.comment_box_prompt]: SessionPanelLayoutsTypes.AddEngagementCommentBox,
	});
};

const pathSlugCache = new Map<number, string>();
export const createSessionPanel = async (pageModule: PageModule, group: string, label?: string): Promise<SessionPanelWithPages> => {
	const pathSlug = pathSlugCache.get(pageModule.id as number) ?? await latinChars.slugify(pageModule.content?.custom_heading?.base ?? pageModule.content?.title.base);
	pathSlugCache.set(pageModule.id as number, pathSlug);

	return {
		label: label ?? pageModule.content?.custom_heading?.base,
		path: path(`/content/${group}/${PageModuleToGroupMap[pageModule.type] ?? 'unknown'}`),
		type: "page_module",
		key: pathSlug,
		pageModuleType: pageModule.type,
		pages: []
	};
};

export const createSessionPanelEdit = (panel: SessionPanelWithPages): SessionPanel[] => {
	return [
		{
			label: `${PageModuleToGroupMap[panel.pageModuleType ?? 0]} Library`,
			path: `${panel.path}/library`,
			type: "page_module_edit",
			key: `${panel.key}-library`,
			pageModuleType: panel.pageModuleType
		},
		{
			label: `Create ${PageModuleToGroupMap[panel.pageModuleType ?? 0]}`,
			path: `${panel.path}/create`,
			type: "page_module_edit",
			key: `create-${panel.key}`,
			pageModuleType: panel.pageModuleType
		}
	];
};

const WrapperTypes = {
	root: TopPanelWrapper,
	group: PanelWrapper,
	page_module: PanelWrapper,
	page_module_edit: SubPanelWrapper
};

export default function SessionPanelsRouter() {
	// leaving the door open to modify the router at runtime if needs be
	// but this is not being done right now
	const [panels, /*setPanels*/] = useState(_SessionPanels);
	const [routes, setRoutes] = useState<JSX.Element[]>([]);
	const location = useLocation();

	useEffect(() => {
		const _panels: JSX.Element[] = [];

		const addPanels = (level: SessionPanelWithPages[]) => {
			for (const panel of level) {
				const Component = panel.component as () => JSX.Element;
				const Wrapper = WrapperTypes[panel.type];
				const canShowTabs = panel.type === "group" || panel.type === "page_module";

				// we want the longest routes to be first in order (as much as possible)
				// and the longest routs live at the tips of these branches. Using unshift as we traverse
				// deeper in the panel paths will put the longer routes ahead of the shorter ones, 
				// which makes route determination easier in react router
				_panels.unshift(
					<Route path={panel.path} exact={true} key={panel.key}>
						<Wrapper
							title={panel.label}
							id={panel.key}
							enforceLabelTitle={panel?.enforceLabelTitle}
							rightHeader={panel.rightHeader}
						>
							<>
								{canShowTabs && <ModuleTabs />}
								{typeof Component === 'function' ? <Component /> : <h1>unmapped</h1>}
							</>
						</Wrapper>
					</Route>
				);

				if (panel.pages) {
					addPanels(panel.pages);
				}
			}
		};

		addPanels(panels);
		setRoutes(_panels);
	}, [panels]);

	// useEffect(() => {
	// 	console.log({ routes });
	// }, [routes]);

	return (
		<TransitionGroup className="transition-wrapper">
			<CSSTransition
				key={location.pathname}
				classNames="fade"
				timeout={200}
			>
				<Switch location={location}>
					{routes}
				</Switch>
			</CSSTransition>
		</TransitionGroup>
	);
}