import { Delete, GetHv, HvHostMap, Put } from "./helpers";
import { LeaderboardEntry, LeaderboardScoreForProfile } from '../types/leaderboard';


// using post instead of get in order to include data in request body instead of parameters
export function SearchLeaderboard(
	token: string,
	eventUUID: string,
	paginationLimit: number,
	paginationOffset: number,
	searchCriteria: string,
): Promise<{ results: LeaderboardEntry[]; total: number; }> {
	return GetHv(HvHostMap.eventData, `/e3-get-leaderboard/${eventUUID}/${paginationOffset}/${paginationLimit}?${searchCriteria}`, token);
}

// using post instead of get in order to include data in request body instead of parameters
export function GetLeaderboardForUser(
	token: string,
	eventUUID: string,
	paginationLimit: number,
	profileUuid: string
): Promise<{ results: LeaderboardEntry[]; total: number; }> {
	return GetHv(HvHostMap.eventData, `/e3-get-leaderboard/${eventUUID}/${profileUuid}/${paginationLimit}`, token);
}

export function UpdateLeaderboardPresets(token: string, channel: number, url: string, event_id: number): Promise<string> {
	return Put(`/v3/admin/leaderboard/presets/${channel}`, token, { url, event_id });
}

export function DeleteLeaderboardPresets(token: string, channel: number, event_id: number): Promise<number> {
	return Delete(`/v3/admin/leaderboard/presets/${channel}`, token, { event_id }, false, true);
}

export function GetLeaderboardDataForProfile(
	token: string,
	eventUUID: string,
	blProfileUUID: string
): Promise<LeaderboardScoreForProfile[]> {
	return GetHv(HvHostMap.eventData, `/e3-get-leaderboard/${eventUUID}/profile/${blProfileUUID}`, token);
}
