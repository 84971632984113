import { useCallback, useEffect, useState } from "react";
import { GetRecordingJWT } from "connection/sessions";
import { useTypedSelector } from "../store/reducers/use-typed-selector";
import { getCloudfrontUrl, jwtDecode, cloudfrontUrlMap } from "utils/utils";
import { FeatureFlagsEnum } from "types/working-model";

export function useGetRecordingJWT() {

	const [recordingJWT, setRecordingJWT] = useState<string | null>(null);

	useEffect(() => {
		async function getRecordingToken() {
			const jwtToken = await getRecordingJWT();
			setRecordingJWT(jwtToken);
		}

		getRecordingToken();

	}, []);

	return recordingJWT;
};


const recordingCloudfrontUrlMap: Record<string, string> = {
	...cloudfrontUrlMap,
	"sandbox-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "d1id3p5qs7opcd.cloudfront.net",
	// "sandbox-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "recordings.brandlive-sandbox.com", // TODO: Update after the CNAME record is in place
	"dev-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "recordings.brandlive-dev.com",
	"qa-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "recordings.brandlive-qa.com",
	"staging-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "recordings.brandlive-staging.com",
	"prod-events-3-ivs-recordings.s3-us-west-2.amazonaws.com": "recordings.brandlive.com",
	"prod-events-3-ivs-recordings-alt.s3-us-east-1.amazonaws.com": "recordings.brandlive.com",
};

export function useGetRecordingUrl() {
	const recordingRequiresJWTFeatureFlag = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags[FeatureFlagsEnum.recording_requires_jwt]);

	const getRecordingUrl = useCallback((url?: string, recordingJWT?: string | null): string | undefined => {
		if (!url || !recordingRequiresJWTFeatureFlag) { return url; }
		try {
			const urlObject = new URL(url);

			if (urlObject.hostname in recordingCloudfrontUrlMap) {
				urlObject.hostname = recordingCloudfrontUrlMap[urlObject.hostname];
				if (recordingJWT && urlNeedsRecordingJWT(urlObject.toString())) {
					urlObject.searchParams.set('jwt', recordingJWT);
				}
			}

			return urlObject.toString();
		} catch (e) {
			console.error('Error getting recording url', e, url);
			return url;
		}
	}, [recordingRequiresJWTFeatureFlag]);

	return getRecordingUrl;
}

const recordingDomains = [
	'd1id3p5qs7opcd.cloudfront.net',
	'recordings.brandlive-sandbox.com',
	'recordings.brandlive-dev.com',
	'recordings.brandlive-qa.com',
	'recordings.brandlive-staging.com',
	'recordings.brandlive.com',
];

export const urlNeedsRecordingJWT = (url?: string | null) => {
	try {
		if (!url) { return false; }

		const cloudfrontUrl = getCloudfrontUrl(url);
		const urlObj = new URL(cloudfrontUrl);

		return recordingDomains.includes(urlObj.hostname);
	} catch {
		return false;
	}
};

// Static async function for getting recording JWT 
// Use localStorage so the HLS player can get a refreshed token if it expires. 
export const getRecordingJWT = async (url?: string, token?: string): Promise<string> => {

	const recordingJWT = localStorage.getItem('recordingJWT');

	// Return existing token if it's not going to expire in the next 60 seconds
	if (recordingJWT) {
		try {
			const { exp } = jwtDecode<{ exp: number }>(recordingJWT) as { exp: number };
			if (exp > (Math.round((Date.now() / 1000)) + 60)) {
				return recordingJWT;
			}
		} catch (e) {
			console.error(e, 'Error decoding recording JWT');
			localStorage.removeItem('recordingJWT');
		}
	}
	try {
		const { jwtToken } = await GetRecordingJWT(url, token ?? '');
		localStorage.setItem('recordingJWT', jwtToken);
		return jwtToken;
	} catch (e) {
		console.error(e);
		return '';
	}


};