import { Action, AsyncAction } from "../../../types/actions";
import {
	GetAudienceInviteStatus,
	GetPasscodeListMembers,
	SearchMembers,
	SearchMembersCount
} from "../../../connection/passcode-list-members";

export const GET_PASSCODE_LIST_MEMBERS = "GET_PASSCODE_LIST_MEMBERS";
export function getPasscodeListMembers(
	token: string,
	passcode_list: number,
	offset: number,
	order: number
): AsyncAction {
	return {
		type: GET_PASSCODE_LIST_MEMBERS,
		promise: GetPasscodeListMembers(token, passcode_list, offset * 50, order)
	};
}

export const SEARCH_MEMBERS = "SEARCH_MEMBERS";
export function searchMembers(
	token: string,
	passcode_list: number,
	offset: number,
	value: string,
	order: number
): AsyncAction {
	return {
		type: SEARCH_MEMBERS,
		promise: SearchMembers(token, passcode_list, offset * 50, value.toLowerCase(), order)
	};
}

export const SEARCH_MEMBER_COUNT = "SEARCH_MEMBER_COUNT";
export function searchMemberCount(
	token: string,
	list: number,
	value: string
): AsyncAction {
	return {
		type: SEARCH_MEMBER_COUNT,
		promise: SearchMembersCount(token, list, value.toLowerCase())
	};
}

export const ADD_SELECTED_MEMBER = "ADD_SELECTED_MEMBER";
export function addSelectedMember(member: unknown): Action {
	return {
		type: ADD_SELECTED_MEMBER,
		payload: member
	};
}

export const REMOVE_SELECTED_MEMBER = "REMOVE_SELECTED_MEMBER";
export function removeSelectedMember(member: unknown): Action {
	return {
		type: REMOVE_SELECTED_MEMBER,
		payload: member
	};
}

export const REMOVE_ALL_SELECTED_MEMBERS = "REMOVE_ALL_SELECTED_MEMBERS";
export function removeAllSelectedMembers(): Action {
	return {
		type: REMOVE_ALL_SELECTED_MEMBERS,
		payload: null,
	};
}

export const REMOVE_ALL_MEMBERS = "REMOVE_ALL_MEMBERS";
export function removeAllMembers(): Action {
	return {
		type: REMOVE_ALL_MEMBERS,
		payload: null,
	};
}

export const GET_AUDIENCE_INVITE_STATUS = "GET_AUDIENCE_INVITE_STATUS";
export function getAudienceInviteStatus(token: string, channel: number, passcodeList: number, emails: string[]): AsyncAction {
	return {
		type: GET_AUDIENCE_INVITE_STATUS,
		promise: GetAudienceInviteStatus(token, channel, passcodeList, emails)
	};
}
