interface IProps {
	isRegistrationClosed: boolean;
	isFinalStep: boolean;
	// any is the return type for the t function
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	t: (key: string, fallback?: string | undefined, options?: Record<string, string> | undefined) => any;
}

const registrationFirstStepButtonText = ({ isRegistrationClosed, isFinalStep, t }: IProps) => {
	if (isRegistrationClosed) {
		return t("homepage:registration.Registration is closed");
	}
	if (isFinalStep) {
		return t("homepage:Submit", "Submit");
	}
	return t("homepage:Next", "Next");
};

export default registrationFirstStepButtonText;
