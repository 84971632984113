import { useEffect } from 'react';

import { useTranslate } from '../../../i18n/useTranslationModules';
import useTranslationRoute from '../../live-event/hooks/use-translation-route';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { showAlert } from '../../general-ui/alert/alert-service';

const useCheckBreakoutBootedStatus = (): void => {
	const eventUuid = useTypedSelector(state => state.LiveEventReducer?.eventBundle?.uuid);
	const { t } = useTranslate(useTranslationRoute());

	useEffect(() => {
		let isBooted = sessionStorage.getItem(`booted.${eventUuid}`) as any;

		if (isBooted) {
			isBooted = JSON.parse(isBooted);
		}

		if (eventUuid && isBooted) {
			sessionStorage.removeItem(`booted.${eventUuid}`);
			showAlert({
				message: `${t('Session host has removed you from this', '')} ${isBooted?.type || t('room', '')}.`,
				type: 'warning',
				duration: 10000
			});
		}
	}, [eventUuid, t]);
};

export default useCheckBreakoutBootedStatus;

