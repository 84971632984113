import { useMemo } from 'react';

import { BrandliveEvent } from '../types/working-model';
import { EPaletteModes, hex, opacity, ThemePack } from 'types/theme-packs';
import { useGetColorOptionsFromColor } from './colors/use-get-color-options-from-color';

export function parseColor(color: [string, number]): string {
	const [h, opacity] = color;
	const r = '0x' + h[1] + h[2];
	const g = '0x' + h[3] + h[4];
	const b = '0x' + h[5] + h[6];

	return `rgba(${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(
		b,
		16
	)},${opacity})`;
}

export function parseColorRGB(color: [hex, opacity]): string {
	const [h] = color;
	const r = '0x' + h[1] + h[2];
	const g = '0x' + h[3] + h[4];
	const b = '0x' + h[5] + h[6];

	return `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`;
}

//Export this helper functions so that live-event-document-head.tsx has access and the classOverrides and colorVariables only have to be edited in one place
export function getVariablesAndOverrides(
	event: BrandliveEvent | null,
	colorTheme: EPaletteModes,
	colorsOverrides?: ThemePack
): {
	colorVariables: string;
	classOverrides: string;
} {
	const colors = useGetColorOptionsFromColor(colorsOverrides || event?.settings?.design?.colors, colorTheme);

	return useMemo(() => {
		const _colorVariables: string[] = [];
		const _classOverrides: string[] = [];

		Object.entries(colors).forEach(([name, color]) => {
			// Short curcuit if color is falsy  or if it's not an array of [hex, opacity]
			if (!color || !Array.isArray(color) || color.length !== 2) {
				return;
			}
			const parsedColor = parseColor(color);
			_colorVariables.push(`--${name}: ${parsedColor};`);
			_colorVariables.push(`--${name}-rgb: ${parseColorRGB(color)};`);
			//The !important tag needs to stay here.
			//This is the finest detail of customization we can go to so they need to overide everything else. 
			//!important tags should be avoided everywhere else (if at all possible) so that these classes maintain their override power.
			_classOverrides.push(`
				.${name}-background { background-color: ${parsedColor} !important;}
				.${name}-border { border: 1px solid ${parsedColor} !important;}
				.${name}-color, .${name}-color>path, .${name}-color * { color: ${parsedColor} !important; fill: ${parsedColor} !important}
				.${name}-hover:hover { color: rgba(${parseColorRGB(color)}, .5) !important }
			`);
		});


		return {
			colorVariables: _colorVariables.join(' '),
			classOverrides: _classOverrides.join(' ')
		};
	}, [colors]);
}
