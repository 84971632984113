import { Action } from '../../../types/actions';
import { PageModuleType } from '../../../types/working-model';
import {
	RESET_SCROLL_STATE,
	SET_PANEL_OPEN,
	SET_SCROLL_TO_MODULE,
	SET_SESSION_GROUP,
} from '../../actions/admin/event-panel';

export interface EventPanelState {
	panelOpen: boolean;
	scrollToModule: PageModuleType | null;
	sessionEditorGroup: string | null;
}

export const initialEventPanelState: EventPanelState = {
	panelOpen: true,
	scrollToModule: null,
	sessionEditorGroup: null,
};

export default function EventPanelReducer(state: EventPanelState = initialEventPanelState, action: Action): EventPanelState {
	switch (action.type) {
		case SET_PANEL_OPEN: {
			return {
				...state,
				panelOpen: action.payload
			};
		}
		case SET_SCROLL_TO_MODULE: {
			return {
				...state,
				scrollToModule: action.payload
			};
		}
		case SET_SESSION_GROUP: {
			return {
				...state,
				sessionEditorGroup: action.payload
			};
		}
		case RESET_SCROLL_STATE: {
			return {
				...state,
				scrollToModule: null,
				sessionEditorGroup: null,
			};
		}
		default: return state;
	}
}