import { useMemo } from "react";
import { Channel } from "../../../types/working-model";
import './channel-avatar.scss';

interface Avatar {
	channel: Channel;
}

export function ChannelAvatar({ channel }: Avatar): JSX.Element {
	return useMemo(() => {
		const avatarBackgroundColor = channel.colors.primary || "#333333";
		const avatarSrc = channel.logos.color || channel.logos.white || channel.logos.gray;

		const avatarLogo = () => {
			return avatarSrc
				? <img src={avatarSrc} alt={channel.name} />
				: <h5>{channel.name[0]}</h5>;
		};

		return (
			<div className="channel-avatar" style={{ backgroundColor: avatarBackgroundColor }} >
				{avatarLogo()}
			</div>
		);
	}, [channel]);
}