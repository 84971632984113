import classNames from 'classnames';
import React from 'react';
import Icon, { COLORS } from '../icon';
import '../../../scss/live-event/base/general-ui/tag.scss';

export enum ETagColor {
	Orange = 'Orange',
	Red = 'Red',
}
const COLOR_MAP: Record<ETagColor, string> = {
	Orange: '#FF8164',
	Red: 'red',
};

interface TagProps {
	classNameTag?: string;
	text: string;
	color?: ETagColor;
	icon?: string;
	iconColor?: string;
	iconSize?: number;
	onClick?: () => void;
}

export const Tag = React.forwardRef<HTMLDivElement, TagProps>(({ classNameTag, text, color, icon, iconColor, iconSize = 16, onClick }, ref) => {
	return (
		<div
			className={classNames(classNameTag, "tag-container")}
			style={color ? { backgroundColor: COLOR_MAP[color] } : undefined}
			onClick={onClick}
			ref={ref}
		>
			{icon && <div className="tag-icon-container"><Icon name={icon} size={iconSize} color={iconColor ? iconColor : COLORS.WHITE} /></div>}
			<span>{text}</span>
		</div>
	);
});

Tag.displayName = 'Tag';

