import classNames from 'classnames';

import { Button } from '../../../../../general-ui/button/button';
import Icon, { COLORS, ICONS } from '../../../../../general-ui/icon';
import { VideoControlPopup } from './video-controls-popup';
import { useIsAdmin } from 'hooks/context.hooks';

import './streams-action-button.scss';
import '../../../../../../scss/live-event/base/session/video-control-popup.scss';
import { OptionalComponent } from 'utils/optional-component';

export type StreamsActionButtonProps = {
	className?: string;
	template?: string;
	onClick?: (() => void) | ((e: React.MouseEvent) => void);
	icon: string
	popupText: string;
	url?: string;
	darkIcon?: boolean;
	size?: number;
	editable?: boolean;
}
export const StreamsActionButton = ({
	className = '',
	template,
	onClick,
	icon,
	popupText,
	url,
	darkIcon,
	size = 20,
	editable = false
}: StreamsActionButtonProps) => {
	const isAdmin = useIsAdmin();

	return (
		url && !isAdmin ?
			<a
				className={classNames('streams-action-button', className, { 'dark-icon': darkIcon })}
				href={isAdmin ? undefined : url}
				target="_blank"
				rel="noreferrer"
			>
				<Icon name={icon} size={size} color={''} />
				<VideoControlPopup text={popupText} />
			</a> :

			<div className={classNames('social-link-icon-container', { 'admin-edit-social-link-icon-container': isAdmin })} onClick={onClick}>
				<Button
					classButton={classNames('streams-action-button', className, { 'dark-icon': darkIcon })}
					template={template}
				>
					<Icon name={icon} size={size} color={''} />
					<VideoControlPopup text={popupText} />
				</Button>
				<OptionalComponent display={isAdmin && editable}>
					<div className="admin-edit-social-link-icon">
						<Icon name={ICONS.EDIT} size={16} color={COLORS.WHITE} />
					</div>
				</OptionalComponent>
			</div>
	);
};
