import Socket from "../../../connection/socket";
import { Action } from "../../../types/actions";
import { EBreakoutPanels, IAttendeeVideoSettings, IBreakoutRoomProfile } from "../../../types/working-model";

import {
	ADD_CONNECTED_PROFILE,
	CLEAR_BREAKOUT_ROOM,
	REMOVE_CONNECTED_PROFILE,
	SET_BREAKOUT_ID,
	SET_CONNECTED_PROFILES,
	SET_LOADING_PROFILES,
	UPDATE_CONNECTED_PROFILES,
	SET_OPENTOK_ID,
	SET_BREAKOUT_PANEL_ITEM,
	SET_VIEW_ATTENDEE,
	ADD_LIST_OF_PROFILES,
	SET_ATTENDEE_VIDEO_SETTINGS,
	SET_BREAKOUT_ROOM_USAGE,
	SET_BREAKOUT_SOCKET,
	FORCE_BREAKOUT_RENDER,
	SET_CLOSING_ALL_ROOMS,
} from '../../actions/event/breakout-rooms';

interface IConnectedProfilesState {
	breakout_id: number | null;
	connected_profiles: IBreakoutRoomProfile[];
	loading: boolean;
	opentok_id: number | string | null;
	active_panel: EBreakoutPanels;
	view_attendee: number | null;
	attendee_video_settings: { [key: string]: IAttendeeVideoSettings };
	room_usage: any;
	socket: Socket | null;
	breakout_count_render: number;
	closing_all_rooms: boolean,
}

const defaultState: IConnectedProfilesState = {
	active_panel: EBreakoutPanels.attendees,
	attendee_video_settings: {},
	breakout_count_render: 0,
	breakout_id: null,
	closing_all_rooms: false,
	connected_profiles: [],
	loading: true,
	opentok_id: null,
	room_usage: null,
	socket: null,
	view_attendee: null,
};

export default function BreakoutRoomsReducer(state = defaultState, action: Action): IConnectedProfilesState {
	switch (action.type) {
		case SET_CONNECTED_PROFILES: {
			//should effectively prevent reordering and always place newly added admins to the end
			const connectedProfiles = new Map<number, any>([
				...state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]),
				...action.payload.map((blProfile: any) => [blProfile.bl_profile, blProfile])
			]);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case ADD_CONNECTED_PROFILE: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.set(action.payload.bl_profile, action.payload);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case ADD_LIST_OF_PROFILES: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			action.payload.forEach((profile: any) => {
				connectedProfiles.set(profile.bl_profile, profile);
			});
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case REMOVE_CONNECTED_PROFILE: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.delete(action.payload.bl_profile);
			// remove the users video settings:
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [action?.payload?.bl_profile]: current, ...restOfUserSettings } = { ...state.attendee_video_settings };
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values()),
				attendee_video_settings: restOfUserSettings,
			};
		}
		case UPDATE_CONNECTED_PROFILES: {
			const connectedProfiles = new Map(state.connected_profiles.map((blProfile: any) => [blProfile.bl_profile, blProfile]));
			connectedProfiles.set(action.payload.brandlive_profile, action.payload);
			return {
				...state,
				connected_profiles: Array.from(connectedProfiles.values())
			};
		}
		case SET_BREAKOUT_ID: {
			return {
				...state,
				breakout_id: action.payload || null,
			};
		}
		case CLEAR_BREAKOUT_ROOM: {
			return defaultState;
		}
		case SET_LOADING_PROFILES: {
			return {
				...state,
				loading: !!action.payload,
			};
		}
		case SET_OPENTOK_ID: {
			return {
				...state,
				opentok_id: action.payload,
			};
		}
		case SET_BREAKOUT_PANEL_ITEM: {
			return {
				...state,
				active_panel: action.payload,
			};
		}
		case SET_VIEW_ATTENDEE: {
			return {
				...state,
				view_attendee: action.payload,
			};
		}
		case SET_ATTENDEE_VIDEO_SETTINGS: {
			if (!action.payload?.bl_profile) return state;
			return {
				...state,
				attendee_video_settings: {
					...state.attendee_video_settings,
					[action.payload.bl_profile]: action.payload,
				},
			};
		}

		case SET_BREAKOUT_ROOM_USAGE: {
			return {
				...state,
				room_usage: action.payload,
			};
		}

		case SET_BREAKOUT_SOCKET: {
			return {
				...state,
				socket: action.payload,
			};
		}

		case FORCE_BREAKOUT_RENDER: {
			return {
				...state,
				breakout_count_render: state.breakout_count_render + 1,
			};
		}

		case SET_CLOSING_ALL_ROOMS: {
			return {
				...state,
				closing_all_rooms: action.payload,
			};
		}

		default: return state;
	}
}