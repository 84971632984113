import { CreateRegistrationQuestion, RegistrationQuestion } from '../types/working-model';
import { Post, Get, Put, Delete } from './helpers';

export const GetRegistrationQuestions = async (channel: number, eventUUID: string, token: string): Promise<any> => {
	return Get(`/v3/admin/events/registration-questions/${channel}/${eventUUID}`, token, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const GetRegistrationQuestionsWithoutTranslations = async (channel: number, token: string): Promise<any> => {
	return Get(`/v3/admin/events/registration-questions/${channel}`, token, {}, true)
		.then(async res => {
			return await res.json();
		});
};

export const SaveRegistrationQuestion = async (question: RegistrationQuestion, eventUUID: string, token: string): Promise<any> => {
	return Put(`/v3/admin/events/registration-questions/${question.registration_question}`, token, { question, eventUUID }, false, true)
		.then(async res => {
			return await res.json();
		});
};

export function CreateRegQuestion(question: CreateRegistrationQuestion, eventUUID: string, token: string): Promise<RegistrationQuestion> {
	return Post('/v3/admin/events/registration-questions', token, { question, eventUUID });
}

export function DeleteRegistrationQuestion(questionId: number, token: string): Promise<any> {
	return Delete(`/v3/admin/events/registration-questions/${questionId}`, token, null, true);
}
