import { useParams, Redirect, useRouteMatch } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { SessionTabNames, SessionPanelLayoutsTypes, PageModuleType } from "../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { ParamsProps } from "../../../../../../live-event/live-event";
import ActiveProductsList from "../extras/products/active-products-list";
import { usePageModuleGroup } from "../hooks/panel.hooks";
import EmptyStatePanel from "./empty-state-panel";
import { PageModuleTypeMap, SessionPanelMap } from "../session-panel-route-map";
import { useHistoryState } from "../../../../../../../utils/use-history-state";
import ActiveResourcesList from "../extras/resources/active-resources-list";

// custom tabs can be of any content type OR be empty
// currently, I am using the empty and unused .feed type to hold onto any 
// custom tabs that have no content because we won't know what type they are 
// until the user picks a content type, at that point we'll update the page_module type to match
// the content type
export const CustomTab: React.FC<unknown> = () => {
	const { page_module } = useParams<ParamsProps>();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const moduleType = workingSession?.modules.find(module => module.id === parseInt(page_module))?.type;
	const tabRenderer = (type: PageModuleType) => {
		switch (type) {
			case PageModuleType.products:
				return <ActiveProductsList />;
			case PageModuleType.documents:
				return <ActiveResourcesList />;
			case PageModuleType.similar_sessions:
				return <></>;
			case PageModuleType.speakers:
				return <></>;
			case PageModuleType.blank:
				return <></>;
			case PageModuleType.feed:
			default: {
				return (
					<div className="empty-state-panel-container">
						<EmptyStatePanel
							title="Add Content to Custom Tab"
							description="Tabs without content will not be visible to the audience"
						/>
					</div>
				);
			}
		}
	};

	return tabRenderer(moduleType ?? PageModuleType.feed);
};

export default function CustomTabPanel(): JSX.Element {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const adminPath = useGetAdminUrl();
	const group = usePageModuleGroup();
	const hasContent = group?.modules?.length;
	const match = useRouteMatch<{ customPath: string }>();
	const historyState = useHistoryState();

	// when the user arrives at the custom tab for the first time, redirect to the first subtab (which may be invisible)
	const getFirstTab = () => {
		const typeMap = PageModuleTypeMap('custom');
		const firstModule = workingSession?.modules.find(module => group?.modules?.includes(module.id as number));
		const type = firstModule?.type;
		if (type) {
			const panel = typeMap[type];
			if (panel) {
				return adminPath({ path: SessionPanelMap[panel as SessionPanelLayoutsTypes] ?? SessionPanelMap[SessionTabNames.Content_v2], customPath: match.params.customPath, page_module: firstModule?.id });
			}
		}

		return adminPath({ path: SessionPanelMap[SessionTabNames.Content_v2] });
	};

	return hasContent ? (
		<>
			{!!group && (
				<Redirect to={{ pathname: getFirstTab(), state: historyState }} />
			)}
		</>
	) : (
		<EmptyStatePanel
			title="Add Content to Custom Tab"
			description="Tabs without content will not be visible to the audience"
		/>
	);
}
