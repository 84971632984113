import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { refreshLiveEventBundle } from "../../../../connection/live-events/event";
import { PatchBreakoutRoom, PatchBreakoutRooms, RefreshBreakoutRoomsForSessionHv } from "../../../../connection/sessions";
import { getBreakoutRoomsForSessionHv, setClosingAllRooms } from "../../../../store/actions/event/breakout-rooms";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { BreakoutRoomPatch, SessionTypesEnum, BreakoutSessionHost, Session } from "../../../../types/working-model";
import { isDevEnvironment } from "../../../../utils/utils";
import { DisableZoomRoom } from '../../../../connection/zoom';
import { SaveBreakoutRoomsForSession } from '../../../../connection/sessions';
import socketManager from "../../../../connection/socket-main-thread/socket-manager";
import { SocketConnection } from "../../../../connection/socket-main-thread/socket-connection";
import { getLogger } from "../../../../utils/debug-logger";

const debugBreakouts = getLogger('bl-breakouts:use-breakout-rooms');

export interface IReturnUseBreakoutRoomReturn {
	closeBreakoutRoom: (
		breakoutSessionId: number,
		breakoutRoomId: number,
		closeFailedCallback?: (...args: any[]) => any
	) => void;
	closeBreakoutSession: (
		breakoutSessionId: number,
		session?: Session
	) => void;
	isBreakoutSessionHost: (breakoutSessionId: number) => boolean;
	setRefreshCallback: (...args: any[]) => any;
}

const useBreakoutRoom = (): IReturnUseBreakoutRoomReturn => {
	const dispatch = useDispatch();

	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const attendeeToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const adminToken = useTypedSelector(state => state.AuthReducer.token);
	const blProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const event = eventBundle || workingEvent;
	const eventUuid = event?.uuid;
	const socket = useRef<SocketConnection>();
	const refreshCallback = useRef<(...args: any[]) => any>(() => { return; });
	const closingSession = useRef<number>(0);

	// this component is used both in attendee side AND admin side - as such, we have to try to handle 
	// both token types. TODO: Refactor this
	const token = attendeeToken || adminToken;

	// create/destroy socket
	useEffect(() => {
		if (eventUuid) {
			socket.current = socketManager.get(`${eventUuid}-breakouts`);
		}

		return () => {
			socketManager.leave(`${eventUuid}-breakouts`);
		};
	}, [eventUuid]);

	const closeBreakoutRoom = async (
		breakoutSessionId: number,
		breakoutRoomId: number,
		closeFailedCallback?: (...args: any[]) => any
	): Promise<void> => {
		closingSession.current = breakoutSessionId;

		if (!validate(breakoutSessionId, breakoutRoomId) || !event || !token) {
			if (closeFailedCallback) closeFailedCallback();
			return;
		}

		const session = event.sessions.find(_session => _session.session === breakoutSessionId);

		if (!session || !session.breakout_rooms) {
			if (closeFailedCallback) closeFailedCallback();
			return;
		}

		const breakoutRoom = session.breakout_rooms.find(breakout_room => breakout_room.id === breakoutRoomId);

		if (!breakoutRoom || breakoutRoom?.closed) {
			if (closeFailedCallback) closeFailedCallback();
			return;
		}

		const didUpdateSucceed = await PatchBreakoutRoom(token, {
			...breakoutRoom,
			session: session.session,
			id: breakoutRoom.id as number,
			closed: true
		}) as boolean;

		if (!didUpdateSucceed) {
			if (closeFailedCallback) closeFailedCallback();
			return;
		}

		// TODO: do not reload event bundle just because the room has been closed
		// Room is not updated immediately after awaiting this
		await refreshBundle(session.uuid);

		// TODO: do not send breakout refresh message from client-side - anyone can send this, should be sent from back-end like everything else
		socket.current?.sendMessage({ type: 'refresh-breakout-rooms', data: { session: session?.uuid } });
		closingSession.current = breakoutSessionId;

		// Room closing flag set to false when updated room is received
	};

	//Kick everyone out of the zoom call and update the break out rooms after clicking END ALL ROOM in the main banner.
	const endedZoomCall = useCallback(async (_session: Session | undefined, usedZoom: boolean): Promise<void> => {
		if (_session && _session.breakout_rooms && token) {
			//iterate through zoom rooms to end the zoom sessions via the zoom api
			for (const room of _session.breakout_rooms) {
				if (room.video_settings.zoom.enabled) {
					await DisableZoomRoom(room.video_settings.zoom.id, token);
				}
			}

			//update the rooms to all be zoom disabled (meaning ended)
			const rooms = _session.breakout_rooms.map(room => {
				if (room.video_settings?.zoom) {
					room.video_settings.zoom.enabled = false;
				}
				return room;
			});

			//update the breakout rooms
			await SaveBreakoutRoomsForSession(rooms, usedZoom, _session.session, token);

		}
	}, [token]);

	const isBreakoutSessionHost = useCallback((breakoutSessionId: number): boolean => {
		const session = event?.sessions.find(_session => _session.session === breakoutSessionId);

		if (!session || !session.breakout_session_hosts) {
			return false;
		}

		return (session.breakout_session_hosts as any[])
			.some((break_session_host: BreakoutSessionHost) => break_session_host.email === blProfile?.email);
	}, [blProfile?.email, event?.sessions]);

	const reloadBreakoutRooms = useCallback(async (message: any) => {
		if (!token) {
			return;
		}

		await dispatch(getBreakoutRoomsForSessionHv(message.session, token));
		refreshCallback.current();
	}, [dispatch, token]);

	const refreshBundle = useCallback(async (sessionUuid: string): Promise<void> => {
		if (!event || !token) {
			return;
		}

		if (!isDevEnvironment()) {
			const eventNamePass = window.location.pathname.split("/")?.[1];

			if (eventNamePass) {
				const host = window.location.hostname;

				await RefreshBreakoutRoomsForSessionHv(sessionUuid, token);
				await refreshLiveEventBundle(host.replace(/\.brandlive.*\.com/, '.brand.live'), eventNamePass);
				return;
			}
		} else {
			await RefreshBreakoutRoomsForSessionHv(sessionUuid, token);
			await refreshLiveEventBundle('dev', event.uuid);
			return;
		}
	}, [event, token]);

	const setRefreshCallback = (callback: (...args: any[]) => any) => {
		refreshCallback.current = callback;
	};

	const validate = useCallback((breakoutSessionId: number, breakoutRoomId?: number): boolean => {
		if (!token || !event) {
			return false;
		}

		const session = event.sessions.find(_session => _session.session === breakoutSessionId);

		if (!session || session.session_type !== SessionTypesEnum.breakoutRooms || !session.breakout_rooms) {
			return false;
		}

		if (breakoutRoomId && (!session.breakout_rooms || !session.breakout_rooms?.some(breakout_room => breakout_room.id === breakoutRoomId))) {
			return false;
		}

		return isBreakoutSessionHost(breakoutSessionId);
	}, [event, isBreakoutSessionHost, token]);

	const closeBreakoutSession = useCallback(async (
		breakoutSessionId: number,
		session?: Session
	): Promise<void> => {
		debugBreakouts('Going to close breakout session.');

		if (!token || !event || !session && (!event || !validate(breakoutSessionId))) {
			debugBreakouts('No token, event, session, or the breakout uuid is invalid');
			return;
		}

		const _session = session ? session : event.sessions.find(_session => _session.session === breakoutSessionId);

		if (!_session || !_session?.breakout_rooms) {
			debugBreakouts('No session or the session has no breakout rooms');
			return;
		}

		const usedZoom = _session?.breakout_session?.use_zoom;

		if (usedZoom) {
			debugBreakouts('Ending zoom call');
			endedZoomCall(_session, usedZoom);
		}

		const roomsToClose = _session.breakout_rooms
			.filter(breakout_room => !breakout_room.closed)
			.map(breakout_room => {
				return {
					...breakout_room,
					closed: true
				} as BreakoutRoomPatch;
			});

		if (!roomsToClose.length) {
			debugBreakouts('No rooms to close');
			return;
		}

		dispatch(setClosingAllRooms(true));
		const didUpdateSucceed = await PatchBreakoutRooms(token, roomsToClose);
		if (!didUpdateSucceed) {
			dispatch(setClosingAllRooms(false));
			debugBreakouts('Ending rooms failed');
			return;
		}

		// TODO: do not update bundle because of updated data, send selective message from back end notifying users that bundle has been updated
		await refreshBundle(_session.uuid);

		// TODO: send message of ended breakout from back-end
		socket.current?.sendMessage({ type: 'refresh-breakout-rooms', data: { session: _session?.uuid } });
		closingSession.current = breakoutSessionId;

		// Until the updated rooms are received, the End All Rooms button will be enabled
		// To fix, this dispatch should occur when the updated bundle arrives
		dispatch(setClosingAllRooms(false));
		debugBreakouts('Ending rooms succeeded');
	}, [dispatch, endedZoomCall, event, refreshBundle, token, validate]);

	useEffect(() => {
		socket.current?.addListener('refresh-breakout-rooms', reloadBreakoutRooms);

		return () => {
			socket.current?.removeListener('refresh-breakout-rooms', reloadBreakoutRooms);
		};
	}, [reloadBreakoutRooms, socket]);

	return {
		closeBreakoutRoom,
		closeBreakoutSession,
		isBreakoutSessionHost,
		setRefreshCallback
	};
};

export default useBreakoutRoom;
