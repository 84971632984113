import { useMemo } from "react";

type HtmlToReactProps = {
	html: string;
	tailElement?: JSX.Element;
};

export const getCleanedHtmlText = (html: string): string => {
	const element = document.createElement('div');
	element.innerHTML = html;

	if (!element.children.length) {
		element.innerHTML = `<p>${html}</p>`;
	}

	let text = '';

	// innerText by itself does not preserve newlines
	// so to attempt to display things properly, nest in one level to make sure that we
	// preserve newlines in lists. If they go deeper than that, they get what they get.
	for (const el of element.children) {
		if (el.childNodes.length > 1) {
			for (const child of el.childNodes) {
				if (child.nodeType === child.TEXT_NODE) {
					text += " " + (child as Text).textContent;
				} else if (child.nodeType === child.ELEMENT_NODE) {
					text += " " + (child as HTMLElement).innerText;
				}
			}
		} else {
			text += " " + (el as HTMLElement).innerText;
		}
	}

	return text;
};

// This just takes the HTML string from the editor and converts everything to plain text.
// It also clamps the text to 150 characters and adds a tail element if the text is longer than that.
const HtmlToReact: React.FC<HtmlToReactProps> = ({ html, tailElement }) => {
	const processElements = useMemo(() => {
		const clampedText = getCleanedHtmlText(html);
		// const useTail = (clampedText.length < text.length) && tailElement;

		return (
			<><p>{clampedText}</p>{tailElement}</>
		);
	}, [html, tailElement]);

	return useMemo(() => <>{processElements}</>, [processElements]);
};

export default HtmlToReact;
