import { CreateSurvey, SurveyQuestionType } from "../../../../../../../types/working-model";

export const FEEDBACK_SCALE = [
	{
		label: "3",
		value: "3",
	},
	{
		label: "4",
		value: "4",
	},
	{
		label: "5",
		value: "5",
	},
];

export enum FEEDBACK_SHAPE_TYPES {
	Star = "star",
	Thumb = "thumb",
	Heart = "heart",
	Smiley = "smiley",
}

export const INITIAL_SURVEY: CreateSurvey = {
	name: {
		base: '',
		changed: '',
	},
	description: {
		base: '',
		changed: '',
	},
	completion_message: {
		base: '',
		changed: '',
	},
	allow_retake: false,
	show_results: false,
	questions: [],
	survey_type: undefined,
	passing_threshold: null,
};

export const SELECT_OPTIONS = [
	{
		label: 'Single choice',
		value: String(SurveyQuestionType.radio),
	},
	{
		label: 'Multiple choice',
		value: String(SurveyQuestionType.checkbox),
	},
	{
		label: 'Comment box',
		value: String(SurveyQuestionType.text),
	}
];
