import { useEffect, useState } from "react";
import { throttle } from "underscore";

interface Props {
	scrolledContainerRef: HTMLDivElement | null;
	offset?: number;
	throttleTime?: number;
	initialState?: boolean;
}

interface ReturnType {
	hasScrolledToTop: boolean;
	setHasScrolledToTop: React.Dispatch<React.SetStateAction<boolean>>;
}

const useHasScrolledToTop = ({
	scrolledContainerRef,
	offset = 0,
	throttleTime = 100,
	initialState = false,
}: Props): ReturnType => {
	const [hasScrolledToTop, setHasScrolledToTop] = useState(initialState);

	useEffect(() => {
		const currentRef = scrolledContainerRef;
		let lastScrollTop = 0;
		const handleScroll = throttle(() => {
			if (currentRef) {
				const scrollTop = currentRef.scrollTop;
				const top =
					currentRef.scrollHeight >=
					scrollTop + currentRef.scrollHeight - offset;
				// reach top AND scroll direction must be upwards
				if (top && scrollTop <= lastScrollTop) {
					setHasScrolledToTop(true);
				} else {
					setHasScrolledToTop(false);
				}
				lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
			}
		}, throttleTime);
		if (currentRef) {
			currentRef.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener("scroll", handleScroll);
			}
		};
	}, [scrolledContainerRef, offset, throttleTime]);

	return {
		hasScrolledToTop,
		setHasScrolledToTop
	};
};

export default useHasScrolledToTop;
