import { PGSortOrder } from "../components/live-event/marketing-page/marketing-registered-directory/marketing-registered-directory";
import { BLChannelProfile, BlProfileSearchResult, RegistrationQuestion } from "../types/working-model";
import { GetHv, HvHostMap, PostHv } from "./helpers";

export function SearchProfiles(
	channelId: number,
	eventId: number,
	criteria: string,
	paginationLimit: number,
	paginationOffset: number,
	token: string,
	filters?: any[] | null,
	columns?: Pick<RegistrationQuestion, 'registration_question' | 'type'>[] | null,
	sortColumn?: RegistrationQuestion["registration_question"] | null,
	sortOrder?: PGSortOrder | null,
): Promise<BlProfileSearchResult> {
	return PostHv(HvHostMap.eventData, '/e3-live-event/profiles/search', { channelId, eventId, criteria, paginationLimit, paginationOffset, filters, columns, sortColumn, sortOrder }, token);
}

export function BookmarkProfile(channel: number, profileUuid: string, token: string): Promise<any> {
	return PostHv(HvHostMap.eventData, '/e3-bookmark-profile', { channel, profileUuid }, token);
}

export function GetAttendeeProfile(profileUuid: string, token: string, channelUrl: string, eventUrl: string): Promise<BLChannelProfile> {
	return GetHv(HvHostMap.eventData, `/e3-live-event/profiles/search/${profileUuid}/${channelUrl}/${eventUrl}`, token);
}

export function BlockChatParticipant(channel: number, token: string, profileUuidToBlock: string, activeUserPBlProfile: number, status: 'blocked' | 'unblocked'): Promise<any> {
	return PostHv(HvHostMap.eventData, '/e3-block-participant', { profileUuidToBlock, activeUserPBlProfile, channel, status }, token);
}