import { WorkingPasscodeList } from '../components/admin/create/registration/passcode-list';
import { PasscodeList, PasscodeListMemberType, PASSCODE_LIST_TYPE } from '../types/working-model';
import { Put, Get, Post, Delete } from './helpers';

export const GetAssociatedEvents = async (token: string, passcode_list: number, channel: number): Promise<string[]> => {
	return Post(`/v3/admin/content/associated-events`, token, { passcode_list, channel }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			return [];
		}).catch(e => ({ error: e }));
};

export const GetAssociatedEventsDetails = async (token: string, event_list: string[], channel: number): Promise<({ name: string, uuid: string }[])> => {
	if (event_list.length === 0) return [];
	return Post(`/v3/admin/content/associated-events-details`, token, { event_list, channel }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			return [];
		}).catch(e => ({ error: e }));
};

export const GetLinkedEmailToEvents = async (token: string, email: string, channel: number): Promise<string[]> => {
	if (!email || !channel) return [];
	return Post(`/v3/admin/content/linked-emails-to-events`, token, { email, channel }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}
			return [];
		}).catch(e => ({ error: e }));
};


export async function GetMagicLinksListTemplate(token: string, event_uuid: string): Promise<({ csv: string })> {
	return Post(`/v3/admin/events/${event_uuid}/magic-links`, token, {});
}

export const GetPasscodeLists = async (channel: number, token: string): Promise<any> => {
	return Get(`/v3/admin/content/passcode-lists/${channel}`, token, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			return { error: res.status };
		}).catch(e => ({ error: e }));
};


export const CreateNewPasscodeList = async (token: string, channel: number, passcode_list: WorkingPasscodeList, uuid?: string, list_id?: number, dma_id?: string | null): Promise<any> => {
	if (passcode_list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS) {
		return Post(`/v3/admin/events/${uuid}/magic-links/upload`, token, { ...passcode_list, channel: channel, list_id: list_id, dma_id }, false, {}, true)
			.then(async res => {
				if (res.ok) {
					return await res.json();
				}

				return { error: res.status };
			}).catch(e => ({ error: e }));
	}
	else if (passcode_list.url === '') {
		return Post('/v3/admin/content/passcode-lists/no-members', token, { ...passcode_list, channel: channel, dma_id }, false, {}, true)
			.then(async res => {
				if (res.ok) {
					return await res.json();
				}

				return { error: res.status };
			}).catch(e => ({ error: e }));
	}
	else {
		return Post('/v3/admin/content/passcode-lists', token, { ...passcode_list, channel: channel, dma_id }, false, {}, true)
			.then(async res => {
				if (res.ok) {
					return await res.json();
				}

				return { error: res.status };
			}).catch(e => ({ error: e }));
	}
};

export function UpdateMaxUses(
	token: string,
	uses: number,
	list: number | undefined
): Promise<any> {
	if (list) {
		return Put(
			`/v3/admin/content/passcode-lists/update-list/max-uses`,
			token,
			{
				uses,
				list,
			}
		);
	}
	else { return Promise.resolve(); }
}

export function UpdateMasterPasscode(
	token: string,
	passcode: string,
	list: number | undefined
): Promise<any> {
	if (list) {
		return Put(
			`/v3/admin/content/passcode-lists/update-list/master-passcode`,
			token,
			{ passcode, list }
		);
	}
	else { return Promise.resolve(); }
}

export const UploadMembers = async (token: string, channel: number, filename: string, passcode_list: number, type: number, url: string): Promise<any> => {
	return Post('/v3/admin/content/passcode-lists/members', token, { filename, passcode_list, type, url, channel }, false, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			return { error: res.status };
		}).catch(e => ({ error: e }));
};

export const DeleteMembers = async (members: PasscodeListMemberType[], token: string): Promise<any> => {
	const membersToDelete: number[] = members.map((member) => member.passcode_list_member);

	return Delete('/v3/admin/content/passcode-lists/removemember', token, { passcodeListMembers: membersToDelete }, false, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			return { error: res.status };
		}).catch(e => ({ error: e }));
};

export async function GetPasscodeListEncrypt(token: string, channel: number, passcodelist: number): Promise<any> {
	return Get(`/v3/admin/content/passcode-lists/encrypt/${channel}/${passcodelist}`, token);
}

export async function DeletePasscodeList(token: string, channel: number, list: PasscodeList): Promise<any> {
	return Put(`/v3/admin/content/passcode-lists/delete`, token, { channel, list }, true, false);
}