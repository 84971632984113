import classNames from "classnames";

import { useIsNewModuleGrouping } from "../../../../hooks/session.hooks";
import { OptionalComponent } from "../../../../utils/optional-component";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { Session } from "../../../../types/working-model";

interface IScrollOverlayProps {
	mobileOnly?: boolean;
	session: Session | null;
	forceOverlay?: boolean;
}

const ScrollOverlays = ({ mobileOnly, session, forceOverlay }: IScrollOverlayProps) => {

	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const promotion = useTypedSelector(state => state.ChatReducer.chatPromotion);

	const isChatOverlay = (!mobileOnly && session?.layout?.overlayChat || forceOverlay);

	return (
		<OptionalComponent display={isModuleGroupingV2}>
			<>
				<div onTransitionEnd={(e) => e.stopPropagation()} className={classNames('messages-above', { "hide-fade": isChatOverlay })}></div>
				{/* do not show the bottom overlay if a fireside hand is raised because it covers the button */}
				<OptionalComponent display={!promotion}>
					<div onTransitionEnd={(e) => e.stopPropagation()} className={classNames('messages-below', { "hide-fade": isChatOverlay })}></div>
				</OptionalComponent>
			</>
		</OptionalComponent>
	);
};

export default ScrollOverlays;
