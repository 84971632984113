import { Suspense, lazy } from 'react';
const CustomHTMLWrapped = lazy(() => import('./edit-html-wrapped'));

interface Props {
	onChange: (html: string) => void;
	defaultValue: string;
	maxHeight?: string;
}

export default function CustomHTML(props: Props): JSX.Element {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<CustomHTMLWrapped {...props} />
		</Suspense>
	);
}
