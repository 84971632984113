import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { useParams } from "react-router";
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { CreateSurvey, Survey, SurveyQuestion, SurveyType } from "../../../../../../../types/working-model";
import { OptionalComponent } from "../../../../../../../utils/optional-component";
import Divider from "../../../../../../general-ui/divider/divider";
import { ParamsProps } from "../../../../../../live-event/live-event";
import { SurveyQuestionComponent } from "../../../editor/surveys/survey-question";
import { useDeleteQuestion } from "../hooks/engage.hooks";
import { ErrorsTypes, EngagementErrorsTexts } from "../types/engage";
import { handleDragEndHandler, removeOptionHandler, saveQuestionHandler } from "../utils/engage-create.utils";
import CreateEngageOptions from "./create-engage-options";
import CreateEngageQuestion from "./create-engage-question";

interface CreateEngageContentProps {
	survey_type: SurveyType;
	validationErrors: { [p: string]: string } | null;
	workingSurvey: Survey | CreateSurvey;
	workingQuestion: SurveyQuestion | null;
	setWorkingQuestion: Dispatch<SetStateAction<SurveyQuestion | null>>;
	setWorkingSurvey: Dispatch<SetStateAction<Survey | CreateSurvey | null>>;
	clearErrors: (key: (ErrorsTypes | null)) => void;
	validateQuestionHandler: () => { [p: string]: string };
}

export default function CreateEngageContent({
	survey_type,
	validationErrors,
	workingSurvey,
	workingQuestion,
	setWorkingQuestion,
	setWorkingSurvey,
	clearErrors,
	validateQuestionHandler
}: CreateEngageContentProps): JSX.Element | null {
	const { language } = useParams<ParamsProps>();
	const [isGrabbing, setIsGrabbing] = useState<boolean>(false);
	const [titleHasFocus, setTitleHasFocus] = useState(true);

	// Checks if all the fields are added in except for "Not Saved"
	const disableSave = !!Object
		.keys(validationErrors || {})
		.filter(error =>
			validationErrors?.[error] !== EngagementErrorsTexts.NotSavedQuestion).length;

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragStart = () => setIsGrabbing(true);
	const handleDragEnd = handleDragEndHandler({ setIsGrabbing, setWorkingSurvey });
	const editQuestion = (question: SurveyQuestion) => {
		setWorkingQuestion(question);
	};
	const handleDeleteQuestion = useDeleteQuestion({
		workingSurvey,
		setWorkingSurvey,
		setWorkingQuestion,
		clearErrors,
	});

	const saveQuestion = saveQuestionHandler({
		workingQuestion,
		setWorkingSurvey,
		setWorkingQuestion,
		validateQuestionHandler,
		clearErrors,
	});

	const isQuiz = workingSurvey?.survey_type === SurveyType.quiz;

	const sortableContextItems = useMemo(() => {
		return workingSurvey.questions.map((question: SurveyQuestion) => String(question.survey_question));
	}, [workingSurvey]);

	const onCancel = useCallback(() => {
		setTitleHasFocus(true);

		setWorkingSurvey(survey => {
			if (!survey) return null;

			const question = survey.questions.find(q => q.survey_question === workingQuestion?.survey_question);

			// user hit cancel but this question was already saved - do nothing
			if ('survey' in survey && question && 'question' in question) {
				return survey;
			}

			if (question?.title.changed) {
				setWorkingQuestion(null);

				return survey;
			}

			return {
				...survey,
				questions: survey.questions.filter(q => q.survey_question !== workingQuestion?.survey_question),
			};
		});

		setWorkingQuestion(null);
	}, [setWorkingQuestion, setWorkingSurvey, workingQuestion?.survey_question]);


	const removeOption = removeOptionHandler(setWorkingQuestion);

	const [shouldSave, setShouldSave] = useState(false);

	const handleSaveQuestion = () => {
		// if workingQuestion options length
		// filter out any options that are empty
		const options = workingQuestion?.options || [];
		for (const option of options) {
			if (option.label.changed !== 'true' && !option.label?.base.trim().length) {
				removeOption(option.survey_question_option);
			}
		}

		// we need to move the saving out into a useEffect or we'll be trying to save stale data because of the updates above
		setShouldSave(true);
	};

	// save the question
	useEffect(() => {
		if (shouldSave) {
			setShouldSave(false);
			saveQuestion();
		}
	}, [saveQuestion, shouldSave]);

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			modifiers={[restrictToParentElement]}
			autoScroll={false}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={sortableContextItems}
				strategy={rectSortingStrategy}
			>
				<div className="e-engage-list">
					{workingSurvey.questions.map((question: SurveyQuestion) => {
						const editedQuestion = workingQuestion?.survey_question === question.survey_question ? workingQuestion : question;

						return (
							<Fragment key={question.survey_question}>
								{(workingQuestion?.survey_question !== question.survey_question) && (
									<SurveyQuestionComponent
										id={String(question.survey_question)}
										items={workingSurvey.questions.length}
										question={editedQuestion}
										language={language}
										editQuestion={editQuestion}
										handleDeleteQuestion={handleDeleteQuestion}
										className={isGrabbing ? 'grabbing' : undefined}
									/>
								)}

								<OptionalComponent display={!!(workingQuestion && workingQuestion.survey_question === question.survey_question)}>
									<div className="e-engage-card editable">
										<CreateEngageQuestion
											survey_type={survey_type}
											workingQuestion={workingQuestion}
											validationErrors={validationErrors}
											clearErrors={clearErrors}
											setWorkingQuestion={setWorkingQuestion}
											setTitleHasFocus={setTitleHasFocus}
										/>
										<Divider />
										<CreateEngageOptions
											workingQuestion={workingQuestion}
											isQuiz={isQuiz}
											validationErrors={validationErrors}
											clearErrors={clearErrors}
											setWorkingQuestion={setWorkingQuestion}
											canFocus={!titleHasFocus}
											survey_type={survey_type}
										/>
										<div className="e-engage-card-survey-actions">
											<button className="small" onClick={onCancel}>Cancel</button>
											<button className="small lemonade" onClick={handleSaveQuestion} disabled={disableSave}>Save</button>
										</div>
									</div>
								</OptionalComponent>
							</Fragment>
						);
					})}
				</div>
			</SortableContext>
		</DndContext>
	);
}