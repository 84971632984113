//const permissionExample = "groups.*.events.*.sessions.*";

import { EPermissions } from "types/working-model";

export interface PermissionObject {
	groupUuid?: string;
	eventUuid?: string;
	sessionUuid?: string;
	allGroupsInChannel?: boolean;
	allEventsInGroup?: boolean;
	allSessionsInEvent?: boolean;
}

export const arrayToTuples = (arr: string[]): [string, string][] => {
	const chunks: [string, string][] = [];
	for (let i = 0; i < arr.length; i += 2) {
		const firstItem = arr[i];
		const secondItem = arr[i + 1];
		chunks.push([firstItem, secondItem]);
	}
	return chunks;
};

export const isGroupTuple = (tuple: [string, string]): boolean => {
	return tuple[0] === "groups";
};

export const isEventTuple = (tuple: [string, string]): boolean => {
	return tuple[0] === "events";
};

export const isSessionTuple = (tuple: [string, string]): boolean => {
	return tuple[0] === "sessions";
};

// permission statement in database has no hyphens - replace hypens to stringify
export const formatUuid = (uuid: string): string => {
	return `${uuid.substring(0, 8)}-${uuid.substring(8, 12)}-${uuid.substring(12, 16)}-${uuid.substring(16, 20)}-${uuid.substring(20)}`;
};

export const parsePermission = (permission: string): PermissionObject => {
	const permissionParts = permission.split(".");

	// min length permission string should be 2 (groups.*)
	if (permissionParts.length < 2) {
		throw new Error("Invalid permission string");
	}

	// begins with wildcard
	if (permissionParts[0] === "*") {
		// drop the leading wildcard
		permissionParts.shift();
	}

	// ex: groups.*.events.*.sessions.* -> [['groups', '*'], ['events', '*'], ['sessions', '*']]
	const tuples = arrayToTuples(permissionParts);

	const permissionObject: PermissionObject = {};

	for (const tuple of tuples) {
		if (isGroupTuple(tuple)) {
			if (tuple[1] === "*") {
				permissionObject.allGroupsInChannel = true;
			} else {
				permissionObject.groupUuid = formatUuid(tuple[1]);
			}
		}

		if (isEventTuple(tuple)) {
			if (tuple[1] === "*") {
				permissionObject.allEventsInGroup = true;
			} else {
				permissionObject.eventUuid = formatUuid(tuple[1]);
			}
		}

		if (isSessionTuple(tuple)) {
			if (tuple[1] === "*") {
				permissionObject.allSessionsInEvent = true;
			} else {
				permissionObject.sessionUuid = formatUuid(tuple[1]);
			}
		}
	}

	return permissionObject;
};

export function isEPermission(permission: string | number): permission is keyof typeof EPermissions {
	return permission in EPermissions;
}