import React, { useEffect, useState } from "react";

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import AvatarUpload from "../avatar-upload";
import { TypographyItem } from "../../../general-ui/typography-item/typography-item";
import { ContentProps } from "../registration";
import { RegistrationFooter } from "../registration-footer";
import { getTemplateClassName } from "../../../../utils/utils";
import { RegFieldsEnum } from "../../../../types/working-model";

export function AvatarContent({
	footerProps,
	template,
	onSaveData,
	isDisplayOnly = false
}: ContentProps): JSX.Element {
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const [uploading, setUploading] = useState(false);
	const channel = eventBundle?.channel ?? 0;
	const profile = blProfileUser?.profile?.[channel];
	const [image, setImage] = useState<string | null>(profile?.avatar ?? null);

	const { t } = useTranslate('homepage');

	useEffect(() => {
		setImage(profile?.avatar);
	}, [profile?.avatar]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (image && image !== 'undefined') {
			// include profile first name here so that if avatar is last step we can still send reg email with their first name.
			// Could send the full profile, but that may include a passcode and we preferably don't surface that more than we have to.
			onSaveData({ [RegFieldsEnum.first_name]: profile?.[RegFieldsEnum.first_name], avatar: image });
		} else {
			onSaveData({ [RegFieldsEnum.first_name]: profile?.[RegFieldsEnum.first_name] });
		}
	};

	return (
		<form className="registration-form" onSubmit={handleSubmit}>
			<div className="registration-form-inner">
				<TypographyItem className="evt-heading-2 stable registration-form-title" tagName="h2">
					{uploading ? t("Uploading...") : t("registration.Upload your Avatar")}
				</TypographyItem>

				<AvatarUpload
					isDisplayOnly={isDisplayOnly}
					image={image}
					setImage={setImage}
					setUploading={setUploading}
				/>

			</div>
			{!isDisplayOnly && <RegistrationFooter
				{...footerProps}
				isNextDisabled={!image && !profile?.avatar}
				template={getTemplateClassName(template)}
				isSkippable
			/>}
		</form>
	);
}
