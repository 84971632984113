import { SetStateAction, useMemo } from "react";

import { useAppDispatch, useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { removeRegistrationRequirement, setRegistrationType, } from "../../../../../../../store/actions/admin/create-event";
import { GateTypes, RegistrationTypes, RequirementMap } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { Popover } from "../../../../../../general-ui/popover/popover";

interface CardEditRequirementButtonProps {
	popoverOpen: GateTypes | null;
	setAllRequirements: (value: SetStateAction<RequirementMap | undefined>) => void;
	setEditRequirement: (value: SetStateAction<boolean>) => void;
	setPopoverOpen: (value: SetStateAction<GateTypes | null>) => void;
	type: GateTypes;
	reqIndex?: number;
}
const CardEditRequirementButton: React.FC<CardEditRequirementButtonProps> = (props) => {

	// The original domain requirement. Te be used when resetting the req
	const { type, setEditRequirement, popoverOpen, setPopoverOpen, reqIndex } = props;

	// The copy of the original requirement to be edited

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const requirements = workingEvent?.registration_settings?.requirements;
	const dispatch = useAppDispatch();

	const closePopover = () => {
		setPopoverOpen(null);
	};
	const openPopover = (key: GateTypes) => {
		setPopoverOpen(key);
	};

	const turnOffGating = () => {
		dispatch(setRegistrationType(RegistrationTypes.open));
	};

	const removeRequirement = (type: GateTypes, reqIndex?: number | null) => {
		// if deleting the last gating requirement (or last passcode list), then turn off gating
		if (requirements && Object.keys(requirements)?.length === 1) {
			if (requirements[GateTypes.passcode_list]) {
				if (requirements[GateTypes.passcode_list]?.length === 1) {
					turnOffGating();
				}
			} else {
				turnOffGating();
			}
		}
		dispatch(removeRegistrationRequirement(type, reqIndex ?? undefined));

		setPopoverOpen(null);
		// setRequirement(undefined);
	};

	const isOpen = useMemo(() => popoverOpen === type, [popoverOpen, type]);

	return (
		<>
			<button
				onClick={() => openPopover(type)}
				className="no-style no-padding options-button"
			>
				<Icon name={ICONS.THREE_DOTS_VERTICAL} color={COLORS.WHITE} size={16} />

			</button>
			<Popover
				open={isOpen}
				onClose={closePopover}
			>
				<div className="row">
					<Icon name={ICONS.EDIT} color={COLORS.WHITE} size={16} />
					<button onClick={() => setEditRequirement(true)}>Edit</button>
				</div>

				<div className="row">
					<Icon name={ICONS.DELETE} color={COLORS.WHITE} size={16} />
					<button onClick={(e) => {
						// stop propagation to parent element as to not fire that onClick as well.
						e.stopPropagation();
						removeRequirement(type, reqIndex);
						document.body.click(); // this forces a click to trigger popover state close so that we can immedatiately open another popover on click
					}}>Delete</button>
				</div>


			</Popover>
		</>
	);
};
export default CardEditRequirementButton;
