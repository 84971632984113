import { useEffect, useState, RefObject, useMemo } from 'react';

import { IEventTag, LanguagesAbbr } from '../../../../../types/working-model';
import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';

const RESIZER_DEBOUNCE_TIMEOUT = 300;

type TUseGetAdjustedTagsCount = (params: {
	tags: IEventTag[],
	tagsContainerRef?: RefObject<HTMLDivElement>,
	language: LanguagesAbbr,
	shouldRecalculate?: boolean,
}) => {
	showedTags: IEventTag[];
	hiddenTags: IEventTag[];
};

export const useGetAdjustedTagsCount: TUseGetAdjustedTagsCount = ({ tags: _tags, tagsContainerRef, language, shouldRecalculate }) => {
	const eventBundleTracks = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.tracks_translations);
	const workingEventTracks = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.tracks_translations);
	const [adjustedTagsCount, setAdjustedTagsCount] = useState(_tags?.length);
	const [tags, setTags] = useState<IEventTag[]>(_tags);
	const tagsTranslations = eventBundleTracks || workingEventTracks;

	useEffect(() => {
		const adjustedTags = _tags.map(tag => {
			const translation = tagsTranslations?.[tag.name]?.[language];
			if (translation && typeof translation === 'string') {
				return {
					...tag,
					name: translation,
				};
			}
			return tag;
		});
		setAdjustedTagsCount(adjustedTags?.length);
		setTags(adjustedTags);
	}, [_tags, language, tagsTranslations]);

	useEffect(() => {
		if (!shouldRecalculate) {
			return;
		}
		const recalculateTagsCount = (): void => {
			if (!tagsContainerRef?.current || !tagsContainerRef?.current.parentElement) return;
			const currentContainerWidth = tagsContainerRef?.current.parentElement?.getBoundingClientRect().width;

			if (!currentContainerWidth) return;

			// NOTE: We need this to correctly calculate number of showed tags (TK)
			const reservedTagSpace = 20;
			const reservedContainerSpace = 50;

			const containerWidth = currentContainerWidth - reservedContainerSpace;
			let calculatedTagsWidth = 0;
			let count = 0;

			const tagElementsList = Array.from(tagsContainerRef.current.children);

			tagElementsList.forEach((child) => {
				const childWidth = child.getBoundingClientRect().width + reservedTagSpace;

				calculatedTagsWidth += childWidth;

				if (calculatedTagsWidth <= containerWidth) {
					count++;
				}
			});

			setAdjustedTagsCount(count);
		};

		let waitTimeout: ReturnType<typeof setTimeout>;

		const observer = new ResizeObserver(() => {
			if (waitTimeout) clearTimeout(waitTimeout);

			waitTimeout = setTimeout(() => {
				recalculateTagsCount();
			}, RESIZER_DEBOUNCE_TIMEOUT);
		});

		if (tagsContainerRef?.current?.parentElement) {
			observer.observe(tagsContainerRef?.current.parentElement);
		}

		recalculateTagsCount();

		return (): void => observer.disconnect();
	}, [tagsContainerRef, shouldRecalculate]);

	const showedTags = tags?.slice(0, adjustedTagsCount);
	const hiddenTags = tags?.slice(adjustedTagsCount);

	return { showedTags, hiddenTags };
};
