import React, { useCallback, useContext, useEffect, useRef, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import classNames from "classnames";

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import {
	RegistrationPanelLayoutsTypes,
	RegistrationStepType,
	REGISTRATION_STEP_NAME_MAP,
	TranslateString,
} from "../../../types/working-model";
import { useGetAdminUrl } from "../../../utils/admin-routing-utils";
import { getRegistrationPanelRouteState } from "../../../utils/path-utils";
import { useDispatch } from "react-redux";

import "./module-tabs.scss";
import { RegistrationPanelContext, setActiveTab } from "./registration-panel-state";
import { RegistrationPanelMap, } from "./registration-panel-route-map";
import { chooseRegistrationStep } from "store/actions/admin/create-event";

// watch the path to determine what module is active at any given time
// they should not be set manually because user may refresh
export const ActiveTabModuleSetter = () => {
	const location = useLocation();
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const { dispatch } = useContext(RegistrationPanelContext);

	useEffect(() => {
		if (!workingSession) return;

		const { isContent, isLayout, isSettings } = getRegistrationPanelRouteState(location.pathname, true);

		let tab: string | undefined;

		if (isContent) {
			tab = RegistrationPanelMap[RegistrationPanelLayoutsTypes.Content];
		} else if (isLayout) {
			tab = RegistrationPanelMap[RegistrationPanelLayoutsTypes.Layout];
		} else if (isSettings) {
			tab = RegistrationPanelMap[RegistrationPanelLayoutsTypes.Settings];
		}

		dispatch(setActiveTab(tab));
	}, [dispatch, location.pathname, workingSession]);

	return (
		<></>
	);
};

export default function SubTabNavigation(): JSX.Element {
	const history = useHistory<{ panelTitle?: string | TranslateString, activeModule?: number, currentTab?: string }>();
	const adminPath = useGetAdminUrl();
	const tabContainerRefs = useRef<Record<number, React.RefObject<HTMLDivElement>>>({});
	const dispatch = useDispatch();

	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);

	const handleTab = useCallback((tab: RegistrationStepType) => {

		dispatch(chooseRegistrationStep(tab));

		history.replace(adminPath(
			{ path: RegistrationPanelMap[tab] }),
			{ ...history.location.state }
		);
	}, [adminPath, dispatch, history]);


	const getTabIsActive = useCallback((tab: string) => {
		const urls = {
			"general-information": RegistrationStepType.general,
			"avatar": RegistrationStepType.avatar,
			"profile": RegistrationStepType.profile,
			"ticketing": RegistrationStepType.ticketing
		};

		const paramMatch = (window.location.pathname.split('/').pop() || "general-information") as "general-information" | "avatar" | "profile" | "ticketing";

		if (paramMatch && paramMatch in urls) {
			return tab === urls[paramMatch];
		}
	}, []);

	useEffect(() => {
		// if active tab does not match registration step in redux, update redux
		// this sync problem occurs on page refresh
		const activeTab = Object.values(RegistrationStepType).find((tab) => getTabIsActive(tab));
		if (activeTab && activeTab !== registrationStep) {
			handleTab(activeTab);
		}
	}, [getTabIsActive, handleTab, registrationStep]);

	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<div className={classNames("sub-tab-navigation")} ref={containerRef}>
			{[RegistrationStepType.general,
			RegistrationStepType.profile,
			RegistrationStepType.avatar,
			RegistrationStepType.ticketing
			].map((tab, idx) => {
				if (!module) return <Fragment key={idx} />;
				const isActiveTab = getTabIsActive(tab);
				const tabName = REGISTRATION_STEP_NAME_MAP[tab];

				return (
					<div
						className="module-tab editable-tab"
						ref={tabContainerRefs.current[idx]}
						key={tab + idx}
					>
						<button
							className={classNames("step-tab", { "selected": isActiveTab })}
							onClick={() => handleTab(tab)}
						>
							{tabName}
						</button>
					</div>
				);
			})}
		</div>
	);
}
