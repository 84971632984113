import { Action } from "../../../types/actions";
import { ConnectedAdmin } from '../../../types/working-model';
import { ADD_CONNECTED_ADMIN, REMOVE_CONNECTED_ADMIN, SET_CONNECTED_ADMINS, UPDATE_CONNECTED_ADMIN } from '../../actions/admin/connected-admins';

interface ConnectedAdminsState {
	admins: ConnectedAdmin[];
}

const defaultState: ConnectedAdminsState = {
	admins: []
};


export default function ConnectedAdminsReducer(state = defaultState, action: Action): ConnectedAdminsState {
	switch (action.type) {
		case SET_CONNECTED_ADMINS: {
			//should effectively prevent reordering and always place newly added admins to the end
			const admins = new Map<number, ConnectedAdmin>([
				...state.admins.map((admin: ConnectedAdmin) => [admin.brandlive_profile, admin]),
				...(action?.payload ?? []).map((admin: ConnectedAdmin) => [admin.brandlive_profile, admin])
			]);
			return ({
				...state,
				admins: Array.from(admins.values())
			});
		}
		case ADD_CONNECTED_ADMIN: {
			const admins = new Map(state.admins.map((admin: ConnectedAdmin) => [admin.brandlive_profile, admin]));
			admins.set(action.payload.brandlive_profile, action.payload);
			return ({
				...state,
				admins: Array.from(admins.values())
			});
		}
		case REMOVE_CONNECTED_ADMIN: {
			//known issue with this approach, if the same user goes into the room then leaves in another browser, they've left.
			const admins = new Map(state.admins.map((admin: ConnectedAdmin) => [admin.brandlive_profile, admin]));
			admins.delete(action.payload.brandlive_profile);
			return ({
				...state,
				admins: Array.from(admins.values())
			});
		}
		case UPDATE_CONNECTED_ADMIN: {
			const admins = new Map(state.admins.map((admin: ConnectedAdmin) => [admin.brandlive_profile, admin]));
			admins.set(action.payload.brandlive_profile, action.payload);
			return ({
				...state,
				admins: Array.from(admins.values())
			});
		}
		default: return state;
	}
}