import { useParams } from 'react-router';

import { OptionalComponent } from '../../../../../utils/optional-component';
import { LeaderboardEntry } from "../../../../../types/leaderboard";
import { BlProfileValues } from "../../../../../types/working-model";
import PodiumScoreBadge from "./podium-score-badge";
import { useTypedSelector } from '../../../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../../../i18n/useTranslationModules';
import { ParamsProps } from '../../../live-event';
import { useMemo } from 'react';
import { getBylineMap, getScore } from '../leaderboard-utils';

interface IPodiumDetails {
	entry: LeaderboardEntry;
}

const PodiumDetails: React.FC<IPodiumDetails> = ({ entry }) => {
	const userProfile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const eventBylineQuestion = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.leaderboard_settings?.byline_question);
	const editorBylineQuestion = useTypedSelector(state => state.CreateEventReducer.workingEvent?.settings?.leaderboard_settings?.byline_question);
	const regQuestions = useTypedSelector(state => state.LiveEventReducer.eventBundle?.registration_questions);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);

	const eventUuid = (eventBundle?.uuid ?? workingEvent?.uuid) as string;

	const { t } = useTranslate('homepage');
	const { language } = useParams<ParamsProps>();

	const profile = entry.profile;
	const score = getScore(entry.score, eventUuid);

	const firstName = profile?.[BlProfileValues.firstName] ?? '';
	const lastName = profile?.[BlProfileValues.lastName] ?? '';

	const bylineMap = useMemo(() => {
		if (regQuestions) {
			return getBylineMap(regQuestions, t, language);
		} else {
			return new Map();
		}
	}, [language, regQuestions, t]);

	let byline = bylineMap.get(profile?.[`select_${eventBylineQuestion ?? editorBylineQuestion ?? 0}`]) ?? profile?.[eventBylineQuestion ?? editorBylineQuestion ?? 0];

	// for editor, use placeholder text for custom byline if not found in sample profiles
	if (editorBylineQuestion && editorBylineQuestion > 0 && !byline) {
		byline = 'Attendee response';
	}

	const thisIsMe = userProfile?.uuid === entry.bl_profile_uuid;

	return (
		<div className="podium-entry-details">
			<div className="podium-entry-name">
				<span className="podium-list-rank">{entry.rank}.</span> {firstName} {lastName}
				<OptionalComponent display={thisIsMe}>
					<span className="me">{t('leaderboard.Me')}</span>
				</OptionalComponent>
			</div>
			<div className="podium-entry-job">{byline}</div>
			<PodiumScoreBadge score={score} language={language} />
		</div>
	);
};

export default PodiumDetails;
