import { Dispatch, SetStateAction } from "react";
import { PageModule } from "../../../../../../../../types/working-model";
import ModuleDisplayOptions from "../module-display-options";
import { AllLayoutTypes } from "../../../../../../../../types/template-layouts";

interface IAdvancedSpeakersLayoutProps {
	module: PageModule;
	setSelectedDisplayOptions?: Dispatch<SetStateAction<number | null>>;
	selectedDisplayOptions?: number | null;
	selectedLayout?: AllLayoutTypes | null;
	speakerImageRadius?: boolean | null;
	setSpeakerImageRadius?: Dispatch<SetStateAction<boolean | null>>;
}

const AdvancedSpeakersLayout = (props: IAdvancedSpeakersLayoutProps) => {
	const {
		module,
		setSelectedDisplayOptions,
		selectedDisplayOptions,
		selectedLayout,
		speakerImageRadius,
		setSpeakerImageRadius,
	} = props;

	if (!module) return null;
	return (
		<ModuleDisplayOptions
			pageModuleType={module.type}
			module={module}
			setSelectedDisplayOptions={setSelectedDisplayOptions}
			selectedDisplayOption={selectedDisplayOptions}
			selectedLayout={selectedLayout}
			speakerImageRadius={speakerImageRadius}
			setSpeakerImageRadius={setSpeakerImageRadius}
		/>
	);
};

export default AdvancedSpeakersLayout;
