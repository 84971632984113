import { Suspense, useEffect, useMemo, useState, lazy } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { TemplateNames } from "../../../types/template-layouts";
import {
	ActionSource,
	CustomPage,
	EPreviewTypes,
	IPostRegisterHomeModule,
	ModuleMap,
	PageModule,
	PageModuleType,
	Session,
	SessionTypesFeatures,
} from "../../../types/working-model";
import WaitingIndicator from "../../general-ui/waiting-indicator/waiting-indicator";
import MainEventBannerLive from "../modules/main-event-banner-live/main-event-banner-live";
import MainEventBanner from "../modules/main-event-banner/main-event-banner";
import { checkIsLive } from "../../../utils/live-checker";
import { ParamsProps } from "../live-event";
import { eventPath } from "../utils";
import { useTracking } from "../../../utils/tracking";
import MyListTrackSelectModal from './MyListTrackSelectModal';
import { getTemplateClassName, sortSessionsByTime } from "../../../utils/utils";
import { getCurrentCustomPage } from "../../../utils/admin-routing-utils";
import { hasPageModuleControls } from "../../../utils/controls-utils";
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from "../../../utils/error-boundary";
import { clearErrorBoundaryComponents } from "../../../store/actions/event/event-actions";
import '../../../scss/live-event/base/marketing-page/marketing-registered-page.scss';
import surveyResultsListenerService from "../../../utils/survey-results-listener-service";
import { OptionalComponent } from "utils/optional-component";

const BlankSection = lazy(() => import("../modules/blank-section/blank-section"));
const Speakers = lazy(() => import("../modules/speakers/speakers"));
const Agenda = lazy(() => import('../modules/agenda/agenda'));
const CallOut = lazy(() => import("../modules/call-out/call-out"));
const Sponsors = lazy(() => import("../modules/sponsors/sponsors"));
const Products = lazy(() => import("../modules/products/products"));
const Videos = lazy(() => import("../modules/video/videos"));
const Footer = lazy(() => import("./footer/footer"));
const About = lazy(() => import('../modules/about/about'));
const Survey = lazy(() => import('../session/session-modules/survey/survey'));
const Accordion = lazy(() => import('../modules/accordion/accordion'));

export const MarketingRegisteredPage = ({ isCustomPage }: { isCustomPage?: boolean; }): JSX.Element => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const currentPostRegisterPage = useTypedSelector(state => state.CreateEventReducer.currentPostRegisterPage);
	const authorizedPages = useTypedSelector(state => state.LiveEventReducer.authorizedPages);
	const userSavedSessions = useTypedSelector(state => state.LiveEventReducer.userSavedSessions);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const { customPath, eventName, uuid, language } = useParams() as ParamsProps;
	const history = useHistory();
	const dispatch = useDispatch();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const event = eventBundle || workingEvent;
	const homepage = event?.homepage;
	const template = event?.template.name || TemplateNames.Classic;
	const templateClassName = getTemplateClassName(template);

	const isPreview = previewMode === EPreviewTypes.Home || previewMode === EPreviewTypes.CustomPage;

	let sessions = event?.sessions;
	if (!sessions) sessions = []; // fixes crashing app during the loading phase

	const customPagePath = isPreview ? currentPostRegisterPage : customPath;
	const customPages = event?.custom_pages;
	const currentCustomPage: CustomPage | null = useMemo(
		() => getCurrentCustomPage(customPages, customPagePath),
		[customPages, customPagePath]
	);
	const currentPageAuthorized: boolean = !!currentCustomPage && (isPreview || !event?.registration_on || authorizedPages.includes(currentCustomPage.custom_page));

	const myListActive = eventBundle?.settings.personalizedAttendeeList;
	const trackModalActive = eventBundle?.settings.personalizedAttendeeListModal;

	const eventTracks = useMemo(() => {
		const tracksArr: string[] = [];
		sessions?.forEach((session: Session) => {
			if (session.tracks) {
				session.tracks.forEach((track: string) => {
					if (!tracksArr.includes(track)) tracksArr.push(track);
				});
			}
		});
		return tracksArr;
	}, [sessions]);

	useEffect(() => {
		// if users saved sessions are null, they haven't skipped or set their saved sessions yet
		// show track modal if event settings enabled and tracks present
		if (
			!userSavedSessions &&
			trackModalActive &&
			myListActive &&
			eventTracks.length > 0
		) {
			setModalOpen(true);
		}
	}, [eventTracks.length, myListActive, trackModalActive, userSavedSessions]);

	useEffect(() => {
		//Sometimes the route is defaulting to RouteMap.Custom in "marketing-registered-routes.tsx" even when the url has no "pages/<page-name-ext>" so there is no custom_page to find.
		//I can not find any reason why it would be defaulting like this so the only solution I can think of is to use this fallback redirect - Scott Harlan
		if (isCustomPage && ((!customPagePath && !isPreview) || !currentPageAuthorized)) {
			history.replace(`/${eventPath(eventName, uuid)}/${language}/home`);
		}
	}, [currentPageAuthorized, customPagePath, eventName, history, isCustomPage, isPreview, language, uuid]);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	const homepageModules = useMemo(() => {
		if (!homepage) return null;

		const sortedSessions: Session[] | undefined = sessions?.sort(sortSessionsByTime) as Session[] | undefined;
		const liveSessionList = isPreview ? workingEvent?.sessions.filter(session => SessionTypesFeatures[session.session_type].has_start_time) : sortedSessions?.filter(checkIsLive);

		const getModules = (modules?: PageModule[]) => {
			return modules?.map((post_register_module) => {
				if (post_register_module && post_register_module.is_on) {
					let moduleToRender = <></>;
					let pageModule: PageModule | undefined = post_register_module;

					// for backwards compatibility of events already published with homepage modules matching landing page
					if (!hasPageModuleControls(post_register_module)) {
						// if post_register_module is not a full module, then get the full module from landing page
						const fullModule = homepage.modules.find(_module => _module.id === (post_register_module as IPostRegisterHomeModule).module_id);
						if (fullModule && !fullModule?.is_on) {
							fullModule.is_on = true;
						}
						pageModule = fullModule;
					}

					switch (pageModule?.type) {
						case PageModuleType.main_event_banner: {
							const enableHighlightLiveBanner = pageModule.content?.enable_live_banner ?? true;
							moduleToRender =
								(!isPreview && liveSessionList?.length && enableHighlightLiveBanner) ? (
									<MainEventBannerLive
										module={pageModule}
										template={templateClassName}
										key={pageModule.id}
										liveSessionList={liveSessionList}
									/>
								) : (
									<MainEventBanner
										module={pageModule}
										template={templateClassName}
										key={pageModule.id}
										eventBundle={event}
									/>
								);
							break;
						}
						case PageModuleType.about:
							moduleToRender = (
								<About
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.speakers:
							moduleToRender = (
								<Speakers
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.agenda:
							moduleToRender = (
								<Agenda
									isLive={!!liveSessionList?.length}
									module={pageModule}
									template={templateClassName}
									sessions={sessions ?? []}
									key={pageModule.id}
									eventBundle={event}
									showMyList
									editable={false}
								/>
							);
							break;
						case PageModuleType.callout:
							moduleToRender = (
								<CallOut
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.sponsors:
							moduleToRender = (
								<Sponsors
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.products:
							moduleToRender = (
								<Products
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.videos:
							moduleToRender = (
								<Videos
									key={pageModule.id}
									module={pageModule}
									template={templateClassName} />
							);
							break;
						case PageModuleType.blank:
							moduleToRender = (
								<BlankSection
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
							break;
						case PageModuleType.survey:
							moduleToRender = (
								<Survey
									module={pageModule}
									template={templateClassName}
									preview={isPreview}
								/>
							);
							break;
						case PageModuleType.accordion:
							moduleToRender = (
								<Accordion
									key={pageModule.id}
									module={pageModule}
									template={templateClassName}
								/>
							);
					}
					return (
						<ErrorBoundary key={pageModule?.id} uniqueLabel={`${ModuleMap[pageModule?.type ?? 0]} ${pageModule?.id}`}>
							{moduleToRender}
						</ErrorBoundary>
					);
				}
			});
		};

		if (isCustomPage) return getModules(currentCustomPage?.modules);

		return getModules(homepage.post_register_home_modules);
	}, [homepage, sessions, isPreview, workingEvent?.sessions, isCustomPage, currentCustomPage?.modules, event, templateClassName]);

	const trackingPayload = isCustomPage ? {
		source_type: ActionSource.CustomPage,
		source_id: currentCustomPage?.custom_page
	} : {
		source_type: ActionSource.Home,
		source_id: homepage?.homepage
	};
	const { Track } = useTracking(trackingPayload);
	const eventId = event?.event;
	const eventUuid = event?.uuid;

	useEffect(() => {
		let hasSurveys = false;
		if (currentCustomPage) {
			hasSurveys = currentCustomPage.modules?.some(module =>
				module.type === PageModuleType.survey && module.is_on && module.modules?.length
			) ?? false;
		} else {
			hasSurveys = homepage?.post_register_home_modules?.some(module => {
				return module.type === PageModuleType.survey && module.is_on && module.modules?.length;
			}) ?? false;
		}

		if (hasSurveys) {
			surveyResultsListenerService.in(eventUuid, eventId, blProfileUserToken);

			return () => {
				surveyResultsListenerService.out();
			};
		}
	}, [eventId, eventUuid, currentCustomPage, homepage?.post_register_home_modules, blProfileUserToken]);

	if (!event) return <WaitingIndicator />;

	if (!homepage) return <WaitingIndicator />;

	return (
		<div className={'marketing-registered-page'}>
			<Track>
				<div className="registered-main-content">
					<Suspense fallback="">
						{homepageModules}
					</Suspense>
					{homepage.footer && workingEvent?.settings.display_session_footer !== false && (
						<ErrorBoundary uniqueLabel="Homepage footer">
							<Footer footer={homepage.footer} template={templateClassName} />
						</ErrorBoundary>
					)}
				</div>
			</Track>
			{trackModalActive && modalOpen &&
				<ErrorBoundary uniqueLabel="Track select modal">
					<MyListTrackSelectModal open={modalOpen} requestClose={() => setModalOpen(false)} previewMode={false} />
				</ErrorBoundary>
			}
		</div>
	);
};
