import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { AnsweredQuestion, ApproveType, ChatMessage, DeleteQuestionComment, DeleteType, Message, ModeratorReply, QuestionBan, StatusUpdate } from 'types/socket-messages';
import { useSocket } from '../../../../../../../connection/socket';
import { IncomingSocketQuestion, LanguagesAbbr, PaginatedQuestion, SqsSocketTypes } from '../../../../../../../types/working-model';
import { QuestionsContext, questionDelete, questionsAnsweredBulk, prependQuestions, questionCommentDelete, questionLike, questionUnlike, appendOneQuestionsComment, questionAnswered, handleBan } from '../../panel/contexts/engage/questions-state';

const IncomingSocketQuestionToPaginatedQuestion = (incoming: IncomingSocketQuestion): PaginatedQuestion => ({
	...incoming,
	created_at: incoming.created_at.toString(),
	comments: 0,
	likes: 0,
	avatar: Object.values(incoming.profile ?? {})[0].avatar ?? null,
	language: incoming.language ?? 'en',
	pending_moderator: true
});

const QuestionMessagesSocketListener: React.FC<{ singleLanguage: boolean }> = React.memo(({ singleLanguage }) => {
	const [, dispatch] = useContext(QuestionsContext);

	const { session: session_uuid, language } = useParams<{ session: string, language: LanguagesAbbr }>();
	const socketId = singleLanguage ? `session-${session_uuid}` : `session-${session_uuid}-${language}`;
	const modSocketId = singleLanguage ? `session-${session_uuid}-mod` : `session-${session_uuid}-${language}-mod`;
	const generalSocket = useSocket(`session-${session_uuid}`);

	// listen to sockets
	const socket = useSocket(socketId);
	const modSocket = useSocket(modSocketId);

	useEffect(() => {
		const questionListener = (data: ChatMessage) => {
			dispatch(prependQuestions(
				data.chat_messages.map(IncomingSocketQuestionToPaginatedQuestion)
			));
		};

		const questionCommentListener = (data: ChatMessage | ModeratorReply) => {
			if ('chat_messages' in data && data.chat_messages.length > 0) {
				dispatch(appendOneQuestionsComment(
					data.chat_messages[0].question_id as number,
					data.chat_messages
				));
			} else if ('moderator_reply' in data && data.moderator_reply) {
				dispatch(appendOneQuestionsComment(
					data.data.question_id as number,
					[data.data]
				));
			}
		};

		const questionLikeListener = (data: ChatMessage) => {
			dispatch(questionLike(data.chat_messages[0].question_id as number));
		};

		const questionUnlikeListener = (data: ChatMessage) => {
			dispatch(questionUnlike(data.chat_messages[0].question_id as number));
		};

		socket.addListener(SqsSocketTypes.QUESTION, questionListener);
		modSocket.addListener(SqsSocketTypes.QUESTION, questionListener);
		socket.addListener(SqsSocketTypes.QUESTION_COMMENT, questionCommentListener);
		modSocket.addListener(SqsSocketTypes.QUESTION_COMMENT, questionCommentListener);
		socket.addListener(SqsSocketTypes.QUESTION_LIKE, questionLikeListener);
		modSocket.addListener(SqsSocketTypes.QUESTION_LIKE, questionLikeListener);
		socket.addListener(SqsSocketTypes.QUESTION_UNLIKE, questionUnlikeListener);
		modSocket.addListener(SqsSocketTypes.QUESTION_UNLIKE, questionUnlikeListener);

		const updateQuestionStatus = (data: Message<StatusUpdate>) => {
			console.warn("UNIMPLEMENTED", data);
			// dispatch(updateModeratorQuestion(data.data));
		};

		const deleteQuestion = (data: Message<DeleteType>) => {

			// update moderator question
			const { id } = data.data;
			dispatch(questionDelete(id));
		};

		const unDeleteQuestion = (data: Message<ApproveType>) => {
			dispatch(prependQuestions([IncomingSocketQuestionToPaginatedQuestion(data.data)]));
		};

		const updateQuestionCommentStatus = (data: Message<StatusUpdate>) => {
			console.warn("UNIMPLEMENTED", data);

			// dispatch(updateModeratorQuestion(data.data));
		};

		const deleteQuestionComment = (data: Message<DeleteQuestionComment>) => {
			dispatch(questionCommentDelete(data.data.comment_id, data.data.question_id));
		};

		const unDeleteQuestionComment = questionCommentListener;

		const updateQuestionOrCommentMessage = (msg: Message<StatusUpdate>) => {
			console.warn("UNIMPLEMENTED", msg);
		};

		const handleQuestionAnswered = (data: Message<AnsweredQuestion>) => {
			dispatch(questionsAnsweredBulk(data.data.question_ids, data.data.answered));
		};

		const handleQuestionsBanned = (data: Message<QuestionBan[]>) => {
			dispatch(handleBan(data.data));
		};

		// moderator updates
		socket.addListener('question_answered_bulk', handleQuestionAnswered);
		generalSocket.addListener('question_banned', handleQuestionsBanned);
		socket.addListener('approve_question', updateQuestionStatus);
		socket.addListener('unapprove_question', updateQuestionStatus);
		socket.addListener('delete_question', deleteQuestion);
		socket.addListener('undelete_question', unDeleteQuestion);
		socket.addListener('approve_question_comment', updateQuestionCommentStatus);
		socket.addListener('unapprove_question_comment', updateQuestionCommentStatus);
		socket.addListener('delete_question_comment', deleteQuestionComment);
		socket.addListener('undelete_question_comment', unDeleteQuestionComment);
		socket.addListener('update_question_message', updateQuestionOrCommentMessage);
		socket.addListener('update_question_comment_message', updateQuestionOrCommentMessage);
		return () => {
			socket.removeListener(SqsSocketTypes.QUESTION, questionListener);
			modSocket.removeListener(SqsSocketTypes.QUESTION, questionListener);
			socket.removeListener(SqsSocketTypes.QUESTION_COMMENT, questionCommentListener);
			modSocket.removeListener(SqsSocketTypes.QUESTION_COMMENT, questionCommentListener);
			socket.removeListener(SqsSocketTypes.QUESTION_LIKE, questionLikeListener);
			modSocket.removeListener(SqsSocketTypes.QUESTION_LIKE, questionLikeListener);
			socket.removeListener(SqsSocketTypes.QUESTION_UNLIKE, questionUnlikeListener);
			modSocket.removeListener(SqsSocketTypes.QUESTION_UNLIKE, questionUnlikeListener);

			socket.removeListener('question_answered_bulk', handleQuestionAnswered);
			generalSocket.removeListener('question_banned', handleQuestionsBanned);
			socket.removeListener('approve_question', updateQuestionStatus);
			socket.removeListener('unapprove_question', updateQuestionStatus);
			socket.removeListener('delete_question', deleteQuestion);
			socket.removeListener('undelete_question', unDeleteQuestion);
			socket.removeListener('approve_question_comment', updateQuestionCommentStatus);
			socket.removeListener('unapprove_question_comment', updateQuestionCommentStatus);
			socket.removeListener('delete_question_comment', deleteQuestionComment);
			socket.removeListener('undelete_question_comment', unDeleteQuestionComment);
			socket.removeListener('update_question_message', updateQuestionOrCommentMessage);
			socket.removeListener('update_question_comment_message', updateQuestionOrCommentMessage);
		};
	}, [socket, modSocket, dispatch, generalSocket]);

	return null;
});

QuestionMessagesSocketListener.displayName = 'QuestionMessagesSocketListener';

export default QuestionMessagesSocketListener;