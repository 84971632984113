import React, { useState, useMemo, useEffect } from 'react';
import { generatePath } from 'react-router';
import { Link, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import classNames from 'classnames';

import { useGetEvent, useIsSingleNoHomepage, useIsSingleSessionWithoutHome } from "../../../../hooks/session.hooks";
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import NewDropdown from '../../../general-ui/dropdown/new-dropdown';
import {
	Channel,
	EPreviewTypes,
	LanguagesAbbr,
	NavMainItems,
	EventNavbar,
	CustomPage,
	IHomepageMainNavItems,
	FeatureFlagsEnum,
	ENavbarTypes,
	BrandliveEvent
} from '../../../../types/working-model';
import { EIcon } from '../../../general-ui/icon/icon';
import { TemplateClassNames } from '../../../../types/template-layouts';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { eventPath, getDefaultLanguage } from '../../utils';
import { GetDefaultMainNavItems } from '../../../../store/utils/create-event';
import { setStorageItem } from '../../../../utils/local-storage';
import { LanguageDropdown } from '../../../general-ui/language-dropdown/language-dropdown';
import CustomNavigationItems from '../navigation-v2/custom-navigation-items';
import ProfileDetailsDropdown from './profile-details-dropdown';
import useRouteMap from '../../hooks/use-route-map';
import { getEventNavbarStyling } from "../../../live-event/utils";
import Icon, { COLORS, ICONS } from '../../../general-ui/icon';
import { ParamsProps } from '../../live-event';
import { getMockBlProfile, shouldDisplayHomepage } from '../../../../utils/utils';
import { iconNameList } from '../../../../utils/utils';
import '../../../../scss/live-event/base/marketing-page/marketing-nav.scss';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import EventLogo from './event-logo';
import MarketingNavV2 from '../navigation-v2/marketing-nav-v2';
import { useIsNewNavigation } from '../../../../hooks/navigation.hooks';
import { OptionalComponent } from '../../../../utils/optional-component';
import { useLanguagesList } from './use-languages-list';
import LanguageSelectorNav from './language-selector-nav';
import { deregister } from 'utils/deregister';
import { useDispatch } from 'react-redux';
import { setEventNavOpen } from 'store/actions/event/event-actions';

export const getMainNavIcon = (name: NavMainItems | string, size = 24) => {
	switch (name) {
		case NavMainItems.Home:
			return <Icon name={ICONS.HOME} color="" size={size} />;
		case NavMainItems.Sessions:
		case NavMainItems.Session:
			return <Icon name={ICONS.TV_WATCH} color="" size={size} />;
		case NavMainItems.Directory:
		case NavMainItems.People:
			return <Icon name={ICONS.PEOPLE_NAV} color="" size={size} />;
		case NavMainItems.Leaderboard:
			return <Icon name={ICONS.LEADERBOARD_NAV} color="" size={size} />;
		case 'Logout': {
			return <Icon name={ICONS.LOG_OUT} color="" size={size} />;
		}
	}
};

interface MarketingNavProps {
	currentChannel?: Channel;
	template: string;
	previewHide?: boolean;
	logo?: string;
	previewLanguage?: LanguagesAbbr
	showLanguageSelector?: boolean;
	disableProfileBubble?: boolean;
	hideButtons?: boolean;
}

export default function MarketingNav({
	currentChannel,
	template,
	previewHide,
	logo,
	previewLanguage,
	showLanguageSelector = false,
	disableProfileBubble = false,
	hideButtons = false
}: MarketingNavProps): JSX.Element {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const { backgroundColor, backgroundImg } = useMemo(() => {
		const workingNavbar: EventNavbar | undefined = previewMode ? workingEvent?.settings.event_navbar : eventBundle?.settings.event_navbar;
		return getEventNavbarStyling(workingNavbar?.styling_overrides);
	}, [eventBundle?.settings.event_navbar, previewMode, workingEvent?.settings.event_navbar]);
	const showNewNav = useIsNewNavigation();

	if (previewHide && !showNewNav) return <></>;

	return (
		<>
			{showNewNav ? (
				<>
					<MarketingNavV2
						currentChannel={currentChannel}
						template={template}
						previewHide={previewHide}
						logo={logo}
						previewLanguage={previewLanguage}
						showLanguageSelector={showLanguageSelector}
						disableProfileBubble={disableProfileBubble}
						hideButtons={hideButtons}
					/>
				</>
			) : (
				<header className={classNames('marketing-page-navigation', template, backgroundColor, { 'preview-mode': previewMode })} style={backgroundImg} >
					<EventLogo logo={logo} channel={currentChannel} />

					<nav className='navigation'>
						<MarketingNavMenu
							disableProfileBubble={disableProfileBubble}
							showLanguageSelector={showLanguageSelector}
							previewLanguage={previewLanguage} template={template} />
					</nav>
				</header>
			)}
		</>
	);
}

// Render nav links and handle functionality,
// abstracted from MarketingNav to be used in session header
interface IMarketingNavMenuProps {
	isEditorDesktop?: boolean;
	previewLanguage?: LanguagesAbbr;
	template?: string;
	showLanguageSelector?: boolean;
	disableProfileBubble?: boolean;
	hideProfile?: boolean;
}

/*



This entire file should be deprecated - the react components ought to not be used in the new navigation system



*/

export const MarketingNavMenu: React.FC<IMarketingNavMenuProps> = ({
	template,
	isEditorDesktop = false,
	previewLanguage,
	showLanguageSelector,
	disableProfileBubble = false,
	hideProfile = false,
}) => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const previewMode = useTypedSelector(state => state.CreateEventReducer.previewMode);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);
	const profile = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const liveEventChannel = useTypedSelector(state => state?.LiveEventReducer?.eventBundle?.channel);
	const adminUser = useTypedSelector(state => state?.AuthReducer.user);
	const authorizedPages = useTypedSelector(state => state.LiveEventReducer.authorizedPages);
	const leaderboardIsOnSetting = useTypedSelector(state => state.LiveEventReducer.leaderboardIsOn);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const showNewNav = useIsNewNavigation();
	const [selectingLanguage, setSelectingLanguage] = useState(false);

	const leaderboardFeatureFlagOn = featureFlags[FeatureFlagsEnum.leaderboard];

	const { isLessThan1024, isLessThan768, isLessThan640 } = useScreenMediaQuery();
	const { pathname } = useLocation();
	const { uuid, eventName, customPath, language: pathLanguage, ...restParams }: ParamsProps = useParams();

	// temp comment out until notifications is ready
	// const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(pathLanguage ?? '');

	const language = previewLanguage ? previewLanguage : pathLanguage; //when in preview modal portal we no longer have access to params
	const event: BrandliveEvent | null = eventBundle ?? workingEvent;

	const directoryIsOn = !!event?.social_settings?.directory?.isOn;

	const leaderboardIsOn = leaderboardIsOnSetting && leaderboardFeatureFlagOn;

	const allNavItems = event?.homepage?.event_main_nav_items;
	const eventMainNavItems = useMemo(() => {
		if (!allNavItems) return;
		return allNavItems.filter(navItem => {
			if (navItem.name === NavMainItems.Leaderboard && (!leaderboardIsOn || !navItem.is_on)) {
				return false;
			}
			return true;
		});
	}, [allNavItems, leaderboardIsOn]);

	const workingEventallNavItems = workingEvent?.homepage?.event_main_nav_items;
	const workingEventMainNavItems = useMemo(() => {
		if (!workingEventallNavItems) return;
		return workingEventallNavItems.filter(navItem => {
			if (navItem.name === NavMainItems.Leaderboard && (!leaderboardIsOn || !navItem.is_on)) {
				return false;
			}
			return true;
		});
	}, [workingEventallNavItems, leaderboardIsOn]);

	const debug = window.location.hostname === 'localhost';
	const RouteMap = useRouteMap({ debug });

	const match = useRouteMatch<any>();
	const { t } = useTranslate(['homepage', 'home']);
	const editorLessThan1024 = (editorSize === 'mobile') || (editorSize === 'tablet');

	// should be true if user is on the agenda page or in a session
	const isOnSessionURL = match.url === mainNavItemPathMap().sessions || match.path.includes(':sessionUuid');

	const navUuid = uuid ?? workingEvent?.uuid;
	const navEventName = eventName ?? workingEvent?.name;
	const navbarType = eventBundle?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;
	const shouldShowNavIcon = showNewNav && isTopNavbar;
	const isSingleSessionWithoutHomePage = useIsSingleNoHomepage();

	function mainNavItemPathMap(): { [key: string]: string } {
		return {
			home: `/${eventPath(eventName, uuid)}/${language}/home`,
			sessions: `/${eventPath(eventName, uuid)}/${language}/sessions`,
			// this tab only appears when there is a single session
			session: `/${eventPath(eventName, uuid)}/${language}/session/${eventBundle?.sessions?.[0]?.uuid}`,
			directory: `/${eventPath(eventName, uuid)}/${language}/directory`,
			leaderboard: `/${eventPath(eventName, uuid)}/${language}/leaderboard`,
		};
	}
	function getCustomPagePath(customPath: string) {
		return `/${eventPath(eventName, uuid)}/${language}/pages/${customPath}`;
	}

	const { backgroundColor, navItemColor, navItemActiveAccent } = useMemo(() => {
		const workingNavbar: EventNavbar | undefined = previewMode ? workingEvent?.settings.event_navbar : eventBundle?.settings.event_navbar;
		return getEventNavbarStyling(workingNavbar?.styling_overrides);
	}, [eventBundle?.settings.event_navbar, previewMode, workingEvent?.settings.event_navbar]);

	const renderEventMainNavItems = (eventMainNavItems && !isSingleSessionWithoutHomePage)
		? eventMainNavItems.filter(navItem => navItem.name.includes('Session') ? eventBundle?.settings.display_sessions ?? true : true).map(mainNavItem => {
			// check if in a session and tab is sessions
			const isSessionsView = isOnSessionURL && mainNavItem.name.toLowerCase() === 'sessions';
			const active = match.path.includes(mainNavItem.name.toLowerCase());
			let name = mainNavItem.custom_name.base;
			const keyName: string = mainNavItem.custom_name.base;

			if (mainNavItem.custom_name[language]) {
				name = (!mainNavItem.custom_name.changed && ['Sessions', 'Session'].includes(mainNavItem.custom_name.base))
					? t('Watch')
					: mainNavItem.custom_name[language] as string;
			} else if (['Home', 'Watch', 'Leaderboard'].includes(mainNavItem.custom_name.base)) {
				name = t(mainNavItem.custom_name.base as 'Home' | 'Watch' | 'Leaderboard');
			}

			const redirectSessionLink = keyName === 'Session' && eventBundle?.sessions.length === 1 && eventBundle.sessions[0].custom_url_enabled;
			const navIcon = shouldShowNavIcon ? undefined : getMainNavIcon(mainNavItem.name);

			// if single session and breakout room, do not show the nav links
			return (
				<NavItem
					key={mainNavItem.uuid}
					path={mainNavItemPathMap()[mainNavItem.name.toLowerCase()] || pathname}
					isLessThan1024={isLessThan1024}
					icon={navIcon}
					name={t(`home:nav.${keyName}`, name)}
					navItemActiveAccent={navItemActiveAccent}
					active={active}
					isSessionsView={isSessionsView}
					navItemColor={navItemColor}
					mainNavItem={mainNavItem}
					custom_url={redirectSessionLink ? eventBundle?.sessions[0].custom_url : ''}
				/>
			);
		})
		: [];

	const activeCustomNavItems = eventBundle?.custom_pages?.filter(page => page.is_on);
	const authorizedCustomNavItems = (previewMode || !eventBundle?.registration_on) ? activeCustomNavItems : activeCustomNavItems?.filter((page: CustomPage) => authorizedPages.includes(page.custom_page));

	const isDirectoryView = match.path.includes('/directory') || match.path.includes('/:attendeeId');
	const navIcon = shouldShowNavIcon ? undefined : <Icon name={ICONS.PEOPLE_NAV} color="" size={24} />;
	const renderDirectoryNavItem = (directoryIsOn && !isSingleSessionWithoutHomePage) ? [
		<NavItem
			key="directory"
			path={mainNavItemPathMap().directory}
			isLessThan1024={isLessThan1024}
			icon={navIcon}
			name={t("directory.Directory", "Directory")}
			navItemActiveAccent={navItemActiveAccent}
			active={isDirectoryView}
			isSessionsView={false}
			navItemColor={navItemColor}
		/>
	] : [];

	const generalNavItemsCount = renderEventMainNavItems.length + renderDirectoryNavItem.length;
	const languages = useLanguagesList();
	const moreThan1Language = languages.length > 1;

	const renderCustomNavItems = !isSingleSessionWithoutHomePage ? [
		<CustomNavigationItems
			key="others"
			generalNavItemsCount={generalNavItemsCount}
			customNavItems={authorizedCustomNavItems}
			previewLanguage={previewLanguage}
			navItemColor={navItemColor}
			navItemActiveAccent={navItemActiveAccent}
			getCustomPagePath={getCustomPagePath}
			isDesktop={!isLessThan1024}
		/>
	] : [];

	if (isLessThan1024) {
		renderCustomNavItems.push(
			<NavItem
				key="profile"
				path={generatePath(RouteMap.Profile, { uuid: navUuid, eventName: navEventName, language: pathLanguage })}
				isLessThan1024={isLessThan1024}
				icon={undefined}
				name={t("homepage:Profile", "Profile")}
				navItemActiveAccent={navItemActiveAccent}
				active={pathname.includes("profile")}
				isSessionsView={false}
				navItemColor={navItemColor}
			/>
		);

		if (moreThan1Language) {
			renderCustomNavItems.push(
				<NavItem
					key="language"
					path={'#'}
					isLessThan1024={true}
					icon={undefined}
					name={t('homepage:Language', "Language")}
					navItemActiveAccent={navItemActiveAccent}
					active={false}
					isSessionsView={false}
					navItemColor={navItemColor}
					onClick={() => setSelectingLanguage(true)}
				/>
			);
		}

		renderCustomNavItems.push(
			<NavItem
				key="logout"
				path={'#'}
				isLessThan1024={isLessThan1024}
				icon={undefined}
				name={t("homepage:Logout")}
				navItemActiveAccent={navItemActiveAccent}
				active={false}
				isSessionsView={false}
				navItemColor={navItemColor}
				onClick={() => {
					if (eventBundle) {
						deregister(eventBundle);
					}
				}}
			/>
		);
	}

	const renderAllNavItems = [
		...renderEventMainNavItems,
		...renderDirectoryNavItem,
		...renderCustomNavItems
	];

	// get the nav buttons from the working event if the current event is rendering in the preview modal
	const hasHomepage = shouldDisplayHomepage(event);

	const eventPreviewMainNavItems = useMemo(() => {
		if (workingEvent) {
			// get the nav buttons from the working event if the current event is rendering in the preview modal
			return hasHomepage
				? (workingEventMainNavItems || GetDefaultMainNavItems(getDefaultLanguage(workingEvent)))
				: [];
		} else {
			return undefined;
		}
	}, [workingEvent, workingEventMainNavItems, hasHomepage]);

	const renderEventPreviewNavItems = eventPreviewMainNavItems?.map(mainNavItem => {
		const display_sessions = workingEvent?.settings?.display_sessions ?? eventBundle?.settings?.display_sessions ?? true;
		const isHomeActive = (previewMode === EPreviewTypes.Home && mainNavItem.name === NavMainItems.Home);
		const isAgendaActive = (previewMode === EPreviewTypes.Agenda && mainNavItem.name === NavMainItems.Sessions);

		if (mainNavItem.name.includes('Session') && !display_sessions) {
			return <React.Fragment key={mainNavItem.uuid}></React.Fragment>;
		}

		let name: any;
		let useTranslate = false;
		if (!mainNavItem.custom_name[language] && ["Home", "Sessions", "Leaderboard"].includes(mainNavItem.custom_name.base)) {
			const base = mainNavItem.custom_name.base as "Home" | "Sessions" | "Leaderboard";
			name = t(base);
			useTranslate = true;
		}

		const navIcon = shouldShowNavIcon ? undefined : getMainNavIcon(mainNavItem.name);

		return (
			<NavItem
				key={mainNavItem.uuid}
				path={'#'}
				isLessThan1024={isLessThan1024}
				icon={navIcon}
				name={useTranslate ? name : (mainNavItem.custom_name[language] ?? mainNavItem.custom_name.base)}
				navItemActiveAccent={navItemActiveAccent}
				active={isHomeActive || isAgendaActive}
				isSessionsView={false}
				navItemColor={navItemColor}
			/>
		);
	});
	const activePreviewCustomNavItems = workingEvent?.custom_pages?.filter(page => page.is_on);
	const renderPreviewCustomNavItems = activePreviewCustomNavItems?.length
		? activePreviewCustomNavItems.map(page => {
			const navIcon = shouldShowNavIcon ? undefined : <Icon name={page.icon} color="" size={24} />;

			return <NavItem
				key={page.uuid}
				path={'#'}
				isLessThan1024={isLessThan1024}
				icon={navIcon}
				name={page.page_name?.[language] as string ?? page.page_name.base}
				navItemActiveAccent={navItemActiveAccent}
				active={customPath === page.path}
				isSessionsView={false}
				navItemColor={navItemColor}
			/>;
		})
		: [];

	const navDirectoryIcon = shouldShowNavIcon ? undefined : <Icon name={ICONS.PEOPLE_NAV} color="" size={24} />;
	const renderPreviewDirectoryNavItem = directoryIsOn ? (
		<NavItem
			key="directory"
			path={'#'}
			isLessThan1024={isLessThan1024}
			icon={navDirectoryIcon}
			name="DIRECTORY"
			navItemActiveAccent={navItemActiveAccent}
			active={previewMode === EPreviewTypes.Directory}
			isSessionsView={false}
			navItemColor={navItemColor}
		/>
	) : null;

	const renderAllPreviewNavItems = [
		...(renderEventPreviewNavItems ?? []),
		renderPreviewDirectoryNavItem,
		...renderPreviewCustomNavItems
	];



	const history = useHistory();
	const handleLanguageChange = (langAbbr: LanguagesAbbr) => {
		if (!eventBundle?.event) return;
		if (!match?.params?.language) return;
		setSelectedLanguage(langAbbr);
		// language is set in storage so we can remember the users chosen language so if they enter
		// a session in another language and then go back to the home page, we can reset the language to their chosen language
		setStorageItem(`event.${eventBundle.event}.language`, langAbbr);
		setSelectingLanguage(false);
		if (langAbbr && langAbbr !== match?.params?.language) {
			// replace the language in the url with langAbbr
			const updatedParams = { ...match.params };
			updatedParams.language = langAbbr;
			let updatedUrl = match.path;
			for (const key in updatedParams) {
				updatedUrl = updatedUrl.replace(`:${key}`, updatedParams[key]);
			}
			history.replace(updatedUrl);
		}
	};

	const channel = liveEventChannel ?? adminUser?.active_channel;
	const viewIsLessThan1024 = workingEvent ? editorLessThan1024 : isLessThan1024;

	const profilePageView = match.path === RouteMap?.Profile;
	const watchlistPageView = match.path === RouteMap?.Watchlist;
	const backToSessionLink = (!hasHomepage && (profilePageView || watchlistPageView)) ? (
		<NavItem
			path={previewMode ?
				'#' :
				`/${eventPath(eventName, uuid)}/${language}`}
			isLessThan1024={isLessThan1024}
			icon={<Icon name={ICONS.TV_WATCH} color="" size={24} />}
			name={t(`home:nav.Session`, 'Watch')}
			navItemActiveAccent={navItemActiveAccent}
			active={false}
			isSessionsView={false}
			navItemColor={navItemColor}
		/>
	) : null;

	const renderNavItems = () => {
		if (previewMode) {
			if (backToSessionLink) {
				return backToSessionLink;
			} else {
				return renderAllPreviewNavItems;
			}
		} else {
			if (backToSessionLink) {
				return backToSessionLink;
			} else {
				return renderAllNavItems;
			}
		}
	};

	return (
		<>
			{(viewIsLessThan1024) ? (
				<div className="mobile-header-actions-container">
					{/* leaving language selector in here, but hidden behind showLanguageSelector prop, in case product wants to go back to this later */}
					{(showLanguageSelector && moreThan1Language) ? (
						<div className={classNames('mobile-header-language-selector', navItemColor, { 'left-align': !isLessThan768 })}>
							<LanguageDropdown
								key={selectedLanguage}
								template={template}
								languages={languages}
								currentLanguageCode={selectedLanguage}
								onLanguageChange={handleLanguageChange}
							/>
						</div>
					) : null}

					<NewDropdown
						className={classNames('menu-tablet', navItemColor, { 'has-language-selector': showLanguageSelector })}
						popupClassName={classNames("menu-tablet-popup", backgroundColor)}
						showArrow={false}
						renderTitle={({ isOpen }) => {
							if (selectingLanguage) {
								return (
									<button className="no-style round" onClick={(e) => {
										e.stopPropagation();
										setSelectingLanguage(false);
									}}>
										<Icon name={ICONS.ARROW_LEFT_SHADOW} size={22} color='' />
									</button>
								);
							}

							if (isOpen) {
								return <EIcon name="close" size="large" />;
							} else {
								return <EIcon name="menu" size="large" />;
							}
						}}
					>
						<div className="tablet-links">
							{selectingLanguage ? (
								<LanguageSelectorNav
									onLanguageChange={handleLanguageChange}
								/>
							) : (
								<>
									{
										previewMode === EPreviewTypes.Session && hasHomepage
											? renderAllPreviewNavItems
											: renderAllNavItems
									}
								</>
							)}

							{navUuid && navEventName && pathLanguage && (
								<Link
									to={generatePath(RouteMap.Profile, { uuid: navUuid, eventName: navEventName, language: pathLanguage })}
									className={classNames('navigation-link', { active: pathname.includes("profile") })}
								>
								</Link>
							)}
						</div>
					</NewDropdown>
					{backToSessionLink}
					{!hideProfile && channel && event && !profilePageView && (
						<aside className="profile-details-actions" style={disableProfileBubble ? { pointerEvents: 'none' } : {}}>
							<ProfileDetailsDropdown
								profile={(workingEvent && adminUser) ? getMockBlProfile(adminUser) : profile}
								channel={channel}
								eventBundle={event}
								template={template || TemplateClassNames.Apollo}
								isMobile
								isTablet={isLessThan1024 && !isLessThan640}
								isEditor={!!workingEvent}
							/>
						</aside>
					)}
				</div>
			)
				: (
					<OptionalComponent display={!isEditorDesktop}>
						<>
							{renderNavItems()}
						</>
						<OptionalComponent display={!hasHomepage && event?.sessions.length === 1 && !profilePageView}>
							<>
								{backToSessionLink}
							</>
						</OptionalComponent>

					</OptionalComponent>
				)
			}

			{/* TEMP: use !isLessThan1024 for now. Not showing the profile dropdown on smaller screens because there are no */}
			{/* designs for it and it's only got the language selector, which is already in the header on mobile */}
			{!isEditorDesktop && (!viewIsLessThan1024) && event && channel ? (
				<aside className="profile-details-actions" style={disableProfileBubble ? { pointerEvents: 'none' } : {}}>
					{/* Comment out notifications icon until ready */}
					{/* <button
						className={classNames(
							'notification-preview-trigger no-style',
							{ open: notificationsOpen },
						)}
						aria-label={
							notificationsOpen
								? 'Close notification pop-up'
								: 'Open notification pop-up'
						}
						onClick={() => setNotificationsOpen(!notificationsOpen)}
					>
						<EIcon name="reminder-on" size={24} />
						// TODO: Add unread message counter
						<span className="notification-preview-trigger-badge">
							12
						</span>
					</button> */}

					{/* Comment out notifications until ready */}
					{/* <Notifications
						open={notificationsOpen}
						onClose={() => setNotificationsOpen(false)}
						style={{ background: '#fff' }}
						template={template}
					/> */}
					{hideProfile ? null : (
						<ProfileDetailsDropdown
							profile={(workingEvent && adminUser) ? getMockBlProfile(adminUser) : profile}
							channel={channel}
							eventBundle={event}
							template={template || TemplateClassNames.Apollo}
							isEditor={!!workingEvent}
						/>
					)}
				</aside>
			) : null}
		</>
	);
};


interface NavItemProps {
	navItemColor: string;
	active: boolean;
	isSessionsView: boolean;
	path: string;
	icon: JSX.Element | undefined | string;
	name: string;
	isLessThan1024: boolean;
	navItemActiveAccent: string;
	mainNavItem?: IHomepageMainNavItems;
	custom_url?: string;
	onClick?: () => void;
	forceIcon?: boolean;
	fullWidth?: boolean;
}
export const NavItem: React.FC<NavItemProps> = ({
	navItemColor,
	active,
	isSessionsView,
	path,
	icon,
	name,
	isLessThan1024,
	navItemActiveAccent,
	mainNavItem,
	custom_url,
	onClick,
	forceIcon,
	fullWidth
}) => {
	const event = useGetEvent();
	const channelIcons = useTypedSelector(state => state.LiveEventReducer.eventBundle?.channel_event_icons);
	const iconsImageArray = useTypedSelector(state => state.CreateEventReducer.channelIcons);
	//if the user uses the custom icon for main nav item, get the URL from channelIcons.
	const [customIconURL, setCustomIconURL] = useState("");
	const [isCustomIcon, setIsCustomIcon] = useState(mainNavItem?.is_custom);
	const [customIconForCustomPage, setCustomIconForCustomPage] = useState("");
	const showNewNav = useIsNewNavigation();
	const dispatch = useDispatch();

	const customIcons = channelIcons || iconsImageArray;

	// Custom Icons for Main Nav Items - Home and Agenda
	useEffect(() => {
		if (isCustomIcon) {
			const icon = customIcons?.find(icon => (icon?.uuid === mainNavItem?.custom_icon_uuid) && (icon.status !== 0));
			if (icon) {
				setCustomIconURL(icon.url);
			}
		}
		else {
			setIsCustomIcon(false);
		}
	}, [isCustomIcon, mainNavItem?.custom_icon_uuid, customIcons]);

	// Custim Icons for Custom Pages
	useEffect(() => {
		//I checked if it is a string since if it is regular icon, it is having JSX.Element type.
		//If it is a custom icon, I passed it down using custom icon uuid which is a string.
		if (typeof (icon) === 'string') {
			//if the list of channel icons is empty, it returns customIcons = [null], which is why I have this check.
			if (customIcons && customIcons.length > 0 && customIcons[0] !== null) {
				const newIcon = customIcons?.find(i => (i.uuid === icon) && (i.status !== 0));
				if (newIcon) {
					setCustomIconForCustomPage(newIcon.url);
				}
			}
		}
	}, [customIcons, icon]);

	const renderIcon = () => {
		if (forceIcon || !isLessThan1024) {
			//take care of custom icon for main nav item and custom pages.
			if (customIconURL || customIconForCustomPage) {
				return (
					<img className={classNames("custom-icon")} src={customIconURL || customIconForCustomPage} />
				);
			}
			//default icon, not the custom one, return icon as JSX Element type
			if (typeof (icon) !== 'string') {
				return icon;
			}
			//custom icon is deleted, fall back to the first icon in the default list
			else {
				return (
					<Icon name={iconNameList()[0]} size={16} color={COLORS.WHITE} />
				);
			}

		}
		return "";
	};

	const navbarType = event?.settings?.event_navbar?.navbarType;
	const isTopNavbar = navbarType === ENavbarTypes.Horizontal;
	const shouldShowNavIcon = forceIcon || !showNewNav || (showNewNav && !isTopNavbar);

	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		if (onClick) {
			e.preventDefault();
			e.stopPropagation();
			onClick();
		} else if (custom_url) {
			e.preventDefault();
			window.open(custom_url, '_blank');
		}

		dispatch(setEventNavOpen(false));
	};

	return (
		<div className={classNames("side-nav-link", { 'full-width': fullWidth })}>
			<Link
				onClick={handleClick}
				className={classNames('navigation-link', navItemColor, { active: active || isSessionsView })}
				to={custom_url ? '#' : path}
			>
				<div className="navigation-link-body-left">
					{shouldShowNavIcon && renderIcon()}
					{name}
				</div>
				<div className="navigation-link-body-right">
					{!isLessThan1024 && !showNewNav && <div className={classNames("active-border", active && navItemActiveAccent)} />}
					{fullWidth && <div className="full-width-icon"><Icon name={ICONS.ANGLE_RIGHT} size={16} color={''} /></div>}
				</div>
			</Link>
		</div>
	);
};
