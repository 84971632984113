import { matchPath } from "react-router-dom";
import { PATHNAMES } from "../../../utils/admin-routing-utils";
import store from '../../../store/main';
import { BrandliveEvent, IFeedbackSurveyText, LanguagesAbbr, PageModule, PageModuleType, Session, Speaker } from "../../../types/working-model";
import { updateTranslateKey } from "../../../utils/utils";
import { Action, AsyncAction } from "../../../types/actions";
import { Dispatch } from "react";
import { updateWorkingSession } from "store/actions/admin/create-event/session";
import { Transform } from '@dnd-kit/utilities';

export const getNamespaceAdmin = () => {
	const match = matchPath<{ id: string, customPath?: string, session?: string, language: string }>(window.location.pathname, [
		PATHNAMES.Event.Landing,
		PATHNAMES.Event.HomePath,
		PATHNAMES.Event.CustomPage,
		PATHNAMES.Session.Session
	]);

	if (match) {
		const event = store.getState().CreateEventReducer?.workingEvent;

		if (match.params.language === event?.settings?.i18n?.base) {
			return 'homepage';
		}

		if (match.path === PATHNAMES.Event.Landing) {
			return 'landing';
		} else if (match.path === PATHNAMES.Event.HomePath) {
			return 'home';
		} else if (match.path === PATHNAMES.Session.Session) {
			const id = match.params.session as string;
			const session = event?.sessions?.find(session => session.uuid === id);
			return `session.${session?.session}`;
		} else if (match.path === PATHNAMES.Event.CustomPage) {
			const customPath = match.params.customPath as string;
			const page = event?.custom_pages?.find(page => page.path === customPath);
			return `custom_page.${page?.custom_page}`;
		}
	}

	return "homepage";
};

export const isBrandliveEmail = (email: string) => {
	return email.endsWith('@brandlive.com');
};

export const workingEventDefaultLanguage = (workingEvent: BrandliveEvent): LanguagesAbbr => {
	if (workingEvent.settings.i18n?.base) {
		return workingEvent.settings.i18n.base as LanguagesAbbr;
	}

	if (workingEvent.homepage?.languages) {
		return workingEvent.homepage.languages[0] as LanguagesAbbr;
	}

	return workingEvent.sessions[0].default_language as LanguagesAbbr;
};

export const updateUserFeedbackSurveyTextChange = (
	value: string,
	workingEvent: BrandliveEvent,
	token: string,
	language: LanguagesAbbr,
	dispatch: Dispatch<AsyncAction>,
	updateSessionFeedbackSurveyText: (token: string, eventUuid: string, feedbackSurveyText: IFeedbackSurveyText, languages: string[]) => AsyncAction,
	t: (key: string, fallback?: string | undefined, options?: Record<string, string> | undefined) => string,

) => {
	if (!workingEvent?.uuid || !token) return;

	const title = updateTranslateKey({
		translateString: workingEvent.settings?.feedback_survey_text?.title ?? t('userSurvey.ratingLabel'),
		input: value,
		language,
		baseLanguage: workingEvent.settings?.i18n?.base ?? 'en'
	});

	const languages = workingEvent.homepage ? workingEvent.homepage.languages : workingEvent.sessions[0].languages;

	dispatch(updateSessionFeedbackSurveyText(token, workingEvent?.uuid, { title }, languages));
};


export const updateAllSpeakersWithSpeakerId = (speaker: Speaker, session: Session | null | undefined, dispatch: Dispatch<Action>) => {
	const updatedModules = session?.modules?.map((module: PageModule) => {
		if (module.type === PageModuleType.speakers) {
			return {
				...module,
				modules: module.modules?.map((s: Speaker) => {
					if (s.speaker === speaker.speaker) {
						return speaker;
					}
					return s;
				})
			};
		}
		return module;
	});

	// Update session state with the updated modules
	if (updatedModules) {
		const updatedSession = {
			...session,
			modules: updatedModules
		} as Session;

		dispatch(updateWorkingSession(updatedSession));
	}
};

export const getElementTransformValues = (element: Element | null) => {
	if (!element) return;

	const matrix = window.getComputedStyle(element).transform; // returns matrix(1,0,0,1,0,0)
	const matrixArray = matrix.replace("matrix(", "").split(",");
	const scaleX = parseFloat(matrixArray[0]);
	const scaleY = parseFloat(matrixArray[3]);
	const translateX = parseFloat(matrixArray[4]);
	const translateY = parseFloat(matrixArray[5]);

	return { scaleX, scaleY, translateX, translateY };
};

export const getUpdateTransformForDND = (transform: Transform | null, scale: number) => {
	const adjustedTransform = transform
		? {
			x: transform.x / (scale),
			y: transform.y / (scale),
			scaleX: transform.scaleX,
			scaleY: transform.scaleY,
		}
		: null;

	return adjustedTransform;
};