import "./add-tab-button.scss";

const AddTabButton = ({ onClick }: { onClick: () => void }) => (
	<div className="add-tab-container">
		<button
			className="add-tab-button"
			onClick={onClick}
		>
			<span>+ Add Tab</span>
		</button>
	</div>
);

export default AddTabButton;