import Icon, { ICONS, COLORS } from "../icon";
import './temp-card.scss';

export default function TempCard(props: { message: string }) {
	return (
		<div className="temp-card">
			<div className="key-gradient"><Icon name={ICONS.DESKTOP_SLIM} color={COLORS.BLACK} size={16} /></div>
			<div>
				<p>{props.message}</p>
			</div>
		</div>
	);
}