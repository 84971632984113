import { useEffect, useMemo, useState } from "react";
import { useLocation, useHistory, Redirect, useParams } from "react-router-dom";
import '../../../../scss/live-event/base/marketing-page/leaderboard.scss';
import { ILeaderboardSearchCriteria, LeaderboardEntry } from "../../../../types/leaderboard";
import LeaderboardList from "./leaderboard-list";
import Podium from "./podium/podium";
import { OptionalComponent } from "../../../../utils/optional-component";
import { useAppDispatch, useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { makeFakePeople } from '../marketing-registered-directory/mock-directory-data';
import { loadLeaderboardProfilePaginated, searchLeaderboard } from '../../../../store/actions/event/event-actions';
import WaitingIndicator from '../../../general-ui/waiting-indicator/waiting-indicator';
import { getLeaderboardQueryParams, generateLeaderboardQueryParams } from "./leaderboard-utils";
import LeaderboardHeader from "./header/leaderboard-header";
import { eventPath } from "../../utils";
import { ParamsProps } from '../../live-event';
import { FeatureFlagsEnum, NavMainItems } from '../../../../types/working-model';
import { useTranslate } from "../../../../i18n/useTranslationModules";

const entriesPerPage = 9;

// only getting one page worth of fakeAttendee results since we only get one page worth from the api
const fakeAttendees = (): LeaderboardEntry[] => {
	return makeFakePeople(entriesPerPage, true).map((person, idx) => {
		return {
			bl_profile: idx + 1,
			bl_profile_uuid: `b09acec0-5d11-11ec-848d-8b670c9b16${idx + 1}c`,
			profile: person as Record<string | number, string>,
			rank: idx + 1,
			score: 300 - (idx + 1),
		};
	});
};

const Leaderboard = ({ isPreview = false }: { isPreview?: boolean; }): JSX.Element => {
	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const profileUuid = useTypedSelector(state => state.LiveEventReducer.blProfileUser?.uuid);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const leaderboardIsEnabled = useTypedSelector(state => state.LiveEventReducer.leaderboardIsOn);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const { attendees, loading, error, totalResults, initialPage } = useTypedSelector(state => state.LiveEventReducer.leaderboard);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);

	const [searchCriteria, setSearchCriteria] = useState<ILeaderboardSearchCriteria>({ page: 1 });

	const { t } = useTranslate(['homepage']);

	const dispatch = useAppDispatch();
	const location = useLocation();
	const history = useHistory();
	const { eventName, language } = useParams<ParamsProps>();

	const event = eventBundle ?? workingEvent;

	const searchQuestion = event?.settings.leaderboard_settings?.byline_question ?? undefined;
	const filterQuestion = event?.settings.leaderboard_settings?.filter_question ?? undefined;

	useEffect(() => {
		setSearchCriteria(prev => ({ ...prev, sq: searchQuestion, fq: filterQuestion }));
	}, [filterQuestion, searchQuestion]);

	const leaderboard = useMemo(() => {
		return isPreview
			? fakeAttendees()
			: attendees;
	}, [attendees, isPreview]);

	const fetchLeaderboard = (params: ILeaderboardSearchCriteria): void => {
		if (!event || !token) return;

		dispatch(searchLeaderboard(
			token,
			event.uuid,
			entriesPerPage,
			(params.page - 1) * entriesPerPage,
			generateLeaderboardQueryParams(params, true)
		));
	};

	useEffect(() => {
		if (isPreview) return;

		const parsedParams = getLeaderboardQueryParams(location.search);
		const _searchCriteria = { ...parsedParams, sq: searchQuestion, fq: filterQuestion };
		fetchLeaderboard(_searchCriteria);

		// once request has finished, we can set state
		setSearchCriteria(_searchCriteria);

		// Dont add to the dependency array, we want to only run this on load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSearchCriteriaChange = (value: ILeaderboardSearchCriteria) => {
		if (isPreview) return;
		fetchLeaderboard(value);

		// if error while fetching, we should not update params or local state
		history.push({
			pathname: window.location.pathname,
			search: generateLeaderboardQueryParams(value)
		});

		// update state
		setSearchCriteria(value);
	};

	useEffect(() => {
		// performing a leaderboard search resets initialPage to -1, whereas doing a jump to page sets initialPage to whatever the user landed on
		if (initialPage === -1) return;

		setSearchCriteria(prev => {
			const _searchCriteria = {
				...prev,
				page: initialPage,
				search: undefined,
				fv: undefined,
				track: undefined
			};

			history.push({
				pathname: window.location.pathname,
				search: generateLeaderboardQueryParams(_searchCriteria)
			});

			return _searchCriteria;

		});

	}, [initialPage, history]);

	const eventMainNavItems = event?.homepage?.event_main_nav_items;

	const leaderboardNavIsOn = useMemo(() => (
		!!eventMainNavItems?.find(nav => nav.name === NavMainItems.Leaderboard)?.is_on
	), [eventMainNavItems]);
	const leaderboardIsOn = !!leaderboardIsEnabled && leaderboardNavIsOn;
	const leaderboardFeatureFlagOn = featureFlags[FeatureFlagsEnum.leaderboard];

	if ((!leaderboardIsOn || !leaderboardFeatureFlagOn) && !isPreview) {
		return <Redirect to={`/${eventPath(eventName, event?.uuid)}/${language}/home`} />;
	}

	const jumpToPage = () => {
		if (!token || !event?.uuid || !profileUuid) return;
		dispatch(loadLeaderboardProfilePaginated(token, event?.uuid, entriesPerPage, profileUuid));
	};

	const profileDetailsOn = event?.social_settings?.profiles.isOn && !isPreview;
	const viewProfile = (attendeeUUID: string) => {
		// per product, always navigate to profile page regardless of profile display setting
		return history.push(`/${eventPath(eventName, event?.uuid)}/${language}/profile/${attendeeUUID}`);
	};

	const displayLeaderboard = () => {
		if (loading) return (<div className='no-results'><WaitingIndicator /></div>);

		if (error) return (<div className='no-results'>unable to load</div>);

		if (!leaderboard?.length) return (<div className='no-results'>{t('No results')}</div>);

		const pageNumber = searchCriteria.page;
		// we use totalResults from the API instead of the array length because we only get one page of results at a time
		const totalPages = Math.ceil((isPreview ? leaderboard.length : totalResults) / entriesPerPage);

		const showPodium = pageNumber <= 1 && !searchCriteria.search;
		const podium = showPodium ? leaderboard.slice(0, 3) : [];
		const list = showPodium ? leaderboard.slice(3) : leaderboard;

		return (<>
			<OptionalComponent display={!!podium.length}>
				<Podium leaderboardEntries={podium} viewProfile={profileDetailsOn ? viewProfile : undefined} />
			</OptionalComponent>
			<OptionalComponent display={!!list.length}>
				<LeaderboardList
					list={list}
					page={searchCriteria.page}
					setPage={(page) => handleSearchCriteriaChange({ ...searchCriteria, page })}
					totalPages={totalPages}
					jumpToPage={jumpToPage}
					viewProfile={profileDetailsOn ? viewProfile : undefined}
				/>
			</OptionalComponent>
		</>);
	};

	return (
		<div className={'marketing-registered-page'}>
			<div className={"registered-main-content"}>
				<div className="leaderboard-wrapper">
					<div className="leaderboard-main-content">
						<LeaderboardHeader disabled={loading} searchCriteria={searchCriteria} onSearchCriteriaChange={handleSearchCriteriaChange} />
						{displayLeaderboard()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Leaderboard;
