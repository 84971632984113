import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { ActionEvent, ActionTargetType, ActionType, AnnouncementDB, BlProfile, EAnnouncementTypes, FeatureFlagsEnum, IAnnouncementMultiLanguageSettings, BrandliveEvent } from '../../../types/working-model';
import { OptionalComponent } from '../../../utils/optional-component';
import { getCurrentPageSourceInfo, useTracking } from '../../../utils/tracking';
import Icon, { ICONS } from '../../general-ui/icon';
import { ParamsProps } from '../../live-event/live-event';
import { getDefaultLanguage } from '../utils';
import usePrevious from '../../../utils/use-previous';
import { useIsSessionPage, useIsSingleSessionPage } from '../../../hooks/path.hooks';
import { useGetSession } from '../../../hooks/session.hooks';

import useSetBannerHeight from './use-banner-height-setter';
import './announcements-v2.scss';

const getMultiLanguageText = (messageTextMultiLanguage: IAnnouncementMultiLanguageSettings, currentlySelectedLanguage: string, baseLanguage: string) => {
	const messageText = messageTextMultiLanguage.messageText[currentlySelectedLanguage]?.text ?? '';
	const languageIsHidden = messageTextMultiLanguage.messageText[currentlySelectedLanguage]?.hidden ?? true;
	const baseMessageText = messageTextMultiLanguage.messageText[baseLanguage]?.text ?? '';
	const changedMessageText = messageTextMultiLanguage.messageText[currentlySelectedLanguage]?.changed ?? ''; // only non base can be changed (custom translation)

	const buttonText = messageTextMultiLanguage.buttonText[currentlySelectedLanguage]?.text ?? '';
	const baseButtonText = messageTextMultiLanguage.buttonText[baseLanguage]?.text ?? '';
	const changedButtonText = messageTextMultiLanguage.buttonText[currentlySelectedLanguage]?.changed ?? '';

	return {
		messageText: languageIsHidden
			? baseMessageText
			: changedMessageText
				? changedMessageText
				: messageText
					? messageText
					: baseMessageText,
		buttonText: languageIsHidden
			? baseButtonText
			: changedButtonText
				? changedButtonText
				: buttonText
					? buttonText
					: baseButtonText
	};
};

const checkScheduleIsActive = (scheduleDate?: string, expirationDate?: string) => {
	if (!scheduleDate) return false;

	const now = new Date().toISOString();
	const scheduleStartIsPast = now > scheduleDate;

	return expirationDate && scheduleStartIsPast
		? expirationDate > now // Announcement start time is past, but expiration date is in the future
		: scheduleStartIsPast; // Start time has triggered
};

const AnnouncementsV2 = (): JSX.Element => {
	const { trackEvent } = useTracking();
	const { language: currentlySelectedLanguage }: ParamsProps = useParams();
	const session = useGetSession();
	const isSessionPage = useIsSessionPage();
	const isSingleSessionPage = useIsSingleSessionPage();
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle || workingEvent;
	const announcements = useMemo(() => event?.announcements || [], [event?.announcements]);

	// There SHOULD only be one banner announcement per event
	const banner = useMemo(() => announcements.find(a => a.display_settings?.type === 'banner'), [announcements]);
	const prevBanner = usePrevious(banner);
	const [bannerActive, setBannerActive] = useState(banner?.schedule_on || banner?.expiration_time
		? checkScheduleIsActive(String(banner?.schedule_date || ''), String(banner?.expiration_time || ''))
		: true
	);

	const eventLanguages: string[] = useMemo(() =>
		// Look for this in sessions previews so announcements properly display before registration
		Array.from(new Set(event?.sessionsPreviews?.flatMap(session => session.languages) ?? [])),
		[event]
	);
	const eventIsMultiLanguage = eventLanguages.length > 1;

	useEffect(() => {
		// Don't flash the banner if they change it to a new one
		if (banner && prevBanner && (banner.id !== prevBanner.id || banner.schedule_on !== prevBanner.schedule_on)) {
			setBannerActive(false);
		}
	}, [banner, prevBanner]);

	const bannerRef = useRef<AnnouncementDB>();
	useEffect(() => {
		bannerRef.current = banner;
	}, [banner]);

	useEffect(() => {
		const banner = bannerRef.current;
		if (!banner) return;

		const intervalId = setInterval(() => {
			if (!checkScheduleIsActive(String(banner.schedule_date || ''), String(banner.expiration_time || ''))) {
				// Check the dates for the scheduled announcement
				setBannerActive(false);
			} else if (banner.expiration_time && String(banner.expiration_time) < new Date().toISOString()) {
				// Immediate, but the set duration has passed
				setBannerActive(false);
			} else {
				setBannerActive(true);
			}
		}, 2000);

		return () => clearInterval(intervalId);
	}, [banner, workingEvent]);

	useEffect(() => {
		if (banner?.display_settings?.textColor) {
			// <Icon /> doesn't like custom colors
			document.querySelector('#banner-announcement-button-link > svg > path')?.setAttribute('fill', banner.display_settings?.textColor);
		}
	}, [banner, banner?.display_settings?.textColor, bannerActive]);

	if (!featureFlags[FeatureFlagsEnum.announcements_v2_q4_2023] || !banner || !bannerActive || !event) {
		return <div className="banner-announcement-placholder"></div>;
	}

	if (banner.recipients === EAnnouncementTypes.registered) {
		const isRegistered = event.registration_on && blProfileUserToken;

		if (!isRegistered) return <div className="banner-announcement-placholder"></div>;
	}

	if (banner.recipients === EAnnouncementTypes.session) {
		if (!session || !(isSessionPage || isSingleSessionPage)) {
			return <div className="banner-announcement-placholder"></div>;
		}

		if (!banner.to_sessions.includes(session.session)) {
			return <div className="banner-announcement-placholder"></div>;
		}
	}

	return (
		<AnnouncementsV2Ui
			banner={banner}
			trackEvent={trackEvent}
			currentlySelectedLanguage={currentlySelectedLanguage}
			blProfileUser={blProfileUser}
			event={event}
			eventIsMultiLanguage={eventIsMultiLanguage}
		/>
	);
};

type AnnouncementProps = {
	banner: AnnouncementDB;
	trackEvent: (event: any) => void;
	currentlySelectedLanguage: string;
	blProfileUser: BlProfile | null;
	event: BrandliveEvent;
	eventIsMultiLanguage: boolean;
};
const AnnouncementsV2Ui = ({
	banner,
	trackEvent,
	currentlySelectedLanguage,
	blProfileUser,
	event,
	eventIsMultiLanguage
}: AnnouncementProps) => {

	const { source_id, source_type } = useMemo(() => getCurrentPageSourceInfo(location.pathname, event, blProfileUser?.bl_profile || 0), [blProfileUser?.bl_profile, event]);
	const announcements = useMemo(() => event?.announcements || [], [event?.announcements]);

	const baseLanguage = useMemo(() => getDefaultLanguage(event), [event]);

	const bannerTextRef = useRef<HTMLDivElement>(null);

	useSetBannerHeight({ bannerTextRef, hasAnnouncements: !!(announcements && banner) });

	const { messageText, buttonText } = useMemo(() => {
		if (eventIsMultiLanguage) {
			return getMultiLanguageText(banner.message_text_multi_language, currentlySelectedLanguage, baseLanguage);
		} else {
			return { messageText: banner.message_text, buttonText: banner.button_text };
		}
	}, [banner.button_text, banner.message_text, banner.message_text_multi_language, baseLanguage, currentlySelectedLanguage, eventIsMultiLanguage]);

	return useMemo(() => (
		<div ref={bannerTextRef} className="banner-announcement" style={{ background: banner.display_settings?.backgroundColor }}>
			<div className="banner-announcement-text" style={{ color: banner.display_settings?.textColor }}>
				{messageText}
			</div>

			<OptionalComponent display={banner.button_on}>
				<a
					onClick={() => trackEvent({
						action: ActionEvent.Click,
						action_type: ActionType.Active,
						target_type: ActionTargetType.Announcement,
						source_id,
						source_type,
						target_id_string: banner.button_link,
						current_language: currentlySelectedLanguage,
						miscellaneous: JSON.stringify({ baseLanguage, messageText, email: blProfileUser?.email || '' })
					})}
					className="banner-announcement-button"
					href={banner.button_link}
					rel="noreferrer"
					target={banner.button_link_same_tab ? '' : '_blank'}
					id="banner-announcement-button-link"
				>
					<span style={{ color: banner.display_settings?.textColor }}>
						{buttonText}
					</span>
					<Icon name={ICONS.ARROW_RIGHT} size={12} color="" />
				</a>
			</OptionalComponent>
		</div>
	), [banner.button_link, banner.button_link_same_tab, banner.button_on, banner.display_settings?.backgroundColor, banner.display_settings?.textColor, baseLanguage, blProfileUser?.email, buttonText, currentlySelectedLanguage, messageText, source_id, source_type, trackEvent]);
};

export default AnnouncementsV2;
