import { useState } from 'react';
import { useModal } from "react-modal-hook";
import { batch, useDispatch } from "react-redux";
import { EditBreakoutSession, SaveBreakoutRoomsForSession } from "../../../../../../connection/sessions";
import { updateWorkingEventSession } from "../../../../../../store/actions/admin/create-event";
import { updateSession, updateWorkingSession } from "../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { BreakoutRoom, BreakoutSession, Session } from "../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../general-ui/icon";
import BreakoutSessionsModal from "../../../session/breakout-session-modal/breakout-session-modal";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { useParams } from 'react-router-dom';
import { ParamsProps } from '../../../../../live-event/live-event';

interface IBreakoutRoomSettings {
	session: BreakoutSession;
}

const handleUpdateWorkingEventSession = ({
	workingEvent,
	breakoutSession,
	session_id,
	saveBreakoutRoomResults
}: any): Session => {
	const matchedWorkingEventSession = workingEvent?.sessions ? workingEvent?.sessions?.find((session: Session) => {
		if (session.session === session_id) return session;
		return null;
	}) : null;
	if (!matchedWorkingEventSession) throw new Error(`Session not found in working event ${session_id}`);

	matchedWorkingEventSession.breakout_session = {
		session: session_id,
		create_room_when_full: !!breakoutSession.create_room_when_full,
		use_zoom: breakoutSession.use_zoom,
		use_google_meet: breakoutSession.use_google_meet,
	};

	const updatedWorkingEventSession: Session = {
		...matchedWorkingEventSession,
		breakout_rooms: saveBreakoutRoomResults,
		breakout_session_hosts: breakoutSession.hosts.map((email: string) => ({ session: session_id, email }))
	};
	return updatedWorkingEventSession;
};

const BreakoutRoomSettings: React.FC<IBreakoutRoomSettings> = ({ session }) => {
	const { hosts, rooms, create_room_when_full, use_zoom, use_google_meet } = session;

	const [savingBreakoutRooms, setSavingBreakoutRooms] = useState(false);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const active_channel = useTypedSelector(state => state.AuthReducer.user?.active_channel);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const sessionsList = useTypedSelector(state => state.CreateSessionReducer.sessionsList);
	const session_id = workingSession?.session;

	const { language } = useParams<ParamsProps>();

	const backgroundImage = workingSession?.breakout_session?.background_image; // needs to be captured outside the onClose function because there seems to be some mutation going on there

	const dispatch = useDispatch();

	const googleOAuthClientId = process.env.REACT_APP_MEET_INTEGRATION_OAUTH_CLIENT_ID || '';
	const [showBreakoutRooms, hideBreakoutRooms] = useModal(() => (
		// BP-2155 GoogleOAuthProvider wrapper can be removd after phase 0 of meet integration
		<GoogleOAuthProvider clientId={googleOAuthClientId}>

			<BreakoutSessionsModal
				isSaving={savingBreakoutRooms}
				onClose={async (done, breakoutSession) => {
					if (done && breakoutSession && token && session_id && active_channel) {
						setSavingBreakoutRooms(true);
						const body = {
							session: session_id,
							...breakoutSession,
						};

						try {
							// Save breakout session + rooms on the backend
							const [, savedRooms] = await Promise.all([
								EditBreakoutSession(body, session_id, token, { return_results: true }),
								SaveBreakoutRoomsForSession(breakoutSession.rooms, breakoutSession.use_zoom, session_id, token, { return_results: true }),
							]);

							// Update redux 
							const updatedWorkingEventSession = handleUpdateWorkingEventSession({
								workingEvent,
								breakoutSession,
								session_id,
								saveBreakoutRoomResults: savedRooms
							});

							if (!updatedWorkingEventSession.breakout_session) throw new Error('Breakout session not found in working event session!');

							updatedWorkingEventSession.breakout_session.use_zoom = breakoutSession.use_zoom;
							updatedWorkingEventSession.breakout_session.use_google_meet = breakoutSession.use_google_meet;


							if (updatedWorkingEventSession?.breakout_session && backgroundImage) {
								updatedWorkingEventSession.breakout_session.background_image = backgroundImage;
							}

							batch(() => {
								// update workingEvent.sessions in CreateEventReducer (if exists)
								if (updatedWorkingEventSession) {
									dispatch(updateWorkingEventSession(updatedWorkingEventSession));
								}

								const roomIds = savedRooms.map((room: BreakoutRoom) => Number(room.id));

								// update workingSession in CreateSessionReducer (if exists)
								if (workingSession) {
									const updatedSession = {
										...workingSession,
										breakout_rooms: updatedWorkingEventSession?.breakout_rooms,
										breakout_room_order: roomIds,
										breakout_session_hosts: updatedWorkingEventSession?.breakout_session_hosts,
										breakout_session: updatedWorkingEventSession?.breakout_session,
										module_grouping: updatedWorkingEventSession?.module_grouping?.map(moduleGroup => { // if we've added or removed a breakout, need to remove it from module grouping used in sorting
											if (moduleGroup.type === 'breakout') {
												moduleGroup.modules = roomIds;
											}

											return moduleGroup;
										})
									};
									updatedSession.breakout_session!.use_zoom = breakoutSession.use_zoom;
									updatedSession.breakout_session!.use_google_meet = breakoutSession.use_google_meet;

									dispatch(updateSession(updatedSession, token!));
									dispatch(updateWorkingSession(updatedSession));
								}
							});
						} catch (e) {
							console.error(e);
						} finally {
							setSavingBreakoutRooms(false);
						}
					}
					hideBreakoutRooms();
				}}
				initialValues={{
					rooms,
					hosts,
					create_room_when_full,
					use_zoom,
					use_google_meet,
				}}
				language={language}
			/>
		</GoogleOAuthProvider>
	), [
		rooms,
		hosts,
		create_room_when_full,
		workingSession,
		sessionsList,
		savingBreakoutRooms,
		language
	]);

	return (
		<div className="settings-block">
			<div className="settings-block-header">
				<h5>ROOM SETTINGS</h5>
				<button onClick={showBreakoutRooms}><Icon name={ICONS.EDIT} size={16} color={COLORS.CYAN} /></button>
			</div>
			<div className="settings-section">
				<h6>Rooms</h6>
				<div className="breakout-room-chip-container">
					{rooms.map(room => {
						if (!room) return null;
						const elipsis = room.name?.length > 15 ? '...' : '';
						return (
							<div key={room.id} className="tag-container breakout-room-name-tag-container">
								<span className="tag breakout-room-name-tag">
									{room?.name?.substring(0, 15)}{elipsis}
									{/* Zoom powered BRs don't have max users. Just Vonage/BL solution does */}
									{!use_zoom ? ` (${room?.max_users})` : ''}
								</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default BreakoutRoomSettings;
