class DateGetter {
	dates = new Map<number, [string, Date]>();

	get = (date: string | number | Date): [string, Date] => {
		let key: number;

		if (typeof date === 'string') {
			key = new Date(date).valueOf();
		} else {
			key = date.valueOf();
		}

		const dt = Math.floor(key / 1000);
		if (this.dates.has(dt)) {
			return this.dates.get(dt) as [string, Date];
		} else {
			const dateObj = new Date(date);
			const res = dateObj.toLocaleTimeString(navigator.language, { hour: 'numeric', minute: '2-digit' });
			this.dates.set(dt, [res, dateObj]);
			return [res, dateObj];
		}
	};
}

export default new DateGetter();