import classNames from "classnames";
import React from "react";
import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useTranslate } from "../../../../i18n/useTranslationModules";
import Socket from "../../../../connection/socket";
import { setChatPromotion } from "../../../../store/actions/event/chat-actions";
import { joinRequestDeny, lowerHand, raiseHand } from "../../../../store/actions/event/firesides-actions";
import { useTypedSelector } from "../../../../store/reducers/use-typed-selector";
import { TemplateClassNames } from "../../../../types/template-layouts";
import { EPromotionTypes, FiresideSocketMessages, Session } from "../../../../types/working-model";
import usePrevious from "../../../../utils/use-previous";
import { Button } from "../../../general-ui/button/button";
import Icon, { COLORS, ICONS } from "../../../general-ui/icon";
import socketManager from "../../../../connection/socket-main-thread/socket-manager";
import { getStorageItem, removeStorageItem, setStorageItem } from "../../../../utils/local-storage";
import { getAlertById, hideAlert, showAlertLong } from "../../../general-ui/alert/alert-service";
import { getHandRaisedAlertId } from "../live-chat/promotions";

const FiresideModal = React.lazy(() => import("./fireside-modal"));

interface RaiseHandButtonProps {
	session: Session;
	isAdmin?: boolean;
	disableJoinStage: boolean;
}

export default function HandRaiseButton({ session, isAdmin = false, disableJoinStage }: RaiseHandButtonProps): JSX.Element {

	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const handRaised = useTypedSelector(state => state.FiresidesReducer.handRaised);
	const dispatch = useDispatch();

	const [socket, setSocket] = useState<Socket | null>(null);

	const [accepted, setAccepted] = useState(false);

	const prevHandRaised = usePrevious(handRaised);

	const handRaisedAlertId = getHandRaisedAlertId(session.uuid);

	const { t } = useTranslate('session');

	useEffect(() => {
		dispatch(setChatPromotion({
			icon: ICONS.HAND_FRONT,
			actionButton: {
				text: t('Raise hand'),
				action: handleRaiseHand,
				icon: ICONS.HAND_FRONT,
				class: 'small primary'
			},
			type: EPromotionTypes.raise_hand,
			message: t('Use the hand icon at the bottom of the video player'),
			dismissable: true
		}));

		return () => {
			handleLowerHand();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	// show alert to user if hand raised and user tabs away from page
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'hidden') {
				const handRaisedStorage = getStorageItem(handRaisedAlertId);
				if (handRaisedStorage) {
					showAlertLong({
						description: t('Your hand was lowered automatically after switching tabs'),
						// using the same id allows us to target the id so we only show one instance of it
						id: handRaisedAlertId,
					});
				}
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [handRaisedAlertId, session.uuid, t]);

	const setSocketIfNotConnected = () => {
		if (!socket) {
			setSocket(socketManager.get(`fireside-${session.uuid}`));

			window.addEventListener('unload', () => {
				navigator.sendBeacon(`${process.env.REACT_APP_API_HOST}/v3/live-event/firesides-access-deny-self/${session.uuid}`, JSON.stringify({ token }));
			}, true);


			document.addEventListener('visibilitychange', function logData() {
				if (document.visibilityState === 'hidden') {
					navigator.sendBeacon(`${process.env.REACT_APP_API_HOST}/v3/live-event/firesides-access-deny-self/${session.uuid}`, JSON.stringify({ token }));
				}
			});
		}
	};

	const handleRaiseHand = async () => {
		if (!token) return;
		setSocketIfNotConnected();
		await dispatch(raiseHand(session.uuid, token));

		// if there's an alert, remove it because user has re-raised their hand
		const alert = getAlertById(handRaisedAlertId);
		if (alert) {
			hideAlert(alert);
		}
		setStorageItem(handRaisedAlertId, 'true');

		dispatch(setChatPromotion({
			icon: ICONS.HAND_FRONT,
			message: t('You’ve raised your hand! We’ll let speakers know you want to talk'),
			actionButton: {
				text: t('Lower hand'),
				action: handleLowerHand,
				icon: ICONS.HAND_FRONT_OFF,
				class: 'small'
			},
			type: EPromotionTypes.raise_hand,
			dismissable: true
		}));
	};

	const handleLowerHand = () => {
		if (blProfileUser?.email && token) {
			dispatch(lowerHand(session.uuid, token));
			removeStorageItem(handRaisedAlertId);
		}
	};

	useEffect(() => {
		const handleJoinDeny = (data: any) => {
			const { all, uuid } = data.data;

			if (all || uuid === blProfileUser?.uuid) {
				dispatch(joinRequestDeny());
				removeStorageItem(handRaisedAlertId);
			}
		};

		const connectToFireside = (data: any) => {
			const { uuid } = data.data;
			if (uuid === blProfileUser?.uuid) {
				setAccepted(true);
			}
		};

		socket?.addListener(FiresideSocketMessages.JOIN_ACCEPT, connectToFireside);
		socket?.addListener(FiresideSocketMessages.JOIN_DENY, handleJoinDeny);

		return () => {
			socket?.removeListener(FiresideSocketMessages.JOIN_ACCEPT, connectToFireside);
			socket?.removeListener(FiresideSocketMessages.JOIN_DENY, handleJoinDeny);
		};
	}, [socket, dispatch, blProfileUser, handRaisedAlertId]);


	useEffect(() => {
		if (!handRaised && prevHandRaised) {
			dispatch(setChatPromotion(null));
		}
	}, [dispatch, handRaised, prevHandRaised]);

	const handleJoin = () => {
		window.location.href = `${window.location.href}/fireside`;
	};

	return (
		<>
			<Button
				onClick={handRaised ? handleLowerHand : handleRaiseHand}
				classButton={classNames('player-button raise-hand', { active: handRaised })}
				animate={true}
				isDisabled={isAdmin}
			>
				<Icon name={handRaised ? ICONS.HAND_FRONT_OFF : ICONS.HAND_FRONT} size={18} color={COLORS.WHITE} />
				<span>{handRaised ? t('Lower hand') : t('Raise hand')}</span>
			</Button>
			<Suspense fallback="">
				{accepted && <FiresideModal
					template={TemplateClassNames.Apollo}
					session={session}
					onCancelClicked={() => setAccepted(false)}
					onConfirmClicked={handleJoin}
					disableJoinButton={disableJoinStage}
					isOpen={accepted}
					close={() => setAccepted(false)}
				/>}
			</Suspense>
		</>
	);
}