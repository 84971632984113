import { v4 as uuidV4 } from 'uuid';

import { BLChannelProfile, BlProfileValues, BlProfileSearchResult } from "../../../../types/working-model";
import { getDirectoryAsset } from "../../modules/assets/asset-map";

/* Keeping this as I think it can be used for the admin view */
// const people = useMemo(() => {
// 	if (!dummyData.length) return [];
//
// 	// TODO: replace this value with a list of all profiles
// 	const allPeople = dummyData.slice();
// 	const size = getProfilesPerPage(directoryView);
//
// 	if (directoryView === 'list' && sortColumn) {
// 		allPeople.sort((a, b) => {
// 			const rowA = a?.[sortColumn];
// 			const rowB = b?.[sortColumn];
//
// 			if (typeof rowA !== 'string' || typeof rowB !== 'string') return 0;
//
// 			const sortInAscendingOrder = sortDirection === 'asc';
//
// 			const stringA = rowA.toLowerCase();
// 			const stringB = rowB.toLowerCase();
//
// 			const evaluationA = sortInAscendingOrder ? stringA < stringB : stringA > stringB;
// 			const evaluationB = sortInAscendingOrder ? stringA > stringB : stringA < stringB;
//
// 			if (evaluationA) return -1;
// 			if (evaluationB) return 1;
// 			return 0;
// 		});
// 	}
//
// 	return chunk(allPeople, size);
// }, [directoryView, getProfilesPerPage, sortColumn, sortDirection]);

export const mockPeople = [
	{
		[BlProfileValues.firstName]: 'Steven',
		[BlProfileValues.lastName]: 'Soto',
		[BlProfileValues.jobTitle]: 'Associate 3D Artist',
		avatar: getDirectoryAsset('Steven'),
		uuid: 'b09acec0-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Marvin',
		[BlProfileValues.lastName]: 'Daniel',
		[BlProfileValues.jobTitle]: '3D Artist',
		avatar: getDirectoryAsset('Marvin'),
		uuid: 'b4ad76e4-f876-469e-b073-8573fb7c7908',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Victoria',
		[BlProfileValues.lastName]: 'Lambert',
		[BlProfileValues.jobTitle]: 'Game Developer',
		avatar: getDirectoryAsset('Victoria'),
		uuid: 'b09ad8d4-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Travis',
		[BlProfileValues.lastName]: 'Watkins',
		[BlProfileValues.jobTitle]: '3D Artist',
		avatar: getDirectoryAsset('Daniel'),
		uuid: 'b09ab7a0-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Clara',
		[BlProfileValues.lastName]: 'Walters',
		[BlProfileValues.jobTitle]: 'Designer',
		avatar: getDirectoryAsset('Clara'),
		uuid: 'b09ac132-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Eva',
		[BlProfileValues.lastName]: 'Wilkerson',
		[BlProfileValues.jobTitle]: 'Game Developer',
		avatar: getDirectoryAsset('Eva'),
		uuid: 'b09ac45c-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Leanne',
		[BlProfileValues.lastName]: 'Graham',
		[BlProfileValues.jobTitle]: 'Marketing',
		avatar: '',
		uuid: 'b05ac46c-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Nicholas',
		[BlProfileValues.lastName]: 'Runolfsdottir',
		[BlProfileValues.jobTitle]: 'Engineer',
		avatar: '',
		uuid: 'b06bd570-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
	{
		[BlProfileValues.firstName]: 'Walter',
		[BlProfileValues.lastName]: 'Howell',
		[BlProfileValues.jobTitle]: 'Senior Designer',
		avatar: '',
		uuid: 'b82hf72c-5d11-11ec-848d-8b670c9b166c',
		total_profiles: 1
	},
];


export function makeFakePeople(numberOfPeople: number, useRawList?: boolean): BlProfileSearchResult[] {
	const fakePeople: BlProfileSearchResult[] = [];

	const quantity = useRawList ? mockPeople.length : numberOfPeople;
	Array(quantity).fill(0).map((_item, idx) => {
		const shouldRemoveAvatar = Math.floor(Math.random() * 100) >= 90;
		const userByIdx = useRawList ? mockPeople[idx] : mockPeople[Math.floor(Math.random() * mockPeople.length)];
		const profile = { ...userByIdx };

		// leaving here incase we want to add mock interests to the mock data to imitate filtering
		// profile.interests = mockInterests.filter(() => Math.floor(Math.random() * 100) >= 50);
		if (shouldRemoveAvatar) profile.avatar = '';
		profile.uuid = uuidV4(); // just to prevent duplicate key warnings
		profile.total_profiles = numberOfPeople; // added for pagination
		fakePeople.push(profile);
	});

	return fakePeople;
}

export const mockSocialProfile: BLChannelProfile = {
	[BlProfileValues.firstName]: 'Daniel',
	[BlProfileValues.lastName]: 'Thompson',
	[BlProfileValues.jobTitle]: '3D Artist',
	avatar: getDirectoryAsset('Daniel_large'),
	uuid: 'b09ab7a0-5d11-11ec-848d-8b670c9b166c',
	total_profiles: 1
};

