/*
Depending on the condition, this will wrap the children or not 

An example for ConditionalWrapper:
<ConditionalWrapper condition={isTrue} wrapper={children => WrapperComponent(children)}>
	<div>children components</div>			
</ConditionalWrapper>


If condition  is true:
<WrapperComponent>
		<div>children components</div>	
</WrapperComponent>


and if condition  is false:
<div>children components</div>	

*/




type Props = {
	condition: boolean;
	children: JSX.Element;
	wrapper: (children: JSX.Element) => JSX.Element;
};

export const ConditionalWrapper = ({ condition, wrapper, children }: Props) =>
	condition ? wrapper(children) : children;
