import { PageModuleGroupModules, TranslateString } from "../../../../../../../../types/working-model";
import { v4 as uuid } from 'uuid';
import { latinChars } from "../../../../../../../../i18n/transliteration";

export type PageModuleGroupProps = {
	name: string;
	currentLanguage: string;
}

// TODO: 
/*
1. Create page module group, append to workingSession.module_grouping
2. Create page module using GetDefaultPageModule, call CreatePageModule, append to workingSession.modules
3. Append newly created page_module to page_module_grouping.modules
4. Create new route in admin-routing-utils.ts to load a module by uuid rather than named path
5. Route to new module
6. Figure out how to handle the module groupings in the session panel
*/
export class PageModuleGroup implements PageModuleGroupModules {
	icon?: string;
	uuid: string;
	name: TranslateString;
	is_on: boolean;
	modules: number[]; // array of module ids representing the display order
	type: string;

	constructor(props: PageModuleGroupProps) {
		this.uuid = uuid();
		this.name = {
			base: props.name,
			[props.currentLanguage]: props.name,
			changed: props.currentLanguage
		};
		this.is_on = true;
		this.modules = [];
		this.type = "custom";
	}

	ready = async () => {
		await this.setTransliterated(this.name.base);
	};

	setTransliterated(name: string): Promise<void> {
		return latinChars.slugify(name).then(res => {
			this.name.transliterated = res;
			return;
		});
	}

	toJson(): PageModuleGroupModules {
		return {
			uuid: this.uuid,
			name: this.name,
			is_on: this.is_on,
			modules: this.modules,
			type: this.type
		};
	}
}