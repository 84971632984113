import { useEffect, useRef, useState } from "react";

import { IRegistrationGDPR } from "../../connection/registration";
import Socket, { SocketMessage } from "../../connection/socket";
import SocketManager from "../../connection/socket-main-thread/socket-manager";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { LanguagesAbbr, MarkedAttendedRegistration } from "../../types/working-model";

interface UserRegistration {
	registration_id?: number;
	event: number;
	created?: string;
	email: string | null;
	values: { [key: number]: string };
	from_saml: boolean;
	uuid?: string;
	valid_sessions: string[] | null;
	bl_profile: number | null;
	channel: number;
	user_sessions: number[] | null;
	utm_params: any | null;
	referrer?: string;
	hidden?: boolean;
	valid_passcode_lists?: number[];
	registered_language?: LanguagesAbbr;
	gdpr?: IRegistrationGDPR;
	registration_bypass?: boolean;
	marked_attended_by_admin?: MarkedAttendedRegistration;
	deleted: boolean;
}

const useResetRegistrationSockets = () => {
	const socket = useRef<Socket | null>(null);
	const [hasSocket, setHasSocket] = useState(false);

	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const registrationId = useTypedSelector(event => event.LiveEventReducer.registrationId);

	const socketKey = `event-reset-registrations-${eventBundle?.uuid}`;

	// only acquire a new connection to this socket when the uuid actually changes and does not contain undefined (which is only while the page is loading)
	useEffect(() => {
		if (!socketKey.includes('undefined')) {
			socket.current = SocketManager.get(socketKey);
			setHasSocket(true);
		}

		return () => {
			SocketManager.leave(socketKey);
			setHasSocket(false);
		};
	}, [socketKey]);

	useEffect(() => {
		const handleResetRegistrations = async (message: SocketMessage) => {
			if (registrationId) {
				try {
					if (message?.data?.registrationsToRevoke?.length) {
						const registrations: UserRegistration[] = message.data.registrationsToRevoke;
						registrations.forEach(registration => {
							if (registrationId === registration.uuid) {
								return window.location.reload();
							}
						});
					}
				} catch (e) {
					console.error(e);
				}
			}
		};

		if (hasSocket) {
			socket.current?.addListener('reset_registrations', handleResetRegistrations);
		}
	}, [hasSocket, registrationId]);
};

export default useResetRegistrationSockets;