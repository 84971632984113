import { AdminCreateEventChat, AdminDeleteEventChat, AdminGetEventChats, AdminRenameEventChat } from "../../../connection/event-chat";
import { AsyncActionType, AsyncAction, Action } from "../../../types/actions";
import { LanguagesAbbr } from "../../../types/working-model";
import { IEventChat } from "../../reducers/event/event-chat-reducer";

export const GET_ADMIN_EVENT_CHATS = "GET_ADMIN_EVENT_CHATS";
export function getEventChats(token: string, eventUuid: string): AsyncActionType<IEventChat[]> {
	return {
		type: GET_ADMIN_EVENT_CHATS,
		promise: AdminGetEventChats(token, eventUuid)
	};
}

export const CREATE_ADMIN_EVENT_CHAT = "CREATE_ADMIN_EVENT_CHAT";
export function createAdminEventChat(token: string, event: number, defaultLangauge: LanguagesAbbr, languages: LanguagesAbbr[], title: string, image: string | null): AsyncActionType<IEventChat> {
	return {
		type: CREATE_ADMIN_EVENT_CHAT,
		promise: AdminCreateEventChat(token, event, defaultLangauge, languages, title, image)
	};
}

export const DELETE_ADMIN_EVENT_CHAT = "DELETE_ADMIN_EVENT_CHAT";
export function deleteAdminEventChat(token: string, eventChat: number): AsyncAction {
	return {
		type: DELETE_ADMIN_EVENT_CHAT,
		promise: AdminDeleteEventChat(token, eventChat)
	};
}

export const CLEAR_ADMIN_EVENT_CHAT = "CLEAR_ADMIN_EVENT_CHAT";
export function clearAdminEventChat(): Action {
	return {
		type: CLEAR_ADMIN_EVENT_CHAT,
		payload: true
	};
}

export const RENAME_EVENT_CHAT_CHANNEL = "RENAME_EVENT_CHAT_CHANNEL";
export function renameEventChatChannel(token: string, id: number, name: string): AsyncAction {
	return {
		type: RENAME_EVENT_CHAT_CHANNEL,
		promise: AdminRenameEventChat(token, id, name)
	};
}