import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { TicketingSet } from '../../../types/working-model';
import { ADD_PAID_LIST_MEMBER, GET_PAID_LISTS, GET_PAID_LIST_MEMBERS, SEARCH_PAID_LIST_MEMBERS, SET_SELECTED_PAID_LIST } from '../../actions/admin/paid-lists-members';

export interface IPaidList {
	ticketingSet: TicketingSet;
	eventName: string;
	eventUuid: string;
	members: number;
	sessionNames: string[];
	created_at?: Date;
}

export interface PaidListsResponse {
	[key: string]: IPaidList;
}

export interface TicketSetMember {
	succeeded: boolean;
	email: string;
	created: string;
	event: number;
	currency: string;
	amount: number;
	fee_amount: number;
	ticket_uuid: number;
}

export interface PaidListMemberState {
	ticketSets: PaidListsResponse;
	ticketSetMembers: TicketSetMember[];
	loadingTicketSets: boolean;
	errorLoadingTicketSets: boolean;
	loadingSetMembers: boolean;
	errorLoadingSetMembers: boolean;
	selectedPaidList: IPaidList | null;
}

const initialState: PaidListMemberState = {
	errorLoadingSetMembers: false,
	errorLoadingTicketSets: false,
	loadingSetMembers: false,
	loadingTicketSets: false,
	selectedPaidList: null,
	ticketSetMembers: [],
	ticketSets: {},
};

export default function PaidListMemberReducer(state = initialState, action: Action): PaidListMemberState {
	switch (action.type) {
		case GET_PAID_LISTS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingTicketSets: true }),
				finish: state => ({ ...state, loadingTicketSets: false }),
				success: state => ({ ...state, ticketSets: action.payload })
			});
		}
		case GET_PAID_LIST_MEMBERS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingSetMembers: true }),
				finish: state => ({ ...state, loadingSetMembers: false }),
				success: state => ({ ...state, ticketSetMembers: action.payload.members })
			});
		}
		case SEARCH_PAID_LIST_MEMBERS: {
			return handle(state, action, {
				start: state => ({ ...state, loadingSetMembers: true }),
				finish: state => ({ ...state, loadingSetMembers: false }),
				success: state => ({ ...state, ticketSetMembers: action.payload })
			});
		}
		case ADD_PAID_LIST_MEMBER: {
			return ({ ...state, ticketSetMembers: [action.payload, ...state.ticketSetMembers] });
		}
		case SET_SELECTED_PAID_LIST: {
			return ({ ...state, selectedPaidList: action.payload });
		}
		default: return state;
	}
}