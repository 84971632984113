import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router';
import { Link } from "react-router-dom";
import { isArray, isEqual } from "underscore";

import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { CreateNewPasscodeList } from "../../../../../../../../connection/passcode-lists";
import { ParamsProps } from '../../../../../../../live-event/live-event';
import { addRegistrationRequirement, removeRegistrationRequirement, setRegistrationType, updateRegistrationRequirement, } from "../../../../../../../../store/actions/admin/create-event";
import { addPasscodeList, getPasscodeLists } from "../../../../../../../../store/actions/admin/passcode-lists";
import { addChecklistItem } from "../../../../../../../../store/actions/authentication";
import { ChecklistTitles } from "../../../../../../../../store/reducers/admin/assistant";
import { GateTypes, PasscodeList, PASSCODE_LIST_TYPE, RegistrationTypes, Requirement, Session, FeatureFlagsEnum, ChannelFeaturesEnum } from "../../../../../../../../types/working-model";
import { isUsingCustomSSO } from "../../../../../../../../utils/sso-utils";
import { toDict } from "../../../../../../../../utils/utils";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import LargeButton from "../../../../../../../general-ui/button/large-button";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import ModalComponent from "../../../../../../../general-ui/modal/modal";
import { Popover } from "../../../../../../../general-ui/popover/popover";
import SelectInput from "../../../../../../../general-ui/select/select";
import SessionSelectInput from "../../../../../../../general-ui/select/session-select";
import Switch from "../../../../../../../general-ui/switch/switch";
import TagSelectInput from "../../../../../../../general-ui/tag-select/tag-select";
import TextInput, { Validation } from "../../../../../../../general-ui/text-input/text";
import { Tooltip } from "../../../../../../../general-ui/tooltip/tooltip";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import PasscodeListCreator, { WorkingPasscodeList } from "../../../../../registration/passcode-list";
import { PrimaryTooltip } from "../../../../../session/session-modal";
import { PATHNAMES } from "../../../../../../../../utils/admin-routing-utils";
import { AudienceListRadios } from "../../../../../registration/audience-list-radios";
import { generateMagicLinksForEvent } from "../../../../../../../../store/actions/admin/magic-links";
import { OptionalComponent } from "../../../../../../../../utils/optional-component";
import CustomPageGating from "../../event/custom-page-nav/page-gating";

export const gateTypeOptions = [
	{
		label: "Domain email",
		value: GateTypes.domain_email
	},
	{
		label: "Shared passcode",
		value: GateTypes.shared_passcode
	},
	{
		label: "Audience List",
		value: GateTypes.passcode_list
	}
];

function getEmptyGateRequirement(type = GateTypes.domain_email): Requirement {
	return {
		gatedSessions: [],
		type,
	};
}

const RegistrationGating: React.FC = () => {
	const passcodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const channelFeatures = useTypedSelector(state => state.AuthReducer?.channels?.find(c => c.channel === state.AuthReducer?.user?.active_channel)?.enabled_features);

	const publishedUrl = useTypedSelector(state => state.CreateEventReducer.publishedUrl);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);

	const requirements = workingEvent?.registration_settings?.requirements;
	const isRegistrationGating = workingEvent?.registration_settings?.type === RegistrationTypes.gated;
	const emailGating = requirements?.[GateTypes.domain_email];
	const passcodeGating = requirements?.[GateTypes.shared_passcode];
	const passcodeListGatings = requirements?.[GateTypes.passcode_list];
	// registration gating cannot be toggled on when custom SSO is in use
	const usingCustomSSO: boolean = isUsingCustomSSO(workingEvent);

	const [showRegistrationGating, setShowRegistrationGating] = useState(true);
	const [gatingModalOpen, setGatingModalOpen] = useState(false);
	// if user clicks cancel when gating modal first appears and no current gating requirements exits, turn gating off
	const [canCancelGating, setCanCancelGating] = useState(false);
	const [popoverOpen, setPopoverOpen] = useState<GateTypes | null>(null);
	const [openPasscodeListIndex, setOpenPasscodeListIndex] = useState<number | null>(null); //this will be the index of passcodeListGatings 
	const [gatingRequirementsOpen, setGatingRequirementsOpen] = useState(false);
	const [disableEditType, setDisableEditType] = useState(false);
	const [workingGateRequirement, setWorkingGateRequirement] = useState<Requirement | null>(null);
	const [loading, setLoading] = useState(false);
	const [passcodeListToCreate, setPasscodeListToCreate] = useState<WorkingPasscodeList>();
	const [formError, setFormError] = useState("");
	const [creatingNewPasscodeList, setCreatingNewPasscodeList] = useState(!passcodeLists);

	const dmaEnabled = channelFeatures?.includes(ChannelFeaturesEnum.dma_law);
	const FF_MAGIC_LINK = featureFlags[FeatureFlagsEnum.magic_links];

	const dispatch = useDispatch();
	const { language } = useParams() as ParamsProps;

	useEffect(() => {
		if (user?.active_channel && token) {
			dispatch(getPasscodeLists(user.active_channel, token));
		}
	}, [dispatch, token, user]);

	// passing a type in turns this into a Edit gate requirement function
	const addGatedRequirement = (type?: GateTypes) => {
		// determine if a requirement type does not exist, have that type loaded first, prioritize email
		const _type = !emailGating
			? GateTypes.domain_email
			: !passcodeListGatings && !passcodeLists.length
				? GateTypes.shared_passcode
				: GateTypes.passcode_list;
		setGateType(type || _type);
		setGatingRequirementsOpen(true);
		// do not allow admin to edit gate type when editing a requirement
		setDisableEditType(!!type);
	};
	const closeGatingRequirements = () => {
		if (canCancelGating) turnOffGating();
		setGatingRequirementsOpen(false);
		setOpenPasscodeListIndex(null);
	};

	const closePopover = () => {
		setPopoverOpen(null);
	};
	const openPopover = (key: GateTypes, passcodeListIndex?: number) => {
		setOpenPasscodeListIndex(passcodeListIndex ?? null);
		setPopoverOpen(key);
	};

	const closeGatingModal = () => setGatingModalOpen(false);

	const turnOffGating = () => {
		dispatch(setRegistrationType(RegistrationTypes.open));
		closeGatingModal();
	};

	// const handleRegistrationGating = (type: RegistrationTypes) => {
	const handleRegistrationGating = (_: any, isOn: boolean) => {
		const type = isOn ? RegistrationTypes.gated : RegistrationTypes.open;
		if (type === RegistrationTypes.open) {
			setGatingModalOpen(true);
		} else {
			dispatch(setRegistrationType(type));
			addGatedRequirement();
			// if there are not any gating requirements, then canCancelGating
			requirements && Object.keys(requirements).length === 0 && setCanCancelGating(true);
		}
	};

	const removeRequirement = (type: GateTypes) => {
		// if deleting the last gating requirement (or last passcode list), then turn off gating
		if (requirements && Object.keys(requirements)?.length === 1) {
			if (requirements[GateTypes.passcode_list]) {
				if (requirements[GateTypes.passcode_list]?.length === 1) {
					turnOffGating();
				}
			} else {
				turnOffGating();
			}
		}
		dispatch(removeRegistrationRequirement(type, openPasscodeListIndex ?? undefined));
		setOpenPasscodeListIndex(null);
		setPopoverOpen(null);
	};

	const codeOrListError = "Your event cannot have a shared passcode AND an audience list.";
	const passcodeListNameError = "Your list must have a name.";
	const passcodeListURLError = "You must upload an audience list.";
	const noSessionsError = "You must connect at least one Session to this list.";
	const formErrorMessage = () => {
		switch (workingGateRequirement?.type) {
			//only Passcode list OR shared pass code may exist at the same time
			case GateTypes.passcode_list: {
				if (passcodeGating) return codeOrListError;
				if (!workingGateRequirement.gatedSessions?.length) return noSessionsError;
				if (passcodeListToCreate && !passcodeListToCreate.url) return passcodeListURLError;
				if (passcodeListToCreate && !passcodeListToCreate.name) return passcodeListNameError;
				else return "";
			}

			case GateTypes.shared_passcode: {
				const hasNonWhiteSpaceCharacters = /\S/;
				if (passcodeListGatings?.length) return codeOrListError;
				else if (!workingGateRequirement.sharedPasscode) return "Passcode field must have a value";
				else if (!hasNonWhiteSpaceCharacters.test(workingGateRequirement.sharedPasscode)) return "Passcode must have at least one character";
				else return "";
			}
			case GateTypes.domain_email: {
				if (!workingGateRequirement.allowedDomains?.length) return "Must include at least one email domain";
				//If the domain doesnt include either .** or .*** warn the user
				const domainRegex = /\.[a-z]{2,3}/i;
				for (const domain of workingGateRequirement.allowedDomains) {
					if (!domainRegex.test(domain)) {
						return "Please include an extension e.g. '.com'";
					}
				}
				return "";
			}
			default: return "";
		}
	};

	const downloadErrorCSV = (newPasscodeList: any) => {
		// Downloads a csv file with all the broken users
		if (newPasscodeList.csv) {
			const blob = new Blob([newPasscodeList["csv"]], { type: 'text/csv;charset=utf-8' });
			const link = document.createElement('a');
			link.setAttribute('href', URL.createObjectURL(blob));
			link.setAttribute('download', 'magic-link-nonvalid-emails.csv');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	async function finished() {
		if (!token || !user?.active_channel || fetchingPublishedStatus) return;

		// Prevent attaching a magic link list to the event if event has never been published
		const hasMagicLinks = creatingNewPasscodeList
			? passcodeListToCreate?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS
			: workingGateRequirement?.passcodeList?.some((listId) => {
				return passcodeListMap[listId]?.type === PASSCODE_LIST_TYPE.MAGIC_LINKS;
			});
		if (hasMagicLinks && !publishedUrl) {
			return showAlert({
				message: 'Please Publish Project First',
				description: `You need to publish ${workingEvent?.name || 'this event'} first before you can attach a magic links list`,
				type: 'error',
				duration: 5000,
			});
		}

		if (formErrorMessage()) {
			setFormError(formErrorMessage());
			return;
		} else {
			setFormError("");
		}

		let _workingGateRequirement = workingGateRequirement;

		// Check for no passcode lists
		if (!passcodeListToCreate && !workingGateRequirement?.passcodeList?.length && workingGateRequirement?.type === GateTypes.passcode_list) {
			return showAlert({
				message: 'Audience Lists Required',
				description: 'Please choose one or more audience lists from the dropdown.',
				type: 'error',
				duration: 5000,
			});
		}

		if (passcodeListToCreate && workingGateRequirement?.type === GateTypes.passcode_list) {
			const listToCreate = {
				...passcodeListToCreate,
				name: passcodeListToCreate.name.trim(),
			};

			// Check for empty name
			if (!listToCreate.name) {
				return showAlert({
					message: 'Name Required',
					description: 'Please enter a unique name for this audience list.',
					type: 'error',
					duration: 5000,
				});
			}

			// Check for duplicate names
			const lowerCaseName = listToCreate.name.toLowerCase();
			const isDuplicate = passcodeLists.some((list) => {
				return lowerCaseName === list.name?.toLowerCase();
			});
			if (isDuplicate) {
				return showAlert({
					message: 'Duplicate Audience List Name',
					description: `"${listToCreate.name}" is already in use. Please enter a unique name.`,
					type: 'error',
					duration: 5000,
				});
			}

			setLoading(true);
			const newPasscodeList = await CreateNewPasscodeList(token, user.active_channel, listToCreate, workingEvent?.uuid, undefined, workingEvent?.dma_id);

			if ("csv" in newPasscodeList) {
				showAlert({
					message: 'Unable to Create',
					description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
					type: 'error',
					duration: 10000,
					onClick: () => downloadErrorCSV(newPasscodeList)
				});
				return setLoading(false);
			}
			else if ("error" in newPasscodeList) {
				showAlert({
					message: 'Unable to Create',
					description: newPasscodeList.error ? newPasscodeList.error : 'Failed to create new audience list',
					type: 'error',
					duration: 5000,
				});
				return setLoading(false);
			}
			if (isArray(newPasscodeList.failedEmails) && newPasscodeList.failedEmails.length > 0) {
				showAlert({
					message: 'Some emails were invalid and failed to upload:',
					description: newPasscodeList.failedEmails.join(' '),
					type: 'error',
					duration: 5000
				});
			}
			dispatch(addPasscodeList(newPasscodeList));

			const previousList = workingGateRequirement?.passcodeList || [];
			_workingGateRequirement = workingGateRequirement
				? {
					...workingGateRequirement,
					passcodeList: [
						...previousList,
						(newPasscodeList.passcode_list)
					]
				}
				: null;

			if (token && user && newPasscodeList && newPasscodeList.passcode_list && workingEvent?.uuid && hasMagicLinks) {
				dispatch(generateMagicLinksForEvent(token, user?.active_channel, newPasscodeList?.passcode_list, workingEvent?.uuid));
			}
		}

		if (_workingGateRequirement) {
			const type = _workingGateRequirement?.type;
			// To avoid duplicate requirements, first check if requirement exists and update accordingly. If the type doesnt exist then add it
			if (type === GateTypes.passcode_list && openPasscodeListIndex !== null) {
				//must pass the passcodeList index here
				dispatch(updateRegistrationRequirement(GateTypes.passcode_list, _workingGateRequirement, openPasscodeListIndex));
			} else if (type === GateTypes.domain_email && emailGating || type === GateTypes.shared_passcode && passcodeGating) {
				dispatch(updateRegistrationRequirement(type, _workingGateRequirement));
			} else {
				dispatch(addRegistrationRequirement(type, _workingGateRequirement));

				// ASSISTANT CHECKLIST ITEM: complete users checklist item for editing registration settings
				if (
					user &&
					!user.onboarded.includes(ChecklistTitles.editRegistration)
				) dispatch(addChecklistItem(token, user.id, user.onboarded, ChecklistTitles.editRegistration));
			}
		}

		setCanCancelGating(false);
		setOpenPasscodeListIndex(null);
		setWorkingGateRequirement(null);
		setGatingRequirementsOpen(false);
		setLoading(false);
		setPasscodeListToCreate(undefined);
	}
	function setSessionIds(sessions: number[]) {
		if (!isEqual(workingGateRequirement?.gatedSessions, sessions)) {
			setWorkingGateRequirement((workingGateRequirement: Requirement | null) => {
				if (workingGateRequirement) {
					return ({ ...workingGateRequirement, gatedSessions: sessions });
				} else {
					return null;
				}
			});
		}
	}
	function setGateType(value: GateTypes) {
		setFormError(""); //default to no form errors
		setWorkingGateRequirement(() => {
			// load the existing requirement if it exists, if not load an empty req
			switch (value) {
				case GateTypes.passcode_list: {
					if (passcodeGating) setFormError(codeOrListError); //display error message if they already have passcode gating
					//openPasscodeListIndex will be the index of the current passlist in passcodeListGatings
					return (passcodeListGatings?.length && openPasscodeListIndex !== null)
						? passcodeListGatings[openPasscodeListIndex]
						: getEmptyGateRequirement(GateTypes.passcode_list);
				}
				case GateTypes.shared_passcode: {
					if (passcodeListGatings?.length) setFormError(codeOrListError);//display error message if they already have passlist gating
					setPasscodeListToCreate(undefined);
					return passcodeGating ? passcodeGating : getEmptyGateRequirement(GateTypes.shared_passcode);
				}
				default: {
					setPasscodeListToCreate(undefined);
					return emailGating ? emailGating : getEmptyGateRequirement(GateTypes.domain_email);
				}
			}
		});

	}
	function setAllowedDomains(domains: string[]) {
		//remove @, and change to lower case
		const constrainedDomains = domains.map(domain => domain.replace("@", "").toLowerCase());

		setWorkingGateRequirement((workingGateRequirement: Requirement | null) => {
			if (workingGateRequirement) {
				return ({ ...workingGateRequirement, allowedDomains: constrainedDomains });
			} else {
				return null;
			}
		});
	}

	function setPasscode(e: React.ChangeEvent<HTMLInputElement>) {
		const val = e.target.value.toLocaleLowerCase();
		setWorkingGateRequirement((workingGateRequirement: Requirement | null) => {
			if (workingGateRequirement) {
				return ({ ...workingGateRequirement, sharedPasscode: val });
			} else {
				return null;
			}
		});
	}

	// To consolidate the change handling, this now accepts string[] from TagSelectInput via PasscodeListCreator...
	function handleChangePasscodeLists(listNames: string[]) {
		// ...but we'll need to update the working gate requirement with a list of IDs...
		setWorkingGateRequirement((workingGateRequirement: Requirement | null) => {
			if (workingGateRequirement) {
				// ...by first finding the passcode lists by name (yes, this is a problem)
				const lists = listNames.reduce((lists: PasscodeList[], name) => {
					if (passcodeLists) {
						const found = passcodeLists.find((list) => name === list.name);
						if (found) lists.push(found);
						return lists;
					}
					else {
						return [];
					}
				}, []);

				// ...and then mapping that into a list of IDs
				const passcodeList: number[] = lists
					.map(list => list.passcode_list)
					.filter(listNum => listNum) as number[];

				return {
					...workingGateRequirement,
					passcodeList,
				};
			} else { return null; }
		});
	}
	// keeping this function and useEffect around in case product wants to alert admin that its a partially gated event
	// function validGates(): Session[] {
	// 	if (workingEvent?.registration_settings?.requirements && workingEvent.sessions) {
	// 		//if there are passcode list requirements
	// 		if (passcodeListGatings && passcodeListGatings?.length > 0) {

	// 			//array of session ids that have passcode lists attached
	// 			const sessionsWithLists = flatten(passcodeListGatings.map((requirement) => requirement.gatedSessions));

	// 			//ensure that every session id is in the sessionsWithLists array
	// 			const needsGates = workingEvent.sessions.filter(session => !sessionsWithLists.includes(session.session));

	// 			return needsGates;
	// 		}
	// 	}
	// 	return [];
	// }
	// const invalidSessions = useMemo(validGates, [workingEvent, passcodeListGatings]);
	// useEffect(() => {
	// 	if (invalidSessions.length) {
	// 		const invalidList: string = invalidSessions.map(session => session.title.base).join(", ");
	// 		showAlertLong({
	// 			type: "error",
	// 			message: "If an audience list is connected to any session, all sessions must include an audience list. Please connect an audience list to: ",
	// 			description: invalidList,
	// 		});
	// 	}
	// }, [invalidSessions]);

	const passcodeListMap = useMemo(() => toDict('passcode_list', passcodeLists), [passcodeLists]);
	const renderPasslists = (currentPasscodeList: Requirement) => {
		const lists = currentPasscodeList
			?.passcodeList
			?.map((listId: number) => passcodeListMap[listId])
			?.filter(list => !!list)
			?? [];
		return (

			<ul className="tag-list">
				{lists.map((list: PasscodeList) => (

					FF_MAGIC_LINK ?
						<>
							{
								<Link
									role="button"
									key={list?.passcode_list}
									to={PATHNAMES.AudienceList.AudienceListLink(workingEvent ? workingEvent?.uuid : "", language, list.uuid)}
									className={`clear no-style ${list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? "cursor-type-normal" : ""}`}
									onClick={list.type === PASSCODE_LIST_TYPE.MAGIC_LINKS ? (event) => event.preventDefault() : undefined}
								>
									<li className="tag" key={list?.passcode_list} >
										{list?.name}
									</li>
								</Link>
							}
						</> :

						<li className="tag" key={list?.passcode_list} >
							{list?.name}
						</li>

				))}
			</ul>
		);
	};

	const sessionMap = useMemo(() => {
		if (workingEvent?.sessions) {
			return toDict('session', workingEvent.sessions);
		}
		return {};
	}, [workingEvent]);

	const renderGatedSessions = (currentPasscodeList: Requirement) => {
		const sessions = currentPasscodeList
			?.gatedSessions
			?.map((sessionId: number) => sessionMap[sessionId])
			?.filter(session => !!session)
			?? [];
		return (
			<ul className="tag-list">
				{sessions?.map((session: Session) => (
					<div className="tag" key={session.session}>
						{session.title?.base || ''}
					</div>
				))}
			</ul>
		);
	};

	const allowedDomains = useMemo(() => {
		return (<ul className="tag-list">
			{
				emailGating
					?.allowedDomains
					?.map((domain, i) => (
						<li className="tag capitalize" key={i}>
							{domain}
						</li>
					))
			}
		</ul>);
	}, [emailGating]);

	const getEditRequirementButton = (type: GateTypes, label: string, passcodeListIndex?: number) => {
		const isOpen = type !== GateTypes.passcode_list
			? popoverOpen === type
			: popoverOpen === type && passcodeListIndex === openPasscodeListIndex;
		return (
			<label>
				{label}
				<button
					onClick={() => openPopover(type, passcodeListIndex)}
					className="no-style options-button"
				>
					<Icon name={ICONS.THREE_DOTS_VERTICAL} color={COLORS.WHITE} size={16} />
					<Popover
						open={isOpen}
						onClose={closePopover}
					>
						<button onClick={() => addGatedRequirement(type)}>Edit Requirements</button>
						<button onClick={(e) => {
							// stop propagation to parent element as to not fire that onClick as well.
							e.stopPropagation();
							removeRequirement(type);
							document.body.click(); // this forces a click to trigger popover state close so that we can immedatiately open another popover on click
						}}>Delete</button>
					</Popover>
				</button>
			</label>
		);
	};

	const getTypeOptions = () => {
		switch (workingGateRequirement?.type) {
			case GateTypes.domain_email: {
				return (
					<div>
						<TagSelectInput
							onChange={setAllowedDomains}
							label={"Allowed Email Domains *"}
							tags={workingGateRequirement.allowedDomains ?? []}
							errorMessage={formError}
							disableFocus
						/>
					</div>
				);
			}
			case GateTypes.passcode_list: {
				const passCodeListNames = workingGateRequirement.passcodeList?.map(id => passcodeListMap[id]);
				return (
					<PasscodeListCreator
						isCreating={dmaEnabled ? true : creatingNewPasscodeList}
						onChange={handleChangePasscodeLists}
						setPasscodeListToCreate={setPasscodeListToCreate}
						passcodeLists={passCodeListNames}
						nameErrorMessage={formError === passcodeListNameError ? passcodeListNameError : undefined}
						urlErrorMessage={formError === passcodeListURLError ? passcodeListURLError : undefined}
						isCustomGating={false}
						eventHasBeenPublished={Boolean(publishedUrl)}
					/>
				);
			}
			case GateTypes.shared_passcode: {
				return (
					<div>
						<TextInput
							placeholder={"Enter shared passcode"}
							label={"Shared Passcode"}
							tooltip={[PrimaryTooltip, 'Note: shared passcodes are not case sensitive']}
							onChange={setPasscode}
							value={workingGateRequirement?.sharedPasscode || ''}
							valid={(formError !== codeOrListError) && formError ? Validation.error : Validation.normal}
							errorMessage={(formError !== codeOrListError) && formError || undefined}
						/>
					</div>
				);
			}
			default: return <></>;
		}
	};

	const ConfirmationModal = (
		<ModalComponent
			size='small'
			closeable={false}
			cancellable={false}
			open={gatingModalOpen}
			onRequestClose={closeGatingModal}
			title={"Turn the registration gating off?"}
			footer={(
				<>
					<button onClick={closeGatingModal}>Cancel</button>
					<button onClick={turnOffGating} className="lemonade">
						Turn off gating
					</button>
				</>
			)}
		>
			<p>If you turn the registration gating off, all attendees will have the same type of access to the whole event content.</p>
		</ModalComponent>
	);

	return (
		<>
			<div className="settings-card">
				<label>
					{isRegistrationGating && (
						<button className={classNames("no-style", { show: showRegistrationGating })} onClick={() => setShowRegistrationGating(prev => !prev)}>
							<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} size={12} color={COLORS.WHITE} />
						</button>
					)}
					Gating
					<Tooltip tooltip="Grant access to your sessions based on individual session or tracks. Be sure to account for each session by assigning a unique registration type for each.">
						<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
					</Tooltip>
				</label>
				<Switch
					value={"Single sign on"}
					on={isRegistrationGating}
					onClick={handleRegistrationGating}
					disabled={usingCustomSSO}
				/>
			</div>
			<OptionalComponent display={!!workingEvent?.custom_pages?.length && !!workingEvent?.custom_pages?.find(page => !page.deleted)}>
				<CustomPageGating />
			</OptionalComponent>
			{showRegistrationGating && isRegistrationGating && (
				<>
					{emailGating && (
						<div className="settings-card sub-list detailed-card">
							{getEditRequirementButton(GateTypes.domain_email, "Allowed email domains")}
							{allowedDomains}
						</div>
					)}
					{passcodeGating && (
						<div className="settings-card sub-list detailed-card">
							{getEditRequirementButton(GateTypes.shared_passcode, "Shared passcode")}
							<p className="tag clear">{passcodeGating?.sharedPasscode}</p>
						</div>
					)}
					{passcodeListGatings?.length
						? passcodeListGatings.map((passcodeList, index) => (
							<div key={index} className="settings-card sub-list detailed-card">
								{getEditRequirementButton(GateTypes.passcode_list, "Audience lists", index)}
								{renderPasslists(passcodeList)}
								<p>Gated Sessions</p>
								{renderGatedSessions(passcodeList)}
							</div>
						))
						: null
					}
					<div className="left-margin">
						<LargeButton
							title={"Add Requirements"}
							style={{ width: "100%" }}
							//undefined needed for the exclusion logic to work.
							//otherwise it'll send the click event and mess that up
							onClick={() => addGatedRequirement(undefined)}
							nonUpload
						/>
					</div>
				</>
			)}

			{/* GatingRequirements Settings Modal */}
			<ModalComponent
				size="medium"
				open={gatingRequirementsOpen}
				onRequestClose={closeGatingRequirements}
				title={"Gate Requirements"}
				cancellable={false}
				closeable={false}
				footer={(
					<>
						<button onClick={closeGatingRequirements}>Cancel</button>
						<button disabled={loading} onClick={finished} className="lemonade">
							{loading || fetchingPublishedStatus ? <WaitingIndicator /> : 'Apply'}
						</button>
					</>
				)}
			>
				<div className="gating-requirements-modal">
					{workingGateRequirement?.type === GateTypes.passcode_list && (
						<SessionSelectInput
							sessions={workingEvent?.sessions ?? []}
							onChange={setSessionIds}
							placeholder={"Select at least one session"}
							preSelectedSessions={workingGateRequirement?.gatedSessions}
							errorMessage={formError === noSessionsError ? noSessionsError : undefined}
							ignoreInlineStyling
						/>
					)}

					<div className="gating-dropdown-container">
						<SelectInput
							options={gateTypeOptions}
							selected={workingGateRequirement?.type || GateTypes.domain_email}
							onChange={setGateType}
							label="Type of Gating*"
							mainIcon={<Icon name={ICONS.KEYBOARD_ARROW_DOWN} size={12} color={`${COLORS.WHITE} select-icon`} />}
							iconIfOpen={<Icon name={ICONS.KEYBOARD_ARROW_UP} size={12} color={COLORS.WHITE} />}
							errorMessage={formError === codeOrListError ? formError : undefined}
							ignoreSpacing
							ignoreInlineStyling
							disabled={disableEditType}
						/>
						{workingGateRequirement?.type === GateTypes.domain_email ? (
							<p className="info">
								Allow registration for all emails associated with a domain listed in the email field.
							</p>
						) : workingGateRequirement?.type === GateTypes.shared_passcode ? (
							<p className="info">
								Allow registration for all users with a passcode below.
							</p>
						) : workingGateRequirement?.type === GateTypes.passcode_list && !dmaEnabled ? (
							<div style={{ marginTop: '21px' }}>
								<AudienceListRadios
									setCreatingNewPasscodeList={setCreatingNewPasscodeList}
									creatingNewPasscodeList={creatingNewPasscodeList}
								/>
							</div>
						) : null}
					</div>
					{getTypeOptions()}
				</div>
			</ModalComponent>
			{ConfirmationModal}
		</>
	);
};

export default RegistrationGating;
