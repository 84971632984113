import { isObject } from "underscore";
import { BLAdmin } from "../types/working-model";
import {
	EPaletteModes,
	ColorOptions,
	TColorVariantsExplicit,
} from "types/theme-packs";

export class IncorrectTypeError extends Error {
	constructor(...args: any) {
		super(args);
	}
}

export function assertIsAnchorElement(el: any): asserts el is HTMLAnchorElement {
	if (typeof el !== 'object' && el?.nodeName !== 'A') {
		throw new IncorrectTypeError(`Anchor element required, received ${el.nodeName}`);
	}
}

export function assertIsButtonElement(el: any): asserts el is HTMLButtonElement {
	if (typeof el !== 'object' && el?.nodeName !== 'BUTTON') {
		throw new IncorrectTypeError(`Button element required, received ${el.nodeName}`);
	}
}

export function assertIsHTMLInputElement(el: any): asserts el is HTMLInputElement {
	if (typeof el !== 'object' && el?.nodeName !== 'INPUT') {
		throw new IncorrectTypeError(`Property 'value' does not exist on type 'HTMLElement'.`);
	}
}

export function assertAdminIsSignedIn(user: BLAdmin | undefined | null): asserts user is BLAdmin {
	if (!user) {
		throw new Error("User must be signed in to access this view.");
	}
}

export function assertTokenIsSet(token: string | undefined | null): asserts token is string {
	if (!token) {
		throw new Error("User must be signed in to access this view.");
	}
}

export function assertIsColorOptions(colors: unknown): asserts colors is TColorVariantsExplicit {
	if (!isObject(colors)) {
		throw new IncorrectTypeError(`ColorOptions required, received ${typeof colors}`);
	}

	if (
		!colors.accentColor
		|| !colors.headingTextColor
		|| !colors.backgroundColor
		|| !colors.bodyTextColor
		|| !colors.containerColor
		|| !colors.secondaryAccentColor
		|| !colors.secondaryBackgroundColor
	) {
		throw new IncorrectTypeError(`ColorOptions missing color keys ${colors}`);
	}
}

export function assertIsColorOptionsV2(colors: unknown): asserts colors is ColorOptions {
	if (!isObject(colors)) {
		throw new IncorrectTypeError(`ColorOptions V2 required, received ${typeof colors}`);
	}

	if (!colors?.[EPaletteModes.Light] && !colors?.[EPaletteModes.Dark]) {
		throw new IncorrectTypeError(`ColorOptions V2 missing color "light" or "dark" theme key ${colors}`);
	}
}
