import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";

import NewDropdown from "../../../general-ui/dropdown/new-dropdown";
import { SelectOption } from "../../../general-ui/select/select";
import { useTranslate } from "../../../../i18n/useTranslationModules";
import useTranslationRoute from "../../hooks/use-translation-route";
import Checkbox from "../../../general-ui/checkbox/checkbox";
import { Dictionary } from "../../../../types/working-model";
import { toDictTyped } from "../../../../utils/utils";
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import '../../../../scss/live-event/base/modules/session-filter.scss';

interface IFilterDropdownProps {
	onChange: (option: SelectOption) => void;
	options: SelectOption[];
	initialTitle?: string;
	template: string;
	value: any;
	className?: string;
	isLangDropdown?: boolean;
	displayOnly?: boolean;
	translatedTitleQuestionFilter?: string;
	animateDropdown?: boolean;
	useArrowOutline?: boolean;
	style?: CSSProperties;
	valueClassName?: string;
}
const FilterDropdown: React.FC<IFilterDropdownProps> = (props) => {
	const {
		onChange,
		template,
		initialTitle,
		options,
		value,
		className,
		isLangDropdown,
		displayOnly = false,
		translatedTitleQuestionFilter,
		animateDropdown = false,
		useArrowOutline = false,
		style,
		valueClassName,
	} = props;

	const automaticSessionFiltering = useTypedSelector(state => state.LiveEventReducer.eventBundle?.automatic_session_filter_by_user_language);

	const translationRoute = useTranslationRoute();
	const { t } = useTranslate(['homepage', translationRoute, 'session', 'languages-list']);
	const [title, setTitle] = useState(translatedTitleQuestionFilter || "");
	const [initialLocalTitle, setInitialLocalTitle] = useState(initialTitle);

	useEffect(() => {
		// if value changes outside this component, then this will update title to the new value;
		const newTitle = options.find(o => o.value === value);
		setTitle(newTitle?.label);
	}, [options, value]);

	useEffect(() => {
		if (initialTitle) {
			// titleChange is to ensure that the title in this local component stays in sync with the parent component
			const titleChange = !title || initialLocalTitle !== initialTitle;

			if ((automaticSessionFiltering && isLangDropdown) || (initialTitle === t('All languages') && titleChange)) {
				setTitle(initialTitle);
				setInitialLocalTitle(initialTitle);
			} else if (initialTitle === 'All languages' && titleChange) {
				setTitle(t(initialTitle, ''));
				setInitialLocalTitle(t(initialTitle, ''));
			}

			if (initialTitle === t('All tracks') && titleChange) {
				setTitle(initialTitle);
				setInitialLocalTitle(initialTitle);
			} else if (initialTitle === 'All tracks' && titleChange) {
				setTitle(t(initialTitle, ''));
				setInitialLocalTitle(t(initialTitle, ''));
			}

			if (initialTitle === t('All dates') && titleChange) {
				setTitle(initialTitle);
				setInitialLocalTitle(initialTitle);
			} else if (initialTitle === 'All dates' && titleChange) {
				setTitle(t(initialTitle, ''));
				setInitialLocalTitle(t(initialTitle, ''));
			}

			if (initialTitle === t('All attributes') && titleChange) {
				setTitle(initialTitle);
				setInitialLocalTitle(initialTitle);
			} else if (initialTitle === 'All attributes' && titleChange) {
				setTitle(t(initialTitle, ''));
				setInitialLocalTitle(t(initialTitle, ''));
			}

			if (initialTitle === t('All categories') && titleChange) {
				setTitle(initialTitle);
				setInitialLocalTitle(initialTitle);
			} else if (initialTitle === 'All categories' && titleChange) {
				setTitle(t(initialTitle, ''));
				setInitialLocalTitle(t(initialTitle, ''));
			}

		} else if (options[0].label === 'All languages') {
			options[0].label = t('All languages');
			setTitle(t('homepage:All languages', 'All languages'));
		} else if (options[0].label === 'All tracks') {
			options[0].label = t('All tracks', 'All Tracks');
			setTitle(t('homepage:All tracks', 'All Tracks'));
		} else if (options[0].label === 'All dates') {
			options[0].label = t('All dates', 'All dates');
			setTitle(t('homepage:All dates', 'All dates'));
		} else if (options[0].label === 'All attributes') {
			options[0].label = t('All attributes', 'All attributes');
			setTitle(t('homepage:All attributes', 'All attributes'));
		} else if (options[0].label === 'All categories') {
			options[0].label = t('All categories', 'All categories');
			setTitle(t('homepage:All categories', 'All categories'));
		} else {
			if (!title && !initialLocalTitle) {
				setTitle(options[0].label);
				setInitialLocalTitle(options[0].label);
			}
		}
	}, [automaticSessionFiltering, initialLocalTitle, initialTitle, isLangDropdown, options, t, title, value]);


	const translateLabel = useCallback((option: SelectOption): string => {
		if (isLangDropdown) {
			const codeToTranslate = option.value ? `languages-list:Languages.${option.value}` : null;
			if (!codeToTranslate) return t(option.label, option.label ?? '');
			// variables in t function is a limitation of typescript, not react-i18n (https://react.i18next.com/latest/typescript#type-errors)
			// I'm changing to "any" instead of disabling typescript. Just seems a little safer to do so
			return t(codeToTranslate as any, '');
		} else {
			return t(option.label, option.label ?? '');
		}
	}, [isLangDropdown, t]);

	return (
		<NewDropdown
			renderTitle={() => translatedTitleQuestionFilter ? translatedTitleQuestionFilter : title}
			template={template}
			className={classNames('filter-dropdown', className, { 'display-only': displayOnly })}
			edge="right"
			isNoScroll={false}
			animateDropdown={animateDropdown}
			useArrowOutline={useArrowOutline}
			limitHeight
			maxHeight='50vh'
			style={style}
			valueClassName={valueClassName}
			ignoreNavigation
		>
			<div className="filter-list">
				{options.map((option) => (
					<button
						key={option.value}
						onClick={() => {
							onChange(option);
							setTitle(option.label);
						}}
						className={classNames('no-style filter-list-item', {
							selected: option.value === value,
						})}
					>
						{translateLabel(option)}
					</button>
				))}
			</div>
		</NewDropdown>
	);
};

export default FilterDropdown;

export interface IGroupOption {
	title: string;
	options: SelectOption[]
	selectAll?: boolean;
}

/** {
 * [title: IGroupOption['title']]: options: SelectOption[];
 * selectAll?: boolean;
 * } */
export type IGroupValue = Dictionary

interface IGroupFilterDropdownProps extends Omit<IFilterDropdownProps, "options" | "onChange" | "value"> {
	/** please memoize the value passed to groupOptions prop */
	groupOptions: IGroupOption[];
	onChange: (value: IGroupValue) => void;
	value: IGroupValue;
}

export const GroupFilterDropdown: React.FC<IGroupFilterDropdownProps> = (props) => {
	const {
		groupOptions,
		onChange,
		template,
		className,
		displayOnly,
		value,
		animateDropdown
	} = props;

	const translationRoute = useTranslationRoute();
	const { t } = useTranslate(['homepage', translationRoute]);

	const groupMapWithOptionsMap = useMemo(() => {
		const _groupOptionsMapped = groupOptions.map(option => ({
			...option,
			options: toDictTyped('value', option.options)
		}));
		const groupMap = toDictTyped('title', _groupOptionsMapped);

		return groupMap;
	}, [groupOptions]);

	const handleChange = useCallback((group: string, option: SelectOption, isOn: boolean) => {

		if (groupMapWithOptionsMap[group].selectAll) {
			const _value = { ...value };
			groupOptions.forEach(group => {
				_value.selectAll = isOn;
				_value[group.title] = isOn ? group.options.map(o => o.value) : [];
			});
			onChange(_value);
			return;
		}

		const _value = { ...value };
		if (isOn) {
			// added check to make sure this group is not the selectAll value so we dont try to spread a non iterable value
			if (!groupMapWithOptionsMap[group].selectAll) {
				_value[group] = [..._value[group], option.value];
			}
			// if all filters are on, toggle on selectAll
			if (groupOptions.every(group => _value[group.title]?.length === group.options?.length)) _value.selectAll = true;
		} else {
			// added check to make sure this group is not the selectAll value so we dont try to spread a non iterable value
			if (!groupMapWithOptionsMap[group].selectAll) {
				_value[group] = _value[group].filter((value: string) => value !== option.value);
			}
			_value.selectAll = false;
		}

		onChange(_value);
	}, [groupMapWithOptionsMap, groupOptions, onChange, value]);

	const renderTitleElement = useMemo(() => {
		if (value.selectAll) {
			const title = groupOptions.find(group => group?.selectAll)?.title;
			return (
				<div className="group-filter-title-container">
					<div className="group-filter-title">
						<p className="ignore-modal-styling value-label">{t(title ?? '') || title}</p>
					</div>
				</div>
			);
		}

		const valueEntries = Object.entries(value);
		if (valueEntries.every(([key, value]) => key === 'selectAll' || !value.length)) {
			return (
				<div className="group-filter-title-container">
					<div className="group-filter-title">
						<p className="ignore-modal-styling value-label">--</p>
					</div>
				</div>
			);
		}

		const numberOfSelectedOptions = valueEntries.reduce((a: string[][], c) => {
			const [key, value]: [string, string[]] = c;
			if (key === 'selectAll') return a;
			const labelList = value.map(x => groupMapWithOptionsMap?.[key]?.options?.[x]?.label).filter(l => l);
			a.push(labelList);
			return a;
		}, []).flat();

		// multiple are selected
		if (numberOfSelectedOptions.length > 1) {
			return (
				<div className="group-filter-title-container">
					<div className="group-filter-title">
						<p className="ignore-modal-styling value-label">{t("Multiple")}</p>
					</div>
				</div>
			);
		}
		// one option selected
		if (numberOfSelectedOptions.length === 1) {
			return (
				<div className="group-filter-title-container">
					<div className="group-filter-title">
						<p className="ignore-modal-styling value-label">{numberOfSelectedOptions[0]}</p>
					</div>
				</div>
			);
		}

		// fallback
		return (
			<div className="group-filter-title-container">
				<div className="group-filter-title">
					<p className="ignore-modal-styling value-label">--</p>
				</div>
			</div>
		);
	}, [groupMapWithOptionsMap, groupOptions, t, value]);

	return (
		<NewDropdown
			renderTitle={() => renderTitleElement}
			template={template}
			className={classNames('filter-dropdown group-select', className, { 'display-only': displayOnly })}
			edge="right"
			isNoScroll={false}
			animateDropdown={animateDropdown}
			limitHeight
			maxHeight='50vh'
		>
			<div className="filter-list">
				{groupOptions.map(({ title, options, selectAll }) => selectAll ? (
					<div key={title} className="filter-group">
						{/* SELECT ALL */}
						<button className="no-style no-padding filter-list-item categorized-option" onClick={(e) => {
							e.stopPropagation();
							handleChange(title, { label: "", value: "" }, !value?.selectAll);
						}}>
							<Checkbox checked={!!value?.selectAll} value="" onChange={(_, isOn) => {
								handleChange(title, { label: "", value: "" }, isOn);
							}} />
							<p className="ignore-modal-styling">{t(title) ?? t("Select All")}</p>
						</button>
					</div>
				) : (
					<div key={title} className="filter-group">
						{/* Groups */}
						<p className="ignore-modal-styling group-label">{t(title)}</p>
						{options.map(option => {
							const selected = value[title]?.includes(option.value);
							return (
								<button key={option.value} className={classNames("no-style no-padding filter-list-item categorized-option", { selected })} onClick={(e) => {
									e.stopPropagation();
									handleChange(title, option, !selected);
								}}>
									<Checkbox
										checked={selected}
										value={option.value}
										onChange={() => handleChange(title, option, !selected)}
									/>
									<p className="ignore-modal-styling">{t(option.label, option.label)}</p>
								</button>
							);
						})}
					</div>
				))}
			</div>
		</NewDropdown>
	);
};
