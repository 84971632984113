import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import {
	ADD_SURVEY,
	GET_SURVEYS,
	UPDATE_SURVEY,
	GET_ENGAGEMENT_RESULTS,
	SET_ENGAGEMENT_RESULTS,
	UPDATE_ENGAGEMENT_RESULTS,
	SET_STATS_RESULTS,
	ADD_TEXT_ANSWER,
	SET_TEXT_ANSWERS,
	ADD_USER_ENGAGEMENT_PENDING
} from '../../actions/admin/surveys';
import { Survey, TextAnswer } from '../../../types/working-model';

type BLProfileId = number;
type QuestionId = number;

// answers are mutable so react has trouble detecting changes HOWEVER we need to maintain
// the same message ordering AND each reply must be unique by bl_profile (users can only submit one answer
// and when new answers are submitted they replace the old one)
// using a plain object would not work because the order of the keys is not guaranteed AND I was seeing
// tha the questions would be re-ordered when a user updated their text answer
export type AnswerMap = { total: number, answers: Map<BLProfileId, TextAnswer> };
export type QuestionTextAnswerMap = Record<QuestionId, AnswerMap>;

const textAnswerArrayToAnswerMap = (answersByProfile: AnswerMap, answer: TextAnswer) => {
	answersByProfile.answers.set(answer.bl_profile, answer);
	return answersByProfile;
};

type OptionId = number;
export interface SurveysState {
	surveys: Survey[];
	isLoadingSurveys: boolean;
	engagementResults: {
		[engagementKey: string]: number;
	} | null;
	engagementsPending: {
		[questionId: number]: OptionId[];
	}
	statsResults: {
		[key: string]: {
			total: number,
			counts: {
				[optionKey: number]: number
			}
		};
	} | null;
	textResults: QuestionTextAnswerMap;
}

const initialState: SurveysState = {
	engagementResults: null,
	engagementsPending: {},
	statsResults: null,
	isLoadingSurveys: false,
	surveys: [],
	textResults: {}
};

export default function SurveysReducer(
	state: SurveysState = initialState,
	action: Action
): SurveysState {
	switch (action.type) {
		case GET_SURVEYS: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					isLoadingSurveys: true,
				}),
				finish: (state) => ({
					...state,
					isLoadingSurveys: false,
				}),
				success: (state) => ({
					...state,
					surveys: action.payload,
				}),
			});
		}
		case ADD_SURVEY: {
			return { ...state, surveys: [action.payload, ...state.surveys] };
		}
		case UPDATE_SURVEY: {
			return {
				...state,
				surveys: state.surveys.map((survey) => {
					if (survey.survey === action.payload.survey) return action.payload;
					return survey;
				}),
			};
		}
		case GET_ENGAGEMENT_RESULTS: {
			if (!action.payload) {
				return state;
			}

			return {
				...state,
				engagementResults: action.payload,
				engagementsPending: {}
			};
		}

		case SET_ENGAGEMENT_RESULTS: {
			return {
				...state,
				engagementResults: action.payload
			};
		}

		case ADD_USER_ENGAGEMENT_PENDING: {
			const [questionId, optionId] = action.payload as [number, number[]];
			return {
				...state,
				engagementsPending: {
					...state.engagementsPending,
					[questionId]: optionId
				}
			};
		}

		case SET_STATS_RESULTS: {

			const tempResults = { ...state.statsResults };
			const question = action.payload.question;
			const results = action.payload.statsResults;

			const key = `engagement_question:${question}`;
			tempResults[key] = { ...results };

			return {
				...state,
				statsResults: { ...tempResults }
			};
		}

		case UPDATE_ENGAGEMENT_RESULTS: {
			const pending = { ...state.engagementsPending };

			// user has submitted result in memory but is awaiting an update from the server
			// which should clear out the local pending update
			if (Object.keys(pending).length) {
				const res = Object.keys(action.payload);
				for (const resultKey of res) {
					const match = resultKey.match(/engagement_question:(?<survey_question>\d+)-answer:(?<option>\d+)/);

					if (match && match.groups) {
						const { survey_question: surveyQuestion, option: optionId } = match.groups;

						// we have a pending update on this question, but now 
						// we have the real data, so we can remove the pending update
						if (pending[Number(surveyQuestion)]?.includes(Number(optionId))) {
							delete pending[Number(surveyQuestion)];
						}
					}
				}
			}

			return {
				...state,
				engagementResults: action.payload,
				engagementsPending: pending
			};
		}

		case ADD_TEXT_ANSWER: {
			const payload = action.payload as { questionId: number, answer: TextAnswer };
			const results = { ...state.textResults };

			// no results for this question yet, create a new entry
			if (!results[payload.questionId]) {
				results[payload.questionId] = { total: 1, answers: new Map() };
			}

			// did this user already have a response? If so, we are not updating the total,
			// just replacing the old answer with the new one
			const isEdit = results[payload.questionId].answers.has(payload.answer.bl_profile);

			// get new map reference so that react will detect the change but keep natural ordering
			results[payload.questionId].answers = new Map(results[payload.questionId].answers);

			// appends the new answer to the map OR replaces the old answer without reordering the map
			results[payload.questionId].answers.set(payload.answer.bl_profile, payload.answer);

			if (!isEdit) {
				results[payload.questionId].total += 1;
			}

			return {
				...state,
				textResults: results
			};
		}

		case SET_TEXT_ANSWERS: {
			const payload = action.payload as { questionId: number, answers: TextAnswer[], total: number };
			const results = { ...state.textResults };

			results[payload.questionId] = (payload.answers ?? []).reduce<AnswerMap>(
				textAnswerArrayToAnswerMap,
				{ total: payload.total ?? 0, answers: new Map() }
			);

			return {
				...state,
				textResults: results
			};
		}
		default:
			return state;
	}
}
