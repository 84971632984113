import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { HvHostMap } from "../../connection/helpers";

import { ValidateRegisteredUserSessions } from "../../connection/registration";
import { updateLoadingValidSessions, updateValidSessions } from "../../store/actions/event/event-actions";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { deregister } from "../../utils/deregister";
import usePrevious from "../../utils/use-previous";

const useCheckUserGating = (): void => {
	const dispatch = useDispatch();

	const eventBundle = useTypedSelector(state => state?.LiveEventReducer.eventBundle);
	const eventBundleUpdateCounter = useTypedSelector(state => state?.LiveEventReducer.eventBundleUpdateCounter);
	const blProfileToken = useTypedSelector(state => state?.LiveEventReducer.blProfileUserToken);
	const registrationId = useTypedSelector(state => state?.LiveEventReducer?.registrationId);
	const pageSession = useTypedSelector(event => event.LiveEventReducer.pageSession);

	const [initialFetch, setInitialFetch] = useState(false);

	const eventUuid = eventBundle?.uuid;

	const prevBundleUpdateCount = usePrevious(eventBundleUpdateCounter);

	// we only want this hook to run once when the page is loaded/refreshed,
	// or anytime we receive an updated event bundle via an admin publish socket (eventBundleUpdateCounter)
	useEffect(() => {
		if (
			eventBundle
			&& eventUuid
			&& eventBundleUpdateCounter
			&& blProfileToken
			&& registrationId
			// this last condition uses initialFetch to make sure we don't get an infinite loop,
			// however, we still want to fetch if our event bundle has been updated via a publish
			&& (!initialFetch || prevBundleUpdateCount !== eventBundleUpdateCounter)
		) {
			setInitialFetch(true);

			const checkAndUpdatePasscodes = async () => {
				try {
					if (prevBundleUpdateCount !== eventBundleUpdateCounter) {
						return;  // Stop fetching user on event publish 
					}
					const result = await ValidateRegisteredUserSessions({
						eventUuid,
						blProfileToken,
						registrationId,
						checkType: prevBundleUpdateCount === eventBundleUpdateCounter ? 'reload' : 'publish'
					}) as Record<string, any>;

					// if there's been a change in the users session access, we need to update the users valid sessions in redux
					if (result?.success && result?.registeredUser) {
						dispatch(updateValidSessions({
							sessions: result?.sessions ?? [],
							validSessions: result.registeredUser.validSessions ?? [],
							validPasscodeLists: result.registeredUser?.validPasscodeLists,
							registration_bypass: result.registeredUser?.registration_bypass,
						}));
					}

					// if the user no longer has any access, we need to log them out
					if (result?.logout === registrationId) {
						navigator.sendBeacon(`${HvHostMap.sessionTracking}/update-page-session-end-time`, JSON.stringify({ uuid: pageSession }));
						deregister(eventBundle);
					}
				} catch (e) {
					console.error(e);
				} finally {
					dispatch(updateLoadingValidSessions(false));
				}
			};
			checkAndUpdatePasscodes();
		}

	}, [
		initialFetch,
		eventBundle,
		eventBundleUpdateCounter,
		eventUuid,
		blProfileToken,
		registrationId,
		dispatch,
		prevBundleUpdateCount,
		pageSession,
	]);
};

export default useCheckUserGating;
