import { useCallback, useEffect, useState } from "react";
import { Key, Path, TokensToRegexpOptions, pathToRegexp } from 'path-to-regexp';

import { BrandliveEvent } from "types/working-model";
import { PATHNAMES } from "utils/admin-routing-utils";
import { EPaletteModes } from 'types/theme-packs';
import { useTypedSelector } from "store/reducers/use-typed-selector";

type TOptions = {
	isAdmin?: boolean;
}

const useDetermineWhichColorThemeToUse = (
	event: BrandliveEvent | null,
	options?: TOptions,
): { theme: EPaletteModes; } => {
	const { isAdmin = false } = options || {};

	const [theme, setTheme] = useState(EPaletteModes.Light);

	const viewingSessionUuid = useTypedSelector(state => state.LiveEventReducer.viewingSessionUuid);

	const pathname = window.location.pathname;

	const getEventTheme = useCallback(() => {
		return event?.settings?.design?.color_theme;
	}, [event]);

	const shouldUseProjectTheme = useCallback(() => {
		return event?.settings?.design?.use_project_theme;
	}, [event]);

	// admin email urls and use the email setting
	const getEmailPathsToCheck = useCallback(() => {
		const paths: { path: Path; keys?: Key[], options?: TokensToRegexpOptions }[] = [
			{ path: PATHNAMES.Event.EmailList },
		];

		return paths;
	}, []);

	const getSessionMode = useCallback(() => {
		if (!viewingSessionUuid) return null;
		// if the user has set a project mode, then we always use that so we return null here
		if (shouldUseProjectTheme()) return null;

		const session = event?.sessions?.find(session => session.uuid === viewingSessionUuid);

		if (!session) return null;

		return session.session_chat_dark_mode_enabled ? EPaletteModes.Dark : EPaletteModes.Light;
	}, [event?.sessions, shouldUseProjectTheme, viewingSessionUuid]);

	const _emailPaletteMode = event?.settings?.email?.color_palette_mode || EPaletteModes.Light;
	const getEmailPaletteMode = useCallback(() => {
		const emailPaths = getEmailPathsToCheck();
		const isEmailPage = emailPaths.some(({ path, keys, options }) => {
			const regex = pathToRegexp(path, keys, options);
			const match = regex.exec(pathname);
			return match;
		});

		if (!isEmailPage) {
			return null;
		}

		return _emailPaletteMode;
	}, [_emailPaletteMode, getEmailPathsToCheck, pathname]);

	useEffect(() => {
		// initialize with event level theme
		let _theme = getEventTheme() || EPaletteModes.Light;

		const sessionMode = getSessionMode();
		if (sessionMode) {
			_theme = sessionMode;
		}

		// we'll never need this check if we're not in admin, so let's make sure we only do this in admin
		if (isAdmin) {
			const emailPaletteMode = getEmailPaletteMode();
			if (emailPaletteMode) {
				_theme = emailPaletteMode;
			}
		}
		setTheme(_theme);

	}, [getEmailPaletteMode, getEventTheme, getSessionMode, isAdmin]);

	return { theme };
};

export default useDetermineWhichColorThemeToUse;
