import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { Action } from "redux";

import { useAppDispatch } from "../store/reducers/use-typed-selector";
import { PageModuleGroupModules } from "../types/working-model";
import { useScreenMediaQuery } from "../utils/use-screen-media-query";
import { useIsNewModuleGrouping } from "./session.hooks";

/*

This hook will has been updated to remove the scroll handler. This was done to reduce the need
to trigger code on each scroll event as well as measure DOM elements which triggers a reflow.

These reflows can cause the scroll to be janky and slow. Instead we are using the IntersectionObserver
to determine when the user has scrolled past the fold. 

There are multiple IntersectionObservers. One for the scroll container and one for each of the tabs. We only need to 
account for the height of the video when on mobile because the video player is fixed to the top of the screen.
When on mobile, we create a resize observer on the scroll container to determine what the height of the
video player is. We then use that height to determine when the user has scrolled past the fold.

On desktop, we don't need to account for the video player because it is not fixed to the top of the screen,
so the intersection observer only needs to look for when the user has scrolled past the top of the scroll container.

*/
export const useBelowTheFoldScroll = (
	autoScroll: boolean,
	isPreview: boolean,
	isScrolledPastTheFold: boolean,
	moduleGroupsRefs: RefObject<HTMLDivElement>[],
	scrollContainer: RefObject<HTMLDivElement>,
	selectedTabUuid: string | undefined | null,
	sessionModuleGrouping: PageModuleGroupModules[] | undefined,
	setIsScrolledPastTheFold: (isScrolledPastTheFold: boolean) => void,
	setSelectedTabUuid: (Dispatch<SetStateAction<string | undefined>>) | null,
	setActiveSessionGroupModdTabUuid: ((moduleGroupUuid: string | null) => Action) | null,
) => {
	const dispatch = useAppDispatch();
	const [thresholdTop, setThresholdTop] = useState(0);
	const scrolledPastRef = useRef(setIsScrolledPastTheFold);
	const setActiveSessionGroupModdTabUuidRef = useRef(setActiveSessionGroupModdTabUuid);
	const setSelectedTabUuidRef = useRef(setSelectedTabUuid);
	const resizeObserverRef = useRef<ResizeObserver | null>(null);
	const autoScrollRef = useRef(autoScroll);
	const autoScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const isLessThan640Ref = useRef(false);
	const isLessThan1024Ref = useRef(false);
	const isPast = useRef(false);

	const isModuleGroupingV2 = useIsNewModuleGrouping();

	const { isLessThan640, isLessThan1024 } = useScreenMediaQuery();

	// we don't want to redeclare all our hooks when these variables change
	// because they can change unpredictably. Because the functions that use these
	// will trigger asynchronously, for the most part we don't depend on these  
	// and can just trigger whatever the current value is when the function is called
	useEffect(() => {
		isLessThan640Ref.current = isLessThan640;
	}, [isLessThan640]);

	useEffect(() => {
		isLessThan1024Ref.current = isLessThan1024;
	}, [isLessThan1024]);

	useEffect(() => {
		scrolledPastRef.current = setIsScrolledPastTheFold;
	}, [setIsScrolledPastTheFold]);

	useEffect(() => {
		setActiveSessionGroupModdTabUuidRef.current = setActiveSessionGroupModdTabUuid;
	}, [setActiveSessionGroupModdTabUuid]);

	useEffect(() => {
		setSelectedTabUuidRef.current = setSelectedTabUuid;
	}, [setSelectedTabUuid]);

	// user has clicked a tab - should ignore our intersection observers until the scroll 
	// animation has completed
	useEffect(() => {
		if (autoScroll) {
			autoScrollRef.current = true;
			if (autoScrollTimeoutRef.current) {
				clearTimeout(autoScrollTimeoutRef.current);
			}
		} else {
			autoScrollTimeoutRef.current = setTimeout(() => {
				autoScrollRef.current = false;
			}, 750);
		}
	}, [autoScroll]);

	// checks to determine what our current offset threshold should be on mobile
	useEffect(() => {
		const scroll = scrollContainer.current;

		if (scroll) {
			// debounce and fire when the user's resize has settled
			let checkTimeout: NodeJS.Timeout | null = null;

			const checkTop = () => {
				if (checkTimeout) {
					clearTimeout(checkTimeout);
				}

				checkTimeout = setTimeout(() => {
					// use a ref to check what the current value of isLessThan640 is
					// because this hook would lose reference to the value of isLessThan640
					if (isLessThan640Ref.current) {
						setThresholdTop(Math.floor(scroll.getBoundingClientRect().top) * -1);
						// setThresholdTop(0);

					} else if (isLessThan1024Ref.current) {
						setThresholdTop(0);
					} else {
						setThresholdTop(25);
					}
				}, 750); // a slight delay in updating the visible state for these buttons on resize (not scroll) is acceptable
			};

			// observe resize events of the scroll container
			resizeObserverRef.current = new ResizeObserver(checkTop);
			resizeObserverRef.current.observe(scroll);

			return () => {
				resizeObserverRef.current?.disconnect();
			};
		}
	}, [scrollContainer]);

	useEffect(() => {
		if (scrollContainer.current) {
			const attachTabsToTop = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.rootBounds === null) return;

					// the top of the scroll container has gone up out of the viewport (user scrolled down)
					const outTop = entry.boundingClientRect.top <= entry.rootBounds?.top && !entry.isIntersecting;

					// the top of the scroll container has entered the viewport from the top down (user scrolled up)
					const inTop = entry.boundingClientRect.bottom >= entry.rootBounds?.top && entry.isIntersecting;

					// pop the tab container out and affix to the top of the page/bottom of the video player
					if (inTop) {
						scrolledPastRef.current(false);
						isPast.current = false;

						// user has scrolled to the top, force set the tab to the first tab
						if (setSelectedTabUuidRef.current && sessionModuleGrouping?.[0] && isModuleGroupingV2) {
							setSelectedTabUuidRef.current(sessionModuleGrouping[0].uuid);
						}
					}

					// put the tab container back in its normal spot
					else if (outTop) {
						isPast.current = true;
						scrolledPastRef.current(true);
					}
				});
				// threshold: 0 means that this triggers when any part of the scroll container enters/leaves the viewport
			}, { threshold: 0, rootMargin: `${thresholdTop}px 0px 0px 0px` });

			// watch the scroll itself to determine when it leaves the top of the window viewport
			attachTabsToTop.observe(scrollContainer.current);

			// each module group section has a div trigger as an anchor with an ID at the top, we're going to check
			// when this div intersects with the top of the scroll container
			const tabListeners: IntersectionObserver[] = [];

			moduleGroupsRefs.forEach((tab, index) => {
				if (tab.current && sessionModuleGrouping) {
					const updateSelectedTab = new IntersectionObserver((entries) => {
						entries.forEach((entry) => {
							if (autoScrollRef.current || !entry.rootBounds) return;

							// tab anchor exited page out the top
							const outTop = entry.boundingClientRect.top <= entry.rootBounds?.top && !entry.isIntersecting;

							// tab anchor entered page from the top
							const inTop = entry.boundingClientRect.bottom >= entry.rootBounds?.top && entry.isIntersecting;

							// tab anchor exited teh page out the bottom
							const outBottom = !entry.isIntersecting && entry.boundingClientRect.top > entry.rootBounds?.bottom;

							if (isPast.current) {
								// if the tab anchor is at the top of the page, set select that tab
								if (outTop || inTop) {
									if (isPreview && setActiveSessionGroupModdTabUuidRef.current) {
										dispatch(setActiveSessionGroupModdTabUuidRef.current(sessionModuleGrouping[index].uuid));
									} else if (setSelectedTabUuidRef.current) {
										setSelectedTabUuidRef.current(sessionModuleGrouping[index].uuid);
									}
								}

								// if the tab anchor has left the page to the bottom, the user is scrolling up and the
								// tab is no longer visible. Select the previous tab.
								else if (outBottom) {
									if (isPreview && setActiveSessionGroupModdTabUuidRef.current && sessionModuleGrouping[index - 1]) {
										dispatch(setActiveSessionGroupModdTabUuidRef.current(sessionModuleGrouping[index - 1].uuid || null));
									} else if (setSelectedTabUuidRef.current && sessionModuleGrouping[index - 1]) {
										setSelectedTabUuidRef.current(sessionModuleGrouping[index - 1].uuid);
									}
								}
							}
						});

						// offset the threshold by 120 pixels to account for the top position of the anchor and the height of the tab bar
					}, { threshold: 0, rootMargin: `${thresholdTop - 120}px 0px 0px 0px` });

					// watch the tab to determine when it has left the viewport
					updateSelectedTab.observe(tab.current);
					tabListeners.push(updateSelectedTab);
				}
			});

			return () => {
				attachTabsToTop.disconnect();
				tabListeners.forEach(tab => tab.disconnect());
			};
		}
	}, [dispatch, thresholdTop, isPreview, moduleGroupsRefs, scrollContainer, sessionModuleGrouping, isModuleGroupingV2]);
};
