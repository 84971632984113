import { GetRegistrationQuestionsWithoutTranslations, GetRegistrationQuestions, DeleteRegistrationQuestion } from "../../../connection/registration-questions";
import { Action, AsyncAction } from "../../../types/actions";
import { RegistrationQuestion } from "../../../types/working-model";

export const GET_REGISTRATION_QUESTIONS = "GET_REGISTRATION_QUESTIONS";
export function getRegistrationQuestions(channel: number, eventUUID: string, token: string): AsyncAction {
	return {
		type: GET_REGISTRATION_QUESTIONS,
		promise: GetRegistrationQuestions(channel, eventUUID, token),
	};
}

export function getRegistrationQuestionsWithoutTranslations(channel: number, token: string): AsyncAction {
	return {
		type: GET_REGISTRATION_QUESTIONS,
		promise: GetRegistrationQuestionsWithoutTranslations(channel, token),
	};
}

export const UPDATE_REGISTRATION_QUESTIONS = "UPDATE_REGISTRATON_QUESTIONS";
export function updateRegistrationQuestions(
  updatedQuestions: RegistrationQuestion[]
): Action {
  return {
    type: UPDATE_REGISTRATION_QUESTIONS,
    payload: updatedQuestions,
  };
}

export const ADD_NEW_REGISTRATION_QUESTION = "ADD_NEW_REGISTRATION_QUESTION";
export function addNewRegistrationQuestion(
  question: RegistrationQuestion
): Action {
  return {
    type: ADD_NEW_REGISTRATION_QUESTION,
    payload: question,
  };
}

export const DELETE_REGISTRATION_QUESTION = "DELETE_REGISTRATION_QUESTION";
export function deleteRegistrationQuestion(questionId: number, token: string): AsyncAction {
  return {
    type: DELETE_REGISTRATION_QUESTION,
    promise: DeleteRegistrationQuestion(questionId, token),
  };
}