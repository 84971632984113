import { SearchProps } from "./types";

export const GetSearchParams = (props: Partial<SearchProps>): string => {
	const { sort, limit, offset, search, filter, eFilter } = props;

	const params = new URLSearchParams();

	// append them one-by-one so we don't have empty strings in our params
	if (sort) {
		params.append('sort', sort);
	}

	if (limit) {
		params.append('limit', limit.toString());
	}

	if (offset) {
		params.append('offset', offset.toString());
	}

	if (search) {
		params.append('search', search);
	}

	if (filter) {
		params.append('filter', filter);
	}

	if (eFilter) {
		params.append('eFilter', eFilter);
	}

	return params.toString();
};