import { useCallback, useEffect, useState } from "react";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import { CreateRegistrationQuestion, QuestionType, RegistrationQuestion } from "../../../../../../../types/working-model";
import { showAlert } from "../../../../../../general-ui/alert/alert-service";

import { useFieldRequired } from "utils/registration-required-fields";
import EditRegistrationQuestionV2 from "./edit-registration-question-v2";
import { RegistrationPanelMap } from "@admin/navigation/registration-panel-route-map";


const PanelCreateQuestion: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);
	const isFieldRequired = useFieldRequired();

	const [editingQuestion, setEditingQuestion] = useState<CreateRegistrationQuestion | RegistrationQuestion | null>({
		name: "Enter Question Title",
		type: QuestionType.text,
		options: null,
		persistent: false,
		default_value: null,
		registration_question: 0,
		is_required: false,
	});

	const [requiredQuestions, setRequiredQuestions] = useState<number[]>([]);
	const [selectedQuestions, setSelectedQuestions] = useState<RegistrationQuestion[]>([]);

	const selectedIds = selectedQuestions.map((question: RegistrationQuestion) => question.registration_question);

	// change question properties
	const setQuestionRequired = useCallback((question: number, value: number) => {
		const required = Boolean(value);
		const _editingQuestion = { ...editingQuestion, is_required: required } as CreateRegistrationQuestion;
		if (_editingQuestion) {
			setEditingQuestion(_editingQuestion);
		}

		if (required) {
			if (isFieldRequired.onAndOptional.includes(question)) {
				showAlert({
					message: "This field must be on and not required.",
					duration: 3000,
					type: "error",
				});
				return;
			}
			setRequiredQuestions([...requiredQuestions, question]);
		} else {
			if (isFieldRequired.on.includes(question)) {
				showAlert({
					message: "This field is required for event features currently in use.",
					duration: 3000,
					type: "error",
				});
				return;
			}
			setRequiredQuestions(requiredQuestions.filter((_question: number) => _question !== question));
		}
	}, [isFieldRequired.onAndOptional, isFieldRequired.on, editingQuestion, requiredQuestions]);

	useEffect(() => {
		if (!workingEvent?.registration_steps) return;
		setRequiredQuestions(workingEvent.required_registration_questions ?? []);
	}, [registrationStep, workingEvent?.required_registration_questions, workingEvent?.registration_steps]);

	useEffect(() => {
		if (!workingEvent?.registration_steps) return;
		setRequiredQuestions(workingEvent.required_registration_questions ?? []);
		setSelectedQuestions(workingEvent.registration_steps.find(step => step.type === registrationStep)?.questions || []);
	}, [registrationStep, workingEvent?.required_registration_questions, workingEvent?.registration_steps]);

	return (
		<div className={"registration-panel-v2"}>
			<div className="registration-panel-content">
				<div className="registration-options-container-v2">
					<EditRegistrationQuestionV2
						{...{
							question: editingQuestion as RegistrationQuestion,
							requiredQuestions,
							selectedIds,
							setQuestionRequired,
							editingQuestion,
							setEditingQuestion,
							sendTo: RegistrationPanelMap.Content,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default PanelCreateQuestion;
