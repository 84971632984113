import { ColorOptions, ColorValueIndexes, EPaletteModes } from "types/theme-packs";
import { isColorLightOrDarkOnBlendedBackground } from "utils/colors/is-color-light-or-dark";

type TBlendsProps = {
	opacity?: number;
	useThemeBlend?: boolean;
	colorTheme: EPaletteModes;
	colors?: ColorOptions;
}

export const getButtonTextColorOnAccentAndBackgroundBlend = ({
	colorTheme,
	colors,
	opacity,
	useThemeBlend,
}: TBlendsProps) => {
	// session mode backgrounds are hard coded to these colors for light and dark modes:
	let backgroundColor = colorTheme === EPaletteModes.Dark ? '#1a1a1f' : '#f5f7ff';
	let backgroundOpacity = 1;
	if (useThemeBlend) {
		backgroundColor = colors?.[colorTheme]?.backgroundColor?.[ColorValueIndexes.hex] || backgroundColor;
		backgroundOpacity = colors?.[colorTheme]?.backgroundColor?.[ColorValueIndexes.opacity] || backgroundOpacity;
	}
	const accentOnBackgroundAnd20PercentAccent = isColorLightOrDarkOnBlendedBackground(
		[
			[colors?.[colorTheme]?.accentColor?.[0] || '#000000', opacity ?? 0.2],
			[backgroundColor, backgroundOpacity]
		],
		colors?.[colorTheme]?.accentColor[ColorValueIndexes.hex] || '#000000'
	);
	const result = accentOnBackgroundAnd20PercentAccent === EPaletteModes.Light ? '#ffffff' : '#000000';
	return result;
};
