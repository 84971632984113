import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { PageModule } from "../../../../../../../../types/working-model";
import Select from '../../../../../../../general-ui/select/select';

interface IAdvancedProductsLayoutProps {
	module: PageModule;
	setSelectedDisplayOptions?: Dispatch<SetStateAction<number | null>>;
	selectedDisplayOptions?: number | null;
}

const AdvancedProductsLayout = ({
	module,
	setSelectedDisplayOptions,
	selectedDisplayOptions
}: IAdvancedProductsLayoutProps) => {
	const initCount: number = module?.content?.display_options || 0;
	const [count, setCount] = useState(initCount);

	useEffect(() => {
		if (selectedDisplayOptions === null) {
			setSelectedDisplayOptions?.(initCount);
		}
	}, [initCount, selectedDisplayOptions, setSelectedDisplayOptions]);

	if (!module) return null;

	return (
		<>
			<div className="module-display-options">
				<div className="module-display-options-label">
					Displayed Products:
				</div>
				<div className="module-display-options-select-wrapper">
					<Select
						containerClass="module-display-options-select"
						options={[
							{ label: 'All', value: 0 },
							...Array.from({ length: 12 }).map((_, idx) => ({
								label: idx + 1,
								value: idx + 1,
							}))
						]}
						selected={count}
						onChange={(value => {
							setSelectedDisplayOptions?.(value);
							setCount(value);
						})}
						ignorePositioning
						hideEmptyOption
					/>
				</div>
			</div>
		</>
	);
};

export default AdvancedProductsLayout;
