import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { reRegister, registrationSignIn } from '../../../../store/actions/event/event-actions';
import { EIcon } from '../../../general-ui/icon/icon';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import TextInput, { Validation } from '../../../general-ui/text-input/text';
import { isValidEmail } from '../../../../utils/utils';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import { ParamsProps } from '../../live-event';
import { Button } from '../../../general-ui/button/button';
import { handleRecaptcha } from '../../../../utils/execute-recaptcha';
import { OptionalComponent } from '../../../../utils/optional-component';
import RecaptchaV2 from '../../../general-ui/recaptcha-v2/recaptcha-v2';
import { RegFieldsEnum } from 'types/working-model';
import { useGetRegistrationErrorMessage } from 'utils/use-get-registration-error-message';
import { Redirects } from 'utils/redirects';

interface ISignIn {
	template: string;
	onPrevStepClick: () => void;
	handleShowPasswordResetView?: () => void;
}

export function SignIn({ template, onPrevStepClick, handleShowPasswordResetView }: ISignIn): JSX.Element {
	const { t } = useTranslate('homepage');
	const { language } = useParams() as ParamsProps;
	const dispatch = useDispatch();

	const registering = useTypedSelector(state => state.LiveEventReducer.registering);
	const eventUuid = useTypedSelector(state => state.LiveEventReducer.eventBundle?.uuid);
	const eventBundle = useTypedSelector(event => event.LiveEventReducer.eventBundle);
	const loginRecaptchaActionRequired = useTypedSelector(state => state.LiveEventReducer.loginRecaptchaActionRequired);
	const recaptchaEnabled = useTypedSelector(state => !!state.LiveEventReducer.eventBundle?.registration_settings?.enableRecaptcha);
	const shouldRedirectToAlreadyRegisteredEmail = useTypedSelector(state => state.LiveEventReducer.shouldRedirectToAlreadyRegisteredEmail);
	const registrationSignInSuccess = useTypedSelector(state => state.LiveEventReducer.registrationSignInSuccess);

	const [email, setEmail] = useState('');
	const [valid, setValid] = useState(Validation.normal);
	const [widgetId, setWidgetId] = useState<number | null>(null);
	const [password, setPassword] = useState('');

	const regErrorMessage = useGetRegistrationErrorMessage();
	const passwordGatingEnabled = useMemo(() => !!eventBundle?.registration_settings?.password_gating_enabled, [eventBundle?.registration_settings?.password_gating_enabled]);

	const setValue = useCallback((value: string) => {
		setEmail(value);
		setValid(isValidEmail(value) ? Validation.ok : Validation.error);
	}, []);

	const getWidgetId = useCallback((widgetId) => {
		setWidgetId(widgetId);
	}, []);

	useEffect(() => {
		if (registrationSignInSuccess) {
			location.replace(Redirects.fromRegistration(eventBundle));
		}
	}, [registrationSignInSuccess, eventBundle]);

	const handleSubmit = useCallback(async (e: React.FormEvent) => {
		e.preventDefault();

		if (isValidEmail(email) && eventUuid) {
			try {
				const ACTION = 'login';
				// recaptcha (runs v3, if v3 fails, runs v2)
				const recaptchaResponse = recaptchaEnabled && await handleRecaptcha({
					action: ACTION,
					useV2: loginRecaptchaActionRequired,
					widgetId,
				});
				const headers = recaptchaResponse?.headers ?? {};

				if (passwordGatingEnabled && password) {
					const path = window.location.href.split("/");
					path.pop();
					const source = path.join("/");
					dispatch(registrationSignIn(
						eventUuid,
						email,
						password,
						source,
						language,
						Intl.DateTimeFormat().resolvedOptions().timeZone,
					));
				} else {
					dispatch(reRegister(
						{
							eventUuid,
							email,
							lang: language,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							action: ACTION, // for recaptcha v3
							hostname: window.location.hostname, // for recaptcha
							...(passwordGatingEnabled ? { password } : {})
						},
						headers
					));
				}
			} catch (e) {
				console.error(e);
			}
		}
	}, [
		email,
		eventUuid,
		dispatch,
		language,
		widgetId,
		loginRecaptchaActionRequired,
		recaptchaEnabled,
		passwordGatingEnabled,
		password
	]);

	useEffect(() => {
		if (shouldRedirectToAlreadyRegisteredEmail) {
			setValue(shouldRedirectToAlreadyRegisteredEmail);
		}
	}, [shouldRedirectToAlreadyRegisteredEmail]);

	return (
		<div className="registration-slider">
			{/* this component is dynamically rendered so we can hard code "active" class to make sure it's visible when rendered */}
			<div className="registration-panel-v2 fixed active">
				<form className="registration-form" onSubmit={handleSubmit}>
					<div className="registration-form-inner verification" onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
						<TypographyItem className={classNames("evt-heading-2 stable registration-form-title", template)} tagName="h2">
							{t('homepage:registration.verificationHeader')}
						</TypographyItem>
						<OptionalComponent display={!passwordGatingEnabled}>
							<TypographyItem tagName="p" className={classNames(template)}>
								{t("homepage:registration.signinBody")}
							</TypographyItem>
						</OptionalComponent>
						<br />
						<div className={classNames('evt-field-wrapper', template)} id={`registration-field-${RegFieldsEnum.email}`}>
							<TextInput
								id="reregister-email-field"
								placeholder={t(`homepage:registration.registration_fields.${RegFieldsEnum.email}`)}
								label={t(`homepage:registration.registration_fields.${RegFieldsEnum.email}`)}
								required
								onChange={e => setValue(e.target.value)}
								email
								valid={valid}
								value={email}
							/>
							<OptionalComponent display={valid === Validation.error}>
								<p className="registration-error" role="alert">{t("homepage:registration.Please enter a valid email address", 'lease enter a valid email address')}</p>
							</OptionalComponent>
							<OptionalComponent display={passwordGatingEnabled}>
								<div
									className="forgot-password-link"
									onClick={handleShowPasswordResetView}
								>
									{t('homepage:registration.Forgot Password?', 'Forgot password?')}
								</div>
								<TextInput
									id="reregister-password-field"
									required
									label={t('homepage:registration.registration_fields.Password', 'Password')}
									placeholder={t('homepage:registration.registration_fields.Password', 'Password')}
									onChange={e => setPassword(e.target.value)}
									password
									showPasswordIcon
									autoCorrect="off"
									autoCapitalize="off"
								/>
							</OptionalComponent>
						</div>
					</div>
					<OptionalComponent display={!!regErrorMessage}>
						<p className="registration-error" role="alert">{regErrorMessage}</p>
					</OptionalComponent>
					<div className="registration-actions secondary">
						<Button
							isRound={true}
							classButton={classNames('registration-button secondary')}
							template={template}
							onClick={onPrevStepClick}
							typeBtn="button"
						>
							<EIcon name="keyboard-arrow-left" />
						</Button>
						<div>
							<OptionalComponent display={loginRecaptchaActionRequired}>
								<RecaptchaV2
									// we need the widget id to pass to grecaptcha's `getResponse` method to ensure we're using the correct widget
									// just in case we have multiple recaptcha widget's on the screen at any time
									getWidgetId={getWidgetId}
								/>
							</OptionalComponent>
							<Button
								classButton={classNames('registration-button primary')}
								template={template}
								typeBtn="submit"
								isDisabled={!email || registering || valid === Validation.error || (passwordGatingEnabled && !password)}
							>
								{t("homepage:Submit")}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
