import { cookieStore } from 'utils/cookie-store-polyfill';
import { useCallback, useEffect, useRef } from 'react';

export const useForceSecureCookies = () => {
	const cookieIntervalRef = useRef<NodeJS.Timeout | null>(null);

	const forceAllSecure = useCallback(async () => {
		try {
			// get all cookies using the cookie store (experimental API but has polyfill)
			const cookies = await cookieStore.getAll();

			// iterate over all cookies we can access
			await Promise.all(cookies.map(cookie => {

				// if this is a brandlive cookie or localhost cookie, force it to be secure
				if (cookie.domain?.includes('brandlive') || cookie.domain?.includes('localhost')) {
					return cookieStore.set({
						...cookie,
						domain: cookie.domain ?? null,
						expires: cookie.expires ?? null,
						secure: true
					});
				}
			}));
		} catch (e) {
			console.error('Error forcing secure cookies', e);
		}
	}, []);

	useEffect(() => {
		if (process.env.REACT_APP_ALLOW_INSECURE_COOKIES) {
			return;
		}

		// run on load, then add listener for further changes
		forceAllSecure().then(() => {

			// every minute check for any insecure cookies and force them to be secure
			cookieIntervalRef.current = setInterval(forceAllSecure, 1000 * 60);
		}).catch(console.warn);

		return () => {
			if (cookieIntervalRef.current) {
				clearInterval(cookieIntervalRef.current);
			}
		};
	}, [forceAllSecure]);
};