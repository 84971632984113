import { MousePopupOptions } from "../mouse-popup/mouse-popup";
import { ToastAlert } from "./alert";

const listeners = new Map<string, Listener>();

type Listener = (...args: any[]) => unknown;
export function addListener(name: string, func: Listener): void {
	listeners.set(name, func);
}

export function removeListener(name: string): void {
	listeners.delete(name);
}

export const showAlert = (options: ToastAlert): unknown => {
	const func = listeners.get('showAlert');
	return func && func(options);
};

export const showAlertLong = (options: ToastAlert): unknown => {
	const func = listeners.get('showAlertLong');
	return func && func(options);
};

export const hideAlert = (options: ToastAlert): unknown => {
	const func = listeners.get('hideAlert');
	return func && func(options);
};

export const noConnectionAlert = (): unknown => {
	const func = listeners.get('noConnectionAlert');
	return func && func();
};

export const showAlertOpentokMuted = (options: ToastAlert): unknown => {
	const func = listeners.get('showAlertOpentokMuted');
	return func && func(options);
};

export const showAnnouncement = (options: ToastAlert): unknown => {
	const func = listeners.get('showAnnouncement');
	return func && func(options);
};

export const clearListeners = (): void => {
	listeners.clear();
};

export const getAlertById = (id: string): ToastAlert | undefined => {
	const func = listeners.get('getAlertById');
	return func && func(id) as ToastAlert | undefined;
};

export const getAllAlerts = (): ToastAlert[] | undefined => {
	const func = listeners.get('getAllAlerts');
	return func && func() as ToastAlert[] | undefined;
};

export const mouseAlert = (options: MousePopupOptions) => {
	listeners.get('mouse-popup')?.(options);
};

export const ALERT_TYPES = {
	WARNING: 1,
	ERROR: 2,
	NEUTRAL: 3,
	POSITIVE: 4,
	CHAT: 5,
	ANNOUNCE: 6,
};

Object.freeze(showAlert);
Object.freeze(showAlertLong);
Object.freeze(showAlertOpentokMuted);
Object.freeze(hideAlert);
Object.freeze(noConnectionAlert);
Object.freeze(clearListeners);
