import './panel-wrapper-v2.scss';

const PanelWrapper: React.FC<{ children: JSX.Element, title?: string, id?: string }> = ({ children, title, id }) => {

	return (
	<div className="panel-wrapper-v2" style={{ zIndex: 1 }} id={id}>
		<div className="panel-body-v2">
			{children}
		</div>
	</div>
	);
};

export default PanelWrapper;