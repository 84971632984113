import { Dispatch, SetStateAction } from 'react';
import cn from 'classnames';

import Icon, { COLORS, ICONS } from '../icon';
import { RatingScale } from '../rating-scale/rating-scale';
import { useTranslate } from '../../../i18n/useTranslationModules';
import { useScreenMediaQuery } from '../../../utils/use-screen-media-query';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { EditorSizes } from '../../../types/template-layouts';
import { SessionTabsLayoutTypes } from '../../../types/working-model';
import '../../../scss/live-event/base/containers/user-survey-modal.scss';
import { getTextValue } from '../../../utils/utils';
import { useParams } from 'react-router';
import { ParamsProps } from '../../live-event/live-event';
import useTranslationV2Attendee from "../../live-event/hooks/use-translations-v2-attendee";
import { useIsNewModuleGrouping } from 'hooks/session.hooks';

interface UserSurveyAlertProps {
	onClick?: (rating: number) => void;
	rating: number | null;
	show?: boolean;
	setShow?: Dispatch<SetStateAction<boolean>>;
	layout: SessionTabsLayoutTypes;
}

const UserSurveyAlert = ({ onClick, rating, show, setShow, layout }: UserSurveyAlertProps): JSX.Element => {
	const { language } = useParams<ParamsProps>();
	const { t } = useTranslate('session');
	const { isLessThan640 } = useScreenMediaQuery();
	const isNewModuleGrouping = useIsNewModuleGrouping();

	const isEditor = useTypedSelector(state => state?.CreateEventReducer?.workingEvent);
	const editorSize = useTypedSelector(state => state?.CreateEventReducer?.editorSize);
	const workingEvent = useTypedSelector(state => state?.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state?.LiveEventReducer.eventBundle);


	const event = eventBundle ?? workingEvent;
	const userFeedbackText = event?.settings?.feedback_survey_text;
	const shouldUseTranslationV2 = useTranslationV2Attendee(event);

	// Force translate from json, if not v2, use specified text
	const userFeedbackTitle = (shouldUseTranslationV2 || !userFeedbackText?.title)
		? t('session:userSurvey.ratingLabel')
		: getTextValue(userFeedbackText, language).title;

	if (isNewModuleGrouping) return <></>;

	return (
		<div className={cn('user-survey-alert-container', {
			hidden: !show,
			'editor-tablet': editorSize === EditorSizes.tablet,
			mobile: editorSize === EditorSizes.mobile || isLessThan640,
			[layout]: layout
		})}>
			<div className={cn('user-survey-alert', { 'is-editor': isEditor })}>
				<span>{userFeedbackTitle}</span>
				<div className="right-actions">
					<RatingScale onChange={onClick} rating={rating} />
					<span onClick={() => setShow && setShow(false)}>
						<Icon color={COLORS.BLACK} size={12} name={ICONS.CLOSE} />
					</span>
				</div>
			</div>
		</div>
	);
};

export default UserSurveyAlert;