import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { GetDefaultSpeaker, GetDefaultTranslateString } from "../../../../../../../../store/utils/create-event";
import { initialRequiredSpeakerFields } from "../../../../../../content-creation/speakers/speaker";
import { Validation } from "../../../../../../../general-ui/text-input/text";
import { CreateSpeaker, LanguagesAbbr, PageModuleType, RelatedSpeakerSessions, SessionPanelLayoutsTypes, SocialLink, Speaker } from "../../../../../../../../types/working-model";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { InsertSpeaker, SaveSpeaker } from "../../../../../../../../connection/speakers";
import { createSpeaker, updateSpeaker } from "../../../../../../../../store/actions/admin/speakers";
import { useFinishNavigate, usePageModule } from "../../hooks/panel.hooks";
import { addSessionPageModule } from "../../../../../../../../store/actions/admin/create-event/session";
import { GetPageModule } from "../../../../../../../../connection/page-modules";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { SessionPanelMap } from "../../session-panel-route-map";
import CreateNewSpeakerInfo from "./create-new-speaker";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";

import './create-new-speaker.scss';
import { updateTranslateKey } from "utils/utils";
import { updateAllSpeakersWithSpeakerId } from "@admin/utils/utils";

const CreateNewSpeaker: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const history = useHistory<{ speakerToEdit?: Speaker, relatedSessions?: number[] }>();
	const finish = useFinishNavigate();
	const [workingSpeaker, setWorkingSpeaker] = useState<Speaker | CreateSpeaker>(GetDefaultSpeaker());
	const [requiredFields, setRequiredFields] = useState(initialRequiredSpeakerFields);
	const [workingRelatedSessions, setWorkingRelatedSessions] = useState<number[]>([]);
	const [uploadingImage, setUploadingImage] = useState(false);
	const [saving, setSaving] = useState(false);
	const [fetchingPageModule, setFetchingPageModule] = useState(false);

	const { language, page_module: pageModuleId, customPath } = useParams<{ language: LanguagesAbbr; page_module?: string, customPath?: string; }>();

	const dispatch = useAppDispatch();

	const adminPath = useGetAdminUrl();

	const pageModule = usePageModule();

	const baseLanguage = (workingSession ? workingSession?.default_language : workingEvent?.settings.i18n?.base) as LanguagesAbbr;

	useEffect(() => {
		if (history.location.state?.speakerToEdit) {
			setWorkingSpeaker(history.location.state.speakerToEdit);
		}

		if (history.location.state?.relatedSessions) {
			setWorkingRelatedSessions(history.location.state.relatedSessions);
		}
	}, [baseLanguage, history.location.state, language]);

	useEffect(() => {
		if (!pageModule && pageModuleId && token && !fetchingPageModule) {
			// fetch page module from DB and set in redux
			setFetchingPageModule(true);
			GetPageModule(token, Number(pageModuleId))
				.then((module) => {
					dispatch(addSessionPageModule(module));
				})
				.catch(err => {
					console.error(err);
				});
		}
	}, [dispatch, fetchingPageModule, pageModule, pageModuleId, token]);

	const getRelatedSessionsComplete = (relatedSessions: number[], speaker: number | undefined): RelatedSpeakerSessions[] | null => {
		if (relatedSessions && speaker) {

			let relatedSessionExists = false;
			const _relatedSessions = pageModule?.content?.related_sessions?.map((r: RelatedSpeakerSessions) => {
				if (speaker === r.speaker) {
					relatedSessionExists = true;
					return {
						speaker,
						sessions: relatedSessions
					};
				}
				return r;
			}) ?? [];
			// if speaker doesnt already have related_sessions
			if (!relatedSessionExists) {
				_relatedSessions?.push({
					speaker,
					sessions: relatedSessions
				});
			}
			return _relatedSessions;
		}
		return null;
	};

	const updateModuleContent = (speaker: Speaker, relatedSessions: number[]) => {
		if (!pageModule) return;

		const _relatedSessions = speaker.speaker && getRelatedSessionsComplete(relatedSessions, speaker.speaker);
		if ((workingSpeaker as Speaker)?.speaker) {
			const updatedModules = (pageModule?.modules || [speaker])?.map((module: Speaker) => {
				if (module.speaker === (speaker as Speaker).speaker) {
					return speaker;
				} else {
					return module;
				}
			});
			dispatch(updateSpeaker(speaker as Speaker));
			dispatch(updatePageModuleAndSave({
				...pageModule,
				type: PageModuleType.speakers,
				modules: updatedModules,
				content: { ...pageModule.content, related_sessions: _relatedSessions }
			}));
		} else {
			dispatch(updatePageModuleAndSave({
				...pageModule,
				type: PageModuleType.speakers,
				content_modules: [...(pageModule?.content_modules ?? []), speaker.speaker],
				modules: [...(pageModule?.modules ?? []), speaker],
				content: { ...pageModule.content, related_sessions: _relatedSessions }
			}));
		}
	};

	const leave = () => {
		if (!pageModule) return;

		const path = (() => {
			if (customPath) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.CustomSpeakers], customPath, page_module: pageModule.id });
			}

			if (pageModule.content.custom_tab) {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraCustom], page_module: pageModule.id });
			} else {
				return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraSpeaker], page_module: pageModule.id });
			}
		})();

		finish(path);
	};

	const viewMoreTranslation = (workingSpeaker: Speaker | CreateSpeaker) => {
		return updateTranslateKey({
			translateString: workingSpeaker?.misc_translations?.view_more || GetDefaultTranslateString(),
			input: (
				workingSpeaker?.misc_translations?.view_more?.[language]
				|| workingSpeaker?.misc_translations?.view_more?.base
				|| "View more"
			) as string,
			baseLanguage,
			language,
		});
	};

	const handleSave = async () => {
		if (!token || !pageModule) return;

		let _workingSpeaker: Speaker | CreateSpeaker = { ...workingSpeaker };

		let _hasError = false;

		const firstName = _workingSpeaker?.first_name;
		const lastName = _workingSpeaker?.last_name;
		const jobTitle = _workingSpeaker?.job_title?.base;
		const speakerImage = _workingSpeaker?.image;

		const hasFirstName = firstName?.trim();
		const hasLastName = lastName?.trim();
		const hasJobTitle = jobTitle?.trim();
		const hasImage = speakerImage?.trim();

		if (!hasFirstName || !hasLastName || !hasJobTitle || !hasImage) {
			_hasError = true;
		}

		setRequiredFields(prev => ({
			...prev,
			firstName: hasFirstName ? Validation.normal : Validation.error,
			lastName: hasLastName ? Validation.normal : Validation.error,
			jobTitle: hasJobTitle ? Validation.normal : Validation.error,
			speakerImage: hasImage ? Validation.normal : Validation.error,
		}));

		if (_hasError || Object.values(requiredFields).includes(Validation.error)) {
			return;
		}

		// remove any social links that have not been properly set
		_workingSpeaker = {
			..._workingSpeaker,
			social_links: _workingSpeaker?.social_links?.filter((link: SocialLink) => {
				return link.platform && link.url.trim();
			}),
		};

		// if no misc_translations then add it with "View more" 
		if (!_workingSpeaker.misc_translations?.view_more) {
			_workingSpeaker.misc_translations = {
				..._workingSpeaker?.misc_translations,
				view_more: viewMoreTranslation(_workingSpeaker),
			};
		}

		// if no social links, turn it off
		if (!_workingSpeaker.social_links?.length) {
			_workingSpeaker.link_social = false;
		}

		setSaving(true);
		try {
			if ('speaker' in workingSpeaker && workingSpeaker.speaker) {
				const updatedSpaker: Speaker = {
					...workingSpeaker,
					misc_translations: {
						...workingSpeaker?.misc_translations,
						view_more: viewMoreTranslation(workingSpeaker),
					},
				};
				updateAllSpeakersWithSpeakerId(updatedSpaker, workingSession, dispatch);

				await SaveSpeaker(token, updatedSpaker, workingEvent?.homepage?.languages, baseLanguage);
				updateModuleContent(workingSpeaker, workingRelatedSessions);
				setWorkingSpeaker(GetDefaultSpeaker());
				setRequiredFields(initialRequiredSpeakerFields);
				setWorkingRelatedSessions([]);
			} else {
				const newSpeaker = await InsertSpeaker(
					token,
					_workingSpeaker,
					workingEvent?.homepage?.languages,
					baseLanguage,
				);
				updateModuleContent(newSpeaker, workingRelatedSessions);
				// update Redux with new speaker so it can be added to a page_module
				dispatch(createSpeaker(_workingSpeaker as Speaker));
				setWorkingSpeaker(GetDefaultSpeaker());
				setRequiredFields(initialRequiredSpeakerFields);
				setWorkingRelatedSessions([]);
			}

			leave();

		} catch (e) {
			console.error(e);
		} finally {
			setSaving(false);
		}
	};

	if (!pageModule) return <WaitingIndicator />;
	return (
		<div
			className="session-panel create-speaker-wrapper"
		>
			<CreateNewSpeakerInfo
				workingSpeaker={workingSpeaker}
				setWorkingSpeaker={setWorkingSpeaker}
				setUploadingImage={setUploadingImage}
				baseLanguage={baseLanguage}
				language={language}
				workingRelatedSessions={workingRelatedSessions}
				setWorkingRelatedSessions={setWorkingRelatedSessions}
				requiredFields={requiredFields}
				setRequiredFields={setRequiredFields}
			/>
			<div className={"session-panel-footer visible"}>
				<button
					onClick={leave}
					disabled={saving}
				>
					Cancel
				</button>
				<button
					className="lemonade"
					disabled={uploadingImage || saving}
					onClick={handleSave}
				>
					{saving ? <WaitingIndicator /> : "Save"}
				</button>
			</div>
		</div>
	);
};

export default CreateNewSpeaker;
