import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'underscore';
import { v4 as uuid } from 'uuid';

import { BlProfile } from '../../../types/working-model';
import { mergeRefs } from '../../../utils/merge-refs';
import { Field, FieldLabel, FieldProps } from '../field/field';
import Icon, { COLORS, ICONS } from '../icon';
import { OptionalComponent } from 'utils/optional-component';

export interface TextInputProps<T = string | number> extends FieldProps<T> {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
	value?: string;
	style?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	numeric?: boolean;
	email?: boolean;
	disabled?: boolean;
	id?: string;
	password?: boolean;
	size?: 'large' | 'small' | 'normal';
	inputSize?: string | number;
	icon?: string;
	prefix?: string;
	onEscape?: () => void;
	onTab?: () => void;
	onEnterKey?: (value: string, event?: KeyboardEvent) => void;
	updateValue?: string; //deprecated and should not be used moving forward
	shouldUpdateValue?: boolean; //deprecated and should not be used moving forward
	useFocusClick?: boolean; //use this if your input is inside a dragable component
	minLength?: number;
	maxLength?: number;
	min?: number;
	max?: number;
	step?: number;
	tooltip?: string | [string | (() => JSX.Element), string];
	profile?: BlProfile | null;
	channel?: number;
	persistent?: boolean;
	onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	autoFocus?: boolean;
	autoComplete?: string;
	/** Note: autocorrect is safari only [mdn docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#autocorrect) */
	autoCorrect?: string;
	autoCapitalize?: string;
	className?: string;
	monospace?: boolean;
	noPlaceholder?: boolean;
	onDebounce?: (value: string) => void;
	debounceTime?: number;
	ignoreZIndex?: boolean;
	highlightOnFocus?: boolean;
	onClear?: () => void;
	labelClassName?: string;
	noMargin?: boolean;
	isAdmin?: boolean;
	showPasswordIcon?: boolean;
}

export enum Validation {
	normal = 'normal',
	ok = 'ok',
	warn = 'warn',
	error = 'error',
	flash = 'flash'
}

// eslint-disable-next-line react/display-name
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref): JSX.Element => {
	const {
		tooltip,
		label,
		onChange,
		placeholder,
		defaultValue,
		style,
		numeric = false,
		email = false,
		disabled = false,
		password = false,
		id = uuid(),
		valid = Validation.normal,
		onFocus = () => ({}),
		onBlur = () => ({}),
		size = 'normal',
		inputSize = '',
		icon,
		value,
		prefix,
		onEscape = () => ({}),
		onTab = () => ({}),
		onEnterKey = () => ({}),
		updateValue = '',
		shouldUpdateValue = false,
		required,
		useFocusClick = false,
		name,
		min = 0,
		max = 999999999,
		step = 1,
		onKeyUp,
		onKeyDown = () => null,
		autoFocus,
		autoComplete,
		autoCapitalize,
		autoCorrect,
		className,
		monospace = false,
		noPlaceholder = false,
		onDebounce,
		debounceTime = 300,
		ignoreZIndex = false,
		highlightOnFocus = false,
		onClear,
		maxLength,
		labelClassName = '',
		noMargin = false,
		isAdmin,
		showPasswordIcon
	} = props;

	const [entered, setEntered] = useState(false);
	const prefixRef = useRef<HTMLSpanElement | null>(null);
	const input = useRef<HTMLInputElement | null>(null);
	const [paddingLeft, setPaddingLeft] = useState(16);
	const [internalText, setInternalText] = useState(defaultValue || '');
	const [changeFlash, setChangeFlash] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);

	const fontFamily = monospace ? 'monospace' : isAdmin ? 'var(--editorHeadingFont)' : 'inherit';
	const useInternalState = !value && value !== "";

	useEffect(() => {
		const handleShouldFlash = () => {
			setChangeFlash(true);
			setTimeout(() => {
				setChangeFlash(false);
			}, 300);
		};

		input.current?.addEventListener('flash', handleShouldFlash);

		return () => {
			input.current?.removeEventListener('flash', handleShouldFlash);
		};
	}, []);

	const debounced = useRef(debounce((value: string) => {
		onDebounce?.(value);
	}, debounceTime)).current;

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (maxLength && e.target.value.length > maxLength) return;

		if (useInternalState) {
			onChange?.(e);
			setInternalText(e.target.value);
		} else {
			onChange?.(e);
		}
		if (onDebounce) {
			debounced(e.target.value);
		}
	}

	function keyUp(e: React.KeyboardEvent<HTMLInputElement>) {
		setEntered(!!input.current?.value);
		if (onKeyUp) onKeyUp(e);
	}

	useEffect(() => {
		if (defaultValue) {
			setEntered(true);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (autoFocus) {
			input.current?.focus();
			if (highlightOnFocus) {
				input.current?.select();
			}
		}
	}, [autoFocus, highlightOnFocus]);

	//This way of updating the component is deprecated and should not be used moving forward
	useEffect(() => {
		if (input?.current?.value && shouldUpdateValue && value === undefined) {
			input.current.value = updateValue || '';
		}
	}, [updateValue, shouldUpdateValue, value]);

	useEffect(() => {
		if (prefixRef.current) {
			setTimeout(() => {
				const size = prefixRef.current?.getBoundingClientRect();
				setPaddingLeft(15 + (size?.width ?? 0));
			}, 150);
		}
	}, []);

	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (input.current && document.activeElement === input.current) {
				if (e.key === 'Escape') {
					onEscape();
				}
				if (e.key === 'Tab') {
					onTab();
				}
				if (e.key === 'Enter') {
					onEnterKey(input.current.value, e);
				}
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [input, onEnterKey, onEscape, onTab]);

	const type = useMemo(() => {
		if (numeric) {
			return 'number';
		}
		if (email) {
			return 'email';
		}
		if (password) {
			return 'password';
		}
		return 'text';
	}, [email, numeric, password]);

	function handleFocusClick() {
		const isFocused = document.activeElement === input.current;
		if (useFocusClick && !isFocused) {
			input.current?.focus();
		}
	}

	const handleNumericKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (['e', 'E', '+', '-'].includes(e.key)) {
			e.preventDefault()
		} else {
			onKeyDown(e);
		}
	};

	const providedValue = value !== undefined ? value : defaultValue;
	const inputValue = useInternalState ? internalText : providedValue;

	return (
		<Field {...props}>
			<div
				className={classNames('field-group text', { entered, flash: changeFlash, 'no-margin': noMargin, 'show-password-icon': showPasswordIcon }, valid, size, className)}
				style={style}
			>
				{label ? (
					<div style={{
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						...(ignoreZIndex ? {} : { zIndex: 1 })
					}}>
						<FieldLabel className={labelClassName} label={label} required={required} tooltip={tooltip} />
					</div>
				) : null}
				{prefix && (
					<span className={classNames("prefix", { 'has-tooltip': !!tooltip })} ref={prefixRef}>
						{prefix}
					</span>
				)}
				<input
					onClick={handleFocusClick}
					className={classNames('evt-field-input', { 'has-icon': !!icon, 'isAdmin': !!isAdmin })}
					onBlur={onBlur}
					onFocus={onFocus}
					id={name ? `file-input-${name}` : id}
					disabled={disabled}
					ref={mergeRefs(input, ref)}
					type={passwordVisible ? 'text' : type}
					placeholder={noPlaceholder ? '' : (placeholder || label)}
					onChange={handleChange}
					onKeyUp={keyUp}
					onKeyDown={numeric ? handleNumericKeydown : onKeyDown}
					value={inputValue}
					name={name}
					min={numeric ? min : undefined}
					max={numeric ? max : undefined}
					step={numeric ? step : undefined}
					style={{ paddingLeft, fontFamily, ...props.inputStyle }} //don't take this out - removing it caused a regression. If you need to make a change here, change the paddingLeft variable, but leave this in here.
					autoComplete={autoComplete || 'on'}
					autoCapitalize={autoCapitalize}
					autoCorrect={autoCorrect}
					size={Number(inputSize)}
				/>

				{icon && (
					<div className="input-icon">
						<Icon name={icon} size={14} color={COLORS.DEFAULT_GRAY} />
					</div>
				)}

				<OptionalComponent display={showPasswordIcon}>
					<div className="toggle-password-visibility-icon" onClick={() => setPasswordVisible(!passwordVisible)}>
						<Icon name={passwordVisible ? ICONS.VIEWERS_EYE_OFF : ICONS.VIEWERS_EYE} size={16} color={COLORS.BLACK} />
					</div>
				</OptionalComponent>

				{!!onClear && (
					<button
						className={classNames("clear-button no-style no-padding no-marging", { visible: !!internalText })}
						onClick={() => {
							if (input.current) {
								setInternalText('');
								onClear();
							}
						}}
					>
						<Icon name={ICONS.CLOSE} size={12} color={COLORS.DEFAULT_GRAY} />
					</button>
				)}
			</div>
		</Field>
	);
});
export default TextInput;
