import React, { useEffect, useRef } from 'react';
import { useCallback, useState } from 'react';
import classNames from 'classnames';

import { useTranslate } from '../../../i18n/useTranslationModules';
import { Tooltip, TooltipPositions } from '../tooltip/tooltip';
import Icon, { COLORS, ICONS, IconType } from '../icon';
import WaitingIndicator from '../waiting-indicator/waiting-indicator';

import './message-card.scss';

export enum EMessageStatus {
	Waiting = 'Waiting',
	Approved = 'Approved',
	Deleted = 'Deleted',
}

export enum EMessageType {
	Chat = 'Chat',
	Post = 'Post',
	Question = 'Question',
}

export enum EMessageVisibility {
	Presenter = 'Presenter',
	Moderator = 'Moderator',
}

const MESSAGE_TYPE_ICON_MAP: Record<EMessageType, IconType> = {
	[EMessageType.Chat]: 'TAB_CHAT',
	[EMessageType.Post]: 'CHAT_COMMENT',
	[EMessageType.Question]: 'QUESTION_FILLED',
};

export enum EMessageButtonType {
	Approve = 'Approve',
	Unapprove = 'Unapprove',
	SendToPresenter = 'SendToPresenter',
	RemoveFromPresenter = 'RemoveFromPresenter',
	Delete = 'Delete',
	UnDelete = 'UnDelete',
	SendToModerator = 'SendToModerator', // not in use
	RemoveFromModerator = 'RemoveFromModerator', // not in  use
	Reply = 'Reply',
	BanUser = 'BanUser',
	UnbanUser = 'UnbanUser',
	GreenroomModeratorApprove = 'GreenroomModeratorApprove',
	GreenroomModeratorShow = 'GreenroomModeratorShow',
	GreenroomModeratorHide = 'GreenroomModeratorHide',
	GreenroomModeratorSave = 'GreenroomModeratorSave',
	GreenroomModeratorUnsave = 'GreenroomModeratorUnsave',
}

export interface IMessage {
	id: string;
	author: {
		firstName: string;
		lastName: string;
	};
	text: string;
	time: string;
	type: EMessageType;
	status: EMessageStatus;
	visibility?: EMessageVisibility[];
	replyMessage?: IMessage;
}

export interface IMessageCardReplyClickEventArgs {
	text: string;
	message: IMessage;
}

export interface IMessageCardActionClickEventArgs {
	type: EMessageButtonType;
	message: IMessage;
}

interface MessageCardProps {
	message: IMessage;
	imageUrls?: string[];
	onReplyClick?: (props: IMessageCardReplyClickEventArgs) => void;
	onActionClick?: (props: IMessageCardActionClickEventArgs) => void;
}

export const MessageCard = ({
	imageUrls,
	onActionClick,
	onReplyClick,
	...rest
}: MessageCardProps): JSX.Element => {

	// Main message or first message always shown into top of card
	const mainMessage = rest.message.replyMessage
		? rest.message.replyMessage
		: rest.message;

	const replyMessage = rest.message.replyMessage
		? rest.message
		: rest.message.replyMessage;

	const [showReply, setShowReply] = useState(false);

	const handleActionClick = useCallback(
		(type) => {
			onActionClick && onActionClick({ type, message: mainMessage });

			if (type === EMessageButtonType.Reply) {
				setShowReply(true);
			} else {
				setShowReply(false);
			}
		},
		[onActionClick, mainMessage]
	);

	const handleSendReplyClick = useCallback(
		(text) => {
			setShowReply(false);
			onReplyClick && onReplyClick({ message: mainMessage, text });
		},
		[onReplyClick, mainMessage]
	);

	const renderReply = useCallback((): JSX.Element | null => {
		if (!replyMessage) {
			return null;
		}

		return (
			<div className="message-card-quote">
				<div className="message-card-header">
					<div className="message-card-header-main">
						<Icon
							name={ICONS.MESSAGE}
							size={16}
							color={COLORS.DEFAULT_GRAY}
						/>
						<div className="text-ellipsis">
							<span className="message-card-title">
								{replyMessage.author.firstName}{' '}
								{replyMessage.author.lastName}
							</span>
							<time dateTime="">{replyMessage.time}</time>
						</div>
					</div>
				</div>
				<div className="message-card-text text-ellipsis">
					{replyMessage.text}
				</div>
			</div>
		);
	}, [replyMessage]);

	const renderImages = (): JSX.Element | null => {
		if (!imageUrls) {
			return null;
		}
		const len = imageUrls.length;
		const images = imageUrls.map((url) => (
			<div key={url} style={{ backgroundImage: `url(${url})` }}></div>
		));
		return (
			<div
				className={classNames('message-card-gallery', {
					'single-image': len === 1,
					'double-sided': len === 2,
				})}
			>
				{images}
			</div>
		);
	};

	return (
		<React.Fragment>
			<div className="message-card">
				<div className="message-card-header">
					<div className="message-card-header-main">
						{!replyMessage && (
							<Icon
								name={
									ICONS[
									MESSAGE_TYPE_ICON_MAP[mainMessage.type]
									]
								}
								size={16}
								color={COLORS.DEFAULT_GRAY}
							/>
						)}
						<div className="text-ellipsis">
							<span
								className={classNames('message-card-title', {
									'text-primary': replyMessage,
								})}
							>
								{mainMessage.author.firstName}{' '}
								{mainMessage.author.lastName}
							</span>
							{replyMessage && (
								<Icon
									name={ICONS.SHIELD}
									size={16}
									color={COLORS.CYAN}
								/>
							)}
							<time dateTime="">{mainMessage.time}</time>
						</div>
					</div>
					<span
						className={classNames('message-card-status', {
							'text-danger':
								mainMessage.status === EMessageStatus.Deleted,
						})}
					>
						{mainMessage.status}
					</span>
				</div>
				{renderReply()}

				<div className="message-card-body">
					<div className="message-card-text">{mainMessage.text}</div>
					{renderImages()}
				</div>

				<MessageCardActions
					visibility={mainMessage.visibility || []}
					status={mainMessage.status}
					onClick={handleActionClick}
				/>
			</div>
			{showReply && <ReplyMessage onSendClick={handleSendReplyClick} />}
		</React.Fragment>
	);
};

interface IReplyMessageProps {
	onSendClick: (text: string) => void;
}

export const ReplyMessage = ({ onSendClick }: IReplyMessageProps): JSX.Element => {
	const textAreaRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	}, [textAreaRef]);

	const handleClick = useCallback(() => {
		onSendClick(textAreaRef.current?.innerText || '');
	}, [onSendClick]);

	const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		if (e.code === 'Enter') {
			onSendClick(textAreaRef.current?.innerText || '');
		}
	};

	return (
		<div className="reply-message-card">
			<div
				className="reply-message"
				contentEditable="true"
				onKeyPress={handleKeyPress}
				ref={textAreaRef}
			></div>
			<button
				className="reply-button primary round"
				onClick={handleClick}
			>
				<Icon name={ICONS.SEND} size={16} color={COLORS.BLACK} />
			</button>
		</div>
	);
};

const getMessageActions = ({
	status,
	visibility,
}: {
	status: EMessageStatus;
	visibility: EMessageVisibility[];
}): EMessageButtonType[] => {
	switch (status) {
		case EMessageStatus.Waiting: {
			return [
				EMessageButtonType.Delete,
				visibility.includes(EMessageVisibility.Moderator)
					? EMessageButtonType.RemoveFromModerator
					: EMessageButtonType.SendToModerator,
				visibility.includes(EMessageVisibility.Presenter)
					? EMessageButtonType.RemoveFromPresenter
					: EMessageButtonType.SendToPresenter,
				EMessageButtonType.Reply,
			];
		}
		case EMessageStatus.Deleted: {
			return [
				EMessageButtonType.UnDelete,
				visibility.includes(EMessageVisibility.Moderator)
					? EMessageButtonType.RemoveFromModerator
					: EMessageButtonType.SendToModerator,
				visibility.includes(EMessageVisibility.Presenter)
					? EMessageButtonType.RemoveFromPresenter
					: EMessageButtonType.SendToPresenter,
				EMessageButtonType.Reply,
			];
		}
		case EMessageStatus.Approved: {
			return [
				EMessageButtonType.Delete,
				visibility.includes(EMessageVisibility.Moderator)
					? EMessageButtonType.RemoveFromModerator
					: EMessageButtonType.SendToModerator,
				visibility.includes(EMessageVisibility.Presenter)
					? EMessageButtonType.RemoveFromPresenter
					: EMessageButtonType.SendToPresenter,
				EMessageButtonType.Reply,
			];
		}
	}
};

interface IMessageCardActionsProps {
	status: EMessageStatus;
	visibility: EMessageVisibility[];
	onClick: (type: EMessageButtonType) => void;
}

const MessageCardActions = ({
	status,
	visibility,
	onClick,
}: IMessageCardActionsProps) => {
	const buttons = getMessageActions({ status, visibility });
	return (
		<div className="message-card-actions">
			{buttons.map(
				(type): JSX.Element => (
					<MessageButton
						buttonType={type}
						key={type}
						onClick={onClick}
					/>
				)
			)}
		</div>
	);
};

interface IButtonConfig {
	title: string;
	icon: keyof typeof ICONS;
	size: number;
}

interface IMessageButtonProps {
	buttonType: EMessageButtonType;
	onClick: (type: EMessageButtonType) => void;
	buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
	loading?: boolean;
	tooltipPosition?: TooltipPositions;
}

export const MessageButton: React.FC<IMessageButtonProps> = ({
	buttonType,
	onClick,
	buttonProps = {},
	loading = false,
	tooltipPosition = TooltipPositions.bottomLeft
}) => {

	const { t } = useTranslate('session');

	const BUTTONS_CONFIG: Record<EMessageButtonType, IButtonConfig> = {
		[EMessageButtonType.Approve]: {
			icon: 'DONE_FILLED',
			title: t('Approve'),
			size: 18,
		},
		[EMessageButtonType.Unapprove]: {
			icon: 'ADD_CLOSE_CIRCLE',
			title: t('Decline'),
			size: 18,
		},
		[EMessageButtonType.Delete]: {
			icon: 'TRASH_OUTLINE',
			title: t('Delete'),
			size: 20,
		},
		[EMessageButtonType.RemoveFromModerator]: { // not in use
			icon: 'MODERATION_CROSSED_FILLED',
			title: t('Remove from moderator chat'),
			size: 18,
		},
		[EMessageButtonType.RemoveFromPresenter]: {
			icon: 'ARROW_RIGHT_BOTTOM_FILLED',
			title: t('Remove from presenter'),
			size: 18,
		},
		[EMessageButtonType.Reply]: {
			icon: 'REPLY_FILLED',
			title: t('Reply'),
			size: 18,
		},
		[EMessageButtonType.SendToPresenter]: {
			icon: 'ARROW_RIGHT_TOP_FILLED',
			title: t('Send to presenter'),
			size: 18,
		},
		[EMessageButtonType.SendToModerator]: { // not in use
			icon: 'MODERATION_FILLED',
			title: t('Send to moderator'),
			size: 18,
		},
		[EMessageButtonType.UnDelete]: {
			icon: 'UNDELETE_FILLED',
			title: t('Undelete'),
			size: 18,
		},
		[EMessageButtonType.BanUser]: {
			icon: 'MODERATION_CROSSED_FILLED',
			title: t('Ban User'),
			size: 18,
		},
		[EMessageButtonType.UnbanUser]: {
			icon: 'MODERATION_FILLED',
			title: t('Unban User'),
			size: 18,
		},
		[EMessageButtonType.GreenroomModeratorApprove]: {
			icon: 'DONE_FILLED',
			title: 'Approve',
			size: 16,
		},
		[EMessageButtonType.GreenroomModeratorShow]: {
			icon: 'VIEWERS_EYE',
			title: 'Show',
			size: 16,
		},
		[EMessageButtonType.GreenroomModeratorHide]: {
			icon: 'VIEWERS_EYE_OFF',
			title: 'Hide',
			size: 16,
		},
		[EMessageButtonType.GreenroomModeratorSave]: {
			icon: 'STAR_OUTLINE',
			title: 'Save',
			size: 14,
		},
		[EMessageButtonType.GreenroomModeratorUnsave]: {
			icon: 'STAR',
			title: 'Unsave',
			size: 14,
		},
	};

	const config = BUTTONS_CONFIG[buttonType];

	return (
		<Tooltip tooltip={config.title} position={tooltipPosition}>
			<button
				style={{ position: 'relative' }}
				className="round"
				aria-label={config.title}
				onClick={() => onClick(buttonType)}
				{...buttonProps}
			>
				{loading ? (
					<>
						<div style={{
							position: 'absolute',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%',
							top: '0',
							left: '0',
						}}>
							<WaitingIndicator />
						</div>
						<div style={{ visibility: 'hidden' }}>
							<Icon
								name={ICONS[config.icon]}
								size={config.size}
								color={COLORS.WHITE}
							/>
						</div>
					</>
				) : (
					<Icon
						name={ICONS[config.icon]}
						size={config.size}
						color={COLORS.WHITE}
					/>
				)}
			</button>
		</Tooltip>
	);
};
