import {
	GET_BROADCAST_SERVER,
	GET_EVENTS_LEGACY_BROADCAST_ENDPOINT,
	GET_EVENTS_SESSION_BROADCAST_ENDPOINT,
	GET_SHOWROOMS_BROADCAST_ENDPOINT,
	RESET_BROADCAST_SERVER,
	SET_BROADCAST_STATE,
	SET_BROADCAST_STATS,
	SET_CUSTOM_BROADCAST_ENDPOINT,
	SET_GO_LIVE_BUTTON_CLICKED,
	SET_PREVIOUS_BROADCAST_STATE,
	SET_STREAM_STATE,
	START_BROADCAST,
	STOP_BROADCAST,
} from '../../actions/admin/broadcasting';

import { handle } from 'redux-pack';
// import { Action } from '../types/types';
// import { SelectGroupOption } from '../../components/general-components/select-group'

export interface Action {
	type: string;
	payload: any;
	remote?: boolean;
	id?: number;
}

export interface SelectGroupOption {
	label: string;
	value: any;
	selected?: boolean;
	description?: string;
	className?: string;
}

export interface BroadcastingState {
	server: any;							//server data from API side - entry in gr_broadcasts table
	loadingBroadcastServer: boolean;		//getting server data from API
	loadingBroadcastServerError: boolean;	//couldn't get server data from API
	broadcasting: boolean;					//is actively broadcasting
	startingBroadcast: boolean;				//broadcast request sent to API
	failedToStartBroadcast: boolean;		//500 error received from API
	stoppingBroadcast: boolean;				//broadcast stop request sent to API
	failedToStopBroadcast: boolean;			//500 error received from API
	serverState: BROADCAST_STATES;					//corresponds to BROADCAST_STATES below
	streamState: number;
	previousServerState: BROADCAST_STATES;						//corresponds to STREAM_STATES below
	stats: BroadcastStats;
	broadcastEndpoint: {
		url: string;
		stream_key: string;
	} | undefined,
	customEndpoint: {
		name: string;
		url: string;
		stream_key: string;
	},
	useCustomEndpoint: boolean;
	goLiveButtonClicked: boolean;
}

export const BROADCAST_STATESS = {
	OFF: 0, 				//broadcast server has not been created for this meeting - either too early or too late
	STARTING_UP: 1, 		//server is starting up but is not yet available
	READY: 2, 				//server can be broadcast to
	BROADCAST_STARTED: 3, 	//start response received from broadcast server - it's actually live
	BROADCAST_STOPPED: 4, 	//stopped signal has been received from server
	DRAINING: 5, 			//server is actively terminating - may go unused
	KILLED: 6, 				//server is dead and unusable
	BROADCAST_STARTING: 7, 	//start signal has been sent to broadcast server but not yet received confirmation that it's live
	BROADCAST_FAILED: 8, 	//broadcast signal sent but the broadcast failed
	BROADCAST_PREWARMING: 9,	//server is starting a fake broadcast really quick to get everything in sync. Random necessary step.
	BROADCAST_PREWARM_FAILED: 10,
};

export enum BROADCAST_STATES {
	//broadcast server has not been created for this meeting - either too early or too late
	OFF = 'off',
	//server is starting up but is not yet available
	STARTING_UP = 'starting_up',
	//server can be broadcasted to
	READY = 'ready',
	//start response received from broadcast server - it's actually live
	BROADCAST_STARTED = 'broadcast_started',
	//stopped signal has been received from server
	BROADCAST_STOPPED = 'broadcast_stopped',
	//server is actively terminating - may go unused
	DRAINING = 'draining',
	//server is dead and unusable
	KILLED = 'killed',
	//start signal has been sent to broadcast server but not yet received confirmation that it's live
	BROADCAST_STARTING = 'broadcast_starting',
	//broadcast signal sent but the broadcast failed
	BROADCAST_FAILED = 'broadcast_failed',
	// broadcast is doing a pre-warm test
	BROADCAST_PREWARMING = 'broadcast_prewarming',
	// test for pre-warm has failed
	BROADCAST_PREWARM_FAILED = 'broadcast_prewarm_failed',
}

export const STREAM_STATES = {
	OFF: 0,
	PRE: 1,
	MAIN: 2,
	POST: 3
};

export const DESTINATION_TYPE = {
	BRANDLIVE: -1,
	STREAMS: 0,
	ALLHANDS: 1,
	SHOWROOMS: 2,
	FACEBOOK: 3,
	YOUTUBE: 4,
	VIMEO: 5,
	LINKEDIN: 6,
	OTHER: 7,
};

export const RESOLUTION_OPTIONS: SelectGroupOption[] = [
	{ label: '1080p', value: '1080p' },
	{ label: '720p', value: '720p' },
	{ label: '480p', value: '480p' }
];

export const CUSTOM_DESTINATION_OPTIONS: SelectGroupOption[] = [

];

export interface BroadcastStats {
	bitrate: number;		//in kbps
	fps: number;			//in frames per second
	health: number;			//1 is good - less than 1 the server is falling behind - less than 0.95 frames will be lost, broadcast will buffer in video player periodically
}

export const initialBroadcastingState: BroadcastingState = {
	broadcastEndpoint: {
		url: '',
		stream_key: ''
	},
	broadcasting: false,
	customEndpoint: {
		name: '',
		url: '',
		stream_key: ''
	},
	failedToStartBroadcast: false,
	failedToStopBroadcast: false,
	goLiveButtonClicked: false,
	loadingBroadcastServer: false,
	loadingBroadcastServerError: false,
	previousServerState: BROADCAST_STATES.BROADCAST_PREWARMING,
	server: null,
	serverState: BROADCAST_STATES.BROADCAST_PREWARMING,
	startingBroadcast: false,
	stats: {
		bitrate: 0,
		fps: 0,
		health: 0
	},
	stoppingBroadcast: false,
	streamState: 2,
	useCustomEndpoint: false,
};



export default function BroadcastReducer(
	state: BroadcastingState = initialBroadcastingState,
	action: Action
): BroadcastingState {
	switch (action.type) {
		case GET_BROADCAST_SERVER: {
			return handle(state, action, {
				start: state => {
					return ({ ...state, loadingBroadcastServer: true });
				},
				failure: state => {
					return ({ ...state, loadingBroadcastServerError: true });
				},
				success: state => {
					const newState = { ...state };

					if (action.payload?.error) {
						newState.serverState = BROADCAST_STATES.BROADCAST_PREWARMING;
					} else {
						if (action.payload?.starting_up) {
							newState.serverState = BROADCAST_STATES.STARTING_UP;
						} else if (action.payload?.id) {
							newState.server = action.payload;
							newState.serverState = action.payload.current_state;
							newState.broadcasting = action.payload.current_state === BROADCAST_STATES.BROADCAST_STARTED;
						}
					}

					return newState;
				},
				finish: state => {
					return ({ ...state, loadingBroadcastServer: false });
				}
			});
		}
		case START_BROADCAST: {
			return handle(state, action, {
				start: state => {
					return ({ ...state, startingBroadcast: true });
				},
				failure: state => {
					return ({ ...state, failedToStartBroadcast: true });
				},
				success: state => {
					return ({ ...state, broadcasting: true, failedToStartBroadcast: false });
				},
				finish: state => {
					return ({ ...state, startingBroadcast: false });
				}
			});
		}
		case STOP_BROADCAST: {
			return handle(state, action, {
				start: state => {
					return ({ ...state, stoppingBroadcast: true });
				},
				failure: state => {
					return ({ ...state, failedToStopBroadcast: true });
				},
				success: state => {
					return ({ ...state, broadcasting: false, failedToStopBroadcast: false });
				},
				finish: state => {
					return ({ ...state, stoppingBroadcast: false });
				}
			});
		}
		case SET_BROADCAST_STATE: return { ...state, serverState: action.payload };
		case SET_STREAM_STATE: return { ...state, streamState: action.payload };
		case SET_PREVIOUS_BROADCAST_STATE: return { ...state, previousServerState: action.payload };
		case SET_BROADCAST_STATS: return { ...state, stats: action.payload };
		case GET_EVENTS_SESSION_BROADCAST_ENDPOINT: {
			return handle(state, action, {
				success: state => {
					const newState = { ...state };

					if (action.payload.rtmps_address) {
						newState.broadcastEndpoint = {
							url: action.payload.rtmps_address,
							stream_key: action.payload.stream_key
						};
					}

					return newState;
				},
				failure: state => {
					return ({ ...state, broadcastEndpoint: { url: "Error getting URL", stream_key: "" } });
				}
			});
		}
		case GET_EVENTS_LEGACY_BROADCAST_ENDPOINT: {
			return handle(state, action, {
				success: state => {
					const newState = { ...state };

					if (action.payload.url) {
						newState.broadcastEndpoint = {
							url: action.payload.url,
							stream_key: action.payload.stream_key
						};
					}

					return newState;
				},
				failure: state => {
					return ({ ...state, broadcastEndpoint: { url: "Error getting URL", stream_key: "" } });
				}
			});
		}

		case GET_SHOWROOMS_BROADCAST_ENDPOINT: {
			return handle(state, action, {
				success: state => {
					let broadcastEndpoint;
					if (action.payload) {
						const broadcastConfig = action.payload.broadcastStreamingConfiguration;

						// RTMP address = rtmps://[endpoint]/[app name]/[stream key]
						// "/app" is the stream name for showrooms, usually the default on most rtmp servers, but could be something different.
						broadcastEndpoint = {
							url: `rtmps://${broadcastConfig.ingestionEndpoint}/app`,
							stream_key: broadcastConfig.streamKey
						};
					}

					return ({ ...state, broadcastEndpoint: broadcastEndpoint });
				},
				failure: state => {
					return ({ ...state, broadcastEndpoint: { url: "Error getting URL", stream_key: "" } });
				}
			});
		}
		case SET_CUSTOM_BROADCAST_ENDPOINT: {
			return {
				...state,
				useCustomEndpoint: action.payload.useCustomEndpoint,
				customEndpoint: action.payload.endpoint,
			};
		}
		case SET_GO_LIVE_BUTTON_CLICKED: {
			return { ...state, goLiveButtonClicked: action.payload };
		}
		case RESET_BROADCAST_SERVER: {
			return handle(state, action, {
				start: state => {
					return ({ ...state, loadingBroadcastServer: true });
				},
				failure: state => {
					return ({ ...state, loadingBroadcastServerError: true });
				},
				success: state => {
					const newState = { ...state };
					if ('error' in action.payload) {
						console.log(action.payload.error);
						newState.serverState = BROADCAST_STATES.BROADCAST_PREWARMING;
					} else {
						if ('starting_up' in action.payload) {
							newState.serverState = BROADCAST_STATES.STARTING_UP;
						} else if (action.payload.id) {
							newState.server = action.payload;
							newState.serverState = action.payload.current_state;
							newState.broadcasting = action.payload.current_state === BROADCAST_STATES.BROADCAST_STARTED;
						}
					}

					return newState;
				},
				finish: state => {
					return ({ ...state, loadingBroadcastServer: false });
				}
			});
		}

		default:
			return state;
	}
}
