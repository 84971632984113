const categoryId = localStorage.getItem('data-categoryid');
const subCategoryId = localStorage.getItem('data-subcategoryid');

type Payload = Record<string, string | undefined>;

export enum TrackingPayloadEventTypes {
	pageLoad = 'blevent_page_load'
}

export const buildTrackingPayload = (
	name: string,
	feature: string,
	pathname: string,
	registrationComplete?: string
): Payload => {
	return {
		'data-categoryid': categoryId ?? '',
		'data-subcategoryid': subCategoryId ?? '',
		'data-eventName': name,
		'data-eventFeature': feature,
		'data-pageName': pathname,
		'data-registration':
			registrationComplete ? 'Event Registration Complete' : undefined
	};
};

export const sendTrackingPayload = (payload: Payload): void => {
	document.dispatchEvent(
		new CustomEvent<Payload>(
			TrackingPayloadEventTypes.pageLoad,
			{
				detail: payload
			}
		)
	);
};