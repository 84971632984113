import { useRef, useState, useCallback, useEffect } from "react";
import classNames from 'classnames';
import { debounce } from "underscore";

import { Validation } from "./text";
import Icon, { COLORS, ICONS } from "../icon";
import { OptionalComponent } from "../../../utils/optional-component";

import './basic-input.scss';

interface IBasicInputProps {
	debounceTime?: number;
	defaultText?: string;
	focus?: boolean;
	label?: string;
	margin?: 'none' | 'normal';
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
	onDebounce?: (value: string) => void;
	required?: boolean;
	setFocus?: React.Dispatch<React.SetStateAction<boolean>>;
	tooltip?: () => JSX.Element;
	valid?: Validation;
	maxLength?: number;
	displayMaxLengthCounter?: boolean;
	showTail?: boolean;
	tailPosition?: 'left' | 'center' | 'right';
	showCounterAtCharIndex?: number;
}

const BasicInput: React.FC<IBasicInputProps & React.HTMLProps<HTMLInputElement>> = ({
	debounceTime = 300,
	defaultText = '',
	focus,
	label,
	margin = 'normal',
	onBlur = () => null,
	onChange = () => null,
	onDebounce = () => null,
	required = false,
	setFocus,
	tooltip,
	type = 'text',
	valid = Validation.normal,
	maxLength,
	displayMaxLengthCounter = false,
	tailPosition = 'center',
	showTail = false,
	showCounterAtCharIndex,
	...rest
}) => {
	const [value, setValue] = useState(defaultText);
	const [showPassword, setShowPassword] = useState(false);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const passwordTimer = useRef<NodeJS.Timeout | null>(null);

	const debounced = useRef(debounce((value: string) => {
		onDebounce(value);
	}, debounceTime)).current;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		debounced(e.target.value);
		onChange(e);
	};

	const togglePassword = useCallback((e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setShowPassword(!showPassword);
	}, [showPassword]);

	useEffect(() => {
		if (passwordTimer.current) clearTimeout(passwordTimer.current);

		if (showPassword) {
			passwordTimer.current = setTimeout(() => {
				setShowPassword(false);
			}, 3000);
		}
	}, [showPassword]);


	useEffect(() => {
		if (inputRef?.current && focus) {
			inputRef.current.focus();
		}
	}, [focus]);

	// if the parent passes down the method setFocus, then we want to make
	// sure we set it to false when the user blurs - note: we cannot use reacts onBlur method
	// because it will not trigger under every scenario
	useEffect(() => {
		const referencedRef = inputRef?.current;
		const handleBlur = () => {
			setFocus && setFocus(false);
		};
		if (referencedRef) {
			referencedRef.addEventListener('blur', handleBlur);
		}
		return () => {
			if (referencedRef) {
				referencedRef.removeEventListener('blur', handleBlur);
			}
		};
	}, [inputRef, setFocus]);

	const shouldShowMaxLength = useCallback(() => {
		if (!maxLength) return false;
		if (!value) return false;
		if (!displayMaxLengthCounter) return false;

		let hasReachedCount = value.length >= maxLength / 2;
		if (showCounterAtCharIndex) {
			hasReachedCount = value.length >= showCounterAtCharIndex;
		}
		return hasReachedCount;
	}, [displayMaxLengthCounter, maxLength, value, showCounterAtCharIndex]);

	return (
		<div className={`basic-input-container field-group text normal ${margin === 'none' ? 'no-margin' : ''} ${valid}`}>
			{label ? (
				<div className="basic-input-label-container">
					<label
						className={classNames(
							"evt-field-label",
							{ "evt-field-label-required": required },
						)}
					>
						{label}
					</label>
					<OptionalComponent display={!!tooltip}>
						<div style={{ marginLeft: '5px' }}>{tooltip?.()}</div>
					</OptionalComponent>
				</div>
			) : null}
			<input
				ref={inputRef}
				type={showPassword ? 'text' : type}
				onChange={handleChange}
				onBlur={onBlur}
				value={value}
				maxLength={maxLength}
				{...rest}
				className={`${rest?.className || ''} basic-input-field`}
			/>

			{type === 'password' && (
				<button onMouseDown={togglePassword} type="button" onTouchStart={togglePassword} className="no-style show-password"><Icon name={ICONS.VIEWERS_EYE} size={12} color={COLORS.WHITE} /></button>
			)}

			{shouldShowMaxLength()
				? (
					<div className="input-character-count-container">
						<div
							className={classNames(
								'input-character-count',
								`${tailPosition}-tail`,
								{
									'show-tail': showTail,
								}
							)}
						>{value.length}/{maxLength}</div>
					</div>
				)
				: null}
		</div>
	);
};

export default BasicInput;
