import { isObject } from "underscore";

import {
	ColorOptions,
	ColorValueIndexes,
	defaultColors,
	IColorVariants,
} from "types/theme-packs";
import { assertIsColorOptionsV2 } from "utils/assertions";
import generateSelfContrastingColor from "./generate-self-contrasting-color";
import getRelativeContrastColor from "./generate-relative-contrasting-color";


// This function is used to recursively spread an object, and is used to convert ColorOptions to ColorOptionsV2
// WARNING: Do not use this function for spreading objects which contain properties that you cannot guarantee are safe to spread
// since the conditionals are not exhaustive
const recursiveSpread = <T>(obj: T): T => {
	const isArray = Array.isArray(obj);
	const isObj = isObject(obj);
	if (
		(!isObj && !isArray)
		|| obj === null
		|| obj === undefined
		|| typeof obj === 'function'
		|| obj instanceof Date
		|| obj instanceof RegExp
		|| obj instanceof Map
		|| obj instanceof Set
		|| obj instanceof WeakMap
		|| obj instanceof WeakSet
		|| obj instanceof Error
		|| obj instanceof Promise
		|| obj instanceof URL
		|| obj instanceof URLSearchParams
	) {
		return obj;
	}
	if (isArray) {
		return (obj.map(recursiveSpread) as unknown) as T;
	}
	return (Object.fromEntries(
		Object.entries(obj).map(([key, value]) => [key, recursiveSpread(value)])
	) as unknown) as T;
};

export const mutateV1ColorsToV2Colors = (colors?: ColorOptions): ColorOptions => {
	if (!colors || !isObject(colors)) return defaultColors;

	const colorsCopy = recursiveSpread<ColorOptions | undefined>(colors);

	if (!colorsCopy) return defaultColors;
	try {
		// if valid v2 colors, just go ahead and return them
		assertIsColorOptionsV2(colorsCopy);

		// if object keys length is 2 and they're only light and dark, we need to add the top level keys
		// this is so we can keep the top level keys in the object and have complete consistency for all color packs (new and old v1)
		if (Object.keys(colorsCopy).length === 2 && colorsCopy.light && colorsCopy.dark) {
			return {
				...colorsCopy.light,
				...colorsCopy,
			};
		}
		// else just return the entire v2 colors
		return colorsCopy;
	} catch (e) {
		// if v1 colors, convert them to v2 colors and be sure to include buttonTextolor
		// buttonTextColor is a completely new color type that doesn't align with anything else, so we need to add it
		if (!colorsCopy?.buttonTextColor) {
			colorsCopy.buttonTextColor = [
				getRelativeContrastColor(
					colorsCopy?.headingTextColor?.[ColorValueIndexes.hex] || colorsCopy?.bodyTextColor?.[ColorValueIndexes.hex] || '#000000',
					colorsCopy?.accentColor?.[ColorValueIndexes.hex] || colorsCopy?.headingTextColor?.[ColorValueIndexes.hex] || '#000000',
				),
				1
			];
		}

		const { light, dark, ...topLevelColors } = colorsCopy;

		return {
			...defaultColors,
			...topLevelColors,
			light: {
				...defaultColors.light,
				...(light || topLevelColors || {})
			} as IColorVariants,
			dark: {
				...defaultColors.dark,
				...(dark || topLevelColors || {}),
				// since there is no actual dark mode on the palette and we are "creating" it
				// we're just going set some sensible default dark mode values. Also asserting because we know they exist on default colors
				backgroundColor: defaultColors?.dark?.backgroundColor as [string, number],
				containerColor: defaultColors?.dark?.containerColor as [string, number],
				headingTextColor: defaultColors?.dark?.headingTextColor as [string, number],
				bodyTextColor: defaultColors?.dark?.bodyTextColor as [string, number],
			} as IColorVariants,
		};
	}
};
