import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// REDUX AND HOOKS IMPORTS
import { useTranslate } from "../../../../../../../i18n/useTranslationModules";
import { setCustomPasscodeLabel } from "../../../../../../../store/actions/admin/create-event";
import { EventsState } from "../../../../../../../store/types";
import { RegFieldsEnum } from "../../../../../../../types/working-model";
import { ParamsProps } from "../../../../../../live-event/live-event";

// UTIL IMPORTS
import { updateTranslateKey } from "../../../../../../../utils/utils";
import { getHomepageDefaultLanguage } from "../../../../../../../utils/get-language";

// COMPONENT UI IMPORTS
import ModalComponent from "../../../../../../general-ui/modal/modal";
import Icon, { ICONS } from "../../../../../../general-ui/icon";
import TextInput from "../../../../../../general-ui/text-input/text";
import GatedCustomErrorMessage from "../settings/registration-settings/gated-custom-error-message";
import { useAppDispatch } from "store/reducers/use-typed-selector";

const PanelAdvancedSettings: React.FC = () => {
	const workingEvent = useSelector((state: EventsState) => state.CreateEventReducer.workingEvent);
	const [passcodeLabelModalOpen, setPasscodeLabelModalOpen] = useState(false);
	const [enteredPasscodeLabel, setEnteredPasscodeLabel] = useState("");

	const { language } = useParams() as ParamsProps;
	const { t } = useTranslate('homepage');
	const dispatch = useAppDispatch();

	const shouldShowPasscodeLabelCard = useMemo(() => {
		const registrationSteps = workingEvent?.registration_steps;
		const activeSteps = registrationSteps?.filter(step => step.isOn);
		if (activeSteps) {
			return activeSteps.some(step => step.questions?.some(question => question.registration_question === RegFieldsEnum.passcode));
		}

		return false;
	}, [workingEvent]);

	const passcodeLabel = useMemo(() => {
		if (workingEvent?.registration_settings) {
			if (workingEvent.registration_settings.customPasscodeLabel?.[language]) {
				return workingEvent.registration_settings.customPasscodeLabel[language];
			}
		}

		return t(`registration.registration_fields.10`); //RegFieldsEnum.passcode - typescript will yell at you if you use the enum here
	}, [workingEvent?.registration_settings, language, t]);

	const openPasscodeLabelModal = useCallback(() => {
		setPasscodeLabelModalOpen(true);
	}, []);

	const closePasscodeLabelModal = useCallback(() => {
		setPasscodeLabelModalOpen(false);
	}, []);

	const setPasscodeLabel = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
		setEnteredPasscodeLabel(value);
	}, []);

	const savePasscodeLabel = useCallback(() => {
		dispatch(setCustomPasscodeLabel(
			updateTranslateKey({
				translateString: workingEvent?.registration_settings?.customPasscodeLabel ?? { base: 'Passcode', changed: '' },
				input: enteredPasscodeLabel,
				language,
				baseLanguage: getHomepageDefaultLanguage()
			})
		));

		closePasscodeLabelModal();
	}, [dispatch, closePasscodeLabelModal, enteredPasscodeLabel, language, workingEvent?.registration_settings?.customPasscodeLabel]);


	return (
		<>
			<GatedCustomErrorMessage />

			{shouldShowPasscodeLabelCard && (
				<>
					<p className="advanced-settings-text">Passcode Label</p>
					<div className="settings-card">
						<label>
							{passcodeLabel}
						</label>
						<button className="no-style no-margin no-padding options" onClick={openPasscodeLabelModal}>
							<Icon name={ICONS.EDIT} size={20} color={'cyan'} />
						</button>
					</div>
				</>
			)}



			<ModalComponent
				open={passcodeLabelModalOpen}
				onRequestClose={closePasscodeLabelModal}
				title={"Passcode Label"}
				cancellable={true}
				closeable={true}
				footer={(
					<>
						<button onClick={closePasscodeLabelModal}>Cancel</button>
						<button onClick={savePasscodeLabel} className="lemonade" disabled={!enteredPasscodeLabel.trim().length}>
							Apply
						</button>
					</>
				)}
			>
				<TextInput
					label={"Label"}
					defaultValue={passcodeLabel as string}
					placeholder={"Enter a custom passcode label"}
					onChange={setPasscodeLabel}
				/>
			</ModalComponent>
		</>
	);
};

export default PanelAdvancedSettings;
