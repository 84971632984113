import { PageModuleGroup } from "./prototypes/page-module-group-modules";

export const appendNewPageModulesToGroups = (uuid: string, newModules: number[]) => (group: PageModuleGroup) => {
	if (uuid === group.uuid) {
		return {
			...group,
			modules: Array.from(new Set([
				...group.modules,
				...newModules
			]))
		};
	}

	return group;
};