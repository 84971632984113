import { SurveyQuestionOption, SurveyType } from "../../../../../../../../types/working-model";
import Icon, { ICONS, COLORS } from "../../../../../../../general-ui/icon";

type QuestionCardOptionProps = {
	option: SurveyQuestionOption;
	type: SurveyType;
	language: string;
	total: number;
	responses: number;
}

const QuestionCardOption = (props: QuestionCardOptionProps) => {
	const { option, language, total, responses, type } = props;
	const canCalculate = total > 0 && responses > 0;
	const perc = canCalculate ? Math.floor((responses / total) * 100) : 0;
	const resLabel = responses !== 1 ? 'responses' : 'response';
	const isQuiz = type === SurveyType.quiz;
	return (
		<div className="survey-results-question-card-option">
			<div
				className={`survey-results-question-card-option-bar card-type-${type} ${option.is_correct ? "correct" : "incorrect"}`}
				style={{
					width: `${perc}%`,
				}}
			/>
			<div className="survey-results-question-card-option-text">
				<label>{option.label[language] ?? option.label.base}</label>
				<span>{perc}% answered · {responses} {resLabel}</span>
			</div>
			{isQuiz && (
				<div className="survey-results-question-card-option-correct">
					{option.is_correct ? (
						<Icon name={ICONS.CHECK_CIRCLE_FILLED} size={20} color={COLORS.BRIGHT_GREEN} />
					) : (
						<Icon name={ICONS.ADD_CLOSE_CIRCLE} size={22} color={COLORS.BORDER_MED_GRAY} />
					)}
				</div>
			)}
		</div>
	);
};

export default QuestionCardOption;