import React, { useCallback, useState, useEffect, useRef, useMemo, Suspense, lazy, useContext } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { PageModule, Session, SessionTypesEnum } from '../../../../types/working-model';
import { MainEventBannerLayoutTypesClassic, EditorSizes, TemplateNames } from '../../../../types/template-layouts';
import { Tag } from '../../../general-ui/tag/tag';
import { Button } from '../../../general-ui/button/button';
import { TypographyItem } from '../../../general-ui/typography-item/typography-item';
import { ParamsProps } from '../../live-event';
import { checkIsLive } from '../../../../utils/live-checker';
import { dateCountdown, getLiveSessionBackground, resizedImage, niceDateTimeTz, displayAddToCalendarButton, shouldDisplayHomepage } from '../../../../utils/utils';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import SessionHeader from '../../session/session-modules/header/header';
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { Countdown } from '../../../general-ui/countdown/countdown';
import Icon, { ICONS } from '../../../general-ui/icon';
import { getDefaultAgendaImage } from '../../../../store/utils/create-event';
import useBreakoutRoom from '../breakout-rooms/use-breakout-rooms';
import WaitingIndicator from '../../../general-ui/waiting-indicator/waiting-indicator';
import ModalComponent from '../../../general-ui/modal/modal';
import { getSessionHeaderStyleOverrides } from '../../../../utils/style-overrides';
import '../../../../scss/live-event/base/modules/breakout-session-banner.scss';
import { useTranslate } from '../../../../i18n/useTranslationModules';
import AboveTheFoldContent from '../../session/above-the-fold/above-the-fold-content';
import { useIsAboveTheFold, useIsNewModuleGrouping } from '../../../../hooks/session.hooks';
import { ConditionalWrapper } from '../../../../utils/conditional-wrapper';
import { useIsAdminCreateSessionPage, useIsSessionPage } from '../../../../hooks/path.hooks';
import NavigationToggle from '../../marketing-page/navigation-v2/navigation-toggle';
import { ButtonIcon, ControlButton } from '../../session/session-stream/video/controls';
import { linker } from '../../hooks/use-route-map';
import { OptionalComponent } from '../../../../utils/optional-component';
import images from '../../../../images';
import { ThemeContext } from 'components/live-event/theme-context';
import { EPaletteModes } from 'types/theme-packs';
const AddToCalendarModal = lazy(() => import('../agenda/add-to-calendar-modal/add-to-calendar-modal'));

import './main-event-banner-breakout.scss';

const withinOneHour = (date: number): boolean => {
	const HOUR = 1000 * 60 * 60;
	const now = Date.now();
	const anHourFromStart = date - HOUR;
	// if now is greater than one hour from start time and now us less than start time
	return now > anHourFromStart && now < date;
};

interface MainEventBannerBreakoutProps {
	module?: PageModule;
	template: string;
	session: Session;
	isEditor?: boolean;
	hideMobileHeader?: boolean;
	useSessionThumbnail?: boolean;
	hideCountdown?: boolean;
	renderGoogleMeet?: () => JSX.Element;
	googleMeetBreakoutsRef?: React.MutableRefObject<HTMLDivElement | null>;
	googleMeetJoined?: string | null;
	fullScreenMode?: boolean;
	isOnHomepage?: boolean;
}

const MainEventBannerBreakout: React.FC<MainEventBannerBreakoutProps> = ({
	module,
	template,
	session,
	hideMobileHeader = false,
	useSessionThumbnail = false,
	hideCountdown = false,
	renderGoogleMeet,
	googleMeetBreakoutsRef,
	googleMeetJoined,
	fullScreenMode,
	isEditor,
	isOnHomepage = false
}) => {
	const { isBreakoutSessionHost, closeBreakoutSession } = useBreakoutRoom();
	const [openAddToCalendar, setOpenAddToCalendar] = useState(false);
	const [openEndRoomsModal, setOpenEndRoomsModal] = useState(false);
	const [renderStatus, setRenderStatus] = useState<'live' | 'waiting-room' | 'not-live'>('not-live');

	const closingAllRooms = useTypedSelector(state => state.BreakoutRoomsReducer.closing_all_rooms);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const editorSize = useTypedSelector(state => state.CreateEventReducer.editorSize);

	const event = eventBundle || workingEvent;
	const disableSessionThumbnails = event?.settings?.disable_session_thumbnails === true;
	const streamPlaceholderBGColor = session?.layout?.styling_overrides?.content?.stream_placeholder_background_color;

	const useZoom = !!session.breakout_session?.use_zoom;
	const noZoomEnabledRooms = useZoom && !session.breakout_rooms?.some(room => room.video_settings?.zoom.enabled);
	const allRoomsClosed = !session.breakout_rooms?.some(room => room.closed === false);
	const isCloseAllDisabled = closingAllRooms || allRoomsClosed || noZoomEnabledRooms;

	const history = useHistory();
	const { language }: ParamsProps = useParams();
	// const { /* content, */ styling_overrides } = module;
	// const { image, originalImage } = content;
	const layout_type = module?.content?.layout_type || MainEventBannerLayoutTypesClassic.standard;
	const { t } = useTranslate('homepage');

	const { isLessThan1024 } = useScreenMediaQuery();

	const isLive = useCallback(() => checkIsLive(session), [session]);

	const waitingRoom = session?.timestamp ? withinOneHour(session.timestamp) : false;

	// const stylingOverrides = getStylingOverrides(styling_overrides);

	const isDesktop = editorSize === 'desktop';

	const HeaderStyleOverrides = getSessionHeaderStyleOverrides(session, template, isDesktop);

	const isSingleSessionNoHomepage = event?.sessions?.length === 1 && !shouldDisplayHomepage(event);
	const isSessionDetailsAboveTheFoldV2 = useIsAboveTheFold(session);

	const isNewModuleGrouping = useIsNewModuleGrouping();

	// dateCountdown has a useCallback in it, so we cannot call it inside any of the render methods
	// or it will crash. We need to call it before any "return" statements
	const liveIn = session?.timestamp ? dateCountdown(session.timestamp) : null;

	// check every 5 seconds the status of the session so we can update the banner in real time
	const timeout = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		const runTimer = () => {
			timeout.current = setTimeout(() => {
				if (timeout.current) {
					clearTimeout(timeout.current);
				}
				const isWaitingRoom = session?.timestamp ? withinOneHour(session.timestamp) : false;
				if (isWaitingRoom) {
					setRenderStatus('waiting-room');
				} else {
					if (isLive()) {
						setRenderStatus('live');
					} else {
						setRenderStatus('not-live');
					}
				}
				runTimer();
			}, 5000); // five seconds because I don't want this function to run too much
		};
		runTimer();

		return () => {
			if (timeout?.current) {
				clearTimeout(timeout.current);
			}
		};
	}, [timeout, isLive, waitingRoom, session.timestamp]);

	const showAddToCalBtn = event?.settings?.show_add_to_cal_btn;
	const showAddToCalendarButton = useMemo(() => {
		if (!session) return false;
		return displayAddToCalendarButton(session, showAddToCalBtn);
	}, [session, showAddToCalBtn]);

	const openAddToCalendarModal = useCallback(() => {
		setOpenAddToCalendar(true);
	}, []);

	const closeAddToCalendar = useCallback(() => {
		setOpenAddToCalendar(false);
	}, []);

	const renderNotLive = () => {
		if (renderStatus !== 'not-live' || waitingRoom || isLive() || !session.timestamp) return null;
		return (
			<div className="breakout-session-add-calendar">
				<div className="breakout-session-add-calendar-content">
					<TypographyItem className="breakout-session-add-calendar-title evt-heading-4" tagName="h2">
						{liveIn}
					</TypographyItem>
					<TypographyItem
						className={classNames('breakout-session-add-calendar-subtitle')}
						tagName="time"
					>
						{niceDateTimeTz(session.timestamp)}
					</TypographyItem>
				</div>
				{showAddToCalendarButton ? (
					<Button
						classButton={"lemonade"}
						template={template}
						onClick={openAddToCalendarModal}
					>
						<span style={{ marginRight: '8px' }}>{t("ADD")}</span>
						<Icon name={ICONS.CALENDAR_ADD} color="" size={22} />
					</Button>
				) : null}

				{eventBundle && (
					<Suspense fallback="">
						<AddToCalendarModal
							session={session}
							eventName={eventBundle.name}
							open={openAddToCalendar}
							close={closeAddToCalendar}
							language={language}
							isSingleSessionNoHomepage={isSingleSessionNoHomepage}
						/>
					</Suspense>
				)}
			</div>
		);
	};

	const renderWaitingRoom = () => {
		if (renderStatus !== 'waiting-room' || !waitingRoom || !session.timestamp || hideCountdown) return null;
		return (
			<Countdown date={new Date(session.timestamp)} template={template} />
		);
	};

	const isOnDemand = session.session_type === SessionTypesEnum.onDemand;
	const isBroadcast = session.session_type === SessionTypesEnum.broadcast;
	const isBreakoutRooms = session.session_type === SessionTypesEnum.breakoutRooms;
	const isFireside = session.session_type === SessionTypesEnum.fireside;

	const tagText = (): string => {
		if (isOnDemand) {
			return t("On Demand");
		} else if (isBroadcast) {
			return t("Broadcast");
		} else if (isBreakoutRooms) {
			return t("Breakout Rooms");
		} else if (isFireside) {
			return t("Fireside");
		} else {
			return "";
		}
	};

	const cardBackgroundImage = (image: string | undefined | null) => {
		// if thumbnails disabled, return nothing
		if (disableSessionThumbnails) {
			return '';
		}


		// Use image if the user uploaded one for the room. If not, use the default template image background
		if (image) {
			return `linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.5)), url(${resizedImage(image, { width: 1920 })})`;
		}

		if (template === TemplateNames.Limelight) {
			return `linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.5)), url(${images.LimelightBanner})`;
		}

		return `linear-gradient(rgba(0,0,0,0.0), rgba(0,0,0,0.5)), url(${getDefaultAgendaImage(template, 0)})`;
	};

	const handleCloseEndRoomModal = () => {
		setOpenEndRoomsModal(false);
	};

	const _closeBreakoutSession = () => {
		closeBreakoutSession(session.session, undefined);
		handleCloseEndRoomModal();
	};

	const handleEndAllRooms = () => {
		setOpenEndRoomsModal(true);
	};

	const isNotDesktop = editorSize !== EditorSizes.desktop;
	const sessionThumbnail = getLiveSessionBackground(session);
	const isSessionPage = useIsSessionPage();
	const isAdminSessionPage = useIsAdminCreateSessionPage();

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	if (!session || !event) return null;
	return (
		<ConditionalWrapper
			condition={isSessionDetailsAboveTheFoldV2 && (isSessionPage || isAdminSessionPage)}
			wrapper={children =>
				<div className={classNames(
					'breakout-session-above-the-fold',
					{ 'dark-mode': isDarkMode }
				)}>{children}</div>}
		>
			<>
				{renderGoogleMeet && renderGoogleMeet()}

				{!googleMeetJoined && (
					<section
						className={
							classNames(
								'breakout-session-banner',
								`breakout-session-banner-${layout_type}`,
								template,
								editorSize,
								{
									'no-image': disableSessionThumbnails
								}
							)}
						style={{
							...(disableSessionThumbnails ? {} : {
								backgroundImage: useSessionThumbnail && sessionThumbnail
									? `url(${sessionThumbnail})`
									: cardBackgroundImage(session?.breakout_session?.banner_image)
							}),
							...(disableSessionThumbnails && streamPlaceholderBGColor?.length ? {
								backgroundColor: streamPlaceholderBGColor[0]
							} : {})
						}}
					>
						<div className={classNames("breakout-session-banner-container", { hiddenHeaderMargin: hideMobileHeader && (isLessThan1024 || isNotDesktop) })}>
							<div className="breakout-session-banner-tags">
								<Tag
									classNameTag={classNames('tag-container-type')}
									text={tagText()}
								/>
								{isLive() || renderStatus === 'live' ? (
									<Tag
										classNameTag={classNames('tag-container-live')}
										text={t("live")}
									/>
								) : null}
							</div>
							<TypographyItem
								className={classNames(
									"breakout-session-banner-title evt-heading-2",
									{
										'light-mode': !isDarkMode
									}
								)}
								tagName="h1"
							>
								{session.title?.[language] || session.title.base}
							</TypographyItem>
							{renderWaitingRoom()}
							{renderNotLive()}
							{isBreakoutRooms
								&& isBreakoutSessionHost(session.session)
								&& !session.breakout_session?.use_google_meet
								&& (
									<button
										disabled={isCloseAllDisabled}
										className='breakouts-room-action' onClick={handleEndAllRooms}
									>
										<span>{closingAllRooms ? <WaitingIndicator /> : t("End All Rooms")}</span>
									</button>
								)}
						</div>
						<ModalComponent
							className={"breakout-end-rooms-modal"}
							open={openEndRoomsModal}
							onRequestClose={handleCloseEndRoomModal}
							cancellable
							closeable
							isAdmin={false}
							title={t("End All Rooms", "End All Rooms")}
							footer={(
								<>
									<button onClick={handleCloseEndRoomModal}>Cancel</button>
									<button onClick={_closeBreakoutSession} className="lemonade">Yes</button>
								</>
							)}
						>
							<div>
								<TypographyItem>{t("Are you sure you want to end all rooms?")}</TypographyItem>
								<TypographyItem>{t("This action cannot be undone!")}</TypographyItem>
							</div>
						</ModalComponent>
					</section>
				)}

				{/* Always display the session header, it dislays the menu/back  */}
				{(!fullScreenMode && !isNewModuleGrouping) ? (
					<SessionHeader
						session={session}
						eventBundle={event}
						isEditor={!!workingEvent}
						editorSize={editorSize}
						styleOverrides={HeaderStyleOverrides}
						hideProfile={(!isLessThan1024 || isSingleSessionNoHomepage) && editorSize === "desktop"}
						hideNavItems={(!isLessThan1024 || isSingleSessionNoHomepage) && editorSize === "desktop"}
					/>
				) : (
					<OptionalComponent display={!isOnHomepage}>
						<div className="new-nav-buttons" style={{ zIndex: 101 }}>
							<NavigationToggle
								hideToggle={false}
								isDesktop={isDesktop}
								forceOn={true}
							/>
							<OptionalComponent display={!isSingleSessionNoHomepage}>
								<ControlButton
									onClick={() => {
										history.push(linker.getLink('Home'));
									}}
								>
									{ButtonIcon(ICONS.ARROW_LEFT_SHADOW)}
								</ControlButton>
							</OptionalComponent>
						</div>
					</OptionalComponent>
				)}


				{isSessionDetailsAboveTheFoldV2 && (isSessionPage || isAdminSessionPage) && !isLessThan1024 && // !isMobileEditor && // && isChatPanelOpen
					<Suspense fallback="">
						<AboveTheFoldContent
							session={session}
							language={language}
							googleMeetBreakoutsRef={googleMeetBreakoutsRef}
							chatClosed={false}
							isEditor={!!isEditor}
						/>
					</Suspense>
				}

			</>
		</ConditionalWrapper>
	);
};

export default MainEventBannerBreakout;
