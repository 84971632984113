import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { BLAdmin } from '../../../types/working-model';
import Icon, { COLORS, ICONS } from '../../general-ui/icon';
import { signOut } from '../../../store/actions/authentication';
import { PATHNAMES } from '../../../utils/admin-routing-utils';
import { removeStorageItem } from '../../../utils/local-storage';
import { StoredTokens } from '../../../store/reducers/authentication';

import './profile-details.scss';
import { useTypedSelector } from 'store/reducers/use-typed-selector';

interface Props {
	open: boolean;
	onClose?: () => void;
	style?: React.CSSProperties;
	account: BLAdmin;
	position?: string;
}

export function ProfileDetails({
	open,
	onClose,
	style = {},
	account,
	position
}: Props): JSX.Element {
	const [isOpen, setIsOpen] = useState(open);
	const selector = useRef<HTMLDivElement | null>(null);
	const signingOut = useTypedSelector((state) => state.AuthReducer.signingOut);

	const dispatch = useDispatch();

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	useEffect(() => {
		function checkClick(e: any) {
			const path = e.path || e.composedPath();
			for (const item of path) {
				if (selector.current === item) return;
			}
			setIsOpen(false);
			onClose && onClose();
		}

		if (isOpen) {
			window.addEventListener('click', checkClick);
		}

		return () => window.removeEventListener('click', checkClick);
	}, [isOpen, onClose]);

	function handleLogOut() {
		// THESE IS TEMPORARY. We are going to include the stored token when we have
		// two extra security measures in place
		removeStorageItem(StoredTokens.storedTwoFactor);
		removeStorageItem('currentChannel');
		removeStorageItem('admin_SSO_access');
		removeStorageItem('sso_admin_auth');
		removeStorageItem('auth_email');

		dispatch(signOut());

		if (window.zE?.hide) {
			window.zE.hide(); // hide the Zendesk support widget
		}
	}

	return (
		<div
			className={`profile-details-wrapper ${isOpen ? "open" : ""} ${position === 'top' ? "top" : ""}`}
			style={{ ...style, display: isOpen ? "flex" : "none" }}
			ref={selector}
		>
			<ul>
				<div className="profile-details-top">
					<li className="profile-details-header">
						<Link to={PATHNAMES.AdminProfile}>
							<div className="profile-details-header-left">
								<span className="name">{account.profile?.first_name} {account.profile?.last_name}</span>
								<span>See your profile</span>
							</div>
							<div className="profile-details-header-right">
								<Icon name={ICONS.KEYBOARD_ARROW_RIGHT} size={12} color={COLORS.WHITE} />
							</div>
						</Link>
					</li>
				</div>
				<li>
					<a href={PATHNAMES.Support} target="__blank" rel="noopener">
						<Icon name={ICONS.SUPPORT} size={16} color={COLORS.WHITE} />
						<span>{"Help & Support"}</span>
					</a>
				</li>
				<li>
					<button className="no-style" onClick={handleLogOut} disabled={signingOut}>
						<Icon name={ICONS.LOG_OUT} size={16} color={COLORS.WHITE} />
						{"Log Out"}
					</button>
				</li>
			</ul>
		</div >
	);
}
