import { PageModuleType, SessionPanelLayoutsTypes } from "./working-model";

type ModuleTabTypesMap = { [key in PageModuleType]?: SessionPanelLayoutsTypes };
type CustomTab = { custom_tab: SessionPanelLayoutsTypes };
type ModyleTabTypesMapWithCustom = {
	base: ModuleTabTypesMap & CustomTab;
	custom: ModuleTabTypesMap & CustomTab;
}

export const MODULE_TAB_TYPES: ModyleTabTypesMapWithCustom = {
	base: {
		[PageModuleType.speakers]: SessionPanelLayoutsTypes.Speakers,
		[PageModuleType.products]: SessionPanelLayoutsTypes.ExtraProducts,
		[PageModuleType.documents]: SessionPanelLayoutsTypes.ExtraResources,
		[PageModuleType.embed_widget]: SessionPanelLayoutsTypes.EmbedSection,
		[PageModuleType.survey]: SessionPanelLayoutsTypes.Engage,
		[PageModuleType.similar_sessions]: SessionPanelLayoutsTypes.ExtraSuggested,
		custom_tab: SessionPanelLayoutsTypes.ExtraCreate
	},
	custom: {
		[PageModuleType.speakers]: SessionPanelLayoutsTypes.CustomSpeakers,
		[PageModuleType.products]: SessionPanelLayoutsTypes.CustomProducts,
		[PageModuleType.documents]: SessionPanelLayoutsTypes.CustomResources,
		[PageModuleType.embed_widget]: SessionPanelLayoutsTypes.CustomEmbed,
		[PageModuleType.survey]: SessionPanelLayoutsTypes.Custom, // doesn't really exist
		[PageModuleType.similar_sessions]: SessionPanelLayoutsTypes.CustomSuggested,
		[PageModuleType.feed]: SessionPanelLayoutsTypes.CustomCreate,
		custom_tab: SessionPanelLayoutsTypes.CustomCreate
	}
};

export const MODULE_TAB_NAMES: { [key in PageModuleType]?: string } = {
	[PageModuleType.speakers]: "Speakers",
	[PageModuleType.products]: "Products",
	[PageModuleType.documents]: "Resources",
	[PageModuleType.embed_widget]: "Embed Section",
	[PageModuleType.similar_sessions]: "Suggested",
};