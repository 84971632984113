import { useMemo } from "react";
import {
	useGetNavItemsCount
} from '../components/live-event/marketing-page/navigation-v2/hooks/navigation.hooks';
import { ENavbarTypes, CustomPage } from "../types/working-model";
import { useGetEvent } from "./session.hooks";
import { useNavbarV2 } from "./channel.hooks";

const newNavbarTypes = [ENavbarTypes.Horizontal, ENavbarTypes.Vertical];

export const useIsNewNavigation = (): boolean => {
	const event = useGetEvent();
	const navbarFeatureFlag = useNavbarV2();

	const navbarType = event?.settings.event_navbar?.navbarType;

	return !!(navbarType && newNavbarTypes.includes(navbarType) && navbarFeatureFlag);
};

type CustomNavItem = (params: {
	customNavItems?: CustomPage[],
	allNavItemsCount: number
	generalNavItemsCount: number
}) => { showItemsCount: CustomPage[], hideItemsCount: CustomPage[], isOthersButtonHidden: boolean };

export const useGetItemsCount: CustomNavItem = ({ customNavItems, allNavItemsCount, generalNavItemsCount }) => {
	const showNewNav = useIsNewNavigation();
	const itemsCount = useGetNavItemsCount();

	const sliceItemsCount = allNavItemsCount > itemsCount ? itemsCount - generalNavItemsCount : 0;
	const isOthersButtonHidden = showNewNav && allNavItemsCount <= itemsCount;

	return useMemo(() => {
		const showItemsCount = customNavItems?.slice(0, sliceItemsCount) || [];
		const hideItemsCount = customNavItems?.slice(sliceItemsCount) || [];

		return { showItemsCount, hideItemsCount, isOthersButtonHidden };
	}, [customNavItems, isOthersButtonHidden, sliceItemsCount]);
};