import { matchPath } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { DebugDefaultRouteMap, DefaultRouteMap } from './use-route-map';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { BrandliveEvent, SessionPreview } from '../../../types/working-model';
import { PATHNAMES } from '../../../utils/admin-routing-utils';

const getLandingNamespace = (eventBundle: BrandliveEvent) => {
	const hasLandingPage = eventBundle.homepage && (eventBundle.settings.display_landing_page || eventBundle.settings.display_homepage) !== false;
	const singleSession = !hasLandingPage && eventBundle.sessionsPreviews?.length === 1;
	if (singleSession) {
		const session = (eventBundle.sessionsPreviews as SessionPreview[])[0].session;
		return `session.${session}`;
	} else if (hasLandingPage) {
		return 'landing';
	} else {
		return 'home';
	}
};

const getSessionNamespace = (eventBundle: BrandliveEvent, sessionUuid: string) => {
	const session = eventBundle?.sessions?.find((session) => session.uuid === sessionUuid);
	if (session) {
		return `session.${session.session}`;
	} else {
		return 'home';
	}
};

const getCustompageNamespace = (eventBundle: BrandliveEvent, customPath: string) => {
	const custom = eventBundle?.custom_pages?.find(page => page.path === customPath);
	if (custom) {
		return `custom_page.${custom.custom_page}`;
	} else {
		return 'home';
	}
};

const getPostRegisterPreview = (eventBundle: BrandliveEvent) => {
	const hasHomepage = !!eventBundle.homepage;
	const singleSession = eventBundle.sessions?.length === 1;

	if (singleSession) {
		if (!hasHomepage) {
			return `session.${eventBundle.sessions[0].session}`;
		}
	}

	return 'home';
};

const CustomPage = [
	PATHNAMES.Event.CustomPage,
	DefaultRouteMap.CustomPage,
	DebugDefaultRouteMap.CustomPage,
];

const SessionPage = [
	PATHNAMES.Session.Session,
	DefaultRouteMap.Session,
	DebugDefaultRouteMap.Session,
];

const HomePage = [
	PATHNAMES.Event.Event,
	PATHNAMES.Event.HomePath,
	DebugDefaultRouteMap.Home,
	DefaultRouteMap.Home,
	'/admin/events/create/:id/event/:language/sessions/event-agenda', // hardcoded value to show in admin console
	DefaultRouteMap.Sessions,
	DebugDefaultRouteMap.Sessions
];

const LandingPage = [
	PATHNAMES.Event.Landing,
	DefaultRouteMap.Landing,
	DebugDefaultRouteMap.Landing,
];

const RegistrationPage = [
	PATHNAMES.Event.Registration,
	DebugDefaultRouteMap.Registration,
	DefaultRouteMap.Registration,
];

const CheckPages = [
	...CustomPage,
	...SessionPage,
	...RegistrationPage,
	...HomePage,
	...LandingPage
];

const useLocationCustom = () => {
	const [pathname, setPathname] = useState(window.location.pathname);

	useEffect(() => {
		const listener = () => {
			setPathname(window.location.pathname);
		};

		window.addEventListener('locationchange', listener);

		return () => {
			window.removeEventListener('locationchange', listener);
		};
	}, []);

	return pathname;
};

const useTranslationRoute = (): string => {
	try {
		const location = useLocationCustom();
		const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
		const workingEventBundle = useTypedSelector(state => state.CreateEventReducer.workingEvent);
		const bundle = workingEventBundle ?? eventBundle;
		return useMemo(() => {
			if (!bundle) return 'homepage';

			const match = matchPath(location, CheckPages);

			if (match) {
				const useLanding = LandingPage.includes(match.path);

				if (useLanding) {
					return getLandingNamespace(bundle);
				}

				const useSession = SessionPage.includes(match.path);

				if (useSession) {
					const params = match.params as { sessionUuid: string, session: string };
					const uuid = params.sessionUuid ?? params.session;
					return getSessionNamespace(bundle, uuid);
				}

				const useCustom = CustomPage.includes(match.path);

				if (useCustom) {
					return getCustompageNamespace(bundle, (match.params as { customPath: string }).customPath);
				}

				const useRegistration = RegistrationPage.includes(match.path);
				if (useRegistration) {
					return 'registration';
				}

				if (match.path === PATHNAMES.Event.HomePath) {
					return getPostRegisterPreview(bundle);
				}

				return 'home';
			} else {
				return 'home';
			}
		}, [bundle, location]);
	} catch (e) {
		console.error(e);
		return 'homepage';
	}
};

export default useTranslationRoute;