import { useRef, useEffect } from 'react';
import hexToRGB from '../../../utils/hexToRGB';
import './buffering-indicator.scss';

const BufferingIndicator = ({ fill = '#FFFFFF', size = 64 }: { fill?: string, size?: number }): JSX.Element => {
	const wrapper = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isNaN(parseInt(fill.replace('#', '0x'), 16))) {
			console.warn(`Invalid fill color: ${fill}\n Must be 6 digit HEX`);
			wrapper.current?.setAttribute('style', `--buffering-fill: 255, 0, 255`);
		} else {
			const rgbStr = hexToRGB(fill)?.replace(/[^\d ,]/g, ' ').trim();
			wrapper.current?.setAttribute('style', `--buffering-fill: ${rgbStr}`);
		}
	}, [fill]);

	return (
		<div className="buffering-indicator-wrapper" ref={wrapper} style={{ height: size, width: size }}>
			<svg className="buffering-indicator" width={size} height={size} viewBox={`0 0 64 64`} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path id="one" d="M32 4C28.6 4 26 6.6 26 10C26 13.4 28.6 16 32 16C35.4 16 38 13.4 38 10C38 6.6 35.4 4 32 4Z" fill={fill} />
				<path id="two" d="M20.6004 12.2C18.4004 9.99999 14.4004 9.99999 12.2004 12.2C9.80039 14.6 9.80039 18.4 12.2004 20.6C13.4004 21.8 14.8004 22.4 16.4004 22.4C18.0004 22.4 19.6004 21.8 20.6004 20.6C23.0004 18.4 23.0004 14.6 20.6004 12.2Z" fill={fill} />
				<path id="three" d="M16 32C16 28.6 13.4 26 10 26C6.6 26 4 28.6 4 32C4 35.4 6.6 38 10 38C13.4 38 16 35.4 16 32Z" fill={fill} />
				<path id="four" d="M12.2004 43.4C9.80039 45.8 9.80039 49.6 12.2004 51.8C13.4004 53 14.8004 53.6 16.4004 53.6C18.0004 53.6 19.6004 53 20.6004 51.8C23.0004 49.4 23.0004 45.6 20.6004 43.4C18.4004 41 14.4004 41 12.2004 43.4Z" fill={fill} />
				<path id="five" d="M32 48C28.6 48 26 50.6 26 54C26 57.4 28.6 60 32 60C35.4 60 38 57.4 38 54C38 50.6 35.4 48 32 48Z" fill={fill} />
				<path id="six" d="M43.3996 43.4C40.9996 45.8 40.9996 49.6 43.3996 51.8C44.5996 53 45.9996 53.6 47.5996 53.6C49.1996 53.6 50.7996 53 51.7996 51.8C54.1996 49.4 54.1996 45.6 51.7996 43.4C49.5996 41 45.5996 41 43.3996 43.4Z" fill={fill} />
				<path id="seven" d="M54 26C50.6 26 48 28.6 48 32C48 35.4 50.6 38 54 38C57.4 38 60 35.4 60 32C60 28.6 57.4 26 54 26Z" fill={fill} />
				<path id="eight" d="M48 10C44.6 10 42 12.6 42 16C42 19.4 44.6 22 48 22C51.4 22 54 19.4 54 16C54 12.6 51.4 10 48 10Z" fill={fill} />
			</svg>
		</div>
	);
};

export default BufferingIndicator;