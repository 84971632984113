import { useEffect, useState } from "react";
import { AllLayoutTypes, maxCount } from "../../../../../../../types/template-layouts";
import { ModuleMap, PageModule, PageModuleType } from "../../../../../../../types/working-model";
import Select from '../../../../../../general-ui/select/select';
import './module-display-options.scss';
import RoundedSpeakerImages from "./rounded-speaker-images";

interface IModuleDisplayOptionsProps {
	module?: PageModule;
	pageModuleType: PageModuleType;
	setSelectedDisplayOptions?: React.Dispatch<React.SetStateAction<number | null>>;
	selectedDisplayOption?: number | null;
	selectedLayout?: AllLayoutTypes | null;
	speakerImageRadius?: boolean | null;
	setSpeakerImageRadius?: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const ModuleDisplayOptions = (props: IModuleDisplayOptionsProps) => {
	const {
		module,
		pageModuleType,
		setSelectedDisplayOptions,
		selectedDisplayOption,
		selectedLayout,
		speakerImageRadius,
		setSpeakerImageRadius,
	} = props;

	const layout = selectedLayout ?? selectedDisplayOption ?? module?.content?.layout_type ?? 'standard';

	const initCount: number = module?.content?.display_options ?? (module ? maxCount(module.type, layout) : 0);
	const [count, setCount] = useState(initCount);

	const initImageRadius = !!module?.content?.speaker_image_radius;
	const [hasImageRadius, setHasImageRadius] = useState(initImageRadius);

	useEffect(() => {
		// initialize the parent value
		if (selectedDisplayOption === null) {
			setSelectedDisplayOptions?.(initCount);
		}
	}, [initCount, selectedDisplayOption, setSelectedDisplayOptions]);

	useEffect(() => {
		// initialize the parent value for image radius
		if (speakerImageRadius === null) {
			setSpeakerImageRadius?.(initImageRadius);
		}
	}, [speakerImageRadius, setSpeakerImageRadius, initImageRadius]);

	const handleImageRadiusChange = (isOn: boolean) => {
		setHasImageRadius(isOn);
		setSpeakerImageRadius?.(isOn);
	};

	if (!module) return null;
	return (
		<>
			<div className="module-display-options">
				<div className="module-display-options-label">
					Displayed {ModuleMap[pageModuleType]}:
				</div>
				<div className="module-display-options-select-wrapper">
					<Select
						containerClass="module-display-options-select"
						options={[
							{ label: 'All', value: 0 },
							...Array.from({ length: 12 }).map((_, idx) => ({
								label: idx + 1,
								value: idx + 1,
							}))
						]}
						selected={count}
						onChange={(value => {
							setSelectedDisplayOptions?.(value);
							setCount(value);
						})}
						ignorePositioning
						hideEmptyOption
					/>
				</div>
			</div>
			<RoundedSpeakerImages
				hasImageRadius={hasImageRadius}
				onChange={handleImageRadiusChange}
				layout={layout}
			/>
		</>
	);
};

export default ModuleDisplayOptions;
