import { CSSProperties } from "react";
import { ICONS } from "@general-ui/icon";
import { Module } from "./working-model";

// https://www.w3.org/WAI/WCAG21/Understanding/contrast-enhanced.html
export const AAA_COTNRAST_RATIO = 7;

export enum EColorPackchannels {
	/** 0 (zero) is the channel number for our default/base color packs */ // intentionally assigning zero to be explicit
	base_colors = 0,
}

export enum SafeFonts {
	Arial = 'Arial',
	CourierNew = 'Courier New',
	Georgia = 'Georgia',
	Helvetica = 'Helvetica',
	Verdana = 'Verdana',
	Tahoma = 'Tahoma',
	TimesNewRoman = 'Times New Roman',
	TrebuchetMS = 'Trebuchet MS',
}

export interface FontPack extends Module {
	title: string;
	description: string;
	heading_url: string;
	heading_name: string;
	body_url: string;
	body_name: string;
	font_pack: number;
	// New parameters must be optional or nullable
}

export interface CreateFontPack {
	title: string;
	description: string;
	heading_url: string;
	heading_name: string;
	body_url: string;
	body_name: string;
	// New parameters must be optional or nullable
}

/** formerly Color */
export interface ThemePack extends Module {
	title: string;
	color_pack: number;
	colors: ColorOptions;
	creation_type?: string | null;
	font_pack?: FontPack | null;
	custom_styles?: ICustomDesignSettings;
	updated_at?: Date | null;
	/** theme_mode is the "default" theme mode for a pack. Not to be confused with the event settings color_theme which is the event will use */
	theme_mode?: EPaletteModes;
	// New parameters must be optional or nullable
}

/** formerly UpdateColorPack */
export interface UpdateThemePack extends Module {
	title: string;
	color_pack: number;
	colors: ColorOptions;
	creation_type?: string | null;
	font_pack?: number;
	custom_styles?: ICustomDesignSettings;
	updated_at?: Date | null;
	theme_mode?: EPaletteModes;
}

/** formerly CreateColorPack */
export interface CreateThemePack {
	color_pack?: number;
	channel?: number;
	title: string;
	colors: ColorOptions;
	creation_type?: string | null;
	custom_styles?: ICustomDesignSettings;
	theme_mode?: EPaletteModes;
	font_pack?: number;
	// New parameters must be optional or nullable
}

export enum ColorKeys { //Dont use this to type incoming Color Keys because they may include any number of custom Colors
	backgroundColor = 'backgroundColor',
	bodyTextColor = 'bodyTextColor',
	containerColor = 'containerColor',
	accentColor = 'accentColor',
	headingTextColor = 'headingTextColor',
	secondaryBackgroundColor = 'secondaryBackgroundColor',
	secondaryAccentColor = 'secondaryAccentColor',
	darkModeColor = 'darkModeColor',
	lightModeColor = 'lightModeColor',
	buttonTextColor = 'buttonTextColor',
}

/** colors are stored as [string, number] where idx 0 is the hex and idx 1 is the opacity */
export enum ColorValueIndexes {
	hex = 0,
	opacity = 1
}

export type hex = string;
export type opacity = number;
export type colorVariableName = string;
export type TColorItemArray = [hex, opacity];

export type TColorVariantsExplicit = {
	backgroundColor: [hex, opacity];
	bodyTextColor: [hex, opacity];
	containerColor: [hex, opacity];
	accentColor: [hex, opacity];
	headingTextColor: [hex, opacity];
	secondaryBackgroundColor: [hex, opacity];
	secondaryAccentColor: [hex, opacity];
}

/** buttonTextColor is optional because it's a new addition which may not exist */
export type TColorVariantsExplicitOptional = {
	buttonTextColor: [string, number];
}

export type CustomColorPrefix = 'customColor';
export type CustomKeys = `${CustomColorPrefix}${number}`;
export type TCustomColors = {
	[key in CustomKeys]: [string, number];
}

export type IColorVariants = TColorVariantsExplicit & Partial<TColorVariantsExplicitOptional> & Partial<TCustomColors>;

export type TColorVariantKeys = keyof IColorVariants;

export interface ColorOptions extends IColorVariants {
	[EPaletteModes.Dark]?: IColorVariants;
	[EPaletteModes.Light]?: IColorVariants;
}

export type TColorOptionsKeys = keyof ColorOptions;

export type TEditableColorPack = CreateThemePack | ThemePack;

export enum EPaletteModes {
	Light = "light",
	Dark = "dark",
}

export interface IWorkingColorPack {
	title?: string;
	colorTheme?: EPaletteModes;
	colorPack?: ColorOptions | null;
}

export enum EColorVariablesV2 {
	primary = "primary",
	accents = "accents",
	buttonText = "buttonText",
	headingText = "headingText",
	bodyText = "bodyText",
	banner = "banner",
	background = "background",
}

export const ColorPaletteTypeDisplayNames: Record<string, string> = {
	complementaryPalette: "Blend",
	analogousPalette: "Harmony",
	monochromaticPalette: "Unity",
	triadicPalette: "Flare",
};

export const defaultColors: ColorOptions = {
	accentColor: ["#3456ff", 1],
	bodyTextColor: ["#081543", 1],
	buttonTextColor: ["#ffffff", 1],
	containerColor: ["#ffffff", 1],
	backgroundColor: ["#f5f7ff", 1],
	headingTextColor: ["#ffffff", 1],
	secondaryAccentColor: ["#ff8164", 1],
	secondaryBackgroundColor: ["#0e2b89", 1],
	[EPaletteModes.Light]: {
		accentColor: ["#3456ff", 1],
		bodyTextColor: ["#081543", 1],
		buttonTextColor: ["#ffffff", 1],
		containerColor: ["#ffffff", 1],
		backgroundColor: ["#f5f7ff", 1],
		headingTextColor: ["#ffffff", 1],
		secondaryAccentColor: ["#ff8164", 1],
		secondaryBackgroundColor: ["#0e2b89", 1],
	},
	[EPaletteModes.Dark]: {
		accentColor: ["#3456ff", 1],
		bodyTextColor: ["#ffffff", 1],
		containerColor: ["#26242b", 1],
		backgroundColor: ["#1a1a1f", 1],
		headingTextColor: ["#ffffff", 1],
		secondaryAccentColor: ["#ff8164", 1],
		secondaryBackgroundColor: ["#26242b", 1],
		buttonTextColor: ["#ffffff", 1],
	},
};

export interface IAutoAdjustableColor {
	color: IPanelThemeColorMeta;
	adjustedValuesToUse: { light: string; dark: string };
}

export interface IPanelThemeColorMeta {
	id: EColorVariablesV2;
	title: string;
	description: string;
	mappedV1Value: TColorVariantKeys;
	valuesToAutoAdjust?: IAutoAdjustableColor[];
}

export const PANEL_THEME_COLOR_CONTENT: IPanelThemeColorMeta[] = [
	{
		id: EColorVariablesV2.primary,
		title: "Primary",
		description: "Primary actions and buttons, highlights",
		mappedV1Value: "accentColor",
	},
	{
		id: EColorVariablesV2.accents,
		title: "Accent",
		description: "Accents, captions",
		mappedV1Value: "secondaryAccentColor",
	},
	{
		id: EColorVariablesV2.banner,
		title: "Banner",
		description: "Banners and containers",
		mappedV1Value: "secondaryBackgroundColor",
	},
	{
		id: EColorVariablesV2.background,
		title: "Background Color",
		description: "Background color",
		mappedV1Value: "backgroundColor",
	},
	{
		id: EColorVariablesV2.buttonText,
		title: "Button Text",
		description: "Text color for primary buttons",
		mappedV1Value: "buttonTextColor",
	},
	{
		id: EColorVariablesV2.headingText,
		title: "Heading Text",
		description: "Heading Text",
		mappedV1Value: "headingTextColor",
	},
	{
		id: EColorVariablesV2.bodyText,
		title: "Description Text",
		description: "Description Text",
		mappedV1Value: "bodyTextColor",
	},
];

export const PANEL_THEME_COLOR_CONTENT_MAP = Object.fromEntries(PANEL_THEME_COLOR_CONTENT.map(color => [color.id, color]));

export const COLOR_VARIABLES_LIST_V1: { name: TColorVariantKeys; description: string; }[] = [
	{ name: "accentColor", description: "Accent Color" },
	{ name: "headingTextColor", description: "Heading Text Color" },
	{ name: "bodyTextColor", description: "Body Text Color" },
	{ name: "backgroundColor", description: "Background Color" },
	{ name: "secondaryAccentColor", description: "Secondary Background Color" },
	{ name: "secondaryBackgroundColor", description: "Secondary Accent Color" },
	{ name: "containerColor", description: "Container Color" },
];

export const COLOR_VARIABLES_LIST_V2: { name: TColorVariantKeys; description: string; }[] = [
	{ name: "accentColor", description: "Primary" },
	{ name: "secondaryAccentColor", description: "Accent" },
	{ name: "secondaryBackgroundColor", description: "Banner" },
	{ name: "backgroundColor", description: "Background Color" },
	{ name: "buttonTextColor", description: "Button Text" },
	{ name: "headingTextColor", description: "Heading Text" },
	{ name: "bodyTextColor", description: "Description Text" },
];

export const COLOR_VARIABLES_LIST_V2_MAP = COLOR_VARIABLES_LIST_V2.reduce((map: Record<string, string>, { name }) => {
	map[name] = name;
	return map;
}, {});

/** EDefaultColorPackIds to be used sparingly, and only in places where we do not want to load all the color packs */
export enum EDefaultColorPackIds {
	Limelight = 69
}

export interface IAutoGenerateColors {
	primary: [hex, opacity];
	accent: [hex, opacity];
	banner: [hex, opacity];
	headingText: [hex, opacity];
	bodyText: [hex, opacity];
}

export const COLOR_MODES = [
	{
		id: EPaletteModes.Light,
		name: "Light Mode",
		icon: ICONS.LIGHT_MODE,
	},
	{
		id: EPaletteModes.Dark,
		name: "Dark Mode",
		icon: ICONS.DARK_MODE,
	},
];

export enum ECustomDesignTypes {
	input = 'input',
	button = 'button',
	container = 'container',
	img = 'img',
	label = 'label'
}

export interface ICustomDesignItem {
	css: CSSProperties;
	use_custom: boolean;
	// New parameters must be optional or nullable
}

export interface ICustomDesignSettings {
	[ECustomDesignTypes.input]?: ICustomDesignItem;
	[ECustomDesignTypes.button]?: ICustomDesignItem;
	[ECustomDesignTypes.container]?: ICustomDesignItem;
	[ECustomDesignTypes.img]?: ICustomDesignItem;
	[ECustomDesignTypes.label]?: ICustomDesignItem;
}


export interface ISessionColors {
	[EPaletteModes.Dark]?: Partial<IColorVariants>;
	[EPaletteModes.Light]?: Partial<IColorVariants>;
}

export const BORDER_RADIUS_TOOLTIPS = {
	Inputs: "Field where text is entered",
	Buttons: "Important actions or triggers",
	Containers: "Interactive components",
	Images: "Photos or video stills",
	Labels: "Descriptive elements",
};
