import { Action, AnyAction, Dispatch } from "redux";
import { clearChatMessages, loadChatMessages, loadChatMessagesPage } from "../../../../store/actions/event/chat-actions";
import { useAppDispatch } from "store/reducers/use-typed-selector";
import { useEffect, useState } from "react";

export const cleanup = (dispatch: Dispatch<Action | AnyAction>) => {
	dispatch(clearChatMessages());
};

type LoadChatMessagesEffectProps = {
	dispatch: Dispatch<Action | AnyAction>,
	sessionUuid: string | undefined,
	isPreview: boolean,
	language: string,
	singleLanguage: boolean,
	blProfile?: number
}
export const loadChatMessagesEffect = ({
	dispatch,
	sessionUuid,
	isPreview,
	language,
	singleLanguage,
	blProfile
}: LoadChatMessagesEffectProps) => {
	cleanup(dispatch);

	if (sessionUuid && !isPreview) {
		dispatch(loadChatMessages(sessionUuid, singleLanguage ? 'all' : language, blProfile));
	}
};

interface FetchSessionChatProps {
	// registration: IRegistrationResponse | null;
	// token: string | null;
	language: string | null;
	sessionUuid?: string;
	cursor?: number;
	limit?: number;
}


export const useFetchSessionChat = ({
	language,
	sessionUuid
}: FetchSessionChatProps) => {
	const dispatch = useAppDispatch();
	const [cursor, loadChatBeforeId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (sessionUuid) {
			dispatch(loadChatMessagesPage({
				sessionUuid,
				lang: language,
				cursor,
				size: 100
			}));
		}
	}, [cursor, dispatch, language, sessionUuid]);

	return loadChatBeforeId;
};