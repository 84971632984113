import React, { useMemo } from "react";
import ActiveResourcesList from "../extras/resources/active-resources-list";
import { usePageModule } from "../hooks/panel.hooks";

import { extrasResourcesItems } from "./constants/empty-panel";
import EmptyStatePanel from "./empty-state-panel";
import { useParams } from "react-router";

export default function ExtrasResourcesPanel({ custom = false }: { custom?: boolean }): JSX.Element {
	const pageModule = usePageModule();
	const hasContent = pageModule?.modules?.length;

	const { customPath } = useParams<{ customPath?: string; }>();

	return hasContent ? (
		<ActiveResourcesList />
	) : (
		<EmptyStatePanel
			title="Add Files, Media or Related Content"
			description="Sections without content will not be visible to the audience"
			dropDownItems={extrasResourcesItems(!!customPath)}
		/>
	);
}