import { Action } from "../../../types/actions";
import { handle } from "redux-pack";
import { Speaker } from "../../../types/working-model";
import {
	CREATE_SPEAKER,
	GET_SPEAKERS,
	SAVE_SPEAKER,
	UPDATE_SPEAKER
} from "../../actions/admin/speakers";

export interface SpeakersState {
	speakers: Speaker[];
	loadingSpeakers: boolean;
	speakersError: string;
	savingSpeakers: boolean;
	savingSpeakersError: string;
	creatingSpeaker: boolean;
	creatingSpeakerError: string;
}

const initialState: SpeakersState = {
	creatingSpeaker: false,
	creatingSpeakerError: "",
	loadingSpeakers: false,
	savingSpeakers: false,
	savingSpeakersError: "",
	speakers: [],
	speakersError: "",
};

export default function SpeakersReducer(
	state: SpeakersState = initialState,
	action: Action
): SpeakersState {
	switch (action.type) {
		case GET_SPEAKERS: {
			return handle(state, action, {
				start: (state: SpeakersState) => ({ ...state, loadingSpeakers: true }),
				failure: (state: SpeakersState) => ({
					...state,
					speakersError: "Unable to load speakers."
				}),
				success: (state: SpeakersState) => ({
					...state,
					speakersError: "",
					speakers: action.payload
				}),
				finish: (state: SpeakersState) => ({ ...state, loadingSpeakers: false })
			});
		}
		case CREATE_SPEAKER: {
			return { ...state, speakers: [...state.speakers, action.payload] };
		}
		case UPDATE_SPEAKER: {
			return {
				...state,
				speakers: state.speakers.map((speaker: Speaker) => {
					if (speaker.speaker === action.payload.speaker) {
						return action.payload;
					}

					return speaker;
				})
			};
		}
		case SAVE_SPEAKER: {
			return handle(state, action, {
				start: (state: SpeakersState) => ({ ...state, savingSpeakers: true }),
				failure: (state: SpeakersState) => ({
					...state,
					savingSpeakersError: "Unable to save speaker"
				}),
				success: (state: SpeakersState) => ({
					...state,
					savingSpeakersError: "",
					speakers: state.speakers.map((speaker: Speaker) => {
						if (speaker.speaker === action.payload.speaker) {
							return action.payload;
						} else {
							return speaker;
						}
					})
				}),
				finish: (state: SpeakersState) => ({ ...state, savingSpeakers: false })
			});
		}
		default:
			return state;
	}
}
