import { isObject } from "underscore";

import {
	EPaletteModes,
	IColorVariants,
	defaultColors,
	ThemePack,
} from "types/theme-packs";

export const getColorOptionsFromColor = (color?: ThemePack | null, colorTheme = EPaletteModes.Light): IColorVariants => {
	const _colors = color?.colors;
	let colors = defaultColors[colorTheme || EPaletteModes.Light] as IColorVariants;

	// if colors ColorOptionsV2 do not exist, return the default color options
	if (!color || !isObject(_colors)) {
		return colors;
	}

	const { light, dark, ...topLevelColors } = _colors || {};

	if (!light && !dark) {
		// adding unknown conversion to avoid type error (in this case, we know better than TS)
		colors = topLevelColors;
	} else {
		colors = _colors[colorTheme] ?? _colors?.[EPaletteModes.Light] ?? colors;
	}

	return colors;
};
