import { ICONS } from "../components/general-ui/icon";

export const socialOptions = [
	{
		label: "Facebook",
		value: ICONS.FACEBOOK
	},
	{
		label: "X",
		value: ICONS.TWITTER // Keep this as Twitter for backwards compatibility
	},
	{
		label: "LinkedIn",
		value: ICONS.LINKEDIN
	},
	{
		label: "Instagram",
		value: ICONS.INSTAGRAM
	},
	{
		label: "YouTube",
		value: ICONS.YOUTUBE
	},
	{
		label: "TikTok",
		value: ICONS.TIKTOK
	},
	{
		label: "Website",
		value: ICONS.WEBSITE
	}
];