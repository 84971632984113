import { useContext, useEffect, useRef } from 'react';
import { Session, FeatureFlagMap } from '../types/working-model';
import Socket from 'connection/socket';
import SocketManager from 'connection/socket-main-thread/socket-manager';
import { useGetEvent } from 'hooks/session.hooks';
import { LiveStateChangePayload, StreamState } from 'types/live-state';
import { useDispatch } from 'react-redux';
import { liveStageChange, setLiveStates } from 'store/actions/event/event-actions';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { showAlert } from '@general-ui/alert/alert-service';
import AppContext, { AppContexts } from 'components/app-context';

export default function useLive(playbackUrl: string | null | undefined, session?: Session, featureFlags?: FeatureFlagMap): [boolean, number | undefined, number | undefined] {
	const liveStates = useTypedSelector(state => state.LiveEventReducer.liveStates);
	const playerLatencyInSeconds = useTypedSelector(state => state.LiveEventReducer.playerLatencyInSeconds);
	const event = useGetEvent();
	const socket = useRef<Socket | null>(null);
	const eventUuid = event?.uuid;
	const sessionUuid = session?.uuid;
	const isTestBroadcast = session?.streaming_options.test_stream;
	const appContext = useContext(AppContext);
	const isAttendee = appContext === AppContexts.liveEvent;
	const playerLatencyInSecondsRef = useRef(playerLatencyInSeconds);
	const playerLatencyTimeout = useRef<NodeJS.Timeout | null>(null);
	const shouldListenForLiveUpdates = !(isAttendee && isTestBroadcast);
	const dispatch = useDispatch();

	useEffect(() => {
		playerLatencyInSecondsRef.current = playerLatencyInSeconds;
	}, [playerLatencyInSeconds]);

	useEffect(() => {
		if (sessionUuid && shouldListenForLiveUpdates) {
			dispatch(setLiveStates(sessionUuid));
		}
	}, [sessionUuid, dispatch, shouldListenForLiveUpdates]);

	useEffect(() => {
		socket.current = SocketManager.get(`event-${eventUuid}`);

		const handleLiveStateChange = ({ data }: { data: LiveStateChangePayload }) => {
			if (shouldListenForLiveUpdates) {
				// if there is playback latency and the stream is not live, wait 
				// until the latency ends before changing the live state to offline
				// this is because the mux offline event is triggered before the stream ends
				if (playerLatencyInSecondsRef.current && data.state !== StreamState.live) {
					// wait until player latency has passed before changing live state
					playerLatencyTimeout.current = setTimeout(() => {
						dispatch(liveStageChange(data));
					}, playerLatencyInSecondsRef.current * 1000);
				} else {
					dispatch(liveStageChange(data));
				}
			}
		};

		socket.current?.addListener('live-state-change', handleLiveStateChange);

		const handleOnline = () => {
			if (sessionUuid && shouldListenForLiveUpdates) {
				dispatch(setLiveStates(sessionUuid));
			}
		};

		const handleOffline = () => {
			// user connection dropped, show alert to user
			showAlert({
				message: 'Network Offline',
				description: 'It looks like you are offline. Please check your network connection.',
				type: 'warning'
			});
		};

		const handleNetworkChange = () => {
			console.warn(`Network change, network is ${navigator.onLine ? 'online' : 'offline'}`);

			// network changed from offline to online, re-check current live state
			if (navigator.onLine && sessionUuid) {
				dispatch(setLiveStates(sessionUuid));
			} else if (!navigator.onLine) {

				// user connection dropped, show alert to user
				showAlert({
					message: 'Network Offline',
					description: 'It looks like you are offline. Please check your network connection.',
					type: 'warning'
				});
			}
		};

		if ('connection' in navigator) {
			try {
				(navigator.connection as any).addEventListener('change', handleNetworkChange);
			} catch {
				window.addEventListener('online', handleOnline);
				window.addEventListener('offline', handleOffline);
				console.warn('navigator.connection not supported');
			}
		} else {
			window.addEventListener('online', handleOnline);
			window.addEventListener('offline', handleOffline);
		}

		return () => {
			socket.current?.removeListener('live-state-change', handleLiveStateChange);
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
			if ('connection' in navigator) {
				try {
					(navigator.connection as any).removeEventListener('change', handleNetworkChange);
				} catch {
					console.warn('navigator.connection not supported');
				}
			}
		};
	}, [eventUuid, dispatch, sessionUuid, shouldListenForLiveUpdates]);

	useEffect(() => {
		return () => {
			if (playerLatencyTimeout.current) {
				clearTimeout(playerLatencyTimeout.current);
			}
		};
	}, []);

	const {
		state,
		liveAt,
		endAt
	} = liveStates[playbackUrl ?? ''] ?? { state: StreamState.offline };

	return [state === StreamState.live, liveAt, endAt];
}