import { PageModule, HomepageHeader, HomepageFooter, EventNavbar, PageModuleType, Dictionary } from "../types/working-model";

export function hasPageModuleControls(_module: PageModule | HomepageHeader | HomepageFooter | EventNavbar): _module is PageModule {
	// check to see if module has key that only lives on PageModule types
	return (_module as PageModule)?.content_modules !== undefined;
}

export function hasFooterControls(_module: PageModule | HomepageHeader | HomepageFooter | PageModuleType | EventNavbar): _module is HomepageFooter {
	// if _module has a callToAction then this will fail
	// and we know its not a footer
	const isNotHeader = !hasHeaderControls(_module);
	return isNotHeader && (_module as HomepageFooter)?.logo !== undefined;
}

export function hasHeaderControls(_module: PageModule | HomepageHeader | HomepageFooter | PageModuleType | EventNavbar): _module is HomepageHeader {
	return (_module as HomepageHeader)?.callToAction !== undefined;
}

export function hasNavbarControls(_module: PageModule | HomepageHeader | HomepageFooter | PageModuleType | EventNavbar): _module is EventNavbar {
	return (_module as EventNavbar)?.navbarItems !== undefined;
}

export function hasProvidedControls<T>(object: Dictionary, key: keyof T): boolean {
	return (object as T)?.[key] !== undefined;
}
